const getAddressFields = (objectName: string) => ({
  zipcode: {
    name: `${objectName}.zipCode`,
    label: "CEP*",
    hasError: false,
    validation: false,
    maxLength: 9,
    minLenght: 9
  },

  streetType: {
    name: `${objectName}.streetType`,
    label: "Tipo Logradouro*",
    hasError: false,
    maxLength: 9,
    minLenght: 9
  },

  street: {
    name: `${objectName}.street`,
    label: "Logradouro*",
    //Nome do logradouro do endereço da pessoa. É composto por até 40 caracteres. Campo obrigatório.
  },
  number: {
    name: `${objectName}.number`,
    label: "Número*",
    //Número do endereço da pessoa. É composto por até 6 caracteres. Caso o endereço não possua número, informar SN. Campo obrigatório.
  },

  complement: {
    name: `${objectName}.complement`,
    label: "Complemento",
    //Complemento do endereço da pessoa. É composto por até 20 caracteres. Campo opcional.
  },

  district: {
    name: `${objectName}.district`,
    label: "Bairro*",
    //Bairro do endereço da pessoa. É composto por até 40 caracteres. Campo obrigatório.
  },

  city: {
    name: `${objectName}.city`,
    label: "Municipio*",
    //Cidade do endereço da pessoa. É composto por até 30 caracteres. Campo obrigatório.
  },

  state: {
    name: `${objectName}.state`,
    label: "Estado*",
    //ENUM COM SIGLAS //[ AC, AL, AM, AP, BA, CE, DF, ES, GO, MA, MG, MS, MT, PA, PB, PE, PI, PR, RJ, RN, RO, RR, RS, SC, SE, SP, TO ]
  },

  hasComplement: {
    name: `${objectName}.hasComplement`,
    label: "Possui Complemento",
    //Campo booleano que indica se o endereço possui complemento. Campo obrigatório.
  },
  propertyType: {
    name: `${objectName}.propertyType`,
    label: "Tipo de Imóvel*"
  }
});

export const formField = {
  product: {
    code: {
      name: "product.code",
      requiredErrorMsg: "Necessário selecionar uma opção. ",
    },
  },
  lessor: {
    document: {
      name: "lessor.document",
      label: "CPF/CNPJ*",
      requiredErrorMsg: "CPF ou CNPJ do locador. Campo obrigatório.",
    },
    name: {
      name: "lessor.name",
      label: "Nome do locador*",
      requiredErrorMsg:
        "Nome e sobrenome ou razão social do locador do imóvel. É composto por no mínimo 5 caracteres, no máximo 64 caracteres, pelo menos 1 espaço entre o nome e sobrenome e sem caracteres especiais. Campo obrigatório.",
    },
    birthdate: {
      name: "lessor.birthDate",
      label: "Data de nascimento*",
      requiredErrorMsg:
        "Data de nascimento do locador no formato DD/MM/AAAA. Campo obrigatório se locador for pessoa física.",
    },
    mother: {
      name: "lessor.mother",
      label: "Nome da Mãe",
      invalidErrorMsg:
        "Nome da mãe do locador. É composto por no mínimo 5 caracteres, no máximo 60 caracteres, pelo menos 1 espaço entre o nome e sobrenome e sem caracteres especiais. Campo opcional e utilizado apenas se o locador for pessoa física.",
    },
    email: {
      name: "lessor.email",
      label: "Email*",
      requiredErrorMsg:
        "É o endereço de e-mail do locador. É composto por no máximo 64 caracteres, deve conter um @, um . após o @, ao menos 5 caracteres antes do @, não deve terminar com um caracter especial, não deve conter nenhum caracter especial além do arroba, hífen e underline/underscore, não deve conter espaços. Campo obrigatório.",
    },
    cellphone: {
      name: "lessor.cellphone",
      label: "Telefone celular",
      //Número do telefone celular do locador, incluindo o código DDD. É composto por 11 caracteres numéricos, sendo os dois primeiros o código DDD e os demais o número do celular, preenchido com zeros à esquerda, sem máscaras, sem caracteres especiais ou espaços. Campo obrigatório para pessoa física.
    },
    commercialPhone: {
      name: "lessor.commercialPhone",
      label: "Telefone Comercial",
      //Número do telefone comercial do locador, incluindo o código DDD. É composto por 10 caracteres numéricos, sendo os dois primeiros o código DDD e os demais o número do telefone, preenchido com zeros à esquerda, sem máscaras, sem caracteres especiais ou espaços. Campo obrigatório para pessoa jurídica.
    },
    residentialPhone: {
      name: "lessor.residentialPhone",
      label: "Telefone Residêncial",
      //Número do telefone residencial do locador, incluindo o código DDD. É composto por 10 caracteres numéricos, sendo os dois primeiros o código DDD e os demais o número do telefone, preenchido com zeros à esquerda, sem máscaras, sem caracteres especiais ou espaços. Campo opcional e utilizado apenas se o locador for pessoa física.
    },
    address: getAddressFields("lessor.address"),
  },
  property: getAddressFields("property"),
  tenants: {
    document: {
      name: "tenants.document",
      label: "CPF/CNPJ*",
      //CPF do pretendente / locatário / inquilino informado na API de orçamento. Deve conter 11 digitos numéricos, sem máscaras ou caracteres especiais e caso tenha menos que 11 digitos preencher com zeros à esquerda. Campo obrigatório.
    },
    name: {
      name: "tenants.name",
      label: "Nome*",
      //Nome e sobrenome do pretendente / locatário / inquilino. É composto por no mínimo 5 caracteres, no máximo 60 caracteres, pelo menos 1 espaço entre o nome e sobrenome e sem caracteres especiais. Campo obrigatório.
    },
    holder: {
      name: "tenants.holder",
      label: "Titular?*",
      // [ S, N ]
    },

    birthdate: {
      name: "tenants.birthdate",
      label: "Data de nascimento*",
      //Data de nascimento do locatário no formato DD/MM/AAAA. Campo obrigatório.
    },

    gender: {
      name: "tenants.gender",
      label: "Sexo/Gênero: ",
      //[ M, F, I ]
    },
    mother: {
      name: "tenants.mother",
      label: "Nome da mãe",
      //Nome da mãe do locatário. É composto por no mínimo 5 caracteres, no máximo 60 caracteres, pelo menos 1 espaço entre o nome e sobrenome e sem caracteres especiais. Campo opcional.
    },
    email: {
      name: "tenants.email",
      label: "Email*",
      //É o endereço de e-mail do locatário. É composto por no máximo 64 caracteres, deve conter um @, um . após o @, ao menos 5 caracteres antes do @, não deve terminar com um caracter especial, não deve conter nenhum caracter especial além do arroba, hífen e underline/underscore, não deve conter espaços. Campo opcional, que deverá a partir de agora ser enviado ainda no orçamento.
    },
    cellphone: {
      name: "tenants.cellphone",
      label: "Telefone celular*",
      //Número do telefone celular do locatário, incluindo o código DDD. É composto por 11 caracteres numéricos, sendo os dois primeiros o código DDD e os demais o número do celular, preenchido com zeros à esquerda, sem máscaras, sem caracteres especiais ou espaços. Campo opcional, que deverá a partir de agora ser enviado ainda no orçamento.
    },
    commercialPhone: {
      name: "tenants.commercialPhone",
      label: "Telefone Comercial",
      //Número do telefone comercial do locatário, incluindo o código DDD. É composto por 10 caracteres numéricos, sendo os dois primeiros o código DDD e os demais o número do telefone, preenchido com zeros à esquerda, sem máscaras, sem caracteres especiais ou espaços. Campo opcional.
    },
    residentialPhone: {
      name: "tenants.residentialPhone",
      label: "Telefone Residêncial",
      //Número do telefone residencial do locatário, incluindo o código DDD. É composto por 10 caracteres numéricos, sendo os dois primeiros o código DDD e os demais o número do telefone, preenchido com zeros à esquerda, sem máscaras, sem caracteres especiais ou espaços. Campo opcional.
    },
  },
  contract: {
    vigencyType: {
      name: "contract.vigencyType",
      label: "Tipo de vigência*",
      //Tipo de vigência do contrato. [ 0, 1, 2 ]
    },
    startContractDate: {
      name: "contract.startContractDate",
      label: "Data de início do contrato*",
      //Data de início do contrato no formato DD/MM/AAAA. Campo obrigatório.
    },
    endContractDate: {
      name: "contract.endContractDate",
      label: "Data de término do contrato*",
      //Data de término do contrato no formato DD/MM/AAAA. Campo obrigatório.
    },
    startInsuranceDate: {
      name: "contract.startInsuranceDate",
      label: "Data de início do seguro*",
      //Data de início do seguro no formato DD/MM/AAAA. Campo obrigatório.
    },
    endInsuranceDate: {
      name: "contract.endInsuranceDate",
      label: "Data de término do seguro*",
      //Data de término do seguro no formato DD/MM/AAAA. Campo obrigatório.
    }}
}
