import React, { useEffect, useState } from 'react';
import {
  Button,
  Dimmer,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Input,
  Loader,
  Table
} from 'semantic-ui-react';
import { Container, Form, Title, Subtitle, Disclaimer } from './styles';
import { getUsers } from '../../services/broker';
import { UsersToCustomDropDownOptions as getOptionsConverted } from '../../util';
import { CustoDropDownItemProps } from '../../components/types';
import { getEstatesByClaimResponsibleId } from '../../services/estate';
import Toggle from '../../components/Toggle';
import { dangerNotification, successNotification } from '../../services/notification';
import {
  getClaimsByClaimResponsible,
  transferClaimsResponsibleById
} from '../../services/claim';
import { ClaimEstate } from '../claim_estates/types/CaimEstate.types';
import { DateToStringDate } from '../../services/date';

const ClaimEstatesResponsibleTransfer = () => {
  const [step, setStep] = useState<1 | 2>(1);
  const [isEstatesLoading, setIsEstatesLoading] = useState<boolean>(false);
  const [oldAccountManagerSelected, setOldAccountManagerSelected] = useState<string>();
  const [newAccountManagerSelected, setNewAccountManagerSelected] = useState<string>();
  const [estatesSelected, setEstatesSelected] = useState<string[]>([]);
  const [isAccountManagersLoading, setIsAccountManagersLoading] =
    useState<boolean>(false);
  const [estates, setEstates] = useState<CustoDropDownItemProps[]>([]);
  const [accountManagers, setAccountManagers] = useState<CustoDropDownItemProps[]>([]);
  const [isClaimsLoading, setIsClaimsLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [claims, setClaims] = useState<ClaimEstate[]>([]);
  const [shouldTransferAllClaims, setShouldTransferAllClaims] = useState<boolean>(true);
  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);

  const w100 = { width: '100%', marginTop: 5 };

  useEffect(() => {
    const getAccountManagersData = async () => {
      setIsAccountManagersLoading(true);
      try {
        const response = await getUsers();
        if (response) {
          const accountManagerOptions = getOptionsConverted(false, response);
          setAccountManagers(accountManagerOptions);
        }
      } catch (err) {
        dangerNotification('Oops', 'Ocorreu um erro ao buscar os responsáveis');
      } finally {
        setIsAccountManagersLoading(false);
      }
    };

    getAccountManagersData();
  }, []);

  useEffect(() => {
    if (oldAccountManagerSelected) {
      const getEstatesData = async () => {
        setIsEstatesLoading(true);
        try {
          const response = await getEstatesByClaimResponsibleId(
            oldAccountManagerSelected
          );
          if (response) {
            const estatesOptions = getOptionsConverted(false, response);
            setEstates(estatesOptions);
          }
        } catch (err) {
          dangerNotification(
            'Oops',
            'Ocorreu um erro ao buscar as imobiliárias do responsável'
          );
        } finally {
          setIsEstatesLoading(false);
        }
      };

      getEstatesData();
    }
  }, [oldAccountManagerSelected]);

  const shouldEnableSubmitButton = (): boolean => {
    return (
      step === 1 &&
      !!oldAccountManagerSelected &&
      !!newAccountManagerSelected &&
      estatesSelected?.length > 0
    );
  };

  const clearPage = () => {
    setStep(1);
    setNewAccountManagerSelected(undefined);
    setOldAccountManagerSelected(undefined);
    setClaims([]);
    setEstates([]);
    setEstatesSelected([]);
    setIsTermsAccepted(false);
  };

  useEffect(() => {
    if (
      shouldTransferAllClaims &&
      step === 2 &&
      oldAccountManagerSelected &&
      estatesSelected.length > 0
    ) {
      const getClaimsData = async () => {
        try {
          setIsClaimsLoading(true);
          const response = await getClaimsByClaimResponsible(
            oldAccountManagerSelected,
            estatesSelected
          );
          if (response) {
            setClaims(response);
          }
        } catch (err) {
          dangerNotification(
            'Oops',
            'Ocorreu um erro ao buscar os sinistros do responsável'
          );
        } finally {
          setIsClaimsLoading(false);
        }
      };
      getClaimsData();
    }
  }, [estatesSelected, oldAccountManagerSelected, shouldTransferAllClaims, step]);

  const getAccountManagerNameById = (id: string): string => {
    return accountManagers.find(item => item.value === id)?.text || '';
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (
        oldAccountManagerSelected &&
        newAccountManagerSelected &&
        estatesSelected.length > 0
      ) {
        await transferClaimsResponsibleById(
          oldAccountManagerSelected,
          newAccountManagerSelected,
          estatesSelected,
          shouldTransferAllClaims
        );
        successNotification('Sucesso!', 'Transferência de responsável concluída.');
        clearPage();
      }
    } catch (err) {
      dangerNotification('Oops', 'Ocorreu um erro ao enviar seus dados');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container>
        <Title>Transferir imobiliárias e Sinistros</Title>
        {step === 1 && (
          <Subtitle>
            Selecione abaixo o usuário responsável pelos Sinistros de 1 ou mais
            imobiliárias para que sejam atribuídos ao novo responsável.
          </Subtitle>
        )}
        {step === 2 && (
          <Subtitle>
            Confira as informações abaixo e confirme as imobiliárias e sinistros a serem
            transferidas para o novo responsável.
          </Subtitle>
        )}
        <Divider />
        {step === 1 && (
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <label>Responsável atual*</label>
                  <div style={w100}>
                    <Dropdown
                      style={w100}
                      value={oldAccountManagerSelected}
                      name={`oldAccountManager`}
                      options={accountManagers || []}
                      disabled={isAccountManagersLoading || isEstatesLoading}
                      loading={isAccountManagersLoading || isEstatesLoading}
                      placeholder={'Selecionar...'}
                      search
                      selection
                      onChange={(_, { value }) => {
                        setOldAccountManagerSelected(value as string);
                      }}
                      clearable
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <label>Selecione as imobiliárias que deseja transferir*</label>
                  <div style={w100}>
                    <Dropdown
                      style={w100}
                      value={estatesSelected}
                      name={`estatesSelected`}
                      options={estates || []}
                      disabled={
                        !oldAccountManagerSelected ||
                        isEstatesLoading ||
                        isAccountManagersLoading
                      }
                      loading={
                        isEstatesLoading || isAccountManagersLoading || isEstatesLoading
                      }
                      placeholder={'Selecionar...'}
                      search
                      selection
                      multiple
                      onChange={(_, { value }) => {
                        setEstatesSelected(value as string[]);
                      }}
                      clearable
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <label>
                    Deseja transferir também todos os sinistros em andamento?*
                  </label>
                  <Toggle
                    style={w100}
                    checked={shouldTransferAllClaims}
                    onChange={() => setShouldTransferAllClaims(prevValue => !prevValue)}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <label>
                    Selecione o usuário que irá receber as imobiliárias transferidas*
                  </label>
                  <div style={w100}>
                    <Dropdown
                      style={w100}
                      value={newAccountManagerSelected}
                      name={`newAccountManager`}
                      options={accountManagers || []}
                      disabled={isAccountManagersLoading || isEstatesLoading}
                      loading={isAccountManagersLoading || isEstatesLoading}
                      placeholder={'Selecionar...'}
                      search
                      selection
                      onChange={(_, { value }) => {
                        setNewAccountManagerSelected(value as string);
                      }}
                      clearable
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="right">
                  <Button
                    color="blue"
                    disabled={!shouldEnableSubmitButton()}
                    type="submit"
                    size="medium"
                    onClick={() => {
                      setStep(2);
                    }}
                  >
                    Avançar
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
        {step === 2 && (
          <Grid>
            <Dimmer active={isLoading || isClaimsLoading} inverted>
              <Loader />
            </Dimmer>
            <Grid.Row>
              <Grid.Column width={8}>
                <label>
                  <b>Novo responsável selecionado</b>
                </label>
                <div style={w100}>
                  <span>
                    {newAccountManagerSelected &&
                      getAccountManagerNameById(newAccountManagerSelected)}
                  </span>
                </div>
              </Grid.Column>
              <Grid.Column width={8}>
                <label>
                  <b>Responsável antigo</b>
                </label>
                <div style={w100}>
                  <span>
                    {oldAccountManagerSelected &&
                      getAccountManagerNameById(oldAccountManagerSelected)}
                  </span>
                </div>
              </Grid.Column>
            </Grid.Row>
            {shouldTransferAllClaims && (
              <Grid.Row>
                <Grid.Column>
                  <b>Sinistros transferidos</b>
                  <Table>
                    <Table.Header>
                      <Table.HeaderCell>Solicitado em</Table.HeaderCell>
                      <Table.HeaderCell>Seguradora</Table.HeaderCell>
                      <Table.HeaderCell>Imobiliária</Table.HeaderCell>
                      <Table.HeaderCell>Inquilino</Table.HeaderCell>
                      <Table.HeaderCell>Responsável</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                      {claims?.map(claim => (
                        <Table.Row key={claim.id}>
                          <Table.Cell>
                            {claim.createdBy
                              ? DateToStringDate(claim.createdAt)
                              : 'Não informado'}
                          </Table.Cell>
                          <Table.Cell>{claim?.insurer?.name}</Table.Cell>
                          <Table.Cell>{claim?.estate?.name}</Table.Cell>
                          <Table.Cell>{claim?.claimTenant?.fullName}</Table.Cell>
                          <Table.Cell>{claim?.responsible?.name}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            )}
            <Disclaimer>
              <p>
                <Icon bordered name="exclamation" /> <b>Atenção</b>
              </p>
              <p>
                Ao realizar a transferência das imobiliárias e sinistros selecionados os
                mesmos serão removidos dos atuais responsáveis. Deseja continuar?
              </p>
              <p>
                <Input
                  type="checkbox"
                  value={isTermsAccepted}
                  onChange={() => {
                    setIsTermsAccepted(prevValue => !prevValue);
                  }}
                />
                Estou ciente e quero continuar.
              </p>
            </Disclaimer>
            <Grid.Row>
              <Grid.Column textAlign="right">
                <Button
                  color="grey"
                  type="button"
                  size="medium"
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  Voltar
                </Button>
                <Button
                  color="green"
                  disabled={!isTermsAccepted}
                  type="button"
                  size="medium"
                  onClick={handleSubmit}
                >
                  Finalizar
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default ClaimEstatesResponsibleTransfer;
