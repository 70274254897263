import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px;
`;

export const Disclaimer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding-bottom: 24px;

  h4 {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }
`;

export const StyledModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  padding-bottom: 0;
  border-top: 1px solid #e0e0e0;
  //background-color: #f9f9f9;

  .ui.button {
    margin-left: 0.5em;
  }
`;

