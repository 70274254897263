import {ObjectSchema, Ref, Schema} from "yup";
import * as Yup from "yup";
import {
  ConnectorEnabledTenantInputSectionSchema
} from "./tenant-input-section/connector-enabled-tenant-input-section/connector-tenant-input-section-schema";
import {
  FairfaxTenantInputSectionSchema
} from "./tenant-input-section/fairfax-tenant-input-section/fairfax-tenant-input-section-schema";
import {
  TokioMarineTenantInputSectionSchema
} from "./tenant-input-section/tokio-marine-tenant-input-section/tokio-marine-tenant-input-section-schema";
import {
  InsuranceDataSectionSchema,
  insuranceDataSectionYupSchema
} from "./insurance-data-section/insurance-data-section-schema";
import {
  PropertyInputSectionSchema,
  propertyInputSectionYupSchema
} from "./property-input-section/property-input-section-schema";
import {OwnerInputSectionSchema, ownerInputSectionYupSchema} from "./owner-input-section/owner-input-section-schema";
import {getFireInsuranceCoveragesYupSchema} from "./coverages-section";
import {ConnectorCoverageSectionSchema} from "./coverages-section/connector-coverages-section/connector-coverage-section-schema";
import {getFireInsuranceTenantSectionYupSchema} from "./tenant-input-section";

export const getFireInsuranceQuotationYupSchema = (isConnectorEnabled: boolean, insurerCode: string): ObjectSchema<FireInsuranceQuotationSchema> => {
  const insuranceDataSection = insuranceDataSectionYupSchema;
  const propertyInputSection = propertyInputSectionYupSchema;
  const coveragesSection = getFireInsuranceCoveragesYupSchema(isConnectorEnabled, insurerCode);
  const tenantInputSection = getFireInsuranceTenantSectionYupSchema(isConnectorEnabled, insurerCode);
  const ownerInputSection = ownerInputSectionYupSchema;

  const object: any = {
    insuranceDataSection,
    propertyInputSection,
    coveragesSection,
    tenantInputSection,
    ownerInputSection
  }

  const schema: ObjectSchema<FireInsuranceQuotationSchema> = Yup.object().shape({
    ...object
  });

  return schema;
}

export type FireInsuranceQuotationSchema = {
  insuranceDataSection: ObjectSchema<InsuranceDataSectionSchema>;
  propertyInputSection: ObjectSchema<PropertyInputSectionSchema>;
  coveragesSection?: ObjectSchema<ConnectorCoverageSectionSchema>;
  tenantInputSection: ObjectSchema<FairfaxTenantInputSectionSchema>
    | ObjectSchema<TokioMarineTenantInputSectionSchema>
    | ObjectSchema<ConnectorEnabledTenantInputSectionSchema>;
  ownerInputSection: OwnerInputSectionSchema;
}
