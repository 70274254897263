import { post, get } from "./request";
import moment from "moment-timezone";

import { FireInsuranceProposal, FireInsuranceBudget } from "../types/FireInsurance";
import { FilterData } from "../pages/fire-insurance-proposal-list/types";
import { dangerNotification, warningNotification } from "./notification";
import { FireInsuranceCancel } from "../types/FireInsuranceCancel";
import { FireInsuranceCancelRefundInfo } from '../types/FireInsuranceCancelRefundInfo';

export enum FireInsuranceProposalStatus {
  CREATED = "CREATED",
  CANCELED = "CANCELED",
  WAITING = "WAITING",
  APPROVED = "APPROVED",
}

export const fireInsuranceProposalStatusLabel = new Map<
  FireInsuranceProposalStatus,
  string
>([
  [FireInsuranceProposalStatus.CREATED, "Emitida"],
  [FireInsuranceProposalStatus.CANCELED, "Cancelada"],
  [FireInsuranceProposalStatus.WAITING, "Aguardando Emissão"],
  [FireInsuranceProposalStatus.APPROVED, "Contratada"],
]);

export enum FireInsuranceProposalSendStatus {
  PENDING = "PENDING",
  SENT = "SENT",
}

export const createProposal = async (
  proposal: FireInsuranceProposal
): Promise<FireInsuranceProposal> => {
  let body: object;

  if (!proposal.id) {
    body = { proposal: proposal };
  } else {
    body = { fire_insurance_proposal_id: proposal.id };
  }

  const response = await post(`/fire-insurance-proposals`, body);

  return response;
};

// export const cancelProposal = async (proposal: FireInsuranceProposal): Promise<void> => {
//   if (proposal.id) {
//     const body = [{ id: proposal.id }];
//     return await post("/fire-insurance-proposals/cancel", body);
//   } else {
//     return new Promise((_, reject) => reject("Proposal ID is not defined."));
//   }
// };

export const cancelProposal = async (payload: FireInsuranceCancel): Promise<any> => {
  const url = `/fire-insurance-proposals/cancel`;

  try {
    return await post(url, payload);
  } catch (error) {
    dangerNotification('Erro', 'Não foi possível solicitar o cancelamento.');
    console.log('Erro ao cancelar apólices');
  }
}

export const cancelPortoSeguroProposal = async (payload: FireInsuranceCancel): Promise<any> => {
  const url = '/fire-insurance-proposals/porto-seguro/proposal/cancel';

  try {
    return await post(url, payload);
  } catch (error) {
    console.log(error);
    dangerNotification('Erro', 'Não foi possível solicitar o cancelamento para o seguro incêndio da Porto Seguro.');
  }
};


export const cancelGeralProposal = async (payload: FireInsuranceCancel): Promise<any> => {
  const url = '/fire-insurance-proposals/proposal/cancel';

  try {
    return await post(url, payload);
  } catch (error) {
    console.log(error);
    dangerNotification('Erro', 'Não foi possível solicitar o cancelamento para o seguro incêndio.');
  }
};
export const cancelSegImobProposal = async (payload: FireInsuranceCancel): Promise<any> => {
  const url = '/fire-insurance-proposals/segimob/proposal/cancel';

  try {
    return await post(url, payload);
  } catch (error) {
    console.log(error);
    dangerNotification('Erro', 'Não foi possível solicitar o cancelamento para o seguro incêndio da SegImob.');
  }
};

export const cancelAlfaSegurosProposal = async (payload: FireInsuranceCancel): Promise<any> => {
  const url = '/fire-insurance-proposals/alfa-seguros/proposal/cancel';

  try {
    return await post(url, payload);
  } catch (error) {
    console.log(error);
    dangerNotification('Erro', 'Não foi possível solicitar o cancelamento para o seguro incêndio da SegImob.');
  }
}

export const getRefundInfo = async (policyId: string): Promise<any> => {
  const url = `/fire-insurance-proposals/alfa-seguros/proposal/cancel/refund/${policyId}`;

  try {
    return await get(url);
  } catch (error) {
    console.log(error);
    dangerNotification('Erro', 'Não foi possível pegar as informações de restituição para o seguro incêndio.');
  }
}

export const cancelProposalRefundInfo = async (payload: FireInsuranceCancelRefundInfo): Promise<any> => {
  const url = '/fire-insurance-proposals/cancel/refund-info';

  try {
    return await post(url, payload);
  } catch (error) {
    console.log(error);
    dangerNotification('Erro', 'Não foi possível adicionar informações de restituição para o seguro incêndio.');
  }
}

export const getProposalRefundInfo = async (policyId: string): Promise<any> => {
  const url = `/fire-insurance-proposals/cancel/refund-info/${policyId}`;

  try {
    return await get(url);
  } catch (error) {
    console.log(error);
    dangerNotification('Erro', 'Não foi possível obter as informações de restituição para o seguro incêndio.');
  }
}

export const getProposalCancelInfo = async (policyId: string): Promise<any> => {
  const url = `/fire-insurance-proposals/cancel/info/${policyId}`;
  try {
    return await get(url);
  } catch (error) {
    console.log(error);
    dangerNotification('Erro', 'Não foi possível obter as informações de cancelamento para o seguro incêndio.');
  }
}

export const printProposal = async (
  id: string,
  userId: string,
  code: string,
  insurer?: string | undefined
): Promise<any> => {
  try {
    warningNotification("Aviso", "Baixando Proposta");
    return await get(
      `/fire-insurance-proposals/print?proposal_id=${id}&user_id=${userId}&code=${code}&insurer_id=${insurer || 0
      }`
    );
  } catch (e) {
    throw e;
  }
};

export const createProposalFromBudget = async (
  budget: FireInsuranceBudget,
  insurerId?: string
): Promise<FireInsuranceProposal> => {
  let body: object;

  if (!budget.id) {
    body = { budget: budget, insurer_id: insurerId };
  } else {
    body = {
      fire_insurance_budget_id: budget.id,
      budget: budget,
      insurer_id: insurerId,
    };
  }

  const response = await post(`/fire-insurance-proposals`, body);

  return response;
};

export const getProposals = async (
  filter?: FilterData
): Promise<Array<FireInsuranceProposal>> => {
  let query = "";

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  let response;

  try {
    response = await get(`/fire-insurance-proposals/filter?${query}`);
  } catch (e) {
    response = [];
  }
  return response;
};

export const getProposal = async (
  id?: string
): Promise<FireInsuranceProposal> => {
  return await get(`/fire-insurance-proposals/id/${id}`);
};

export const getProposalStatus = async (
  user_id: string,
  proposal_id: string,
  code: string
): Promise<any> => {
  let response;

  try {
    response = await get(
      `/fire-insurance-proposals/status?user_id=${user_id}&proposal_id=${proposal_id}&code=${code}`
    );
  } catch (e) {
    response = [];
  }
  return response;
};

export const sortProposals = (
  data: Array<FireInsuranceProposal>,
  ascending: boolean
): Array<FireInsuranceProposal> => {
  return data.sort(
    (p1: FireInsuranceProposal, p2: FireInsuranceProposal): number => {
      const p1CreatedAt = moment(p1.created_at || new Date(0, 0, 0));
      const p2CreatedAt = moment(p2.created_at || new Date(0, 0, 0));
      if (p1CreatedAt.isSame(p2CreatedAt)) {
        return 0;
      } else {
        return ascending === p1CreatedAt.isAfter(p2CreatedAt) ? -1 : 1;
      }
    }
  );
};

export const getPolicyLink = async (dto: { fireInsurancePolicyId: string }): Promise<any> => {
  const { fireInsurancePolicyId } = dto;
  try {
    const data = await get(`/fire-insurance-proposals/download-policy?fireInsurancePolicyId=${fireInsurancePolicyId}`);

    return data;
  } catch (err) {
    throw err;
  }
}
