import React, { useEffect, useState } from 'react';
import { Button, Header, Icon, Modal, Step, Loader, Dimmer } from 'semantic-ui-react';

import DocumentsComponent from '../Steps/DocumentsComponentStep';
import { Formik } from 'formik';
import { CreateCancelationInitialInfo } from '../Formik/InitialValues/CreateCancelationInitialValues';
import CancelationInfoStep from '../Steps/CancelationInfoStep';
import { CreateCancelationInitialValuesType } from '../Formik/types/CreateCancelationInitialValuesTypes';
import {
  dangerNotification,
  successNotification,
  warningNotification
} from '../../../../../services/notification';
import { InsurerState } from '../../../../../types/InsurerState';
import { connect } from 'react-redux';
import { iRootDispatch, iRootState } from '../../../../../store';
import { AvailableBrokersAndEstatesState } from '../../../../../store/types/temp-types';
import { CustoDropDownItemProps } from '../../../../../components/types';
import { uuid } from 'uuidv4';
import { postFileAndGetPreSignAxios } from '../../../../../services/file';
import { createCancelation } from '../../../../../services/canceled-bond-insurance';
import { useHistory } from 'react-router-dom';
import { isEmail } from '../../../../../util';
import CheckPolicyStep from '../Steps/CheckPolicyStep';
import { get } from '../../../../../services/storage';

type ModalContentType = {
  children: React.ReactNode;
  values: CreateCancelationInitialValuesType;
};

const CreateCancelationModal = (props: {
  availableBrokersAndEstates: any; //{ estates: CustoDropDownItemProps[] };
}) => {
  const estates: CustoDropDownItemProps[] = props.availableBrokersAndEstates.estates;
  const insurers: InsurerState[] | null =
    props.availableBrokersAndEstates.bondInsurers.map((insurer: any) => insurer.value);
  const [openHireProposalModal, setOpenHireProposalModal] = useState(false);
  const [stepOfHireModal, setStepOfHireModal] = useState('check_policy');
  const [registerId, setRegisterId] = useState('');
  const [flagSubmitFrom, setFlagSubmitFrom] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setRegisterId(uuid());
  }, []);

  function handleCloseModal() {
    setOpenHireProposalModal(false);
    setFlagSubmitFrom(false);
  }

  function handleOpenModal() {
    setStepOfHireModal('check_policy');
    setOpenHireProposalModal(true);
    setFlagSubmitFrom(false);
  }

  async function handleNextStep(values: CreateCancelationInitialValuesType) {
    switch (stepOfHireModal) {
      case 'check_policy': {
        let error = false;

        if (!values.checked) {
          warningNotification(
            'Ops...',
            'Por favor, busque pelo documento do inquilino antes de prosseguir.'
          );
          error = true;
        }

        if (!values.proceed) {
          warningNotification('Ops...', 'Por favor, confirme que deseja prosseguir.');
          error = true;
        }

        if (!error) {
          setStepOfHireModal('cancelation_info');
        }
        return;
      }
      case 'cancelation_info': {
        let error = false;
        ///@ToDo Verficar se é broker, se for, obrigar informar o estateId. Se for imob, pegar do localstorage
        if (!values.cancelation.estateId) {
          warningNotification('Ops...', 'Por favor, selecione a imobiliária desejada!');
          error = true;
        }

        if (!values.cancelation.insurerId) {
          warningNotification('Ops...', 'Por favor, selecione a seguradora desejada!');
          error = true;
        }

        if (!values.cancelation.cancellationReason) {
          warningNotification('Ops...', 'Por favor, preencha o motivo da cancelamento!');
          error = true;
        }

        if (
          !values.cancelation.notificationEmails ||
          values.cancelation.notificationEmails.length < 1 ||
          !values.cancelation.notificationEmails[0]
        ) {
          warningNotification(
            'Ops...',
            'Por favor, insira pelo menos um email para notificação!'
          );
          error = true;
        }

        if (values.cancelation.notificationEmails) {
          const emails = values.cancelation.notificationEmails as string[];
          const validEmails = emails.reduce((validEmails: string[], email: string) => {
            if (email !== '') {
              validEmails.push(email);
            }
            return validEmails;
          }, []);
          validEmails.forEach((email: string) => {
            if (!isEmail(email)) {
              warningNotification(
                'Oops...',
                `O email ${email} é inválido, por favor corrija-o.`
              );
              error = true;
            }
          });
        }

        if (!values.tenant.document) {
          warningNotification('Ops...', 'Por favor, informe o DOCUMENTO do locatário!');
          error = true;
        }

        if (!values.tenant.fullName) {
          warningNotification(
            'Ops...',
            'Por favor, informe o NOME COMPLETO do locatário!'
          );
          error = true;
        }

        if (!values.property.zipCode) {
          warningNotification('Ops...', 'Por favor, informe o CEP do imóvel!');
          error = true;
        }

        if (!values.property.state) {
          warningNotification('Ops...', 'Por favor, informe o ESTADO do imóvel!');
          error = true;
        }

        if (!values.property.city) {
          warningNotification('Ops...', 'Por favor, informe a CIDADE do imóvel!');
          error = true;
        }

        if (!values.property.district) {
          warningNotification('Ops...', 'Por favor, informe o BAIRRO do imóvel!');
          error = true;
        }

        if (!values.property.street) {
          warningNotification('Ops...', 'Por favor, informe o LOGRADOURO do imóvel!');
          error = true;
        }

        if (!values.property.number) {
          warningNotification('Ops...', 'Por favor, informe o NÚMERO do imóvel!');
          error = true;
        }

        if (!error) {
          setStepOfHireModal('document_attachment');
        }
        return;
      }
      case 'document_attachment': {
        if (!values.files || values.files.length < 1) {
          warningNotification('Ops...', 'Por favor, anexe pelo menos um arquivo!');
          return;
        }
        try {
          setLoadingFiles(true);
          await submitFiles(values.files);
          setLoadingFiles(false);
        } catch (err) {
          console.log(err);
          dangerNotification('Erro', 'Falha ao enviar os arquivos');
          return;
        } finally {
          setLoadingFiles(false);
        }
        await submitCancelation(values);
      }
    }
  }

  async function handleBackStep(values: CreateCancelationInitialValuesType) {
    switch (stepOfHireModal) {
      case 'check_policy': {
        handleCloseModal();
        return;
      }
      case 'cancelation_info': {
        values.checked = false;
        values.proceed = false;
        setStepOfHireModal('check_policy');
        return;
      }
      case 'document_attachment': {
        setStepOfHireModal('cancelation_info');
        return;
      }
    }
  }

  async function submitFiles(
    files: {
      file: File;
      name: string;
    }[]
  ) {
    const responses = [];
    for (const file of files) {
      const { data: response } = await postFileAndGetPreSignAxios({
        filename: file.file.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/[`~!@#$%^&*()_|+-=?;:'",<>{}[]\/]/gi, ''),
        contentType: file.file.type,
        referencedTableName: 'bond_insurance',
        referencedTableId: registerId,
        name: file.name
      });

      const fileToBlob = async (theFile: File) =>
        new Blob([new Uint8Array(await theFile.arrayBuffer())], {
          type: theFile.type
        });
      const blob = await fileToBlob(file.file);

      await fetch(response.uploadURL, {
        method: 'PUT',
        body: blob
      });
      responses.push({ id: response.id, contextType: file.name });
    }
    return responses;
  }

  async function submitCancelation(values: CreateCancelationInitialValuesType) {
    setLoading(true);

    const userId = get('uid');
    values.cancelation.id = registerId;
    values.cancelation.createdBy = userId!;
    values.tenant.document = values.tenant.document.replace(/\D/g, '');

    const payload = {
      cancelation: values.cancelation,
      property: values.property,
      tenant: values.tenant
    };
    if (payload.cancelation.notificationEmails as string[]) {
      payload.cancelation.notificationEmails = (
        payload.cancelation.notificationEmails as string[]
      ).join(';');
    }

    try {
      await createCancelation(payload);
      successNotification(
        'Sucesso',
        'Solicitação de cancelamento externo realizada com sucesso!'
      );
    } catch (err) {
      dangerNotification('Erro', 'Erro ao efetuar solicitação de cancelamento externo');
      console.log(err);
    } finally {
      setLoading(false);
      handleCloseModal();
      history.push('canceled-bond-insurances');
    }
  }

  const ModalContent = ({ children, values }: ModalContentType) => (
    <>
      <Modal.Content scrolling>
        <Dimmer active={loadingFiles}>
          <Loader indeterminate> Enviando documentos... </Loader>
        </Dimmer>
        <Dimmer active={loading}>
          <Loader indeterminate> Comunicando solicitação... </Loader>
        </Dimmer>

        <div style={{ width: '100%' }}>
          <Step.Group widths={8}>
            <Step
              active={stepOfHireModal === 'check_policy'}
              title="Checagem de Registros"
              description="Checagem de apólices ou cancelamentos de um mesmo Inquilino"
              icon={{ color: 'grey', name: 'search' }}
            />
            <Step
              active={stepOfHireModal === 'cancelation_info'}
              title="Informações Básicas"
              description="Informações sobre imóvel e locatário"
              icon={{ color: 'grey', name: 'home' }}
            />
            <Step
              active={stepOfHireModal === 'document_attachment'}
              title="Documentos"
              description="Anexo dos documentos essenciais"
              icon={{ color: 'grey', name: 'upload' }}
            />
          </Step.Group>
        </div>

        {children}
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={() => handleBackStep(values)}>
          <Icon name="remove" />
          {stepOfHireModal === 'check_policy' ? 'Sair' : 'Voltar'}
        </Button>
        <Button color="green" onClick={() => handleNextStep(values)}>
          <Icon name="checkmark" />{' '}
          {stepOfHireModal === 'document_attachment' ? 'Solicitar' : 'Próximo'}
        </Button>
      </Modal.Actions>
    </>
  );

  return (
    <div>
      <Modal
        style={{ height: 'auto', width: '70vw', zIndex: 10 }}
        closeIcon
        open={openHireProposalModal}
        trigger={
          <Button primary icon="hotjar" type="button">
            Novo Cancelamento de Apólice
          </Button>
        }
        onOpen={() => handleOpenModal()}
        onClose={() => handleCloseModal()}
      >
        <Header style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <Icon name={'file alternate outline'} /> Novo Cancelamento
          </div>
        </Header>

        <Formik
          initialValues={CreateCancelationInitialInfo}
          validationSchema={() => true}
          onSubmit={() => true}
        >
          {innerValues => {
            switch (stepOfHireModal) {
              case 'check_policy':
                return (
                  <ModalContent
                    values={innerValues.values}
                    children={<CheckPolicyStep {...innerValues} />}
                  />
                );

              case 'cancelation_info':
                return (
                  <ModalContent
                    values={innerValues.values}
                    children={
                      <CancelationInfoStep
                        {...innerValues}
                        estates={estates}
                        insurers={insurers}
                      />
                    }
                  />
                );

              case 'document_attachment':
                const fileIdentifiers = [
                  { name: 'Outros', key: 'OTHER', required: false },
                  {
                    name: 'Termo compra do imóvel',
                    key: 'PROPERTY_PURCHASE_TERM'
                  },
                  {
                    name: 'Termo de antecipação de locação',
                    key: 'LEASE_ADVANCE_TERM'
                  },
                  {
                    name: 'Termo de desistência de locação',
                    key: 'LEASE_WITHDRAWL_TERM'
                  },
                  {
                    name: 'Termo de Entrega de Chaves com Inexistência de débitos',
                    key: 'DELIVERY_KEYS_TERM',
                    required: true
                  },
                  {
                    name: 'Termo de troca de locatário',
                    key: 'TENANT_CHANGE_TERM'
                  },
                  {
                    name: 'Termo cancelamento de troca de seguradora',
                    key: 'INSURANCE_CHANGE_TERM'
                  },
                  {
                    name: 'Termo cancelamento duplicidade de apólice',
                    key: 'DUPLICATION_POLICY_TERM'
                  },
                  {
                    name: 'Termo cancelamento troca de garantia',
                    key: 'CHANGE_WARRANTY_TERM'
                  },
                  { name: 'Termo de rescisão', key: 'TERMINATION_TERM', required: true },
                  {
                    name: 'Endosso de cancelamento',
                    key: 'CANCELATION_ENDORSEMENT'
                  },
                  {
                    name: 'Comprovante de pagamento',
                    key: 'PAYMENT_VOUCHER'
                  }
                ];

                return (
                  <ModalContent
                    values={innerValues.values}
                    children={
                      <DocumentsComponent
                        fileIdentifiers={fileIdentifiers}
                        {...innerValues}
                      />
                    }
                  />
                );
            }
          }}
        </Formik>
      </Modal>
    </div>
  );
};

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

export default connect(mapState, mapDispatch)(CreateCancelationModal);
