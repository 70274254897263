import VMasker from 'vanilla-masker';

export const formatAmount = (amount: string | number, options?: any) => {
  if (!amount) return '';
  return VMasker.toMoney(amount, options);
}

// export const numberToMoneyString = (amount: number): string => {
//   const integerPart: string = VMasker.toMoney(Math.trunc(amount), {
//     precision: 0,
//     separator: ',',
//     delimiter: '.',
//     zeroCents: false
//   });

//   const decimalPart: string = (amount - Math.trunc(amount)).toFixed(2).replace('0.', ',');
//   return integerPart + decimalPart;
// }

export const numberToMoneyString = (amount: number): string => {
  const formattedAmount = amount.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });

  return formattedAmount;
}

export const moneyStringToNumber = (money: string): number => {
  return Number(money.replace(/\./g, '').replace(/,/, '.'));
}

export const maskDate = (date: string) =>
  VMasker.toPattern(date, "SS/SS/SSSS");

export const maskZipCode = (zipCode: string) =>
  VMasker.toPattern(zipCode, "SSSSS-SSS");

export const maskPhoneNumber = (phoneNumber: string) => {
  const onlyNumbers = phoneNumber.replace(/\D/g, '');
  if(!onlyNumbers) return '';
  if (onlyNumbers.length <= 10) {
    return VMasker.toPattern(onlyNumbers, "(SS) SSSS-SSSS");
  } else {
    return VMasker.toPattern(onlyNumbers, "(SS) SSSSS-SSSS");
  }
}

export const unmaskPhoneNumber = (value: string) => value.replace(/\(|\)|\+|-|\s*/g, '');

export const fixNumber = (value: number) => {
  return value.toFixed(2).replace('.', ',');
  // if (!(value.toString().indexOf(".") >= 0) && !(value.toString().indexOf(',') >= 0)) {
  //   return Number(
  //     value.toString().slice(0, -2)
  //     + '.' +
  //     value.toString().substring(value.toString().length, value.toString().length - 2)
  //   );
  // }
  // return value;
};

export const fixMissingZerosNumber = (value: number) => {
  if (!(value.toString().indexOf(".") >= 0) && !(value.toString().indexOf(',') >= 0)) {
    return Number(value + '.00');
  }
  return value;
}

export const maskMoney = (value: string, float = false, precision = 2) => {
  const toParse = !float ? value.replace(/\./g, '').replace(/,/, '.') : value;

  return parseFloat(toParse)
    .toFixed(precision)
    .toString()
    .replace(/\./, ',')
    .replace(/\B(?=(\d{3})+(?!\d),)/g, '.');
};

export const unmaskMoney = (value: string) => parseFloat(`${value}`.replace(/\./g, '').replace(/,/, '.'));

export const maskCpf = (value: string) => value
  .replace(/\D/g, '')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d{1,2})/, '$1-$2')
  .replace(/(-\d{2})\d+?$/, '$1');

export const maskCnpj = (value: string) => value
  .replace(/\D/g, '')
  .replace(/(\d{2})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d{4})/, '$1/$2')
  .replace(/(\d{4})(\d{1,2})/, '$1-$2')
  .replace(/(-\d{2})\d+?$/, '$1');

export const unmaskCpfOrCnpj = (value: string) => value.replace(/\(|\)|\.|\/|-|\s*/g, '');

export const maskCpfOrCnpj = (value: string) => {
  if (!value) {
    return value;
  }

  if (unmaskCpfOrCnpj(value).length <= 11) {
    return maskCpf(value);
  }

  return maskCnpj(value);
};

export const DATE_INPUT_MASK = [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
