import * as Yup from 'yup';
import moment from 'moment';


export default [
  Yup.object().shape({
    // []: Yup.string().required(`${lessor.document.requiredErrorMsg}`),
    // [lessor.address.zipcode.name]: Yup.string()
    //   .required(`${lessor.address.zipcode.requiredErrorMsg}`)
    //   .test(
    //     'len',
    //     `${lessor.address.zipcode.invalidErrorMsg}`,
    //     val => val && val.length === 5
    //   ),
  }),
];
