import React, { SyntheticEvent, useEffect, useState } from 'react';
import { StepItem } from './types';
import { StepNav } from './components/StepForm';
import { InitialInfoStep } from './steps/initial-info/InitialInfoStep';
import { InputOnChangeData } from 'semantic-ui-react';
import { PageWrapper, RightAlignedButton } from './style';
import { initialValues, BondInsuranceFormProps } from './BondInsuranceForm';
import { setAllStepTouched, allDataAreFilled } from '../../util';
import LegalCommercialResidentialForm from './steps/tenant-info/legal-commercial-residential/LegalCommercialResidentialForm';
import { PersonTypesEnum } from '../../enums/person-types.enum';
import NaturalCommercialResidentialForm from './steps/tenant-info/natural-commercial-residential/NaturalCommercialResidentialForm';
import { AppModal } from '../../components/Modal';
import { getBondInsuranceByID, save } from '../../services/bond-insurance';
import { dangerNotification } from '../../services/notification';
import { PurposesEnum } from '../../enums/purposes.enum';
import PropertyInfoStep from './steps/property-info/PropertyInfoStep';
import { useParams } from 'react-router-dom';
import { CustoDropDownItemProps } from '../../components/types';
import { maskCpfOrCnpj, maskPhoneNumber, unmaskCpfOrCnpj } from '../../services/masks';
// import OwnerInfo from './steps/owner-info';
import { validateCNPJ, validateCPF } from '../../services/validators';

interface ParamsProps {
  copy_id?: string;
}

const BondInsuranceContentForm = (props: BondInsuranceFormProps) => {
  const {
    values,
    setFieldValue,
    errors,
    validateForm,
    setTouched,
    bondInsuranceForm,
    updateBondInsurance,
    setValues,
    user,
    availableBrokersAndEstates
  } = props;

  const estates: CustoDropDownItemProps[] = availableBrokersAndEstates
    ? availableBrokersAndEstates?.estates
    : [];

  const { copy_id }: ParamsProps = useParams();
  const [isCopied, setIsCopied] = useState<boolean>();

  useEffect(() => {
    setIsCopied(false);
    const getBondInsuranceToCopy = async () => {
      if (copy_id) {
        setIsCopied(true);
        const response = await getBondInsuranceByID(copy_id);
        delete response?.property?.bond_insurance_id;
        delete response?.property?.user_id;
        delete response?.user_id;
        const {
          analysis_assurance,
          natural_tenant,
          tenant_type,
          purpose,
          estate,
          legal_tenant,
          property
        } = response;
        setFieldValue('initial_info.analysis_assurance', analysis_assurance);
        setFieldValue('tenant_info.partner_rent_composition', false);
        if (estate) {
          const selected = estates.find(c => `${c?.value?.id}` === `${estate.id}`);
          if (!selected) return;
          setFieldValue('initial_info.estate', selected.value);
        }
        setFieldValue('initial_info.purpose', purpose);
        setFieldValue('initial_info.tenant_type', tenant_type);
        if (tenant_type === PersonTypesEnum.legal) {
          setFieldValue('tenant_info', {
            ...legal_tenant,
            document: maskCpfOrCnpj(legal_tenant.document),
            contact_phone: maskPhoneNumber(legal_tenant.contact_phone)
          });
        } else {
          setFieldValue('tenant_info', {
            ...natural_tenant,
            main_tenant_cpf: maskCpfOrCnpj(natural_tenant.main_tenant_cpf),
            main_tenant_phone: maskPhoneNumber(natural_tenant.main_tenant_phone),
            main_tenant_birth_date: natural_tenant.main_tenant_birth_date
              ? new Date(natural_tenant.main_tenant_birth_date)
              : undefined,
            partners: natural_tenant?.partners?.map(partner => ({
              ...partner,
              document: maskCpfOrCnpj(partner.document),
              date_of_entry: new Date(partner.date_of_entry)
            })),
            employment_admission_date: new Date(natural_tenant?.employment_admission_date),
            main_tenant_rg_emission_date: natural_tenant?.main_tenant_rg_emission_date
          });
        }
        if (property) {
          setFieldValue('property_info', {
            ...property,
            rental_period_start_date: property.rental_period_start_date
              ? new Date(property.rental_period_start_date)
              : undefined,
            rental_period_end_date: property.rental_period_end_date
              ? new Date(property.rental_period_end_date)
              : undefined,
            total_amount: +property?.total_amount || 0,
            taxes_value: +property?.taxes_value || 0,
            water_bill: +property?.water_bill || 0,
            energy_bill: +property?.energy_bill || 0,
            condominium_fee: +property?.condominium_fee || 0,
            gas_bill: +property?.gas_bill || 0
          });
        }
        setFieldValue('tenant_info.has_restrictions', false);
        setFieldValue('tenant_info.include_sympathise_tenants', false);
      }
    };
    getBondInsuranceToCopy();
  }, [copy_id, estates, setFieldValue]);

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [isSubmited, setIsSubmited] = useState<boolean>(false);

  const handleClose = () => {
    props.updateBondInsurance({
      ...props.bondInsuranceForm,
      currentPage: 0,
      currentStep: steps[0].section,
      tenantType: PersonTypesEnum.natural,
      propertyType: PurposesEnum.residential
    });
    setValues(initialValues);
    setIsSubmited(false);
    setModalIsOpen(false);
  };

  const onStepChange = (step: number) => {
    updateBondInsurance({
      ...bondInsuranceForm,
      currentPage: bondInsuranceForm.currentPage + 1
    });
  };

  const handleInputChange = (_: any, data: InputOnChangeData): void => {
    if(data.field.name === 'property_info.number') {
      const number = data.value.replace(/\D/g, '');
      setFieldValue(data.field.name, number);
    } else {
      setFieldValue(data.field.name, data.value);
    }
  };

  const handleDateChange = (
    fieldName: string,
    date: Date,
    e: SyntheticEvent<HTMLElement, Event>
  ): void => {
    setFieldValue(fieldName, date);
  };

  useEffect(() => {
    handleClose();
  }, []);

  const steps: StepItem[] = [
    {
      label: 'Dados Iniciais',
      section: 'initial_info',
      component: (
        <InitialInfoStep
          {...props}
          isCopied={isCopied}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
        />
      )
    },
    {
      label: 'Dados do Imóvel',
      section: 'property_info',
      exceptions: [
        'complement',
        'property_type',
        'is_condominium_fee_required',
        'is_taxes_value_required',
        'is_energy_bill_required',
        'is_water_bill_required',
        'is_gas_bill_required',
        'condominium_fee',
        'taxes_value',
        'energy_bill',
        'water_bill',
        'gas_bill',
        'real_occupant_name',
        'real_occupant_doc',
        'contract_penalties_value',
        'interior_painting_value',
        'exterior_painting_value',
        'furniture_damage_value',
        'property_damage_value',
        'readjustment_index',
        'rent_insurance_contract_start_date',
        'rent_insurance_contract_end_date',
        'rent_indemnity_period',
        'rental_periodicity',
        'rental_period_start_date',
        'rental_period_end_date'
      ],
      component: (
        <PropertyInfoStep
          {...props}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
        />
      )
    },
    // {
    //   label: 'Dados do Proprietário',
    //   section: 'owner_info',
    //   exceptions: ['complement'],
    //   component: (
    //     <OwnerInfo
    //       {...props}
    //       handleInputChange={handleInputChange}
    //       handleDateChange={handleDateChange}
    //     />
    //   )
    // },
    {
      label: 'Dados do Locatário',
      section: 'tenant_info',
      exceptions: [
        'complement',
        'main_tenant_document_type',
        'main_tenant_document',
        'main_tenant_dispatcher',
        'main_tenant_date_of_dispatch',
        'main_tenant_profession',
        'main_tenant_rg',
        'main_tenant_rg_expeditor_organ',
        'main_tenant_rg_emission_date',
        'main_tenant_current_address_zipcode',
        'main_tenant_other_incomes',
        'resident_other_incomes',
        'main_tenant_monthly_fixed_income',
        'tenant_info.sympathise_tenants',
        'partner_cpf'
      ],
      component:
        bondInsuranceForm.tenantType === PersonTypesEnum.legal ? (
          <LegalCommercialResidentialForm
            {...props}
            handleInputChange={handleInputChange}
            handleDateChange={handleDateChange}
          />
        ) : (
          <NaturalCommercialResidentialForm
            {...props}
            handleInputChange={handleInputChange}
            handleDateChange={handleDateChange}
          />
        )
    }
  ];

  const isSubmitDisabled = () => {
    if (values?.tenant_info?.has_restrictions) {
      return true;
    }
    if ((errors as any)[bondInsuranceForm.currentStep]) {
      return true;
    }
    if (
      !allDataAreFilled(
        (values as any)[bondInsuranceForm.currentStep],
        steps[props.bondInsuranceForm.currentPage].exceptions || undefined
      )
    ) {
      return true;
    }
    if (isSubmited) {
      return true;
    }

    return false;
  };

  const isNextButtonDisabled = () => {
    // if (
    //   bondInsuranceForm.currentStep === 'owner_info' &&
    //   values?.initial_info?.tenant_type === PersonTypesEnum.natural &&
    //   !!values?.tenant_info?.main_tenant_cpf &&
    //   !!values?.owner_info?.document &&
    //   values?.tenant_info?.main_tenant_cpf === values?.owner_info?.document
    // ) {
    //   return true;
    // }

    // if (
    //   bondInsuranceForm.currentStep === 'owner_info' &&
    //   values?.initial_info?.tenant_type === PersonTypesEnum.legal &&
    //   !!values?.tenant_info?.document &&
    //   !!values?.owner_info?.document &&
    //   values?.tenant_info?.document === values?.owner_info?.document
    // ) {
    //   return true;
    // }

    if(bondInsuranceForm.currentStep === 'initial_info') {
      return values?.initial_info?.tenant_type === PersonTypesEnum.natural ?
      (!values?.tenant_info?.main_tenant_cpf || (unmaskCpfOrCnpj(values?.tenant_info?.main_tenant_cpf)?.length !== 11 || !validateCPF(values?.tenant_info?.main_tenant_cpf)))
      : (!values?.tenant_info?.document || (unmaskCpfOrCnpj(values?.tenant_info?.document)?.length !== 14 || !validateCNPJ(values?.tenant_info?.document)))
    }

    return (
      !allDataAreFilled(
        (values as any)[bondInsuranceForm.currentStep],
        steps[props.bondInsuranceForm.currentPage].exceptions || undefined
      ) ||
      (errors as any)[bondInsuranceForm.currentStep] ||
      values.initial_info?.estate?.insurers?.length === 0 ||
      values?.tenant_info?.has_restrictions ||
      (values?.initial_info?.tenant_type === PersonTypesEnum.natural &&
        unmaskCpfOrCnpj(values?.tenant_info?.main_tenant_cpf || '')?.length === 11 &&
        !validateCPF(values?.tenant_info?.main_tenant_cpf)) ||
      (values?.initial_info?.tenant_type === PersonTypesEnum.legal &&
        unmaskCpfOrCnpj(values?.tenant_info?.document || '')?.length === 14 &&
        !validateCNPJ(values?.tenant_info?.document))
    );
  };

  const NextButton = () => {
    return (
      <RightAlignedButton
        id="bondInsuranceBudgetForm.nextButton"
        type="button"
        color="blue"
        disabled={isNextButtonDisabled()}
        onClick={() => {
          props.updateBondInsurance({
            ...props.bondInsuranceForm,
            currentPage: props.bondInsuranceForm.currentPage + 1,
            currentStep: steps[props.bondInsuranceForm.currentPage + 1].section
          });
          setTouched(
            setAllStepTouched(
              (values as any)[props.bondInsuranceForm.currentStep],
              props.bondInsuranceForm.currentStep
            )
          );
          validateForm();
        }}
      >
        Próximo
      </RightAlignedButton>
    );
  };

  const PreviousButton = () => {
    return (
      <RightAlignedButton
        type="button"
        id="bondInsuranceBudgetForm.backButton"
        color="blue"
        onClick={() => {
          props.updateBondInsurance({
            ...props.bondInsuranceForm,
            currentPage: props.bondInsuranceForm.currentPage - 1,
            currentStep: steps[props.bondInsuranceForm.currentPage - 1].section
          });
          validateForm();
        }}
      >
        Anterior
      </RightAlignedButton>
    );
  };

  const SubmitButton = () => {
    return (
      <RightAlignedButton
        id="bondInsuranceBudgetForm.submitButton"
        type="submit"
        color="blue"
        disabled={isSubmitDisabled()}
        onClick={async () => {
          if (!isSubmited) {
            try {
              setIsSubmited(true);
              validateForm();
              props.updateBondInsurance({ ...props.bondInsuranceForm, isLoading: true });
              await save(values, user.id, availableBrokersAndEstates.brokers[0].value.id);
              props.updateBondInsurance({ ...props.bondInsuranceForm, isLoading: false });
              setModalIsOpen(true);
            } catch (e) {
              setIsSubmited(false);
              props.updateBondInsurance({ ...props.bondInsuranceForm, isLoading: false });
              dangerNotification('Oops...', (e as Error)?.message);
            }
          }
        }}
      >
        Finalizar
      </RightAlignedButton>
    );
  };

  return (
    <PageWrapper>
      <StepNav
        isLoading={bondInsuranceForm.isLoading}
        steps={steps}
        currentStep={bondInsuranceForm.currentPage}
        onStepChange={onStepChange}
      />
      {bondInsuranceForm.currentPage === steps.length - 1 && (
        <AppModal
          message={'Seus dados foram enviados para análise'}
          success={true}
          modalIsOpen={modalIsOpen}
          handleClose={handleClose}
          trigger={<SubmitButton />}
        />
      )}
      {bondInsuranceForm.currentPage < steps.length - 1 && <NextButton />}
      {bondInsuranceForm.currentPage >= 1 && <PreviousButton />}
    </PageWrapper>
  );
};

export default BondInsuranceContentForm;
