/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { SelectableModalProps } from './types';
import styled from 'styled-components';
import ReactToPrint from 'react-to-print';
import ModalAttachFiles from './ModalAttachFiles';
import AuthorizationContainer from './AuthorizationContainer';
import { ActionEnum } from '../enums/authz-action.enum';
import { FeatureEnum } from '../enums/authz-feature.enum';

export const ModalContent = styled(Modal.Content)`
  line-height: 1.8rem;
  div:last-child:not(:first-child) {
    font-weight: bold;
  }
`;

const PrintedContainer = styled.div`
  @media print {
    @page {
      margin-top: 1cm;
      margin-bottom: 0.5cm;
      margin-left: 0.5cm;
      margin-right: 0.5cm;
    }

    .payment-container {
      page-break-after: always;
    }
  }
`;

type PrintableModalProps = {
  bondInsuranceId?: string;
};

const PrintableModal = (
  props: SelectableModalProps & PrintableModalProps
): JSX.Element => {
  const { content, header, onClose, disabled = false } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const open = (): void => {
    setIsOpen(true);
  };
  const close = (): void => {
    setIsOpen(false);
  };

  const componentRef = useRef(null);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const confirmButton = (
    <ReactToPrint
      trigger={() => (
        <Button
          color={'blue'}
          icon={'print'}
          type="button"
          floated="right"
          style={{ marginBottom: '10px' }}
        >
          <Icon name="print" /> Imprimir
        </Button>
      )}
      content={() => componentRef.current!}
    />
  );

  return (
    <Modal open={isOpen} onOpen={open} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content scrolling>
        <PrintedContainer ref={componentRef}>{content}</PrintedContainer>
      </Modal.Content>
      <Modal.Actions>
        {[
          props.bondInsuranceId ? (
            <AuthorizationContainer action={ActionEnum.download} feature={FeatureEnum.files}>
              <ModalAttachFiles
                bondInsuranceId={props.bondInsuranceId}
                brokerOnly={true}
                disabled={disabled}
              />
            </AuthorizationContainer>
          ) : (
            <div />
          ),
          confirmButton
        ]}
      </Modal.Actions>
    </Modal>
  );
};
export default connect()(PrintableModal);
