import { Field, FormikProps } from "formik";
import React, { useEffect } from "react";
import { Grid, Input } from "semantic-ui-react";
import CustomDatePicker from "../../../../../../../../components/CustomDatePicker";
import AppMaskedInput from "../../../../../../../../components/MaskedInput";

import SectionLabel from "../../../../../../../../components/SectionLabel";
import { NaturalTenantForGetBondInsurancesResponseDTO } from "../../../../../../../../dtos/bond-insurance-list/get-bond-insurances-response.dto";
import { DateToStringDate } from "../../../../../../../../services/date";
import {
  maskCpfOrCnpj,
  maskDate,
  maskPhoneNumber,
} from "../../../../../../../../services/masks";
import { FieldLabel } from "../../../../../../../../styles";
import { PayloadPortoUpdateRequestInterface } from "../../ModalHireProposal/interfaces/SubmitEssencialHirePorto.interface";
import { formField } from "../../ModalHireProposal/Model/formFields";

type TenantFormRequestInterface = {
  tenant?: NaturalTenantForGetBondInsurancesResponseDTO; //|| LegalTenant?? ;
};

const TenantForm = ({
  values,
  setFieldValue,
  tenant,
}: FormikProps<PayloadPortoUpdateRequestInterface> &
  TenantFormRequestInterface) => {
  useEffect(() => {
    if (tenant) {
      setFieldValue(
        formField.tenants.document.name,
        maskCpfOrCnpj(tenant.main_tenant_cpf)
      );
      setFieldValue(formField.tenants.name.name, tenant.main_tenant_name);
      setFieldValue(formField.tenants.email.name, tenant.main_tenant_email);
      setFieldValue(formField.tenants.cellphone.name, tenant.main_tenant_phone);
      setFieldValue(
        formField.tenants.birthdate.name,
        new Date(tenant.main_tenant_birth_date)
      );
    }
  }, [tenant]);

  return (
    <div style={{ padding: "0 0 0 10px" }}>
      <Grid columns="equal">
        <SectionLabel text="Informações do inquilino" />
        <Grid.Row>
          <Grid.Column>
            <FieldLabel>{formField.tenants.document.label}</FieldLabel>
            <Field
              name={formField.tenants.document.name}
              value={values.tenants.document}
              customInput={<Input />}
              component={Input}
              disabled
              fluid
            />
          </Grid.Column>
          <Grid.Column>
            <FieldLabel>{formField.tenants.name.label}</FieldLabel>
            <Field
              disabled
              name={formField.tenants.name.name}
              value={values.tenants.name}
              customInput={<Input />}
              component={Input}
              onChange={(e: any) =>
                setFieldValue(formField.tenants.name.name, e.target.value)
              }
              fluid
              error={values.tenants.name.length > 60}
            />
            <span>
              {60 - values.tenants.name?.length ?? 0} caracteres restantes
            </span>
          </Grid.Column>
          <Grid.Column>
            <FieldLabel>{formField.tenants.birthdate.label}</FieldLabel>
            <Field
              name={formField.tenants.birthdate.name}
              selected={values.tenants.birthdate}
              component={CustomDatePicker}
              fluid
              onChange={(date: Date, e: any) => {
                setFieldValue(formField.tenants.birthdate.name, date);
              }}
              disabled
              customInput={
                <AppMaskedInput
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    "/",
                    /[0-9]/,
                    /[0-9]/,
                    "/",
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                  ]}
                />
              }
              dateFormat={"dd/MM/yyyy"}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <FieldLabel>{formField.tenants.email.label}</FieldLabel>
            <Field
              name={formField.tenants.email.name}
              value={values.tenants.email}
              onChange={(e: any) =>
                setFieldValue(formField.tenants.email.name, e.target.value)
              }
              customInput={<Input />}
              component={Input}
              fluid
            />
          </Grid.Column>
          <Grid.Column>
            <FieldLabel>{formField.tenants.cellphone.label}</FieldLabel>
            <Field
              name={formField.tenants.cellphone.name}
              value={maskPhoneNumber(values.tenants.cellphone)}
              onChange={(e: any) =>
                setFieldValue(formField.tenants.cellphone.name, e.target.value)
              }
              customInput={<Input />}
              component={Input}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
export default TenantForm;
