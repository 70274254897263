import React from 'react';
import { Dropdown, DropdownItemProps, Grid, Input, TextArea } from 'semantic-ui-react';
import { CreatePolicyProps } from '../../types';
import { formatAmount } from '../../../../../../services/masks';
import CustomDatePicker from '../../../../../../components/CustomDatePicker';
import AppMaskedInput from '../../../../../../components/MaskedInput';
import { TextAreaWrapper } from '../../styles';

const RenderProposalForm = ({
  values,
  setFieldValue,
  insurers
}: {
  values: CreatePolicyProps;
  setFieldValue: (field: string, value: any) => void;
  insurers: DropdownItemProps[];
}) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <label>SEGURADORA / GARANTIDORA:</label>
          <Dropdown
            search
            style={{ width: '100%' }}
            clearable
            selection
            options={insurers}
            placeholder={'Selecionar...'}
            value={values?.analysis?.insurerId}
            onChange={(e, { value }) => {
              setFieldValue('analysis.insurerId', value);
            }}
          />
        </Grid.Column>

        <Grid.Column width={8}>
          <label>DIA DE VENCIMENTO DO ALUGUEL</label>

          <Input
            fluid
            type="number"
            min={1}
            max={31}
            value={values?.bondInsurance?.property?.rent_due_date}
            onChange={(e, { value }) => {
              if (+value > 31) {
                return;
              }
              if (+value < 1) {
                return;
              }

              setFieldValue('bondInsurance.property.rent_due_date', value);
            }}
            placeholder={'Digite aqui'}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={8}>
          <label>VALOR DA ANÁLISE</label>

          <Input
            fluid
            value={values?.analysis?.value ? formatAmount(values?.analysis?.value) : ''}
            onChange={(e, { value }) => {
              setFieldValue('analysis.value', value);
            }}
            placeholder={'R$ 0,00'}
          />
        </Grid.Column>

        <Grid.Column width={8}>
          {/* <label>PREMIO TOTAL COTADO</label>
          <Input
            fluid
            value={
              values?.hiredData?.totalPremiumAmount
                ? formatAmount(values?.hiredData?.totalPremiumAmount)
                : ''
            }
            placeholder={'R$ 0,00'}
          /> */}
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={8}>
          <label style={{ paddingBottom: '5px' }}>INICIO DA VIGÊNCIA DA LOCAÇÃO</label>
          <CustomDatePicker
            fluid
            name={'start'}
            placeholder={'--/--/--'}
            type={'date'}
            customInput={
              <AppMaskedInput
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/
                ]}
              />
            }
            dateFormat={'dd/MM/yyyy'}
            style={{
              marginTop: 0
            }}
            selected={values?.bondInsurance?.rentalPeriodStartDate}
            onChange={(date: Date, _e: any) => {
              setFieldValue('bondInsurance.rentalPeriodStartDate', date);
            }}
          />
        </Grid.Column>

        <Grid.Column width={8}>
          <label style={{ paddingBottom: '5px' }}>FIM DA VIGÊNCIA DA LOCAÇÃO</label>
          <CustomDatePicker
            fluid
            name={'start'}
            placeholder={'--/--/--'}
            type={'date'}
            customInput={
              <AppMaskedInput
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/
                ]}
              />
            }
            dateFormat={'dd/MM/yyyy'}
            style={{
              marginTop: 0
            }}
            selected={values?.bondInsurance?.rentalPeriodEndDate}
            onChange={(date: Date, _e: any) => {
              setFieldValue('bondInsurance.rentalPeriodEndDate', date);
            }}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={8}>
          <label style={{ paddingBottom: '5px' }}>INICIO DA GARANTIA</label>
          <CustomDatePicker
            fluid
            name={'start'}
            placeholder={'--/--/--'}
            type={'date'}
            customInput={
              <AppMaskedInput
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/
                ]}
              />
            }
            dateFormat={'dd/MM/yyyy'}
            style={{
              marginTop: 0
            }}
            selected={values?.bondInsurance?. rentInsuranceContractStartDate}
            onChange={(date: Date, _e: any) => {
              setFieldValue('bondInsurance.rentInsuranceContractStartDate', date);
            }}
          />
        </Grid.Column>

        <Grid.Column width={8}>
          <label style={{ paddingBottom: '5px' }}>FIM DA GARANTIA</label>
          <CustomDatePicker
            fluid
            name={'start'}
            placeholder={'--/--/--'}
            type={'date'}
            customInput={
              <AppMaskedInput
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/
                ]}
              />
            }
            dateFormat={'dd/MM/yyyy'}
            style={{
              marginTop: 0
            }}
            selected={values?.bondInsurance?. rentInsuranceContractEndDate}
            onChange={(date: Date, _e: any) => {
              setFieldValue('bondInsurance.rentInsuranceContractEndDate', date);
            }}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          <label>OBSERVAÇÕES</label>
          <TextAreaWrapper>
            <TextArea
              value={values?.hiredData?.observations}
              onChange={(e, { value }) => {
                setFieldValue('hiredData.observations', value);
              }}
            />
          </TextAreaWrapper>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};


export default RenderProposalForm;
