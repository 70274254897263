import React, { useEffect, useState } from "react";
import MessageList from "../GeneralMessagesList";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { Timeline, TimelineEvent } from 'react-event-timeline';

import {
  dangerNotification,
  successNotification,
  warningNotification
} from '../../services/notification';
import { get } from '../../services/storage';
import Button from '../Button';

import { ActionEnum } from "../../enums/authz-action.enum";
import { FeatureEnum } from "../../enums/authz-feature.enum";
import { hasPermission } from "../AuthorizationContainer";
import { DivFollowup, MessageContainer } from './styles';
import moment from 'moment-timezone';
import { Icon } from 'semantic-ui-react';
import { sendMessage } from "../../services/generalfollowup";

const MessageSender = (props: any) => {
  const { id, relatedId, context, involved, viewedBy, isInternal } = props;
  const user_id = String(get('uid'));
  const user_name = String(get('name'));
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [tempMessages, setTempMessages] = useState<any[]>([]);

  const [userCanSendMessage, setUserCanSendMessage] = useState<boolean>(false);

  useEffect(() => {
    const has = hasPermission(ActionEnum.create, FeatureEnum.followups);
    setUserCanSendMessage(has);
  }, []);

  const handleSubmit = async () => {
    if (!message.trim()) {
      warningNotification('Oops...', 'Por favor, digite uma mensagem antes de enviar.');
    } else {
      try {
        setLoading(true);
        const sentMessage = await sendMessage(
          relatedId,
          user_id,
          message,
          user_name,
          context,
          involved,
          viewedBy,
          id,
          isInternal
        );
        setTempMessages([sentMessage, ...tempMessages]);
        successNotification('Sucesso!', 'Mensagem enviada!');
      } catch (error) {
        console.log('ERRO AO ENVIAR MENSAGEM: (GENERAL SENDER)', error);
        dangerNotification(
          'Oops...',
          'Não foi possível enviar a mensagem, tente novamente mais tarde.'
        );
      } finally {
        setMessage('');
        setLoading(false);
      }
    }
  };

  const modules = {
    toolbar: [
      [{ align: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ size: ['normal', 'large', 'huge'] }],
      [{ color: [] }, { background: [] }]
    ]
  };

  return (
    <DivFollowup>
      <MessageContainer>
        <div className="quilljs">
          <ReactQuill
            theme="snow"
            modules={modules}
            value={message}
            onChange={value => setMessage(value)}
            readOnly={loading || props.isBlocked || !userCanSendMessage}
          />
        </div>

        <div>
          <Button
            primary
            content="Enviar"
            labelPosition="right"
            icon="send"
            onClick={handleSubmit}
            loading={loading}
            disabled={loading || !userCanSendMessage || props.isBlocked}
          />
        </div>
      </MessageContainer>

      <div style={{ overflowY: 'scroll' }}>
        {tempMessages.length > 0 ? (
          <Timeline>
            {tempMessages.map((message: any) => (
              <TimelineEvent
                key={message.id}
                title={message.senderId.name}
                createdAt={moment(message.createdAt).format('DD/MM/YY - HH:mm')}
                icon={<Icon name="envelope open outline" size="large" />}
                iconColor="#5C6BC0"
                style={{
                  backgroundColor: '#fff',
                  padding: 10,
                  boxShadow: '0 0 3px 1px #5C6BC0',
                  fontSize: '12px'
                }}
                iconStyle={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: 2
                }}
                contentStyle={{
                  backgroundColor: '#FEFEFE',
                  color: '#5C6BC0',
                  fontSize: '16px'
                }}
                titleStyle={{ fontWeight: 'bold' }}
              >
                <ReactQuill theme="bubble" value={message.message} readOnly={true} />
              </TimelineEvent>
            ))}
          </Timeline>
        ) : (
          <span style={{ paddingLeft: '40px' }}>Ainda sem mensagens neste Follow Up</span>
        )}
      </div>
    </DivFollowup>
  );
};

export default MessageSender;
