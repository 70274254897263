import {CurrentPageReducer, CurrentPageState} from "../types/temp-types";

type CurrentPageRematchModel = {
  state: CurrentPageState;
  reducers: CurrentPageReducer;
}

const currentPage: CurrentPageRematchModel = {
  state: {},
  reducers: {
    updateCurrentPage: (state: CurrentPageState, payload: CurrentPageState): CurrentPageState => payload
  }
};

export default currentPage;
