import React, { useState } from 'react';
import {
  Button,
  Dimmer,
  Dropdown,
  Grid,
  Input,
  Loader,
  TextArea
} from 'semantic-ui-react';
import {
  Container,
  FormContainer,
  Header,
  Line,
  FormTitle,
  ButtonContainer,
  ErrorMessage
} from './styles';
import { EstatePreregisterState } from '../../types/EstatePreregisterState';
import { useHistory, useParams } from 'react-router-dom';
import { dangerNotification, successNotification } from '../../services/notification';
import { useQuery } from '@tanstack/react-query';
import {
  getEstatePreregisterById,
  createEstatePreregister,
  updateEstatePreregisterById
} from '../../services/estate';
import { Formik } from 'formik';
import {
  maskCpfOrCnpj,
  maskPhoneNumber,
  maskZipCode,
  unmaskCpfOrCnpj,
  unmaskPhoneNumber
} from '../../services/masks';
import { AddressService } from '../../services/address';
import { iRootState } from '../../store';
import { connect } from 'react-redux';
import { FormErrorsMessages, statusOptions } from '../estates-preregister-list/types';
import { AxiosError } from 'axios';
import { isValidEmail } from '../../utils/emailValidator';
import { TextAreaWrapper } from '../bond-insurance-policy-list/components/CreatePolicyModal/styles';
import { FieldLabel } from '../../styles';

const mapState = (state: iRootState) => ({
  user: state.user
});

export type EstatePreregisterFormPageProps = ReturnType<typeof mapState>;

const EstatePreregisterForm: React.FC<EstatePreregisterFormPageProps> = ({ user }) => {
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const [formErrors, setFormErrors] = useState<FormErrorsMessages>({
    name: '',
    document: '',
    status: '',
    responsible_name: '',
    responsible_email: '',
    responsible_phone: '',
    phone: '',
    email: '',
    zipcode: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: '',
    user_id: ''
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValidForm, setisValidForm] = useState<boolean>(false);
  const [isCepLoading, setIsCepLoading] = useState<boolean>(false);

  const getData = async () => {
    const response = await getEstatePreregisterById(id);
    return {
      ...response,
      document: maskCpfOrCnpj(response.document),
      responsible_phone: maskPhoneNumber(response.responsible_phone),
      phone: maskPhoneNumber(response.phone)
    };
  };

  const { data: preRegister  } = useQuery(
    ['getEstatePreregisterById', id],
    getData,
    {
      enabled: id !== 'new',
      keepPreviousData: true
    }
  );

  const validateForm = (values: EstatePreregisterState) => {
    setisValidForm(true);
    const errors: FormErrorsMessages = {
      name: '',
      document: '',
      status: '',
      responsible_name: '',
      responsible_email: '',
      responsible_phone: '',
      phone: '',
      email: '',
      zipcode: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      user_id: ''
    };

    errors.email = !values.email
      ? 'E-mail obrigatório'
      : !isValidEmail(values.email)
      ? 'E-mail inválido'
      : '';
    errors.document = !values.document ? 'Campo obrigatório' : '';
    errors.name = !values.name ? 'Campo obrigatório' : '';
    errors.phone = !values.phone ? 'Campo obrigatório' : '';
    errors.responsible_name = !values.responsible_name ? 'Campo obrigatório' : '';
    errors.responsible_email = !values.responsible_email
      ? 'E-mail obrigatório'
      : !isValidEmail(values.responsible_email)
      ? 'E-mail inválido'
      : '';
    errors.responsible_phone = !values.responsible_phone ? 'Campo obrigatório' : '';
    errors.zipcode = !values.zipcode ? 'Campo obrigatório' : '';
    errors.street = !values.street ? 'Campo obrigatório' : '';
    errors.number = !values.number ? 'Campo obrigatório' : '';
    errors.district = !values.district ? 'Campo obrigatório' : '';
    errors.city = !values.city ? 'Campo obrigatório' : '';
    errors.state = !values.state ? 'Campo obrigatório' : '';

    return { errors, isValid: Object.values(errors).every(error => error === '') };
  };

  async function handleSubmit(values: EstatePreregisterState) {
    setIsLoading(true);

    const result = validateForm(values);
    setFormErrors(result?.errors);

    if (!result?.isValid) return;

    try {
      const payload = {
        name: values?.name || '',
        document: values?.document ? unmaskCpfOrCnpj(values?.document) : '',
        status: values?.status || '',
        responsible_name: values?.responsible_name || '',
        responsible_email: values?.responsible_email || '',
        responsible_phone: values?.responsible_phone
          ? unmaskPhoneNumber(values?.responsible_phone)
          : '',
        phone: values?.phone ? unmaskPhoneNumber(values?.phone) : '',
        email: values?.email || '',
        zipcode: values?.zipcode || '',
        street: values?.street || '',
        number: values?.number || '',
        complement: values?.complement || '',
        district: values?.district || '',
        city: values?.city || '',
        state: values?.state || '',
        user_id: user?.id,
        observations: values?.observations || ''
      };

      if (id === 'new') {
        await createEstatePreregister(payload);
      } else {
        await updateEstatePreregisterById(id, payload);
      }

      successNotification(
        'Sucesso',
        `Pré-cadastro ${id ? 'atualizado' : 'criado'} com sucesso!`
      );

      history.push(`/admin/estates-preregisters`);
    } catch (error) {
      if ((error as any).message) {
        const errorMessage = (error as any).message;
        if (errorMessage.includes('Duplicate entry')) {
          const duplicated = errorMessage.split(' ')[2];
          dangerNotification(
            'Valor Duplicado',
            `O valor ${duplicated} já foi cadastrado anteriormente.`
          );
        } else {
          dangerNotification('Ops...', errorMessage);
        }
      } else {
        dangerNotification(
          'Oops...',
          (error as AxiosError).response?.data?.message || 'Erro ao criar pré-cadastro'
        );
      }
    } finally {
      setIsLoading(false);
    }
  }

  const renderActionButtons = (values: EstatePreregisterState): React.ReactNode => {
    return (
      <ButtonContainer>
        <Button
          type="button"
          color="red"
          onClick={() => history.push(`/admin/estates-preregisters`)}
        >
          Voltar
        </Button>
        <Button
          type="submit"
          color="green"
          form="policy-form"
          onClick={e => {
            e.preventDefault();
            handleSubmit(values);
          }}
        >
          Salvar
        </Button>
      </ButtonContainer>
    );
  };

  const changeZipCode = (cep: string, setFieldValue: any) => {
    setFieldValue('zipcode', maskZipCode(cep));
    if (maskZipCode(cep).length === 9) {
      setIsCepLoading(true);
      AddressService.getAddressByZipCode(cep, user.id)
        .then((address: any) => {
          setFieldValue('city', address.city);
          setFieldValue('district', address.district);
          setFieldValue('state', address.state);
          setFieldValue('street', address.street);

          setIsCepLoading(false);
        })
        .catch(e => {
          console.log('address error:', e);
        });
    }
  };

  const renderGeneralForm = (values: EstatePreregisterState, setFieldValue: any) => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <label>CPF/CNPJ*</label>
            <Input
              placeholder="CPF/CNPJ"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('document', maskCpfOrCnpj(e.target.value))
              }
              value={values?.document}
              error={isValidForm && values?.document === ''}
            />
            <ErrorMessage>{formErrors.document}</ErrorMessage>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={'equal'}>
          <Grid.Column>
            <label>NOME/RAZÃO SOCIAL*</label>
            <Input
              placeholder="Razão Social"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('name', e.target.value)
              }
              value={values?.name}
              error={isValidForm && values?.name === ''}
            />
            <ErrorMessage>{formErrors.name}</ErrorMessage>
          </Grid.Column>
          <Grid.Column>
            <label>E-MAIL PRINCIPAL*</label>
            <Input
              placeholder="E-mail Principal"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('email', e.target.value)
              }
              value={values?.email}
              error={
                isValidForm && !(values?.email !== '' && isValidEmail(values?.email))
              }
            />
            <ErrorMessage>{formErrors.email}</ErrorMessage>
          </Grid.Column>
          <Grid.Column>
            <label>TELEFONE PRINCIPAL*</label>
            <Input
              placeholder="Telefone Principal"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('phone', maskPhoneNumber(e.target.value))
              }
              value={values?.phone}
              error={isValidForm && values?.phone === ''}
            />
            <ErrorMessage>{formErrors.phone}</ErrorMessage>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={'equal'}>
          <Grid.Column>
            <label>NOME DO RESPONSÁVEL*</label>
            <Input
              placeholder="Nome do Responsável"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('responsible_name', e.target.value)
              }
              value={values?.responsible_name}
              error={isValidForm && values?.responsible_name === ''}
            />
            <ErrorMessage>{formErrors.responsible_name}</ErrorMessage>
          </Grid.Column>
          <Grid.Column>
            <label>E-MAIL DO RESPONSÁVEL*</label>
            <Input
              placeholder="E-mail do Responsável"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('responsible_email', e.target.value)
              }
              value={values?.responsible_email}
              error={
                isValidForm &&
                !(
                  values?.responsible_email !== '' &&
                  isValidEmail(values?.responsible_email)
                )
              }
            />
            <ErrorMessage>{formErrors.responsible_email}</ErrorMessage>
          </Grid.Column>
          <Grid.Column>
            <label>CELULAR DO RESPONSÁVEL*</label>
            <Input
              placeholder="Celular do Responsável"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('responsible_phone', maskPhoneNumber(e.target.value))
              }
              value={values?.responsible_phone}
              error={isValidForm && values?.responsible_phone === ''}
            />
            <ErrorMessage>{formErrors.responsible_phone}</ErrorMessage>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const renderPropertyForm = (values: EstatePreregisterState, setFieldValue: any) => {
    return (
      <Grid>
        <Grid.Row columns={'equal'}>
          <Grid.Column>
            <label>CEP*</label>
            <Input
              placeholder="CEP"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                changeZipCode(e.target.value, setFieldValue)
              }
              value={values?.zipcode}
              error={isValidForm && values?.zipcode === ''}
            />
            {values?.zipcode === '' && <ErrorMessage>{formErrors.zipcode}</ErrorMessage>}
          </Grid.Column>
          <Grid.Column>
            RUA
            <label>*</label>
            <Input
              placeholder="Rua"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('street', e.target.value)
              }
              value={values?.street}
              error={isValidForm && values?.street === ''}
            />
            {values?.street === '' && <ErrorMessage>{formErrors.street}</ErrorMessage>}
          </Grid.Column>
          <Grid.Column>
            <label>BAIRRO*</label>
            <Input
              placeholder="Bairro"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('district', e.target.value)
              }
              value={values?.district}
              disabled={isCepLoading}
              error={isValidForm && values?.district === ''}
            />
            {values?.district === '' && (
              <ErrorMessage>{formErrors.district}</ErrorMessage>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={'equal'}>
          <Grid.Column>
            <label>CIDADE*</label>
            <Input
              placeholder="Cidade"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('city', e.target.value)
              }
              value={values?.city}
              disabled={isCepLoading}
              error={isValidForm && values?.city === ''}
            />
            {values?.city === '' && <ErrorMessage>{formErrors.city}</ErrorMessage>}
          </Grid.Column>
          <Grid.Column>
            <label>UF*</label>
            <Input
              placeholder="UF"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('state', e.target.value)
              }
              value={values?.state}
              disabled={isCepLoading}
              error={isValidForm && values?.state === ''}
            />
            {values?.state === '' && <ErrorMessage>{formErrors.state}</ErrorMessage>}
          </Grid.Column>
          <Grid.Column>
            <label>NÚMERO*</label>
            <Input
              placeholder="Número"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('number', e.target.value)
              }
              value={values?.number}
              error={isValidForm && values?.number === ''}
            />
            {values?.number === '' && <ErrorMessage>{formErrors.number}</ErrorMessage>}
          </Grid.Column>
          <Grid.Column>
            <label>COMPLEMENTO</label>
            <Input
              placeholder="Complemento"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('complement', e.target.value)
              }
              value={values?.complement}
              disabled={isCepLoading}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  return (
    <Dimmer.Dimmable dimmed={isCepLoading}>
      <Dimmer active={isLoading} inverted>
        <Loader />
      </Dimmer>
      <Container>
        <Formik
          enableReinitialize
          initialValues={{
            name: '',
            document: '',
            status: 'PENDING',
            responsible_name: '',
            responsible_email: '',
            responsible_phone: '',
            phone: '',
            email: '',
            zipcode: '',
            street: '',
            number: '',
            complement: '',
            district: '',
            city: '',
            state: '',
            user_id: '',
            observations: ''
          }}
          validationSchema={{}}
          onSubmit={handleSubmit}
        >
          {innerProps => {
            const { setFieldValue, values } = innerProps;
            return (
              <>
                <Header>
                  <h3>{preRegister?.id ? preRegister?.name : 'Nova imobiliária'}</h3>

                  <div className="status-container">
                    <label>Status*</label>
                    <Dropdown
                      value={values?.status}
                      name={`status`}
                      options={statusOptions}
                      placeholder={'Selecionar...'}
                      search
                      selection
                      onChange={(_, { value }) => {
                        setFieldValue('status', value);
                      }}
                      clearable
                      disabled
                    />
                  </div>
                </Header>
                <FormContainer>
                  <FormTitle>DADOS CADASTRAIS</FormTitle>
                  <Line />
                  {renderGeneralForm(values, setFieldValue)}
                </FormContainer>
                <FormContainer>
                  <FormTitle>ENDEREÇO</FormTitle>
                  <Line />
                  {renderPropertyForm(values, setFieldValue)}

                      <br/>
                <Grid.Row>
                    <TextAreaWrapper>
                      <FieldLabel>Observações da parceria</FieldLabel>
                      <TextArea
                        value={values.observations}
                        onChange={(e, data) => setFieldValue('observations', data.value)}
                      />
                    </TextAreaWrapper>
                </Grid.Row>
                </FormContainer>
                {renderActionButtons(values)}
              </>
            );
          }}
        </Formik>
      </Container>
    </Dimmer.Dimmable>
  );
};

export default connect(mapState)(EstatePreregisterForm);
