import React, { useEffect, useState, KeyboardEvent } from "react";
import {
  Table,
  Icon,
  Dimmer,
  Segment,
  Loader,
  Dropdown,
  Menu,
  Button,
  Form,
  Grid,
  Modal,
  Input,
  Popup,
} from "semantic-ui-react";
import { connect } from "react-redux";
import styled from "styled-components";
import { iRootState, iRootDispatch } from "../../store";
import { EstateState } from "../../types/EstateState";
import { UserState } from "../../types/UserState";
import CustomDatePicker from "../../components/CustomDatePicker";
import {
  ContextEnum,
  EntityEnum,
  LabelEnum,
  OriginEnum,
  ProviderEnum,
  StatusEnum,
  TypeEnum,
} from "./enums";
import { DataSyncFilter, EventsFilter, EventsResponseType } from "./types";
import moment from "moment";
import { getEvents } from "../../services/events";
import { isEmptyObject } from "../../util";
import { dangerNotification } from "../../services/notification";
import Lottie from "react-lottie";
import Coding from "../../resources/lotties/coding3.json";
import { maskPhoneNumber } from "../../services/masks";
import { FieldLabel } from "../../styles";
import { getDataSyncControlEntries } from "../../services/external-sync-control";

const Header = styled(Table.Header)`
  background-color: #e1e8ed;
`;

const TableWrapper = styled.div`
  font-size: 0.85em;
  font-weight: bold;

  .ui.table {
    border: none;
    text-align: center;
    color: #364445;
  }
`;

const NotFoundWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #364445;
  font-size: 1.2em;
`;

const mapState = (state: iRootState) => ({
  estatesList: state.estatesList,
  user: state.user,
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateEstatesList: (payload: any) =>
    dispatch.estatesList.updateEstatesList(payload),
});

type FilterProps = {
  name?: string;
  state?: string;
  user_id?: string;
  additional?: boolean;
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Coding,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

type Props = {} & ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const EventsTable = (props: Props) => {
  const [allEstates, setAllEstates] = useState<EstateState[]>();

  const user: UserState = props.user;
  const { isLoading, estates } = props.estatesList;
  const [dataCount, setDataCount] = useState<number>(0);
  const [events, setEvents] = useState<EventsResponseType[]>([]);
  const [dataSyncEntries, setDataSyncEntries] = useState<any>([]);
  const [dataSyncCount, setDataSyncCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [oldPage, setOldPage] = useState<number>(1);
  const [isLoadingEvents, setIsLoadingEvents] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [type, setType] = useState<string>("");
  const [origin, setOrigin] = useState<string>("");
  const [context, setContext] = useState<string>("");
  const [label, setLabel] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [provider, setProvider] = useState<string>("");
  const [entity, setEntity] = useState<string>("");
  const [relatedId, setRelatedId] = useState<string>("");
  const [status, setStatus] = useState<number>();

  const [currentPayload, setCurrentPayload] = useState<any>({});
  const [currentCode, setCurrentCode] = useState<number>(0);
  const [isPayloadModalOpen, setIsPayloadModalOpen] = useState<boolean>(false);
  const [integrationLog, setIntegrationLog] = useState<boolean>(false);

  const handleSubmit = async () => {
    setPage(1);
    getData();
  };

  const isPayloadEmpty = (payload: { new: any; old?: any }) => {
    if (payload.new || payload.old) {
      if (payload.new) {
        return false;
      }

      return true;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (startDate && !integrationLog) {
      const addThirtyDays = (date: Date) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 30);
        return newDate;
      };

      setEndDate(addThirtyDays(startDate));
    }
  }, [startDate]);

  const clearFilters = (): void => {
    setStartDate(null);
    setEndDate(null);
    setType("");
    setOrigin("");
    setContext("");
    setLabel("");
    setCode("");
    setRelatedId("");
    setProvider("");
    setEntity("");
  };

  // const dataCount = estates.length;
  const lastPage = !integrationLog
    ? dataCount % 10
      ? Math.floor(dataCount / 10) + 1
      : Math.floor(dataCount / 10)
    : dataSyncCount % 10
    ? Math.floor(dataSyncCount / 10) + 1
    : Math.floor(dataSyncCount / 10);

  const getData = async () => {
    if (!startDate && !endDate) {
      dangerNotification(
        "Não foi possível realizar busca",
        "Por favor, selecione a Data Inical e Data Final"
      );
      return;
    }

    setIsLoadingEvents(true);
    try {
      const filters: EventsFilter = {
        page: page,
        start: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
        end: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
        type: type ? type : null,
        origin: origin ? origin : null,
        context: context ? context : null,
        label: label ? label : null,
        code: code ? Number(code) : null,
        relatedId: relatedId ? relatedId : null,
      };
      const dataSyncFilters: DataSyncFilter = {
        page: page,
        start: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
        end: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
        provider: provider ? provider : null,
        entity: entity ? entity : null,
        relatedId: relatedId ? relatedId : null,
        status: status ? status : null,
      };

      if (integrationLog) {
        const response: { data: any; count: number } =
          await getDataSyncControlEntries(dataSyncFilters);
        setDataSyncEntries(response.data);
        setDataSyncCount(response.count);
      } else {
        const response: { data: EventsResponseType[]; count: number } =
          await getEvents(filters);
        setEvents(response.data);
        setDataCount(response.count);
      }
    } catch (err) {
      dangerNotification(
        "Erro",
        "Não foi possível realizar a consulta. Contate o administrador."
      );
    } finally {
      setIsLoadingEvents(false);
    }
  };

  useEffect(() => {
    if (page !== oldPage) {
      setOldPage(page);
      getData();
    }
  }, [page, oldPage]);

  const openPayloadModal = (payload: any, code: number) => {
    setCurrentPayload(payload);
    setCurrentCode(code);
    setIsPayloadModalOpen(true);
  };

  const handleEnterKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  function renderEventLogTableHeader() {
    return (
      <Table.Row>
        <Table.HeaderCell>#</Table.HeaderCell>
        <Table.HeaderCell>Data</Table.HeaderCell>
        <Table.HeaderCell>Origem</Table.HeaderCell>
        <Table.HeaderCell>Label</Table.HeaderCell>
        <Table.HeaderCell>Related ID</Table.HeaderCell>
        <Table.HeaderCell>Mensagem</Table.HeaderCell>
        <Table.HeaderCell>Usuário</Table.HeaderCell>
        <Table.HeaderCell>Payload</Table.HeaderCell>
      </Table.Row>
    );
  }

  function renderTableData() {
    return events.map((event: any) => {
      return (
        <Table.Row key={event.code}>
          <Table.Cell>{event.code}</Table.Cell>
          <Table.Cell>
            {moment(event.createdAt).format("DD/MM/YYYY - HH:mm:ss")}
          </Table.Cell>
          <Table.Cell>{event.origin}</Table.Cell>
          <Table.Cell>{event.label}</Table.Cell>
          <Table.Cell>{event.relatedId}</Table.Cell>
          <Table.Cell>{event.content}</Table.Cell>
          <Table.Cell style={{ cursor: event.user?.name ? "help" : "default" }}>
            {event.user?.name ? (
              <Popup
                trigger={<span>{event.user.name}</span>}
                flowing={true}
                position={"left center"}
              >
                <Popup.Content>
                  <div>
                    <strong>Nome: </strong> {event.user.name}
                  </div>
                  <div>
                    <strong>E-mail: </strong> {event.user.email}
                  </div>
                  <div>
                    <strong>Telefone: </strong>{" "}
                    {event.user.cellphone
                      ? maskPhoneNumber(event.user.cellphone)
                      : ""}
                  </div>
                  <div>
                    <strong>Perfil: </strong> <i>{event.user.role}</i>
                  </div>
                  {event.user.broker && (
                    <div>
                      <strong>Corretora: </strong> {event.user.broker.name}
                    </div>
                  )}
                  {event.user.estate && (
                    <div>
                      <strong>Imobiliária: </strong> {event.user.estate.name}
                    </div>
                  )}
                </Popup.Content>
              </Popup>
            ) : (
              "-"
            )}
          </Table.Cell>
          <Table.Cell>
            {!isPayloadEmpty(event.payload) ? (
              <Icon
                name={"eye"}
                onClick={() => openPayloadModal(event.payload, event.code)}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <Icon name={"ban"} style={{ cursor: "no-drop" }} />
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  function renderTableIntegrationData() {

    return dataSyncEntries.map((dataSyncEntry: any) => {

      const createdAt = new Date(dataSyncEntry.created_at);
      const dateFormatted = createdAt.toLocaleDateString("pt-BR");
      const timeFormatted = createdAt.toLocaleTimeString("pt-BR");
      const policyIssueDate = moment(dataSyncEntry.hiredData.insurerPolicyCreatedAt).format("DD/MM/YYYY - HH:mm:ss")


      return (
        <Table.Row key={dataSyncEntry.id}>
          <Table.Cell>
          {`${dateFormatted} ${timeFormatted}`}
          </Table.Cell>
          <Table.Cell>{dataSyncEntry.status == 1 ? 'Não Sincronizado' : 'Sincronizado'}</Table.Cell>
          <Table.Cell>{`${policyIssueDate}`}</Table.Cell>
          <Table.Cell>{dataSyncEntry.hiredData.insurerPolicyId}</Table.Cell>
          <Table.Cell>{dataSyncEntry.entity}</Table.Cell>
          <Table.Cell>{dataSyncEntry.provider_id}</Table.Cell>
          <Table.Cell>
            {dataSyncEntry.hiredData.bondInsurance.naturalTenant
              ? dataSyncEntry.hiredData.bondInsurance.naturalTenant
                  .main_tenant_name
              : dataSyncEntry.hiredData.bondInsurance.legalTenant.company}
          </Table.Cell>
          <Table.Cell>
            {dataSyncEntry.hiredData.bondInsurance.estate.name}
          </Table.Cell>
          <Table.Cell>{dataSyncEntry.observations}</Table.Cell>
        </Table.Row>
      );
    });
  }

  function renderIntegrationLogTableHeader() {
    return (
      <Table.Row>
        <Table.HeaderCell>Data de Criação</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Data de Emissão da Apólice</Table.HeaderCell>
        <Table.HeaderCell>Apólice</Table.HeaderCell>
        <Table.HeaderCell>Entidade</Table.HeaderCell>
        <Table.HeaderCell>Provedor</Table.HeaderCell>
        <Table.HeaderCell>Locatário</Table.HeaderCell>
        <Table.HeaderCell>Imobiliária</Table.HeaderCell>
        <Table.HeaderCell>Observações</Table.HeaderCell>
      </Table.Row>
    );
  }

  useEffect(() => {
    if (!isEmptyObject(currentPayload)) {
      console.log({ currentPayload });
    }
  }, [currentPayload]);

  function renderPayloadView() {
    const payload: { new: any; old: any } = currentPayload;

    if (typeof payload?.new === typeof payload?.old) {
      return (
        <div>
          <h3>New</h3>
          <pre>{JSON.stringify(payload.new, null, 2)}</pre>
          <h3>Old</h3>
          <pre>{JSON.stringify(payload.old, null, 2)}</pre>
        </div>
      );
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1>Em desenvolvimento...</h1>
        <Lottie options={defaultOptions} height={500} width={500} />
      </div>
    );
  }

  return (
    <>
      <Form>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: "15px",
            gap: "10px",
          }}
        >
          <Button
            icon
            circular
            primary
            onClick={() => setIntegrationLog(!integrationLog)}
          >
            <Icon name={"calendar alternate outline"} />
          </Button>
          <FieldLabel
            style={{ cursor: "pointer" }}
            onClick={() => setIntegrationLog(!integrationLog)}
          >
            {integrationLog
              ? "Exibir Log de Eventos"
              : "Exibir Log de Integrações"}
          </FieldLabel>
        </div>
        <Grid columns={"equal"}>
          <Grid.Row>
            <Grid.Column width={2}>
              <Form.Field>
                <label>DATA INICIAL</label>
                <CustomDatePicker
                  fluid
                  maxDate={endDate && endDate}
                  value={startDate && moment(startDate).format("DD/MM/YYYY")}
                  onChange={(date: Date) => setStartDate(date)}
                  placeholder={"Data Inicial"}
                />
              </Form.Field>
            </Grid.Column>

            <Grid.Column width={2}>
              <Form.Field>
                <label>DATA FINAL</label>
                <CustomDatePicker
                  fluid
                  placeholder={"Data Final"}
                  minDate={startDate && startDate}
                  value={endDate && moment(endDate).format("DD/MM/YYYY")}
                  onChange={(date: Date) => setEndDate(date)}
                />
              </Form.Field>
            </Grid.Column>

            <Grid.Column width={!integrationLog ? 2 : 3}>
              <Form.Field>
                <label>{!integrationLog ? "TYPE" : "PROVEDOR"}</label>
                <Dropdown
                  fluid
                  onChange={
                    !integrationLog
                      ? (e, { value }) => setType(String(value))
                      : (e, { value }) => setProvider(String(value))
                  }
                  value={!integrationLog ? type : provider}
                  placeholder={!integrationLog ? "Type" : "Provedor"}
                  options={!integrationLog ? TypeEnum : ProviderEnum}
                  selection
                  clearable
                  search
                />
              </Form.Field>
            </Grid.Column>

            <Grid.Column width={!integrationLog ? 2 : 4}>
              <Form.Field>
                <label>{!integrationLog ? "ORIGIN" : "ENTIDADE"}</label>
                <Dropdown
                  fluid
                  onChange={
                    !integrationLog
                      ? (e, { value }) => setOrigin(String(value))
                      : (e, { value }) => setEntity(String(value))
                  }
                  value={!integrationLog ? origin : entity}
                  placeholder={!integrationLog ? "Origin" : "Entidade"}
                  options={!integrationLog ? OriginEnum : EntityEnum}
                  selection
                  clearable
                  search
                />
              </Form.Field>
            </Grid.Column>

            {integrationLog && (
              <Grid.Column>
                <Form.Field>
                  <label>STATUS</label>
                  <Dropdown
                    fluid
                    onChange={(e, { value }) => setStatus(Number(value))}
                    value={status}
                    placeholder={"Status"}
                    options={StatusEnum}
                    selection
                    clearable
                    search
                  />
                </Form.Field>
              </Grid.Column>
            )}

            {!integrationLog && (
              <Grid.Column>
                <Form.Field>
                  <label>LABEL</label>
                  <Dropdown
                    fluid
                    onChange={(e, { value }) => setLabel(String(value))}
                    value={label}
                    placeholder={"Label"}
                    options={LabelEnum}
                    selection
                    clearable
                    search
                  />
                </Form.Field>
              </Grid.Column>
            )}

            {!integrationLog && (
              <Grid.Column>
                <Form.Field>
                  <label>CONTEXT</label>
                  <Dropdown
                    fluid
                    onChange={(e, { value }) => setContext(String(value))}
                    value={context}
                    placeholder={"Context"}
                    options={ContextEnum}
                    selection
                    clearable
                    search
                  />
                </Form.Field>
              </Grid.Column>
            )}
          </Grid.Row>

          <Grid.Row>
            {!integrationLog && (
              <Grid.Column width={2}>
                <Form.Field>
                  <label>CODE</label>
                  <Input
                    fluid
                    onChange={(event) => {
                      if (!isNaN(Number(event.target.value)))
                        setCode(String(event.target.value));
                    }}
                    onKeyUp={handleEnterKeyPress}
                    value={code}
                    placeholder={"Code"}
                  />
                </Form.Field>
              </Grid.Column>
            )}

            <Grid.Column width={4}>
              <Form.Field>
                <label>RELATED ID</label>
                <Input
                  fluid
                  onChange={(event) => setRelatedId(String(event.target.value))}
                  onKeyUp={handleEnterKeyPress}
                  value={relatedId}
                  placeholder={"Related ID"}
                />
              </Form.Field>
            </Grid.Column>

            <Grid.Column width={"5"} floated={"right"}>
              <Grid columns={"equal"}>
                <Grid.Column>
                  <Form.Field>
                    <label>&nbsp;</label>
                    <Button
                      fluid
                      onClick={clearFilters}
                      labelPosition="right"
                      icon={"eraser"}
                      content={"Limpar Filtros"}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>&nbsp;</label>
                    <Button
                      fluid
                      color={"blue"}
                      onClick={handleSubmit}
                      labelPosition="right"
                      icon={"search"}
                      content={"Pesquisar"}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>

      <Dimmer.Dimmable as={Segment} dimmed={isLoadingEvents}>
        <Dimmer active={isLoadingEvents} inverted>
          <Loader />
        </Dimmer>
        {(events && events.length > 0) ||
        (dataSyncEntries && dataSyncEntries.length > 0) ? (
          <TableWrapper>
            <Table>
              <Header>
                {!integrationLog
                  ? renderEventLogTableHeader()
                  : renderIntegrationLogTableHeader()}
              </Header>
              <Table.Body>
                {!integrationLog
                  ? renderTableData()
                  : renderTableIntegrationData()}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="17">
                    <Menu floated="right" pagination>
                      <Menu.Item>
                        <p>{`${page * 10 - 9} - ${page * 10} de ${
                          !integrationLog ? dataCount : dataSyncCount
                        }`}</p>
                      </Menu.Item>
                      <Menu.Item
                        disabled={page === 1}
                        as="a"
                        icon
                        onClick={() => setPage(1)}
                      >
                        <Icon name="angle double left" />
                      </Menu.Item>
                      <Menu.Item
                        disabled={page === 1}
                        as="a"
                        icon
                        onClick={() => setPage(page - 1)}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item as="a">{page}</Menu.Item>
                      <Menu.Item
                        disabled={page === lastPage || events.length < 10 && dataSyncEntries.length < 10}
                        as="a"
                        icon
                        onClick={() => setPage(page + 1)}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                      <Menu.Item as="a" icon>
                        <Icon
                          disabled={page === lastPage || events.length < 10 && dataSyncEntries.length < 10}
                          name="angle double right"
                          onClick={() => setPage(lastPage)}
                        />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </TableWrapper>
        ) : (
          <NotFoundWrapper>
            <p>Nenhum Log Cadastrado</p>
          </NotFoundWrapper>
        )}
      </Dimmer.Dimmable>

      <Modal
        open={isPayloadModalOpen}
        onClose={() => setIsPayloadModalOpen(false)}
        size={"large"}
      >
        <Modal.Header>Detalhes do Payload #{currentCode}</Modal.Header>
        <Modal.Content scrolling>{renderPayloadView()}</Modal.Content>
      </Modal>
    </>
  );
};

export default connect(mapState, mapDispatch)(EventsTable);
