import React from 'react';

import { Dropdown as SemanticDropDown, DropdownProps } from 'semantic-ui-react';

import styled from 'styled-components';

const DropdownWrapper = styled.div`
  .ui.dropdown{
    /* background-color: #F5F8FA !important; */
  }

  .disabled.dropdown {
    background-color: #F5F8FA !important;
    opacity: 1 !important;
  }
`

const StatusDropdown = (data: DropdownProps) => {
  const { form } = data;
  const { setFieldTouched } = form;

  return (
    <>
      <DropdownWrapper>
        <SemanticDropDown
          {...data.field}
          {...data}
          button
          className='icon'
          floating
          color={'blue'}
          onBlur={(e, { value }) => {
            setFieldTouched(data.field.name, true, true);
          }}
        />
      </DropdownWrapper>
    </>
  );
}

export default StatusDropdown;
