export const InvoicesStatusList = [
  {
    key: 'OPEN',
    value: 'OPEN',
    text: 'Aberto'
  },
  {
    key: 'CLOSED',
    value: 'CLOSED',
    text: 'Pago'
  },
  {
    key: 'CANCELED',
    value: 'CANCELED',
    text: 'Cancelado'
  },
];

export function translateInvoiceStatus(status: string) {
  switch (status) {
    case 'OPEN':
      return 'Aberto';
    case 'CLOSED':
      return 'Pago';
    case 'CANCELED':
      return 'Cancelado';
    default:
      return 'Não Definido'
  }
}
