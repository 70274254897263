import { Field, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Input,
  Dropdown as DropdownNativo,
  Checkbox,
  DropdownItemProps
} from 'semantic-ui-react';

import SectionLabel from '../../../../../../../../components/SectionLabel';
import { BondInsurancePropertyForGetBondInsurancesResponseDTO } from '../../../../../../../../dtos/bond-insurance-list/get-bond-insurances-response.dto';
import { HireTypePropertyEnum } from '../../../../../../../../enums/residential-occupations.enum';
import { AddressService } from '../../../../../../../../services/address';
import { maskZipCode } from '../../../../../../../../services/masks';
import { FieldLabel } from '../../../../../../../../styles';
import { PayloadPortoUpdateRequestInterface } from '../../ModalHireProposal/interfaces/SubmitEssencialHirePorto.interface';
import { formField } from '../../ModalHireProposal/Model/formFields';
import { PortoStreetTypeOptions } from '../Helpers/portoStreetType';
import AddressWarningBanner from '../../../AddressWarningBanner';
import { sliceString } from '../../../../../../../../utils/sliceString';

const portoPropertyTypeOptions: Array<DropdownItemProps> = [
  {
    value: HireTypePropertyEnum.apartment,
    key: HireTypePropertyEnum.apartment,
    text: 'Apartamento'
  },
  {
    value: HireTypePropertyEnum.kitnet,
    key: HireTypePropertyEnum.kitnet,
    text: 'Kitnet'
  },
  {
    value: HireTypePropertyEnum.standardHouse,
    key: HireTypePropertyEnum.standardHouse,
    text: 'Casa Padrão'
  },
  { value: HireTypePropertyEnum.roof, key: HireTypePropertyEnum.roof, text: 'Cobertura' },
  {
    value: HireTypePropertyEnum.residenceService,
    key: HireTypePropertyEnum.residenceService,
    text: 'Flat/Residence Service'
  },
  { value: HireTypePropertyEnum.loft, key: HireTypePropertyEnum.loft, text: 'Sobrado' }
];

type PropertyFormRequestInterface = {
  property?: BondInsurancePropertyForGetBondInsurancesResponseDTO;
};

const PropertyForm = ({
  property,
  values,
  setFieldValue
}: FormikProps<PayloadPortoUpdateRequestInterface> & PropertyFormRequestInterface) => {
  const [cepLoading, setCepLoading] = useState<boolean>(false);
  const [noComplement, setNoComplement] = useState(values.property.hasComplement);

  useEffect(() => {
    if (property) {
      setFieldValue(formField.property.zipcode.name, maskZipCode(property.zip_code));
      setFieldValue(formField.property.estate.name, property.state);
      setFieldValue(formField.property.city.name, property.city);
      setFieldValue(formField.property.district.name, property.district);
      setFieldValue(formField.property.street.name, property.street);
      setFieldValue(formField.property.number.name, property.number);
      setFieldValue(formField.property.complement.name, property.complement);
      setFieldValue(formField.property.propertyType.name, property.property_type);
      if (values.property.hasComplement == undefined) {
        setNoComplement(false);
        setFieldValue(formField.property.hasComplement.name, false);
      }
    }
  }, [property]);

  const onChangeCep = (zipCode: string): void => {
    const maskedValue = maskZipCode(zipCode);
    setFieldValue(formField.property.zipcode.name, maskedValue);
    if (maskedValue.length >= 9) {
      setCepLoading(true);

      AddressService.getAddressByZipCode(zipCode)
        .then(address => {
          console.log(address);
          setFieldValue(formField.property.city.name, address.city);
          setFieldValue(formField.property.district.name, address.district);
          setFieldValue(formField.property.estate.name, address.state);
          setFieldValue(formField.property.street.name, address.street);
          setCepLoading(false);
        })
        .catch(e => {
          console.log('address error:', e);
        });
    }
  };

  return (
    <div style={{ padding: '0 0 0 10px' }}>
      <Grid.Row>
        <p>
          <b style={{ color: 'red' }}> ATENÇÃO: </b>
          {`Por regra da seguradora Porto Seguro, a precificação da oferta do seguro fiança só é garantida no dia da contratação/emissão da apólice, pois terá, de fato, os dados reais da data de início e fim da apólice, sendo o início a data da contratação na cia (não é possível retroagir) e o fim a data final de contrato de locação. Isso quer dizer que o preço apresentado na análise/cotação poderá sofrer variações no momento da contratação/emissão.`}
           
          <br />
        </p>
      </Grid.Row>
      <SectionLabel text="Informações do imóvel" />
      <Grid columns="equal">
        <Grid.Row>
          <AddressWarningBanner />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <FieldLabel>{formField.property.zipcode.label}</FieldLabel>
            <Field
              name={formField.property.zipcode.name}
              value={values.property.zipcode}
              onChange={(e: any) => {
                onChangeCep(e.target.value);
              }}
              component={Input}
              maxLength={9}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>{formField.property.estate.label}</FieldLabel>
            <Field
              name={formField.property.estate.name}
              component={Input}
              value={values.property.estate}
              onChange={(e: any) => {
                setFieldValue(formField.property.estate.name, e.target.value);
              }}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>{formField.property.city.label}</FieldLabel>
            <Field
              name={formField.property.city.name}
              component={Input}
              value={sliceString(values.property.city, 30)}
              onChange={(e: any) => {
                setFieldValue(
                  formField.property.city.name,
                  sliceString(e.target.value, 30)
                );
              }}
              fluid
              disabled
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <FieldLabel>{formField.property.district.label}</FieldLabel>
            <Field
              name={formField.property.district.name}
              component={Input}
              value={sliceString(values.property.district, 40)}
              onChange={(e: any) => {
                setFieldValue(
                  formField.property.district.name,
                  sliceString(e.target.value, 40)
                );
              }}
              fluid
              disabled
            />
          </Grid.Column>
          <Grid.Column>
            <FieldLabel>{formField.property.streetType.label}</FieldLabel>
            <DropdownNativo
              style={{ width: '100%' }}
              name={formField.property.streetType.name}
              search
              clearable
              placeholder={'Selecione'}
              options={PortoStreetTypeOptions()}
              onChange={(e: any, { value }) =>
                setFieldValue(formField.property.streetType.name, value)
              }
              value={values.property.streetType}
              component={DropdownNativo}
              selection
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>{formField.property.street.label}</FieldLabel>
            <Field
              name={formField.property.street.name}
              component={Input}
              value={sliceString(values.property.street, 40)}
              onChange={(e: any) => {
                setFieldValue(
                  formField.property.street.name,
                  sliceString(e.target.value, 40)
                );
              }}
              fluid
              disabled
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={3}>
            <FieldLabel>{formField.property.number.label}</FieldLabel>
            <Field
              name={sliceString(formField.property.number.name, 6)}
              component={Input}
              value={values.property.number}
              onChange={(e: any) => {
                setFieldValue(
                  formField.property.number.name,
                  sliceString(e.target.value, 6)
                );
              }}
              fluid
            />
          </Grid.Column>

          <Grid.Column width={3}>
            <FieldLabel>{formField.property.complement.label}</FieldLabel>
            <Field
              name={formField.property.complement.name}
              component={Input}
              value={sliceString(values.property.complement, 20)}
              onChange={(e: any) => {
                setFieldValue(
                  formField.property.complement.name,
                  sliceString(e.target.value, 20)
                );
              }}
              error={sliceString(values.property.complement, 20)?.length > 20}
              disabled={noComplement}
            />
            <span>
              {20 - (sliceString(values.property.complement, 20)?.length ?? 0)} caracteres
              restantes.
            </span>
          </Grid.Column>

          <Grid.Column verticalAlign="middle" width={3}>
            <Checkbox
              label="Sem Complemento"
              checked={noComplement || false}
              onChange={(e: any, data) => {
                if (data.checked) {
                  setNoComplement(true);
                  setFieldValue(formField.property.hasComplement.name, true);
                } else {
                  setNoComplement(false);
                  setFieldValue(formField.property.hasComplement.name, false);
                }
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <FieldLabel>{formField.property.propertyType.label}</FieldLabel>
            <DropdownNativo
              style={{ width: '100%' }}
              name={formField.property.propertyType.name}
              search
              clearable
              placeholder={'Selecione'}
              options={portoPropertyTypeOptions}
              onChange={(e: any, { value }) =>
                setFieldValue(formField.property.propertyType.name, value)
              }
              value={values.property.propertyType}
              component={DropdownNativo}
              selection
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
export default PropertyForm;
