import * as Yup from 'yup';
import { EmploymentRelationshipEnum } from '../../enums/employment-relationship.enum';

const requiredField = 'Campo obrigatório!';

export const legalCommercialResidentialSchema = Yup.object().shape({
  tenant_info: Yup.object().shape({
    company: Yup.string().required(requiredField),
    document: Yup.string()
      .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/, 'CNPJ inválido!')
      .required(requiredField),
    foundation_date: Yup.date().required(requiredField),
    last_contractual_alteration_date: Yup.date().required(requiredField),
    share_capital: Yup.string().required(requiredField),
    paid_in_capital: Yup.string().required(requiredField),
    anual_revenue: Yup.string().required(requiredField),
    phone: Yup.string().required(requiredField),
    contact_name: Yup.string().required(requiredField),
    contact_phone: Yup.string().required(requiredField),
    zip_code: Yup.string()
      .min(9, 'Número do CEP Inválido')
      .max(9, 'Número do CEP Inválido')
      .required(requiredField),
    street: Yup.string().required(requiredField),
    district: Yup.string().required(requiredField),
    city: Yup.string().required(requiredField),
    state: Yup.string().required(requiredField),
    number: Yup.string().required(requiredField),
    property_type: Yup.string().required(requiredField),
    email: Yup.string().email('Email inválido!').required(requiredField),
    partners: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(requiredField),
        document: Yup.string().matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, 'CPF inválido!'),
        percent: Yup.number().required(requiredField),
        date_of_entry: Yup.date().required(requiredField)
      })
    )
  })
});

export const naturalResidentialSchema = (invalidEmail: string) =>
  Yup.object().shape({
    // (invalidEmail: string) =>
    tenant_info: Yup.object().shape({
      main_tenant_name: Yup.string().required(requiredField),
      main_tenant_cpf: Yup.string()
        .matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, 'CPF inválido!')
        .required(requiredField),
      // main_tenant_rg: Yup.string().required(requiredField),
      // main_tenant_rg_expeditor_organ: Yup.string().required(requiredField),
      // main_tenant_rg_emission_date: Yup.date().required(requiredField),
      main_tenant_email: Yup.string()
        .email('Email inválido!')
        .notOneOf(
          [invalidEmail],
          'O email do locatário não pode ser igual ao email da Imobiliária!'
        )
        .required(requiredField),
      main_tenant_phone: Yup.string().required(requiredField).isValidMaskedTelephone(),
      main_tenant_gender: Yup.string().required(requiredField),
      main_tenant_birth_date: Yup.date().required(requiredField),
      main_tenant_mother_name: Yup.string().required(requiredField),
      main_tenant_marital_status: Yup.string().required(requiredField),
      partner_rent_composition: Yup.boolean().when('main_tenant_marital_status', {
        is: 'CASADO',
        then: Yup.boolean().required(requiredField)
      }),
      partner_name: Yup.string().when('partner_rent_composition', {
        is: true,
        then: Yup.string().required(requiredField)
      }),
      partner_cpf: Yup.string().when('partner_rent_composition', {
        is: true,
        then: Yup.string()
          .matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, 'CPF inválido!')
          .required(requiredField)
      }),
      employment_relationship: Yup.string().required(requiredField),
      employment_admission_date: Yup.date().when('employment_relationship', {
        is:
          EmploymentRelationshipEnum.privateServant ||
          EmploymentRelationshipEnum.civilServant,
        then: Yup.date().required(requiredField)
      }),
      resident: Yup.boolean().required(requiredField),
      resident_name: Yup.string().when('resident', {
        is: false,
        then: Yup.string().required(requiredField)
      }),
      resident_cpf: Yup.string().when('resident', {
        is: false,
        then: Yup.string()
          .matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, 'CPF inválido!')
          .required(requiredField)
      }),
      resident_birth_date: Yup.date().when('resident', {
        is: false,
        then: Yup.date().required(requiredField)
      }),
      // resident_rg: Yup.string()
      //   .when('resident', {
      //     is: false,
      //     then: Yup.string()
      //       .required(requiredField)
      //   }),
      // resident_rg_expeditor_organ: Yup.string()
      //   .when('resident', {
      //     is: false,
      //     then: Yup.string()
      //       .required(requiredField)
      //   }),
      // resident_rg_emission_date: Yup.string()
      //   .when('resident', {
      //     is: false,
      //     then: Yup.string()
      //       .required(requiredField)
      //   }),
      resident_phone: Yup.string().when('resident', {
        is: false,
        then: Yup.string().required(requiredField)
      }),
      resident_gender: Yup.string().when('resident', {
        is: false,
        then: Yup.string().required(requiredField)
      }),
      resident_nationality: Yup.string().when('resident', {
        is: false,
        then: Yup.string().required(requiredField)
      }),
      // resident_current_address_zipcode: Yup.string()
      //   .when('resident', {
      //     is: false,
      //     then: Yup.string()
      //       .min(9, 'Número do CEP Inválido')
      //       .max(9, 'Número do CEP Inválido')
      //       .required(requiredField),
      //   }),
      resident_politically_exposed_person: Yup.string().when('resident', {
        is: false,
        then: Yup.string().required(requiredField)
      }),
      resident_close_to_politically_exposed_person: Yup.object().when('resident', {
        is: false,
        then: Yup.object().shape({
          politically_exposed_person_name: Yup.string().when(
            'politically_exposed_person',
            {
              is: 'CLOSE_TO',
              then: Yup.string().required(requiredField),
              otherwise: Yup.string()
            }
          ),
          politically_exposed_person_cpf: Yup.string().when(
            'politically_exposed_person',
            {
              is: 'CLOSE_TO',
              then: Yup.string().required(requiredField),
              otherwise: Yup.string()
            }
          ),
          degree_of_kinship_to_politically_exposed_person: Yup.string().when(
            'politically_exposed_person',
            {
              is: 'CLOSE_TO',
              then: Yup.string().required(requiredField),
              otherwise: Yup.string()
            }
          )
        })
      }),
      resident_profession: Yup.string().when('resident', {
        is: false,
        then: Yup.string().required(requiredField)
      }),
      // resident_monthly_fixed_income: Yup.string()
      //   .when('resident', {
      //     is: false,
      //     then: Yup.string()
      //       .required(requiredField)
      //   }),
      resident_employment_relationship: Yup.string().when('resident', {
        is: false,
        then: Yup.string().required(requiredField)
      }),
      resident_email: Yup.string().when('resident', {
        is: false,
        then: Yup.string().email('Email inválido!').required(requiredField)
      }),
      quiz_company_created: Yup.boolean().required(requiredField),
      quiz_company_cnpj: Yup.string().when('quiz_company_created', {
        is: true,
        then: Yup.string()
          .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/, 'CNPJ inválido!')
          .required(requiredField)
      }),
      quiz_company_rent: Yup.string().when('quiz_company_created', {
        is: true,
        then: Yup.string().required(requiredField)
      }),
      quiz_partners: Yup.boolean().required(requiredField),
      quiz_company_field_of_activity: Yup.string().when('quiz_company_created', {
        is: true,
        then: Yup.string().required(requiredField)
      }),
      partners: Yup.array().when('quiz_partners', {
        is: true,
        then: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required(requiredField),
            document: Yup.string()
              .matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, 'CPF inválido!')
              .required(requiredField)
          })
        )
      }),
      include_sympathise_tenants: Yup.boolean().required(requiredField),
      sympathise_tenants: Yup.array().when('include_sympathise_tenants', {
        is: true,
        then: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required(requiredField),
            cpf: Yup.string()
              .matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, 'CPF inválido!')
              .required(requiredField),
            // rg: Yup.string().required(requiredField),
            // rg_expeditor_organ: Yup.string().required(requiredField),
            // rg_emission_date: Yup.string().required(requiredField),
            phone: Yup.string().required(requiredField),
            gender: Yup.string().required(requiredField),
            birth_date: Yup.date().required(requiredField),
            nationality: Yup.string().required(requiredField),
            // current_address_zipcode: Yup.string().min(9, 'Número do CEP Inválido').max(9, 'Número do CEP Inválido').required(requiredField),
            politically_exposed_person: Yup.string().required(requiredField),
            close_to_politically_exposed_person: Yup.object().shape({
              politically_exposed_person_name: Yup.string().when(
                'politically_exposed_person',
                {
                  is: 'CLOSE_TO',
                  then: Yup.string().required(requiredField),
                  otherwise: Yup.string()
                }
              ),
              politically_exposed_person_cpf: Yup.string().when(
                'politically_exposed_person',
                {
                  is: 'CLOSE_TO',
                  then: Yup.string().required(requiredField),
                  otherwise: Yup.string()
                }
              ),
              degree_of_kinship_to_politically_exposed_person: Yup.string().when(
                'politically_exposed_person',
                {
                  is: 'CLOSE_TO',
                  then: Yup.string().required(requiredField),
                  otherwise: Yup.string()
                }
              )
            }),
            profession: Yup.string().required(requiredField),
            monthly_fixed_income: Yup.string().required(requiredField),
            resident: Yup.string().required(requiredField),
            employment_relationship: Yup.string().required(requiredField),
            email: Yup.string().email('Email inválido!').required(requiredField)
          })
        )
      })
    })
  });

export const validationSchemas = [
  Yup.object().shape({
    initial_info: Yup.object().shape({
      estate: Yup.string().required(requiredField),
      purpose: Yup.string().required(requiredField),
      tenant_type: Yup.string().required(requiredField),
      analysis_assurance: Yup.string().required(requiredField)
    })
  }),
  Yup.object().shape({
    property_info: Yup.object().shape({
      // rent_insurance_contract_start_date: Yup.date().required(requiredField),
      // rent_insurance_contract_end_date: Yup.date().required(requiredField),
      // rental_period_start_date: Yup.date().required(requiredField),
      // rental_period_end_date: Yup.date().required(requiredField),
      zip_code: Yup.string()
        .min(9, 'Número do CEP Inválido')
        .max(9, 'Número do CEP Inválido')
        .required(requiredField),
      street: Yup.string().required(requiredField),
      district: Yup.string().required(requiredField),
      city: Yup.string().required(requiredField),
      state: Yup.string().required(requiredField),
      number: Yup.number()
        .required(requiredField)
        .typeError('Deve ser um valor numérico'),
      property_type: Yup.string().required(requiredField),
      reason: Yup.string().required(requiredField),
      rent_value: Yup.string().greaterThanFiveHundredReals('O valor do aluguel deve ser maior que R$ 500,00').required(requiredField),

      // To toggle if it's optional or not
      location_in_progress: Yup.boolean(),
      rental_period_start_date: Yup.date().when('location_in_progress', {
        is: true,
        then: Yup.date().required(requiredField)
      }),
      rental_period_end_date: Yup.date().when('location_in_progress', {
        is: true,
        then: Yup.date().required(requiredField)
      }),
      is_condominium_fee_required: Yup.boolean(),
      condominium_fee: Yup.string().when('is_condominium_fee_required', {
        is: true,
        then: Yup.string().required(requiredField)
      }),
      is_energy_bill_required: Yup.boolean(),
      energy_bill: Yup.string().when('is_energy_bill_required', {
        is: true,
        then: Yup.string().required(requiredField)
      }),
      is_gas_bill_required: Yup.boolean(),
      gas_bill: Yup.string().when('is_gas_bill_required', {
        is: true,
        then: Yup.string().required(requiredField)
      }),
      is_water_bill_required: Yup.boolean(),
      water_bill: Yup.string().when('is_water_bill_required', {
        is: true,
        then: Yup.string().required(requiredField)
      }),
      is_taxes_value_required: Yup.boolean(),
      taxes_value: Yup.string().when('is_taxes_value_required', {
        is: true,
        then: Yup.string().required(requiredField)
      }),
      // rent_indemnity_period: Yup.number().required(requiredField),
      validity: Yup.number()
        .required(requiredField)
        .typeError('Você deve selecionar um valor válido'),
      total_amount: Yup.number().required(requiredField),
      real_occupant_name: Yup.string().when('reason', {
        is: 'Locação para moradia',
        then: Yup.string().required(requiredField)
      }),
      real_occupant_doc: Yup.string().when('reason', {
        is: 'Locação para moradia',
        then: Yup.string()
          .matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, 'Documento inválido!')
          .required(requiredField)
      })
    })
  }),
  // Yup.object().shape({
  //   owner_info: Yup.object().shape({
  //     name: Yup.string().required(requiredField),
  //     document: Yup.string().required(requiredField),
  //     email: Yup.string().required(requiredField),
  //     phone: Yup.string().required(requiredField),
  //     zip_code: Yup.string()
  //       .min(9, 'Número do CEP Inválido')
  //       .max(9, 'Número do CEP Inválido')
  //       .required(requiredField),
  //     street: Yup.string().required(requiredField),
  //     district: Yup.string().required(requiredField),
  //     city: Yup.string().required(requiredField),
  //     state: Yup.string().required(requiredField),
  //     number: Yup.number().required(requiredField).typeError('Deve ser um valor numérico')
  //   })
  // }),
  Yup.object().shape({
    tenant_info: Yup.object().shape({
      company: Yup.string().required(requiredField),
      document: Yup.string()
        .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/, 'CNPJ inválido!')
        .required(requiredField),
      foundation_date: Yup.date().required(requiredField),
      last_contractual_alteration_date: Yup.date().required(requiredField),
      share_capital: Yup.string().required(requiredField),
      paid_in_capital: Yup.string().required(requiredField),
      anual_revenue: Yup.string().required(requiredField),
      phone: Yup.string().required(requiredField),
      contact_name: Yup.string().required(requiredField),
      contact_phone: Yup.string().required(requiredField),
      zip_code: Yup.string()
        .min(9, 'Número do CEP Inválido')
        .max(9, 'Número do CEP Inválido')
        .required(requiredField),
      street: Yup.string().required(requiredField),
      district: Yup.string().required(requiredField),
      city: Yup.string().required(requiredField),
      state: Yup.string().required(requiredField),
      number: Yup.string().required(requiredField),
      complement: Yup.string().required(requiredField),
      email: Yup.string().email('Email inválido!').required(requiredField),
      partners: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(requiredField),
          document: Yup.string().matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, 'CPF inválido!'),
          percent: Yup.number().required(requiredField),
          date_of_entry: Yup.date().required(requiredField)
        })
      )
    })
  })
];
