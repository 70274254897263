import { get } from './request';
import { BrokerState } from '../types/BrokerState';
import { FireInsuranceProposal, FireInsurancePolicy } from '../types/FireInsurance';
import { EstateState } from '../types/EstateState';
import { BondInsurance } from '../types/BondInsurance';
import {
  EstateForGetEstatesResponseDTO,
  EstatesByFilterDTO
} from '../dtos/estate/estate-for-get-estates-response.dto';
import {
  BrokerForGetBrokerResponseDTO,
  EstateForGetBrokerResponseDTO
} from '../dtos/broker/broker-for-get-broker-response.dto';
import { GetBondInsuranceProposalsListResponseDTO } from '../dtos/bond-insurance-proposal-list/get-bond-insurance-proposals-list-response-dto';
import { GetBondInsurancePoliciesListResponseDTO } from '../dtos/bond-insurance-policy-list/get-bond-insurance-policies-list-response-dto';
import * as storage from './storage';

export const list = async (): Promise<BrokerForGetBrokerResponseDTO[]> => {
  let response;

  try {
    response = await get('/brokers');
  } catch (e) {
    throw e;
  }

  return response;
};

export const getBroker = async (id: string): Promise<BrokerForGetBrokerResponseDTO> => {
  const cachedBroker = storage.get('broker');

  if (cachedBroker) {
    const { data, timestamp } = JSON.parse(cachedBroker);

    if (timestamp + 600000 > Date.now()) { // 10 minutes
      return data;
    } else {
      storage.remove('broker');
    }
  }

  let response;
  try {
    response = await get(`/brokers/${id}`);

    const item = {
      data: response,
      timestamp: Date.now()
    }

    storage.set('broker', JSON.stringify(item));
  } catch (e) {
    throw e;
  }

  return response;
};

export const getRoles = async () => {
  const savedRoles = storage.get('roles');
  if (savedRoles) {
    const { data, timestamp } = JSON.parse(savedRoles);
    if (timestamp + 600000 > Date.now()) { // 10 minutes
      return data;
    } else {
      storage.remove('roles');
    }
  }
  let response;

  try {
    response = await get(`/authz`);
    const item = {
      data: response,
      timestamp: Date.now()
    }
    storage.set('roles', JSON.stringify(item));
  } catch (e) {
    throw e;
  }

  return response;
};

export const getFranchises = async () => {
  const brokerId = '7554f468-bce2-4e41-91c1-de527fb01f47';
  const franchisesCached = storage.get('franchises');
  if (franchisesCached) {
    const { data, timestamp } = JSON.parse(franchisesCached);
    if (timestamp + 600000 > Date.now()) {
      return data;
    } else {
      storage.remove('franchises');
    }
  }
  try {
    const response = await get(`/brokers/${brokerId}/franchises`);
    const item = {
      data: response,
      timestamp: Date.now()
    };
    storage.set('franchises', JSON.stringify(item));
    return response;
  } catch (error) {
    return null;
  }
};

export const getBrokerListFromGetBrokerListDTO = (
  brokerDTOList: BrokerForGetBrokerResponseDTO[]
): BrokerState[] => {
  return brokerDTOList.map(brokerDTO => {
    return getBrokerObjectFromGetBrokerDTO(brokerDTO);
  });
};

export const getBrokerObjectFromGetBrokerDTO = (brokerDTO: BrokerForGetBrokerResponseDTO): BrokerState => {
  const brokerObject: BrokerState = {
    id: brokerDTO.id,
    fee: brokerDTO.fee,
    name: brokerDTO.name,
    minimumGain: brokerDTO.minimum_gain,
    estates: brokerDTO?.estates?.map((estate: EstateForGetBrokerResponseDTO) => {
      const estateObject: EstateState = {
        id: estate.id,
        name: estate.name,
        cnpj: estate.cnpj,
        company_name: estate.company_name,
        batchClosingDay: estate.batch_closing_day,
        profitShare: estate.profit_share,
        multiplier: estate.multiplier,
        createdAt: estate.created_at,
        updatedAt: estate.updated_at,
        email: estate.email,
        calculationMode: estate.calculation_mode,
        calculationType: estate.calculation_type,
        estateZipCode: estate.estate_zip_code,
        estateCity: estate.estate_city,
        estateState: estate.estate_state,
        estateComplement: estate.estate_complement,
        estateDistrict: estate.estate_district,
        estateNumber: estate.estate_number,
        estateStreet: estate.estate_street,
        fireInsurers: estate.fire_insurers?.map(fireInsurer => {
          return {
            id: fireInsurer.id,
            code: fireInsurer.code,
            name: fireInsurer.name,
            imageUrl: fireInsurer.image,
            fee: fireInsurer.fee ? fireInsurer.fee : 0,
            availability: fireInsurer.availability
          };
        }),
        capitalizations: estate.capitalizations?.map(capitalization => {
          return {
            id: capitalization.id,
            code: capitalization.code,
            name: capitalization.name,
            imageUrl: capitalization.image,
            fee: capitalization.fee ? capitalization.fee : 0,
            availability: capitalization.availability
          };
        }),
        insurers: estate.insurers?.map(insurer => {
          return {
            id: insurer.id,
            code: insurer.code,
            name: insurer.name,
            imageUrl: insurer.image,
            tax: insurer.bond_insurance_tax ? insurer.bond_insurance_tax : null,
            fee: insurer.fee ? insurer.fee : 0,
            availability: insurer.availability,
            proposals: insurer.proposals_sending ? insurer.proposals_sending : false
          };
        }),
        user: {
          id: estate.user?.id,
          fid: estate.user?.fid,
          refreshToken: estate.user?.refresh_token,
          idToken: estate.user?.id_token,
          name: estate.user?.name,
          status: estate.user?.status,
          document: estate.user?.doc,
          cellphone: estate.user?.cellphone,
          email: estate.user?.email,
          role: estate.user?.role
        },
        insurerSpecificData: [],
        insurer_data: estate.insurer_data?.filter(
          insurerData => insurerData.insurance_type === 'BOND_INSURANCE'
        ),
        menu_fire_insurance: estate.menu_fire_insurance,
      };

      return estateObject;
    })
  };

  return brokerObject;
};

export const getEstates = async (
  id: string
): Promise<EstateForGetEstatesResponseDTO[]> => {
  let response;

  try {
    response = await get(`/brokers/${id}/estates`);
  } catch (e) {
    throw e;
  }

  return response;
};

export const getEstatesByPage = async (
  id: string,
  page: number,
  filter?: any
): Promise<EstatesByFilterDTO[]> => {
  let response;
  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  try {
    response = await get(`/brokers/${id}/estates?${query}&page=${page}`);
  } catch (e) {
    throw e;
  }

  return response;
};

export const getEstatesObjectsFromGetEstatesDTO = (
  data: EstateForGetEstatesResponseDTO[]
): EstateState[] => {
  return data.map(estateDTO => {
    const estateObject: EstateState = {
      id: estateDTO.id,
      name: estateDTO.name,
      company_name: estateDTO.company_name,
      cnpj: estateDTO.cnpj,
      batchClosingDay: estateDTO.batch_closing_day,
      profitShare: estateDTO.profit_share,
      multiplier: estateDTO.multiplier,
      createdAt: estateDTO.created_at,
      updatedAt: estateDTO.updated_at,
      email: estateDTO.email,
      calculationMode: estateDTO.calculation_mode,
      calculationType: estateDTO.calculation_type,
      estateZipCode: estateDTO.estate_zip_code,
      estateState: estateDTO.estate_state,
      estateCity: estateDTO.estate_city,
      estateDistrict: estateDTO.estate_district,
      estateStreet: estateDTO.estate_street,
      estateNumber: estateDTO.estate_number,
      estateComplement: estateDTO.estate_complement,
      status: estateDTO.status,
      responsible_company: estateDTO.responsible_company,

      fireInsurers: estateDTO.fire_insurers
        ? estateDTO.fire_insurers.map(fireInsurer => {
          return {
            id: fireInsurer.id,
            code: fireInsurer.code,
            name: fireInsurer.name,
            imageUrl: fireInsurer.image
          };
        })
        : [],
      capitalizations: estateDTO?.capitalizations
        ? estateDTO.capitalizations.map(capitalization => {
          return {
            id: capitalization.id,
            code: capitalization.code,
            name: capitalization.name,
            imageUrl: capitalization.image
          };
        })
        : [],
      insurers: estateDTO.insurers.map(insurer => {
        return { id: insurer.id, code: insurer.code };
      }),
      user: {
        id: estateDTO.user.id,
        refreshToken: estateDTO.user.refresh_token,
        idToken: estateDTO.user.id_token,
        name: estateDTO.user.name,
        status: estateDTO.user.status,
        document: estateDTO.user.doc,
        cellphone: estateDTO.user.cellphone,
        email: estateDTO.user.email,
        role: estateDTO.user.role
      },
      insurerSpecificData: [],
      insurer_data: estateDTO.insurer_data
    };

    if (estateDTO.franchise?.id) {
      estateObject.franchise = {
        id: estateDTO.franchise.id,
        name: estateDTO.franchise.name
      };
    }
    return estateObject;
  });
};

export const getProposals = async (
  brokerID: string,
  estateID?: string
): Promise<Array<FireInsuranceProposal>> => {
  let response: Array<FireInsuranceProposal>;

  const baseRoute = `/brokers/${brokerID}/proposals`;
  const route = estateID ? baseRoute + `?estate_id=${estateID}` : baseRoute;

  try {
    response = await get(route);
  } catch (e) {
    console.error(e);
    response = [];
  }
  return response;
};

export const getPolicies = async (
  brokerID: string,
  estateID?: string
): Promise<Array<FireInsurancePolicy>> => {
  let response: Array<FireInsurancePolicy>;

  const baseRoute = `/brokers/${brokerID}/fire-insurance-policies`;
  const route = estateID ? baseRoute + `?estate_id=${estateID}` : baseRoute;

  try {
    response = await get(route);
  } catch (e) {
    console.error(e);
    response = [];
  }
  return response;
};

export const getUsers = async (brokerId?: string, query?: any[]): Promise<Array<any>> => {
  let URL = `/brokers/${brokerId}/users`;
  if (query) {
    URL += `?${query.map(q =>
      Object.entries(q).map(entry => `${entry[0]}=${entry[1]}`)
    )}`;
  }

  try {
    return await get(URL);
  } catch (e) {
    throw e;
  }
};

export const getFilteredUsers = async (
  estateId: string,
  page: number,
  filter?: any[]
): Promise<Array<any>> => {
  let query = '';
  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  let response;

  try {
    response = await get(`/brokers/${estateId}/users_filtered?${query}&page=${page}`);
  } catch (e) {
    console.error(e);
    response = [];
  }
  return response;
};

export const getBondInsurances = async (
  brokerID: string,
  filter?: any
): Promise<Array<BondInsurance>> => {
  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  let response;
  try {
    response = await get(`/brokers/${brokerID}/bond-insurances?${query}&take=10&skip=0`);
  } catch (e) {
    console.error(e);
    response = [];
  }
  return response;
};

export const getBrokerBondInsuranceProposals = async (
  page: number,
  filter?: any
): Promise<GetBondInsuranceProposalsListResponseDTO> => {
  let response: GetBondInsuranceProposalsListResponseDTO;
  let query = '';

  if (query) {
    query += `&page=${page}`;
  } else {
    query += `page=${page}`;
  }

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        if (query) {
          query += `&${map[0]}=${map[1]}`;
        } else {
          query += `${map[0]}=${map[1]}`;
        }
      }
    });
  }

  // console.log('QUERY: ', query);

  try {
    response = await get(`/bond-insurance/proposals/?${query}`);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

/*
export const getBrokerBondInsurancePolicies = async (brokerId: string, page: number, filter?: any)
  : Promise<GetBondInsurancePoliciesListResponseDTO> => {
  let response: GetBondInsurancePoliciesListResponseDTO;
  let query: string = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        if (query) {
          query += `&${map[0]}=${map[1]}`
        } else {
          query += `${map[0]}=${map[1]}`
        }
      }
    })
  }

  if (query) {
    query += `&page=${page}`
  } else {
    query += `page=${page}`
  }

  try {
    response = await get(`/bond-insurance/policies/by-broker/${brokerId}?${query}`);
    return response;
  } catch (e) {
    console.error(e);
    throw e
  }
}
*/
