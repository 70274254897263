import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  padding-bottom: 20px;
`;

export const ErrorLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: #E10000;
`;

export const Card = styled.div`
  position: relative;
  padding: 4px 12px;
  box-shadow: 0 0 10px #00000045;
  border-radius: 6px;
  width: 305px;
  cursor: pointer;

  checkbox {
    position: absolute;
    top: 4px;
    left: 4px;
  }

  span {
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }

  .info-icon {
    position: absolute;
    top: 4px;
    right: 4px;
    color: #2185D0;
  }

  ul {
    padding: 0 36px;
    margin: 5px 0 12px;
    li {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .value {
    font-weight: 700;
  }

`;

