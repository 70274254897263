import { EstatePreregisterState } from '../../types/EstatePreregisterState';

export interface EstatePreregisterListResponseDTO {
  // We will use this when we enable the pagination
  data: EstatePreregisterState[];
  count: number;
  current: number;
  page: number;
}

export interface FormErrorsMessages {
  name: string;
  document: string;
  status: string;
  responsible_name: string;
  responsible_email: string;
  responsible_phone: string;
  phone: string;
  email: string;
  zipcode: string;
  street: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  state: string;
  user_id: string;
}

export const typeOptions = [
  {
    index: 1,
    value: 'document',
    text: 'CPF ou CNPJ Parceiro'
  },
  {
    index: 2,
    value: 'name',
    text: 'Nome do Parceiro'
  },
  {
    index: 3,
    value: 'address',
    text: 'Endereço do Parceiro'
  }
];

export enum preregisterStatus {
  ACTIVE = 'Ativo',
  INACTIVE = 'Inativo',
  PENDING = 'Pendente'
}

export const statusOptions = [
  {
    value: 'PENDING',
    key: 0,
    text: 'Pendente'
  },
  {
    value: 'CONCLUDED',
    key: 1,
    text: 'Concluído'
  },
];
