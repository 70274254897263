import React, { useState } from 'react';
import { Dropdown, Icon, Grid } from 'semantic-ui-react';
import { iRootState } from '../../store';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { cleanUserSession } from '../../services/auth';
import { CurrentPageState } from '../../store/types/temp-types';
import { UserState } from '../../types/UserState';
import UnifiedApps from '../../components/UnifiedApps';
import { get } from '../../services/request';
import {
  Avatar,
  CustomDropdownItemContainer,
  DropdownContainer,
  Logo,
  MobileHeaderContainer,
  NavbarContainer,
  NavbarText,
  UserContainer,
  DescriptionText,
  UserInfoContainer,
  UserName,
  ContentText
} from './styles';
import useWindowSize from '../../hooks/useWindowSize';
import logo from '../../resources/imgs/logo.png';
import { DateToStringDateTime } from '../../services/date';
import { translateUserRole } from '../../util';

const mapState = (state: iRootState) => ({
  user: state.user,
  currentPage: state.currentPage
});

const mapDispatch = () => ({});

export async function invalidateCache() {
  try {
    await get('/users/invalidate-cache');
  } catch (error) {
    console.error('Failed to invalidate cache:', error);
  } finally {
    cleanUserSession();
  }
}

export type NavbarProps = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch> &
  RouteComponentProps & { handleToggleSidebar: () => void };

const Navbar = (props: NavbarProps) => {
  const currentPage: CurrentPageState = props.currentPage;
  const user: UserState = props.user;

  const { isMobile } = useWindowSize();
  const dropdownMenuStyles = {
    borderTop: '8px solid #2185D0',
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7
  };

  const logoutHandler = async () => {
    await invalidateCache();
  };

  const getInitials = (fullName: string): string => {
    const nameParts = fullName.split(' ');
    if (nameParts.length === 1) {
      return nameParts[0].charAt(0).toUpperCase();
    } else if (nameParts.length > 1) {
      const firstNameInitial = nameParts[0].charAt(0).toUpperCase();
      const lastNameInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();
      return `${firstNameInitial}${lastNameInitial}`;
    }
    return '';
  };

  const trigger = (
    <>
      <UserContainer>
        {!isMobile && (
          <UserInfoContainer>
            <UserName>{user.name}</UserName>
            <DescriptionText>{user.email}</DescriptionText>
          </UserInfoContainer>
        )}
        {!isMobile && <Icon name={'angle down'} color="blue" size="large" />}
        {isMobile && (
          <Avatar flex={0.3} circular color={'blue'} size="big">
            {getInitials(user?.name || user?.estate?.name || '')}
          </Avatar>
        )}
      </UserContainer>
    </>
  );

  const CustomHeader = (): JSX.Element => {
    // If the currentPage exists and has a title
    if (currentPage && currentPage.title) {
      // If the user is an estate and the current page is the proposals listing
      if (user && user.estate && currentPage.name === 'insurance[proposals]') {
        return (
          <Grid.Column style={{ flex: '0.98', paddingTop: '1em' }}>
            <NavbarText>
              <span>{currentPage.title}</span>
            </NavbarText>
            <p style={{ paddingLeft: '2em', paddingTop: '0.5em' }}>
              Dia de fechamento de lote: {user.estate.batchClosingDay}
            </p>
          </Grid.Column>
        );
      } else {
        return (
          <NavbarText>
            <span>{currentPage.title}</span>
          </NavbarText>
        );
      }
    } else {
      return <NavbarText />;
    }
  };

  const renderDropdownMenu = () => {
    return (
      <Dropdown.Menu style={dropdownMenuStyles}>
        <CustomDropdownItemContainer hasBottomBorder disabled>
          <section>
            <UserName>{user?.name || user.estate?.name}</UserName>
            <DescriptionText weight={400}>
              Permissão/{translateUserRole(user.role)}
            </DescriptionText>
          </section>
          <section>
            <DescriptionText weight={400}>Contato</DescriptionText>
            <ContentText>{user.email}</ContentText>
          </section>
          {(user.estate?.name || user.franchise?.name) && (
            <section>
              <DescriptionText weight={400}>Filiação</DescriptionText>
              <ContentText>{user.estate?.name || user.franchise?.name}</ContentText>
            </section>
          )}
          {user?.lastLoginAt && (
            <section>
              <DescriptionText weight={400}>
                Último acesso: {DateToStringDateTime(user?.lastLoginAt)}
              </DescriptionText>
            </section>
          )}
        </CustomDropdownItemContainer>
        <CustomDropdownItemContainer
          align="row"
          text="Dados cadastrais"
          icon="file alternate outline"
          hasBottomBorder
        />
        <CustomDropdownItemContainer
          align="row"
          text="Sair"
          icon="sign-out"
          onClick={logoutHandler}
        />
      </Dropdown.Menu>
    );
  };

  const MobileHeader = (): JSX.Element => {
    return (
      <MobileHeaderContainer>
        <Icon name="bars" color="blue" size="large" onClick={props.handleToggleSidebar} />
        <Logo src={logo} />
        <Dropdown trigger={trigger} icon={null} direction="left">
          {renderDropdownMenu()}
        </Dropdown>
      </MobileHeaderContainer>
    );
  };

  return (
    <>
      {!isMobile && (
        <NavbarContainer>
          <CustomHeader />
          <DropdownContainer>
            <Dropdown trigger={trigger} icon={null} direction="left">
              {renderDropdownMenu()}
            </Dropdown>
            <UnifiedApps />
          </DropdownContainer>
        </NavbarContainer>
      )}
      {isMobile && <MobileHeader />}
    </>
  );
};

export default connect(mapState, mapDispatch)(Navbar);
