import React, { SyntheticEvent, useState, useEffect } from "react";
import { Grid, Dropdown } from "semantic-ui-react";
import { Field } from "formik";
import { FieldLabel } from "../../../styles";
import { ReportFormProps } from "../ReportForm";
import SectionLabel from "../../../components/SectionLabel";
import { CustoDropDownItemProps } from "../../../components/types";
import { dangerNotification } from "../../../services/notification";
import Toggle from "../../../components/Toggle";
import { SmallData, SpecificInfosWrapper } from "../styles";
import Input from "../../../components/Input";
import { DATE_INPUT_MASK, formatAmount } from "../../../services/masks";

import { iRootDispatch, iRootState } from "../../../store";

import Button from "../../../components/Button";
import AppMaskedInput from "../../../components/MaskedInput";
import CustomDatePicker from "../../../components/CustomDatePicker";

import { getUsers } from '../../../services/broker';
import { UsersToCustomDropDownOptions } from '../../../util';
import { UserForUpdateEstateForm } from "../../estates-list/types";
import { BondInsuranceAnalysisStatusEnum } from "../../../enums/bond-insurance-analysis-status.enum";
import { list as listInsurer } from "../../../services/insurer";

import { AvailableBrokersAndEstatesState } from "../../../store/types/temp-types";
import { InsurerState } from "../../../types/InsurerState";
import { BondInsuranceHiredDataStatusEnum } from "../../../enums/bond-insurance-hired-data-status.enum";

interface DropdownOptions {
  index: number;
  value: number | string;
  text: string;
}

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user,
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (
    availableBrokers: AvailableBrokersAndEstatesState
  ) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(
      availableBrokers
    ),
});

const statusAnalysis: DropdownOptions[] = [
  {
    index: 0,
    value: 'ALL',
    text: 'Todos'
  },
  {
    index: 1,
    value: BondInsuranceAnalysisStatusEnum.approved,
    text: 'Aprovado'
  },
  {
    index: 2,
    value: BondInsuranceAnalysisStatusEnum.analysis,
    text: 'Em Análise'
  },
  {
    index: 3,
    value: BondInsuranceAnalysisStatusEnum.waiting,
    text: 'Em Espera'
  },
  {
    index: 4,
    value: BondInsuranceAnalysisStatusEnum.proposal,
    text: 'Em Proposta'
  },
  {
    index: 5,
    value: BondInsuranceAnalysisStatusEnum.pending,
    text: 'Pendenciado'
  },
  {
    index: 6,
    value: BondInsuranceAnalysisStatusEnum.rejected,
    text: 'Recusado'
  },
  {
    index: 7,
    value: BondInsuranceAnalysisStatusEnum.biometry,
    text: 'Biometria'
  },
];

const statusProposal: DropdownOptions[] = [
  {
    index: 0,
    value: 'Todos',
    text: 'Todos'
  },
  {
    index: 1,
    value: 'Contratação em Andamento',
    text: 'Contratação em Andamento'
  },
  {
    index: 2,
    value: 'Erro na Contratação',
    text: 'Erro na Contratação'
  }
];

const statusPolicy: DropdownOptions[] = [
  {
    index: 0,
    value: "ALL",
    text: "Todos"
  },
  // {
  //   index: 1,
  //   value: "WAITING",
  //   text: "Aguardando Ação"
  // },
  // {
  //   index: 2,
  //   value: "INSURER_HIRE_RELATED_INFORMATION_PENDING",
  //   text: "Informações de Contratação Enviadas para a Seguradora"
  // },
  // {
  //   index: 3,
  //   value: "HIRE_RELATED_INFORMATION_ERROR",
  //   text: "Erro no Envio de Informações de Contratação"
  // },
  // {
  //   index: 4,
  //   value: "INSURER_READY_TO_HIRE",
  //   text: "Pronto para Contratar"
  // },
  // {
  //   index: 5,
  //   value: "INSURER_HIRE_PENDING",
  //   text: "Contratação em Andamento"
  // },
  // {
  //   index: 6,
  //   value: "HIRE_ERROR",
  //   text: "Erro na Contratação"
  // },
  {
    index: 7,
    value: BondInsuranceHiredDataStatusEnum.insurerPolicyPending,
    text: "Emissão de Apólice Pendente pela Seguradora"
  },
  {
    index: 8,
    value: BondInsuranceHiredDataStatusEnum.policyError,
    text: "Erro na Emissão de Apólice"
  },
  {
    index: 9,
    value: BondInsuranceHiredDataStatusEnum.insurerPolicyCreated,
    text: "Emitida"
  },
  {
    index: 10,
    value: BondInsuranceHiredDataStatusEnum.underRenewal,
    text: 'Em renovação'
  },
  {
    index: 11,
    value: BondInsuranceHiredDataStatusEnum.renewedCreated,
    text: 'Renovada'
  },
  {
    index: 12,
    value: BondInsuranceHiredDataStatusEnum.notRenewed,
    text: 'Não Renovada'
  },
  {
    index: 13,
    value: BondInsuranceHiredDataStatusEnum.overdue,
    text: 'Vencida'
  },
  {
    index: 14,
    value: BondInsuranceHiredDataStatusEnum.overdueIssued,
    text: 'Vencida Emitida'
  },
  {
    index: 15,
    value: BondInsuranceHiredDataStatusEnum.expired,
    text: 'Expirada'
  }
  // {
  //   index: 16,
  //   value: "CANCELLED",
  //   text: "Cancelada"
  // }
];

export const ReportData = (props: ReportFormProps) => {
  const { availableBrokersAndEstates, setFieldValue, history } = props;

  const [analysisSelected, setAnalysisSelected] = useState<boolean>(false);
  const [proposalSelected, setProposalSelected] = useState<boolean>(false);
  const [policySelected, setPolicySelected] = useState<boolean>(false);
  const insurers: InsurerState[] | null =
    props.availableBrokersAndEstates.bondInsurers.map((insurer: any) => insurer.value);

  const selectableInsurers = insurers.map((insurer) => ({
    index: insurer.id,
    value: insurer.id,
    text:  insurer.name,
  }));

  const brokerId = props.availableBrokersAndEstates.brokers.map(broker => broker.value.id)[0];

  const [selectedInsurer, setSelectedInsurer] = useState<string>('')
  const [selectedEstate, setSelectedEstate] = useState<string>('');
  const [selectedManager, setSelectedManager] = useState<string>('');

  const [isAnalystsLoading, setIsAnalystsLoading] = useState(false);
  const [isEstatesLoading, setIsEstatesLoading] = useState(false);
  const [isInsurersLoading, setIsInsurersLoading] = useState(false);

  const [accountManagerOptions, setAccountManagerOptions] = useState<CustoDropDownItemProps[]>([]);

  const [analysisStartDate, setAnalysisStartDate] = useState<Date | string>('');
  const [analysisEndDate, setAnalysisEndDate] = useState<Date | string>('');

  const [proposalStartDate, setProposalStartDate] = useState<Date | string>('');
  const [proposalEndDate, setProposalEndDate] = useState<Date | string>('');

  const [policyStartDate, setPolicyStartDate] = useState<Date | string>('');
  const [policyEndDate, setPolicyEndDate] = useState<Date | string>('');

  const [analysisStatus, setAnalysisStatus] = useState<string>('Todos');
  const [proposalStatus, setProposalStatus] = useState<string>('Todos');
  const [policyStatus, setPolicyStatus] = useState<string>('Todos');

  useEffect(() => {
    if (insurers.length !== availableBrokersAndEstates.insurers.length) {
      setIsEstatesLoading(true);
    } else {
      setIsEstatesLoading(false);
    }
  }, [insurers])

  const estates = availableBrokersAndEstates.estates.map(estate => {
    const response = {
      index: estate.key,
      value: estate.value.id,
      text: estate.text
    };

    return response;
  });

  useEffect(() => {
    if (estates.length !== availableBrokersAndEstates.estates.length) {
      setIsEstatesLoading(true);
    } else {
      setIsEstatesLoading(false);
    }
  }, [estates])

  useEffect(() => {
    handleChangeDate('analysis.startDate', props.initialValues.analysis.startDate);
    handleChangeDate('analysis.endDate', props.initialValues.analysis.endDate);

    handleChangeDate('proposal.startDate', props.initialValues.proposal.startDate);
    handleChangeDate('proposal.endDate', props.initialValues.proposal.endDate);

    handleChangeDate('policy.startDate', props.initialValues.policy.startDate);
    handleChangeDate('policy.endDate', props.initialValues.policy.endDate);
  }, []);

  const handleChangeDate = (field: string, data: any) => {
    if (data && typeof data !== 'string') {
      const newDate = data.toISOString().substring(0, 10);

      setFieldValue(field, newDate);
    } else {
      setFieldValue(field, data);
    }
  }

  const getAccountManagers = async () => {
    setIsAnalystsLoading(true);
    getUsers(brokerId, [{ isDropdown: true}]).then((result) => {
      const convertedValues: UserForUpdateEstateForm[] = result.map((user: any) => {
        const userForForm: UserForUpdateEstateForm = {
          id: user.id,
          name: user.name
        }

        return userForForm
      })

      const analystsOptions = UsersToCustomDropDownOptions(false, convertedValues);
      setAccountManagerOptions(analystsOptions);
    }).catch((error) => {
      dangerNotification('Oops...', 'Não foi possível encontrar os analistas disponíveis')
    }).finally(() => {
      setIsAnalystsLoading(false)
    });
  }

  useEffect(() => {
    //getInsurers();
    getAccountManagers();
  }, [])

  const handleToggle = (e: SyntheticEvent, value: any) => {
    switch (value.name) {
      case 'analysis':
        setAnalysisSelected(prevState => !prevState);
        setFieldValue('isAnalysisSelected', !analysisSelected);
        break;
      case 'proposals':
        setProposalSelected(prevState => !prevState);
        setFieldValue('isProposalSelected', !proposalSelected);
        break;
      case 'policy':
        setPolicySelected(prevState => !prevState);
        setFieldValue('isPolicySelected', !policySelected);
        break;
    }
  };

  const handleChangeStatus = (e: SyntheticEvent, data: any, list: DropdownOptions[]) => {
    list.map(option => {
      if (option.value === data.value) {
        setFieldValue(data.name, option.value);
        if (data.name === 'proposal.status') {
          setFieldValue(data.name, String(option.index));
        }
      }
    })
  }

  const handleClearStates = () => {
    history.push('reports-form');
  }

  return (
    <>
      <SectionLabel text="SELECIONE AS ETAPAS QUE DESEJA GERAR RELATÓRIO:" />
      <Grid.Row>
        <Grid.Column width={4} >
          <FieldLabel style={{ paddingRight: '16px' }}>ANÁLISES</FieldLabel>
          <Toggle
            name='analysis'
            id='analysis'
            style={{ height: "25%", flex: "none" }}
            onChange={handleToggle}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <FieldLabel style={{ paddingRight: '16px' }}>PROPOSTAS</FieldLabel>
          <Toggle
            name='proposals'
            id='proposals'
            style={{ height: "25%", flex: "none" }}
            onChange={handleToggle}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <FieldLabel style={{ paddingRight: '16px' }}>APÓLICES</FieldLabel>
          <Toggle
            name='policy'
            id='policy'
            style={{ height: "25%", flex: "none" }}
            onChange={handleToggle}
          />
        </Grid.Column>
        <Grid.Column width={4} textAlign='right' >
          <Button
            content="Limpar"
            labelPosition='left'
            icon='erase'
            onClick={handleClearStates}
            className="ui button"
          />
        </Grid.Column>
      </Grid.Row>

      <SectionLabel text="GERAL" />

      <Grid.Row>
        <Grid.Column width={4}>
          <FieldLabel>Imobiliária:</FieldLabel>
          <div style={{ width: "100%" }}>
            <Dropdown
              loading={isEstatesLoading}
              style={{ width: "100%" }}
              value={selectedEstate}
              name={`estate`}
              options={estates || []}
              placeholder={"Selecionar..."}
              search
              selection
              onChange={(e, data) => {
                setFieldValue('general.estate', data.value);
                setSelectedEstate(String(data.value));
              }}
              clearable
            />
          </div>
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>Seguradora:</FieldLabel>
          <div style={{ width: "100%" }}>
            <Dropdown
              loading={isInsurersLoading}
              style={{ width: "100%" }}
              name={`insurer`}
              value={selectedInsurer}
              options={selectableInsurers || []}
              placeholder={"Selecionar..."}
              search
              selection
              onChange={(e, data) => {
                setFieldValue('general.insurer', data.value);
                setSelectedInsurer(String(data.value));
              }}
              clearable
            />
          </div>
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>Gestor de Contas:</FieldLabel>
          <div style={{ width: "100%" }}>
            <Dropdown
              name="accountManager"
              loading={isAnalystsLoading}
              style={{ width: "100%" }}
              value={selectedManager}
              search
              clearable
              placeholder={'Selecionar...'}
              options={accountManagerOptions}
              onChange={(e, data) => {
                setFieldValue('general.accountManager', data.value);
                setSelectedManager(String(data.value));
              }}
              component={Dropdown}
              selection
            />
          </div>
        </Grid.Column>
      </Grid.Row>

      {analysisSelected &&
        <>
          <SectionLabel text="ANÁLISES" />
          <Grid.Row style={{ marginLeft: '20px' }}>
            <SpecificInfosWrapper>
              <Grid.Row>
                <Grid.Column width={6}>
                  <div style={{ paddingBottom: '20px' }}>
                    <FieldLabel>STATUS:</FieldLabel>
                  </div>
                  <div style={{ width: "100%", paddingRight: '20px' }}>
                    <Dropdown
                      style={{ width: "100%" }}
                      name="analysis.status"
                      options={statusAnalysis || []}
                      defaultValue={statusAnalysis[0].value}
                      placeholder={"Selecionar..."}
                      search
                      selection
                      onChange={(e, data) => {
                        setAnalysisStatus(String(data.value));
                        handleChangeStatus(e, data, statusAnalysis);
                      }}
                      clearable
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={8}>
                  <FieldLabel style={{ paddingRight: '16px' }}>PERÍODO:</FieldLabel>
                  <SpecificInfosWrapper>

                    <SmallData>
                      <FieldLabel>Data Inicial:</FieldLabel>
                      <Field
                        // defaultValue={reportFormInitialValues.analysis.startDate}
                        maxDate={new Date()}
                        name='analysisStartDate'
                        selected={
                          analysisStartDate ? analysisStartDate : props.initialValues.analysis.startDate
                        }
                        onChange={(date: Date, e: SyntheticEvent) => {
                          handleChangeDate('analysis.startDate', date);
                          setAnalysisStartDate(date);
                        }}
                        component={CustomDatePicker}
                        customInput={
                          <AppMaskedInput
                            mask={DATE_INPUT_MASK}
                          />
                        }
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </SmallData>

                    <SmallData>
                      <FieldLabel>Data Final:</FieldLabel>
                      <Field
                        maxDate={new Date()}
                        name='analysisEndDate'
                        selected={
                          analysisEndDate ? analysisEndDate : props.initialValues.analysis.endDate
                        }
                        onChange={(date: Date, e: SyntheticEvent) => {
                          handleChangeDate('analysis.endDate', date);
                          setAnalysisEndDate(date);
                        }}
                        component={CustomDatePicker}
                        customInput={
                          <AppMaskedInput
                            mask={DATE_INPUT_MASK}
                          />
                        }
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </SmallData>
                  </SpecificInfosWrapper>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={2}>
                  <FieldLabel style={{ paddingRight: '16px' }}>VALOR DA PARCELA:</FieldLabel>
                  <SpecificInfosWrapper>

                    <SmallData>
                      <FieldLabel>
                        Valor Inicial
                      </FieldLabel>
                      <Field
                        name="analysis.initialValue"
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, formatAmount(data.value));
                        }}
                        fluid
                      />
                    </SmallData>


                    <SmallData>
                      <FieldLabel>
                        Valor Final
                      </FieldLabel>
                      <Field
                        name="analysis.finalValue"
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, formatAmount(data.value));
                        }}
                        fluid
                      />
                    </SmallData>
                  </SpecificInfosWrapper>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row verticalAlign={"middle"}>
                <FieldLabel style={{ paddingRight: '16px' }}>A EXPIRAR:</FieldLabel>
                <Grid.Column width={2}>
                  <SpecificInfosWrapper>
                    <SmallData>
                      <FieldLabel>
                        Em dias
                      </FieldLabel>
                      <Field
                        name="analysis.expiresInDays"
                        component={Input}
                        type='number'
                        min='0'
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, data.value);
                        }}
                        fluid
                      />
                    </SmallData>
                  </SpecificInfosWrapper>
                </Grid.Column>
              </Grid.Row>
            </SpecificInfosWrapper>
          </Grid.Row>
        </>
      }

      {proposalSelected &&
        <>
          <SectionLabel text="PROPOSTAS" />
          <Grid.Row style={{ marginLeft: '20px' }}>
            <SpecificInfosWrapper>
              <Grid.Row>
                <Grid.Column width={4}>
                  <div style={{ paddingBottom: '20px' }}>
                    <FieldLabel>STATUS:</FieldLabel>
                  </div>

                  <div style={{ width: "100%", paddingRight: '20px' }}>
                    <Dropdown
                      style={{ width: "100%" }}
                      name="proposal.status"
                      options={statusProposal || []}
                      defaultValue={statusProposal[0].value}
                      placeholder={"Selecionar..."}
                      search
                      selection
                      onChange={(e, data) => {
                        setProposalStatus(String(data.value));
                        handleChangeStatus(e, data, statusProposal);
                      }}
                      clearable
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={8}>
                  <FieldLabel style={{ paddingRight: '16px' }}>PERÍODO:</FieldLabel>
                  <SpecificInfosWrapper>

                    <SmallData>
                      <FieldLabel>Data Inicial:</FieldLabel>
                      <Field
                        maxDate={new Date()}
                        name='proposalStartDate'
                        selected={
                          proposalStartDate ? proposalStartDate : props.initialValues.proposal.startDate
                        }
                        onChange={(date: Date, e: SyntheticEvent) => {
                          handleChangeDate('proposal.startDate', date);
                          setProposalStartDate(date);
                        }}
                        component={CustomDatePicker}
                        customInput={
                          <AppMaskedInput
                            mask={DATE_INPUT_MASK}
                          />
                        }
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </SmallData>

                    <SmallData>
                      <FieldLabel>Data Final:</FieldLabel>
                      <Field
                        maxDate={new Date()}
                        name='proposalEndDate'
                        selected={
                          proposalEndDate ? proposalEndDate : props.initialValues.proposal.endDate
                        }
                        onChange={(date: Date, e: SyntheticEvent) => {
                          handleChangeDate('proposal.endDate', date);
                          setProposalEndDate(date);
                        }}
                        component={CustomDatePicker}
                        customInput={
                          <AppMaskedInput
                            mask={DATE_INPUT_MASK}
                          />
                        }
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </SmallData>
                  </SpecificInfosWrapper>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={2}>
                  <FieldLabel style={{ paddingRight: '16px' }}>VALOR DA PARCELA:</FieldLabel>
                  <SpecificInfosWrapper>

                    <SmallData>
                      <FieldLabel>
                        Valor Inicial
                      </FieldLabel>
                      <Field
                        name="proposal.initialValue"
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, formatAmount(data.value));
                        }}
                        fluid
                      />
                    </SmallData>


                    <SmallData>
                      <FieldLabel>
                        Valor Final
                      </FieldLabel>
                      <Field
                        name="proposal.finalValue"
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, formatAmount(data.value));
                        }}
                        fluid
                      />
                    </SmallData>
                  </SpecificInfosWrapper>
                </Grid.Column>
              </Grid.Row>
            </SpecificInfosWrapper>
          </Grid.Row>
        </>
      }

      {policySelected &&
        <>
          <SectionLabel text="APÓLICES" />
          <Grid.Row style={{ marginLeft: '20px' }}>
            <SpecificInfosWrapper>
              <Grid.Column width={4}>
                <div style={{ paddingBottom: '20px' }}>
                  <FieldLabel>STATUS:</FieldLabel>
                </div>
                <div style={{ width: "100%", paddingRight: '20px' }}>
                  <Dropdown
                    style={{ width: "100%" }}
                    name="policy.status"
                    defaultValue={statusPolicy[0].value}
                    options={statusPolicy || []}
                    placeholder={"Selecionar..."}
                    search
                    selection
                    onChange={(e, data) => {
                      setPolicyStatus(String(data.value));
                      handleChangeStatus(e, data, statusPolicy);
                    }}
                    clearable
                  />
                </div>
              </Grid.Column>

              <Grid.Row>
                <Grid.Column width={8}>
                  <FieldLabel style={{ paddingRight: '16px' }}>PERÍODO:</FieldLabel>
                  <SpecificInfosWrapper>

                    <SmallData>
                      <FieldLabel>Data Inicial:</FieldLabel>
                      <Field
                        maxDate={new Date()}
                        name='policyStartDate'
                        selected={
                          policyStartDate ? policyStartDate : props.initialValues.policy.startDate
                        }
                        onChange={(date: Date, e: SyntheticEvent) => {
                          handleChangeDate('policy.startDate', date);
                          setPolicyStartDate(date);
                        }}
                        component={CustomDatePicker}
                        customInput={
                          <AppMaskedInput
                            mask={DATE_INPUT_MASK}
                          />
                        }
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </SmallData>

                    <SmallData>
                      <FieldLabel>Data Final:</FieldLabel>
                      <Field
                        maxDate={new Date()}
                        name='policyEndDate'
                        selected={
                          policyEndDate ? policyEndDate : props.initialValues.policy.endDate
                        }
                        onChange={(date: Date, e: SyntheticEvent) => {
                          handleChangeDate('policy.endDate', date);
                          setPolicyEndDate(date);
                        }}
                        component={CustomDatePicker}
                        customInput={
                          <AppMaskedInput
                            mask={DATE_INPUT_MASK}
                          />
                        }
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </SmallData>
                  </SpecificInfosWrapper>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={2}>
                  <FieldLabel style={{ paddingRight: '16px' }}>VALOR DA PARCELA:</FieldLabel>
                  <SpecificInfosWrapper>

                    <SmallData>
                      <FieldLabel>
                        Valor Inicial
                      </FieldLabel>
                      <Field
                        name="policy.initialValue"
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, formatAmount(data.value));
                        }}
                        fluid
                      />
                    </SmallData>


                    <SmallData>
                      <FieldLabel>
                        Valor Final
                      </FieldLabel>
                      <Field
                        name="policy.finalValue"
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, formatAmount(data.value));
                        }}
                        fluid
                      />
                    </SmallData>
                  </SpecificInfosWrapper>
                </Grid.Column>
              </Grid.Row>
            </SpecificInfosWrapper>
          </Grid.Row>
        </>
      }

      <Grid.Row>
      </Grid.Row>
    </>
  );
};
