/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { SyntheticEvent, useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Button,
  Header,
  Icon,
  Modal,
  Loader,
  Dimmer,
  Dropdown,
  Grid,
  InputOnChangeData,
  Input,
  Radio,
  DropdownItemProps,
  Segment,
  Popup,
} from "semantic-ui-react";

import { ReanalysisModalProps } from "../Types/ReanalysisModal.types";
import { Field, FieldArray, Formik } from "formik";

import {
  dangerNotification,
  warningNotification,
} from "../../../../../../../services/notification";
import { DATE_INPUT_MASK, maskCpf, maskCpfOrCnpj, maskMoney, maskPhoneNumber, maskZipCode, unmaskCpfOrCnpj } from "../../../../../../../services/masks";
import { FieldLabel } from "../../../../../../../styles";
import SectionLabel from "../../../../../../../components/SectionLabel";
import { PurposesEnum } from "../../../../../../../enums/purposes.enum";
import {
  UFOptions,
  employmentRelationshipOptions,
  genderOptions,
  legalCommercialResidentialReasonOptions,
  maritalStatusOptions,
  naturalCommercialReasonOptions,
  naturalResidentialReasonOptions,
  validityOptions,
} from "../../../../../../bond-insurance-budget-form/types";
import { AddressService } from "../../../../../../../services/address";
import { PersonTypesEnum } from "../../../../../../../enums/person-types.enum";
import { put } from "../../../../../../../services/request";
import { hasPermission } from "../../../../../../../components/AuthorizationContainer";
import { ActionEnum } from "../../../../../../../enums/authz-action.enum";
import { FeatureEnum } from "../../../../../../../enums/authz-feature.enum";
import OwnDropdown from "../../../../../../../components/Dropdown";
import CustomDatePicker from '../../../../../../../components/CustomDatePicker';
import AppMaskedInput from '../../../../../../../components/MaskedInput';
import { PoliticallyExposedPersonEnum } from '../../../../../../../enums/politically-exposed-person.enum';
import moment from 'moment';
import { BondInsuranceAnalysisStatusEnum } from "../../../../../../../enums/bond-insurance-analysis-status.enum";
import {
  hasNotChanged,
  initialValues,
  makePayload,
  newSympathiseTenants,
  validationSchema
} from '../Utils/reanalysis.util';
import { getDataByDocument } from "../../../../../../../services/documents";
import { validateCNPJ, validateCPF } from "../../../../../../../services/validators";
import { FormikErrorMessage } from "../../../../../../../components/ErrorMessage";

const GeneralReanalysisModal = ({
  bondInsurance,
  nominalTotalPremiumAmount,
  callback,
  close,
  garanttiException = false
}: ReanalysisModalProps) => {
  const [openReanalysisModal, setOpenReanalysisModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reasonsOptions, setReasonsOptions] = useState<Array<DropdownItemProps>>([]);
  const [cepLoading, setCepLoading] = useState<boolean>(false);
  const [documentLoading, setDocumentLoading] = useState<boolean>(false);
  const [userCanReanalyse, setUserCanReanalyse] = useState(false);
  const [allRefused, setAllRefused] = useState(false);

  // const [ownerDocument, setOwnerDocument] = useState<string>('');

  useEffect(() => {
    const has = hasPermission(ActionEnum.reanalyse, FeatureEnum.analysis);
    setUserCanReanalyse(has);
  }, []);

  const updateState = (state: string) => {
    const item = UFOptions.find(uf => (
      uf.key === state
    ));

    return item
  }

  useEffect(() => {
    if (bondInsurance) {
      const refused = bondInsurance.bond_insurance_analysis.every(analysis => analysis.status === BondInsuranceAnalysisStatusEnum.rejected)

      if (!garanttiException) setAllRefused(refused);

      // const doc = bondInsurance.property_owner_natural_person?.document ? bondInsurance.property_owner_natural_person?.document :
      //   (bondInsurance.property_owner_natural_person?.cnpj ? maskCpfOrCnpj(bondInsurance.property_owner_natural_person?.cnpj) :
      //     maskCpfOrCnpj(bondInsurance.property_owner_natural_person?.cpf ?? ''))
      // setOwnerDocument(doc);
    }
  }, [bondInsurance]);

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (
      bondInsurance?.purpose === PurposesEnum.commercial &&
      bondInsurance?.tenant_type === PersonTypesEnum.natural
    ) {
      setReasonsOptions(naturalCommercialReasonOptions);
    } else if (
      bondInsurance?.purpose === PurposesEnum.residential &&
      bondInsurance?.tenant_type === PersonTypesEnum.natural
    ) {
      setReasonsOptions(naturalResidentialReasonOptions);
    } else {
      setReasonsOptions(legalCommercialResidentialReasonOptions);
    }
  }, [bondInsurance]);

  return (
    <div style={{ outline: "none" }}>
      <Formik
        initialValues={initialValues(bondInsurance)}

        onSubmit={async (values, { setSubmitting, setFieldValue, validateForm, setFieldTouched }) => {
          // if (!values.propertyOwner.document) {
          //   return warningNotification('Ops.', 'Por favor informe o documento do Proprietário');
          // }

          // if (!values.propertyOwner.name) {
          //   return warningNotification('Ops.', 'Por favor informe o nome do Proprietário');
          // }

          if (hasNotChanged(bondInsurance, values)) {
            return warningNotification('Ops.', 'A reanálise só será permitida caso algum dado seja alterado');
          }

          if (Number(values.property.rent_value) < 200) {
            return warningNotification("Ops.", "O valor de aluguel mínimo é de R$200,00");
          }

          const payload = makePayload(values, garanttiException);

          try {
            setLoading(true);
            await put("/bond-insurance-analysis/reanalysis", payload);
          } catch (err) {
            console.log(err);
            if ((err as any).message) {
              dangerNotification('Atenção', (err as any).message);
            } else {
              dangerNotification('Opps..', "Algo deu errado");
            }
          } finally {
            setLoading(false);
            callback();
            close();
          }
        }}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          handleChange,
        }) => {
          const onChangeCep = (zipCode: string, isProperty?: boolean, isOwner?: boolean): void => {
            const maskedValue = maskZipCode(zipCode);
            if(!isOwner) {
              setFieldValue("zipCode", maskedValue);
            }
            if (maskedValue.length >= 9) {
              setCepLoading(true);
              isProperty
                ? AddressService.getAddressByZipCode(zipCode)
                  .then((address) => {
                    setFieldValue("city", address.city);
                    setFieldValue("district", address.district);
                    setFieldValue("state", address.state);
                    setFieldValue("street", address.street);
                    setCepLoading(false);
                  })
                  .catch((e) => {
                    console.log("address error:", e);
                  })
                : isOwner ? AddressService.getAddressByZipCode(zipCode)
                  .then((address) => {
                    setFieldValue("propertyOwner.city", address.city);
                    setFieldValue("propertyOwner.district", address.district);
                    setFieldValue("propertyOwner.state", address.state);
                    setFieldValue("propertyOwner.street", address.street);
                    setCepLoading(false);
                  })
                  .catch((e) => {
                    console.log("address error:", e);
                  })
                  : AddressService.getAddressByZipCode(zipCode)
                    .then((address) => {
                      setFieldValue("estate.estateCity", address.city);
                      setFieldValue("estate.estateDistrict", address.district);
                      setFieldValue("estate.estateState", address.state);
                      setFieldValue("estate.estateStreet", address.street);
                      setCepLoading(false);
                    })
                    .catch((e) => {
                      console.log("address error:", e);
                    });
            }
          };

          return (
            <Modal
              closeIcon
              open={openReanalysisModal}
              onClose={() => setOpenReanalysisModal(false)}
              trigger={
                garanttiException ? (
                  <Popup
                    content='Solicitar Reanálise'
                    trigger={
                      <Button
                        disabled={!userCanReanalyse || allRefused}
                        color='blue'
                        icon='sync alternate'
                        onClick={() =>
                          MySwal.fire({
                            title: `Reanálise - Seguro Fiança`,
                            text: "Importante: Aumento do  valor de Aluguel: Será realizada uma reanálise e o STATUS atual poderá ser alterado, inclusive para recusado. O valor da parcela sofrerá alteração.",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Sim, prosseguir para a Reanalise!",
                            cancelButtonText: "Cancelar",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setOpenReanalysisModal(true);
                            } else {
                              Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Para continuar, deve aceitar os termos!",
                              });
                            }
                          })
                        }
                      />
                    }
                  />
                )
                  : (<Button
                    disabled={!userCanReanalyse || allRefused}
                    color='blue'
                  >
                    <Icon name='sync alternate' />
                    Realizar Reanálise
                  </Button>)
              }
              onOpen={() =>
                MySwal.fire({
                  title: `Reanálise - Seguro Fiança`,
                  text: "Importante: Aumento do  valor de Aluguel: Será realizada uma reanálise e o STATUS atual poderá ser alterado, inclusive para recusado. O valor da parcela sofrerá alteração.",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Sim, prosseguir para a Reanalise!",
                  cancelButtonText: "Cancelar",
                }).then((result) => {
                  if (result.isConfirmed) {
                    setOpenReanalysisModal(true);
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Para continuar, deve aceitar os termos!",
                    });
                  }
                })
              }
            >
              <Header
                icon="file alternate outline"
                content={'Reanálise - Seguro Fiança'}
              />
              <Dimmer active={loading}>
                <Loader indeterminate> Registrando reanalise... </Loader>
              </Dimmer>

              <Modal.Content>
                <form>
                  <Grid>
                    <SectionLabel text="INFORMAÇÕES DA LOCAÇÃO" />
                    <Grid.Row columns="equal">
                      <Grid.Column width={5}>
                        {values.purpose === PurposesEnum.residential && (
                          <Radio
                            label='Residencial'
                            name={'purpose'}
                            value={PurposesEnum.residential}
                            checked={true}
                          />
                        )}
                        {values.purpose === PurposesEnum.commercial && (
                          <Radio
                            label='Comercial'
                            name={'bondInsurance.purpose'}
                            value={PurposesEnum.commercial}
                            checked={true}
                          />
                        )}
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={8}>
                        <FieldLabel>MOTIVO DA LOCAÇÃO*</FieldLabel>
                        <Field
                          name={'reason'}
                          value={values.reason}
                          options={naturalResidentialReasonOptions}
                          onChange={(e: SyntheticEvent, data: any) => {
                            setFieldValue('reason', data.value);
                          }}
                          component={Dropdown}
                          selection
                          fluid
                        />
                      </Grid.Column>

                      <Grid.Column width={8}>
                        <FieldLabel>VIGÊNCIA DO CONTRATO DE LOCAÇÃO*</FieldLabel>
                        <Field
                          name={'validity'}
                          value={values.validity}
                          completeValue={true}
                          options={validityOptions}
                          onChange={(e: SyntheticEvent, data: any) => {
                            setFieldValue('validity', data.value);
                          }}
                          component={Dropdown}
                          selection
                          fluid
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <SectionLabel text="ENDEREÇO DO IMÓVEL" />
                    <Grid.Row>
                      <Grid.Column width={4}>
                        <FieldLabel>CEP*</FieldLabel>
                        <Field
                          id={'zipCode'}
                          name={'zipCode'}
                          value={values.zipCode}
                          loading={cepLoading}
                          onChange={(_: any, data: InputOnChangeData) => {
                            onChangeCep(data.value, true);
                            setFieldValue('zipCode', maskZipCode(data.value));
                          }}
                          error={errors.zipCode && touched.zipCode}
                          component={Input}
                          maxLength={9}
                          fluid
                        />
                      </Grid.Column>

                      <Grid.Column width={8}>
                        <FieldLabel>LOGRADOURO*</FieldLabel>
                        <Field
                          id={'street'}
                          name={'street'}
                          value={values.street}
                          onChange={handleChange}
                          component={Input}
                          error={errors.street && touched.street}
                          fluid
                        />
                      </Grid.Column>

                      <Grid.Column width={4}>
                        <FieldLabel>NÚMERO*</FieldLabel>
                        <Field
                          id={'number'}
                          name={'number'}
                          value={values.number}
                          onChange={handleChange}
                          component={Input}
                          error={errors.number && touched.number}
                          fluid
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={5}>
                        <FieldLabel>BAIRRO*</FieldLabel>
                        <Field
                          id={'district'}
                          name={'district'}
                          value={values.district}
                          onChange={handleChange}
                          component={Input}
                          error={errors.district && touched.district}
                          fluid
                        />
                      </Grid.Column>

                      <Grid.Column width={6}>
                        <FieldLabel>CIDADE*</FieldLabel>
                        <Field
                          id={'city'}
                          name={'city'}
                          value={values.city}
                          onChange={handleChange}
                          component={Input}
                          error={errors.city && touched.city}
                          fluid
                        />
                      </Grid.Column>

                      <Grid.Column width={5}>
                        <FieldLabel>UF*</FieldLabel>
                        <Field
                          id={'state'}
                          name={'state'}
                          completeValue={true}
                          value={values.state}
                          options={UFOptions}
                          onChange={(_: any, data: InputOnChangeData) =>
                            setFieldValue('state', data.value)
                          }
                          component={OwnDropdown}
                          selection
                          fluid
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={16}>
                        <FieldLabel>COMPLEMENTO</FieldLabel>
                        <Field
                          id={'complement'}
                          name={'complement'}
                          value={values.complement}
                          onChange={handleChange}
                          component={Input}
                          fluid
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Grid>
                    <SectionLabel text="DADOS DO IMÓVEL" />
                    <Grid.Row columns="equal">
                      <Grid.Column>
                        <FieldLabel>VALOR DO ALUGUEL*</FieldLabel>
                        <Field
                          name={'rentValue'}
                          value={maskMoney(values.rentValue, true, 2)}
                          onChange={(_: any, data: InputOnChangeData) => {
                            const rent = Number(data.value.replace(/\D/g, "") || 0) / 100;
                            setFieldValue('rentValue', rent);
                          }}
                          onBlur={() => setFieldTouched(`rentValue`, true)}
                          component={Input}
                          error={errors.rentValue && touched.rentValue}
                          fluid
                        />
                        <FormikErrorMessage component="div" name={`rentValue`} />
                      </Grid.Column>

                      <Grid.Column>
                        <FieldLabel>VALOR DO CONDOMÍNIO</FieldLabel>
                        <Field
                          name={'condominiumFee'}
                          value={maskMoney(values.condominiumFee, true, 2)}
                          onChange={(_: any, data: InputOnChangeData) => {
                            const condominiumFee = Number(data.value.replace(/\D/g, "") || 0) / 100;
                            setFieldValue('condominiumFee', condominiumFee);
                          }}
                          component={Input}
                          fluid
                        />
                      </Grid.Column>

                      <Grid.Column>
                        <FieldLabel>VALOR DO IPTU</FieldLabel>
                        <Field
                          name={'taxesValue'}
                          value={maskMoney(values.taxesValue, true, 2)}
                          component={Input}
                          onChange={(_: any, data: InputOnChangeData) => {
                            const taxesValue = Number(data.value.replace(/\D/g, "") || 0) / 100;
                            setFieldValue('taxesValue', taxesValue);
                          }}
                          fluid
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns="equal">
                      <Grid.Column>
                        <FieldLabel>VALOR DA ENERGIA</FieldLabel>
                        <Field
                          name={'energyBill'}
                          value={maskMoney(values.energyBill, true, 2)}
                          onChange={(_: any, data: InputOnChangeData) => {
                            const energyBill = Number(data.value.replace(/\D/g, "") || 0) / 100;
                            setFieldValue('energyBill', energyBill);
                          }}
                          component={Input}
                          fluid
                        />
                      </Grid.Column>

                      <Grid.Column>
                        <FieldLabel>VALOR DA ÁGUA</FieldLabel>
                        <Field
                          name={'waterBill'}
                          value={maskMoney(values.waterBill, true, 2)}
                          onChange={(_: any, data: InputOnChangeData) => {
                            const waterBill = Number(data.value.replace(/\D/g, "") || 0) / 100;
                            setFieldValue('waterBill', waterBill);
                          }}
                          component={Input}
                          fluid
                        />
                      </Grid.Column>

                      <Grid.Column>
                        <FieldLabel>VALOR DO GÁS CANALIZADO</FieldLabel>
                        <Field
                          name={'gasBill'}
                          value={maskMoney(values.gasBill, true, 2)}
                          onChange={(_: any, data: InputOnChangeData) => {
                            const gasBill = Number(data.value.replace(/\D/g, "") || 0) / 100;
                            setFieldValue('gasBill', gasBill);
                          }}
                          component={Input}
                          fluid
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  {/* <Grid>
                    <SectionLabel text="DADOS DO PROPRIETÁRIO" />
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <FieldLabel>CPF/CNPJ DO PROPRIETÁRIO</FieldLabel>
                        <Field
                          id={'ownerDocument'}
                          name={'ownerDocument'}
                          loading={documentLoading}
                          value={ownerDocument}
                          onChange={(_: any, data: InputOnChangeData) => {
                            setOwnerDocument(data.value)

                            const document = unmaskCpfOrCnpj(data.value);


                            if (document.length >= 11) {
                              const isCpfValid = validateCPF(document);
                              const isCnpjValid = validateCNPJ(document);

                              if (isCpfValid && document.length === 11) {
                                setFieldValue('propertyOwner.document', maskCpfOrCnpj(data.value));
                                setDocumentLoading(true)
                                getDataByDocument(document)
                                  .then(data => {
                                    setFieldValue('propertyOwner.name', data.name);
                                    setFieldValue('propertyOwner.phone', '');
                                    setFieldValue('propertyOwner.email', '');
                                  })
                                  .catch(e => {
                                    dangerNotification(
                                      'Oops...',
                                      'Não foi possível encontrar dados associados ao documento informado' + e
                                    );
                                  })
                                  .finally(() => {
                                    setFieldValue('propertyOwner.cpf', document);
                                    setFieldValue('propertyOwner.cnpj', null);
                                    setFieldValue('propertyOwner.document', maskCpfOrCnpj(document));
                                    setOwnerDocument(maskCpfOrCnpj(document));
                                    setDocumentLoading(false);
                                  });

                              }

                              if (isCnpjValid && document.length === 14) {
                                setFieldValue('propertyOwner.document', maskCpfOrCnpj(data.value));
                                setDocumentLoading(true)
                                getDataByDocument(document)
                                  .then(data => {
                                    setFieldValue('propertyOwner.name', data.company);
                                    if(data.phone) setFieldValue('propertyOwner.phone', maskPhoneNumber(data.phone));
                                    if(data.email) setFieldValue('propertyOwner.email', data.email);
                                  })
                                  .catch(e => {
                                    dangerNotification(
                                      'Oops...',
                                      'Não foi possível encontrar dados associados ao documento informado'
                                    );
                                  })
                                  .finally(() => {
                                    setFieldValue('propertyOwner.cnpj', document);
                                    setFieldValue('propertyOwner.cpf', null);
                                    setFieldValue('propertyOwner.document', maskCpfOrCnpj(document));
                                    setOwnerDocument(maskCpfOrCnpj(document));
                                    setDocumentLoading(false);
                                  });
                              }
                            }
                          }}
                          component={Input}
                          error={errors.street && touched.street}
                          fluid
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <FieldLabel>NOME DO PROPRIETÁRIO</FieldLabel>
                        <Field
                          id={'propertyOwner.name'}
                          name={'propertyOwner.name'}
                          value={values.propertyOwner?.name}
                          onChange={(_: any, data: InputOnChangeData) => {
                            setFieldValue('propertyOwner.name', data.value);
                          }}
                          component={Input}
                          error={errors.street && touched.street}
                          fluid
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <FieldLabel>EMAIL DO PROPRIETÁRIO</FieldLabel>
                        <Field
                          id={'propertyOwner.email'}
                          name={'propertyOwner.email'}
                          value={values.propertyOwner?.email}
                          onChange={(_: any, data: InputOnChangeData) => {
                            setFieldValue('propertyOwner.email', data.value);
                          }}
                          component={Input}
                          fluid
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <FieldLabel>TELEFONE DO PROPRIETÁRIO</FieldLabel>
                        <Field
                          id={'propertyOwner.phone'}
                          name={'propertyOwner.phone'}
                          value={maskPhoneNumber(values.propertyOwner?.phone ?? '')}
                          onChange={(_: any, data: InputOnChangeData) => {
                            setFieldValue('propertyOwner.phone', data.value);
                          }}
                          component={Input}
                          fluid
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <SectionLabel text="ENDEREÇO DO PROPRIETÁRIO" />
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <FieldLabel>CEP*</FieldLabel>
                        <Field
                          id={'propertyOwner.zip_code'}
                          name={'propertyOwner.zip_code'}
                          value={values.propertyOwner?.zip_code}
                          loading={cepLoading}
                          onChange={(_: any, data: InputOnChangeData) => {
                            onChangeCep(data.value, false, true);
                            setFieldValue('propertyOwner.zip_code', maskZipCode(data.value));
                          }}
                          error={errors.zipCode && touched.zipCode}
                          component={Input}
                          maxLength={9}
                          fluid
                        />
                      </Grid.Column>

                      <Grid.Column width={10}>
                        <FieldLabel>LOGRADOURO*</FieldLabel>
                        <Field
                          id={'propertyOwner.street'}
                          name={'propertyOwner.street'}
                          value={values.propertyOwner?.street}
                          onChange={(_: any, data: InputOnChangeData) => {
                            setFieldValue('propertyOwner.street', data.value);
                          }}
                          component={Input}
                          error={errors.street && touched.street}
                          fluid
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={6}>
                        <FieldLabel>CIDADE*</FieldLabel>
                        <Field
                          id={'propertyOwner.city'}
                          name={'propertyOwner.city'}
                          value={values.propertyOwner?.city}
                          onChange={(_: any, data: InputOnChangeData) => {
                            setFieldValue('propertyOwner.city', data.value);
                          }}
                          component={Input}
                          error={errors.city && touched.city}
                          fluid
                        />
                      </Grid.Column>

                      <Grid.Column width={6}>
                        <FieldLabel>BAIRRO*</FieldLabel>
                        <Field
                          id={'propertyOwner.district'}
                          name={'propertyOwner.district'}
                          value={values.propertyOwner?.district}
                          onChange={(_: any, data: InputOnChangeData) => {
                            setFieldValue('propertyOwner.district', data.value);
                          }}
                          component={Input}
                          error={errors.district && touched.district}
                          fluid
                        />
                      </Grid.Column>

                      <Grid.Column width={4}>
                        <FieldLabel>UF*</FieldLabel>
                        <Field
                          id={'propertyOwner.state'}
                          name={'propertyOwner.state'}
                          completeValue={true}
                          value={values.propertyOwner?.state}
                          options={UFOptions}
                          onChange={(_: any, data: InputOnChangeData) =>
                            setFieldValue('propertyOwner.state', data.value)
                          }
                          component={OwnDropdown}
                          selection
                          fluid
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={4}>
                        <FieldLabel>NÚMERO*</FieldLabel>
                        <Field
                          id={'propertyOwner.number'}
                          name={'propertyOwner.number'}
                          value={values.propertyOwner?.number}
                          onChange={(_: any, data: InputOnChangeData) => {
                            setFieldValue('propertyOwner.number', data.value);
                          }}
                          component={Input}
                          error={errors.number && touched.number}
                          fluid
                        />
                      </Grid.Column>
                      <Grid.Column width={12}>
                        <FieldLabel>COMPLEMENTO</FieldLabel>
                        <Field
                          id={'propertyOwner.complement'}
                          name={'propertyOwner.complement'}
                          value={values.propertyOwner?.complement}
                          onChange={(_: any, data: InputOnChangeData) => {
                            setFieldValue('propertyOwner.complement', data.value);
                          }}
                          component={Input}
                          fluid
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid> */}

                  <Grid>
                    <SectionLabel text="LOCATÁRIO SOLIDÁRIO" />
                  </Grid>

                  <FieldArray name='sympathiseTenants'>
                    {({ remove, push }) => (
                      <>
                        {values.sympathiseTenants?.map((sympathiseTenant, index) => {
                          return (
                            <Segment>
                              <Grid key={index}>
                                <div style={{ flex: 1, textAlign: 'right', padding: '2px 0' }}>
                                  <Button icon color='red' size='mini' onClick={() => remove(index)}>
                                    <Icon name='close' />
                                  </Button>
                                </div>

                                {/* Nome | CPF | Data de nascimento */}
                                <Grid.Row columns='equal'>
                                  <Grid.Column>
                                    <FieldLabel>NOME*</FieldLabel>
                                    <Field
                                      id={`sympathiseTenants.${index}.name`}
                                      name={`sympathiseTenants.${index}.name`}
                                      value={sympathiseTenant.name}
                                      onChange={handleChange}
                                      customInput={<Input />}
                                      component={Input}
                                      error={
                                        errors.sympathiseTenants && errors.sympathiseTenants[index]?.name &&
                                        touched.sympathiseTenants && touched.sympathiseTenants[index]?.name
                                      }
                                      fluid
                                    />
                                  </Grid.Column>

                                  <Grid.Column>
                                    <FieldLabel>CPF*</FieldLabel>
                                    <Field
                                      id={`sympathiseTenants.${index}.cpf`}
                                      name={`sympathiseTenants.${index}.cpf`}
                                      value={maskCpf(sympathiseTenant?.cpf ?? '')}
                                      onChange={handleChange}
                                      component={Input}
                                      error={
                                        errors.sympathiseTenants && errors.sympathiseTenants[index]?.cpf &&
                                        touched.sympathiseTenants && touched.sympathiseTenants[index]?.cpf
                                      }
                                      fluid
                                    />
                                  </Grid.Column>

                                  <Grid.Column>
                                    <FieldLabel>DATA DE NASCIMENTO*</FieldLabel>
                                    <CustomDatePicker
                                      name={`sympathiseTenants.${index}.birthDate`}
                                      selected={sympathiseTenant?.birthDate ? new Date(sympathiseTenant.birthDate) : undefined}
                                      onChange={(date: Date) => {
                                        setFieldValue(`sympathiseTenants.${index}.birthDate`, moment(date).format('YYYY/MM/DD') ?? undefined);
                                      }}
                                      customInput={
                                        <AppMaskedInput
                                          error={
                                            errors.sympathiseTenants && errors.sympathiseTenants[index]?.birthDate &&
                                            touched.sympathiseTenants && touched.sympathiseTenants[index]?.birthDate
                                          }
                                          mask={DATE_INPUT_MASK}
                                        />
                                      }
                                      dateFormat={'dd/MM/yyyy'}
                                      style={{ marginTop: 0 }}
                                    />
                                  </Grid.Column>
                                </Grid.Row>

                                {/* Gênero | Estado Civil | Nacionalidade */}
                                <Grid.Row columns='equal'>
                                  <Grid.Column>
                                    <FieldLabel>GÊNERO*</FieldLabel>
                                    <Field
                                      name={`sympathiseTenants.${index}.gender`}
                                      value={sympathiseTenant.gender}
                                      completeValue={true}
                                      options={genderOptions}
                                      onChange={(e: SyntheticEvent, data: any) => {
                                        setFieldValue(`sympathiseTenants.${index}.gender`, data.value);
                                      }}
                                      component={Dropdown}
                                      selection
                                      error={
                                        errors.sympathiseTenants && errors.sympathiseTenants[index]?.gender &&
                                        touched.sympathiseTenants && touched.sympathiseTenants[index]?.gender
                                      }
                                      fluid
                                    />
                                  </Grid.Column>

                                  <Grid.Column>
                                    <FieldLabel>ESTADO CIVIL*</FieldLabel>
                                    <Field
                                      class={"dropdown"}
                                      autoCorrect="off"
                                      autoCapitalize="none"
                                      spellCheck="false"
                                      autoComplete="off"
                                      search={false}
                                      name={`sympathiseTenants.${index}.maritalStatus`}
                                      value={sympathiseTenant.maritalStatus}
                                      completeValue={true}
                                      placeholder={"Selecionar..."}
                                      options={maritalStatusOptions}
                                      // onChange={handleChange}
                                      onChange={(e: SyntheticEvent, data: any) => {
                                        setFieldValue(`sympathiseTenants.${index}.maritalStatus`, data.value);
                                      }}
                                      component={Dropdown}
                                      selection
                                      error={
                                        errors.sympathiseTenants && errors.sympathiseTenants[index]?.maritalStatus &&
                                        touched.sympathiseTenants && touched.sympathiseTenants[index]?.maritalStatus
                                      }
                                      fluid
                                    />
                                  </Grid.Column>

                                  <Grid.Column>
                                    <FieldLabel>NACIONALIDADE*</FieldLabel>
                                    <Field
                                      id={`sympathiseTenants.${index}.nationality`}
                                      name={`sympathiseTenants.${index}.nationality`}
                                      value={sympathiseTenant.nationality}
                                      onChange={handleChange}
                                      component={Input}
                                      error={
                                        errors.sympathiseTenants && errors.sympathiseTenants[index]?.nationality &&
                                        touched.sympathiseTenants && touched.sympathiseTenants[index]?.nationality
                                      }
                                      fluid
                                    />
                                  </Grid.Column>
                                </Grid.Row>

                                {/* Nome da mãe | Email | Telefone */}
                                <Grid.Row columns='equal'>
                                  <Grid.Column>
                                    <FieldLabel>NOME DA MÃE*</FieldLabel>
                                    <Field
                                      autoCorrect="off"
                                      autoCapitalize="none"
                                      spellCheck="false"
                                      autoComplete="new-password"
                                      id={`sympathiseTenants.${index}.motherName`}
                                      name={`sympathiseTenants.${index}.motherName`}
                                      value={sympathiseTenant.motherName}
                                      onChange={handleChange}
                                      component={Input}
                                      error={
                                        errors.sympathiseTenants && errors.sympathiseTenants[index]?.motherName &&
                                        touched.sympathiseTenants && touched.sympathiseTenants[index]?.motherName
                                      }
                                      fluid
                                    />
                                  </Grid.Column>

                                  <Grid.Column>
                                    <FieldLabel>EMAIL*</FieldLabel>
                                    <Field
                                      id={`sympathiseTenants.${index}.email`}
                                      name={`sympathiseTenants.${index}.email`}
                                      value={sympathiseTenant.email}
                                      onChange={handleChange}
                                      component={Input}
                                      error={
                                        errors.sympathiseTenants && errors.sympathiseTenants[index]?.email &&
                                        touched.sympathiseTenants && touched.sympathiseTenants[index]?.email
                                      }
                                      fluid />
                                  </Grid.Column>

                                  <Grid.Column>
                                    <FieldLabel>TELEFONE*</FieldLabel>
                                    <Field
                                      id={`sympathiseTenants.${index}.phone`}
                                      name={`sympathiseTenants.${index}.phone`}
                                      value={maskPhoneNumber(sympathiseTenant?.phone ?? '')}
                                      onChange={handleChange}
                                      component={Input}
                                      error={
                                        errors.sympathiseTenants && errors.sympathiseTenants[index]?.phone &&
                                        touched.sympathiseTenants && touched.sympathiseTenants[index]?.phone
                                      }
                                      fluid
                                    />
                                  </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                  <Grid.Column width={4}>
                                    <FieldLabel>RESIDENTE?*</FieldLabel>
                                    <Grid.Row>
                                      <Grid.Column width={3} style={{ paddingTop: '10px' }}>
                                        <Radio
                                          style={{ paddingLeft: '0px' }}
                                          label='Sim'
                                          name={`sympathiseTenants.${index}.resident`}
                                          checked={sympathiseTenant.resident}
                                          onChange={(_, data) => {
                                            setFieldValue(`sympathiseTenants.${index}.resident`, true);
                                          }}
                                        />
                                        <Radio
                                          style={{ paddingLeft: '30px' }}
                                          label='Não'
                                          name={`sympathiseTenants.${index}.resident`}
                                          checked={!sympathiseTenant.resident}
                                          onChange={(_, data) => {
                                            setFieldValue(`sympathiseTenants.${index}.resident`, false);
                                          }}
                                        />
                                      </Grid.Column>
                                    </Grid.Row>
                                  </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                  <Grid.Column width={6}>
                                    <FieldLabel paddingTop={true}>
                                      PESSOA POLITICAMENTE EXPOSTA?
                                    </FieldLabel>
                                    <Grid.Row>
                                      <Grid.Column
                                        width={5}
                                        style={{ paddingTop: "10px" }}
                                      >
                                        <Radio
                                          style={{ marginRight: "1rem" }}
                                          label="Sim"
                                          id={`sympathiseTenants.${index}.politicallyExposedPerson.yes`}
                                          name={`sympathiseTenants.${index}.politicallyExposedPerson.yes`}
                                          value={PoliticallyExposedPersonEnum.yes}
                                          checked={
                                            sympathiseTenant.politicallyExposedPerson ===
                                            PoliticallyExposedPersonEnum.yes
                                          }
                                          onChange={(_, data) => {
                                            setFieldValue(
                                              data.name!,
                                              data.value
                                                ? PoliticallyExposedPersonEnum.yes
                                                : PoliticallyExposedPersonEnum.no
                                            );
                                          }}
                                        />

                                        <Radio
                                          style={{ marginRight: "1rem" }}
                                          label="Não"
                                          id={`sympathiseTenants.${index}.politicallyExposedPerson.no`}
                                          name={`sympathiseTenants.${index}.politicallyExposedPerson.no`}
                                          value={PoliticallyExposedPersonEnum.no}
                                          checked={
                                            sympathiseTenant.politicallyExposedPerson ===
                                            PoliticallyExposedPersonEnum.no
                                          }
                                          onChange={(_, data) => {
                                            setFieldValue(
                                              data.name!,
                                              data.value
                                                ? PoliticallyExposedPersonEnum.no
                                                : PoliticallyExposedPersonEnum.no
                                            );
                                          }}
                                        />

                                        <Radio
                                          label="Próximo"
                                          id={`sympathiseTenants.${index}.politicallyExposedPerson.closer`}
                                          name={`sympathiseTenants.${index}.politicallyExposedPerson.closer`}
                                          value={PoliticallyExposedPersonEnum.closeTo}
                                          checked={
                                            sympathiseTenant.politicallyExposedPerson ===
                                            PoliticallyExposedPersonEnum.closeTo
                                          }
                                          onChange={(_, data) => {
                                            setFieldValue(
                                              data.name!,
                                              data.value
                                                ? PoliticallyExposedPersonEnum.closeTo
                                                : PoliticallyExposedPersonEnum.no
                                            );
                                          }}
                                        />
                                      </Grid.Column>
                                    </Grid.Row>
                                  </Grid.Column>
                                </Grid.Row>

                                {sympathiseTenant.politicallyExposedPerson === PoliticallyExposedPersonEnum.closeTo && (
                                  <Grid.Row columns='equal'>
                                    <Grid.Column>
                                      <FieldLabel>NOME DA PESSOA*</FieldLabel>
                                      <Field
                                        autoCorrect="off"
                                        autoCapitalize="none"
                                        spellCheck="false"
                                        autoComplete="new-password"
                                        id={`sympathiseTenants.${index}.closeToPoliticallyExposedPerson.politicallyExposedPersonName`}
                                        name={`sympathiseTenants.${index}.closeToPoliticallyExposedPerson.politicallyExposedPersonName`}
                                        value={
                                          sympathiseTenant?.closeToPoliticallyExposedPerson?.politicallyExposedPersonName
                                        }
                                        onChange={handleChange}
                                        customInput={<Input />}
                                        component={Input}
                                        fluid
                                      />
                                    </Grid.Column>

                                    <Grid.Column>
                                      <FieldLabel>CPF DA PESSOA*</FieldLabel>
                                      <Field
                                        id={`sympathiseTenants.${index}.closeToPoliticallyExposedPerson.politicallyExposedPersonCpf`}
                                        name={`sympathiseTenants.${index}.closeToPoliticallyExposedPerson.politicallyExposedPersonCpf`}
                                        value={
                                          sympathiseTenant?.closeToPoliticallyExposedPerson?.politicallyExposedPersonCpf
                                        }
                                        onChange={handleChange}
                                        component={Input}
                                        fluid
                                      />
                                    </Grid.Column>

                                    <Grid.Column>
                                      <FieldLabel>GRAU DE PARENTESCO*</FieldLabel>
                                      <Field
                                        autoCorrect="off"
                                        autoCapitalize="none"
                                        spellCheck="false"
                                        autoComplete="new-password"
                                        id={`sympathiseTenants.${index}.closeToPoliticallyExposedPerson.degreeOfKinshipToPoliticallyExposedPerson`}
                                        name={`sympathiseTenants.${index}.closeToPoliticallyExposedPerson.degreeOfKinshipToPoliticallyExposedPerson`}
                                        value={
                                          sympathiseTenant.closeToPoliticallyExposedPerson?.degreeOfKinshipToPoliticallyExposedPerson
                                        }
                                        onChange={handleChange}
                                        customInput={<Input />}
                                        component={Input}
                                        fluid
                                      />
                                    </Grid.Column>
                                  </Grid.Row>
                                )}

                                <Grid.Row columns='equal'>
                                  <Grid.Column>
                                    <FieldLabel>VÍNCULO EMPREGATÍCIO*</FieldLabel>
                                    <Field
                                      id={`sympathiseTenants.${index}.employmentRelationship`}
                                      name={`sympathiseTenants.${index}.employmentRelationship`}
                                      value={sympathiseTenant.employmentRelationship}
                                      completeValue={true}
                                      placeholder={'Selecionar...'}
                                      options={employmentRelationshipOptions}
                                      onChange={(_: any, data: InputOnChangeData) =>
                                        setFieldValue(`sympathiseTenants.${index}.employmentRelationship`, data.value)
                                      }
                                      component={OwnDropdown}
                                      selection
                                      error={
                                        errors.sympathiseTenants && errors.sympathiseTenants[index]?.employmentRelationship &&
                                        touched.sympathiseTenants && touched.sympathiseTenants[index]?.employmentRelationship
                                      }
                                      fluid
                                    />
                                  </Grid.Column>

                                  <Grid.Column>
                                    <FieldLabel>PROFISSÃO*</FieldLabel>
                                    <Field
                                      id={`sympathiseTenants.${index}.profession`}
                                      name={`sympathiseTenants.${index}.profession`}
                                      value={sympathiseTenant.profession}
                                      onChange={handleChange}
                                      component={Input}
                                      error={
                                        errors.sympathiseTenants && errors.sympathiseTenants[index]?.profession &&
                                        touched.sympathiseTenants && touched.sympathiseTenants[index]?.profession
                                      }
                                      fluid
                                    />
                                  </Grid.Column>

                                  <Grid.Column width={3}>
                                    <FieldLabel>RENDA MENSAL*</FieldLabel>
                                    <Field
                                      name={`sympathiseTenants.${index}.monthlyFixedIncome`}
                                      value={maskMoney(String(sympathiseTenant.monthlyFixedIncome), true, 2)}
                                      component={Input}
                                      onChange={(_: any, data: InputOnChangeData) => {
                                        const monthlyFixedIncome = Number(data.value.replace(/\D/g, "") || 0) / 100;
                                        setFieldValue(`sympathiseTenants.${index}.monthlyFixedIncome`, monthlyFixedIncome);
                                      }}
                                      fluid
                                    />
                                  </Grid.Column>

                                  <Grid.Column width={3}>
                                    <FieldLabel>OUTRAS RENDAS</FieldLabel>
                                    <Field
                                      name={`sympathiseTenants.${index}.otherIncomes`}
                                      value={maskMoney(String(sympathiseTenant.otherIncomes), true, 2)}
                                      component={Input}
                                      onChange={(_: any, data: InputOnChangeData) => {
                                        const otherIncomes = Number(data.value.replace(/\D/g, "") || 0) / 100;
                                        setFieldValue(`sympathiseTenants.${index}.otherIncomes`, otherIncomes);
                                      }}
                                      fluid
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Segment>
                          )
                        })}

                        <div style={{ paddingTop: '10px' }}>
                          <Button color='green' size='small' onClick={() => push(newSympathiseTenants)}>
                            Adicionar Locatário
                          </Button>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </form>
              </Modal.Content>

              <Modal.Actions>
                <Button color="red" onClick={() => setOpenReanalysisModal(false)}>
                  <Icon name="remove" /> Sair
                </Button>
                <Button color="green" type='submit' onClick={() => handleSubmit()}>
                  <Icon name="checkmark" /> Confirmar
                </Button>
              </Modal.Actions>
            </Modal>
          );
        }}
      </Formik>
    </div>
  );
};
export default GeneralReanalysisModal;
