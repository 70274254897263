import React, { useEffect, useState } from "react";
import { Loader, Modal, Dimmer } from "semantic-ui-react";
import { SelectableModalProps } from "../../../components/types";
import FollowUp from "../../../components/FollowUp";
import { getMessages } from "../../../services/followup";
import MessageSender from "../../../components/MessageSender";
import { maskCpfOrCnpj } from "../../../services/masks";
import styled from "styled-components";
import moment from "moment-timezone";

const InfoContainer = styled.div`
  width: 100%;

  background-color: #f7f0eb;
  padding: 1rem;
  border-radius: 6px;

  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.35rem;

    > div {
      flex: 1;
    }
  }
`;

export type FollowUpModalProps = {
  onClose: () => any;
  onOpen: () => any;
  // setUnread: (a: number) => any;
  // unread: number;
  id: string;
  isBlocked?: boolean;
  claim: any;
} & SelectableModalProps;

const FollowUpModal = (props: FollowUpModalProps) => {
  const { header, onClose, onOpen, isOpen, id, claim } = props; // , setUnread, unread
  const [messages, setMessages] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const street = claim.claimProperty.street || null;
  const number = claim.claimProperty.number || null;
  const complement = claim.claimProperty.complement || null;
  const city = claim.claimProperty.city || null;
  const uf = claim.claimProperty.state || null;

  const address = `${street ? street : ""}${number ? ", " + number : ""}${
    complement ? " " + complement : ""
  }${city ? " - " + city : ""}${uf ? " - " + uf : ""}`;

  useEffect(() => {
    getMessages(id).then((response) => {
      setMessages([...response]);
      setIsLoading((prevState) => !prevState);
    });
  }, []);

  return (
    <>
      <Modal
        style={{ width: "50%", height: "90vh" }}
        open={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        closeIcon
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>
          {isLoading ? (
            <Dimmer active={isLoading} inverted>
              <Loader content="Carregando mensagens..." />
            </Dimmer>
          ) : (
            <>
              <InfoContainer>
                <div>
                  <div>
                    <strong>Inquilino: </strong>
                    <span>
                      {claim.claimTenant.fullName} (
                      {maskCpfOrCnpj(claim.claimTenant.document)})
                    </span>
                  </div>
                </div>
                <div>
                  <div>
                    <strong>Imóvel: </strong>
                    <span>{address}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <strong>Imobiliária: </strong>
                    <span>{claim.estate.name}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <strong>Data de Abertura: </strong>
                    <span>
                      {moment(claim.createdAt).format("DD/MM/YYYY")}
                    </span>{" "}
                    {/* TODO: TROCAR P/ O CAMPO DE DATA DE ABERTURA QUE DEVERÁ SER CRIADO */}
                  </div>
                  {/* <div>
                        <strong>Data de Criação: </strong>
                        <span>{moment(claim.createdAt).format('DD/MM/YYYY - HH:mm:ss')}</span>
                      </div>
                      <div>
                        <strong>Data de Atualização: </strong>
                        <span style={{color: Boolean(claim.updatedAt) ? 'default' : '#aeaeae'}}>{claim.updatedAt ? moment(claim.updatedAt).format('DD/MM/YYYY - HH:mm:ss') : 'Não houve atualização'}</span>
                      </div> */}
                </div>
              </InfoContainer>
              {messages.length > 0 ? (
                <FollowUp
                  messages={messages}
                  id={id}
                  isBlocked={props.isBlocked}
                /> // setUnread={setUnread} unread={unread}
              ) : (
                <MessageSender id={id} isBlocked={props.isBlocked} />
              )}
            </>
          )}
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    </>
  );
};

export default FollowUpModal;
