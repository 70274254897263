import React, { useEffect, useState } from 'react';
import { BondInsurance } from '../../../types/BondInsurance';
import PrintableModal from '../../../components/PrintableModal';
import {
  Button,
  Confirm,
  Dimmer,
  Grid,
  Loader,
  Message,
  Segment
} from 'semantic-ui-react';
import { BondInsuranceMainInfoDetails } from './BondInsuranceMainInfoDetails';
import { BondInsurancePropertyDetails } from './BondInsurancePropertyDetails';
import SectionLabel from '../../../components/SectionLabel';
import { PersonTypesEnum } from '../../../enums/person-types.enum';
import { BondInsuranceCancellationDetails } from './BondInsuranceCancellationDetails';
import { BondInsuranceNaturalTenantDetails } from './BondInsuranceNaturalTenantDetails';
import { BondInsuranceLegalTenantDetails } from './BondInsuranceLegalTenantDetails';
import { BondInsuranceForGetBondInsurancesResponseCamelCaseDTO } from '../../../dtos/bond-insurance-list/get-bond-insurances-response.dto';
import { GetCanceledBondInsurancesResponseDTO } from '../../../dtos/canceled-bond-insurance/get-canceled-bond-insurances-response.dto';
import { getCanceledBondInsuranceByID } from '../../../services/canceled-bond-insurance';
import { AnalysisDetailsTableForm } from './AnalysisDetails';
import { BondInsuranceAnalysis } from '../../../types/BondInsuranceAnalysis';
import NotificationEmailsModal from './NotificationEmails/Modal';
import moment from 'moment-timezone';
import { uncancel } from '../../../services/canceled-bond-insurance';
import { dangerNotification, successNotification } from '../../../services/notification';

import { connect } from 'react-redux';
import { iRootDispatch, iRootState } from '../../../store';

import { useHistory } from 'react-router-dom';
import { UserRolesEnum } from '../../../enums/user-roles.enum';
import GenericLogModal from '../../../components/GenericLogModal/LogModal';

type UserProps = {
  id: string;
  name: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapState = (state: iRootState) => ({
  user: state.user
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatch = (dispatch: iRootDispatch) => ({});

export type Props = {
  canceledBondInsurance?: GetCanceledBondInsurancesResponseDTO;
  bondInsurance?: BondInsuranceForGetBondInsurancesResponseCamelCaseDTO;
  isOpen: boolean;
  id: string;
  bondInsuranceId: string;
  changeStatus: (canceledId: string, status: string) => void;
  onClose: React.ReactEventHandler<{}>;
} & ReturnType<typeof mapState>;

const BondInsuranceDetailsComponent = (props: Props) => {
  const [cancelledBondInsurance, setCancelledBondInsurance] = useState<any>(null);
  const [statusWasCanceled, setStatusWasCanceled] = useState<string>('');
  const [isReady, setIsReady] = useState<boolean>(false);
  const [analysis, setAnalysis] = useState<BondInsuranceAnalysis[]>([]);

  const { user, isOpen, onClose, id, changeStatus } = props;
  const bondInsurance = cancelledBondInsurance
    ? cancelledBondInsurance.bondInsurance
    : null;

  const [openEmailsModal, setOpenEmailsModal] = useState(false);

  const [isUncancelingLoading, setIsUncancelingLoadingLoading] = useState<boolean>(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);

  const setIsLoading = (isLoading: boolean) => {
    setIsReady(isLoading);
  };

  const history = useHistory();

  const getData = async () => {
    setIsReady(false);
    getCanceledBondInsuranceByID(id)
      .then(res => {
        const resBondInsurance = res.bondInsurance;
        const bondInsuranceAnalisys = (resBondInsurance as any).bondInsuranceAnalysis;
        setCancelledBondInsurance(res);
        setStatusWasCanceled(
          resBondInsurance?.bondInsuranceHiredData === null
            ? 'EM ANÁLISE'
            : resBondInsurance?.bondInsuranceHiredData.insurerPolicyId !== null
            ? 'APÓLICE EMITIDA'
            : 'EM PROPOSTA'
        );
        setAnalysis(bondInsuranceAnalisys);
        setIsReady(true);
        changeStatus(id, res.status);
      })
      .catch(err => {
        console.error({ err });
      });
  };

  useEffect(() => {
    if (!!id.length) {
      getData();
    }
  }, [id]);

  useEffect(() => {
    if (!cancelledBondInsurance) {
      setIsReady(false);
    }
  }, [cancelledBondInsurance]);

  const closeModal = (e: any) => {
    setCancelledBondInsurance(null);
    onClose(e);
  };

  const isUncancelable = (): boolean => {
    let isAble = false;

    if (user.role.includes('BROKER')) {
      if (
        statusWasCanceled !== 'APÓLICE EMITIDA' &&
        statusWasCanceled !== 'EM PROPOSTA'
      ) {
        const analysisAble =
          cancelledBondInsurance.bondInsurance.bondInsuranceAnalysis.find(
            (analysis: any) => {
              const a = moment(analysis.createdAt).add(1, 'days');
              const b = moment();

              const underThrityDays = b.diff(a, 'days') <= 30;

              return !!underThrityDays;
            }
          );

        isAble = !!analysisAble;
      } else {
        isAble = false;
      }
    } else {
      isAble = false;
    }

    return isAble;
  };

  async function uncancelBondInsurance(canceledBondInsuranceId: string) {
    setIsUncancelingLoadingLoading(true);
    const uncancelling = await uncancel(canceledBondInsuranceId, user.id);

    if (uncancelling) {
      successNotification('Sucesso!', 'Voltou para análise com sucesso!');
      history.push('/admin/canceled-bond-insurances');
    } else {
      dangerNotification('Ooops!', 'Erro ao Voltar para Análise\nContate o Suporte');
    }
    setIsUncancelingLoadingLoading(false);
  }

  return (
    <>
      <PrintableModal
        isOpen={isOpen}
        onClose={closeModal}
        header={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            Seguro Fiança Cancelado
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              {user.role === UserRolesEnum.brokerAdmin && (
                <GenericLogModal
                  createdAt={cancelledBondInsurance?.bondInsurance.createdAt}
                  updatedAt={
                    cancelledBondInsurance?.updatedAt ||
                    cancelledBondInsurance?.bondInsurance?.updatedAt ||
                    cancelledBondInsurance?.createdAt
                  }
                  relatedIds={[
                    cancelledBondInsurance?.bondInsurance.id,
                    cancelledBondInsurance?.id,
                    cancelledBondInsurance?.bondInsurance?.bondInsuranceHiredData?.id
                  ]}
                />
              )}
            </div>
          </div>
        }
        bondInsuranceId={cancelledBondInsurance?.bondInsurance.id}
        content={
          <Grid>
            <Dimmer active={!isReady}>
              <Loader content={'Carregando dados'} />
            </Dimmer>

            {cancelledBondInsurance !== null && (
              <>
                {analysis.length > 0 && (
                  <Segment
                    style={{
                      width: '100%',
                      backgroundColor: '#f7f7f7',
                      boxShadow: '0px 0px 17px 7px rgba(0,0,0,0.5)'
                    }}
                  >
                    <AnalysisDetailsTableForm
                      isCancelled
                      analysisDetails={analysis}
                      setIsLoading={setIsLoading}
                      cancelledBondInsurance={cancelledBondInsurance}
                    />

                    {(cancelledBondInsurance.observations ||
                      cancelledBondInsurance.confirmObservations) && (
                      <Grid padded>
                        <Grid.Row columns={'equal'}>
                          {cancelledBondInsurance.observations && (
                            <Grid.Column width={'8'}>
                              <Message info>
                                <Message.Header>
                                  Observações - Solicitação do Cancelamento
                                </Message.Header>
                                <span>{cancelledBondInsurance.observations}</span>
                              </Message>
                            </Grid.Column>
                          )}

                          {cancelledBondInsurance.pendingObservations && (
                            <Grid.Column width={'8'}>
                              <Message info>
                                <Message.Header>
                                  Observações - Cancelamento Pendente
                                </Message.Header>
                                <span>{cancelledBondInsurance.pendingObservations}</span>
                              </Message>
                            </Grid.Column>
                          )}

                          {cancelledBondInsurance.confirmObservations && (
                            <Grid.Column width={'8'}>
                              <Message info>
                                <Message.Header>
                                  Observações - Confirmação do Cancelamento
                                </Message.Header>
                                <span>{cancelledBondInsurance.confirmObservations}</span>
                              </Message>
                            </Grid.Column>
                          )}
                        </Grid.Row>
                      </Grid>
                    )}

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <NotificationEmailsModal
                        closeModal={() => setOpenEmailsModal(false)}
                        setOpen={() => setOpenEmailsModal(true)}
                        canceledId={cancelledBondInsurance.id}
                        openModal={openEmailsModal || false}
                        callback={getData}
                        emails={cancelledBondInsurance.notificationEmails}
                        triggerOptions={{
                          floated: 'right',
                          color: 'blue'
                        }}
                      />
                    </div>

                    {isUncancelable() && (
                      <>
                        <Button
                          color={'instagram'}
                          floated={'right'}
                          disabled={isUncancelingLoading}
                          loading={isUncancelingLoading}
                          onClick={() => setIsConfirmOpen(true)}
                        >
                          Voltar para Análise
                        </Button>

                        <Confirm
                          open={isConfirmOpen}
                          onCancel={() => setIsConfirmOpen(false)}
                          onConfirm={() => {
                            setIsConfirmOpen(false);
                            uncancelBondInsurance(cancelledBondInsurance.id);
                          }}
                          cancelButton={'Cancelar'}
                          confirmButton={'Voltar para Análise'}
                          header={'Voltar para Análise'}
                          content={'Você tem certeza que deseja voltar para análise?'}
                        />
                      </>
                    )}
                  </Segment>
                )}

                <SectionLabel text="DADOS DO CANCELAMENTO" />
                <BondInsuranceCancellationDetails
                  canceledBondInsurance={cancelledBondInsurance}
                  statusWasCanceled={statusWasCanceled}
                />
                <SectionLabel text="DADOS DA ANÁLISE" />
                <BondInsuranceMainInfoDetails bondInsurance={bondInsurance} />
                <BondInsurancePropertyDetails bondInsurance={bondInsurance} />
                <SectionLabel text="DADOS DO LOCATÁRIO" />
                {bondInsurance.tenantType === PersonTypesEnum.natural && (
                  <BondInsuranceNaturalTenantDetails bondInsurance={bondInsurance} />
                )}
                {bondInsurance.tenantType === PersonTypesEnum.legal && (
                  <BondInsuranceLegalTenantDetails bondInsurance={bondInsurance} />
                )}
              </>
            )}
          </Grid>
        }
        callback={(): Promise<void | BondInsurance> => {
          return {} as Promise<BondInsurance>;
        }}
      />
    </>
  );
};

export const BondInsuranceDetails = connect(
  mapState,
  mapDispatch
)(BondInsuranceDetailsComponent);
