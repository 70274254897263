import styled from 'styled-components';
import { Table } from 'semantic-ui-react';

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
`;

export const Header = styled(Table.Header)`
  background-color: #e1e8ed;
`;

export const TableWrapper = styled.div`
  font-size: 0.85em;
  font-weight: bold;

  .ui.table {
    border: none;
    text-align: center;
    color: #364445;
  }
`;

export const NotFoundWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #364445;
  font-size: 1.2em;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;
