import React from 'react';
import { Container, ContainerAsADiv } from './styles';
import { Banner } from '../../../../types/Banner';
import { Carousel } from 'react-responsive-carousel';
import { Dimmer, Loader } from 'semantic-ui-react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const CustomBanner = ({
  banners,
  isLoading
}: {
  banners: Banner[] | undefined;
  isLoading: boolean;
}) => {
  return (
    <>
      {/* {isLoading && (
        <Dimmer active={isLoading} inverted>
          <Loader content="Carregando mensagens..." />
        </Dimmer>
      )} */}
      {!isLoading && (
        <Carousel showThumbs={false} width="100%" showStatus={false}>
          {banners?.map(banner => {
            if (banner?.redirectUrl) {
              return (
                <Container
                  href={banner?.redirectUrl}
                  target={banner?.newTab ? '_blank' : 'self'}
                  rel="noopener noreferrer"
                >
                  <img
                    alt={banner?.title}
                    src={banner?.imageUrl}
                    style={{
                      aspectRatio: 'auto',
                      cursor: 'pointer'
                    }}
                  />
                </Container>
              );
            }
            return (
              <ContainerAsADiv>
                <img
                  alt={banner?.title}
                  src={banner?.imageUrl}
                  style={{
                    aspectRatio: 'auto',
                    cursor: 'pointer'
                  }}
                />
              </ContainerAsADiv>
            );
          })}
        </Carousel>
      )}
    </>
  );
};

export default CustomBanner;
