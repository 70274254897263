import React from 'react';
import { Dropdown, Grid, TextArea } from 'semantic-ui-react';
import { CreatePolicyProps } from '../../types';
import { CustomDropDownOptions } from '../../../../../../types';
import { analysisOptions, purposeOptions, tenantTypeOptions } from '../../utils';
import { PurposesEnum } from '../../../../../../enums/purposes.enum';
import {
  legalCommercialResidentialReasonOptions,
  naturalResidentialReasonOptions,
  validityOptions
} from '../../../../../bond-insurance-budget-form/types';
import { TextAreaWrapper } from '../../styles';

const RenderAnalysisForm = ({
  values,
  setFieldValue,
  estatesOptions
}: {
  values: CreatePolicyProps;
  setFieldValue: (field: string, value: any) => void;
  estatesOptions: CustomDropDownOptions[];
}) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <label>IMOBILIÁRIA</label>
          <Dropdown
            style={{ width: '100%' }}
            search
            value={values?.bondInsurance?.estateId}
            onChange={(e, { value }) => {
              setFieldValue('bondInsurance.estateId', value);
            }}
            clearable
            selection
            options={estatesOptions?.map(item => ({
              ...item,
              value: item.value?.id
            }))}
            placeholder={'Selecionar...'}
          />
        </Grid.Column>

        <Grid.Column width={8}>
          <label> FINALIDADE </label>
          <Dropdown
            style={{ width: '100%' }}
            search
            clearable
            selection
            options={purposeOptions}
            value={values?.bondInsurance?.purpose}
            onChange={(e, { value }) => {
              setFieldValue('bondInsurance.purpose', value);
              setFieldValue('bondInsurance.property.reason', undefined);
            }}
            placeholder={'Selecionar...'}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={8}>
          <label>TIPO DE LOCATÁRIO</label>

          <Dropdown
            style={{ width: '100%' }}
            search
            clearable
            selection
            options={tenantTypeOptions}
            value={values?.bondInsurance?.tenantType}
            onChange={(e, { value }) => {
              setFieldValue('bondInsurance.tenantType', value);
            }}
            placeholder={'Selecionar...'}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <label>MOTIVO DA LOCAÇÃO</label>

          <Dropdown
            style={{ width: '100%' }}
            search
            clearable
            selection
            disabled={!values?.bondInsurance?.purpose}
            value={values?.bondInsurance?.property?.reason}
            onChange={(e, { value }) => {
              setFieldValue('bondInsurance.property.reason', value);
            }}
            options={
              values?.bondInsurance?.purpose === PurposesEnum.commercial
                ? legalCommercialResidentialReasonOptions
                : naturalResidentialReasonOptions
            }
            placeholder={'Selecionar...'}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={8}>
          <label>GARANTIA DA ANÁLISE</label>

          <Dropdown
            search
            style={{ width: '100%' }}
            clearable
            selection
            options={analysisOptions}
            value={values?.bondInsurance?.analysisAssurance}
            onChange={(e, { value }) => {
              setFieldValue('bondInsurance.analysisAssurance', value);
            }}
            placeholder={'Selecionar...'}
          />
        </Grid.Column>

        <Grid.Column width={8}>
          <label>VIGÊNCIA DO CONTRATO</label>

          <Dropdown
            search
            style={{ width: '100%' }}
            clearable
            selection
            value={values?.bondInsurance?.property?.validity}
            onChange={(e, { value }) => {
              setFieldValue('bondInsurance.property.validity', value);
            }}
            options={validityOptions}
            placeholder={'Selecionar...'}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          <label>Observações</label>
          <TextAreaWrapper>
            <TextArea
              value={values?.analysis?.observation}
              onChange={(e, { value }) => {
                setFieldValue('analysis.observation', value);
              }}
            />
          </TextAreaWrapper>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
export default RenderAnalysisForm;
