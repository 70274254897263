export enum CanceledStatusEnum {
  CANCELLATION_IN_PROGRESS = 'CANCELLATION_IN_PROGRESS',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  UNCANCELLED = 'UNCANCELLED',
  PENDING = 'PENDING',
  PENDING_BY_INSURER = 'PENDING_BY_INSURER',
  FINANCIAL_PENDING = 'FINANCIAL_PENDING',
  CLAIM_CANCELLATION = 'CLAIM_CANCELLATION',
}
