import React, { useState, SyntheticEvent, useEffect } from 'react';
import Input from '../../../components/Input';
import BrokersDropdown from '../../../components/BrokersDropdown';
import SectionLabel from '../../../components/SectionLabel';
import {
  Grid,
  Dropdown as DropdownNativo,
  InputOnChangeData,
  Icon,
  Button,
  Modal,
  Header,
  SemanticWIDTHS,
  TextArea
} from 'semantic-ui-react';
import { Field } from 'formik';
import {
  unmaskCpfOrCnpj,
  maskCnpj,
  maskZipCode,
  maskPhoneNumber,
  maskCpfOrCnpj
} from '../../../services/masks';
import { docValidator } from '../../../services/validators';
import { FormikErrorMessage, DivErrorMessage } from '../../../components/ErrorMessage';
import Dropdown from '../../../components/Dropdown';
import { FieldLabel } from '../../../styles';
import { EstateFormProps } from '../UpdateEstateForm';
import { getUsers } from '../../../services/broker';
import { CustoDropDownItemProps } from '../../../components/types';
import { UsersToCustomDropDownOptions } from '../../../util';
import { UserForUpdateEstateForm } from '../types';
import { AddressService } from '../../../services/address';
import styled from 'styled-components';
import { activeEstate, inactiveEstate } from '../../../services/estate';
import { successNotification, dangerNotification } from '../../../services/notification';
import { useParams } from 'react-router-dom';
import EventsTimeline from '../../../components/EventsTimeline';
import { EstatePartner } from '../../../types/EstatePartner';
import { PartnerHeader, PartnerLabel } from '../../estates-form/styles';
import { deleteByID } from '../../../services/estate-partner';
import { companyOptions } from '../../estates-form/components/constants';

const AdditionalEmailWrapper = styled.div`
  display: flex;
  gap: 10px;

  > div:nth-child(1) {
    width: 50%;
  }

  > div:nth-child(2) {
    width: 40%;
  }

  > div:nth-child(3) {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 5px;

  > textarea {
    background-color: #f5f8fa;
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    padding: 10px 4px;
    outline: 0;

    &:focus {
      border-color: #85b7d9;
    }
  }

  > label {
    // font-weight: bold;
    margin-bottom: 5px;
  }
`;

type NotificationEmailType = {
  id?: string;
  email: string;
  category: string;
};

type EmailCategoryMap = Record<string, string[]>;

export const EstateData = (props: EstateFormProps & any) => {
  const {
    errors,
    touched,
    handleInputChange,
    values,
    setFieldValue,
    availableBrokersAndEstates
  } = props;
  const params = useParams();
  const franchises = availableBrokersAndEstates.franchises;
  const id = props.match.params.id;

  const [isValidCnpj, setValidCnpj] = useState<boolean>(true);
  const [isAnalystsLoading, setIsAnalystsLoading] = useState<boolean>(false);
  const [analystsOptions, setAnalystsOptions] = useState<CustoDropDownItemProps[]>([]);
  const [additionalAccountManagerOptions, setAdditionalAccountManagerOptions] = useState<
    CustoDropDownItemProps[]
  >([]);
  const [cepLoading, setCepLoading] = useState<boolean>(false);
  const [additionalEmailList, setAdditionalEmailList] = useState<string[]>(['']);
  const [isAdded, setIsAdded] = useState<boolean>(false);
  const [toggleActiveModal, setToggleActiveModal] = useState<boolean>(false);
  const [isCpf, setIsCpf] = useState(false);
  const [columnSize, setColumnSize] = useState<SemanticWIDTHS | undefined>(5);

  const [isEventsTimelineOpen, setIsEventsTimelineOpen] = useState<boolean>(false);

  const [classification, setClassification] = useState<string>(values.classification);
  const [averageProperties, setAverageProperties] = useState<number | undefined>(
    undefined
  );
  const [estatePartners, setEstatePartners] = useState<EstatePartner[]>([]);

  const options = [
    { key: 'PENDING', text: 'Pendente', value: 'PENDING' },
    { key: 'ACTIVE', text: 'Ativo', value: 'ACTIVE' },
    { key: 'INACTIVE', text: 'Inativo', value: 'INACTIVE' },
    { key: 'BLOCKED', text: 'Bloqueado', value: 'BLOCKED' },
    { key: 'UPDATE', text: 'Update', value: 'UPDATE' }
  ];

  const classifications = [
    { key: 'BRONZE', text: 'Bronze', value: 'BRONZE' },
    { key: 'SILVER', text: 'Prata', value: 'SILVER' },
    { key: 'GOLD', text: 'Ouro', value: 'GOLD' },
    { key: 'DIAMOND', text: 'Diamante', value: 'DIAMOND' }
  ];

  const optionsSource = [
    { key: 'CONFIAX_COMERCIAL', text: 'Confiax Comercial', value: 'CONFIAX_COMERCIAL' },
    { key: 'REDE_VISTORIAS', text: 'Rede Vistorias', value: 'REDE_VISTORIAS' }
  ];

  const categories = [
    { key: 'ANALYSIS', text: 'Análises', value: 'ANALYSIS' },
    { key: 'HIRING', text: 'Contratações', value: 'HIRING' },
    { key: 'CANCELEDS', text: 'Cancelamentos', value: 'CANCELEDS' },
    { key: 'CLAIMS', text: 'Sinistros', value: 'CLAIMS' },
    { key: 'RENEWALS', text: 'Renovações', value: 'RENEWALS' },
    { key: 'FIRE_INSURANCE', text: 'Seguro Incêndio', value: 'FIRE_INSURANCE' }
  ];

  const onChangeCep = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const maskedValue = maskZipCode(e.target.value);
    setFieldValue(`estateZipcode`, maskedValue);
    if (maskedValue.length === 9) {
      setCepLoading(true);
      AddressService.getAddressByZipCode(e.target.value)
        .then(address => {
          setFieldValue('estateCity', address.city);
          setFieldValue('estateDistrict', address.district);
          setFieldValue('estateState', address.state);
          setFieldValue('estateStreet', address.street);
          setCepLoading(false);
        })
        .catch(e => {
          console.log('address error:', e);
        });
    }
  };

  useEffect(() => {
    if (averageProperties) {
      if (averageProperties < 100) {
        setClassification(classifications[0].value);
      } else if (averageProperties < 200) {
        setClassification(classifications[1].value);
      } else if (averageProperties < 300) {
        setClassification(classifications[2].value);
      } else {
        setClassification(classifications[3].value);
      }
    }
  }, [averageProperties]);

  useEffect(() => {
    setFieldValue('classification', classification);
  }, [classification]);

  useEffect(() => {
    const verifyCnpj = unmaskCpfOrCnpj(values.cnpj);
    if (verifyCnpj.length === 11) {
      setIsCpf(true);
      setColumnSize(3);
    } else {
      setIsCpf(false);
      setColumnSize(5);
    }
  }, [values.cnpj]);

  useEffect(() => {
    const arrayJoined = additionalEmailList.join(';');
    if (arrayJoined.length > 0) {
      setFieldValue('additionalEmails', arrayJoined);
    } else {
      setFieldValue('additionalEmails', null);
    }
  }, [additionalEmailList]);

  useEffect(() => {
    if (!isAdded) {
      if (values.additionalEmails) {
        if (values.additionalEmails.length) {
          const emails = removeLastChar(values.additionalEmails).split(';');
          emails.push('');
          setAdditionalEmailList(emails);
        } else {
          setAdditionalEmailList(['']);
        }
      } else {
        setAdditionalEmailList(['']);
      }
      setIsAdded(true);
    }
  }, [values.additionalEmails, isAdded]);

  function removeLastChar(str: string) {
    if (str.length > 0) {
      if (str.substring(str.length, str.length - 1) === ';') {
        return str.substring(0, str.length - 1);
      } else {
        return str;
      }
    } else {
      return str;
    }
  }

  useEffect(() => {
    const updateUsers = async () => {
      if (values.broker?.id) {
        setIsAnalystsLoading(true);
        getUsers(values.broker.id)
          .then(result => {
            const convertedValues: UserForUpdateEstateForm[] = result.map((user: any) => {
              const userForForm: UserForUpdateEstateForm = {
                id: user.id,
                name: user.name
              };

              return userForForm;
            });

            const analystsOptions = UsersToCustomDropDownOptions(true, convertedValues);
            setAnalystsOptions(analystsOptions);
            setAdditionalAccountManagerOptions(analystsOptions);
          })
          .catch(error => {
            dangerNotification(
              'Oops...',
              'Não foi possível encontrar os analistas disponíveis'
            );
          })
          .finally(() => {
            setIsAnalystsLoading(false);
          });
      }
    };

    updateUsers();
  }, [values.broker]);

  const toggleActive = async (way: string) => {
    const id = (params as any).id;
    const userId = props.user.id;

    let success = false;

    if (way === 'active') {
      const active = await activeEstate(id, userId);

      if (active) success = true;
    }

    if (way === 'inactive') {
      const inactive = await inactiveEstate(id, userId);

      if (inactive) success = true;
    }

    if (success) {
      successNotification('Sucesso', 'Imobiliária atualizada com sucesso');
      props.history.push('/admin/estates');
    } else {
      dangerNotification('Oops...', 'Não foi possível atualizar o imobiliária');
    }

    setToggleActiveModal(false);
  };

  const [emailCategories, setEmailCategories] = useState<EmailCategoryMap>({});

  useEffect(() => {
    const emailCategoriesMap: EmailCategoryMap = {};

    if (values.notificationEmails) {
      values.notificationEmails.forEach(({ email, category }: NotificationEmailType) => {
        if (!emailCategoriesMap[email]) {
          emailCategoriesMap[email] = [];
        }
        emailCategoriesMap[email].push(category);
      });

      setEmailCategories(emailCategoriesMap);
      setAdditionalEmailList(Object.keys(emailCategoriesMap));
    }
  }, []);

  useEffect(() => {
    if (values?.partners?.length > 0) {
      setEstatePartners(
        values?.partners?.map((partner: EstatePartner) => ({
          ...partner,
          document: maskCpfOrCnpj(partner.document)
        })) || []
      );
    } else if (values?.partners?.length === 0) {
      // Start the form with the empty fields
      setEstatePartners([
        {
          name: '',
          email: '',
          document: ''
        }
      ]);
    }
  }, [values.partners]);

  const handleCategoryChange = (email: string, selectedCategories: any) => {
    if (!emailCategories[email]) {
      emailCategories[email] = [];
    }
    const inicial = new Set(emailCategories[email]);
    const final = new Set(selectedCategories);

    const novosElementos = selectedCategories.filter(
      (elemento: string) => !inicial.has(elemento)
    );
    const elementosRemovidos = emailCategories[email].filter(
      elemento => !final.has(elemento)
    );

    if (novosElementos.length > 0) {
      const emailsToInsert: NotificationEmailType[] = [];

      novosElementos.forEach((category: string) => {
        const novo: NotificationEmailType = {
          email: email,
          category: category
        };
        emailsToInsert.push(novo);
      });

      if (!values.emailsToInsert) {
        setFieldValue(`emailsToInsert`, emailsToInsert);
      } else {
        setFieldValue(`emailsToInsert`, [...emailsToInsert, ...values.emailsToInsert]);
      }
    }

    if (elementosRemovidos.length > 0) {
      const emailsToRemove: (string | undefined)[] = [];
      elementosRemovidos.forEach(category => {
        values.notificationEmails.find((notificationEmail: NotificationEmailType) => {
          if (
            notificationEmail.email === email &&
            notificationEmail.category === category
          ) {
            emailsToRemove.push(notificationEmail.id);
          }
        });
      });
      if (!values.emailsToRemove) {
        setFieldValue(`emailsToRemove`, emailsToRemove);
      } else {
        setFieldValue(`emailsToRemove`, [...values.emailsToRemove, ...emailsToRemove]);
      }
    }
    setEmailCategories({ ...emailCategories, [email]: selectedCategories });
  };

  const handleRemoveEmail = (email: string) => {
    const emailsToRemove: (string | undefined)[] = [];
    values.notificationEmails.map((item: NotificationEmailType) => {
      if (item.email === email) {
        emailsToRemove.push(item.id);
      }
    });
    setFieldValue(`emailsToRemove`, emailsToRemove);
  };

  const handleChangePartner = (
    e: React.ChangeEvent<HTMLInputElement>,
    data: any,
    estatePartner: EstatePartner
  ) => {
    const partnerIndex = estatePartners.findIndex(
      item => item?.document === estatePartner?.document
    );
    if (partnerIndex >= 0) {
      const newEstatePartners =
        estatePartners?.map(partner => {
          if (
            partner.name === estatePartner.name &&
            partner.document === estatePartner.document
          ) {
            return {
              ...partner,
              [e.target.name]: data.toString()
            };
          }
          return partner;
        }) || [];
      setEstatePartners(newEstatePartners);
    }
  };

  const handleRemovePartner = async (estatePartner: EstatePartner) => {
    // TODO: Add a warning toast before delete the item
    const newPartners = estatePartners.filter(
      item =>
        estatePartner.document !== item?.document || estatePartner.name !== item?.name
    );
    setEstatePartners(newPartners);
    if (estatePartner?.id) await deleteByID(estatePartner?.id);
    setFieldValue('partners', newPartners);
  };

  return (
    <>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '8px'
          }}
        >
          <Button icon circular primary onClick={() => setIsEventsTimelineOpen(true)}>
            <Icon name={'calendar alternate outline'} />
          </Button>
          <FieldLabel>Log de Alterações</FieldLabel>
        </div>
        {values.status === 'ACTIVE' && (
          <Button color={'red'} onClick={() => setToggleActiveModal(true)}>
            Desativar Imobiliária
          </Button>
        )}

        {values.status === 'INACTIVE' && (
          <Button color={'green'} onClick={() => setToggleActiveModal(true)}>
            Ativar Imobiliária
          </Button>
        )}
      </div>

      <SectionLabel text="DADOS DA IMOBILIÁRIA" />

      <Grid.Row>
        <Grid.Column width={6}>
          <FieldLabel>Corretora*</FieldLabel>
          <Field
            name="broker"
            completeValue={true}
            className={
              errors && errors.broker && touched && touched.broker
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={BrokersDropdown}
            fluid
          />
          <FormikErrorMessage component="div" name="brokerId" />
        </Grid.Column>

        <Grid.Column width={5}>
          <FieldLabel>E-mail Principal*</FieldLabel>
          <Field name="email" component={Input} fluid onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}/>
          <FormikErrorMessage component="div" name="email" />
        </Grid.Column>

        <Grid.Column width={5} style={{ display: 'flex', flexDirection: 'column' }}>
          <FieldLabel>Origem do contato*</FieldLabel>
          <DropdownNativo
            value={values.contactSource}
            name="contactSource"
            clearable
            search
            options={optionsSource}
            selection
            onChange={(e, { value }) => setFieldValue(`contactSource`, value)}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row stretched>
        <Grid.Column width={6}>
          <FieldLabel>Nome da Imobiliária*</FieldLabel>
          <Field
            name="name"
            component={Input}
            className={
              errors && errors.name && touched && touched.name
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            value={values.name}
            fluid
          />
          <FormikErrorMessage component="div" name="name" />
        </Grid.Column>

        <Grid.Column width={5}>
          <FieldLabel>Razão Social*</FieldLabel>
          <Field
            name="company_name"
            component={Input}
            className={
              errors && errors.company_name && touched && touched.company_name
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            value={values.company_name}
            fluid
          />
          <FormikErrorMessage component="div" name="company_name" />
        </Grid.Column>

        <Grid.Column width={columnSize}>
          <FieldLabel>CNPJ*</FieldLabel>
          <Field
            name="cnpj"
            className={
              (errors && errors.cnpj && touched && touched.cnpj) || !isValidCnpj
                ? 'text-input error'
                : 'text-input'
            }
            component={Input}
            onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
              data.value = maskCnpj(data.value);
              handleInputChange(data, props);
            }}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
              const document = unmaskCpfOrCnpj(e.target.value);
              setValidCnpj(docValidator(document));
            }}
            fluid
          />
          {isValidCnpj ? null : <DivErrorMessage>CNPJ inválido</DivErrorMessage>}
          <FormikErrorMessage component="div" name="cnpj" />
        </Grid.Column>

        {isCpf && (
          <Grid.Column width={2}>
            <FieldLabel>CRECI</FieldLabel>
            <Field
              name="creci"
              component={Input}
              className={
                errors && errors.creci && touched && touched.creci
                  ? 'text-input error'
                  : 'text-input'
              }
              onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
              fluid
              value={values.creci}
            />
            <FormikErrorMessage component="div" name="creci" />
          </Grid.Column>
        )}
      </Grid.Row>

      <Grid.Row stretched>
        <Grid.Column width={6}>
          <FieldLabel>Nome do Responsável*</FieldLabel>
          <Field
            name="responsibleName"
            component={Input}
            className={
              errors && errors.responsibleName && touched && touched.responsibleName
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            fluid
          />
          <FormikErrorMessage component="div" name="responsibleName" />
        </Grid.Column>
        <Grid.Column width={5}>
          <FieldLabel>Email do Responsável*</FieldLabel>
          <Field
            name="responsibleEmail"
            className={
              errors && errors.responsibleEmail && touched && touched.responsibleEmail
                ? 'text-input error'
                : 'text-input'
            }
            component={Input}
            onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
              handleInputChange(data, props);
            }}
            fluid
          />
          <FormikErrorMessage component="div" name="responsibleEmail" />
        </Grid.Column>
        <Grid.Column width={5}>
          <FieldLabel>Telefone do Responsável*</FieldLabel>
          <Field
            name="responsiblePhone"
            component={Input}
            className={
              errors && errors.responsiblePhone && touched && touched.responsiblePhone
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => {
              data.value = maskPhoneNumber(data.value);
              handleInputChange(data, props);
            }}
            fluid
          />
          <FormikErrorMessage component="div" name="responsiblePhone" />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row stretched>
        <Grid.Column width={6}>
          <FieldLabel>Responsável Financeiro*</FieldLabel>
          <Field
            name="financialResponsible"
            component={Input}
            className={
              errors &&
              errors.financialResponsible &&
              touched &&
              touched.financialResponsible
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            fluid
          />
          <FormikErrorMessage component="div" name="financialResponsible" />
        </Grid.Column>

        <Grid.Column width={5}>
          <FieldLabel>Email do Financeiro*</FieldLabel>
          <Field
            name="financialEmail"
            className={
              errors && errors.financialEmail && touched && touched.financialEmail
                ? 'text-input error'
                : 'text-input'
            }
            component={Input}
            onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
              handleInputChange(data, props);
            }}
            fluid
          />
          <FormikErrorMessage component="div" name="financialEmail" />
        </Grid.Column>

        <Grid.Column width={5}>
          <FieldLabel>Status*</FieldLabel>
          <DropdownNativo
            defaultValue="active"
            value={values.status}
            name="status"
            clearable
            search
            options={options}
            selection
            onChange={(e, { value }) => setFieldValue(`status`, value)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row stretched>
        <Grid.Column width={6}>
          <FieldLabel>Gestor de Contas*</FieldLabel>
          <Field
            name="estateAnalystUser"
            loading={isAnalystsLoading}
            options={analystsOptions}
            className={
              errors && errors.estateAnalystUser && touched && touched.estateAnalystUser
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Dropdown}
            selection
            search
            clearable
            compact
            basic
            fluid
          />
          <FormikErrorMessage component="div" name="estateAnalystUser" />
        </Grid.Column>

        <Grid.Column width={5}>
          <FieldLabel>Gestor de Contas (Adicional)</FieldLabel>
          <Field
            name="additionalAccountManager"
            loading={isAnalystsLoading}
            options={additionalAccountManagerOptions}
            className={
              errors &&
              errors.additionalAccountManager &&
              touched &&
              touched.additionalAccountManager
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Dropdown}
            selection
            search
            clearable
            compact
            basic
            fluid
          />
          <FormikErrorMessage component="div" name="additionalAccountManager" />
        </Grid.Column>

        <Grid.Column width={5}>
          <Grid columns={2}>
            <Grid.Column>
              <FieldLabel>Média de Imóveis na Carteira *</FieldLabel>
              <Field
                name="averagePropertiesWallet"
                component={Input}
                onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
                  setAverageProperties(Number(data.value));
                  handleInputChange(data, props);
                }}
                fluid
                value={values.averagePropertiesWallet}
                className={
                  Number(values.averagePropertiesWallet) !== 0
                    ? 'text-input'
                    : 'text-input error'
                }
              />
              {Number(values.averagePropertiesWallet) !== 0 ? null : (
                <DivErrorMessage>O valor tem que ser maior que 0</DivErrorMessage>
              )}
            </Grid.Column>

            <Grid.Column verticalAlign="bottom">
              <FieldLabel>Classificação</FieldLabel>
              <DropdownNativo
                value={classification}
                name="classification"
                clearable
                search
                options={classifications}
                selection
                onChange={(e, { value }) => setClassification(String(value))}
              />
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={6}>
          <FieldLabel>Franquia</FieldLabel>
          <Field
            name="franchiseId"
            options={franchises}
            search
            clearable
            className={
              errors && errors.franchiseId && touched && touched.franchiseId
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => {
              handleInputChange(data, props);
            }}
            component={Dropdown}
            selection
            compact
            basic
            fluid
            selected={values.franchiseId}
          />
        </Grid.Column>
        <Grid.Column width={5}>
          <FieldLabel>Responsável por Sinistros</FieldLabel>
          <Field
            name="claimResponsible"
            loading={isAnalystsLoading}
            options={analystsOptions}
            className={
              errors && errors.claimResponsible && touched && touched.claimResponsible
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Dropdown}
            selection
            search
            clearable
            compact
            basic
            fluid
          />
          <FormikErrorMessage component="div" name="claimResponsible" />
        </Grid.Column>

        <Grid.Column width={5} style={{ display: 'flex', flexDirection: 'column' }}>
          <FieldLabel>Empresa Responsável*</FieldLabel>
          <DropdownNativo
            value={values.responsible_company}
            name="responsible_company"
            clearable
            search
            options={companyOptions}
            selection
            onChange={(e, { value }) => setFieldValue(`responsible_company`, value)}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column widths={'equal'}>
          <TextAreaWrapper>
            <FieldLabel>Observações da Parceria</FieldLabel>
            <TextArea
              loading={isAnalystsLoading}
              value={values.observations}
              onChange={(e, data) => setFieldValue('observations', data.value)}
            />
          </TextAreaWrapper>
        </Grid.Column>
      </Grid.Row>

      <SectionLabel text="QUADRO DE SÓCIOS" />
      {estatePartners.map((estatePartner, index) => {
        return (
          <Grid.Row key={estatePartner?.id} streched>
            {index >= 0 && (
              <Grid.Column width={16}>
                <PartnerHeader>
                  <PartnerLabel>{index + 1}. SÓCIO</PartnerLabel>
                  <Button
                    color="red"
                    basic
                    type="button"
                    size="mini"
                    onClick={() => handleRemovePartner(estatePartner)}
                  >
                    <Icon name="cancel" /> Remover
                  </Button>
                </PartnerHeader>
              </Grid.Column>
            )}
            <Grid.Column width={8}>
              <FieldLabel>Nome</FieldLabel>
              <Field
                name="name"
                component={Input}
                onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
                  handleChangePartner(e, data.value, estatePartner);
                }}
                fluid
                onBlur={() => setFieldValue('partners', estatePartners)}
                value={estatePartner.name}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <FieldLabel>CPF</FieldLabel>
              <Field
                name="document"
                component={Input}
                onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
                  handleChangePartner(e, maskCpfOrCnpj(data.value), estatePartner);
                }}
                fluid
                onBlur={() => setFieldValue('partners', estatePartners)}
                value={estatePartner.document}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <FieldLabel>E-MAIL</FieldLabel>
              <Field
                name="email"
                component={Input}
                onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
                  handleChangePartner(e, data.value, estatePartner);
                }}
                fluid
                onBlur={() => setFieldValue('partners', estatePartners)}
                value={estatePartner.email}
              />
            </Grid.Column>
          </Grid.Row>
        );
      })}
      <Grid.Row>
        <Button
          style={{ marginTop: 10 }}
          primary
          onClick={() => {
            if (estatePartners.length > 0) {
              const lastPartner = estatePartners[estatePartners.length - 1];
              if (lastPartner?.name?.length > 0 && lastPartner?.document?.length > 0)
                setEstatePartners(estatePartner => [
                  ...estatePartner,
                  { name: '', document: '', email: '' }
                ]);
            } else {
              setEstatePartners([{ name: '', document: '', email: '' }]);
            }
          }}
        >
          <Icon name={'plus circle'} />
          Adicionar Sócio
        </Button>
      </Grid.Row>

      <SectionLabel text="E-MAILS ADICIONAIS" />
      <Grid.Row streched>
        <Grid.Column width={16} style={{ marginTop: -10, paddingTop: -10 }}>
          <p style={{ color: 'gray', marginTop: -10 }}>
            Só preencha esses campos caso queira receber nossas notificações em outros
            e-mails
          </p>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row streched>
        <Grid.Column width={16}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            {additionalEmailList.map((additionalEmail, index) => {
              return (
                <AdditionalEmailWrapper key={`additional_email_${index}`}>
                  <div>
                    <FieldLabel>Email</FieldLabel>
                    <Field
                      name={`additional_email[${index}].email`}
                      placeholder={'E-mail adicional'}
                      component={Input}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
                        setAdditionalEmailList(additionalEmailList => [
                          ...additionalEmailList.slice(0, index),
                          data.value,
                          ...additionalEmailList.slice(index + 1)
                        ]);
                      }}
                      fluid
                      value={additionalEmail}
                    />
                  </div>
                  <div>
                    <FieldLabel>Categoria</FieldLabel>
                    <DropdownNativo
                      clearable
                      multiple
                      selection
                      fluid
                      name={`category_${index}`}
                      options={categories}
                      value={emailCategories[additionalEmail]}
                      onChange={(e, { value }) => {
                        handleCategoryChange(additionalEmail, value);
                      }}
                    />
                  </div>
                  <div>
                    <Icon
                      name={'remove circle'}
                      color={'grey'}
                      style={{ cursor: 'pointer' }}
                      size={'large'}
                      onClick={() => {
                        handleRemoveEmail(additionalEmailList[index]);
                        const updatedAdditionalEmailList = [
                          ...additionalEmailList.slice(0, index),
                          ...additionalEmailList.slice(index + 1)
                        ];
                        setAdditionalEmailList(updatedAdditionalEmailList);
                      }}
                    />
                  </div>
                </AdditionalEmailWrapper>
              );
            })}
          </div>
          <Button
            style={{ marginTop: 10 }}
            primary
            onClick={() => {
              if (additionalEmailList.length) {
                const lastEmail = additionalEmailList[additionalEmailList.length - 1];
                if (lastEmail.length > 0)
                  setAdditionalEmailList(additionalEmailList => [
                    ...additionalEmailList,
                    ''
                  ]);
              } else {
                setAdditionalEmailList(['']);
              }
            }}
          >
            <Icon name={'plus circle'} />
            Adicionar E-mail
          </Button>
        </Grid.Column>
      </Grid.Row>

      <SectionLabel text="ENDEREÇO DA IMOBILIARIA" />
      <Grid.Row stretched>
        <Grid.Column width={4}>
          <FieldLabel>CEP</FieldLabel>
          <Field
            name="estateZipcode"
            value={values.estateZipcode}
            component={Input}
            loading={cepLoading}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
              data: InputOnChangeData
            ): void => {
              onChangeCep(e);
              data.value = maskZipCode(e.target.value);
            }}
            maxLength={9}
            fluid
          />
          <FormikErrorMessage component="div" name="estate_zipcode" />
        </Grid.Column>

        <Grid.Column width={6}>
          <FieldLabel>LOGRADOURO*</FieldLabel>
          <Field
            name={`estateStreet`}
            value={values.estateStreet}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`estateStreet`} />
        </Grid.Column>

        <Grid.Column width={5}>
          <FieldLabel>BAIRRO*</FieldLabel>
          <Field
            name={`estateDistrict`}
            value={values.estateDistrict}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`estateDistrict`} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={4}>
          <FieldLabel>CIDADE*</FieldLabel>
          <Field
            name={`estateCity`}
            value={values.estateCity}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`estateCity`} />
        </Grid.Column>

        <Grid.Column width={5}>
          <FieldLabel>UF*</FieldLabel>
          <Field
            name={`estateState`}
            value={values.estateState}
            component={Input}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            fluid
          />
          <FormikErrorMessage component="div" name={`estateState`} />
        </Grid.Column>
        <Grid.Column width={4}>
          <FieldLabel>NÚMERO*</FieldLabel>
          <Field
            name={`estateNumber`}
            value={values.estateNumber}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`estateNumber`} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={6}>
          <FieldLabel>COMPLEMENTO</FieldLabel>
          <Field
            name={`estateComplement`}
            value={values.estateComplement}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Input}
            fluid
          />
        </Grid.Column>
      </Grid.Row>

      <Modal
        closeIcon
        open={toggleActiveModal}
        onClose={() => setToggleActiveModal(false)}
        onOpen={() => setToggleActiveModal(true)}
      >
        <Header
          content={`${values.status === 'ACTIVE' ? 'Desativar' : 'Ativar'} imobiliária`}
        />
        <Modal.Content>
          {values.status === 'ACTIVE' && (
            <p style={{ color: 'red' }}>
              <Icon name={'warning sign'} />
              Ao desativar uma imobiliária, todos os usuários que estiverem vinculados a
              ela serão desativados. Sendo necessário a reativação manual de cada usuário,
              caso deseje reativar a imobiliária.
            </p>
          )}
          <p>
            Tem certeza que deseja {values.status === 'ACTIVE' ? 'desativar' : 'ativar'} a
            Imobiliária?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setToggleActiveModal(false)}>Fechar</Button>

          <Button
            color={values.status === 'ACTIVE' ? 'red' : 'green'}
            onClick={() =>
              toggleActive(values.status === 'ACTIVE' ? 'inactive' : 'active')
            }
          >
            {values.status === 'ACTIVE' ? 'Desativar' : 'Ativar'}
          </Button>
        </Modal.Actions>
      </Modal>
      <EventsTimeline
        open={isEventsTimelineOpen}
        setOpen={setIsEventsTimelineOpen}
        relatedId={id}
      />
    </>
  );
};
