import React, { useEffect, useState } from 'react';
import { Button, Header, Icon, Modal, Step, Loader, Dimmer } from 'semantic-ui-react';

import DocumentsComponent from '../Steps/DocumentsComponentStep';
import { Formik } from 'formik';
import { CreateClaimEstateInitialInfo } from '../Formik/InitialValues/CreateClaimEstateInitialInitialValues';
import ClaimEstateInfoStep from '../Steps/ClaimEstateInfoStep';
import ClaimValuesStep from '../Steps/ClaimValuesStep';
import {
  ClaimValueFormikType,
  CreateClaimEstateInitialInitialValuesType
} from '../Formik/types/CreateClaimEstateInitialValuesTypes';
import {
  dangerNotification,
  successNotification,
  warningNotification
} from '../../../../../services/notification';
import { InsurerState } from '../../../../../types/InsurerState';
import { connect } from 'react-redux';
import { iRootDispatch, iRootState } from '../../../../../store';
import { AvailableBrokersAndEstatesState } from '../../../../../store/types/temp-types';
import { CustoDropDownItemProps } from '../../../../../components/types';
import { uuid } from 'uuidv4';
import { postFileAndGetPreSignAxios } from '../../../../../services/file';
import { createClaimEstate } from '../../../../../services/claims';
import { ClaimPropertyStatusEnum } from '../../../../../enums/claim-property-status.enum';
import { FormatDateYMD } from '../../../../../services/date';
import { useHistory } from 'react-router-dom';
import { translateValuesType } from '../../../util';
import ModalInstructions from '../../ModalInstructions';
import { InsurersCodeEnum } from '../../../../../enums/insurers-code.enum';
import { PolicyForGetBondInsurancePoliciesListResponseDTO } from '../../../../../dtos/bond-insurance-policy-list/interfaces/policy-for-get-bond-insurance-policies-list-response-dto';
import { getFileIdentifiers } from '../utils';
import { Callback } from '../../../../../types/Calback';
import { get } from '../../../../../services/storage';

type ModalContentType = {
  children: React.ReactNode;
  values: CreateClaimEstateInitialInitialValuesType;
};

const CreateClaimEstateModal = (props: {
  availableBrokersAndEstates: AvailableBrokersAndEstatesState;
  onlyModal?: boolean;
  policy?: PolicyForGetBondInsurancePoliciesListResponseDTO;
  onClose?: Callback;
}) => {
  const { onlyModal = false, policy, onClose } = props;
  const estates: CustoDropDownItemProps[] = props.availableBrokersAndEstates.estates;
  let insurers: InsurerState[] | null = props.availableBrokersAndEstates.insurers.map(
    (insurer: any) => insurer.value
  );
  if (insurers) {
    insurers = insurers.concat({
      id: '297c0ed6-a9ea-48d3-b0da-235597ae9f7a',
      name: 'Liberty Seguros',
      code: InsurersCodeEnum.Liberty
    } as InsurerState);
  }
  const [openHireProposalModal, setOpenHireProposalModal] = useState(false);
  const [stepOfHireModal, setStepOfHireModal] = useState('claim_esate_info');
  const [registerId, setRegisterId] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const history = useHistory();

  const [openModal, setOpenModal] = useState(false);
  const toggleOpen = () => setOpenModal(true);
  const toggleClose = () => setOpenModal(false);

  useEffect(() => {
    if (onlyModal) {
      toggleOpen();
      setOpenHireProposalModal(true);
    }
  }, [onlyModal]);

  useEffect(() => {
    setRegisterId(uuid());
  }, []);

  function handleCloseModal() {
    setOpenHireProposalModal(false);
    onClose && onClose();
  }

  function handleOpenModal() {
    setStepOfHireModal('claim_esate_info');
    setOpenHireProposalModal(true);
  }

  async function handleNextStep(values: CreateClaimEstateInitialInitialValuesType) {
    switch (stepOfHireModal) {
      case 'claim_esate_info': {
        let error = false;
        ///@ToDo Verficar se é broker, se for, obrigar informar o estateId. Se for imob, pegar do localstorage
        if (!values.claimEstate.estateId) {
          warningNotification('Ops...', 'Por favor, selecione a imobiliária desejada!');
          error = true;
        }

        if (
          !values.claimEstate.notificationEmails ||
          values.claimEstate.notificationEmails.length < 1 ||
          !values.claimEstate.notificationEmails[0]
        ) {
          warningNotification(
            'Ops...',
            'Por favor, insira pelo menos um email para notificação!'
          );
          error = true;
        }

        if (
          values.claimEstate.notificationEmails &&
          values.claimEstate.notificationEmails.length > 0
        ) {
          const emails = values.claimEstate.notificationEmails;
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          for (let i = 0; i < emails.length; i++) {
            if (!emailRegex.test(emails[i]) && emails[i].length !== 0) {
              dangerNotification(
                'E-mail Adicional',
                "O campo E-mail Adicional '" +
                  emails[i] +
                  "' é inválido, verifique e tente novamente."
              );
              return;
            }
          }
        }

        if (!values.claimEstate.status) {
          warningNotification(
            'Ops...',
            'Por favor, informe se o imóvel está ocupado ou desocupado!'
          );
          error = true;
        }

        if (!values.claimTenant.document) {
          warningNotification('Ops...', 'Por favor, informe o DOCUMENTO do locatário!');
          error = true;
        }

        if (!values.claimTenant.fullName) {
          warningNotification(
            'Ops...',
            'Por favor, informe o NOME COMPLETO do locatário!'
          );
          error = true;
        }

        if (!values.claimTenant.cellphone) {
          warningNotification('Ops...', 'Por favor, informe o TELEFONE do locatário!');
          error = true;
        }

        if (!values.claimTenant.email) {
          warningNotification('Ops...', 'Por favor, informe o EMAIL do locatário!');
          error = true;
        }

        if (
          values.claimProperty.status === ClaimPropertyStatusEnum.vacant &&
          !values.claimProperty.deliveryKeysDate
        ) {
          warningNotification(
            'Ops...',
            'Por favor, informe a DATA DE ENTREGA DAS CHAVES!'
          );
          error = true;
        }

        if (!values.claimProperty.zipCode) {
          warningNotification('Ops...', 'Por favor, informe o CEP do imóvel!');
          error = true;
        }

        if (!values.claimProperty.state) {
          warningNotification('Ops...', 'Por favor, informe o ESTADO do imóvel!');
          error = true;
        }

        if (!values.claimProperty.city) {
          warningNotification('Ops...', 'Por favor, informe a CIDADE do imóvel!');
          error = true;
        }

        if (!values.claimProperty.district) {
          warningNotification('Ops...', 'Por favor, informe o BAIRRO do imóvel!');
          error = true;
        }

        if (!values.claimProperty.street) {
          warningNotification('Ops...', 'Por favor, informe o LOGRADOURO do imóvel!');
          error = true;
        }

        if (!values.claimProperty.number) {
          warningNotification('Ops...', 'Por favor, informe o NÚMERO do imóvel!');
          error = true;
        }

        if (!error) {
          setStepOfHireModal('claim_values_info');
        }
        return;
      }
      case 'claim_values_info': {
        let error = false;
        const claimValues = values.claimValues.condominiumFee
          .concat(values.claimValues.energyBill)
          .concat(values.claimValues.gasBill)
          .concat(values.claimValues.paintingValue)
          .concat(values.claimValues.penalty)
          .concat(values.claimValues.propertyDamageValue)
          .concat(values.claimValues.rentValue)
          .concat(values.claimValues.taxesValue)
          .concat(values.claimValues.waterBill)
          .concat(values.claimValues.fireInsuranceBill);
        const cv = [];
        claimValues.forEach(e => {
          if (e.value && e.valueType) {
            if (!e.dueDate) {
              warningNotification(
                'Ops...',
                `Favor informar a data de vencimento de ${translateValuesType(
                  e.valueType
                )}`
              );
              error = true;
            }
            if (e.value <= 0) {
              warningNotification(
                'Ops...',
                `Valor de ${translateValuesType(e.valueType)}; Deve ser maior que Zero`
              );
              error = true;
            }
            if (e.value && e.valueType && e.dueDate) {
              cv.push(e);
            }
          }
        });

        if (cv.length < 1) {
          warningNotification(
            'Ops...',
            'Por favor, informe pelo menos um valor para abertura do sinistro!'
          );
          error = true;
        }
        if (!error) {
          setStepOfHireModal('document_attachment');
        }
        return;
      }
      case 'document_attachment': {
        const fileIdentifiers =
          values.claimProperty.status === ClaimPropertyStatusEnum.occupied
            ? [
                { name: 'Boleto Aluguel', key: 'RENT_BILL', required: true },
                {
                  name: 'Boleto Condomínio',
                  key: 'CONDOMINIUM_BILL',
                  required: false
                },
                {
                  name: 'Guia de IPTU',
                  key: 'IPTU_GUIDE',
                  required: false
                },
                {
                  name: 'Vistoria Inicial do Imóvel',
                  key: 'INITIAL_INSPECTION_PROPERTY',
                  required: false
                },
                {
                  name: 'RG do Locador',
                  key: 'LOCATOR_DOCUMENT',
                  required: false
                },
                {
                  name: 'Comprovante de Endereço Locador',
                  key: 'LOCATOR_ADDRESS_DOCUMENT',
                  required: false
                },
                {
                  name: 'RG/CPF do Locatário',
                  key: 'TENANT_DOCUMENT',
                  required: false
                },
                {
                  name: 'Contrato Garantti Locatário',
                  key: 'LOCATOR_GARANTTI_CONTRACT',
                  required:
                    values.claimEstate.insurerId && insurers
                      ? insurers
                          .find(c => c.id === values.claimEstate.insurerId)
                          ?.code.includes(InsurersCodeEnum.Garantti)
                        ? true
                        : false
                      : false
                },
                {
                  name: 'Imissão na posse',
                  key: 'POSSESSION_IMMISSION',
                  required: false
                },
                {
                  name: 'Acordo escritório',
                  key: 'OFFICE_AGREEMENT',
                  required: false
                },
                {
                  name: 'Apólice Seguro Incêndio',
                  key: 'FIRE_INSURANCE_POLICY',
                  required: false
                },
                {
                  name: 'Comprovante de Pagamento (planilha)',
                  key: 'PAYMENT_VOUCHER',
                  required: false
                },
                {
                  name: 'Contrato de administração',
                  key: 'PROPERTY_MANAGEMENT_CONTRACT',
                  required: true
                },
                {
                  name: 'Procuração',
                  key: 'LETTER_ATTORNEY',
                  required: true
                },
                {
                  name: 'Declaração de débitos',
                  key: 'DEBITS_SHEET',
                  required: true
                },
                {
                  name: 'Acordo de Confissão de Dívidas',
                  key: 'DEBT_CONFESS_AGREEMENT',
                  required: false
                },
                {
                  name: 'Contrato de Locação',
                  key: 'LOCATION_QUOTATION',
                  required: true
                },
                {
                  name: 'Planilha de indenização',
                  key: 'INDEMNITY_WORKSHEET',
                  required: false
                },
                {
                  name: 'Planilha de acordo',
                  key: 'ACCORD_WORKSHEET',
                  required: false
                },
                {
                  name: 'Água',
                  key: 'WATER_BILL',
                  required: false
                },
                {
                  name: 'Luz',
                  key: 'ENERGY_BILL',
                  required: false
                },
                {
                  name: 'Apólice Seguro Fiança',
                  key: 'BAIL_INSURANCE_POLICY',
                  required: false
                },
                {
                  name: 'Boleto Devolutivo',
                  key: 'RETURN_BILL',
                  required: false
                },
                {
                  name: 'Planilha de danos',
                  key: 'DAMAGE_WORKSHEET',
                  required: false
                },
                {
                  name: 'Planilha de pintura',
                  key: 'PAINTING_WORKSHEET',
                  required: false
                },
                {
                  name: 'Informativo de sinistro',
                  key: 'CLAIM_INFORMATION',
                  required: false
                },
                { name: 'Outros', key: 'OTHER', required: false }
              ]
            : [
                { name: 'Boleto Aluguel', key: 'RENT_BILL', required: true },
                {
                  name: 'Boleto Condomínio',
                  key: 'CONDOMINIUM_BILL',
                  required: false
                },
                {
                  name: 'Guia de IPTU',
                  key: 'IPTU_GUIDE',
                  required: false
                },
                {
                  name: 'Vistoria Inicial do Imóvel',
                  key: 'INITIAL_INSPECTION_PROPERTY',
                  required: values.damageControl
                },
                {
                  name: 'Vistoria Final do Imóvel',
                  key: 'FINAL_INSPECTION_PROPERTY',
                  required: values.damageControl
                },
                { name: 'Orçamentos', key: 'BUDGETS', required: values.damageControl },
                {
                  name: 'Termo de entrega das chaves',
                  key: 'TERM_DELIVERY_PROPERTY_KEYS',
                  required: true
                },
                {
                  name: 'RG do Locador',
                  key: 'LOCATOR_DOCUMENT',
                  required: false
                },
                {
                  name: 'Comprovante de Endereço Locador',
                  key: 'LOCATOR_ADDRESS_DOCUMENT',
                  required: false
                },
                {
                  name: 'RG/CPF do Locatário',
                  key: 'TENANT_DOCUMENT',
                  required: false
                },
                {
                  name: 'Contrato Garantti Locatário',
                  key: 'LOCATOR_GARANTTI_CONTRACT',
                  required:
                    values.claimEstate.insurerId && insurers
                      ? insurers
                          .find(c => c.id === values.claimEstate.insurerId)
                          ?.code.includes(InsurersCodeEnum.Garantti)
                        ? true
                        : false
                      : false
                },
                {
                  name: 'Imissão na posse',
                  key: 'POSSESSION_IMMISSION',
                  required: false
                },
                {
                  name: 'Acordo escritório',
                  key: 'OFFICE_AGREEMENT',
                  required: false
                },
                {
                  name: 'Apólice Seguro Incêndio',
                  key: 'FIRE_INSURANCE_POLICY',
                  required: false
                },
                {
                  name: 'Comprovante de Pagamento (planilha)',
                  key: 'PAYMENT_VOUCHER',
                  required: false
                },
                {
                  name: 'Declaração de débitos',
                  key: 'DEBITS_SHEET',
                  required: true
                },
                {
                  name: 'Acordo de Confissão de Dívidas',
                  key: 'DEBT_CONFESS_AGREEMENT',
                  required: false
                },
                {
                  name: 'Contrato de Locação',
                  key: 'LOCATION_QUOTATION',
                  required: true
                },
                {
                  name: 'Planilha de indenização',
                  key: 'INDEMNITY_WORKSHEET',
                  required: false
                },
                {
                  name: 'Planilha de acordo',
                  key: 'ACCORD_WORKSHEET',
                  required: false
                },
                {
                  name: 'Água',
                  key: 'WATER_BILL',
                  required: false
                },
                {
                  name: 'Luz',
                  key: 'ENERGY_BILL',
                  required: false
                },
                {
                  name: 'Apólice Seguro Fiança',
                  key: 'BAIL_INSURANCE_POLICY',
                  required: false
                },
                {
                  name: 'Boleto Devolutivo',
                  key: 'RETURN_BILL',
                  required: false
                },
                {
                  name: 'Planilha de danos',
                  key: 'DAMAGE_WORKSHEET',
                  required: false
                },
                {
                  name: 'Planilha de pintura',
                  key: 'PAINTING_WORKSHEET',
                  required: false
                },
                {
                  name: 'Informativo de sinistro',
                  key: 'CLAIM_INFORMATION',
                  required: false
                },
                { name: 'Outros', key: 'OTHER', required: false }
              ];

        let requireds = fileIdentifiers.filter(fi => fi.required === true);
        values.claimFiles.forEach(
          file => (requireds = requireds.filter(c => c.key !== file.name))
        );
        if (requireds.length > 0) {
          requireds.forEach(req => {
            warningNotification(
              'Ops',
              `Por favor, anexe um arquivo do tipo: ${req.name}`
            );
          });
          return;
        }
        try {
          setLoadingFiles(true);
          await submitFiles(values.claimFiles);
          setLoadingFiles(false);
        } catch (err) {
          console.log(err);
          dangerNotification('Erro', 'Falha ao enviar os arquivos');
          return;
        } finally {
          setLoadingFiles(false);
        }
        await submitClaimEstate(values);
      }
    }
  }

  async function handleBackStep() {
    switch (stepOfHireModal) {
      case 'claim_esate_info': {
        handleCloseModal();
        return;
      }
      case 'claim_values_info': {
        setStepOfHireModal('claim_esate_info');
        return;
      }
      case 'document_attachment': {
        setStepOfHireModal('claim_values_info');
        return;
      }
    }
  }

  async function submitFiles(
    files: {
      file: File;
      name: string;
    }[]
  ) {
    const responses = [];
    for (const file of files) {
      const { data: response } = await postFileAndGetPreSignAxios({
        filename: file.file.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/[`~!@#$%^&*()_|+-=?;:'",<>{}[]\/]/gi, ''),
        contentType: file.file.type,
        referencedTableName: 'CLAIM_ESTATES',
        referencedTableId: registerId,
        name: file.name
      });

      const fileToBlob = async (theFile: File) =>
        new Blob([new Uint8Array(await theFile.arrayBuffer())], {
          type: theFile.type
        });
      const blob = await fileToBlob(file.file);

      await fetch(response.uploadURL, {
        method: 'PUT',
        body: blob
      });
      responses.push({ id: response.id, contextType: file.name });
    }
    return responses;
  }

  async function submitClaimEstate(values: CreateClaimEstateInitialInitialValuesType) {
    setLoading(true);
    if (values.claimProperty.deliveryKeysDate) {
      values.claimProperty.deliveryKeysDate = FormatDateYMD(
        new Date(values.claimProperty.deliveryKeysDate!)
      );
    }

    const userId = get('uid');
    values.claimEstate.id = registerId;
    values.claimEstate.createdBy = userId!;
    values.claimValues.taxesValue = values.claimValues.taxesValue.map(c => ({
      ...c,
      totalInstallments: values.claimValues.taxesValue[0].totalInstallments
    }));
    const claimValues = values.claimValues.condominiumFee
      .concat(values.claimValues.energyBill)
      .concat(values.claimValues.gasBill)
      .concat(values.claimValues.paintingValue)
      .concat(values.claimValues.penalty)
      .concat(values.claimValues.propertyDamageValue)
      .concat(values.claimValues.rentValue)
      .concat(values.claimValues.taxesValue)
      .concat(values.claimValues.waterBill)
      .concat(values.claimValues.fireInsuranceBill);
    const cv: any = [];
    claimValues.forEach(e => {
      if (e.value) cv.push(e);
    });

    const cvFormatted = cv.map((claimValue: ClaimValueFormikType) => {
      const format = claimValue;
      format.dueDate = FormatDateYMD(new Date(claimValue.dueDate!));
      return format;
    });

    const payload = {
      claimEstate: {
        ...values.claimEstate,
        insurerId: values?.claimEstate?.insurerId || null
      },
      claimProperty: values.claimProperty,
      claimTenant: values.claimTenant,
      claimValues: cvFormatted
    };
    if (payload.claimEstate.notificationEmails as string[]) {
      payload.claimEstate.notificationEmails = (
        payload.claimEstate.notificationEmails as string[]
      ).join(';');
    }

    try {
      await createClaimEstate(payload);
      successNotification(
        'Sucesso',
        'Solicitação de abertura de sinistro realizada com sucesso!'
      );
    } catch (err) {
      dangerNotification('Erro', 'Erro ao efetuar solicitação de abertura de sinistro');
      console.log(err);
    } finally {
      setLoading(false);
      handleCloseModal();
      history.push('claim_estates');
    }
  }

  const ModalContent = ({ children, values }: ModalContentType) => (
    <>
      <Modal.Content scrolling>
        <Dimmer active={loadingFiles}>
          <Loader indeterminate> Enviando documentos... </Loader>
        </Dimmer>
        <Dimmer active={loading}>
          <Loader indeterminate> Comunicando solicitação... </Loader>
        </Dimmer>

        <div style={{ width: '100%' }}>
          <Step.Group widths={8}>
            <Step
              active={stepOfHireModal === 'claim_esate_info'}
              title="Informações Básicas"
              description="Informações sobre imóvel e locatário"
              icon={{ color: 'grey', name: 'home' }}
            />
            <Step
              active={stepOfHireModal === 'claim_values_info'}
              title="Sobre Valores"
              description="Datas de vencimento e valores devidos;"
              icon={{ color: 'grey', name: 'dollar sign' }}
            />

            <Step
              active={stepOfHireModal === 'document_attachment'}
              title="Documentos"
              description="Anexo dos documentos essenciais"
              icon={{ color: 'grey', name: 'upload' }}
            />
          </Step.Group>
        </div>

        {children}
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={() => handleBackStep()}>
          <Icon name="remove" />
          {stepOfHireModal === 'claim_esate_info' ? 'Sair' : 'Voltar'}
        </Button>
        <Button color="green" onClick={() => handleNextStep(values)}>
          <Icon name="checkmark" />{' '}
          {stepOfHireModal === 'document_attachment' ? 'Solicitar' : 'Próximo'}
        </Button>
      </Modal.Actions>
    </>
  );

  return (
    <div>
      <Modal
        style={{ height: 'auto', width: '70vw', zIndex: 10 }}
        closeIcon
        open={openHireProposalModal}
        trigger={
          !onlyModal ? (
            <Button primary icon="hotjar" type="button">
              Novo Sinistro
            </Button>
          ) : (
            <></>
          )
        }
        onOpen={() => handleOpenModal()}
        onClose={() => handleCloseModal()}
      >
        <Header style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <Icon name={'file alternate outline'} /> Abertura de Sinistro
          </div>

          <ModalInstructions
            openModal={openModal}
            toggleClose={toggleClose}
            toggleOpen={toggleOpen}
          />
        </Header>

        <Formik
          initialValues={CreateClaimEstateInitialInfo}
          validationSchema={() => true}
          onSubmit={() => true}
        >
          {innerValues => {
            switch (stepOfHireModal) {
              case 'claim_esate_info':
                return (
                  <ModalContent
                    values={innerValues.values}
                    children={
                      <ClaimEstateInfoStep
                        {...innerValues}
                        estates={estates}
                        insurers={insurers}
                        policy={policy}
                      />
                    }
                  />
                );
              case 'claim_values_info':
                return (
                  <ModalContent
                    values={innerValues.values}
                    children={<ClaimValuesStep {...innerValues} />}
                  />
                );
              case 'document_attachment':
                return (
                  <ModalContent
                    values={innerValues.values}
                    children={
                      <DocumentsComponent
                        fileIdentifiers={getFileIdentifiers(innerValues)}
                        {...innerValues}
                      />
                    }
                  />
                );
            }
          }}
        </Formik>
      </Modal>
    </div>
  );
};

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

export default connect(mapState, mapDispatch)(CreateClaimEstateModal);
