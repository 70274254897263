import styled from 'styled-components';
import { Table } from 'semantic-ui-react';

export const Header = styled(Table.Header)`
  background-color: #e1e8ed;
`;

export const TableWrapper = styled.div`
  font-size: 0.85em;
  font-weight: bold;

  .ui.table {
    border: none;
    text-align: center;
    color: #364445;
  }
`;

export const NotFoundWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #364445;
  font-size: 1.2em;
`;

export const FilterWrapper = styled.div`
  border: 1px solid rgba(138, 138, 150, 0.5);
  border-radius: 5px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 6px;
`;
