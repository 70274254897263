import axios from 'axios'
import * as Storage from '../services/storage';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

instance.interceptors.request.use((config) => {
  config.headers.Authorization = Storage.get('id_token');
  return config
})

//config.headers.uid = Storage.get('uid');

export default instance
