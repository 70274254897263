import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { styles } from '../styles';
import TableHeader from './TableHeader';

const PropertyDataTable = ({ data }: any) => {
  return (
    <>
      <View style={styles.tableContainer}>
        <TableHeader title={'ENDEREÇO DO IMÓVEL SEGURADO'} />

        <View style={styles.row}>
          <View style={styles.column}>
            <Text style={styles.label}>{'CEP'}</Text>
            <Text style={styles.value}>{data.zipcode}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.column}>
            <Text style={styles.label}>{'LOGRADOURO'}</Text>
            <Text style={styles.value}>{data.street}</Text>
          </View>

          <View style={styles.column}>
            <Text style={styles.label}>{'NÚMERO'}</Text>
            <Text style={styles.value}>{data.number}</Text>
          </View>

          <View style={styles.column}>
            <Text style={styles.label}>{'COMPLEMENTO'}</Text>
            <Text style={styles.value}>{data.complement}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.column}>
            <Text style={styles.label}>{'BAIRRO'}</Text>
            <Text style={styles.value}>{data.district}</Text>
          </View>

          <View style={styles.column}>
            <Text style={styles.label}>{'CIDADE'}</Text>
            <Text style={styles.value}>{data.city}</Text>
          </View>

          <View style={styles.column}>
            <Text style={styles.label}>{'UF'}</Text>
            <Text style={styles.value}>{data.state}</Text>
          </View>
        </View>
      </View>
    </>
  );
};

export default PropertyDataTable;
