import React, { useState } from 'react';
import { Grid, Input } from 'semantic-ui-react';
import { maskCpf, maskPhoneNumber } from '../../../../../../../../services/masks';
import CustomDatePicker from '../../../../../../../../components/CustomDatePicker';
import AppMaskedInput from '../../../../../../../../components/MaskedInput';
import { FormProps } from '../types';
import { getDataByDocument } from '../../../../../../../../services/documents';

interface TenantFormProps {
  form: FormProps;
  validateForm: boolean;
  setForm: React.Dispatch<React.SetStateAction<FormProps>>;
}

const SaveQuotationModalTenantForm: React.FC<TenantFormProps> = ({
  form,
  validateForm,
  setForm
}: TenantFormProps) => {
  const [loadDocument, setLoadDocument] = React.useState(false);
  const [disableNameField, setDisabledNameField] = useState<boolean>(true);

  async function getTenantByDocument(document: string) {
    try {
      const unformatedDoc = document.replace(/\D/g, '');
      const isCPF = unformatedDoc.length === 11;
      setLoadDocument(true);
      const data = await getDataByDocument(unformatedDoc);
      if (isCPF) {
        const birthDate = new Date(data.birth_date);
        birthDate.setTime(
          birthDate.getTime() + birthDate.getTimezoneOffset() * 60 * 1000
        );
        setForm((prevValue: FormProps) => ({
          ...prevValue,
          tenantIdentification: {
            ...prevValue.tenantIdentification,
            name: data.name,
            birthdate: birthDate
          }
        }));
        setDisabledNameField(true);
      } else {
        setForm((prevValue: FormProps) => ({
          ...prevValue,
          tenantIdentification: {
            ...prevValue.tenantIdentification,
            name: data.company,
            cellphone: data.phone,
            email: data.email
          }
        }));
        setDisabledNameField(false);
      }
    } catch (err) {
      console.log(err);
      setDisabledNameField(false);
    } finally {
      setLoadDocument(false);
      // setLoadResponsibleDocument(false);
    }
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={5}>
          <label>CPF*</label>
          <Input
            placeholder="CPF"
            fluid
            error={validateForm && form?.tenantIdentification?.document === ''}
            value={form?.tenantIdentification?.document}
            onChange={(_, data) => {
              setForm((prevValue: FormProps) => ({
                ...prevValue,
                tenantIdentification: {
                  ...prevValue.tenantIdentification,
                  document: maskCpf(data?.value)
                }
              }));
            }}
            onBlur={(e: any) => {
              getTenantByDocument(e.target.value);
            }}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <label>NOME*</label>
          <Input
            placeholder="Nome"
            fluid
            disabled={disableNameField}
            loading={loadDocument}
            value={form?.tenantIdentification?.name}
            error={validateForm && form?.tenantIdentification?.name === ''}
            onChange={(_, data) =>
              setForm((prevValue: FormProps) => ({
                ...prevValue,
                tenantIdentification: {
                  ...prevValue.tenantIdentification,
                  name: data?.value
                }
              }))
            }
          />
        </Grid.Column>
        <Grid.Column width={5}>
          <label>DATA DE NASCIMENTO*</label>
          <CustomDatePicker
            placeholderText={'Data de Nascimento'}
            loading={loadDocument}
            error={validateForm && !!form?.tenantIdentification?.birthdate}
            selected={form?.tenantIdentification?.birthdate ?? undefined}
            onChange={(date: Date) =>
              setForm((prevValue: FormProps) => ({
                ...prevValue,
                tenantIdentification: {
                  ...prevValue.tenantIdentification,
                  birthdate: date
                }
              }))
            }
            maxDate={new Date()}
            customInput={
              <AppMaskedInput
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/
                ]}
              />
            }
            dateFormat={'dd/MM/yyyy'}
            style={{
              marginTop: 0
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <label>EMAIL*</label>
          <Input
            placeholder="Email"
            type="email"
            fluid
            loading={loadDocument}
            error={validateForm && form?.tenantIdentification?.email === ''}
            value={form?.tenantIdentification?.email}
            onChange={(_, data) =>
              setForm((prevValue: FormProps) => ({
                ...prevValue,
                tenantIdentification: {
                  ...prevValue.tenantIdentification,
                  email: data?.value
                }
              }))
            }
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <label>Telefone*</label>
          <Input
            placeholder="Telefone"
            fluid
            loading={loadDocument}
            error={validateForm && form?.tenantIdentification?.phoneNumber === ''}
            value={form?.tenantIdentification?.phoneNumber}
            onChange={(_, data) =>
              setForm((prevValue: FormProps) => ({
                ...prevValue,
                tenantIdentification: {
                  ...prevValue.tenantIdentification,
                  phoneNumber: maskPhoneNumber(data?.value)
                }
              }))
            }
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SaveQuotationModalTenantForm;
