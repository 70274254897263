import { get } from './request';
import axiosRequest from './axios-instance';
import { dangerNotification } from './notification';

export interface FireInsuranceBillet {
  estateId: string;
  insurerId: string;
  contractCode: string;
  emissionCode: string;
};

export const getInvoices = async (filter: any) => {
  let query = '';
  let response;

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`
      }
    })
  }

  try {
    const [data, quantity] = await get(`/alfa-seguros/invoices?${query}`);

    response = {
      data,
      quantity
    }
  } catch (e) {
    throw e;
    response = null;
  }

  return response;
};

export const getInvoiceDetails = async (invoiceId: string) => {
  let response;

  try {
    const data = await get(`/alfa-seguros/invoices-details/${invoiceId}`);

    response = {
      data
    };
  } catch (e) {
    throw e;
    response = null;
  }

  return response;
};

export const getInvoicesBillet = async (dto: FireInsuranceBillet) => {
  let response;

  try {
    response = await axiosRequest.post('/alfa-seguros/invoice-billet', dto, { responseType: 'arraybuffer' });

    if (response.status !== 201) {
      return dangerNotification('Erro', 'Não foi possível baixar o boleto, por favor, tente mais tarde.');
    }

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const blobUrl = window.URL.createObjectURL(blob);
    const anchor = window.document.createElement('a');
    anchor.download = `boleto_${new Date().getTime()}.pdf`;
    anchor.href = blobUrl;
    anchor.click();
    window.URL.revokeObjectURL(blobUrl);
  } catch (e) {
    response = null;
    console.log(e);
  }

  return response;
};

export const getProLaboreDetails = async (contributionNumber: string) => {
  let response;

  try {
    const data = await get(`/alfa-seguros/prolabore-details/${contributionNumber}`);

    response = {
      data
    };
  } catch (e) {
    throw e;
    response = null;
  }

  return response;
};
