/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { SelectableModalProps } from './types';
import styled from 'styled-components';
import ReactToPrint from 'react-to-print';

export const ModalContent = styled(Modal.Content)`
  line-height: 1.8rem;
  div:last-child:not(:first-child){
    font-weight: bold;
  }
`;

const PrintedContainer = styled.div`
  @media print {
    @page {
      margin-top: 1cm;
      margin-bottom: 0.5cm;
      margin-left: 0.5cm;
      margin-right: 0.5cm;
    }

    .payment-container {
      page-break-after: always;
    }
  }
`;

type PrintableModalProps = {
  bondInsuranceId?: string;
}

const PrintableModalWithoutAttach = (props: SelectableModalProps & PrintableModalProps): JSX.Element => {
  const { content, header, onClose } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const open = (): void => { setIsOpen(true); };
  const close = (): void => { setIsOpen(false); };

  const componentRef = useRef(null);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const confirmButton = (<ReactToPrint
    trigger={() =>
      <Button
        color={"blue"}
        icon={'print'}
        type="button"
        floated='right'
        style={{ marginBottom: '10px' }}>
        <Icon name='print' /> Imprimir
      </Button>
    }
    content={() => componentRef.current!} />);

  return (
    <Modal open={isOpen} onOpen={open} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content scrolling><PrintedContainer ref={componentRef}>{content}</PrintedContainer></Modal.Content>
      <Modal.Actions>{[confirmButton]}</Modal.Actions>
    </Modal>
  );
};
export default connect()(PrintableModalWithoutAttach);
