import React, { useState, useEffect } from 'react';
import {
  Table,
  Dimmer,
  Segment,
  Loader,
  Menu,
  Icon,
  Button,
  Label,
  Popup
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { maskCpfOrCnpj } from '../../services/masks';
import { iRootState, iRootDispatch } from '../../store';
import { BondInsuranceDetails } from './components/BondInsuranceDetails';
import { ClickableTableCell, SelectableTableRow } from '../../styles';
import { GetCanceledBondInsurancesResponseDTO } from '../../dtos/canceled-bond-insurance/get-canceled-bond-insurances-response.dto';
import { TranslatedCanceledStatus } from './types';
import moment from 'moment-timezone';
import FollowUpModal from '../../components/GeneralFollowUpModal';
import { ExternalBondInsuranceDetails } from './components/ExternalBondInsuranceDetails';
import ModalAttachFiles from '../../components/ModalAttachFiles';
import ChangeStatusModal from './components/AnalysisProgressModal/ConfirmationModal/ChangeStatusModal';
import { Canceled } from './components/AnalysisProgressModal/types/ConfirmCancelModalProps';
import { hasPermission } from '../../components/AuthorizationContainer';
import { ActionEnum } from '../../enums/authz-action.enum';
import { FeatureEnum } from '../../enums/authz-feature.enum';
import { BondInsuranceStatusEnum } from '../../enums/bond-insurance-status.enum';
import EditModal from './components/EditModal';
import { UpdateCancelation } from './components/EditModal/types';
import { dangerNotification, successNotification } from '../../services/notification';
import { updateCanceledBondInsurance } from '../../services/bond-insurance';
import { CustoDropDownItemProps } from '../../components/types';

const ProposalsTableComponent = styled.div`
  margin-top: 1rem;
`;

const Header = styled(Table.Header)`
  background-color: #e1e8ed;
`;

const TableWrapper = styled.div`
  font-size: 0.85em;
  font-weight: bold;

  .ui.table {
    border: none;
    text-align: center;
    color: #364445;
  }
`;

const NotFoundWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #364445;
  font-size: 1.2em;
`;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapState = (state: iRootState) => ({
  user: state.user,
  availableBrokersAndEstates: state.availableBrokersAndEstates
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatch = (dispatch: iRootDispatch) => ({});

type Props = {
  canceledBondInsurances: GetCanceledBondInsurancesResponseDTO[];
  responsibleOptions: CustoDropDownItemProps[];
  notRead: number[];
  setNotRead: Function;
  isLoading: boolean;
  getData: Function;
  currentPage: number;
  setCurrentPage: Function;
  dataCount: number;
} & ReturnType<typeof mapState>;

const emptyCanceled: Canceled = {
  bondInsurance: {
    id: '',
    estate: {
      name: ''
    },
    property: {
      city: '',
      number: '',
      state: '',
      street: ''
    },
    tenant: {
      name: '',
      document: ''
    }
  },
  status: '',
  updatedAt: ''
};

const CanceledBondInsurancesTable = (props: Props): JSX.Element => {
  const {
    canceledBondInsurances,
    responsibleOptions,
    isLoading,
    getData,
    user,
    currentPage,
    setCurrentPage,
    dataCount,
    setNotRead,
  } = props;
  const [openExternal, setOpenExternal] = useState<string>('');
  const [indexOpen, setIndexOpen] = useState<string>('');
  const [bondInsuranceIdOpen, setBondInsuranceIdOpen] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openExternalModal, setOpenExternalModal] = useState<boolean>(false);
  const [openFollowUp, setOpenFollowUp] = useState<boolean>(false);
  const [openEditStatus, setOpenEditStatus] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [idFollowUp, setIdFollowUp] = useState<string>('');
  const [idCanceled, setIdCanceled] = useState<string>('');
  const [index, setIndex] = useState<number>();
  const [canceled, setCanceled] = useState<Canceled>(emptyCanceled);
  const [userCanEdit, setUserCanEdit] = useState<boolean>(false);
  const [userCanUpdate, setUserCanUpdate] = useState<boolean>(false);

  useEffect(() => {
    const hasedit = hasPermission(ActionEnum.edit, FeatureEnum.canceleds);
    setUserCanEdit(hasedit);

    const hasupdate = hasPermission(ActionEnum.update, FeatureEnum.canceleds);
    setUserCanUpdate(hasupdate);
  }, []);

  useEffect(() => {
    setOpenModal(!!indexOpen.length);
  }, [indexOpen]);

  useEffect(() => {
    setOpenExternalModal(!!openExternal.length);
  }, [openExternal]);

  useEffect(() => {
    setOpenFollowUp(!!idFollowUp.length);
  }, [idFollowUp]);

  const lastPage = Math.floor(dataCount / 10) + 1;

  function getStatus(status: string) {
    switch (status) {
      case 'CANCELLATION_IN_PROGRESS': {
        return TranslatedCanceledStatus.CANCELLATION_IN_PROGRESS;
      }
      case 'CANCELLED_IN_PROGRESS': {
        return TranslatedCanceledStatus.CANCELLATION_IN_PROGRESS;
      }
      case 'CANCELED': {
        return TranslatedCanceledStatus.CANCELED;
      }
      case 'CANCELLED': {
        return TranslatedCanceledStatus.CANCELED;
      }
      case 'UNCANCELLED': {
        return TranslatedCanceledStatus.UNCANCELLED;
      }
      case 'INSURER_HIRE_PENDING': {
        return TranslatedCanceledStatus.PENDING;
      }
      case 'CANCELLATION_PENDING': {
        return TranslatedCanceledStatus.PENDING;
      }
      case 'PENDING': {
        return TranslatedCanceledStatus.PENDING;
      }
      case `PENDING_BY_INSURER`: {
        return TranslatedCanceledStatus.PENDING_BY_INSURER;
      }
      case `FINANCIAL_PENDING`: {
        return TranslatedCanceledStatus.FINANCIAL_PENDING;
      }
      case `CLAIM_CANCELLATION`: {
        return TranslatedCanceledStatus.CLAIM_CANCELLATION;
      }
      case `EXPIRED`: {
        return TranslatedCanceledStatus.EXPIRED;
      }

      default: {
        return status;
      }
    }
  }

  const handleFollowUp = (index: number) => {
    setOpenFollowUp(true);
    setIndex(index);
  };

  const handleOpen = () => {
    setOpenFollowUp(true);
  };

  const handleClose = () => {
    setOpenFollowUp(false);
    setNotRead(index, 0);
  };

  const handleOpenEditStatusModal = () => {
    setOpenEditStatus(true);
  };

  const handleCloseEdit = () => {
    setOpenEditStatus(false);
  };

  const handleOpenEditModal = () => {
    setIsOpenEditModal(true);
  };

  const handleSetCanceled = (cancelled: any, status: string) => {
    if (cancelled.bondInsurance) {
      const tenant = cancelled.bondInsurance.legalTenant
        ? {
            name: cancelled.bondInsurance.legalTenant.company,
            document: cancelled.bondInsurance.legalTenant.document
          }
        : {
            name: cancelled.bondInsurance.naturalTenant.main_tenant_name,
            document: cancelled.bondInsurance.naturalTenant.main_tenant_cpf
          };
      const property = {
        city: cancelled.bondInsurance.property.city,
        street: cancelled.bondInsurance.property.street,
        state: cancelled.bondInsurance.property.state,
        number: cancelled.bondInsurance.property.number
      };
      const canceled: Canceled = {
        bondInsurance: {
          id: cancelled.bondInsurance.id,
          estate: {
            name: cancelled.bondInsurance.estate
              ? cancelled.bondInsurance.estate?.name
              : ''
          },
          tenant: tenant,
          property: property
        },
        status: status,
        updatedAt: cancelled.updatedAt ? cancelled.updatedAt : ''
      };

      setCanceled(canceled);
    } else {
      const tenant = {
        name: cancelled.externalCancelation.tenant.fullName,
        document: cancelled.externalCancelation.tenant.document
      };
      const property = {
        city: cancelled.externalCancelation.property.city,
        street: cancelled.externalCancelation.property.street,
        state: cancelled.externalCancelation.property.state,
        number: cancelled.externalCancelation.property.number
      };
      const canceled: Canceled = {
        bondInsurance: {
          id: cancelled.externalCancelation.id,
          estate: {
            name: cancelled.externalCancelation.estate
              ? cancelled.externalCancelation.estate?.name
              : ''
          },
          tenant: tenant,
          property: property
        },
        status: status,
        updatedAt: cancelled.updatedAt ? cancelled.updatedAt : ''
      };

      setCanceled(canceled);
    }
  };

  function changeStatus(canceledId: string, status: string): void {
    const ind = canceledBondInsurances.findIndex(canceled => canceled.id === canceledId);
    if (canceledBondInsurances[ind].status !== status)
      canceledBondInsurances[ind].status = status;
  }

  const handleSubmitEditModal = async (id: string, data: UpdateCancelation) => {
    try {
      await updateCanceledBondInsurance(id, data);
      setIsOpenEditModal(false);
      successNotification('Sucesso!', 'Sua análise foi atualizada com sucesso.');
    } catch (e) {
      dangerNotification('Erro!', `Erro ao atualizar análise: ${(e as Error).message}`);
    } finally {
      getData();
    }
  };

  const blockIfIsAnalysis = (canceled: any): boolean => {
    if (canceled.externalCancelation) {
      return true;
    }
    if (!canceled?.bondInsurance?.bondInsuranceHiredData?.insurer) {
      return false;
    }
    return true;
  };

  return (
    <>
      {openEditStatus && (
        <ChangeStatusModal
          openModal={openEditStatus}
          setOpen={handleOpenEditStatusModal}
          closeModal={handleCloseEdit}
          canceledId={idCanceled}
          callback={() => getData()}
          canceled={canceled}
        />
      )}
      {isOpenEditModal && (
        <EditModal
          isOpen={isOpenEditModal}
          onClose={() => setIsOpenEditModal(false)}
          handleSubmit={data => handleSubmitEditModal(idCanceled, data)}
          canceledId={idCanceled}
          canceled={canceled}
          responsibleOptions={responsibleOptions}
        />
      )}

      <ProposalsTableComponent>
        <Dimmer.Dimmable as={Segment} dimmed={isLoading}>
          <Dimmer active={isLoading} inverted>
            <Loader />
          </Dimmer>
          {canceledBondInsurances && canceledBondInsurances.length > 0 ? (
            <TableWrapper>
              <Table>
                <Header>
                  <Table.Row>
                    <Table.HeaderCell width={2}>CANCELADA EM</Table.HeaderCell>
                    <Table.HeaderCell width={3}>IMOBILIÁRIA</Table.HeaderCell>
                    <Table.HeaderCell width={3}>SEGURADORA</Table.HeaderCell>
                    <Table.HeaderCell width={2}>STATUS</Table.HeaderCell>
                    <Table.HeaderCell width={2}>LOCATÁRIO</Table.HeaderCell>
                    <Table.HeaderCell width={2}>ENDEREÇO</Table.HeaderCell>
                    <Table.HeaderCell width={3}>RESPONSÁVEL</Table.HeaderCell>
                    <Table.HeaderCell width={3}>AÇÕES</Table.HeaderCell>
                  </Table.Row>
                </Header>
                <Table.Body>
                  {canceledBondInsurances.map((cancelled, index) => {
                    let infos = {
                      estateName: '',
                      insurerName: '',
                      tenantName: '',
                      tenantDocument: '',
                      address: '',
                      purpose: '',
                      responsibleName: '',
                    };

                    if (cancelled.externalCancelation) {
                      const { property, tenant, estate, insurer } = cancelled.externalCancelation;
                      const address = `${property.street}${
                        property.number ? `, ${property.number}` : ''
                      }${property.complement ? ` - ${property.complement}` : ''}`;

                      infos = {
                        estateName: estate.name,
                        insurerName: insurer.name,
                        tenantName: tenant.fullName,
                        tenantDocument: tenant.document,
                        address,
                        purpose: '',
                        responsibleName: cancelled.responsible?.name ?? '',
                      };
                    } else {
                      const {
                        legalTenant,
                        naturalTenant,
                        estate,
                        property,
                        bondInsuranceHiredData,
                        purpose
                      } = cancelled.bondInsurance;

                      const insurerName = bondInsuranceHiredData?.insurer.name || (
                        <span style={{ color: '#ccc', fontWeight: 'normal' }}>
                          (Sem Seguradora)
                        </span>
                      );

                      const tenantName = legalTenant
                        ? legalTenant.company
                        : naturalTenant.main_tenant_name;
                      const tenantDocument = legalTenant
                        ? legalTenant.document
                        : naturalTenant.main_tenant_cpf;
                      const address = `${property.street}${
                        property.number ? `, ${property.number}` : ''
                      }${property.complement ? ` - ${property.complement}` : ''}`;

                      infos = {
                        estateName: estate!.name,
                        insurerName,
                        tenantName,
                        tenantDocument,
                        address,
                        purpose,
                        responsibleName: cancelled.responsible?.name ?? '',
                      };
                    }
                    const { id, createdAt } = cancelled;

                    const status = getStatus(cancelled.status);

                    let naoLeu = 0;

                    for (const message of cancelled.followup) {
                      if (message) {
                        if (
                          user.id !== message.senderId &&
                          message.viewedBy &&
                          !message.viewedBy.includes(user.id)
                        ) {
                          naoLeu++;
                        }
                      }
                    }

                    const clickableCell = cancelled.externalCancelation
                      ? () => {
                          setOpenExternal(id);
                          setBondInsuranceIdOpen(cancelled.externalCancelation.id);
                        }
                      : () => {
                          setIndexOpen(id);
                          setBondInsuranceIdOpen(cancelled.bondInsurance.id);
                        };

                    return (
                      <>
                        {openFollowUp &&
                          (bondInsuranceIdOpen == cancelled?.bondInsurance?.id ||
                            bondInsuranceIdOpen ==
                              cancelled?.externalCancelation?.id) && (
                            <FollowUpModal
                              isOpen={openFollowUp}
                              onOpen={handleOpen}
                              onClose={handleClose}
                              header="Follow Up"
                              id={cancelled.id}
                              context={'CANCELED'}
                              user={user}
                            />
                          )}
                        <SelectableTableRow key={id} style={{ cursor: 'pointer' }}>
                          <ClickableTableCell onClick={clickableCell}>
                            {cancelled.externalCancelation && (
                              <Popup
                                content={'Cancelamento Externo'}
                                position={'bottom right'}
                                trigger={
                                  <Label
                                    empty
                                    circular
                                    color={'blue'}
                                    size={'mini'}
                                    style={{ position: 'relative', left: -10 }}
                                  />
                                }
                              />
                            )}
                            {moment(createdAt).format('DD/MM/YYYY HH:mm')}
                          </ClickableTableCell>
                          <ClickableTableCell onClick={clickableCell}>
                            {infos?.estateName}
                          </ClickableTableCell>
                          <ClickableTableCell onClick={clickableCell}>
                            {infos.insurerName}
                          </ClickableTableCell>
                          <ClickableTableCell onClick={clickableCell}>
                            {status}
                          </ClickableTableCell>
                          <ClickableTableCell onClick={clickableCell}>
                            {infos.tenantName}
                            <br />({maskCpfOrCnpj(infos.tenantDocument)})
                          </ClickableTableCell>
                          {infos.purpose ? (
                            <ClickableTableCell onClick={clickableCell}>
                              {infos.address}
                              <br />(
                              {infos.purpose === 'RESIDENTIAL'
                                ? 'Residencial'
                                : 'Comercial'}
                              )
                            </ClickableTableCell>
                          ) : (
                            <ClickableTableCell onClick={clickableCell}>
                              {infos.address}
                            </ClickableTableCell>
                          )}
                          <ClickableTableCell onClick={clickableCell}>
                            {infos.responsibleName}
                          </ClickableTableCell>
                          {/* //estate?.user.name */}
                          <Table.Cell>
                            <Menu compact>
                              <Menu.Item>
                                <Button
                                  primary
                                  icon="chat"
                                  onClick={() => {
                                    const bondId = cancelled.externalCancelation
                                      ? cancelled.externalCancelation.id
                                      : cancelled.bondInsurance.id;
                                    // console.log({ chat: bondId })
                                    setIdFollowUp(id);
                                    setBondInsuranceIdOpen(bondId);
                                    handleFollowUp(index);
                                  }}
                                />
                                {naoLeu !== 0 && (
                                  <Label color="red" circular floating>
                                    {naoLeu}
                                  </Label>
                                )}
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  const bondId = cancelled.externalCancelation
                                    ? cancelled.externalCancelation.id
                                    : cancelled.bondInsurance.id;
                                }}
                              >
                                <ModalAttachFiles
                                  message={`A inserção de documentos é obrigatória para a solicitação de cancelamento.`}
                                  trigger={<Button color="teal" icon="attach" />}
                                  bondInsuranceId={
                                    cancelled.externalCancelation
                                      ? cancelled.externalCancelation.id
                                      : cancelled.bondInsurance.id
                                  }
                                  disabled={
                                    cancelled?.status === BondInsuranceStatusEnum.canceled
                                  }
                                  brokerOnly={true}
                                />
                              </Menu.Item>
                              {status !== 'Não concluído' &&
                                blockIfIsAnalysis(cancelled) &&
                                ( userCanUpdate || userCanEdit ) &&
                                (
                                  <Menu.Item>
                                    <Button
                                      color="yellow"
                                      icon="pencil"
                                      onClick={() => {
                                        handleSetCanceled(cancelled, status);
                                        setIdCanceled(cancelled.id);
                                        if (userCanEdit) {
                                          handleOpenEditModal();
                                        } else {
                                          handleOpenEditStatusModal();
                                        }
                                      }}
                                    />
                                  </Menu.Item>
                                )}
                            </Menu>
                          </Table.Cell>
                        </SelectableTableRow>
                      </>
                    );
                  })}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="17">
                      <Menu floated="right" pagination>
                        <Menu.Item>
                          <p>{`${currentPage * 10 - 9} - ${
                            dataCount < 10 || dataCount < currentPage * 10
                              ? dataCount
                              : currentPage * 10
                          } de ${dataCount}`}</p>
                        </Menu.Item>
                        <Menu.Item
                          disabled={currentPage === 1}
                          as="a"
                          icon
                          onClick={() => setCurrentPage(1)}
                        >
                          <Icon name="angle double left" />
                        </Menu.Item>
                        <Menu.Item
                          disabled={currentPage === 1}
                          as="a"
                          icon
                          onClick={() => setCurrentPage(currentPage - 1)}
                        >
                          <Icon name="chevron left" />
                        </Menu.Item>
                        <Menu.Item as="a">{currentPage}</Menu.Item>
                        <Menu.Item
                          disabled={
                            currentPage === lastPage || canceledBondInsurances.length < 10
                          }
                          as="a"
                          icon
                          onClick={() => setCurrentPage(currentPage + 1)}
                        >
                          <Icon name="chevron right" />
                        </Menu.Item>
                        <Menu.Item as="a" icon>
                          <Icon
                            disabled={
                              currentPage === lastPage ||
                              canceledBondInsurances.length < 10
                            }
                            name="angle double right"
                            onClick={() => setCurrentPage(lastPage)}
                          />
                        </Menu.Item>
                      </Menu>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </TableWrapper>
          ) : (
            <NotFoundWrapper>
              <p>Análises não encontradas</p>
            </NotFoundWrapper>
          )}
        </Dimmer.Dimmable>
      </ProposalsTableComponent>

      <BondInsuranceDetails
        isOpen={openModal}
        id={indexOpen}
        onClose={() => {
          setIndexOpen('');
          setBondInsuranceIdOpen('');
        }}
        bondInsuranceId={bondInsuranceIdOpen}
        changeStatus={changeStatus}
      />

      <ExternalBondInsuranceDetails
        isOpen={openExternalModal}
        id={openExternal}
        onClose={() => {
          setOpenExternal('');
          setBondInsuranceIdOpen('');
        }}
        bondInsuranceId={bondInsuranceIdOpen}
        changeStatus={changeStatus}
      />
    </>
  );
};

export default connect(mapState, mapDispatch)(CanceledBondInsurancesTable);
