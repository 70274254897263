import { useEffect, useRef } from 'react'

// This detects clicks that occur outside of the React node the hook is used in and calls a callback function.
// This is useful for dialogs, tooltips, and other functionality we might want to dismiss.

type Callback = () => void

function useClickOutside<T extends HTMLElement>(
  callback: Callback
): React.RefObject<T> {
  const ref = useRef<T>(null)

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (ref?.current && !ref?.current?.contains(event.target as Node)) {
        callback()
      }
    }
    setTimeout(() => {
      document.addEventListener('click', handleClick)
    }, 0)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [callback])

  return ref
}

export default useClickOutside
