import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Radio,
  Grid,
  Input,
  Dropdown as DropdownNativo,
  Icon,
  Button,
  Checkbox
} from 'semantic-ui-react';
import { Field, FormikProps } from 'formik';
import SectionLabel from '../../../../../../components/SectionLabel';
import { FieldLabel } from '../../../../../../styles';
import { CreateCapitalizationType } from '../../Formik/types/CreateCapitalizationInitialValuesTypes';
import { maskZipCode } from '../../../../../../services/masks';
import { AddressService } from '../../../../../../services/address';
import { hirePropertyTypeOptions } from '../../../../../bond-insurance-budget-form/types';
import { PurposesEnum } from '../../../../../../enums/purposes.enum';

const CapitalizationPropertyStep = ({
  values,
  setFieldValue
}: FormikProps<CreateCapitalizationType>) => {
  const [cepLoading, setCepLoading] = useState(false);

  const onChangeCep = (zipCode: string): void => {
    const maskedValue = maskZipCode(zipCode);

    setFieldValue('property.zipCode', maskedValue);
    if (maskedValue.length >= 9) {
      setCepLoading(true);

      AddressService.getAddressByZipCode(zipCode)
        .then(address => {
          setFieldValue('property.city', address.city);
          setFieldValue('property.district', address.district);
          setFieldValue('property.state', address.state);
          setFieldValue('property.street', address.street);
          setCepLoading(false);
        })
        .catch(e => {
          console.log('address error:', e);
        });
    }
  };

  return (
    <div style={{ padding: '0 0 0 10px' }}>
      <SectionLabel text="Dados do imóvel" />
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column>
            <FieldLabel>CEP </FieldLabel>
            <Field
              name={`property.zipcode`}
              value={values.property.zipCode}
              onChange={(e: any) => {
                onChangeCep(e.target.value);
              }}
              component={Input}
              maxLength={9}
              fluid
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>Estado</FieldLabel>
            <Field
              name={`property.state`}
              component={Input}
              value={values.property.state}
              onChange={(e: any) => {
                setFieldValue('property.state', e.target.value);
              }}
              fluid
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>Cidade</FieldLabel>
            <Field
              name={`property.city`}
              component={Input}
              value={values.property.city}
              onChange={(e: any) => {
                setFieldValue(`property.city`, e.target.value);
              }}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <FieldLabel>Bairro</FieldLabel>
            <Field
              name={`property.district`}
              component={Input}
              value={values.property.district}
              onChange={(e: any) => {
                setFieldValue(`property.district`, e.target.value);
              }}
              fluid
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>Logradouro</FieldLabel>
            <Field
              name={`property.street`}
              component={Input}
              value={values.property.street}
              onChange={(e: any) => {
                setFieldValue(`property.street`, e.target.value);
              }}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <FieldLabel>Número </FieldLabel>
            <Field
              name={`property.number`}
              component={Input}
              value={values.property.number}
              onChange={(e: any) => {
                setFieldValue(`property.number`, e.target.value);
              }}
              fluid
            />
          </Grid.Column>

          <Grid.Column width={12}>
            <FieldLabel>Complemento</FieldLabel>
            <Field
              name={`property.complement`}
              component={Input}
              value={values.property.complement}
              onChange={(e: any) => {
                setFieldValue(`property.complement`, e.target.value);
              }}
              disabled={values.property.hasComplement}
            />
            <Checkbox
              label="Sem Complemento"
              checked={values.property.hasComplement}
              onChange={(e: any, data) => {
                setFieldValue('property.hasComplement', data.checked);
              }}
              style={{
                marginLeft: 5
              }}
            />
          </Grid.Column>
          <Grid.Column verticalAlign="middle" width={6}></Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>
            <FieldLabel>Tipo do Imóvel</FieldLabel>
            <DropdownNativo
              upward
              style={{ width: '100%' }}
              value={values.property.propertyType}
              name={`property.propertyType`}
              options={hirePropertyTypeOptions}
              placeholder={'Selecionar...'}
              selection
              onChange={(e, { value }) => setFieldValue('property.propertyType', value)}
              clearable
            />
          </Grid.Column>
          <Grid.Column width={10}>
            <FieldLabel>Tipo da Locação</FieldLabel>
            <Grid.Column width={6}>
              <Radio
                label="Residencial"
                name={`locationType`}
                value={PurposesEnum.residential}
                checked={values.locationType === PurposesEnum.residential}
                onChange={(_, data) => {
                  setFieldValue(data.name!, data.value);
                }}
              />
            </Grid.Column>

            <Grid.Column width={4}>
              <Radio
                label="Comercial"
                name={`locationType`}
                value={PurposesEnum.commercial}
                checked={values.locationType === PurposesEnum.commercial}
                onChange={(_, data) => {
                  setFieldValue(data.name!, data.value);
                }}
              />
            </Grid.Column>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default CapitalizationPropertyStep;
