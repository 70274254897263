import React, { useEffect } from "react";
import { Button, Grid, Icon, Input } from "semantic-ui-react";
import { Field, FormikProps } from "formik";
import SectionLabel from "../../../../../../components/SectionLabel";
import { NotificationEmailsValues } from "../../Formik/initialStates";
import _ from "lodash";
import { NotificationEmailsProps } from "../../types/NotificationEmailsModalProps";
import { get } from "../../../../../../services/storage";

const NotificationEmailsForm = ({
  callbackSubmit,
  setFlagSubmit,
  setFieldValue,
  flagSubmit,
  canceledId,
  values,
  actionText,
  emails
}: FormikProps<typeof NotificationEmailsValues> &
NotificationEmailsProps) => {

  const userId = get("uid");

  useEffect(() => {
    function submitForm() {
      const error = false;
      setFlagSubmit && setFlagSubmit(false);
      if (error) {
        return;
      }
      const payload = {
        emails: values.notificationEmails,
        userId
      };
      callbackSubmit(payload);
    }
    flagSubmit && submitForm();
  }, [flagSubmit]);

  useEffect(() => {
    const emailList = emails ? emails.split(';') : [''];
    setFieldValue(`notificationEmails`, emailList);
  }, []);

  return (
    <div>
      <SectionLabel text={actionText} />
      <Grid columns="16" style={{ paddingLeft: "1em" }}>
        {(values.notificationEmails as string[]).map(
          (notificationEmail, index) => {
            return (
              <Grid.Row>
                <Grid.Column width={4}>
                  <Field
                    value={notificationEmail}
                    component={Input}
                    fluid
                    onChange={(e: any, { value }: { value: string }) => {
                      setFieldValue(
                        `notificationEmails[${index}]`,
                        value
                      );
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={1}>
                  <Icon
                    name={"remove circle"}
                    color={"grey"}
                    style={{ cursor: "pointer", paddingTop: "5px" }}
                    size={"large"}
                    onClick={() => {
                      if (values.notificationEmails?.length === 1) {
                        setFieldValue(
                          `notificationEmails`,
                          [""]
                        );
                      } else {
                        const arr1 =
                          values.notificationEmails?.slice(0, index);
                        const arr2 =
                          values.notificationEmails?.slice(
                            index + 1
                          );
                        const newArr = [...arr1 as string[], ...arr2 as string[]];
                        setFieldValue(`notificationEmails`, newArr);
                      }
                    }}
                  />
                </Grid.Column>
                {index + 1 === values.notificationEmails?.length && (
                  <Grid.Column width={5}>
                    <Button
                      primary
                      onClick={() => {
                        if (values.notificationEmails?.length) {
                          const lastEmail =
                            values.notificationEmails[
                            values.notificationEmails.length - 1
                            ];
                          if (lastEmail.length > 0)
                            setFieldValue(`notificationEmails`, [
                              ...values.notificationEmails as string[],
                              "",
                            ]);
                        } else {
                          setFieldValue(
                            `notificationEmails[${index + 1}]`,
                            ""
                          );
                        }
                      }}
                    >
                      <Icon name={"plus circle"} />
                      Adicionar E-mail
                    </Button>
                  </Grid.Column>
                )}
              </Grid.Row>
            );
          }
        )}
      </Grid>
    </div>
  );
};
export default NotificationEmailsForm;
