import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { iRootDispatch, iRootState } from '../../store';
import SectionLabel from '../../components/SectionLabel';
import { Button, TextArea, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { getUser, updateUser } from '../../services/user';
import {
  maskPhoneNumber,
  unmaskPhoneNumber,
  maskCpf,
  unmaskCpfOrCnpj
} from '../../services/masks';
import { successNotification, dangerNotification } from '../../services/notification';
import { FieldLabel } from '../../styles';
import { UserRolesEnum } from '../../enums/user-roles.enum';
import { UserStatusEnum } from '../../enums/user-status.enum';
import * as Yup from 'yup';
import {
  getBroker,
  getBrokerObjectFromGetBrokerDTO,
} from '../../services/broker';
import {
  BrokersToCustomDropDownOptions,
} from '../../util';
import { AvailableBrokersAndEstatesState } from '../../store/types/temp-types';
import { BrokerForGetBrokerResponseDTO } from '../../dtos/broker/broker-for-get-broker-response.dto';
import { BrokerState } from '../../types/BrokerState';

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

type OptionsType = {
  value?: string;
  text: string;
  key: number;
};

const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 5px;

  > textarea {
    background-color: #f5f8fa;
    width: 135%;
    border-radius: 4px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    padding: 10px 4px;
    outline: 0;

    &:focus {
      border-color: #85b7d9;
    }
  }

  > label {
    // font-weight: bold;
    margin-bottom: 5px;
  }
`;

export type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const statusOptions: OptionsType[] = [
  { key: 0, value: UserStatusEnum.active, text: 'Ativo' },
  { key: 1, value: UserStatusEnum.inactive, text: 'Inativo' },
  { key: 2, value: UserStatusEnum.pending, text: 'Pendente' }
];

const UsersForm: React.FC<Props> = (props: Props) => {
  const { role } = props.user;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [loadedUser, setLoadedUser] = useState<any>({ id: undefined });
  const [isReady, setIsReady] = useState<boolean>(false);
  const [previousEstate, setPreviousEstate] = useState<string>('');
  const [previousFranchise, setPreviousFranchise] = useState<string>('');

  const [franchiseOptions, setFranchiseOptions] = useState<OptionsType[]>(
    props.availableBrokersAndEstates.franchises
  );
  const [estateOptions, setEstateOptions] = useState<OptionsType[]>([]);

  const [selectedEstate, setSelectedEstate] = useState<string>('');
  const [selectedFranchise, setSelectedFranchise] = useState<string>('');

  const [userOptions, setUserOptions] = useState<OptionsType[]>([]);

  const confiaxUsers = [
    UserRolesEnum.brokerAdmin,
    UserRolesEnum.brokerAnalyst,
    UserRolesEnum.accountManager,
    UserRolesEnum.cancelationAnalyst,
    UserRolesEnum.claimAnalyst
  ];
  const estateUsers = [UserRolesEnum.estateAdmin, UserRolesEnum.estateUser];
  const franchiseUsers = [UserRolesEnum.franchiseAdmin];

  useEffect(() => {
    (async () => {
      const { id } = (props as any).location.state;

      if (id) {
        const user = await getUserById(id);

        const dto = {
          id: user.id,
          name: user.name,
          email: user.email,
          estate: user.estate,
          franchise: user.franchise,
          role: user.role,
          cellphone: user.cellphone,
          doc: user.doc,
          status: user.status,
          observation: user.observation,
          estateId: user.estate?.id
        };
        const estate = user.estate?.id || null;
        const franchise = user.franchise?.id || null;
        if (estate) {
          setPreviousEstate(String(estate));
          setEstateOptions([
            { key: user.estate.id, text: user.estate.name, value: user.estate.id }
          ]);
          setSelectedEstate(String(estate));
          setFranchiseOptions(props.availableBrokersAndEstates.franchises);
        }

        if (franchise) {
          setPreviousFranchise(String(franchise));
          setFranchiseOptions([
            {
              key: user.franchise.id,
              text: user.franchise.name,
              value: user.franchise.id
            }
          ]);
          setSelectedFranchise(String(franchise));
          const options = props.availableBrokersAndEstates.estates.map(estate => {
            return {
              key: estate.key,
              text: estate.text,
              value: estate.value.id
            };
          });
          setEstateOptions(options);
        }

        if (!franchise && !estate) {
          const options = props.availableBrokersAndEstates.estates.map(estate => {
            return {
              key: estate.key,
              text: estate.text,
              value: estate.value.id
            };
          });
          setEstateOptions(options);
          setFranchiseOptions(props.availableBrokersAndEstates.franchises);
        }

        setLoadedUser(dto);
        setIsReady(true);
      }
    })();
  }, [props]);

  useEffect(() => {
    if (userOptions.length === 0) {
      setLoading(true);
      const confiaxOptions = props.availableBrokersAndEstates.roles.filter(role =>
        confiaxUsers.includes(role.value as UserRolesEnum)
      );
      setUserOptions(confiaxOptions);
      setLoading(false);
    }
  }, [userOptions]);

  const getUserById = async (id: string) => {
    setLoading(true);
    const searchUser = await getUser(id);

    setLoading(false);
    if (searchUser) {
      return searchUser;
    }
  };

  const update = async (payload: any) => {
    setLoading(true);
    try {
      const { id } = payload;
      if (payload.scope === 'estate') {
        if (!payload.estateId) {
          dangerNotification('Oops...', 'Selecione uma imobiliária');
          return;
        }
      }

      if (payload.scope === 'franchise') {
        if (!payload.franchiseId) {
          dangerNotification('Oops...', 'Selecione uma franquia');
          return;
        }
      }

      payload = {
        ...payload,
        cellphone: payload.cellphone ? unmaskPhoneNumber(payload.cellphone) : null,
        doc: payload.doc ? unmaskCpfOrCnpj(payload.doc) : null,
        estateId: payload.estateId ? payload.estateId : null,
        franchiseId: payload.franchiseId ? payload.franchiseId : null,
        observation: payload.observation || null
      };

      delete payload.email;
      delete payload.scope;
      delete payload.estate;
      delete payload.franchise;

      // TODO: refactor it and remove the duplicated logic
      const brokerDTO: BrokerForGetBrokerResponseDTO = await getBroker(
        '7c98d9d9-a975-45a3-9131-cc1961250182'
      );
      const broker: BrokerState | null = brokerDTO
        ? getBrokerObjectFromGetBrokerDTO(brokerDTO)
        : null;

      props.updateAvailableBrokersAndEstates({
        ...props.availableBrokersAndEstates,
        brokers: BrokersToCustomDropDownOptions(true, broker ? [broker] : [])
      });

      await updateUser(id, payload);
      successNotification('Sucesso', 'Usuário editado com sucesso!');
      (props as any).history.push('/admin/users');
    } catch (error) {
      dangerNotification('Oops...', (error as any).message);
    }
    setLoading(false);
  };

  const checkEscope = (role: string) => {
    if (estateUsers.includes(role as UserRolesEnum)) {
      return 'estate';
    } else if (franchiseUsers.includes(role as UserRolesEnum)) {
      return 'franchise';
    } else if (confiaxUsers.includes(role as UserRolesEnum)) {
      return 'Confiax';
    } else {
      return '';
    }
  };

  const userEstate = loadedUser.role
    ? loadedUser.role.includes('ESTATE')
      ? loadedUser.estate
        ? loadedUser.estate
        : undefined
      : null
    : '';
  const userFranchise = loadedUser.role
    ? loadedUser.role.includes('FRANCHISE')
      ? loadedUser.franchise
        ? loadedUser.franchise
        : undefined
      : null
    : '';
  const userScope = loadedUser.role ? checkEscope(loadedUser.role) : '';

  const initialValuesUserForm = {
    id: loadedUser.id,
    name: loadedUser.name,
    email: loadedUser.email,
    role: loadedUser.role,
    estate: userEstate,
    franchise: userFranchise,
    doc: typeof loadedUser.doc === 'string' ? maskCpf(loadedUser.doc) : loadedUser.doc,
    cellphone:
      typeof loadedUser.cellphone === 'string'
        ? maskPhoneNumber(loadedUser.cellphone)
        : loadedUser.cellphone,
    observation: loadedUser.observation,
    status: loadedUser.status || UserStatusEnum.active,
    scope: userScope,
    estateId: userEstate?.id || '',
    franchiseId: userFranchise?.id || ''
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Campo Obrigatório'),
    email: Yup.string().email('E-mail inválido').required('Campo Obrigatório'),
    role: Yup.string().required('Campo Obrigatório'),
    estate: Yup.string(),
    status: Yup.string(),
    cellphone: Yup.string().matches(
      /^\([0-9]{2}\) [0-9]{4,5}-[0-9]{4}$/,
      'Telefone inválido'
    ),
    doc: Yup.string().matches(/^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$/, 'CPF inválido'),
    observation: Yup.string()
  });

  return (
    <>
      {isReady && (
        <Formik
          initialValues={initialValuesUserForm}
          validationSchema={validationSchema}
          onSubmit={() => {}}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <SectionLabel text={'DADOS DO USUÁRIO'} />
              {(role === UserRolesEnum.brokerAdmin ||
                role === UserRolesEnum.brokerAnalyst ||
                role === UserRolesEnum.accountManager) && (
                <>
                  <Form.Group>
                    <Form.Radio
                      name={'scope'}
                      label={'Confiax'}
                      value={values.scope}
                      checked={values.scope === 'Confiax'}
                      onChange={(e, data) => {
                        setFieldValue('scope', 'Confiax');
                        setFieldValue('estate', null);
                        setFieldValue('franchise', null);
                      }}
                    />

                    <Form.Radio
                      name={'scope'}
                      label={'Imobiliária'}
                      value={values.scope}
                      checked={values.scope === 'estate'}
                      onChange={(e, data) => {
                        setFieldValue('scope', 'estate');
                        setFieldValue('role', UserRolesEnum.estateUser);
                        setFieldValue('estate', previousEstate);
                        setFieldValue('franchise', null);
                      }}
                    />

                    <Form.Radio
                      name={'scope'}
                      label={'Franquia'}
                      value={values.scope}
                      checked={values.scope === 'franchise'}
                      onChange={(e, data) => {
                        setFieldValue('scope', 'franchise');
                        setFieldValue('role', UserRolesEnum.franchiseAdmin);
                        setFieldValue('franchise', previousFranchise);
                        setFieldValue('estate', null);
                      }}
                    />
                  </Form.Group>
                </>
              )}
              <Form.Group widths={'equal'}>
                {values.scope === 'Confiax' ? (
                  <Form.Select
                    name={'role'}
                    options={userOptions}
                    label={'Tipo de Usuário'}
                    loading={isLoading}
                    clearable={true}
                    value={values.role}
                    onChange={(e, data) => setFieldValue('role', data.value)}
                  />
                ) : values.scope === 'estate' ? (
                  <Form.Select
                    name={'estate'}
                    options={estateOptions}
                    disabled={estateOptions.length === 1}
                    label={'Imobiliária'}
                    onChange={(e, data) => {
                      setSelectedEstate(String(data.value));
                      setFieldValue('estateId', String(data.value));
                    }}
                    value={selectedEstate}
                  />
                ) : (
                  <Form.Select
                    name={'franchise'}
                    options={franchiseOptions}
                    disabled={franchiseOptions.length === 1}
                    label={'Franquia'}
                    onChange={(e, data) => {
                      setSelectedFranchise(String(data.value));
                      setFieldValue('franchiseId', String(data.value));
                    }}
                    value={selectedFranchise}
                  />
                )}
                <Form.Input
                  name={'name'}
                  label={'Nome'}
                  value={values.name}
                  onChange={e => setFieldValue('name', e.target.value)}
                />

                <Form.Input
                  name={'email'}
                  label={'E-mail'}
                  readonly={true}
                  value={values.email}
                  onChange={e => setFieldValue('email', e.target.value)}
                  readOnly
                />
              </Form.Group>

              <Form.Group widths={'equal'}>
                <Form.Input
                  name={'doc'}
                  label={'CPF'}
                  value={values.doc}
                  onChange={e => setFieldValue('doc', maskCpf(e.target.value))}
                />

                <Form.Input
                  name={'cellphone'}
                  label={'Celular'}
                  value={values.cellphone}
                  onChange={e =>
                    setFieldValue('cellphone', maskPhoneNumber(e.target.value))
                  }
                />

                <Form.Select
                  name={'status'}
                  options={statusOptions}
                  disabled={
                    values.status === 'Confiax' ? true : statusOptions.length === 1
                  }
                  label={'Status'}
                  loading={isLoading}
                  clearable={true}
                  value={
                    statusOptions.length === 1 ? statusOptions[0].value : values.status
                  }
                  onChange={(e, data) => setFieldValue('status', data.value)}
                />
              </Form.Group>
              <Form.Group widths={'equal'}>
                <TextAreaWrapper>
                  <FieldLabel>Observações</FieldLabel>
                  <TextArea
                    value={values.observation}
                    onChange={(e, data) => setFieldValue('observation', data.value)}
                  />
                </TextAreaWrapper>
              </Form.Group>

              <Button onClick={() => update(values)} color="green">
                Editar
              </Button>
              <Button onClick={() => (props as any).history.push('/admin/users')}>
                Cancelar
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default connect(mapState, mapDispatch)(UsersForm);
