import React from 'react';
import { ContentSteps } from '../styled-components';
import { Grid, Icon } from 'semantic-ui-react';
import { allDataAreFilled } from '../../../../util';
import { sectionLabels, BudgetProps, Insurance, Property, Tenant, Owner, sectionExceptions, CoverageProps } from '../../types';

type SectionsProps = {
  budget: BudgetProps;
}

type SectionProp = {
  data: Insurance | Property | Tenant | Owner;
  dataKey: string;
}

const Section = (props: SectionProp) => {
  const { data, dataKey } = props;

  return (
    <Grid.Row>
      <Grid.Column width={2}>
        {allDataAreFilled(data, sectionExceptions[dataKey]) ? (<Icon name="check" color="blue" />) : (<Icon name="times" color="blue" />)}
      </Grid.Column>
      <Grid.Column width={14}>
        <span>{sectionLabels[dataKey]}</span>
      </Grid.Column>
    </Grid.Row>
  );
}

export const Sections = (props: SectionsProps) => {

  const { insurance, property, tenant, owner } = props.budget;

  return (
    <ContentSteps>
      <Section data={insurance} dataKey={'insurance'} />
      <Section data={property} dataKey={'property'} />
      <Section data={tenant} dataKey={'tenant'} />
      <Section data={owner} dataKey={'owner'} />
    </ContentSteps>
  );
}
