import React from 'react';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { Info } from '../../../../../components/Info';
import { DateToStringDate } from '../../../../../services/date';
import { BondInsuranceHiredDataForGetCompleteBondInsurancePolicyByIdResponseDTO } from '../../../../../dtos/bond-insurance-policy-list/get-complete-bond-insurance-policy-by-id-response.dto';
import { getBondInsurancePolicyStatusLabel } from '../../../../../services/bond-insurance-policy';
import SectionLabel from '../../../../../components/SectionLabel';
import { maskMoney } from '../../../../../services/masks';
import { BondInsuranceAnalysisForGetCompleteBondInsuranceProposalByIdResponseDTO } from '../../../../../dtos/bond-insurance-proposal-list/get-complete-bond-insurance-proposal-by-id-response.dto';
import { PreviousHiredButton } from '../../../styles';

export type Props = {
  bondInsuranceHiredData: BondInsuranceHiredDataForGetCompleteBondInsurancePolicyByIdResponseDTO;
  bondInsuranceAnalysis: BondInsuranceAnalysisForGetCompleteBondInsuranceProposalByIdResponseDTO;
  getPreviousHired: Function;
};

export const BondInsurancePolicyMainInfoDetails = (props: Props) => {
  const { bondInsuranceHiredData, bondInsuranceAnalysis, getPreviousHired } = props;
  return (
    <>
      {bondInsuranceHiredData.previousHiredDataId !== null ? (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', minWidth:'100%' }}>
          <SectionLabel text="DADOS DA APÓLICE" />
          <PreviousHiredButton
            onClick={() => {getPreviousHired(bondInsuranceHiredData.previousHiredDataId)}}
          >
            <Icon name='reply' color='blue'/>
            Ver apólice original
          </PreviousHiredButton>
        </div>) :
        <SectionLabel text="DADOS DA APÓLICE" />
      }

      <Grid.Row>
        <Info name={'ID'} value={bondInsuranceHiredData.id} width={8} />
        <Info
          name={'STATUS'}
          value={getBondInsurancePolicyStatusLabel(bondInsuranceHiredData.status)}
          width={8}
        />
      </Grid.Row>
      <Grid.Row>
        <Info
          name={'CÓD. DA APÓLICE'}
          value={
            bondInsuranceHiredData.insurerPolicyId
              ? bondInsuranceHiredData.insurerPolicyId
              : 'Apólice não emitida'
          }
          width={8}
        />
        <Info
          name={'DATA DE EMISSÃO DA APÓLICE'}
          value={
            bondInsuranceHiredData.insurerPolicyCreatedAt
              ? DateToStringDate(bondInsuranceHiredData.insurerPolicyCreatedAt)
              : 'Indisponível'
          }
          width={8}
        />
      </Grid.Row>
      <Grid.Row>
        <Info
          name={'Nº DE PARCELAS CONTRATADAS'}
          value={bondInsuranceHiredData.hiredInstallments ? Number(bondInsuranceHiredData.hiredInstallments) : "Indisponível"}
          width={8}
        />
        <Info
          name={'VALOR DA PARCELA (R$)'}
          value={bondInsuranceHiredData.hiredInstallmentsValue ? maskMoney(String(bondInsuranceHiredData.hiredInstallmentsValue), true, 2) : "Indisponível"}
          width={8}
        />
      </Grid.Row>
      <Grid.Row>
        <Info
          name={'FORMA DE PAGAMENTO'}
          value={bondInsuranceHiredData.paymentWay}
          width={8}
        />
        <Info
          name={'PREMIO TOTAL'}
          value={
            bondInsuranceHiredData.totalPremiumAmount &&
            bondInsuranceHiredData.totalPremiumAmount > 0
              ? maskMoney(String(bondInsuranceHiredData.totalPremiumAmount), true, 2)
              : maskMoney(String(bondInsuranceHiredData.hiredInstallmentsValue *
                  bondInsuranceHiredData.hiredInstallments), true, 2)
          }
          width={8}
        />
      </Grid.Row>

      {(bondInsuranceAnalysis.observations || bondInsuranceHiredData.observations) && (
        <Grid.Row>
          {bondInsuranceAnalysis.observations &&
            <Info
              name={'Observações análise'}
              value={bondInsuranceAnalysis.observations}
              width={8}
            />
          }
          {bondInsuranceHiredData.observations &&
            <Info
              name={'Observações Contratação'}
              value={bondInsuranceHiredData.observations}
              width={8}
            />
          }
        </Grid.Row>
      )}

      {(bondInsuranceHiredData.nonRenewalReason || bondInsuranceHiredData.responsible) && (
        <Grid.Row>
          {bondInsuranceHiredData.nonRenewalReason &&
            <Info
              name={'Motivo De Não Renovação'}
              value={bondInsuranceHiredData.nonRenewalReason}
              width={16}
            />
          }
          {bondInsuranceHiredData.responsible &&
            <Info
              name={'Responsável pela Renovação'}
              value={bondInsuranceHiredData.responsible}
              width={16}
            />
          }
        </Grid.Row>
      )}

      {(bondInsuranceHiredData.confirmer || bondInsuranceHiredData.editor) && (
        <Grid.Row>
          {bondInsuranceHiredData.editor && <Info
            name={'EDITADO POR'}
            value={bondInsuranceHiredData.editor}
            width={8}
          />}
          {bondInsuranceHiredData.confirmer && <Info
            name={'CONFIRMADO POR'}
            value={bondInsuranceHiredData.confirmer}
            width={8}
          />}
        </Grid.Row>
      )}
    </>
  );
};
