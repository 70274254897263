import React, { useRef, useEffect, useState } from 'react';
import { Dimmer, Loader, Select } from 'semantic-ui-react';
import { File as FileIcon } from '@styled-icons/boxicons-regular';
import { RemoveCircle as RemoveIcon } from '@styled-icons/ionicons-outline';
import * as S from './styles';
import {
  deleteFilesLinkAxios,
  postFileAndGetPreSignAxios,
  postFileAxios
} from '../../services/file';
import {
  dangerNotification,
  successNotification,
  warningNotification
} from '../../services/notification';
import * as Storage from '../../services/storage';
import { DateToStringDate } from '../../services/date';
import { UserRolesEnum } from '../../enums/user-roles.enum';
import { Link } from 'react-router-dom';

type FileLineProps = {
  key: number;
  file: FileIdentifier;
};

export type FileAttachmentProps = {
  fileLink: string;
  option: string;
  id: string;
};

type FileIdentifier = {
  file: File;
  name: string;
};

export type fileTypeProps = {
  name: string;
  key: string;
  required?: boolean;
};

export type FileUploaderProps = {
  fileIdentifiers: fileTypeProps[];
  files: FileIdentifier[];
  setFieldValue: (a: string, b: FileIdentifier[]) => void;
  fieldName: string;
};

const FileUploader = ({
  fileIdentifiers,
  files,
  setFieldValue,
  fieldName
}: FileUploaderProps) => {
  const userRole = Storage.get('role');
  const [loading, setLoading] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState<FileAttachmentProps[]>([]);
  const fileOptions = fileIdentifiers.map(fileIdentifier => ({
    key: fileIdentifier.key,
    value: fileIdentifier.name
  }));

  let ref: any = useRef(null);

  function handleAddFile(fileList: FileList) {
    if (!fileList) {
      return;
    }
    const array = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (file.size > 50 * 1024 * 1024) {
        warningNotification(
          'Limite excedido',
          `O arquvio ${file.name.substr(
            0,
            10
          )} é muito grande. Nosso limite interno é de 50MB.`
        );
        return;
      }

      array.push({ file, name: 'OTHER' });
    }
    setFieldValue(fieldName, (files || []).concat(array));
  }

  function handleRemoveFile(toRemove: FileIdentifier) {
    const array = files?.filter(fileIdentified => fileIdentified !== toRemove);
    setFieldValue(fieldName, array);
  }

  const Item = ({ key, file: fileIdentified }: FileLineProps) => {
    function handleChange(value: string) {
      const arrayFiles = files?.map(file =>
        file !== fileIdentified ? file : { ...fileIdentified, name: value }
      );
      setFieldValue(fieldName, arrayFiles);
    }
    return (
      <S.FileLine key={key}>
        <S.Icon>
          <FileIcon color="blue" />
        </S.Icon>
        <p>{fileIdentified.file.name}</p>
        <p>{(fileIdentified.file.size / 1024 / 1024).toFixed(2)} MB</p>
        <S.Select
          onChange={e => handleChange(e.target.value)}
          placeholder="Identificacao do documento"
        >
          {fileOptions.map(fileType => (
            <option
              value={fileType.key}
              key={fileType.key}
              selected={fileType.key === fileIdentified.name}
            >
              {fileType.value}
            </option>
          ))}
        </S.Select>
        {(userRole === UserRolesEnum.brokerAdmin ||
          userRole === UserRolesEnum.brokerAnalyst) && (
          <S.Icon onClick={() => handleRemoveFile(fileIdentified)}>
            <RemoveIcon color="red" width={'1.5rem'} />
          </S.Icon>
        )}
      </S.FileLine>
    );
  };

  async function SoftDeleteFile(id: string) {
    try {
      if (window.confirm('Deseja deletar este arquivo?')) {
        await deleteFilesLinkAxios(id);
        const array = attachedFiles?.filter(fileIdentified => fileIdentified.id !== id);
        setAttachedFiles(array);
      }
      successNotification('Sucesso!', 'Arquivo deletado com sucesso!');
    } catch (err) {
      console.log(err);
      warningNotification('Ops...', 'Erro ao deletar arquivo.');
    }
  }

  const ItemAttachment = ({ fileLink, option, id }: FileAttachmentProps) => {
    return (
      <S.FileLine key={id}>
        <Link to={{ pathname: fileLink }} target="_blank" rel="noopener noreferrer">
          <S.Icon>
            <FileIcon color="green" />
          </S.Icon>
        </Link>
        <Link to={{ pathname: fileLink }} target="_blank" rel="noopener noreferrer">
          <p>{fileOptions.find(c => c.key === option)?.value || option}</p>
        </Link>
        <p> </p>
        <S.Select placeholder="Identificacao do documento">
          {fileOptions.map(fileType => (
            <option
              value={fileType.key}
              key={fileType.key}
              selected={fileType.key === option}
              disabled
            >
              {' '}
              {fileType.value}{' '}
            </option>
          ))}
        </S.Select>
        {(userRole === UserRolesEnum.brokerAdmin ||
          userRole === UserRolesEnum.brokerAnalyst) && (
          <S.Icon onClick={() => SoftDeleteFile(id)}>
            <RemoveIcon color="red" />
          </S.Icon>
        )}
      </S.FileLine>
    );
  };

  return (
    <S.Wrapper>
      {attachedFiles && attachedFiles.length > 0 && (
        <S.FileWrapper>
          <S.LabelSavedFiles> Arquivos salvos </S.LabelSavedFiles>
          {attachedFiles.map(file => (
            <ItemAttachment fileLink={file.fileLink} option={file.option} id={file.id} />
          ))}
        </S.FileWrapper>
      )}

      {files && files.length > 0 && (
        <S.WrapperFiles>
          <S.LabelSavedFiles> Arquivos anexados </S.LabelSavedFiles>
          {files.map((file, i: number) => (
            <Item key={i} file={file} />
          ))}
        </S.WrapperFiles>
      )}
      <div>
        <Dimmer active={loading}>
          <Loader indeterminate> Fazendo upload dos arquivos... </Loader>
        </Dimmer>
        <S.InputButton
          type="button"
          value="Adicionar Arquivo"
          onClick={() => ref && ref.click()}
        />
        <S.InputFile
          type="file"
          name="file"
          multiple
          ref={input => {
            ref = input;
          }}
          onChange={e => e.target.files && handleAddFile(e.target.files)}
          accept="image/png, image/jpeg, image/webp, application/msword, application/pdf, application/vnd.oasis.opendocument.text"
        />
      </div>
    </S.Wrapper>
  );
};
export default FileUploader;
