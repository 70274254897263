import React, {ReactHTMLElement, SyntheticEvent, useCallback, useEffect, useRef, useState} from "react";
import Toggle from "../../../../components/Toggle";
import {Field} from "formik";
import Input from "../../../../components/Input";
import styled from "styled-components";
import {InsuranceConnectorCoverageState} from "../../../../types/PropertyTypeState";
import {FieldLabel} from "../../../../styles";
import {formatAmount, maskMoney, moneyStringToNumber, numberToMoneyString, unmaskMoney} from "../../../../services/masks";
import {FormikErrorMessage} from "../../../../components/ErrorMessage";
import {connect} from "react-redux";
import {iRootDispatch, iRootState} from "../../../../store";
import {connectorCoverageOptionYupSchema} from "../../validators/coverages-section/connector-coverages-section/connector-coverage-option-schema";
import {DefaultCoverageQuotationValueStrategyEnum} from "../../enums/default-coverage-quotation-value-strategy.enum";
import {debounce} from "lodash";
import {getDefaultInsuredAmountByStrategy} from "../../../../services/connector-coverage";

const mapDispatchToProps = (dispatch: iRootDispatch) => ({})
const mapStateToProps = (state: iRootState) => ({
  setFieldTouched: state.formik.actions.setFieldTouched
});

export type ConnectorCoverageOptionProps = {
  coverage: InsuranceConnectorCoverageState;
  widthPercentSize: number;
  handleCoverageToggleCallback: (coverage: InsuranceConnectorCoverageState, checked: boolean, insuredAmount: number) => void;
  handleCoverageInsuredAmountChangeCallback: (coverage: InsuranceConnectorCoverageState, insuredAmount: number) => void;
  context?: {
    rentValue: number;
    estateMultiplier: number;
  };
}

const ConnectorCoverageOption = (
  props: ConnectorCoverageOptionProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>
) => {
  const {
    coverage,
    widthPercentSize,
    handleCoverageToggleCallback,
    handleCoverageInsuredAmountChangeCallback,
    context
  } = props;
  const insuredAmountRef = useRef<any>(null);
  const debouncedHandleInsuredAmountChange = useCallback(debounce((coverage: InsuranceConnectorCoverageState, insuredAmount: number) => {
    return props.handleCoverageInsuredAmountChangeCallback(coverage, insuredAmount);
  }, 500), []);


  useEffect(() => {
    if (coverage.mandatoryInQuotation) {
      insuredAmountRef.current?.focus();
    }
  }, [insuredAmountRef]);

  useEffect(() => {
    if (coverage.defaultCoverageValueStrategy && context) {
      updateInsuredAmountByStrategy(context);
    }
  }, [props.context?.rentValue]);

  const [checked, setChecked] = useState(coverage.mandatoryInQuotation);
  const [insuredAmount, setInsuredAmount] = useState('0,00');
  let textStyle;

  if (checked) {
    textStyle = {}
  } else {
    textStyle = {
      opacity: '0.5'
    }
  }

  const handleInsuredAmountFieldRef = (el: ReactHTMLElement<any>) => {
    insuredAmountRef.current = el;
  }

  const validateInsuredAmount = async () => {
    let error;
    try {
        const res = await connectorCoverageOptionYupSchema.validate({
          checked,
          insuredAmount: unmaskMoney(insuredAmount),
          maximumInsuredAmount: coverage.maximumInsuredAmount
        });
    } catch (e) {
        if (e.type === 'max' && e.path === 'insuredAmount') {
          error = e.message + formatAmount(maskMoney(e.params.max.toString()));
        } else {
          error = e.message;
        }
    }
    return error;
  }

  const updateInsuredAmountByStrategy = (context: ConnectorCoverageOptionProps['context']) => {
    const value: number = getDefaultInsuredAmountByStrategy(coverage.defaultCoverageValueStrategy,
      {
        rentValue: context?.rentValue ? context.rentValue : 0,
        estateMultiplier: context?.estateMultiplier ? context.estateMultiplier : 0,
      });

      setInsuredAmount(numberToMoneyString(value));

      if (checked) {
        debouncedHandleInsuredAmountChange(coverage, value);
        props.setFieldTouched && props.setFieldTouched(`budget.connectorCoverage.${coverage.id}.insuredAmount`);
      }
  }

  const handleInsuredAmountChange = async (e: SyntheticEvent, data: any) => {
    if (data) {
      props.setFieldTouched && props.setFieldTouched(`budget.connectorCoverage.${coverage.id}.insuredAmount`);

      setInsuredAmount(formatAmount(data.value));

      debouncedHandleInsuredAmountChange(coverage, moneyStringToNumber(formatAmount(data.value)));
    }
  }

  const handleToggle = async (e: SyntheticEvent, data: any) => {
    if (data) {
      props.setFieldTouched && props.setFieldTouched(`budget.connectorCoverage.${coverage.id}.insuredAmount`);

      setChecked(data.checked);

      handleCoverageToggleCallback(coverage, data.checked, unmaskMoney(insuredAmount));
    }
  }

  return (
  <OptionContainer style={{flex: `0 0 ${widthPercentSize.toString()}%`}}>
      <TextContainer>
        <FieldLabel style={{
          margin: '0.5em 0 0',
          transition: 'opacity linear 0.075s',
          ...textStyle

        }}>
          {`${coverage.description}`}
        </FieldLabel>
        <FieldLabel style={{
          margin: '0 0 0.5em',
          ...textStyle
        }}>
          {`(${coverage.insuredFloorPlan})`}
          {coverage.mandatoryInQuotation && <span style={{color: 'red'}}>*</span>}
        </FieldLabel>
      </TextContainer>
      <Field name={`budget.connectorCoverage.${coverage.id}.insuredAmount`}
        component={Input}
        disabled={!checked}
        onChange={handleInsuredAmountChange}
        value={formatAmount(insuredAmount)}
        handleRef={handleInsuredAmountFieldRef}
        /*             onBlur={handleInsuredAmountBlur}*/
        style={{
          width: '100%'
        }}
        validate={validateInsuredAmount}
        />
    <FormikErrorMessage component="div" name={`budget.connectorCoverage.${coverage.id}.insuredAmount`} />
    <Toggle name={`budget.connectorCoverage.${coverage.id}.checked`}
            color='blue'
            style={{alignSelf: 'center'}}
            onChange={handleToggle}
            checked={checked}
            disabled={coverage.mandatoryInQuotation}
    />
  </OptionContainer>
  );
}

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5em 0;
  width: 100%;
  border: 1px solid silver;
  border-radius: 5px;
  padding: 1.5%;
`;

const TextContainer = styled.div`
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 1366px) {
    min-height: 100px;
  }
`

export default connect(mapStateToProps, mapDispatchToProps)(ConnectorCoverageOption)


