import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Icon, Modal, Step, Loader, Dimmer, Header } from 'semantic-ui-react';

import { Formik } from 'formik';
import CapitalizationInfoStep from '../Steps/CapitalizationInfoStep';
import {
  dangerNotification,
  successNotification,
  warningNotification
} from '../../../../../services/notification';
import { connect } from 'react-redux';
import { iRootDispatch, iRootState } from '../../../../../store';
import {
  AvailableBrokersAndEstatesState,
  CurrentPageState
} from '../../../../../store/types/temp-types';
import { CustoDropDownItemProps } from '../../../../../components/types';
import { uuid } from 'uuidv4';
import { useHistory } from 'react-router-dom';
import { CreateCapitalizationType } from '../Formik/types/CreateCapitalizationInitialValuesTypes';
import { CreateCapitalizationInitialInfo } from '../Formik/InitialValues/CreateCapitalizationInitialValues';
import {
  createCapitalization,
  updateCapitalization
} from '../../../../../services/capitalization';
import CapitalizationPropertyStep from '../Steps/CapitalizationPropertyStep';
import CapitalizationTenantStep from '../Steps/CapitalizationTenantStep';
import { CapitalizationStatusEnum } from '../../../enums/capitalization-status.enum';
import { ListCapitalizationInterface } from '../../../ListCapitalizationInitial.interface';
import { DropdownOptions } from '../../create-capitalization-modal/Steps/CapitalizationInfoStep';
import { UserRolesEnum } from '../../../../../enums/user-roles.enum';
import { BondInsuranceForGetBondInsurancesResponseDTO } from '../../../../../dtos/bond-insurance-list/get-bond-insurances-response.dto';
import { list } from '../../../../../services/insurer';
import { maskCpfOrCnpj, maskMoney } from '../../../../../services/masks';
import { PushRoute } from '../../../../../util';
import { get } from '../../../../../services/storage';

type ModalContentType = {
  children: React.ReactNode;
  values: CreateCapitalizationType;
};

const EditCapitalizationModal = (props: {
  availableBrokersAndEstates: any;
  updateCurrentPage: any;
  capitalization?: ListCapitalizationInterface;
  trigger?: ReactElement;
  insurers?: DropdownOptions[];
  bondInsuance?: BondInsuranceForGetBondInsurancesResponseDTO;
}) => {
  const { insurers } = props;
  const [insurersLoaded, setInsurersLoaded] = useState(insurers);
  const estates: CustoDropDownItemProps[] = props.availableBrokersAndEstates.estates;
  const [openHireProposalModal, setOpenHireProposalModal] = useState(false);
  const [stepOfHireModal, setStepOfHireModal] = useState('capitalization_info');
  const [initialValues, setInitialValues] = useState<CreateCapitalizationType>();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const role = get('role');

  const loadInsurers = async () => {
    const response = await list({ availability: 'CAPITALIZATION' });
    const mapedInsurers = response.map((insurer: any) => ({
      index: insurer.id,
      value: insurer.id,
      text: insurer.name
    }));
    setInsurersLoaded(mapedInsurers);
  };

  useEffect(() => {
    if (!insurersLoaded) loadInsurers();
  }, []);

  useEffect(() => {
    if (openHireProposalModal) {
      setInitialValues(CreateCapitalizationInitialInfo);
      const initialValues = CreateCapitalizationInitialInfo;
      if (props.capitalization) {
        initialValues.lessorName = props.capitalization.lessorName;
        initialValues.lessorDocument = maskCpfOrCnpj(props.capitalization.lessorDocument);
        initialValues.rentalValue = Number(props.capitalization.rentalValue);
        initialValues.multiplier = props.capitalization.multiplier;
        initialValues.plan = props.capitalization.plan;
        initialValues.totalValue = Number(props.capitalization.totalValue);
        initialValues.startVigency = props.capitalization.startVigency
          ? new Date(props.capitalization.startVigency)
          : undefined;
        initialValues.endVigency = props.capitalization.endVigency
          ? new Date(props.capitalization.endVigency)
          : undefined;
        initialValues.status = props.capitalization.status;
        initialValues.insurerId = props.capitalization.insurer?.id;
        initialValues.estateId = props.capitalization.estate?.id;
        initialValues.property = props.capitalization.capitalizationProperty;
        initialValues.tenant = props.capitalization.capitalizationTenant;
        initialValues.id = props.capitalization.id;
        initialValues.tenant.id = props.capitalization.capitalizationTenant.id;

        initialValues.property.id = props.capitalization.capitalizationProperty.id;
        initialValues.locationType = props.capitalization.locationType;
        initialValues.property.hasComplement = props.capitalization.capitalizationProperty
          .complement
          ? false
          : true;
      } else if (props.bondInsuance) {
        initialValues.status = CapitalizationStatusEnum.inIssue;
        initialValues.bondInsuranceId = props.bondInsuance.id;
        initialValues.estateId = props.bondInsuance.estate?.id;
        initialValues.property.propertyType = props.bondInsuance.property.property_type;
        initialValues.rentalValue = Number(props.bondInsuance.property.rent_value);
        initialValues.taxesValue = Number(props.bondInsuance.property.taxes_value);
        initialValues.rentalValue = Number(props.bondInsuance.property.rent_value);
        initialValues.gasBill = Number(props.bondInsuance.property.gas_bill);
        initialValues.waterBill = Number(props.bondInsuance.property.water_bill);
        initialValues.energyBill = Number(props.bondInsuance.property.energy_bill);
        initialValues.condominiumFee = Number(
          props.bondInsuance.property.condominium_fee
        );
        initialValues.property.city = props.bondInsuance.property.city;
        initialValues.property.state = props.bondInsuance.property.state;
        initialValues.property.district = props.bondInsuance.property.district;
        initialValues.property.street = props.bondInsuance.property.street;
        initialValues.property.number = props.bondInsuance.property.number;
        initialValues.property.complement = props.bondInsuance.property.complement;
        initialValues.property.zipCode = props.bondInsuance.property.zip_code;
        initialValues.tenant.document = props.bondInsuance.natural_tenant
          ? maskCpfOrCnpj(props.bondInsuance.natural_tenant.main_tenant_cpf)
          : maskCpfOrCnpj(props.bondInsuance.legal_tenant.document);
        initialValues.tenant.name = props.bondInsuance.natural_tenant
          ? props.bondInsuance.natural_tenant.main_tenant_name
          : props.bondInsuance.legal_tenant.company;
        initialValues.tenant.email = props.bondInsuance.natural_tenant
          ? props.bondInsuance.natural_tenant.main_tenant_email
          : props.bondInsuance.legal_tenant.email;
        initialValues.tenant.cellphone = props.bondInsuance.natural_tenant
          ? props.bondInsuance.natural_tenant.main_tenant_phone
          : props.bondInsuance.legal_tenant.phone;
        initialValues.tenant.birthDate = props.bondInsuance.natural_tenant
          ? String(props.bondInsuance.natural_tenant.main_tenant_birth_date)
          : undefined;
        initialValues.tenant.maritalStatus = props.bondInsuance.natural_tenant
          ? props.bondInsuance.natural_tenant.main_tenant_marital_status
          : undefined;
        initialValues.tenant.nationality =
          props.bondInsuance.natural_tenant?.main_tenant_nationality;
        initialValues.tenant.gender =
          props.bondInsuance.natural_tenant?.main_tenant_gender;
        initialValues.tenant.politicallyExposedPerson =
          props.bondInsuance.natural_tenant?.main_tenant_politically_exposed_person;
        initialValues.tenant.profession =
          props.bondInsuance.natural_tenant?.main_tenant_profession;
      }
      setInitialValues(initialValues);
    }
  }, [openHireProposalModal]);

  function handleCloseModal() {
    setOpenHireProposalModal(false);
  }

  function handleOpenModal() {
    setStepOfHireModal('capitalization_info');
    setOpenHireProposalModal(true);
  }

  async function handleNextStep(values: CreateCapitalizationType) {
    switch (stepOfHireModal) {
      case 'capitalization_info': {
        let error = false;

        if (
          role === UserRolesEnum.brokerAdmin ||
          role === UserRolesEnum.brokerAnalyst ||
          role === UserRolesEnum.accountManager
        ) {
          if (!values.estateId) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, selecione a imobiliária para continuar!'
            );
            error = true;
          }

          if (!values.status) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, selecione o status inicial para continuar!'
            );
            error = true;
          }

          if (!values.insurerId) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, selecione a seguradora para continuar!'
            );
            error = true;
          }
        }

        if (!values.lessorDocument) {
          warningNotification(
            'Ops...',
            'Por favor, informe o documento do proprietário!'
          );
          error = true;
        }

        if (!values.lessorName) {
          warningNotification('Ops...', 'Por favor, informe o nome do proprietário!');
          error = true;
        }

        if (!values.rentalValue || values.rentalValue <= 0) {
          warningNotification('Ops...', 'Por favor, informe o valor da locação!');
          error = true;
        }

        if (!values.multiplier) {
          warningNotification('Ops...', 'Por favor, selecione o multiplicador!');
          error = true;
        }

        if (!values.plan) {
          warningNotification('Ops...', 'Por favor, selecione um plano para continuar!');
          error = true;
        }

        if (!values.totalValue || values.totalValue <= 0) {
          warningNotification(
            'Ops...',
            'Por favor, insira o valor do título para continuar!'
          );
          error = true;
        }

        if (values.totalValue && values.totalValue < 1000) {
          warningNotification(
            'Valor mínimo do título',
            'O valor do título deve ser de no mínimo R$ 1.000,00!'
          );
          error = true;
        }

        if (!error) {
          setStepOfHireModal('capitalization_property');
        }
        return;
      }
      case 'capitalization_property': {
        let error = false;

        if (!values.property.zipCode) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o CEP do imóvel para continuar!'
          );
          error = true;
        }

        if (!values.property.state) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o ESTADO do imóvel para continuar!'
          );
          error = true;
        }

        if (!values.property.city) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira a CIDADE do imóvel para continuar!'
          );
          error = true;
        }

        if (!values.property.district) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o BAIRRO do imóvel para continuar!'
          );
          error = true;
        }

        if (!values.property.street) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o LOGRADOURO do imóvel para continuar!'
          );
          error = true;
        }

        if (!values.property.number) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o NÚMERO do imóvel para continuar!'
          );
          error = true;
        }

        if (!values.property.complement && !values.property.hasComplement) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, caso não haja complemento, marque o checkbox SEM COMPLEMENTO!'
          );
          error = true;
        }

        if (!values.property.propertyType) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o Tipo do Imóvel para continuar!'
          );
          error = true;
        }

        if (!values.locationType) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o Tipo da Locação para continuar!'
          );
          error = true;
        }

        if (!error) {
          setStepOfHireModal('capitalization_tenant');
        }
        return;
      }
      case 'capitalization_tenant': {
        let error = false;

        if (!values.tenant.document) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o DOCUMENTO do inquilino para continuar!'
          );
          error = true;
        }

        if (values.tenant.document === values.lessorDocument) {
          warningNotification(
            'Atenção',
            'O Locatário não pode ser o proprietário do imóvel!'
          );
          error = true;
        }

        if (!values.tenant.name) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o NOME/RAZÃO SOCIAL do inquilino para continuar!'
          );
          error = true;
        }

        const isPJ = values.tenant.document && values.tenant.document.length > 14;
        if (isPJ) {
          if (
            !values.tenant.companyAnnualRevenue ||
            values.tenant.companyAnnualRevenue <= 0
          ) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira o FATURAMENTO ANUAL do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.companyRepresentativeName) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira o NOME DO REPRESENTANTE da empresa do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.constitutionDate) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira a DATA DE CONSTITUIÇÃO da empresa do inquilino para continuar!'
            );
            error = true;
          }

          if (
            !values.tenant.municipalRegistration &&
            !values.tenant.hasMunicipalRegistration
          ) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira a INSCRIÇÃO MUNICIPAL da empresa do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.stateRegistration && !values.tenant.hasStateRegistration) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira a INSCRIÇÃO ESTADUAL da empresa do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.companyRepresentativeDocument) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira o DOCUMENTO DO REPRESENTANTE da empresa do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.companyRepresentativeCellphone) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira o TELEFONE DO REPRESENTANTE da empresa do inquilino para continuar!'
            );
            error = true;
          }
        } else {
          if (!values.tenant.birthDate) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira a DATA DE NASCIMENTO do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.maritalStatus) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira o ESTADO CIVIL do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.nationality) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira a NACIONALIDADE do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.profession) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira a PROFISSÃO do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.identityDocument) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira o RG do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.identityDocumentDate) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira a DATA DE EMISSÃO do RG do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.identityDocumentUf) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira a UF de emissão do RG do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.identityDocumentIssuance) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira a ORGÃO EMISSOR do RG do inquilino para continuar!'
            );
            error = true;
          }
        }
        if (!values.tenant.cellphone) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o TELEFONE do inquilino para continuar!'
          );
          error = true;
        }

        if (!values.tenant.email) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o EMAIL do inquilino para continuar!'
          );
          error = true;
        }

        if (error) {
          return;
        }

        await submitCapitalization(values);
      }
    }
  }

  async function handleBackStep() {
    switch (stepOfHireModal) {
      case 'capitalization_info': {
        handleCloseModal();
        return;
      }
      case 'capitalization_property': {
        setStepOfHireModal('capitalization_info');
        return;
      }
      case 'capitalization_tenant': {
        setStepOfHireModal('capitalization_property');
        return;
      }
    }
  }

  async function submitCapitalization(values: any) {
    setLoading(true);
    try {
      const status =
        values.status && ((CapitalizationStatusEnum as any)[values.status] as string);

      if (props.bondInsuance) {
        await createCapitalization(values);
        successNotification('Sucesso', 'Capitalização solicitada com sucesso!');
      } else {
        await updateCapitalization({ ...values, status });
        successNotification(
          'Sucesso',
          'Atualização de capitalização realizada com sucesso!'
        );
      }
    } catch (err) {
      dangerNotification('Erro', 'Erro ao efetuar Atualização de capitalização');
      console.log(err);
    } finally {
      setLoading(false);
      handleCloseModal();
      PushRoute(history, props, 'capitalizations');
    }
  }

  const ModalContent = ({ children, values }: ModalContentType) => (
    <>
      <Modal.Content scrolling>
        <Dimmer active={loading}>
          <Loader indeterminate> Comunicando solicitação... </Loader>
        </Dimmer>

        <div style={{ width: '100%' }}>
          <Step.Group widths={3}>
            <Step
              active={stepOfHireModal === 'capitalization_info'}
              title="Informações Gerais"
              description="Informações sobre valores e proprietário"
              icon={{ color: 'grey', name: 'address card' }}
            />
            <Step
              active={stepOfHireModal === 'capitalization_property'}
              title="Dados do imóvel"
              description="Dados do imóvel que está sendo alugado"
              icon={{ color: 'grey', name: 'home' }}
            />

            <Step
              active={stepOfHireModal === 'capitalization_tenant'}
              title="Informações do locatário"
              description="Dados do locatário"
              icon={{ color: 'grey', name: 'user' }}
            />
          </Step.Group>
        </div>

        {children}
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={() => handleBackStep()}>
          <Icon name="remove" />
          {stepOfHireModal === 'capitalization_info' ? 'Sair' : 'Voltar'}
        </Button>
        <Button color="green" onClick={() => handleNextStep(values)}>
          <Icon name="checkmark" />{' '}
          {stepOfHireModal === 'capitalization_tenant'
            ? props.bondInsuance
              ? 'Criar'
              : 'Editar'
            : 'Próximo'}
        </Button>
      </Modal.Actions>
    </>
  );

  return (
    <div>
      <Modal
        style={{ height: 'auto', width: '90vw', zIndex: 10 }}
        closeIcon
        open={openHireProposalModal}
        trigger={props.trigger}
        onOpen={() => handleOpenModal()}
        onClose={() => handleCloseModal()}
      >
        <Header style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <Icon name={'file alternate outline'} />{' '}
            {props.bondInsuance ? 'Cadastro de capitalização' : 'Edição de capitalização'}
          </div>
        </Header>
        {initialValues && (
          <Formik
            initialValues={initialValues}
            validationSchema={() => true}
            onSubmit={() => true}
          >
            {innerValues => {
              switch (stepOfHireModal) {
                case 'capitalization_info':
                  return (
                    <ModalContent
                      values={innerValues.values}
                      children={
                        <CapitalizationInfoStep
                          {...innerValues}
                          estates={estates}
                          insurers={insurersLoaded!}
                        />
                      }
                    />
                  );
                case 'capitalization_property':
                  return (
                    <ModalContent
                      values={innerValues.values}
                      children={<CapitalizationPropertyStep {...innerValues} />}
                    />
                  );
                case 'capitalization_tenant':
                  return (
                    <ModalContent
                      values={innerValues.values}
                      children={<CapitalizationTenantStep {...innerValues} />}
                    />
                  );
              }
            }}
          </Formik>
        )}
      </Modal>
    </div>
  );
};

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(
      availableBrokers
    ),
  updateCurrentPage: (state: CurrentPageState) =>
    dispatch.currentPage.updateCurrentPage(state)
});

export default connect(mapState, mapDispatch)(EditCapitalizationModal);
