import { BondInsuranceProposalState } from "../../types/BondInsuranceProposal";
import {
  BondInsuranceProposalsListReducer,
  BondInsuranceProposalsListState
} from "../types/temp-types";


type BondInsuranceProposalsListRematchModel = {
  state: BondInsuranceProposalsListState;
  reducers: BondInsuranceProposalsListReducer;
}

const bondInsuranceProposalsList: BondInsuranceProposalsListRematchModel = {
  state: {
    proposals: Array<BondInsuranceProposalState>(),
    onceRequested: false,
    isLoading: false,
    ascending: true
  },
  reducers: {
    updateBondInsuranceProposal: (
      state: BondInsuranceProposalsListState,
      updatedProposal: BondInsuranceProposalState
    ): BondInsuranceProposalsListState => {
      return {
/*        proposals: state.proposals?.map((proposal) => proposal.id === updatedProposal.id ? updatedProposal : proposal),*/
        ...state
      }
    },
    updateBondInsuranceProposalsList: (
      state: BondInsuranceProposalsListState,
      payload: BondInsuranceProposalsListState
    ): BondInsuranceProposalsListState => payload
  }
};

export default bondInsuranceProposalsList;
