import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";

import CreateClaimEstateModal from "./components/create-claim-modal/Modal";
import ModalInstructions from "./components/ModalInstructions";
import Table from "./components/Table";
import { connect } from "react-redux";
import { CustoDropDownItemProps } from "../../components/types";
import { iRootDispatch, iRootState } from "../../store";
import { AvailableBrokersAndEstatesState } from "../../store/types/temp-types";
import GeneralCondition from "./components/GeneralCondition";
import AuthorizationContainer from "../../components/AuthorizationContainer";
import { ActionEnum } from "../../enums/authz-action.enum";
import { FeatureEnum } from "../../enums/authz-feature.enum";

const ClaimEstateIndex = (props: any) => {
  const { availableBrokersAndEstates } = props;
  const brokers: CustoDropDownItemProps[] = availableBrokersAndEstates.brokers;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openConditions, setOpenConditions] = useState<boolean>(false);
  const [broker, setBroker] = useState<any>();

  const toggleOpen = () => {
    setOpenModal(true);
  };

  const toggleClose = () => {
    setOpenModal(false);
  };

  const toggleOpenConditions = () => {
    setOpenConditions(true);
  };

  const toggleCloseConditions = () => {
    setOpenConditions(false);
  };

  useEffect(() => {
    if (!!brokers.length) {
      setBroker(brokers[0].value);
    }
  }, [brokers]);

  return (
    <>
      <Grid>
        <Grid.Row columns={"equal"}>
          <AuthorizationContainer action={ActionEnum.create} feature={FeatureEnum.claims}>
            <Grid.Column>
              <div style={{ display: "flex" }}>
                <CreateClaimEstateModal />
              </div>
            </Grid.Column>
          </AuthorizationContainer>

          <Grid.Column textAlign="right">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <ModalInstructions
                  openModal={openModal}
                  toggleOpen={toggleOpen}
                  toggleClose={toggleClose}
                />
              </div>

              <div>
                <GeneralCondition
                  openModal={openConditions}
                  toggleOpen={toggleOpenConditions}
                  toggleClose={toggleCloseConditions}
                />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Table />
        </Grid.Row>
      </Grid>
    </>
  );
};

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user,
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (
    availableBrokers: AvailableBrokersAndEstatesState
  ) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(
      availableBrokers
    ),
});

export default connect(mapState, mapDispatch)(ClaimEstateIndex);
