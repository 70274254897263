export enum ClaimValuesTypesEnum {
  condominium_fee = 'CONDOMINIUM_FEE',
  energy_bill = 'ENERGY_BILL',
  gas_bill = 'GAS_BILL',
  penalty = 'PENALTY',
  painting_value = 'PAINTING_VALUE',
  property_damage_value = 'PROPERTY_DAMAGE_VALUE',
  rent_value = 'RENT_VALUE',
  taxes_value = 'TAXES_VALUE',
  water_bill = 'WATER_BILL',
  fire_insurance_bill = "FIRE_INSURANCE_BILL"
}
