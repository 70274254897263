import { get } from "./request";
import { FormatDateYMD } from "./date";

export const getDataByDocument = async (document: string): Promise<any> => {
  return document.length <= 11 ? getDataByCPF(document) : getDataByCNPJ(document);
}

const getDataByCPF = async (cpf: string) => {

  const response = await get(`/documents/${cpf}`);
  return response;

}

const getDataByCNPJ = async (cnpj: string) => {

  return await get(`/documents/${cnpj}`);
}
