import { Field, FormikProps } from "formik";
import React, { SyntheticEvent, useEffect } from "react";
import { Dropdown, Grid } from "semantic-ui-react";
import CustomDatePicker from "../../../../../../../../components/CustomDatePicker";
import AppMaskedInput from "../../../../../../../../components/MaskedInput";

import SectionLabel from "../../../../../../../../components/SectionLabel";
import { BondInsurancePropertyForGetBondInsurancesResponseDTO } from "../../../../../../../../dtos/bond-insurance-list/get-bond-insurances-response.dto";
import { FieldLabel } from "../../../../../../../../styles";
import { monthDiff } from "../../../../../../../../util";
import { PayloadPortoUpdateRequestInterface } from "../../ModalHireProposal/interfaces/SubmitEssencialHirePorto.interface";
import { formField } from "../../ModalHireProposal/Model/formFields";
import { DaysDropdownOptions } from "../../../../../../../../enums/days.dropdown";

type ContractDateFormRequestInterface = {
  property?: BondInsurancePropertyForGetBondInsurancesResponseDTO;
  readOnly?: boolean; // if true, the form is readOnly and the user can't change the values
  minDate?: Date;  // create At da analise
};

const ContractDateForm = ({
  values,
  setFieldValue,
  property,
  readOnly,
  minDate
}: FormikProps<PayloadPortoUpdateRequestInterface> &
  ContractDateFormRequestInterface) => {

  return (
    <div style={{ height: "55vh", padding: "0 0 0 10px" }}>
      <SectionLabel text="Ajuste das datas de vigência do seguro" />

      <Grid>
      {property?.validity !==
          monthDiff(
            new Date(values.contract.startContractDate),
            new Date(values.contract.endContractDate)
          ) && (
          <Grid.Row>
            <Grid.Column color="red"> <b>
              Atenção: Alterações na vigência do seguro/contrato pode gerar
              alteração no valor da parcela.</b>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column width={8}>
          <FieldLabel>
            Vigência informada na cotação: {property?.validity} meses
          </FieldLabel>
          </Grid.Column>
          <Grid.Column width={8}>
          <FieldLabel>
            Vigência informada na contratação:{" "}
            {
                 values.contract.startContractDate
              && values.contract.endContractDate
              ? `${monthDiff(
                new Date(values.contract.startContractDate),
                new Date(values.contract.endContractDate)
              )} meses`
              : "Não Informado"
            }
          </FieldLabel>
          </Grid.Column>
        </Grid.Row>

      </Grid>

      <Grid columns="equal">
        <Grid.Row>
          {
            <Grid.Column width={8}>
              <FieldLabel>
                {formField.contract.startContractDate.label}
              </FieldLabel>
              <Field
                name={formField.contract.startContractDate.name}
                selected={values.contract.startContractDate}
                component={CustomDatePicker}
                minDate={minDate ? new Date(minDate) : undefined}
                maxDate={ minDate ?
                  new Date(new Date().setDate(new Date(minDate!).getDate() + 90)) : undefined
                }
                customInput={
                  <AppMaskedInput
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      "/",
                      /[0-9]/,
                      /[0-9]/,
                      "/",
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                  />
                }
                dateFormat={"dd/MM/yyyy"}
                disabled={readOnly}
                onChange={(date: Date, _e: any) => {
                  setFieldValue(
                    formField.contract.startContractDate.name,
                    date
                  );
                  setFieldValue(
                    formField.contract.startInsuranceDate.name,
                    date
                  );
                }}
              />
              <div
                style={{
                  backgroundColor: "#ddd",
                  borderRadius: "5px",
                  padding: "5px",
                  marginTop: "10px",
                }}
              >
                Regras: <br />
                <p style={{ fontSize: "14px", marginLeft: "10px" }}>
                  {" "}
                  A data de inicio do contrato deve ser igual a data inicial do
                  seguro.
                  <br /> A data inicial do contrato precisa ser maior ou igual a
                  data de hoje.
                </p>
              </div>
            </Grid.Column>
          }
          <Grid.Column width={8}>
            <FieldLabel>{formField.contract.endContractDate.label}</FieldLabel>
            <Field
              name={formField.contract.endContractDate.name}
              selected={values.contract.endContractDate}
              minDate={new Date(new Date().setMonth(new Date().getMonth() + 12))}
              maxDate={
                new Date(new Date().setFullYear(new Date().getFullYear() + 5))
              }
              disabled={readOnly}
              component={CustomDatePicker}
              customInput={
                <AppMaskedInput
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    "/",
                    /[0-9]/,
                    /[0-9]/,
                    "/",
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                  ]}
                />
              }
              dateFormat={"dd/MM/yyyy"}
              onChange={(date: Date, _e: SyntheticEvent) => {
                setFieldValue(formField.contract.endContractDate.name, date);
                setFieldValue(formField.contract.endInsuranceDate.name, date);
              }}
            />
            <div
              style={{
                backgroundColor: "#ddd",
                borderRadius: "5px",
                padding: "5px",
                marginTop: "10px",
              }}
            >
              Regras: <br />
              <p style={{ fontSize: "14px", marginLeft: "10px" }}>
                {" "}
                A data final do contrato deve ser igual a data final do seguro.
                <br /> A vigência precisa ser de pelo menos 12 meses.
                <br /> O tempo máximo do seguro é de 5 anos.
              </p>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel>
              {formField.contract.startInsuranceDate.label}
            </FieldLabel>
            <Field
              disabled
              name={formField.contract.startInsuranceDate.name}
              selected={values.contract.startInsuranceDate}
              component={CustomDatePicker}
              customInput={
                <AppMaskedInput
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    "/",
                    /[0-9]/,
                    /[0-9]/,
                    "/",
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                  ]}
                />
              }
              dateFormat={"dd/MM/yyyy"}
              onChange={(date: Date, _e: SyntheticEvent) => {
                setFieldValue(formField.contract.startInsuranceDate.name, date);
              }}
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <FieldLabel>{formField.contract.endInsuranceDate.label}</FieldLabel>
            <Field
              disabled
              name={formField.contract.endInsuranceDate.name}
              selected={values.contract.endInsuranceDate}
              component={CustomDatePicker}
              customInput={
                <AppMaskedInput
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    "/",
                    /[0-9]/,
                    /[0-9]/,
                    "/",
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                  ]}
                />
              }
              dateFormat={"dd/MM/yyyy"}
              onChange={(date: Date, _e: SyntheticEvent) => {
                setFieldValue(formField.contract.endInsuranceDate.name, date);
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
              <FieldLabel>Dia de vencimento do Aluguel* </FieldLabel>
              <Dropdown
                placeholder="Selecione o dia"
                fluid
                selection
                disabled={readOnly}
                options={DaysDropdownOptions}
                value={values.contract.dueDate}
                onChange={(_e, { value }) => {
                  setFieldValue(formField.contract.dueDate.name, value);
                }} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
export default ContractDateForm;
