import React, { useState, useEffect } from 'react';
import { Table, Dimmer, Segment, Loader, Menu, Icon, Label, Button, TableCell } from 'semantic-ui-react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import IconButton from '../../components/IconButton';
import { maskCpf, maskCnpj } from '../../services/masks';
import { sortBondInsurances } from '../../services/bond-insurance';
import { iRootState, iRootDispatch } from '../../store';
import { DateToStringDate, DateToStringTime } from '../../services/date';
import { BondInsuranceDetails } from './components/BondInsuranceDetails';
import { BondInsuranceAnalysisStatusEnum } from '../../enums/bond-insurance-analysis-status.enum';
import { round } from '../../util';
import { PieChart } from 'react-minimal-pie-chart';
import AnalysisProgressModal from './components/AnalysisProgressModal';
import {
  BondInsuranceAnalysisForGetBondInsurancesResponseDTO,
  BondInsuranceForGetBondInsurancesResponseDTO,
} from '../../dtos/bond-insurance-list/get-bond-insurances-response.dto';
import { ClickableTableCell, SelectableTableRow } from '../../styles';
import { hasPermission } from '../../components/AuthorizationContainer';
import { ActionEnum } from '../../enums/authz-action.enum';
import { FeatureEnum } from '../../enums/authz-feature.enum';
import useWindowSize from '../../hooks/useWindowSize';
import FollowUpModal from '../../components/GeneralFollowUpModal';
import ModalAttachFiles from '../../components/ModalAttachFiles';
import { get } from '../../services/storage';
import { analysisFileIdentifiers } from './types';
import { sendMessage } from '../../services/generalfollowup';

const ProposalsTableComponent = styled.div`
  margin-top: 1rem;
`;

const Header = styled(Table.Header)`
  background-color: #e1e8ed;
`;

const TableWrapper = styled.div`
  font-size: 0.85em;
  font-weight: bold;

  .ui.table {
    border: none;
    text-align: center;
    color: #364445;
  }
`;

const NotFoundWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #364445;
  font-size: 1.2em;
`;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapState = (state: iRootState) => ({
  user: state.user,
  availableBrokersAndEstates: state.availableBrokersAndEstates
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatch = (dispatch: iRootDispatch) => ({});

type Props = {
  bondInsurances: BondInsuranceForGetBondInsurancesResponseDTO[];
  isLoading: boolean;
  getData: Function;
  currentPage: number;
  setCurrentPage: Function;
  dataCount: number;
  externalBondInsuranceAnalysisId?: string;
  cleanIdReferences?: () => void;
} & ReturnType<typeof mapState>;

type MatchAnalysisProgressBondInsurance<T> = T & {
  bond_insurance_analysis: BondInsuranceAnalysisForGetBondInsurancesResponseDTO[];
};

const BondInsurancesTable = (props: Props): JSX.Element => {
  const {
    bondInsurances,
    isLoading,
    getData,
    user,
    currentPage,
    setCurrentPage,
    dataCount,
    externalBondInsuranceAnalysisId,
    cleanIdReferences
  } = props;
  const [listData, setListData] = useState<
    BondInsuranceForGetBondInsurancesResponseDTO[]
  >([]);
  const [ascendingOrder, setAscendingOrder] = useState<boolean>(true);
  const [indexOpen, setIndexOpen] = useState<number>(-1);
  const { isMobile } = useWindowSize();
  const [openFollowUp, setOpenFollowUp] = useState<boolean>(false);

  const lastPage = Math.floor(dataCount / 10) + 1;

  const [userCanEdit, setUserCanEdit] = useState<boolean>(false);

  const [selectedItem, setSelectedItem] =
    useState<BondInsuranceForGetBondInsurancesResponseDTO | null>(null);

  const userId = get('uid');
  const userName = get('name');

  useEffect(() => {
    const has = hasPermission(ActionEnum.update, FeatureEnum.analysis);
    setUserCanEdit(has);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setListData(bondInsurances);
    }
  }, [bondInsurances, isLoading]);

  const handleOpen = () => {
    setOpenFollowUp(true);
  };

  const handleClose = () => {
    setOpenFollowUp(false);
    setSelectedItem(null);
    getData();
  };

  const handleCallBackFiles = async (data: any, bondInsuranceId: string) => {
      let message = 'Foram anexados os seguintes arquivos: ';
      message += `${data.join(', ')}.`;
      await sendMessage(
        bondInsuranceId,
        userId ?? '',
        message,
        userName ?? '',
        'BOND_INSURANCES',
        null,
        userId,
        bondInsuranceId,
        false
      );
  }

  const handleCalcUnread = (followup: any[], idUser: string) => {

    if (idUser === '') return 0;

    if (followup.length === 0) return 0;

    let count = 0;
    followup.forEach(item => {
      const dontCount = user.role.includes('ESTATE') && item.isInternal;
      if (item.viewed_by !== undefined && item.viewed_by.length > 0 && !dontCount) {
        if (!item.viewed_by.includes(idUser)) count++;
      }
    })

    return count;
  }

  function toggleAscending(): void {
    const toggledAscending = !ascendingOrder;
    setAscendingOrder(toggledAscending);
    const orderedData = sortBondInsurances(bondInsurances, toggledAscending);
    setListData(orderedData);
  }

  function analysisProgress<T>(bondInsurance: MatchAnalysisProgressBondInsurance<T>) {
    let chartData;
    let progress: number;
    if (
      bondInsurance.bond_insurance_analysis &&
      bondInsurance.bond_insurance_analysis.length > 0
    ) {
      const analysisList = bondInsurance.bond_insurance_analysis;
      const proposal = analysisList.filter(
        a => a.status === BondInsuranceAnalysisStatusEnum.proposal
      ).length;
      const waiting = analysisList.filter(
        a => a.status === BondInsuranceAnalysisStatusEnum.waiting
      ).length;
      const pending = analysisList.filter(
        a => a.status === BondInsuranceAnalysisStatusEnum.pending
      ).length;
      const analysis = analysisList.filter(
        a => a.status === BondInsuranceAnalysisStatusEnum.analysis
      ).length;
      const rejected = analysisList.filter(
        a => a.status === BondInsuranceAnalysisStatusEnum.rejected
      ).length;
      const biometry = analysisList.filter(
        a => a.status === BondInsuranceAnalysisStatusEnum.biometry
      ).length;
      const approved = analysisList.filter(
        a => a.status === BondInsuranceAnalysisStatusEnum.approved
      ).length;
      const preapproved = analysisList.filter(
        a => a.status === BondInsuranceAnalysisStatusEnum.preapproved
      ).length;
      const error = analysisList.filter(
        a => a.status === BondInsuranceAnalysisStatusEnum.error
      ).length;
      chartData = [
        {
          title: 'PENDENCIADO',
          value: round((pending / analysisList.length) * 100, 2),
          color: '#FBBD08'
        },
        {
          title: 'EM PROPOSTA',
          value: round((proposal / analysisList.length) * 100, 2),
          color: '#710193'
        },
        {
          title: 'EM ANÁLISE',
          value: round((analysis / analysisList.length) * 100, 2),
          color: '#2185D0'
        },
        {
          title: 'REJEITADA',
          value: round((rejected / analysisList.length) * 100, 2),
          color: '#DB2828'
        },
        {
          title: 'BIOMETRIA',
          value: round((biometry / analysisList.length) * 100, 2),
          color: '#FE9A76'
        },
        {
          title: 'APROVADA',
          value: round((approved / analysisList.length) * 100, 2),
          color: '#21BA45'
        },
        {
          title: 'PRÉ-APROVADA',
          value: round((preapproved / analysisList.length) * 100, 2),
          color: '	#66B2B2'
        },
        {
          title: 'ERRO',
          value: round((error / analysisList.length) * 100, 2),
          color: 'black'
        },
        {
          title: 'EM ESPERA',
          value: round((waiting / analysisList.length) * 100, 2),
          color: 'grey'
        }
      ];
      progress = round(((approved + rejected) / analysisList.length) * 100, 0);
    } else {
      chartData = [{ title: 'EM ESPERA', value: 100, color: 'grey' }];
      progress = 0;
    }

    return (
      <div style={{ width: '100%' }}>
        <PieChart
          data={chartData}
          label={({ dataEntry }) => `${progress} %`}
          labelPosition={0}
          lineWidth={25}
          animate
          labelStyle={{ fontSize: '2.2em', fill: '#b8bbbc' }}
          style={{ height: '35px', padding: '0px', cursor: 'pointer', width: '100%' }}
        />
      </div>
    );
  }

  return (
    <ProposalsTableComponent>
      <Dimmer.Dimmable as={Segment} dimmed={isLoading}>
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
        {listData && listData.length > 0 ? (
          <TableWrapper>
            <Table tablet stackable>
              <Header>
                <Table.Row>
                  <Table.HeaderCell collapsing width={2}>
                    CRIADO EM
                    <IconButton
                      name={
                        ascendingOrder
                          ? 'long arrow alternate up'
                          : 'long arrow alternate down'
                      }
                      color="blue"
                      onClick={toggleAscending}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell collapsing width={2}>
                    IMOBILIÁRIA
                  </Table.HeaderCell>
                  <Table.HeaderCell collapsing width={2}>
                    INQUILINO
                  </Table.HeaderCell>
                  <Table.HeaderCell collapsing width={2}>
                    ENDEREÇO
                  </Table.HeaderCell>
                  <Table.HeaderCell collapsing width={3}>
                    GESTOR DE CONTAS
                  </Table.HeaderCell>
                  <Table.HeaderCell collapsing width={1}>
                    STATUS DA ANÁLISE
                  </Table.HeaderCell>
                  <Table.HeaderCell collapsing width={2}>
                    AÇÕES
                  </Table.HeaderCell>
                </Table.Row>
              </Header>
              <Table.Body>
                {listData?.map((bondInsurance, index) => {
                  const unread = handleCalcUnread(bondInsurance.followup ?? [], userId ?? '');
                  const initialParcel = bondInsurance.bond_insurance_analysis?.map(
                    analysis => {
                      if (analysis.value === null) {
                        return 0;
                      }

                      const value =
                        typeof analysis.value === 'string'
                          ? parseFloat(analysis.value)
                          : analysis.value;
                      return value;
                    }
                  );

                  const tenantDocument = bondInsurance.natural_tenant
                    ? maskCpf(bondInsurance.natural_tenant.main_tenant_cpf)
                    : maskCnpj(bondInsurance.legal_tenant.document);
                  const tenantName = bondInsurance.natural_tenant
                    ? bondInsurance.natural_tenant.main_tenant_name
                    : bondInsurance.legal_tenant.company;

                  return (
                    <>
                      <BondInsuranceDetails
                        bondInsurance={bondInsurance}
                        isOpen={index === indexOpen}
                        onClose={() => setIndexOpen(-1)}
                        insurersData={
                          props.availableBrokersAndEstates.estates.find(
                            c => c.value.id === bondInsurance.estate?.id
                          )?.value?.insurer_data
                        }
                        insurers={
                          props.availableBrokersAndEstates.estates.find(
                            c => c.value.id === bondInsurance.estate?.id
                          )?.value?.insurers
                        }
                      />

                      <SelectableTableRow
                        key={bondInsurance.id}
                        style={{ cursor: 'pointer' }}
                      >
                        <ClickableTableCell onClick={() => setIndexOpen(index)}>
                          {`${DateToStringDate(
                            bondInsurance.created_at || new Date(0, 0, 0)
                          )}`}
                          <br />
                          {DateToStringTime(
                            bondInsurance.created_at || new Date(0, 0, 0)
                          )}
                        </ClickableTableCell>
                        <ClickableTableCell onClick={() => setIndexOpen(index)}>
                          {bondInsurance.estate?.name}
                        </ClickableTableCell>
                        <ClickableTableCell onClick={() => setIndexOpen(index)}>
                          <div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              {tenantName}
                              <span style={{fontSize: '12px', color: '#808080'}}>{tenantDocument}</span>
                            </div>
                            {bondInsurance.last_bond_insurances_reanalysis_id && (
                              <Label
                                empty
                                circular
                                color={'orange'}
                                size={'mini'}
                                style={{ position: 'relative', top: -10, right: -5 }}
                              />
                            )}
                          </div>
                        </ClickableTableCell>
                        <ClickableTableCell onClick={() => setIndexOpen(index)}>
                          {bondInsurance.property.street}
                          <br />(
                          {bondInsurance.purpose === 'COMMERCIAL'
                            ? 'Comercial'
                            : 'Residencial'}
                          )
                        </ClickableTableCell>
                        <ClickableTableCell onClick={() => setIndexOpen(index)}>
                          {bondInsurance.estate?.user.name}
                        </ClickableTableCell>
                        <ClickableTableCell>
                          {
                            <AnalysisProgressModal
                              user={user}
                              positive
                              header="Detalhes da Análise"
                              initialParcel={initialParcel}
                              bondInsurance={bondInsurance}
                              bondInsuranceId={bondInsurance.id}
                              property={bondInsurance.property}
                              estate={bondInsurance.estate as any}
                              analysisDetails={bondInsurance.bond_insurance_analysis}
                              validityMonths={bondInsurance.property.validity}
                              naturalTentant={bondInsurance.natural_tenant}
                              totalAmount={bondInsurance.property.total_amount}
                              rentValue={parseFloat(`${bondInsurance.property.rent_value}`)}
                              calculationMode={bondInsurance.estate!.calculationMode}
                              calculationType={bondInsurance.estate?.calculationType}
                              canEdit={userCanEdit}
                              trigger={analysisProgress(bondInsurance)}
                              externalBondInsuranceAnalysisId={externalBondInsuranceAnalysisId}
                              cleanIdReferences={cleanIdReferences}
                              callback={async (): Promise<void> => {await getData()}}
                            />
                          }
                        </ClickableTableCell>
                        <TableCell>
                          <Menu compact>
                            <Menu.Item>
                              <Button
                                primary
                                icon="chat"
                                onClick={() => {
                                  setSelectedItem(bondInsurance);
                                  setOpenFollowUp(true);
                                }}
                              />
                              {unread !== 0 && (
                                <Label color="red" circular floating >
                                  {unread}
                                </Label>
                              )}
                              { selectedItem &&
                                selectedItem.id === bondInsurance.id &&
                                openFollowUp && (
                                  <FollowUpModal
                                    id={bondInsurance.id}
                                    isOpen={true}
                                    onOpen={handleOpen}
                                    onClose={handleClose}
                                    header="Follow Up"
                                    context={'BOND_INSURANCES'}
                                    user={user}
                                  />
                                )
                              }
                            </Menu.Item>
                            <Menu.Item>
                            <ModalAttachFiles
                              message={`Gerencie os anexos no contexto de Análises`}
                              trigger={<Button color="blue" icon="attach" basic/>}
                              bondInsuranceId={bondInsurance.id}
                              tableName={'bond_insurance'}
                              fileIdentifiers={analysisFileIdentifiers}
                              callback={data => handleCallBackFiles(data, bondInsurance.id)}
                              brokerOnly={true}
                              tenantName={tenantName}
                            />
                          </Menu.Item>
                          </Menu>
                        </TableCell>
                      </SelectableTableRow>
                    </>
                  );
                })}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="17">
                    <Menu floated={isMobile ? false : 'right'} pagination>
                      {!isMobile && (
                        <Menu.Item>
                          <p>{`${currentPage * 10 - 9} - ${dataCount < 10 ?
                            dataCount : (currentPage * 10)
                          }  de ${dataCount}`}</p>
                        </Menu.Item>
                      )}
                      <Menu.Item
                        disabled={currentPage === 1}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(1)}
                      >
                        <Icon name="angle double left" />
                      </Menu.Item>
                      <Menu.Item
                        disabled={currentPage === 1}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item as="a">{currentPage}</Menu.Item>
                      <Menu.Item
                        disabled={currentPage === lastPage || bondInsurances.length < 10}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                      <Menu.Item as="a" icon>
                        <Icon
                          disabled={
                            currentPage === lastPage || bondInsurances.length < 10
                          }
                          name="angle double right"
                          onClick={() => setCurrentPage(lastPage)}
                        />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </TableWrapper>
        ) : (
          <NotFoundWrapper>
            <p>Análises não encontradas</p>
          </NotFoundWrapper>
        )}
      </Dimmer.Dimmable>
    </ProposalsTableComponent>
  );
};

export default connect(mapState, mapDispatch)(BondInsurancesTable);
