import React, { useState } from 'react';
import {
  Button,
  Dropdown,
  Grid,
  Icon,
  Input,
  InputOnChangeData,
  Radio
} from 'semantic-ui-react';
import { CreatePolicyProps } from '../../types';
import { PersonTypesEnum } from '../../../../../../enums/person-types.enum';
import {
  DATE_INPUT_MASK,
  formatAmount,
  maskCnpj,
  maskCpf,
  maskCpfOrCnpj,
  maskPhoneNumber,
  unmaskCpfOrCnpj
} from '../../../../../../services/masks';
import CustomDatePicker from '../../../../../../components/CustomDatePicker';
import AppMaskedInput from '../../../../../../components/MaskedInput';
import {
  UFOptions,
  employmentRelationshipOptions,
  genderOptions,
  hirePropertyTypeOptions,
  maritalStatusOptions
} from '../../../../../bond-insurance-budget-form/types';
import { EmploymentRelationshipEnum } from '../../../../../../enums/employment-relationship.enum';
import { FormTitle, Line } from '../../styles';
import { onChangeCep } from '../../utils';
import { specialCharRemove } from '../../../../../../utils/string.util';
import { SympathiseTenantForGetBondInsurancesResponseDTO } from '../../../../../../dtos/bond-insurance-list/get-bond-insurances-response.dto';
import {
  SympathiseTenantHeader,
  SympathiseTenantLabel
} from '../../../../../bond-insurance-budget-form/style';
import { FieldLabel } from '../../../../../../styles';
import { PoliticallyExposedPersonEnum } from '../../../../../../enums/politically-exposed-person.enum';
import { getDataByDocument } from '../../../../../../services/documents';
import { dangerNotification } from '../../../../../../services/notification';

const sympathiseTenantBlankInfo: any = {
  name: '',
  cpf: '',
  rg: '',
  rg_expeditor_organ: '',
  gender: '',
  mother_name: '',
  marital_status: '',
  nationality: '',
  profession: '',
  current_address_zipcode: '',
  monthly_fixed_income: '',
  other_incomes: '',
  document: '',
  resident: false,
  politically_exposed_person: PoliticallyExposedPersonEnum.no,
  close_to_politically_exposed_person: {
    politically_exposed_person_name: '',
    politically_exposed_person_cpf: '',
    degree_of_kinship_to_politically_exposed_person: ''
  }
};

export const RenderTenantForm = ({
  values,
  setFieldValue
}: {
  values: CreatePolicyProps;
  setFieldValue: (field: string, value: any) => void;
}) => {
  const [cepLoading, setCepLoading] = React.useState(false);
  const [isCpfLoading, setIsCpfLoading] = useState<boolean>(false);
  const [sympathiseTenants, setSympathiseTenants] = useState<
    SympathiseTenantForGetBondInsurancesResponseDTO[]
  >([]);

  const handleChangeSympathiseTenant = (
    e: React.ChangeEvent<HTMLInputElement> | string,
    data: any,
    index: number
  ) => {
    const newSympathiseTenants =
      sympathiseTenants?.map((tenant, i) => {
        if (i === index) {
          return {
            ...tenant,
            [typeof e === 'string' ? e : e.target.name]: data
          };
        }
        return tenant;
      }) || [];
    setSympathiseTenants(newSympathiseTenants);
    setFieldValue('bondInsurance.naturalTenant.sympathise_tenants', newSympathiseTenants);
  };

  const handleRemoveSympathiseTenant = async (
    tenant: SympathiseTenantForGetBondInsurancesResponseDTO
  ) => {
    const newTenants = sympathiseTenants.filter(
      item => tenant.document !== item?.document
    );
    setSympathiseTenants(newTenants);
    setFieldValue('bondInsurance.naturalTenant.sympathise_tenants', newTenants);
  };

  const onChangeCpf = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const unmaskedValue = unmaskCpfOrCnpj(e.target.value);

    if (unmaskedValue.length >= 11) {
      setIsCpfLoading(true);

      getDataByDocument(unmaskedValue)
        .then(data => {
          const birthDate = new Date(data.birth_date);
          birthDate.setTime(
            birthDate.getTime() + birthDate.getTimezoneOffset() * 60 * 1000
          );

          const newSympathiseTenants =
            sympathiseTenants?.map((tenant, i) => {
              if (i === index) {
                return {
                  ...tenant,
                  name: data?.name || '',
                  birth_date: data.birth_date ? birthDate : undefined,
                  gender: data?.gender || '',
                  mother_name: data?.mother_name || ''
                };
              }
              return tenant;
            }) || [];
          setSympathiseTenants(newSympathiseTenants);
        })
        .catch(() => {
          dangerNotification(
            'Oops...',
            'Não foi possível encontrar dados associados ao documento informado'
          );
        })
        .finally(() => {
          setIsCpfLoading(false);
        });
    }
  };

  const renderSympathiseTenants = () => {
    return (
      <Grid>
        {sympathiseTenants?.map((tenant, index) => {
          return (
            <>
              {index >= 0 && (
                <Grid.Row>
                  <Grid.Column width={16}>
                    <SympathiseTenantHeader>
                      <SympathiseTenantLabel>
                        {index + 1}. LOCATÁRIO SOLIDÁRIO
                      </SympathiseTenantLabel>
                      <Button
                        color="red"
                        basic
                        type="button"
                        size="mini"
                        onClick={() => handleRemoveSympathiseTenant(tenant)}
                      >
                        <Icon name="cancel" /> Remover
                      </Button>
                    </SympathiseTenantHeader>
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row>
                <Grid.Column width={4}>
                  <label>CPF*</label>
                  <Input
                    value={tenant.cpf}
                    name="cpf"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ) => {
                      handleChangeSympathiseTenant(e, maskCpfOrCnpj(data.value), index);
                    }}
                    onBlur={(e: any) => onChangeCpf(e, index)}
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={4}>
                  <label>NOME*</label>
                  <Input
                    name="name"
                    value={tenant.name}
                    loading={isCpfLoading}
                    disabled={isCpfLoading}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ): void => {
                      handleChangeSympathiseTenant(e, data.value, index);
                    }}
                    fluid
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <label>GÊNERO* </label>
                  <Dropdown
                    name="gender"
                    loading={isCpfLoading}
                    disabled={isCpfLoading}
                    value={tenant.gender}
                    completeValue={true}
                    onChange={(e, { value }) => {
                      handleChangeSympathiseTenant(e as any, value, index);
                    }}
                    placeholder={'Selecionar...'}
                    options={genderOptions}
                    search
                    clearable
                    selection
                    fluid
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <label>DATA DE NASCIMENTO* </label>
                  <CustomDatePicker
                    selected={tenant.birth_date}
                    onChange={(date: Date) => {
                      handleChangeSympathiseTenant('birth_date', date, index);
                    }}
                    customInput={
                      <AppMaskedInput name="birth_date" mask={DATE_INPUT_MASK} />
                    }
                    loading={isCpfLoading}
                    disabled={isCpfLoading}
                    dateFormat={'dd/MM/yyyy'}
                    style={{
                      marginTop: 0
                    }}
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <label>NOME DA MÃE*</label>
                  <Input
                    name="mother_name"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ): void => {
                      handleChangeSympathiseTenant(e, data.value, index);
                    }}
                    loading={isCpfLoading}
                    disabled={isCpfLoading}
                    value={tenant.mother_name}
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={4}>
                  <label>EMAIL*</label>
                  <Input
                    name="email"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ): void => {
                      handleChangeSympathiseTenant(e, data.value, index);
                    }}
                    value={tenant.email}
                    fluid
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <label>TELEFONE*</label>
                  <Input
                    name="phone"
                    value={tenant.phone}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ): void => {
                      handleChangeSympathiseTenant(e, maskPhoneNumber(data.value), index);
                    }}
                    fluid
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <label>ESTADO CIVIL* </label>
                  <Dropdown
                    name="marital_status"
                    onChange={(e, data): void => {
                      handleChangeSympathiseTenant('marital_status', data.value, index);
                    }}
                    value={tenant.marital_status}
                    completeValue={true}
                    placeholder={'Selecionar...'}
                    options={maritalStatusOptions}
                    search
                    clearable
                    selection
                    fluid
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <label>NACIONALIDADE*</label>
                  <Input
                    name="nationality"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ): void => {
                      handleChangeSympathiseTenant(e, data.value, index);
                    }}
                    value={tenant.nationality}
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={4}>
                  <label>RESIDENTE?*</label>
                  <Grid.Row>
                    <Grid.Column width={3} style={{ paddingTop: '10px' }}>
                      <Radio
                        style={{ paddingLeft: '0px' }}
                        label="Sim"
                        checked={tenant.resident}
                        onChange={(_, data) => {
                          handleChangeSympathiseTenant('resident', true, index);
                        }}
                      />
                      <Radio
                        style={{ paddingLeft: '30px' }}
                        label="Não"
                        checked={!tenant.resident}
                        onChange={() => {
                          handleChangeSympathiseTenant('resident', false, index);
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={4}>
                  <FieldLabel paddingTop={true}>PESSOA POLITICAMENTE EXPOSTA?</FieldLabel>
                  <Grid.Row>
                    <Grid.Column
                      width={5}
                      style={{
                        paddingTop: '10px'
                      }}
                    >
                      <Radio
                        style={{ marginRight: '1rem' }}
                        label="Sim"
                        // value={PoliticallyExposedPersonEnum.yes}
                        // checked={
                        //   tenant.politically_exposed_person ===
                        //   PoliticallyExposedPersonEnum.yes
                        // }
                        onChange={(_, data) => {
                          // handleChangeSympathiseTenant(
                          //   'politically_exposed_person',
                          //   data.value
                          //     ? PoliticallyExposedPersonEnum.yes
                          //     : PoliticallyExposedPersonEnum.no,
                          //   index
                          // );
                        }}
                      />

                      <Radio
                        style={{ marginRight: '1rem' }}
                        label="Não"
                        // value={PoliticallyExposedPersonEnum.no}
                        // checked={
                        //   tenant.politically_exposed_person ===
                        //   PoliticallyExposedPersonEnum.no
                        // }
                        // onChange={(_, data) => {
                        //   handleChangeSympathiseTenant(
                        //     'politically_exposed_person',
                        //     data.value
                        //       ? PoliticallyExposedPersonEnum.no
                        //       : PoliticallyExposedPersonEnum.no,
                        //     index
                        //   );
                        // }}
                      />

                      <Radio
                        label="Próximo"
                        // value={PoliticallyExposedPersonEnum.closeTo}
                        // checked={
                        //   tenant.politically_exposed_person ===
                        //   PoliticallyExposedPersonEnum.closeTo
                        // }
                        // onChange={(_, data) => {
                        //   handleChangeSympathiseTenant(
                        //     'politically_exposed_person',
                        //     data.value
                        //       ? PoliticallyExposedPersonEnum.closeTo
                        //       : PoliticallyExposedPersonEnum.no,
                        //     index
                        //   );
                        // }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid.Column>

                {tenant?.politically_exposed_person ===
                  PoliticallyExposedPersonEnum.closeTo && (
                  <>
                    <Grid.Column width={4}>
                      <label>NOME DA PESSOA*</label>
                      <Input
                        name="close_to_politically_exposed_person.politically_exposed_person_name"
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                          data: InputOnChangeData
                        ): void => {
                          handleChangeSympathiseTenant(e, data.value, index);
                        }}
                        value={
                          tenant?.close_to_politically_exposed_person
                            ?.politically_exposed_person_name
                        }
                        fluid
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <label>CPF DA PESSOA*</label>
                      <Input
                        name="close_to_politically_exposed_person.politically_exposed_person_cpf"
                        value={
                          tenant?.close_to_politically_exposed_person
                            ?.politically_exposed_person_cpf
                        }
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                          data: InputOnChangeData
                        ): void => {
                          handleChangeSympathiseTenant(
                            e,
                            maskCpfOrCnpj(data.value),
                            index
                          );
                        }}
                        fluid
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <label>GRAU DE PARENTESCO*</label>
                      <Input
                        name="close_to_politically_exposed_person.degree_of_kinship_to_politically_exposed_person"
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                          data: InputOnChangeData
                        ): void => {
                          handleChangeSympathiseTenant(e, data.value, index);
                        }}
                        value={
                          tenant?.close_to_politically_exposed_person
                            ?.degree_of_kinship_to_politically_exposed_person
                        }
                        fluid
                      />
                    </Grid.Column>
                  </>
                )}
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={4}>
                  <label>VÍNCULO EMPREGATÍCIO*</label>
                  <Dropdown
                    name="employment_relationship"
                    onChange={(e, { value }) => {
                      handleChangeSympathiseTenant(
                        'employment_relationship',
                        value,
                        index
                      );
                    }}
                    value={tenant.employment_relationship}
                    completeValue={true}
                    placeholder={'Selecionar...'}
                    options={employmentRelationshipOptions}
                    search
                    clearable
                    selection
                    fluid
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <label>PROFISSÃO*</label>
                  <Input
                    name="profession"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ): void => {
                      handleChangeSympathiseTenant(e, data.value, index);
                    }}
                    value={tenant.profession}
                    fluid
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <label>RENDA MENSAL*</label>
                  <Input
                    name="monthly_fixed_income"
                    value={tenant.monthly_fixed_income}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ): void => {
                      handleChangeSympathiseTenant(e, formatAmount(data.value), index);
                    }}
                    fluid
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <label>OUTRAS RENDAS</label>
                  <Input
                    name="other_incomes"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ): void => {
                      handleChangeSympathiseTenant(e, formatAmount(data.value), index);
                    }}
                    value={tenant.other_incomes}
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            </>
          );
        })}
        <Grid.Row>
          <Grid.Column width={16}>
            <Button
              style={{ marginTop: 10 }}
              primary
              color="green"
              onClick={() => {
                if (sympathiseTenants.length > 0) {
                  const lastTenant = sympathiseTenants[sympathiseTenants.length - 1];
                  if (lastTenant?.name?.length > 0 && lastTenant?.cpf?.length > 0)
                    setSympathiseTenants(tenant => [
                      ...tenant,
                      sympathiseTenantBlankInfo
                    ]);
                } else {
                  setSympathiseTenants([sympathiseTenantBlankInfo]);
                }
              }}
              type="button"
            >
              Adicionar Locatário
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  return values?.bondInsurance?.tenantType === PersonTypesEnum.natural ? (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>CPF</label>
            <Input
              fluid
              value={
                values?.bondInsurance?.naturalTenant?.main_tenant_cpf
                  ? maskCpf(values?.bondInsurance?.naturalTenant?.main_tenant_cpf)
                  : ''
              }
              onChange={e => {
                setFieldValue(
                  'bondInsurance.naturalTenant.main_tenant_cpf',
                  specialCharRemove(e.target.value)
                );
              }}
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <label>NOME</label>
            <Input
              fluid
              value={values?.bondInsurance?.naturalTenant?.main_tenant_name}
              onChange={e => {
                setFieldValue(
                  'bondInsurance.naturalTenant.main_tenant_name',
                  e.target.value
                );
              }}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <label style={{ paddingBottom: '5px' }}>DATA DE NASCIMENTO</label>
            <CustomDatePicker
              fluid
              name={'birth_date'}
              placeholder={'--/--/--'}
              type={'date'}
              customInput={
                <AppMaskedInput
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/
                  ]}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              style={{
                marginTop: 0
              }}
              selected={values?.bondInsurance?.naturalTenant?.main_tenant_birth_date}
              onChange={(date: Date, _e: any) => {
                setFieldValue('bondInsurance.naturalTenant.main_tenant_birth_date', date);
              }}
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <label>E-MAIL</label>
            <Input
              fluid
              value={values?.bondInsurance?.naturalTenant?.main_tenant_email}
              onChange={e => {
                setFieldValue(
                  'bondInsurance.naturalTenant.main_tenant_email',
                  e.target.value
                );
              }}
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <label>GÊNERO</label>
            <Dropdown
              search
              style={{ width: '100%' }}
              clearable
              selection
              options={genderOptions}
              value={values?.bondInsurance?.naturalTenant?.main_tenant_gender}
              onChange={(e, { value }) => {
                setFieldValue('bondInsurance.naturalTenant.main_tenant_gender', value);
              }}
              placeholder={'Selecionar...'}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <label>TELEFONE</label>
            <Input
              fluid
              value={
                values?.bondInsurance?.naturalTenant?.main_tenant_phone
                  ? maskPhoneNumber(
                      values?.bondInsurance?.naturalTenant?.main_tenant_phone
                    )
                  : ''
              }
              onChange={e => {
                setFieldValue(
                  'bondInsurance.naturalTenant.main_tenant_phone',
                  e.target.value
                );
              }}
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <label>ESTADO CIVIL</label>
            <Dropdown
              search
              style={{ width: '100%' }}
              clearable
              selection
              options={maritalStatusOptions}
              value={values?.bondInsurance?.naturalTenant?.main_tenant_marital_status}
              onChange={(e, { value }) => {
                setFieldValue(
                  'bondInsurance.naturalTenant.main_tenant_marital_status',
                  value
                );
              }}
              placeholder={'Selecionar...'}
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <label>VÍNCULO EMPREGATÍCIO</label>
            <Dropdown
              search
              style={{ width: '100%' }}
              clearable
              selection
              options={employmentRelationshipOptions}
              value={values?.bondInsurance?.naturalTenant?.employment_relationship}
              onChange={(e, { value }) => {
                setFieldValue(
                  'bondInsurance.naturalTenant.employment_relationship',
                  value
                );
              }}
              placeholder={'Selecionar...'}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <label>PROFISSÃO</label>
            <Input
              fluid
              value={values?.bondInsurance?.naturalTenant?.main_tenant_profession}
              onChange={e => {
                setFieldValue(
                  'bondInsurance.naturalTenant.main_tenant_profession',
                  e.target.value
                );
              }}
            />
          </Grid.Column>

          <Grid.Column width={6}>
            {values.bondInsurance?.naturalTenant?.employment_relationship ===
              EmploymentRelationshipEnum.privateServant ||
            values.bondInsurance?.naturalTenant?.employment_relationship ===
              EmploymentRelationshipEnum.civilServant ? (
              <>
                <label style={{ paddingBottom: '5px' }}>DATA DE ADMISSÃO</label>
                <CustomDatePicker
                  fluid
                  name={'employment_admission_date'}
                  placeholder={'--/--/--'}
                  type={'date'}
                  customInput={
                    <AppMaskedInput
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/
                      ]}
                    />
                  }
                  dateFormat={'dd/MM/yyyy'}
                  style={{
                    marginTop: 0
                  }}
                  selected={
                    values?.bondInsurance?.naturalTenant?.employment_admission_date
                  }
                  onChange={(date: Date, _e: any) => {
                    setFieldValue(
                      'bondInsurance.naturalTenant.employment_admission_date',
                      date
                    );
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </Grid.Column>

          <Grid.Column width={5}> </Grid.Column>
        </Grid.Row>
      </Grid>
      {renderSympathiseTenants()}
    </>
  ) : (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>CNPJ</label>
            <Input
              fluid
              value={maskCnpj(values?.bondInsurance?.legalTenant?.document || '')}
              onChange={e => {
                setFieldValue(
                  'bondInsurance.legalTenant.document',
                  specialCharRemove(e.target.value)
                );
              }}
              placeholder="Digite..."
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <label>EMPRESA</label>
            <Input
              fluid
              value={values?.bondInsurance?.legalTenant?.company}
              onChange={e => {
                setFieldValue('bondInsurance.legalTenant.company', e.target.value);
              }}
              placeholder="Digite..."
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <label>TELEFONE</label>
            <Input
              fluid
              value={
                values?.bondInsurance?.legalTenant?.phone
                  ? maskPhoneNumber(values?.bondInsurance?.legalTenant?.phone)
                  : ''
              }
              onChange={e => {
                setFieldValue('bondInsurance.legalTenant.phone', e.target.value);
              }}
              placeholder="(__) _____-____"
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <label>E-MAIL</label>
            <Input
              fluid
              value={values?.bondInsurance?.legalTenant?.email}
              onChange={e => {
                setFieldValue('bondInsurance.legalTenant.email', e.target.value);
              }}
              placeholder="Digite..."
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <label style={{ paddingBottom: '5px' }}>DATA DE CONSTITUIÇÃO</label>
            <CustomDatePicker
              fluid
              name={'foundation_date'}
              placeholder={'--/--/--'}
              type={'date'}
              customInput={
                <AppMaskedInput
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/
                  ]}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              style={{
                marginTop: 0
              }}
              selected={values?.bondInsurance?.legalTenant?.foundation_date}
              onChange={(date: Date, _e: any) => {
                setFieldValue('bondInsurance.legalTenant.foundation_date', date);
              }}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <label style={{ paddingBottom: '5px' }}>ÚLT. ALT. CONTRATUAL</label>
            <CustomDatePicker
              fluid
              name={'last_contractual_alteration_date'}
              placeholder={'--/--/--'}
              type={'date'}
              customInput={
                <AppMaskedInput
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/
                  ]}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              style={{
                marginTop: 0
              }}
              selected={
                values?.bondInsurance?.legalTenant?.last_contractual_alteration_date
              }
              onChange={(date: Date, _e: any) => {
                setFieldValue(
                  'bondInsurance.legalTenant.last_contractual_alteration_date',
                  date
                );
              }}
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <label>CAPITAL SOCIAL</label>
            <Input
              fluid
              value={
                values?.bondInsurance?.legalTenant?.share_capital
                  ? formatAmount(values?.bondInsurance?.legalTenant?.share_capital)
                  : ''
              }
              onChange={e => {
                setFieldValue('bondInsurance.legalTenant.share_capital', e.target.value);
              }}
              placeholder="R$ 0,00"
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <label>CAPITAL INTEGRALIZADO</label>
            <Input
              fluid
              value={
                values?.bondInsurance?.legalTenant?.paid_in_capital
                  ? formatAmount(values?.bondInsurance?.legalTenant?.paid_in_capital)
                  : ''
              }
              onChange={e => {
                setFieldValue(
                  'bondInsurance.legalTenant.paid_in_capital',
                  e.target.value
                );
              }}
              placeholder="R$ 0,00"
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <label>FATURAMENTO ANUAL</label>
            <Input
              fluid
              value={
                values?.bondInsurance?.legalTenant?.anual_revenue
                  ? formatAmount(values?.bondInsurance?.legalTenant?.anual_revenue)
                  : ''
              }
              onChange={e => {
                setFieldValue('bondInsurance.legalTenant.anual_revenue', e.target.value);
              }}
              placeholder="R$ 0,00"
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <label>CONTATO (NOME)</label>
            <Input
              fluid
              value={values?.bondInsurance?.legalTenant?.contact_name}
              onChange={e => {
                setFieldValue('bondInsurance.legalTenant.contact_name', e.target.value);
              }}
              placeholder="Digite..."
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <label>CONTATO (TELEFONE)</label>
            <Input
              fluid
              value={
                values?.bondInsurance?.legalTenant?.contact_phone
                  ? maskPhoneNumber(values?.bondInsurance?.legalTenant?.contact_phone)
                  : ''
              }
              onChange={e => {
                setFieldValue('bondInsurance.legalTenant.contact_phone', e.target.value);
              }}
              placeholder="(__) _____-____"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <br />
      <br />
      <FormTitle>ENDEREÇO DA EMPRESA</FormTitle>
      <Line />
      <Grid>
        <Grid.Row>
          <Grid.Column width={6}>
            <label>CEP</label>
            <Input
              fluid
              value={values?.bondInsurance?.legalTenant?.zip_code}
              onChange={e => {
                onChangeCep(setFieldValue, 'bondInsurance.legalTenant', e, setCepLoading);
              }}
              placeholder="______-__"
            />
          </Grid.Column>

          <Grid.Column width={10}>
            <label>LOGRADOURO</label>
            <Input
              fluid
              value={values?.bondInsurance?.legalTenant?.street}
              onChange={e => {
                setFieldValue('bondInsurance.legalTenant.street', e.target.value);
              }}
              placeholder="Digite..."
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <label>BAIRRO</label>
            <Input
              fluid
              value={values?.bondInsurance?.legalTenant?.district}
              onChange={e => {
                setFieldValue('bondInsurance.legalTenant.district', e.target.value);
              }}
              placeholder="Digite..."
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <label>CIDADE</label>
            <Input
              fluid
              value={values?.bondInsurance?.legalTenant?.city}
              onChange={e => {
                setFieldValue('bondInsurance.legalTenant.city', e.target.value);
              }}
              placeholder="Digite..."
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <label>UF</label>
            <Dropdown
              search
              loading={cepLoading}
              style={{ width: '100%' }}
              clearable
              selection
              options={UFOptions}
              placeholder={'Selecionar...'}
              value={values?.bondInsurance?.legalTenant?.state}
              onChange={(e, { value }) => {
                setFieldValue('bondInsurance.legalTenant.state', value);
              }}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <label>NÚMERO</label>
            <Input
              fluid
              value={values?.bondInsurance?.legalTenant?.number}
              onChange={e => {
                setFieldValue('bondInsurance.legalTenant.number', e.target.value);
              }}
              placeholder="Digite..."
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <label>COMPLEMENTO</label>
            <Input
              fluid
              value={values?.bondInsurance?.legalTenant?.complement}
              onChange={e => {
                setFieldValue('bondInsurance.legalTenant.complement', e.target.value);
              }}
              placeholder="Digite..."
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <label>TIPO DO IMÓVEL</label>
            <Dropdown
              search
              style={{ width: '100%' }}
              clearable
              selection
              options={hirePropertyTypeOptions}
              placeholder={'Selecionar...'}
              value={values?.bondInsurance?.legalTenant?.property_type}
              onChange={(e, { value }) => {
                setFieldValue('bondInsurance.legalTenant.property_type', value);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
