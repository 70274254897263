import { PropertyInfoType } from "../../canceled-bond-insurance-list/components/create-cancelation-modal/types/PropertyInfoTypes";

export interface DropdownOptions {
  index: number;
  value: number | string;
  text: string;
}

export type ListRecordsType = {
  id: string;
  name?: string;
  property?: PropertyInfoType;
  company_name?: string;
  cpf?: string;
  cnpj?: string;
  origem: string;
  estate: string;
  insurer?: string;
};

export const typeOptions = [
  {
    index: 1,
    value: 'document',
    text: 'CPF ou CNPJ'
  },
  {
    index: 2,
    value: 'name',
    text: 'Nome do locatário'
  },
  {
    index: 3,
    value: 'address',
    text: 'Endereço do imóvel'
  },
  {
    index: 4,
    value: 'insurerPolicyId',
    text: 'Código da apólice'
  },
  {
    index: 5,
    value: 'policyId',
    text: 'ID da apólice'
  }
]
