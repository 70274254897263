import {LoadingReducer} from "../types/temp-types";

type LoadingRematchModel = {
  state: boolean;
  reducers: LoadingReducer;
}

const loading: LoadingRematchModel = {
  state: false,
  reducers: {
    setLoading: (state: boolean, payload: boolean): boolean => payload
  }
};

export default loading;
