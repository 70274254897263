const today = new Date()
const year = today.getFullYear();
const month = today.getMonth();
const day = today.getDate();
const nextYear = new Date(year + 1, month, day);

export const SurtyGeneralInsuraceInitialValues = {
  purpose: '',
  analysisValue: 0,
  observations: '',
  property: {
    reason: '',
    zip_code: '',
    street: '',
    district: '',
    city: '',
    state: '',
    number: '',
    complement: '',
    rent_insurance_contract_start_date: today,
    rent_insurance_contract_end_date: nextYear,
    rental_period_start_date: today,
    rental_period_end_date: nextYear,
    rent_value: '',
    condominium_fee: '',
    taxes_value: '',
    energy_bill: '',
    water_bill: '',
    gas_bill: '',
    formatted_rent_value: '',
    type: '',
    validity: 0,
  },
  natural_tenant: {},
  legal_tenant: {},
  estate: {
    estateZipCode: '',
    estateStreet: '',
    estateDistrict: '',
    estateCity: '',
    estateState: '',
    estateNumber: '',
    estateComplement: ''
  },
  analysis_assurance: '',
  owner_data: {
    public_document_id: '',
    name: '',
  },
  hiredInstallments: 0,
  hiredInstallmentsValue: 0,
  formattedValues: {
    rent_value: '',
    condominium_fee: '',
    taxes_value: '',
    energy_bill: '',
    water_bill: '',
    gas_bill: '',
  },
}

