import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';
import { Grid } from 'semantic-ui-react';
import { maskCnpj } from '../../services/masks';
import { BrokerState } from '../../types/BrokerState';
import { ReportFormProps } from './ReportForm';
import { ReportData } from './components/ReportData';
import Loader from "../../components/Loader";

const ReportContainer = styled.div`
  height: 100%;
`;

const handleFormInputChange = (data: any, formProps: ReportFormProps) => {
  const { setFieldValue, values } = formProps;
  const { field } = data;
  const { value } = data;

  setFieldValue(field.name, value);
};

const ReportFormContent = (props: ReportFormProps) => {

  const { isLoading } = props;

  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <ReportContainer>
      {isLoading &&
        <>
          <Loader />
        </>
      }
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid>
              <ReportData {...props} handleInputChange={handleFormInputChange} />
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign='right' width={16}>
            <Button text="Gerar Relatório"
              color='green'
              size='large'
              type='button'
              onClick={(e) => {
                props.onSubmit(props.values)
              }} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ReportContainer>
  );
}

export default ReportFormContent;
