import React, {useState, useEffect, SyntheticEvent, useCallback} from 'react';
import {
  FireInsuranceBudgetFormSectionProps,
  submitQuote,
  getQuotationParams,
  wasQuoted,
  isAbleToSimulate,
  calculateCoverageDamage,
  getSegimobInstallmentsOptions,
  QuotePayload,
  getInstallmentsOptions,
  getNullInstallmentsOptions
} from '../../FireInsuranceBudgetForm';
import { DropdownItemProps, Grid, Radio, Popup, Icon, Image, Button, Label } from 'semantic-ui-react';
import { InsurerState, Code } from '../../../../types/InsurerState';
import { allDataAreFilled } from '../../../../util';
import { AssistanceState } from '../../../../types/AssistanceState';
import { PropertyTypeState } from '../../../../types/PropertyTypeState';
import SectionLabel from '../../../../components/SectionLabel';
import { FormikErrorMessage } from '../../../../components/ErrorMessage';
import {Field} from 'formik';
import {formatAmount, moneyStringToNumber, numberToMoneyString, unmaskMoney} from '../../../../services/masks';
import { getErrorFormik } from '../../../../services/errors';
import Dropdown from '../../../../components/Dropdown';
import Input from '../../../../components/Input';
import { CustomDropDownOptions } from '../../../../types';
import { installmentsOptions, BudgetProps, CoverageProps } from '../../types';
import { initialSimulator } from '../../constants';
import { sortAssistanceOptionsByValue } from '../../../../util';
import { FieldLabel } from '../../../../styles';
import { dangerNotification } from '../../../../services/notification';
import {iRootDispatch, iRootState} from "../../../../store";
import {connect} from "react-redux";
import {
  FireInsuranceBudgetSelectedAssistanceState,
  FireInsuranceBudgetSelectedInsurerState, FireInsuranceBudgetSelectedOccupationState,
  FireInsuranceBudgetSelectedPropertyTypeState, FireInsuranceQuotationFormSections,
  RentForFireInsuranceQuotationState
} from "../../../../types/FireInsurance";
import styled from "styled-components";
import {debounce} from "lodash";
import {OccupationState} from "../../../../types/OccupationState";
import {SimulatorState} from "../../../../store/types/temp-types";

type InsuranceDataProps =
  ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  & FireInsuranceBudgetFormSectionProps;

const mapDispatchToProps = (dispatch: iRootDispatch) => ({
  updateSimulatorFields: (simulatorFields: Partial<SimulatorState>) => dispatch.simulator.updateSimulatorFields(simulatorFields),
  resetSimulatorFields: () => dispatch.simulator.resetSimulatorFields(),
  resetFireInsuranceBudgetConnectorData: () => dispatch.fireInsuranceBudget.resetConnectorQuotationData(),
  resetFireInsuranceFormValuesFromInsurer: () => dispatch.fireInsuranceBudget.resetFormValuesFromInsurer(),
  resetSelectedConnectorCoverages: () => dispatch.fireInsuranceBudget.resetSelectedConnectorCoverages(),
  toggleToResetFormSections: (payload: Partial<FireInsuranceQuotationFormSections>) => dispatch.fireInsuranceBudget.toggleToResetFormSection(payload),
  updateSelectedPropertyType: (payload: Partial<FireInsuranceBudgetSelectedPropertyTypeState>) =>
    dispatch.fireInsuranceBudget.updateSelectedPropertyType(payload),
  updateSelectedOccupation: (payload: Partial<FireInsuranceBudgetSelectedOccupationState>) =>
    dispatch.fireInsuranceBudget.updateSelectedOccupation(payload),
  updateSelectedAssistance: (payload: Partial<FireInsuranceBudgetSelectedAssistanceState>) =>
    dispatch.fireInsuranceBudget.updateSelectedAssistance(payload),
  updateSelectedInsurer: (payload: Partial<FireInsuranceBudgetSelectedInsurerState>) =>
    dispatch.fireInsuranceBudget.updateSelectedInsurer(payload),
  updateRent: (payload: Partial<RentForFireInsuranceQuotationState>) =>
    dispatch.fireInsuranceBudget.updateRent(payload)
});

const mapStateToProps = (state: iRootState) => ({
  rentValue: state.fireInsuranceBudget.rent.value,
  selectedInsurerId: state.fireInsuranceBudget.selected.insurer.id,
  selectedBrokerId: state.fireInsuranceBudget.selected.broker.id,
  insurers: state.insurers,
  user: state.user,
  setFieldValue: (fieldName: string, value: any) => state.formik.actions.setFieldValue &&
    state.formik.actions?.setFieldValue(fieldName, value),
  setFieldTouched: (fieldName: string, touched: boolean) => state.formik.actions.setFieldTouched &&
    state.formik.actions?.setFieldTouched(fieldName, touched)
});

const InsuranceData = (props: any) => {
  const [propertyTypeOptions, setPropertyTypeOptions] = useState<Array<CustomDropDownOptions>>([]);
  const debouncedUpdateRent = useCallback(debounce((payload) => props.updateRent(payload), 200),[]);
  const {
    values,
    errors,
    touched,
    setIsLoading,
    quotePayload,
    setQuotePayload,
    handleInputChange,
    sectionKey,
    setFieldValue,
    setFieldTouched,
    resetSimulatorFields,
    resetFireInsuranceBudgetConnectorData,
    resetFireInsuranceFormValuesFromInsurer,
    resetSelectedConnectorCoverages,
    toggleToResetFormSections,
    updateSelectedPropertyType,
    updateSelectedOccupation,
    updateSelectedAssistance,
    updateSelectedInsurer,
    updateRent,
    selectedInsurerId,
    selectedBrokerId,
    rentValue
  } = props;
  const { budget, simulator } = props.values;

  const [occupationOptions, setOccupationOptions] = useState<Array<DropdownItemProps>>([]);
  const [assistanceOptions, setAssistanceOptions] = useState<Array<DropdownItemProps>>([]);
  const [selectedInsurer, setSelectedInsurer] = useState<InsurerState>();

  let insurersOptions: Array<InsurerState> = [];

  insurersOptions = props.insurers.insurers;

  const validateErrors = (field: string) => getErrorFormik(errors, touched, sectionKey, field);

  const setInsuranceFieldValue = (field: string, value: any) => {
    setFieldValue(`budget.insurance.${field}`, value);
  }

  const getSegimobAssistancesByOccupation = (occupationId: string) => {
    const occupations = [
      { id: '644', assistances: ['1', '2', '3', '4'] },
      { id: '645', assistances: ['1', '2', '3', '4'] },
      { id: '646', assistances: ['5', '6'] },
      { id: '647', assistances: ['5', '6'] },
      { id: '648', assistances: ['5', '6'] },
      { id: '649', assistances: ['5', '6'] },
      { id: '650', assistances: ['5', '6'] },
      { id: '651', assistances: ['5', '6'] },
    ]
    const o = occupations.filter((o) => o.id === occupationId)[0]
    return o ? o.assistances : []
  }

  useEffect(() => {
    selectInsurer(budget.insurance.insurer)
    getQuotationParams(budget, props);
  }, [budget.insurance.insurer]);

  const selectInsurer = (selectedInsurer: InsurerState): void => {
    //Reminder to fix the relationship between form fields and Redux state
    resetSimulatorFields();
    resetFireInsuranceBudgetConnectorData();
    resetFireInsuranceFormValuesFromInsurer();
    toggleToResetFormSections({
      propertyInputSection: true,
      tenantInputSection: true,
      ownerInputSection: true
    });

    clearCoverage();

    updatePropertyTypeOptions(selectedInsurer);
    setFieldValue('simulator', initialSimulator);
    setFieldValue('simulator.installmentsOptions', getNullInstallmentsOptions());
    setFieldValue('budget.insurance.rentValue', formatAmount(0));

    const propertyTypesList = selectedInsurer.propertyTypes;

    updateSelectedInsurer({
      id: selectedInsurer.id,
      code: selectedInsurer.code,
      isConnectorEnabled: selectedInsurer.connectorInformation?.enabled === 'Y' ? true : false
    });

    if (propertyTypesList && propertyTypesList.length >= 0) {
      updateSelectedPropertyType({
        id: propertyTypesList[0].id,
        description: propertyTypesList[0].description,
        fromConnectorTable: propertyTypesList[0].fromConnectorTable
      });

      if (propertyTypesList[0] && propertyTypesList[0].occupations.length >= 0) {
        const occupation = propertyTypesList[0].occupations[0]

        updateSelectedOccupation({
          id: occupation.id,
          description: occupation.presentationalDescription,
          fromConnectorTable: occupation.fromConnectorTable
        });
      }
    }


    if (selectedInsurer.assistances && selectedInsurer.assistances.length > 0) {
      const assistance = selectedInsurer.assistances[0];

      updateSelectedAssistance({
        id: assistance.id,
        description: assistance.description,
        fromConnectorTable: assistance.fromConnectorTable ? true : false
      });
    }

    setInsuranceFieldValue('insurer', selectedInsurer);
    setFieldValue('simulator.installments', 1);
    setFieldValue('simulator.installmentsOptions', getNullInstallmentsOptions());
    setFieldValue('budget.insurance.insurer', selectedInsurer);

    setSelectedInsurer(selectedInsurer);
  }

  const updatePropertyTypeOptions = (insurerOption: InsurerState) => {
    if (insurerOption.propertyTypes) {
      const propertyOptions: Array<CustomDropDownOptions> = insurerOption.propertyTypes.map((opt, i): CustomDropDownOptions => {
        return {
          value: { ...opt },
          key: i,
          text: opt.description
        }
      });
      setPropertyTypeOptions(propertyOptions);
      budget.insurance.propertyType = propertyOptions.length > 0 ? propertyOptions[0].value : '';
      updateOccupationOptions(insurerOption);
    }
  }

  const updateOccupationOptions = (insurerOption: InsurerState) => {
    if (insurerOption.propertyTypes && insurerOption.propertyTypes.length > 0) {
      let property = insurerOption.propertyTypes[0];

      if (budget.insurance.propertyType) {
        property = budget.insurance.propertyType as unknown as PropertyTypeState;
      }
      const options: Array<CustomDropDownOptions> = property.occupations.map((opt, i): CustomDropDownOptions => {
        return {
          value: { ...opt },
          key: i,
          text: opt.presentationalDescription
        }
      });
      setOccupationOptions(options);
      budget.insurance.occupation = options[0].value as unknown as OccupationState;
      updateAssistanceOptions(insurerOption);
    }
  }

  const updateAssistanceOptions = (insurerOption: InsurerState) => {
    if (!insurerOption.assistances) {
      return
    }
    let assistances = insurerOption.assistances
    //Filter assistenaces by occupation group risk
    if (insurerOption.code === Code.TokioMarine) {
      if (!budget.insurance.occupation) {
        return
      }
      assistances = assistances.filter((a) => getSegimobAssistancesByOccupation(budget.insurance.occupation.id).includes(a.id))
    }
    const assistanceOptions: Array<CustomDropDownOptions> = assistances.map((opt, i): CustomDropDownOptions => {
      return {
        value: { ...opt },
        key: i,
        text: opt.description
      }
    });
    setAssistanceOptions(sortAssistanceOptionsByValue(assistanceOptions));
    budget.insurance.assistance = (insurerOption.code === Code.TokioMarine) ?
      assistanceOptions.filter((a) => (a.text === 'COM ASSISTÊNCIA 24H' || a.text === 'MAIS'))[0].value as unknown as AssistanceState
      : assistanceOptions[0].value as unknown as AssistanceState;
  }

  const simulateQuotation = async (budget: BudgetProps) => {
    if (!wasQuoted(budget, quotePayload)
      //&& allDataAreFilled(budget.insurance.insurer)
      && allDataAreFilled(budget.insurance.assistance)
      && allDataAreFilled(budget.insurance.occupation)
      && unmaskMoney(budget.insurance.rentValue) > 0) {
      if (budget.insurance.insurer.code !== Code.TokioMarine) {
        const value = budget.insurance.rentValue;
        const insurance: any = budget.insurance || {};
        const estate = insurance.estate ? insurance.estate : {};
        if (estate.multiplier) {
          const coverage = calculateCoverageDamage(unmaskMoney(value), estate.multiplier);
          setFieldValue('budget.coverage', coverage);
        }
        return true;
      }
      setIsLoading(true);

      try {
        const res = await submitQuote(budget, simulator, props, selectedBrokerId);
        setQuotePayload({
          insurerId: budget.insurance.insurer.id,
          assistanceId: budget.insurance.assistance.id,
          occupationId: budget.insurance.occupation.id,
          propertyType: budget.insurance.propertyType,
          rentValue: budget.insurance.rentValue,
          coverage: Object.values(budget.coverage)
        } as QuotePayload);

        return res;
      } catch (e) {
        dangerNotification('Erro', 'Não foi possível realizar a cotação do seguro');
        throw e;
      } finally {
        setIsLoading(false);
      }
    }
  }

  const clearCoverage = async () => {
    setFieldValue('budget.coverage', []);
    setFieldValue('simulator', initialSimulator);
    budget.coverage = [];
  }

  const onChangeOccupation = async (e: SyntheticEvent, data: CustomDropDownOptions) => {
    clearCoverage();
    if (isAbleToSimulate(budget, errors.budget)) {
      setFieldValue('simulator', initialSimulator);
    }

    const occupation = data.value as OccupationState;

    updateSelectedOccupation({
      id: occupation.id,
      description: occupation.presentationalDescription,
      fromConnectorTable: occupation.fromConnectorTable
    });

    handleInputChange(e, data);
    await simulateQuotation(budget);
  }

  const onChangePropertyType = async (e: SyntheticEvent, data: CustomDropDownOptions) => {
    resetSelectedConnectorCoverages();
    clearCoverage();

    await getQuotationParams(budget, props);

    if (isAbleToSimulate(budget, errors.budget)) {
      setFieldValue('simulator', initialSimulator);
    }
    if (selectedInsurer && selectedInsurer.propertyTypes) {
      budget.insurance.propertyType = data.value;
      updateOccupationOptions(selectedInsurer);
    }

    const propertyType = data.value as PropertyTypeState;

    updateSelectedPropertyType({
      id: propertyType.id,
      description: propertyType.description,
      fromConnectorTable: propertyType.fromConnectorTable
    });

    if (propertyType.occupations && propertyType.occupations.length >= 0) {
      const occupation = propertyType.occupations[0];

      updateSelectedOccupation({
        id: occupation.id,
        description: occupation.presentationalDescription,
        fromConnectorTable: occupation.fromConnectorTable
      });
    }

    handleInputChange(e, data);
    await simulateQuotation(budget);
  }

  const onChangeRentValue = async (e: SyntheticEvent, data: CustomDropDownOptions) => {
    debouncedUpdateRent({value: moneyStringToNumber(formatAmount(data.value))});

    clearCoverage();
/*    if (isAbleToSimulate(budget, errors.budget)) {
      setFieldValue('simulator', initialSimulator);
    }*/
    data.value = formatAmount(data.value);
    budget.insurance.rentValue = data.value;

    resetSimulatorFields();

    handleInputChange(e, data);
  }

  const onBlurRentValue = async (e: any, data: CustomDropDownOptions) => {
    e.persist();

    debouncedUpdateRent({
      value: moneyStringToNumber(formatAmount(e.target.value)),
      touched: true
    });

    setFieldTouched('budget.insurance.rentValue', true);
    try {
      await simulateQuotation(budget);
    } catch (e) {
      console.log(e);
    }
  }

  const onChangeAssistance = async (e: SyntheticEvent, data: CustomDropDownOptions) => {
    clearCoverage();

    if (isAbleToSimulate(budget, errors.budget)) {
      setFieldValue('simulator', initialSimulator);
    }

    const assistance = data.value as AssistanceState;

    updateSelectedAssistance({
      id: assistance.id,
      description: assistance.description,
      fromConnectorTable: assistance.fromConnectorTable ? true : false
    });

    budget.insurance.assistance = data.value;
    await simulateQuotation(budget);
    handleInputChange(e, data);
  }

  return (
    <>
      <SectionLabel text='DADOS DO SEGURO' />
      <Grid.Row verticalAlign='middle'>
        {insurersOptions.map((insurerOption: InsurerState, index: number) => (
          <React.Fragment key={insurerOption.id}>
            <Grid.Column verticalAlign='middle' key={index}>
              <Radio name='budget.insurance.insurer' value={insurerOption.id}
                checked={budget.insurance.insurer.id === insurerOption.id}
                onChange={(): void => {
                  selectInsurer(insurerOption);
                }} />
              <FormikErrorMessage component="div" name="budget.insurance.insurer" />
            </Grid.Column>
            <Grid.Column width={3} style={{ marginLeft: '10px' }}>
              <Image src={insurerOption.imageUrl} alt={insurerOption.name}
                     onClick={(): void => {
                  selectInsurer(insurerOption);
                }} />
              {/* {(insurerOption.code === Code.TokioMarine) ?
                <FieldLabel
                  style={{ color: '#F9050D', marginLeft: '5px', marginTop: '10px', display: 'block' }}>Beta</FieldLabel> : <></>} */}
            </Grid.Column>
          </React.Fragment>
        )
        )}
      </Grid.Row>
      <FormGridRowContainer isVisible={selectedInsurerId ? true : false}>
        <Grid.Column width={8}>
          <FieldLabel>TIPO DE IMÓVEL<span style={{color: 'red'}}>*</span></FieldLabel>
          <Field name="budget.insurance.propertyType"
            options={propertyTypeOptions}
            placeholder='Selecionar...'
            onChange={onChangePropertyType}
            component={Dropdown}
            error={validateErrors('propertyType')}
            disabled={propertyTypeOptions.length <= 1}
            fluid />
          <FormikErrorMessage component="div" name="budget.insurance.propertyType" />
        </Grid.Column>
        <Grid.Column width={8}>
          <FieldLabel>OCUPAÇÃO<span style={{color: 'red'}}>*</span></FieldLabel>
          <Field name="budget.insurance.occupation"
            value={budget.insurance.occupation.id ? budget.insurance.occupation : null}
            options={occupationOptions}
            disabled={occupationOptions.length === 0}
            component={Dropdown}
            error={validateErrors('occupation')}
            onChange={onChangeOccupation}
            placeholder='Selecionar...'
            fluid />
          <FormikErrorMessage component="div" name="budget.insurance.occupation" />
        </Grid.Column>
      </FormGridRowContainer>
      <FormGridRowContainer isVisible={selectedInsurerId ? true : false}>
        <Grid.Column width={8}>
          <Grid.Row>
            <FieldLabel>ASSISTÊNCIA 24H<span style={{color: 'red'}}>*</span></FieldLabel>
            <Popup content='Assistência' trigger={<Icon name='info circle' />} />
          </Grid.Row>
          <Field name="budget.insurance.assistance"
            value={budget.insurance.assistance.id ? budget.insurance.assistance : null}
            options={assistanceOptions}
            disabled={assistanceOptions.length <= 1}
            component={Dropdown}
            error={validateErrors('assistance')}
            onChange={onChangeAssistance}
            placeholder='Selecionar...'
            fluid />
          <FormikErrorMessage component="div" name="budget.insurance.assistance" />
        </Grid.Column>
        <Grid.Column width={8}>
          <FieldLabel>VALOR DO ALUGUEL<span style={{color: 'red'}}>*</span></FieldLabel>
          <Field name="budget.insurance.rentValue"
            component={Input}
            /*value={numberToMoneyString(rentValue)}*/
            error={validateErrors('rentValue')}
            onChange={onChangeRentValue}
            onBlur={onBlurRentValue}
            fluid />
          <FormikErrorMessage component="div" name="budget.insurance.rentValue" />
        </Grid.Column>
      </FormGridRowContainer>
    </>
  );
}

const FormGridRowContainer = styled(Grid.Row)`
  transition: opacity linear 0.5s;
  opacity: ${(props: FormGridRowContainerProps) => props.isVisible ? 1 : 0};
`;

type FormGridRowContainerProps = {
  isVisible: boolean;
};

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceData);
