import React, { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { IdentificationSection } from './components/IdentificationSection';
import { StepProps } from '../../../BondInsuranceForm';
import  AddressSection  from './components/AddressSection';
import { PartnersSection } from './components/PartnersSection';
import {iRootDispatch, iRootState} from "../../../../../store";
import {BondInsuranceFormState} from "../../../../../store/types/temp-types";
import {connect} from "react-redux";

const mapState = (state: iRootState) => ({
  bondInsuranceForm: state.bondInsuranceForm,
  availableBrokersAndEstates: state.availableBrokersAndEstates
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateBondInsurance: (bondInsuranceFormState: BondInsuranceFormState) =>
    dispatch.bondInsuranceForm.updateBondInsurance(bondInsuranceFormState)
});

type LegalCommercialResidentialFormProps = StepProps & ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const LegalCommercialResidentialForm = (props: LegalCommercialResidentialFormProps) => {

  useEffect(() => {
    if (props.bondInsuranceForm) {
      props.updateBondInsurance({ ...props.bondInsuranceForm })
    }
  }, [])

  return (
    <Grid>
      <IdentificationSection  {...props} />
      <AddressSection {...props} />
      <PartnersSection {...props} />
    </Grid>
  );
}

export default connect(mapState, mapDispatch)(LegalCommercialResidentialForm);
