import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Table,
  Icon,
  Dimmer,
  Segment,
  Loader,
  Dropdown,
  Input,
  Menu,
  Button,
  Grid
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { iRootState, iRootDispatch } from '../../store';
import { DateToStringDate } from '../../services/date';
import { EstateState } from '../../types/EstateState';
import {
  getEstatesObjectsFromGetEstatesDTO,
  getEstatesByPage
} from '../../services/broker';
import IconButton from '../../components/IconButton';
import {
  DATE_INPUT_MASK,
  maskCnpj,
  maskCpfOrCnpj,
  unmaskCpfOrCnpj
} from '../../services/masks';
import { UserState } from '../../types/UserState';
import { EstateStatusEnum } from '../../enums/estate-status.enum';
import { getUsers } from '../../services/broker';
import { UserForUpdateEstateForm, stateOptions, typeOptions } from './types';
import { dangerNotification } from '../../services/notification';
import { UsersToCustomDropDownOptions } from '../../util';
import { CustoDropDownItemProps } from '../../components/types';
import AuthorizationContainer, {
  hasPermission
} from '../../components/AuthorizationContainer';
import { ActionEnum } from '../../enums/authz-action.enum';
import { FeatureEnum } from '../../enums/authz-feature.enum';
import { Header, TableWrapper, NotFoundWrapper, ButtonsContainer } from './styles';
import { getEstateToView } from '../../services/estate';
import { GetEstateToViewDTO } from '../../dtos/estate/estate-for-get-estates-response.dto';
import ViewModal from './components/ViewModal';
import { ClickableTableCell, DateContainer, EndDate, FieldLabel, StartDate } from '../../styles';
import AppMaskedInput from '../../components/MaskedInput';
import { statusList, companyOptions } from './types';
import CustomDatePicker from '../../components/CustomDatePicker';
import moment from 'moment';
import FollowUpModal from '../../components/GeneralFollowUpModal';

const mapState = (state: iRootState) => ({
  estatesList: state.estatesList,
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateEstatesList: (payload: any) => dispatch.estatesList.updateEstatesList(payload)
});

type FilterProps = {
  name?: string;
  document?: string;
  state_id?: string;
  user_id?: string;
  franchise_id?: string;
  state?: string;
  status?: string;
  responsible_company?: string;
  start?: Date | string | undefined;
  end?: Date | string | undefined;
};

type Props = { currentPage: number; setCurrentPage: Function } & ReturnType<
  typeof mapState
> &
  ReturnType<typeof mapDispatch>;

const w100 = {
  width: '100%'
};

const EstatesTable = (props: Props) => {
  const { currentPage, setCurrentPage } = props;
  const user: UserState = props.user;
  const { isLoading, estates } = props.estatesList;
  const [dataCount, setDataCount] = useState<number>(0);
  const [filters, setFilters] = useState<FilterProps>({});
  const [selectedStatus, setSelectedStatus] = useState<string>('ALL');
  const [accountManagerOptions, setAccountManagerOptions] = useState<
    CustoDropDownItemProps[]
  >([]);
  const [isAccountManagerLoading, setIsAccountManagerLoading] = useState<boolean>(false);
  const lastPage = Math.floor(dataCount / 10) + 1;
  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);
  const [estateSelected, setEstateSelected] = useState<GetEstateToViewDTO | null>(null);
  const [type, setType] = useState<string>('document');
  const [isSearchEnabled, setSearchEnabled] = useState(true);

  const [openFollowUp, setOpenFollowUp] = useState<boolean>(false);
  const [followUpId, setFollowUpId] = useState<string>('');

  const [userCanSeeDetails, setUserCanSeeDetails] = useState<boolean>(false);
  const [argument, setArgument] = useState<string>('');

  useEffect(() => {
    const has = hasPermission(ActionEnum.seeDetails, FeatureEnum.estates);
    setUserCanSeeDetails(has);
  }, []);

  useEffect(() => {
    const getAccountManagers = async () => {
      setIsAccountManagerLoading(true);
      getUsers(user?.broker?.id || 'test', [{ isDropdown: true}])
        .then(result => {
          const convertedValues: UserForUpdateEstateForm[] = result.map((user: any) => {
            const userForForm: UserForUpdateEstateForm = {
              id: user.id,
              name: user.name
            };

            return userForForm;
          });

          const analystsOptions = UsersToCustomDropDownOptions(false, convertedValues);
          setAccountManagerOptions(analystsOptions);
        })
        .catch(error => {
          dangerNotification(
            'Oops...',
            'Não foi possível encontrar os analistas disponíveis'
          );
        })
        .finally(() => {
          setIsAccountManagerLoading(false);
        });
    };
    getAccountManagers();
  }, []);

  const getData = async () => {
    try {
      props.updateEstatesList({ isLoading: true, estates: [] });
      const request = (await getEstatesByPage(user?.broker?.id || 'test', currentPage, {
        ...filters,
        start: filters.start ? moment(filters.start).format('YYYY-MM-DD') : undefined,
        end: filters.end ? moment(filters.end).format('YYYY-MM-DD') : undefined,
        document: filters.document ? unmaskCpfOrCnpj(filters.document) : undefined
      })) as any;
      const DTO = request.data;
      if(currentPage === 1) setDataCount(request.count);

      const data = getEstatesObjectsFromGetEstatesDTO(DTO);

      const dataFormatted = data.map(d => {
        let status = 'Pendente';
        switch (d.status) {
          case EstateStatusEnum.blocked:
            status = 'Bloqueado';
            break;
          case EstateStatusEnum.inactive:
            status = 'Inativo';
            break;
          case EstateStatusEnum.pending:
            status = 'Pendente';
            break;
          case EstateStatusEnum.active:
            status = 'Ativo';
            break;
          case EstateStatusEnum.update:
            status = 'Update';
            break;
        }
        return { ...d, status };
      });

      props.updateEstatesList({
        isLoading: false,
        estates: dataFormatted || []
      });
    } catch (err) {
      dangerNotification('Oops...', 'Não foi possível encontrar as imobiliárias!');
    }
  };

  useEffect(() => {
    getData();
  }, [currentPage]);

  const trigger = (
    <>
      <IconButton color="blue" name="ellipsis vertical" />
    </>
  );

  const handleFollowUp = (id: string) => { //id: string, status: string, item: any
    console.log('ABRIU FOLLOW UP')
    setFollowUpId(id);
    setOpenFollowUp(true);
    //setIsOpen(true);
    //setFollowUpClaim(item);
    //setId(id);
    //setIsBlocked(false);
    //setIsBlocked(status === 'FINISHED');
  };

  const handleOpen = () => {
    setOpenFollowUp(true);
  };

  const handleClose = () => {
    setOpenFollowUp(false);
    // setNotRead(index, 0);
  };

  async function handleOpenViewModal(estateId: string) {
    const response = await getEstateToView(estateId);
    setEstateSelected(response);
    setIsViewModalOpen(true);
  }

  function renderTableData(estates: EstateState[], props: any) {
    return estates.map(estate => {
      return (
        <Table.Row
          key={estate.id}
          style={{ cursor: userCanSeeDetails && 'pointer' }}
        >
          <ClickableTableCell onClick={() => {
            if (userCanSeeDetails) {
              handleOpenViewModal(estate.id || '');
            }
          }}>
            {DateToStringDate(estate.createdAt || new Date(0, 0, 0))}
          </ClickableTableCell>
          <ClickableTableCell onClick={() => {
            if (userCanSeeDetails) {
              handleOpenViewModal(estate.id || '');
            }
          }}>
            {estate.name}
          </ClickableTableCell>
          <ClickableTableCell onClick={() => {
            if (userCanSeeDetails) {
              handleOpenViewModal(estate.id || '');
            }
          }}>
            {estate.status}
          </ClickableTableCell>
          <ClickableTableCell onClick={() => {
            if (userCanSeeDetails) {
              handleOpenViewModal(estate.id || '');
            }
          }}>
            {maskCnpj(estate.cnpj)}
          </ClickableTableCell>
          <ClickableTableCell onClick={() => {
            if (userCanSeeDetails) {
              handleOpenViewModal(estate.id || '');
            }
          }}>
            {estate.estateCity}
          </ClickableTableCell>
          <ClickableTableCell onClick={() => {
            if (userCanSeeDetails) {
              handleOpenViewModal(estate.id || '');
            }
          }}>
            {estate.estateState}
          </ClickableTableCell>
          <ClickableTableCell onClick={() => {
            if (userCanSeeDetails) {
              handleOpenViewModal(estate.id || '');
            }
          }}>
            {estate.user.name}
          </ClickableTableCell>
          <ClickableTableCell onClick={() => {
            if (userCanSeeDetails) {
              handleOpenViewModal(estate.id || '');
            }
          }}>
            {estate?.franchise?.name}
          </ClickableTableCell>
          <Table.Cell width={1} textAlign={'right'}>
            <Menu compact>
              <AuthorizationContainer
                action={ActionEnum.update}
                feature={FeatureEnum.estates}
              >
                <Menu.Item>
                  <Dropdown trigger={trigger} icon={null} direction="left">
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleOpenViewModal(estate.id || '')}>
                        Visualizar imobiliária
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => props.history.push('/admin/estate-edit/' + estate.id)}
                      >
                        Editar Imobiliária
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          props.history.push(
                            '/admin/change-estate-email/' + estate.id,
                            estate
                          )
                        }
                      >
                        Alterar e-mail
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu.Item>
              </AuthorizationContainer>
              <Menu.Item>
                <Button
                  primary
                  icon="chat"
                  onClick={() => {
                    handleFollowUp(estate?.id ? estate?.id : ''); //id, status, item
                  }}
                />
                {/* {unread !== 0 && (
                        <Label color="red" circular floating>
                          {unread}
                        </Label>
                      )} */}
              </Menu.Item>
            </Menu>
          </Table.Cell>
        </Table.Row>
      );
    });
  }
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case 'argument': {
        if (type === 'document') {
          const numericValue = value?.replace(/\D/g, '');
          const maskedValue = maskCpfOrCnpj(numericValue);
          setArgument(maskedValue);
          const isValidDocumentSearch =
            numericValue.length === 11 || numericValue.length === 14;
          setSearchEnabled(isValidDocumentSearch);
          setFilters(prevValue => ({
            ...prevValue,
            document: maskedValue
          }));
          break;
        } else if (type === 'name') {
          setFilters(prevValue => ({
            ...prevValue,
            name: value
          }));
        }
        setSearchEnabled(true);
        setArgument(value);
        break;
      }
      case 'start':
        setFilters({ ...filters, start: value });
        break;
      case 'end':
        setFilters({ ...filters, end: value });
        break;
    }
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    getData();
  };

  const clearInputs = () => {
    setFilters({
      document: '',
      name: '',
      start: undefined,
      end: undefined,
      status: '',
      user_id: '',
      franchise_id: '',
      state: '',
      responsible_company: ''
    });
    setSelectedStatus('TODOS');
    setType('');
    setArgument('');
    getData();
  };

  return (
    <>
      {estateSelected && (
        <ViewModal
          isOpen={isViewModalOpen}
          onClose={() => setIsViewModalOpen(false)}
          estate={estateSelected}
        />
      )}
      {openFollowUp && (
        <FollowUpModal
          isOpen={openFollowUp}
          onOpen={handleOpen}
          onClose={handleClose}
          header="Follow Up"
          id={followUpId}
          context={'ESTATES'}
          user={user}
        />
      )}
      <form onSubmit={handleSubmit}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={3}>
              <FieldLabel>Pesquisar por</FieldLabel>
              <div style={w100}>
                <Dropdown
                  style={w100}
                  value={type}
                  name="type"
                  options={typeOptions}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  disabled={isLoading}
                  onChange={(e, { value }) => {
                    setType(value as string);
                    setArgument('');
                    setFilters(prevValue => ({
                      ...prevValue,
                      argument: ''
                    }));
                  }}
                  clearable
                />
              </div>
            </Grid.Column>
            <Grid.Column width={6}>
              <FieldLabel>Termo de pesquisa:</FieldLabel>
              <Input
                name="argument"
                onChange={handleInputChange}
                disabled={isLoading}
                value={argument}
                fluid
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <FieldLabel>UF:</FieldLabel>
              <div style={w100}>
                <Dropdown
                  clearable
                  search
                  options={stateOptions}
                  selection
                  placeholder="Filtrar por UF"
                  value={filters?.state}
                  onChange={(_, { value }) =>
                    setFilters({ ...filters, state: String(value) })
                  }
                  style={w100}
                />
              </div>
            </Grid.Column>
            <Grid.Column width={4}>
              <FieldLabel>Data de Cadastro:</FieldLabel>
              <DateContainer>
                <StartDate>
                  <label style={{ paddingRight: 5 }}>De:</label>
                  <CustomDatePicker
                    name="start"
                    selected={filters.start}
                    disabled={isLoading}
                    onChange={(date: Date) => {
                      setFilters(prevValue => ({ ...prevValue, start: date }));
                    }}
                    customInput={<AppMaskedInput mask={DATE_INPUT_MASK} />}
                    dateFormat={'dd/MM/yyyy'}
                  />
                </StartDate>
                <EndDate>
                  <label style={{ paddingRight: 5 }}>Até:</label>
                  <CustomDatePicker
                    name="end"
                    selected={filters.end}
                    disabled={isLoading}
                    onChange={(date: Date) =>
                      setFilters(prevValue => ({ ...prevValue, end: date }))
                    }
                    customInput={<AppMaskedInput mask={DATE_INPUT_MASK} />}
                    dateFormat={'dd/MM/yyyy'}
                  />
                </EndDate>
              </DateContainer>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={3}>
              <FieldLabel>Parceiro:</FieldLabel>
              <div style={w100}>
                <Dropdown
                  style={w100}
                  value={filters.franchise_id}
                  name={`franchise`}
                  options={props?.availableBrokersAndEstates?.franchises}
                  disabled={
                    isLoading ||
                    props?.availableBrokersAndEstates?.franchises?.length === 1
                  }
                  defaultValue={
                    props?.availableBrokersAndEstates?.franchises?.length === 1
                      ? props?.availableBrokersAndEstates?.franchises[0]?.value
                      : undefined
                  }
                  loading={isLoading}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  onChange={(_, { value }) => {
                    setFilters({
                      ...filters,
                      franchise_id: (value as string) || undefined
                    });
                  }}
                  clearable
                />
              </div>
            </Grid.Column>
            {!user.role.includes('ESTATE') && (
              <Grid.Column width={3}>
                <FieldLabel>Gestor de contas:</FieldLabel>
                <div style={w100}>
                  <Dropdown
                    style={w100}
                    value={filters.user_id}
                    name={`accountManager`}
                    options={accountManagerOptions || []}
                    disabled={isLoading || isAccountManagerLoading}
                    loading={isAccountManagerLoading}
                    placeholder={'Selecionar...'}
                    search
                    selection
                    onChange={(_, { value }) => {
                      setFilters({
                        ...filters,
                        user_id: (value as string) || undefined
                      });
                    }}
                    clearable
                  />
                </div>
              </Grid.Column>
            )}
            <Grid.Column width={3}>
              <FieldLabel>Status:</FieldLabel>
              <div style={w100}>
                <Dropdown
                  style={w100}
                  value={filters.status}
                  placeholder="Todos"
                  name={`estate`}
                  disabled={isLoading}
                  options={statusList || []}
                  search
                  selection
                  onChange={(_, { value }) => {
                    setFilters(prevValue => ({ ...prevValue, status: String(value) }));
                  }}
                  clearable
                />
              </div>
            </Grid.Column>
            <Grid.Column width={3}>
              <FieldLabel>Responsável:</FieldLabel>
              <div style={w100}>
                <Dropdown
                  style={w100}
                  value={filters.responsible_company}
                  placeholder="Todas"
                  name={`responsible_company`}
                  disabled={isLoading}
                  options={companyOptions || []}
                  search
                  selection
                  onChange={(_, { value }) => {
                    setFilters(prevValue => ({ ...prevValue, responsible_company: String(value) }));
                  }}
                  clearable
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}></Grid.Column>
            <Grid.Column width={4}>
              <ButtonsContainer>
                <Button
                  fluid
                  onClick={clearInputs}
                  content={'Limpar'}
                  type="button"
                  disabled={isLoading || !isSearchEnabled}
                />

                <Button
                  primary
                  fluid
                  type="submit"
                  disabled={isLoading || !isSearchEnabled}
                  content={'Pesquisar'}
                />
              </ButtonsContainer>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </form>
      <Dimmer.Dimmable as={Segment} dimmed={isLoading}>
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
        {estates && estates.length > 0 ? (
          <TableWrapper>
            <Table>
              <Header>
                <Table.Row>
                  <Table.HeaderCell>CRIADO EM</Table.HeaderCell>
                  <Table.HeaderCell>NOME</Table.HeaderCell>
                  <Table.HeaderCell>STATUS</Table.HeaderCell>
                  <Table.HeaderCell>CNPJ</Table.HeaderCell>
                  <Table.HeaderCell>CIDADE</Table.HeaderCell>
                  <Table.HeaderCell>UF</Table.HeaderCell>
                  <Table.HeaderCell>GESTOR DE CONTAS</Table.HeaderCell>
                  <Table.HeaderCell>PARCEIRO</Table.HeaderCell>
                  <Table.HeaderCell>
                    AÇÕES
                    {/*   width={1} textAlign={'right'} <IconButton color="blue" name="setting" /> */}
                  </Table.HeaderCell>
                </Table.Row>
              </Header>
              <Table.Body>{renderTableData(estates, props)}</Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="17">
                    <Menu floated="right" pagination>
                      <Menu.Item>
                        <p>{`${currentPage * 10 - 9} - ${dataCount < 10 ? dataCount : (currentPage * 10)}  de ${dataCount}`}</p>
                      </Menu.Item>
                      <Menu.Item
                        disabled={currentPage === 1}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(1)}
                      >
                        <Icon name="angle double left" />
                      </Menu.Item>
                      <Menu.Item
                        disabled={currentPage === 1}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item as="a">{currentPage}</Menu.Item>
                      <Menu.Item
                        disabled={currentPage === lastPage || estates.length < 10}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                      <Menu.Item as="a" icon>
                        <Icon
                          disabled={currentPage === lastPage || estates.length < 10}
                          name="angle double right"
                          onClick={() => setCurrentPage(lastPage)}
                        />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </TableWrapper>
        ) : (
          <NotFoundWrapper>
            <p>Nenhuma imobiliária cadastrada</p>
          </NotFoundWrapper>
        )}
      </Dimmer.Dimmable>
    </>
  );
};

export default connect(mapState, mapDispatch)(EstatesTable);
