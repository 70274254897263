import React, { useState, useEffect } from 'react';
import { Route, RouteComponentProps, Switch, useLocation } from 'react-router-dom';
import { iRootState, iRootDispatch } from './store';
import EstateForm from './pages/estates-form/EstateForm';
import PageNotFound from './pages/PageNotFound';
import Estates from './pages/estates-list/Estates';
import EstatePreregisterListPage from './pages/estates-preregister-list';
import BlacklistPage from './pages/blacklist';
import Events from './pages/events/Events';
import ClaimEstates from './pages/claim_estates';
import Proposals from './pages/fire-insurance-proposal-list/Proposals';
import Policies from './pages/fire-insurance-policies-list/Policies';
import FireInsuranceBudgetForm from './pages/fire-insurance-budget-form/FireInsuranceBudgetForm';
import FireInsuranceBudget from './pages/fire-insurance/budget';
import FireInsuranceBudgetList from './pages/fire-insurance/budget-list';
import FireInsuranceCanceledList from './pages/fire-insurance/canceled-list';
import FireInsurancePolicyList from './pages/fire-insurance/policy-list';
import FireFireInsuranceInvoices from './pages/fire-insurance/invoices';
import UsersForm from './pages/users-form/UsersForm';
import UsersList from './pages/users-list/UsersList';
import UsersEdit from './pages/users-edit/index';
import BondInsuranceForm from './pages/bond-insurance-budget-form/BondInsuranceForm';
import BondInsurancesForm from './pages/bond-insurance-list/BondInsurancesForm';
import CanceledBondInsurancesForm from './pages/canceled-bond-insurance-list/CanceledBondInsurancesForm';
import ExpiredBondInsurancesForm from './pages/bond-insurance-expired-list/ExpiredBondInsurancesForm';
import ReportForm from './pages/reports-form/ReportForm';
import FindRecord from './pages/find-record';
import {
  getBroker,
  getRoles,
  getFranchises,
  getBrokerObjectFromGetBrokerDTO
} from './services/broker';
import * as insurerService from './services/insurer';
import {
  BrokersToCustomDropDownOptions,
  EstatesToCustomDropDownOptions,
  InsurersToCustomDropDownOptions,
  RolesToCustomDropDownOptions,
  FranchisesToCustomDropDownOptions
} from './util';
import { BrokerState } from './types/BrokerState';
import UpdateEstateForm from './pages/estates-edit-form/UpdateEstateForm';
import ChangeEmailForm from './pages/change-email/ChangeEmailForm';

import {
  AvailableBrokersAndEstatesState,
  CurrentPageState,
  ScrollState,
  ToggledMenuState
} from './store/types/temp-types';
import { BrokerForGetBrokerResponseDTO } from './dtos/broker/broker-for-get-broker-response.dto';
import BondInsuranceProposalsForm from './pages/bond-insurance-proposal-list/BondInsuranceProposalsForm';
import BondInsurancePoliciesForm from './pages/bond-insurance-policy-list/BondInsurancePolicyForm';
import CapitalizationIndex from './pages/capitalization';
import PowerBIPage from './pages/powerbi';
import OperationalBIPage from './pages/operational-bi';
import Loader from './components/Loader';
import FranchisesList from './pages/franchises-list';
import FranchiseForm from './pages/franchise-form';
import { hasPermission } from './components/AuthorizationContainer';
import { ActionEnum } from './enums/authz-action.enum';
import { FeatureEnum } from './enums/authz-feature.enum';
import estatePreregisterForm from './pages/estate-preregister-form';
import Dashboard from './pages/dashboard';
import { connect } from 'react-redux';
import Budgets from './pages/fire-insurance-budget-list/Budgets';
import Sidebar from './components/Sidebar';
import { menuOptions } from './services/menu';
import BannerManagement from './pages/banner-management';
import { getFranchiseByLoggedUser } from './services/franchises';
import ClaimEstatesResponsibleTransfer from './pages/claims_estates_responsible_transfer/index';
import * as storage from './services/storage';
import { UserRolesEnum } from './enums/user-roles.enum';

type MatchParams = {
  name: string;
};

type MatchProps = RouteComponentProps<MatchParams> & {
  match: any;
};

const mapState = (state: iRootState) => ({
  user: state.user,
  toggledMenu: state.toggledMenu,
  currentPage: state.currentPage
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateScroll: (scroll: ScrollState) => dispatch.scroll.updateScroll(scroll),
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(
      availableBrokers
    ),
  updateCurrentPage: (state: CurrentPageState) =>
    dispatch.currentPage.updateCurrentPage(state)
});

export type RoutesProps = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch> &
  RouteComponentProps &
  ToggledMenuState;

const Routes = (componentProps: RoutesProps) => {
  const { user } = componentProps;
  const [isLoading, setIsLoading] = useState<boolean>(true);

  async function getInsurers() {
    const cachedInsurers = storage.get('insurers:both');

    if (cachedInsurers) {
      const { data, timestamp } = JSON.parse(cachedInsurers);

      if (timestamp + 600000 > Date.now()) {
        return data;
      } else {
        storage.remove('franchises');
      }
    }

    const rawInsurers = await insurerService.list({ availability: 'BOTH' });
    const insurers = rawInsurers.filter(insurer => {
      return insurer;
    });

    storage.set('insurers:both', JSON.stringify({ data: insurers, timestamp: Date.now() }));

    return insurers;
  }

  async function getAvailableBrokersAndEstates() {
    const insurers = await getInsurers();
    const bondInsurers = insurers.filter((insurer: any) =>
      insurer.availability.includes('BOND_INSURANCE')
    );

    const brokerDTO: BrokerForGetBrokerResponseDTO = await getBroker('7c98d9d9-a975-45a3-9131-cc1961250182');

    const broker: BrokerState | null = brokerDTO
      ? getBrokerObjectFromGetBrokerDTO(brokerDTO)
      : null;

    let enableFireInsuranceMenu = [
      UserRolesEnum.brokerAdmin,
      UserRolesEnum.accountManager
    ].includes(user.role as UserRolesEnum);

    if (broker?.estates?.length === 1) {
      enableFireInsuranceMenu = broker?.estates[0]?.menu_fire_insurance ?? false;
    }

    const estates = broker?.estates;
    const roles = await getRoles();

    let franchises = [];

    const hasFranchisesPermission = hasPermission(
      ActionEnum.read,
      FeatureEnum.franchises
    );

    if (hasFranchisesPermission) {
      franchises = await getFranchises();
    }

    if (user.franchiseId) {
      const franchiseUser = await getFranchiseByLoggedUser();
      franchises = [franchiseUser];
    }

    componentProps.updateAvailableBrokersAndEstates({
      brokers: BrokersToCustomDropDownOptions(true, broker ? [broker] : []),
      estates: EstatesToCustomDropDownOptions(true, estates || []),
      insurers: InsurersToCustomDropDownOptions(true, insurers || []),
      roles: RolesToCustomDropDownOptions(roles || []),
      franchises: FranchisesToCustomDropDownOptions(franchises),
      bondInsurers: InsurersToCustomDropDownOptions(true, bondInsurers || []),
      enableFireInsuranceMenu: enableFireInsuranceMenu,
    });

    // If user is an ESTATE, all necessary data will come in the login request
    // The only available broker will the one related to the estate  data
    setIsLoading(false);
  }

  const location = useLocation();

  useEffect(() => {
    menuOptions.forEach(menu => {
      const menuSelected = menuOptions?.find(subMenu => {
        return subMenu?.route === location?.pathname?.replace('/admin/', '');
      });

      if (menuSelected?.id !== componentProps?.currentPage?.id && menuSelected) {
        componentProps.updateCurrentPage({
          id: menuSelected.id,
          name: menuSelected?.name,
          text: menuSelected.text,
          title: menuSelected?.title,
          parent: {
            id: componentProps?.currentPage?.parent?.id,
            name: componentProps?.currentPage?.parent?.name
          }
        });
        return;
      }

      menu?.submenuItems?.forEach(subMenuItem => {
        const itemSelected = subMenuItem?.submenus?.find(subMenu => {
          return subMenu?.route === location?.pathname?.replace('/admin/', '');
        });
        if (itemSelected?.id !== componentProps?.currentPage?.id && itemSelected) {
          componentProps.updateCurrentPage({
            id: itemSelected.id,
            name: itemSelected?.name,
            text: itemSelected.text,
            title: itemSelected?.title,
            parent: {
              id: componentProps?.currentPage?.parent?.id,
              name: componentProps?.currentPage?.parent?.name
            }
          });
          return;
        }
      });
    });
  }, [componentProps, location?.pathname]);

  useEffect(() => {
    getAvailableBrokersAndEstates();
  }, [user]);

  return (
    <>
      <Route path="/" />
      {!isLoading ? (
        <Route
          component={(props: MatchProps) => {
            return (
              <>
                <Sidebar {...componentProps}>
                  <Switch>
                    <Route path={`${props.match.url}/dashboard`} component={Dashboard} />
                    <Route
                      path={`${props.match.url}/budget`}
                      exact
                      component={FireInsuranceBudgetForm}
                    />

                    <Route
                      path={`${props.match.url}/fire-insurance-budget/:id?`}
                      component={FireInsuranceBudget}
                    />
                    <Route
                      path={`${props.match.url}/fire-insurance-budgets`}
                      exact
                      component={FireInsuranceBudgetList}
                    />
                    <Route
                      path={`${props.match.url}/fire-insurance-canceleds`}
                      exact
                      component={FireInsuranceCanceledList}
                    />
                    <Route path={`${props.match.url}/budgets`} component={Budgets} />
                    <Route
                      path={`${props.match.url}/fire-insurance-policies/:id?`}
                      component={FireInsurancePolicyList}
                    />
                    <Route
                      path={`${props.match.url}/fire-insurance-invoices`}
                      exact
                      component={FireFireInsuranceInvoices}
                    />

                    <Route path={`${props.match.url}/estate`} component={EstateForm} />
                    <Route path={`${props.match.url}/estates`} component={Estates} />
                    <Route
                      path={`${props.match.url}/estates-preregister/:id`}
                      component={estatePreregisterForm}
                    />
                    <Route
                      path={`${props.match.url}/estates-preregisters`}
                      component={EstatePreregisterListPage}
                    />
                    <Route path={`${props.match.url}/logs`} component={Events} />
                    <Route
                      path={`${props.match.url}/claim_estates`}
                      component={ClaimEstates}
                    />
                    <Route
                      path={`${props.match.url}/claim-estate-responsible-transfer`}
                      component={ClaimEstatesResponsibleTransfer}
                    />
                    <Route path={`${props.match.url}/proposals`} component={Proposals} />
                    <Route path={`${props.match.url}/policies`} component={Policies} />
                    <Route
                      path={`${props.match.url}/bond-insurance/:copy_id?`}
                      component={BondInsuranceForm}
                    />
                    <Route
                      path={`${props.match.url}/find-record`}
                      component={FindRecord}
                    />
                    <Route
                      path={`${props.match.url}/canceled-bond-insurances`}
                      component={CanceledBondInsurancesForm}
                    />
                    <Route
                      path={`${props.match.url}/expired-bond-insurances`}
                      component={ExpiredBondInsurancesForm}
                    />
                    <Route
                      path={`${props.match.url}/bond-insurances/:id?`}
                      component={BondInsurancesForm}
                    />
                    <Route
                      path={`${props.match.url}/bond-insurance-proposals`}
                      component={BondInsuranceProposalsForm}
                    />
                    <Route
                      path={`${props.match.url}/bond-insurance-policies`}
                      component={BondInsurancePoliciesForm}
                    />
                    <Route
                      path={`${props.match.url}/reports-form`}
                      component={ReportForm}
                    />
                    <Route path={`${props.match.url}/user`} component={UsersForm} />
                    <Route path={`${props.match.url}/users`} component={UsersList} />
                    <Route path={`${props.match.url}/users-edit`} component={UsersEdit} />
                    <Route
                      path={`${props.match.url}/franchises`}
                      component={FranchisesList}
                    />
                    <Route
                      path={`${props.match.url}/franchise/:id`}
                      component={FranchiseForm}
                    />
                    <Route
                      path={`${props.match.url}/estate-edit/:id`}
                      component={UpdateEstateForm}
                    />
                    <Route
                      path={`${props.match.url}/change-estate-email/:id`}
                      component={ChangeEmailForm}
                    />
                    <Route
                      path={`${props.match.url}/capitalizations`}
                      component={CapitalizationIndex}
                    />
                    <Route path={`${props.match.url}/powerbi`} component={PowerBIPage} />
                    <Route
                      path={`${props.match.url}/operational-bi`}
                      component={OperationalBIPage}
                    />
                    <Route
                      path={`${props.match.url}/banner-management`}
                      component={BannerManagement}
                    />
                    <Route
                      path={`${props.match.url}/blacklist`}
                      component={BlacklistPage}
                    />
                    <Route component={PageNotFound} />
                  </Switch>
                </Sidebar>
              </>
            );
          }}
        />
      ) : (
        <Loader active={isLoading} />
      )}
    </>
  );
};

export default connect(mapState, mapDispatch)(Routes);
