import React, {useCallback, useEffect, useState} from 'react';
import {debounce, throttle} from 'lodash';
import Input from '../../../../components/Input';
import {reach, Schema} from 'yup';
import {maskZipCode} from '../../../../services/masks';
import { AddressService } from '../../../../services/address';
import SectionLabel from '../../../../components/SectionLabel';
import {Grid, InputOnChangeData} from 'semantic-ui-react';
import {FastField, Field} from 'formik';
import { FormikErrorMessage } from '../../../../components/ErrorMessage';
import { dangerNotification } from '../../../../services/notification';
import { FieldLabel } from '../../../../styles';
import {connect} from "react-redux";
import {iRootDispatch, iRootState} from "../../../../store";
import {PropertyState} from "../../../../types/FireInsurance";
import {propertyInputSectionYupSchema} from "../../validators/property-input-section/property-input-section-schema";

const mapDispatchToProps = (dispatch: iRootDispatch) => ({
  updateInputData: (payload: Partial<PropertyState>) => dispatch.fireInsuranceBudget.updatePropertyInputData(payload),
  resetInputData: () => dispatch.fireInsuranceBudget.resetPropertyFormSection()
})
const mapStateToProps = (state: iRootState) => ({
  selectedInsurerData: state.fireInsuranceBudget.selected.insurer,
  userId: state.user.id,
  street: state.fireInsuranceBudget.property.street,
  setFormikFieldValue: (fieldName: string, value: any) => state.formik.actions.setFieldValue &&
    state.formik.actions?.setFieldValue(fieldName, value),
  setFormikFieldTouched: (fieldName: string, touched: boolean) => state.formik.actions.setFieldTouched &&
    state.formik.actions?.setFieldTouched(fieldName, touched),
  toggleToResetForm: state.fireInsuranceBudget.toggleToResetFormSections.propertyInputSection
});

export type PropertyDataProps = {

} & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & {
  sectionKey: string;
}


const PropertyData = (props: PropertyDataProps) => {
  const debouncedUpdate = useCallback(debounce((payload) => props.updateInputData(payload), 500),[]);
  const throttledUpdate = useCallback(throttle((payload) => props.updateInputData(payload), 1000),[]);
  const {
    sectionKey,
    setFormikFieldTouched,
    setFormikFieldValue,
    updateInputData,
    resetInputData,
    toggleToResetForm,
    selectedInsurerData} = props;

  const [cepLoading, setCepLoading] = useState<boolean>(false);

  const validate = async (fieldName: string, value?: any) => {
    let error: string | undefined;
    let fieldSchema: Schema<any>;

    try {
      fieldSchema = reach(propertyInputSectionYupSchema, fieldName);
    } catch (e) {
      return undefined;
    }

    try {
      const res = await fieldSchema.validate(value);
    } catch (e) {
      error = (e as any).message;
    } finally {
      return error;
    }
  }

  const handleChange = (_: any, data: InputOnChangeData) => {
    const updatePayload: any = {};
    const formikFieldName = data.name;
    const sanitizedName = formikFieldName.replace(`${sectionKey}.`,'');

    updatePayload[sanitizedName] = data.value;
    setFormikFieldValue(formikFieldName, data.value);
    setFormikFieldTouched(formikFieldName, true);

    debouncedUpdate(updatePayload);
  };

  const handleBlur = (e: any) => {
    e.persist();

    const updatePayload: any = {};
    const formikFieldName = e.target.name;
    const sanitizedName = formikFieldName.replace(`${sectionKey}.`,'');

    updatePayload[sanitizedName] = e.target.value;

    updateInputData(updatePayload);
  };

  const onChangeCep = (e: React.ChangeEvent<HTMLInputElement>, data: any) => {
    const updatePayload: any = {};
    const formikFieldName = data.name;
    const sanitizedName = formikFieldName.replace(`${sectionKey}.`,'');
    const maskedValue = maskZipCode(data.value);

    updatePayload[sanitizedName] = maskedValue;
    setFormikFieldValue(formikFieldName, maskedValue);
    setFormikFieldTouched(formikFieldName, true);

    throttledUpdate(updatePayload);

    if (maskedValue.length === 9) {
      setCepLoading(true);

      AddressService.getAddressByZipCode(maskedValue, props.userId).then((address) => {
        updateInputData({
          city: address.city,
          district: address.district,
          state: address.state,
          street: address.street
        });

        setFormikFieldValue(`${sectionKey}.city`, address.city);
        setFormikFieldValue(`${sectionKey}.district`, address.district);
        setFormikFieldValue(`${sectionKey}.state`, address.state);
        setFormikFieldValue(`${sectionKey}.street`, address.street);

        setCepLoading(false);
      }).catch((e) => {
        updateInputData({
          city: '',
          district: '',
          state: '',
          street: '',
          number: ''
        });

        setFormikFieldValue(`${sectionKey}.city`, '');
        setFormikFieldValue(`${sectionKey}.district`, '');
        setFormikFieldValue(`${sectionKey}.state`, '');
        setFormikFieldValue(`${sectionKey}.street`, '');
        setFormikFieldValue(`${sectionKey}.number`, '');

        dangerNotification('Oops...', 'Não foi possível recuperar as informações do CEP')
      }).finally(() => {
        setFormikFieldTouched(`${sectionKey}.city`, true);
        setFormikFieldTouched(`${sectionKey}.district`, true);
        setFormikFieldTouched(`${sectionKey}.state`, true);
        setFormikFieldTouched(`${sectionKey}.street`, true);
        setFormikFieldTouched(`${sectionKey}.number`, true);

        setCepLoading(false);
      })
    }
  };

  const clearFormValues = () => {
    setFormikFieldValue(`${sectionKey}.zipCode`, '');
    setFormikFieldValue(`${sectionKey}.street`, '');
    setFormikFieldValue(`${sectionKey}.district`, '');
    setFormikFieldValue(`${sectionKey}.city`, '');
    setFormikFieldValue(`${sectionKey}.state`, '');
    setFormikFieldValue(`${sectionKey}.number`, '');
    setFormikFieldValue(`${sectionKey}.complement`, '');
    setFormikFieldValue(`${sectionKey}.externalCode`, '');

    setFormikFieldTouched(`${sectionKey}.zipCode`, false);
    setFormikFieldTouched(`${sectionKey}.street`, false);
    setFormikFieldTouched(`${sectionKey}.district`, false);
    setFormikFieldTouched(`${sectionKey}.city`, false);
    setFormikFieldTouched(`${sectionKey}.state`, false);
    setFormikFieldTouched(`${sectionKey}.number`, false);
    setFormikFieldTouched(`${sectionKey}.complement`, false);
    setFormikFieldTouched(`${sectionKey}.externalCode`, false);

    resetInputData();
  }

  useEffect(() => {
    clearFormValues();
  }, [selectedInsurerData.code, toggleToResetForm])

  return (
    <>
      <SectionLabel text='DADOS DO IMÓVEL' />
      <Grid.Row>
        <Grid.Column width={5}>
          <FieldLabel>CEP<span style={{color: 'red'}}>*</span></FieldLabel>
          <Field name="budget.property.zipCode"
            loading={cepLoading}
            onChange={onChangeCep}
            validate={(value: any) => validate('zipCode', value)}
            component={Input}
            maxLength={9}
            fluid />
          <FormikErrorMessage component="div" name="budget.property.zipCode" />
        </Grid.Column>
        <Grid.Column width={11}>
          <FieldLabel>LOGRADOURO<span style={{color: 'red'}}>*</span></FieldLabel>
          <FastField name="budget.property.street"
            component={Input}
            validate={(value: any) => validate('street', value)}
                     onChange={handleChange}
                     onBlur={handleBlur}
            fluid />
          <FormikErrorMessage component="div" name="budget.property.street" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5}>
          <FieldLabel>BAIRRO<span style={{color: 'red'}}>*</span></FieldLabel>
          <FastField name="budget.property.district"
            component={Input}
            validate={(value: any) => validate('district', value)}
                     onChange={handleChange}
                     onBlur={handleBlur}
            fluid />
          <FormikErrorMessage component="div" name="budget.property.district" />
        </Grid.Column>
        <Grid.Column width={6}>
          <FieldLabel>CIDADE<span style={{color: 'red'}}>*</span></FieldLabel>
          <FastField name="budget.property.city"
            component={Input}
            validate={(value: any) => validate('city', value)}
                     onChange={handleChange}
                     onBlur={handleBlur}
            fluid />
          <FormikErrorMessage component="div" name="budget.property.city" />
        </Grid.Column>
        <Grid.Column width={5}>
          <FieldLabel>UF<span style={{color: 'red'}}>*</span></FieldLabel>
          <FastField name="budget.property.state"
            component={Input}
            validate={(value: any) => validate('state', value)}
                     onChange={handleChange}
                     onBlur={handleBlur}
            fluid />
          <FormikErrorMessage component="div" name="budget.property.state" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5}>
          <FieldLabel>NÚMERO<span style={{color: 'red'}}>*</span></FieldLabel>
          <FastField name="budget.property.number"
            validate={(value: any) => validate('number', value)}
            component={Input}
            onChange={handleChange}
            onBlur={handleBlur}
            fluid />
          <FormikErrorMessage component="div" name="budget.property.number" />
        </Grid.Column>
        <Grid.Column width={6}>
          <FieldLabel>COMPLEMENTO</FieldLabel>
          <FastField name="budget.property.complement"
            component={Input}
            validate={(value: any) => validate('complement', value)}
            onChange={handleChange}
            onBlur={handleBlur}
                     fluid />
        </Grid.Column>
        <Grid.Column width={5}>
          <FieldLabel>CÓD. EXT. DO IMÓVEL</FieldLabel>
          <FastField name="budget.property.externalCode"
            component={Input}
            onChange={handleChange}
            onBlur={handleBlur}
            validate={(value: any) => validate('externalCode', value)}
            fluid />
          <FormikErrorMessage component="div" name="budget.property.externalCode" />
        </Grid.Column>
      </Grid.Row>
    </>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(PropertyData)
