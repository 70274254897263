import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  Button,
  Dropdown,
  Grid,
  Icon,
  Menu,
  Loader,
  Table,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableHeaderCell,
  Dimmer,
} from 'semantic-ui-react';

import CustomDatePicker from '../../../components/CustomDatePicker';
import AppMaskedInput from '../../../components/MaskedInput';
import { DATE_INPUT_MASK, numberToMoneyString } from '../../../services/masks';
import { FieldLabel } from '../../../styles';
import InvoiceDetailModal from './Details';
import { iRootDispatch, iRootState } from '../../../store';
import { AvailableBrokersAndEstatesState } from '../../../store/types/temp-types';
import { CustoDropDownItemProps } from '../../../components/types';
import IconButton from '../../../components/IconButton';
import { InvoicesStatusList, translateInvoiceStatus } from '../utils/AlfaSeguros';
import { getInvoices, getInvoicesBillet } from '../../../services/fire-insurance-invoices';
import ProLaboreDetailModal from './ProLaboreDetails';

const ClickableTableCell = styled(TableCell)`
  cursor: pointer;
  position: relative;
`;

const ClickableTableRow = styled(TableRow)`
  :hover {
    background-color: #f0f0f0;
  }
`;

const FireInsuranceInvoices = (props: { availableBrokersAndEstates: AvailableBrokersAndEstatesState }) => {
  const { availableBrokersAndEstates } = props;
  const estatesOptions: CustoDropDownItemProps[] = availableBrokersAndEstates.estates;
  const insurerOptions: CustoDropDownItemProps[] = availableBrokersAndEstates.insurers.filter(insurer =>
    insurer.value.availability.includes('FIRE_INSURANCE'));

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isDownloadBillet, setIsDownloadBillet] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [status, setStatus] = useState<string>('');
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [selectedEstate, setSelectedEstate] = useState<any>();
  const [selectedInsurer, setSelectedInsurer] = useState<any>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openProLaboreModal, setOpenProLaboreModal] = useState<boolean>(false);
  const [currentInvoice, setCurrentInvoice] = useState<any>(null);
  const [invoicesData, setInvoicesData] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);

  const getData = async () => {
    setIsLoadingData(true);

    const filter = {
      insurerId: selectedInsurer?.id || undefined,
      estateId: selectedEstate?.id || undefined,
      start: start ? moment(start).format('YYYY-MM-DD') : undefined,
      end: end ? moment(end).format('YYYY-MM-DD') : undefined,
      status: status || undefined,
      page: currentPage,
    };

    const invoices = await getInvoices(filter);

    setInvoicesData(invoices?.data);
    setCount(invoices?.quantity);
    setIsLoadingData(false);
  };

  const handleDownloadBillet = async (invoice: any) => {
    setIsDownloadBillet(true);

    await getInvoicesBillet({
      estateId: invoice.estate.id,
      insurerId: invoice.insurer.id,
      contractCode: invoice.contractCode,
      emissionCode: invoice.emissionCode,
    });

    setIsDownloadBillet(false);
  }

  const handleOpenProLaboreDetailsModal = async (invoice: any) => {
    setCurrentInvoice(invoice);
    setOpenProLaboreModal(true);
  };

  const handleCloseProLaboreDetailsModal = () => {
    setCurrentInvoice(null);
    setOpenProLaboreModal(false);
  };

  const handleCloseModal = () => {
    setCurrentInvoice(null);
    setOpenModal(false);
  };

  const renderDetailModal = (invoice: any) => {
    setCurrentInvoice(invoice);
    setOpenModal(true);
  };

  useEffect(() => {
    getData();
  }, [currentPage]);

  return (
    <>
      {isDownloadBillet ? (
        <Dimmer active inverted page>
          <Loader indeterminate>Baixando boleto...</Loader>
        </Dimmer>
      ) : null}

      <Grid columns={'equal'}>
        <Grid.Row>
          <Grid.Column>
            <FieldLabel>Seguradora</FieldLabel>
            <Dropdown
              fluid
              search
              clearable
              selection
              name={'insurer'}
              placeholder={'Selecionar'}
              value={selectedInsurer}
              options={insurerOptions || []}
              onChange={(e, { value }) => setSelectedInsurer(value)}
              defaultValue={insurerOptions.length === 1 ? insurerOptions[0].value : '' || ''}
              disabled={insurerOptions.length === 1 || false}
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>Imobiliária</FieldLabel>
            <Dropdown
              fluid
              search
              clearable
              selection
              name={'estate'}
              placeholder={'Selecionar'}
              value={selectedEstate}
              options={estatesOptions || []}
              onChange={(e, { value }) => setSelectedEstate(value)}
              defaultValue={estatesOptions.length === 1 ? estatesOptions[0].value : '' || ''}
              disabled={estatesOptions.length === 1 || false}
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>Data de Início</FieldLabel>
            <CustomDatePicker
              fluid
              selected={start}
              maxDate={end}
              type={'date'}
              placeholder={'Data de início'}
              onChange={(date: Date) => setStart(date)}
              customInput={<AppMaskedInput mask={DATE_INPUT_MASK} />}
              dateFormat={'dd/MM/yyyy'}
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>Fim</FieldLabel>
            <CustomDatePicker
              fluid
              selected={end}
              minDate={start}
              type={'date'}
              placeholder={'Data de Fim'}
              onChange={(date: Date) => setEnd(date)}
              customInput={<AppMaskedInput mask={DATE_INPUT_MASK} />}
              dateFormat={'dd/MM/yyyy'}
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>Status</FieldLabel>
            <Dropdown
              fluid
              search
              clearable
              selection
              placeholder={'Status'}
              options={InvoicesStatusList}
              onChange={(e, { value }) => setStatus(value as string)}
              value={status}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column textAlign={'right'}>
            <Button
              primary
              disabled={isLoadingData}
              loading={isLoadingData}
              onClick={() => getData()}
            >
              Pesquisar
            </Button>

            <Button
              basic
              disabled={isLoadingData}
              loading={isLoadingData}
              onClick={() => {
                setStatus('');
                setStart(undefined);
                setEnd(undefined);
                setSelectedEstate('');
                setSelectedInsurer('');
              }}
            >
              Limpar
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Table singleLine>
        <TableHeader fullWidth>
          <TableRow>
            <TableHeaderCell textAlign={'center'}>Gerado em</TableHeaderCell>
            <TableHeaderCell textAlign={'center'}>Imobiliária</TableHeaderCell>
            <TableHeaderCell textAlign={'center'}>Seguradora</TableHeaderCell>
            <TableHeaderCell textAlign={'center'}>Valor total</TableHeaderCell>
            <TableHeaderCell textAlign={'center'}>Data de vencimento</TableHeaderCell>
            <TableHeaderCell textAlign={'center'}>Status</TableHeaderCell>
            <TableHeaderCell textAlign={'center'}>Ações</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isLoadingData ? (
            <TableRow>
              <TableCell textAlign={'center'} colSpan={9}>
                <Loader active inline />
              </TableCell>
            </TableRow>
          ) : (
            <>
                {invoicesData.length ? (
                  invoicesData.map((invoice: any) => {
                    const [date, hour] = invoice.createdAt && moment(invoice.createdAt).format('DD/MM/YYYY HH:mm').split(' ');

                    return (
                      <ClickableTableRow key={invoice.id}>
                        <ClickableTableCell
                          textAlign={'center'}
                          onClick={() => renderDetailModal(invoice)}
                        >
                          {date}
                          <br />
                          {hour}
                        </ClickableTableCell>

                        <ClickableTableCell
                          textAlign={'center'}
                          onClick={() => renderDetailModal(invoice)}
                        >
                          {invoice.estate.name}
                        </ClickableTableCell>

                        <ClickableTableCell
                          textAlign={'center'}
                          onClick={() => renderDetailModal(invoice)}
                        >
                          {invoice.insurer.name}
                        </ClickableTableCell>

                        <ClickableTableCell
                          textAlign={'center'}
                          onClick={() => renderDetailModal(invoice)}
                        >
                          {numberToMoneyString(Number(invoice.amount))}
                        </ClickableTableCell>

                        <ClickableTableCell
                          textAlign={'center'}
                          onClick={() => renderDetailModal(invoice)}
                        >
                          {moment(invoice.dueDate).format('DD/MM/YYYY')}
                        </ClickableTableCell>

                        <ClickableTableCell
                          textAlign={'center'}
                          onClick={() => renderDetailModal(invoice)}
                        >
                          {translateInvoiceStatus(invoice.status)}
                        </ClickableTableCell>

                      <TableCell textAlign={'center'}>
                        <Menu compact>
                          <Menu.Item>
                            <Dropdown
                              trigger={
                                <IconButton
                                  name='ellipsis vertical'
                                  color='blue'
                                />
                              }
                              icon={null}
                              direction='left'
                            >
                              <Dropdown.Menu style={{ zIndex: 1000 }}>
                                <Dropdown.Item
                                  icon='download'
                                  text={'Baixar boleto'}
                                  onClick={() => handleDownloadBillet(invoice)}
                                />

                                <Dropdown.Item
                                  icon='info circle'
                                  text={'Detalhes do Pro-Labore'}
                                  disabled={invoice.status === 'OPEN'}
                                  onClick={() => handleOpenProLaboreDetailsModal(invoice)}
                                />
                              </Dropdown.Menu>
                            </Dropdown>
                          </Menu.Item>
                        </Menu>
                      </TableCell>
                    </ClickableTableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={9} textAlign={'center'}>
                    Nenhum registro encontrado
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>

        <Table.Footer fullWidth>
          <TableRow>
            <TableHeaderCell colSpan={9} textAlign={'right'}>
              {!isLoadingData && (
                <Menu floated="right" pagination>
                  <Menu.Item>
                    <p>{`${invoicesData.length ? currentPage * 10 - 9 : 0} - ${
                      count < currentPage * 10 ? count : currentPage * 10
                    } de ${count}`}</p>
                  </Menu.Item>
                  <Menu.Item
                    disabled={currentPage === 1}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(1)}
                  >
                    <Icon name="angle double left" />
                  </Menu.Item>
                  <Menu.Item
                    disabled={currentPage === 1}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item as="a">{currentPage}</Menu.Item>
                  <Menu.Item
                    disabled={currentPage ===  Math.floor(count / 10) + 1 || invoicesData.length < 10}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                  <Menu.Item as="a" icon>
                    <Icon
                      disabled={currentPage ===  Math.floor(count / 10) + 1 || invoicesData.length < 10}
                      name="angle double right"
                      onClick={() => setCurrentPage( Math.floor(count / 10) + 1)}
                    />
                  </Menu.Item>
                </Menu>
              )}
            </TableHeaderCell>
          </TableRow>
        </Table.Footer>
      </Table>

      {openModal && <InvoiceDetailModal
        open={openModal}
        onClose={handleCloseModal}
        invoice={currentInvoice}
        key={'detailPolicy'}
      />}

      {openProLaboreModal && <ProLaboreDetailModal
        open={openProLaboreModal}
        onClose={handleCloseProLaboreDetailsModal}
        contributionNumber={currentInvoice.contributionNumber}
        key={'proLaboreDetail'}
      />}
    </>
  );
};

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

export default connect(mapState, mapDispatch)(FireInsuranceInvoices);
