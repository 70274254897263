import moment from 'moment-timezone';
import { GetCompleteBondInsurancePolicyByIdResponseDTO } from '../dtos/bond-insurance-policy-list/get-complete-bond-insurance-policy-by-id-response.dto';
import { get, patch, post, put } from './request';
import { GetBondInsurancePoliciesListResponseDTO } from '../dtos/bond-insurance-policy-list/get-bond-insurance-policies-list-response-dto';
import { ResponsePrintBondInsuracePolicyDTO } from '../dtos/bond-insurance-policy-list/get-bond-insurance-policies-download';
import { warningNotification } from './notification';
import { BondInsuranceHiredDataStatusEnum } from '../enums/bond-insurance-hired-data-status.enum';
import {
  NonRenewBondInsurancePayload,
  RenewBondInsurancePayload
} from '../pages/bond-insurance-policy-list/components/RenewRequestModal/types';
import { ConfirmRenewRequestHiredData, ConfirmRenewRequestHiredData as ConfirmModalHandleSubmitProps } from '../pages/bond-insurance-policy-list/components/ConfirmRenewRequestModal/types';
import { unmaskMoney } from './masks';

// export type BondInsuranceHiredDataStatus =
//   "WAITING" |
//   "INSURER_HIRE_RELATED_INFORMATION_PENDING" |
//   "HIRE_RELATED_INFORMATION_ERROR" |
//   "INSURER_READY_TO_HIRE" |
//   "INSURER_HIRE_PENDING" |
//   "HIRE_ERROR" |
//   "INSURER_POLICY_PENDING" |
//   "POLICY_ERROR" |
//   "INSURER_POLICY_CREATED" |
//   "CANCELLED";
export interface InvolvedType {
	editor?: string;
  responsible?: string;
	confirmer?: string;
	creator?: string;
}

export interface policyFollowUpType {
  id?: string;
  relatedId?: string;
  bondInsuranceId?: string;
  senderId?: string;
  senderName?: string;
  context?: string;
  message?: string;
  isInternal?: boolean;
  viewedBy?: string;
  involved?: string;
  createdAt?: string;
  updatedAt?: string;
}

export function getBondInsurancePolicyStatusLabel(
  status: BondInsuranceHiredDataStatusEnum
): string {
  switch (status) {
    case BondInsuranceHiredDataStatusEnum.waiting:
      return 'Aguardando Ação';
    case BondInsuranceHiredDataStatusEnum.insurerHireRelatedInformationPending:
      return 'Informações de Contratação Enviadas para a Seguradora';
    case BondInsuranceHiredDataStatusEnum.hireRelatedInformationError:
      return 'Erro no Envio de Informações de Contratação';
    case BondInsuranceHiredDataStatusEnum.insurerReadyToHire:
      return 'Pronto para Contratar';
    case BondInsuranceHiredDataStatusEnum.insurerHirePending:
      return 'Contratação em Andamento';
    case BondInsuranceHiredDataStatusEnum.insurerHirePendingIntegred:
      return 'Contratação integrada em Andamento';
    case BondInsuranceHiredDataStatusEnum.hireError:
      return 'Erro na Contratação';
    case BondInsuranceHiredDataStatusEnum.insurerPolicyPending:
      return 'Emissão de Apólice Pendente pela Seguradora';
    case BondInsuranceHiredDataStatusEnum.policyError:
      return 'Erro na Emissão de Apólice';
    case BondInsuranceHiredDataStatusEnum.insurerPolicyCreated:
      return 'Emitida';
    case BondInsuranceHiredDataStatusEnum.renewCanceled:
      return 'Renovação Cancelada';
    case BondInsuranceHiredDataStatusEnum.cancelledInProgress:
      return 'Cancelamento em Andamento';
    case BondInsuranceHiredDataStatusEnum.underRenewal:
      return 'Em renovação';
    case BondInsuranceHiredDataStatusEnum.originalRenewal:
      return 'Original em Renovação';
    case BondInsuranceHiredDataStatusEnum.notRenewed:
      return 'Não Renovada';
    case BondInsuranceHiredDataStatusEnum.renewed:
      return 'Renovada Original';
    case BondInsuranceHiredDataStatusEnum.renewedCreated:
      return 'Renovada';
    case BondInsuranceHiredDataStatusEnum.overdue:
      return 'Vencida';
    case BondInsuranceHiredDataStatusEnum.expired:
      return 'Expirada';
    case BondInsuranceHiredDataStatusEnum.overdueIssued:
      return 'Vencida Emitida';
    default:
      return 'Indefinida';
  }
}

export const getBondInsurancePolicy = async (
  hiredDataId: string
): Promise<GetCompleteBondInsurancePolicyByIdResponseDTO> => {
  return await get(`/bond-insurance/policies/${hiredDataId}`);
};

export const getPolicyHasClaim = async (
  insurerPolicyId: string
): Promise<boolean> => {
  return await get(`/bond-insurance/policies/has-claim/${insurerPolicyId}`);
};

export const createPolicy = async (
  payload: any
): Promise<any> => {
  const route = `/bond-insurance/policies/external`;
  return await post(route, payload);
};

export const getBondInsurancePoliciesList = async (
  page: number,
  filter?: any
): Promise<GetBondInsurancePoliciesListResponseDTO> => {
  let response: GetBondInsurancePoliciesListResponseDTO;
  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        if (query) {
          query += `&${map[0]}=${map[1]}`;
        } else {
          query += `${map[0]}=${map[1]}`;
        }
      }
    });
  }

  try {
    response = await get(
      `/bond-insurance/policies/?page=${page}&${query}`
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export function sortBondInsurancePolicies<T>(
  data: Array<SortBondInsurancePoliciesType<T>>,
  ascending: boolean
): Array<SortBondInsurancePoliciesType<T>> {
  return data.sort(
    (
      p1: SortBondInsurancePoliciesType<T>,
      p2: SortBondInsurancePoliciesType<T>
    ): number => {
      const p1CreatedAt = moment(p1.insurerPolicyCreatedAt || new Date(0, 0, 0));
      const p2CreatedAt = moment(p2.insurerPolicyCreatedAt || new Date(0, 0, 0));
      if (p1CreatedAt.isSame(p2CreatedAt)) {
        return 0;
      } else {
        return ascending === p1CreatedAt.isAfter(p2CreatedAt) ? -1 : 1;
      }
    }
  );
}

export const printBondInsuracePolicy = async (
  bond_insurance_hire_data_id: string
): Promise<ResponsePrintBondInsuracePolicyDTO> => {
  try {
    warningNotification(
      'Aguarde!',
      'Carregando apólice para download. Esse processo pode levar até 1 minuto',
      undefined,
      10000
    );
    const response: ResponsePrintBondInsuracePolicyDTO = await post(
      '/bond-insurance-analysis/document/policy',
      { bond_insurance_hire_data_id }
    );
    if (response.success) {
      return response;
    } else {
      throw new Error(JSON.stringify(response.errorMessages));
    }
  } catch (e) {
    throw e;
  }
};

export const printPottencialBondInsuracePolicy = async (
  bond_insurance_analisys_id: string
): Promise<ResponsePrintBondInsuracePolicyDTO> => {
  try {
    warningNotification(
      'Aguarde!',
      'Carregando apólice para download. Esse processo pode levar até 1 minuto',
      undefined,
      5000
    );
    const response: ResponsePrintBondInsuracePolicyDTO = await get(
      `/bond-insurance/policies/pottencial/${bond_insurance_analisys_id}`
    );
    if (response.success) {
      return response;
    } else {
      throw new Error(JSON.stringify(response.errorMessages));
    }
  } catch (e) {
    throw e;
  }
};

export const renewBondInsurance = async (
  data: RenewBondInsurancePayload
): Promise<any> => {
  const route = `/bond-insurances/renewal`;
  const payload = {
    ...data,
    rentInsuranceContractStartDate: data.rentInsuranceContractStartDate.toISOString(),
    rentInsuranceContractEndDate: data.rentInsuranceContractEndDate.toISOString(),
    rentalPeriodStartDate: data.rentalPeriodStartDate.toISOString(),
    rentalPeriodEndDate: data.rentalPeriodEndDate.toISOString()
  };
  const response = await post(route, payload);
  return response;
};

export const nonRenewBondInsurance = async (
  data: NonRenewBondInsurancePayload
): Promise<any> => {
  const route = `/bond-insurances/non-renewal`;
  const payload = {
    bondInsuranceHiredDataId: data.bondInsuranceHiredDataId,
    nonRenewalReason: data.status,
    observation: data.observation
  };
  const response = await post(route, payload);
  return response;
};

export const confirmRenewBondInsurance = async (
  hiredDataId: string,
  data: ConfirmModalHandleSubmitProps
): Promise<any> => {
  const route = `/bond-insurances/confirm-renewal/${hiredDataId}`;
  const payload = {
    ...data,
    rentInsuranceContractStartDate: data?.rentInsuranceContractStartDate?.toISOString() || null,
    rentInsuranceContractEndDate: data?.rentInsuranceContractEndDate?.toISOString()  || null,
    rentalPeriodStartDate: data?.rentalPeriodStartDate?.toISOString()  || null,
    rentalPeriodEndDate: data?.rentalPeriodEndDate?.toISOString()  || null,
    insurerPolicyCreatedAt: data?.insurerPolicyCreatedAt?.toISOString()  || null,
    installments: +data?.installments || null,
    installmentValue: Number(unmaskMoney(data?.installmentValue)) || 0,
    totalPremium: +data?.totalPremium || null,
    rentValue: Number(unmaskMoney(data?.rentValue)) || 0,
    condominiumFee: Number(unmaskMoney(data?.condominiumFee)) || 0,
    taxesValue: Number(unmaskMoney(data?.taxesValue)) || 0,
    energyBill: Number(unmaskMoney(data?.energyBill)) || 0,
    waterBill: Number(unmaskMoney(data?.waterBill)) || 0,
    gasBill: Number(unmaskMoney(data?.gasBill)) || 0,
    renewalResponsible: data.renewalResponsible || null
  };
  const response = await post(route, payload);
  return response;
};

export const cancelUnderRenewal = async (policyId: string) => {
  const payload = {
    action: 'cancel-renew'
  }
  const response = await post(`/bond-insurances/cancel-renewal/${policyId}`, payload);
  return response;
}

export const getBondInsuranceHiredData = async (
  hiredDataId: string
): Promise<ConfirmRenewRequestHiredData> => {
  return get(`/bond-insurances/renewal/${hiredDataId}`);
};

export const getHiredInvolvedByBondId = async (bondId: string) => {
  const response = await get(`/bond-insurances/involved/${bondId}`);
  return response;
};

export const updateRenewalResponsible = async (hiredId?: string, responsible?: string) => {
  const payload = { renewalResponsible: responsible }
  const response = await put(`/bond-insurances/renewal-responsible/${hiredId}`, payload);
  return response;
};

type SortBondInsurancePoliciesType<T> = T & { insurerPolicyCreatedAt: string | Date };
