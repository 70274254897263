import React from 'react';
import { Loader, Icon, Segment } from 'semantic-ui-react';
import { CardContainer, ContentWrapper, Label, Quantity, MainContent } from './styles';

interface CardInterface {
  label: string;
  isLoading: boolean;
  quantity: number | undefined;
  onClick?: () => void;
  isNegative?: boolean;
}

const Card = ({ label, isLoading, quantity, onClick, isNegative }: CardInterface) => {
  return (
    <CardContainer>
      <ContentWrapper>
        <MainContent
          style={{ cursor: onClick ? 'pointer' : 'default' }}
          {...(onClick ? { onClick: onClick } : '')}
        >
          <Label style={{ cursor: onClick ? 'pointer' : 'default' }}>{label}</Label>
          <Quantity
            style={{ cursor: onClick ? 'pointer' : 'default' }}
            isNegative={isNegative}
          >
            {isLoading ? (
              <Segment style={{ border: 0, boxShadow: 'none', margin: 0, padding: 0 }}>
                <Loader active inline />
              </Segment>
            ) : (
              quantity
            )}
            {isNegative && <Icon name="warning circle" size="small" />}
          </Quantity>
        </MainContent>
      </ContentWrapper>
    </CardContainer>
  );
};

export default Card;
