import SectionLabel from "../../../../../components/SectionLabel";
import { Grid, DropdownItemProps, Radio } from "semantic-ui-react";
import { Field } from "formik";
import { FormikErrorMessage } from "../../../../../components/ErrorMessage";
import React, {
  ReactElement,
  useState,
  useEffect,
  SyntheticEvent,
} from "react";
import { getErrorFormik } from "../../../../../services/errors";
import { StepProps } from "../../../BondInsuranceForm";
import { FieldLabel } from "../../../../../styles";
import Dropdown from "../../../../../components/Dropdown";
import { PurposesEnum } from "../../../../../enums/purposes.enum";
import { PersonTypesEnum } from "../../../../../enums/person-types.enum";
import {
  naturalCommercialReasonOptions,
  naturalResidentialReasonOptions,
  legalCommercialResidentialReasonOptions,
  validityOptions,
} from "../../../types";
import CustomDatePicker from "../../../../../components/CustomDatePicker";
import AppMaskedInput from "../../../../../components/MaskedInput";
import { iRootDispatch, iRootState } from "../../../../../store";
import { BondInsuranceFormState } from "../../../../../store/types/temp-types";
import { connect } from "react-redux";
import { DATE_INPUT_MASK } from "../../../../../services/masks";

const SECTION_NAME = "property_info";

const mapState = (state: iRootState) => ({

});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateBondInsurance: (bondInsuranceFormState: Partial<BondInsuranceFormState>) =>
    dispatch.bondInsuranceForm.updateBondInsurance(bondInsuranceFormState)
});

type ContractDataProps = StepProps
  & ReturnType<typeof mapState>
  & ReturnType<typeof mapDispatch>;

const ContractData = (props: ContractDataProps): ReactElement => {
  const {
    errors,
    touched,
    handleInputChange,
    handleDateChange,
    values,
    setFieldTouched,
    setFieldValue,
    updateBondInsurance
  } = props;

  const [locationInProgress, setLocationInProgress] = useState<boolean>(false);

  const { property_info, initial_info } = values;

  const [reasonsOptions, setReasonsOptions] = useState<
    Array<DropdownItemProps>
  >([]);

  useEffect(() => {
    if (
      initial_info.purpose === PurposesEnum.commercial &&
      initial_info.tenant_type === PersonTypesEnum.natural
    ) {
      setReasonsOptions(naturalCommercialReasonOptions);
      setFieldValue(`${SECTION_NAME}.reason`, "Sede de empresa");
    } else if (
      initial_info.purpose === PurposesEnum.residential &&
      initial_info.tenant_type === PersonTypesEnum.natural
    ) {
      setReasonsOptions(naturalResidentialReasonOptions);
      setFieldValue(`${SECTION_NAME}.reason`, "Imóvel próximo de familiares");
    } else {
      setReasonsOptions(legalCommercialResidentialReasonOptions);
    }
  }, []);

  const validateErros = (field: string) =>
    getErrorFormik(errors, touched, SECTION_NAME, field);

  return (
    <>
      <SectionLabel text="DADOS DO CONTRATO" />
      <Grid.Row>
        <Grid.Column width={8}>
          <FieldLabel>MOTIVO DA LOCAÇÃO* </FieldLabel>
          <Field
            id={`${SECTION_NAME}.reason`}
            name={`${SECTION_NAME}.reason`}
            onChange={handleInputChange}
            onClose={() => setFieldTouched(`${SECTION_NAME}.reason`, true)}
            error={validateErros("reason")}
            options={reasonsOptions}
            component={Dropdown}
            placeholder="Selecionar..."
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.reason`} />
        </Grid.Column>

        <Grid.Column width={8}>
          <FieldLabel>VIGÊNCIA DO CONTRATO DE LOCAÇÃO* </FieldLabel>
          <Field
            id={`${SECTION_NAME}.validity`}
            name={`${SECTION_NAME}.validity`}
            completeValue={true}
            options={validityOptions}
            onChange={(e: SyntheticEvent, data: any) => {
              setFieldValue(`${SECTION_NAME}.validity`, data.value);
            }}
            className={validateErros("validity")}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.validity`, true)}
            component={Dropdown}
            selection
            compact
            basic
            fluid
          />
          <FormikErrorMessage
            component="div"
            name={`${SECTION_NAME}.validity`}
          />
        </Grid.Column>
      </Grid.Row>

      <FieldLabel style={{ paddingTop: '2rem' }}>O CONTRATO DE LOCAÇÃO JÁ SE ENCONTRA EM ANDAMENTO?*</FieldLabel>
      <Grid.Row>
        <Grid.Column width={1}>
          <Radio
            style={{ marginRight: '1rem' }}
            label="Sim"
            name="location_in_progress"
            checked={locationInProgress}
            onChange={() => {
              setLocationInProgress((prevState) => !prevState);
              setFieldValue('property_info.location_in_progress', !locationInProgress)
            }}
          />
        </Grid.Column>
        <Grid.Column width={1}>
          <Radio
            style={{ marginRight: '1rem' }}
            label="Não"
            name="location_in_progress"
            checked={!locationInProgress}
            onChange={() => {
              setLocationInProgress((prevState) => !prevState);
              setFieldValue('property_info.location_in_progress', !locationInProgress)
            }}
          />
        </Grid.Column>
      </Grid.Row>

      {locationInProgress && (
        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel>INICIO DA VIGÊNCIA DO CONTRATO DE LOCAÇÃO* </FieldLabel>
            <Field
              name={`rental_period_start_date`}
              selected={values.property_info.rental_period_start_date}
              component={CustomDatePicker}
              customInput={
                <AppMaskedInput
                  mask={DATE_INPUT_MASK}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              onChange={(date: Date, e: SyntheticEvent) => {
                setFieldValue('property_info.rental_period_start_date', date);
              }}
              className={validateErros("rental_period_start_date")}
            />
            <FormikErrorMessage
              component="div"
              name={`property_info.rental_period_start_date`}
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <FieldLabel>FIM DA VIGÊNCIA DO CONTRATO DE LOCAÇÃO* </FieldLabel>
            <Field
              name={`rental_period_end_date`}
              selected={values.property_info.rental_period_end_date}
              component={CustomDatePicker}
              customInput={
                <AppMaskedInput
                  mask={DATE_INPUT_MASK}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              onChange={(date: Date, e: SyntheticEvent) => {
                setFieldValue('property_info.rental_period_end_date', date);
              }}
              className={validateErros("rental_period_end_date")}
            />
            <FormikErrorMessage
              component="div"
              name={`property_info.rental_period_start_date`}
            />
          </Grid.Column>
        </Grid.Row>)}

    </>
  );
};

export default connect(mapState, mapDispatch)(ContractData);
