import React from "react";
import { ProductFormProps } from "../../ModalHireProposal";
import * as S from "./styles";
import { Popup, Icon } from "semantic-ui-react";

export type PortoSelectCardProps = {
  setSelected: (selected: string) => void;
  selected: string;
  offers: ProductFormProps[];
};

function maskMoney(valor: number) {
  return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}


const Porto20Details = () => {
  return (
    <div style={{margin: '4px'}}>
    <S.Table>
    <tr><th> Coberturas </th>  <th> Limite Máximo de Idênização  </th>  </tr>
    <tr> <td> Aluguel </td> <td>  Até 20x a verba coberta </td></tr>
    <tr> <td> IPTU, condomínio, água, Luz e Gás </td> <td>  Até 20x a verba coberta </td></tr>
    <tr> <td> Danos ao imóvel </td> <td>  Até 6 alugueis </td></tr>
    <tr> <td> Multa por Recisão contratual  </td> <td>  Até 3 alugueis </td></tr>
    <tr> <td> Pintura interna e externa </td> <td>  Até 2 alugueis </td></tr>
  </S.Table>
  </div>
)}

const Porto30Details = () => {
  return (
  <div style={{margin: 4}}>
  <S.Table>
    <tr><th> Coberturas </th>  <th> Limite Máximo de Idênização  </th>  </tr>
    <tr> <td> Aluguel </td> <td>  Até 30x a verba coberta </td></tr>
    <tr> <td> IPTU, condomínio, água, Luz e Gás </td> <td>  Até 30x a verba coberta </td></tr>
    <tr> <td> Danos ao imóvel </td> <td>  Até 6 alugueis </td></tr>
    <tr> <td> Multa por Recisão contratual  </td> <td>  Até 3 alugueis </td></tr>
    <tr> <td> Pintura interna e externa </td> <td>  Até 3 alugueis </td></tr>
  </S.Table>

  </div>

)}

export const PortoSelectCards = ({
  offers,
  selected,
  setSelected,
}: PortoSelectCardProps) => {
  return (
    <S.Wrapper>
      {offers &&
        offers.map((option, i) => {
          return (
            <S.Card key={i} isSelected={selected === option.seguro} onClick={() => setSelected(option.seguro)}>
              <S.Input type="checkbox" checked={selected === option.seguro} />
              <S.Content>
                <S.Row>
                <h2 style={{marginTop: 3}}>{option.nomeSeguro } </h2>
                <Popup
                   style={{overflow: "visible", position: "absolute"}}
                   flowing
                   hoverable
                   position='left center'
                  trigger={<Icon name={'info circle'} color={'blue'} style={{ cursor: 'help', marginTop: 2 }} />}
              >
              {
                option.nomeSeguro === "ESSENCIAL 20" ? Porto20Details() : Porto30Details()
              }

              </Popup>
              </S.Row>
                <p>
                  Parcela: <b>{maskMoney(option.valorParcela)}</b>
                </p>
                <p>
                  Prêmio Total: <b>{maskMoney(option.premioTotal)}</b>
                </p>
              </S.Content>
            </S.Card>
          );
        })}
    </S.Wrapper>
  );
};
export default PortoSelectCards;
