import React, { useEffect, useState } from 'react';
import {
  Grid,
  Radio,
  Dropdown as DropdownNativo,
  InputOnChangeData,
  Input,
  Modal,
  Button,
  Icon,
  Checkbox
} from 'semantic-ui-react';
import { FieldLabel, TextInfo } from '../../../../styles';
import { CustoDropDownItemProps } from '../../../../components/types';
import { StepProps } from '../../BondInsuranceForm';
import { FormikErrorMessage } from '../../../../components/ErrorMessage';
import { PurposesEnum } from '../../../../enums/purposes.enum';
import { PersonTypesEnum } from '../../../../enums/person-types.enum';
import { AnalysisAssuranceEnum } from '../../../../enums/analysis-assurance.enum';
import { iRootDispatch, iRootState } from '../../../../store';
import { BondInsuranceFormState } from '../../../../store/types/temp-types';
import {
  formatAmount,
  maskCnpj,
  maskCpf,
  maskMoney,
  maskPhoneNumber,
  unmaskCpfOrCnpj
} from '../../../../services/masks';
import { InsurersCodeEnum } from '../../../../enums/insurers-code.enum';
import { DivErrorMessage } from '../../../../components/ErrorMessage';
import { CheckboxContainer, Disclaimer } from '../../style';
import { getDataByDocument } from '../../../../services/documents';
import { UserBondInsurance } from '../../types';
import moment from 'moment';
import { dangerNotification } from '../../../../services/notification';
import { getUserBondInsurances } from '../../../../services/bond-insurance-analysis.service';
import { Field } from 'formik';
import { getErrorFormik } from '../../../../services/errors';
import { useHistory } from 'react-router-dom';
import { validateCNPJ, validateCPF } from '../../../../services/validators';
import { getEstateToView } from '../../../../services/estate';

const SECTION_NAME = 'initial_info';

const mapState = (state: iRootState) => ({
  bondInsuranceForm: state.bondInsuranceForm,
  availableBrokersAndEstates: state.availableBrokersAndEstates
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateBondInsurance: (bondInsuranceFormState: BondInsuranceFormState) =>
    dispatch.bondInsuranceForm.updateBondInsurance(bondInsuranceFormState)
});

type InitialInfoStepProps = StepProps &
  ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch>;

export const InitialInfoStep = (props: InitialInfoStepProps) => {
  const history = useHistory();
  const [cpfIsLoading, setCpfIsLoading] = useState<boolean>(false);
  const [isCpfModalOpen, setIsCpfModalOpen] = useState<boolean>(false);
  const [cnpjIsLoading, setCnpjIsLoading] = useState<boolean>(false);
  const [isCpfInvalid, setIsCpfInvalid] = useState<boolean>(false);
  const [isCnpjInvalid, setIsCnpjInvalid] = useState<boolean>(false);

  const [userBondInsuranceData, setUserBondInsuranceData] =
    useState<UserBondInsurance[]>();
  const [estateOptions, setEstateOptions] = useState([
    { key: 0, text: 'Confiax Comercial', value: 0 }
  ]);
  const [selectedEstate, setSelectedEstate] = useState<number>();
  const {
    values,
    availableBrokersAndEstates,
    setFieldValue,
    updateBondInsurance,
    setFieldTouched,
    handleInputChange,
    errors,
    touched,
    isCopied = false
  } = props;
  const validateErros = (field: string) =>
    getErrorFormik(errors, touched, SECTION_NAME, field);

  const { initial_info, tenant_info } = values;
  const [hasAgreedWithTerms, setHasAgreedWithTerms] = useState<boolean>(false);

  const estates: CustoDropDownItemProps[] = availableBrokersAndEstates
    ? availableBrokersAndEstates?.estates
    : [];

  // const udpateOwnerAddress = async (estateId: string) => {
  //   try {
  //     const estateData = await getEstateToView(estateId);
  //     setFieldValue('owner_info', {
  //       email: estateData?.email || '',
  //       phone: estateData?.responsible_phone || '',
  //       zip_code: estateData?.estate_zip_code,
  //       street: estateData?.estate_street,
  //       district: estateData?.estate_district,
  //       number: estateData?.estate_number,
  //       city: estateData?.estate_city,
  //       state: estateData?.estate_state,
  //       complement: estateData?.estate_complement
  //     });
  //   } catch (err) {
  //     dangerNotification(
  //       'Oops',
  //       'Ocorreu um erro ao buscar as informações da imobiliária'
  //     );
  //   }
  // };

  useEffect(() => {
    if (
      values?.initial_info?.estate &&
      values?.initial_info?.estate?.id !== selectedEstate
    ) {
      const selected = estates.find(
        c => `${c?.value?.id}` === `${values?.initial_info?.estate?.id}`
      );
      if (!selected) return;
      setFieldValue('initial_info.estate', selected.value);
      setSelectedEstate(selected.key);
      // udpateOwnerAddress(selected.value.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estates, selectedEstate, setFieldValue, values?.initial_info?.estate]);

  const analysisOptions: CustoDropDownItemProps[] = [
    {
      key: 1,
      text: 'Plano Seguro Completo (Garantido)',
      value: AnalysisAssuranceEnum.complete
    },
    { key: 2, text: 'Plano Seguro Simples (Básico)', value: AnalysisAssuranceEnum.simple }
  ];

  const onChangeCpf = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const unmaskedValue = unmaskCpfOrCnpj(e.target.value);

    if (unmaskedValue.length >= 11) {
      if (!validateCPF(unmaskedValue)) {
        setIsCpfInvalid(true);
        return;
      }
      setIsCpfInvalid(false);
      setCpfIsLoading(true);

      getDataByDocument(unmaskedValue)
        .then(data => {
          const birthDate = new Date(data.birth_date);
          birthDate.setTime(
            birthDate.getTime() + birthDate.getTimezoneOffset() * 60 * 1000
          );

          setFieldValue('tenant_info.main_tenant_name', data.name);
          setFieldValue('tenant_info.main_tenant_birth_date', birthDate);
          setFieldValue('tenant_info.main_tenant_gender', data.gender);
          setFieldValue('tenant_info.main_tenant_mother_name', data.mother_name);

          const estateId = values?.initial_info?.estate?.id;

          const startDate = new Date();
          startDate.setDate(startDate.getDate() - 30);
          startDate.setHours(0, 0, 0, 0);

          if (estateId) {
            getUserBondInsurances(unmaskedValue, estateId, 'APPROVED', startDate)
              .then(data => {
                setUserBondInsuranceData(data?.length ? data : []);
              })
              .catch(error => {
                console.log({ error });
                setFieldValue('tenant_info.has_restrictions', false);
              });
          }
        })
        .catch(e => {
          setFieldValue('tenant_info.has_restrictions', false);
          dangerNotification(
            'Oops...',
            'Não foi possível encontrar dados associados ao documento informado'
          );
          console.log(e);
        })
        .finally(() => {
          setCpfIsLoading(false);
        });
    }
  };

  const onChangeCnpj = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const unmaskedValue = unmaskCpfOrCnpj(e.target.value);

    if (unmaskedValue.length >= 11) {
      if (!validateCNPJ(unmaskedValue)) {
        setIsCnpjInvalid(true);
        return;
      }
      setIsCnpjInvalid(false);
      setCnpjIsLoading(true);

      getDataByDocument(unmaskedValue)
        .then(data => {
          setFieldValue('tenant_info.company', data.company);
          setFieldValue('tenant_info.foundation_date', new Date(data.foundation_date));
          setFieldValue(
            'tenant_info.share_capital',
            maskMoney(data.share_capital.toString())
          );
          setFieldValue('tenant_info.phone', maskPhoneNumber(data.phone));
          setFieldValue('tenant_info.email', data.email);
          setFieldValue('tenant_info.number', data.address.number);
          setFieldValue('tenant_info.zip_code', data.address.zip_code);
        })
        .catch(e => {
          dangerNotification(
            'Oops...',
            'Não foi possível encontrar dados associados ao documento informado'
          );
        })
        .finally(() => {
          setCnpjIsLoading(false);
        });
    }
  };

  const handleNewChangeEstate = (estateKey: any) => {
    const selected = estates.find(c => c.key === estateKey);
    if (!selected) return;

    props.bondInsuranceForm.isToo = false;

    setSelectedEstate(estateKey);
    initial_info.estate = selected.value;
    props.bondInsuranceForm.estateEmail = initial_info.estate.email;
    setFieldValue('initial_info.estate', selected.value);
    setAssurance();
  };

  const setDefaultValues = () => {
    if (estates.length === 1) {
      initial_info.estate = estates[0].value;
    }
    if (initial_info.estate) {
      setFieldValue('initial_info.analysis_assurance', analysisOptions[0].value);
      initial_info.analysis_assurance = analysisOptions[0].value;
    }
  };

  const setAssurance = () => {
    setFieldValue('initial_info.analysis_assurance', analysisOptions[0].value);
    initial_info.analysis_assurance = analysisOptions[0].value;
  };

  useEffect(() => {
    setDefaultValues();
    const estateOptions = estates.map(estate => ({
      key: estate.key,
      text: estate.text,
      value: estate.key
    }));
    setEstateOptions(estateOptions);
    if (estates.length === 1) {
      const selected = estates[0];
      if (!selected) return;
      setSelectedEstate(estates[0].key);
      initial_info.estate = selected.value;
      setFieldValue('initial_info.estate', selected.value);
      setAssurance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estates]);
  const [sanatizedUserBondInsurance, setSanatizedUserBondInsurance] = useState<any[]>([]);

  function translateBondInsuranceStatus(status: string) {
    let newStatus = '';
    let color = '';

    switch (status) {
      case 'APPROVED':
        newStatus = 'Aprovado';
        color = 'rgba(19, 135, 46, 1)';
        break;
      case 'CANCELED':
        newStatus = 'Cancelado';
        color = 'rgba(219, 40, 40, 1)';
        break;
      case 'EXPIRED':
        newStatus = 'Expirado';
        color = 'rgba(219, 40, 40, 1)';
        break;
      default:
        newStatus = '';
        break;
    }

    return { text: newStatus, color };
  }

  function translateBondInsuranceAnalysisStatus(status: string) {
    let newStatus = '';
    let color = '';

    switch (status) {
      case 'APPROVED':
        newStatus = 'Aprovado';
        color = 'rgba(19, 135, 46, 1)';
        break;
      case 'PROPOSAL':
        newStatus = 'Em proposta';
        color = 'rgba(33, 133, 208, 1)';
        break;
      case 'ANALYSIS':
        newStatus = 'Em análise';
        color = 'rgba(33, 133, 208, 1)';
        break;
      case 'ERROR':
        newStatus = 'Erro';
        color = 'rgba(219, 40, 40, 1)';
        break;
      case 'PENDING':
        newStatus = 'Pendenciado';
        color = 'rgba(242, 113, 28, 1)';
        break;
      case 'REJECTED':
        newStatus = 'Recusado';
        color = 'rgba(219, 40, 40, 1)';
        break;
      case 'UNDEFINED':
        newStatus = 'Indefinido';
        color = 'rgba(219, 40, 40, 1)';
        break;
      case 'WAITING':
        newStatus = 'Em espera';
        color = 'rgba(242, 113, 28, 1)';
        break;
    }

    return { text: newStatus, color };
  }

  useEffect(() => {
    if (userBondInsuranceData?.length) {
      let newItem: UserBondInsurance[] = [];
      const finalItem: any[] = [];
      userBondInsuranceData.forEach((item, index, array) => {
        const oldItem = array[index - 1];
        const bondInsuranceId = item.bondInsurance.id;
        const bondInsuranceIdOld = oldItem?.bondInsurance.id;

        if (oldItem) {
          if (bondInsuranceId !== bondInsuranceIdOld) {
            finalItem.push(newItem);

            newItem = [item];
          } else {
            newItem.push(item);
          }
        } else {
          newItem.push(item);
        }

        if (array.length === index + 1) {
          finalItem.push(newItem);
        }
      });

      setSanatizedUserBondInsurance(finalItem);
      setIsCpfModalOpen(true);
      setFieldValue('tenant_info.has_restrictions', true);
    } else {
      setFieldValue('tenant_info.has_restrictions', false);
    }
  }, [setFieldValue, userBondInsuranceData]);

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel paddingTop={true}>IMOBILIÁRIA* </FieldLabel>
            <div style={{ width: '100%' }}>
              <DropdownNativo
                style={{ width: '100%' }}
                value={selectedEstate}
                name={`${SECTION_NAME}.estate`}
                id={`${SECTION_NAME}.estate`}
                defaultValue={estates.length === 1 ? estates[0].value : null}
                disabled={estates.length === 1}
                options={estateOptions}
                placeholder={'Selecionar...'}
                control
                search
                selection
                onChange={(e, { value }) => handleNewChangeEstate(value)}
              />
            </div>

            <FormikErrorMessage component="div" name={`${SECTION_NAME}.estate`} />
          </Grid.Column>
        </Grid.Row>

        <FieldLabel paddingTop={true}>TIPO DO IMÓVEL * </FieldLabel>

        <Grid.Row>
          <Grid.Column width={5}>
            <Radio
              id={`${SECTION_NAME}.purpose.residential`}
              label="Residencial"
              name={`${SECTION_NAME}.purpose`}
              value={PurposesEnum.residential}
              checked={initial_info.purpose === PurposesEnum.residential}
              onChange={(_, data) => {
                setFieldValue(data.name!, data.value);
                updateBondInsurance({
                  ...props.bondInsuranceForm,
                  propertyType: data.value!.toString()
                });
              }}
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <Radio
              id={`${SECTION_NAME}.purpose.comercial`}
              label="Comercial"
              name={`${SECTION_NAME}.purpose`}
              value={PurposesEnum.commercial}
              checked={initial_info.purpose === PurposesEnum.commercial}
              onChange={(_, data) => {
                setFieldValue(data.name!, data.value);
                updateBondInsurance({
                  ...props.bondInsuranceForm,
                  propertyType: data.value!.toString()
                });
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <FormikErrorMessage component="div" name="initial_info.property_type" />

        <FieldLabel paddingTop={true}>TIPO DO LOCATÁRIO * </FieldLabel>

        <Grid.Row>
          <Grid.Column width={5}>
            <Radio
              id={`tenant_type.natural`}
              label="Pessoa Física"
              name={`${SECTION_NAME}.tenant_type`}
              value={PersonTypesEnum.natural}
              checked={initial_info.tenant_type === PersonTypesEnum.natural}
              onChange={(_, data) => {
                setFieldValue(data.name!, data.value);
                updateBondInsurance({
                  ...props.bondInsuranceForm,
                  tenantType: data.value!.toString()
                });
              }}
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <Radio
              id={`tenant_type.legal`}
              label="Pessoa Jurídica"
              name={`${SECTION_NAME}.tenant_type`}
              value={PersonTypesEnum.legal}
              checked={initial_info.tenant_type === PersonTypesEnum.legal}
              onChange={(_, data) => {
                setFieldValue(data.name!, data.value);
                updateBondInsurance({
                  ...props.bondInsuranceForm,
                  tenantType: data.value!.toString()
                });
              }}
            />
          </Grid.Column>
        </Grid.Row>

        <FormikErrorMessage component="div" name={`${SECTION_NAME}.tenant_type`} />

        <Grid.Row>
          <Grid.Column width={4}>
            {initial_info.tenant_type === PersonTypesEnum.natural && (
              <>
                <FieldLabel>CPF*</FieldLabel>
                <Field
                  id={'tenant_info.main_tenant_cpf'}
                  loading={cpfIsLoading}
                  name={'tenant_info.main_tenant_cpf'}
                  className={isCpfInvalid ? 'text-input error' : ''}
                  value={tenant_info.main_tenant_cpf}
                  disabled={!initial_info?.estate?.id || isCopied}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                    data: InputOnChangeData
                  ): void => {
                    data.value = maskCpf(data.value);
                    handleInputChange(e, data);
                  }}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldTouched('tenant_info.main_tenant_cpf', true);
                    onChangeCpf(e);
                  }}
                  error={
                    validateErros('main_tenant_cpf') || tenant_info?.has_restrictions
                  }
                  component={Input}
                  fluid
                />
                <FormikErrorMessage
                  component="div"
                  name={'tenant_info.main_tenant_cpf'}
                />
                <b>{tenant_info?.has_restrictions}</b>
                {tenant_info?.has_restrictions && (
                  <DivErrorMessage clickable onClick={() => setIsCpfModalOpen(true)}>
                    *O locatário já possui uma análise em andamento.
                  </DivErrorMessage>
                )}
                {isCpfInvalid && <DivErrorMessage>Documento inválido!</DivErrorMessage>}
              </>
            )}
            {initial_info.tenant_type === PersonTypesEnum.legal && (
              <>
                <FieldLabel>CNPJ*</FieldLabel>
                <Field
                  loading={cnpjIsLoading}
                  name={'tenant_info.document'}
                  disabled={isCopied}
                  value={tenant_info.document}
                  className={isCpfInvalid ? 'text-input error' : ''}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                    data: InputOnChangeData
                  ): void => {
                    data.value = maskCnpj(data.value);
                    handleInputChange(e, data);
                  }}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldTouched('tenant_info.document', true);
                    onChangeCnpj(e);
                  }}
                  error={validateErros('document')}
                  component={Input}
                  fluid
                />
                <FormikErrorMessage component="div" name={'tenant_info.document'} />
                {isCnpjInvalid && <DivErrorMessage>Documento inválido!</DivErrorMessage>}
              </>
            )}
          </Grid.Column>
        </Grid.Row>

        <FieldLabel paddingTop={true}>SEGURADORAS / GARANTIDORAS ATIVAS </FieldLabel>
        <Grid.Row>
          <div
            style={{
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'row',
              gap: '1rem',
              width: '80vw',
              whiteSpace: 'nowrap',
              marginLeft: '1rem'
            }}
          >
            {values.initial_info?.estate?.insurers?.map((insurers, index) => {
              const insurerData = values.initial_info?.estate?.insurer_data?.find(
                c => c?.insurer?.id === insurers.id
              );
              return (
                insurerData && (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'left',
                      alignItems: 'left',
                      width: '240px',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '5px',
                      marginBottom: '10px'
                    }}
                  >
                    <h3
                      style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        marginBottom: '4px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                    >
                      {insurerData.insurer?.name}
                    </h3>
                    {insurerData.insurer?.code === InsurersCodeEnum.Porto ||
                    insurerData.insurer?.code === InsurersCodeEnum.Garantti ||
                    insurerData.insurer?.code === InsurersCodeEnum.Too ||
                    insurerData.insurer?.code === InsurersCodeEnum.TokioMarine ? (
                      <p style={{ fontSize: '14px' }}>
                        Taxa, cobertura e LMI: <br /> &nbsp; &nbsp; Por Oferta Aprovada{' '}
                      </p>
                    ) : insurerData.insurer?.code === InsurersCodeEnum.PortoEssencial ? (
                      <p style={{ fontSize: '14px' }}>
                        Cobertura:{' '}
                        {insurerData.assurance === AnalysisAssuranceEnum.complete
                          ? 'Completa'
                          : 'Simples'}
                        <br />
                        LMI e Taxas: Por Oferta Aprovada{' '}
                      </p>
                    ) : (
                      <p style={{ fontSize: '14px' }}>
                        Cobertura:{' '}
                        {insurerData.assurance === AnalysisAssuranceEnum.complete
                          ? 'Completa'
                          : 'Simples'}
                        ;{' '}
                        {insurerData.insurer.code === InsurersCodeEnum.Pottencial ? (
                          <span>Multiplo: {insurerData.multiple}</span>
                        ) : (
                          insurerData?.lmi > 0 && <span>LMI: {insurerData.lmi}x</span>
                        )}
                        <br />
                        {parseFloat(insurerData.pf_comercial_tax) > 0 && (
                          <span>
                            {' '}
                            Taxa Comercial:{' '}
                            {formatAmount(
                              (parseFloat(insurerData.pf_comercial_tax) * 100).toFixed(2)
                            )}
                            % <br />
                          </span>
                        )}
                        {parseFloat(insurerData.pf_residential_tax) > 0 && (
                          <span>
                            {' '}
                            Taxa Residêncial:{' '}
                            {formatAmount(
                              (parseFloat(insurerData.pf_residential_tax) * 100).toFixed(
                                2
                              )
                            )}
                            % <br />
                          </span>
                        )}
                      </p>
                    )}
                  </div>
                )
              );
            })}
          </div>
          {values?.initial_info?.estate?.insurers?.length === 0 && (
            <Disclaimer>
              <p>Atenção: Nenhuma seguradora ativa para a imobiliária selecionada</p>
            </Disclaimer>
          )}
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <TextInfo>
              <b>Atenção:</b> O tipo do imóvel, tipo do locatário e o locatário não podem
              ser alterados depois de uma análise realizada. <br />
              Caso seja necessário alterar, deverá ser realizado uma nova análise e com
              isso, por regra das seguradoras, <br />
              <b>o parecer da análise poderá ser alterado</b>.
              <br />
              <b>Imóvel Residencial:</b> O pretendente a locação que será analisado
              precisa ser o locatário e deverá morar no imóvel, sob risco de recusa no
              momento do sinistro.
            </TextInfo>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Modal
        onClose={() => setIsCpfModalOpen(false)}
        onOpen={() => setIsCpfModalOpen(true)}
        open={isCpfModalOpen}
        size={'large'}
      >
        <Modal.Header>Este CPF possui análises em nosso sistema</Modal.Header>
        <Modal.Content scrolling>
          {sanatizedUserBondInsurance.map(item => {
            const userBondInsurance: UserBondInsurance[] = item;

            return (
              <>
                <div
                  key={userBondInsurance[0].bondInsurance.id}
                  style={{
                    flex: 1,
                    padding: 10,
                    border: '1px solid #ccc',
                    borderWidth: 0.5,
                    borderRadius: 6,
                    marginTop: 5,
                    marginBottom: 5
                  }}
                >
                  <div style={{ display: 'flex', flex: 1, gap: 20 }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        justifyContent: 'space-between',
                        gap: 20
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontSize: 13 }}>Data de Criação da Análise:</span>
                        <span style={{ flex: 1, fontSize: 16, fontWeight: 'bold' }}>
                          {userBondInsurance[0].bondInsurance.createdAt
                            ? moment(userBondInsurance[0].bondInsurance.createdAt).format(
                                'DD/MM/YYYY HH:mm'
                              )
                            : 'Sem inicio'}
                        </span>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontSize: 13 }}>Data de Cancelamento:</span>
                        <span style={{ flex: 1, fontSize: 16, fontWeight: 'bold' }}>
                          {userBondInsurance[0].bondInsurance.canceledAt
                            ? moment(
                                userBondInsurance[0].bondInsurance.canceledAt
                              ).format('DD/MM/YYYY HH:mm')
                            : '--/--/--'}
                        </span>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontSize: 13 }}>Data de Contratação:</span>
                        <span style={{ flex: 1, fontSize: 16, fontWeight: 'bold' }}>
                          {userBondInsurance[0].bondInsurance.bondInsuranceHiredData
                            ?.createdAt
                            ? moment(
                                userBondInsurance[0].bondInsurance.bondInsuranceHiredData
                                  .createdAt
                              ).format('DD/MM/YYYY HH:mm')
                            : '--/--/--'}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        gap: 20,
                        justifyContent: 'space-evenly'
                      }}
                    >
                      {userBondInsurance.map(bondInsurance => {
                        return (
                          <div
                            key={bondInsurance.id}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              color:
                                bondInsurance.bondInsurance.status === 'CREATED'
                                  ? translateBondInsuranceAnalysisStatus(
                                      bondInsurance.status
                                    ).color
                                  : translateBondInsuranceStatus(
                                      bondInsurance.bondInsurance.status
                                    ).color
                            }}
                          >
                            <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                              {bondInsurance.bondInsurance.status === 'CREATED'
                                ? translateBondInsuranceAnalysisStatus(
                                    bondInsurance.status
                                  ).text
                                : translateBondInsuranceStatus(
                                    bondInsurance.bondInsurance.status
                                  ).text}
                            </span>
                            <span style={{ fontSize: 11 }}>
                              {bondInsurance.insurer.name}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        justifyContent: 'space-between',
                        gap: 20
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontSize: 13 }}>Endereço:</span>
                        <span style={{ flex: 1, fontSize: 16, fontWeight: 'bold' }}>
                          {userBondInsurance[0].bondInsurance.property.street},{' '}
                          {userBondInsurance[0].bondInsurance.property.number} -{' '}
                          {userBondInsurance[0].bondInsurance.property.district} -{' '}
                          {userBondInsurance[0].bondInsurance.property.city} -{' '}
                          {userBondInsurance[0].bondInsurance.property.state}
                        </span>
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'end' }}>
                      <Button
                        type="button"
                        color="grey"
                        onClick={() => {
                          history.push(
                            `bond-insurances/${userBondInsurance[0]?.bondInsurance?.id}`
                          );
                        }}
                      >
                        <Icon name="arrow right" /> Acessar análise
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </Modal.Content>
        <Modal.Actions>
          <Grid>
            <Grid.Column width={12}>
              <CheckboxContainer>
                <Checkbox
                  checked={hasAgreedWithTerms}
                  onChange={(_, value) => {
                    setHasAgreedWithTerms(!!value.checked);
                  }}
                />
                <span>
                  Estou ciente das análises existentes no sistema e desejo realizar uma
                  nova. A nova análise poderá ser influenciada pelas análises existentes
                  no sistema.
                </span>
              </CheckboxContainer>
            </Grid.Column>
            <Grid.Column width={4}>
              <Button
                disabled={!hasAgreedWithTerms}
                onClick={() => {
                  setFieldValue('tenant_info.has_restrictions', false);
                  setIsCpfModalOpen(false);
                }}
                type="submit"
                color="blue"
              >
                <Icon name="arrow right" /> Criar nova análise
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
    </>
  );
};
