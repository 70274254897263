import React from 'react';
import { CreatePolicyProps } from '../../types';
import { Dropdown, Grid, Input } from 'semantic-ui-react';
import { maskCpfOrCnpj, maskZipCode } from '../../../../../../services/masks';
import { onChangeCep } from '../../utils';
import { UFOptions } from '../../../../../bond-insurance-budget-form/types';

export const RenderPropertyOwnerForm = ({
  values,
  setFieldValue
}: {
  values: CreatePolicyProps;
  setFieldValue: (field: string, value: any) => void;
}) => {
  const [cepLoading, setCepLoading] = React.useState(false);
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <label>NOME</label>
          <Input
            fluid
            value={values?.bondInsurance?.propertyOwnerNaturalPerson?.name}
            onChange={e => {
              setFieldValue(
                'bondInsurance.propertyOwnerNaturalPerson.name',
                e.target.value
              );
            }}
            placeholder="Digite..."
          />
        </Grid.Column>

        <Grid.Column width={8}>
          <label>CPF/CNPJ</label>
          <Input
            fluid
            value={
              values?.bondInsurance?.propertyOwnerNaturalPerson?.cpf
                ? maskCpfOrCnpj(values?.bondInsurance?.propertyOwnerNaturalPerson?.cpf)
                : ''
            }
            onChange={e => {
              setFieldValue(
                'bondInsurance.propertyOwnerNaturalPerson.cpf',
                e.target.value
              );
            }}
            placeholder="Digite..."
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={5}>
          <label>CEP</label>
          <Input
            placeholder="CEP"
            fluid
            value={
              values?.bondInsurance?.responsibleAddress?.zip_code
                ? maskZipCode(values?.bondInsurance?.responsibleAddress?.zip_code)
                : ''
            }
            onChange={e => {
              onChangeCep(setFieldValue, 'bondInsurance.responsibleAddress', e, setCepLoading);
            }}
            loading={cepLoading}
          />
        </Grid.Column>

        <Grid.Column width={6}>
          <label>LOGRADOURO</label>
          <Input
            value={values?.bondInsurance?.responsibleAddress?.street}
            onChange={e => {
              setFieldValue('bondInsurance.responsibleAddress.street', e.target.value);
            }}
            placeholder="Logradouro"
            fluid
            loading={cepLoading}
          />
        </Grid.Column>

        <Grid.Column width={5}>
          <label>BAIRRO</label>
          <Input
            placeholder="Bairro"
            fluid
            value={values?.bondInsurance?.responsibleAddress?.district}
            onChange={e => {
              setFieldValue('bondInsurance.responsibleAddress.district', e.target.value);
            }}
            loading={cepLoading}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={5}>
          <label>CIDADE</label>
          <Input
            placeholder="Cidade"
            fluid
            value={values?.bondInsurance?.responsibleAddress?.city}
            onChange={e => {
              setFieldValue('bondInsurance.responsibleAddress.city', e.target.value);
            }}
            loading={cepLoading}
          />
        </Grid.Column>

        <Grid.Column width={3}>
          <label>UF</label>
          <Dropdown
            search
            style={{ width: '100%' }}
            clearable
            selection
            options={UFOptions}
            placeholder={'Selecionar...'}
            value={values?.bondInsurance?.responsibleAddress?.state}
            onChange={(e, { value }) => {
              setFieldValue('bondInsurance.responsibleAddress.state', value);
            }}
            loading={cepLoading}
          />
        </Grid.Column>

        <Grid.Column width={3}>
          <label>NÚMERO</label>
          <Input
            placeholder="NÚMERO"
            fluid
            value={values?.bondInsurance?.responsibleAddress?.number}
            onChange={e => {
              setFieldValue('bondInsurance.responsibleAddress.number', e.target.value);
            }}
          />
        </Grid.Column>

        <Grid.Column width={5}>
          <label>COMPLEMENTO</label>
          <Input
            placeholder="Complemento"
            fluid
            value={values?.bondInsurance?.responsibleAddress?.complement}
            onChange={e => {
              setFieldValue(
                'bondInsurance.responsibleAddress.complement',
                e.target.value
              );
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
