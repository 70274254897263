import { FormikProps } from 'formik';
import { ClaimPropertyStatusEnum } from '../../../../enums/claim-property-status.enum';
import { CreateClaimEstateInitialInitialValuesType } from './Formik/types/CreateClaimEstateInitialValuesTypes';

export const getFileIdentifiers = (
  innerValues: FormikProps<CreateClaimEstateInitialInitialValuesType>
) => {
  return innerValues.values.claimProperty.status === ClaimPropertyStatusEnum.occupied
    ? [
        {
          name: 'Boleto Aluguel',
          key: 'RENT_BILL',
          required: true
        },
        {
          name: 'Boleto Condomínio',
          key: 'CONDOMINIUM_BILL',
          required: false
        },
        {
          name: 'Guia de IPTU',
          key: 'IPTU_GUIDE',
          required: false
        },
        {
          name: 'Vistoria Inicial do Imóvel',
          key: 'INITIAL_INSPECTION_PROPERTY',
          required: innerValues.values.damageControl
        },
        {
          name: 'Vistoria Final do Imóvel',
          key: 'FINAL_INSPECTION_PROPERTY',
          required: innerValues.values.damageControl
        },
        {
          name: 'Contrato de administração',
          key: 'PROPERTY_MANAGEMENT_CONTRACT',
          required: true
        },
        {
          name: 'Procuração',
          key: 'LETTER_ATTORNEY',
          required: true
        },
        {
          name: 'Declaração de débitos',
          key: 'DEBITS_SHEET',
          required: true
        },
        {
          name: 'Acordo de Confissão de Dívidas',
          key: 'DEBT_CONFESS_AGREEMENT',
          required: false
        },
        {
          name: 'Contrato de Locação',
          key: 'LOCATION_QUOTATION',
          required: true
        },
        {
          name: 'RG do Locador',
          key: 'LOCATOR_DOCUMENT',
          required: false
        },
        {
          name: 'Comprovante de Endereço Locador',
          key: 'LOCATOR_ADDRESS_DOCUMENT',
          required: false
        },
        {
          name: 'RG/CPF do Locatário',
          key: 'TENANT_DOCUMENT',
          required: false
        },
        {
          name: 'Contrato Garantti Locatário',
          key: 'LOCATOR_GARANTTI_CONTRACT',
          required: false
        },
        {
          name: 'Imissão na posse',
          key: 'POSSESSION_IMMISSION',
          required: false
        },
        {
          name: 'Acordo escritório',
          key: 'OFFICE_AGREEMENT',
          required: false
        },
        {
          name: 'Apólice Seguro Incêndio',
          key: 'FIRE_INSURANCE_POLICY',
          required: false
        },
        {
          name: 'Comprovante de Pagamento (planilha)',
          key: 'PAYMENT_VOUCHER',
          required: false
        },
        {
          name: 'Planilha de indenização',
          key: 'INDEMNITY_WORKSHEET',
          required: false
        },
        {
          name: 'Planilha de acordo',
          key: 'ACCORD_WORKSHEET',
          required: false
        },
        {
          name: 'Água',
          key: 'WATER_BILL',
          required: false
        },
        {
          name: 'Luz',
          key: 'ENERGY_BILL',
          required: false
        },
        {
          name: 'Apólice Seguro Fiança',
          key: 'BAIL_INSURANCE_POLICY',
          required: false
        },
        {
          name: 'Boleto Devolutivo',
          key: 'RETURN_BILL',
          required: false
        },
        {
          name: 'Planilha de danos',
          key: 'DAMAGE_WORKSHEET',
          required: false
        },
        {
          name: 'Planilha de pintura',
          key: 'PAINTING_WORKSHEET',
          required: false
        },
        {
          name: 'Informativo de sinistro',
          key: 'CLAIM_INFORMATION',
          required: false
        },
        { name: 'Outros', key: 'OTHER', required: false }
      ]
    : [
        {
          name: 'Boleto Aluguel',
          key: 'RENT_BILL',
          required: true
        },
        {
          name: 'Boleto Condomínio',
          key: 'CONDOMINIUM_BILL',
          required: false
        },
        {
          name: 'Guia de IPTU',
          key: 'IPTU_GUIDE',
          required: false
        },
        {
          name: 'Vistoria Inicial do Imóvel',
          key: 'INITIAL_INSPECTION_PROPERTY',
          required: innerValues.values.damageControl
        },
        {
          name: 'Vistoria Final do Imóvel',
          key: 'FINAL_INSPECTION_PROPERTY',
          required: innerValues.values.damageControl
        },
        {
          name: 'Orçamentos',
          key: 'BUDGETS',
          required: innerValues.values.damageControl
        },
        {
          name: 'Termo de entrega das chaves',
          key: 'TERM_DELIVERY_PROPERTY_KEYS',
          required: true
        },
        {
          name: 'Declaração de débitos',
          key: 'DEBITS_SHEET',
          required: true
        },
        {
          name: 'Acordo de Confissão de Dívidas',
          key: 'DEBT_CONFESS_AGREEMENT',
          required: false
        },
        {
          name: 'Contrato de Locação',
          key: 'LOCATION_QUOTATION',
          required: true
        },
        {
          name: 'RG do Locador',
          key: 'LOCATOR_DOCUMENT',
          required: false
        },
        {
          name: 'Comprovante de Endereço Locador',
          key: 'LOCATOR_ADDRESS_DOCUMENT',
          required: false
        },
        {
          name: 'RG/CPF do Locatário',
          key: 'TENANT_DOCUMENT',
          required: false
        },
        {
          name: 'Contrato Garantti Locatário',
          key: 'LOCATOR_GARANTTI_CONTRACT',
          required: false
        },
        {
          name: 'Imissão na posse',
          key: 'POSSESSION_IMMISSION',
          required: false
        },
        {
          name: 'Acordo escritório',
          key: 'OFFICE_AGREEMENT',
          required: false
        },
        {
          name: 'Apólice Seguro Incêndio',
          key: 'FIRE_INSURANCE_POLICY',
          required: false
        },
        {
          name: 'Comprovante de Pagamento (planilha)',
          key: 'PAYMENT_VOUCHER',
          required: false
        },
        {
          name: 'Planilha de indenização',
          key: 'INDEMNITY_WORKSHEET',
          required: false
        },
        {
          name: 'Planilha de acordo',
          key: 'ACCORD_WORKSHEET',
          required: false
        },
        {
          name: 'Água',
          key: 'WATER_BILL',
          required: false
        },
        {
          name: 'Luz',
          key: 'ENERGY_BILL',
          required: false
        },
        {
          name: 'Apólice Seguro Fiança',
          key: 'BAIL_INSURANCE_POLICY',
          required: false
        },
        {
          name: 'Boleto Devolutivo',
          key: 'RETURN_BILL',
          required: false
        },
        {
          name: 'Planilha de danos',
          key: 'DAMAGE_WORKSHEET',
          required: false
        },
        {
          name: 'Planilha de pintura',
          key: 'PAINTING_WORKSHEET',
          required: false
        },
        {
          name: 'Informativo de sinistro',
          key: 'CLAIM_INFORMATION',
          required: false
        },
        { name: 'Outros', key: 'OTHER', required: false }
      ];
};
