import { get, post, put } from './request';

import { EstateState } from '../types/EstateState';
import { EstatePreregisterListResponseDTO } from '../pages/estates-preregister-list/types';
import { UserState } from '../types/UserState';
import {
  EstateForGetEstatesResponseDTO,
  GetEstateToViewDTO,
  PreRegisterResponseDTO
} from '../dtos/estate/estate-for-get-estates-response.dto';
import { UpdateEstateFormValues } from '../pages/estates-edit-form/types';
import { InsurersCodeEnum } from '../enums/insurers-code.enum';
import { formatAmount } from './masks';
import { GetBondInsuranceProposalsListResponseDTO } from '../dtos/bond-insurance-proposal-list/get-bond-insurance-proposals-list-response-dto';
import { GaranttiTaxesClientData } from '../pages/estates-edit-form/components/modal/types';
import { EstatePreregisterState } from '../types/EstatePreregisterState';

export const list = async (): Promise<Array<EstateState>> => {
  let response;

  try {
    response = await get('/estates');
  } catch (e) {
    throw e;
  }

  return response;
};

export const getEstate = async (
  estateId: string
): Promise<EstateForGetEstatesResponseDTO> => {
  let response;

  try {
    response = await get(`/estates/${estateId}`);
  } catch (e) {
    throw e;
  }

  return response;
};

export const getPreRegisterEstate = async (
  id: string
): Promise<PreRegisterResponseDTO> => {
  let response;

  try {
    response = await get(`/estates/preregister/${id}`);
  } catch (e) {
    throw e;
  }

  return response;
};

export const getEstateToView = async (estateId: string): Promise<GetEstateToViewDTO> => {
  let response;

  try {
    response = await get(`/estates/${estateId}/view`);
  } catch (e) {
    throw e;
  }

  return response;
};

export const getEstateData = async (
  clientId: string
): Promise<GaranttiTaxesClientData> => {
  const endpoint = `/garantti-integration/taxes/${clientId}`;
  try {
    const response = await get(endpoint);
    return response;
  } catch (error) {
    throw new Error('Erro ao buscar dados da imobiliária na Garantti');
  }
};

export const getEstatesByClaimResponsibleId = async (
  claimResponsibleId: string
): Promise<EstateForGetEstatesResponseDTO[]> => {
  let response;

  try {
    response = await get(`/estates/by-claim-responsible/${claimResponsibleId}`);
  } catch (e) {
    throw e;
  }

  return response;
};

export const getClassification = (value: number | undefined) => {
  console.log('EI', value);
  if (value === undefined) {
    return 'BRONZE';
  }

  if (value < 100) {
    return 'BRONZE';
  } else if (value < 200) {
    return 'SILVER';
  } else if (value < 300) {
    return 'GOLD';
  } else {
    return 'DIAMOND';
  }
};

export const getEstateObjectFromGetEstateDTO = (
  data: EstateForGetEstatesResponseDTO
): EstateState => {
  const estateObject: EstateState = {
    id: data.id,
    name: data.name,
    company_name: data.company_name,
    cnpj: data.cnpj,
    creci: data.creci ? data.creci : undefined,
    batchClosingDay: data.batch_closing_day,
    profitShare: data.profit_share,
    multiplier: data.multiplier,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    email: data.email,
    calculationMode: data.calculation_mode,
    calculationType: data.calculation_type,
    fireInsurers: data.fire_insurers.map(fireInsurer => {
      return {
        id: fireInsurer.id,
        code: fireInsurer.code,
        name: fireInsurer.name,
        imageUrl: fireInsurer.image
      };
    }),
    insurers: data.insurers.map(insurer => {
      return { id: insurer.id, code: insurer.code };
    }),
    capitalizations: data?.capitalizations.map(capitalization => {
      return {
        id: capitalization.id,
        code: capitalization.code,
        name: capitalization.name,
        imageUrl: capitalization.image
      };
    }) || [],
    user: {
      id: data.user.id,
      fid: data.user.fid,
      refreshToken: data.user.refresh_token,
      idToken: data.user.id_token,
      name: data.user.name,
      status: data.user.status,
      document: data.user.doc,
      cellphone: data.user.cellphone,
      email: data.user.email,
      role: data.user.role
    },
    insurerSpecificData: [],
    insurer_data: []
  };

  if (data.bank_account) {
    estateObject.bankAccount = {
      accountDv: data.bank_account.account_dv,
      accountNumber: data.bank_account.account_number,
      agencyDv: data.bank_account.agency_dv,
      agencyNumber: data.bank_account.agency_number,
      bankCode: data.bank_account.bank_code
    };
  }

  if (data.insurer_data && data.insurer_data.length > 0) {
    estateObject.insurerSpecificData = data.insurer_data.map(insurerSpecificData => {
      return {
        id: insurerSpecificData.id,
        estateId: data.id,
        insurerId: insurerSpecificData.insurer.id,
        insuranceType: insurerSpecificData.insurance_type,
        offerLetter: insurerSpecificData.offer_letter,
        commissionPercentage: formatAmount(
          (parseFloat(insurerSpecificData.commission_percentage) * 100).toFixed(2)
        ),
        pfResidentialTax: formatAmount(
          (parseFloat(insurerSpecificData.pf_residential_tax) * 100).toFixed(2)
        ),
        pfComercialTax: formatAmount(
          (parseFloat(insurerSpecificData.pf_comercial_tax) * 100).toFixed(2)
        ),
        lmi: insurerSpecificData.lmi,
        garanttiPlan: insurerSpecificData.garantti_plan
        // porto12XTax: formatAmount((parseFloat(insurerSpecificData.porto_12x_tax) * 100).toFixed(2)),
        // porto18XTax: formatAmount((parseFloat(insurerSpecificData.porto_18x_tax) * 100).toFixed(2)),
      };
    });
  }

  return estateObject;
};

export const getPartialEstateFormValuesFromGetEstateDTO = (
  estateDTO: EstateForGetEstatesResponseDTO
): Partial<UpdateEstateFormValues> => {
  const formObject: Partial<UpdateEstateFormValues> = {
    name: estateDTO.name,
    observations: estateDTO.observations,
    email: estateDTO.email,
    company_name: estateDTO.company_name,
    cnpj: estateDTO.cnpj,
    creci: estateDTO.creci ? estateDTO.creci : undefined,
    franchiseId: estateDTO.franchise_id ? estateDTO.franchise_id : undefined,
    partners: estateDTO.partners,

    hasPreferenceBondInsurance: estateDTO.has_preference_bond_insurance,

    estateZipcode: estateDTO.estate_zip_code,
    estateState: estateDTO.estate_state,
    estateCity: estateDTO.estate_city,
    estateDistrict: estateDTO.estate_district,
    estateStreet: estateDTO.estate_street,
    estateNumber: estateDTO.estate_number,
    estateComplement: estateDTO.estate_complement,

    responsibleName: estateDTO.responsible_name ? estateDTO.responsible_name : undefined,
    responsibleEmail: estateDTO.responsible_email
      ? estateDTO.responsible_email
      : undefined,
    responsiblePhone: estateDTO.responsible_phone
      ? estateDTO.responsible_phone
      : undefined,

    financialResponsible: estateDTO.financial_responsible,
    financialEmail: estateDTO.financial_email,
    status: estateDTO.status,
    contactSource: estateDTO.contact_source,

    responsible_company: estateDTO.responsible_company,

    additionalEmails: estateDTO.additional_emails,
    notificationEmails: estateDTO.notification_emails,
    portoCode: estateDTO.porto_code,
    garanttiCode: estateDTO.garantti_code,

    averagePropertiesWallet: estateDTO.average_properties_wallet,
    classification: estateDTO.classification,
    monthlyTenantryCapacity: estateDTO.monthly_tenantry_capacity,

    estateAnalystUser: {
      id: estateDTO.user.id,
      name: estateDTO.user.name
    },

    claimResponsible: {
      id: estateDTO.claim_admin ? estateDTO.claim_admin.id : '',
      name: estateDTO.claim_admin ? estateDTO.claim_admin.name : ''
    },

    additionalAccountManager: {
      id: estateDTO.additional_account_manager
        ? estateDTO.additional_account_manager.id
        : '',
      name: estateDTO.additional_account_manager
        ? estateDTO.additional_account_manager.name
        : ''
    },

    bankAccount: {
      id: estateDTO.bank_account?.id ? estateDTO.bank_account?.id : '',
      accountDv: estateDTO.bank_account?.account_dv
        ? estateDTO.bank_account?.account_dv
        : '',
      accountNumber: estateDTO.bank_account?.account_number
        ? estateDTO.bank_account?.account_number
        : '',
      agencyDv: estateDTO.bank_account?.agency_dv
        ? estateDTO.bank_account?.agency_dv
        : '',
      agencyNumber: estateDTO.bank_account?.agency_number
        ? estateDTO.bank_account?.agency_number
        : '',
      bankCode: estateDTO.bank_account?.bank_code ? estateDTO.bank_account?.bank_code : ''
    },
    bondInsurers: estateDTO.insurers.map(bondInsurer => {
      return {
        id: bondInsurer.id,
        name: bondInsurer.name,
        code: bondInsurer.code,
        imageURL: bondInsurer.image
      };
    }),
    fireInsurers: estateDTO.fire_insurers.map(fireInsurer => {
      return {
        id: fireInsurer.id,
        name: fireInsurer.name,
        code: fireInsurer.code,
        imageURL: fireInsurer.image
      };
    }),
    capitalizations: estateDTO?.capitalizations?.map(insurer => {
      return {
        id: insurer.id,
        name: insurer.name,
        code: insurer.code,
        imageURL: insurer.image
      };
    }) || [],
    isLibertyFireInsuranceSelected: false,
    isTooSegurosBondInsuranceSelected: false,
    isTokioMarineBondInsuranceSelected: false,
    isGaranttiBondInsuranceSelected: false,
    isPortoEssencialBondInsuranceSelected: false,
    isPortoTradicionalBondInsuranceSelected: false,
    isPottencialFireInsuranceSelected: estateDTO.fire_insurers.find(
      fireInsurer => fireInsurer.code === InsurersCodeEnum.Pottencial
    )
      ? true
      : false
  };

  formObject.insurerSpecificData = {
    liberty: {
      fireInsurance: {
        tableId: '',
        offerLetter: ''
      }
    },
    tooSeguros: {
      bondInsurance: {
        tableId: '',
        commissionPercentage: '',
        orderPreference: 0,
        pfResidentialTax: '',
        pfComercialTax: '',
        lmi: 0,
        assurance: undefined
      }
    },
    tokioMarine: {
      bondInsurance: {
        tableId: '',
        commissionPercentage: '',
        orderPreference: 0,
        pfResidentialTax: '',
        pfComercialTax: '',
        lmi: 0,
        assurance: undefined
      }
    },
    garantti: {
      bondInsurance: {
        tableId: '',
        pfResidentialTax: '',
        pfComercialTax: '',
        lmi: 0,
        garanttiPlan: '',
        garanttiBonus: false,
        orderPreference: 0,
        commissionPercentage: ''
      }
    },
    pottencial: {
      bondInsurance: {
        tableId: '',
        multiple: 0,
        assurance: undefined,
        orderPreference: 0,
        pfResidentialTax: '',
        pfComercialTax: '',
        lmi: 0,
        commissionPercentage: ''
      },
      fireInsurance: {
        tableId: '',
        multiplier: 200,
        commissionPercentage: 40
      }
    },
    portoEssencial: {
      bondInsurance: {
        tableId: '',
        orderPreference: 0,
        pfResidentialTax: '',
        pfComercialTax: '',
        lmi: 0,
        assurance: undefined,
        commissionPercentage: ''
      }
    },
    portoTradicional: {
      bondInsurance: {
        tableId: '',
        orderPreference: 0,
        pfResidentialTax: '',
        pfComercialTax: '',
        lmi: 0,
        assurance: undefined,
        commissionPercentage: ''
      }
    }
  };

  for (const specificData of estateDTO.insurer_data) {
    if (specificData.insurer.code === InsurersCodeEnum.Liberty) {
      if (specificData.insurance_type === 'FIRE_INSURANCE') {
        formObject.insurerSpecificData.liberty.fireInsurance = {
          tableId: specificData.id,
          offerLetter: specificData.offer_letter
        };
      }
    }

    if (specificData.insurer.code === InsurersCodeEnum.Too) {
      if (specificData.insurance_type === 'BOND_INSURANCE') {
        formObject.insurerSpecificData.tooSeguros.bondInsurance = {
          tableId: specificData.id,
          commissionPercentage: (
            parseFloat(specificData.commission_percentage) * 100
          ).toFixed(2),
          orderPreference: specificData.order_preference,
          pfResidentialTax: (parseFloat(specificData.pf_residential_tax) * 100).toFixed(
            2
          ),
          pfComercialTax: (parseFloat(specificData.pf_comercial_tax) * 100).toFixed(2),
          lmi: specificData.lmi,
          assurance: specificData.assurance
        };
      }
    }

    if (specificData.insurer.code === InsurersCodeEnum.TokioMarine) {
      if (specificData.insurance_type === 'BOND_INSURANCE') {
        formObject.insurerSpecificData.tokioMarine.bondInsurance = {
          tableId: specificData.id,
          commissionPercentage: (
            parseFloat(specificData.commission_percentage) * 100
          ).toFixed(2),
          orderPreference: specificData.order_preference,
          pfResidentialTax: (parseFloat(specificData.pf_residential_tax) * 100).toFixed(
            2
          ),
          pfComercialTax: (parseFloat(specificData.pf_comercial_tax) * 100).toFixed(2),
          lmi: specificData.lmi,
          assurance: specificData.assurance
        };
      }
    }
    if (specificData.insurer.code === InsurersCodeEnum.Garantti) {
      if (specificData.insurance_type === 'BOND_INSURANCE') {
        formObject.insurerSpecificData.garantti.bondInsurance = {
          tableId: specificData.id,
          pfResidentialTax: (parseFloat(specificData.pf_residential_tax) * 100).toFixed(
            2
          ),
          pfComercialTax: (parseFloat(specificData.pf_comercial_tax) * 100).toFixed(2),
          lmi: specificData.lmi,
          garanttiPlan: specificData.garantti_plan,
          garanttiBonus: specificData.garantti_bonus,
          orderPreference: specificData.order_preference,
          commissionPercentage: (
            parseFloat(specificData.commission_percentage) * 100
          ).toFixed(2)
        };
      }
    }

    if (specificData.insurer.code === InsurersCodeEnum.PortoEssencial) {
      if (specificData.insurance_type === 'BOND_INSURANCE') {
        formObject.insurerSpecificData.portoEssencial.bondInsurance = {
          tableId: specificData.id,
          orderPreference: specificData.order_preference,
          pfResidentialTax: (parseFloat(specificData.pf_residential_tax) * 100).toFixed(
            2
          ),
          pfComercialTax: (parseFloat(specificData.pf_comercial_tax) * 100).toFixed(2),
          lmi: specificData.lmi,
          commissionPercentage: (
            parseFloat(specificData.commission_percentage) * 100
          ).toFixed(2),
          assurance: specificData.assurance
        };
      }
    }

    if (specificData.insurer.code === InsurersCodeEnum.Porto) {
      if (specificData.insurance_type === 'BOND_INSURANCE') {
        formObject.insurerSpecificData.portoTradicional.bondInsurance = {
          tableId: specificData.id,
          orderPreference: specificData.order_preference,
          pfResidentialTax: (parseFloat(specificData.pf_residential_tax) * 100).toFixed(
            2
          ),
          pfComercialTax: (parseFloat(specificData.pf_comercial_tax) * 100).toFixed(2),
          lmi: specificData.lmi,
          commissionPercentage: (
            parseFloat(specificData.commission_percentage) * 100
          ).toFixed(2),
          assurance: specificData.assurance
        };
      }
    }

    if (specificData.insurer.code === InsurersCodeEnum.Pottencial) {
      if (specificData.insurance_type === 'BOND_INSURANCE') {
        formObject.insurerSpecificData.pottencial.bondInsurance = {
          tableId: specificData.id,
          assurance: specificData.assurance,
          multiple: specificData.multiple,
          orderPreference: specificData.order_preference,
          pfResidentialTax: (parseFloat(specificData.pf_residential_tax) * 100).toFixed(
            2
          ),
          pfComercialTax: (parseFloat(specificData.pf_comercial_tax) * 100).toFixed(2),
          lmi: specificData.lmi,
          commissionPercentage: Number(
            parseFloat(specificData.commission_percentage) * 100
          ).toFixed(2)
        };
      }
    }

    if (specificData.insurer.code === InsurersCodeEnum.Pottencial) {
      if (specificData.insurance_type === 'FIRE_INSURANCE') {
        formObject.insurerSpecificData.pottencial.fireInsurance = {
          tableId: specificData.id,
          commissionPercentage: Number(specificData.commission_percentage),
          multiplier: Number(specificData.multiplier)
        };
      }
    }

    if (specificData.insurer.code === InsurersCodeEnum.alfa) {
      if (specificData.insurance_type === 'FIRE_INSURANCE') {
        if (!formObject.insurerSpecificData.alfa) {
          formObject.insurerSpecificData.alfa = {};
      }
        (formObject.insurerSpecificData as any).alfa.fireInsurance = {
          brokerCommission: estateDTO.alfaSegurosConfigs?.brokerCommission,
          insurerId: estateDTO.alfaSegurosConfigs?.insurerId,
          realEstateCode: estateDTO.alfaSegurosConfigs?.code,
          login: estateDTO.alfaSegurosConfigs?.username,
          password: estateDTO.alfaSegurosConfigs?.password,
          proLabore: estateDTO.alfaSegurosConfigs?.compensation,
          lotClosingDay: estateDTO.alfaSegurosConfigs?.closingDay,
          residentialSurcharge: estateDTO.alfaSegurosConfigs?.residentialGrievance,
          transferBy: estateDTO.alfaSegurosConfigs?.transferBy,
          commercialSurcharge: estateDTO.alfaSegurosConfigs?.commercialGrievance,
          residentialDiscount: estateDTO.alfaSegurosConfigs?.residentialDiscount,
          commercialDiscount: estateDTO.alfaSegurosConfigs?.commercialDiscount,
          plans: estateDTO.alfaSegurosConfigs?.plans,
          coverages: estateDTO.alfaSegurosConfigs?.coverages,
          installmentOptions: estateDTO.alfaSegurosConfigs?.installmentOptions?.split(',').map((option: string) => Number(option.trim()))
        };
      }
    }
  }

  for (const fireInsurer of estateDTO.fire_insurers) {
    if (fireInsurer.code === InsurersCodeEnum.Liberty) {
      formObject.isLibertyFireInsuranceSelected = true;
    }
  }

  for (const bondInsurer of estateDTO.insurers) {
    if (bondInsurer.code === InsurersCodeEnum.Too) {
      formObject.isTooSegurosBondInsuranceSelected = true;
    }

    if (bondInsurer.code === InsurersCodeEnum.TokioMarine) {
      formObject.isTokioMarineBondInsuranceSelected = true;
    }

    if (bondInsurer.code === InsurersCodeEnum.Garantti) {
      formObject.isGaranttiBondInsuranceSelected = true;
    }

    if (bondInsurer.code === InsurersCodeEnum.Pottencial) {
      formObject.isPottencialBondInsuranceSelected = true;
    }

    if (bondInsurer.code === InsurersCodeEnum.Porto12) {
      formObject.isPorto12xBondInsuranceSelected = true;
    }

    if (bondInsurer.code === InsurersCodeEnum.Porto18) {
      formObject.isPorto18xBondInsuranceSelected = true;
    }

    if (bondInsurer.code === InsurersCodeEnum.Porto20) {
      formObject.isPorto20xBondInsuranceSelected = true;
    }

    if (bondInsurer.code === InsurersCodeEnum.Porto30) {
      formObject.isPorto30xBondInsuranceSelected = true;
    }

    if (bondInsurer.code === InsurersCodeEnum.PortoEssencial) {
      formObject.isPortoEssencialBondInsuranceSelected = true;
    }

    if (bondInsurer.code === InsurersCodeEnum.Porto) {
      formObject.isPortoTradicionalBondInsuranceSelected = true;
    }
  }

  return formObject;
};

export const getUsers = async (estateId: string): Promise<Array<UserState>> => {
  let response;

  try {
    response = await get(`/estates/${estateId}/users`);
  } catch (e) {
    throw e;
  }

  return response;
};

export const getFilteredUsers = async (
  estateId: string,
  page: number,
  filter?: any[]
): Promise<Array<UserState>> => {
  let query = '';
  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  let response;

  try {
    response = await get(`/estates/${estateId}/users_filtered?${query}&page=${page}`);
  } catch (e) {
    console.error(e);
    response = [];
  }
  return response;
};

export const getEstateBondInsuranceProposals = async (
  estateId: string,
  page: number,
  filter?: any
): Promise<GetBondInsuranceProposalsListResponseDTO> => {
  let response: GetBondInsuranceProposalsListResponseDTO;
  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        if (query) {
          query += `&${map[0]}=${map[1]}`;
        } else {
          query += `${map[0]}=${map[1]}`;
        }
      }
    });
  }

  if (query) {
    query += `&page=${page}`;
  } else {
    query += `page=${page}`;
  }

  try {
    response = await get(`/bond-insurance/proposals/?${query}`);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const inactiveEstate = async (
  estateId: string,
  userId: string
): Promise<boolean> => {
  let response = null;
  try {
    response = await put(`/estates/${estateId}/inactive`, { userId });
  } catch (e) {
    throw e;
  }

  if (response === null) {
    return false;
  }

  return true;
};

export const activeEstate = async (
  estateId: string,
  userId: string
): Promise<boolean> => {
  let response = null;
  try {
    response = await put(`/estates/${estateId}/active`, { userId });
  } catch (e) {
    throw e;
  }

  if (response === null) {
    return false;
  }

  return true;
};

export const getEstatesPreregisterList = async (filter?: any): Promise<any> => {
  let response;
  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  try {
    response = await get(`/estates/preregister?${query}`);
  } catch (e) {
    throw e;
  }

  return response;
};

export const getEstatePreregisterById = async (
  id: string
): Promise<EstatePreregisterState> => {
  let response;
  try {
    response = await get(`/estates/preregister/${id}`);
  } catch (e) {
    throw e;
  }

  return response;
};

export const createEstatePreregister = async (
  estate: EstatePreregisterState
): Promise<EstatePreregisterState> => {
  let response;
  try {
    response = await post(`/estates/preregister`, estate);
  } catch (e) {
    throw e;
  }

  return response;
};

export const updateEstatePreregisterById = async (
  id: string,
  estate: EstatePreregisterState
): Promise<EstatePreregisterState> => {
  let response;
  try {
    response = await put(`/estates/preregister/${id}`, estate);
  } catch (e) {
    throw e;
  }

  return response;
};
