import React from 'react';

import { Image, Grid } from 'semantic-ui-react';

import styled from 'styled-components';
import image from '../resources/imgs/404.png';

const TitleContainer = styled.div`
  h1 {
    color: #4369FA;
    font-size: 30px;
  }
  h4 {
    font-size: 14px;
  }

  .text {
    margin-top: 10px;
  }
`;

const PageNotFound: React.FC = () => {
  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <TitleContainer>
              <div>
                <h1>Ops... Desculpa!</h1>
              </div>
              <div className="text">
                <h4>Essa página está em construção no momento!</h4>
              </div>
              <div>
                <h4>Estamos fazendo o nosso melhor e voltaremos em breve.</h4>
              </div>
            </TitleContainer>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={12}>
            <Image src={image} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default PageNotFound;
