import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Button, Checkbox, Dimmer, Dropdown, DropdownProps, Grid, Header, Input, InputOnChangeData, Loader, Modal } from 'semantic-ui-react';

import { FieldLabel } from '../../../../styles';
import { dangerNotification, successNotification, warningNotification } from '../../../../services/notification';
import { cancelAlfaSegurosProposal, cancelGeralProposal, cancelPortoSeguroProposal, cancelSegImobProposal, getRefundInfo } from '../../../../services/fire-insurance-proposal';
import { ObservationsText } from './styles';
import { CancelPolicyModalInterface } from './interfaces/cancel-policy.interface';
import { CANCELLATION_REASON_OPTIONS } from '../../utils/Segimob';
import { InsurersCodeEnum } from '../../../../enums/insurers-code.enum';
import { isValidEmail } from '../../../../utils/emailValidator';
import { DATE_INPUT_MASK, numberToMoneyString } from '../../../../services/masks';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import AppMaskedInput from '../../../../components/MaskedInput';


const CancelPolicyModal = ({ open, close, policyId, insurerCode }: CancelPolicyModalInterface) => {
  const history = useHistory();

  const [reason, setReason] = useState<string>('');
  const [emails, setEmails] = useState<string>('');
  const [observations, setObservations] = useState<string>('');
  const [hasRefund, setHasRefund] = useState<boolean>(false);
  const [refundValue, setRefundValue] = useState<number>(0);
  const [expectedPaymentDate, setExpectedPaymentDate] = useState<Date | undefined>(undefined);
  const [checkBox, setCheckBox] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCanceling, setIsCanceling] = useState<boolean>(false);
  const [reasonHasBeenSelected, setReasonHasBeenSelected] = useState<boolean>(false);

  const handleOnChangeReason = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    setReason(data.value as string);
    setReasonHasBeenSelected(data.value !== '');
  }

  const handleOnChangeEmail = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setEmails(data.value as string);
  }

  const handleOnChangeObservation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObservations(event.target.value);
  }

  const toggleCheckBox = () => {
    setCheckBox((prev) => !prev);
  }

  const handleOnClickClose = () => {
    setReason('');
    setObservations('');
    setHasRefund(false);
    setRefundValue(0);
    setExpectedPaymentDate(undefined);
    close();
  }

  const handleOnClickConfirm = async () => {
    setIsCanceling(true);

    try {
      if (!reason.length) {
        setReasonHasBeenSelected(false);
        setIsCanceling(false);

        return warningNotification(
          'Opps...',
          'Selecione o motivo do cancelamento'
        );
      }

      const notificationEmails = emails.replace(/ /g, '');
      const emailsValidation = notificationEmails.split(',');

      for (const email of emailsValidation) {
        if (!isValidEmail(email)) {
          return warningNotification(
            'Opps...',
            'Por favor, digite um E-mail válido.'
          );
        }
      }

      if (!policyId && !insurerCode) {
        return dangerNotification(
          'Erro',
          'Não foi possível solicitar o cancelamento.'
        );
      }

      const payload = {
        policyId,
        notificationEmails,
        reason,
        refundValue,
        expectedPaymentDate,
        observations,
      };

      let response: any;

      switch (insurerCode) {
        case InsurersCodeEnum.Porto:
          response = await cancelPortoSeguroProposal(payload);

          if (response) {
            if (response.status === 200) {
              successNotification(
                'Sucesso',
                'Solicitação de cancelamento criada com sucesso.'
              );
            }

            if (response.status === 400) {
              warningNotification(
                'Opps...',
                'Erro ao solicitar o cancelamento'
              );
            }
          }

          break;
        // case InsurersCodeEnum.TokioMarineSegimob:
        //   response = await cancelSegImobProposal(payload);

        //   if (response) {
        //     if (response.status === 200) {
        //       successNotification(
        //         'Sucesso',
        //         'Cancelamento efetuado com sucesso.'
        //       );
        //     }

        //     if (response.status === 201) {
        //       successNotification(
        //         'Sucesso',
        //         'Solicitação de cancelamento criada com sucesso.'
        //       );
        //     }

        //     if (response.status === 400) {
        //       warningNotification(
        //         'Opps...',
        //         'Erro ao solicitar o cancelamento.'
        //       );
        //     }
        //   }

        //   break;
        case InsurersCodeEnum.alfa:
          response = await cancelAlfaSegurosProposal(payload);

          if (response) {
            if (response.status === 200) {
              successNotification(
                'Sucesso',
                'Solicitação de cancelamento criada com sucesso.'
              );
            }

            if (response.status === 400) {
              warningNotification(
                'Opps...',
                'Erro ao solicitar o cancelamento'
              );
            }
          }

          break;
        default:
          response = await cancelGeralProposal(payload);

          if (response) {
            if (response.status === 200) {
              successNotification(
                'Sucesso',
                'Cancelamento efetuado com sucesso.'
              );
            }

            if (response.status === 201) {
              successNotification(
                'Sucesso',
                'Solicitação de cancelamento criada com sucesso.'
              );
            }

            if (response.status === 400) {
              warningNotification(
                'Opps...',
                'Erro ao solicitar o cancelamento.'
              );
            }
          }

          break;
      };

      history.push('/admin/fire-insurance-policies');
    } catch (error) {
      console.log(error);
      dangerNotification(
        'Erro',
        'Não foi possível solicitar o cancelamento.'
      );
    } finally {
      setIsCanceling(false);
    }
  }

  const getRefundData = async (policyId: string) => {
    setIsLoading(true);

    try {
      const response = await getRefundInfo(policyId);

      if (response) {
        response?.hasRefund && setHasRefund(response?.hasRefund);
        response?.refundValue && setRefundValue(Number(response.refundValue));
        response?.expectedPaymentDate && setExpectedPaymentDate(new Date(response.expectedPaymentDate));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    policyId && getRefundData(policyId);
  }, [policyId]);

  return (
    <Modal
      open={open}
      onClose={close}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Header icon='trash alternate' content='Cancelar apólice de incêndio' />

      <Modal.Content scrolling>
        <Dimmer active={isLoading || isCanceling} inverted>
          <Loader />
        </Dimmer>

        <Grid style={{padding: 5}} columns={'equal'}>
          <Grid.Row>
            <Grid.Column>
              <FieldLabel required>Motivo</FieldLabel>
              <Dropdown
                fluid
                search
                clearable
                selection
                options={CANCELLATION_REASON_OPTIONS}
                value={reason}
                onChange={handleOnChangeReason}
                placeholder='Selecione o motivo do cancelamento'
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <FieldLabel required>E-mails para notificação</FieldLabel>
              <Input
                fluid
                type='email'
                value={emails}
                onChange={handleOnChangeEmail}
              />
              <label>Os e-mails devem ser separados por vírgula.</label>
              </Grid.Column>
          </Grid.Row>

          {hasRefund && (
            <>
              <Grid.Row>
                <Grid.Column>
                  <FieldLabel required>Valor da restituição</FieldLabel>
                  <Input
                    fluid
                    disabled
                    value={numberToMoneyString(refundValue ?? 0)}
                  />
                </Grid.Column>

                <Grid.Column>
                  <FieldLabel required>Data limite do pagamento</FieldLabel>
                  <CustomDatePicker
                    fluid
                    disabled
                    type={'date'}
                    placeholder={'Data limite do pagamento'}
                    selected={expectedPaymentDate}
                    customInput={<AppMaskedInput mask={DATE_INPUT_MASK} />}
                    dateFormat={'dd/MM/yyyy'}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row style={{ margin: 0, padding: 0 }}>
                <Grid.Column>
                  <FieldLabel>
                    * Os valores podem sofrer alterações conforme período proporcional aos dias corridos para o cálculo do valor a ser restituído.
                  </FieldLabel>
                </Grid.Column>
              </Grid.Row>
            </>
          )}

          <Grid.Row>
            <Grid.Column>
              <FieldLabel>Observações</FieldLabel>
              <ObservationsText
                fluid={'true'}
                rows={8}
                value={observations}
                onChange={handleOnChangeObservation}
              />
              </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Checkbox
                label={
                  <label>
                    <b>Estou ciente que esta operação é irreversível.</b> Ao optar por cancelar uma apólice de incêndio,
                    a seguradora vai suspender as coberturas e não será possível reversão, caso ocorra desistência,
                    deverá ser feita uma nova contratação.
                  </label>
                }
                onChange={toggleCheckBox}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>

      <Modal.Actions>
        <Button
          color='red'
          disabled={isCanceling}
          onClick={handleOnClickClose}
        >
          Cancelar
        </Button>
        <Button
          color='green'
          loading={!reasonHasBeenSelected && isCanceling}
          disabled={!checkBox || !reasonHasBeenSelected || isCanceling}
          onClick={handleOnClickConfirm}
        >
          Confirmar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CancelPolicyModal;
