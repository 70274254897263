import styled from 'styled-components'

export const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border: 1px solid #666;
  border-radius: 4px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 100%;
`

export const FileWrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin: 1rem;
  width: 100%;
  border: 1px solid #666;
  border-radius: 4px;
`

export const WrapperFiles = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border: 1px solid #666;
  border-radius: 4px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 100%;
`

export const InputFile = styled.input`
  display: none;
`
export const FileLine = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0.2rem 2rem;
  width: 100%;
  p {
    font-size: 1rem;
    text-align: justify;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1rem;
  }

  p:nth-child(1){
    text-align: left;
  }
`
export const Icon = styled.div`
  width: 2rem;
  cursor: pointer;
  padding: 0 2px;
  margin: 0 1rem;
`
export const InputButton = styled.input`
  margin-top: 2rem;
  font-size: 1rem;
  padding: 0.8rem 1.2rem;
  background-color: #666;
  border-radius: 4px;
  color: white;
  border: 1px solid transparent;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: #666;
    border: 1px solid #666;
  }
`
export const Select = styled.select`
    background-position: center right;
    background-repeat: no-repeat;
    border: 1px solid #AAA;
    border-radius: 2px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    color: #555;
    font-size: inherit;
    margin: 0;
    overflow: hidden;
    padding-top: 2px;
    padding-bottom: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    outline: none;
`

export const LabelSavedFiles = styled.p`
width: 100%;
  text-align: left;
`
