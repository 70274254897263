import React, { useState, useEffect, FormEvent } from 'react';
import Loader from '../../components/Loader';
import { connect } from 'react-redux';
import { Formik, FormikProps, FormikErrors } from 'formik';
import { fireInsuranceBudgetValidationSchema } from './validators';
import { BudgetProps, FireInsuranceBudgetFormData, CoverageProps, sectionExceptions, SimulatorProps } from './types';
import {
  FireInsuranceCoverageState,
  ConnectorPropertiesForFireInsuranceQuotationState
} from '../../types/FireInsurance';
import {unmaskMoney, formatAmount, fixNumber, numberToMoneyString} from '../../services/masks';
import { quote, getQuoteParams } from '../../services/fire-insurance-quotation';
import { dangerNotification, successNotification } from '../../services/notification';
import styled from 'styled-components';
import { iRootState, iRootDispatch } from '../../store';
import { Grid, InputOnChangeData, LoaderProps, DropdownItemProps } from 'semantic-ui-react';
import { SectionProps } from '../../types';
import { allDataAreFilled } from '../../util';
import Budget from './components/Budget';
import Simulator from './components/Simulator';
import { initialSimulator, initialFireInsuranceBudget } from './constants';
import moment from 'moment-timezone';
import { PropertyTypeState } from '../../types/PropertyTypeState';
import {
  AvailableBrokersAndEstatesState,
  FormikStateActions,
  InsurersListState,
  SimulatorState
} from "../../store/types/temp-types";
import {FireInsuranceCreateQuotationRequestDTO} from "../../dtos/fire-insurance-budget/fire-insurance-create-quotation-request-dto";
import {FireInsuranceCoverageDTO} from "../../dtos/fire-insurance-budget/fire-insurance-coverage.dto";
import {
  FireInsuranceCreateQuotationResponseDTO,
  FireInsuranceQuotationInstallmentData
} from "../../dtos/fire-insurance-budget/fire-insurance-create-quotation-response-dto";

export type InstallmentValueToNominalValueComparison = "DISCOUNT" | "FEES" | "NONE";

const BudgetContainer = styled.div`
  height: 100%;
`;

type SimulatorContainerProps = {
  posY: number;
  isVisible: boolean;
};

export type QuotePayload = {
  insurerId: string;
  assistanceId: string;
  occupationId: string;
  propertyType: PropertyTypeState;
  rentValue: string;
  coverage: [];
}

const SimulatorContainer = styled.div`
  width: 100%;
  position: absolute;
  transition: opacity linear 0.5s;
  top: ${(props: SimulatorContainerProps) => props.posY > 10 ? (props.posY - 10) : 0}px;
  opacity: ${(props: SimulatorContainerProps) => props.isVisible ? 1 : 0};
`;

export const getSegimobInstallmentsOptions = (installments: number) => {
  const options = [];
  for (let i = 1; i <= installments; i++) {
    options.push({ value: i, key: i, text: (i > 1) ? i + " parcelas" : i + " parcela" } as DropdownItemProps);
  }
  return options;
};

export const getInstallmentsOptions = (orderedInstallmentsData: FireInsuranceQuotationInstallmentData[], nominalValue: number): DropdownItemProps[] => {
  const options = [];

  for (let i = 1; i <= orderedInstallmentsData.length; i++) {
    const evaluation: InstallmentValueToNominalValueComparison =
      checkForDiscountOrFees(orderedInstallmentsData[i-1], nominalValue);
    let additionalText = "";

    if (evaluation === "DISCOUNT") {
      additionalText = "(Com Desconto)";
    } else if (evaluation === "FEES") {
      additionalText = "(Com Juros)";
    }

    options.push({
      value: i,
      key: i,
      text: (i > 1) ?
        `${i} parcelas de R$${numberToMoneyString(orderedInstallmentsData[i-1].totalPremiumAmount)} ${additionalText}`
        :
        `${i} parcela de R$${numberToMoneyString(orderedInstallmentsData[i-1].totalPremiumAmount)} ${additionalText}`
    } as DropdownItemProps);
  }

  return options;
};

export function checkForDiscountOrFees(installmentData: FireInsuranceQuotationInstallmentData, nominalValue: number): InstallmentValueToNominalValueComparison {
  const valueTolerance = 0.05;
  const totalInstallmentChoiceAmount: number = installmentData.installmentsAmount * installmentData.totalPremiumAmount;

  if (Math.abs(nominalValue - totalInstallmentChoiceAmount) <= valueTolerance ) {
    return "NONE";
  }

  if ((nominalValue - totalInstallmentChoiceAmount) > 0) {
    return "DISCOUNT"
  } else {
    return "FEES"
  }
}

export const getNullInstallmentsOptions = (): DropdownItemProps[] => {
  const options = [];

    options.push({
      value: -1,
      key: -1,
      text: ""
    } as DropdownItemProps);

  return options;
};

export const isAbleToSimulate = (budget: BudgetProps, errors?: FormikErrors<any>) => {
/*  console.log('isAbleToSimulate');
  console.log(budget);*/
  const { insurance, property, owner, tenant } = budget;
  // if (errors) console.log("errors: ", errors);

/*  const insuranceTest = allDataAreFilled(insurance);
  const ownerTest = allDataAreFilled(owner, sectionExceptions['owner']);
  const tenantTest = allDataAreFilled(tenant, sectionExceptions['tenant']);
  const unmaskMoneyTest = unmaskMoney(budget.insurance.rentValue) > 0;
  const propertyTest = allDataAreFilled(property, sectionExceptions['property']);

  return insuranceTest && ownerTest && tenantTest && unmaskMoneyTest && propertyTest;*/

  return allDataAreFilled(insurance)
    && allDataAreFilled(property, sectionExceptions['property'])
    && allDataAreFilled(owner, sectionExceptions['owner'])
    && allDataAreFilled(tenant, sectionExceptions['tenant'])
    && unmaskMoney(budget.insurance.rentValue) > 0
    && !errors;
};

export const wasQuoted = (budget: BudgetProps, quotePayload: QuotePayload): boolean => {
  if (quotePayload
    && quotePayload.insurerId === budget.insurance.insurer.id
    && quotePayload.rentValue === budget.insurance.rentValue
    && quotePayload.assistanceId === budget.insurance.assistance.id
    && quotePayload.propertyType === budget.insurance.propertyType
    && quotePayload.occupationId === budget.insurance.occupation.id

    && (Object.values(budget.coverage).filter((c, i) => (c
      && quotePayload.coverage[i]
      && (quotePayload.coverage[i] as CoverageProps).damageAmount === c.damageAmount
      && (quotePayload.coverage[i] as CoverageProps).rent === c.rent
      && (quotePayload.coverage[i] as CoverageProps).idCoverage === c.idCoverage)
      && (quotePayload.coverage[i] as CoverageProps).isActive === c.isActive)
      .length === Object.values(budget.coverage).length)) {
    successNotification('Sucesso!', 'Simulação já realizada.');
    return true;
  }
  return false;
}

export const calculateCoverageDamage = (rentValue: number, estateMultiplier: number): Array<CoverageProps> => {
  const damageCoverage = rentValue * estateMultiplier;
  const rentCoverage = damageCoverage / 10;
  return [{
    damageAmount: formatAmount(damageCoverage.toFixed(2)),
    rent: formatAmount(rentCoverage.toFixed(2)),
    name: 'INCÊNDIO/RAIO/EXPLOSÃO',
    idCoverage: '0',
    idStateParamFireCoverage: '0',
    iof: '0',
    maxValue: '0',
    minValue: '0',
    netPrize: '0',
    totalPrize: '0',
    isActive: true,
  },
  {
    damageAmount: formatAmount(damageCoverage.toFixed(2)),
    rent: formatAmount(rentCoverage.toFixed(2)),
    name: 'PERDA OU PAGAMENTO ALUGUEL',
    idCoverage: '0',
    idStateParamFireCoverage: '0',
    iof: '0',
    maxValue: '0',
    minValue: '0',
    netPrize: '0',
    totalPrize: '0',
    isActive: true,
  }];
};

export const getQuotationParams = async (budget: BudgetProps, props: FireInsuranceBudgetFormProps) => {
  if(!budget?.insurance?.propertyType || !props.user) {
    return
  }

  try {
    const property = budget.insurance.propertyType as unknown as PropertyTypeState;
    const { user } = props;
    const { setFieldValue } = props;

    const response = await getQuoteParams(property.id, user.id);
    setFieldValue('budget.insurance.brokerPercent', response.brokerPercent);
    setFieldValue('budget.insurance.estatePercent', response.estatePercent);
  } catch (e) {
    console.log(e);
  }
}

export const submitQuote = async (
  budget: BudgetProps,
  simulator: SimulatorProps,
  props: FireInsuranceBudgetFormProps,
  brokerId: string
) => {
  const { setFieldValue, updateSimulatorFields } = props;

  try {
    const sanitizationRegex = new RegExp('[-/,.() ]+', 'g');
    const insurance = budget.insurance;
    const { user } = props;
    const coverages: FireInsuranceCoverageDTO[] = budget.coverage
      .filter((f) => f !== undefined)
      .map((c) => {
        const coverage: FireInsuranceCoverageDTO = {
          damage_amount: unmaskMoney(c.damageAmount).toString(),
          rent: c.rent,
          name: c.name,
          id_coverage: c.idCoverage,
          id_state_param_fire_coverage: c.idStateParamFireCoverage,
          iof: c.iof,
          max_value: c.maxValue,
          min_value: c.minValue,
          net_prize: c.netPrize,
          total_prize: c.totalPrize,
          is_active: c.isActive
        };

        return coverage
      })
      .filter((c) => c.id_coverage)
    const quotationQuery: FireInsuranceCreateQuotationRequestDTO = {
      coverage: coverages,
      rent_value: unmaskMoney(budget.insurance.rentValue).toString(),
      validity: simulator.validity.toString(),
      broker_id: brokerId,
      user_id: user.id,
      estate_id: insurance.estate.id,
      insurer_id: insurance.insurer.id,
      property_type: {
        id: insurance.propertyType.id,
        from_connector_table: insurance.propertyType.fromConnectorTable
      },
      occupation: {
        id: insurance.occupation.id,
        from_connector_table: insurance.occupation.fromConnectorTable
      },
      assistance_id: insurance.assistance.id,
      estate_percent: insurance.estatePercent || `${insurance.estate.profitShare}`,
      broker_percent: insurance.brokerPercent || `${insurance.broker.fee}`,
      initial_date: (moment(simulator.startDate)).format('YYYY-MM-DDT00:00:00.000'),
      cover_value: (Number(unmaskMoney(`${simulator.coverValue}`)) > 0) ? String(Number(unmaskMoney(`${simulator.coverValue}`))) : '',
      property_data: {
        zip_code: budget.property.zipCode.replace(sanitizationRegex, ''),
        address: budget.property.street,
        district: budget.property.district,
        city: budget.property.city,
        state: budget.property.state,
        address_number: budget.property.number,
        address_extra_information: budget.property.complement,
      },
      tenant_data: {
        name: budget.tenant.name,
        public_document_id: budget.tenant.document.replace(sanitizationRegex, ''),
        email: budget.tenant.email,
        tenant_telephone: budget.tenant.telephone ? budget.tenant.telephone.replace(sanitizationRegex, '') : undefined
      },
      owner_data: {
        name: budget.owner.name,
        public_document_id: budget.owner.document.replace(sanitizationRegex, ''),
        email: budget.owner.email
      },
      connector_coverages: []
    };

    if (props.selectedConnectorCoverages) {
      for (const connectorCoverage of props.selectedConnectorCoverages) {
        quotationQuery.connector_coverages?.push({
          connector_coverage_id: connectorCoverage.id,
          connector_coverage_name: connectorCoverage.name,
          insured_amount: connectorCoverage.insuredAmount
        })
      }
    }

    const quotation: FireInsuranceCreateQuotationResponseDTO
      = await quote(quotationQuery);

    if (quotation.connector_data) {
      props.updateConnectorData({
        quotationHeadId: quotation.connector_data.quotation_head_id,
        fluidResponseId: quotation.connector_data.fluid_response_id,
      })
    }

    const coverageCollection: FireInsuranceCoverageState[] = [];
    setFieldValue('simulator.value', quotation.value);
    setFieldValue('simulator.iof', quotation.iof);
    //fixMissingZerosNumber
    if (simulator.coverValue) {
      setFieldValue('simulator.coverValue', fixNumber(Number(quotation.value)));
      //simulator.cover_value = quotation.value;
    }

    setFieldValue('budget.insurance.brokerPercent', quotation.broker_percent);
    setFieldValue('budget.insurance.estatePercent', quotation.estate_percent);
    setFieldValue('simulator.insurerGain', quotation.insurer_gain);
    setFieldValue('simulator.confiaxComission', 0.7 - insurance.estate.profitShare - insurance.broker.fee);
    setFieldValue('budget.confiaxComission', 0.7 - insurance.estate.profitShare - insurance.broker.fee);
    quotation.coverage
      .filter((f) => f !== undefined)
      .map((c) => {
        c.damage_amount = parseFloat(c.damage_amount).toFixed(2).toString();
        c.rent = parseFloat(c.rent).toFixed(2).toString();

        const coverageState: FireInsuranceCoverageState = {
          name: c.name,
          damageAmount: c.damage_amount,
          rent: c.rent,
          idCoverage: c.id_coverage,
          idStateParamFireCoverage: c.id_state_param_fire_coverage,
          iof: c.iof,
          maxValue: c.max_value,
          minValue: c.min_value,
          netPrize: c.net_prize,
          totalPrize: c.total_prize,
          isActive: c.is_active
        }
        coverageCollection[Number(c.id_coverage)] = coverageState;
      });
    budget.coverage = coverageCollection;
    setFieldValue('budget.coverage', coverageCollection);

    const options = getInstallmentsOptions(quotation.installmentsData, quotation.value);

    setFieldValue('simulator.installmentsOptions', options);

    updateSimulatorFields({
      installmentsData: quotation.installmentsData
    });

    /*simulator.installmentsOptions = options;*/
    setFieldValue('simulator.installments', 1);
/*    simulator.installments = 1;*/
    return true;
  } catch (error) {
    const e = error as any;
    setFieldValue('simulator.installmentsOptions', getNullInstallmentsOptions());

    updateSimulatorFields({
      installmentsData: []
    });

    setFieldValue('simulator.installments', 0);

    if (typeof e.message === 'string') {
      dangerNotification('Erro!', e.message, 5000);
    } else if (Array.isArray(e.message) && e.message.length > 0) {
      e.message.forEach((errorMessageObject: any) => {
        dangerNotification('Erro!', errorMessageObject.description, 5000);
      })
    }
  }
}

export type FireInsuranceBudgetFormProps =
  FireInsuranceBudgetFormData
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & FormikProps<FireInsuranceBudgetFormData>
  & { updateSimulatorFields: (simulatorFields: any) => void};

export type FireInsuranceBudgetFormSectionProps =
  FireInsuranceBudgetFormProps
  & SectionProps
  & { handleInputChange: (_: any, data: InputOnChangeData) => void }
  & { setIsLoading: React.Dispatch<React.SetStateAction<boolean>> }
  & { quotePayload: QuotePayload }
  & { setQuotePayload: React.Dispatch<React.SetStateAction<QuotePayload>> };

const initialFormData = {
  budget: initialFireInsuranceBudget,
  simulator: initialSimulator
} as FireInsuranceBudgetFormData;

const mapStateToProps = (state: iRootState) => ({
  scroll: state.scroll,
  user: state.user,
  insurers: state.insurers,
  selectedConnectorCoverages: state.fireInsuranceBudget.selected.connectorCoverages,
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  isLoading: state.loading,
  selectedInsurerId: state.fireInsuranceBudget.selected.insurer.id,
  selectedBrokerId: state.fireInsuranceBudget.selected.broker.id
});

const mapDispatchToProps = (dispatch: iRootDispatch) => ({
  updateInsurersList: (insurers: InsurersListState) => dispatch.insurers.updateInsurersList(insurers),
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) => dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers),
  setLoading: (loading: boolean) => dispatch.loading.setLoading(loading),
  updateConnectorData: (payload: ConnectorPropertiesForFireInsuranceQuotationState) => dispatch.fireInsuranceBudget.updateConnectorData(payload),
  updateSimulatorFields: (simulatorFields: Partial<SimulatorState>) => dispatch.simulator.updateSimulatorFields(simulatorFields),
  setFormikActions: (actions: Partial<FormikStateActions>) =>  dispatch.formik.setFormikActions(actions)
});

const FireInsuranceBudgetForm = (props: FireInsuranceBudgetFormProps) => {
  const { scroll } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [quotationLoading, setQuotationLoading] = useState<boolean>(false);
  const [quotePayload, setQuotePayload] = useState<QuotePayload>({} as QuotePayload);

  const loaderProps = {
    active: isLoading
  } as LoaderProps

  return (
    <>
      <Loader {...loaderProps} />
      <Formik
        enableReinitialize
        initialValues={initialFormData}
        validationSchema={fireInsuranceBudgetValidationSchema}
        onSubmit={() => { }}
      >
        {innerProps => {
          const { setFieldValue, setFieldTouched, handleBlur } = innerProps;
          const { budget, simulator } = innerProps.values;
          const {setFormikActions} = props;

          const eProps = { ...props };
          eProps.setFieldValue = setFieldValue;
          setFormikActions({setFieldValue, setFieldTouched, handleBlur});

          const handleInputChange = (_: any, data: InputOnChangeData): void => {
            setFieldValue(`${data.field.name}`, data.value);
            setFieldTouched(`${data.field.name}`, true);
          };

          const onSubmit = async (e: FormEvent) => {
            e.preventDefault();

/*            if (!wasQuoted(budget, quotePayload)) {
              let temProps = { ...props };
              temProps.setFieldValue = setFieldValue;
              setQuotationLoading(true);
              let res = await submitQuote(budget, simulator, temProps);
              setQuotationLoading(false);

              if (res) {
                setQuotePayload({
                  insurerId: budget.insurance.insurer.id,
                  assistanceId: budget.insurance.assistance.id,
                  occupationId: budget.insurance.occupation.id,
                  propertyType: budget.insurance.propertyType,
                  rentValue: budget.insurance.rentValue,
                  coverage: Object.values(budget.coverage)
                } as QuotePayload);
              } else {
                dangerNotification('Erro', 'Não foi possível realizar a cotação do seguro');
              }
            }*/
            const temProps = { ...props };
            temProps.setFieldValue = setFieldValue;
            setQuotationLoading(true);
            const res = await submitQuote(budget, simulator, temProps, props.selectedBrokerId);
            setQuotationLoading(false);

            if (res) {
              setQuotePayload({
                insurerId: budget.insurance.insurer.id,
                assistanceId: budget.insurance.assistance.id,
                occupationId: budget.insurance.occupation.id,
                propertyType: budget.insurance.propertyType,
                rentValue: budget.insurance.rentValue,
                coverage: Object.values(budget.coverage)
              } as QuotePayload);

              successNotification('Sucesso', 'Cotação realizada com sucesso!');
            } else {
              dangerNotification('Erro', 'Não foi possível realizar a cotação do seguro');
            }
          }

          return (
            <form onSubmit={onSubmit}>
              <BudgetContainer>
                <Grid>
                  <Grid.Row>
                    <Budget
                      {...innerProps}
                      setIsLoading={setIsLoading}
                      handleInputChange={handleInputChange}
                      setQuotePayload={setQuotePayload}
                      quotePayload={quotePayload}
                      quotationLoading={quotationLoading} />
                    <Grid.Column width={5}>
                      <SimulatorContainer posY={scroll.posY} isVisible={props.selectedInsurerId ? true : false}>
                        <Simulator {...innerProps}
                          setIsLoading={setIsLoading}
                          setQuotePayload={setQuotePayload}
                          /*fireInsuranceBudgetFormProps={eProps}*/ handleInputChange={handleInputChange} />
                      </SimulatorContainer>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </BudgetContainer>
            </form>
          );
        }}
      </Formik>
    </>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(FireInsuranceBudgetForm);
