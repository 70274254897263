import {CustomDropDownOptions} from "../../types";
import {AvailableBrokersAndEstatesReducer, AvailableBrokersAndEstatesState} from "../types/temp-types";

type AvailableBrokersAndEstatesRematchModel = {
  state: AvailableBrokersAndEstatesState;
  reducers: AvailableBrokersAndEstatesReducer;
}

const availableBrokersAndEstates: AvailableBrokersAndEstatesRematchModel = {
  state: {
    brokers: Array<CustomDropDownOptions>(),
    estates: Array<CustomDropDownOptions>(),
    insurers: Array<CustomDropDownOptions>(),
    roles: Array<CustomDropDownOptions>(),
    franchises: Array<CustomDropDownOptions>(),
    bondInsurers: Array<CustomDropDownOptions>(),
  },
  reducers: {
    updateAvailableBrokersAndEstates: (state: AvailableBrokersAndEstatesState, payload: Partial<AvailableBrokersAndEstatesState>) => {
      return {
        ...state,
        ...payload
      };
    }
  }
};

export default availableBrokersAndEstates;
