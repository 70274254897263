import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Grid, Dropdown as DropdownNativo } from 'semantic-ui-react';
import { ProposalsFilterData } from '../types';
import Button from '../../../components/Button';
import { iRootDispatch, iRootState } from '../../../store';
import AppMaskedInput from '../../../components/MaskedInput';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { FieldLabel } from '../../../styles';
import Input from '../../../components/Input';
import BondInsuranceProposalsTable from './BondInsuranceProposalsTable';
import { getBrokerBondInsuranceProposals, getUsers } from '../../../services/broker';
import { sortBondInsuranceProposals } from '../../../services/bond-insurance-proposal';
import {
  AvailableBrokersAndEstatesState,
  InsurersListState
} from '../../../store/types/temp-types';
import { BondInsuranceProposalsListProps } from '../BondInsuranceProposalsForm';
import { CustoDropDownItemProps } from '../../../components/types';
import { dangerNotification } from '../../../services/notification';
import { ProposalForGetBondInsuranceProposalsListResponseDTO } from '../../../dtos/bond-insurance-proposal-list/interfaces/proposal-for-get-bond-insurance-proposals-list-response-dto';
import { GetBondInsuranceProposalsListResponseDTO } from '../../../dtos/bond-insurance-proposal-list/get-bond-insurance-proposals-list-response-dto';
import { UserForUpdateEstateForm } from '../../estates-list/types';
import { UsersToCustomDropDownOptions } from '../../../util';
import { InsurerState } from '../../../types/InsurerState';
import { useLocation } from 'react-router-dom';
import { UserRolesEnum } from '../../../enums/user-roles.enum';
import { InputCursorMove } from 'styled-icons/remix-editor';

interface LocationState {
  bondInsuranceId?: string;
}

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user,
  insurers: state.insurers
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(
      availableBrokers
    ),

  updateInsurersList: (insurers: InsurersListState) =>
    dispatch.insurers.updateInsurersList(insurers)
});

interface DropdownOptions {
  index: number;
  value: number | string;
  text: string;
}

const hiredDataStatusList: DropdownOptions[] = [
  {
    index: 0,
    value: 'Todos',
    text: 'Todos'
  },
  {
    index: 1,
    value: 'Contratação em Andamento',
    text: 'Contratação em Andamento'
  },
  {
    index: 2,
    value: 'Erro na Contratação',
    text: 'Erro na Contratação'
  },
  {
    index: 3,
    value: 'Contratação integrada em Andamento',
    text: 'Contratação integrada em Andamento'
  }
];

const BondInsuranceProposalsPage = (props: BondInsuranceProposalsListProps) => {
  const { id } = props.match.params;
  const location = useLocation();
  const { availableBrokersAndEstates, user, setFieldValue, values } = props;
  const brokers: CustoDropDownItemProps[] = availableBrokersAndEstates.brokers;
  const estates: CustoDropDownItemProps[] = availableBrokersAndEstates.estates;
  const [bondInsuranceProposals, setBondInsuranceProposals] = useState<
    ProposalForGetBondInsuranceProposalsListResponseDTO[]
  >([]);
  const insurers: InsurerState[] | null =
    props.availableBrokersAndEstates.bondInsurers.map((insurer: any) => insurer.value);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [ascendingOrder, setAscendingOrder] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataCount, setDataCount] = useState<number>(0);
  const [startFilter, setStartFilter] = useState<Date | null>();
  const [endFilter, setEndFilter] = useState<Date | null>();
  const [selectedEstate, setSelectedEstate] = useState<any>();
  const [cleanInputs, setCleanInputs] = useState(false);
  const [selectableStatus, setSelectableStatus] = useState<string>('Todos');
  const [status, setStatus] = useState<number>(0);
  const statusList: DropdownOptions[] = hiredDataStatusList;
  const bondInsuranceIdInitialValue = (location.state as LocationState)?.bondInsuranceId;
  const [bondInsuranceId, setBondInsuranceId] = useState<any>(
    bondInsuranceIdInitialValue
  );
  const selectableInsurers = insurers.map(insurer => ({
    index: insurer.id,
    value: insurer.id,
    text: insurer.name
  }));
  const [selectedInsurer, setSelectedInsurer] = useState<string>('');
  const [selectedAccountManager, setSelectedAccountManager] = useState<string | null>(
    null
  );
  const [isAccountManagerLoading, setIsAccountManagerLoading] = useState<boolean>(false);
  const [accountManagerOptions, setAccountManagerOptions] = useState<
    CustoDropDownItemProps[]
  >([]);

  const getAccountManagers = async () => {
    if (props.user.broker?.id) {
      setIsAccountManagerLoading(true);
      getUsers(props.user.broker.id, [{ isDropdown: true}])
        .then(result => {
          const convertedValues: UserForUpdateEstateForm[] = result.map((user: any) => {
            const userForForm: UserForUpdateEstateForm = {
              id: user.id,
              name: user.name
            };
            return userForForm;
          });

          const analystsOptions = UsersToCustomDropDownOptions(false, convertedValues);
          setAccountManagerOptions(analystsOptions);
        })
        .catch(error => {
          dangerNotification(
            'Oops...',
            'Não foi possível encontrar os analistas disponíveis'
          );
        })
        .finally(() => {
          setIsAccountManagerLoading(false);
        });
    }
  };

  const handleNewChangeAccountManager = (accountManagerKey: string) => {
    if (accountManagerKey !== '') {
      setSelectedAccountManager(accountManagerKey);
    } else {
      setSelectedAccountManager(null);
    }
  };

  useEffect(() => {
    if (accountManagerOptions.length === 0) {
      getAccountManagers();
    }
  }, [accountManagerOptions]);

  useEffect(() => {
    const newStart = startFilter ? startFilter.toISOString().substring(0, 10) : '';
    setFieldValue('start', newStart);
  }, [startFilter, endFilter]);

  useEffect(() => {
    const newEnd = endFilter ? endFilter.toISOString().substring(0, 10) : '';
    setFieldValue('end', newEnd);
  }, [endFilter]);

  useEffect(() => {
    const id = selectedEstate && selectedEstate !== '' ? selectedEstate.id : '';
    setFieldValue('estate_id', id);
  }, [selectedEstate]);

  useEffect(() => {
    setFieldValue('status', status);
  }, [status]);

  useEffect(() => {
    setFieldValue('insurer', selectedInsurer);
  }, [selectedInsurer]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case 'name':
        setFieldValue('name', value);
        break;
      case 'address':
        setFieldValue('address', value);
        break;
      case 'document':
        setFieldValue('document', value);
        break;
      case 'start':
        setFieldValue('start', value);
        break;
      case 'end':
        setFieldValue('end', value);
        break;
    }
  };

  const clearInputs = () => {
    setFieldValue('name', '');
    setFieldValue('address', '');
    setFieldValue('document', '');
    setFieldValue('start', '');
    setFieldValue('end', '');
    setFieldValue('estate_id', null);
    setFieldValue('insurer', '');
    setFieldValue('bondInsuranceId', undefined);
    setFieldValue('accountManagerId', undefined);
    setStatus(0);
    setSelectableStatus('Todos');
    setSelectedEstate(null);
    setSelectedInsurer('');
    setStartFilter(null);
    setEndFilter(null);
    setBondInsuranceId(undefined);
    setSelectedAccountManager(null);
    setCleanInputs(true);
  };

  useEffect(() => {
    cleanInputs && getData();
  }, [cleanInputs]);

  async function loadTotal(filter: Partial<ProposalsFilterData> ) {
    if(currentPage === 1)  {
      const data: GetBondInsuranceProposalsListResponseDTO = await getBrokerBondInsuranceProposals(currentPage, {...filter, action: "COUNT"});
      setDataCount(data.count)
    }
  }

  const getData = async () => {
    setIsLoading(true);
    try {
      const filter: Partial<ProposalsFilterData> = values;
      if (bondInsuranceId) {
        filter.bondInsuranceId = bondInsuranceId;
      }
      if (estates.length === 1) {
        filter.estate_id = estates[0].value.id;
      }
      if (brokers.length === 1) {
        filter.broker_id = brokers[0].value.id;
      }
      if (selectedAccountManager) {
        filter.accountManager = selectedAccountManager;
      }

      const { broker_id, ...rest } = filter;
      delete filter.broker_id;

      if (!id) {
        const data: GetBondInsuranceProposalsListResponseDTO =
          await getBrokerBondInsuranceProposals(currentPage, filter);

        const orderedProposals: ProposalForGetBondInsuranceProposalsListResponseDTO[] =
          data.proposals.length > 1
            ? sortBondInsuranceProposals(data.proposals, ascendingOrder)
            : data.proposals;

        loadTotal(filter)
        setBondInsuranceProposals(orderedProposals);
      }
      setBondInsuranceId(undefined);
      filter.bondInsuranceId = undefined;
      setFieldValue('bondInsuranceId', undefined);
      setIsLoading(false);
    } catch (e) {
      dangerNotification('Oops...', 'Não foi possível buscar as propostas');
    } finally {
      setIsLoading(false);
      setCleanInputs(false);
    }
  };

  useEffect(() => {
    window.history.replaceState(
      {
        ...(location.state as any),
        bondInsuranceId: undefined
      },
      ''
    );
  }, [location]);

  useEffect(() => {
    (values.broker_id || user.role) && getData();
  }, [currentPage]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setCurrentPage(1);
    getData();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid>
          <Grid.Row columns={4}>
            <Grid.Column>
              <FieldLabel>IMOBILIÁRIA:</FieldLabel>
              <div style={{ width: '100%' }}>
                <DropdownNativo
                  style={{ width: '100%' }}
                  value={selectedEstate}
                  name={`estate`}
                  defaultValue={
                    availableBrokersAndEstates.estates.length === 1
                      ? availableBrokersAndEstates.estates[0].value
                      : '' || ''
                  }
                  disabled={isLoading || availableBrokersAndEstates.estates.length === 1}
                  options={availableBrokersAndEstates.estates || []}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  onChange={(e, { value }) => setSelectedEstate(value)}
                  clearable
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <FieldLabel>SEGURADORA:</FieldLabel>
              <div style={{ width: '100%' }}>
                <DropdownNativo
                  style={{ width: '100%' }}
                  value={selectedInsurer}
                  name={`estate`}
                  defaultValue={
                    selectableInsurers.length === 1
                      ? selectableInsurers[0].value
                      : '' || ''
                  }
                  disabled={isLoading || selectableInsurers.length === 1}
                  options={selectableInsurers || []}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  onChange={(e, { value }) => setSelectedInsurer(String(value))}
                  clearable
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <FieldLabel>STATUS:</FieldLabel>
              <div style={{ width: '100%' }}>
                <DropdownNativo
                  style={{ width: '100%' }}
                  value={selectableStatus}
                  name={`estate`}
                  defaultValue={statusList.length === 1 ? statusList[0].value : '' || ''}
                  disabled={isLoading || statusList.length === 1}
                  options={statusList || []}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  onChange={(e, { value }) => {
                    setSelectableStatus(String(value));
                    switch (value) {
                      case 'Todos':
                        setStatus(0);
                        break;
                      case 'Contratação em Andamento':
                        setStatus(1);
                        break;
                      case 'Erro na Contratação':
                        setStatus(2);
                        break;
                      case 'Contratação integrada em Andamento':
                        setStatus(5);
                        break;
                      default:
                        setStatus(0);
                        break;
                    }
                  }}
                  clearable
                />
              </div>
            </Grid.Column>
            {!user.role.includes('ESTATE') && (
              <Grid.Column>
                <FieldLabel>GESTOR DE CONTAS:</FieldLabel>
                <div style={{ width: '100%' }}>
                  <DropdownNativo
                    style={{ width: '100%' }}
                    value={selectedAccountManager || undefined}
                    name={`accountManager`}
                    options={accountManagerOptions}
                    disabled={isLoading || isAccountManagerLoading}
                    loading={isAccountManagerLoading}
                    placeholder={'Selecionar...'}
                    search
                    selection
                    onChange={(e, { value }) =>
                      handleNewChangeAccountManager(value as string)
                    }
                    clearable
                  />
                </div>
              </Grid.Column>
            )}
          </Grid.Row>
          {isExpanded && (
            <Grid.Row verticalAlign={'middle'}>
              <Grid.Column width={2}>
                <FieldLabel>Data Inicial:</FieldLabel>
                <Field
                  name={`start`}
                  selected={startFilter ? startFilter : ''}
                  onChange={setStartFilter}
                  component={CustomDatePicker}
                  disabled={isLoading}
                  customInput={
                    <AppMaskedInput
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/
                      ]}
                    />
                  }
                  dateFormat={'dd/MM/yyyy'}
                />
              </Grid.Column>

              <Grid.Column width={2}>
                <FieldLabel>Data Final:</FieldLabel>
                <Field
                  name={`end`}
                  selected={endFilter ? endFilter : ''}
                  onChange={setEndFilter}
                  component={CustomDatePicker}
                  disabled={isLoading}
                  customInput={
                    <AppMaskedInput
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/
                      ]}
                    />
                  }
                  dateFormat={'dd/MM/yyyy'}
                />
              </Grid.Column>

              <Grid.Column width={3}>
                <FieldLabel>Nome:</FieldLabel>
                <Input
                  name="name"
                  onChange={handleInputChange}
                  value={values.name}
                  disabled={isLoading}
                  fluid
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <FieldLabel>CPF/CNPJ:</FieldLabel>
                <Input
                  name="document"
                  onChange={handleInputChange}
                  value={values.document}
                  disabled={isLoading}
                  fluid
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <FieldLabel>Endereço:</FieldLabel>
                <Input
                  name="address"
                  onChange={handleInputChange}
                  value={values.address}
                  disabled={isLoading}
                  fluid
                />
              </Grid.Column>
              <Grid.Column width={1}></Grid.Column>
              <Grid.Column width={2} textAlign={'center'}>
                <Button
                  fluid
                  type={'submit'}
                  size={'mini'}
                  color="blue"
                  text="Pesquisar"
                  style={{ margin: '0.5em 0em' }}
                  onClick={handleSubmit}
                />
                <Button
                  basic
                  fluid
                  type={'button'}
                  size={'mini'}
                  disabled={isLoading}
                  text="Limpar"
                  onClick={clearInputs}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </form>
      <BondInsuranceProposalsTable
        bondInsuranceProposals={bondInsuranceProposals}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        getData={getData}
        dataCount={dataCount}
      />
    </>
  );
};

export default connect(mapState, mapDispatch)(BondInsuranceProposalsPage);
