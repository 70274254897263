import { Grid, Input, InputOnChangeData, StepProps, Radio, ButtonProps, Button } from 'semantic-ui-react';
import { Field, FieldArray, FieldArrayRenderProps } from 'formik';
import React, { ReactElement, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import "../../../../../../resources/css/styles.css";
import { activitiesOptions } from '../../../../types';
import { getErrorFormik } from '../../../../../../services/errors';
import SectionLabel from '../../../../../../components/SectionLabel';
import { FieldLabel } from '../../../../../../styles';
import { FormikErrorMessage } from '../../../../../../components/ErrorMessage';
import { maskCpf, formatAmount, maskCnpj } from '../../../../../../services/masks';
import Dropdown from '../../../../../../components/Dropdown';
import { NaturalTenant } from '../../../../../../types/NaturalTenant';

const SECTION_NAME = 'tenant_info';

export const QuizSection = (props: StepProps): ReactElement => {

  const {
    errors,
    touched,
    handleInputChange,
    handleDateChange,
    values,
    setFieldTouched,
    setFieldValue,
  } = props;

  const tenant_info = values.tenant_info as NaturalTenant;

  useEffect(() => {
  }, [])

  const validateErros = (field: string) => getErrorFormik(errors, touched, SECTION_NAME, field);

  const AddPartnerButton = (props: ButtonProps): ReactElement => {
    return (
      <Button color="green" type="button" {...props}>Adicionar Sócio</Button>
    );
  }

  return (
    <>
      <SectionLabel text='QUESTIONÁRIO' />
      <Grid.Row >
        <Grid.Column width={5}>
          <FieldLabel paddingTop={true}>EMPRESA JÁ CONSTITUÍDA? </FieldLabel>
          <Grid.Row>
            <Grid.Column width={5} style={{ paddingTop: '10px' }}>
              <Radio
                style={{ paddingLeft: '120px' }}
                label='Sim'
                id={`${SECTION_NAME}.quiz_company_created.yes`}
                name={`${SECTION_NAME}.quiz_company_created`}
                checked={tenant_info.quiz_company_created}
                onChange={(_, data) => {
                  setFieldValue(`${SECTION_NAME}.quiz_company_created`, true);
                }}
              />
              <Radio
                style={{ paddingLeft: '30px' }}
                label='Não'
                id={`${SECTION_NAME}.quis_company_created.no`}
                name={`${SECTION_NAME}.quiz_company_created`}
                checked={!tenant_info.quiz_company_created}
                onChange={(_, data) => {
                  setFieldValue(`${SECTION_NAME}.quiz_company_created`, false);
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.quiz_company_created`} />
        </Grid.Column>

        <Grid.Column width={3}>
          <FieldLabel paddingTop={true}>RAMO DE ATIVIDADE*</FieldLabel>
          <Field
            id={`${SECTION_NAME}.quiz_company_field_of_activity`}
            name={`${SECTION_NAME}.quiz_company_field_of_activity`}
            value={tenant_info.quiz_company_field_of_activity}
            completeValue={true}
            placeholder={'Selecionar...'}
            options={activitiesOptions}
            component={Dropdown}
            onChange={handleInputChange}
            onClose={() => setFieldTouched(`${SECTION_NAME}.quiz_company_field_of_activity`, true)}
            error={validateErros('quiz_company_field_of_activity')}
            fluid />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.quiz_company_field_of_activity`} />
        </Grid.Column>

        {tenant_info.quiz_company_created &&
          <>
            <Grid.Column width={3}>
              <FieldLabel>CNPJ*</FieldLabel>
              <Field
                id={`${SECTION_NAME}.quiz_company_cnpj`}
                name={`${SECTION_NAME}.quiz_company_cnpj`}
                value={tenant_info.quiz_company_cnpj}
                onChange={(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
                  data.value = maskCnpj(data.value);
                  handleInputChange(e, data);
                }}
                onBlur={() => setFieldTouched(`${SECTION_NAME}.quiz_company_cnpj`, true)}
                error={validateErros('quiz_company_cnpj')}
                component={Input}
                fluid />
              <FormikErrorMessage component="div" name={`${SECTION_NAME}.quiz_company_cnpj`} />
            </Grid.Column>


            <Grid.Column width={2}>
              <FieldLabel>RENDA MENSAL*</FieldLabel>
              <Field
                id={`${SECTION_NAME}.quiz_company_rent`}
                name={`${SECTION_NAME}.quiz_company_rent`}
                value={tenant_info.quiz_company_rent}
                onChange={(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
                  data.value = formatAmount(data.value);
                  handleInputChange(e, data);
                }} onBlur={() => setFieldTouched(`${SECTION_NAME}.quiz_company_rent`, true)}
                customInput={<Input />}
                error={validateErros(`quiz_company_rent`)}
                component={Input}
                fluid />
              <FormikErrorMessage component="div" name={`${SECTION_NAME}.quiz_company_rent`} />
            </Grid.Column>
          </>}

        <Grid.Column width={3}>
          <FieldLabel paddingTop={true}> POSSUI SÓCIOS? </FieldLabel>
          <Grid.Row>
            <Grid.Column width={5} style={{ paddingTop: '10px' }}>
              <Radio
                style={{ paddingLeft: '0px' }}
                label='Sim'
                id={`${SECTION_NAME}.quiz_partners.yes`}
                name={`${SECTION_NAME}.quiz_partners`}
                checked={tenant_info.quiz_partners}
                onChange={(_, data) => {
                  setFieldValue(`${SECTION_NAME}.quiz_partners`, true);
                }}
              />
              <Radio
                style={{ paddingLeft: '30px' }}
                label='Não'
                id={`${SECTION_NAME}.quiz_partners.no`}
                name={`${SECTION_NAME}.quiz_partners`}
                checked={!tenant_info.quiz_partners}
                onChange={(_, data) => {
                  setFieldValue(`${SECTION_NAME}.quiz_partners`, false);
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.quiz_partners`} />
        </Grid.Column>
      </Grid.Row>

      {tenant_info.quiz_partners &&
        <>
          <FieldArray
            name={`tenant_info.partners`}
            render={(arrayHelper: FieldArrayRenderProps) => (
              <>
                {tenant_info.partners.map((partner, index) => {
                  return (
                    <>
                      <Grid.Row >
                        <Grid.Column width={8}>
                          <FieldLabel>NOME DO SÓCIO*</FieldLabel>
                          <Field
                            id={`${SECTION_NAME}.partners[${index}].name`}
                            name={`${SECTION_NAME}.partners[${index}].name`}
                            value={tenant_info.partners[index].name}
                            onChange={handleInputChange}
                            onBlur={() => setFieldTouched(`${SECTION_NAME}.partners[${index}].name`, true)}
                            customInput={<Input />}
                            error={validateErros(`partners[${index}].name`)}
                            component={Input}
                            fluid />
                          <FormikErrorMessage component="div" name={`${SECTION_NAME}.partners[${index}].name`} />
                        </Grid.Column>

                        <Grid.Column width={3}>
                          <FieldLabel>CPF DO SÓCIO*</FieldLabel>
                          <Field
                            id={`${SECTION_NAME}.partners[${index}].document`}
                            name={`${SECTION_NAME}.partners[${index}].document`}
                            value={tenant_info.partners[index].document}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
                              data.value = maskCpf(data.value);
                              handleInputChange(e, data);
                            }}
                            onBlur={() => setFieldTouched(`${SECTION_NAME}.partners[${index}].document`, true)}
                            error={validateErros(`partners[${index}].document`)}
                            component={Input}
                            fluid />
                          <FormikErrorMessage component="div" name={`${SECTION_NAME}.partners[${index}].document`} />
                        </Grid.Column>
                      </Grid.Row>
                    </>
                  )
                })}
                <Grid.Row>
                  <AddPartnerButton onClick={() => {
                    arrayHelper.push({ name: '', document: '', percent: 0, date_of_entry: '' })
                  }} />
                </Grid.Row>
              </>

            )}
          />
        </>}
    </>
  )
};
