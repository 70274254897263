export const getErrorFormik = (errors: any, touched: any, sectionKey: string, field: string, ) => {
  const levels = sectionKey.split('.');

  try {
    if (Boolean(Object.keys(errors).length) && errors && touched) {
      const fieldErrors = levels.slice(1).reduce((previous, current) => {
        return previous[current];
      }, errors[levels[0]])[field];

      const fieldTouched = levels.slice(1).reduce((previous, current) => {
        return previous[current];
      }, touched[levels[0]])[field];

      return fieldErrors && fieldTouched;

    }
    return false;
  } catch (e) {
    //console.log(e);
  }
}

