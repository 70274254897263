import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dimmer,
  Dropdown,
  Grid,
  Icon,
  Input,
  Loader,
  Modal
} from 'semantic-ui-react';

import { FieldLabel } from '../../../../../../../styles';
import { useHistory } from 'react-router-dom';
import {
  DATE_INPUT_MASK,
  maskCpfOrCnpj,
  maskPhoneNumber,
  maskZipCode,
  numberToMoneyString,
} from '../../../../../../../services/masks';
import {
  CoveragesRow,
  Disclaimer,
  DisclaimerContainer,
  HeaderAndDivider,
  HeaderSubtitle,
  QuotationContainer,
  QuotationHeader,
  QuotationList,
  QuotationResultContainer,
  QuotationValue
} from './styles';
import {
  warningNotification
} from '../../../../../../../services/notification';
import { AlfaProposalFormProps } from '../../interfaces/alfa.proposal.interface';
import {
  SEX_TYPE,
  coverageTypesOptions,
  getOccupationTypeOptions,
  getPropertyTypeOptions
} from '../../constants/form.constant';
import CustomDatePicker from '../../../../../../../components/CustomDatePicker';
import AppMaskedInput from '../../../../../../../components/MaskedInput';
import { get, post } from '../../../../../../../services/request';
import {
  AlfaBudgetRequest,
  AlfaBudgetResponse,
  AlfaBudgetResponseErrorResponse,
  AlfaBudgetResponseSuccessResponse
} from '../../interfaces/alfa.budget.interface';
import { getDataByDocument } from '../../../../../../../services/documents';
import moment from 'moment-timezone';
import { AddressService } from '../../../../../../../services/address';
import { validPhone } from '../../../../../../../utils/validPhone';
import { FireInsuranceAnalysisStatusEnum } from '../../../../../utils/Pottencial';
import { isValidEmail } from '../../../../../../../utils/emailValidator';
import { isValidDocument } from '../../../../../../fire-insurance-budget-form/validators';
import { adjustDateForTimezone } from '../../../../../../../utils/AjustDates';
import GenericLogModal from '../../../../../../../components/GenericLogModal/LogModal';
import * as storage from '../../../../../../../services/storage';
import { UserRolesEnum } from '../../../../../../../enums/user-roles.enum';
import { phoneIsValid } from '../../../../../../../utils/phoneIsValid';
import { BudgetContext } from '../..';
const ControlledField = ({
  label,
  value
}: {
  label: string;
  value: string | number | undefined;
}) => {
  return (
    <>
      <FieldLabel>{label}</FieldLabel>
      <span>{value}</span>
    </>
  );
};

const AlfaCreatePolicyModal = (props: AlfaProposalFormProps) => {
  const history = useHistory();

  const {
    open,
    onClose,
    context,
    data: propsData,
    requestData: propsRequestData,
    fireInsuranceAnalysisId,
    isHireRequest,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState(propsData);
  const [requestData, setRequestData] = useState(propsRequestData);
  const [isHiring, setIsHiring] = useState<boolean>(false);
  const [installments, setInstallments] = useState<number>(1);
  const [tenantBirthDate, setTenantBirthDate] = useState<Date | undefined>(undefined);
  const [tentantEmail, setTenantEmail] = useState<string | undefined>(undefined);
  const [tenantCellphone, setTenantCellphone] = useState<string | undefined>(undefined);
  const [addressNumber, setAddressNumber] = useState<string>('');
  const [notAddressNumber, setNotAddressNumber] = useState<boolean>(false);
  const [addressComplement, setAddressComplement] = useState<string | undefined>(undefined);
  const [zipCode, setZipCode] = useState<string | undefined>(undefined);
  const [city, setCity] = useState<string | undefined>(undefined);
  const [state, setState] = useState<string | undefined>(undefined);
  const [district, setDistrict] = useState<string | undefined>(undefined);
  const [street, setStreet] = useState<string | undefined>(undefined);
  const [beneficiaryDocument, setBeneficiaryDocument] = useState<string | undefined>(undefined);
  const [beneficiaryName, setBeneficiaryName] = useState<string | undefined>();
  const [loadingTenantDocument, setLoadTenantDocument] = useState<boolean>(false);
  const [loadingBeneficiaryDocument, setLoadBeneficiaryDocument] = useState<boolean>(false);
  const [tenantDocument, setTenantDocument] = useState<string | undefined>(undefined);
  const [tenantName, setTenantName] = useState<string | undefined>(undefined);
  const [tenantSex, setTenantSex] = useState<string | undefined>(undefined);
  const [zipCodeLoading, setZipCodeLoading] = useState<boolean>(false);
  const [isPF, setIsPF] = useState<boolean>(false);
  const [isCanceled, setIsCanceled] = useState<boolean>(false);
  const [renewData, setRenewData] = useState<any>(undefined);
  const [reference, setReference] = useState<string>('');
  const [limitLogDates, setLimitLogDates] = useState<Date[]>([]);
  const [cancelationDate, setCancelationDate] = useState<string>('');
  const [cancelationUser, setCancelationUser] = useState<string>('4Seg');
  const [cancelationObs, setCancelationObs] = useState<string>('');

  const role = storage.get('role');

  const onBlurDocument = async (document: string, step = 'tenant') => {
    if (!document) return;

    try {
      const unformattedDoc = document.replace(/\D/g, '');
      const data = await getDataByDocument(unformattedDoc);

      if (step === 'tenant') {
        setIsPF(unformattedDoc.length === 11);
        setLoadTenantDocument(true);
        setTenantName(data?.name ?? data?.company ?? '');
        Boolean(data?.name) && setTenantSex(data.gender === 'MASCULINO' ? '0' : '1');
        setTenantBirthDate((data?.birth_date && moment(data?.birth_date).isValid()) ? adjustDateForTimezone(data?.birth_date) : undefined);
        setTenantEmail(data?.email ?? '');
        setTenantCellphone(data?.phone ? validPhone(data?.phone): '');
      } else {
        setLoadBeneficiaryDocument(true);
        setBeneficiaryName(data?.name ?? data?.company ?? "");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadTenantDocument(false);
      setLoadBeneficiaryDocument(false);
    }
  };

  const onChangeZipCode = (zipCode: string): void => {
    setZipCodeLoading(true);
    const maskedValue = maskZipCode(zipCode);
    setZipCode(maskedValue);
    if (maskedValue.length >= 9) {
      AddressService.getAddressByZipCode(zipCode)
        .then(address => {
          setCity(address.city ?? '');
          setDistrict(address.district ?? '');
          setState(address.state ?? '');
          setStreet(address.street ?? '');
        })
        .catch(() => {
          warningNotification('CEP invalido!', 'Por favor, digite um CEP valido.');
        });
    }

    setZipCodeLoading(false);
  };

  const loadDataNewBudget = async () => {
    setAddressComplement('');
    setAddressNumber('');
    setZipCode('');
    setCity('');
    setState('');
    setDistrict('');
    setStreet('');
    setTenantBirthDate(undefined);
    setTenantEmail('');
    setTenantCellphone('');
    setTenantDocument('');
    setTenantName('');
    setTenantSex('');
    setReference('');
    data?.installments && setInstallments(data.installments);
  };

  const loadData = async () => {
    try {
      setLoading(true);

      if (!requestData) {
        onClose();

        return warningNotification(
          'Erro ao carregar os dados do orçamento',
          'Se o erro persistir contacte o suporte'
        );
      };

      const { data } = requestData;
      const { property } = data;

      property?.complement && setAddressComplement(property.complement);
      property?.number && property.number ? setAddressNumber(property.number) : setNotAddressNumber(true);
      property?.zipcode && setZipCode(property.zipcode);
      property?.city && setCity(property.city);
      property?.state && setState(property.state);
      property?.district && setDistrict(property.district);
      property?.street && setStreet(property.street);
      data?.tenantName && setTenantName(data.tenantName);
      data?.tenantDocument && setTenantDocument(maskCpfOrCnpj(data.tenantDocument));
      setIsPF(data.tenantDocument.replace(/\D/g, '').length === 11);
      data?.tenantSex && setTenantSex(data.tenantSex);
      data?.tenantEmail && setTenantEmail(data.tenantEmail);
      data?.tenantCellphone && setTenantCellphone(data.tenantCellphone);
      data?.propertyOwnerName && setBeneficiaryName(data.propertyOwnerName);
      data?.propertyOwnerDocument && setBeneficiaryDocument(data.propertyOwnerDocument);
      data?.tenantBirthdate && setTenantBirthDate(adjustDateForTimezone(data.tenantBirthdate as string));
      data?.reference && setReference(data.reference || '');
      data?.installments && setInstallments(data.installments);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadDataForDetail = async () => {
    try {
      setLoading(true);

      const budgetData = await get(`/fire-insurance-quotations/alfa-seguros/budget/${fireInsuranceAnalysisId}`);

      const { fireInsurance, coverages } = budgetData;
      const { propertyAddresses: property } = fireInsurance;

      property?.complement && setAddressComplement(property?.complement);
      property?.number && property?.number ? setAddressNumber(property?.number) : setNotAddressNumber(true);
      property?.zipcode && setZipCode(property?.zipcode);
      property?.city && setCity(property?.city);
      property?.state && setState(property?.state);
      property?.district && setDistrict(property?.district);
      property?.street && setStreet(property?.street);
      fireInsurance?.tenantName && setTenantName(fireInsurance?.tenantName);
      fireInsurance?.tenantDocument && setTenantDocument(maskCpfOrCnpj(fireInsurance?.tenantDocument));
      setIsPF(fireInsurance?.tenantDocument.replace(/\D/g, '').length === 11);
      fireInsurance?.tenantSex ? setTenantSex(fireInsurance?.tenantSex) :
        fireInsurance?.tenantGender && setTenantSex(fireInsurance?.tenantGender === 'M' ? '0' : '1');
      fireInsurance?.tenantEmail && setTenantEmail(fireInsurance?.tenantEmail);
      fireInsurance?.tenantCellphone && setTenantCellphone(fireInsurance?.tenantCellphone);
      fireInsurance?.propertyOwnerName && setBeneficiaryName(fireInsurance?.propertyOwnerName);
      fireInsurance?.propertyOwnerDocument && setBeneficiaryDocument(fireInsurance?.propertyOwnerDocument);
      fireInsurance?.tenantBirthdate && setTenantBirthDate(adjustDateForTimezone(fireInsurance?.tenantBirthdate as string));
      budgetData?.reference && setReference(budgetData?.reference || '');
      budgetData?.status && setIsCanceled(budgetData.status === FireInsuranceAnalysisStatusEnum.Canceled);
      budgetData?.renewal && setRenewData(budgetData.renewal);
      budgetData?.installments && setInstallments(budgetData.installments);
      setLimitLogDates([budgetData.created_at, budgetData?.updatedAt]);
      setInstallments(Number(budgetData.installments));

      const payload: any = {
        estateId: fireInsurance.estateId,
        insurerId: budgetData.insurerId,
        data: {
          rentValue: Number(fireInsurance.rentValue),
          installments: Number(budgetData.installments),
          informedCost: Number(budgetData.grossPremium),
          action: 0,
          policyPeriodStart: budgetData.policyPeriodStart,
          policyPeriodEnd: budgetData.policyPeriodEnd,
          channel: Number(budgetData.channel.code),
          discountPercentage: Number(budgetData.discountPercentage),
          propertyType: Number(fireInsurance.propertyType),
          propertyUseType: Number(fireInsurance.propertyUseType),
          coverageType: Number(budgetData.coverageType),
          planCode: budgetData.plan.code,
          property: {
            zipcode: property.zipCode?.replace(/\D/g, ''),
            city: property.city,
            state: property.state,
            street: property.street,
            district: property.district,
            number: property.number,
            complement: property.complement,
          },
          reference: budgetData.reference,
          coverages,
        }
      };

      setRequestData(payload);

      setData({
        id: fireInsuranceAnalysisId,
        estateName: fireInsurance?.estate?.name,
        addressComplement: property?.complement,
        addressNumber: property?.number,
        city: property?.city,
        district: property?.district,
        street: property?.street,
        state: property?.state,
        zipcode: property?.zipcode,
        coverageType: data?.coverageType || coverageTypesOptions.find(c => c.value === budgetData.coverageType)?.text || 'NÃO LOCALIZADO',
        channel: data?.channel || budgetData.channel?.name,
        installments: data?.installments || budgetData.installments,
        policyPeriodStart: moment(Date.now()).format('DD/MM/YYYY'),
        policyPeriodEnd: budgetData.channel?.name.includes('Anual') ? moment(Date.now()).add('1', 'year').format('DD/MM/YYYY') : moment(Date.now()).add('1', 'month').format('DD/MM/YYYY'),
        propertyType: data?.propertyType || getPropertyTypeOptions().find( c => Number(c.value) === Number(fireInsurance.propertyType))?.text || 'NÃO LOCALIZADO',
        propertyUseType: data?.propertyUseType || getOccupationTypeOptions().find( c => Number(c.value) === Number(fireInsurance.propertyUseType))?.text || 'NÃO LOCALIZADO',
        rentValue: data?.rentValue || numberToMoneyString(Number(fireInsurance.rentValue)),
        totalPremium: data?.totalPremium || Number(budgetData.grossPremium),
        coverages: coverages?.map((c: any) => ({
          code: Number(c.referenceCode),
          name: String(c.coverageName),
          value: Number(c.value),
        }))
        .sort((a: any, b: any) => {
          const awi = a.name.startsWith('Incêndio');
          const bwi = b.name.startsWith('Incêndio');
          if (awi && !bwi) return -1;
          if (!awi && bwi) return 1;
          return 0;
        }),
        tenantDocument: maskCpfOrCnpj(fireInsurance.tenantDocument),
        tenantName: fireInsurance?.tenantName,
        tenantSex: (fireInsurance?.tenantGender === 'M' || fireInsurance?.tenantSex === '0') ? 'Masculino' : 'Feminino',
        discount: data?.discount || budgetData.discountPercentage || 0,
        assistance: data?.assistance || budgetData?.plan?.name,
        reference: data?.reference || '',
      });

      if (budgetData.status === FireInsuranceAnalysisStatusEnum.Canceled) {
        budgetData?.canceledAt && setCancelationDate(moment(budgetData.canceledAt).format('DD/MM/YYYY'));
        budgetData?.updatedBy && setCancelationUser(budgetData?.updatedBy?.name);
        budgetData?.observations && setCancelationObs(budgetData?.observations);
      }
    } catch (error) {
      console.log(error);

      onClose();

      return warningNotification(
        'Erro ao carregar os dados do orçamento',
        'Se o erro persistir contacte o suporte'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleHireButton = async () => {
    setIsHiring(true);

    try {
      if (!requestData) {
        return;
      }

      if (!tenantBirthDate && isPF) {
        warningNotification('Atenção', 'Informe a data de nascimento do locatário.');
        setIsHiring(false);
        return;
      }

      /// valida a data, minimo 14 anos
      const minDate = new Date();
      minDate.setFullYear(minDate.getFullYear() - 14);
      if (isPF && tenantBirthDate && tenantBirthDate > minDate) {
        warningNotification('Atenção', 'Idade do Locatário menor de 14 anos.');
        setIsHiring(false);
        return;
      }

      /// maior de 100 anos
      const maxDate = new Date();
      maxDate.setFullYear(maxDate.getFullYear() - 100);
      if (isPF && tenantBirthDate && tenantBirthDate < maxDate) {
        warningNotification('Atenção', 'Idade do Locatário maior de 100 anos.');
        setIsHiring(false);
        return;
      }

      if (!tentantEmail) {
        warningNotification('Atenção', 'Informe o email do locatário.');
        setIsHiring(false);
        return;
      }

      // validar email
      if(!isValidEmail(tentantEmail.trim())) {
        warningNotification('Atenção', 'Email inválido.');
        setIsHiring(false);
        return;
      }

      if (!tenantCellphone) {
        warningNotification('Atenção', 'Informe o telefone do locatário.');
        setIsHiring(false);
        return;
      }

      if (!phoneIsValid(tenantCellphone)) {
        warningNotification('Atenção', 'Telefone do locatário invalido.');
        setIsHiring(false);
        return;
      }

      if (!beneficiaryDocument) {
        warningNotification('Atenção', 'Informe o CPF/CNPJ do beneficiário.');
        setIsHiring(false);
        return;
      }

      if(!isValidDocument(beneficiaryDocument.trim())) {
        warningNotification('Atenção', 'CPF/CNPJ do beneficiário inválido.');
        setIsHiring(false);
        return;
      }

      if (!beneficiaryName || beneficiaryName.split(' ').length < 2) {
        warningNotification(
          'Atenção',
          'Informe o nome do beneficiário, deve conter pelo menos duas palavras.');
        setIsHiring(false);
        return;
      }

      if (!zipCode) {
        warningNotification('Atenção', 'Informe o CEP do imóvel.');
        setIsHiring(false);
        return;
      }

      // valid zip code
      if (zipCode.replace(/\D/g, '').length < 8) {
        warningNotification('Atenção', 'CEP inválido.');
        setIsHiring(false);
        return;
      }

      if (!city) {
        warningNotification('Atenção', 'Informe a cidade do imóvel.');
        setIsHiring(false);
        return;
      }

      if (!state) {
        warningNotification('Atenção', 'Informe o estado do imóvel.');
        setIsHiring(false);
        return;
      }

      if (!district) {
        warningNotification('Atenção', 'Informe o bairro do imóvel.');
        setIsHiring(false);
        return;
      }

      if (!street) {
        warningNotification('Atenção', 'Informe o logradouro do imóvel.');
        setIsHiring(false);
        return;
      }

      if (!addressNumber && !notAddressNumber) {
        warningNotification('Atenção', 'Informe o número do imóvel.');
        setIsHiring(false);
        return;
      }

      if (!tenantDocument) {
        warningNotification('Atenção', 'Informe o CPF/CNPJ do locatário.');
        setIsHiring(false);
        return;
      }

      if(!isValidDocument(tenantDocument)) {
        warningNotification('Atenção', 'CPF/CNPJ do locatário inválido.');
        setIsHiring(false);
        return;
      }

      if (!tenantName || tenantName.split(' ').length < 2) {
        warningNotification(
          'Atenção',
          'Informe o nome do segurado, deve conter pelo menos duas palavras.');
        setIsHiring(false);
        return;
      }

      if (tenantDocument?.replace(/\D/g, '')?.length < 11 && !tenantSex) {
        warningNotification('Atenção', 'Informe o sexo do locatário.');
        setIsHiring(false);
        return;
      }

      let channel: number;
      let coverages: any[];

      if (typeof requestData.data.channel === 'number') {
        channel = requestData.data.channel;
      } else {
        channel = +(requestData as any).data.channel?.code;
      }

      coverages = requestData.data?.coverages.filter((c: any) => c.checked).map((c: any) => ({
        referenceCode: +c.code,
        value: c.value,
      }));

      if (!coverages.length) {
        coverages = requestData.data?.coverages.map((c: any) => ({
          referenceCode: +c.referenceCode,
          value: +c.value,
        }));
      }

      const payload: AlfaBudgetRequest = {
        isEdit:  [BudgetContext.detail,BudgetContext.editing].includes(context),
        estateId: requestData.estateId,
        insurerId: requestData.insurerId,
        renewalFireInsuranceId: BudgetContext.copyCanceled !== context
          ? fireInsuranceAnalysisId
          : undefined,
        data: {
          rentValue: requestData.data.rentValue,
          installments: Number(installments) || 1,
          discountPercentage: +requestData.data.discountPercentage,
          informedCost: data?.totalPremium || 0,
          action: 0,
          policyPeriodStart: requestData.data.policyPeriodStart,
          policyPeriodEnd: requestData.data.policyPeriodEnd,
          channel,
          tenantDocument: tenantDocument?.replace(/\D/g, ''),
          tenantName: tenantName.trim(),
          tenantEmail: tentantEmail.trim(),
          tenantBirthdate: tenantBirthDate ? tenantBirthDate : undefined,
          tenantCellphone: validPhone(tenantCellphone),
          tenantSex: tenantSex || '0',
          propertyOwnerDocument: beneficiaryDocument?.replace(/\D/g, ''),
          propertyOwnerName: beneficiaryName,
          propertyType: +requestData.data.propertyType,
          propertyUseType: requestData.data.propertyUseType,
          coverageType: +requestData.data.coverageType,
          planCode: requestData.data.planCode,
          reference: reference ? reference.trim() : '',
          property: {
            zipcode: zipCode.replace(/\D/g, ''),
            city: city,
            state: state,
            street: street,
            district: district,
            number: addressNumber,
            complement: addressComplement,
          },
          coverages,
        }
      };

      console.log('PAYLOAD:', payload);

      const response: AlfaBudgetResponse = await post(
        '/fire-insurance-quotations/alfa-seguros/budget?save=true',
        payload
      );

      if (response.status === 200) {
        if (!props.isHireRequest) {
          history.push('/admin/fire-insurance-budgets');
          return;
        }

        const data = response.data as AlfaBudgetResponseSuccessResponse['data'];

        const proposalResponse = await post(
          '/fire-insurance-proposals/alfa-seguros/proposal',
          {
            fireInsuranceAnalysisId: data.fireInsuranceAnalysisId,
          }
        );

        if (!proposalResponse.success) {
          warningNotification(
            'Atenção',
            'Apólice Salva, mas não foi possível criar a proposta de seguro incêndio. ' + (proposalResponse?.data?.message ? proposalResponse?.data?.message : '')
          );
          history.push('/admin/fire-insurance-budgets');
          return;
        }

        history.push('/admin/fire-insurance-policies');
      } else {
        const data2 = response.data as AlfaBudgetResponseErrorResponse['data'];

        if(data2?.message?.includes('fora do limite')) {
          warningNotification(
            'Valor de cobertura fora do limite',
            data2.message, undefined , 7000
          );
          return;
        }

        if (requestData?.isEdit) {
          warningNotification(
            'Erro ao criar proposta de seguro incendio',
            'Plano solicitado não disponível para o seu cadastro, contacte o seu Gestor de Contas. ' + data2.message
          );
        } else {
          warningNotification(
            'Erro ao criar orçamento de seguro incendio',
            data2.message
          );
        }
      }
    } catch (error) {
      console.log(error);
      warningNotification(
        'Erro ao criar proposta de seguro incendio',
        'Plano solicitado não disponível para o seu cadastro, contacte o seu Gestor de Contas! ' + ((error as any)?.message ? (error as any).message : '')
      );
    } finally {
      setIsHiring(false);
    }
  };

  useEffect(() => {
    switch (context) {
      case BudgetContext.new:
        loadDataNewBudget();
        break;
      case BudgetContext.editing:
      case BudgetContext.renewal:
      case BudgetContext.copyCanceled:
        loadData();
        break;
      case BudgetContext.detail:
        fireInsuranceAnalysisId && loadDataForDetail();
        break;
      default:
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal open={open} onClose={onClose} closeOnEscape={false} closeOnDimmerClick={false}>
     <Modal.Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      Detalhes do Orçamento de Seguro Incêndio
      {role === UserRolesEnum.brokerAdmin && fireInsuranceAnalysisId && limitLogDates[0] &&
        <GenericLogModal
          relatedIds={[fireInsuranceAnalysisId]}
          createdAt={limitLogDates[0]}
          updatedAt={limitLogDates[1]}
          context={'FIRE_INSURANCE'}
        />
      }
      </Modal.Header>
      <Modal.Content scrolling>
        {loading && (
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
        )}

        {context === BudgetContext.detail && (
          <DisclaimerContainer>
            <Disclaimer>
              {isCanceled ? (
                <>
                  <Icon bordered name='exclamation' />
                  <p>Este orçamento está cancelado, não será possível realizar a contratação.</p>
                </>
              ) : (
                <>
                  <Icon bordered name="exclamation" />
                  <p>
                    Este orçamento está salvo para contratação posterior. Você terá um prazo de{' '}
                    <time>30</time> dias para confirmar a contratação
                  </p>
                </>
              )}
            </Disclaimer>
          </DisclaimerContainer>
        )}

        {data && (
          <Grid style={{ padding: 5 }} columns={'equal'}>
           { renewData && <>
           <HeaderAndDivider>Renovação</HeaderAndDivider>
            <Grid.Row>
              <Grid.Column>
                <ControlledField label={'ID DA APÓLICE ANTERIOR'} value={renewData.fireInsurancePolicyId} />
              </Grid.Column>
              <Grid.Column>
                <ControlledField label={'SEGURADORA ANTERIOR'} value={renewData?.insurer?.name} />
              </Grid.Column>
              <Grid.Column>
                <ControlledField
                  label={'INÍCIO DA VIGÊNCIA'}
                  value={renewData.policyPeriodStart ? moment(renewData.policyPeriodStart).format('DD/MM/YYYY') : "INDISPONÍVEL"}
                />
              </Grid.Column>
              <Grid.Column>
                <ControlledField
                  label={'FIM DA VIGÊNCIA'}
                  value={renewData.policyPeriodEnd ? moment(renewData.policyPeriodEnd).format('DD/MM/YYYY') : "INDISPONÍVEL"}
                />
              </Grid.Column>
            </Grid.Row>
            </>}

            <HeaderAndDivider>Dados do orçamento</HeaderAndDivider>
            <Grid.Row>
              <Grid.Column>
                <ControlledField label={'ID DO ORÇAMENTO'} value={data.id} />
              </Grid.Column>
              <Grid.Column>
                <ControlledField label={'IMOBILIÁRIA'} value={data.estateName} />
              </Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <ControlledField label={'FINALIDADE DA LOCAÇÃO'} value={data.channel} />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <ControlledField label={'TIPO DO IMÓVEL'} value={data.propertyType} />
              </Grid.Column>
              <Grid.Column>
                <ControlledField label={'OCUPAÇÃO'} value={data.propertyUseType} />
              </Grid.Column>

              <Grid.Column>
                <ControlledField label={'VALOR DO ALUGUEL'} value={data.rentValue} />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <ControlledField label={'TIPO DE COBERTURA'} value={data.coverageType} />
              </Grid.Column>

              <Grid.Column>
                <ControlledField
                  label={'INÍCIO DA VIGÊNCIA'}
                  value={data.policyPeriodStart}
                />
              </Grid.Column>

              <Grid.Column>
                <ControlledField
                  label={'FINAL DA VIGÊNCIA'}
                  value={data.policyPeriodEnd}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <ControlledField label={'ASSISTÊNCIA'} value={data.assistance} />
              </Grid.Column>

              <Grid.Column>
                <ControlledField label={'Nº DE PARCELAS'} value={installments} />
              </Grid.Column>

              <Grid.Column></Grid.Column>
            </Grid.Row>

            {isCanceled && context === BudgetContext.detail && (
              <Grid.Row>
                <Grid.Column>
                  <ControlledField label={'DATA DO CANCELAMENTO'} value={cancelationDate} />
                </Grid.Column>

                <Grid.Column>
                  <ControlledField label={'CANCELADO POR'} value={cancelationUser} />
                </Grid.Column>

                <Grid.Column>
                  <ControlledField label={'OBSERVAÇÕES DO CANCELAMENTO'} value={cancelationObs} />
                </Grid.Column>
              </Grid.Row>
            )}

            <HeaderAndDivider>
              Dados do Segurado
              <HeaderSubtitle>(Pode ser inquilino ou proprietário)</HeaderSubtitle>
            </HeaderAndDivider>

            <Grid.Row>
              <Grid.Column>
                <FieldLabel paddingBottom>CPF/CNPJ</FieldLabel>
                <Input
                  fluid
                  disabled={isCanceled}
                  name={'tenantDocument'}
                  placeholder={'Digite...'}
                  value={tenantDocument ? maskCpfOrCnpj(tenantDocument) : undefined}
                  onChange={e => setTenantDocument(e.target.value)}
                  onBlur={() => onBlurDocument(tenantDocument || '', 'tenant')}
                />
              </Grid.Column>

              <Grid.Column>
                <FieldLabel paddingBottom>NOME</FieldLabel>
                <Input
                  fluid
                  maxLength='40'
                  disabled={isCanceled}
                  name={'tenantName'}
                  placeholder={'Digite...'}
                  value={tenantName}
                  loading={loadingTenantDocument}
                  onChange={e => setTenantName(e.target.value)}
                />
              </Grid.Column>

              {tenantDocument && tenantDocument.replace(/\D/g, '').length < 14 && (
                <Grid.Column>
                  <FieldLabel paddingBottom>SEXO</FieldLabel>
                  <Dropdown
                    fluid
                    clearable
                    selection
                    disabled={isCanceled}
                    name={'tenantSexType'}
                    options={SEX_TYPE}
                    placeholder={'Selecione o sexo do Segurado'}
                    value={tenantSex}
                    loading={loadingTenantDocument}
                    onChange={(_event, { value }) => {
                      setTenantSex(value as string);
                    }}
                  />
                </Grid.Column>
              )}
            </Grid.Row>

            <Grid.Row>
              {tenantDocument && tenantDocument.replace(/\D/g, '').length < 14 && (
                <Grid.Column>
                  <FieldLabel>DATA DE NASCIMENTO</FieldLabel>
                  <CustomDatePicker
                    name={'birthdate'}
                    disabled={isCanceled}
                    dateFormat={'dd/MM/yyyy'}
                    placeholderText={'Digite...'}
                    selected={tenantBirthDate || ''}
                    customInput={<AppMaskedInput mask={DATE_INPUT_MASK} />}
                    loading={loadingTenantDocument}
                    onChange={(date: Date) => {
                      setTenantBirthDate(date);
                    }}
                  />
                </Grid.Column>
              )}

              <Grid.Column>
                <FieldLabel paddingBottom>EMAIL</FieldLabel>
                <Input
                  fluid
                  disabled={isCanceled}
                  name={'tenantEmail'}
                  placeholder={'Digite...'}
                  value={tentantEmail}
                  loading={loadingTenantDocument}
                  onChange={e => setTenantEmail(e.target.value)}
                />
              </Grid.Column>

              <Grid.Column>
                <FieldLabel paddingBottom>TELEFONE</FieldLabel>
                <Input
                  fluid
                  disabled={isCanceled}
                  name={'tenantCellphone'}
                  placeholder={'Digite...'}
                  value={tenantCellphone ? maskPhoneNumber(tenantCellphone) : ''}
                  loading={loadingTenantDocument}
                  onChange={e => {
                    const value = validPhone(e.target.value);
                    setTenantCellphone(value);
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            <HeaderAndDivider>
              Dados do Beneficiado
              <HeaderSubtitle>(Pode ser inquilino ou proprietário)</HeaderSubtitle>
            </HeaderAndDivider>
            <Grid.Row>
              <Grid.Column>
                <FieldLabel paddingBottom>CPF/CNPJ</FieldLabel>
                <Input
                  fluid
                  disabled={isCanceled}
                  name={'beneficiaryDocument'}
                  placeholder={'Digite...'}
                  value={beneficiaryDocument ? maskCpfOrCnpj(beneficiaryDocument) : undefined}
                  onChange={e => setBeneficiaryDocument(e.target.value)}
                  onBlur={() => onBlurDocument(beneficiaryDocument || '', 'beneficiary')}
                />
              </Grid.Column>

              <Grid.Column>
                <FieldLabel paddingBottom>NOME</FieldLabel>
                <Input
                  fluid
                  maxLength='40'
                  disabled={isCanceled}
                  name={'beneficiaryName'}
                  placeholder={'Nome do Beneficiario'}
                  value={beneficiaryName}
                  onChange={e => setBeneficiaryName(e.target.value)}
                  loading={loadingBeneficiaryDocument}
                />
              </Grid.Column>
            </Grid.Row>

            <HeaderAndDivider>Endereço do imóvel segurado</HeaderAndDivider>
            <Grid.Row>
              <Grid.Column>
                <FieldLabel paddingBottom>CEP</FieldLabel>
                <Input
                  fluid
                  disabled={isCanceled}
                  name={'zipcode'}
                  placeholder={'Digite...'}
                  value={maskZipCode(zipCode ?? '')}
                  onChange={e => {
                    onChangeZipCode(e.target.value);
                    setZipCode(e.target.value);
                  }}
                  loading={zipCodeLoading}
                />
              </Grid.Column>

              <Grid.Column>
                <FieldLabel paddingBottom>LOGRADOURO</FieldLabel>
                <Input
                  fluid
                  disabled={isCanceled}
                  name={'street'}
                  placeholder={'Digite...'}
                  value={street}
                  onChange={e => setStreet(e.target.value)}
                  loading={zipCodeLoading}
                />
              </Grid.Column>

              <Grid.Column>
                <FieldLabel paddingBottom>BAIRRO</FieldLabel>
                <Input
                  fluid
                  disabled={isCanceled}
                  name={'district'}
                  placeholder={'Digite...'}
                  value={district}
                  onChange={e => setDistrict(e.target.value)}
                  loading={zipCodeLoading}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <FieldLabel paddingBottom>CIDADE</FieldLabel>
                <Input
                  fluid
                  disabled={isCanceled}
                  name={'city'}
                  placeholder={'Digite...'}
                  value={city}
                  onChange={e => setCity(e.target.value)}
                  loading={zipCodeLoading}
                />
              </Grid.Column>

              <Grid.Column>
                <FieldLabel paddingBottom>UF</FieldLabel>
                <Input
                  fluid
                  disabled={isCanceled}
                  name={'state'}
                  placeholder={'Digite...'}
                  value={state}
                  onChange={e => setState(e.target.value)}
                  loading={zipCodeLoading}
                />
              </Grid.Column>

              <Grid.Column>
                <FieldLabel paddingBottom>NÚMERO</FieldLabel>
                <Input
                  fluid
                  type='text'
                  disabled={isCanceled || notAddressNumber}
                  name={'addressNumber'}
                  placeholder={!notAddressNumber ? 'Digite...' : 'S/N'}
                  value={addressNumber}
                  onChange={e => {
                    const numericValue = String(e.target.value).replace(/[^0-9]/g, '');
                    setAddressNumber(numericValue);
                  }}
                  loading={zipCodeLoading}
                />
              </Grid.Column>

              <Grid.Column verticalAlign='middle' width={2}>
                <Checkbox
                  label='S/N'
                  checked={notAddressNumber}
                  onChange={(e: any, { checked }) => {
                    if (checked) {
                      setNotAddressNumber(true);
                    } else {
                      setNotAddressNumber(false);
                    }
                    setAddressNumber('');
                  }}
                  style={{ paddingTop: '25px' }}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <FieldLabel paddingBottom>COMPLEMENTO</FieldLabel>
                <Input
                  fluid
                  disabled={isCanceled}
                  name={'addressComplement'}
                  placeholder={'Digite...'}
                  value={addressComplement}
                  onChange={e => setAddressComplement(e.target.value)}
                  loading={zipCodeLoading}
                />
              </Grid.Column>

              <Grid.Column>
                <FieldLabel paddingBottom>CÓDIGO DE REFERÊNCIA</FieldLabel>
                <Input
                  fluid
                  disabled={isCanceled}
                  maxLength='15'
                  name={'reference'}
                  placeholder={'Digite um código de referencia (alfanumérico)'}
                  value={reference}
                  onChange={e => {
                    if (e.target.value.match('^[a-zA-Z0-9]*$')) {
                      setReference(e.target.value);
                    }
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}

        <QuotationContainer>
          <QuotationHeader>
            <h3>
              <Icon name="shield" /> Orçamento de Seguro Incêndio
            </h3>
          </QuotationHeader>
          <QuotationResultContainer>
            <QuotationList>
              <>
                {data?.coverages?.map(c => (
                  <CoveragesRow key={c.code}>
                    <div>
                      <span className={'full-insurance-name'}>
                        <input type="checkbox" disabled checked />
                        {c.name}
                      </span>
                    </div>

                    <div>
                      <span className="insurance-value">
                        {numberToMoneyString(c.value)}
                      </span>
                    </div>
                  </CoveragesRow>
                ))}
              </>
            </QuotationList>
            {(() => {
              const totalPremium = data?.totalPremium || 0;
              const formattedPremium = numberToMoneyString(totalPremium);
              const parcelValue = numberToMoneyString(totalPremium / installments);

              return (
                <QuotationValue>
                  <h3 style={{ fontSize: '20px' }}>Valor total</h3>
                  <label style={{ fontSize: '17px' }}>
                    {formattedPremium}
                  </label>
                  <label style={{ fontSize: '13px', paddingBottom: '10px' }}>
                    {installments} x FATURA MENSAL - SEM ENTRADA
                  </label>
                  <small style={{ fontSize: '15px' }}>
                    Valor das parcelas:
                    {parcelValue}
                  </small>
                </QuotationValue>
              );
            })()}
          </QuotationResultContainer>
        </QuotationContainer>
      </Modal.Content>

      <Modal.Actions>
        <Button icon disabled={isHiring} labelPosition={'left'} onClick={() => onClose()}>
          <Icon name={'arrow left'} />
          Voltar
        </Button>

        <Button
          positive
          icon
          labelPosition={'right'}
          onClick={handleHireButton}
          loading={isHiring}
          disabled={isHiring || isCanceled}
        >
          <Icon name={'checkmark'} />
          {props.isHireRequest ? 'Contratar' : 'Salvar'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AlfaCreatePolicyModal;
