export enum FeatureEnum {
  all = 'ALL',
  analysis = 'ANALYSIS',
  dashboard = 'DASHBOARD',
  proposals = 'PROPOSALS',
  policies = 'POLICIES',
  canceleds = 'CANCELEDS',
  expireds = 'EXPIREDS',
  estates = 'ESTATES',
  claims = 'CLAIMS',
  users = 'USERS',
  insurers = 'INSURERS',
  captalizations = 'CAPTALIZATIONS',
  fireinsurances = 'FIREINSURANCES',
  logs = 'LOGS',
  powerbi = 'POWERBI',
  files = 'FILES',
  followups = 'FOLLOWUPS',
  franchises = 'FRANCHISES',
  preregisters = 'PREREGISTERS',
  claimEstateTransfer = 'CLAIMESTATESTRANSFER',
  letters = 'LETTERS',
  blacklist = 'BLACKLIST',
}
