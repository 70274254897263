import * as Storage from './storage';
import { post, get, put } from './request';
import {
  unmaskMoney,
  unmaskCpfOrCnpj,
  unmaskPhoneNumber,
  moneyStringToNumber
} from './masks';
import { BondInsuranceSteps } from '../pages/bond-insurance-budget-form/types';
import { PersonTypesEnum } from '../enums/person-types.enum';
import { CreateBondInsuranceRequest } from '../interfaces/create-bond-insurance.request';
import { DateToStringDate, FormatDateYMD } from './date';
import { SympathiseTenant } from '../types/SympathiseTenant';
import { Partner } from '../types/Partner';
import { LegalTenant } from '../types/LegalTenant';
import moment from 'moment-timezone';
import {
  BondInsuranceForGetBondInsurancesResponseDTO,
  GetBondInsurancesResponseDTO
} from '../dtos/bond-insurance-list/get-bond-insurances-response.dto';
import JSZip from 'jszip';
import { UpdateCancelation as EditModalUpdateCancelation } from '../pages/canceled-bond-insurance-list/components/EditModal/types';

export const save = async (
  data: BondInsuranceSteps,
  user_id: string,
  broker_id: string
): Promise<any> => {
  const route = `/bond-insurances`;

  const { purpose, estate, tenant_type, analysis_assurance } = data.initial_info;
  const {
    rent_value,
    energy_bill,
    gas_bill,
    condominium_fee,
    water_bill,
    taxes_value,
    contract_penalties_value,
    interior_painting_value,
    exterior_painting_value,
    furniture_damage_value,
    property_damage_value,
    validity,
    real_occupant_doc,
    location_in_progress,
    rental_period_start_date,
    rental_period_end_date
  } = data.property_info;

  let payload = {
    estate_id: estate.id,
    broker_id,
    user_id,
    purpose,
    tenant_type,
    analysis_assurance,
    // propertyOwnerNaturalPerson: {
    //   ...data.owner_info,
    //   [unmaskCpfOrCnpj(data?.owner_info?.document || '').length > 11 ? 'cnpj' : 'cpf']:
    //     unmaskCpfOrCnpj(data?.owner_info?.document || ''),
    //   phone: unmaskPhoneNumber(data?.owner_info?.phone || '')
    // },
    property: {
      ...data.property_info,
      rental_period_start_date: location_in_progress
        ? FormatDateYMD(rental_period_start_date)
        : undefined,
      rental_period_end_date: location_in_progress
        ? FormatDateYMD(rental_period_end_date)
        : undefined,

      location_in_progress: location_in_progress,
      real_occupant_doc: real_occupant_doc
        ? unmaskCpfOrCnpj(real_occupant_doc)
        : undefined,
      validity: validity,
      rent_value: rent_value ? unmaskMoney(rent_value) : 0,
      condominium_fee: condominium_fee ? unmaskMoney(condominium_fee) : 0,
      energy_bill: energy_bill ? unmaskMoney(energy_bill) : 0,
      gas_bill: gas_bill ? unmaskMoney(gas_bill) : 0,
      water_bill: water_bill ? unmaskMoney(water_bill) : 0,
      taxes_value: taxes_value ? unmaskMoney(taxes_value) : 0,
      contract_penalties_value: contract_penalties_value
        ? unmaskMoney(contract_penalties_value)
        : 0,
      interior_painting_value: interior_painting_value
        ? unmaskMoney(interior_painting_value)
        : 0,
      exterior_painting_value: exterior_painting_value
        ? unmaskMoney(exterior_painting_value)
        : 0,
      furniture_damage_value: furniture_damage_value
        ? unmaskMoney(furniture_damage_value)
        : 0,
      property_damage_value: property_damage_value
        ? unmaskMoney(property_damage_value)
        : 0
    }
  } as CreateBondInsuranceRequest;

  delete payload.property.is_condominium_fee_required;
  delete payload.property.is_energy_bill_required;
  delete payload.property.is_gas_bill_required;
  delete payload.property.is_taxes_value_required;
  delete payload.property.is_water_bill_required;

  if (tenant_type === PersonTypesEnum.natural) {
    const {
      main_tenant_date_of_dispatch,
      last_contractual_alteration_date,
      main_tenant_birth_date,
      main_tenant_phone,
      partner_date_of_dispatch,
      main_tenant_rg_emission_date,
      resident_birth_date,
      employment_admission_date,
      main_tenant_cpf,
      // main_tenant_monthly_fixed_income,
      // main_tenant_other_incomes,
      partner_cpf,
      sympathise_tenants,
      partners,
      quiz_partners,
      resident_cpf,
      resident_rg_emission_date,
      resident_phone,
      resident_monthly_fixed_income,
      resident_other_incomes,
      include_sympathise_tenants,
      main_tenant_close_to_politically_exposed_person,
      resident_close_to_politically_exposed_person
    } = data.tenant_info;

    payload = {
      ...payload,
      natural_tenant: {
        ...data.tenant_info,
        main_tenant_phone: unmaskPhoneNumber(main_tenant_phone),
        rent: 0,
        partner_rent: 0,
        partner_cpf: partner_cpf ? unmaskCpfOrCnpj(partner_cpf) : '',
        main_tenant_cpf: unmaskCpfOrCnpj(main_tenant_cpf),
        main_tenant_date_of_dispatch: main_tenant_date_of_dispatch
          ? FormatDateYMD(main_tenant_date_of_dispatch)
          : undefined,
        last_contractual_alteration_date: last_contractual_alteration_date
          ? FormatDateYMD(last_contractual_alteration_date)
          : undefined,
        main_tenant_rg_emission_date: FormatDateYMD(main_tenant_rg_emission_date),
        main_tenant_birth_date: FormatDateYMD(main_tenant_birth_date),
        // main_tenant_monthly_fixed_income: main_tenant_monthly_fixed_income ? unmaskMoney(main_tenant_monthly_fixed_income) : 0,
        // main_tenant_other_incomes: main_tenant_other_incomes ? unmaskMoney(main_tenant_other_incomes) : 0,
        partner_date_of_dispatch: partner_date_of_dispatch
          ? FormatDateYMD(partner_date_of_dispatch)
          : undefined,
        resident_cpf: resident_cpf ? unmaskCpfOrCnpj(resident_cpf) : undefined,
        resident_rg_emission_date: resident_rg_emission_date
          ? FormatDateYMD(resident_rg_emission_date)
          : undefined,
        resident_birth_date: resident_birth_date
          ? FormatDateYMD(resident_birth_date)
          : undefined,
        resident_phone: resident_phone ? unmaskPhoneNumber(resident_phone) : undefined,
        resident_monthly_fixed_income: resident_monthly_fixed_income
          ? unmaskMoney(resident_monthly_fixed_income)
          : 0,
        resident_other_incomes: resident_other_incomes
          ? unmaskMoney(resident_other_incomes)
          : 0,
        employment_admission_date: employment_admission_date
          ? FormatDateYMD(employment_admission_date)
          : undefined,
        sympathise_tenants: include_sympathise_tenants
          ? formatSympatiseTenants(sympathise_tenants)
          : undefined,
        partners: quiz_partners ? formatPartners(partners) : undefined
      }
    };

    if (
      main_tenant_close_to_politically_exposed_person &&
      main_tenant_close_to_politically_exposed_person.politically_exposed_person_cpf
    ) {
      payload.natural_tenant.main_tenant_close_to_politically_exposed_person.politically_exposed_person_cpf =
        unmaskCpfOrCnpj(
          main_tenant_close_to_politically_exposed_person.politically_exposed_person_cpf
        );
    }

    if (
      resident_close_to_politically_exposed_person &&
      resident_close_to_politically_exposed_person.politically_exposed_person_cpf
    ) {
      payload.natural_tenant.resident_close_to_politically_exposed_person.politically_exposed_person_cpf =
        unmaskCpfOrCnpj(
          resident_close_to_politically_exposed_person.politically_exposed_person_cpf
        );
    }
  } else {
    const {
      foundation_date,
      last_contractual_alteration_date,
      partners,
      anual_revenue,
      share_capital,
      paid_in_capital,
      phone,
      contact_phone,
      document
    } = data.tenant_info;

    payload = {
      ...payload,
      legal_tenant: {
        ...data.tenant_info,
        phone: unmaskPhoneNumber(phone),
        document: document ? unmaskCpfOrCnpj(document) : undefined,
        contact_phone: unmaskPhoneNumber(contact_phone),
        anual_revenue: unmaskMoney(anual_revenue),
        share_capital: unmaskMoney(share_capital),
        paid_in_capital: unmaskMoney(paid_in_capital),
        foundation_date: FormatDateYMD(foundation_date),
        last_contractual_alteration_date: FormatDateYMD(last_contractual_alteration_date),
        partners: formatPartners(partners)
      } as unknown as LegalTenant
    };
  }

  return await post(route, payload);
};

export const updateAnalysis = async (analysis: any[]): Promise<any> => {
  const route = `/bond-insurance-analysis`;
  // console.log(analysis)
  const payload = analysis.map(a => {
    return {
      id: a.id,
      status: a.status,
      observations: a.observations,
      value: unmaskMoney(a.value || '0'),
      userId: a.userId
    };
  });
  const userId = Storage.get('uid');
  return await post(route, { analysis: payload, userId: userId });
};

export const editPlans = async (payload: any, analysisId: string): Promise<any> => {
  const route = `/bond-insurance-analysis/edit-plans/${analysisId}`;

  return await post(route, payload);
};

export const updatePolicy = async (data: any): Promise<any> => {
  const route = `/bond-insurance/policies/update`;
  // const isCnpj =
  //   unmaskCpfOrCnpj(data.analysis?.bondInsurance?.ownerData?.document).length > 11;

  delete data.analysis.analysisAssurance;
  delete data.hiredData.validationMessage;
  const payload = {
    ...data,
    analysis: {
      ...data.analysis,
      value: +data.analysis.value.replace(/\D/g, '') / 100,
      createdAt: data.analysis.createdAt,
      insurerProposalCreatedAt: data.analysis.insurerProposalCreatedAt,
      bondInsurance: {
        ...data.analysis.bondInsurance,
        // ownerData: {
        //   name: data.analysis?.bondInsurance?.ownerData?.name,
        //   cpf: isCnpj
        //     ? undefined
        //     : unmaskCpfOrCnpj(data.analysis?.bondInsurance?.ownerData?.document),
        //   cnpj: !isCnpj
        //     ? undefined
        //     : unmaskCpfOrCnpj(data.analysis?.bondInsurance?.ownerData?.document),
        //   address: data.analysis?.bondInsurance?.ownerData?.address
        // }
      }
    },
    hiredData: {
      ...data.hiredData,
      hiredInstallmentsValue:
        +data?.hiredData?.hiredInstallmentsValue.replace(/\D/g, '') / 100,
      hiredInstallments: +data?.hiredData?.hiredInstallments,
      totalPremiumAmount: +data?.hiredData?.totalPremiumAmount?.replace(/\D/g, '') / 100
    }
  };
  return put(route, payload);
};

export const updateCanceledBondInsurance = async (
  id: string,
  data: EditModalUpdateCancelation
): Promise<any> => {
  const route = `/canceled-bond-insurances/edit/${id}`;
  const payload = {
    ...data,
    cancelation: {
      ...data.cancelation,
      notificationEmails: data?.cancelation?.notificationEmails?.join(';') || ''
    }
  };
  await put(route, payload);
  return { status: 200 };
};

function base64ToBuffer(str: string) {
  str = window.atob(str);
  const buffer = new ArrayBuffer(str.length),
    view = new Uint8Array(buffer);
  for (let i = 0; i < str.length; i++) {
    view[i] = str.charCodeAt(i);
  }
  return buffer;
}

async function downloadFiles(files: any[], tentantName: string) {
  const zip = new JSZip();
  files.forEach((file: any) => {
    const buffer = base64ToBuffer(file.content);
    zip.file(file.filename, buffer);
  });

  const link = document.createElement('a');
  zip.generateAsync({ type: 'blob' }).then(function (blob) {
    link.href = window.URL.createObjectURL(blob);
    link.download = `cartas_e_clausulas_${tentantName}.zip`;
    link.click();
  });
}

export const sendAnyLetter = async (analysisId: string): Promise<any> => {
  const route = `/bond-insurance-analysis/letter`;
  const payload = { analysisId };
  await post(route, payload);
  return { status: 200 };
};

export const downloadLetter = async (
  analysisId: string,
  tenantName: string
): Promise<any> => {
  const route = `/bond-insurance-analysis/letter/download`;
  const payload = { analysisId };
  const response = await post(route, payload);
  await downloadFiles(response, tenantName);
  return { status: 200 };
};

export const sendApprovalLetter = async (analysis: any): Promise<any> => {
  const route = `/bond-insurance-analysis/approval-letter`;
  const payload = {
    id: analysis.id,
    status: analysis.status,
    observations: analysis.observations,
    value: unmaskMoney(analysis.value || '0')
  };
  return await post(route, payload);
};

export const sendRefusalLetter = async (analysis: any): Promise<any> => {
  const route = `/bond-insurance-analysis/refusal-letter`;

  const payload = {
    id: analysis.id,
    status: analysis.status,
    observations: analysis.observations,
    value: unmaskMoney(analysis.value || '0')
  };

  return await post(route, payload);
};

export const sendPendingLetter = async (analysis: any): Promise<any> => {
  const route = `/bond-insurance-analysis/pending-letter`;

  const payload = {
    id: analysis.id,
    status: analysis.status,
    observations: analysis.observations,
    value: unmaskMoney(analysis.value || '0')
  };

  return await post(route, payload);
};

export const getBondInsuranceByID = async (
  ID: string
): Promise<BondInsuranceForGetBondInsurancesResponseDTO> => {
  return await get(`/bond-insurances/id/${ID}`);
};

export const getBondInsurances = async (
  page: number,
  filter?: any
): Promise<GetBondInsurancesResponseDTO> => {
  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  let response;
  try {
    response = await get(`/bond-insurances?${query}&page=${page}`);
  } catch (e) {
    console.error(e);
    response = [];
  }
  return response;
};

function formatSympatiseTenants(sympathise_tenants: SympathiseTenant[]) {
  const formated: SympathiseTenant[] = [];
  if (sympathise_tenants) {
    sympathise_tenants.forEach(tenant => {
      const tenantForPayload = {
        ...tenant,
        phone: unmaskPhoneNumber(tenant.phone),
        email: tenant.email,
        cpf: unmaskCpfOrCnpj(tenant.cpf),
        date_of_dispatch: tenant.date_of_dispatch
          ? FormatDateYMD(tenant.date_of_dispatch)
          : undefined,
        rg_emission_date: tenant.rg_emission_date
          ? FormatDateYMD(tenant.rg_emission_date)
          : undefined,
        birth_date: tenant.birth_date ? FormatDateYMD(tenant.birth_date) : undefined,
        rent: 0,
        other_incomes: tenant.other_incomes ? unmaskMoney(tenant.other_incomes) : 0,
        monthly_fixed_income: tenant.monthly_fixed_income
          ? unmaskMoney(tenant.monthly_fixed_income)
          : 0
      };

      const { close_to_politically_exposed_person } = tenant;

      if (
        close_to_politically_exposed_person &&
        close_to_politically_exposed_person.politically_exposed_person_cpf
      ) {
        tenantForPayload.close_to_politically_exposed_person.politically_exposed_person_cpf =
          unmaskCpfOrCnpj(
            close_to_politically_exposed_person.politically_exposed_person_cpf
          );
      }

      formated.push(tenantForPayload as unknown as SympathiseTenant);
    });
  }
  return formated;
}

function formatPartners(partners: Partner[]) {
  const formated: Partner[] = [];
  if (partners) {
    partners.forEach(partner => {
      formated.push({
        ...partner,
        document: unmaskCpfOrCnpj(partner.document),
        date_of_entry: FormatDateYMD(partner.date_of_entry)
      } as unknown as Partner);
    });
  }
  return formated;
}

export function sortBondInsurances<T>(
  data: Array<MatchBondInsurance<T>>,
  ascending: boolean,
  isCanceled?: boolean
): Array<MatchBondInsurance<T>> {
  if (isCanceled) {
    return data.sort((p1: MatchBondInsurance<T>, p2: MatchBondInsurance<T>): number => {
      const p1CreatedAt = moment(p1.canceled_at || new Date(0, 0, 0));
      const p2CreatedAt = moment(p2.canceled_at || new Date(0, 0, 0));
      if (p1CreatedAt.isSame(p2CreatedAt)) {
        return 0;
      } else {
        return ascending === p1CreatedAt.isAfter(p2CreatedAt) ? -1 : 1;
      }
    });
  }

  return data.sort((p1: MatchBondInsurance<T>, p2: MatchBondInsurance<T>): number => {
    const p1CreatedAt = moment(p1.created_at || new Date(0, 0, 0));
    const p2CreatedAt = moment(p2.created_at || new Date(0, 0, 0));
    if (p1CreatedAt.isSame(p2CreatedAt)) {
      return 0;
    } else {
      return ascending === p1CreatedAt.isAfter(p2CreatedAt) ? -1 : 1;
    }
  });
}

export function getPoliticallyExposedPersonLabel(
  politicallyExposedPersonEnum: string | undefined
) {
  if (!politicallyExposedPersonEnum) {
    return 'Indisponível';
  }

  switch (politicallyExposedPersonEnum) {
    case 'YES':
      return 'Sim';
    case 'NO':
      return 'Não';
    case 'CLOSE_TO':
      return 'Próximo';
    default:
      return 'Indisponível';
  }
}

type MatchBondInsurance<T> = T & { created_at: Date; canceled_at?: Date };
