import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { styles } from '../styles';
import TableHeader from './TableHeader';

const InsuredDataTable = ({ data }: any) => {
  return (
    <>
      <View style={styles.tableContainer}>
        <TableHeader title={'DADOS DO BENEFICIADO'} />

        <View style={styles.row}>
          <View style={styles.column}>
            <Text style={styles.label}>{'BENEFICIADO'}</Text>
            <Text style={styles.value}>{data.propertyOwnerName}</Text>
          </View>

          <View style={styles.column}>
            <Text style={styles.label}>{data.propertyOwnerIsPJ ? 'CNPJ' : 'CPF'}</Text>
            <Text style={styles.value}>{data.propertyOwnerDocument}</Text>
          </View>
        </View>
      </View>
    </>
  );
};

export default InsuredDataTable;
