import React, { SyntheticEvent, useState, useEffect } from "react";
import { FormikProps, Formik, Field } from "formik";
import styled from "styled-components";
import Input from "../../components/Input";
import { iRootDispatch, iRootState } from "../../store";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";
import Dropdown from "../../components/Dropdown";
import IconButton from "../../components/IconButton";
import Button from "../../components/Button";
import { getBudgets } from "../../services/fire-insurance-budget";
import { maskZipCode } from "../../services/masks";
import BrokersDropdown from "../../components/BrokersDropdown";
import { FilterData } from "./types";
import { EstatesToCustomDropDownOptions } from "../../util";
import { CustoDropDownItemProps } from "../../components/types";
import { FieldLabel } from "../../styles";
import { getUserFireInsuranceBudgets } from "../../services/user";
import { UserRolesEnum } from "../../enums/user-roles.enum";
import {
  AvailableBrokersAndEstatesState,
  FireInsuranceBudgetsListState,
} from "../../store/types/temp-types";

const AdvancedSearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  font-weight: bold;
`;

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user,
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateList: (state: FireInsuranceBudgetsListState) =>
    dispatch.fireInsuranceBudgetsList.updateFireInsuranceBudgetsList(state),
  updateAvailableBrokersAndEstates: (
    availableBrokers: AvailableBrokersAndEstatesState
  ) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(
      availableBrokers
    ),
});

type Props = ReturnType<typeof mapDispatch> &
  ReturnType<typeof mapState> &
  FormikProps<FilterData>;

const Filter = (props: Props) => {
  const {
    availableBrokersAndEstates,
    updateAvailableBrokersAndEstates,
    user,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const brokers: CustoDropDownItemProps[] = availableBrokersAndEstates.brokers;
  let defaultBrokerID = "";
  const estates: CustoDropDownItemProps[] = availableBrokersAndEstates.estates;
  let defaultEstateID = "";
  if (estates.length === 1) {
    defaultEstateID = estates[0].value.id;
  }
  if (brokers.length === 1) {
    defaultBrokerID = brokers[0].value.id;
  }

  return (
    <Formik
      initialValues={{
        property_zipcode: "",
        broker_id: defaultBrokerID,
        document: "",
        estate_id: defaultEstateID,
        property_code: "",
      }}
      onSubmit={async (values, actions) => {
        props.updateList({ isLoading: true, budgets: [] });
        const filter: FilterData = { ...values };
        if (filter.property_zipcode)
          filter.property_zipcode = filter.property_zipcode.replace("-", "");
        const data =
          user.role === UserRolesEnum.brokerAnalyst || user.role === UserRolesEnum.accountManager
            ? await getUserFireInsuranceBudgets(user.id, filter)
            : await getBudgets({ ...filter });
        props.updateList({ isLoading: false, budgets: data });
      }}
    >
      {(formikProps) => {
        return (
          <form onSubmit={formikProps.handleSubmit}>
            <Grid>
              <Grid.Row verticalAlign={"middle"}>
                <Grid.Column width={6}>
                  <FieldLabel>CORRETORA:</FieldLabel>
                  <Field
                    name="broker"
                    completeValue={true}
                    searchable={false}
                    onChange={(e: SyntheticEvent, data: any) => {
                      formikProps.setFieldValue("broker_id", data.value.id);
                      updateAvailableBrokersAndEstates({
                        brokers: availableBrokersAndEstates.brokers,
                        estates: EstatesToCustomDropDownOptions(
                          true,
                          data.value.estates || []
                        ),
                        insurers: availableBrokersAndEstates.insurers,
                        bondInsurers: availableBrokersAndEstates.bondInsurers,
                        roles: availableBrokersAndEstates.roles,
                        franchises: availableBrokersAndEstates.franchises
                      });
                    }}
                    component={BrokersDropdown}
                    fluid
                  />
                </Grid.Column>

                <Grid.Column width={7}>
                  <FieldLabel>IMOBILIÁRIA:</FieldLabel>
                  <Field
                    name="estate"
                    completeValue={false}
                    onChange={(e: SyntheticEvent, data: any) => {
                      formikProps.setFieldValue("estate_id", data.value.id);
                    }}
                    defaultValue={estates.length === 1 ? estates[0].value : ""}
                    disabled={estates.length === 1 || false}
                    options={availableBrokersAndEstates.estates || []}
                    component={Dropdown}
                    fluid
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <AdvancedSearchWrapper>
                    <label>Pesquisa Avançada</label>
                    <IconButton
                      name={isExpanded ? "chevron down" : "chevron up"}
                      color="blue"
                      onClick={(e: SyntheticEvent) => {
                        setIsExpanded(!isExpanded);
                      }}
                    />
                  </AdvancedSearchWrapper>
                </Grid.Column>
              </Grid.Row>
              {isExpanded && (
                <Grid.Row verticalAlign={"middle"}>
                  <Grid.Column width={4}>
                    <FieldLabel>CÓDIGO DO IMÓVEL:</FieldLabel>
                    <Field
                      name="property_code"
                      onChange={formikProps.handleChange}
                      component={Input}
                      fluid
                    />
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <FieldLabel>CPF/CNPJ:</FieldLabel>
                    <Field
                      name="document"
                      onChange={formikProps.handleChange}
                      component={Input}
                      fluid
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <FieldLabel>CEP:</FieldLabel>
                    <Field
                      name="property_zipcode"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const maskedValue = maskZipCode(e.target.value);
                        formikProps.setFieldValue(
                          "property_zipcode",
                          maskedValue
                        );
                      }}
                      component={Input}
                      fluid
                    />
                  </Grid.Column>
                  <Grid.Column width={3} textAlign={"center"}>
                    <Button
                      fluid
                      type={"submit"}
                      size={"mini"}
                      color="blue"
                      text="Pesquisar"
                      style={{ margin: "0.5em 0em" }}
                      onClick={() => formikProps.handleSubmit}
                    />
                    <Button
                      basic
                      fluid
                      type={"button"}
                      size={"mini"}
                      text="Limpar"
                      onClick={(e: SyntheticEvent) => {
                        formikProps.setValues({
                          property_zipcode: "",
                          estate_id: "",
                          broker_id: "",
                          document: "",
                          property_code: "",
                        });
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default connect(mapState, mapDispatch)(Filter);
