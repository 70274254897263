import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Dimmer,
  Dropdown,
  Form,
  Grid,
  Icon,
  Input,
  InputOnChangeData,
  Loader,
  Sticky
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as S from './styles';
import { FieldLabel } from '../../../../../styles';
import AppMaskedInput from '../../../../../components/MaskedInput';
import CustomDatePicker from '../../../../../components/CustomDatePicker';
import { iRootDispatch, iRootState } from '../../../../../store';
import { CurrentPageState } from '../../../../../store/types/temp-types';
import { monthsOptions } from './utils/months-options.util';
import { AddressService } from '../../../../../services/address';
import {
  dangerNotification,
  successNotification,
  warningNotification
} from '../../../../../services/notification';
import {
  PortoSeguroBudgetFormProps,
  PortoSeguroBudgetRequest,
  PortoSeguroBudgetResponse
} from './interfaces/portoseguro.budget.interface';
import {
  DATE_INPUT_MASK,
  maskCpfOrCnpj,
  maskPhoneNumber,
  maskZipCode,
  numberToMoneyString,
  unmaskCpfOrCnpj,
  unmaskPhoneNumber
} from '../../../../../services/masks';
import {
  EDITABLE_COVERAGE,
  FORM_INITIAL_VALUES,
  STREET_TYPE,
  PROPERTY_TYPE,
  RESIDENTIAL_FLAG,
  SEX_TYPE,
  MARITAL_STATUS
} from './constants/form.constant';
import { post } from '../../../../../services/request';
import { getDataByDocument } from '../../../../../services/documents';
import PortoSeguroCreatePolicyModal from './components/CreatePolicyModal';
import { PortoSeguroBudget } from './interfaces/portoseguro.proposal.interface';

const PortoSeguroBudgetForm = (props: PortoSeguroBudgetFormProps) => {
  const history = useHistory();
  const { estate, insurerId } = props;

  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [zipCodeLoading, setZipCodeLoading] = useState<boolean>(false);
  const [loadDocument, setLoadDocument] = useState<boolean>(false);
  const [isCPF, setIsCPF] = useState<boolean>(false);
  const [editableCoverage, setEditableCoverage] = useState<boolean[]>(EDITABLE_COVERAGE);
  const [budgetResponse, setBudgetResponse] = useState<PortoSeguroBudget>();
  const [openCreatePolicyModal, setOpenCreatePolicyModal] = useState<boolean>(false);

  const HandleAllEditableCoverage = (residentialFlag: string) => {
    setAllChecked(!allChecked);
    setEditableCoverage(prevState =>
      prevState.map((state: boolean, idx: number) => {
        if (idx === 0) {
          return state;
        }

        if (idx === 7 && residentialFlag !== 'S') {
          return state;
        }

        return allChecked;
      })
    );
  };

  const HandleEditableCoverage = (index: number) => {
    setEditableCoverage(prevState =>
      prevState.map((state: boolean, idx: number) => (idx === index ? !state : state))
    );
  };

  const handleFormSubmit = async (
    formValues: PortoSeguroBudgetRequest,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      formValues.estateId = estate.id;
      formValues.insurerId = insurerId;
      formValues.document = unmaskCpfOrCnpj(formValues.document);
      formValues.zipCode = formValues.zipCode.replace(/[^0-9]/g, '');
      formValues.cellphone = unmaskPhoneNumber(formValues.cellphone);

      await post('/fire-insurance-quotations/porto-seguro/budget', formValues)
        .then(response => {
          setBudgetResponse({ ...formValues, ...response });
          setOpenCreatePolicyModal(true);
        }
      );

      // setBudgetResponse({ ...formValues, ...response });

      // successNotification(
      //   'Seguro incendio',
      //   'Orçamento de seguro incendio criado com sucesso.',
      //   'bottom',
      //   7000
      // );
    } catch (error) {
      console.log(error);
      dangerNotification(
        'Erro ao criar orçamento de seguro incendio',
        'Por favor, tente novamente mais tarde ou contate o suporte.',
        7000
      );
    } finally {
      console.log(budgetResponse);
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    residentialFlag: Yup.string().test('residentialFlag', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo tipo do imóvel é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    flagProponentOwner: Yup.string().test('flagProponentOwner', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo proponente é proprietário é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    initialEffectiveDate: Yup.string().test('initialEffectiveDate', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo inicio da vigência é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    monthsValidity: Yup.string().test('monthsValidity', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo meses de vigência é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    document: Yup.string().test('document', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo documento é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    proponentName: Yup.string().test('proponentName', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo nome do proponente é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    birthdate: Yup.string().test('birthdate', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo data de nascimento é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    sexType: Yup.string().test('sexType', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo gênero é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    maritalStatus: Yup.string().test('maritalStatus', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo estado civil é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    proponentEmail: Yup.string().test('proponentEmail', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo email é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    cellphone: Yup.string().test('cellphone', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo telefone é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    zipCode: Yup.string().test('zipCode', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo CEP é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    city: Yup.string().test('city', 'error', value => {
      if (!value || value === '0,0') {
        warningNotification(
          'O campo cidade é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    district: Yup.string().test('district', 'error', value => {
      if (!value || value === '0,0') {
        warningNotification(
          'O campo bairro é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    state: Yup.string().test('state', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo estado é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    streetType: Yup.string().test('streetType', 'error', value => {
      if (!value || value === '0,0') {
        warningNotification(
          'O campo tipo de logradouro é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    street: Yup.string().test('street', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo logradouro é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    number: Yup.string().test('number', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo número do imóvel é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    codePropertyType: Yup.string().test('codePropertyType', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo tipo de propriedade é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    })
  });

  return (
    <>
      <Formik
        initialValues={FORM_INITIAL_VALUES}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(formValues, formikActions) =>
          handleFormSubmit(formValues, formikActions.setSubmitting)
        }
      >
        {({
          values,
          errors,
          setFieldError,
          touched,
          setFieldTouched,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting
        }) => {
          const onChangeZipCode = (zipCode: string): void => {
            setZipCodeLoading(true);

            const maskedValue = maskZipCode(zipCode);
            setFieldValue('zipCode', maskedValue);

            if (maskedValue.length >= 9) {
              AddressService.getAddressByZipCode(zipCode)
                .then(address => {
                  setFieldValue('city', address.city);
                  setFieldValue('district', address.district);
                  setFieldValue('state', address.state);
                  setFieldValue('street', address.street);

                  if (address.street) {
                    const streetType = address.street
                      .split(' ')
                      ?.shift()
                      ?.toLocaleUpperCase();
                    const item = STREET_TYPE.find(item => item.text === streetType);

                    setFieldValue('streetType', item?.key);
                  }
                })
                .catch(() => {
                  dangerNotification('CEP invalido!', 'Por favor, digite um CEP valido.');
                });
            }

            setZipCodeLoading(false);
          };

          const onChangeCoverage = (field: string, valueMask: string): void => {
            const onlyNumber = valueMask.replace(/\D/g, '');
            const value = Number(onlyNumber) / 100;

            setFieldValue(field, value);
          };

          const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(event.target.name, event.target.value);

            if (event.target.value.length === 1) {
              setFieldTouched(event.target.name as any, false, false);
            }
          };

          const onBlurDocument = async (document: string) => {
            try {
              setLoadDocument(true);

              const unformattedDoc = document.replace(/\D/g, '');
              const isCPF = unformattedDoc.length === 11;
              const data = await getDataByDocument(unformattedDoc);

              if (isCPF) {
                setIsCPF(true);
                const sexType = data.gender === 'MASCULINO' ? 'M' : 'F';

                setFieldValue('proponentName', data?.name ?? '');
                setFieldValue('birthdate', new Date(data?.birth_date));
                setFieldValue('sexType', sexType ?? '');
              } else {
                setIsCPF(false);
                setFieldValue('proponentName', data?.company ?? '');
                setFieldValue('cellphone', data?.phone ?? '');
                setFieldValue('proponentEmail', data?.email ?? '');
                setFieldValue('birthdate', new Date(data?.foundation_date));
                setFieldValue('sexType', null);
                setFieldValue('maritalStatus', null);
              }
            } catch (err) {
              console.log(err);
            } finally {
              setLoadDocument(false);
            }
          };

          const w100 = {
            width: '100%'
          };

          const columnDistance = {
            marginBottom: 20
          };

          const flexCenter = {
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: 0,
            paddingRight: 0
          };

          return (
            <>
              <Dimmer active={isSubmitting} inverted page>
                <Loader indeterminate> Gerando orçamento... </Loader>
              </Dimmer>

              <Form id={'ConfirmRenewRequestForm'} onSubmit={handleSubmit}>
                <S.Container>
                  <Grid style={w100}>
                    <Grid.Column
                      computer={8}
                      mobile={16}
                      tablet={16}
                      style={columnDistance}
                    >
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={16}>
                            <S.Subtitle>Dados para cotação</S.Subtitle>
                          </Grid.Column>
                          <Grid.Column width={16}>
                            <S.Hr />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>IMÓVEL RESIDENCIAL*</FieldLabel>
                            <Dropdown
                              fluid
                              search
                              clearable
                              selection
                              name={'residentialFlag'}
                              placeholder={'Tipo de imóvel'}
                              options={RESIDENTIAL_FLAG}
                              value={values.residentialFlag}
                              onChange={(_event, { value }) => {
                                setFieldValue('residentialFlag', value);
                                setFieldTouched('residentialFlag', false, false);
                              }}
                              error={
                                touched.residentialFlag &&
                                errors.residentialFlag === 'error'
                              }
                            />
                          </Grid.Column>

                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>TIPO DE OCUPAÇÃO*</FieldLabel>
                            <Dropdown
                              fluid
                              search
                              clearable
                              selection
                              name={'codePropertyType'}
                              placeholder={'Selecione o tipo do imóvel'}
                              options={PROPERTY_TYPE.filter(item => {
                                if (values.residentialFlag === 'N') return item.key <= 4;
                                if (values.residentialFlag === 'S') return item.key >= 5;
                              })}
                              value={values.codePropertyType}
                              onChange={(_event, { value }) => {
                                setFieldValue('codePropertyType', value);
                                setFieldTouched('codePropertyType', false, false);
                              }}
                              error={
                                touched.codePropertyType &&
                                errors.codePropertyType === 'error'
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel>INÍCIO DE VIGÊNCIA*</FieldLabel>
                            <S.DatePickerWrapper>
                              <CustomDatePicker
                                name={'initialEffectiveDate'}
                                dateFormat={'dd/MM/yyyy'}
                                placeholderText={'Data Inicial'}
                                selected={values.initialEffectiveDate ?? undefined}
                                customInput={
                                  <AppMaskedInput
                                    mask={DATE_INPUT_MASK}
                                    error={errors.initialEffectiveDate === 'error'}
                                  />
                                }
                                onChange={(date: Date) => {
                                  setFieldValue(
                                    'initialEffectiveDate',
                                    date ?? undefined
                                  );
                                  setFieldError('initialEffectiveDate', '');
                                }}
                              />
                            </S.DatePickerWrapper>
                          </Grid.Column>

                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>MESES DE VIGÊNCIA*</FieldLabel>
                            <Dropdown
                              fluid
                              search
                              clearable
                              selection
                              name={'monthsValidity'}
                              placeholder={'Número de Meses'}
                              options={monthsOptions()}
                              value={values.monthsValidity}
                              onChange={(_event, { value }) => {
                                setFieldValue('monthsValidity', value);
                                setFieldTouched('monthsValidity', false, false);
                              }}
                              error={
                                touched.monthsValidity &&
                                errors.monthsValidity === 'error'
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>
                              PROPONENTE É PROPRIETÁRIO DO IMÓVEL*
                            </FieldLabel>
                            <Dropdown
                              fluid
                              search
                              clearable
                              selection
                              name={'flagProponentOwner'}
                              placeholder={'Selecione uma opção...'}
                              options={RESIDENTIAL_FLAG}
                              value={values.flagProponentOwner}
                              onChange={(_event, { value }) => {
                                setFieldValue('flagProponentOwner', value);
                                setFieldTouched('flagProponentOwner', false, false);
                              }}
                              error={
                                touched.flagProponentOwner &&
                                errors.flagProponentOwner === 'error'
                              }
                            />
                          </Grid.Column>

                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>VALOR DO ALUGUEL</FieldLabel>
                            <Input
                              fluid
                              placeholder={'Valor do Aluguel'}
                              value={numberToMoneyString(values.rentValue)}
                              onChange={event =>
                                onChangeCoverage('rentValue', event.target.value)
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={16}>
                            <S.SecctionName>Dados do proponente</S.SecctionName>
                          </Grid.Column>
                          <Grid.Column width={16}>
                            <S.Hr />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>CPF/CNPJ*</FieldLabel>
                            <Input
                              fluid
                              name={'document'}
                              placeholder={'Documento do proponente'}
                              value={maskCpfOrCnpj(values.document)}
                              onChange={handleOnChange}
                              onBlur={() => onBlurDocument(values.document)}
                              error={touched.document && errors.document === 'error'}
                            />
                          </Grid.Column>

                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>NOME*</FieldLabel>
                            <Input
                              fluid
                              name="proponentName"
                              placeholder={'Nome do proponente'}
                              value={values.proponentName}
                              onChange={handleOnChange}
                              error={
                                touched.proponentName && errors.proponentName === 'error'
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>

                        {isCPF && (
                          <Grid.Row>
                            <Grid.Column
                              computer={8}
                              mobile={16}
                              tablet={16}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>GÊNERO*</FieldLabel>
                              <Dropdown
                                fluid
                                clearable
                                selection
                                name={'sexType'}
                                options={SEX_TYPE}
                                placeholder={'Selecione o gênero do proponente'}
                                loading={loadDocument}
                                value={values.sexType}
                                onChange={(_event, { value }) => {
                                  setFieldValue('sexType', value);
                                  setFieldTouched('sexType', false, false);
                                }}
                                error={touched.sexType && errors.sexType === 'error'}
                              />
                            </Grid.Column>

                            <Grid.Column
                              computer={8}
                              mobile={16}
                              tablet={16}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>ESTADO CIVIL*</FieldLabel>
                              <Dropdown
                                fluid
                                clearable
                                selection
                                name={'maritalStatus'}
                                options={MARITAL_STATUS}
                                placeholder={'Selecione o estado civil do proponente'}
                                loading={loadDocument}
                                value={values.maritalStatus}
                                onChange={(_event, { value }) => {
                                  setFieldValue('maritalStatus', value);
                                  setFieldTouched('maritalStatus', false, false);
                                }}
                                error={
                                  touched.maritalStatus &&
                                  errors.maritalStatus === 'error'
                                }
                              />
                            </Grid.Column>
                          </Grid.Row>
                        )}

                        <Grid.Row>
                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel>
                              {isCPF
                                ? 'DATA DE NASCIMENTO*'
                                : 'DATA DE FUNDAÇÃO DA EMPRESA*'}
                            </FieldLabel>
                            <S.DatePickerWrapper>
                              <CustomDatePicker
                                name={'birthdate'}
                                dateFormat={'dd/MM/yyyy'}
                                placeholderText={
                                  isCPF
                                    ? 'Data de nascimento'
                                    : 'Data de fundação da empresa'
                                }
                                selected={values.birthdate ?? undefined}
                                customInput={
                                  <AppMaskedInput
                                    mask={DATE_INPUT_MASK}
                                    error={errors.birthdate === 'error'}
                                  />
                                }
                                onChange={(date: Date) => {
                                  setFieldValue('birthdate', date ?? undefined);
                                  setFieldError('birthdate', '');
                                }}
                              />
                            </S.DatePickerWrapper>
                          </Grid.Column>

                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>EMAIL*</FieldLabel>
                            <Input
                              fluid
                              name={'proponentEmail'}
                              placeholder={'Email do proponente'}
                              loading={loadDocument}
                              value={values.proponentEmail}
                              onChange={handleOnChange}
                              error={
                                touched.proponentEmail &&
                                errors.proponentEmail === 'error'
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>CELULAR*</FieldLabel>
                            <Input
                              fluid
                              name={'cellphone'}
                              placeholder={'Telefone do proponente'}
                              loading={loadDocument}
                              value={maskPhoneNumber(values.cellphone)}
                              onChange={handleOnChange}
                              error={touched.cellphone && errors.cellphone === 'error'}
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={16}>
                            <S.SecctionName>Endereço do imóvel</S.SecctionName>
                          </Grid.Column>
                          <Grid.Column width={16}>
                            <S.Hr />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>CEP*</FieldLabel>
                            <Input
                              fluid
                              name={'zipCode'}
                              placeholder={'Digite o CEP'}
                              value={maskZipCode(values.zipCode)}
                              onChange={(_event, data: InputOnChangeData) => {
                                onChangeZipCode(data.value);
                                setFieldTouched('zipCode', false, false);
                              }}
                              error={touched.zipCode && errors.zipCode === 'error'}
                            />
                          </Grid.Column>

                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>TIPO LOGRADOURO*</FieldLabel>
                            <Dropdown
                              fluid
                              search
                              clearable
                              selection
                              name={'streetType'}
                              options={STREET_TYPE}
                              placeholder={'Selecione o tipo de logradouro'}
                              loading={zipCodeLoading}
                              value={values.streetType}
                              onChange={(_event, { value }) => {
                                setFieldValue('streetType', value);
                                setFieldTouched('streetType', false, false);
                              }}
                              error={touched.streetType && errors.streetType === 'error'}
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>LOGRADOURO*</FieldLabel>
                            <Input
                              fluid
                              name={'street'}
                              placeholder={'Digite o logradouro'}
                              loading={zipCodeLoading}
                              value={values.street}
                              onChange={handleOnChange}
                              error={touched.street && errors.street === 'error'}
                            />
                          </Grid.Column>

                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>BAIRRO*</FieldLabel>
                            <Input
                              fluid
                              name={'district'}
                              placeholder={'Digite o bairro'}
                              loading={zipCodeLoading}
                              value={values.district}
                              onChange={handleOnChange}
                              error={touched.district && errors.district === 'error'}
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>CIDADE*</FieldLabel>
                            <Input
                              fluid
                              name={'city'}
                              placeholder={'Digite a cidade'}
                              loading={zipCodeLoading}
                              value={values.city}
                              onChange={handleOnChange}
                              error={touched.city && errors.city === 'error'}
                            />
                          </Grid.Column>

                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>ESTADO*</FieldLabel>
                            <Input
                              fluid
                              name={'state'}
                              placeholder={'Digite o estado'}
                              loading={zipCodeLoading}
                              value={values.state}
                              onChange={handleOnChange}
                              error={touched.state && errors.state === 'error'}
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column computer={4} mobile={16} tablet={8} style={columnDistance}>
                            <FieldLabel paddingBottom>NÚMERO*</FieldLabel>
                            <Input
                              fluid
                              type={'number'}
                              name={'number'}
                              placeholder={'Digite o número'}
                              value={values.number}
                              onChange={handleChange}
                            />
                          </Grid.Column>

                          <Grid.Column computer={12} mobile={16} tablet={8} style={columnDistance}>
                            <FieldLabel paddingBottom>COMPLEMENTO</FieldLabel>
                            <Input
                              fluid
                              name={'complement'}
                              placeholder={'Complemento...'}
                              value={values.complement}
                              onChange={handleChange}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>

                    <Grid.Column
                      computer={8}
                      mobile={16}
                      tablet={16}
                      style={columnDistance}
                    >
                      <Sticky offset={80}>
                        <S.PreQuoteWrapper>
                          <S.PreQuoteHeader>
                            <span className="left">
                              <Icon name="shield" />
                              Orçamento de Seguro
                            </span>
                          </S.PreQuoteHeader>

                          <Grid style={{ height: 'calc(100% - 60px)', margin: 0 }}>
                            <S.SecctionName>Coberturas disponíveis</S.SecctionName>

                            <S.CoveragesRow>
                              <div>
                                <span className="insurance-name">
                                  <Icon name="shield" className="low-opacity" /> Incêndio
                                  &nbsp;
                                  <span className="required">(Obrigatório)</span>
                                </span>
                              </div>

                              <div>
                                {editableCoverage[0] ? (
                                  <span className="insurance-value">
                                    {numberToMoneyString(values.fireCoverageValue)}
                                    <Icon
                                      name="pencil"
                                      color="black"
                                      className="low-opacity pencil-icon"
                                      onClick={() => HandleEditableCoverage(0)}
                                    />
                                  </span>
                                ) : (
                                  <div className="editable-coverages">
                                    <Input
                                      fluid
                                      className="editable-coverages-input"
                                      value={numberToMoneyString(
                                        values.fireCoverageValue
                                      )}
                                      onChange={event =>
                                        onChangeCoverage(
                                          'fireCoverageValue',
                                          event.target.value
                                        )
                                      }
                                    />
                                    <Icon
                                      className="icon"
                                      name="check"
                                      color="green"
                                      onClick={() => HandleEditableCoverage(0)}
                                    />
                                  </div>
                                )}
                              </div>
                            </S.CoveragesRow>

                            <S.CoveragesRow>
                              <div
                                className="low-opacity"
                                onClick={() =>
                                  HandleAllEditableCoverage(values.residentialFlag)
                                }
                              >
                                <span style={{ cursor: 'pointer' }}>
                                  <input type="checkbox" checked={allChecked} />
                                  Habilitar/Desabilitar todas as coberturas adicionais
                                </span>
                              </div>
                            </S.CoveragesRow>

                            <S.CoveragesRow>
                              <div>
                                <span className={'full-insurance-name'}>
                                  <input
                                    type="checkbox"
                                    onClick={() => HandleEditableCoverage(1)}
                                    checked={values.civilLiabilityCoverageValue > 0}
                                  />{' '}
                                  Cobertura responsabilidade civil
                                </span>
                              </div>

                              <div>
                                {editableCoverage[1] ? (
                                  <>
                                    <span className="insurance-value">
                                      {numberToMoneyString(
                                        values.civilLiabilityCoverageValue
                                      )}
                                    </span>
                                    <Icon
                                      name="pencil"
                                      className="low-opacity  pencil-icon"
                                      onClick={() => HandleEditableCoverage(1)}
                                    />
                                  </>
                                ) : (
                                  <div className="editable-coverages">
                                    <Input
                                      fluid
                                      className="editable-coverages-input"
                                      value={numberToMoneyString(
                                        values.civilLiabilityCoverageValue
                                      )}
                                      onChange={event =>
                                        onChangeCoverage(
                                          'civilLiabilityCoverageValue',
                                          event.target.value
                                        )
                                      }
                                    />
                                    <Icon
                                      className="icon"
                                      name="check"
                                      color="green"
                                      onClick={() => HandleEditableCoverage(1)}
                                    />
                                  </div>
                                )}
                              </div>
                            </S.CoveragesRow>

                            <S.CoveragesRow>
                              <div>
                                <span className={'full-insurance-name'}>
                                  <input
                                    type="checkbox"
                                    onClick={() => HandleEditableCoverage(2)}
                                    checked={values.coverageContentValue > 0}
                                  />
                                  Cobertura de conteúdo
                                </span>
                              </div>

                              <div>
                                {editableCoverage[2] ? (
                                  <>
                                    <span className="insurance-value">
                                      {numberToMoneyString(values.coverageContentValue)}
                                    </span>
                                    <Icon
                                      name="pencil"
                                      className="low-opacity  pencil-icon"
                                      onClick={() => HandleEditableCoverage(2)}
                                    />
                                  </>
                                ) : (
                                  <div className="editable-coverages">
                                    <Input
                                      fluid
                                      className="editable-coverages-input"
                                      value={numberToMoneyString(
                                        values.coverageContentValue
                                      )}
                                      onChange={event =>
                                        onChangeCoverage(
                                          'coverageContentValue',
                                          event.target.value
                                        )
                                      }
                                    />
                                    <Icon
                                      className="icon"
                                      name="check"
                                      color="green"
                                      onClick={() => HandleEditableCoverage(2)}
                                    />
                                  </div>
                                )}
                              </div>
                            </S.CoveragesRow>

                            <S.CoveragesRow>
                              <div>
                                <span className={'full-insurance-name'}>
                                  <input
                                    type="checkbox"
                                    onClick={() => HandleEditableCoverage(3)}
                                    checked={values.electricalDamageCoverageValue > 0}
                                  />
                                  Cobertura danos elétricos
                                </span>
                              </div>

                              <div>
                                {editableCoverage[3] ? (
                                  <>
                                    <span className="insurance-value">
                                      {numberToMoneyString(
                                        values.electricalDamageCoverageValue
                                      )}
                                    </span>
                                    <Icon
                                      name="pencil"
                                      className="low-opacity  pencil-icon"
                                      onClick={() => HandleEditableCoverage(3)}
                                    />
                                  </>
                                ) : (
                                  <div className="editable-coverages">
                                    <Input
                                      fluid
                                      className="editable-coverages-input"
                                      value={numberToMoneyString(
                                        values.electricalDamageCoverageValue
                                      )}
                                      onChange={event =>
                                        onChangeCoverage(
                                          'electricalDamageCoverageValue',
                                          event.target.value
                                        )
                                      }
                                    />
                                    <Icon
                                      className="icon"
                                      name="check"
                                      color="green"
                                      onClick={() => HandleEditableCoverage(3)}
                                    />
                                  </div>
                                )}
                              </div>
                            </S.CoveragesRow>

                            <S.CoveragesRow>
                              <div>
                                <span className={'full-insurance-name'}>
                                  <input
                                    type="checkbox"
                                    onClick={() => HandleEditableCoverage(3)}
                                    checked={
                                      values.coverageImpactsAirLandVehiclesValue > 0
                                    }
                                  />
                                  Cobertura por impactos de veículos terrestres ou aéreos
                                </span>
                              </div>

                              <div>
                                {editableCoverage[4] ? (
                                  <>
                                    <span className="insurance-value">
                                      {numberToMoneyString(
                                        values.coverageImpactsAirLandVehiclesValue
                                      )}
                                    </span>
                                    <Icon
                                      name="pencil"
                                      className="low-opacity  pencil-icon"
                                      onClick={() => HandleEditableCoverage(4)}
                                    />
                                  </>
                                ) : (
                                  <div className="editable-coverages">
                                    <Input
                                      fluid
                                      className="editable-coverages-input"
                                      value={numberToMoneyString(
                                        values.coverageImpactsAirLandVehiclesValue
                                      )}
                                      onChange={event =>
                                        onChangeCoverage(
                                          'coverageImpactsAirLandVehiclesValue',
                                          event.target.value
                                        )
                                      }
                                    />
                                    <Icon
                                      className="icon"
                                      name="check"
                                      color="green"
                                      onClick={() => HandleEditableCoverage(4)}
                                    />
                                  </div>
                                )}
                              </div>
                            </S.CoveragesRow>

                            <S.CoveragesRow>
                              <div>
                                <span className={'full-insurance-name'}>
                                  <input
                                    type="checkbox"
                                    onClick={() => HandleEditableCoverage(5)}
                                    checked={values.rentalLossCoverageValue > 0}
                                  />
                                  Cobertura por perda de aluguel
                                </span>
                              </div>

                              <div>
                                {editableCoverage[5] ? (
                                  <>
                                    <span className="insurance-value">
                                      {numberToMoneyString(
                                        values.rentalLossCoverageValue
                                      )}
                                    </span>
                                    <Icon
                                      name="pencil"
                                      className="low-opacity pencil-icon"
                                      onClick={() => HandleEditableCoverage(5)}
                                    />
                                  </>
                                ) : (
                                  <div className="editable-coverages">
                                    <Input
                                      fluid
                                      className="editable-coverages-input"
                                      value={numberToMoneyString(
                                        values.rentalLossCoverageValue
                                      )}
                                      onChange={event =>
                                        onChangeCoverage(
                                          'rentalLossCoverageValue',
                                          event.target.value
                                        )
                                      }
                                    />
                                    <Icon
                                      className="icon"
                                      name="check"
                                      color="green"
                                      onClick={() => HandleEditableCoverage(5)}
                                    />
                                  </div>
                                )}
                              </div>
                            </S.CoveragesRow>

                            <S.CoveragesRow>
                              <div>
                                <span className={'full-insurance-name'}>
                                  <input
                                    type="checkbox"
                                    onClick={() => HandleEditableCoverage(6)}
                                    checked={values.galeCoverageValue > 0}
                                  />
                                  Cobertura por vendaval
                                </span>
                              </div>

                              <div>
                                {editableCoverage[6] ? (
                                  <>
                                    <span className="insurance-value">
                                      {numberToMoneyString(values.galeCoverageValue)}
                                    </span>
                                    <Icon
                                      name="pencil"
                                      className="low-opacity pencil-icon"
                                      onClick={() => HandleEditableCoverage(6)}
                                    />
                                  </>
                                ) : (
                                  <div className="editable-coverages">
                                    <Input
                                      fluid
                                      className="editable-coverages-input"
                                      value={numberToMoneyString(
                                        values.galeCoverageValue
                                      )}
                                      onChange={event =>
                                        onChangeCoverage(
                                          'galeCoverageValue',
                                          event.target.value
                                        )
                                      }
                                    />
                                    <Icon
                                      className="icon"
                                      name="check"
                                      color="green"
                                      onClick={() => HandleEditableCoverage(6)}
                                    />
                                  </div>
                                )}
                              </div>
                            </S.CoveragesRow>

                            <S.CoveragesRow>
                              <div>
                                <span
                                  className={`full-insurance-name ${
                                    values.residentialFlag !== 'S' && 'low-opacity'
                                  }`}
                                >
                                  <input
                                    type="checkbox"
                                    disabled={values.residentialFlag !== 'S'}
                                    onClick={() => HandleEditableCoverage(7)}
                                    checked={values.riotCoverageValue > 0}
                                  />
                                  Cobertura por tumulto
                                </span>
                              </div>

                              <div>
                                {editableCoverage[7] ? (
                                  <>
                                    <span className="insurance-value">
                                      {numberToMoneyString(values.riotCoverageValue)}
                                    </span>
                                    <Icon
                                      name="pencil"
                                      disabled={values.residentialFlag !== 'S'}
                                      className="low-opacity pencil-icon"
                                      onClick={() => HandleEditableCoverage(7)}
                                    />
                                  </>
                                ) : (
                                  <div className="editable-coverages">
                                    <Input
                                      fluid
                                      className="editable-coverages-input"
                                      value={numberToMoneyString(
                                        values.riotCoverageValue
                                      )}
                                      onChange={event =>
                                        onChangeCoverage(
                                          'riotCoverageValue',
                                          event.target.value
                                        )
                                      }
                                    />
                                    <Icon
                                      className="icon"
                                      name="check"
                                      color="green"
                                      onClick={() => HandleEditableCoverage(7)}
                                    />
                                  </div>
                                )}
                              </div>
                            </S.CoveragesRow>

                            <S.Summarysection>
                              <div className="buttons">
                                <Button primary submit>
                                  <Icon name="clipboard check" />
                                  Criar Orçamento
                                </Button>
                              </div>
                            </S.Summarysection>
                          </Grid>
                        </S.PreQuoteWrapper>
                      </Sticky>
                    </Grid.Column>
                  </Grid>
                </S.Container>
              </Form>
            </>
          );
        }}
      </Formik>

      <PortoSeguroCreatePolicyModal
        open={openCreatePolicyModal}
        onClose={() => setOpenCreatePolicyModal(false)}
        fireInsuranceBudget={budgetResponse as PortoSeguroBudget}
      />
    </>
  );
};

const mapState = (state: iRootState) => {
  return {};
};

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateCurrentPage: (state: CurrentPageState) =>
    dispatch.currentPage.updateCurrentPage(state)
});

export default connect(mapState, mapDispatch)(PortoSeguroBudgetForm);
