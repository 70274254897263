import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Checkbox, Icon, Dimmer, Loader, Segment } from 'semantic-ui-react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

import { Timeline, TimelineEvent } from 'react-event-timeline';
import { getMessages, sendMessage, updateViewed } from '../../services/generalfollowup';
import Button from '../Button';
import { get } from '../../services/storage';

import { DivFollowup, MessageContainer } from './styles';
import {
  dangerNotification,
  successNotification,
  warningNotification
} from '../../services/notification';
import { UserRolesEnum } from '../../enums/user-roles.enum';
import { ActionEnum } from '../../enums/authz-action.enum';
import { FeatureEnum } from '../../enums/authz-feature.enum';
import { hasPermission } from '../AuthorizationContainer';

export type MessageType = {
  id?: string;
  message: string;
  senderId: {
    id: string;
    name: string;
    role: string;
  };
  viewedBy: string;
  involved: string;
  createdAt: moment.MomentInput;
};

export type FollowUpProps = {
  messages: MessageType[];
  id: string;
  context: string;
  estateId?: string;
  bondInsuranceId?: string;
  relatedId?: string;
  isBlocked?: boolean;
  involved: string;
  viewedBy: string;
  isInternal: boolean;
};

const FollowUp = (props: FollowUpProps) => {
  const { id, relatedId, involved, context, viewedBy, isInternal } = props;
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState(props.messages);
  const [message, setMessage] = useState('');
  const userId = get('uid');
  const userName = get('name');
  const userRole = get('role');
  const [checked, setChecked] = useState(false);
  const [forAll, setForAll] = useState(false);

  const confiaxUsers = [
    UserRolesEnum.brokerAdmin,
    UserRolesEnum.brokerAnalyst,
    UserRolesEnum.accountManager,
    UserRolesEnum.cancelationAnalyst,
    UserRolesEnum.claimAnalyst
  ];

  const [userCanSendMessage, setUserCanSendMessage] = useState<boolean>(false);

  useEffect(() => {
    const has = hasPermission(ActionEnum.create, FeatureEnum.followups);
    setUserCanSendMessage(has);
  }, []);

  useEffect(() => {
    const notSeenYet = messages.filter((message: any) => {
      const viewedBy = message.viewedBy?.split(',');
      if (!viewedBy?.includes(userId)) return message;
    });

    setChecked(notSeenYet?.length === 0)

    if (confiaxUsers.includes(userRole as UserRolesEnum)) {
      return;
    }

    notSeenYet?.length > 0 &&
      successNotification('Lido', `${notSeenYet?.length} mensagens marcadas como lidas!`);
    for (const message of notSeenYet) {
      if (message.id && userId) {
        const newViewedBy = message?.viewedBy + ',' + userId;
        updateViewed(message.id, newViewedBy);
      }
    }
  }, [props.messages, userRole]);

  const handleMakeRead = async (message: MessageType) => {
    const viewedBy = message.viewedBy;
    try {
      setLoading(true)
      if (message.id && userId) {
        const viewed = (viewedBy.includes(userId) && checked) ? viewedBy.replace(userId + ',', '') : viewedBy + ',' + userId;
        await updateViewed(message?.id, viewed);
      }
    } catch (error) {
      console.log('ERRO AO ATUALIZAR MENSAGEM (General):', error);
      dangerNotification(
        'Oops...',
        'Não foi possível atualizar a mensagem, tente novamente mais tarde.'
      );
      setLoading(false)
    } finally {
      if (userId && viewedBy.includes(userId) && checked) {
        warningNotification('Não Lida', `Mensagem marcada como não-lida.`)
      } else {
        successNotification('Lido', `Mensagem marcada como lida.`);
      }
      const response = await getMessages({ id, context, isInternal });
      setMessages(response);
      setLoading(false)
    }
  }

  const handleMakeAllRead = async (messages: MessageType[]) => {
    try {
      setLoading(true)
      let pos = 0
      for (const message of messages) {
        if (message.id && userId) {
          // const canRemoveAll = (forAll && checked) canRemoveAll ? message.senderId.id : (
          const viewed = checked ? viewedBy.replace(userId, '') : viewedBy + ',' + userId;
          await updateViewed(message?.id, viewed);
        }
        pos++;
      }
    } catch (error) {
      console.log('ERRO AO ATUALIZAR AS MENSAGENS:', error);
      dangerNotification(
        'Oops...',
        'Não foi possível atualizar as mensagens, tente novamente mais tarde.'
      );
      setLoading(false)
    } finally {
      if (!checked) {
        setChecked(true)
        successNotification('Lidas', `${messages?.length} mensagens marcadas como lidas!`);
      } else {
        setChecked(false)
        warningNotification('Não Lidas', `${messages?.length} mensagens marcadas como não-lidas!`)
      }
      const response = await getMessages({ id, context, isInternal });
      setMessages(response);
      setLoading(false)
    }
  }

  const handleSubmit = async () => {
    if (!message.trim() || !message || message.length < 1) {
      warningNotification('Oops...', 'Por favor, digite uma mensagem antes de enviar.');
    } else {
      try {
        setLoading(true);
        await sendMessage(
          relatedId!,
          userId!,
          message,
          userName!,
          context,
          involved,
          viewedBy,
          id,
          isInternal
        );
        const response = await getMessages({ id, context, isInternal });
        setMessages([...response]);
        successNotification('Sucesso!', 'Mensagem enviada!');
      } catch (error) {
        console.log('ERRO AO ENVIAR MENSAGEM (General):', error);
        dangerNotification(
          'Oops...',
          'Não foi possível enviar a mensagem, tente novamente mais tarde.'
        );
      } finally {
        setMessage('');
        setLoading(false);
      }
    }
  };

  const modules = {
    toolbar: [
      [{ align: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ size: ['normal', 'large', 'huge'] }],
      [{ color: [] }, { background: [] }]
    ]
  };

  return (
    <DivFollowup>
      <MessageContainer>
        <div className="quilljs">
          <ReactQuill
            theme="snow"
            modules={modules}
            value={message}
            onChange={value => setMessage(value)}
            readOnly={loading || !userCanSendMessage || props.isBlocked}
          />
        </div>

        <div>
          <Button
            primary
            content="Enviar"
            labelPosition="right"
            icon="send"
            onClick={handleSubmit}
            loading={loading}
            disabled={loading || !userCanSendMessage || props.isBlocked}
          />
        </div>
      </MessageContainer>

      <Dimmer.Dimmable as={Segment} dimmed={loading}>
        <Dimmer active={loading} inverted>
          <Loader />
        </Dimmer>
        <div style={{ overflowY: 'scroll' }}>
          {confiaxUsers.includes(userRole as UserRolesEnum) && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingRight: '24px', width: '100%' }}>
            <Checkbox label={checked ? 'Marcar todas como Não-lidas' : 'Marcar todas como Lidas'} checked={checked} onClick={() => handleMakeAllRead(messages)} />
            {/* {checked && <Checkbox toggle label='Para Todos' checked={forAll} onClick={() => setForAll(!forAll)} style={{ paddingTop: '16px' }} />} */}
          </div>}
          <Timeline>
            {messages.map((message: MessageType) => {
              console.log('MONTANDO', messages);
              return (
                <TimelineEvent
                  key={message.id}
                  title={message.senderId?.name ? message.senderId?.name : userName}
                  createdAt={moment(message.createdAt).format('DD/MM/YY - HH:mm')}
                  icon={<Icon name="envelope open outline" size="large" />}
                  iconColor="#5C6BC0"
                  style={{
                    backgroundColor: '#fff',
                    padding: 10,
                    boxShadow: '0 0 3px 1px #5C6BC0',
                    fontSize: '12px'
                  }}
                  iconStyle={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 2
                  }}
                  contentStyle={{
                    backgroundColor: '#FEFEFE',
                    color: '#5C6BC0',
                    fontSize: '16px'
                  }}
                  titleStyle={{ fontWeight: 'bold' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'start', justifyContent: 'space-between' }}>
                    {(confiaxUsers.includes(userRole as UserRolesEnum) && userId && message?.id) && <Checkbox checked={message?.viewedBy?.includes(userId)} label={checked ? 'Lido' : 'Não-lido'} onClick={() => { handleMakeRead(message) }} />}
                    <ReactQuill
                      theme="bubble"
                      value={message?.message}
                      readOnly={true}
                    />
                  </div>
                </TimelineEvent>

              );
            })}
          </Timeline>
        </div>
      </Dimmer.Dimmable>
    </DivFollowup>
  );
};

export default FollowUp;
