import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { Field } from 'formik';
import { EstateFormProps } from '../UpdateEstateForm';
import { list } from '../../../services/insurer';
import { dangerNotification } from '../../../services/notification';
import Input from '../../../components/Input';
import Toggle from '../../../components/Toggle';
import SectionLabel from '../../../components/SectionLabel';
import { FormikErrorMessage } from '../../../components/ErrorMessage';
import { FieldLabel } from '../../../styles';
import {
  FireInsurersRow,
  InsurerCard,
  InsurerCardContent,
  InsurerCardContentTitle,
  InsurerCardIcon,
  InsurerCardToggleContent,
  NoParamsMessage
} from '../styles';
import { InsurersCodeEnum } from '../../../enums/insurers-code.enum';

export const CapitalizationInsuranceData = (props: EstateFormProps) => {
  const { errors, touched, handleInputChange, values, setFieldValue } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [availableInsurers, setAvailableInsurers] = useState<any[]>([]);
  const [insurerSelected, setInsurerSelected] = useState<any>();

  useEffect(() => {
    setIsLoading(true);

    list({ availability: 'CAPITALIZATION' })
      .then(result => {
        setAvailableInsurers(result);
      })
      .catch(error => {
        dangerNotification(
          'Oops...',
          'Não foi possível buscar as seguradoras disponíveis'
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [props.values.isPortoTradicionalCapitalizationSelected, values?.capitalizations]);

  const handleToggle = async (e: SyntheticEvent, value: any) => {
    if (value) {
      const insurer = availableInsurers.find(i => i.id == value.name);
      if (value.checked && insurer) {
        if (value.id.includes(InsurersCodeEnum.Porto)) {
          setFieldValue('isPortoTradicionalCapitalizationSelected', true);
        }
        values.capitalizations.push({
          id: insurer.id,
          code: insurer.code,
          name: insurer.name,
          imageUrl: insurer.image
        });
      } else if (!value.checked && insurer) {
        values.capitalizations = values.capitalizations.filter(
          (i: any) => i.id != value.name
        );
        setFieldValue('isPortoTradicionalCapitalizationSelected', false);
      }
      setFieldValue(`values.capitalizations`, values.capitalizations);
    }
  };

  return (
    <>
      <SectionLabel text="TÍTULO DE CAPITALIZAÇÃO" />
      <FireInsurersRow>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          {availableInsurers.map((insurerOption: any) => (
            <InsurerCard
              key={insurerOption.id}
              isSelected={insurerSelected?.id === insurerOption.id}
              onClick={() => setInsurerSelected(insurerOption)}
            >
              <InsurerCardContent>
                <InsurerCardIcon src={insurerOption.image} />
                <InsurerCardContentTitle>{insurerOption.name}</InsurerCardContentTitle>
              </InsurerCardContent>
              <InsurerCardToggleContent>
                <Toggle
                  name={insurerOption.id}
                  id={`updateEstate-capitalization-${insurerOption.code}`}
                  label={
                    values.capitalizations.find((i: any) => i.id === insurerOption.id)
                      ? 'ATIVA'
                      : 'INATIVA'
                  }
                  checked={
                    !!values.capitalizations.find((i: any) => i.id === insurerOption.id)
                  }
                  onChange={handleToggle}
                />
              </InsurerCardToggleContent>
            </InsurerCard>
          ))}
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <FieldLabel>CONFIGURAÇÕES DA SEGURADORA</FieldLabel>
          <p style={{ padding: 0, color: 'rgba(54, 68, 69, 0.5)' }}>
            Altere os parâmetros de configuração da seguradora.{' '}
          </p>

          {insurerSelected?.code.includes(InsurersCodeEnum.Porto) && (
            <>
              <FieldLabel>Código da Seguradora* </FieldLabel>
              <Field
                name="portoCode"
                onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
                  handleInputChange(data, props);
                }}
                component={Input}
                className={
                  errors && errors.portoCode && touched && touched.portoCode
                    ? 'text-input error'
                    : 'text-input'
                }
                fluid
              />
              <FormikErrorMessage component="div" name="portoCode" />
            </>
          )}
          {insurerSelected &&
            !insurerSelected?.code.includes(InsurersCodeEnum.Porto) && (
              <NoParamsMessage>
                *Não há parâmetros de configuração disponíveis para a seguradora
                selecionada.
              </NoParamsMessage>
            )}
        </Grid.Column>
      </FireInsurersRow>
    </>
  );
};
