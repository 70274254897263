import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px;
`;

export const Header = styled.div`
  padding: 24px 18px;
  h3 {
    color: #393939;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const Line = styled.div`
  height: 1px;
  background: #364445;
`;

export const FormContainer = styled.div`
  padding: 10px 24px 30px;
`;

export const FormTitle = styled.h4`
  font-size: 14px;
  font-weight: 800;
  line-height: 19px;
  margin: 10px 0 !important;
  color: #393939;
`;

export const Form = styled.form`
  label {
    display: flex;
    flex-direction: column;
    gap: 10px;

    font-size: 14px;
    font-weight: 800;
    line-height: 19px;
    letter-spacing: 0em;
  }

  p {
    margin: 5px 0 10px;
  }

  input {
    width: 100%;
  }

  .row > input {
    margin-top: 0px;
  }

  .ui.grid > .row {
    padding-bottom: 0px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
  width: 100%;
`;

export const EmailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  .input {
    margin-right: 10px;
    width: 350px;
  }
`;
