export const getMonthsOptions = (minValue?: number, maxValue?: number) => {
  const options = Array.from(Array(maxValue || 60).keys()).map(month => {
    return {
      key: month + 1,
      text: `${month + 1} ${month >= 1 ? 'Meses' : 'Mês'}`,
      value: String(month + 1)
    };
  });

  if (minValue && minValue > 0) {
    return options.filter((_, index) => index + 1 >= minValue);
  }

  return options;
};
