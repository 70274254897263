import React from 'react';
import { Checkbox, Grid, Input } from 'semantic-ui-react';
import { maskZipCode } from '../../../../../../../../services/masks';
import { AddressService } from '../../../../../../../../services/address';
import { FormProps } from '../types';
import { dangerNotification } from '../../../../../../../../services/notification';
import { UserState } from '../../../../../../../../types/UserState';

interface PropertyFormProps {
  form: FormProps;
  validateForm: boolean;
  setForm: React.Dispatch<React.SetStateAction<FormProps>>;
  isLoadingCEP: boolean;
  setIsLoadingCEP: React.Dispatch<React.SetStateAction<boolean>>;
  user: UserState;
}

const SaveQuotationModalPropertyForm: React.FC<PropertyFormProps> = ({
  form,
  setForm,
  validateForm,
  setIsLoadingCEP,
  isLoadingCEP,
  user
}: PropertyFormProps) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={4}>
          <label>
            CEP*
          </label>
          <Input
            placeholder="CEP"
            fluid
            error={validateForm && form?.propertyAddress?.zipCode === ''}
            value={form?.propertyAddress?.zipCode}
            onChange={(_, data) => {
              const cep = maskZipCode(data?.value);
              setForm((prevValue: FormProps) => ({
                ...prevValue,
                propertyAddress: {
                  ...prevValue.propertyAddress,
                  zipCode: cep
                }
              }));
              if (cep.length === 9) {
                setIsLoadingCEP(true);

                AddressService.getAddressByZipCode(cep, user.id)
                  .then(address => {
                    setForm((prevValue: FormProps) => ({
                      ...prevValue,
                      propertyAddress: {
                        ...prevValue.propertyAddress,
                        city: address.city,
                        state: address.state,
                        neighborhood: address.district,
                        street: address.street
                      }
                    }));
                    setIsLoadingCEP(false);
                  })
                  .catch(e => {
                    dangerNotification(
                      'CEP Inválido',
                      'Por favor, digite um CEP válido'
                    );
                    console.log('address error:', e);
                  });
              }
            }}
          />
        </Grid.Column>
        <Grid.Column width={12}>
          <label>
            LOGRADOURO*
          </label>
          <Input
            placeholder="LOGRADOURO"
            fluid
            disabled={isLoadingCEP}
            error={validateForm && form?.propertyAddress?.street === ''}
            value={form?.propertyAddress?.street}
            onChange={(_, data) => {
              setForm((prevValue: FormProps) => ({
                ...prevValue,
                propertyAddress: {
                  ...prevValue.propertyAddress,
                  street: data?.value
                }
              }));
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>
          <label>
            BAIRRO*
          </label>
          <Input
            placeholder="Bairro"
            fluid
            disabled={isLoadingCEP}
            error={validateForm && form?.propertyAddress?.neighborhood === ''}
            value={form?.propertyAddress?.neighborhood}
            onChange={(_, data) =>
              setForm((prevValue: FormProps) => ({
                ...prevValue,
                propertyAddress: {
                  ...prevValue.propertyAddress,
                  neighborhood: data?.value
                }
              }))
            }
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <label>
            CIDADE*
          </label>
          <Input
            placeholder="Cidade"
            fluid
            disabled={isLoadingCEP}
            error={validateForm && form?.propertyAddress?.city === ''}
            value={form?.propertyAddress?.city}
            onChange={(_, data) =>
              setForm((prevValue: FormProps) => ({
                ...prevValue,
                propertyAddress: {
                  ...prevValue.propertyAddress,
                  city: data?.value
                }
              }))
            }
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <label>
            ESTADO*
          </label>
          <Input
            placeholder="Estado"
            fluid
            disabled={isLoadingCEP}
            error={validateForm && form?.propertyAddress?.state === ''}
            value={form?.propertyAddress?.state}
            onChange={(_, data) =>
              setForm((prevValue: FormProps) => ({
                ...prevValue,
                propertyAddress: {
                  ...prevValue.propertyAddress,
                  state: data?.value
                }
              }))
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>
          <label>
            NÚMERO*
          </label>
          <Input
            type='number'
            placeholder="Número"
            fluid
            disabled={isLoadingCEP}
            error={validateForm && form?.propertyAddress?.number === ''}
            value={form?.propertyAddress?.number}
            onChange={(_, data) =>
              setForm((prevValue: FormProps) => ({
                ...prevValue,
                propertyAddress: {
                  ...prevValue.propertyAddress,
                  number: data?.value
                }
              }))
            }
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <label>
            COMPLEMENTO*
          </label>
          <Input
            placeholder="Complemento"
            fluid
            error={validateForm && form?.propertyAddress?.complement === ''}
            value={form?.propertyAddress?.complement}
            disabled={form?.propertyAddress?.noComplement}
            onChange={(_, data) =>
              setForm((prevValue: FormProps) => ({
                ...prevValue,
                propertyAddress: {
                  ...prevValue.propertyAddress,
                  complement: data?.value
                }
              }))
            }
          />
        </Grid.Column>

        <Grid.Column verticalAlign="middle" width={4}>
          <Checkbox
            label="Sem Complemento"
            checked={form?.propertyAddress?.noComplement || false}
            onChange={(e: any, data) => {
              setForm((prevValue: FormProps) => ({
                ...prevValue,
                propertyAddress: {
                  ...prevValue.propertyAddress,
                  noComplement: data.checked || false,
                  complement: data.checked ? 'SEM COMPLEMENTO' : ''
                }
              }));
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SaveQuotationModalPropertyForm;
