import React, { useState } from "react";
import MessageList from "../MessagesList";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { sendMessage } from "../../services/followup";
import {
  dangerNotification,
  successNotification,
  warningNotification,
} from "../../services/notification";
import { get } from "../../services/storage";
import Button from "../Button";

import { MessageContainer, MessageInput } from "./styles";

const MessageSender = (props: any) => {
  const { id } = props;
  const user_id = String(get("uid"));
  const user_name = String(get("name"));
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [tempMessages, setTempMessages] = useState<any[]>([]);

  const handleSubmit = async () => {
    if (!message.trim()) {
      warningNotification(
        "Oops...",
        "Por favor, digite uma mensagem antes de enviar."
      );
    } else {
      try {
        setLoading(true);
        const sentMessage = await sendMessage(id, user_id, message, user_name);
        setTempMessages([...tempMessages, sentMessage]);
        successNotification("Sucesso!", "Mensagem enviada!");
      } catch (error) {
        console.log("ERRO AO ENVIAR MENSAGEM:", error);
        dangerNotification(
          "Oops...",
          "Não foi possível enviar a mensagem, tente novamente mais tarde."
        );
      } finally {
        setMessage("");
        setLoading(false);
      }
    }
  };

  const modules =  {
    toolbar: [
      [{ 'align': [] }],
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'size': [ 'normal', 'large', 'huge'] }],  // custom dropdown
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    ],
  }

  return (
    <>
      <MessageContainer>
        <div className="quilljs">
           <ReactQuill theme="snow" modules={modules} value={message} onChange={(value) => setMessage(value)} readOnly={loading || props.isBlocked}/>
        </div>

        <div>
          <Button
            primary
            content="Enviar"
            labelPosition="right"
            icon="send"
            onClick={handleSubmit}
            loading={loading}
            disabled={loading || props.isBlocked}
          />
        </div>
      </MessageContainer>
      {tempMessages.length > 0 ? (
        <MessageList messages={tempMessages} />
      ) : (
        <span style={{ paddingLeft: "40px" }}>
          Ainda sem mensagens neste Follow Up
        </span>
      )}
    </>
  );
};

export default MessageSender;
