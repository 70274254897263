import React from 'react';
import moment from 'moment';
import { Grid, Icon } from 'semantic-ui-react';

import { FieldLabel } from '../../../../../styles';
import { turnUFIntoEstate } from '../../../util';
import { getCoverages } from '../../../utils/PortoSeguro';
import {
  maskCpfOrCnpj,
  maskPhoneNumber,
  maskZipCode,
  numberToMoneyString
} from '../../../../../services/masks';
import {
  CoveragesRow,
  HeaderAndDivider,
  QuotationContainer,
  QuotationHeader,
  QuotationList,
  QuotationResultContainer,
  QuotationValue
} from './styles';
import { translateProposalStatus } from '../../../utils/Pottencial';

interface DetailPortoSeguroProps {
  fireInsurancePolicy: any;
  showJson?: boolean;
}

const ControlledField = ({
  label,
  value
}: {
  label: string;
  value: string | number | undefined;
}) => {
  return (
    <>
      <FieldLabel>{label}</FieldLabel>
      <span>{value}</span>
    </>
  );
};

const DetailPortoSeguro = (props: DetailPortoSeguroProps) => {
  const { fireInsurancePolicy, showJson } = props;
  const { fireInsuranceAnalysis } = fireInsurancePolicy;
  const coverages = getCoverages(fireInsuranceAnalysis.coverages);

  return (
    <>
      <Grid style={{ padding: 5 }} columns={'equal'}>
        <HeaderAndDivider>Dados da Apólice</HeaderAndDivider>
        <Grid.Row>
          <Grid.Column>
            <ControlledField
              label={'Nº da Proposta:'}
              value={fireInsurancePolicy?.externalProposalCode || '-'}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Nº da Apólice:'}
              value={fireInsurancePolicy?.externalPolicyCode || '-'}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Status:'}
              value={translateProposalStatus(fireInsurancePolicy.status)}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <ControlledField label={'ID:'} value={fireInsurancePolicy?.id} />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Data de Criação:'}
              value={moment(fireInsurancePolicy?.createdAt).format('DD/MM/YYYY HH:mm')}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Última Atualização:'}
              value={moment(fireInsurancePolicy?.updatedAt).format('DD/MM/YYYY HH:mm')}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <ControlledField
              label={'Data de Início:'}
              value={moment(fireInsuranceAnalysis?.policyPeriodStart).format(
                'DD/MM/YYYY'
              )}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Data de Término:'}
              value={moment(fireInsuranceAnalysis?.policyPeriodEnd).format('DD/MM/YYYY')}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Vigência do Contrato:'}
              value={`${moment(fireInsuranceAnalysis.policyPeriodEnd).diff(
                moment(fireInsuranceAnalysis.policyPeriodStart),
                'months'
              )} meses`}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <ControlledField
              label={'Seguradora'}
              value={fireInsurancePolicy?.fireInsuranceAnalysis?.insurer?.name || ''}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField label={''} value={undefined} />
          </Grid.Column>

          <Grid.Column>
            <ControlledField label={''} value={undefined} />
          </Grid.Column>
        </Grid.Row>

        <HeaderAndDivider>Dados do Imóvel</HeaderAndDivider>
        <Grid.Row>
          <Grid.Column>
            <ControlledField
              label={'CEP:'}
              value={maskZipCode(
                fireInsuranceAnalysis?.fireInsurance?.propertyAddresses.zipcode ?? ''
              )}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Logradouro:'}
              value={fireInsuranceAnalysis?.fireInsurance?.propertyAddresses.street}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Bairro:'}
              value={fireInsuranceAnalysis?.fireInsurance?.propertyAddresses.district}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <ControlledField
              label={'Cidade:'}
              value={fireInsuranceAnalysis?.fireInsurance?.propertyAddresses.city}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Estado:'}
              value={
                fireInsuranceAnalysis?.fireInsurance?.propertyAddresses.state
                  ? turnUFIntoEstate(
                      fireInsuranceAnalysis?.fireInsurance?.propertyAddresses.state
                    )
                  : ''
              }
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Número:'}
              value={fireInsuranceAnalysis?.fireInsurance?.propertyAddresses.number}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <ControlledField
              label={'Valor do aluguel:'}
              value={numberToMoneyString(
                Number(fireInsuranceAnalysis?.fireInsurance?.rentValue) ?? 0
              )}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Complemento:'}
              value={
                fireInsuranceAnalysis?.fireInsurance?.propertyAddresses.complement ??
                'SEM COMPLEMENTO'
              }
            />
          </Grid.Column>

          <Grid.Column></Grid.Column>
        </Grid.Row>

        <HeaderAndDivider>Dados do Proponente</HeaderAndDivider>
        <Grid.Row>
          <Grid.Column>
            <ControlledField
              label={'Nome/Razão Social:'}
              value={fireInsuranceAnalysis?.fireInsurance?.tenantName}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'CPF/CNPJ:'}
              value={maskCpfOrCnpj(
                fireInsuranceAnalysis?.fireInsurance?.tenantDocument ?? ''
              )}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={
                fireInsuranceAnalysis?.fireInsurance?.tenantDocument.length === 11
                  ? 'Data de Nascimento:'
                  : 'Data de fundação da empresa'
              }
              value={moment(fireInsuranceAnalysis?.fireInsurance?.tenantBirthdate).format(
                'DD/MM/YYYY'
              )}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <ControlledField
              label={'E-mail:'}
              value={fireInsuranceAnalysis?.fireInsurance?.tenantEmail}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Telefone:'}
              value={maskPhoneNumber(
                fireInsuranceAnalysis?.fireInsurance?.tenantCellphone ?? ''
              )}
            />
          </Grid.Column>

          <Grid.Column></Grid.Column>
        </Grid.Row>
      </Grid>

      <HeaderAndDivider>Dados de cobertura do seguro incêndio</HeaderAndDivider>
      <QuotationContainer>
        <QuotationHeader>
          <h3>
            <Icon name="shield" /> Coberturas
          </h3>
        </QuotationHeader>
        <QuotationResultContainer>
          <QuotationList>
            <>
              <CoveragesRow>
                <div>
                  <span className="insurance-name">
                    <Icon name="shield" className="low-opacity" /> Incêndio &nbsp;
                    <span className="required">(Obrigatório)</span>
                  </span>
                </div>
                <div>
                  <span className="insurance-value">
                    {numberToMoneyString(coverages?.fireCoverageValue ?? 0)}
                  </span>
                </div>
              </CoveragesRow>

              <CoveragesRow>
                <div>
                  <span className={'full-insurance-name'}>
                    <input
                      type="checkbox"
                      disabled
                      checked={coverages?.fireCoverageValue ? true : false}
                    />
                    Cobertura responsabilidade civil
                  </span>
                </div>
                <div>
                  <span className="insurance-value">
                    {numberToMoneyString(coverages?.civilLiabilityCoverageValue ?? 0)}
                  </span>
                </div>
              </CoveragesRow>

              <CoveragesRow>
                <div>
                  <span className={'full-insurance-name'}>
                    <input
                      type="checkbox"
                      disabled
                      checked={coverages?.coverageContentValue ? true : false}
                    />
                    Cobertura de conteúdo
                  </span>
                </div>
                <div>
                  <span className="insurance-value">
                    {numberToMoneyString(coverages?.coverageContentValue ?? 0)}
                  </span>
                </div>
              </CoveragesRow>

              <CoveragesRow>
                <div>
                  <span className={'full-insurance-name'}>
                    <input
                      type="checkbox"
                      disabled
                      checked={coverages?.electricalDamageCoverageValue ? true : false}
                    />
                    Cobertura danos elétricos
                  </span>
                </div>
                <div>
                  <span className="insurance-value">
                    {numberToMoneyString(coverages?.electricalDamageCoverageValue ?? 0)}
                  </span>
                </div>
              </CoveragesRow>

              <CoveragesRow>
                <div>
                  <span className={'full-insurance-name'}>
                    <input
                      type="checkbox"
                      disabled
                      checked={
                        coverages?.coverageImpactsAirLandVehiclesValue ? true : false
                      }
                    />
                    Cobertura por impactos de veículos terrestres ou aéreos
                  </span>
                </div>
                <div>
                  <span className="insurance-value">
                    {numberToMoneyString(
                      coverages?.coverageImpactsAirLandVehiclesValue ?? 0
                    )}
                  </span>
                </div>
              </CoveragesRow>

              <CoveragesRow>
                <div>
                  <span className={'full-insurance-name'}>
                    <input
                      type="checkbox"
                      disabled
                      checked={coverages?.rentalLossCoverageValue ? true : false}
                    />
                    Cobertura por perda de aluguel
                  </span>
                </div>
                <div>
                  <span className="insurance-value">
                    {numberToMoneyString(coverages?.rentalLossCoverageValue ?? 0)}
                  </span>
                </div>
              </CoveragesRow>

              <CoveragesRow>
                <div>
                  <span className={'full-insurance-name'}>
                    <input
                      type="checkbox"
                      disabled
                      checked={coverages?.galeCoverageValue ? true : false}
                    />
                    Cobertura por vendaval
                  </span>
                </div>
                <div>
                  <span className="insurance-value">
                    {numberToMoneyString(coverages?.galeCoverageValue ?? 0)}
                  </span>
                </div>
              </CoveragesRow>

              <CoveragesRow>
                <div>
                  <span className={'full-insurance-name'}>
                    <input
                      type="checkbox"
                      disabled
                      checked={coverages?.riotCoverageValue ? true : false}
                    />
                    Cobertura por tumulto
                  </span>
                </div>
                <div>
                  <span className="insurance-value">
                    {numberToMoneyString(coverages?.riotCoverageValue ?? 0)}
                  </span>
                </div>
              </CoveragesRow>
            </>
          </QuotationList>
          <QuotationValue>
            <h3 style={{ fontSize: '20px' }}>Seu orçamento</h3>
            <label style={{ fontSize: '13px', paddingBottom: '10px' }}>
              {fireInsuranceAnalysis.installments} x FATURA MENSAL - SEM ENTRADA
            </label>
            <small style={{ fontSize: '15px' }}>
              Valor da primeira parcela:{' '}
              {numberToMoneyString(Number(fireInsuranceAnalysis.valueFirstInstallment))}
            </small>
            <small style={{ fontSize: '15px', paddingBottom: '10px' }}>
              Valor das demais parcelas:{' '}
              {numberToMoneyString(Number(fireInsuranceAnalysis.value))}
            </small>
            <label style={{ fontSize: '17px' }}>
              Total:{' '}
              {numberToMoneyString(
                Number(fireInsuranceAnalysis.valueFirstInstallment) +
                  Number(
                    (fireInsuranceAnalysis.installments - 1) * fireInsuranceAnalysis.value
                  )
              )}
            </label>
          </QuotationValue>
        </QuotationResultContainer>
      </QuotationContainer>
    </>
  );
};

export default DetailPortoSeguro;
