import { FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import PortoSelectCards from "../../components/SelectCards";
import { ProductFormProps } from "../../ModalHireProposal";
import { PayloadPortoUpdateRequestInterface } from "../../ModalHireProposal/interfaces/SubmitEssencialHirePorto.interface";
import { formField } from "../../ModalHireProposal/Model/formFields";

export type ProductSelectFormProps =  {
  offers: ProductFormProps[];
}


const ProductSelectForm = ({
  offers,
  values,
  setFieldValue,
}: FormikProps<PayloadPortoUpdateRequestInterface> & ProductSelectFormProps) => {
  const [selected, setSelected] = useState<string>(values.product.code);

  useEffect(() => {
    if (selected) {
      setFieldValue(formField.product.code.name, selected);
    } else {
      setFieldValue(formField.product.code.name, "");
    }
  }, [selected]);



  return (
    <div style={{padding: "0 0 0 10px", marginTop: "1rem"}}>
      <PortoSelectCards
        selected={selected}
        setSelected={(s: string) => setSelected(s)}
        offers={offers}
      />
    </div>
  );
};
export default ProductSelectForm;
