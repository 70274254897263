export enum ResidentialOccupationsEnum {
  house = 'CASA',
  apartment = 'APARTAMENTO',
  condominium = 'CONDOMINIO',
}

export enum HireTypePropertyEnum {
  wholeFloor = 'ANDAR INTEIRO',
  apartment = 'APARTAMENTO',
  kitnet = 'KITNET',
  standardHouse = 'CASA PADRÃO',
  commercialHouse = 'CASA COMERCIAL',
  roof = 'COBERTURA',
  setOfStores = 'CONJUNTO DE LOJAS',
  roomSet = 'CONJUNTO DE SALAS',
  residenceService = 'FLAT/RESIDENCE SERVICE',
  commercialStore = 'LOJA COMERCIAL',
  pavilion = 'PAVILHAO',
  edifice = 'PRÉDIO',
  commercialRoom = 'SALA COMERCIAL',
  loft = 'SOBRADO',
  mezzanine = 'SOBRELOJA',
  ground = 'TERRENO'
}
