import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Grid, Icon, Input, Modal } from 'semantic-ui-react';
import { SaveQuotationModalProps, FormProps } from './types';
import {
  Container,
  Header,
  Line,
  FormContainer,
  FormTitle,
  Form,
  DisclaimerContainer,
  Disclaimer,
  ButtonContainer,
  QuotationContainer,
  QuotationHeader,
  QuotationResultContainer,
  QuotationList,
  QuotationValue,
  QuotationListItem
} from './styles';
import CustomDatePicker from '../../../../../../../components/CustomDatePicker';
import AppMaskedInput from '../../../../../../../components/MaskedInput';
import { formatAmount } from '../../../../../../../services/masks';
import { warningNotification } from '../../../../../../../services/notification';
import { iRootState } from '../../../../../../../store';
import { connect } from 'react-redux';
import TenantForm from './forms/SaveQuotationModalTenantForm';
import OwnerForm from './forms/SaveQuotationModalOwnerForm';
import PropertyForm from './forms/SaveQuotationModalPropertyForm';
import { cnpjValidity, cpfValidity } from '../../../../../../../util';

const mapState = (state: iRootState) => ({
  user: state.user
});

const SaveQuotationModal: React.FC<
  SaveQuotationModalProps & ReturnType<typeof mapState>
> = ({
  isOpen,
  onClose,
  handleSubmit,
  quotation,
  quote,
  user,
  isHiring,
  occupation,
}: SaveQuotationModalProps & ReturnType<typeof mapState>) => {
  const [form, setForm] = useState<FormProps>({
    tenantIdentification: {
      name: '',
      document: '',
      birthdate: undefined,
      email: '',
      phoneNumber: ''
    },
    ownerIdentification: {
      name: '',
      document: '',
      email: ''
    },
    propertyAddress: {
      zipCode: '',
      street: '',
      city: '',
      state: '',
      neighborhood: '',
      number: '',
      complement: '',
      noComplement: false
    },
    quotation: {
      rentValue: '',
      vigency: '',
      startDate: new Date()
    }
  });
  const [validateForm, setValidateForm] = useState<boolean>(false);
  const [isLoadingCEP, setIsLoadingCEP] = useState<boolean>(false);

  const getFormIsValid = () => {
    const tenantForm = Object.values(form.tenantIdentification);
    const ownerForm = Object.values(form.ownerIdentification);
    const propertyForm = Object.values(form.propertyAddress);
    let isValid = true;
    Object.values(tenantForm).map(item => {
      if (item === '') {
        isValid = false;
      }
    });
    Object.values(ownerForm).map(item => {
      if (item === '') {
        isValid = false;
      }
    });
    Object.values(propertyForm).map(item => {
      if (item === '') {
        isValid = false;
      }
    });

    if (form.ownerIdentification.document === form.tenantIdentification.document) {
      isValid = false;
      warningNotification(
        'Atenção',
        'O CPF do proprietário não pode ser igual ao CPF do inquilino.'
      );
    }

    if(occupation === 645 && !form.propertyAddress.complement) {
      isValid = false;
      warningNotification(
        'Atenção',
        'Para Apartamento, o complemento do endereço é obrigatório!'
      );
      form.propertyAddress.noComplement = false;
    }

    if(isNaN(Number(form.propertyAddress.number))) {
      isValid = false;
      warningNotification(
        'Atenção',
        'O Número do Endereço deve conter apenas números.'
      );
    }

    if (!form.propertyAddress.noComplement && !form.propertyAddress.complement) {
      isValid = false;
      warningNotification('Atenção', 'O complemento do endereço é obrigatório.');
    }
    if (
      !cpfValidity(form.tenantIdentification.document) &&
      !cnpjValidity(form.tenantIdentification.document)
    ) {
      isValid = false;
      warningNotification('Atenção', 'Documento do inquilino inválido.');
    }

    if (
      !cpfValidity(form.ownerIdentification.document) &&
      !cnpjValidity(form.ownerIdentification.document)
    ) {
      isValid = false;
      warningNotification('Atenção', 'Documento do proprietário inválido.');
    }

    return isValid;
  };

  useEffect(() => {
    if (quotation) {
      setForm(prevValue => ({
        ...prevValue,
        quotation
      }));
    }
  }, [quotation]);

  const monthsOptions = () => {
    return Array.from(Array(12).keys()).map(month => {
      return {
        key: month + 1,
        text: `${month + 1} ${month >= 1 ? 'Meses' : 'Mês'}`,
        value: month + 1
      };
    });
  };

  if (!isOpen) {
    return null;
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    setValidateForm(true);
    if (getFormIsValid()) {
      let payload = form;
      if (form.propertyAddress.noComplement) {
        payload = {
          ...form,
          propertyAddress: {
            ...form.propertyAddress,
            complement: ''
          }
        };
      }
      handleSubmit(payload);
    } else {
      warningNotification(
        'Campos obrigatórios',
        'Por favor, preencha todos os campos obrigatórios.'
      );
    }
  };

  const renderQuotationForm = (): React.ReactNode => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>
              VALOR DO ALUGUEL
              <Input
                placeholder="Valor do Aluguel"
                fluid
                disabled
                value={form?.quotation?.rentValue}
              />
            </label>
          </Grid.Column>
          <Grid.Column width={8}>
            <label>
              DURAÇÃO DA VIGÊNCIA
              <Dropdown
                fluid
                search
                clearable
                selection
                disabled
                placeholder={'Número de Meses'}
                options={monthsOptions()}
                value={form?.quotation?.vigency}
              />
            </label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <label>
              INÍCIO
              <CustomDatePicker
                selected={form?.quotation?.startDate ?? undefined}
                minDate={new Date()}
                customInput={
                  <AppMaskedInput
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/
                    ]}
                  />
                }
                disabled
                dateFormat={'dd/MM/yyyy'}
                style={{
                  marginTop: 0
                }}
              />
            </label>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const renderQuotationData = (): React.ReactNode => {
    const selectedInstallment = quote.installments.find(
      item => item.numeroParcela === quote.maxInstallments
    );
    return (
      <QuotationContainer>
        <QuotationHeader>
          <h3>
            <Icon name="shield" /> Resumo do orçamento
          </h3>
        </QuotationHeader>
        <QuotationResultContainer>
          <QuotationList>
            {quote.coverages.map((coverage, index) => (
              <QuotationListItem key={coverage.id} $isOdd={index % 2 === 0}>
                <div
                  className={`item-name ${
                    !coverage.flagSelecionada ? 'not-included' : ''
                  }`}
                >
                  {coverage.nomeCobertura}
                  {/*  TODO: improve this logic */}
                  {index === 0 && (
                    <label className="required-message">(Obrigatório)</label>
                  )}
                </div>
                {coverage.flagSelecionada && (
                  <div className="value">
                    R$ {formatAmount(coverage.valorPremioTotal)}
                  </div>
                )}
                {!coverage.flagSelecionada && (
                  <small className="not-included">Não incluso</small>
                )}
              </QuotationListItem>
            ))}
          </QuotationList>
          <QuotationValue>
            <h3>Seu orçamento</h3>
            <label>
              <small>{selectedInstallment?.numeroParcela}x</small> R${' '}
              {formatAmount(selectedInstallment?.valorParcela || '')}
            </label>
            <small>Total: R$ {formatAmount(quote.totalValue)}</small>
          </QuotationValue>
        </QuotationResultContainer>
      </QuotationContainer>
    );
  };

  const renderActionButtons = (): React.ReactNode => {
    return (
      <ButtonContainer>
        <Button type="button" color="red" onClick={onClose}>
          Cancelar
        </Button>
        <Button type="submit" color={isHiring ? "green" : "blue"}>
          {isHiring ? 'Contratar Seguro' : 'Salvar Orçamento'}
        </Button>
      </ButtonContainer>
    );
  };

  return (
    <Modal open={isOpen} onClose={onClose} closeIcon>
      <Container>
        <Header>
          <h3>
            <Icon name="shield" /> {isHiring ? 'Contratar Seguro' : 'Salvar Orçamento'}
          </h3>
        </Header>
        <Line />
        {!isHiring && (
          <DisclaimerContainer>
            <Disclaimer>
              <Icon bordered name="exclamation" />
              <p>
                Você pode salvar esse orçamento para contratação posterior. Você terá um
                prazo de <time>30</time> dias para confirmar a contratação
              </p>
            </Disclaimer>
          </DisclaimerContainer>
        )}
        <Form onSubmit={handleFormSubmit}>
          <FormContainer>
            <FormTitle>IDENTIFICAÇÃO DO INQUILINO</FormTitle>
            <Line />
            <TenantForm form={form} setForm={setForm} validateForm={validateForm} />
          </FormContainer>
          <FormContainer>
            <FormTitle>IDENTIFICAÇÃO DO PROPRIETÁRIO</FormTitle>
            <Line />
            <OwnerForm form={form} setForm={setForm} validateForm={validateForm} />
          </FormContainer>
          <FormContainer>
            <FormTitle>ENDEREÇO DO IMÓVEL</FormTitle>
            <Line />
            <PropertyForm
              form={form}
              setForm={setForm}
              validateForm={validateForm}
              isLoadingCEP={isLoadingCEP}
              setIsLoadingCEP={setIsLoadingCEP}
              user={user}
            />
          </FormContainer>
          <FormContainer>
            <FormTitle>DADOS DA COTAÇÃO</FormTitle>
            <Line />
            {renderQuotationForm()}
          </FormContainer>
          <FormContainer>{renderQuotationData()}</FormContainer>
          <FormContainer>{renderActionButtons()}</FormContainer>
        </Form>
      </Container>
    </Modal>
  );
};

export default connect(mapState)(SaveQuotationModal);
