import React from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import { ViewModalProps } from './types';
import {
  Container,
  FormContainer,
  Form,
  Header,
  Line,
  FormTitle,
  FormContent,
  FormRow
} from './styles';
import { maskCpfOrCnpj } from '../../../../services/masks';
import { statusOptions } from '../../types';
import { DateToStringDateTime } from '../../../../services/date';

const ViewModal: React.FC<ViewModalProps> = ({
  isOpen,
  onClose,
  blacklist
}: ViewModalProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal open={isOpen} onClose={onClose} closeIcon>
      <Container>
        <Header>
          <Icon name="ban" /> <h3>{blacklist?.name}</h3>
        </Header>
        <Line />
        <Form>
          <FormContainer>
            <FormTitle>DADOS CADASTRAIS</FormTitle>
            <Line />
            <FormContent>
              <FormRow>
                <label>
                  <b>DOCUMENTO</b>
                  <span>{maskCpfOrCnpj(blacklist?.document)}</span>
                </label>
                <label>
                  <b>NOME</b>
                  <span>{blacklist?.name}</span>
                </label>
                <label>
                  <b>STATUS</b>
                  <span>
                    {
                      statusOptions.find(status => status.value === blacklist?.status)
                        ?.text
                    }
                  </span>
                </label>
                <label>
                  <b>DATA DE CRIAÇÃO</b>
                  <span>
                    {DateToStringDateTime(blacklist?.createdAt || new Date(0, 0, 0))}
                  </span>
                </label>
              </FormRow>
            </FormContent>
          </FormContainer>
          <FormContainer>
            <FormRow>
              <label>
                <b>OBSERVAÇÕES</b>
                <span>{blacklist?.observations || 'Sem observações.'}</span>
              </label>
            </FormRow>
          </FormContainer>
        </Form>
      </Container>
    </Modal>
  );
};

export default ViewModal;
