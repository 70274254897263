import React from "react";
import { Button, Grid, Icon } from "semantic-ui-react";
import { Info } from "../../../../../components/Info";
import SectionLabel from "../../../../../components/SectionLabel";
import { ComboTooFireInsurancePoliciesListResponseDTO } from "../../../../../dtos/bond-insurance-policy-list/interfaces/policy-for-get-bond-insurance-policies-list-response-dto";
import { printPolicyByCombo } from "../../../../../services/fire-insurance-policy";
import { successNotification, warningNotification } from "../../../../../services/notification";

export type Props = {
  combo: ComboTooFireInsurancePoliciesListResponseDTO;
};

export const ComboFireInsuranceDetails = (props: Props) => {
  const { combo } = props;

  async function handleDownloadApolice() {
    warningNotification(
      "Aguarde",
      "Estamos localizando sua apólice."
    );
    try {
      const data = await printPolicyByCombo(combo.ticket_number);
      const linkSource = `data:application/pdf;base64,${data.attachment}`;
      const downloadLink = document.createElement("a");
      const fileName = "apolice_incendio_" + data.filename + ".pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      successNotification(
        "Sucesso",
        "Download realizado com sucesso!"
      );
    } catch (err) {
      warningNotification(
        "Opss..",
        "Ainda não recebemos essa apólice. A emissão da mesma pode demorar até 30 dias. Tente novamente mais tarde."
      );
      console.log(err);
    }
  }

  return (
    <>
      <SectionLabel text="DADOS DO SEGURO INCENDIO" />
      <Grid.Row>
        <Info name={"NUMERO DO TICKET"} value={combo.ticket_number} width={4} />
        <Info
          name={"PRÊMIO TOTAL"}
          value={combo.total_premium_amount}
          width={4}
        />
        <Info name={"PARCELAS"} value={combo.installments_amount} width={4} />
        <Button
          color={"blue"}
          icon={"print"}
          onClick={handleDownloadApolice}
          type="button"
          floated="right"
          style={{ marginBottom: "10px" }}
        >
          <Icon name="print" /> Imprimir Apólice
        </Button>
      </Grid.Row>
    </>
  );
};
