import styled from 'styled-components';

export const Container = styled.div`
  input {
    width: 100%;
    margin-top: 10px !important;
  }

  .ui.grid > .row {
    padding-bottom: 0px;
  }

  small {
    color: #808080;
  }
`;

export const ModalToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  label {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-right: 6px;
  }
`;
