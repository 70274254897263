import { FormikProps } from "formik";
import React from "react";
import { Grid } from "semantic-ui-react";

import FileUploader, {
  fileTypeProps,
} from "../../../../../../components/UploadFilesUncontroled";
import { FieldLabel } from "../../../../../../styles";
import { CreateCancelationInitialValuesType } from "../../Formik/types/CreateCancelationInitialValuesTypes";

type Properties = {
  fileIdentifiers: fileTypeProps[];
};

const DocumentsComponent = ({
  fileIdentifiers,
  values,
  setFieldValue,
}: FormikProps<CreateCancelationInitialValuesType> & Properties) => {
  return (
    <div
      style={{
        height: "60vh",
        overflowY: "scroll",
        overflowX: "hidden",
        paddingTop: "16px",
      }}
    >
       <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
          <FieldLabel>
        A inserção de documentos é obrigatória para um novo cancelamento. <br />
        São aceitos arquivos de até <b>50MB</b> <br />
        Formatos Aceitos: jpeg, png, webp, doc, docx, pdf e odt
      </FieldLabel>
      <FieldLabel>
        Para solicitar o cancelamento da Apólice é OBRIGATÓRIO anexar pelo menos
        um dos seguintes arquivos: <br /> <br />
      </FieldLabel>
      <FieldLabel>
        - Termo cancelamento compra do imóvel. (
        <a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Termo+cancelamento+compra+do+im%C3%B3vel.docx">
          Baixar Modelo
        </a>
        )<br />
      </FieldLabel>
      <FieldLabel>
        - Termo cancelamento de desistência de locação. (
        <a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Termo+cancelamento+de+desist%C3%AAncia+de+loca%C3%A7%C3%A3o.docx">
          Baixar Modelo
        </a>
        )<br />
      </FieldLabel>
      <FieldLabel>
        - Termo cancelamento de entrega de chaves e inexistência de débitos. (
        <a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Termo+cancelamento+de+entrega+de+chaves+e+inexist%C3%AAncia+de+d%C3%A9bitos.docx">
          Baixar Modelo
        </a>
        )<br />
      </FieldLabel>
      <FieldLabel>
        - Termo cancelamento de troca de locatário. (
        <a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Termo+cancelamento+de+troca+de+locat%C3%A1rio.docx">
          Baixar Modelo
        </a>
        )<br />
      </FieldLabel>
      <FieldLabel>
        - Termo cancelamento de troca de seguradora. (
        <a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Termo+cancelamento+de+troca+de+seguradora.docx">
          Baixar Modelo
        </a>
        )<br />
      </FieldLabel>
      <FieldLabel>
        - Termo cancelamento duplicidade de apólice. (
        <a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Termo+cancelamento+duplicidade+de+ap%C3%B3lice.docx">
          Baixar Modelo
        </a>
        )<br />
      </FieldLabel>
      <FieldLabel>
        - Termo cancelamento troca de garantia. (
        <a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Termo+cancelamento+troca+de+garantia.docx">
          Baixar Modelo
        </a>
        )<br />
      </FieldLabel>
      <FieldLabel>
        - Termo de inexistência de débitos. (
        <a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Termo+de+inexistencia+de+debitos.docx">
          Baixar Modelo
        </a>
        )<br />
      </FieldLabel>
      <FieldLabel>
        - Distrato locador imobiliária e inexistência de débitos. (
        <a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Distrato+locador+imobiliaria+e+inexistencia+de+debitos.docx">
          Baixar Modelo
        </a>
        )<br />
      </FieldLabel>
      <FieldLabel>- Termo de recisão. </FieldLabel>
      <FileUploader
        fileIdentifiers={fileIdentifiers}
        files={values.files}
        setFieldValue={setFieldValue}
        fieldName={"files"}
      />
      </Grid.Column>
      </Grid.Row>
      </Grid>
    </div>
  );
};
export default DocumentsComponent;
