import { AssistanceState } from "./AssistanceState";
import { PropertyTypeState } from "./PropertyTypeState";

export enum Code {
  Fairfax = 'FAIRFAX',
  TokioMarine = 'TOKIOMARINE',
  Liberty = 'LIBERTY',
  Porto = 'PORTO',
  Porto12 = 'PORTODOZE',
  Porto18 = 'PORTODEZOITO',
  Pottencial = 'POTTENCIAL'
}

type InsurerConnectorInformationState = {
  enabled: string;
  insuranceType: string;
}

export interface InsurerState {
  id: string;
  name: string;
  code: string;
  imageUrl?: string;
  availabily?: Array<string>;
  assistances?: AssistanceState[];
  propertyTypes?: PropertyTypeState[];
  connectorInformation?: InsurerConnectorInformationState;
}
