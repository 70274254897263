import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px;
`;

export const FormContainer = styled.div`
  padding: 24px;
`;

export const StepForm = styled.div``

export const Header = styled.div`
  padding: 24px 18px 0 18px;
  h3 {
    color: #393939;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const FormTitle = styled.h4`
  font-size: 14px;
  font-weight: 800;
  line-height: 19px;
  margin: 10px 0 !important;
  color: #393939;
`;

export const Line = styled.div`
  height: 1px;
  background: #364445;
`;

export const Form = styled.div`
  padding-bottom: 20px;
  label {
    display: flex;
    flex-direction: column;
    gap: 10px;

    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0em;
  }

  input {
    width: 100%;
  }

  .row input {
    margin-top: 0px;
  }

  .ui.grid > .row {
    padding-bottom: 0px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
  width: 100%;
  margin-top: 15px;
`;


export const Steper = styled.div`
  display: flex;
  padding: 10px 0px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 20px;
  margin: 10px 25px 0 25px;
  position: relative; /* Adiciona posicionamento relativo para conter os triângulos */
`;

export const StepItem = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 10px); /* Ajusta a largura para acomodar o triângulo */
  height: 40px;
  background: ${({ active }) => (active ? '#1991EB' : '#FFFFFF')};
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  color: ${({ active }) => (active ? '#FFFFFF' : '#1991EB')};
  font-weight: 600;
  cursor: pointer;
  position: relative; /* Adiciona posicionamento relativo para conter o triângulo */
  border: 1px solid #ccc;
  border-right: none;
  border-left: none;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -10px; /* Controla a posição do triângulo */
    width: 0;
    height: 0;
    border-top: 19px solid transparent; /* Altura do triângulo */
    border-bottom: 19px solid transparent; /* Altura do triângulo */
    border-left: 10px solid ${({ active }) => (active ? '#1991EB' : '#fff')}; /* Cor do triângulo */
    z-index: 2; /* Coloca o triângulo sobre o StepItem */
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: -11px; /* Controla a posição do triângulo */
    width: 0;
    height: 0;
    border-top: 19px solid transparent; /* Altura do triângulo */
    border-bottom: 19px solid transparent; /* Altura do triângulo */
    border-left: 11px solid #ccc;
    z-index: 1; /* Coloca o triângulo sobre o StepItem */
  }


  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left: 1px solid #ccc;

  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: 1px solid #ccc;
  }

  &:last-child::after {
    display: none; /* Oculta o triângulo no último StepItem */
  }

  &:last-child::before {
    display: none; /* Oculta o triângulo no último StepItem */
  }
`;
export const TextAreaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    > textarea {
      width: 100%;
      border-radius: 4px;
      border: 1px solid rgba(34,36,38,.15);
      padding: 10px 5px;
      outline: 0;
      resize: none;

      &:focus {
        border-color: #85b7d9;
      }
    }

    > label {
      // font-weight: bold;
      margin-bottom: 5px;
    }
  `
