export enum InsurersCodeEnum {
  Fairfax = 'FAIRFAX',
  TokioMarine = 'TOKIOMARINE',
  TokioMarineSegimob = 'TOKIOMARINE_SEGIMOB',
  Liberty = 'LIBERTY',
  Porto = 'PORTO',
  PortoEssencial = 'PORTO_ESSENCIAL',
  Porto12 = 'PORTODOZE',
  Porto18 = 'PORTODEZOITO',
  Porto20 = 'PORTOVINTE',
  Porto30 = 'PORTOTRINTA',
  Pottencial = 'POTTENCIAL',
  Garantti = 'GARANTTI',
  Too = 'TOOSEGUROS',
  alfa = 'ALFA_SEGUROS',

}
