import React from 'react';
import { Grid } from 'semantic-ui-react';
import { BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO } from "../../../../../dtos/bond-insurance-proposal-list/get-complete-bond-insurance-proposal-by-id-response.dto";
import SectionLabel from "../../../../../components/SectionLabel";
import { Info } from "../../../../../components/Info";
import { DateToStringDate } from "../../../../../services/date";
import { maskCpf, maskMoney } from "../../../../../services/masks";
import { PersonTypesEnum } from "../../../../../enums/person-types.enum";
import { PurposesEnum } from "../../../../../enums/purposes.enum";

export type Props = {
  bondInsurance: BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO;
};

export const BondInsurancePropertyDetails = (props: Props) => {

  const { property, purpose } = props.bondInsurance;

  return (
    <>
      <SectionLabel text='DADOS DO IMÓVEL' />
      {(property.readjustment_index && property.rent_insurance_contract_end_date && property.rent_insurance_contract_start_date) && (<Grid.Row>
        <Info name={'INÍCIO DO CONTRATO DO SEGURO'} value={property.rent_insurance_contract_start_date ? DateToStringDate(property.rent_insurance_contract_start_date) : 'Indisponível'} width={6} />
        <Info name={'FIM DO CONTRATO DO SEGURO'} value={property.rent_insurance_contract_end_date ? DateToStringDate(property.rent_insurance_contract_end_date) : 'Indisponível'} width={5} />
        <Info name={'ÍNDICE DE REAJUSTE'} value={property.readjustment_index ? property.readjustment_index : 'Indisponível'} width={5} />
      </Grid.Row>)}
      {(property.rental_period_start_date && property.rental_period_end_date && property.rental_periodicity) && (<Grid.Row>
        <Info name={'INÍCIO DO CONTRATO DE LOCAÇÃO'} value={property.rental_period_start_date ? DateToStringDate(property.rental_period_start_date) : 'Indisponível'} width={6} />
        <Info name={'FIM DO CONTRATO DE LOCAÇÃO'} value={property.rental_period_end_date ? DateToStringDate(property.rental_period_end_date) : 'Indisponível'} width={5} />
        <Info name={'PERIODICIDADE DE LOCAÇÃO'} value={property.rental_periodicity ? property.rental_periodicity : 'Indisponível'} width={5} />
      </Grid.Row>)
      }
      <Grid.Row> <Info name={'DIA DE VENCIMENTO DO ALUGUEL'} value={`${property.rent_due_date  ||  'Indisponível'}`} width={8} /> </Grid.Row>
      <Grid.Row>
        <Info name={'LOGRADOURO'} value={property.street} width={4} />
        <Info name={'NÚMERO'} value={property.number} width={4} />
        <Info name={'COMPLEMENTO'} value={property.complement} width={4} />
        <Info name={'BAIRRO'} value={property.district} width={4} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'CIDADE'} value={property.city} width={4} />
        <Info name={'ESTADO'} value={property.state} width={4} />
        <Info name={'CEP'} value={property.zip_code} width={4} />
        {!property.property_type && (<Info name={'TIPO DE LOCAÇÃO'} value={purpose === 'RESIDENTIAL' ? 'Residencial' : 'Comercial'} width={4} />)}
      </Grid.Row>
      {property.property_type && (
        <Grid.Row>
          <Info name={'TIPO DE IMÓVEL'} value={property.property_type} width={4} />
          <Info name={'TIPO DE LOCAÇÃO'} value={purpose === 'RESIDENTIAL' ? 'Residencial' : 'Comercial'} width={4} />
        </Grid.Row>
      )}
      <Grid.Row>
        <Info name={'ALUGUEL (R$)'} value={maskMoney(`${property.rent_value || 0}`, true)} width={6} />
        {property.rent_indemnity_period && (<Info name={'PERIODO INDENITÁRIO ALUGUEL'} value={property.rent_indemnity_period ? `${property.rent_indemnity_period} meses` : 'Indisponível'} width={5} />)}
        <Info name={'CONDOMÍNIO (R$)'} value={maskMoney(`${property.condominium_fee || 0}`, true)} width={5} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'IPTU (R$)'} value={maskMoney(`${property.taxes_value || 0}`, true)} width={4} />
        <Info name={'ENERGIA (R$)'} value={maskMoney(`${property.energy_bill || 0}`, true)} width={4} />
        <Info name={'ÁGUA (R$)'} value={maskMoney(`${property.water_bill || 0}`, true)} width={4} />
        <Info name={'GÁS CANALIZADO (R$)'} value={maskMoney(`${property.gas_bill || 0}`, true)} width={4} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'MULTAS CONTRATUAIS (R$)'} value={maskMoney(`${property.contract_penalties_value || 0}`, true)} width={4} />
        <Info name={'PINTURA INTERNA (R$)'} value={maskMoney(`${property.interior_painting_value || 0}`, true)} width={4} />
        <Info name={'PINTURA EXTERNA (R$)'} value={maskMoney(`${property.exterior_painting_value || 0}`, true)} width={4} />
        <Info name={'DANOS A MÓVEIS (R$)'} value={maskMoney(`${property.furniture_damage_value || 0}`, true)} width={4} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'DANOS AO IMÓVEL (R$)'} value={maskMoney(`${property.property_damage_value || 0}`, true)} width={4} />
      </Grid.Row>
      {/* <Grid.Row>

        {props.bondInsurance.tenantType === PersonTypesEnum.legal
          && props.bondInsurance.purpose === PurposesEnum.residential
          && props.bondInsurance.property.reason === 'Locação para moradia' && (
            <>
              <Info name={'NOME DO REAL OCUPANTE'} value={property.real_occupant_name} width={4} />
              <Info name={'CPF DO REAL OCUPANTE'} value={maskCpf(property.real_occupant_doc)} width={4} />
            </>
          )}
      </Grid.Row> */}
    </>
  )
}
