import React, { useState } from 'react';
import { Grid, Icon, Message, Segment } from 'semantic-ui-react';
import moment from 'moment';

import { FieldLabel } from '../../../../../styles';
import { turnUFIntoEstate } from '../../../util';
import { getCoverages } from '../../../utils/PortoSeguro';
import {
  maskCpfOrCnpj,
  maskPhoneNumber,
  maskZipCode,
  numberToMoneyString,
} from '../../../../../services/masks';
import {
  CoveragesRow,
  HeaderAndDivider,
  QuotationContainer,
  QuotationHeader,
  QuotationList,
  QuotationResultContainer,
  QuotationValue,
} from './styles';

interface PortoSeguroViewProps {
  fireInsurance: any;
  showJson: boolean;
  budgetExpired: boolean;
}

const ControlledField = ({ label, value }: { label: string; value: string | number | undefined}) => {
  return (
    <>
      <FieldLabel>{label}</FieldLabel>
      <span>{value}</span>
    </>
  );
};

const PortoSeguroView = ({ fireInsurance, showJson, budgetExpired }: PortoSeguroViewProps) => {
  const [infoVisible, setInfoVisible] = useState<boolean>(true);

  const coverages = getCoverages(fireInsurance.coverages);

  const handleDismiss = () => {
    setInfoVisible(false);
  };

  return (
    <>
      {infoVisible ? (
        budgetExpired ? (
          <Message negative onDismiss={handleDismiss}>
            <Icon name='warning circle' />
            Este orçamento está expirado, não será possível realizar a contratação.
          </Message>
        ) : (
          <Message info onDismiss={handleDismiss}>
            <Icon name='info circle' />
            Este orçamento tem validade de 30 dias a partir da data de criação.
          </Message>
        )
      ) : null}

      <Grid style={{ padding: 5 }} columns={'equal'}>
        <HeaderAndDivider>Dados do orçamento</HeaderAndDivider>
        <Grid.Row>
          <Grid.Column>
            <ControlledField
              label={'ID:'}
              value={fireInsurance?.id}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Criado em:'}
              value={moment(fireInsurance?.created_at).format('DD/MM/YYYY HH:mm')}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Nº do orçamento:'}
              value={fireInsurance?.referenceCode}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <ControlledField
              label={'Valor do aluguel:'}
              value={numberToMoneyString(Number(fireInsurance?.fireInsurance?.rentValue) ?? 0)}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Data de Início:'}
              value={moment(fireInsurance?.policyPeriodStart).format('DD/MM/YYYY')}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Data de Término:'}
              value={moment(fireInsurance?.policyPeriodEnd).format('DD/MM/YYYY')}
            />
          </Grid.Column>
        </Grid.Row>

        <HeaderAndDivider>Dados do Imóvel</HeaderAndDivider>
        <Grid.Row>
          <Grid.Column>
            <ControlledField
              label={'CEP:'}
              value={maskZipCode(fireInsurance?.fireInsurance?.propertyAddresses.zipcode ?? '')}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Logradouro:'}
              value={fireInsurance?.fireInsurance?.propertyAddresses.street}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Bairro:'}
              value={fireInsurance?.fireInsurance?.propertyAddresses.district}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <ControlledField
              label={'Cidade:'}
              value={fireInsurance?.fireInsurance?.propertyAddresses.city}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Estado:'}
              value={fireInsurance?.fireInsurance?.propertyAddresses.state ? turnUFIntoEstate(fireInsurance?.fireInsurance?.propertyAddresses.state) : ''}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Número:'}
              value={fireInsurance?.fireInsurance?.propertyAddresses.number}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <ControlledField
              label={'Complemento:'}
              value={fireInsurance?.fireInsurance?.propertyAddresses.complement ?? 'SEM COMPLEMENTO'}
            />
          </Grid.Column>
        </Grid.Row>

        <HeaderAndDivider>Dados do Proponente</HeaderAndDivider>
        <Grid.Row>
          <Grid.Column>
            <ControlledField
              label={'Nome/Razão Social:'}
              value={fireInsurance?.fireInsurance?.tenantName}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'CPF/CNPJ:'}
              value={maskCpfOrCnpj(fireInsurance?.fireInsurance?.tenantDocument ?? '')}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={
                fireInsurance?.fireInsurance?.tenantDocument.length === 11
                ? 'Data de Nascimento:'
                : 'Data de fundação da empresa'
              }
              value={moment(fireInsurance?.fireInsurance?.tenantBirthdate).format('DD/MM/YYYY')}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <ControlledField
              label={'E-mail:'}
              value={fireInsurance?.fireInsurance?.tenantEmail}
            />
          </Grid.Column>

          <Grid.Column>
            <ControlledField
              label={'Telefone:'}
              value={maskPhoneNumber(fireInsurance?.fireInsurance?.tenantCellphone ?? '')}
            />
          </Grid.Column>

          <Grid.Column></Grid.Column>
        </Grid.Row>
      </Grid>

      <HeaderAndDivider>Dados de cobertura do seguro incêndio</HeaderAndDivider>
      <QuotationContainer>
      <QuotationHeader>
        <h3>
          <Icon name='shield' /> Coberturas
        </h3>
      </QuotationHeader>
      <QuotationResultContainer>
        <QuotationList>
            <>
              <CoveragesRow>
                <div>
                  <span className='insurance-name'>
                    <Icon name='shield' className='low-opacity' /> Incêndio &nbsp;
                    <span className='required'>(Obrigatório)</span>
                  </span>
                </div>
                <div>
                  <span className='insurance-value'>
                    {numberToMoneyString(coverages?.fireCoverageValue ?? 0)}
                  </span>
                </div>
              </CoveragesRow>

              <CoveragesRow>
                <div>
                  <span className={'full-insurance-name'}>
                    <input
                      type='checkbox'
                      disabled
                      checked={coverages?.fireCoverageValue ? true : false}
                    />Cobertura responsabilidade civil
                  </span>
                </div>
                <div>
                  <span className='insurance-value'>
                    {numberToMoneyString(coverages?.civilLiabilityCoverageValue ?? 0)}
                  </span>
                </div>
              </CoveragesRow>

              <CoveragesRow>
                <div>
                  <span className={'full-insurance-name'}>
                    <input
                      type='checkbox'
                      disabled
                      checked={coverages?.coverageContentValue ? true : false}
                    />Cobertura de conteúdo
                  </span>
                </div>
                <div>
                  <span className='insurance-value'>
                    {numberToMoneyString(coverages?.coverageContentValue ?? 0)}
                  </span>
                </div>
              </CoveragesRow>

              <CoveragesRow>
                <div>
                  <span className={'full-insurance-name'}>
                    <input
                      type='checkbox'
                      disabled
                      checked={coverages?.electricalDamageCoverageValue ? true : false}
                    />Cobertura danos elétricos
                  </span>
                </div>
                <div>
                  <span className='insurance-value'>
                    {numberToMoneyString(coverages?.electricalDamageCoverageValue ?? 0)}
                  </span>
                </div>
              </CoveragesRow>

              <CoveragesRow>
                <div>
                  <span className={'full-insurance-name'}>
                    <input
                      type='checkbox'
                      disabled
                      checked={coverages?.coverageImpactsAirLandVehiclesValue ? true : false}
                    />Cobertura por impactos de veículos terrestres ou aéreos
                  </span>
                </div>
                <div>
                  <span className='insurance-value'>
                    {numberToMoneyString(coverages?.coverageImpactsAirLandVehiclesValue ?? 0)}
                  </span>
                </div>
              </CoveragesRow>

              <CoveragesRow>
                <div>
                  <span className={'full-insurance-name'}>
                    <input
                      type='checkbox'
                      disabled
                      checked={coverages?.rentalLossCoverageValue ? true : false}
                    />Cobertura por perda de aluguel
                  </span>
                </div>
                <div>
                  <span className='insurance-value'>
                    {numberToMoneyString(coverages?.rentalLossCoverageValue ?? 0)}
                  </span>
                </div>
              </CoveragesRow>

              <CoveragesRow>
                <div>
                  <span className={'full-insurance-name'}>
                    <input
                      type='checkbox'
                      disabled
                      checked={coverages?.galeCoverageValue ? true : false}
                    />Cobertura por vendaval
                  </span>
                </div>
                <div>
                  <span className='insurance-value'>
                    {numberToMoneyString(coverages?.galeCoverageValue ?? 0)}
                  </span>
                </div>
              </CoveragesRow>

              <CoveragesRow>
                <div>
                  <span className={'full-insurance-name'}>
                    <input
                      type='checkbox'
                      disabled
                      checked={coverages?.riotCoverageValue ? true : false}
                    />Cobertura por tumulto
                  </span>
                </div>
                <div>
                  <span className='insurance-value'>
                    {numberToMoneyString(coverages?.riotCoverageValue ?? 0)}
                  </span>
                </div>
              </CoveragesRow>
            </>
        </QuotationList>
        <QuotationValue>
          <h3 style={{ fontSize: '20px' }}>Seu orçamento</h3>
          <label style={{ fontSize: '13px', paddingBottom: '10px' }}>
            {fireInsurance.installments} x FATURA MENSAL - SEM ENTRADA
          </label>
          <small style={{ fontSize: '15px' }}>
            Valor da primeira parcela: {numberToMoneyString(Number(fireInsurance.valueFirstInstallment))}
          </small>
          <small style={{ fontSize: '15px', paddingBottom: '10px' }}>
            Valor das demais parcelas: {numberToMoneyString(Number(fireInsurance.value))}
          </small>
          <label style={{ fontSize: '17px' }}>
            Total: {
              numberToMoneyString(
                Number(fireInsurance.valueFirstInstallment) +
                Number((fireInsurance.installments - 1) * (fireInsurance.value))
              )
            }
          </label>
        </QuotationValue>
      </QuotationResultContainer>
      </QuotationContainer>

      <Segment style={{ display: showJson ? 'block' : 'none' }}>
        <pre style={{ width: '100%', height: '40vh', overflowX: 'auto' }}>
          {JSON.stringify(fireInsurance, null, 2)}
        </pre>
      </Segment>
    </>
  );
};

export default PortoSeguroView;
