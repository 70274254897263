import { UserState } from "./UserState";

export enum BannerStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export interface Banner {
  id?: string;

  banner?: any;

  createdBy?: UserState | string;

  updatedBy?: UserState | string;

  title: string;

  observations?: string;

  imageUrl?: string;

  redirectUrl?: string;

  status: BannerStatusEnum;

  newTab: boolean;

  createdAt?: Date;

  updatedAt?: Date;
}
