import React, {SyntheticEvent, useCallback, useEffect} from 'react';
import Input from '../../../../components/Input';
import { FireInsuranceBudgetFormSectionProps } from '../../FireInsuranceBudgetForm';
import SectionLabel from '../../../../components/SectionLabel';
import {Grid, InputOnChangeData} from 'semantic-ui-react';
import {FastField, Field} from 'formik';
import { maskCpfOrCnpj } from '../../../../services/masks';
import { FormikErrorMessage } from '../../../../components/ErrorMessage';
import { FieldLabel } from '../../../../styles';
import {iRootDispatch, iRootState} from "../../../../store";
import {OwnerState} from "../../../../types/FireInsurance";
import {connect} from "react-redux";
import {reach, Schema} from "yup";
import {ownerInputSectionYupSchema} from "../../validators/owner-input-section/owner-input-section-schema";
import {debounce, throttle} from "lodash";

const mapDispatchToProps = (dispatch: iRootDispatch) => ({
  updateInputData: (payload: Partial<OwnerState>) => dispatch.fireInsuranceBudget.updateOwnerInputData(payload),
  resetInputData: () => dispatch.fireInsuranceBudget.resetOwnerFormSection()
});

const mapStateToProps = (state: iRootState) => ({
  selectedInsurerData: state.fireInsuranceBudget.selected.insurer,
  setFormikFieldTouched: (fieldName: string, touched: boolean) => state.formik.actions.setFieldTouched &&
    state.formik.actions?.setFieldTouched(fieldName, touched),
  setFormikFieldValue: (fieldName: string, value: any) => state.formik.actions.setFieldValue &&
    state.formik.actions?.setFieldValue(fieldName, value),
  toggleToResetForm: state.fireInsuranceBudget.toggleToResetFormSections.ownerInputSection
});

export type OwnerDataProps = {

} & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & FireInsuranceBudgetFormSectionProps

const OwnerData = (props: OwnerDataProps) => {
  const debouncedUpdate = useCallback(debounce((payload) => props.updateInputData(payload), 250),[]);
  const throttledUpdate = useCallback(throttle((payload) => props.updateInputData(payload), 1000),[]);

  const {
    sectionKey,
    toggleToResetForm,
    setFormikFieldTouched,
    setFormikFieldValue,
    updateInputData,
    resetInputData,
    selectedInsurerData
  } = props;

  const validate = async (fieldName: string, value: any) => {
    let error;
    let fieldSchema: Schema<any>;

    try {
      fieldSchema = reach(ownerInputSectionYupSchema, fieldName);
    } catch (e) {
      return undefined;
    }

    try {
      const res = await fieldSchema.validate(value);
    } catch (e) {
      error = (e as any).message;
    } finally {
      return error;
    }
  }

  const onChangeDocument = (e: SyntheticEvent, data: any) => {
    const dataCopy = {...data};
    dataCopy.value = maskCpfOrCnpj(data.value);

    handleChange(e, dataCopy);
  }

  const onBlurDocument = (e: any) => {
    e.persist();

    const eventCopy = {...e};
    eventCopy.target.value = maskCpfOrCnpj(e.target.value);

    handleBlur(e);
  }

  const handleChange = (_: any, data: InputOnChangeData) => {
    const updatePayload: any = {};
    const formikFieldName = data.name;
    const sanitizedName = formikFieldName.replace(`${sectionKey}.`,'');

    updatePayload[sanitizedName] = data.value;
    setFormikFieldValue(formikFieldName, data.value);
    setFormikFieldTouched(formikFieldName, true);

    debouncedUpdate(updatePayload);
  };

  const handleBlur = (e: any) => {
    e.persist();

    const updatePayload: any = {};
    const formikFieldName = e.target.name;
    const sanitizedName = formikFieldName.replace(`${sectionKey}.`,'');

    updatePayload[sanitizedName] = e.target.value;

    updateInputData(updatePayload);
  };

  const clearFormValues = () => {
    setFormikFieldValue(`${sectionKey}.document`, '');
    setFormikFieldValue(`${sectionKey}.name`, '');
    setFormikFieldValue(`${sectionKey}.email`, '');

    setFormikFieldTouched(`${sectionKey}.document`, false);
    setFormikFieldTouched(`${sectionKey}.name`, false);
    setFormikFieldTouched(`${sectionKey}.email`, false);

    resetInputData();
  }

  useEffect(() => {
    clearFormValues();
  }, [selectedInsurerData.code, toggleToResetForm]);

  return (
    <>
      <SectionLabel text='DADOS DO PROPRIETÁRIO' />
      <Grid.Row>
        <Grid.Column width={4}>
          <FieldLabel>CPF/CNPJ<span style={{color: 'red'}}>*</span></FieldLabel>
          <Field name="budget.owner.document"
            validate={(value: any) => validate('document', value)}
            component={Input}
            onChange={onChangeDocument}
            onBlur={onBlurDocument}
            fluid />
          <FormikErrorMessage component="div" name="budget.owner.document" />
        </Grid.Column>
        <Grid.Column width={6}>
          <FieldLabel>NOME/RAZÃO SOCIAL<span style={{color: 'red'}}>*</span></FieldLabel>
          <Field name="budget.owner.name"
            validate={(value: any) => validate('name', value)}
            component={Input}
            onChange={handleChange}
            onBlur={handleBlur}
            fluid />
          <FormikErrorMessage component="div" name="budget.owner.name" />
        </Grid.Column>
        <Grid.Column width={6}>
          <FieldLabel>E-MAIL</FieldLabel>
          <Field name="budget.owner.email"
            validate={(value: any) => validate('email', value)}
            component={Input}
            onChange={handleChange}
            onBlur={handleBlur}
            fluid />
          <FormikErrorMessage component="div" name="budget.owner.email" />
        </Grid.Column>
      </Grid.Row>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnerData)
