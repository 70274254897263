import { iRootState, iRootDispatch } from '../../store';
import { Formik, FormikProps } from 'formik';
import { estateFormInitialValues, UpdateEstateFormValues } from './types';
import UpdateEstateFormContent from './UpdateEstateFormContent';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  getEstate,
  getPartialEstateFormValuesFromGetEstateDTO
} from '../../services/estate';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import { dangerNotification, successNotification } from '../../services/notification';
import { put } from '../../services/request';
import { unmaskCpfOrCnpj, unmaskMoney } from '../../services/masks';
import { getEstatesObjectsFromGetEstatesDTO, getEstates } from '../../services/broker';
import { EstatesToCustomDropDownOptions } from '../../util';
import {
  AvailableBrokersAndEstatesState,
  InsurersListState
} from '../../store/types/temp-types';
import { EstateForGetEstatesResponseDTO } from '../../dtos/estate/estate-for-get-estates-response.dto';
import {
  EstateInsurerDataForUpdateEstateRequestDTO,
  UpdateEstateRequestDTO
} from '../../dtos/estate/update-estate-request.dto';
import { updateEstateSchema } from './validators';
import { InsurersCodeEnum } from '../../enums/insurers-code.enum';
import { AxiosError } from 'axios';
import { isValidEmail } from '../../utils/emailValidator';
import {
  create as createPartner,
  update as updatePartner
} from '../../services/estate-partner';

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  setLoading: (loading: boolean) => dispatch.loading.setLoading(loading),
  updateInsurersList: (insurers: InsurersListState) =>
    dispatch.insurers.updateInsurersList(insurers),
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

export type EstateFormProps = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch> &
  FormikProps<any> & {
    handleInputChange: Function;
    onSubmit: Function;
  };

const UpdateEstateForm: React.FC<EstateFormProps> = (props: EstateFormProps) => {
  const [infoEstate, setInfoEstate] = useState<UpdateEstateFormValues>(
    estateFormInitialValues
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const params: any = useParams();
  const { user } = props;

  const getPayloadFormData = (values: UpdateEstateFormValues) => {
    const { bankAccount } = values;

    if (!values.broker.id) {
      dangerNotification('Corretora', 'O campo Corretora não foi preenchido.');
      return;
    }
    if (!values.email) {
      dangerNotification(
        'E-mail Principal',
        'O campo E-mail Principal não foi preenchido.'
      );
      return;
    }
    if (!values.estateAnalystUser.id) {
      dangerNotification(
        'Analista Responsável',
        'O campo Analista Responsável não foi preenchido.'
      );
      return;
    }
    if (!values.name) {
      dangerNotification(
        'Nome da Imobiliária',
        'O campo Nome da Imobiliária não foi preenchido.'
      );
      return;
    }
    if (!values.company_name) {
      dangerNotification('Razão Social', 'O campo Razão Social não foi preenchido.');
      return;
    }
    if (!values.cnpj) {
      dangerNotification('CNPJ', 'O campo CNPJ não foi preenchido.');
      return;
    }
    if (unmaskCpfOrCnpj(values.cnpj).length === 11 && !values.creci) {
      dangerNotification('CRECI', 'O campo CRECI não foi preenchido.');
      return;
    }
    if (!values.cnpj) {
      dangerNotification('Nome Fantasia', 'O campo Nome Fantasia não foi preenchido.');
      return;
    }
    if (!values.contactSource) {
      dangerNotification(
        'Origem do Contato',
        'O campo Origem do Contato não foi preenchido'
      );
      return;
    }
    if (!values.financialResponsible) {
      dangerNotification(
        'Responsável Financeiro',
        'O campo Responsável Financeiro não foi preenchido'
      );
      return;
    }
    if (values.isPortoTradicionalCapitalizationSelected && !values.portoCode) {
      dangerNotification(
        'Código da Seguradora',
        'O campo Código da Seguradora não foi preenchido'
      );
      return;
    }
    if (!values.financialEmail) {
      dangerNotification(
        'Email do Financeiro',
        'O campo Email do Financeiro não foi preenchido'
      );
      return;
    }
    if (!values.estateZipcode) {
      dangerNotification('CEP', 'O campo CEP não foi preenchido');
      return;
    }
    if (!values.estateStreet) {
      dangerNotification('Logradouro', 'O campo Logradouro não foi preenchido');
      return;
    }
    if (!values.estateDistrict) {
      dangerNotification('Bairro', 'O campo Bairro não foi preenchido');
      return;
    }
    if (!values.estateCity) {
      dangerNotification('Cidade', 'O campo Cidade não foi preenchido');
      return;
    }
    if (!values.estateNumber) {
      dangerNotification('Número', 'O campo Número no Endereço não foi preenchido');
      return;
    }
    if (!values.estateState) {
      dangerNotification('Estado', 'O campo Estado não foi preenchido');
      return;
    }
    if (!values.calculationMode) {
      dangerNotification('Cálculo', 'O campo Cálculo não foi preenchido');
      return;
    }
    if (!values.responsiblePhone) {
      dangerNotification(
        'Telefone do Responsável',
        'O campo Telefone do Responsável não foi preenchido.'
      );
      return;
    }
    if (!bankAccount.bankCode) {
      dangerNotification('Banco', 'O campo Banco não foi preenchido.');
      return;
    }
    if (!bankAccount.agencyNumber) {
      dangerNotification('Agência', 'O campo Agência não foi preenchido.');
      return;
    }
    if (!bankAccount.accountNumber) {
      dangerNotification('Conta', 'O campo Conta não foi preenchido.');
      return;
    }
    if (
      values.isPottencialBondInsuranceSelected &&
      !values.insurerSpecificData.pottencial.bondInsurance.multiple
    ) {
      dangerNotification(
        'Multiplo Pottencial',
        'O campo Multiplo Pottencial não foi preenchido.'
      );
      return;
    }

    if (!values.averagePropertiesWallet) {
      dangerNotification(
        'Média de Imóveis na Carteira',
        'O campo Média de Imóveis na Carteira não foi preenchido'
      );
      return;
    }

    if (Number(values.averagePropertiesWallet) === 0) {
      dangerNotification(
        'Média de Imóveis na Carteira',
        'Média de Imóveis na Carteira não pode ser igual a 0!'
      );
      return;
    }

    if (
      values.isPottencialBondInsuranceSelected &&
      !values.insurerSpecificData.pottencial.bondInsurance.assurance
    ) {
      dangerNotification(
        'Cobertura Pottencial',
        'O campo Tipo de Coberturas - Pottencial não foi preenchido.'
      );
      return;
    }

    if (values.isPortoEssencialBondInsuranceSelected) {
      if (!values.portoCode) {
        dangerNotification(
          'Código Porto',
          'O Código da Seguradora Porto não foi preenchido. Campo obrigatório para Porto Essencial.'
        );
        return;
      } else {
        if (values.portoCode.length !== 6) {
          dangerNotification(
            'Código Porto',
            'O Código da Seguradora Porto deve ter 6 dígitos. Campo obrigatório para Porto Essencial.'
          );
          return;
        }
      }
    }

    const payload: UpdateEstateRequestDTO = {
      has_preference_bond_insurance: values.hasPreferenceBondInsurance || false,
      name: values.name,
      observations: values.observations,
      email: values.email.trim().toLowerCase(),
      emailsToInsert: values.emailsToInsert ? values.emailsToInsert : undefined,
      emailsToRemove: values.emailsToRemove ? values.emailsToRemove : undefined,
      cnpj: unmaskCpfOrCnpj(values.cnpj),
      creci: values.creci ? values.creci : undefined,
      franchiseId: values.franchiseId ? values.franchiseId : undefined,
      company_name: values.company_name,
      broker_id: values.broker.id,
      user_id: values.estateAnalystUser.id,
      bank_code: bankAccount.bankCode,
      agency_number: bankAccount.agencyNumber,
      agency_dv: bankAccount.agencyDv,
      account_number: bankAccount.accountNumber,
      account_dv: bankAccount.accountDv,
      bank_account_id: bankAccount.id,
      estate_zipcode: values.estateZipcode,
      estate_street: values.estateStreet,
      estate_number: values.estateNumber,
      estate_district: values.estateDistrict,
      estate_complement: values.estateComplement,
      estate_city: values.estateCity,
      estate_state: values.estateState,
      contact_source: values.contactSource,
      additional_emails: values.additionalEmails?.trim().toLowerCase(),
      porto_code: values.portoCode,
      monthly_tenantry_capacity: values.monthlyTenantryCapacity
        ? Number(values.monthlyTenantryCapacity)
        : 0,
      average_properties_wallet: Number(values.averagePropertiesWallet),
      classification: values.classification,
      additional_account_manager: values.additionalAccountManager
        ? values.additionalAccountManager.id
        : null,
      claimResponsible: values.claimResponsible ? values.claimResponsible.id : null,
      responsible_name: values.responsibleName ? values.responsibleName : undefined,
      responsible_email: values.responsibleEmail
        ? values.responsibleEmail.trim().toLowerCase()
        : undefined,
      responsible_phone: values.responsiblePhone ? values.responsiblePhone : undefined,
      financial_email: values.financialEmail?.trim().toLowerCase(),
      financial_responsible: values.financialResponsible,
      responsible_company: values.responsible_company,
      status: values.status,
      insurers: values.bondInsurers.map(insurer => {
        return {
          id: insurer.id,
          code: insurer.code,
          name: insurer.name
        };
      }),
      fire_insurers: values.fireInsurers.map(insurer => {
        return {
          id: insurer.id,
          code: insurer.code,
          name: insurer.name
        };
      }),
      capitalizations: values.capitalizations.map(insurer => {
        return {
          id: insurer.id,
          code: insurer.code,
          name: insurer.name
        };
      }),
      editedBy: user.id,
      partners:
        values?.partners?.map(partner => ({
          id: partner?.id,
          name: partner.name,
          email: partner.email,
          document: unmaskCpfOrCnpj(partner.document)
        })) || []
    };

    if (!values.email) {
      dangerNotification(
        'E-mail Principal',
        'O campo E-mail Principal não foi preenchido.'
      );
      return;
    }

    if (!isValidEmail(payload.email)) {
      dangerNotification(
        'E-mail Principal',
        'O campo E-mail Principal é inválido, verifique e tente novamente.'
      );
      return;
    }

    if (payload.financial_email && !isValidEmail(payload.financial_email)) {
      dangerNotification(
        'E-mail Financeiro',
        'O campo E-mail do responsável financeiro é inválido, verifique e tente novamente.'
      );
      return;
    }

    if (payload.responsible_email && !isValidEmail(payload.responsible_email)) {
      dangerNotification(
        'E-mail Responsável',
        'O campo E-mail do responsável é inválido, verifique e tente novamente.'
      );
      return;
    }

    if (payload.additional_emails) {
      const additionalEmails = payload.additional_emails.split(';');
      for (let i = 0; i < additionalEmails.length; i++) {
        if (!isValidEmail(additionalEmails[i]) && additionalEmails[i].length !== 0) {
          dangerNotification(
            'E-mail Adicional',
            "O campo E-mail Adicional '" +
              additionalEmails[i] +
              "' é inválido, verifique e tente novamente."
          );
          return;
        }
      }
    }

    const insurerSpecificData: EstateInsurerDataForUpdateEstateRequestDTO[] = [];

    if (values.isTooSegurosBondInsuranceSelected) {
      if (!values.insurerSpecificData.tooSeguros.bondInsurance.commissionPercentage) {
        dangerNotification(
          'Too Seguros',
          'Porcentagem da comissão não preenchida. Favor inserir um número válido.'
        );
        return;
      }
      const tooSegurosInsurer = values.bondInsurers.find(insurer => {
        return insurer.code === InsurersCodeEnum.Too;
      });

      if (tooSegurosInsurer) {
        const pfResidentialTax: number | undefined =
          values.insurerSpecificData.tooSeguros.bondInsurance.pfResidentialTax &&
          values.insurerSpecificData.tooSeguros.bondInsurance.pfResidentialTax !== '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.tooSeguros.bondInsurance.pfResidentialTax
              ) / 100
            : undefined;

        const pfComercialTax: number | undefined =
          values.insurerSpecificData.tooSeguros.bondInsurance.pfComercialTax &&
          values.insurerSpecificData.tooSeguros.bondInsurance.pfComercialTax !== '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.tooSeguros.bondInsurance.pfComercialTax
              ) / 100
            : undefined;

        const commissionPercentage: number | undefined =
          values.insurerSpecificData.tooSeguros.bondInsurance.commissionPercentage &&
          values.insurerSpecificData.tooSeguros.bondInsurance.commissionPercentage !==
            '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.tooSeguros.bondInsurance.commissionPercentage
              ) / 100
            : undefined;

        const lmi: number = values.insurerSpecificData.tooSeguros.bondInsurance.lmi;

        const assurance = values.insurerSpecificData.tooSeguros.bondInsurance.assurance;

        if (!lmi) {
          dangerNotification(
            'Too Seguros',
            'LMI  não preenchido. Favor selecionar uma opção.'
          );
          return;
        }

        if (!assurance) {
          dangerNotification(
            'Too Seguros',
            'Tipo de cobertura não preenchida. Favor selecionar uma opção.'
          );
          return;
        }

        if (!pfResidentialTax) {
          dangerNotification(
            'Too Seguros',
            'Taxa Residêncial não preenchida. Por favor, insira uma porcentagem válida.'
          );
          return;
        }

        if (!pfComercialTax) {
          dangerNotification(
            'Too Seguros',
            'Taxa Comercial não preenchida. Por favor, insira uma porcentagem válida.'
          );
          return;
        }
        insurerSpecificData.push({
          id: values.insurerSpecificData.tooSeguros.bondInsurance.tableId,
          estateId: params.id,
          insurerId: tooSegurosInsurer?.id ? tooSegurosInsurer.id : '',
          insuranceType: 'BOND_INSURANCE',
          orderPreference: Number(
            values.insurerSpecificData.tooSeguros.bondInsurance.orderPreference
          ),
          commissionPercentage: commissionPercentage ? commissionPercentage / 100 : 0,
          pfResidentialTax: pfResidentialTax / 100,
          pfComercialTax: pfComercialTax / 100,
          lmi,
          assurance
        });
      }
    }

    if (values.isTokioMarineBondInsuranceSelected) {
      if (!values.insurerSpecificData.tokioMarine.bondInsurance.commissionPercentage) {
        dangerNotification(
          'Tokio Marine',
          'Porcentagem da comissão não preenchida. Favor inserir um número válido.'
        );
        return;
      }
      const tokioMarineInsurer = values.bondInsurers.find(insurer => {
        return insurer.code === InsurersCodeEnum.TokioMarine;
      });

      if (tokioMarineInsurer) {
        const pfResidentialTax: number | undefined =
          values.insurerSpecificData.tokioMarine.bondInsurance.pfResidentialTax &&
          values.insurerSpecificData.tokioMarine.bondInsurance.pfResidentialTax !== '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.tokioMarine.bondInsurance.pfResidentialTax
              ) / 100
            : undefined;

        const pfComercialTax: number | undefined =
          values.insurerSpecificData.tokioMarine.bondInsurance.pfComercialTax &&
          values.insurerSpecificData.tokioMarine.bondInsurance.pfComercialTax !== '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.tokioMarine.bondInsurance.pfComercialTax
              ) / 100
            : undefined;

        const commissionPercentage: number | undefined =
          values.insurerSpecificData.tokioMarine.bondInsurance.commissionPercentage &&
          values.insurerSpecificData.tokioMarine.bondInsurance.commissionPercentage !==
            '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.tokioMarine.bondInsurance.commissionPercentage
              ) / 100
            : undefined;

        const lmi: number = values.insurerSpecificData.tokioMarine.bondInsurance.lmi;

        const assurance = values.insurerSpecificData.tokioMarine.bondInsurance.assurance;

        if (!lmi) {
          dangerNotification(
            'Tokio Marine',
            'LMI  não preenchido. Favor selecionar uma opção.'
          );
          return;
        }

        if (!assurance) {
          dangerNotification(
            'Tokio Marine',
            'Tipo de cobertura não preenchida. Favor selecionar uma opção.'
          );
          return;
        }

        if (!pfResidentialTax) {
          dangerNotification(
            'Tokio Marine',
            'Taxa Residêncial não preenchida. Por favor, insira uma porcentagem válida.'
          );
          return;
        }

        if (!pfComercialTax) {
          dangerNotification(
            'Tokio Marine',
            'Taxa Comercial não preenchida. Por favor, insira uma porcentagem válida.'
          );
          return;
        }
        insurerSpecificData.push({
          id: values.insurerSpecificData.tokioMarine.bondInsurance.tableId,
          estateId: params.id,
          insurerId: tokioMarineInsurer?.id ? tokioMarineInsurer.id : '',
          insuranceType: 'BOND_INSURANCE',
          orderPreference: Number(
            values.insurerSpecificData.tokioMarine.bondInsurance.orderPreference
          ),
          commissionPercentage: commissionPercentage ? commissionPercentage / 100 : 0,
          pfResidentialTax: pfResidentialTax / 100,
          pfComercialTax: pfComercialTax / 100,
          lmi,
          assurance
        });
      }
    }

    if (values.isGaranttiBondInsuranceSelected) {
      if (!values.insurerSpecificData.garantti.bondInsurance.commissionPercentage) {
        dangerNotification(
          'Garantti',
          'Porcentagem da comissão não preenchida. Favor inserir um número válido.'
        );
        return;
      }
      const garanttiInsurer = values.bondInsurers.find(insurer => {
        return insurer.code === InsurersCodeEnum.Garantti;
      });

      const pfResidentialTax: number | undefined =
        values.insurerSpecificData.garantti.bondInsurance.pfResidentialTax &&
        values.insurerSpecificData.garantti.bondInsurance.pfResidentialTax !== '0,00'
          ? unmaskMoney(
              values.insurerSpecificData.garantti.bondInsurance.pfResidentialTax
            ) / 100
          : undefined;

      const pfComercialTax: number | undefined =
        values.insurerSpecificData.garantti.bondInsurance.pfComercialTax &&
        values.insurerSpecificData.garantti.bondInsurance.pfComercialTax !== '0,00'
          ? unmaskMoney(
              values.insurerSpecificData.garantti.bondInsurance.pfComercialTax
            ) / 100
          : undefined;

      const lmi: number = values.insurerSpecificData.garantti.bondInsurance.lmi;

      const garanttiPlan: string =
        values.insurerSpecificData.garantti.bondInsurance.garanttiPlan;
      const garanttiBonus: boolean =
        values.insurerSpecificData.garantti.bondInsurance.garanttiBonus;

      const commissionPercentage: number | undefined =
        values.insurerSpecificData.garantti.bondInsurance.commissionPercentage &&
        values.insurerSpecificData.garantti.bondInsurance.commissionPercentage !== '0,00'
          ? unmaskMoney(
              values.insurerSpecificData.garantti.bondInsurance.commissionPercentage
            ) / 100
          : undefined;

      if (garanttiInsurer) {
        insurerSpecificData.push({
          id: values.insurerSpecificData.garantti.bondInsurance.tableId,
          estateId: params.id,
          insurerId: garanttiInsurer?.id ? garanttiInsurer.id : '',
          insuranceType: 'BOND_INSURANCE',
          commissionPercentage: commissionPercentage ? commissionPercentage / 100 : 0,
          pfResidentialTax: pfResidentialTax ? pfResidentialTax / 100 : 0,
          pfComercialTax: pfComercialTax ? pfComercialTax / 100 : 0,
          lmi,
          garanttiPlan,
          garanttiBonus,
          orderPreference: Number(
            values.insurerSpecificData.garantti.bondInsurance.orderPreference
          )
        });
      }
    }

    if (values.isPortoEssencialBondInsuranceSelected) {
      if (!values.insurerSpecificData.portoEssencial.bondInsurance.commissionPercentage) {
        dangerNotification(
          'Porto Essencial',
          'Porcentagem da comissão não preenchida. Favor inserir um número válido.'
        );
        return;
      }
      const portoEssencial = values.bondInsurers.find(insurer => {
        return insurer.code === InsurersCodeEnum.PortoEssencial;
      });

      if (portoEssencial) {
        const pfResidentialTax: number | undefined =
          values.insurerSpecificData.portoEssencial.bondInsurance.pfResidentialTax &&
          values.insurerSpecificData.portoEssencial.bondInsurance.pfResidentialTax !==
            '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.portoEssencial.bondInsurance.pfResidentialTax
              ) / 100
            : undefined;

        const pfComercialTax: number | undefined =
          values.insurerSpecificData.portoEssencial.bondInsurance.pfComercialTax &&
          values.insurerSpecificData.portoEssencial.bondInsurance.pfComercialTax !==
            '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.portoEssencial.bondInsurance.pfComercialTax
              ) / 100
            : undefined;

        const commissionPercentage: number | undefined =
          values.insurerSpecificData.portoEssencial.bondInsurance.commissionPercentage &&
          values.insurerSpecificData.portoEssencial.bondInsurance.commissionPercentage !==
            '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.portoEssencial.bondInsurance
                  .commissionPercentage
              ) / 100
            : undefined;

        const lmi: number = values.insurerSpecificData.portoEssencial.bondInsurance.lmi;

        const assurance =
          values.insurerSpecificData.portoEssencial.bondInsurance.assurance;

        if (!lmi) {
          dangerNotification(
            'Porto Essencial',
            'LMI  não preenchido. Favor selecionar uma opção.'
          );
          return;
        }

        if (!assurance) {
          dangerNotification(
            'Porto Essencial',
            'Tipo de cobertura não preenchido. Favor selecionar uma opção.'
          );
          return;
        }

        if (!pfResidentialTax) {
          dangerNotification(
            'Porto Essencial',
            'Taxa Residêncial não preenchida. Por favor, insira uma porcentagem válida.'
          );
          return;
        }

        if (!pfComercialTax) {
          dangerNotification(
            'Porto Essencial',
            'Taxa Comercial não preenchida. Por favor, insira uma porcentagem válida.'
          );
          return;
        }

        insurerSpecificData.push({
          id: values.insurerSpecificData.portoEssencial.bondInsurance.tableId,
          estateId: params.id,
          insurerId: portoEssencial?.id ? portoEssencial.id : '',
          insuranceType: 'BOND_INSURANCE',
          orderPreference: Number(
            values.insurerSpecificData.portoEssencial.bondInsurance.orderPreference
          ),
          commissionPercentage: commissionPercentage ? commissionPercentage / 100 : 0,
          pfResidentialTax: pfResidentialTax / 100,
          pfComercialTax: pfComercialTax / 100,
          lmi,
          assurance
        });
      }
    }

    if (values.isPortoTradicionalBondInsuranceSelected) {
      if (
        !values.insurerSpecificData.portoTradicional.bondInsurance.commissionPercentage
      ) {
        dangerNotification(
          'Porto Tradicional',
          'Porcentagem da comissão não preenchida. Favor inserir um número válido.'
        );
        return;
      }
      const portoTradicional = values.bondInsurers.find(insurer => {
        return insurer.code === InsurersCodeEnum.Porto;
      });

      if (portoTradicional) {
        const pfResidentialTax: number | undefined =
          values.insurerSpecificData.portoTradicional.bondInsurance.pfResidentialTax &&
          values.insurerSpecificData.portoTradicional.bondInsurance.pfResidentialTax !==
            '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.portoTradicional.bondInsurance.pfResidentialTax
              ) / 100
            : undefined;

        const pfComercialTax: number | undefined =
          values.insurerSpecificData.portoTradicional.bondInsurance.pfComercialTax &&
          values.insurerSpecificData.portoTradicional.bondInsurance.pfComercialTax !==
            '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.portoTradicional.bondInsurance.pfComercialTax
              ) / 100
            : undefined;

        const commissionPercentage: number | undefined =
          values.insurerSpecificData.portoTradicional.bondInsurance
            .commissionPercentage &&
          values.insurerSpecificData.portoTradicional.bondInsurance
            .commissionPercentage !== '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.portoTradicional.bondInsurance
                  .commissionPercentage
              ) / 100
            : undefined;

        const lmi: number = values.insurerSpecificData.portoTradicional.bondInsurance.lmi;

        const assurance =
          values.insurerSpecificData.portoTradicional.bondInsurance.assurance;

        if (!lmi) {
          dangerNotification(
            'Porto Tradicional',
            'LMI  não preenchido. Favor selecionar uma opção.'
          );
          return;
        }

        if (!assurance) {
          dangerNotification(
            'Porto Tradicional',
            'Tipo de cobertura não preenchido. Favor selecionar uma opção.'
          );
          return;
        }

        if (!pfResidentialTax) {
          dangerNotification(
            'Porto Tradicional',
            'Taxa Residêncial não preenchida. Por favor, insira uma porcentagem válida.'
          );
          return;
        }

        if (!pfComercialTax) {
          dangerNotification(
            'Porto Tradicional',
            'Taxa Comercial não preenchida. Por favor, insira uma porcentagem válida.'
          );
          return;
        }

        insurerSpecificData.push({
          id: values.insurerSpecificData.portoTradicional.bondInsurance.tableId,
          estateId: params.id,
          insurerId: portoTradicional?.id ? portoTradicional.id : '',
          insuranceType: 'BOND_INSURANCE',
          orderPreference: Number(
            values.insurerSpecificData.portoTradicional.bondInsurance.orderPreference
          ),
          commissionPercentage: commissionPercentage ? commissionPercentage / 100 : 0,
          pfResidentialTax: pfResidentialTax / 100,
          pfComercialTax: pfComercialTax / 100,
          lmi,
          assurance
        });
      }
    }

    if (values.isPottencialBondInsuranceSelected) {
      if (!values.insurerSpecificData.pottencial.bondInsurance.commissionPercentage) {
        dangerNotification(
          'Pottencial',
          'Porcentagem da comissão não preenchida. Favor inserir um número válido.'
        );
        return;
      }
      const pottencialInsurer = values.bondInsurers.find(insurer => {
        return insurer.code === InsurersCodeEnum.Pottencial;
      });

      const multiple =
        values.insurerSpecificData.pottencial.bondInsurance.multiple || undefined;
      const assurance =
        values.insurerSpecificData.pottencial.bondInsurance.assurance || undefined;

      if (pottencialInsurer) {
        const pfResidentialTax: number | undefined =
          values.insurerSpecificData.pottencial.bondInsurance.pfResidentialTax &&
          values.insurerSpecificData.pottencial.bondInsurance.pfResidentialTax !== '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.pottencial.bondInsurance.pfResidentialTax
              ) / 100
            : undefined;

        const pfComercialTax: number | undefined =
          values.insurerSpecificData.pottencial.bondInsurance.pfComercialTax &&
          values.insurerSpecificData.pottencial.bondInsurance.pfComercialTax !== '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.pottencial.bondInsurance.pfComercialTax
              ) / 100
            : undefined;

        const commissionPercentage: number | undefined =
          values.insurerSpecificData.pottencial.bondInsurance.commissionPercentage &&
          values.insurerSpecificData.pottencial.bondInsurance.commissionPercentage !==
            '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.pottencial.bondInsurance.commissionPercentage
              ) / 100
            : undefined;

        if (!multiple || multiple < 1) {
          dangerNotification(
            'Pottencial',
            'Multiplo não preenchido. Favor selecionar uma opção.'
          );
          return;
        }

        if (!assurance) {
          dangerNotification(
            'Pottencial',
            'Tipo de cobertura não preenchida. Favor selecionar uma opção.'
          );
          return;
        }

        if (!pfResidentialTax || pfResidentialTax < 1) {
          dangerNotification(
            'Pottencial',
            'Taxa Residêncial não preenchida. Por favor, insira uma porcentagem válida.'
          );
          return;
        }

        if (!pfComercialTax || pfComercialTax < 1) {
          dangerNotification(
            'Pottencial',
            'Taxa Comercial não preenchida. Por favor, insira uma porcentagem válida.'
          );
          return;
        }

        insurerSpecificData.push({
          id: values.insurerSpecificData.pottencial.bondInsurance.tableId,
          estateId: params.id,
          insurerId: pottencialInsurer?.id ? pottencialInsurer.id : '',
          insuranceType: 'BOND_INSURANCE',
          multiple,
          assurance,
          orderPreference: Number(
            values.insurerSpecificData.pottencial.bondInsurance.orderPreference
          ),
          commissionPercentage: commissionPercentage ? commissionPercentage / 100 : 0,
          pfResidentialTax: pfResidentialTax / 100,
          pfComercialTax: pfComercialTax / 100
        });
      }
    }

    if (values.isPottencialFireInsuranceSelected) {
      const pottencialInsurer = values.fireInsurers.find(insurer => {
        return insurer.code === InsurersCodeEnum.Pottencial;
      });
      if (pottencialInsurer) {
        insurerSpecificData.push({
          id: values.insurerSpecificData.pottencial.fireInsurance.tableId,
          estateId: params.id,
          insurerId: pottencialInsurer?.id ? pottencialInsurer.id : '',
          insuranceType: 'FIRE_INSURANCE',
          multiplier: Number(
            values.insurerSpecificData.pottencial.fireInsurance.multiplier
          ),
          commissionPercentage: Number(
            values.insurerSpecificData.pottencial.fireInsurance.commissionPercentage
          )
        });
      }
    }

    const alfaInsurer = values?.fireInsurers?.find(
      insurer => insurer.code === InsurersCodeEnum.alfa
    );

    if (alfaInsurer) {
      const alfaData = (values.insurerSpecificData as any).alfa?.fireInsurance;

      const brokerCommission = +alfaData?.brokerCommission || 0;
      const proLabore = +alfaData?.proLabore || 0;

      if ((brokerCommission + proLabore) !== 70) {
        dangerNotification(
          'Erro parametrização Alfa Seguros',
          'O somatório do Pro-labore da Imobiliária e da Corretora deve somar 70%.'
        );
        return;
      }

      const plans: string[] = [];

      alfaData?.plans?.forEach((plan: any) => {
        if (!plan?.id) return;
        plans.push(plan?.id);
      });

      insurerSpecificData.push({
        id: alfaData.tableId,
        estateId: params.id,
        insurerId: alfaInsurer.id,
        insuranceType: 'FIRE_INSURANCE',
        login: alfaData.login,
        password: alfaData.password,
        realEstateCode: alfaData.realEstateCode,
        brokerCommission: Number(alfaData.brokerCommission),
        proLabore: Number(alfaData.proLabore),
        closingDay: Number(alfaData.lotClosingDay),
        transferBy: alfaData.transferBy,
        residentialGrievance: Number(alfaData.residentialSurcharge),
        commercialGrievance: Number(alfaData.commercialSurcharge),
        residentialDiscount: Number(alfaData.residentialDiscount),
        commercialDiscount: Number(alfaData.commercialDiscount),
        installmentOptions: alfaData?.installmentOptions,
        plans,
        coverages: alfaData?.coverages?.map((coverage: any) => ({
          id: coverage.id,
          selectedMinValue: unmaskMoney(coverage.selectedMinValue),
          selectedMaxValue: unmaskMoney(coverage.selectedMaxValue),
          enabled: coverage.selected,
          isRequired: coverage.isRequired,
          multiplier: coverage.multiplier
        }))
      } as any);
    }

    payload.insurerSpecificData = insurerSpecificData;
    // if (payload.insurerSpecificData.length === 0) {
    //   dangerNotification('Ops.', 'Você precisa selecionar ao menos uma Seguradora.');
    //   return;
    // }

    return payload;
  };

  const updateEstate = async (payload: any, props: any) => {
    if (!payload?.partners) return;

    const partners = [...payload.partners];
    let success = false;
    if (payload) {
      if (payload.additional_account_manager?.length === 0)
        payload.additional_account_manager = null;
      if (payload.claimResponsible?.length === 0) payload.claimResponsible = null;
      setLoading(true);
      try {
        delete payload.partners;
        await put(`/estates/${params.id}`, payload);
        const DTO = await getEstates(payload.broker_id);
        const data = getEstatesObjectsFromGetEstatesDTO(DTO);
        // Create or update the partners
        partners.forEach(async partner => {
          if (partner?.name === '' || partner?.document === '') return;

          const partnerToUpdate = {
            ...partner,
            estateId: params.id
          };
          delete partnerToUpdate?.id;
          if (partner?.id) {
            await updatePartner(partner?.id, partnerToUpdate);
          } else {
            await createPartner(partnerToUpdate);
          }
        });

        props.updateAvailableBrokersAndEstates({
          ...props.availableBrokersAndEstates,
          estates: EstatesToCustomDropDownOptions(true, data || [])
        });
        props.history.push('/admin/estates');
        successNotification('Sucesso', 'Imobiliária atualizada com sucesso');
        success = true;
      } catch (error) {
        console.log(error);
        dangerNotification(
          'Oops...',
          (error as AxiosError).response?.data?.message || 'Erro ao atualizar imobiliária'
        );
      } finally {
        setLoading(false);
      }
    }

    return success;
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response: EstateForGetEstatesResponseDTO = await getEstate(params.id);
      const data: Partial<UpdateEstateFormValues> =
        getPartialEstateFormValuesFromGetEstateDTO(response);
      const payload = {
        ...infoEstate,
        ...data
      };
      setInfoEstate(payload);
      setLoading(false);
    })();
  }, []);

  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={infoEstate}
      validationSchema={updateEstateSchema}
      onSubmit={(values: any) => updateEstate(getPayloadFormData(values), props)}
    >
      {innerProps => {
        return (
          <UpdateEstateFormContent
            {...props}
            {...innerProps}
            onSubmit={(values: any) => updateEstate(getPayloadFormData(values), props)}
          />
        );
      }}
    </Formik>
  );
};

export default connect(mapState, mapDispatch)(UpdateEstateForm);
