import React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { iRootState } from '../../store';
import EstatesTable from './Table';
import { ListWrapper } from './styles';

const mapState = (state: iRootState) => ({
  budgetsData: state.fireInsuranceBudgetsList
});

export type Props = ReturnType<typeof mapState> & RouteComponentProps;

const Estates = (props: Props) => {
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  return (
    <>
      <ListWrapper>
        <EstatesTable
          {...props}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </ListWrapper>
    </>
  );
};

export default connect(mapState, null)(Estates);
