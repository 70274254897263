import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Dropdown,
  DropdownItemProps,
  Icon,
  Popup,
  TextArea,
} from "semantic-ui-react";
import { Field, FormikProps } from "formik";
import SectionLabel from "../../../../../../../../components/SectionLabel";
import { FieldLabel } from "../../../../../../../../styles";
import {
  formatAmount,
  maskCpf,
  maskCpfOrCnpj,
  maskDate,
  maskMoney,
  maskPhoneNumber,
  maskZipCode,
  numberToMoneyString,
} from "../../../../../../../../services/masks";
import {
  HireProposalModalProps,
  HireProposalModalPropsForm,
} from "../../../../types/ModalHireProposalProps";
import { SyntheticEvent } from "react-router/node_modules/@types/react";
import { cancellationReasonOptions } from "../../../../../../types";
import { CancellationInitialValues } from "../../Formik/initialStatesCancel";
import _ from "lodash";
import { warningNotification } from "../../../../../../../../services/notification";
import FormatedTextArea from "../../../../../../../../components/FormatedTextArea";
import { get } from "../../../../../../../../services/storage";

const CancellationInsuranceForm = ({
  values,
  setFieldValue,
  flagSubmit,
  callbackSubmit,
  setFlagSubmit,
  bondInsurance
}: FormikProps<typeof CancellationInitialValues> &
  HireProposalModalProps &
  HireProposalModalPropsForm) => {
  const [reasonsOptions, setReasonsOptions] = useState<
    Array<DropdownItemProps>
  >([]);

  const userId = get("uid");

  useEffect(() => {
    setReasonsOptions(cancellationReasonOptions)
  }, []);

  useEffect(() => {
    function submitForm() {
      let error = false;
      setFlagSubmit && setFlagSubmit(false);

      if (!values.cancellationReason) {
        warningNotification(
          "Ops...",
          "Por favor, preencha o motivo da locação!"
        );
        error = true;
      }

      if (error) {
        return;
      }
      const payload = {
        bondInsuranceId: bondInsurance?.id,
        userId: userId,
        cancellationReason: values.cancellationReason,
        observations: values.observations,
      };
      console.log('PAYLOAD: ', payload);
      callbackSubmit(payload);
    }
    flagSubmit && submitForm();
  }, [flagSubmit]);

  return (
    <div>
      <SectionLabel text="INFORMAÇÕES DO CANCELAMENTO" />
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel>MOTIVO DO CANCELAMENTO* </FieldLabel>
            <Dropdown
              name={`bondInsurance.reason`}
              value={values.cancellationReason}
              options={reasonsOptions}
              onChange={(e, { value }) =>
                setFieldValue("cancellationReason", value)
              }
              floating
              search
              selection
              fluid
              placeholder="Selecionar..."
            />
          </Grid.Column>
        </Grid.Row>

        <SectionLabel text="Observações" />
        <Grid.Row>
          <Grid.Column width={16}>
            <Field
              name={`bondInsurance.observations`}
              rows={8}
              value={values.observations}
              onChange={(_: SyntheticEvent, data: any) => {
                setFieldValue(`observations`, data.value);
              }}
              component={FormatedTextArea}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
export default CancellationInsuranceForm;
