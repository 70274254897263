import moment from "moment-timezone";

const COVERAGES = {
  fireCoverageValue: 'Cobertura por incêndio',
  civilLiabilityCoverageValue: 'Cobertura para responsabilidade civil',
  coverageContentValue: 'Cobertura de conteúdo',
  electricalDamageCoverageValue: 'Cobertura danos elétricos',
  coverageImpactsAirLandVehiclesValue: 'Cobertura por impactos de veículos terrestres ou aéreos',
  rentalLossCoverageValue: 'Cobertura por perda de aluguel',
  galeCoverageValue: 'Cobertura por vendaval',
  riotCoverageValue: 'Cobertura por tumulto',
};

interface Coverage {
  coverageName: string;
  value: string;
};

interface Coverages {
  fireCoverageValue: number;
  civilLiabilityCoverageValue: number;
  coverageContentValue: number;
  electricalDamageCoverageValue: number;
  coverageImpactsAirLandVehiclesValue: number;
  rentalLossCoverageValue: number;
  galeCoverageValue: number;
  riotCoverageValue: number;
};

const getKeyByValue = (obj: any, value: any) =>
  Object.keys(obj).find(key => obj[key] === value);

const getCoverages = (coverages: Array<any>): Coverages => {
  const coveragesData: any = {};

  coverages.forEach((coverage: Coverage) => {
    const { coverageName, value } = coverage;
    const newKey = getKeyByValue(COVERAGES, coverageName);

    if (newKey) {
      coveragesData[newKey] = Number(value);
    }
  });

  return coveragesData as Coverages;
};

const budgetIsExpired = (budgetDate: string): boolean => {
  const days = moment().diff(budgetDate, 'days');

  return (days > 30);
};

export {
  getCoverages,
  budgetIsExpired,
};
