import React from 'react';
import { Modal } from 'semantic-ui-react';
import { ViewModalProps } from './types';
import {
  Container,
  FormContainer,
  Form,
  Header,
  Line,
  FormTitle,
  FormContent,
  FormRow
} from './styles';
import { maskCpfOrCnpj, maskPhoneNumber, maskZipCode } from '../../../../services/masks';

const ViewModal: React.FC<ViewModalProps> = ({
  isOpen,
  onClose,
  estate
}: ViewModalProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal open={isOpen} onClose={onClose} closeIcon>
      <Container>
        <Header>
          <h3>{estate?.name}</h3>
        </Header>
        <Line />
        <Form>
          <FormContainer>
            <FormTitle>DADOS CADASTRAIS</FormTitle>
            <Line />
            <FormContent>
              <FormRow>
                <label>
                  <b>CPF/CNPJ</b>
                  <span>{maskCpfOrCnpj(estate?.document)}</span>
                </label>
              </FormRow>
              <FormRow>
                <label>
                  <b>NOME/RAZÃO SOCIAL </b>
                  <span>{estate?.name}</span>
                </label>
                <label>
                  <b>E-MAIL PRINCIPAL</b>
                  <span>{estate?.email}</span>
                </label>
                <label>
                  <b>TELEFONE PRINCIPAL</b>
                  <span>{maskPhoneNumber(estate?.phone)}</span>
                </label>
              </FormRow>
              <FormRow>
                <label>
                  <b>NOME DO RESPONSÁVEL</b>
                  <span>{estate?.responsible_name}</span>
                </label>
                <label>
                  <b>E-MAIL DO RESPONSÁVEL</b>
                  <span>{estate?.responsible_email}</span>
                </label>
                <label>
                  <b>CELULAR DO RESPONSÁVEL</b>
                  <span>{maskPhoneNumber(estate?.responsible_phone)}</span>
                </label>
              </FormRow>
            </FormContent>
          </FormContainer>
          <FormContainer>
            <FormTitle>ENDEREÇO</FormTitle>
            <Line />
            <FormContent>
              <FormRow>
                <label>
                  <b>CEP</b>
                  <span>{maskZipCode(estate?.zipcode)}</span>
                </label>
                <label>
                  <b>LOGRADOURO</b>
                  <span>{estate?.street}</span>
                </label>
                <label>
                  <b>BAIRRO</b>
                  <span>{estate?.district}</span>
                </label>
              </FormRow>
              <FormRow>
                <label>
                  <b>CIDADE</b>
                  <span>{estate?.city}</span>
                </label>
                <label>
                  <b>UF</b>
                  <span>{estate?.state}</span>
                </label>
                <label>
                  <b>NÚMERO</b>
                  <span>{estate?.number}</span>
                </label>
              </FormRow>
              <FormRow>
                <label>
                  <b>COMPLEMENTO</b>
                  <span>{estate?.complement}</span>
                </label>
              </FormRow>
            </FormContent>
          </FormContainer>
         {estate?.observations &&<FormContainer>
            <FormRow>
              <label>
                <b>OBSERVAÇÕES DA PARCERIA</b>
                <span>{estate?.observations}</span>
              </label>
            </FormRow>
          </FormContainer>}
        </Form>
      </Container>
    </Modal>
  );
};

export default ViewModal;
