import { Modal } from "semantic-ui-react";
import styled from "styled-components";

export const InsurerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    height: 86px;
    width: 100px;
    object-fit: fill;
    border-radius: 10px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
  }
`;

export const PlanContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;

  p {
    margin: 0;
    padding: 0;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  padding: 0 1.5rem;
  min-height: 105px;

  h1 {
    font-size: 24px;
    margin-bottom: 8px;
  }

  p {
    font-size: 12px;
    font-weight: 300;
  }
`;

export const CustomModalContent = styled(Modal.Content)`
  width: 100%;
  .ui.grid>.row {
    padding-top: 0px !important;
  }
`;

export const Attention = styled.p`
  color: red;
  margin-left: 0;
  margin-top: 2rem;
`;

export const Separation = styled.hr`
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
`;
