import createPersistPlugin from '@rematch/persist'
import fireInsuranceBudget from './models/fire-insurance-budget'
import availableBrokersAndEstates from './models/available-brokers-and-estates'
import estatesList from './models/estates-list'
import scroll from './models/scroll'
import fireInsuranceBudgetsList from './models/fire-insurance-budgets-list'
import fireInsuranceProposalsList from './models/fire-insurance-proposals-list'
import fireInsurancePoliciesList from './models/fire-insurance-policies-list'
import insurers from './models/insurers'
import user from './models/user'
import simulator from './models/simulator'
import loading from './models/loading'
import currentPage from "./models/current-page";
import currentToggledMenu from './models/current-toggled-menu'
import toggledMenu from './models/toggled-menu'
import bondInsuranceForm from './models/bond-insurance-form'
import formik from './models/formik'
import storage from 'redux-persist/lib/storage'
import { createBrowserHistory } from 'history'
import {ExtractRematchDispatchersFromModels, init, Models, RematchDispatch, RematchRootState} from '@rematch/core';
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createTransform } from 'redux-persist';
import { JsogService } from 'jsog-typescript';
import {MapDispatchToProps, MapDispatchToPropsFactory} from "react-redux";
import bondInsuranceProposalsList from "./models/bond-insurance-proposals-list";

const jsog = new JsogService();

const transformCircular = createTransform(
  (inboundState, key) => jsog.serialize(inboundState),
  (outboundState, key) => jsog.deserialize(outboundState),
)

export const history = createBrowserHistory();

const persistPlugin = createPersistPlugin({
  key: 'root',
  storage: storage,
  blacklist: ['fireInsuranceBudget', 'simulator','bondInsuranceForm', 'scroll', 'formik', 'estatesList', 'availableBrokersAndEstates'],
  transforms: [transformCircular]
});

const reducers = { router: connectRouter(history) };

/*export interface RootModel extends Models<any> {
  fireInsuranceBudget: typeof fireInsuranceBudget;
  availableBrokersAndEstates: typeof availableBrokersAndEstates;
  estatesList: typeof estatesList;
  scroll: typeof scroll;
  fireInsuranceBudgetsList: typeof fireInsuranceBudgetsList;
  fireInsurancePoliciesList: typeof fireInsurancePoliciesList;
  insurers: typeof insurers;
  user: typeof user;
  simulator: typeof simulator;
  loading: typeof loading;
  currentToggledMenu: typeof currentToggledMenu;
  toggledMenu: typeof toggledMenu;
  bondInsuranceForm: typeof bondInsuranceForm;
  currentPage: typeof currentPage;
  formik: typeof formik;
}*/

const models = {
  fireInsuranceBudget,
  availableBrokersAndEstates,
  estatesList,
  scroll,
  fireInsuranceBudgetsList,
  fireInsuranceProposalsList,
  fireInsurancePoliciesList,
  insurers,
  user,
  simulator,
  loading,
  currentToggledMenu,
  toggledMenu,
  bondInsuranceForm,
  bondInsuranceProposalsList,
  currentPage,
  formik
};

export const store = init({
  models,
  plugins: [persistPlugin],
  redux: {
    reducers,
    middlewares: [routerMiddleware(history)],
    devtoolOptions: {},
    rootReducers: {
      'RESET': (state, action) => undefined,
    }
  }
});

export type Store = typeof store;
export type Dispatch = typeof store.dispatch;
export type iRootState = RematchRootState<typeof models>;
/*export type iRootDispatch = RematchDispatch<typeof models>;*/
export type iRootDispatch = any & RematchDispatch<typeof models>;

export default store;
