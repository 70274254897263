import { Dropdown, Grid, Input } from 'semantic-ui-react';
import { FieldLabel } from '../../../../styles';
import { Field, FormikProps } from 'formik';
import React, { SyntheticEvent, useEffect, useState  } from 'react';
import { batchClosingDayOptions, installmentsOptions } from '../../types';
import { iRootDispatch, iRootState } from '../../../../store';
import { AvailableBrokersAndEstatesState } from '../../../../store/types/temp-types';
import CoveragesConfigModal from './ModalContent';
import { get } from '../../../../services/request';
import { DropdownOptions } from '../../../find-record/types/options-find.type';
import { unmaskMoney } from '../../../../services/masks';
import { successNotification } from '../../../../services/notification';

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  setLoading: (loading: boolean) => dispatch.loading.setLoading(loading),
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

export type AlfaFireInsuranceConfigProps =
  ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch> &
  FormikProps<any> & {
    handleInputChange: Function;
  };

interface PlanInterface {
  id: string;
  channelId: string;
  name: string;
  code: string;
};

const AlfaFireInsuranceConfig = (props: AlfaFireInsuranceConfigProps) => {
  const { setFieldValue, handleInputChange } = props;
  const [plansOptions, setPlansOptions] = useState<DropdownOptions[]>([]);

  useEffect(() => {
    const fetchPlans = async () => {
      const allPlans = await get('/alfa-seguros/plan');
      const array: DropdownOptions[] = []
      allPlans.forEach((element: PlanInterface, index: number) => {
        array.push({
          index: index,
          text: element.name,
          value: element as any
        });
      });

      const selectedPlanIds: string[] = props.values.insurerSpecificData?.alfa?.fireInsurance?.plans;
      if(selectedPlanIds && selectedPlanIds.length > 0) {
          const filteredPlans = allPlans.filter((plan: PlanInterface) => selectedPlanIds.includes(plan.id));
          setFieldValue('insurerSpecificData.alfa.fireInsurance.plans', filteredPlans);
      }

      setPlansOptions(array);
    };

    fetchPlans();
  }, []);

  return (
    <Grid>
      <Grid.Row columns={'equal'}>
        <Grid.Column>
          <FieldLabel>Login </FieldLabel>
          <Field
            value={props.values.insurerSpecificData?.alfa?.fireInsurance?.login}
            name="insurerSpecificData.alfa.fireInsurance.login"
            onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
              handleInputChange(data, props);
            }}
            type="text"
            component={Input}
            fluid
          />
        </Grid.Column>
        <Grid.Column>
          <FieldLabel>Senha </FieldLabel>
          <Field
            value={props.values.insurerSpecificData?.alfa?.fireInsurance?.password}
            name="insurerSpecificData.alfa.fireInsurance.password"
            component={Input}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            fluid
            type="text"
          />
        </Grid.Column>

        <Grid.Column>
          <FieldLabel>Cód. Imobiliária </FieldLabel>
          <Field
            value={props.values.insurerSpecificData?.alfa?.fireInsurance?.realEstateCode}
            name="insurerSpecificData.alfa.fireInsurance.realEstateCode"
            component={Input}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            fluid
            type="text"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={'equal'}>
        <Grid.Column>
          <FieldLabel>Comissão corretora (%) </FieldLabel>
          <Field
            value={props.values.insurerSpecificData?.alfa?.fireInsurance?.brokerCommission}
            name="insurerSpecificData.alfa.fireInsurance.brokerCommission"
            component={Input}
            onChange={(e: SyntheticEvent, data: any) => {
              setFieldValue(data.field.name, data.value);
            }}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value.replace(/,/g, '.').replace(/[^\d.]/g, '');

              if (Number(value) > 100) {
                setFieldValue(
                  'insurerSpecificData.alfa.fireInsurance.brokerCommission',
                  Number(100).toFixed(2)
                );
                return;
              }

              if (Number(value) < 0) {
                setFieldValue(
                  'insurerSpecificData.alfa.fireInsurance.brokerCommission',
                  0
                );
                return;
              }
              setFieldValue(
                'insurerSpecificData.alfa.fireInsurance.brokerCommission',
                Number(value).toFixed(2)
              );
            }}
            type="number"
            max={100.0}
            min={1.0}
            fluid
          />
        </Grid.Column>
        <Grid.Column>
          <FieldLabel>Pró-labore (%) </FieldLabel>
          <Field
            value={props.values.insurerSpecificData?.alfa?.fireInsurance?.proLabore}
            name="insurerSpecificData.alfa.fireInsurance.proLabore"
            component={Input}
            onChange={(e: SyntheticEvent, data: any) => {
              setFieldValue(data.field.name, data.value);
            }}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value.replace(/,/g, '.').replace(/[^\d.]/g, '');

              if (Number(value) > 100) {
                setFieldValue(
                  'insurerSpecificData.alfa.fireInsurance.proLabore',
                  Number(100).toFixed(2)
                );
                return;
              }

              if (Number(value) < 0) {
                setFieldValue('insurerSpecificData.alfa.fireInsurance.proLabore', 0);
                return;
              }
              setFieldValue(
                'insurerSpecificData.alfa.fireInsurance.proLabore',
                Number(value).toFixed(2)
              );
            }}
            type="number"
            max={100.0}
            min={1.0}
            fluid
          />
        </Grid.Column>

        <Grid.Column>
          <FieldLabel>Dia de fechamento do lote</FieldLabel>
          <Dropdown
            style={{
              width: '100%'
            }}
            value={props.values.insurerSpecificData?.alfa?.fireInsurance?.lotClosingDay}
            name={`insurerSpecificData.alfa.fireInsurance.lotClosingDay`}
            options={batchClosingDayOptions}
            placeholder={'Selecionar...'}
            search
            selection
            onChange={(_, { value }) => {
              setFieldValue(`insurerSpecificData.alfa.fireInsurance.lotClosingDay`, value);
            }}
            clearable
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row >
      <Grid.Column>
          <FieldLabel>Tipo de Repasse</FieldLabel>
          <Dropdown
            style={{
              width: '100%'
            }}
            value={props.values.insurerSpecificData?.alfa?.fireInsurance?.transferBy}
            name={`insurerSpecificData.alfa.fireInsurance.transferBy`}
            options={
              [{
                key: 'CORRETORA',
                text: 'CORRETORA',
                value: 'CORRETORA'
              },
              {
                key: 'SEGURADORA',
                text: 'SEGURADORA',
                value: 'SEGURADORA'
              }]
            }
            placeholder={'Selecionar...'}
            search
            selection
            onChange={(_, { value }) => {
              setFieldValue(`insurerSpecificData.alfa.fireInsurance.transferBy`, value);
            }}
            clearable
          />
        </Grid.Column>
         </Grid.Row>

      <Grid.Row columns={'equal'}>
        <Grid.Column>
          <FieldLabel>Planos </FieldLabel>
          <Dropdown
            style={{
              width: '100%'
            }}
            value={props.values.insurerSpecificData?.alfa?.fireInsurance?.plans}
            name={`insurerSpecificData.alfa.fireInsurance.plans`}
            options={plansOptions}
            placeholder={'Selecionar...'}
            search
            selection
            multiple
            onChange={
              (_, { value }) => {
                setFieldValue(`insurerSpecificData.alfa.fireInsurance.plans`, value);
              }
            }
            clearable
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={'equal'}>
        <Grid.Column>
          <FieldLabel> Parcelas </FieldLabel>
          <Dropdown
            style={{
              width: '100%'
            }}
            value={props.values.insurerSpecificData?.alfa?.fireInsurance?.installmentOptions}
            name={`insurerSpecificData.alfa.fireInsurance.installmentOptions`}
            options={installmentsOptions}
            placeholder={'Selecionar...'}
            search
            selection
            multiple
            onChange={
              (_, { value }) => {
                setFieldValue(`insurerSpecificData.alfa.fireInsurance.installmentOptions`, value);
              }
            }
            clearable
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={'equal'}>
        <Grid.Column>
          <FieldLabel>Agravo residencial (%) </FieldLabel>
          <Field
            value={props.values.insurerSpecificData?.alfa?.fireInsurance?.residentialSurcharge}
            name="insurerSpecificData.alfa.fireInsurance.residentialSurcharge"
            component={Input}
            onChange={(e: SyntheticEvent, data: any) => {
              setFieldValue(data.field.name, data.value);
            }}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value.replace(/,/g, '.').replace(/[^\d.]/g, '');

              if (Number(value) > 100) {
                setFieldValue(
                  'insurerSpecificData.alfa.fireInsurance.residentialSurcharge',
                  Number(100).toFixed(2)
                );
                return;
              }

              if (Number(value) < 0) {
                setFieldValue(
                  'insurerSpecificData.alfa.fireInsurance.residentialSurcharge',
                  0
                );
                return;
              }


              if(props.values.insurerSpecificData?.alfa?.fireInsurance?.residentialDiscount &&
                Number(props.values.insurerSpecificData?.alfa?.fireInsurance?.residentialDiscount) > (Number(value) / 2)
                ) {
                successNotification('Agravo residencial', `O Desconto residencial não pode ser maior que 50% do agravo residencial.`);
                setFieldValue(
                  'insurerSpecificData.alfa.fireInsurance.residentialSurcharge',
                  Number(value).toFixed(2)
                );

                setFieldValue(
                  'insurerSpecificData.alfa.fireInsurance.residentialDiscount',
                  (Number(value) / 2).toFixed(2)
                );
              }


              setFieldValue(
                'insurerSpecificData.alfa.fireInsurance.residentialSurcharge',
                Number(value).toFixed(2)
              );
            }}
            type="number"
            max={100.0}
            min={1.0}
            fluid
          />
        </Grid.Column>
        <Grid.Column>
          <FieldLabel>Agravo Comercial (%) </FieldLabel>
          <Field
            value={props.values.insurerSpecificData?.alfa?.fireInsurance?.commercialSurcharge}
            name="insurerSpecificData.alfa.fireInsurance.commercialSurcharge"
            component={Input}
            onChange={(e: SyntheticEvent, data: any) => {
              setFieldValue(data.field.name, data.value);
            }}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value.replace(/,/g, '.').replace(/[^\d.]/g, '');

              if (Number(value) > 100) {
                setFieldValue(
                  'insurerSpecificData.alfa.fireInsurance.commercialSurcharge',
                  Number(100).toFixed(2)
                );
                return;
              }

              if (Number(value) < 0) {
                setFieldValue(
                  'insurerSpecificData.alfa.fireInsurance.commercialSurcharge',
                  0
                );
                return;
              }

              if(props.values.insurerSpecificData?.alfa?.fireInsurance?.commercialDiscount &&
                Number(props.values.insurerSpecificData?.alfa?.fireInsurance?.commercialDiscount) > (Number(value) / 2)
                ) {
                successNotification('Agravo comercial', `O Desconto comercial não pode ser maior que 50% do agravo comercial.`);
                setFieldValue(
                  'insurerSpecificData.alfa.fireInsurance.commercialSurcharge',
                  Number(value).toFixed(2)
                );

                setFieldValue(
                  'insurerSpecificData.alfa.fireInsurance.commercialDiscount',
                  (Number(value) / 2).toFixed(2)
                );
              }

              setFieldValue(
                'insurerSpecificData.alfa.fireInsurance.commercialSurcharge',
                Number(value).toFixed(2)
              );
            }}
            type="number"
            max={100.0}
            min={1.0}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={'equal'}>
        <Grid.Column>
          <FieldLabel>Desconto residencial (%) </FieldLabel>
          <Field
            value={props.values.insurerSpecificData?.alfa?.fireInsurance?.residentialDiscount}
            disabled={props.values.insurerSpecificData?.alfa?.fireInsurance?.residentialSurcharge ? (
              unmaskMoney(props.values.insurerSpecificData?.alfa?.fireInsurance?.residentialSurcharge) > 0 ? false : true
            ) : true}
            name="insurerSpecificData.alfa.fireInsurance.residentialDiscount"
            component={Input}
            onChange={(e: SyntheticEvent, data: any) => {
              setFieldValue(data.field.name, data.value);
            }}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value.replace(/,/g, '.').replace(/[^\d.]/g, '');
              const residentialSurcharge = unmaskMoney(props.values.insurerSpecificData?.alfa?.fireInsurance?.residentialSurcharge);
              if (Number(value) > residentialSurcharge / 2) {
                successNotification('Desconto residencial', `O desconto residencial máximo permitido é de 50% do agravo residencial.`);
                setFieldValue(
                  'insurerSpecificData.alfa.fireInsurance.residentialDiscount',
                  (residentialSurcharge / 2).toFixed(2)
                );
                return;
              }

              if (Number(value) < 0) {
                setFieldValue(
                  'insurerSpecificData.alfa.fireInsurance.residentialDiscount',
                  0
                );
                return;
              }
              setFieldValue(
                'insurerSpecificData.alfa.fireInsurance.residentialDiscount',
                Number(value).toFixed(2)
              );
            }}
            type="number"
            max={100.0}
            min={1.0}
            fluid
          />
        </Grid.Column>
        <Grid.Column>
          <FieldLabel>Desconto comercial (%) </FieldLabel>
          <Field
            value={props.values.insurerSpecificData?.alfa?.fireInsurance?.commercialDiscount}
            name="insurerSpecificData.alfa.fireInsurance.commercialDiscount"
            disabled={props.values.insurerSpecificData?.alfa?.fireInsurance?.commercialSurcharge ? false : true}
            component={Input}
            onChange={(e: SyntheticEvent, data: any) => {
              setFieldValue(data.field.name, data.value);
            }}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value.replace(/,/g, '.').replace(/[^\d.]/g, '');
              const commercialSurcharge = unmaskMoney(props.values.insurerSpecificData?.alfa?.fireInsurance?.commercialSurcharge);

              if (Number(value) < 0) {
                setFieldValue(
                  'insurerSpecificData.alfa.fireInsurance.commercialDiscount',
                  0
                );
                return;
              }

              if (Number(value) > commercialSurcharge / 2) {
                successNotification('Desconto Comercial', `O desconto comercial máximo permitido é de 50% do agravo comercial.`);
                setFieldValue(
                  'insurerSpecificData.alfa.fireInsurance.commercialDiscount',
                  (commercialSurcharge / 2).toFixed(2)
                );
                return;
              }

              setFieldValue(
                'insurerSpecificData.alfa.fireInsurance.commercialDiscount',
                Number(value).toFixed(2)
              );
            }}
            type="number"
            max={100.0}
            min={1.0}
            fluid
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={'equal'}>
        <Grid.Column width={16}>
          <CoveragesConfigModal
            {...props}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
export default AlfaFireInsuranceConfig;
