import React, { useState } from 'react';
import {
  BondInsuranceAnalysisForGetCompleteBondInsuranceProposalByIdResponseDTO,
  BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO
} from "../../../../dtos/bond-insurance-proposal-list/get-complete-bond-insurance-proposal-by-id-response.dto";
import PrintableModal from "../../../../components/PrintableModal";
import { BondInsuranceHiredDataForGetCompleteBondInsurancePolicyByIdResponseDTO } from "../../../../dtos/bond-insurance-policy-list/get-complete-bond-insurance-policy-by-id-response.dto";
import { Button, Grid, Popup } from "semantic-ui-react";
import { BondInsuranceProposalMainInfoDetails } from "../../../bond-insurance-proposal-list/components/proposal-details/components/BondInsuranceProposalMainInfoDetails";
import SectionLabel from "../../../../components/SectionLabel";
import { BondInsuranceMainInfoDetails } from "../../../bond-insurance-proposal-list/components/proposal-details/components/BondInsuranceMainInfoDetails";
import { BondInsurancePropertyDetails } from "../../../bond-insurance-proposal-list/components/proposal-details/components/BondInsurancePropertyDetails";
import { PersonTypesEnum } from "../../../../enums/person-types.enum";
import { BondInsuranceNaturalTenantDetails } from "../../../bond-insurance-proposal-list/components/proposal-details/components/BondInsuranceNaturalTenantDetails";
import { BondInsuranceLegalTenantDetails } from "../../../bond-insurance-proposal-list/components/proposal-details/components/BondInsuranceLegalTenantDetails";
import { ComboTooFireInsurancePoliciesListResponseDTO } from '../../../../dtos/bond-insurance-policy-list/interfaces/policy-for-get-bond-insurance-policies-list-response-dto';
import { ComboFireInsuranceDetails } from '../../../bond-insurance-proposal-list/components/proposal-details/components/ComboFireInsuranceDetails';
import { BondInsuranceOwnerDetails } from '../../../bond-insurance-proposal-list/components/proposal-details/components/BondInsuranceOwnerDetails';
import { BondInsurancePolicyMainInfoDetails } from './components/BondInsurancePolicyMainInfoDetails';
import { downloadLetter, sendAnyLetter } from '../../../../services/bond-insurance';
import { dangerNotification, successNotification } from '../../../../services/notification';
import { UserRolesEnum } from '../../../../enums/user-roles.enum';
import LogModal from '../../../../components/LogModal/LogModal';
import { get } from '../../../../services/storage';
import AuthorizationContainer from '../../../../components/AuthorizationContainer';
import { ActionEnum } from '../../../../enums/authz-action.enum';
import { FeatureEnum } from '../../../../enums/authz-feature.enum';
import { BondInsuranceHiredDataStatusEnum } from '../../../../enums/bond-insurance-hired-data-status.enum';

export type Props = {
  combo?: ComboTooFireInsurancePoliciesListResponseDTO | null;
  bondInsurance: BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO | null;
  bondInsuranceAnalysis: BondInsuranceAnalysisForGetCompleteBondInsuranceProposalByIdResponseDTO | null;
  bondInsuranceHiredData: BondInsuranceHiredDataForGetCompleteBondInsurancePolicyByIdResponseDTO | null;
  isOpen: boolean;
  onClose: React.ReactEventHandler<{}>;
  bondInsuranceId?: string;
  callback: Function;
  getPrevious: Function;
};

export const BondInsurancePolicyDetails = (props: Props) => {
  const { bondInsurance, bondInsuranceAnalysis, bondInsuranceHiredData, isOpen, onClose, combo, bondInsuranceId, callback, getPrevious } = props;
  const[loadingMail, setLoadingMail] = useState(false)
  const [loadingDownloadFile, setLoadingDownloadFile] = useState(false);
  const [openLogModal, setOpenLogModal] = useState(false);

  const close = (): void => {
    setOpenLogModal(false);
  };

  const userRole = get('role');

  return (
    <>
      {bondInsurance && bondInsuranceAnalysis && bondInsuranceHiredData &&
        <PrintableModal
          isOpen={isOpen}
          bondInsuranceId={bondInsuranceId}
          onClose={onClose}
          header={
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}>
              Apólice de Seguro Fiança
              <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}>
                {userRole === UserRolesEnum.brokerAdmin && (
                  <LogModal
                    setOpen={setOpenLogModal}
                    callback={callback}
                    closeModal={close}
                    analysisId={bondInsurance.id}
                    proposalId={bondInsuranceAnalysis.id}
                    policyId={bondInsuranceHiredData.id}
                    createdAt={bondInsurance.createdAt}
                  />
                )}
                <AuthorizationContainer action={ActionEnum.send} feature={FeatureEnum.letters}>
                  <Button
                    floated='left'
                    color="blue"
                    loading={loadingMail}
                    disabled={loadingMail}
                    onClick={async () => {
                      setLoadingMail(true);
                      try {
                        sendAnyLetter(bondInsuranceAnalysis.id);
                        successNotification("Sucesso", "Carta enviada!");
                      } catch (e) {
                        dangerNotification(
                          "Oops...",
                          "Ocorreu um erro ao enviar a carta"
                        );
                      }
                      setLoadingMail(false);
                    }}
                  >
                    Reenviar carta de aprovação
                  </Button>
                </AuthorizationContainer>
                <AuthorizationContainer action={ActionEnum.download} feature={FeatureEnum.letters}>
                  <Popup
                    content={'Clique para realizar o Download da carta de Aprovação e sua Cláusula!'}
                    position={'top left'}
                    trigger={
                      <Button
                        icon="download"
                        color="blue"
                        loading={loadingDownloadFile}
                        onClick={async () => {
                          try {
                            setLoadingDownloadFile(true);
                            await downloadLetter(
                              bondInsuranceAnalysis.id,
                              bondInsurance.naturalTenant
                                ? bondInsurance.naturalTenant.main_tenant_name
                                : bondInsurance.legalTenant.company
                            );
                            successNotification(
                              'Sucesso',
                              'Download realizado com sucesso!'
                            );
                          } catch (e) {
                            dangerNotification(
                              'Oops...',
                              'Ocorreu um erro ao realizar o download carta'
                            );
                          }
                          finally{
                            setLoadingDownloadFile(false);
                          }
                        }}
                      ></Button>
                    }
                  />
                </AuthorizationContainer>

              </div>

            </div>}
          content={
            bondInsurance && bondInsuranceAnalysis && bondInsuranceHiredData ?
              <Grid>
                {bondInsuranceHiredData.code === "QUIVER" &&
                <div style={{display: 'flex', alignItems: "center", justifyContent: "center", width: "100%"}}>
                  <br/>
                  <br/>
                   <p> Atenção: Apólice importada automaticamente de sistema externo.</p>

                  </div>}
                <BondInsurancePolicyMainInfoDetails bondInsuranceHiredData={bondInsuranceHiredData} bondInsuranceAnalysis={bondInsuranceAnalysis} getPreviousHired={getPrevious}  />
                <BondInsuranceProposalMainInfoDetails bondInsuranceAnalysis={bondInsuranceAnalysis} hiredData={bondInsuranceHiredData} />
                <SectionLabel text='DADOS DA ANÁLISE' />
                <BondInsuranceMainInfoDetails bondInsurance={bondInsurance} bondInsuranceAnalysis={bondInsuranceAnalysis}/>
                <SectionLabel text='DADOS DO PROPRIETÁRIO' />
                <BondInsuranceOwnerDetails bondInsurance={bondInsurance} />
                <BondInsurancePropertyDetails bondInsurance={bondInsurance} />
                <SectionLabel text='DADOS DO LOCATÁRIO' />
                {bondInsurance.tenantType === PersonTypesEnum.natural && (
                  <BondInsuranceNaturalTenantDetails bondInsurance={bondInsurance} />
                )}
                {bondInsurance.tenantType === PersonTypesEnum.legal && (
                  <BondInsuranceLegalTenantDetails bondInsurance={bondInsurance} />
                )}
                {combo !== null && <ComboFireInsuranceDetails combo={combo!} />}
              </Grid>
              :
              <></>
          }
          disabled={
            userRole?.includes('ESTATE') &&
            bondInsuranceHiredData.status !== BondInsuranceHiredDataStatusEnum.underRenewal
          }
          callback={(): Promise<void | BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO> => {
            return {} as Promise<BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO>;
          }
          } />}
    </>
  );
}
