import React, {SyntheticEvent, useCallback, useEffect, useState} from 'react';
import { FireInsuranceBudgetFormSectionProps } from '../../FireInsuranceBudgetForm';
import Input from '../../../../components/Input';
import SectionLabel from '../../../../components/SectionLabel';
import { Grid, InputOnChangeData } from 'semantic-ui-react';
import {FastField, Field} from 'formik';
import {maskCpfOrCnpj, maskPhoneNumber, unmaskPhoneNumber} from '../../../../services/masks';
import { FormikErrorMessage } from '../../../../components/ErrorMessage';
import { FieldLabel } from '../../../../styles';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import AppMaskedInput from '../../../../components/MaskedInput';
import {Code} from "../../../../types/InsurerState";
import {iRootDispatch, iRootState} from "../../../../store";
import {TenantState} from "../../../../types/FireInsurance";
import {reach, Schema} from "yup";
import {getFireInsuranceTenantSectionYupSchema} from "../../validators/tenant-input-section";
import {connect} from "react-redux";
import {debounce} from "lodash";
import {EMPTY_DATE} from "../../../../util";

const mapDispatchToProps = (dispatch: iRootDispatch) => ({
  updateInputData: (payload: Partial<TenantState>) => dispatch.fireInsuranceBudget.updateTenantInputData(payload),
  resetInputData: () => dispatch.fireInsuranceBudget.resetTenantFormSection()
});

const mapStateToProps = (state: iRootState) => ({
  selectedInsurerData: state.fireInsuranceBudget.selected.insurer,
  setFormikFieldTouched: (fieldName: string, touched: boolean) => state.formik.actions.setFieldTouched &&
    state.formik.actions?.setFieldTouched(fieldName, touched),
  setFormikFieldValue: (fieldName: string, value: any) => state.formik.actions.setFieldValue &&
    state.formik.actions?.setFieldValue(fieldName, value),
  formikHandleBlur: (e: any) => state.formik.actions.handleBlur &&
    state.formik.actions?.handleBlur(e) ,
  toggleToResetForm: state.fireInsuranceBudget.toggleToResetFormSections.tenantInputSection
});

export type TenantDataProps = {

} & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & FireInsuranceBudgetFormSectionProps

const TenantData = (props: TenantDataProps) => {
  const [birthDate, setBirthDate] = useState<Date | string |undefined>(EMPTY_DATE);
  const debouncedUpdate = useCallback(debounce((payload) => props.updateInputData(payload), 250),[]);
  const {
    sectionKey,
    selectedInsurerData,
    toggleToResetForm,
    updateInputData,
    resetInputData,
    setFormikFieldValue,
    setFormikFieldTouched,
    formikHandleBlur
  } = props;

  const [validationYupSchema, setValidationYupSchema] = useState<Schema<any> | null>(null);

  const handleChange = (_: any, data: InputOnChangeData) => {
    const updatePayload: any = {};
    const formikFieldName = data.name;
    const sanitizedName = formikFieldName.replace(`${sectionKey}.`,'');

    updatePayload[sanitizedName] = data.value;
    setFormikFieldValue(formikFieldName, data.value)
    setFormikFieldTouched(formikFieldName, true);

    debouncedUpdate(updatePayload);
  };

  const handleBlur = (e: any) => {
    e.persist();

    const updatePayload: any = {};
    const formikFieldName = e.target.name;
    const sanitizedName = formikFieldName.replace(`${sectionKey}.`,'');

    updatePayload[sanitizedName] = e.target.value;

    updateInputData(updatePayload);
  };

  const handleDateChange = (fieldName: string, newDate: Date | undefined) => {
    const updatePayload: any = {};
    const formikFieldName = `${sectionKey}.${fieldName}`;

    updatePayload[fieldName] = newDate;

    setFormikFieldTouched(formikFieldName, true);

    updateInputData(updatePayload);
  };

  const validate = async (fieldName: string, value: any) => {
    if (!validationYupSchema) {
      return undefined;
    }

    let error;
    let fieldSchema: Schema<any>;

    if (fieldName === 'birthDate') {
      value = birthDate;
    }

    try {
      fieldSchema = reach(validationYupSchema, fieldName);
    } catch (e) {
      return undefined;
    }

    try {
      const res = await fieldSchema.validate(value);
    } catch (e) {
      error = (e as any).message;
    } finally {
      return error;
    }
  }

  const clearFormValues = () => {
    setFormikFieldValue(`${sectionKey}.document`, '');
    setFormikFieldValue(`${sectionKey}.name`, '');
    setFormikFieldValue(`${sectionKey}.email`, '');

    setBirthDate(undefined);
    setFormikFieldValue(`${sectionKey}.telephone`, '');

    setFormikFieldTouched(`${sectionKey}.document`, false);
    setFormikFieldTouched(`${sectionKey}.name`, false);
    setFormikFieldTouched(`${sectionKey}.email`, false);
    setFormikFieldTouched(`${sectionKey}.birthDate`, false);
    setFormikFieldTouched(`${sectionKey}.telephone`, false);

    resetInputData();
  }

  const onChangeDocument = (e: SyntheticEvent, data: any) => {
    const dataCopy = {...data};
    dataCopy.value = maskCpfOrCnpj(data.value);

    handleChange(e, dataCopy);
  }

  const onBlurDocument = (e: any) => {
    e.persist();

    const eventCopy = {...e};
    eventCopy.target.value = maskCpfOrCnpj(e.target.value);

    handleBlur(e);
  }

  useEffect(() => {
    clearFormValues();

    setValidationYupSchema(getFireInsuranceTenantSectionYupSchema(selectedInsurerData.isConnectorEnabled, selectedInsurerData.code))
  }, [selectedInsurerData.code]);

  useEffect(() => {
    clearFormValues();
  }, [toggleToResetForm]);


  return (
    <>
      <SectionLabel text='DADOS DO INQUILINO' />
      <Grid.Row>
        <Grid.Column width={4}>
          <FieldLabel>CPF/CNPJ<span style={{color: 'red'}}>*</span></FieldLabel>
          <FastField name="budget.tenant.document"
             validate={(value: any) => validate('document', value)}
             onChange={onChangeDocument}
             onBlur={onBlurDocument}
             component={Input}
             fluid />
          <FormikErrorMessage component="div" name="budget.tenant.document" />
        </Grid.Column>
        <Grid.Column width={6}>
          <FieldLabel>NOME/RAZÃO SOCIAL<span style={{color: 'red'}}>*</span></FieldLabel>
          <FastField name="budget.tenant.name"
            validate={(value: any) => validate('name', value)}
            onChange={handleChange}
            onBlur={handleBlur}
            component={Input}
            fluid />
          <FormikErrorMessage component="div" name="budget.tenant.name" />
        </Grid.Column>
        <Grid.Column width={6}>
          <FieldLabel>E-MAIL<span style={{color: 'red'}}>*</span></FieldLabel>
          <FastField name="budget.tenant.email"
            validate={(value: any) => validate('email', value)}
            onChange={handleChange}
            onBlur={handleBlur}
            component={Input}
            fluid />
          <FormikErrorMessage component="div" name="budget.tenant.email" />
        </Grid.Column>
        </Grid.Row>

        {(selectedInsurerData.code === Code.TokioMarine ||
          selectedInsurerData.isConnectorEnabled) &&
        (<Grid.Row>
            {selectedInsurerData.code === Code.TokioMarine && (
              <Grid.Column width={4}>
                <FieldLabel>DATA DE NASCIMENTO<span style={{color: 'red'}}>*</span></FieldLabel>
                <Field
                       selected={birthDate}
                       name='budget.tenant.birthDate'
                       component={CustomDatePicker}
                       customInput={<AppMaskedInput
                         mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                       />}
                       dateFormat={'dd/MM/yyyy'}
                       onChange={(date: Date, e: SyntheticEvent) => {
                         let newDate: Date | undefined = date;

                         if (date === null) {
                           newDate = undefined;
                         }

                         setBirthDate(newDate);
                         handleDateChange('birthDate', newDate);
                       }}
                       validate={(value: any) => validate('birthDate', value)}
                       onBlur={() => setFormikFieldValue(`${sectionKey}.birthDate`, true)}
                />
                <FormikErrorMessage component="div" name={"budget.tenant.birthDate"} />
              </Grid.Column>
            )}

            <Grid.Column width={3}>
              <FieldLabel>TELEFONE<span style={{color: 'red'}}>*</span></FieldLabel>
              <FastField
                name={"budget.tenant.telephone"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
                  const dataCopy = {...data}
                  dataCopy.value = maskPhoneNumber(unmaskPhoneNumber(dataCopy.value));

                  handleChange(e, dataCopy);
                }}
                onBlur={(e: any) => {
                  e.persist();

                  setFormikFieldTouched("budget.tenant.telephone", true);

                  const eventCopy = {...e}
                  eventCopy.target.value = maskPhoneNumber(unmaskPhoneNumber(e.target.value));

                  handleBlur(e);
                }}
                validate={(value: any) => validate('telephone', value)}
                component={Input}
                fluid />
                <FormikErrorMessage component="div" name={"budget.tenant.telephone"} />
            </Grid.Column>
        </Grid.Row>
      )}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TenantData)

