import React from 'react';
import { Grid } from 'semantic-ui-react';
import { BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO } from "../../../../../dtos/bond-insurance-proposal-list/get-complete-bond-insurance-proposal-by-id-response.dto";
import { Info } from "../../../../../components/Info";

export type Props = {
  bondInsurance: BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO;
};

export const BondInsuranceOwnerDetails = (props: Props) => {
  const { bondInsurance } = props;

  return (
    <>
      <Grid.Row>
        <Info name={'NOME'} value={bondInsurance.ownerData?.name || "Indisponível"} width={8} />
        <Info name={'CPF/CNPJ'} value={bondInsurance.ownerData ? (bondInsurance.ownerData.cpf ? bondInsurance.ownerData.cpf : (bondInsurance.ownerData.cnpj)) : "Indisponível"} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'CEP'} value={bondInsurance.ownerData?.address?.zip_code || "Indisponível"} width={8} />

      </Grid.Row>
      <Grid.Row>
        <Info name={'RUA'} value={bondInsurance.ownerData?.address?.street || "Indisponível"} width={6} />
        <Info name={'N°'} value={bondInsurance.ownerData?.address?.number || "Indisponível"} width={2} />
        <Info name={'BAIRRO'} value={bondInsurance.ownerData?.address?.district || "Indisponível"} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'CIDADE'} value={bondInsurance.ownerData?.address?.city || "Indisponível"} width={8} />
        <Info name={'UF'} value={bondInsurance.ownerData?.address?.state || "Indisponível"} width={8} />
      </Grid.Row>
      {bondInsurance.ownerData?.address?.complement &&
        <Grid.Row>
          <Info name={'Complemento'} value={bondInsurance.ownerData?.address?.complement || "Indisponível"} width={8} />
        </Grid.Row>
      }
    </>
  )
}
