import React, { useState, useEffect, ReactEventHandler, SyntheticEvent } from 'react';
import { Field } from 'formik';
import { connect } from 'react-redux';
import { Grid, Dropdown as DropdownNativo, Checkbox, Dropdown } from 'semantic-ui-react';
import { PoliciesFilters } from '../types';
import Button from '../../../components/Button';
import { iRootDispatch, iRootState } from '../../../store';
import AppMaskedInput from '../../../components/MaskedInput';
import CustomDatePicker from '../../../components/CustomDatePicker';
import Input from '../../../components/Input';
import { FieldLabel } from '../../../styles';
import BondInsurancePoliciesTable from './BondInsurancePoliciesTable';
import { AvailableBrokersAndEstatesState } from '../../../store/types/temp-types';
import { CustoDropDownItemProps } from '../../../components/types';
import { dangerNotification, warningNotification } from '../../../services/notification';
import { BondInsurancePoliciesListProps } from '../BondInsurancePolicyForm';
import { GetBondInsurancePoliciesListResponseDTO } from '../../../dtos/bond-insurance-policy-list/get-bond-insurance-policies-list-response-dto';
import {
  getBondInsurancePoliciesList,
  sortBondInsurancePolicies
} from '../../../services/bond-insurance-policy';
import { PolicyForGetBondInsurancePoliciesListResponseDTO } from '../../../dtos/bond-insurance-policy-list/interfaces/policy-for-get-bond-insurance-policies-list-response-dto';
import { BondInsuranceHiredDataStatusEnum } from '../../../enums/bond-insurance-hired-data-status.enum';
import { getUsers } from '../../../services/broker';
import { UserForUpdateEstateForm } from '../../estates-list/types';
import { UsersToCustomDropDownOptions } from '../../../util';
import { typeOptions } from '../../find-record/types/options-find.type';
import { ChecklistContainer, ButtonsContainer } from '../styles';
import { maskCpfOrCnpj } from '../../../services/masks';
import { useLocation } from 'react-router-dom';
import { InsurerState } from '../../../types/InsurerState';
import CreatePolicyModal from './CreatePolicyModal';
import AuthorizationContainer from '../../../components/AuthorizationContainer';
import { ActionEnum } from '../../../enums/authz-action.enum';
import { FeatureEnum } from '../../../enums/authz-feature.enum';

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

interface LocationState {
  bondInsuranceId?: string;
  expireInDays?: string;
}

enum MoreFiltersEnum {
  unread = 'onlyUnread',
  noPolicy = 'noPolicyAttached',
  warning = 'hasWarning',
  quiver = 'quiver',
  manual = 'manual',
  issuedNew = 'issuedNew'
}

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

const status = [
  {
    index: 0,
    value: 'ALL',
    text: 'Todos'
  },
  {
    index: 7,
    value: BondInsuranceHiredDataStatusEnum.insurerPolicyPending,
    text: 'Emissão de Apólice Pendente pela Seguradora'
  },
  {
    index: 9,
    value: BondInsuranceHiredDataStatusEnum.underRenewal,
    text: 'Em renovação'
  },
  {
    index: 10,
    value: BondInsuranceHiredDataStatusEnum.renewedCreated,
    text: 'Renovada'
  },
  {
    index: 11,
    value: BondInsuranceHiredDataStatusEnum.notRenewed,
    text: 'Não Renovada'
  },
  {
    index: 12,
    value: BondInsuranceHiredDataStatusEnum.insurerPolicyCreated,
    text: 'Emitida'
  },
  {
    index: 13,
    value: BondInsuranceHiredDataStatusEnum.renewCanceled,
    text: 'Renovação Cancelada'
  },
  {
    index: 14,
    value: BondInsuranceHiredDataStatusEnum.overdue,
    text: 'Vencida'
  },
  {
    index: 15,
    value: BondInsuranceHiredDataStatusEnum.overdueIssued,
    text: 'Vencida Emitida'
  },
  {
    index: 16,
    value: BondInsuranceHiredDataStatusEnum.expired,
    text: 'Expirada'
  }
];

const expireInDaysDropdownOptions = [
  {
    index: 1,
    value: '',
    text: 'Mostrar todas'
  },
  {
    index: 2,
    value: '7',
    text: '7 dias'
  },
  {
    index: 3,
    value: '30',
    text: '30 dias'
  }
];

const moreFiltersOptions = [
  {
    index: 1,
    value: MoreFiltersEnum.unread,
    text: 'Follow-up não lido'
  },
  {
    index: 2,
    value: MoreFiltersEnum.noPolicy,
    text: 'Sem apólice anexada'
  },
  {
    index: 3,
    value: MoreFiltersEnum.warning,
    text: 'Apólice Inconsistente'
  },
  {
    index: 4,
    value: MoreFiltersEnum.quiver,
    text: 'Importado do Quiver'
  },
  {
    index: 5,
    value: MoreFiltersEnum.manual,
    text: 'Inserido Manualmente'
  },
  {
    index: 6,
    value: MoreFiltersEnum.issuedNew,
    text: 'Emitida Nova'
  }

];

const w100 = {
  width: '100%'
};

const BondInsurancePoliciesPage = (props: BondInsurancePoliciesListProps) => {
  const { id } = props.match.params;
  const location = useLocation();
  const { availableBrokersAndEstates, user, values } = props;
  const insurers: InsurerState[] | null =
    props.availableBrokersAndEstates.bondInsurers.map((insurer: any) => insurer.value);
  const [bondInsurancePolicies, setBondInsurancePolicies] = useState<
    PolicyForGetBondInsurancePoliciesListResponseDTO[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ascendingOrder] = useState<boolean>(true);
  const [filters, setFilters] = useState<PoliciesFilters>({
    document: '',
    name: '',
    address: '',
    start: '',
    end: '',
    brokerId: '',
    estateId: ''
  });
  const [noPolicyAttached, setNoPolicyAttached] = useState<boolean>(false);
  const [hasWarning, setHasWarning] = useState<boolean>(false);
  const [onlyUnread, setOnlyUnread] = useState<boolean>(false);
  const [onlyQuiver, setOnlyQuiver] = useState<boolean>(false);
  const [onlyManual, setOnlyManual] = useState<boolean>(false);
  const [onlyNew, setOnlyNew] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [startFilter, setStartFilter] = useState<Date | null>();
  const [endFilter, setEndFilter] = useState<Date | null>();
  const [dataCount, setDataCount] = useState<number>(0);
  const [selectedEstate, setSelectedEstate] = useState<any>();
  const [selectedInsurer, setSelectedInsurer] = useState<string>('');
  const [cleanInputs, setCleanInputs] = useState(false);
  const statusList = status;
  const [selectedStatus, setSelectedStatus] = useState<string>('Todos');
  //const bondInsuranceId = (location.state as LocationState)?.bondInsuranceId;
  const bondInsuranceIdInitialValue = (location.state as LocationState)?.bondInsuranceId;
  const [bondInsuranceId, setBondInsuranceId] = useState(bondInsuranceIdInitialValue);
  const [selectedAccountManager, setSelectedAccountManager] = useState<any>();
  const [selectedRenewResponsible, setSelectedRenewResponsible] = useState<any>();
  const [filterSelected, setFilterSelected] = useState<any>();
  const [isAccountManagerLoading, setIsAccountManagerLoading] = useState<boolean>(false);
  const [accountManagerOptions, setAccountManagerOptions] = useState<
    CustoDropDownItemProps[]
  >([]);
  const [type, setType] = useState<any>('document');
  const [argument, setArgument] = useState<string>('');
  const [expireInDays, setExpireInDays] = useState<string>(
    (location.state as LocationState)?.expireInDays || ''
  );
  const [isSearchEnabled, setSearchEnabled] = useState(true);
  const insurersList = insurers.map(insurer => ({
    key: insurer.id,
    value: insurer.id,
    text: insurer.name
  }));

  const getAccountManagers = async () => {
    if (props.user.broker?.id) {
      setIsAccountManagerLoading(true);
      getUsers(props.user.broker.id, [{ isDropdown: true}])
        .then(result => {
          const convertedValues: UserForUpdateEstateForm[] = result.map((user: any) => {
            const userForForm: UserForUpdateEstateForm = {
              id: user.id,
              name: user.name
            };

            return userForForm;
          });

          const analystsOptions = UsersToCustomDropDownOptions(false, convertedValues);
          setAccountManagerOptions(analystsOptions);
        })
        .catch(() => {
          dangerNotification(
            'Oops...',
            'Não foi possível encontrar os analistas disponíveis'
          );
        })
        .finally(() => {
          setIsAccountManagerLoading(false);
        });
    }
  };

  useEffect(() => {
    if (accountManagerOptions.length === 0) {
      getAccountManagers();
    }
  }, [accountManagerOptions]);

  useEffect(() => {
    const newStart = startFilter ? startFilter.toISOString().substring(0, 10) : '';
    setFilters({ ...filters, start: newStart });
  }, [startFilter, endFilter]);

  useEffect(() => {
    const newEnd = endFilter ? endFilter.toISOString().substring(0, 10) : '';
    setFilters({ ...filters, end: newEnd });
  }, [endFilter]);

  useEffect(() => {
    setFilters({ ...filters, insurer: selectedInsurer });
  }, [selectedInsurer]);

  useEffect(() => {
    const id = selectedEstate && selectedEstate !== '' ? selectedEstate.id : '';
    setFilters({ ...filters, estateId: id });
  }, [selectedEstate]);

  async function loadTotal(filter: PoliciesFilters) {
    if(currentPage === 1)  {
      const data: GetBondInsurancePoliciesListResponseDTO = await getBondInsurancePoliciesList(currentPage, {...filter, action: "COUNT"});
      setDataCount(data.count)
    }
  }


  const getData = async () => {
    // const error = false;
    setIsLoading(true);
    // if (!type && argument) {
    //   warningNotification('Ops...', 'O tipo da pequisa deve ser preenchido');
    //   error = true;
    // }
    // if (type && !argument) {
    //   warningNotification('Ops...', 'O termo da pequisa deve ser preenchido');
    //   error = true;
    // }

    // if (error) {
    //   setIsLoading(false);
    //   return;
    // }

    try {
      const filter: PoliciesFilters = filters;
      if (selectedStatus !== 'Todos') {
        filter.status = selectedStatus;
      }

      if (expireInDays) {
        filter.expireInDays = expireInDays;
        filter.status = BondInsuranceHiredDataStatusEnum.insurerPolicyCreated;
      }
      if (bondInsuranceId) {
        filter.bondInsuranceId = bondInsuranceId;
      }
      filter.noPolicyAttached = noPolicyAttached;
      filter.hasWarning = hasWarning;
      filter.onlyUnread = onlyUnread;
      filter.onlyQuiver = onlyQuiver;
      filter.onlyManual = onlyManual;
      filter.onlyNew = onlyNew;
      if (availableBrokersAndEstates.estates.length === 1) {
        filter.estateId = availableBrokersAndEstates.estates[0].value.id;
      }
      if (!id) {
        const data: GetBondInsurancePoliciesListResponseDTO =
          await getBondInsurancePoliciesList(currentPage, filter);

        const orderedPolicies: PolicyForGetBondInsurancePoliciesListResponseDTO[] =
          data.policies.length > 1
            ? sortBondInsurancePolicies(data.policies, ascendingOrder)
            : data.policies;

        loadTotal(filter)
        setBondInsurancePolicies(orderedPolicies);
      }
      setBondInsuranceId(undefined);
      setIsLoading(false);
    } catch (e) {
      dangerNotification('Oops...', 'Não foi possível buscar as apólices');
    } finally {
      setIsLoading(false);
      setCleanInputs(false);
    }
  };

  useEffect(() => {
    window.history.replaceState(
      {
        ...(location.state as any),
        bondInsuranceId: undefined
      },
      ''
    );
  }, [location]);

  useEffect(() => {
    getData();
  }, [currentPage]);

  const clearInputs = () => {
    setFilters({
      document: '',
      name: '',
      address: '',
      start: '',
      end: '',
      brokerId: '',
      estateId: '',
      status: '',
      accountManager: '',
      bondInsuranceId: undefined,
      insurerPolicyId: '',
    });
    setSelectedAccountManager(null);
    setStartFilter(null);
    setEndFilter(null);
    setSelectedEstate(null);
    setSelectedInsurer('');
    setSelectedStatus('');
    setCleanInputs(true);
    setNoPolicyAttached(false);
    setHasWarning(false);
    setOnlyUnread(false);
    setOnlyQuiver(false);
    setOnlyManual(false);
    setOnlyNew(false);
    setBondInsuranceId(undefined);
    setFilterSelected('');
    setType(null);
    setArgument('');
    setExpireInDays('');
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case 'argument': {
        if (type === 'document') {
          const numericValue = value?.replace(/\D/g, '');
          const maskedValue = maskCpfOrCnpj(numericValue);
          setArgument(maskedValue);
          const isValidDocumentSearch =
            numericValue.length === 11 || numericValue.length === 14;
          setSearchEnabled(isValidDocumentSearch);
          break;
        }
        setSearchEnabled(true);
        setArgument(value);
        break;
      }


      case 'start':
        setFilters({ ...filters, start: value });
        break;
      case 'end':
        setFilters({ ...filters, end: value });
        break;
    }
  };

  useEffect(() => {
    if (type === 'document') {
      setFilters({ ...filters, document: argument });
    } else if (type === 'address') {
      setFilters({ ...filters, address: argument });
    } else if (type === 'name') {
      setFilters({ ...filters, name: argument });
    } else if (type === 'insurerPolicyId') {
      setFilters({ ...filters, insurerPolicyId: argument });
    } else if (type === 'policyId') {
      setFilters({ ...filters, policyId: argument });
    }
  }, [argument]);

  useEffect(() => {
    setFilters({
      name: '',
      document: '',
      address: ''
    });
  }, [type]);

  useEffect(() => {
    setFilters({ ...filters, expireInDays });
    if (expireInDays) {
      setSelectedStatus(BondInsuranceHiredDataStatusEnum.insurerPolicyCreated);
    }
  }, [expireInDays]);

  useEffect(() => {
    cleanInputs && getData();
  }, [cleanInputs]);

  async function updateList() {
    setIsLoading(true);
    try {
      const filter: PoliciesFilters = filters;
      const data: GetBondInsurancePoliciesListResponseDTO =
        await getBondInsurancePoliciesList(currentPage, filter);

      const orderedPolicies: PolicyForGetBondInsurancePoliciesListResponseDTO[] =
        data.policies.length > 1
          ? sortBondInsurancePolicies(data.policies, ascendingOrder)
          : data.policies;
      setDataCount(data.count);
      setBondInsurancePolicies(orderedPolicies);
      setIsLoading(false);
    } catch (e) {
      dangerNotification('Oops...', 'Não foi possível buscar as apólices');
    } finally {
      setIsLoading(false);
    }
  }

  const handleMoreFilters = (value: string) => {
    switch (value) {
      case MoreFiltersEnum.unread:
        setOnlyUnread(true);
        setOnlyQuiver(false);
        setOnlyManual(false);
        setNoPolicyAttached(false);
        setHasWarning(false);
        setOnlyNew(false);
        break;
      case MoreFiltersEnum.noPolicy:
        setNoPolicyAttached(true);
        setHasWarning(false);
        setOnlyUnread(false);
        setOnlyQuiver(false);
        setOnlyManual(false);
        setOnlyNew(false);
        break;
      case MoreFiltersEnum.warning:
        setHasWarning(true);
        setOnlyUnread(false);
        setNoPolicyAttached(false);
        setOnlyQuiver(false);
        setOnlyManual(false);
        setOnlyNew(false);
        break;
      case MoreFiltersEnum.quiver:
        setOnlyQuiver(true);
        setHasWarning(false);
        setOnlyUnread(false);
        setNoPolicyAttached(false);
        setOnlyManual(false);
        setOnlyNew(false);
        break;
      case MoreFiltersEnum.manual:
        setOnlyManual(true);
        setHasWarning(false);
        setOnlyUnread(false);
        setNoPolicyAttached(false);
        setOnlyQuiver(false);
        setOnlyNew(false);
        break;
      case MoreFiltersEnum.issuedNew:
        setOnlyManual(false);
        setHasWarning(false);
        setOnlyUnread(false);
        setNoPolicyAttached(false);
        setOnlyQuiver(false);
        setOnlyNew(true);
        break;
      default:
        setNoPolicyAttached(false);
        setHasWarning(false);
        setOnlyUnread(false);
        setOnlyQuiver(false);
        setOnlyManual(false);
        setOnlyNew(false);
      }
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setCurrentPage(1);
    getData();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={3}>
              <FieldLabel>Pesquisar por</FieldLabel>
              <div style={w100}>
                <Dropdown
                  style={w100}
                  value={type}
                  name="type"
                  options={typeOptions}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  disabled={isLoading}
                  onChange={(e, { value }) => setType(value)}
                  clearable
                />
              </div>
            </Grid.Column>
            <Grid.Column width={4}>
              <FieldLabel>Termo de pesquisa:</FieldLabel>
              <Input
                name="argument"
                onChange={handleInputChange}
                disabled={isLoading}
                value={argument}
                fluid
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <FieldLabel>Seguradora:</FieldLabel>
              <div style={{ width: '100%' }}>
                <DropdownNativo
                  style={{ width: '100%' }}
                  value={selectedInsurer}
                  name={`estate`}
                  defaultValue={
                    insurersList.length === 1 ? insurersList[0].value : '' || ''
                  }
                  disabled={isLoading || insurersList.length === 1}
                  options={insurersList || []}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  onChange={(e, { value }) => setSelectedInsurer(String(value))}
                  clearable
                />
              </div>
            </Grid.Column>
            <Grid.Column width={3}>
              <FieldLabel>Imobiliária:</FieldLabel>
              <div style={w100}>
                <DropdownNativo
                  style={w100}
                  value={selectedEstate}
                  name={`estate`}
                  defaultValue={
                    availableBrokersAndEstates.estates.length === 1
                      ? availableBrokersAndEstates.estates[0].value
                      : '' || ''
                  }
                  disabled={isLoading || availableBrokersAndEstates.estates.length === 1}
                  options={availableBrokersAndEstates.estates || []}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  onChange={(e, { value }) => setSelectedEstate(value)}
                  clearable
                />
              </div>
            </Grid.Column>

            {!user.role.includes('ESTATE') && (
              <Grid.Column width={3}>
                <FieldLabel>Responsável pela Renovação:</FieldLabel>
                <div style={w100}>
                  <DropdownNativo
                    style={w100}
                    value={selectedRenewResponsible}
                    name={`renewResponsible`}
                    options={accountManagerOptions || []}
                    disabled={isLoading || isAccountManagerLoading}
                    loading={isAccountManagerLoading}
                    placeholder={'Selecionar...'}
                    search
                    selection
                    onChange={(e, { value }) => {
                      setSelectedRenewResponsible(value);
                      setFilters({
                        ...filters,
                        renewResponsible: (value as string) || undefined
                      });
                    }}
                    clearable
                  />
                </div>
              </Grid.Column>
            )}
          </Grid.Row>
          <Grid.Row>

            <Grid.Column width={2}>
              <FieldLabel>Data Inicial:</FieldLabel>
              <Field
                name={`start`}
                selected={startFilter ? startFilter : ''}
                onChange={setStartFilter}
                component={CustomDatePicker}
                disabled={isLoading}
                customInput={
                  <AppMaskedInput
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/
                    ]}
                  />
                }
                dateFormat={'dd/MM/yyyy'}
              />
            </Grid.Column>

            <Grid.Column width={2}>
              <FieldLabel>Data Final:</FieldLabel>
              <Field
                name={`end`}
                selected={endFilter ? endFilter : ''}
                onChange={setEndFilter}
                component={CustomDatePicker}
                disabled={isLoading}
                customInput={
                  <AppMaskedInput
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/
                    ]}
                  />
                }
                dateFormat={'dd/MM/yyyy'}
              />
            </Grid.Column>

            <Grid.Column width={3}>
              <FieldLabel>Status:</FieldLabel>
              <div style={w100}>
                <DropdownNativo
                  style={w100}
                  value={selectedStatus}
                  placeholder="Todos"
                  name={`estate`}
                  defaultValue={statusList.length === 1 ? statusList[0].value : '' || ''}
                  disabled={isLoading || statusList.length === 1}
                  options={statusList || []}
                  search
                  selection
                  onChange={(e, { value }) => {
                    setSelectedStatus(String(value));
                  }}
                  clearable
                />
              </div>
            </Grid.Column>

            <Grid.Column width={3}>
              <FieldLabel>Expira em:</FieldLabel>
              <div style={w100}>
                <DropdownNativo
                  value={expireInDays}
                  placeholder="Selecionar..."
                  style={w100}
                  name="expireInDays"
                  options={expireInDaysDropdownOptions}
                  search
                  disabled={isLoading}
                  selection
                  onChange={(e, { value }) => setExpireInDays(value?.toString() || '')}
                  clearable
                />
              </div>
            </Grid.Column>

            {!user.role.includes('ESTATE') && (
              <Grid.Column width={3}>
                <FieldLabel>Gestor de contas:</FieldLabel>
                <div style={w100}>
                  <DropdownNativo
                    style={w100}
                    value={selectedAccountManager}
                    name={`accountManager`}
                    options={accountManagerOptions || []}
                    disabled={isLoading || isAccountManagerLoading}
                    loading={isAccountManagerLoading}
                    placeholder={'Selecionar...'}
                    search
                    selection
                    onChange={(e, { value }) => {
                      setSelectedAccountManager(value);
                      setFilters({
                        ...filters,
                        accountManager: (value as string) || undefined
                      });
                    }}
                    clearable
                  />
                </div>
              </Grid.Column>
            )}

            {!user.role.includes('ESTATE') && (
              <Grid.Column width={3}>
                <FieldLabel>Mais Filtros:</FieldLabel>
                <div style={w100}>
                  <DropdownNativo
                    value={filterSelected}
                    placeholder="Selecionar..."
                    style={w100}
                    name="moreFilters"
                    options={moreFiltersOptions}
                    search
                    disabled={isLoading}
                    selection
                    onChange={(e, { value }) => {
                      handleMoreFilters(String(value));
                      setFilterSelected(value);
                    }}
                    clearable
                  />
                </div>
              </Grid.Column>
            )}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <AuthorizationContainer
                action={ActionEnum.create}
                feature={FeatureEnum.policies}
              >
                <CreatePolicyModal
                  insurers={insurersList}
                  estatesOptions={availableBrokersAndEstates.estates || []}
                />
              </AuthorizationContainer>
            </Grid.Column>
            <Grid.Column width={8}></Grid.Column>
            <Grid.Column width={4}>
              <ButtonsContainer>
                <Button
                  fluid
                  type={'submit'}
                  color="blue"
                  disabled={!isSearchEnabled}
                  text="Pesquisar"
                  onClick={handleSubmit}
                />
                <Button basic fluid type={'button'} text="Limpar" onClick={clearInputs} />
              </ButtonsContainer>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </form>
      <BondInsurancePoliciesTable
        updateList={updateList}
        bondInsurancePolicies={bondInsurancePolicies}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        getData={getData}
        dataCount={dataCount}
      />
    </>
  );
};

export default connect(mapState, mapDispatch)(BondInsurancePoliciesPage);
