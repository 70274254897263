/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineAbility } from "@casl/ability";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";

export interface PermissionType {
  action: string;
  feature: string;
}

const cont: any = 'permission';

export const AbilityContext = createContext(cont);
export const Can = createContextualCan(AbilityContext.Consumer);

export const getAbility = (permissions: PermissionType[]) => {
  return defineAbility((can) => {
    if (permissions.length > 1) {// (permissions.length === 1 && permissions[0].action  === null  && permissions[0].feature === null) {
    //   can("manage", "all");
    // } else {
      permissions.forEach((ability: PermissionType) => {
        can(ability.action, ability.feature)
      })
    }
  });
}
