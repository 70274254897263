export const validPhone = (phone: string): string => {
  if(!phone) {
    return ''
  }
  const onlynumbers = phone.replace(/\D/g, '');
  if(onlynumbers.length < 10) {
    return onlynumbers
  }
  const firstExcludeDDD = onlynumbers.slice(2, 3);
  if(firstExcludeDDD === '9' || firstExcludeDDD === '8') {
    return onlynumbers.slice(0,11) // Para Celular, 11 digitos
  }
  else {
    return onlynumbers.slice(0,10) // Para Fixo, 10 digitos
  }
}
