// import * as Sentry from '@sentry/react';
import * as fetchIntercept from 'fetch-intercept';
import * as Storage from '../services/storage';
import { cleanUserSession } from './auth';
import { dangerNotification } from './notification';
fetchIntercept.register({
  request: function (url, config) {
    if (config && config.headers) {
      config.headers.Authorization = Storage.get('id_token');
      return [url, config];
    }
    return [url, config];
  }
});

function maintenanceUrl(base: string): string {
  return `https://${new URL(base).host}/manutencao`;
}

function homeUrl(base: string): string {
  return `https://${new URL(base).host}/`;
}

export const request = async (
  endpoint: string,
  method: string,
  payload: any = {},
  headers: any = {},
  override?: boolean | false,
): Promise<any> => {
  const config: any = {
    method,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      ...headers
    }
  };

  if (method !== 'GET' && method !== 'DELETE') {
    config.body = JSON.stringify(payload);
  }

  let data: any;

  try {
    const response = await fetch(
      override ? endpoint : `${process.env.REACT_APP_API_URL}${endpoint}`,
      config
    );

    const { status, url } = response;
    const currentUrl = window.location.href;

    if (status === 503 && currentUrl !== maintenanceUrl(url)) {
      window.location.replace(maintenanceUrl(url));
      return;
    }

    if (currentUrl === maintenanceUrl(url)) {
      window.location.replace(homeUrl(url));
      return;
    }
    if (status === 401 && endpoint !== '/auth/login') {
      cleanUserSession();
      dangerNotification('Erro', 'Sua sessão expirou, por favor, faça o login novamente para acessar.');
      return;
    }

    data = await response.json();
    return { status: response.status, data };
  } catch (error) {
    console.error('Erro ao fazer a requisição', {
      endpoint,
      method,
      error,
      payload: method !== 'GET' && method !== 'DELETE' ? payload : undefined
    });

    if (typeof error === 'object' && error !== null && 'message' in error) {
      dangerNotification(
        'Erro',
        'Ocorreu um problema na sua requisição. Tente novamente mais tarde.'
      );
      // Sentry.captureException(error, {
      //   tags: {
      //     endpoint,
      //     method
      //   },
      //   extra: {
      //     payload: method !== 'GET' && method !== 'DELETE' ? payload : undefined
      //   }
      // });
      throw error;
    } else {
      const customError = new Error('Um erro inesperado ocorreu durante a requisição');
      // Sentry.captureException(customError, {
      //   tags: {
      //     endpoint,
      //     method
      //   },
      //   extra: {
      //     payload: method !== 'GET' && method !== 'DELETE' ? payload : undefined
      //   }
      // });
      throw customError;
    }
  }
};

export const get = async (
  endpoint: string,
  headers: any = {},
  override?: boolean | false
): Promise<any> => {
  try {
    const response = await request(endpoint, 'GET', {}, headers, override);

    if (response?.statusCode >= 400 || response?.status >= 400) {
      throw new Error(
        response.data && response.data.message
          ? response.data.message
          : `API ERROR: ${response}`
      );
    }

    return response?.data;
  } catch (e) {
    throw e;
  }
};

export const post = async (
  endpoint: string,
  payload: any,
  headers: any = {},
): Promise<any> => {
  try {
    const response = await request(endpoint, 'POST', payload, headers, false);
    if (response?.statusCode >= 400 || response?.status >= 400) {
      throw response.data;
    }

    return response.data;
  } catch (e) {
    throw e;
  }
};

export const put = async (
  endpoint: string,
  payload: any,
  headers: any = {}
): Promise<any> => {
  try {
    const response = await request(endpoint, 'PUT', payload, headers);

    if (response?.statusCode >= 400 || response?.status >= 400) {
      throw new Error(
        response.data?.message ? response.data?.message : `API ERROR: ${response}`
      );
    }

    return response?.data;
  } catch (e) {
    throw e;
  }
};

export const patch = async (
  endpoint: string,
  payload: any,
  headers: any = {},
): Promise<any> => {
  try {
    const response = await request(
      endpoint,
      'PATCH',
      payload,
      headers,
      false,
    );

    if (response?.statusCode >= 400 || response?.status >= 400) {
      throw new Error(
        response?.data?.message ? response?.data?.message : `API ERROR: ${response}`
      );
    }

    return response?.data;
  } catch (e) {
    throw e;
  }
};

export const remove = async (endpoint: string, headers: any = {}): Promise<any> => {
  try {
    const response = await request(endpoint, 'DELETE', headers);

    if (response?.statusCode >= 400 || response?.status >= 400) {
      throw new Error(
        response?.data?.message ? response?.data?.message : `API ERROR: ${response}`
      );
    }

    return response.data;
  } catch (e) {
    throw e;
  }
};
