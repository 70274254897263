import React, { useState, useEffect } from 'react';
import { Form, Grid, Modal, Image, Icon } from 'semantic-ui-react';
import { FormikProps } from 'formik';
import { FormValues } from './types';
import { LoginProps } from './Login';
import Input from '../../components/Input';
import Button from '../../components/Button';
import background from '../../resources/imgs/background-4seg-new.png';
import Loader from '../../components/Loader';
import logo from '../../resources/imgs/logo.png';
import comunicacao_login from '../../resources/imgs/comunicacao-login-rv.png';
import confiax from '../../resources/imgs/logoconfiax.svg';
import { FormikErrorMessage } from '../../components/ErrorMessage';
import {
  Pushable,
  SideBarGrid,
  Logo,
  FormContainer,
  WelcomeText,
  InfoText,
  Pusher,
  ImageBackground,
  Confiax,
  Imag
} from './styles';
import { FieldLabel } from '../../styles';
import { clear, get, set } from '../../services/storage';

const LoginForm = (props: LoginProps & FormikProps<FormValues>) => {
  const { values, handleSubmit, handleChange, isSubmitting } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [hasRead, setHasRead] = useState<boolean>(false);

  const readLogin = async () => {
    setHasRead(true);
    setOpenModal(false);
    set('hasRead', 'true');
  };

  const getRead = async () => {
    const hasRead = get('hasRead');
    setHasRead(hasRead === 'true');
    clear(); // limpar localstorage
  };

  useEffect(() => {
    getRead();
  }, []);

  if (isSubmitting) {
    return (
      <>
        <Loader />
      </>
    );
  }
  return (
    <>
      <Pushable>
        <SideBarGrid>
          <Logo src={logo} />
          <FormContainer>
            <div>
              <WelcomeText>
                Olá, seja <br /> bem-vindo!
              </WelcomeText>

              <InfoText>
                <Icon name="attention" size="large" />
                Nossa forma de login mudou.{' '}
                <a
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Clique aqui e saiba mais!
                </a>
              </InfoText>
              <Form initialValues={values} onSubmit={handleSubmit}>
                <FieldLabel>E-MAIL:</FieldLabel>
                <Input
                  name="email"
                  type="email"
                  icon={{ name: 'users', color: 'blue' }}
                  iconPosition="left"
                  fluid
                  onChange={handleChange}
                  value={values.email}
                />
                <FormikErrorMessage component="div" name="email" />
                <p />
                <Grid columns={2}>
                  <Grid.Row>
                    <Grid.Column textAlign="center" width={16}>
                      <Button
                        text="Verificar e-mail"
                        color="green"
                        size="large"
                        type="submit"
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column textAlign='center' width={16}>
                      Ainda não é cliente?{' '}
                      <a
                        href="https://conteudo.somosgruporv.com.br/solucoes-gruporv?redirectApp=redeConfiax"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Clique aqui
                      </a>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column textAlign="center" width={16}>
                      <Confiax>
                        <Imag src={confiax} />
                      </Confiax>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </div>
          </FormContainer>
        </SideBarGrid>

        <Pusher>
          <ImageBackground src={background} />
        </Pusher>
      </Pushable>

      <Modal open={openModal} size={'large'}>
        <Modal.Header>Modal de Instrução - Novo Login</Modal.Header>
        <Modal.Content scrolling={hasRead} image>
          <Image src={comunicacao_login} wrapped />
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={readLogin} content={'Estou ciente, Prosseguir!'} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default LoginForm;
