import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px;
`;

export const FormContainer = styled.div`
  padding: 10px 24px;
`;

export const Header = styled.div`
  padding: 24px 18px;
  h3 {
    color: #393939;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const FormTitle = styled.h4`
  font-size: 14px;
  font-weight: 800;
  line-height: 19px;
  margin: 10px 0 !important;
  color: #393939;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 14px 0px;
`;

export const Line = styled.div`
  height: 1px;
  background: #364445;
`;

export const Form = styled.form`
  padding-bottom: 20px;
  label {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0em;
    width: 33%;
  }

  .ui.grid > .row {
    padding-bottom: 0px;
  }
`;

export const FormRow = styled.div`
  padding: 10px 0%;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const InsurerCard = styled.div`
  position: relative;
  height: 82px;
  width: 290px;
  box-shadow: 0 0 4px #00000026;
  display: flex;
  flex-direction: row;
  border-radius: 5px;

  .insurer-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 18px 4px 8px;
  }

  .logo {
    width: 82px;
    height: 100%;
  }

  .insurer-name {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
  }

  .info-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    color: #2185D0;
    cursor: pointer;
  }

  small {
    font-size: 14px;
    color: #2185D0;
    font-weight: 400;
  }
`;

export const InfoDisclaimer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 18px 0;

  .disclaimer-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 70%;
    padding: 0px 12px 0 0;
  }

  .disclaimer-info {
    text-align: right;
    width: 30%;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0em;
    color: #393939;
  }

  .icon {
    font-size: 1.5rem;
    color: #A3A3A3;
  }
`;
