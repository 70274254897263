import styled from 'styled-components';

export const HeaderAndDivider = styled.h3`
  border-bottom: 1px solid #3498db;
  color: #3498db;
  width: 100%;
  margin-top: 20px !important;
  padding-bottom: 5px;
  letter-spacing: 0.4px;
`;

export const HeaderSubtitle = styled.text`
  font-size: 14px;
  font-style: normal;
  color: #5c5d5d;
  width: 100%;
  margin-top: 20px !important;
  padding-bottom: 5px;
  padding-left: 5px;
  letter-spacing: 0.4px;
`;

export const QuotationContainer = styled.div`
  margin-top: 20px;
  border: 1px solid rgba(114, 114, 114, 0.29);
  border-radius: 4px;
`;

export const QuotationHeader = styled.div`
  display: flex;
  padding: 10px 16px;
  align-items: center;
  align-self: stretch;
  background-color: #2185d0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  h3 {
    color: #ffffff;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const QuotationResultContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

export const QuotationList = styled.div`
  width: 60%;
  border-right: 1px solid rgba(114, 114, 114, 0.29);
  min-height: minHeightValue;
`;

export const QuotationValue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 24px;
  width: 40%;
  min-height: minHeightValue;
  h3 {
    color: #2185d0;
    font-size: 12px;
    font-weight: 600;
  }

  label {
    display: flex;
    align-items: baseline;
    flex-direction: row;
    gap: 6px;
    color: #2185d0;
    font-size: 28px;
    font-weight: 700;

    small {
      color: #2185d0;
      font-size: 20px;
      font-weight: 600;
    }
  }

  small {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
  }
`;

type CoveragesRowProps = {
  offwhite?: boolean;
};

export const CoveragesRow = styled.div<CoveragesRowProps>`
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
  background-color: ${props => (props.offwhite ? '#eee' : '#fff')};
  padding: 5px 5px;
  margin: 0;
  .low-opacity {
    opacity: 0.5;
  }
  .required {
    color: #2185d0;
  }

  .slice-insurance-name {
    display: block;
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .insurance-name {
    display: flex;
    flex-direction: row;
  }

  .insurance-value {
    color: #2185d0;
  }
  .pencil-icon {
    margin-left: 5px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }

  .editable-coverages {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 8px;

    .editable-coverages-input {
      width: 150px;
      font-size: 14px;
    }

    .icon {
     cursor: pointer;
    }
  }

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    width: 20px;
    height: 17px;
    margin-right: 10px;
  }
  input[type='checkbox']:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
  }
  input[type='checkbox']:checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: 1px solid #2185d0;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 5px;
    left: 8px;
  }
`;

export const DisclaimerContainer = styled.div`
  padding: 24px 48px;
`;

export const Disclaimer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  padding: 18px 24px;
  border-radius: 11px;
  border: 2px solid #2185d0;

  p {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0em;
  }

  time {
    color: #2185d0;
    font-weight: bold;
  }

  i {
    background: #2185d0;
    color: white;
    border-radius: 2px;
    box-shadow: 0px 0px 1px #2185d070;
  }
`;
