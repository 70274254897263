import { Grid, Input, InputOnChangeData, ButtonProps, Button, Radio, Divider } from 'semantic-ui-react';
import { Field, FieldArray, FieldArrayRenderProps } from 'formik';
import React, { ReactElement, SyntheticEvent, useState } from 'react';
import { getErrorFormik } from '../../../../../../services/errors';
import SectionLabel from '../../../../../../components/SectionLabel';
import { FieldLabel } from '../../../../../../styles';
import { FormikErrorMessage } from '../../../../../../components/ErrorMessage';
import { formatAmount, maskCpf, maskPhoneNumber, maskZipCode, unmaskCpfOrCnpj } from '../../../../../../services/masks';
import { StepProps } from '../../../../BondInsuranceForm';
import "react-datepicker/dist/react-datepicker.css";
import "../../../../../../resources/css/styles.css";
import { employmentRelationshipOptions, genderOptions, maritalStatusOptions } from '../../../../types';
import Dropdown from '../../../../../../components/Dropdown';
import { getDataByDocument } from '../../../../../../services/documents';
import { dangerNotification } from '../../../../../../services/notification';
import CustomDatePicker from "../../../../../../components/CustomDatePicker";
import AppMaskedInput from "../../../../../../components/MaskedInput";
import { PoliticallyExposedPersonEnum } from "../../../../../../enums/politically-exposed-person.enum";

const SECTION_NAME = 'tenant_info';

export const SympathiseTenantsSection = (props: StepProps): ReactElement => {

  const {
    errors,
    touched,
    handleDateChange,
    handleInputChange,
    values,
    setFieldTouched,
    setFieldValue } = props;

  const { tenant_info } = values;

  const [cpfIsLoading, setCpfIsLoading] = useState<boolean>(false);

  const onChangeCpf = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const unmaskedValue = unmaskCpfOrCnpj(e.target.value);

    if (unmaskedValue.length >= 11) {
      setCpfIsLoading(true);

      getDataByDocument(unmaskedValue).then((data) => {
        const birthDate = new Date(data.birth_date);
        birthDate.setTime(
          birthDate.getTime() + birthDate.getTimezoneOffset() * 60 * 1000
        );

        setFieldValue(`${SECTION_NAME}.sympathise_tenants[${index}].name`, data.name);
        setFieldValue(`${SECTION_NAME}.sympathise_tenants[${index}].birth_date`, birthDate);
        setFieldValue(`${SECTION_NAME}.sympathise_tenants[${index}].gender`, data.gender);
        setFieldValue(
          `${SECTION_NAME}.sympathise_tenants[${index}].mother_name`,
          data.mother_name
        );
      }).catch((e) => {
        dangerNotification('Oops...', 'Não foi possível encontrar dados associados ao documento informado')
      }).finally(() => {
        setCpfIsLoading(false);
      })
    }
  };

  const validateErros = (field: string) => getErrorFormik(errors, touched, SECTION_NAME, field);

  const AddTenantButton = (props: ButtonProps): ReactElement => {
    return (
      <Button color="green" type="button" {...props}>Adicionar Locatário</Button>
    );
  }

  return (
    <>
      <SectionLabel text='LOCATÁRIOS SOLIDÁRIOS' />

      <Grid.Column width={8}>
        <FieldLabel>ADICIONAR LOCATÁRIO SOLIDÁRIO?</FieldLabel>
        <Grid.Row>
          <Grid.Column width={5} style={{ paddingTop: '10px' }}>
            <Radio
              style={{ paddingLeft: '0px' }}
              label='Sim'
              name={`${SECTION_NAME}.include_sympathise_tenants`}
              checked={tenant_info.include_sympathise_tenants}
              onChange={(_, data) => {
                setFieldValue(`${SECTION_NAME}.sympathise_tenants[${0}].resident`, false);
                setFieldValue(`${SECTION_NAME}.sympathise_tenants[${0}].politically_exposed_person`, PoliticallyExposedPersonEnum.no);
                setFieldValue(`${SECTION_NAME}.sympathise_tenants[${0}].close_to_politically_exposed_person.politically_exposed_person_name`, '');
                setFieldValue(`${SECTION_NAME}.sympathise_tenants[${0}].close_to_politically_exposed_person.politically_exposed_person_cpf`, '');
                setFieldValue(`${SECTION_NAME}.sympathise_tenants[${0}].close_to_politically_exposed_person.degree_of_kinship_to_politically_exposed_person`, '');
                setFieldValue(`${SECTION_NAME}.include_sympathise_tenants`, true);
              }}
            />
            <Radio
              style={{ paddingLeft: '30px' }}
              label='Não'
              name={`${SECTION_NAME}.include_sympathise_tenants`}
              checked={!tenant_info.include_sympathise_tenants}
              onChange={(_, data) => {
                setFieldValue(`${SECTION_NAME}.include_sympathise_tenants`, false);
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <FormikErrorMessage component="div" name={`${SECTION_NAME}.include_sympathise_tenants`} />
      </Grid.Column>
      {tenant_info.include_sympathise_tenants && <FieldArray
        name={`tenant_info.sympathise_tenants`}
        render={(arrayHelper: FieldArrayRenderProps) => (
          <>
            {tenant_info.sympathise_tenants.map((_, index) => {
              return (
                <>
                  {index !== 0 && <Divider />}
                  <Grid.Row >
                    <Grid.Column width={4}>
                      <FieldLabel>CPF*</FieldLabel>
                      <Field
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].cpf`}
                        value={tenant_info.sympathise_tenants[index].cpf}
                        loading={cpfIsLoading}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
                          data.value = maskCpf(data.value);
                          handleInputChange(e, data);
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                          setFieldTouched(`${SECTION_NAME}.sympathise_tenants[${index}].cpf`);
                          onChangeCpf(e, index);
                        }}
                        error={validateErros(`sympathise_tenants[${index}].cpf`)}
                        component={Input}
                        fluid />
                      <FormikErrorMessage component="div" name={`${SECTION_NAME}.sympathise_tenants[${index}].cpf`} />
                    </Grid.Column>

                    {/* <Grid.Column width={4}>
                      <FieldLabel>RG*</FieldLabel>
                      <Field
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].rg`}
                        value={tenant_info.sympathise_tenants[index].rg}
                        onChange={handleInputChange}
                        onBlur={() =>
                          setFieldTouched(`${SECTION_NAME}.sympathise_tenants[${index}].rg`, true)
                        }
                        error={validateErros(`sympathise_tenants[${index}].rg`)}
                        component={Input}
                        fluid
                      />
                      <FormikErrorMessage
                        component="div"
                        name={`sympathise_tenants[${index}].rg`}
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <FieldLabel>DATA DE EMISSÃO DO RG* </FieldLabel>
                      <Field
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].rg_emission_date`}
                        error={validateErros(`sympathise_tenants[${index}].rg_emission_date`)}
                        selected={tenant_info.sympathise_tenants[index].rg_emission_date}
                        component={CustomDatePicker}
                        customInput={
                          <AppMaskedInput
                            mask={[
                              /[0-9]/,
                              /[0-9]/,
                              "/",
                              /[0-9]/,
                              /[0-9]/,
                              "/",
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                            ]}
                          />
                        }
                        dateFormat={"dd/MM/yyyy"}
                        onChange={(date: Date, e: SyntheticEvent) => {
                          handleDateChange(
                            `${SECTION_NAME}.sympathise_tenants[${index}].rg_emission_date`,
                            date,
                            e
                          );
                        }}
                        onBlur={() =>
                          setFieldTouched(
                            `${SECTION_NAME}.sympathise_tenants[${index}].rg_emission_date`,
                            true
                          )
                        }
                        className={validateErros(`sympathise_tenants[${index}].rg_emission_date`)}
                      />
                      <FormikErrorMessage
                        component="div"
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].rg_emission_date`}
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <FieldLabel>ÓRGÃO EXPEDITOR DO RG*</FieldLabel>
                      <Field
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].rg_expeditor_organ`}
                        value={tenant_info.sympathise_tenants[index].rg_expeditor_organ}
                        onChange={handleInputChange}
                        onBlur={() =>
                          setFieldTouched(
                            `${SECTION_NAME}.sympathise_tenants[${index}].rg_expeditor_organ`,
                            true
                          )
                        }
                        error={validateErros(`sympathise_tenants[${index}].rg_expeditor_organ`)}
                        component={Input}
                        maxLength={11}
                        fluid
                      />
                      <FormikErrorMessage
                        component="div"
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].rg_expeditor_organ`}
                      />
                    </Grid.Column> */}
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={4}>
                      <FieldLabel>NOME*</FieldLabel>
                      <Field
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].name`}
                        value={tenant_info.sympathise_tenants[index].name}
                        onChange={handleInputChange}
                        onBlur={() => setFieldTouched(`${SECTION_NAME}.sympathise_tenants[${index}].name`, true)}
                        customInput={<Input />}
                        error={validateErros(`sympathise_tenants[${index}].name`)}
                        component={Input}
                        fluid />
                      <FormikErrorMessage component="div" name={`${SECTION_NAME}.sympathise_tenants[${index}].name`} />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <FieldLabel>GÊNERO* </FieldLabel>
                      <Field
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].gender`}
                        value={tenant_info.sympathise_tenants[index].gender}
                        completeValue={true}
                        placeholder={"Selecionar..."}
                        options={genderOptions}
                        component={Dropdown}
                        onChange={handleInputChange}
                        onClose={() =>
                          setFieldTouched(`${SECTION_NAME}.sympathise_tenants[${index}].gender`, true)
                        }
                        error={validateErros(`sympathise_tenants[${index}].gender`)}
                        fluid
                      />
                      <FormikErrorMessage
                        component="div"
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].gender`}
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <FieldLabel>DATA DE NASCIMENTO* </FieldLabel>
                      <Field
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].birth_date`}
                        error={validateErros(`sympathise_tenants[${index}].birth_date`)}
                        selected={tenant_info.sympathise_tenants[index].birth_date}
                        component={CustomDatePicker}
                        customInput={
                          <AppMaskedInput
                            mask={[
                              /[0-9]/,
                              /[0-9]/,
                              "/",
                              /[0-9]/,
                              /[0-9]/,
                              "/",
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                            ]}
                          />
                        }
                        dateFormat={"dd/MM/yyyy"}
                        onChange={(date: Date, e: SyntheticEvent) => {
                          handleDateChange(
                            `${SECTION_NAME}.sympathise_tenants[${index}].birth_date`,
                            date,
                            e
                          );
                        }}
                        onBlur={() =>
                          setFieldTouched(`${SECTION_NAME}.sympathise_tenants[${index}].birth_date`, true)
                        }
                        className={validateErros(`sympathise_tenants[${index}].birth_date`)}
                      />
                      <FormikErrorMessage
                        component="div"
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].birth_date`}
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <FieldLabel>NOME DA MÃE*</FieldLabel>
                      <Field
                        autoCorrect="off"
                        autoCapitalize="none"
                        spellCheck="false"
                        autoComplete="new-password"
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].mother_name`}
                        value={tenant_info.sympathise_tenants[index].mother_name}
                        onChange={handleInputChange}
                        onBlur={() =>
                          setFieldTouched(`${SECTION_NAME}.sympathise_tenants[${index}].mother_name`, true)
                        }
                        error={validateErros(`sympathise_tenants[${index}].mother_name`)}
                        component={Input}
                        fluid
                      />
                      <FormikErrorMessage
                        component="div"
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].mother_name`}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={4}>
                      <FieldLabel>EMAIL*</FieldLabel>
                      <Field
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].email`}
                        value={tenant_info.sympathise_tenants[index].email}
                        onChange={handleInputChange}
                        onBlur={() => setFieldTouched(`${SECTION_NAME}.sympathise_tenants[${index}].email`, true)}
                        customInput={<Input />}
                        error={validateErros(`sympathise_tenants[${index}].email`)}
                        component={Input}
                        fluid />
                      <FormikErrorMessage component="div" name={`${SECTION_NAME}.sympathise_tenants[${index}].email`} />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <FieldLabel>TELEFONE*</FieldLabel>
                      <Field
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].phone`}
                        value={tenant_info.sympathise_tenants[index].phone}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                          data: InputOnChangeData
                        ): void => {
                          data.value = maskPhoneNumber(data.value);
                          handleInputChange(e, data);
                        }}
                        onBlur={() =>
                          setFieldTouched(`${SECTION_NAME}.sympathise_tenants[${index}].phone`, true)
                        }
                        error={validateErros(`sympathise_tenants[${index}].phone`)}
                        component={Input}
                        fluid
                      />
                      <FormikErrorMessage
                        component="div"
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].phone`}
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <FieldLabel>ESTADO CIVIL* </FieldLabel>
                      <Field
                        class={"dropdown"}
                        autoCorrect="off"
                        autoCapitalize="none"
                        spellCheck="false"
                        autoComplete="off"
                        search={false}
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].marital_status`}
                        value={tenant_info.sympathise_tenants[index].marital_status}
                        completeValue={true}
                        placeholder={"Selecionar..."}
                        options={maritalStatusOptions}
                        component={Dropdown}
                        onChange={handleInputChange}
                        onClose={() =>
                          setFieldTouched(
                            `${SECTION_NAME}.sympathise_tenants[${index}].marital_status`,
                            true
                          )
                        }
                        error={validateErros(`sympathise_tenants[${index}].marital_status`)}
                        fluid
                      />
                      <FormikErrorMessage
                        component="div"
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].marital_status`}
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <FieldLabel>NACIONALIDADE*</FieldLabel>
                      <Field
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].nationality`}
                        value={tenant_info.sympathise_tenants[index].nationality}
                        onChange={handleInputChange}
                        onBlur={() =>
                          setFieldTouched(`${SECTION_NAME}.sympathise_tenants[${index}].nationality`, true)
                        }
                        error={validateErros(`sympathise_tenants[${index}].nationality`)}
                        component={Input}
                        fluid
                      />
                      <FormikErrorMessage
                        component="div"
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].nationality`}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    {/* <Grid.Column width={4}>
                      <FieldLabel>CEP DO ENDEREÇO ATUAL*</FieldLabel>
                      <Field
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].current_address_zipcode`}
                        value={tenant_info.sympathise_tenants[index].current_address_zipcode}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                          data: InputOnChangeData
                        ): void => {
                          setFieldValue(
                            `${SECTION_NAME}.sympathise_tenants[${index}].current_address_zipcode`,
                            maskZipCode(e.target.value)
                          );
                        }}
                        onBlur={() =>
                          setFieldTouched(
                            `${SECTION_NAME}.sympathise_tenants[${index}].current_address_zipcode`,
                            true
                          )
                        }
                        error={validateErros(`$sympathise_tenants[${index}].current_address_zipcode`)}
                        component={Input}
                        maxLength={9}
                        fluid
                      />
                      <FormikErrorMessage
                        component="div"
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].current_address_zipcode`}
                      />
                    </Grid.Column> */}

                    <Grid.Column width={4}>
                      <FieldLabel>RESIDENTE?*</FieldLabel>
                      <Grid.Row>
                        <Grid.Column width={3} style={{ paddingTop: '10px' }}>
                          <Radio
                            style={{ paddingLeft: '0px' }}
                            label='Sim'
                            name={`${SECTION_NAME}.sympathise_tenants[${index}].resident`}
                            checked={tenant_info.sympathise_tenants[index].resident}
                            onChange={(_, data) => {
                              setFieldValue(`${SECTION_NAME}.sympathise_tenants[${index}].resident`, true);
                            }}
                          />
                          <Radio
                            style={{ paddingLeft: '30px' }}
                            label='Não'
                            name={`${SECTION_NAME}.sympathise_tenants[${index}].resident`}
                            checked={!tenant_info.sympathise_tenants[index].resident}
                            onChange={(_, data) => {
                              setFieldValue(`${SECTION_NAME}.sympathise_tenants[${index}].resident`, false);
                            }}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <FormikErrorMessage component="div" name={`${SECTION_NAME}.sympathise_tenants[${index}].resident`} />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={4}>
                      <FieldLabel paddingTop={true}>
                        PESSOA POLITICAMENTE EXPOSTA?{" "}
                      </FieldLabel>
                      <Grid.Row>
                        <Grid.Column
                          width={5}
                          style={{
                            paddingTop: "10px",
                          }}
                        >
                          <Radio
                            style={{ marginRight: "1rem" }}
                            label="Sim"
                            id={`${SECTION_NAME}.sympathise_tenants[${index}].politically_exposed_person.yes`}
                            name={`${SECTION_NAME}.sympathise_tenants[${index}].politically_exposed_person`}
                            value={PoliticallyExposedPersonEnum.yes}
                            checked={
                              tenant_info.sympathise_tenants[index].politically_exposed_person ===
                              PoliticallyExposedPersonEnum.yes
                            }
                            onChange={(_, data) => {
                              setFieldValue(
                                data.name!,
                                data.value
                                  ? PoliticallyExposedPersonEnum.yes
                                  : PoliticallyExposedPersonEnum.no
                              );
                            }}
                          />

                          <Radio
                            style={{ marginRight: "1rem" }}
                            label="Não"
                            id={`${SECTION_NAME}.sympathise_tenants[${index}].politically_exposed_person.no`}
                            name={`${SECTION_NAME}.sympathise_tenants[${index}].politically_exposed_person`}
                            value={PoliticallyExposedPersonEnum.no}
                            checked={
                              tenant_info.sympathise_tenants[index].politically_exposed_person ===
                              PoliticallyExposedPersonEnum.no
                            }
                            onChange={(_, data) => {
                              setFieldValue(
                                data.name!,
                                data.value
                                  ? PoliticallyExposedPersonEnum.no
                                  : PoliticallyExposedPersonEnum.no
                              );
                            }}
                          />

                          <Radio
                            label="Próximo"
                            id={`${SECTION_NAME}.sympathise_tenants[${index}].politically_exposed_person.closer`}
                            name={`${SECTION_NAME}.sympathise_tenants[${index}].politically_exposed_person`}
                            value={PoliticallyExposedPersonEnum.closeTo}
                            checked={
                              tenant_info.sympathise_tenants[index].politically_exposed_person ===
                              PoliticallyExposedPersonEnum.closeTo
                            }
                            onChange={(_, data) => {
                              setFieldValue(
                                data.name!,
                                data.value
                                  ? PoliticallyExposedPersonEnum.closeTo
                                  : PoliticallyExposedPersonEnum.no
                              );
                            }}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <FormikErrorMessage
                        component="div"
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].politically_exposed_person`}
                      />
                    </Grid.Column>

                    {tenant_info.sympathise_tenants[index].politically_exposed_person ===
                      PoliticallyExposedPersonEnum.closeTo && (
                        <>
                          <Grid.Column width={4}>
                            <FieldLabel>NOME DA PESSOA*</FieldLabel>
                            <Field
                              autoCorrect="off"
                              autoCapitalize="none"
                              spellCheck="false"
                              autoComplete="new-password"
                              id={`${SECTION_NAME}.sympathise_tenants[${index}].close_to_politically_exposed_person.politically_exposed_person_name`}
                              name={`${SECTION_NAME}.sympathise_tenants[${index}].close_to_politically_exposed_person.politically_exposed_person_name`}
                              value={
                                tenant_info.sympathise_tenants[index].close_to_politically_exposed_person
                                  .politically_exposed_person_name
                              }
                              onChange={handleInputChange}
                              onBlur={() =>
                                setFieldTouched(
                                  `${SECTION_NAME}.sympathise_tenants[${index}].close_to_politically_exposed_person.politically_exposed_person_name`,
                                  true
                                )
                              }
                              customInput={<Input />}
                              error={validateErros(
                                `sympathise_tenants[${index}].close_to_politically_exposed_person.politically_exposed_person_name`,
                              )}
                              component={Input}
                              fluid
                            />
                            <FormikErrorMessage
                              component="div"
                              name={`${SECTION_NAME}.sympathise_tenants[${index}].close_to_politically_exposed_person.politically_exposed_person_name`}
                            />
                          </Grid.Column>

                          <Grid.Column width={4}>
                            <FieldLabel>CPF DA PESSOA*</FieldLabel>
                            <Field
                              loading={cpfIsLoading}
                              id={`${SECTION_NAME}.sympathise_tenants[${index}].close_to_politically_exposed_person.politically_exposed_person_cpf`}
                              name={`${SECTION_NAME}.sympathise_tenants[${index}].close_to_politically_exposed_person.politically_exposed_person_cpf`}
                              value={
                                tenant_info.sympathise_tenants[index].close_to_politically_exposed_person
                                  .politically_exposed_person_cpf
                              }
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                                data: InputOnChangeData
                              ): void => {
                                data.value = maskCpf(data.value);
                                handleInputChange(e, data);
                              }}
                              onBlur={(
                                e: React.ChangeEvent<HTMLInputElement>,
                                data: InputOnChangeData
                              ) => {
                                setFieldTouched(
                                  `${SECTION_NAME}.sympathise_tenants[${index}].close_to_politically_exposed_person.politically_exposed_person_cpf`,
                                  true
                                );
                              }}
                              error={validateErros(
                                `sympathise_tenants[${index}].close_to_politically_exposed_person.politically_exposed_person_cpf`
                              )}
                              component={Input}
                              fluid
                            />
                            <FormikErrorMessage
                              component="div"
                              name={`${SECTION_NAME}.sympathise_tenants[${index}].close_to_politically_exposed_person.politically_exposed_person_cpf`}
                            />
                          </Grid.Column>

                          <Grid.Column width={4}>
                            <FieldLabel>GRAU DE PARENTESCO*</FieldLabel>
                            <Field
                              autoCorrect="off"
                              autoCapitalize="none"
                              spellCheck="false"
                              autoComplete="new-password"
                              id={`${SECTION_NAME}.sympathise_tenants[${index}].close_to_politically_exposed_person.degree_of_kinship_to_politically_exposed_person`}
                              name={`${SECTION_NAME}.sympathise_tenants[${index}].close_to_politically_exposed_person.degree_of_kinship_to_politically_exposed_person`}
                              value={
                                tenant_info.sympathise_tenants[index].close_to_politically_exposed_person
                                  .degree_of_kinship_to_politically_exposed_person
                              }
                              onChange={handleInputChange}
                              onBlur={() =>
                                setFieldTouched(
                                  `${SECTION_NAME}.sympathise_tenants[${index}].close_to_politically_exposed_person.degree_of_kinship_to_politically_exposed_person`,
                                  true
                                )
                              }
                              customInput={<Input />}
                              error={validateErros(
                                `sympathise_tenants[${index}].close_to_politically_exposed_person.degree_of_kinship_to_politically_exposed_person`
                              )}
                              component={Input}
                              fluid
                            />
                            <FormikErrorMessage
                              component="div"
                              name={`${SECTION_NAME}.sympathise_tenants[${index}].close_to_politically_exposed_person.degree_of_kinship_to_politically_exposed_person`}
                            />
                          </Grid.Column>
                        </>
                      )}
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={4}>
                      <FieldLabel>VÍNCULO EMPREGATÍCIO*</FieldLabel>
                      <Field
                        id={`${SECTION_NAME}.sympathise_tenants[${index}].employment_relationship`}
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].employment_relationship`}
                        value={tenant_info.sympathise_tenants[index].employment_relationship}
                        completeValue={true}
                        placeholder={'Selecionar...'}
                        options={employmentRelationshipOptions}
                        component={Dropdown}
                        onChange={handleInputChange}
                        onClose={() => setFieldTouched(`${SECTION_NAME}.sympathise_tenants[${index}].employment_relationship`, true)}
                        error={validateErros(`${SECTION_NAME}.sympathise_tenants[${index}].employment_relationship`)}
                        fluid />
                      <FormikErrorMessage component="div" name={`${SECTION_NAME}.sympathise_tenants[${index}].employment_relationship`} />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <FieldLabel>PROFISSÃO*</FieldLabel>
                      <Field
                        id={`${SECTION_NAME}.sympathise_tenants[${index}].profession`}
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].profession`}
                        value={tenant_info.sympathise_tenants[index].profession}
                        onChange={handleInputChange}
                        onBlur={() =>
                          setFieldTouched(`${SECTION_NAME}.sympathise_tenants[${index}].profession`, true)
                        }
                        error={validateErros(`sympathise_tenants[${index}].profession`)}
                        component={Input}
                        fluid
                      />
                      <FormikErrorMessage
                        component="div"
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].profession`}
                      />
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <FieldLabel>RENDA MENSAL*</FieldLabel>
                      <Field
                        id={`${SECTION_NAME}.sympathise_tenants[${index}].monthly_fixed_income`}
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].monthly_fixed_income`}
                        value={tenant_info.sympathise_tenants[index].monthly_fixed_income}
                        component={Input}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                          data: InputOnChangeData
                        ): void => {
                          data.value = formatAmount(data.value);
                          handleInputChange(e, data);
                        }}
                        onBlur={(e: React.FocusEvent<any>) => {
                          setFieldTouched(
                            `${SECTION_NAME}.sympathise_tenants[${index}].monthly_fixed_income`,
                            true
                          );
                        }}
                        error={validateErros(`sympathise_tenants[${index}].monthly_fixed_income`)}
                        fluid
                      />
                      <FormikErrorMessage
                        component="div"
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].monthly_fixed_income`}
                      />
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <FieldLabel>OUTRAS RENDAS</FieldLabel>
                      <Field
                        id={`${SECTION_NAME}.sympathise_tenants[${index}].other_incomes`}
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].other_incomes`}
                        value={tenant_info.sympathise_tenants[index].other_incomes}
                        component={Input}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                          data: InputOnChangeData
                        ): void => {
                          data.value = formatAmount(data.value);
                          handleInputChange(e, data);
                        }}
                        onBlur={(e: React.FocusEvent<any>) => {
                          setFieldTouched(
                            `${SECTION_NAME}.sympathise_tenants[${index}].other_incomes`,
                            true
                          );
                        }}
                        error={validateErros(`sympathise_tenants[${index}].other_incomes`)}
                        fluid
                      />
                      <FormikErrorMessage
                        component="div"
                        name={`${SECTION_NAME}.sympathise_tenants[${index}].other_incomes`}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </>
              )
            })}
            <Grid.Row>
              <AddTenantButton onClick={() => {
                arrayHelper.push({
                  name: '',
                  cpf: '',
                  rg: '',
                  rg_expeditor_organ: '',
                  gender: '',
                  mother_name: '',
                  marital_status: '',
                  nationality: '',
                  profession: '',
                  current_address_zipcode: '',
                  monthly_fixed_income: '',
                  other_incomes: '',
                  document: '',
                  percent: 0,
                  date_of_entry: '',
                  resident: false,
                  politically_exposed_person: PoliticallyExposedPersonEnum.no,
                  close_to_politically_exposed_person: {
                    politically_exposed_person_name: '',
                    politically_exposed_person_cpf: '',
                    degree_of_kinship_to_politically_exposed_person: ''
                  },
                })
              }} />
            </Grid.Row>
          </>

        )}
      />}
    </>
  )
};
