export enum CancellationReasonEnum {
  customerGaveUp = "CLIENTE DESISTIU DA LOCACAO",
  rentWithAnotherGuarantee = "ALUGOU COM OUTRA GARANTIA",
  rentWithGuarantor = "ALUGOU COM FIADOR",
  deliveryKeys = 'ENTREGA DE CHAVES',
  deliveryKeysAndNoDebts = 'ENTREGA DE CHAVES E INEXISTÊNCIA DE DÉBITOS',
  nonPayment = 'FALTA DE PAGAMENTO',
  divergenceAddress = 'DIVERGENCIA DE ENDERECO',
  changeOfWarranty = 'TROCA DE GARANTIA',
  propertyPurchase = 'COMPRA DO IMÓVEL',
  errorInHiring = 'ERRO NA CONTRATACAO',
  errorInNumberOfInstallments = 'ERRO NO NUMERO DE PARCELAS',
  leaseAdvance = 'ANTICIPACAO DE LOCACAO',
  changeOfTenant = 'TROCA DE LOCATARIO',
  changeOfInsurer = 'TROCA DE SEGURADORA',
  duplicationOfPolicy = 'DUPLICACAO DE APOLICE',
  claimClosed = 'SINISTRO ENCERRADO',
}
