import {FireInsuranceBudgetsListReducer, FireInsuranceBudgetsListState} from "../types/temp-types";

type FireInsuranceBudgetsListRematchModel = {
  state: FireInsuranceBudgetsListState;
  reducers: FireInsuranceBudgetsListReducer;
}

const fireInsuranceBudgetsList: FireInsuranceBudgetsListRematchModel = {
  state: {
    budgets: [],
    isLoading: true
  },
  reducers: {
    updateFireInsuranceBudgetsList: (state: FireInsuranceBudgetsListState, payload: Partial<FireInsuranceBudgetsListState>) => {
      return {
        ...state,
        ...payload
      }
    }
  }
};

export default fireInsuranceBudgetsList;
