import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px;
`;

export const Header = styled.div`
  padding: 24px 18px;
  h3 {
    color: #393939;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const Line = styled.div`
  height: 1px;
  background: #364445;
`;

export const FormContainer = styled.div`
  padding: 10px 24px 30px;
`;

export const FormTitle = styled.h4`
  font-size: 14px;
  font-weight: 800;
  line-height: 19px;
  margin: 10px 0 !important;
  color: #393939;
`;

export const Form = styled.form`
  label {
    display: flex;
    flex-direction: column;
    gap: 10px;

    font-size: 14px;
    font-weight: 800;
    line-height: 19px;
    letter-spacing: 0em;
  }

  p {
    margin: 5px 0 10px;
  }

  input {
    width: 100%;
  }

  .row > input {
    margin-top: 0px;
  }

  .ui.grid > .row {
    padding-bottom: 0px;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 12px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
  width: 100%;
`;

export const Disclaimer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  width: fit-content;
  padding: 12px 24px;
  border-radius: 11px;
  border: 2px solid #FFB01799;
  margin: auto;

  p {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0em;
  }

  time {
    color: #FFB01799;
    font-weight: bold;
  }

  i {
    background: #FFB01799;
    color: white;
    border-radius: 2px;
    box-shadow: 0px 0px 1px #FFB01799;
  }
`;
