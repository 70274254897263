import React from 'react';
import { Button, Header, Icon, Modal, Popup } from 'semantic-ui-react';
import InstrucaoSinistro from '../../../resources/docs/instrucao-sinistro/instrucao-sinistro';

interface ModalInstructionsProps {
  openModal: boolean;
  toggleOpen: () => void;
  toggleClose: () => void;
}
const ModalInstructions = (props: ModalInstructionsProps) => {
  const { openModal, toggleClose, toggleOpen} = props
  return (
    <>
      <Modal
        open={openModal}
        onClose={toggleClose}
        onOpen={toggleOpen}
      >
        <Modal.Header>
          <Header>Como abrir expectativa de Sinistro</Header>
        </Modal.Header>
        <Modal.Content scrolling>
          <InstrucaoSinistro />
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={toggleClose}
            color={'green'}
          >
            OK, Entendi
          </Button>
        </Modal.Actions>
      </Modal>

      <Popup
        trigger={
          <span  onClick={toggleOpen} style={{color: "#2185d0", fontWeight: 'bold', fontSize: '14px', cursor: 'pointer'} }>
          <Icon
            circular
            inverted
            size={'small'}
            name={'info'}
            color={'blue'}
            style={{ cursor: 'pointer', marginRight: '10px', fontSize: 14 }}
            />
             Como abrir um sinistro?
            </span>
        }
        content={'Clique aqui para maiores informações sobre como abrir um sinistro'}
        position={'left center'}
      />
    </>
  )
}

export default ModalInstructions;
