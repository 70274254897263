import React from 'react';
import { Grid, Divider } from 'semantic-ui-react';
import styled from 'styled-components';
import { SectionLabelProps } from './types';

const LabelText = styled.p`
  font-weight: bold;
  color: #364445;
`

const LabelColumn = styled(Grid.Column)`
  padding: 0em 0em !important;
`

const SectionLabel = (props: SectionLabelProps) => {
  return (
    <Grid.Row style={{
      paddingTop: '0',
      paddingBottom: '0',
      marginTop: props.marginTop ? `${props.marginTop}px` : '1.5em',
      color: props.color ? props.color : '#364445',
    }}>
      <LabelColumn>
        <LabelText>{props.text}</LabelText>
        <Divider />
      </LabelColumn>
    </Grid.Row>
  );
}

export default SectionLabel;
