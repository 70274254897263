import React from 'react';

// import { Container } from './styles';
interface PopupListItemInterface {
  title: string;
  isAble: boolean;
}

const PopupListItem = ({ isAble, title }: PopupListItemInterface) => {
  return (
    <strong
      style={{
        color: isAble ? 'green' : 'red',
      }}
    >
      {title}
    </strong>
  );
}

export default PopupListItem;