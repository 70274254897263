import React, { useEffect, useState } from 'react';
import { Button, Dimmer, Icon, Loader, Modal } from 'semantic-ui-react';
import CancelationInfoStep from './components/CancelationInfoForm';
import { UpdateCancelation } from './types';
import { warningNotification } from '../../../../services/notification';
import { InsurerState } from '../../../../types/InsurerState';
import { connect } from 'react-redux';
import { iRootDispatch, iRootState } from '../../../../store';
import { AvailableBrokersAndEstatesState } from '../../../../store/types/temp-types';
import { CustoDropDownItemProps } from '../../../../components/types';
import { Canceled } from '../AnalysisProgressModal/types/ConfirmCancelModalProps';
import { getExternalCancelation } from '../../../../services/canceled-bond-insurance';
import { Form, Header } from './styles';
import { maskCpfOrCnpj, maskZipCode } from '../../../../services/masks';
import { UserState } from '../../../../types/UserState';
import { isValidEmail } from '../../../../utils/emailValidator';

const EditCancelationModal = ({
  availableBrokersAndEstates,
  isOpen,
  onClose,
  handleSubmit,
  canceled,
  canceledId,
  user,
  responsibleOptions,
}: {
  availableBrokersAndEstates: any;
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (data: UpdateCancelation) => void;
  canceled: Canceled;
  canceledId: string;
  user: UserState;
  responsibleOptions: CustoDropDownItemProps[];
}) => {
  const estates: CustoDropDownItemProps[] = availableBrokersAndEstates.estates;
  const insurers: InsurerState[] | null = availableBrokersAndEstates.bondInsurers.map(
    (insurer: any) => insurer.value
  );
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<UpdateCancelation>({
    status: '',
    cancellationReason: '',
    observations: '',
    cancelation: {
      estateId: '',
      insurerId: '',
      policyNumber: '',
      notificationEmails: ['']
    },
    property: {
      zipCode: '',
      city: '',
      complement: '',
      district: '',
      number: '',
      state: '',
      street: ''
    },
    tenant: {
      document: '',
      fullName: ''
    },
    responsibleId: '',
  });

  const [cancelledBondInsurance, setCancelledBondInsurance] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);

  const external = cancelledBondInsurance
    ? cancelledBondInsurance.externalCancelation
    : null;

  useEffect(() => {
    if (canceledId) {
      const getData = async () => {
        setIsLoading(true);
        getExternalCancelation(canceledId)
          .then(res => {
            setCancelledBondInsurance(res);
            setIsLoading(false);
          })
          .catch(err => {
            console.error({ err });
          });
      };
      getData();
    }
  }, [canceledId]);

  const getTenantFormatted = (data: any) => {
    if (data?.externalCancelation) {
      return {
        document: maskCpfOrCnpj(data?.externalCancelation?.tenant?.document),
        fullName: data?.externalCancelation?.tenant?.fullName
      };
    } else if (data?.bondInsurance?.naturalTenant) {
      return {
        document: maskCpfOrCnpj(data?.bondInsurance?.naturalTenant?.main_tenant_cpf),
        fullName: data?.bondInsurance?.naturalTenant?.main_tenant_name
      };
    }

    return {
      document: maskCpfOrCnpj(data?.bondInsurance?.legalTenant?.document),
      fullName: data?.bondInsurance?.legalTenant?.company
    };
  };

  const getCancelationFormatted = (data: any) => {
    if (data?.externalCancelation) {
      return {
        estateId: data?.externalCancelation?.estateId,
        insurerId: data?.externalCancelation?.insurerId,
        policyNumber: data?.externalCancelation?.policyNumber,
        notificationEmails: data?.externalCancelation?.notificationEmails?.split(';') || []
      };
    }

    return {
      estateId: data?.bondInsurance?.estateId,
      insurerId: data?.bondInsurance?.bondInsuranceHiredData?.insurerId,
      policyNumber: data?.bondInsurance?.bondInsuranceHiredData?.insurerPolicyId,
      notificationEmails: data?.notificationEmails?.split(';') || []
    };
  };

  const getAddressFormatted = (data: any) => {
    if (data?.externalCancelation) {
      return {
        zipCode: data?.externalCancelation?.property?.zipCode,
        city: data?.externalCancelation?.property?.city,
        complement: data?.externalCancelation?.property?.complement,
        district: data?.externalCancelation?.property?.district,
        number: data?.externalCancelation?.property?.number,
        state: data?.externalCancelation?.property?.state,
        street: data?.externalCancelation?.property?.street,
      }
    }

    return {
      zipCode: maskZipCode(data?.bondInsurance?.property?.zip_code),
      city: data?.bondInsurance?.property?.city,
      complement: data?.bondInsurance?.property?.complement,
      district: data?.bondInsurance?.property?.district,
      number: data?.bondInsurance?.property?.number,
      state: data?.bondInsurance?.property?.state,
      street: data?.bondInsurance?.property?.street,
    }
  };

  useEffect(() => {
    if (cancelledBondInsurance) {
      const { status, cancellation_reason, observations, responsibleId } = cancelledBondInsurance;
      setForm({
        status,
        cancellationReason: cancellation_reason || '',
        observations,
        cancelation: getCancelationFormatted(cancelledBondInsurance),
        property: getAddressFormatted(cancelledBondInsurance),
        tenant: getTenantFormatted(cancelledBondInsurance),
        responsibleId,
      });
    }
  }, [cancelledBondInsurance]);

  function onSubmit(values: UpdateCancelation) {
    setIsValidForm(true);
    if (!values.cancelation.estateId) {
      warningNotification('Ops...', 'Por favor, selecione a imobiliária desejada!');
      return;
    }

    if (!values.cancelation.insurerId) {
      warningNotification('Ops...', 'Por favor, selecione a seguradora desejada!');
      return;
    }

    if (!values.cancellationReason) {
      warningNotification('Ops...', 'Por favor, preencha o motivo da cancelamento!');
      return;
    }

    if (
      !values?.cancelation?.notificationEmails ||
      !values?.cancelation?.notificationEmails[0] ||
      values?.cancelation?.notificationEmails.length < 1 ||
      values?.cancelation?.notificationEmails?.findIndex((item) => item == '' || !isValidEmail(item)) > 0 // Find an invalid element
    ) {
      warningNotification(
        'Ops...',
        'Por favor, insira um e-mail válido para as notificações!'
      );
      return;
    }

    if (!values.tenant.document) {
      warningNotification('Ops...', 'Por favor, informe o DOCUMENTO do locatário!');
      return;
    }

    if (!values.tenant.fullName) {
      warningNotification('Ops...', 'Por favor, informe o NOME COMPLETO do locatário!');
      return;
    }

    if (!values.property.zipCode) {
      warningNotification('Ops...', 'Por favor, informe o CEP do imóvel!');
      return;
    }

    if (!values.property.state) {
      warningNotification('Ops...', 'Por favor, informe o ESTADO do imóvel!');
      return;
    }

    if (!values.property.city) {
      warningNotification('Ops...', 'Por favor, informe a CIDADE do imóvel!');
      return;
    }

    if (!values.property.district) {
      warningNotification('Ops...', 'Por favor, informe o BAIRRO do imóvel!');
      return;
    }

    if (!values.property.street) {
      warningNotification('Ops...', 'Por favor, informe o LOGRADOURO do imóvel!');
      return;
    }

    if (!values.property.number) {
      warningNotification('Ops...', 'Por favor, informe o NÚMERO do imóvel!');
      return;
    }

    handleSubmit(form);
  }

  const renderActionButtons = () => (
    <>
      <Modal.Actions>
        <Button color="red" type="button" onClick={() => onClose()}>
          <Icon name="remove" />
          Cancelar
        </Button>
        <Button color="green" type="submit" onClick={() => onSubmit(form)}>
          <Icon name="checkmark" /> Salvar
        </Button>
      </Modal.Actions>
    </>
  );

  return (
    <div>
      <Modal
        closeIcon
        open={isOpen}
        onClose={() => onClose()}
        size='large'
      >
        <Dimmer active={isLoading}>
          <Loader content={'Carregando dados'} />
        </Dimmer>
        <Header>
          <h3>
            <Icon name="pencil alternate" /> Editar cancelamento
          </h3>
        </Header>
        <Modal.Content>
          <Form id="cancelation-form" onSubmit={() => onSubmit(form)}>
            <CancelationInfoStep
              form={form}
              estates={estates}
              insurers={insurers}
              setForm={setForm}
              user={user}
              isValidForm={isValidForm}
              responsibleOptions={responsibleOptions}
            />
          </Form>
        </Modal.Content>
        {renderActionButtons()}
      </Modal>
    </div>
  );
};

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

export default connect(mapState, mapDispatch)(EditCancelationModal);
