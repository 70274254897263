import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Radio,
  Grid,
  InputOnChangeData,
  Dropdown,
  DropdownItemProps,
  Popup,
  Icon,
  List
} from 'semantic-ui-react';
import { Field, FormikProps } from 'formik';
import SectionLabel from '../../../../../../../../components/SectionLabel';
import { FieldLabel } from '../../../../../../../../styles';
import {
  maskCpfOrCnpj,
  maskMoney,
  maskZipCode
} from '../../../../../../../../services/masks';
import { get } from '../../../../../../../../services/storage';
import Input from '../../../../../../../../components/Input';
import { AddressService } from '../../../../../../../../services/address';
import {
  HireProposalModalProps,
  HireProposalModalPropsForm
} from '../../../../types/ModalHireProposalProps';
import CustomDatePicker from '../../../../../../../../components/CustomDatePicker';
import { SyntheticEvent } from 'react-router/node_modules/@types/react';
import { SurtyPottencialInsuraceinitialValues } from '../../Formik/initialStatesSurty';
import AppMaskedInput from '../../../../../../../../components/MaskedInput';
import { FormikErrorMessage } from '../../../../../../../../components/ErrorMessage';
import {
  cnpjValidity,
  cpfValidity,
  dateDiffInYears,
  daysDiff,
  monthDiff
} from '../../../../../../../../util';
import { GetCoveragesDTO } from '../../../../types/GetCoverages';
import { warningNotification } from '../../../../../../../../services/notification';
import { PoliticallyExposedPersonEnum } from '../../../../../../../../enums/politically-exposed-person.enum';
import {
  genderOptions,
  hirePropertyTypeOptions,
  legalCommercialResidentialReasonOptions,
  maritalStatusOptions,
  naturalCommercialReasonOptions,
  naturalResidentialReasonOptions
} from '../../../../../../../bond-insurance-budget-form/types';
import { PersonTypesEnum } from '../../../../../../../../enums/person-types.enum';
import { PurposesEnum } from '../../../../../../../../enums/purposes.enum';
import { AnalysisAssuranceEnum } from '../../../../../../../../enums/analysis-assurance.enum';
import { CustoDropDownItemProps } from '../../../../../../../../components/types';
import { DateToStringDate } from '../../../../../../../../services/date';
import moment from 'moment-timezone';
import AddressWarningBanner from '../../../AddressWarningBanner';
import { getDataByDocument } from '../../../../../../../../services/documents';

const SurtyPottencialInsuranceForm = ({
  values,
  setFieldValue,
  maxInstallments,
  nominal_total_premium_amount,
  flagSubmit,
  callbackSubmit,
  naturalTentant,
  fireQuotationValue,
  property,
  analysisID,
  setFlagSubmit,
  bondInsurance,
  rentOnly,
  approvedAt
}: FormikProps<typeof SurtyPottencialInsuraceinitialValues> &
  HireProposalModalProps &
  HireProposalModalPropsForm) => {
  const [documentError, setDocumentError] = useState(false);
  const [coverages, setCoverages] = useState<GetCoveragesDTO[]>();
  const [cepLoading, setCepLoading] = useState<boolean>(false);
  const [reasonsOptions, setReasonsOptions] = useState<Array<DropdownItemProps>>([]);
  const [loadDocument, setLoadDocument] = useState(false);
  const [disableNameField, setDisabledNameField] = useState<boolean>(true);

  function getBirthDate() {
    const data = moment(bondInsurance?.natural_tenant?.main_tenant_birth_date).toDate();
    return data;
  }

  const analysisOptions: CustoDropDownItemProps[] = [
    {
      key: 1,
      text: 'Plano Seguro Completo (Garantido)',
      value: AnalysisAssuranceEnum.complete
    },
    {
      key: 2,
      text: 'Plano Seguro Simples (Básico)',
      value: AnalysisAssuranceEnum.simple
    }
  ];

  useEffect(() => {
    if (
      bondInsurance?.purpose === PurposesEnum.commercial &&
      bondInsurance?.tenant_type === PersonTypesEnum.natural
    ) {
      setReasonsOptions(naturalCommercialReasonOptions);
    } else if (
      bondInsurance?.purpose === PurposesEnum.residential &&
      bondInsurance?.tenant_type === PersonTypesEnum.natural
    ) {
      setReasonsOptions(naturalResidentialReasonOptions);
    } else {
      setReasonsOptions(legalCommercialResidentialReasonOptions);
    }
  }, [bondInsurance]);

  useEffect(() => {
    setFieldValue('occupation', naturalTentant.main_tenant_profession);
    setFieldValue('fixedIncome', naturalTentant.main_tenant_monthly_fixed_income);
  }, [naturalTentant]);

  useEffect(() => {
    setFieldValue('estate', {
      estateZipCode: (bondInsurance?.estate as any)?.estate_zip_code,
      estateStreet: (bondInsurance?.estate as any).estate_street,
      estateDistrict: (bondInsurance?.estate as any).estate_district,
      estateCity: (bondInsurance?.estate as any).estate_city,
      estateState: (bondInsurance?.estate as any).estate_state,
      estateNumber: (bondInsurance?.estate as any).estate_number,
      estateComplement: (bondInsurance?.estate as any).estate_complement
    });

    setFieldValue('property.rent_value', bondInsurance?.property.rent_value);
    setFieldValue('property.condominium_fee', bondInsurance?.property.condominium_fee);
    setFieldValue('property.taxes_value', bondInsurance?.property.taxes_value);
    setFieldValue('property.energy_bill', bondInsurance?.property.energy_bill);
    setFieldValue('property.water_bill', bondInsurance?.property.water_bill);
    setFieldValue('property.gas_bill', bondInsurance?.property.gas_bill);
    setFieldValue('analysis_assurance', bondInsurance?.analysis_assurance);
  }, [bondInsurance]);

  useEffect(() => {
    setFieldValue('property.reason', property.reason);
    setFieldValue('property.type', property.property_type);
  }, [property]);

  useEffect(() => {
    const diff = monthDiff(
      new Date(values.rent_insurance_contract_start_date || Date.now()),
      new Date(
        values.rent_insurance_contract_end_date ||
          new Date().setMonth(new Date().getMonth() + maxInstallments)
      )
    );
    setFieldValue('hiredInstallments', diff);
  }, [values.rent_insurance_contract_end_date]);

  useEffect(() => {
    function submitForm() {
      let error = false;
      setFlagSubmit && setFlagSubmit(false);
      setDocumentError(false);

      if (!values.estate.estateZipCode) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o Endereço da Imobiliária!'
        );
        error = true;
      }
      if (!values.estate.estateStreet) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o Endereço da Imobiliária!'
        );
        error = true;
      }
      if (!values.estate.estateDistrict) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o Endereço da Imobiliária!'
        );
        error = true;
      }
      if (!values.estate.estateCity) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o Endereço da Imobiliária!'
        );
        error = true;
      }
      if (!values.estate.estateState) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o Endereço da Imobiliária!'
        );
        error = true;
      }
      if (!values.estate.estateNumber) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o Endereço da Imobiliária!'
        );
        error = true;
      }

      const estateNumber = Number(values.estate.estateNumber);
      if (isNaN(estateNumber)) {
        warningNotification(
          'Ops...',
          'Por favor, preencha o Número no Endereço da Imobiliária apenas com valores númericos!'
        );
        error = true;
      }

      if (!values.owner_data.public_document_id) {
        warningNotification('Ops...', 'Por favor, preencha o Documento do Proprietário!');
        error = true;
      }
      if (!values.owner_data.name) {
        warningNotification('Ops...', 'Por favor, preencha o Nome do Proprietário!');
        error = true;
      }

      if (values.owner_data.public_document_id.length === 14) {
        if (!cpfValidity(values.owner_data.public_document_id)) {
          warningNotification('Ops. ', 'CPF inválido');
          setDocumentError(true);
          error = true;
        }
      } else {
        if (!cnpjValidity(values.owner_data.public_document_id)) {
          warningNotification('Ops. ', 'CNPJ inválido');
          setDocumentError(true);
          error = true;
        }
      }

      if (!values.owner_data.email) {
        warningNotification('Ops...', 'Por favor, preencha o Email do Proprietário!');
        error = true;
      }

      if (
        values.owner_data.public_document_id.replace(/\D/g, '') ===
        bondInsurance?.natural_tenant?.main_tenant_cpf.replace(/\D/g, '')
      ) {
        warningNotification(
          'Erro...',
          'O documento do Proprietário deve ser DIFERENTE do documento do Locatário!'
        );
        error = true;
      }
      if (
        !values.rent_insurance_contract_start_date ||
        !values.rent_insurance_contract_end_date ||
        !values.rental_period_start_date ||
        !values.rental_period_end_date ||
        !values.due_date_first_rent ||
        !values.due_date_first_rent
      ) {
        warningNotification(
          'Erro...',
          'Por favor, insira a data Inicial e Final do Contrato de Locação!'
        );
        error = true;
      } else {
        if (
          dateDiffInYears(
            values.rent_insurance_contract_start_date!,
            values.rent_insurance_contract_end_date!
          ) < 1
        ) {
          warningNotification(
            'Ops...',
            'O intervalo de datas entre início e fim do contrato de locação deve ser de no mínimo 12 meses. Em caso de dúvidas entrar em contato com seu gestor de contas.'
          );
          error = true;
        }

        if (
          dateDiffInYears(
            values.rental_period_start_date!,
            values.rental_period_end_date!
          ) < 1
        ) {
          warningNotification('Ops...', 'O aluguel deve ser no mínimo 1 ano completo!');
          error = true;
        }

        if (daysDiff(values.rental_period_start_date!, values.due_date_first_rent!) < 0) {
          warningNotification(
            'Ops...',
            'A data de vencimento do primeiro aluguel deve ser maior ou igual ao início da locação '
          );
          error = true;
        }

        if (
          monthDiff(values.rental_period_start_date!, values.rental_period_end_date!) > 60
        ) {
          warningNotification(
            'Ops...',
            'A diferença máxima entre as datas de inicio e fim do contrato de locação é de 5 anos.'
          );
          error = true;
        }
      }

      const riskAddress =
        (property.street ? property.street + ', ' : '') +
        (property.number ? property.number + ' - ' : '') +
        (property.complement ? property.complement + ', ' : '') +
        (property.district ? property.district + ' - ' : '') +
        (property.city ? property.city + '/' : '') +
        (property.state ? property.state : '') +
        (property.zip_code ? ' - CEP ' + property.zip_code : '');

      const ownerAddress =
        (values.estate.estateStreet ? values.estate.estateStreet + ', ' : '') +
        (values.estate.estateNumber ? values.estate.estateNumber + ' - ' : '') +
        (values.estate.estateComplement ? values.estate.estateComplement + ', ' : '') +
        (values.estate.estateDistrict ? values.estate.estateDistrict + ' - ' : '') +
        (values.estate.estateCity ? values.estate.estateCity + '/' : '') +
        (values.estate.estateState ? values.estate.estateState : '') +
        (values.estate.estateZipCode ? ' - CEP ' + values.estate.estateZipCode : '');

      if (ownerAddress === riskAddress) {
        warningNotification(
          'Ops...',
          'O Endereço do imóvel não pode ser igual ao endereço da Imobiliária/Proprietário.'
        );
        error = true;
      }

      if (error) {
        return;
      }

      function addDays(date: Date, days: number) {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      }

      const userId = get('uid');
      const payload = {
        userId,
        bondInsuranceAnalysisId: analysisID,
        dateInsuranceStart: values.rent_insurance_contract_start_date,
        dateInsuranceEnd: values.rent_insurance_contract_end_date,
        dateRentalStart: values.rental_period_start_date,
        dateRentalEnd: values.rental_period_end_date,
        dueDateFirstRent: addDays(values.due_date_first_rent!, 1),
        owner_address: {
          zipCode: values.estate.estateZipCode,
          street: values.estate.estateStreet,
          district: values.estate.estateDistrict,
          city: values.estate.estateCity,
          state: values.estate.estateState,
          number: values.estate.estateNumber,
          complement: values.estate.estateComplement
        },
        owner_data: {
          documentNumber: values.owner_data.public_document_id.replace(/\D/g, ''),
          name: values.owner_data.name,
          email: values.owner_data.email
        },
        property: {
          reason: values.property.reason,
          type: values.property.type
        }
      };
      console.log(payload);
      callbackSubmit(payload);
    }
    flagSubmit && submitForm();
  }, [flagSubmit]);

  const onChangeCep = (zipCode: string, isProperty?: boolean): void => {
    const maskedValue = maskZipCode(zipCode);

    setFieldValue('zipCode', maskedValue);
    if (maskedValue.length >= 9) {
      setCepLoading(true);
      isProperty
        ? AddressService.getAddressByZipCode(zipCode)
            .then(address => {
              setFieldValue('property.city', address.city);
              setFieldValue('property.district', address.district);
              setFieldValue('property.state', address.state);
              setFieldValue('property.street', address.street);
              setCepLoading(false);
            })
            .catch(e => {
              console.log('address error:', e);
            })
        : AddressService.getAddressByZipCode(zipCode)
            .then(address => {
              setFieldValue('estate.estateCity', address.city);
              setFieldValue('estate.estateDistrict', address.district);
              setFieldValue('estate.estateState', address.state);
              setFieldValue('estate.estateStreet', address.street);
              setCepLoading(false);
            })
            .catch(e => {
              console.log('address error:', e);
            });
    }
  };

  async function getOwnerByDocument(document: string) {
    try {
      setLoadDocument(true);
      const unformatedDoc = document.replace(/\D/g, '');
      const isCPF = unformatedDoc.length === 11;
      const data = await getDataByDocument(unformatedDoc);
      if (isCPF) {
        setFieldValue('owner_data.name', data.name);
        setDisabledNameField(true);
      } else {
        setDisabledNameField(false);
        setFieldValue('owner_data.name', data.company);
        setFieldValue('owner_data.phone', data.phone);
        setFieldValue('owner_data.email', data.email);
      }
    } catch (err) {
      console.log(err);
      setDisabledNameField(false);
    } finally {
      setLoadDocument(false);
    }
  }

  useEffect(() => {
    if (values.estate.estateZipCode) {
    }
    if (
      values.estate.estateZipCode &&
      values.estate.estateZipCode.length >= 8 &&
      !(values.estate.estateZipCode.slice(6) === '000' && values.estate.estateStreet)
    ) {
      onChangeCep(values.estate.estateZipCode);
    }
  }, [values.estate.estateZipCode]);

  return (
    <div>
      <SectionLabel text="INFORMAÇÕES DA LOCAÇÃO" />
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column width={5}>
            {bondInsurance?.purpose === PurposesEnum.residential ? (
              <Radio
                label="Residencial"
                name={`purpose`}
                value={PurposesEnum.residential}
                checked={true}
              />
            ) : (
              <Radio
                label="Comercial"
                name={`bondInsurance.purpose`}
                value={PurposesEnum.commercial}
                checked={true}
              />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel>MOTIVO DA LOCAÇÃO* </FieldLabel>
            <Dropdown
              name={`bondInsurance.reason`}
              value={values.property.reason}
              options={reasonsOptions}
              onChange={(e, { value }) => setFieldValue('property.reason', value)}
              floating
              search
              selection
              fluid
              placeholder="Selecionar..."
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <FieldLabel>TIPO DO IMÓVEL* </FieldLabel>
            <Dropdown
              name={`bondInsurance.propertyType`}
              value={values.property.type}
              options={hirePropertyTypeOptions}
              onChange={(e, { value }) => setFieldValue('property.type', value)}
              floating
              search
              selection
              fluid
              placeholder="Selecionar..."
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <SectionLabel text="INFORMAÇÕES DO CONTRATO" />
      <Grid columns="equal">
        <Grid.Row>
          {
            <Grid.Column width={8}>
              <FieldLabel>DATA DE INICIO DO CONTRATO DE LOCAÇÃO* </FieldLabel>
              <Field
                name={`rental_period_start_date`}
                selected={values.rental_period_start_date}
                minDate={new Date(approvedAt || Date.now())}
                component={CustomDatePicker}
                customInput={
                  <AppMaskedInput
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/
                    ]}
                  />
                }
                dateFormat={'dd/MM/yyyy'}
                onChange={(date: Date, e: SyntheticEvent) => {
                  setFieldValue('rental_period_start_date', date);
                  setFieldValue('due_date_first_rent', date);
                  setFieldValue('rent_insurance_contract_start_date', date);
                }}
              />
              <FormikErrorMessage component="div" name={`rental_period_start_date`} />
            </Grid.Column>
          }
          <Grid.Column width={8}>
            <FieldLabel>DATA DE FINALIZAÇÃO DO CONTRATO DE LOCAÇÃO* </FieldLabel>
            <Field
              name={`rental_period_end_date`}
              selected={values.rental_period_end_date}
              component={CustomDatePicker}
              customInput={
                <AppMaskedInput
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/
                  ]}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              onChange={(date: Date, e: SyntheticEvent) => {
                setFieldValue('rental_period_end_date', date);
                setFieldValue('rent_insurance_contract_end_date', date);
              }}
            />
            <FormikErrorMessage component="div" name={`rental_period_end_date`} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <p>
              {' '}
              Atenção: Informe as datas de início e fim da vigência, conforme o contrato
              de locação. Lembramos que a vigência mínima é de 12 meses completos.
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <SectionLabel text="INFORMAÇÕES DO PROPRIETÁRIO" />
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <FieldLabel>CPF/CNPJ*</FieldLabel>
            <Field
              error={documentError}
              name={`bondInsurance.public_document_id`}
              value={maskCpfOrCnpj(values.owner_data.public_document_id)}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('owner_data.public_document_id', data.value)
              }
              onBlur={(e: { target: { value: string | any[] } }) => {
                const doc = e.target.value as string;
                if (doc.length === 14) {
                  if (!cpfValidity(doc)) {
                    warningNotification('Ops. ', 'CPF inválido');
                    setDocumentError(true);
                    setDisabledNameField(false);
                    return;
                  }
                  setDocumentError(false);
                  getOwnerByDocument(doc);
                } else {
                  if (!cnpjValidity(doc)) {
                    warningNotification('Ops. ', 'CNPJ inválido');
                    setDocumentError(true);
                    setDisabledNameField(false);
                    return;
                  }
                  setDocumentError(false);
                  getOwnerByDocument(doc);
                }
              }}
              component={Input}
              fluid
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>NOME*</FieldLabel>
            <Field
              name={`bondInsurance.name`}
              value={values.owner_data.name}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('owner_data.name', data.value)
              }
              disabled={disableNameField}
              component={Input}
              fluid
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <FieldLabel>EMAIL*</FieldLabel>
            <Field
              name={`bondInsurance.email`}
              value={values.owner_data.email}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('owner_data.email', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>
        </Grid.Row>

        <SectionLabel text="ENDEREÇO DA IMOBILIÁRIA OU PROPRIETÁRIO" />
        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>CEP*</FieldLabel>
            <Field
              name={`bondInsurance.zip_code`}
              value={values.estate?.estateZipCode}
              loading={cepLoading}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateZipCode', maskZipCode(data.value))
              }
              component={Input}
              maxLength={9}
              fluid
            />
          </Grid.Column>

          <Grid.Column width={11}>
            <FieldLabel>LOGRADOURO*</FieldLabel>
            <Field
              name={`bondInsurance.street`}
              value={values.estate?.estateStreet}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateStreet', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>BAIRRO*</FieldLabel>
            <Field
              name={`bondInsurance.district`}
              value={values.estate?.estateDistrict}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateDistrict', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <FieldLabel>CIDADE*</FieldLabel>
            <Field
              name={`bondInsurance.city`}
              value={values.estate?.estateCity}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateCity', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <FieldLabel>UF*</FieldLabel>
            <Field
              name={`bondInsurance.state`}
              value={values.estate?.estateState}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateState', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>NÚMERO*</FieldLabel>
            <Field
              name={`bondInsurance.number`}
              value={values.estate?.estateNumber}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateNumber', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <FieldLabel>COMPLEMENTO</FieldLabel>
            <Field
              name={`bondInsurance.complement`}
              value={values.estate?.estateComplement}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateComplement', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <SectionLabel text="DADOS DO PROPONENTE (Dados do Locatário)" />
      <Grid>
        {bondInsurance?.tenant_type === PersonTypesEnum.legal ? (
          <>
            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>CNPJ*</FieldLabel>
                <Field
                  name={`legalTenant.document`}
                  value={bondInsurance?.legal_tenant.document}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={8}>
                <FieldLabel>EMPRESA*</FieldLabel>
                <Field
                  name={`legalTenant.company`}
                  value={bondInsurance?.legal_tenant.company}
                  customInput={<Input />}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>TELEFONE*</FieldLabel>
                <Field
                  name={`legalTenant.phone`}
                  value={bondInsurance?.legal_tenant.phone}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>EMAIL*</FieldLabel>
                <Field
                  name={`legalTenant.email`}
                  value={bondInsurance?.legal_tenant.email}
                  customInput={<Input />}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>ÚLT. ALT. CONTRATUAL*</FieldLabel>
                <Field
                  name={`legalTenant.last_contractual_alteration_date`}
                  value={bondInsurance?.legal_tenant.last_contractual_alteration_date}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>CAPITAL SOCIAL*</FieldLabel>
                <Field
                  name={`legalTenant.share_capital`}
                  value={bondInsurance?.legal_tenant.share_capital}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>CAPITAL INTEGRALIZADO*</FieldLabel>
                <Field
                  name={`legalTenant.paid_in_capital`}
                  value={bondInsurance?.legal_tenant.paid_in_capital}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>FATURAMENTO ANUAL*</FieldLabel>
                <Field
                  name={`legalTenant.anual_revenue`}
                  value={bondInsurance?.legal_tenant.anual_revenue}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>CONTATO (NOME)*</FieldLabel>
                <Field
                  name={`legalTenant.contact_name`}
                  value={bondInsurance?.legal_tenant.contact_name}
                  customInput={<Input />}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>CONTATO (TELEFONE)*</FieldLabel>
                <Field
                  name={`legalTenant.contact_phone`}
                  value={bondInsurance?.legal_tenant.phone}
                  customInput={<Input />}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>
            </Grid.Row>
          </>
        ) : (
          <>
            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>CPF*</FieldLabel>
                <Field
                  name={`naturalPerson.main_tenant_cpf`}
                  value={bondInsurance?.natural_tenant?.main_tenant_cpf}
                  component={Input}
                  fluid
                  disabled
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>NOME DO LOCATÁRIO*</FieldLabel>
                <Field
                  autoCorrect="off"
                  autoCapitalize="none"
                  spellCheck="false"
                  autoComplete="new-password"
                  name={`naturalPerson.main_tenant_name`}
                  value={bondInsurance?.natural_tenant?.main_tenant_name}
                  customInput={<Input />}
                  component={Input}
                  fluid
                  disabled
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel paddingTop={true}>GÊNERO* </FieldLabel>
                <Field
                  name={`naturalPerson.main_tenant_gender`}
                  value={bondInsurance?.natural_tenant?.main_tenant_gender}
                  completeValue={true}
                  placeholder={'Selecionar...'}
                  options={genderOptions}
                  component={Dropdown}
                  fluid
                  disabled
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>DATA DE NASCIMENTO* </FieldLabel>
                <Field
                  name={`naturalPerson.main_tenant_birth_date`}
                  selected={getBirthDate()}
                  component={CustomDatePicker}
                  customInput={
                    <AppMaskedInput
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/
                      ]}
                    />
                  }
                  dateFormat={'dd/MM/yyyy'}
                  disabled
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>NOME DA MÃE*</FieldLabel>
                <Field
                  autoCorrect="off"
                  autoCapitalize="none"
                  spellCheck="false"
                  autoComplete="new-password"
                  name={`naturalPerson.main_tenant_mother_name`}
                  value={bondInsurance?.natural_tenant?.main_tenant_mother_name}
                  component={Input}
                  fluid
                  disabled
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>EMAIL*</FieldLabel>
                <Field
                  autoCorrect="off"
                  autoCapitalize="none"
                  spellCheck="false"
                  autoComplete="new-password"
                  name={`naturalPerson.main_tenant_email`}
                  value={bondInsurance?.natural_tenant?.main_tenant_email}
                  customInput={<Input />}
                  component={Input}
                  fluid
                  disabled
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>TELEFONE*</FieldLabel>
                <Field
                  name={`naturalPerson.main_tenant_phone`}
                  value={bondInsurance?.natural_tenant?.main_tenant_phone}
                  component={Input}
                  fluid
                  disabled
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel paddingTop={true}>ESTADO CIVIL* </FieldLabel>
                <Field
                  class={'dropdown'}
                  autoCorrect="off"
                  autoCapitalize="none"
                  spellCheck="false"
                  autoComplete="off"
                  search={false}
                  name={`naturalPerson.main_tenant_marital_status`}
                  value={bondInsurance?.natural_tenant?.main_tenant_marital_status}
                  completeValue={true}
                  placeholder={'Selecionar...'}
                  options={maritalStatusOptions}
                  component={Dropdown}
                  fluid
                  disabled
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>NACIONALIDADE*</FieldLabel>
                <Field
                  name={`naturalPerson.main_tenant_nationality`}
                  value={bondInsurance?.natural_tenant?.main_tenant_nationality}
                  component={Input}
                  fluid
                  disabled
                />
              </Grid.Column>
            </Grid.Row>

            {/* <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>CEP DO ENDEREÇO ATUAL*</FieldLabel>
                <Field
                  name={`naturalPerson.main_tenant_current_address_zipcode`}
                  value={
                    bondInsurance?.natural_tenant
                      ?.main_tenant_current_address_zipcode
                  }
                  component={Input}
                  maxLength={9}
                  fluid
                  disabled
                />
              </Grid.Column>
            </Grid.Row> */}
            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel paddingTop={true}>PESSOA POLITICAMENTE EXPOSTA? </FieldLabel>
                <Grid.Row>
                  <Grid.Column
                    width={5}
                    style={{
                      paddingTop: '10px'
                    }}
                  >
                    <Radio
                      style={{ marginRight: '1rem' }}
                      label="Sim"
                      name={`naturalPerson.main_tenant_politically_exposed_person`}
                      value={PoliticallyExposedPersonEnum.yes}
                      checked={
                        bondInsurance?.natural_tenant
                          ?.main_tenant_politically_exposed_person ===
                        PoliticallyExposedPersonEnum.yes
                      }
                      disabled
                    />

                    <Radio
                      style={{ marginRight: '1rem' }}
                      label="Não"
                      name={`naturalPerson.main_tenant_politically_exposed_person`}
                      value={PoliticallyExposedPersonEnum.no}
                      checked={
                        bondInsurance?.natural_tenant
                          ?.main_tenant_politically_exposed_person ===
                        PoliticallyExposedPersonEnum.no
                      }
                      disabled
                    />

                    <Radio
                      label="Próximo"
                      name={`naturalPerson.main_tenant_politically_exposed_person`}
                      value={PoliticallyExposedPersonEnum.closeTo}
                      checked={
                        bondInsurance?.natural_tenant
                          ?.main_tenant_politically_exposed_person ===
                        PoliticallyExposedPersonEnum.closeTo
                      }
                      disabled
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>

              {bondInsurance?.natural_tenant?.main_tenant_politically_exposed_person ===
                PoliticallyExposedPersonEnum.closeTo && (
                <>
                  <Grid.Column width={4}>
                    <FieldLabel>NOME DA PESSOA*</FieldLabel>
                    <Field
                      autoCorrect="off"
                      autoCapitalize="none"
                      spellCheck="false"
                      autoComplete="new-password"
                      name={`naturalPerson.main_tenant_close_to_politically_exposed_person.politically_exposed_person_name`}
                      customInput={<Input />}
                      component={Input}
                      fluid
                      disabled
                    />
                  </Grid.Column>

                  <Grid.Column width={4}>
                    <FieldLabel>CPF DA PESSOA*</FieldLabel>
                    <Field
                      name={`naturalPerson.main_tenant_close_to_politically_exposed_person.politically_exposed_person_cpf`}
                      value={
                        bondInsurance?.natural_tenant
                          ?.main_tenant_close_to_politically_exposed_person
                          ?.politically_exposed_person_cpf
                      }
                      customInput={<Input />}
                      component={Input}
                      fluid
                      disabled
                    />
                  </Grid.Column>

                  <Grid.Column width={4}>
                    <FieldLabel>GRAU DE PARENTESCO*</FieldLabel>
                    <Field
                      autoCorrect="off"
                      autoCapitalize="none"
                      spellCheck="false"
                      autoComplete="new-password"
                      name={`naturalPerson.main_tenant_close_to_politically_exposed_person.degree_of_kinship_to_politically_exposed_person`}
                      value={
                        bondInsurance?.natural_tenant
                          ?.main_tenant_close_to_politically_exposed_person
                          ?.degree_of_kinship_to_politically_exposed_person
                      }
                      customInput={<Input />}
                      component={Input}
                      fluid
                      disabled
                    />
                  </Grid.Column>
                </>
              )}
            </Grid.Row>

            {/* <Grid.Row>
              {bondInsurance?.natural_tenant?.main_tenant_marital_status ===
                "CASADO" && (
                  <Grid.Column width={4}>
                    <FieldLabel paddingTop={true}>
                      CÔNJUGE COMPÕE RENDA?{" "}
                    </FieldLabel>
                    <Grid.Row>
                      <Grid.Column width={5} style={{ paddingTop: "10px" }}>
                        <Radio
                          style={{ marginRight: "1rem" }}
                          label="Sim"
                          name={`naturalPerson.partner_rent_composition`}
                          checked={
                            bondInsurance?.natural_tenant
                              ?.partner_rent_composition
                          }
                          disabled
                        />
                        <Radio
                          style={{ marginRight: "1rem" }}
                          label="Não"
                          name={`naturalPerson.partner_rent_composition`}
                          checked={
                            !bondInsurance?.natural_tenant
                              ?.partner_rent_composition
                          }
                          disabled
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid.Column>
                )}
            </Grid.Row> */}
          </>
        )}
      </Grid>

      <Grid>
        <SectionLabel text="ENDEREÇO DO IMÓVEL" />
        <Grid.Row>
          <AddressWarningBanner />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>CEP*</FieldLabel>
            <Field
              name={`bondInsurance.zip_code`}
              value={property.zip_code}
              component={Input}
              maxLength={9}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column width={11}>
            <FieldLabel>LOGRADOURO*</FieldLabel>
            <Field
              name={`bondInsurance.street`}
              value={property.street}
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>BAIRRO*</FieldLabel>
            <Field
              name={`bondInsurance.district`}
              value={property.district}
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <FieldLabel>CIDADE*</FieldLabel>
            <Field
              name={`bondInsurance.city`}
              value={property.city}
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <FieldLabel>UF*</FieldLabel>
            <Field
              name={`bondInsurance.state`}
              value={property.state}
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>NÚMERO*</FieldLabel>
            <Field
              name={`bondInsurance.number`}
              value={property.number}
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <FieldLabel>COMPLEMENTO</FieldLabel>
            <Field
              name={`bondInsurance.complement`}
              value={property.complement}
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <SectionLabel text="PLANO DE COBERTURA" />
      <Grid.Column width={8}>
        <FieldLabel paddingTop={true}>
          GARANTIA DA ANÁLISE*
          <Popup
            trigger={<Icon name="help circle" style={{ color: '#364445' }} />}
            inverted
          >
            <Popup.Content>
              <List bulleted>
                <List.Item>
                  <b>Plano Seguro Completo (Garantido)</b>
                  <List.List>
                    <List.Item>
                      Cobertura: Aluguel; Encargos declarados na análise; Danos ao imóvel;
                      Multa por rescisão contratual e Pintura.
                    </List.Item>
                  </List.List>
                </List.Item>
                <List.Item>
                  <b>Plano Seguro Simples (Básico)</b>
                  <List.List>
                    <List.Item>
                      Cobertura: Aluguel; Encargos declarados pela imobiliária.
                    </List.Item>
                    <List.Item>
                      Não possui cobertura: Danos ao imóvel; Pintura e Multa por rescisão
                      contratual.
                    </List.Item>
                  </List.List>
                </List.Item>
              </List>
            </Popup.Content>
          </Popup>
        </FieldLabel>
        <Field
          name={`bondinsurance.analysis_assurance`}
          value={values.analysis_assurance}
          defaultValue={null}
          completeValue={true}
          placeholder={'Selecionar...'}
          disabled={true}
          options={analysisOptions}
          component={Dropdown}
          fluid
        />
      </Grid.Column>

      {rentOnly ? (
        <Grid>
          <SectionLabel text="" />
          <Grid.Row>
            <Grid.Column width={4}>
              <FieldLabel>VALOR DO ALUGUEL</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.rent_value`}
                value={maskMoney(String(values.property.rent_value), true, 2)}
                component={Input}
                fluid
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : (
        <Grid>
          <SectionLabel text="" />
          <Grid.Row>
            <Grid.Column width={4}>
              <FieldLabel>VALOR DO ALUGUEL</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.rent_value`}
                value={maskMoney(String(values.property.rent_value), true, 2)}
                component={Input}
                fluid
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <FieldLabel>VALOR DO CONDOMÍNIO</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.condominium_fee`}
                value={maskMoney(String(values.property.condominium_fee), true, 2)}
                component={Input}
                fluid
              />
            </Grid.Column>

            <Grid.Column width={4}>
              <FieldLabel>VALOR DO IPTU</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.taxes_value`}
                value={maskMoney(String(values.property.taxes_value), true, 2)}
                component={Input}
                fluid
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={4}>
              <FieldLabel>VALOR DA ENERGIA</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.energy_bill`}
                value={maskMoney(String(values.property.energy_bill), true, 2)}
                component={Input}
                fluid
              />
            </Grid.Column>

            <Grid.Column width={4}>
              <FieldLabel>VALOR DA ÁGUA</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.water_bill`}
                value={maskMoney(String(values.property.water_bill), true, 2)}
                component={Input}
                fluid
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <FieldLabel>VALOR DO GÁS CANALIZADO</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.gas_bill`}
                value={maskMoney(String(values.property.gas_bill), true, 2)}
                component={Input}
                fluid
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      <Grid>
        <SectionLabel text="Valores a serem contratados" />
        <Grid.Row>
          <Grid.Column width={4}>
            <FieldLabel>Parcelas a serem contratadas</FieldLabel>
            <Field
              name={`hiredInstallments`}
              value={values.hiredInstallments}
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Valor da parcela</FieldLabel>
            <Field
              name={`bondInsurance.analysisValue`}
              value={maskMoney(
                String(nominal_total_premium_amount / maxInstallments),
                true,
                2
              )}
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Prêmio total a ser contratado</FieldLabel>
            <Field
              name={`bondInsurance.analysisValue`}
              value={maskMoney(
                String(
                  (nominal_total_premium_amount / maxInstallments) *
                    values.hiredInstallments
                ),
                true,
                2
              )}
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
export default SurtyPottencialInsuranceForm;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5em 0;
  width: 100%;
  border: 1px solid silver;
  border-radius: 5px;
  padding: 2.5%;
`;
