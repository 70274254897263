import {Code as InsurerCode} from "../../../../types/InsurerState";
import {ObjectSchema} from "yup";
import {
  FairfaxTenantInputSectionSchema,
  fairfaxTenantInputSectionYupSchema
} from "./fairfax-tenant-input-section/fairfax-tenant-input-section-schema";
import {
  TokioMarineTenantInputSectionSchema,
  tokioMarineTenantInputSectionYupSchema
} from "./tokio-marine-tenant-input-section/tokio-marine-tenant-input-section-schema";
import {
  ConnectorEnabledTenantInputSectionSchema,
  connectorEnabledTenantInputSectionYupSchema
} from "./connector-enabled-tenant-input-section/connector-tenant-input-section-schema";

export const getFireInsuranceTenantSectionYupSchema = (isConnectorEnabled: boolean, insurerCode: string):
  ObjectSchema<FairfaxTenantInputSectionSchema>
  | ObjectSchema<TokioMarineTenantInputSectionSchema>
  | ObjectSchema<ConnectorEnabledTenantInputSectionSchema>
  | null => {
  if (isConnectorEnabled) {
    return connectorEnabledTenantInputSectionYupSchema
  } else {
    switch (insurerCode) {
      case InsurerCode.Fairfax:
        return fairfaxTenantInputSectionYupSchema;
        break;
      case InsurerCode.TokioMarine:
        return tokioMarineTenantInputSectionYupSchema;
        break;
      default:
        return null;
        break;
    }
  }
}
