import React, { useEffect, useState } from 'react';
import { Grid, Modal, Divider, Icon, Segment, Button, Label } from 'semantic-ui-react';
import { FieldLabel, FieldValue } from './Table'
import styled from 'styled-components'
import { translatePropertyStatus, translateStatus, translateValuesType } from '../util'

import { ClaimEstate } from '../../../types/ClaimEstate'
import { maskCpfOrCnpj, maskPhoneNumber } from '../../../services/masks';
import { ClaimValuesTypesEnum } from '../enums/ClaimValues.enum';
import moment from 'moment-timezone';
import EventsTimeline from '../../../components/EventsTimeline';

// import { Container } from './styles';
interface IDetailModal {
  claim: ClaimEstate;
  open: boolean;
  close: () => void;
}

const LabelColumn = styled(Grid.Column)`
  padding: 0em 0em !important;
`

const LabelText = styled.p`
  font-weight: 500;
  color: #364445;
  font-size: 20px;
  line-height: 5px;
  margin-left: 5px;
`

interface ISectionLabel {
  text: string;
}

const SectionLabel = (props: ISectionLabel) => {
  return (
    <Grid.Row style={{
      paddingTop: '0',
      paddingBottom: '0',
      marginTop: '2em'
    }}>
      <LabelColumn>
        <LabelText>{props.text}</LabelText>
        <Divider />
      </LabelColumn>
    </Grid.Row>
  );
}

const DetailModal = (props: IDetailModal) => {
  const { claim, open, close } = props;
  const { id, createdAt, updatedAt, createdBy, responsible, estate, insurer, protocolNumber, policyNumber, status, observations, claimProperty, claimTenant, claimValues, claimCode, processNumber  } = claim
  const [sumValues, setSumValues] = useState<number>(0)
  const address = `${claimProperty?.street}${claimProperty?.number ? `, ${claimProperty?.number}` : ''}${claimProperty?.complement ? ` - ${claimProperty?.complement}` : ''}`
  const [isEventsTimelineOpen, setIsEventsTimelineOpen] = useState<boolean>(false)
  useEffect(() => {
    const typeValues = typeof claimValues
    const isArray = Array.isArray(claimValues)
    if (open)
      console.log({claim, typeValues, isArray})

    if (isArray) {
      setSumValues(sumInArray(claimValues, 'value'))
    }
  }, [claim, open])

  function sumInArray(array: any[], prop: string): number {
    return array.reduce((a, b) => a + Number(b[prop]), 0)
  }

  const toggleEventsTimeline = () => {
    setIsEventsTimelineOpen(!isEventsTimelineOpen)
  }

  return (
    <>
    <Modal
      open={open}
      onClose={close}
    >
      <Modal.Header
        style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'
        }}
      >
        <span>Detalhes da Expectativa de Sinistro</span>
        <Button
          icon
          circular
          onClick={toggleEventsTimeline}
          style={{ float: 'right', cursor: 'pointer' }}
        >
          <Icon name='calendar alternate outline' />
        </Button>
      </Modal.Header>
      <Modal.Content>
        <Grid style={{padding: 5}}>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>ID do Sinistro</FieldLabel>
              <FieldValue>{id}</FieldValue>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Data de Solicitação</FieldLabel>
              <FieldValue>{moment(createdAt).subtract(3, 'h').format('DD/MM/YYYY HH:mm:ss')}</FieldValue>
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Última atualização</FieldLabel>
              <FieldValue>
                {
                  updatedAt ?
                  moment(updatedAt).subtract(3, 'h').format('DD/MM/YYYY HH:mm:ss') :
                  (<span style={{color: '#ccc'}}>Não houve atualização</span>)
                }
              </FieldValue>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Criado Por</FieldLabel>
              <FieldValue>{createdBy?.name}</FieldValue>
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Responsável</FieldLabel>
              <FieldValue>{responsible ? responsible?.name : (<span style={{color: '#ccc'}}>Não atribuído</span>)}</FieldValue>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Imobiliária</FieldLabel>
              <FieldValue>{estate?.name}</FieldValue>
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Seguradora</FieldLabel>
              <FieldValue>{insurer ? insurer?.name : (<span style={{color: '#ccc'}}>Não atribuído</span>)}</FieldValue>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Nº do Protocolo</FieldLabel>
              <FieldValue>{protocolNumber}</FieldValue>
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Nº da Apólice</FieldLabel>
              <FieldValue>{policyNumber || (<span style={{color: '#ccc'}}>Não atribuído</span>)}</FieldValue>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Código do Sinistro</FieldLabel>
              <FieldValue>{claimCode || (<span style={{color: '#ccc'}}>Não atribuído</span>)}</FieldValue>
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Nº do Processo</FieldLabel>
              <FieldValue>{processNumber || (<span style={{color: '#ccc'}}>Não atribuído</span>)}</FieldValue>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Status</FieldLabel>
              <FieldValue>{translateStatus(status)}</FieldValue>
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Observações</FieldLabel>
              <FieldValue>{observations}</FieldValue>
            </Grid.Column>
          </Grid.Row>

          <SectionLabel text="Dados do Imóvel" />
          <Grid.Row >
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>ID do Imóvel</FieldLabel>
              <FieldValue>{claimProperty?.id}</FieldValue>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Data de Criação</FieldLabel>
              <FieldValue>
                {
                  claimProperty?.createdAt ?
                  moment(claimProperty.createdAt).subtract(3, 'h').format('DD/MM/YYYY HH:mm:ss') :
                  (<span style={{color: '#ccc'}}>Não Criado</span>)
                }
              </FieldValue>
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Última atualização</FieldLabel>
              <FieldValue>
                {
                  claimProperty?.updatedAt ?
                  moment(claimProperty.updatedAt).subtract(3, 'h').format('DD/MM/YYYY HH:mm:ss') :
                  (<span style={{color: '#ccc'}}>Não houve atualização</span>)
                }
              </FieldValue>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Endereço</FieldLabel>
              <FieldValue>{address}</FieldValue>
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Bairro</FieldLabel>
              <FieldValue>{claimProperty?.district}</FieldValue>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Cidade</FieldLabel>
              <FieldValue>{claimProperty?.city}</FieldValue>
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Estado</FieldLabel>
              <FieldValue>{claimProperty?.state}</FieldValue>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>CEP</FieldLabel>
              <FieldValue>{claimProperty?.zipCode}</FieldValue>
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Situação</FieldLabel>
              <FieldValue>
                {translatePropertyStatus(claimProperty?.status)} <Icon name={'circle'} color={claimProperty?.status === 'OCCUPIED' ? 'red' : 'green'} />
              </FieldValue>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Data de Entrega das Chaves</FieldLabel>
              <FieldValue>
                {
                claimProperty?.deliveryKeysDate ?
                  new Date(claimProperty.deliveryKeysDate).toLocaleDateString('pt-BR')
                  :
                  (<span style={{color: '#ccc'}}>Não entregue / Não informado</span>)
                }
                </FieldValue>
            </Grid.Column>
          </Grid.Row>


          <SectionLabel text="Dados do Inquilino" />
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>ID do Inquilino</FieldLabel>
              <FieldValue>{claimTenant?.id}</FieldValue>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Data de Criação</FieldLabel>
              <FieldValue>
                {
                  claimTenant?.createdAt ?
                  moment(claimTenant.createdAt).subtract(3, 'h').format('DD/MM/YYYY HH:mm:ss') :
                  (<span style={{color: '#ccc'}}>Não Criado</span>)
                }
              </FieldValue>
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Última atualização</FieldLabel>
              <FieldValue>
                {
                  claimTenant?.updatedAt ?
                  moment(claimTenant.updatedAt).subtract(3, 'h').format('DD/MM/YYYY HH:mm:ss') :
                  (<span style={{color: '#ccc'}}>Não houve atualização</span>)
                }
              </FieldValue>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Nome</FieldLabel>
              <FieldValue>{claimTenant?.fullName}</FieldValue>
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Documento</FieldLabel>
              <FieldValue>{maskCpfOrCnpj(claimTenant?.document)}</FieldValue>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Telefone</FieldLabel>
              <FieldValue>{claimTenant?.cellphone ? maskPhoneNumber(claimTenant?.cellphone) : (<span style={{color: '#ccc'}}>Não informado</span>)}</FieldValue>
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel style={{ marginLeft: '5px' }}>Email</FieldLabel>
              <FieldValue>{claimTenant?.email}</FieldValue>
            </Grid.Column>
          </Grid.Row>

          <SectionLabel text="Valores Informados" />
          {
            sumValues > 0 && (
              // <span style={{fontSize: 24, fontWeight: 500}}>Total <strong>{sumValues.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })}</strong> <Icon name={''} color={'green'} /></span>
              <Button as='div' labelPosition='right' style={{cursor: 'default', pointerEvents: 'none', marginLeft: 15}}>
                <Button color={'green'}>
                  Total
                </Button>
                <Label as='a' basic color='green' pointing='left'>
                  {sumValues.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })}
                  {/* <Icon name='money' color={'green'} style={{marginLeft: 5}} /> */}
                </Label>
              </Button>
            )
          }
          {
            Array.isArray(claimValues) && claimValues.length > 0 ? claimValues.map(item => {
              const { createdAt, dueDate, id, numberInstallment, totalInstallments, updatedAt, value, valueType } = item

              return (
                <Grid.Column key={id!} width={16}>
                  <Segment style={{backgroundColor: '#fafafa', borderWidth: 4}}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <FieldLabel style={{ marginLeft: '5px' }}>ID do Valor</FieldLabel>
                          <FieldValue>{id}</FieldValue>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <FieldLabel style={{ marginLeft: '5px' }}>Data de Criação</FieldLabel>
                          <FieldValue>
                            {
                              createdAt ?
                              moment(createdAt).subtract(3, 'h').format('DD/MM/YYYY HH:mm:ss') :
                              (<span style={{color: '#ccc'}}>Não Criado</span>)
                            }
                          </FieldValue>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <FieldLabel style={{ marginLeft: '5px' }}>Última atualização</FieldLabel>
                          <FieldValue>
                            {
                              updatedAt ?
                              moment(updatedAt).subtract(3, 'h').format('DD/MM/YYYY HH:mm:ss') :
                              (<span style={{color: '#ccc'}}>Não houve atualização</span>)
                            }
                          </FieldValue>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <FieldLabel style={{ marginLeft: '5px' }}>Valor</FieldLabel>
                          <FieldValue>{Number(Number(value).toFixed(2)).toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })}</FieldValue>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <FieldLabel style={{ marginLeft: '5px' }}>Data de Vencimento</FieldLabel>
                          <FieldValue>{dueDate ? new Date(dueDate).toLocaleDateString('pt-BR') : (<span style={{color: '#ccc'}}>Não informado</span>)}</FieldValue>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <FieldLabel style={{ marginLeft: '5px' }}>Tipo de Valor</FieldLabel>
                          <FieldValue>{translateValuesType(valueType)}</FieldValue>
                        </Grid.Column>
                        {
                          valueType === ClaimValuesTypesEnum.taxes_value && (
                            <Grid.Column width={8}>
                              <FieldLabel style={{ marginLeft: '5px' }}>Parcela</FieldLabel>
                              <FieldValue>{numberInstallment} de {totalInstallments}</FieldValue>
                            </Grid.Column>
                          )
                        }
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </Grid.Column>
              )
            }) : (
              <Grid.Row>
                <span style={{color: '#ccc', marginLeft: 5}}>Não há valores informados</span>
              </Grid.Row>
            )
          }

          {/* TODO: HIRED DATA */}
          {/* <SectionLabel text="Seguro Fiança - Apólice" />
          {
            bondInsuranceHiredData ? (
              <>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>ID da Apólice</FieldLabel>
                    <FieldValue>{bondInsuranceHiredData.id}</FieldValue>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                        <Grid.Column width={8}>
                          <FieldLabel style={{ marginLeft: '5px' }}>Data de Criação</FieldLabel>
                          <FieldValue>
                            {
                              bondInsuranceHiredData.createdAt ?
                              `${new Date(bondInsuranceHiredData.createdAt).toLocaleDateString('pt-BR')} ${new Date(bondInsuranceHiredData.createdAt).toLocaleTimeString('pt-BR')}` :
                              (<span style={{color: '#ccc'}}>Não Criado</span>)
                            }
                          </FieldValue>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <FieldLabel style={{ marginLeft: '5px' }}>Última atualização</FieldLabel>
                          <FieldValue>
                            {
                              bondInsuranceHiredData.updatedAt ?
                              `${new Date(bondInsuranceHiredData.updatedAt).toLocaleDateString('pt-BR')} ${new Date(bondInsuranceHiredData.updatedAt).toLocaleTimeString('pt-BR')}` :
                              (<span style={{color: '#ccc'}}>Não houve atualização</span>)
                            }
                          </FieldValue>
                        </Grid.Column>
                      </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>Status</FieldLabel>
                    <FieldValue>{translateBondInsuranceProposalStatus(bondInsuranceHiredData.status)}</FieldValue>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>Metódo de Pagamento</FieldLabel>
                    <FieldValue>{bondInsuranceHiredData.paymentWay}</FieldValue>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>Quantidade de Parcelas</FieldLabel>
                    <FieldValue>{bondInsuranceHiredData.installmentsAmount}</FieldValue>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>Total do Prêmio</FieldLabel>
                    <FieldValue>{Number(bondInsuranceHiredData.totalPremiumAmount).toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })}</FieldValue>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>Parcelas Contratadas</FieldLabel>
                    <FieldValue>{Math.round(Number(bondInsuranceHiredData.hiredInstallments))}</FieldValue>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>Valor das Parcelas</FieldLabel>
                    <FieldValue>{Number(bondInsuranceHiredData.hiredInstallmentsValue).toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })}</FieldValue>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>Total IOF</FieldLabel>
                    <FieldValue>{Number(bondInsuranceHiredData.totalIofAmount).toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })}</FieldValue>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>Valor Adicional</FieldLabel>
                    <FieldValue>{Number(bondInsuranceHiredData.additionalAmount).toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })}</FieldValue>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              <span style={{color: '#ccc', marginLeft: 5}}>Nenhuma apólice foi vinculada</span>
            )
          } */}

        </Grid>
        {/* MOSTRAR JSON */}
        {/* <hr style={{marginTop: 100}}/>
        <pre style={{overflowX: 'auto'}}>{JSON.stringify(claim, null, 2)}</pre> */}
      </Modal.Content>
    </Modal>
    <EventsTimeline open={isEventsTimelineOpen} setOpen={setIsEventsTimelineOpen} relatedId={id!} />
    </>
  )

}

export default DetailModal;
