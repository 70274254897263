import React, { useEffect, useState } from 'react';
import { Grid, Dropdown } from 'semantic-ui-react';
import { Field, FormikProps } from 'formik';
import SectionLabel from '../../../../../../../components/SectionLabel';
import { SyntheticEvent } from 'react-router/node_modules/@types/react';
import { CancellationInitialValues } from '../../Formik/initialStatesCancel';
import FormatedTextArea from '../../../../../../../components/FormatedTextArea';
import { ConfirmCancellationInsuranceProps } from '../../../types/ConfirmCancelModalProps';
import { FieldLabel } from '../../../../../../../styles';
import styled from 'styled-components';
import { maskCpfOrCnpj } from '../../../../../../../services/masks';
import moment from 'moment';
import { warningNotification } from '../../../../../../../services/notification';
import { UserRolesEnum } from '../../../../../../../enums/user-roles.enum';
import { CanceledStatusEnum } from '../../../../../../../enums/status-canceled-bond-insurances';
import { get } from '../../../../../../../services/storage';

const InfoContainer = styled.div`
  width: 100%;

  background-color: #f7f0eb;
  padding: 1rem;
  border-radius: 2px;

  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.35rem;

    > div {
      flex: 1;
    }
  }
`;

const availableStatus = [
  {
    key: 0,
    value: CanceledStatusEnum.CANCELLATION_IN_PROGRESS,
    text: 'Em análise'
  },
  {
    key: 1,
    value: CanceledStatusEnum.PENDING,
    text: 'Pendente'
  },
  {
    key: 2,
    value: CanceledStatusEnum.CANCELED,
    text: 'Concluído'
  },
  {
    key: 3,
    value: CanceledStatusEnum.PENDING_BY_INSURER,
    text: 'Solicitado para Seguradora'
  },
  {
    key: 4,
    value: CanceledStatusEnum.FINANCIAL_PENDING,
    text: 'Pendente Financeiro'
  },
  {
    key: 5,
    value: CanceledStatusEnum.CLAIM_CANCELLATION,
    text: 'Cancelamento Sinistro'
  },
  {
    key: 6,
    value: CanceledStatusEnum.UNCANCELLED,
    text: 'Não concluído'
  }
];

const ChangingStatusForm = ({
  callbackSubmit,
  setFlagSubmit,
  setFieldValue,
  flagSubmit,
  canceledId,
  values,
  actionText,
  canceled
}: FormikProps<typeof CancellationInitialValues> & ConfirmCancellationInsuranceProps) => {
  const userId = get('uid');
  const userRole = get('role');
  const [selectedStatus, setSelectedStatus] = useState<any>();

  useEffect(() => {
    if (canceled) {
      availableStatus.forEach(option => {
        if (canceled.status === option.text) {
          setSelectedStatus(option.value);
        }
      });
    }
  }, [canceled]);

  useEffect(() => {
    function submitForm() {
      const error = false;
      setFlagSubmit && setFlagSubmit(false);
      if (error) {
        return;
      }
      const payload = {
        canceledId,
        userId: userId,
        observations: values.observations,
      };
      callbackSubmit(payload, selectedStatus);
    }
    flagSubmit && submitForm();
  }, [
    callbackSubmit,
    canceledId,
    flagSubmit,
    selectedStatus,
    setFlagSubmit,
    userId,
    values.observations,
  ]);

  const handleNewChangeStatus = (statusKey: any) => {
    if (!!statusKey.length) {
      setFieldValue('status', statusKey);
      setSelectedStatus(statusKey);
    } else {
      setFieldValue('status', null);
      setSelectedStatus(null);
    }
  };

  const property = canceled.bondInsurance.property;
  const address =
    property.street +
    ', ' +
    property.number +
    ' - ' +
    property.city +
    ' - ' +
    property.state;

  const atualizedDate = canceled.updatedAt
    ? moment(canceled.updatedAt).format('DD/MM/YYYY HH:mm')
    : 'Ainda não atualizado';

  console.log(canceled);

  return (
    <div>
      <Grid columns="equal">
        <Grid.Row>
          <InfoContainer>
            <div>
              <div>
                <strong>Inquilino: </strong>
                <span>{canceled.bondInsurance.tenant.name}</span>
              </div>
              <div>
                <strong>Documento: </strong>
                <span>{maskCpfOrCnpj(canceled.bondInsurance.tenant.document)}</span>
              </div>
            </div>
            <div>
              <div>
                <strong>Endereço do Imóvel: </strong>
                <span>{address}</span>
              </div>
              <div>
                <strong>Imobiliária: </strong>
                <span>{canceled.bondInsurance.estate.name}</span>
              </div>
            </div>
            <div>
              <div>
                <strong>Status Atual: </strong>
                <span>{canceled.status}</span>
              </div>
              <div>
                <strong>Última atualização: </strong>
                <span>{atualizedDate}</span>
              </div>
            </div>
          </InfoContainer>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>
            <SectionLabel text={actionText} />
            <FieldLabel>Status:</FieldLabel>
            <Dropdown
              style={{ width: '100%' }}
              value={selectedStatus}
              name={`status`}
              options={availableStatus}
              placeholder={'Selecionar...'}
              search
              selection
              onChange={(e, { value }) => {
                if (value === CanceledStatusEnum.UNCANCELLED && userRole !== UserRolesEnum.brokerAdmin) {
                  warningNotification('Atenção', 'Você não tem permissão para isso!');
                  return;
                }
                handleNewChangeStatus(value);
              }}
              clearable
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <SectionLabel text="Observações *" />
            <Field
              name={`bondInsurance.observations`}
              rows={8}
              value={values.observations}
              onChange={(_: SyntheticEvent, data: any) => {
                setFieldValue(`observations`, data.value);
              }}
              component={FormatedTextArea}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
export default ChangingStatusForm;
