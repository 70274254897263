import React, { SyntheticEvent, useState, useEffect } from 'react';
import { Field } from 'formik';
import styled from 'styled-components';
import { iRootDispatch, iRootState } from '../../store';
import { connect } from 'react-redux';
import { Grid, Dropdown as DropdownNativo } from 'semantic-ui-react';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { FilterData } from './types';
import BrokersDropdown from '../../components/BrokersDropdown';
import IconButton from '../../components/IconButton';
import AppMaskedInput from '../../components/MaskedInput';
import CustomDatePicker from '../../components/CustomDatePicker';
import { FieldLabel } from '../../styles';
import { UserRolesEnum } from '../../enums/user-roles.enum';
import {
  sortBondInsurances,
  getBondInsuranceByID,
  getBondInsurances
} from '../../services/bond-insurance';
import ExpiredBondInsurancesTable from './ExpiredBondInsurancesTable';
import { CustoDropDownItemProps } from '../../components/types';
import { BondInsuranceListProps } from './ExpiredBondInsurancesForm';
import { dangerNotification } from '../../services/notification';
import { AvailableBrokersAndEstatesState } from '../../store/types/temp-types';
import { BondInsuranceForGetBondInsurancesResponseDTO } from '../../dtos/bond-insurance-list/get-bond-insurances-response.dto';
import 'moment/locale/pt-br';
import { GetExpiredBondInsurancesResponseDTO } from '../../dtos/expired-bond-insurance/get-expired-bond-insurances-response.dto';
import { useLocation } from 'react-router-dom';

const AdvancedSearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  font-weight: bold;
`;

interface LocationState {
  bondInsuranceId?: string;
}

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

const ExpiredBondInsurances = (props: BondInsuranceListProps) => {
  const { id } = props.match.params;
  const location = useLocation();
  const bondInsuranceIdInitialValue = (location.state as LocationState)?.bondInsuranceId;
  const [bondInsuranceId, setBondInsuranceId] = useState(bondInsuranceIdInitialValue);
  const { availableBrokersAndEstates, user, setFieldValue, values } = props;
  const brokers: CustoDropDownItemProps[] = availableBrokersAndEstates.brokers;
  const estates: CustoDropDownItemProps[] = availableBrokersAndEstates.estates;
  const [bondInsurances, setBondInsurances] = useState<
    BondInsuranceForGetBondInsurancesResponseDTO[]
  >([]);
  const [expiredBondInsurances, setExpiredBondInsurances] = useState<
    GetExpiredBondInsurancesResponseDTO[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [fieldValues, setFieldValues] = useState<FilterData>({});
  const [ascendingOrder, setAscendingOrder] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataCount, setDataCount] = useState<number>(0);
  const [datesRange, setDatesRange] = useState('');
  const [startDate, setStartDate] = useState<Date | string>('');
  const [endDate, setEndDate] = useState<Date | string>('');
  const [selectedEstate, setSelectedEstate] = useState<number>();

  useEffect(() => {
    if (startDate && typeof startDate !== 'string') {
      const newDate = startDate.toISOString().substring(0, 10);

      setFieldValues({ ...fieldValues, start: newDate });
    } else {
      setFieldValues({ ...fieldValues, start: '' });
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate && typeof endDate !== 'string') {
      const newDate = endDate.toISOString().substring(0, 10);
      setFieldValues({ ...fieldValues, end: newDate });
    } else {
      setFieldValues({ ...fieldValues, end: '' });
    }
  }, [endDate]);

  useEffect(() => {
    const splitDate = datesRange.split(' - ');
    if (splitDate.length === 2) {
      const rawStart = splitDate[0];
      const rawEnd = splitDate[1];
      const start = rawStart.split('-').reverse().join('-');
      const end = rawEnd.split('-').reverse().join('-');
      setFieldValue('start', start);
      setFieldValue('end', end);
    }
  }, [datesRange]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFieldValues({ ...fieldValues, [name]: value });
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      let filter = {} as FilterData;
      if (estates.length === 1) {
        filter.estate_id = estates[0].value.id;
      }
      if (brokers.length === 1) {
        filter.broker_id = brokers[0].value.id;
        setFieldValue('broker_id', filter.broker_id);
      }
      if (bondInsuranceId) {
        filter.bondInsuranceId = bondInsuranceId;
      }

      Object.keys(fieldValues).forEach((key: string) => {
        switch (key) {
          case 'name':
            filter.name = fieldValues.name ? fieldValues.name : '';
            break;
          case 'status':
            filter.status = fieldValues.status ? fieldValues.status : '';
            break;
          case 'document':
            filter.document = fieldValues.document
              ? fieldValues.document
              : '';
            break;
          case 'address':
            filter.address = fieldValues.address ? fieldValues.address : '';
            break;
          case 'start':
            filter.start = fieldValues.start ? fieldValues.start : undefined;
            break;
          case 'end':
            filter.end = fieldValues.end ? fieldValues.end : undefined;
            break;
          case 'estate_id':
            filter.estate_id = fieldValues.estate_id ? fieldValues.estate_id : undefined;
            break;
        }
      });

      let data: BondInsuranceForGetBondInsurancesResponseDTO[] = [];

      if (id) {
        const bondInsurance = await getBondInsuranceByID(id);
        if (bondInsurance) {
          data.push(bondInsurance);
          setDataCount(data.length);
          setBondInsurances(data);
        }
      } else {
        if (
          user.role === UserRolesEnum.brokerAnalyst ||
          user.role === UserRolesEnum.brokerAdmin ||
          user.role === UserRolesEnum.accountManager
        ) {
          filter = { ...filter, broker_id: (user as any).broker.id, status: 'EXPIRED' };
        } else {
          filter = { ...filter, status: 'EXPIRED' };
        }
        if (filter.broker_id) {
          const response = await getBondInsurances(currentPage, filter);
          data = sortBondInsurances(response.data, ascendingOrder);

          if(currentPage === 1) setDataCount(response.count);
          setBondInsurances(data);
        }
      }
      setBondInsuranceId(undefined);
      setIsLoading(false);
    } catch (e) {
      console.error({ e });
      dangerNotification('Oops...', 'Não foi possível buscar as análises');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.history.replaceState(
      {
        ...(location.state as any),
        bondInsuranceId: undefined
      },
      ''
    );
  }, [location]);

  const clearInputs = () => {
    setFieldValues({
      name: '',
      address: '',
      status: '',
      document: '',
      start: '',
      end: '',
      estate_id: ''
    });
    if (availableBrokersAndEstates?.estates?.length === 1) {
      setSelectedEstate(availableBrokersAndEstates?.estates[0]?.value?.id);
      setFieldValues(prevValue => ({
        ...prevValue,
        estate_id: availableBrokersAndEstates?.estates[0]?.value?.id
      }));
    } else {
      setSelectedEstate(0);
    }
    setStartDate('');
    setEndDate('');
    setBondInsuranceId(undefined);
  };

  useEffect(() => {
    getData();
  }, [currentPage]);

  const handleNewChangeEstate = (estateKey: any) => {
    const selected = estates.find(c => c.value.id === estateKey.id);
    if (!selected) {
      setFieldValues(prevValue => ({
        ...prevValue,
        estate_id: ''
      }));
      setSelectedEstate(0);
      return;
    }
    setSelectedEstate(estateKey);
    setFieldValues(prevValue => ({
      ...prevValue,
      estate_id: selected.value.id
    }));
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    console.log('HandleSubmit');
    e.preventDefault();
    setCurrentPage(1);
    getData();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid>
          <Grid.Row verticalAlign={'middle'}>
            <Grid.Column width={6}>
              <FieldLabel>CORRETORA:</FieldLabel>
              <Field
                name="broker"
                completeValue={true}
                searchable={false}
                onChange={(e: SyntheticEvent, data: any) => {
                  setFieldValue('broker_id', data.value.id);
                }}
                disabled={isLoading}
                component={BrokersDropdown}
                fluid
              />
            </Grid.Column>
            <Grid.Column width={7}>
              <FieldLabel>IMOBILIÁRIA:</FieldLabel>
              <div style={{ width: '100%' }}>
                <DropdownNativo
                  style={{ width: '100%' }}
                  value={selectedEstate}
                  name={`estate`}
                  defaultValue={
                    availableBrokersAndEstates.estates.length === 1
                      ? availableBrokersAndEstates.estates[0].value
                      : '' || ''
                  }
                  disabled={isLoading || availableBrokersAndEstates.estates.length === 1}
                  options={availableBrokersAndEstates.estates || []}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  onChange={(e, { value }) => handleNewChangeEstate(value)}
                  clearable
                />
              </div>
            </Grid.Column>
            <Grid.Column width={3}>
              <AdvancedSearchWrapper>
                <label>Pesquisa Avançada</label>
                <IconButton
                  name={isExpanded ? 'chevron down' : 'chevron up'}
                  color="blue"
                  onClick={(e: SyntheticEvent) => {
                    setIsExpanded(!isExpanded);
                  }}
                />
              </AdvancedSearchWrapper>
            </Grid.Column>
          </Grid.Row>
          {isExpanded && (
            <Grid.Row verticalAlign={'middle'}>
              <Grid.Column width={2}>
                <FieldLabel>Data Inicial:</FieldLabel>
                <Field
                  name={`start`}
                  selected={startDate ? startDate : ''}
                  onChange={(date: Date, e: SyntheticEvent) => {
                    setStartDate(date);
                  }}
                  disabled={isLoading}
                  component={CustomDatePicker}
                  customInput={
                    <AppMaskedInput
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/
                      ]}
                    />
                  }
                  dateFormat={'dd/MM/yyyy'}
                />
              </Grid.Column>

              <Grid.Column width={2}>
                <FieldLabel>Data Final:</FieldLabel>
                <Field
                  name={`end`}
                  selected={endDate ? endDate : ''}
                  disabled={isLoading}
                  onChange={(date: Date, e: SyntheticEvent) => {
                    setEndDate(date);
                  }}
                  component={CustomDatePicker}
                  customInput={
                    <AppMaskedInput
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/
                      ]}
                    />
                  }
                  dateFormat={'dd/MM/yyyy'}
                />
              </Grid.Column>

              <Grid.Column width={3}>
                <FieldLabel>Nome:</FieldLabel>
                <Input
                  fluid
                  name="name"
                  onChange={handleInputChange}
                  disabled={isLoading}
                  value={fieldValues.name}
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <FieldLabel>CPF/CNPJ:</FieldLabel>
                <Input
                  name="document"
                  onChange={handleInputChange}
                  disabled={isLoading}
                  value={fieldValues.document}
                  fluid
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <FieldLabel>Endereço:</FieldLabel>
                <Input
                  name="address"
                  onChange={handleInputChange}
                  disabled={isLoading}
                  value={fieldValues.address}
                  fluid
                />
              </Grid.Column>
              <Grid.Column width={1}></Grid.Column>
              <Grid.Column width={2} textAlign={'center'}>
                <Button
                  fluid
                  type={'submit'}
                  size={'mini'}
                  color="blue"
                  text="Pesquisar"
                  style={{ margin: '0.5em 0em' }}
                  onClick={handleSubmit}
                />
                <Button
                  basic
                  fluid
                  type={'button'}
                  size={'mini'}
                  disabled={isLoading}
                  text="Limpar"
                  onClick={clearInputs}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </form>
      <ExpiredBondInsurancesTable
        bondInsurances={bondInsurances}
        expiredBondInsurances={expiredBondInsurances}
        isLoading={isLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        getData={() => getData()}
        dataCount={dataCount}
      />
    </>
  );
};

export default connect(mapState, mapDispatch)(ExpiredBondInsurances);
