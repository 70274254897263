import { get } from '../services/request';

export class Address {
  idAddress?: number;
  idCity?: number;
  idState?: number;
  zipCode: string;
  street: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  state: string;

  constructor(zipCode: string,
    street: string,
    number: string,
    complement: string,
    district: string,
    city: string,
    state: string) {
    this.zipCode = zipCode;
    this.street = street;
    this.number = number;
    this.complement = complement;
    this.district = district;
    this.city = city;
    this.state = state;
  }

  public description(): string {
    let description = `${this.street} ${this.number}`;
    description += this.number ? ` ${this.number}` : '';
    description += this.complement ? `, ${this.complement}` : '';
    description += this.district ? `. ${this.district}` : '';
    description += this.city ? ` - ${this.city}` : '';
    return description;
  }
}

export class AddressService {
  static async getAddressByZipCode(zipCode: string, userId?: string): Promise<Address> {
    let request: Promise<any>;

    if (userId) {
      request = get(`/address/${zipCode}?user_id=${userId}`);
    } else {
      request = get(`/address/${zipCode}`);
    }

    try {
      const response = await request;
      if (!response.zip_code) {
        throw (`CEP ${zipCode} não é válido!`);
      }

      return response;
    } catch (e) {
      throw e;
    }
  }
}
