import * as Yup from "yup";
import {GREATER_THAN_ZERO_MESSAGE, LESS_THAN_OR_EQUAL_TO_MESSAGE} from "../../constants";

export const connectorCoverageOptionYupSchema: Yup.ObjectSchema<ConnectorCoverageSchema> = Yup.object().shape({
  checked: Yup.boolean(),
  maximumInsuredAmount: Yup.number(),
  insuredAmount: Yup.number().when('checked', {
    is: true,
    then: Yup.number().moreThan(0, GREATER_THAN_ZERO_MESSAGE).max(Yup.ref('maximumInsuredAmount'), LESS_THAN_OR_EQUAL_TO_MESSAGE),
    otherwise: Yup.number()
  })
})

export type ConnectorCoverageSchema = {
  checked: boolean;
  insuredAmount: number;
  maximumInsuredAmount: number;
}
