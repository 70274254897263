import { FireInsuranceCancelReasonEnum } from "../../../enums/fire-insurance-cancel-reason.enum";

type fileTypeProps = {
  name: string;
  key: string;
  required?: boolean;
};

// ******* Translations *********//
export function translateStatus(status: string) {
  switch (status) {
    case 'WAITING':
      return 'Em espera';
    case 'CREATING':
      return 'Em espera';
    case 'UNDERANALYSIS':
      return 'Em análise';
    case 'DENIED':
      return 'Recusado';
    case 'APPROVED':
      return 'Aguardando Contratação';
    case 'ERROR':
      return 'Erro';
    case 'CANCELATION_REQUEST':
      return 'Cancelamento Solicitado';
    case 'CANCELED':
      return 'Cancelado';
    case '':
      return '(Indisponível)'
    default:
      return status;
  }
}

export function translateProposalStatus(status: string) {
  switch (status) {
    case 'POLICY_CREATED':
      return 'Apólice vigente';
    case 'PROPOSAL_WAITING':
      return 'Em Espera';
    case 'POLICY_CREATING':
      return 'Aguardando Apólice';
    case 'CANCELATION_REQUEST':
      return 'Cancelamento Solicitado';
    case 'POLICY_AWAITING_BILLING':
      return 'Apólice a faturar';
    case 'CANCELED':
      return 'Apólice Cancelada';
    case 'POLICY_EXPIRED':
      return 'Apólice expirada';
    case 'POLICY_ACTIVE':
      return 'Apólice vigente';
    case 'ERROR':
      return 'Erro';
    default:
      return status
  }
}

export enum StatusEnum {
  Waiting = 'WAITING',
  Creating = 'CREATING',
  UnderAnalysis = 'UNDERANALYSIS',
  Denied = 'DENIED',
  Approved = 'APPROVED',
  Error = 'ERROR',
}

export function translatePropertyType(propertyType: string) {
  switch (propertyType) {
    case 'Apartment':
      return 'Apartamento';
    case 'CondominiumApartment':
      return 'Apartamentos em condomínios';
    case 'House':
      return 'Casas';
    case 'CondominiumHouse':
      return 'Casas em condomínios';
    case 'CountrySideHouse':
      return 'Casas no campo';
    default:
      return propertyType
  }
}

export function translatePropertyUseType(propertyUseType: string) {
  switch (propertyUseType) {
    case 'Usual':
      return 'Habitual';
    case 'Summer':
      return 'Veraneio';
    case 'Vacate':
      return 'Desocupada';
    default:
      return propertyUseType
  }
}

export function translateConstructionType(constructionType: string) {
  switch (constructionType) {
    case 'Brick':
      return 'Alvenaria';
    case 'Wood':
      return 'Madeira';
    case 'WoodAndBrick':
      return 'Madeira e alvenaria';
    default:
      return constructionType
  }
}

export function translateCoverageType(coverageType: string) {
  switch (coverageType) {
    case 'basica':
      return 'Incêndio, Queda de Raio, Queda de Aeronave, Fumaça, Explosão de Qualquer Natureza e Implosões'
    case 'danos-eletricos':
      return 'Danos elétricos'
    case 'impacto-veiculos':
      return 'Impacto de veículos terrestres'
    case 'perda-aluguel':
      return 'Perda ou pagamento de aluguel'
    case 'quebra-vidros':
      return 'Quebra de vidros e espelhos, mármore e granito'
    case 'ruptura':
      return 'Ruptura de tubulações'
    case 'roubo-furto':
      return 'Roubo e Furto qualificado de Bens'
    case 'rc-familiar':
      return 'Responsabilidade Civil Familiar'
    case 'vendaval':
      return 'Vendaval, furacão, ciclone, tornado, granizo e neve'
    default:
      return coverageType
  }
}

// ******* Dropdown Options *********//
export const propertyTypesList = [
  {
    key: 'propertyTypes[0]',
    value: 'Apartment',
    text: 'Apartamento'
  }, {
    key: 'propertyTypes[1]',
    value: 'CondominiumApartment',
    text: 'Apartamentos em condomínios'
  }, {
    key: 'propertyTypes[2]',
    value: 'House',
    text: 'Casas'
  }, {
    key: 'propertyTypes[3]',
    value: 'CondominiumHouse',
    text: 'Casas em condomínios'
  }, {
    key: 'propertyTypes[4]',
    value: 'CountrySideHouse',
    text: 'Casas no campo'
  }
]

export const propertyUseTypeList = [
  {
    key: 'propertyUseType[0]',
    value: 'Usual',
    text: 'Habitual'
  }, {
    key: 'propertyUseType[1]',
    value: 'Summer',
    text: 'Veraneio'
  }, {
    key: 'propertyUseType[2]',
    value: 'Vacate',
    text: 'Desocupada'
  }
]

export const constructionTypeList = [
  {
    key: 'constructionType[0]',
    value: 'Brick',
    text: 'Alvenaria'
  }, {
    key: 'constructionType[1]',
    value: 'Wood',
    text: 'Madeira'
  }, {
    key: 'constructionType[2]',
    value: 'WoodAndBrick',
    text: 'Madeira e alvenaria'
  }
]

export const coverageTypeList = [
  {
    key: 'coverageType[0]',
    value: 'basica',
    text: 'Incêndio, Queda de Raio, Queda de Aeronave, Fumaça, Explosão de Qualquer Natureza e Implosões'
  }, {
    key: 'coverageType[1]',
    value: 'danos-eletricos',
    text: 'Danos elétricos'
  }, {
    key: 'coverageType[2]',
    value: 'impacto-veiculos',
    text: 'Impacto de veículos terrestres'
  }, {
    key: 'coverageType[3]',
    value: 'perda-aluguel',
    text: 'Perda ou pagamento de aluguel'
  }, {
    key: 'coverageType[4]',
    value: 'quebra-vidros',
    text: 'Quebra de vidros e espelhos, mármore e granito'
  }, {
    key: 'coverageType[5]',
    value: 'ruptura',
    text: 'Ruptura de tubulações'
  }, {
    key: 'coverageType[6]',
    value: 'roubo-furto',
    text: 'Roubo e Furto qualificado de Bens'
  }, {
    key: 'coverageType[7]',
    value: 'rc-familiar',
    text: 'Responsabilidade Civil Familiar'
  }, {
    key: 'coverageType[8]',
    value: 'vendaval',
    text: 'Vendaval, furacão, ciclone, tornado, granizo e neve'
  }
]

export const historicalProtectedPropertyList = [
  {
    key: 'historicalProtectedProperty[0]',
    value: 'true',
    text: 'Sim'
  }, {
    key: 'historicalProtectedProperty[1]',
    value: 'false',
    text: 'Não'
  }
]

export const sharedPropertyList = [
  {
    key: 'sharedProperty[0]',
    value: 'true',
    text: 'Sim'
  }, {
    key: 'sharedProperty[1]',
    value: 'false',
    text: 'Não'
  }
]

export const insuredOwnerList = [
  {
    key: 'insuredOwner[0]',
    value: 'true',
    text: 'Sim'
  }, {
    key: 'insuredOwner[1]',
    value: 'false',
    text: 'Não'
  }
]

export const locationTypes = [
  {
    key: 'residential',
    value: 'RESIDENTIAL',
    text: 'Residencial'
  }, {
    key: 'commercial',
    value: 'COMMERCIAL',
    text: 'Comercial'
  }
]

export const statusList = [
  {
    key: 'statusList1',
    value: 'APPROVED',
    text: 'Aguardando Contratação'
  },
  {
    key: 'statusList5',
    value: 'CANCELED',
    text: 'Cancelado'
  },
  {
    key: 'statusList6',
    value: 'EXPIRED',
    text: 'Expirado',
  },
]

export const proposalStatusList = [
  {
    'key': 'proposalStatusList1',
    'value': 'POLICY_CREATING',
    'text': 'Aguardando apólice',
  },
  {
    'key': 'proposalStatusList2',
    'value': 'POLICY_AWAITING_BILLING',
    'text': 'Apólice a faturar',
  },
  {
    'key': 'proposalStatusList3',
    'value': 'CANCELED',
    'text': 'Apólice cancelada',
  },
  {
    'key': 'proposalStatusList4',
    'value': 'POLICY_EXPIRED',
    'text': 'Apólice expirada',
  },
  {
    'key': 'proposalStatusList5',
    'value': 'POLICY_CREATED',
    'text': 'Apólice vigente',
  },
  {
    'key': 'proposalStatusList6',
    'value': 'CANCELATION_REQUEST',
    'text': 'Cancelamento solicitado',
  },
  {
    'key': 'proposalStatusList7',
    'value': 'PROPOSAL_WAITING',
    'text': 'Em espera',
  },
  {
    'key': 'proposalStatusList8',
    'value': 'ERROR',
    'text': 'Erro',
  }
];

export const canceledStatusList = [
  {
    key: 'canceledStatusList1',
    value: 'CANCELED',
    text: 'Cancelado'
  },
  {
    key: 'canceledStatusList2',
    value: 'CANCELATION_REQUEST',
    text: 'Cancelamento Solcitado'
  }
]

export const fileIdentifiers: fileTypeProps[] = [
  {
    key: 'OTHER',
    name: 'Outros',
    required: false,
  }, {
    key: 'FIRE_INSURANCE_CANCEL_ENDORSEMENT',
    name: 'Endosso de cancelamento',
    required: false,
  }, {
    key: 'FIRE_INSURANCE_POLICY',
    name: 'Apólice',
    required: false,
  }, {
    key: 'FIRE_INSURANCE_PROPOSAL',
    name: 'Proposta',
    required: false,
  }, {
    key: 'FIRE_INSURANCE_POLICY_CANCELED',
    name: 'Apólice cancelada',
    required: false,
  }, {
    key: 'FIRE_INSURANCE_PROPOSAL_CANCELED',
    name: 'Proposta cancelada',
    required: false,
  }
]

export const cancellationReasonOptions = [
  { value: FireInsuranceCancelReasonEnum.leaseGaveUp, key: FireInsuranceCancelReasonEnum.leaseGaveUp, text: 'Desistência da locação' },
  { value: FireInsuranceCancelReasonEnum.endOfLease, key: FireInsuranceCancelReasonEnum.endOfLease, text: 'Término da locação' },
  { value: FireInsuranceCancelReasonEnum.insuranceChange, key: FireInsuranceCancelReasonEnum.insuranceChange, text: 'Alteração de seguro' },
  { value: FireInsuranceCancelReasonEnum.others, key: FireInsuranceCancelReasonEnum.others, text: 'Outros' },
];

export const ExpireInList = [
  {
    key: 'expireIn3Days',
    value: '3',
    text: '3 Dias'
  },
  {
    key: 'expireIn5Days',
    value: '5',
    text: '5 Dias'
  },
  {
    key: 'expireIn7Days',
    value: '7',
    text: '7 Dias'
  },
  {
    key: 'expireIn15Days',
    value: '15',
    text: '15 Dias'
  },
  {
    key: 'expireIn30Days',
    value: '30',
    text: '30 Dias'
  },
];

// ******* Enums *********//
export enum FireInsuranceAnalysisStatusEnum {
  Waiting = 'WAITING',
  Creating = 'CREATING',
  UnderAnalysis = 'UNDERANALYSIS',
  Denied = 'DENIED',
  Approved = 'APPROVED',
  Error = 'ERROR',
  Canceled = 'CANCELED',
}

export enum RisKObjectsTypeEnum {
  property = 'Property',
}

export enum PropertyTypeEnum {
  apartment = 'Apartment',
  condominiumApartment = 'CondominiumApartment',
  house = 'House',
  condominiumHouse = 'CondominiumHouse',
  countrySideHouse = 'CountrySideHouse'
}

export enum ConstructionTypeEnum {
  brick = 'Brick',
  wood = 'Wood',
  woodAndBrick = 'WoodAndBrick'
}

export enum PropertyUseTypeEnum {
  usual = 'Usual',
  summer = 'Summer',
  vacate = 'Vacate'
}

export enum PolicyTypeEnum {
  unique = 'Unique',
  itemByItem = 'ItemByItem'
}

export enum PhoneTypeEnum {
  personal = 'Personal',
  business = 'Business',
  billing = 'Billing',
  cellphone = 'CellPhone'
}

export enum CoverageKeysEnum {
  basica = 'basica',
  danosEletricos = 'danos-eletricos',
  impactoVeiculos = 'impacto-veiculos',
  perdaAluguel = 'perda-aluguel',
  quebraVidros = 'quebra-vidros',
  rcFamiliar = 'rc-familiar',
  rouboFurto = 'roubo-furto',
  ruptura = 'ruptura',
  vendaval = 'vendaval'
}
