import React from 'react';

import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../services/auth';
import { RouteProps } from 'react-router';

const ProtectedRoute = (routeProps: RouteProps) => {
  const Component: any = routeProps.component;
  const rest = { ...routeProps };

  delete rest.component;

  return (
    <Route
      {...rest}
      render={props => {
        return isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        );
      }}
    />
  );
};

export default ProtectedRoute;
