import { get } from '../services/request';
type BankList = {
  code: string;
  text: string;
  id: string;
};

export type BankOptions = {
  key: string;
  value: string;
  text: string;
};

export const listEstateBanks = async (): Promise<BankOptions[]> => {
  const bank: BankList[] = await get('/banks');

  return bank.map(bank => {
    return {
      key: bank.code,
      value: bank.code,
      text: bank.text
    };
  });
};

export const getEstateBankByCode = async (code: string): Promise<BankOptions> => {
  const bank: BankList = await get(`/banks/${code}`);
  return {
    key: bank.code,
    value: bank.code,
    text: bank.text
  };
};

