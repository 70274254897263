/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Dimmer, Grid, Loader, Modal, ModalProps } from "semantic-ui-react";
import { BondInsuranceMainInfoDetails } from "./DetailsComponents/BondInsuranceMainInfoDetails";
import { BondInsuranceProposalMainInfoDetails } from "./DetailsComponents/BondInsuranceProposalMainInfoDetails";
import { BondInsurancePolicyMainInfoDetails } from './DetailsComponents/BondInsurancePolicyMainInfoDetails';
import { BondInsurancePropertyDetails } from "./DetailsComponents/BondInsurancePropertyDetails";
import { BondInsuranceNaturalTenantDetails } from "./DetailsComponents/BondInsuranceNaturalTentantDetails";
import { BondInsuranceLegalTenantDetails } from "./DetailsComponents/BondInsuranceLegalTenantDetails";
import { BondInsuranceOwnerDetails } from './DetailsComponents/BondInsuranceOwnerDetails';
import { PersonTypesEnum } from "../../../enums/person-types.enum";
import { dangerNotification } from '../../../services/notification';
import { getRecord } from '../../../services/find-record';
import { AnalysisResponseDTO, BondInsuranceResponseDTO, HiredDataResponseDTO } from '../types/bondinsurance-interfaces';
import { FindRecordOriginEnum } from '../../../enums/find-record-origin.enum';
import { CanceledBondInsuranceMainInfoDetails } from './DetailsComponents/CanceledBondInsurancesMainInfoDetails';
import ClaimMainInfoDetails from './DetailsComponents/ClaimMainInfoDetails';

interface DataProps {
  bondInsurance: BondInsuranceResponseDTO;
  bondInsuranceAnalysis: AnalysisResponseDTO;
  bondInsuranceHiredData: HiredDataResponseDTO;
  canceledBondInsurance: any;
  claim: any;
}

export type Props = {
  recordId: string;
  origin: string;
  isOpen: boolean;
  onClose: React.ReactEventHandler<{}>;
  onOpen: React.ReactEventHandler<{}>;
} & ModalProps;

export const DetailModal = (props: Props) => {
  const { isOpen, onClose, onOpen, origin, recordId } = props;
  const [data, setData] = useState<DataProps | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  console.log('PROPS', props);

  const getData = () => {
    getRecord(recordId, origin).then((response) => {
      setData(response as DataProps);
    }).catch((e) => {
      console.log(e);
      dangerNotification('Ops...', 'Ocorreu algum erro ao tentar buscar o registro, tente novamente mais tarde.');
    }).finally(() => {
      setIsLoading(false)
    });
  }

  useEffect(() => {
    if (!!recordId.length) {
      getData();
    }
  }, [recordId]);

  return (
      <Modal open={isOpen} onOpen={onOpen} onClose={onClose} closeIcon>
        <Modal.Header>Resumo do registro em {origin}</Modal.Header>
        <Modal.Content scrolling>
          {isLoading ? (
            <Dimmer active={isLoading} inverted>
              <Loader content="Carregando..." />
            </Dimmer>
          ) : (
            <Grid>
              {data && <>
                {(data.bondInsuranceAnalysis && data.bondInsuranceHiredData && origin === FindRecordOriginEnum.policies) &&
                  <BondInsurancePolicyMainInfoDetails analysis={data.bondInsuranceAnalysis} hiredData={data.bondInsuranceHiredData} />
                }
                {(data.claim && origin === FindRecordOriginEnum.claims) &&
                  <ClaimMainInfoDetails claim={data.claim} />
                }
                {(data.bondInsuranceAnalysis && data.bondInsuranceHiredData && (origin === FindRecordOriginEnum.proposals || origin === FindRecordOriginEnum.policies)) &&
                  <BondInsuranceProposalMainInfoDetails analysis={data.bondInsuranceAnalysis} hiredData={data.bondInsuranceHiredData} />
                }
                {(data.canceledBondInsurance && origin === FindRecordOriginEnum.canceleds) &&
                  <CanceledBondInsuranceMainInfoDetails canceledBondInsurance={data.canceledBondInsurance} />
                }
                {data.bondInsurance !== null &&
                  <BondInsuranceMainInfoDetails bondInsurance={data.bondInsurance} />
                }
                {(data.bondInsurance && (origin === FindRecordOriginEnum.policies || origin === FindRecordOriginEnum.proposals)) &&
                  <BondInsuranceOwnerDetails bondInsurance={data.bondInsurance} />
                }
                {data.bondInsurance !== null &&
                  <BondInsurancePropertyDetails bondInsurance={data.bondInsurance} />
                }
                {(data.bondInsurance !== null && data.bondInsurance.tenantType === PersonTypesEnum.natural) &&
                  <BondInsuranceNaturalTenantDetails bondInsurance={data.bondInsurance} />
                }
                {(data.bondInsurance !== null && data.bondInsurance.tenantType === PersonTypesEnum.legal) &&
                  <BondInsuranceLegalTenantDetails bondInsurance={data.bondInsurance} />
                }
              </>}
            </Grid>
          )}
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
  );
}
