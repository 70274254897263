import React from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import styled from 'styled-components';

function getLabel(max: number, min: number, step: number) {
  const labels = [];
  for (let i = min; i <= max; i += step) {
    labels[i] = i.toString() + "%";
  }
  return labels;
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: -20px 0 15px 0px;

  .slider {
    width: 100%;
    .rangeslider__fill {
      background: #1D79BE;
    }

  }
`;

const RangeSlider = ({
  value,
  onChange,
  min,
  max,
  step
}: {
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
  step: number;
}) => (
  <StyledDiv>
    <Slider
      className="slider"
      min={min}
      max={max}
      step={step}
      value={value}
      format={value => value + "%"}
      labels={getLabel(max, min, 5)}

      onChange={onChange}
    />
  </StyledDiv>
);

export default RangeSlider;
