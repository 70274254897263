import { ResponsibleCompanyEnum } from "../../../enums/responsible-company.enum";

export const options = [
  { key: 'PENDING', text: 'Pendente', value: 'PENDING' },
  { key: 'ACTIVE', text: 'Ativo', value: 'ACTIVE' },
  { key: 'INACTIVE', text: 'Inativo', value: 'INACTIVE' },
  { key: 'BLOCKED', text: 'Bloqueado', value: 'BLOCKED' },
  { key: 'UPDATE', text: 'Update', value: 'UPDATE' }
];

export const classifications = [
  { key: 'BRONZE', text: 'Bronze', value: 'BRONZE' },
  { key: 'SILVER', text: 'Prata', value: 'SILVER' },
  { key: 'GOLD', text: 'Ouro', value: 'GOLD' },
  { key: 'DIAMOND', text: 'Diamante', value: 'DIAMOND' }
];

export const categories = [
  { key: 'ANALYSIS', text: 'Análises', value: 'ANALYSIS' },
  { key: 'HIRING', text: 'Contratações', value: 'HIRING' },
  { key: 'CANCELEDS', text: 'Cancelamentos', value: 'CANCELEDS' },
  { key: 'CLAIMS', text: 'Sinistros', value: 'CLAIMS' },
  { key: 'RENEWALS', text: 'Renovações', value: 'RENEWALS' },
  { key: 'FIRE_INSURANCE', text: 'Seguro Incêndio', value: 'FIRE_INSURANCE' }
];

export const optionsSource = [
  {
    key: 'CONFIAX_COMERCIAL',
    text: 'Confiax Comercial',
    value: 'CONFIAX_COMERCIAL'
  },
  { key: 'REDE_VISTORIAS', text: 'Rede Vistorias', value: 'REDE_VISTORIAS' }
];

export const companyOptions = [
  {
    key: 0,
    text: 'Rede Confiax',
    value: ResponsibleCompanyEnum.confiax
  },
  {
    key: 1,
    text: 'Rede Vistorias',
    value: ResponsibleCompanyEnum.vistorias
  }
];
