import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 11px;
  border: 4px solid #e69e0d;
  padding: 12px 24px;
  gap: 20px;
  width: 100%;

  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;

  p,
  ul {
    margin: 0;
  }

  ul {
    padding-left: 20px;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e69e0d;
    border-radius: 6px;
    width: 57px;
    height: 57px;
    color: white;
    font-size: 28px;
    box-shadow: 0px 0px 2px #e69e0d;
  }
`;
