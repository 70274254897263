import moment from 'moment-timezone';

export const FormatDateYMD = (date: Date): string => {
  return moment(date).tz('America/Sao_Paulo').format('YYYY-MM-DD');
}

export const FormatDateYMD_UTC_IGNORE = (date: Date): string => {
   return moment(date).utc(false).format('YYYY-MM-DD')
}

export const DateToStringDate = (date: Date): string => {
  //return moment(date).utc(true).format('DD/MM/YYYY')
  return moment(date).format('DD/MM/YYYY')
}

export const DateToStringDateTime = (date: Date): string => {
  //return moment(date).utc(true).format('DD/MM/YYYY HH:mm')
  return moment(date).format('DD/MM/YYYY HH:mm')
}

export const DateToStringTime = (date: Date): string => {
  //return moment(date).utc(true).format('HH:mm:ss')
  return moment(date).format('HH:mm:ss')
}

export const addMonthToDate = (date: Date | string, months: number): Date => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + parseInt(`${months}`))
  return newDate;
}

export const subtractMonthToDate = (date: Date | string, months: number): Date => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() - parseInt(`${months}`))
  return newDate;
}

export const getNewZeroDay = (date: Date | string): Date => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}
