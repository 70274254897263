export enum ActionEnum {
  manage = 'MANAGE',
  create = 'CREATE',
  read = 'READ',
  update = 'UPDATE',
  delete = 'DELETE',
  upload = 'UPLOAD',
  download = 'DOWNLOAD',
  contract = 'CONTRACT',
  reanalyse = 'REANALYSE',
  cancel = 'CANCEL',
  seeDetails = 'SEE_DETAILS',
  updatePolicy = 'UPDATE_POLICY',
  renew = 'RENEW',
  notRenew = 'NOT_RENEW',
  confirmRenew = 'CONFIRM_RENEW',
  edit = 'EDIT',
  seeTaxes = 'SEE_TAXES',
  detail = 'DETAIL',
  confirm = 'CONFIRM',
  save = 'SAVE',
  changeResponsible = 'CHANGE_RESPONSIBLE',
  send = 'SEND'
}
