import React, { SyntheticEvent } from 'react';
import { Field, FieldArray } from 'formik';
import {
  FireInsuranceBudgetFormSectionProps,
  submitQuote, wasQuoted, QuotePayload
} from '../../FireInsuranceBudgetForm';
import Input from '../../../../components/Input';
import SectionLabel from '../../../../components/SectionLabel';
import { Grid } from 'semantic-ui-react';
import { formatAmount, unmaskMoney, maskMoney } from '../../../../services/masks';
import { FormikErrorMessage } from '../../../../components/ErrorMessage';
import { getErrorFormik } from '../../../../services/errors';
import { FieldLabel, FieldValue } from '../../../../styles';
import Toggle from '../../../../components/Toggle';
import { CoverageProps, BudgetProps, SimulatorProps } from '../../types';
import { dangerNotification } from '../../../../services/notification';
import {iRootDispatch, iRootState} from "../../../../store";
import {SimulatorState} from "../../../../store/types/temp-types";
import {connect} from "react-redux";

type SegimobCoverageDataProps = FireInsuranceBudgetFormSectionProps
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: iRootState) => ({});
const mapDispatchToProps = (dispatch: iRootDispatch) => ({
  updateSimulatorFields: (simulatorFields: Partial<SimulatorState>) => dispatch.simulator.updateSimulatorFields(simulatorFields),
});

export const CoverageData = (props: FireInsuranceBudgetFormSectionProps) => {
  const {
    errors,
    touched,
    sectionKey,
    values,
    setFieldValue,
    handleInputChange
  } = props;

  const validateErros = (field: string) => getErrorFormik(errors, touched, sectionKey, field);

  const setCoverageFieldValue = (index: number, field: string, value: any) => {
    setFieldValue(`budget.coverage.${index}.${field}`, value);
  }

  const handleChangeCoverageDamage = (e: SyntheticEvent, data: any) => {
    data.value = formatAmount(data.value);
    const rentAmount = unmaskMoney(data.value) / 10;
    setCoverageFieldValue(1, 'rent', formatAmount(rentAmount.toFixed(2)));
    setCoverageFieldValue(1, 'damageAmount', data.value);
    handleInputChange(e, data);
  }

  return (
    <>
      {values.budget.coverage && values.budget.coverage.length > 0 ? (<SectionLabel text='COBERTURA (IMPORTÂNCIA SEGURADA)' />) : (<></>)}
      <FieldArray
        name="budget.coverage"
        render={arrayHelpers => (
          <>
            <Grid.Row>
              {values.budget.coverage && values.budget.coverage.length > 0 && values.budget.coverage[0] ? (
                <Grid.Column width={8}>
                  <FieldLabel>{values.budget.coverage[0].name}*</FieldLabel>
                  <Field name={`budget.coverage.0.damageAmount`}
                    component={Input}
                    value={formatAmount(values.budget.coverage[0].damageAmount)}
                    error={validateErros('budget.coverage.0.damageAmount')}
                    onChange={handleChangeCoverageDamage}
                    fluid />
                  <FormikErrorMessage component="div" name="budget.coverage.0.damageAmount" />
                </Grid.Column>
              ) : (<div></div>)}
              {values.budget.coverage && values.budget.coverage.length > 0 && values.budget.coverage[1] ? (
                <Grid.Column width={8}>
                  <FieldLabel>{values.budget.coverage[1].name}*</FieldLabel>
                  <Field name={`budget.coverage.1.rent`}
                    error={validateErros('budget.coverage.1.rent')}
                    value={formatAmount(values.budget.coverage[1].rent)}
                    disabled
                    component={Input}
                    fluid />
                  <FormikErrorMessage component="div" name="budget.coverage.1.rent" />
                </Grid.Column>
              ) : (<div></div>)}
            </Grid.Row>
          </>
        )} />
    </>
  );
}

const SegimobCoverageData = (props: SegimobCoverageDataProps) => {
  const {
    errors,
    touched,
    sectionKey,
    quotePayload,
    setIsLoading,
    setQuotePayload,
    setFieldValue,
    setFieldTouched,
    selectedBrokerId,
    values } = props;

  const validateErros = (field: string) => getErrorFormik(errors, touched, sectionKey, field);

  const simulateQuotation = async (budget: BudgetProps, q: QuotePayload, simulator: SimulatorProps, props: SegimobCoverageDataProps, toggle?: boolean) => {
    if (toggle || !wasQuoted(budget, q)) {
      setFieldValue('simulator.coverValue', 0);
      setFieldValue('simulator.covered', 0);
      simulator.coverValue = 0;
      simulator.covered = false;

      setIsLoading(true);
      budget.coverage = budget.coverage
      .filter((f) => f !== undefined)
      .map((c) => {
        c.damageAmount = formatAmount(c.damageAmount); return c;})
      try {
        const res = await submitQuote(budget, simulator, props, selectedBrokerId);
        setQuotePayload({
          insurerId: budget.insurance.insurer.id,
          assistanceId: budget.insurance.assistance.id,
          occupationId: budget.insurance.occupation.id,
          propertyType: budget.insurance.propertyType,
          rentValue: budget.insurance.rentValue,
          coverage: Object.values(budget.coverage)
        } as QuotePayload);
      } catch (e) {
        dangerNotification('Erro', 'Não foi possível realizar a cotação do seguro');
      } finally {
        setIsLoading(false);
      }
    }
  }

  const handleCoverageChange = async (e: SyntheticEvent, evt: any) => {
    if (evt) {
      setFieldValue(`budget.coverage.${evt.coverageId}.damageAmount`, formatAmount(evt.value));
      setFieldTouched(`budget.coverage.${evt.coverageId}.damageAmount`, true);
    }
  }

  const blurCoverageChange = async (e: SyntheticEvent, evt: any) => {
    await simulateQuotation(values.budget, quotePayload, values.simulator, props);
  }

  const handleToggle = async (e: SyntheticEvent, evt: any) => {
    if (evt) {
        setFieldValue(`budget.coverage.${evt.coverageId}.isActive`, evt.checked);
        setFieldTouched(`budget.coverage.${evt.coverageId}.isActive`, true);
        values.budget.coverage[evt.coverageId].isActive = evt.checked;

        // @TODO Fix tis bug
        await simulateQuotation(values.budget, quotePayload, values.simulator, props, true);
    }
  }

  const renderRow = (coverage: Array<CoverageProps>) => {
    let j = 1;
    const group: JSX.Element[] = [];
    coverage.map((c, i) => {
        if (c && values.budget.coverage[Number(c.idCoverage)]) {
          group.push(
            <Grid.Column width={4}>
              <Toggle name={`budget.coverage.${c.idCoverage}.isActive`}
                color='blue'
                style={{ marginBottom: '10px', display: 'block' }}
                coverageId={i}
                disabled={(i === 37) ? true : false}
                onChange={handleToggle}
                checked={Boolean(c.isActive)} />
              <FieldLabel>
                {c.name}*
            </FieldLabel>
              <Field name={`budget.coverage.${c.idCoverage}.damageAmount`}
                component={Input}
                value={(c.damageAmount) ? formatAmount(c.damageAmount.replace(',', '.')) : ''}
                coverageId={i}
                disabled={!values.budget.coverage[Number(c.idCoverage)].isActive}
                onChange={handleCoverageChange}
                onBlur={blurCoverageChange}
                error={validateErros(`budget.coverage.${c.idCoverage}.damageAmount`)}
                fluid />
              <FormikErrorMessage component="div" name={`budget.coverage.${c.idCoverage}.damageAmount`} />
            </Grid.Column>
          )
          group.push(
            <Grid.Column width={4}>
              <FieldValue style={{ marginTop: '35px' }}>Prêmio</FieldValue>
              <FieldValue>R$ {maskMoney(c.totalPrize || '0', true)}</FieldValue>
            </Grid.Column>
          )
          if (j == 2) {
            j = 0;
            group.push(<Grid.Row></Grid.Row>);
          }
          j++;
        }
      })
    return group;
  }

  return (
    <>
      {values.budget.coverage && values.budget.coverage.length > 0 ? (<SectionLabel text='COBERTURAS' />) : (<></>)}
      <FieldArray
        name="budget.coverage"
        render={arrayHelpers => (
          <>
            {values.budget.coverage && values.budget && values.budget.coverage.length > 0 ? renderRow(values.budget.coverage) : (<div></div>)}
          </>
        )} />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SegimobCoverageData);
