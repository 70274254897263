import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Dropdown } from 'semantic-ui-react';
import Button from '../../../../components/Button';
import { iRootDispatch, iRootState } from '../../../../store';
import Input from '../../../../components/Input';
import { FieldLabel } from '../../../../styles';
import { AvailableBrokersAndEstatesState } from '../../../../store/types/temp-types';
import { dangerNotification } from '../../../../services/notification';
import { ButtonsContainer } from '../../styles';
import { maskCpfOrCnpj } from '../../../../services/masks';
import BlacklistTable from '../Table';
import { useQuery } from '@tanstack/react-query';
import ViewModal from '../ViewModal';
import { Blacklist } from '../../../../types/Blacklist';
import { statusOptions, typeOptions } from '../../types';
import { hasPermission } from '../../../../components/AuthorizationContainer';
import { ActionEnum } from '../../../../enums/authz-action.enum';
import { FeatureEnum } from '../../../../enums/authz-feature.enum';
import { getList } from '../../../../services/blacklist';
import EditModal from '../EditModal';

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

const w100 = {
  width: '100%'
};

interface EstatesListFilter {
  document: string;
  name: string;
}

const BlacklistPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<Blacklist[]>([]);
  const [filterContent, setFilterContent] = useState<EstatesListFilter>({
    document: '',
    name: ''
  });
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataCount, setDataCount] = useState<number>(0);
  const [cleanInputs, setCleanInputs] = useState(false);
  const [type, setType] = useState<string | null>('document');
  const [argument, setArgument] = useState<string>('');
  const [isSearchEnabled, setSearchEnabled] = useState(true);
  const [itemSelected, setItemSelected] = useState<Blacklist | null>(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [editModalId, setEditModalId] = useState<string>('new');
  const [userCanDetail, setUserCanDetail] = useState<boolean>(false);

  const [total, setTotal] = useState(0);

  useEffect(() => {
    const has = hasPermission(ActionEnum.read, FeatureEnum.blacklist);
    setUserCanDetail(has);
  }, []);

  const getContentByType = () => {
    if (type === 'document') {
      return filterContent.document;
    }

    return filterContent.name;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getData = async () => {
    try {
      setIsLoading(true);
      const filters: {
        page: string;
        type: string | null;
        argument: string;
        status: string;
      } = {
        page: `${currentPage}`,
        type: type || null,
        argument: getContentByType(),
        status: selectedStatus
      };

      const response = await getList(filters);
      setCurrentPage(response?.page || 1);
      if(currentPage === 1) setTotal(response?.count);
      setDataCount(response?.count);
      setData(response.data);
    } catch (e) {
      dangerNotification('Oops...', 'Não foi possível buscar a black list!');
    } finally {
      setIsLoading(false);
      setCleanInputs(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleanInputs]);

  const clearInputs = () => {
    setFilterContent({
      document: '',
      name: ''
    });
    setType(null);
    setArgument('');
    setSelectedStatus('');
    setCleanInputs(true);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case 'argument': {
        if (type === 'document') {
          const numericValue = value?.replace(/\D/g, '');
          const maskedValue = maskCpfOrCnpj(numericValue);
          setArgument(maskedValue);
          const isValidDocumentSearch =
            numericValue.length === 11 || numericValue.length === 14;
          setSearchEnabled(isValidDocumentSearch);
          break;
        }
        setSearchEnabled(true);
        setArgument(value);
        break;
      }
    }
  };

  useEffect(() => {
    if (type === 'document') {
      setFilterContent(prevValue => ({ ...prevValue, document: argument }));
    } else if (type === 'name') {
      setFilterContent(prevValue => ({ ...prevValue, name: argument }));
    }
  }, [argument, type]);

  useEffect(() => {
    setFilterContent({
      name: '',
      document: ''
    });
  }, [type]);

  useEffect(() => {
    cleanInputs && getData();
  }, [cleanInputs, getData]);

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column computer={4} tablet={16} mobile={16}>
            <FieldLabel>Pesquisar por</FieldLabel>
            <div style={w100}>
              <Dropdown
                style={w100}
                value={type || ''}
                name="type"
                options={typeOptions}
                placeholder={'Selecionar...'}
                search
                selection
                onChange={(e, { value }) => setType(value as string)}
                clearable
              />
            </div>
          </Grid.Column>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <FieldLabel>Termo de pesquisa:</FieldLabel>
            <Input
              name="argument"
              onChange={handleInputChange}
              component={Input}
              value={argument}
              fluid
            />
          </Grid.Column>
          <Grid.Column computer={4} tablet={16} mobile={16}>
            <FieldLabel>Status:</FieldLabel>
            <Dropdown
              style={{
                width: '100%'
              }}
              value={selectedStatus}
              name={`status`}
              options={statusOptions}
              placeholder={'Selecionar...'}
              search
              selection
              onChange={(_, { value }) => {
                setSelectedStatus(value as string);
              }}
              clearable
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={8} tablet={8} mobile={16}>
            <Button
              color="blue"
              type={'button'}
              text="Adicionar à Blacklist"
              onClick={() => {
                setEditModalId('new');
                setIsEditModalOpen(true);
              }}
            />
          </Grid.Column>
          <Grid.Column computer={8} tablet={8} mobile={16}>
            <ButtonsContainer>
              <Button
                type={'submit'}
                color="blue"
                disabled={!isSearchEnabled}
                text="Pesquisar"
                onClick={() => {
                  setCurrentPage(1);
                  getData();
                }}
              />
              <Button basic type={'button'} text="Limpar" onClick={clearInputs} />
            </ButtonsContainer>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <BlacklistTable
        blacklist={data || []}
        isLoading={isLoading}
        currentPage={currentPage || 1}
        setCurrentPage={setCurrentPage}
        getData={getData}
        dataCount={total}
        handleSelectItem={(item: Blacklist) => {
          if (userCanDetail) {
            setItemSelected(item);
            setIsViewModalOpen(true);
          }
        }}
        handleEditItem={(itemId: string) => {
          if (userCanDetail) {
            setEditModalId(itemId);
            setIsEditModalOpen(true);
          }
        }}
      />
      {itemSelected && (
        <ViewModal
          isOpen={isViewModalOpen}
          onClose={() => setIsViewModalOpen(false)}
          blacklist={itemSelected}
        />
      )}
      {editModalId && (
        <EditModal
          isOpen={isEditModalOpen}
          closeModal={() => {
            setIsEditModalOpen(false);
            getData();
          }}
          id={editModalId}
        />
      )}
    </>
  );
};

export default connect(mapState, mapDispatch)(BlacklistPage);
