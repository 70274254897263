import { post, get } from "./request";
import {
  FireInsuranceBudget,
  FireInsuranceCoverageState,
} from "../types/FireInsurance";
import { FilterData } from "../pages/fire-insurance-budget-list/types";
import moment from "moment-timezone";
import {
  unmaskMoney,
  unmaskCpfOrCnpj,
  formatAmount,
  unmaskPhoneNumber,
} from "./masks";
import { round, IOF } from "../util";
import {
  SimulatorProps,
  BudgetProps,
} from "../pages/fire-insurance-budget-form/types";
import { FormatDateYMD } from "./date";
import { FireInsuranceAnalysisType } from "../pages/fire-insurance/types/fireInsuranceAnalysisType";
import { FireInsuranceQuoteFiltersInterface } from "../pages/fire-insurance/util";

export const createBudget = async (
  budget: BudgetProps,
  simulator: SimulatorProps,
  user_id: string,
  connectorDetails?: any
): Promise<FireInsuranceBudget> => {
  const payload: FireInsuranceBudget = {
    value: simulator.value,
    code: budget.insurance.insurer.code,
    iof: simulator.iof,
    cover_value:
      simulator.coverValue === 0
        ? simulator.value
        : Number(unmaskMoney(`${simulator.coverValue}`)),
    installments: simulator.installments,
    start_date: moment(simulator.startDate).format("YYYY-MM-DD"),
    end_date: moment(simulator.endDate).format("YYYY-MM-DD"),
    validity: simulator.validity,
    owner_document: unmaskCpfOrCnpj(budget.owner.document),
    owner_name: budget.owner.name,
    owner_email: budget.owner.email,
    tenant_document: unmaskCpfOrCnpj(budget.tenant.document),
    tenant_name: budget.tenant.name,
    tenant_email: budget.tenant.email,
    tenant_telephone: budget.tenant.telephone
      ? unmaskPhoneNumber(budget.tenant.telephone)
      : undefined,
    tenant_birth_date: budget.tenant.birthDate
      ? FormatDateYMD(budget.tenant.birthDate)
      : undefined,
    estate_percent: budget.insurance.estatePercent,
    broker_percent: budget.insurance.brokerPercent,
    property_id_address: budget.property.idAddress,
    property_id_city: budget.property.idCity,
    property_id_state: budget.property.idState,
    property_zipcode: budget.property.zipCode,
    property_street: budget.property.street,
    property_number: budget.property.number,
    property_district: budget.property.district,
    property_city: budget.property.city,
    property_state: budget.property.state,
    property_complement: budget.property.complement || "",
    property_code: budget.property.externalCode || "",
    assurance_rent_value: Number(unmaskMoney(budget.insurance.rentValue)),
    user_id,
    estate_id: budget.insurance.estate.id!,
    brokerage_fee: budget.insurance.broker.fee,
    profit_share: budget.insurance.estate.profitShare,
    occupation_id: budget.insurance.occupation.id,
    assistance_id: budget.insurance.assistance.id,
    quotation_request: budget.quotationRequest,
    coverage: budget.coverage
      .filter((f) => f !== undefined)
      .map((c, i) => {
        return {
          damageAmount: unmaskMoney(formatAmount(c.damageAmount)).toString(),
          rent: unmaskMoney(c.rent).toString(),
          name: c.name,
          idCoverage: c.idCoverage,
          idStateParamFireCoverage: c.idStateParamFireCoverage,
          iof: c.iof,
          maxValue: c.maxValue,
          minValue: c.minValue,
          netPrize: c.netPrize,
          totalPrize: c.totalPrize,
          isActive: c.isActive,
        } as FireInsuranceCoverageState;
      }),
    insurer_gain: simulator.insurerGain,
    confiax_comission: budget.confiaxComission || 0,
    insurer_name: budget.insurance.insurer.name,
  };

  if (connectorDetails) {
    payload.connector_details = connectorDetails;
  }

  const response = await post(`/fire-insurance-budgets`, {
    payload,
    insurer: budget.insurance.insurer.id,
  });

  return response;
};

export const getBudgets = async (
  filter?: FilterData
): Promise<Array<FireInsuranceBudget>> => {
  let query = "";

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  let response;

  try {
    response = await get(`/fire-insurance-budgets/filter?${query}`);
  } catch (e) {
    response = [];
  }
  return response;
};

export const cancelBudget = async (
  id: string,
  code: string
): Promise<boolean> => {
  try {
    const body = [{ id: id, code: code }];
    await post("/fire-insurance-budgets/cancel", body);
    return true;
  } catch (e) {
    throw e;
  }
};

export const printBudget = async (
  id: string,
  userId: string,
  code: string
): Promise<any> => {
  try {
    const body = { id: id, user_id: userId, code: code };
    return await post("/fire-insurance-budgets/print", body);
  } catch (e) {
    throw e;
  }
};

export const coverProposal = (
  broker: string,
  confiax_comission: number,
  broker_minimum_gain: number,
  value: number,
  cover_value: number,
  profit_share: number,
  brokerage_fee: number,
  net_gain_assistance: number
): any => {
  const confiax_percentage = confiax_comission;

  const netInsuranceValue = value / (1 + IOF);
  const netInsuranceCoverValue = cover_value / (1 + IOF);

  const minComissionRate = broker.includes("Confiax")
    ? confiax_percentage / (1 - confiax_percentage)
    : (confiax_percentage + broker_minimum_gain) /
      (1 - (confiax_percentage + broker_minimum_gain));

  const minimumValue = round(
    (minComissionRate * net_gain_assistance + net_gain_assistance) * (1 + IOF),
    2
  );

  const discountValue = value - cover_value;
  const discountPercentage = 1 - cover_value / value;

  if (cover_value < minimumValue || cover_value < 0 || cover_value > value) {
    throw Error("Não é possível cobrir o valor informado");
  }
  let updatedProfitShare = profit_share - discountPercentage;
  let updatedBrokerageFee = brokerage_fee;
  let updatedConfiaxPercentage = confiax_percentage;

  if (updatedProfitShare < 0) {
    updatedBrokerageFee = updatedBrokerageFee + updatedProfitShare;
    updatedProfitShare = 0;
  }
  if (updatedBrokerageFee < 0) {
    updatedConfiaxPercentage = updatedConfiaxPercentage + updatedBrokerageFee;
    updatedBrokerageFee = 0;
  }

  updatedProfitShare = round(
    (netInsuranceValue * updatedProfitShare) / netInsuranceCoverValue,
    5
  );
  updatedBrokerageFee = round(
    (netInsuranceValue * updatedBrokerageFee) / netInsuranceCoverValue,
    5
  );
  updatedConfiaxPercentage = round(
    (netInsuranceValue * updatedConfiaxPercentage) / netInsuranceCoverValue,
    5
  );

  cover_value = value - discountValue;

  return {
    cover_value,
    value,
    profit_share: updatedProfitShare,
    brokerage_fee: updatedBrokerageFee,
    confiax_comission: updatedConfiaxPercentage,
    insurance_minimum_value: minimumValue,
  };
};

export async function sendQuote(quotation: any) {
  try {
    return await post("/fire-insurance-quotations/quote", quotation);
  } catch (e) {
    throw e;
  }
}

export async function sendReanalysis(quotation: any) {
  try {
    return await post("/fire-insurance-quotations/reanalysis", quotation);
  } catch (e) {
    throw e;
  }
}

type getQuotesType = {
  data: FireInsuranceAnalysisType[];
  quantity: number;
}

export async function getQuotes(filter: FireInsuranceQuoteFiltersInterface): Promise<getQuotesType | null> {
  let response;
  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`
      }
    })
  }

  try {
    const [data, quantity] = await get(`/fire-insurance-quotations/quotes?${query}`);
    response = {
      data,
      quantity
    }
  } catch (e) {
    throw e;
  }

  return response;
}
