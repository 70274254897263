import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px;
`;

export const Disclaimer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding-bottom: 24px;

  h4 {
    font-size: 16px;
    font-weight: 700;
    color: #2185d0;
    margin: 0;
  }
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 24px;
`;

export const LinkText = styled.p`
  font-size: 14px;
  font-weight: 700;
  margin: 0;
`;

export const AlertText = styled.span`
  color: red;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
`;

export const CopyButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

