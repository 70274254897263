import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Dropdown, Grid, Icon, Input, Popup } from 'semantic-ui-react';
import SectionLabel from '../../../../../components/SectionLabel';
import { formatAmount, maskCpfOrCnpj, maskPhoneNumber, maskZipCode, moneyStringToNumber, unmaskCpfOrCnpj } from '../../../../../services/masks';
import { FieldLabel } from '../../../../../styles';
import { propertyTypesList, propertyUseTypeList, constructionTypeList, coverageTypeList, historicalProtectedPropertyList, insuredOwnerList, sharedPropertyList, locationTypes } from '../../../utils/Pottencial'
import useDebounce from '../../../../../services/useDebounce'
import { AddressService } from '../../../../../services/address'
import { getDataByDocument } from '../../../../../services/documents';
import { warningNotification, dangerNotification, successNotification } from '../../../../../services/notification';
import moment from 'moment-timezone';
import { sendQuote } from '../../../../../services/fire-insurance-budget';
import { PopupContent, PreQuoteHeader, PreQuoteWrapper, RequirementsWrapper } from '../../styles'
import PopupListItem from '../../../components/PopupListItem';
import { useHistory } from 'react-router-dom'
// import SemanticDatePicker from 'react-semantic-ui-datepickers'
import { sleep } from '../../../../../util';
import extenso from 'extenso';
import CustomDatePicker from '../../../../../components/CustomDatePicker';
import AppMaskedInput from '../../../../../components/MaskedInput';

interface PottencialBudgetFormProps {
  estateId: string;
  insurerId: string;
  userId: string;
  percentage: number;
}

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);
const oneMonthFromNow = new Date(today.getTime() + (30 * 24 * 60 * 60 * 1000));

const PottencialBudgetForm = (props: PottencialBudgetFormProps) => {
  const history = useHistory();
  const { estateId, insurerId, userId } = props;

  console.log({props})

  const percentage = props.percentage || 1;

  const [maskedRentValue, setMaskedRentValue] = useState<string>('0,00');
  // const [maskedPropertyValue, setMaskedPropertyValue] = useState<string>('0,00');
  const [maskedCommissionPercentage, setMaskedCommissionPercentage] = useState<string>(formatAmount(percentage * 100));
  const debouncedmaskedCommissionPercentage = useDebounce<string>(maskedCommissionPercentage, 1000);

  const [propertyType, setPropertyType] = useState<any>('');
  const [propertyUseType, setPropertyUseType] = useState<any>('');
  const [constructionType, setConstructionType] = useState<any>('');
  const [commissionPercentage, setCommissionPercentage] = useState<number>(percentage);
  const [coverageType, setCoverageType] = useState<any>(coverageTypeList[0].value);
  const [rentValue, setRentValue] = useState<number>(0);
  // const [propertyValue, setPropertyValue] = useState<number>(0);
  const [historicalProtectedProperty, setHistoricalProtectedProperty] = useState<any>('');
  const [sharedProperty, setSharedProperty] = useState<any>('');
  const [insuredOwner, setInsuredOwner] = useState<any>('');
  const [locationType, setLocationType] = useState<any>('');

  const [cep, setCep] = useState<string>('');
  const debouncedCep = useDebounce<string>(cep, 500);
  const [street, setStreet] = useState<string>('');
  const [neighborhood, setNeighborhood] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [complement, setComplement] = useState<string>('');
  const [hasNotComplement, setHasNotComplement] = useState<boolean>(false);

  const [document, setDocument] = useState<string>('');
  const debouncedDocument = useDebounce<string>(unmaskCpfOrCnpj(document), 500);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const [ownerDocument, setOwnerDocument] = useState<string>('');
  const debouncedOwnerDocument = useDebounce<string>(unmaskCpfOrCnpj(ownerDocument), 500);
  const [ownerName, setOwnerName] = useState<string>('');
  const [ownerEmail, setOwnerEmail] = useState<string>('');

  const [installments, setInstallments] = useState<number>(11);
  const [validityInMonths, setValidityInMonths] = useState<number>(12);
  const [startDate, setStartDate] = useState<Date | null>(today);
  const [endDate, setEndDate] = useState<Date | null>(getDayAdded365Days(startDate));

  const [loadingCep, setLoadingCep] = useState<boolean>(false);
  const [loadingDocument, setLoadingDocument] = useState<boolean>(false);
  const [loadingOnwerDocument, setLoadingOnwerDocument] = useState<boolean>(false);

  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  const insurerData: boolean = !!insurerId && !!estateId && !!propertyType && !!propertyUseType && !!constructionType && !!coverageType && !!rentValue && !!sharedProperty && !!historicalProtectedProperty && !!insuredOwner && !!commissionPercentage && !!locationType;
  const propertyData: boolean = !!cep && !!street && !!neighborhood && !!city && !!state && !!number && (!hasNotComplement ? !!complement : true);
  const tenantData: boolean = !!document && !!name && !!email && !!phone;
  const ownerData: boolean = !!ownerDocument && !!ownerName && !!ownerEmail;

  const commissionRef = useRef(null)
  const [extendedNumber, setExtendedNumber] = useState<string>('');

  const [isMandatoryComplement, setIsMandatoryComplement] = useState<boolean>(false);

  function getDayAdded365Days(date: Date | null): Date | null {
    if (date) {
      return new Date(date.getTime() + (365 * 24 * 60 * 60 * 1000));
    }

    return null
  }

  useEffect(() => {
    setEndDate(getDayAdded365Days(startDate));
  }, [startDate])

  useEffect(() => {
    if (debouncedCep.length === 9)
      // console.log({debouncedCep})
      if (!loadingCep)
        getAddress()
  }, [debouncedCep])

  useEffect(() => {
    if (debouncedDocument.length === 11 || debouncedDocument.length === 14) {
      // console.log({debouncedDocument})
      getDocument('beneficiary')
    }
  }, [debouncedDocument])

  useEffect(() => {
    if (debouncedOwnerDocument.length === 11 || debouncedOwnerDocument.length === 14) {
      // console.log({debouncedOwnerDocument})
      getDocument('owner')
    }
  }, [debouncedOwnerDocument])

  useEffect(() => {
    if (hasNotComplement) {
      setComplement('')
    }
  }, [hasNotComplement])

  useEffect(() => {
    setRentValue(moneyStringToNumber(maskedRentValue))
  }, [maskedRentValue])

  // useEffect(() => {
  //   setPropertyValue(moneyStringToNumber(maskedPropertyValue))
  // }, [maskedPropertyValue])

  useEffect(() => {
    // console.log({maskedCommissionPercentage})
    setCommissionPercentage(moneyStringToNumber(maskedCommissionPercentage))
  }, [maskedCommissionPercentage])

  useEffect(() => {
    if (!!debouncedmaskedCommissionPercentage) {
      const nmr = moneyStringToNumber(debouncedmaskedCommissionPercentage);

      if (percentage < nmr || nmr < 1) {
        const newValue = formatAmount(percentage * 100)
        warningNotification('Error', `O percentual de comissão deve ser entre 1,00% e ${newValue}%`, 'bottom', 4000);
        (commissionRef.current as any).focus();
        setMaskedCommissionPercentage(newValue);
      } else {
        setExtendedNumber(
          `(${extenso(formatAmount(nmr * 100), { number: { decimal: 'informal' } })} por cento)`
        )
      }
    }
  }, [debouncedmaskedCommissionPercentage])

  useEffect(() => {
    if (propertyType) {
      const typesToMandatoryComplement = ['Apartment', 'CondominiumApartment', 'CondominiumHouse'];
      if (typesToMandatoryComplement.includes(propertyType)) {
        setIsMandatoryComplement(true);
        setHasNotComplement(false);
      } else {
        setIsMandatoryComplement(false);
      }
      console.log({propertyType});
    }
  }, [propertyType])

  const getAddress = async () => {
    setLoadingCep(true);
    if (debouncedCep.length === 9) {
      const response = await fetch(`https://viacep.com.br/ws/${debouncedCep}/json/`);
      try {
        const address = await AddressService.getAddressByZipCode(debouncedCep)
        // console.log({response, address})
        const data = await response.json();
        if (data.erro) {
          setCep('');
          setStreet('');
          setNeighborhood('');
          setCity('');
          setState('');
          setNumber('');
          setComplement('');
        } else {
          setCep(data.cep);
          setStreet(data.logradouro);
          setNeighborhood(data.bairro);
          setCity(data.localidade);
          setState(data.uf);
        }
      } catch (err) {
        warningNotification('Erro ao buscar endereço', err as string)
        // setCep('');
      }
    }
    setLoadingCep(false);
  }

  const getDocument = async (target: string) => {
    if (target === 'beneficiary') {
      setLoadingDocument(true)
      if (debouncedDocument.length === 11 || debouncedDocument.length === 14) {
        getDataByDocument(debouncedDocument)
          .then(data => {
            if (debouncedDocument.length === 14) {
              setName(data.company)
              setEmail(data.email)
              setPhone(data.phone)
            } else {
              setName(data.name)
              // console.log({data})
            }
          })
          .catch(err => {
            warningNotification(`Não foi possível encontrar o ${debouncedDocument.length === 11 ? 'CPF' : 'CNPJ'}`, 'O documento informado não foi encontrado no nosso banco de dados')
          })
      }
      setLoadingDocument(false)
    } else if (target === 'owner') {
      setLoadingOnwerDocument(true);
      if (debouncedOwnerDocument.length === 11 || debouncedOwnerDocument.length === 14) {
        getDataByDocument(debouncedOwnerDocument)
          .then(data => {
            if (debouncedOwnerDocument.length === 14) {
              setOwnerName(data.company);
              setOwnerEmail(data.email)
            } else {
              setOwnerName(data.name);
              // console.log({data})
            }
          })
          .catch(err => {
            warningNotification(`Não foi possível encontrar o ${debouncedOwnerDocument.length === 11 ? 'CPF' : 'CNPJ'}`, 'O documento informado não foi encontrado no nosso banco de dados')
          })
      }
      setLoadingOnwerDocument(false);
    }
  }

  const sendQuotation = async () => {
    setIsSubmiting(true);
    if (insurerData && propertyData && tenantData && ownerData) {
      const quotation = {
        estate_id: estateId,
        insurer_id: insurerId,
        created_by: userId,
        insurer_payload: {
          policy_period_start: moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          // property_value: propertyValue,
          location_type: locationType,
          historical_protected_property: JSON.parse(historicalProtectedProperty.toLocaleLowerCase()),
          shared_property: JSON.parse(sharedProperty.toLocaleLowerCase()),
          insured_owner: JSON.parse(insuredOwner.toLocaleLowerCase()),
          property_type: propertyType,
          construction_type: constructionType,
          property_use_type: propertyUseType,
          commission_percentage: commissionPercentage,
          participants: [
            {
              document_number: debouncedOwnerDocument.replace(/\D/g, ''),
              name: ownerName,
              email: ownerEmail,
              role: 'Beneficiary',
              participation_percentage: 1
            },
            {
              document_number: document.replace(/\D/g, ''),
              name: name,
              email: email,
              cellphone: phone.replace(/\D/g, ''),
              role: 'Insured',
              participation_percentage: 0
            }
          ],
          coverages: [
            {
              reference: {
                coverage_code: coverageType,
              }
            }
          ]
        },
        payload: {
          risk_place: {
            rent_value: rentValue,
            zip_code: cep.replace('-', ''),
            street,
            number,
            district: neighborhood,
            city,
            complement: !!complement ? complement : null,
            state
          },
        }
      }
      console.log({quotation})
      try {
        const send = await sendQuote(quotation)
        if (send.success) {
          // successNotification('Sucesso', 'Sua cotação foi enviada com sucesso, em alguns segundos te atualizaremos sobre o resultado', 'bottom', 8000);
          setIsSubmiting(false);
          // await sleep(2000);
          history.push('/admin/fire-insurance/budget/list')
        }
      } catch (err) {
        dangerNotification('Erro ao enviar a cotação', (err as any).message)
      }
    }

    setIsSubmiting(false);
  }

  return (
    <Grid style={{width: '105%', height: 'calc(100vh - 330px)'}}>
      <Grid.Column width={12} style={{height: '100%', borderRight: '1px solid #ccc', overflowY: 'auto'}}>
        {/* <h1>Pottencial</h1>
        <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          <span>estateId: {estateId}</span>
          <span>insurerId: {insurerId}</span>
        </div> */}
        <Grid>
          <SectionLabel text={'Dados do Seguro'} marginTop={80} />
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel required>TIPO DE IMÓVEL</FieldLabel>
              <Dropdown
                fluid
                search
                clearable
                selection
                placeholder={'Tipo de Imóvel'}
                options={propertyTypesList}
                onChange={(e, { name, value }) => setPropertyType(value)}
                value={propertyType}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel required>TIPO DE OCUPAÇÃO</FieldLabel>
              <Dropdown
                fluid
                search
                clearable
                selection
                options={propertyUseTypeList}
                defaultValue={'Usual'}
                placeholder={'Tipo de Ocupação'}
                onChange={(e, { name, value }) => setPropertyUseType(value)}
                value={propertyUseType}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel required>TIPO DE CONSTRUÇÃO</FieldLabel>
              <Dropdown
                fluid
                search
                clearable
                selection
                options={constructionTypeList}
                placeholder={'Tipo de Construção'}
                onChange={(e, { name, value }) => setConstructionType(value)}
                value={constructionType}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel required>TIPO DE COBERTURA</FieldLabel>
              <Dropdown
                fluid
                search
                clearable
                selection
                disabled
                options={coverageTypeList}
                placeholder={'Tipo de Cobertura'}
                onChange={(e, { name, value }) => setCoverageType(value)}
                value={coverageType}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel required>VALOR DO ALUGUEL</FieldLabel>
              <Input
                fluid
                placeholder={'Valor do Aluguel'}
                icon={'dollar'}
                iconPosition={'left'}
                onChange={(e, data: any) => setMaskedRentValue(formatAmount(data.value))}
                value={maskedRentValue}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                <FieldLabel required>
                  PERCENTUAL DE COMISSÃO
                </FieldLabel>
                <span style={{color: '#ccc'}}>{extendedNumber}</span>
              </div>
              <Input
                fluid
                placeholder={'Percentual de Comissão'}
                icon={'percent'}
                ref={commissionRef}
                iconPosition={'left'}
                onChange={(e, data: any) => setMaskedCommissionPercentage(formatAmount(data.value))}
                value={maskedCommissionPercentage}
              />
            </Grid.Column>
            {/* <Grid.Column width={8}>
              <FieldLabel required>VALOR DO IMÓVEL</FieldLabel>
              <Input
                fluid
                placeholder={'Valor do Imóvel'}
                icon={'dollar'}
                iconPosition={'left'}
                onChange={(e, data: any) => setMaskedPropertyValue(formatAmount(data.value))}
                value={maskedPropertyValue}
              />
            </Grid.Column> */}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel required>IMÓVEL HISTORICAMENTE PROTEGIDO</FieldLabel>
              <Dropdown
                fluid
                search
                clearable
                selection
                options={historicalProtectedPropertyList}
                placeholder={'Imóvel Historicamente Protegido'}
                onChange={(e, { name, value }) => setHistoricalProtectedProperty(value)}
                value={historicalProtectedProperty}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel required>IMÓVEL COMPARTILHADO</FieldLabel>
              <Dropdown
                fluid
                search
                clearable
                selection
                options={sharedPropertyList}
                placeholder={'Imóvel Compartilhado'}
                onChange={(e, { name, value }) => setSharedProperty(value)}
                value={sharedProperty}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel required>SEGURADO É O PROPRIETÁRIO?</FieldLabel>
              <Dropdown
                fluid
                search
                clearable
                selection
                options={insuredOwnerList}
                placeholder={'Segurado é o Proprietário'}
                onChange={(e, { name, value }) => setInsuredOwner(value)}
                value={insuredOwner}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel required>TIPO DE LOCAÇÃO</FieldLabel>
              <Dropdown
                fluid
                search
                clearable
                selection
                options={locationTypes}
                placeholder={'Tipo de Locação'}
                onChange={(e, { name, value }) => setLocationType(value)}
                value={locationType}
              />
            </Grid.Column>
          </Grid.Row>

          <SectionLabel text={'Dados para Cotação'} marginTop={80} />
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel required>QUANTIDADE DE PARCELAS</FieldLabel>
              <Input
                fluid
                size={'small'}
                disabled
                placeholder={'Quantidade de Parcelas'}
                onChange={(e, data: any) => setInstallments(data.value)}
                value={installments}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel required>DURAÇÃO DE VIGÊNCIA</FieldLabel>
              <Input
                fluid
                size={'small'}
                disabled
                value={`${validityInMonths} ${validityInMonths > 1 ? 'Mêses' : 'Mês'}`}
                placeholder={'Duração de Vigência'}
                onChange={(e, data: any) => setValidityInMonths(data.value)}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel>INÍCIO</FieldLabel>
              <CustomDatePicker
                  fluid
                  name={'start'}
                  placeholder={'Data Inicial'}
                  selected={startDate}
                  minDate={yesterday}
                  maxDate={oneMonthFromNow}
                  onChange={(date: any) => setStartDate(date)}
                  customInput={
                    <AppMaskedInput
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        "/",
                        /[0-9]/,
                        /[0-9]/,
                        "/",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                      ]} />}
                  dateFormat={"dd/MM/yyyy"}
                />

              {/* <SemanticDatePicker
                keepOpenOnClear
                locale={'pt-BR'}
                value={startDate}
                format={'DD/MM/YYYY'}
                minDate={yesterday}
                maxDate={oneMonthFromNow}
                onChange={(e, data: any) => setStartDate(data.value)}
                size={'small'}
              /> */}
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel>FIM</FieldLabel>
              <Input
                fluid
                size={'small'}
                disabled
                value={moment(endDate).format('DD/MM/YYYY')}
                placeholder={'Fim'}
                onChange={(e, data: any) => setEndDate(data.value)}
              />
            </Grid.Column>
          </Grid.Row>

          <SectionLabel text={'Dados do Imóvel'} marginTop={80} />
          <Grid.Row>
            <Grid.Column width={5}>
              <FieldLabel required>CEP</FieldLabel>
              <Input
                fluid
                autoComplete={'off'}
                placeholder={'CEP'}
                value={maskZipCode(cep)}
                loading={loadingCep}
                disabled={loadingCep}
                onBlur={() => !loadingCep && getAddress()}
                onChange={(e, data: any) => setCep(data.value)}
              />
            </Grid.Column>
            <Grid.Column width={11}>
              <FieldLabel required>LOGRADOURO</FieldLabel>
              <Input
                fluid
                loading={loadingCep}
                disabled={loadingCep}
                placeholder={'Logradouro'}
                value={street}
                onChange={(e, data: any) => setStreet(data.value)}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <FieldLabel required>BAIRRO</FieldLabel>
              <Input
                fluid
                loading={loadingCep}
                disabled={loadingCep}
                placeholder={'Bairro'}
                value={neighborhood}
                onChange={(e, data: any) => setNeighborhood(data.value)}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <FieldLabel required>CIDADE</FieldLabel>
              <Input
                fluid
                loading={loadingCep}
                disabled={loadingCep}
                placeholder={'Cidade'}
                value={city}
                onChange={(e, data: any) => setCity(data.value)}
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <FieldLabel required>UF</FieldLabel>
              <Input
                fluid
                loading={loadingCep}
                disabled={loadingCep}
                placeholder={'Unidade Federativa'}
                value={state}
                onChange={(e, data: any) => setState(data.value)}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <FieldLabel required>NÚMERO</FieldLabel>
              <Input
                fluid
                loading={loadingCep}
                disabled={loadingCep}
                placeholder={'Número'}
                value={number}
                onChange={(e, data: any) => setNumber(data.value)}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <FieldLabel required={!hasNotComplement}>COMPLEMENTO</FieldLabel>
              <Input
                fluid
                loading={loadingCep}
                disabled={loadingCep || hasNotComplement}
                placeholder={'Complemento'}
                value={complement}
                onChange={(e, data: any) => setComplement(data.value)}
              />
            </Grid.Column>
            <Grid.Column width={5} style={{display: 'flex', alignItems: 'center'}}>
              <Checkbox
                checked={hasNotComplement}
                onClick={() => setHasNotComplement(!hasNotComplement)}
                style={{pointerEvents: isMandatoryComplement && 'none'}}
                label={'Não possui Complemento'}
                disabled={isMandatoryComplement}
              />
            </Grid.Column>
          </Grid.Row>

          <SectionLabel text={'Dados do Inquilino'} marginTop={80} />
          <Grid.Row>
            <Grid.Column width={4}>
              <FieldLabel required>CPF/CNPJ</FieldLabel>
              <Input
                fluid
                placeholder={'CPF ou CNPJ'}
                value={maskCpfOrCnpj(document)}
                onChange={(e, data: any) => setDocument(data.value)}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <FieldLabel required>NOME/RAZÃO SOCIAL</FieldLabel>
              <Input
                fluid
                placeholder={'Nome ou Razão Social'}
                value={name}
                loading={loadingDocument}
                disabled={loadingDocument}
                onChange={(e, data: any) => setName(data.value)}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <FieldLabel required>E-MAIL</FieldLabel>
                <Input
                  fluid
                  placeholder={'E-mail'}
                  value={email}
                  loading={loadingDocument}
                  disabled={loadingDocument}
                  onChange={(e, data: any) => setEmail(data.value)}
                />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <FieldLabel required>TELEFONE</FieldLabel>
              <Input
                fluid
                placeholder={'Telefone'}
                value={maskPhoneNumber(phone)}
                loading={loadingDocument}
                disabled={loadingDocument}
                onChange={(e, data: any) => setPhone(data.value)}
              />
            </Grid.Column>
          </Grid.Row>

          <SectionLabel text={'Dados do Proprietário'} marginTop={80} />
          <Grid.Row>
            <Grid.Column width={4}>
              <FieldLabel required>CPF/CNPJ</FieldLabel>
              <Input
                fluid
                placeholder={'CPF/CNPJ'}
                value={maskCpfOrCnpj(ownerDocument)}
                loading={loadingOnwerDocument}
                disabled={loadingOnwerDocument}
                onChange={(e, data: any) => setOwnerDocument(data.value)}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <FieldLabel required>NOME/RAZÃO SOCIAL</FieldLabel>
              <Input
                fluid
                placeholder={'Nome/Razão Social'}
                value={ownerName}
                loading={loadingOnwerDocument}
                disabled={loadingOnwerDocument}
                onChange={(e, data: any) => setOwnerName(data.value)}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <FieldLabel required>E-MAIL</FieldLabel>
              <Input
                fluid
                placeholder={'E-mail'}
                value={ownerEmail}
                loading={loadingOnwerDocument}
                disabled={loadingOnwerDocument}
                onChange={(e, data: any) => setOwnerEmail(data.value)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>

      <Grid.Column width={4}>
        <PreQuoteWrapper>
          <PreQuoteHeader>
            <h3>Validação para Cotação</h3>
          </PreQuoteHeader>
          <Grid style={{padding: 10, height: 'calc(100% - 60px)'}}>
            <Grid.Row>
              <RequirementsWrapper>
                <Popup
                  trigger={
                    <div
                      style={{cursor: insurerData ? 'default' : 'help', marginBottom: 5}}
                    >
                      <Icon name={insurerData ? 'check' : 'times'} color={insurerData ? 'green' : 'red'} />
                      <strong style={{color: insurerData ? 'green' : 'red'}}>Dados do Seguro</strong>
                    </div>
                  }
                  disabled={insurerData}
                  content={
                    <PopupContent>
                      <h2>Dados do Seguro</h2>
                      <div>
                        <PopupListItem title={'Tipo de Imóvel'} isAble={!!propertyType} />
                        <PopupListItem title={'Tipo de Ocupação'} isAble={!!propertyUseType} />
                        <PopupListItem title={'Tipo de Construção'} isAble={!!constructionType} />
                        <PopupListItem title={'Tipo de Cobertura'} isAble={!!coverageType} />
                        <PopupListItem title={'Valor do Aluguel'} isAble={!!rentValue} />
                        {/* <PopupListItem title={'Valor do Imóvel'} isAble={!!propertyValue} /> */}
                        <PopupListItem title={'Percentual de Comissão'} isAble={!!commissionPercentage} />
                        <PopupListItem title={'Imóvel Historicamente Protegido'} isAble={!!historicalProtectedProperty} />
                        <PopupListItem title={'Imóvel Compartilhado'} isAble={!!sharedProperty} />
                        <PopupListItem title={'Segurado é o Proprietário?'} isAble={!!insuredOwner} />
                        <PopupListItem title={'Tipo de Locação'} isAble={!!locationType} />
                      </div>
                    </PopupContent>
                  }
                >
                </Popup>
                <Popup
                  trigger={
                    <div
                      style={{cursor: Boolean(startDate) ? 'default' : 'help', marginBottom: 5}}
                    >
                      <Icon name={Boolean(startDate) ? 'check' : 'times'} color={Boolean(startDate) ? 'green' : 'red'} />
                      <strong style={{color: Boolean(startDate) ? 'green' : 'red'}}>Dados da Cotação</strong>
                    </div>
                  }
                  disabled={Boolean(startDate)}
                  content={
                    <PopupContent>
                      <h2>Dados da Cotação</h2>
                      <div>
                        <PopupListItem title={'Data de Início'} isAble={Boolean(startDate)} />
                      </div>
                    </PopupContent>
                  }
                  />
                <Popup
                  trigger={
                    <div
                      style={{cursor: propertyData ? 'default' : 'help', marginBottom: 5}}
                    >
                      <Icon name={propertyData ? 'check' : 'times'} color={propertyData ? 'green' : 'red'} />
                      <strong style={{color: propertyData ? 'green' : 'red'}}>Dados do Imóvel</strong>
                    </div>
                  }
                  disabled={propertyData}
                  content={
                    <PopupContent>
                      <h2>Dados do Imóvel</h2>
                      <div>
                        <PopupListItem title={'CEP'} isAble={!!cep} />
                        <PopupListItem title={'Logradouro'} isAble={!!street} />
                        <PopupListItem title={'Bairro'} isAble={!!neighborhood} />
                        <PopupListItem title={'Cidade'} isAble={!!city} />
                        <PopupListItem title={'UF'} isAble={!!state} />
                        <PopupListItem title={'Número'} isAble={!!number} />
                        {
                          !hasNotComplement && (
                            <PopupListItem title={'Complemento'} isAble={!!complement} />
                          )
                        }
                      </div>
                    </PopupContent>
                  }
                />
                <Popup
                  trigger={
                    <div
                      style={{cursor: tenantData ? 'default' : 'help', marginBottom: 5}}
                    >
                      <Icon name={tenantData ? 'check' : 'times'} color={tenantData ? 'green' : 'red'} />
                      <strong style={{color: tenantData ? 'green' : 'red'}}>Dados do Inquilino</strong>
                    </div>
                  }
                  disabled={tenantData}
                  content={
                    <PopupContent>
                      <h2>Dados do Inquilino</h2>
                      <div>
                        <PopupListItem title={'CPF/CNPJ'} isAble={!!document} />
                        <PopupListItem title={'Nome/Razão Social'} isAble={!!name} />
                        <PopupListItem title={'E-mail'} isAble={!!email} />
                        <PopupListItem title={'Telefone'} isAble={!!phone} />
                      </div>
                    </PopupContent>
                  }
                />
                <Popup
                  trigger={
                    <div
                      style={{cursor: ownerData ? 'default' : 'help', marginBottom: 5}}
                    >
                      <Icon name={ownerData ? 'check' : 'times'} color={ownerData ? 'green' : 'red'} />
                      <strong style={{color: ownerData ? 'green' : 'red'}}>Dados do Proprietário</strong>
                    </div>
                  }
                  disabled={ownerData}
                  content={
                    <PopupContent>
                      <h2>Dados do Proprietário</h2>
                      <div>
                        <PopupListItem title={'CPF/CNPJ'} isAble={!!ownerDocument} />
                        <PopupListItem title={'Nome/Razão Social'} isAble={!!ownerName} />
                        <PopupListItem title={'E-mail'} isAble={!!ownerEmail} />
                      </div>
                    </PopupContent>
                  }
                />
              </RequirementsWrapper>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button
                  fluid
                  positive
                  disabled={!insurerData || !propertyData || !tenantData || !ownerData || !startDate || isSubmiting}
                  loading={isSubmiting}
                  icon={'calculator'}
                  content={'Solicitar Orçamento'}
                  onClick={sendQuotation}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PreQuoteWrapper>
      </Grid.Column>
    </Grid>
  )
}

export default PottencialBudgetForm;
