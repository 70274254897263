import { DropdownItemProps } from "semantic-ui-react";
import { CancellationReasonEnum } from "../../enums/cancellation-reason.enum";
import { CanceledStatusEnum } from "../../enums/status-canceled-bond-insurances";

export type BondInsurancesTableProps = {
}

export type FilterData = {
  broker_id?: string;
  estate_id?: string;
  insurer_id?: string;
  user_id?: string;
  address?: string;
  name?: string;
  status?: string;
  origin?: string;
  document?: string;
  start?: string;
  end?: string;
  onlyUnread?: boolean;
  bondInsuranceId?: string;
  responsibleId?: string;
}
export interface ListingRouteInfo {
  id: string;
}

export enum TranslatedCanceledStatus {
  CANCELLATION_IN_PROGRESS = 'Em análise',
  CANCELED = 'Concluído',
  INSURER_HIRE_PENDING = 'Pendente na Seguradora',
  PENDING = 'Pendente',
  PENDING_BY_INSURER = 'Solicitado para Seguradora',
  FINANCIAL_PENDING = 'Pendente Financeiro',
  CLAIM_CANCELLATION = 'Cancelamento Sinistro',
  UNCANCELLED = 'Não concluído',
  EXPIRED = 'Expirado',
}

export enum CanceledOrigin {
  ANALYZES = 'ANALYZES',
  PROPOSALS = 'PROPOSALS',
  POLICIES = 'POLICIES',
  EXTERNAL = 'EXTERNAL',
  ALL = 'ALL'
}

export const statusOptions = [
  {
    key: CanceledStatusEnum.CANCELLATION_IN_PROGRESS,
    text: 'Em análise',
    value: CanceledStatusEnum.CANCELLATION_IN_PROGRESS
  },
  {
    key: CanceledStatusEnum.PENDING,
    text: 'Pendente',
    value: CanceledStatusEnum.PENDING
  },
  {
    key: CanceledStatusEnum.CANCELED,
    text: 'Concluído',
    value: CanceledStatusEnum.CANCELED,
  },
  {
    key: CanceledStatusEnum.UNCANCELLED,
    text: 'Não concluído',
    value: CanceledStatusEnum.UNCANCELLED,
  },
  {
    key: CanceledStatusEnum.EXPIRED,
    text: 'Expirado',
    value: CanceledStatusEnum.EXPIRED,
  }
];

export const cancellationReasonOptions: Array<DropdownItemProps> = [
  { value: CancellationReasonEnum.customerGaveUp, key: CancellationReasonEnum.customerGaveUp, text: 'Cliente desistiu da locação' },
  { value: CancellationReasonEnum.rentWithAnotherGuarantee, key: CancellationReasonEnum.rentWithAnotherGuarantee, text: 'Alugou com outra garantia' },
  { value: CancellationReasonEnum.rentWithGuarantor, key: CancellationReasonEnum.rentWithGuarantor, text: 'Alugou com fiador' },
  { value: CancellationReasonEnum.deliveryKeysAndNoDebts, key: CancellationReasonEnum.deliveryKeysAndNoDebts, text: 'Entrega de chaves e inexistência de débitos' },
  { value: CancellationReasonEnum.nonPayment, key: CancellationReasonEnum.nonPayment, text: 'Falta de pagamento' },
  { value: CancellationReasonEnum.divergenceAddress, key: CancellationReasonEnum.divergenceAddress, text: 'Divergência de endereço' },
  { value: CancellationReasonEnum.changeOfWarranty, key: CancellationReasonEnum.changeOfWarranty, text: 'Troca de garantia' },
  { value: CancellationReasonEnum.propertyPurchase, key: CancellationReasonEnum.propertyPurchase, text: 'Compra do imóvel' },
  { value: CancellationReasonEnum.errorInHiring, key: CancellationReasonEnum.errorInHiring, text: 'Erro na contratação' },
  { value: CancellationReasonEnum.errorInNumberOfInstallments, key: CancellationReasonEnum.errorInNumberOfInstallments, text: 'Erro no número de parcelas' },
  { value: CancellationReasonEnum.leaseAdvance, key: CancellationReasonEnum.leaseAdvance, text: 'Antecipação de locação' },
  { value: CancellationReasonEnum.changeOfTenant, key: CancellationReasonEnum.changeOfTenant, text: 'Troca de locatário' },
  { value: CancellationReasonEnum.changeOfInsurer, key: CancellationReasonEnum.changeOfInsurer, text: 'Troca de seguradora' },
  { value: CancellationReasonEnum.duplicationOfPolicy, key: CancellationReasonEnum.duplicationOfPolicy, text: 'Duplicidade de apólice' },
  { value: CancellationReasonEnum.claimClosed, key: CancellationReasonEnum.claimClosed, text: 'Sinistro encerrado' },
];
