import React from 'react';

import { Image, Grid } from 'semantic-ui-react';

import styled from 'styled-components';
import image from '../resources/imgs/404.png';
import { useEffect } from 'react'
import { get } from '../services/request';

const TitleContainer = styled.div`
  padding: 2rem;
  z-index: 10;

  h1 {
    color: #4369FA;
    font-size: 30px;
  }
  h4 {
    font-size: 18px;
  }

  .text {
    margin-top: 14px;
  }
`;



const PageMaintenance: React.FC = () => {

  useEffect(() => {
    async function serverOnline(){
      try{
        await get('/bond-insurances')
      }
      catch(err){
        console.log("Server in maintenence")
      }
    }
    serverOnline()
  }, [])

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <TitleContainer>
              <div>
                <h1>Ops... Desculpa!</h1>
              </div>
              <div className="text">
                <h4>Nosso sistema está em manutenção no momento!</h4>
              </div>
              <div>
                <h4>Estamos fazendo o nosso melhor e voltaremos em breve ( Com melhorias ).</h4>
              </div>
            </TitleContainer>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={10}>
            <Image src={image} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default PageMaintenance;
