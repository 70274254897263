import React from 'react';
import { Grid, Input } from 'semantic-ui-react';
import { maskCpfOrCnpj } from '../../../../../../../services/masks';
import { FormProps } from '../types';

interface OwnerFormProps {
  form: FormProps;
  validateForm: boolean;
  setForm: React.Dispatch<React.SetStateAction<FormProps>>;
}

const SaveQuotationModalOwnerForm: React.FC<OwnerFormProps> = ({
  validateForm,
  form,
  setForm
}: OwnerFormProps) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <label>
            NOME/RAZÃO SOCIAL*
            <Input
              placeholder="Nome"
              fluid
              error={validateForm && form?.ownerIdentification?.name === ''}
              value={form?.ownerIdentification?.name}
              onChange={(_, data) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  ownerIdentification: {
                    ...prevValue.ownerIdentification,
                    name: data?.value
                  }
                }))
              }
            />
          </label>
        </Grid.Column>
        <Grid.Column width={4}>
          <label>
            CPF/CNPJ*
            <Input
              placeholder="CPF/CNPJ"
              fluid
              error={validateForm && form?.ownerIdentification?.document === ''}
              value={maskCpfOrCnpj(form?.ownerIdentification?.document)}
              onChange={(_, data) => {
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  ownerIdentification: {
                    ...prevValue.ownerIdentification,
                    document: data?.value
                  }
                }));
              }}
            />
          </label>
        </Grid.Column>
        <Grid.Column width={4}>
          <label>
            E-MAIL*
            <Input
              placeholder="E-mail"
              type="email"
              fluid
              error={validateForm && form?.ownerIdentification?.email === ''}
              value={form?.ownerIdentification?.email}
              onChange={(_, data) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  ownerIdentification: {
                    ...prevValue.ownerIdentification,
                    email: data?.value
                  }
                }))
              }
            />
          </label>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SaveQuotationModalOwnerForm;
