import { RecoveryPasswordFormProps, FormValues } from './types';
import { RouteComponentProps } from 'react-router';
import { withFormik } from 'formik';
import RecoveryPasswordForm from './RecoveryPasswordForm';

import * as Yup from "yup";
import { recoveryPassword } from '../../services/auth';
import { successNotification, dangerNotification } from '../../services/notification';

export type RecoveryPasswordProps = RouteComponentProps & RecoveryPasswordFormProps;

const RecoveryPassword = withFormik<RecoveryPasswordProps, FormValues>({
  mapPropsToValues: props => ({
    email: props.initialEmail || ''
  }),

  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("O e-mail informado não é válido")
      .required("Favor informar o e-mail")
  }),

  async handleSubmit({ email }: FormValues, { setSubmitting }) {
    try {
      await recoveryPassword(email);
      successNotification('Sucesso!', 'Um link para redefinição da senha foi enviado para o e-mail informado')
    } catch (e) {
      dangerNotification('Oops...', e.message);
    }
    setSubmitting(false);
  }
})(RecoveryPasswordForm);

export default RecoveryPassword;
