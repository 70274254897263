import React, { useState, useEffect } from "react";
import {
  Button,
  Icon,
  Modal,
  Popup
} from "semantic-ui-react";
import { dangerNotification, successNotification, warningNotification } from "../../../../../../services/notification";
import { Container, Disclaimer, Text, LinkText, AlertText, CopyButton } from './styles';
import { IconFinger } from '../../../../../../components/IconFinger';
import { ConfigKeysEnum, getConfigKey } from "../../../../../../services/configKeys";

export type BiometricsModalProps = {
  insurer: string;
  url?: string;
}

const RegisterBiometricsModal = (props: BiometricsModalProps) => {
  const { insurer, url } = props;
  const [openModal, setOpenModal] = useState(false);
  const [link, setLink] = useState('');

  const handleCopyLink = (link: string) => {
    navigator.clipboard.writeText(link)
      .then(() => {
        successNotification('Sucesso!', 'Link copiado para a área de transferência!')
      })
      .catch((error) => {
        warningNotification('Ops...', 'Erro ao copiar o link, tente novamente');
        console.error('Erro ao copiar o link:', error);
      });
  }

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    async function getLink() {
      if(insurer === 'POTTENCIAL') {
        const response = await getConfigKey(ConfigKeysEnum.POTTENCIAL_REGISTER_BIOMETRICS_URL);

        if (response.success) {
          setLink(response.value);
        } else {
          console.log(response);
          dangerNotification('Atenção!', 'Erro ao carregar link do Cadastro de Biometria Pottencial');
        }
      } else {
        setLink(url ?? 'www.4seg.com.br')
      }

    }
    getLink();
  }, []);

  return (
    <div style={{ zIndex: 10, position: 'relative' }}>
      <Modal
        style={{
          position: "absolute", top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        closeIcon
        open={openModal}
        onClose={handleClose}
        trigger={
          <Popup
            content="Ver link para Biometria"
            trigger={
              <Button
                color='orange'
                icon
                onClick={handleOpen}
              >
                <IconFinger size={18} color='#FFFFFF' />
              </Button>
            }
          />
        }
      >
        <Modal.Content>
          <Container>
            <Disclaimer>
              <IconFinger size={20} color='#2185D0' />
              <h4>
                Link para Biometria
              </h4>
            </Disclaimer>

            <Text>Abaixo o link para coleta da biometria, que você deve compartilhar com o locatário.
              Somente após a realização da biometria o status será alterado para <b>APROVADO</b> e
              assim o seguro poderá ser contratado.
            </Text>

            <LinkText>
              <Icon name="linkify"/> Link para Biometria: <a href={link} target="_blank">Acessar</a>
              <Popup
                content="Copiar link"
                trigger={
                  <CopyButton
                    onClick={() => handleCopyLink(link)}
                  >
                    <Icon name="copy" color="grey" />
                  </CopyButton>
                }
              />
            </LinkText>
            <AlertText>A validade deste link é de 30 dias.</AlertText>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
export default RegisterBiometricsModal;
