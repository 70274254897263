import { Blacklist } from '../types/Blacklist';
import { Payload } from '../types/Request';
import { get, post, patch } from './request';

export const getList = async (filter?: any): Promise<Payload<Blacklist>> => {
  let response;
  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  try {
    response = await get(`/blacklist?${query}`);
  } catch (e) {
    throw e;
  }

  return response;
};

export const getById = async (id: string): Promise<Blacklist> => {
  let response;
  try {
    response = await get(`/blacklist/${id}`);
  } catch (e) {
    throw e;
  }

  return response;
};

export const create = async (blacklist: Blacklist): Promise<Blacklist> => {
  let response;
  try {
    response = await post(`/blacklist`, blacklist);
  } catch (e) {
    throw e;
  }

  return response;
};

export const update = async (id: string, blacklist: Blacklist): Promise<Blacklist> => {
  let response;
  try {
    response = await patch(`/blacklist/${id}`, blacklist);
  } catch (e) {
    throw e;
  }

  return response;
};
