import {InsurersListReducer, InsurersListState} from "../types/temp-types";

type InsurersRematchModel = {
  state: InsurersListState;
  reducers: InsurersListReducer;
}

const insurers: InsurersRematchModel = {
  state: {
    insurers: []
  },
  reducers: {
    updateInsurersList: (state: InsurersListState, payload: Partial<InsurersListState>) => {
      return {
        ...state,
        ...payload
      }
    }
  }
};

export default insurers;
