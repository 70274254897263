import { UserState } from "../../types/UserState";
import { BrokerState } from "../../types/BrokerState";
import { EstateState } from "../../types/EstateState";
import { ProposalForGetBondInsuranceProposalsListResponseDTO } from "../../dtos/bond-insurance-proposal-list/interfaces/proposal-for-get-bond-insurance-proposals-list-response-dto";
import { DropdownItemProps } from "semantic-ui-react";
import { CancellationReasonEnum } from "../../enums/cancellation-reason.enum";

export type ProposalsTableProps = {
}

export interface ProposalsListingRouteInfo {
  id: string;
}

export type ProposalsFilterData = {
  broker_id: string;
  estate_id: string;
  user_id: string;
  address?: string;
  name?: string;
  document?: string;
  start?: string;
  insurer?: string;
  end?: string;
  status?: number;
  accountManager?: string | null;
  bondInsuranceId?: string;
}

export type ProposalsRowProps = {
  proposal: ProposalForGetBondInsuranceProposalsListResponseDTO;
  user: UserState;
}

export type FilterData = {
  broker?: BrokerState;
  estate?: EstateState;
  broker_id: string;
  estate_id: string;
  property_code: string;
  document: string;
  property_zipcode: string;
}

export type CancelModalProps = {
  hiredDataId: string;
  bondInsuranceId: string;
  closeModal: () => void;
  callback: () => void;
  user_id?: string;
  setOpen: () => void;
  setFlagSubmit?: (a: boolean) => void;
  openModal: boolean;
}
export type CancellationInsuranceProps = {
  flagSubmit: boolean;
  callbackSubmit: (a: any) => void;
  setFlagSubmit: (a: boolean) => void;
  hiredDataId: string;
  closeModal: () => void;
}

export const cancellationReasonOptions: Array<DropdownItemProps> = [
  { value: CancellationReasonEnum.customerGaveUp, key: CancellationReasonEnum.customerGaveUp, text: 'Cliente desistiu da locação' },
  { value: CancellationReasonEnum.rentWithAnotherGuarantee, key: CancellationReasonEnum.rentWithAnotherGuarantee, text: 'Alugou com outra garantia' },
  { value: CancellationReasonEnum.rentWithGuarantor, key: CancellationReasonEnum.rentWithGuarantor, text: 'Alugou com fiador' },
  { value: CancellationReasonEnum.deliveryKeysAndNoDebts, key: CancellationReasonEnum.deliveryKeysAndNoDebts, text: 'Entrega de chaves e inexistência de débitos' },
  { value: CancellationReasonEnum.nonPayment, key: CancellationReasonEnum.nonPayment, text: 'Falta de pagamento' },
  { value: CancellationReasonEnum.divergenceAddress, key: CancellationReasonEnum.divergenceAddress, text: 'Divergência de endereço' },
  { value: CancellationReasonEnum.changeOfWarranty, key: CancellationReasonEnum.changeOfWarranty, text: 'Troca de garantia' },
  { value: CancellationReasonEnum.propertyPurchase, key: CancellationReasonEnum.propertyPurchase, text: 'Compra do imóvel' },
  { value: CancellationReasonEnum.errorInHiring, key: CancellationReasonEnum.errorInHiring, text: 'Erro na contratação' },
  { value: CancellationReasonEnum.errorInNumberOfInstallments, key: CancellationReasonEnum.errorInNumberOfInstallments, text: 'Erro no número de parcelas' },
  { value: CancellationReasonEnum.leaseAdvance, key: CancellationReasonEnum.leaseAdvance, text: 'Antecipação de locação' },
  { value: CancellationReasonEnum.changeOfTenant, key: CancellationReasonEnum.changeOfTenant, text: 'Troca de locatário' },
  { value: CancellationReasonEnum.changeOfInsurer, key: CancellationReasonEnum.changeOfInsurer, text: 'Troca de seguradora' },
  { value: CancellationReasonEnum.duplicationOfPolicy, key: CancellationReasonEnum.duplicationOfPolicy, text: 'Duplicidade de apólice' },
  { value: CancellationReasonEnum.claimClosed, key: CancellationReasonEnum.claimClosed, text: 'Sinistro encerrado' },
];
