import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Info } from '../../../components/Info';
import { DateToStringDate } from '../../../services/date';
import { GetCanceledBondInsurancesResponseDTO } from '../../../dtos/canceled-bond-insurance/get-canceled-bond-insurances-response.dto';
import { CanceledStatusEnum } from '../../../enums/status-canceled-bond-insurances';

export type Props = {
  canceledBondInsurance: GetCanceledBondInsurancesResponseDTO;
  statusWasCanceled: string;
};

export const BondInsuranceCancellationDetails = (props: Props) => {
  const { canceledBondInsurance, statusWasCanceled } = props;

  return (
    <>
      <Grid.Row>
        <Info name={'ID'} value={canceledBondInsurance?.id} width={8} />
        <Info name={'DATA DE CANCELAMENTO'} value={DateToStringDate(canceledBondInsurance?.createdAt)} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'MOTIVO'} value={canceledBondInsurance?.cancellation_reason} width={8} />
        <Info name={'CANCELADA POR'} value={canceledBondInsurance?.user.name} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'OBSERVAÇÕES CANCELAMENTO'} value={canceledBondInsurance?.observations} width={8} />
        <Info name={'STATUS SOLICITAÇÃO CANCELAMENTO'} value={statusWasCanceled} width={8} />
      </Grid.Row>

      {
        canceledBondInsurance?.status === CanceledStatusEnum.CANCELED &&
        <Grid.Row>
          <Info name={'STATUS ATUAL'} value="CANCELAMENTO CONFIRMADO"  width={8}/>
          <Info name={'OBSERVAÇÕES CONFIRMAÇÃO CANCELAMENTO'} value={canceledBondInsurance?.confirmObservations} width={8} />
        </Grid.Row>
      }

      {
        canceledBondInsurance?.status === CanceledStatusEnum.CANCELLATION_IN_PROGRESS &&
        <Grid.Row>
          <Info name={'STATUS ATUAL'} value="CANCELAMENTO EM ANDAMENTO" width={8} />
        </Grid.Row>
      }

      <Grid.Row>
        <Info name={'RESPONSÁVEL PELO CANCELAMENTO'} value={canceledBondInsurance.responsible?.name ?? ''} width={8} />
      </Grid.Row>
    </>
  )
}
