import {CurrentToggledMenuReducer, CurrentToggledMenuState} from "../types/temp-types";

type CurrentToggledMenuRematchModel = {
  state: CurrentToggledMenuState;
  reducers: CurrentToggledMenuReducer;
}

const currentToggledMenu: CurrentToggledMenuRematchModel = {
  state: {},
  reducers: {
    updateCurrentToggledMenu: (state: CurrentToggledMenuState, payload: CurrentToggledMenuState): CurrentToggledMenuState => payload
  }
};

export default currentToggledMenu;
