import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px 10px 80px;
  .error {
    input {
      background-color: #fff6f6 !important;
      border-color: #e0b4b4 !important;
      color: #9f3a38 !important;
      box-shadow: none !important;
    }
  };
  .ui.grid > .row {
    padding: 0px !important;
  }
`;

export const Subtitle = styled.h2`
  font-size: 24px;
  color: #2185d0;
`;

export const SecctionName = styled.h3`
  font-size: 18px;
  color: #2185d0;
  font-weight: 600;
  padding-top: 8px;
`;

export const Hr = styled.hr`
  border: 1px solid #ddd;
`;

export const DatePickerWrapper = styled.div`
  margin-top: 0;
  input::placeholder {
    color: #aeaeae;
  }
  input {
    margin-top: 0;
  }
`;

export const PreQuoteHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;

  height: 60px;
  min-width: 200px;

  color: #fff;
  background-color: #2185d0;

  > span {
    font-size: 20px;
    color: #ffffff;
  }

  .left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    width: 100%;
    > span {
      font-size: 22px;
    }
  }
`;

export const PreQuoteWrapper = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;
  margin-top: 1rem;
`;

export const RequirementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

type CoveragesRowProps = {
  offwhite?: boolean;
};

export const CoveragesRow = styled.div<CoveragesRowProps>`
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  @media (max-width: 420px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  justify-content: space-between;
  font-weight: 600;
  background-color: ${props => (props.offwhite ? '#eee' : '#fff')};
  padding: 10px 5px;
  margin: 0;
  .low-opacity {
    opacity: 0.5;
  }
  .required {
    color: #2185d0;
  }

  .slice-insurance-name {
    display: block;
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .insurance-name {
    display: flex;
    flex-direction: row;
  }

  .insurance-value {
    color: #2185d0;
  }
  .pencil-icon {
    margin-left: 5px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }

  .editable-coverages {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 8px;

    .editable-coverages-input {
      width: 150px;
      font-size: 14px;
    }

    .icon {
     cursor: pointer;
    }
  }

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    width: 20px;
    height: 17px;
    margin-right: 10px;
  }
  input[type='checkbox']:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
  }
  input[type='checkbox']:checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: 1px solid #2185d0;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 5px;
    left: 8px;
  }
`;

export const Summarysection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  font-weight: 600;
  justify-content: space-between;
  .budget {
    display: flex;
    flex-direction: column;
    .title {
      font-size: 16px;
      font-weight: 600;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    h1 {
      font-size: 25px;
      color: #2185d0;
      font-weight: 700;
    }
    span {
      font-size: 20px;
      color: #2185d0;
      font-weight: 600;
    }

    .total {
      color: #999;
      font-size: 16px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 420px) {
      width: 100%;
    }
    gap: 10px;
    button {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
    }
  }
`;
