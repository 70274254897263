export interface Blacklist {
  id?: string;
  name: string;
  document: string;
  observations?: string;
  status: BlacklistStatusEnum;
  createdAt?: Date;
  createdBy?: string;
}

export enum BlacklistStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}
