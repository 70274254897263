import { ClaimPropertyStatusEnum } from "../../../../../../enums/claim-property-status.enum";
import { ClaimValuesTypesEnum } from "../../../../enums/ClaimValues.enum";
import { CreateClaimEstateInitialInitialValuesType } from "../types/CreateClaimEstateInitialValuesTypes";

export const CreateClaimEstateInitialInfo: CreateClaimEstateInitialInitialValuesType =
{
  claimEstate: {
    status: "CREATED",
    observations: "",
    responsibleId: null,
    estateId: "",
    insurerId: "",
    createdBy: "",
    policyNumber: null,
    processNumber: null,
    claimCode: null,
    notificationEmails: ['']
  },
  claimProperty: {
    zipCode: "",
    country: "",
    city: "",
    complement: "",
    district: "",
    number: "",
    state: "",
    status: ClaimPropertyStatusEnum.occupied,
    street: "",
    deliveryKeysDate: undefined,
  },
  claimTenant: {
    document: "",
    fullName: "",
    email: "",
    cellphone: "",
  },
  claimFiles: [],
  claimValues: {
    condominiumFee: [
      {
        dueDate: undefined,
        value: undefined,
        totalInstallments: undefined,
        numberInstallment: undefined,
        valueType: ClaimValuesTypesEnum.condominium_fee,
      },
    ],
    energyBill: [
      {
        dueDate: undefined,
        value: undefined,
        totalInstallments: undefined,
        numberInstallment: undefined,
        valueType: ClaimValuesTypesEnum.energy_bill,
      },
    ],
    gasBill: [
      {
        dueDate: undefined,
        value: undefined,
        totalInstallments: undefined,
        numberInstallment: undefined,
        valueType: ClaimValuesTypesEnum.gas_bill,
      },
    ],
    penalty: [
      {
        dueDate: undefined,
        value: undefined,
        totalInstallments: undefined,
        numberInstallment: undefined,
        valueType: ClaimValuesTypesEnum.penalty,
      },
    ],
    paintingValue: [
      {
        dueDate: undefined,
        value: undefined,
        totalInstallments: undefined,
        numberInstallment: undefined,
        valueType: ClaimValuesTypesEnum.painting_value,
      },
    ],
    propertyDamageValue: [
      {
        dueDate: undefined,
        value: undefined,
        totalInstallments: undefined,
        numberInstallment: undefined,
        valueType: ClaimValuesTypesEnum.property_damage_value,
      },
    ],
    rentValue: [
      {
        dueDate: undefined,
        value: undefined,
        totalInstallments: undefined,
        numberInstallment: undefined,
        valueType: ClaimValuesTypesEnum.rent_value,
      },
    ],
    taxesValue: [
      {
        dueDate: undefined,
        value: undefined,
        totalInstallments: undefined,
        numberInstallment: undefined,
        valueType: ClaimValuesTypesEnum.taxes_value,
      },
    ],
    waterBill: [
      {
        dueDate: undefined,
        value: undefined,
        totalInstallments: undefined,
        numberInstallment: undefined,
        valueType: ClaimValuesTypesEnum.water_bill,
      },
    ],
    fireInsuranceBill: [
      {
        dueDate: undefined,
        value: undefined,
        totalInstallments: undefined,
        numberInstallment: undefined,
        valueType: ClaimValuesTypesEnum.fire_insurance_bill,
      },
    ],
  },
  damageControl: false
};
