import React from 'react';
import { Container } from './styles';
import { Icon } from 'semantic-ui-react';

const AddressWarningBanner: React.FC = () => {
  return (
    <Container>
      <div className="icon">
        <Icon name="exclamation" />
      </div>
      <div>
        <p>
          <b>Atenção -</b> O endereço do imóvel deverá ser o mesmo do contrato da locação.
        </p>
        <ul>
          <li>
            Caso o endereço tenha sido alterado e esteja diferente deste, favor solicitar
            uma reanálise.
          </li>
          <li>
            Caso o endereço esteja diferente do contrato de locação, lioderá haver recusa
            de sinistro pela Seguradora.
          </li>
        </ul>
      </div>
    </Container>
  );
};

export default AddressWarningBanner;
