/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, SyntheticEvent, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import EditCapitalizationModal from '../../../capitalization/components/edit-capitalization-modal/Modal';
import {
  Modal,
  Button,
  Form,
  Table,
  Icon,
  SemanticCOLORS,
  TextArea,
  Popup,
  Grid,
  Dimmer,
  Loader,
  Dropdown,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { ModalProps } from '../../../../components/types';
import { BondInsuranceAnalysisStatusEnum } from '../../../../enums/bond-insurance-analysis-status.enum';
import { analysisFileIdentifiers, statusOptions } from '../../types';
import StatusDropdown from '../../../../components/StatusDropdown';
import Input from '../../../../components/Input';
import {
  updateAnalysis,
  sendAnyLetter,
  downloadLetter
} from '../../../../services/bond-insurance';
import {
  successNotification,
  dangerNotification,
  warningNotification
} from '../../../../services/notification';
import { IconText } from '../../../../components/IconText';
import {
  formatAmount,
  maskCpfOrCnpj,
  maskMoney,
  unmaskMoney
} from '../../../../services/masks';
import { CalculationModeEnum } from '../../../../enums/calculation-mode.enum';
import { UserState } from '../../../../types/UserState';
import {
  BondInsuranceAnalysisForGetBondInsurancesResponseDTO,
  BondInsuranceForGetBondInsurancesResponseDTO,
  BondInsurancePropertyForGetBondInsurancesResponseDTO,
  NaturalTenantForGetBondInsurancesResponseDTO
} from '../../../../dtos/bond-insurance-list/get-bond-insurances-response.dto';
// import { FileAttachmentProps } from '../../../../components/UploadFiles';
import PottencialHireProposalModal from './components/PottencialHire/ModalHireProposal';
import { InsurersCodeEnum } from '../../../../enums/insurers-code.enum';
import GeneralHireProposalModal from './components/GeneralHire/ModalHireProposal';
import CancelModal from './components/CancellationModal/CancelModal';
import LogModal from './components/LogModal/LogModal';
import { PersonTypesEnum } from '../../../../enums/person-types.enum';
import { UserRolesEnum } from '../../../../enums/user-roles.enum';
import GeneralReanalysisModal from './components/ReanalysisModal/Modal';
import { CalculationTypeEnum } from '../../../../enums/calculation-type.enum';

import HirePortoModal from './components/PortoEssencialHire/ModalHireProposal';
import { EstateForGetEstatesResponseDTO } from '../../../../dtos/estate/estate-for-get-estates-response.dto';
import GaranttiHireProposalModal from './components/GaranttiHire/ModalHireProposal';
import moment from 'moment';
import HirePortoTradicionalModal from './components/PortoTradicionalHire/ModalHireProposal';
import { PurposesEnum } from '../../../../enums/purposes.enum';
import AuthorizationContainer from '../../../../components/AuthorizationContainer';
import { ActionEnum } from '../../../../enums/authz-action.enum';
import { FeatureEnum } from '../../../../enums/authz-feature.enum';
import { AnalysisAssuranceEnum } from '../../../../enums/analysis-assurance.enum';
import RegisterBiometricsModal from './components/RegisterBiometricsModal';
import EditPlansModal from './components/EditPlansModal';

export const ConfirmationModalContent = styled(Modal.Content)`
  line-height: 1.8rem;
  div:last-child:not(:first-child) {
    font-weight: bold;
  }
`;

const InfoContainer = styled.div`
  width: 100%;

  background-color: #f7f0eb;
  padding: 1rem;
  border-radius: 2px;

  /* display: flex; */
  /* flex-direction: column; */

  /* > div {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.35rem;

    > div {
      flex: 1;
    }
  } */
`;

const RowButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
`;

export type AnalysisDetailsModal = {
  initialParcel: number[];
  bondInsurance: BondInsuranceForGetBondInsurancesResponseDTO;
  analysisDetails: BondInsuranceAnalysisForGetBondInsurancesResponseDTO[];
  naturalTentant: NaturalTenantForGetBondInsurancesResponseDTO;
  validityMonths: number;
  user: UserState;
  totalAmount: number;
  rentValue: number;
  calculationMode: CalculationModeEnum;
  calculationType?: CalculationTypeEnum;
  estate?: EstateForGetEstatesResponseDTO;
  property: BondInsurancePropertyForGetBondInsurancesResponseDTO;
  bondInsuranceId: string;
  externalBondInsuranceAnalysisId?: string;
  canEdit: boolean;
  cleanIdReferences?: () => void;
} & ModalProps;

const toCamel = (s: string) => {
  return s.replace(/([-_][a-z])/gi, $1 => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

const isArray = function (a: any) {
  return Array.isArray(a);
};

const isObject = function (o: any) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

const keysToCamel = function (o: any): any {
  if (isObject(o)) {
    const n = {} as any;

    Object.keys(o).forEach(k => {
      n[toCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i: any) => {
      return keysToCamel(i);
    });
  }

  return o;
};

const AnalysisProgressModal = (props: AnalysisDetailsModal): JSX.Element => {
  const { canEdit } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasChange, setHasChange] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [analysisChanged, setAnalysisChanged] = useState<number[]>([]);
  const [openHireProposalModal, setOpenHireProposalModal] = useState(false);
  const [loadingPottencialStatus, setLoadingPottencialStatus] = useState(false);
  const [showCapitalizationModal, setShowCapitalizationModal] = useState(false);
  const [isRequired, setIsRequired] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const open = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const allRefused = analysisDetails.every(
      (analysis: BondInsuranceAnalysisForGetBondInsurancesResponseDTO) => {
        return analysis.status === BondInsuranceAnalysisStatusEnum.rejected;
      }
    );
    setShowCapitalizationModal(allRefused);
    setIsOpen(true);
  };
  const close = (): void => {
    setIsOpen(false);
  };

  const { header, trigger, callback, user } = props;

  const {
    initialParcel,
    analysisDetails,
    validityMonths,
    totalAmount,
    rentValue,
    calculationMode,
    naturalTentant,
    estate,
    property,
    bondInsuranceId,
    bondInsurance
  } = props;

  const street = bondInsurance.property.street || null;
  const number = bondInsurance.property.number || null;
  const complement = bondInsurance.property.complement || null;
  const city = bondInsurance.property.city || null;
  const uf = bondInsurance.property.state || null;

  const address = `${street ? street : ''}${number ? ', ' + number : ''}${complement ? ' ' + complement : ''
    }${city ? ' - ' + city : ''}${uf ? ' - ' + uf : ''}`;

  const tenant_name =
    bondInsurance.tenant_type === 'NATURAL'
      ? bondInsurance.natural_tenant.main_tenant_name
      : bondInsurance.legal_tenant.company;
  const tenant_document =
    bondInsurance.tenant_type === 'NATURAL'
      ? bondInsurance.natural_tenant.main_tenant_cpf
      : bondInsurance.legal_tenant.document;

  const analysisFormData: any[] = [];

  const getOffers = (insurer: string, insurerResponse: any) => {
    if (insurer === InsurersCodeEnum.Garantti) {
      if (!insurerResponse) {
        const custom = { lmi: 36, residential: 12, commercial: 12 };
        const garanttiAnalysis: any = bondInsurance.bond_insurance_analysis.find(
          analysis => analysis.insurer.code === InsurersCodeEnum.Garantti
        );
        const installmentValue = garanttiAnalysis?.value;
        const installments =
          garanttiAnalysis?.complete_values?.maximum_installments_amount;
        const offer = [
          {
            quantidadeParcela: installments,
            valorParcela: Number(installmentValue),
            nomeSeguro: 'CUSTOM',
            id: 'custom',
            lmi: garanttiAnalysis.used_lmi,
            residentialFee: Number(garanttiAnalysis.used_taxes) * 100,
            commercialFee: Number(garanttiAnalysis.used_taxes) * 100
          }
        ];

        return offer;
      } else {
        const offers = insurerResponse.map((item: any) => {
          return {
            quantidadeParcela: item.rent_months_count,
            valorParcela:
              bondInsurance.purpose === PurposesEnum.residential
                ? item.residentialfee_assurance_month_amount
                : item.commercialfee_assurance_month_amount,
            nomeSeguro: String(
              item.description ? item.description : item.plan_id
            ).toUpperCase(),
            planoSelecionado: item.plan_id,
            id: item.id,
            lmi: item.lmi,
            residentialFee: item.residential_fee,
            commercialFee: item.commercial_fee
          };
        });

        const sortedOffers = offers.sort((a: { valorParcela: number }, b: { valorParcela: number }) => a.valorParcela - b.valorParcela)

        return sortedOffers;
      }
    }

    const sortedResponse = Array.isArray(insurerResponse) ? insurerResponse.sort((a: { valor_parcela: number }, b: { valor_parcela: number }) => a.valor_parcela - b.valor_parcela) : insurerResponse;

    return keysToCamel(sortedResponse);
  };

  analysisDetails.forEach(analysis => {
    const { id, status, observations } = analysis;
    const maximumInstallmentOption = analysis.complete_values?.installments_data.find(
      i => {
        return (
          i.installments_amount === analysis.complete_values.maximum_installments_amount
        );
      }
    );
    let maximumFormattedInstallmentValue: string;

    if (maximumInstallmentOption) {
      maximumFormattedInstallmentValue = maskMoney(
        `${maximumInstallmentOption.total_premium_amount}`,
        true
      );
    } else {
      maximumFormattedInstallmentValue = analysis.value
        ? maskMoney(`${analysis.value}`, true)
        : '0,00';
    }

    analysisFormData.push({
      id,
      status,
      observations,
      code: analysis.code,
      userId: analysis.user_id,
      user: analysis.user,
      offers:
        analysis.insurer_response || analysis.insurer.code === InsurersCodeEnum.Garantti
          ? getOffers(analysis.insurer.code, analysis.insurer_response)
          : [],
      insurer: analysis.insurer,
      value: maximumFormattedInstallmentValue,
      completeValues: analysis.complete_values,
      createdAt: analysis.created_at,
      updatedAt: analysis?.updated_at,
      preapprovedValue: analysis?.preapproved_value,
      biometryUrl: analysis?.biometry_url,
      insurerResponse: analysis?.insurer_response
    });

    // analysisFormData.sort(({ createdAt: a }, { createdAt: b }) => a < b ? -1 : a > b ? 1 : 0);
  });

  const getStatusColor = (status: string): string => {
    switch (status) {
      case BondInsuranceAnalysisStatusEnum.pending:
        return '#FBBD08';
      case BondInsuranceAnalysisStatusEnum.proposal:
        return '#710193';
      case BondInsuranceAnalysisStatusEnum.preapproved:
        return 'teal';
      case BondInsuranceAnalysisStatusEnum.analysis:
        return '#2185D0';
      case BondInsuranceAnalysisStatusEnum.approved:
        return '#21BA45';
      case BondInsuranceAnalysisStatusEnum.rejected:
        return '#DB2828';
      case BondInsuranceAnalysisStatusEnum.biometry:
        return '#FE9A76';
      case BondInsuranceAnalysisStatusEnum.waiting:
        return 'grey';
      case BondInsuranceAnalysisStatusEnum.error:
        return 'black';
      default:
        return 'grey';
    }
  };

  const getStatusText = (status: string): string => {
    switch (status) {
      case BondInsuranceAnalysisStatusEnum.waiting:
        return 'EM ESPERA';
      case BondInsuranceAnalysisStatusEnum.pending:
        return 'PENDENCIADO';
      case BondInsuranceAnalysisStatusEnum.proposal:
        return 'EM PROPOSTA';
      case BondInsuranceAnalysisStatusEnum.analysis:
        return 'EM ANÁLISE';
      case BondInsuranceAnalysisStatusEnum.approved:
        return 'APROVADO';
      case BondInsuranceAnalysisStatusEnum.preapproved:
        return 'PRÉ-APROVADO';
      case BondInsuranceAnalysisStatusEnum.rejected:
        return 'REPROVADO';
      case BondInsuranceAnalysisStatusEnum.biometry:
        return 'BIOMETRIA';
      default:
        return 'INDEFINIDO';
    }
  };

  type DocumentsComponentProps = {
    registerId: string;
  };

  useEffect(() => {
    if (props.externalBondInsuranceAnalysisId) {
      open();
    }
  }, [open, props.externalBondInsuranceAnalysisId]);

  function HireModal({ analysis }: any): JSX.Element {
    const analysisComp = bondInsurance?.bond_insurance_analysis.find(
      item => item.insurer.code === analysis.insurer.code
    );

    bondInsurance.analysis_assurance =
      (analysisComp?.analysis_assurance as AnalysisAssuranceEnum) ??
      bondInsurance.analysis_assurance;

    if (
      analysis.status === BondInsuranceAnalysisStatusEnum.biometry
    ) {
      return analysis.insurer.code !== 'PORTO_ESSENCIAL' ? <RegisterBiometricsModal insurer={analysis.insurer.code} url={analysis.biometryUrl} /> : <></>;
    }

    if (bondInsurance.tenant_type === PersonTypesEnum.natural) {
      if (analysis.insurer.code === InsurersCodeEnum.Pottencial) {
        return (
          <PottencialHireProposalModal
            callback={callback}
            bondInsurance={bondInsurance}
            property={property}
            rentOnly={false}
            user_id={user.id}
            estate_id={estate!.id}
            insurer={analysis.insurer}
            naturalTentant={naturalTentant}
            analysisID={analysis.id}
            approvedAt={analysis.updatedAt}
            maxInstallments={analysis.completeValues.maximum_installments_amount}
            nominal_total_premium_amount={
              analysis.completeValues?.nominal_total_premium_amount
            }
            closeModal={close}
            open={analysis.id === props.externalBondInsuranceAnalysisId}
            cleanIdReferences={props.cleanIdReferences}
          />
        );
      }
      if (
        InsurersCodeEnum.PortoEssencial === analysis.insurer.code &&
        analysis.code &&
        analysis.offers?.length > 0
      ) {
        return (
          <HirePortoModal
            bondInsurance={bondInsurance}
            analysis={analysis}
            estate={estate!}
            callback={callback}
            open={analysis.id === props.externalBondInsuranceAnalysisId}
            cleanIdReferences={props.cleanIdReferences}
          />
        );
      }
    }
    if (InsurersCodeEnum.Garantti === analysis.insurer.code) {
      return (
        <GaranttiHireProposalModal
          callback={callback}
          setOpen={setOpenHireProposalModal}
          bondInsurance={bondInsurance}
          rentOnly={false}
          username={user.name}
          insurer={analysis.insurer}
          property={property}
          user_id={user.id}
          estate_id={estate!.id}
          naturalTentant={naturalTentant}
          analysisID={analysis.id}
          maxInstallments={analysis.completeValues.maximum_installments_amount}
          nominal_total_premium_amount={
            analysis.completeValues?.nominal_total_premium_amount
          }
          closeModal={close}
          open={analysis.id === props.externalBondInsuranceAnalysisId}
          cleanIdReferences={props.cleanIdReferences}
          approvedAt={analysis.createdAt}
          offers={analysis.offers}
        />
      );
    }

    if (
      InsurersCodeEnum.Porto === analysis.insurer.code &&
      analysis.code &&
      !analysis.userId
    ) {
      return (
        <HirePortoTradicionalModal
          bondInsurance={bondInsurance}
          analysis={analysis}
          estate={estate!}
          callback={callback}
          open={analysis.id === props.externalBondInsuranceAnalysisId}
          cleanIdReferences={props.cleanIdReferences}
        />
      );
    }



    return (
      <GeneralHireProposalModal
        callback={callback}
        setOpen={setOpenHireProposalModal}
        bondInsurance={bondInsurance}
        rentOnly={false}
        username={user.name}
        insurer={analysis.insurer}
        property={property}
        user_id={user.id}
        estate_id={estate!.id}
        naturalTentant={naturalTentant}
        analysisID={analysis.id}
        maxInstallments={analysis.completeValues.maximum_installments_amount}
        nominal_total_premium_amount={
          analysis.completeValues?.nominal_total_premium_amount
        }
        closeModal={close}
        open={analysis.id === props.externalBondInsuranceAnalysisId}
        cleanIdReferences={props.cleanIdReferences}
        approvedAt={analysis.createdAt}
      />
    );
  }

  return (
    <Modal
      style={{ width: '95%' }}
      as={Form}
      open={isOpen}
      onOpen={open}
      onClose={close}
      trigger={trigger}
    >
      <Modal.Header>
        <Grid.Row
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Grid.Column>{header}</Grid.Column>

          <Grid.Column>
            <div style={{ display: 'flex', gap: 10 }}>
              {user.role === UserRolesEnum.brokerAdmin && (
                <LogModal
                  callback={callback}
                  closeModal={close}
                  analysisID={bondInsuranceId}
                  createdAt={bondInsurance.created_at}
                  updatedAt={
                    analysisFormData?.length > 0 &&
                    analysisFormData.reduce((r, o) =>
                      o.updated_at < r.updated_at ? o : r
                    )?.updatedAt
                  }
                />
              )}
              <CancelModal
                callback={callback}
                bondInsurance={bondInsurance}
                username={user.name}
                property={property}
                user_id={user.id}
                estate_id={estate?.id || ''}
                naturalTentant={naturalTentant}
                closeModal={close}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Modal.Header>
      <Modal.Content>
        <InfoContainer>
          <Grid columns={3}>
            <Grid.Column width={'7'}>
              <Grid.Row>
                <span>
                  <strong>Inquilino: </strong>
                  {tenant_name}
                </span>
              </Grid.Row>

              <Grid.Row>
                <span>
                  <strong>Endereço do Imóvel: </strong>
                  {address}
                </span>
              </Grid.Row>

              <Grid.Row>
                <span>
                  <strong>Valor do Aluguel: </strong>
                  {Number(bondInsurance.property.rent_value).toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency'
                  })}
                </span>
              </Grid.Row>

              <Grid.Row>
                <span>
                  <strong>Tipo de Locação: </strong>
                  {bondInsurance.purpose === 'RESIDENTIAL' ? 'Residencial' : 'Comercial'}
                </span>
              </Grid.Row>
            </Grid.Column>

            <Grid.Column width={'7'}>
              <Grid.Row>
                <span>
                  <strong>Documento: </strong>
                  {maskCpfOrCnpj(tenant_document)}
                </span>
              </Grid.Row>

              <Grid.Row>
                <span>
                  <strong>Imobiliária: </strong>
                  {bondInsurance.estate?.name}
                </span>
              </Grid.Row>

              <Grid.Row>
                <span>
                  <strong>Valor Final: </strong>
                  {Number(bondInsurance.property.total_amount).toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency'
                  })}
                </span>
              </Grid.Row>

              <Grid.Row>
                <span>
                  <strong>Tipo do Contratante: </strong>
                  {bondInsurance.tenant_type === 'NATURAL'
                    ? 'Pessoa Física'
                    : 'Pessoa Jurídica'}
                </span>
              </Grid.Row>
            </Grid.Column>

            <Grid.Column width={'2'} textAlign="right" verticalAlign="bottom">
              <GeneralReanalysisModal
                bondInsurance={bondInsurance}
                nominalTotalPremiumAmount={0}
                callback={callback}
                close={close}
              />
            </Grid.Column>
          </Grid>
        </InfoContainer>
      </Modal.Content>
      <Formik
        initialValues={{ analysis: analysisFormData }}
        validationSchema={{}}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit={() => { }}
      >
        {innerProps => {
          const { setFieldValue, setFieldTouched, values } = innerProps;
          const { analysis } = values;

          const getStatusLabel = () => {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                STATUS DA ANÁLISE
                <Popup
                  trigger={
                    <Icon
                      name="info circle"
                      color="blue"
                      size='large'
                      style={{ marginLeft: '16px' }}
                    />
                  }
                  content={
                    <div style={{ display: 'flex', width: '360px' }}>
                      <ul style={{ marginLeft: 0, paddingLeft: '24px' }}>
                        <li style={{ marginBottom: '16px' }}>
                          <h4>Pré-aprovado:</h4>
                          <p>
                            <strong>Atenção</strong>: este status de Pré-aprovado significa que a análise está em
                            estágio de pré-aprovação pois o valor aprovado foi menor que o valor total solicitado.
                          </p>
                        </li>
                        <li style={{ marginBottom: '16px' }}>
                          <h4>Biometria</h4>
                          <p>
                            <strong>Atenção</strong>: este status de Biometria indica que a análise está pendente do
                            cliente realizar o reconhecimento facial para conseguir prosseguir com a aprovação e contratação.
                          </p>
                        </li>
                        {/* <li style={{ marginBottom: '16px' }}>
                          <h4>Taxa setup</h4>
                          <p>
                            <strong>Atenção</strong>: este status indica que o cliente precisa realizar o pagamento
                            da taxa de setup solicitada.
                          </p>
                        </li> */}
                      </ul>
                    </div>
                  }
                />
              </div>
            )
          }

          return (
            <>
              <ConfirmationModalContent>
                <Dimmer active={loadingPottencialStatus}>
                  <Loader indeterminate> Aguarde... </Loader>
                </Dimmer>
                {showCapitalizationModal && (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      marginTop: '-15px'
                    }}
                  >
                    <span
                      style={{
                        textAlign: 'center',
                        marginLeft: 'auto',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        marginRight: '10px',
                        paddingTop: '4px',
                        color: '#2185d0'
                      }}
                    >
                      Seu cliente não foi aprovado no seguro fiança? Não perca negócio,
                      contrate agora um título de capitalização!
                    </span>
                    <EditCapitalizationModal
                      bondInsuance={bondInsurance}
                      trigger={
                        <Button color="green">
                          <span
                            style={{
                              textAlign: 'center',
                              fontSize: '16px'
                            }}
                          >
                            Contratar Título
                          </span>
                        </Button>
                      }
                    />
                  </div>
                )}
                <Table>
                  <Table.Header>
                    <Table.Row>
                      {!user.role!.includes('ESTATE') && (
                        <>
                          <Table.HeaderCell width={1}>#</Table.HeaderCell>
                          <Table.HeaderCell width={2}>SEGURADORA</Table.HeaderCell>
                          <Table.HeaderCell width={2}>{getStatusLabel()}</Table.HeaderCell>
                          <Table.HeaderCell width={3}>OBSERVAÇÕES</Table.HeaderCell>
                          <Table.HeaderCell width={2}>VALOR INFORMADO</Table.HeaderCell>
                          <Table.HeaderCell width={2}>VALOR DA PARCELA</Table.HeaderCell>
                          <Table.HeaderCell width={2}>AÇÕES</Table.HeaderCell>
                        </>
                      )}
                      {user.role!.includes('ESTATE') && (
                        <>
                          <Table.HeaderCell width={4}>SEGURADORA</Table.HeaderCell>
                          <Table.HeaderCell width={3}>{getStatusLabel()}</Table.HeaderCell>
                          <Table.HeaderCell width={3}>VALOR INFORMADO</Table.HeaderCell>
                          <Table.HeaderCell width={3}>VALOR DA PARCELA</Table.HeaderCell>
                          <Table.HeaderCell width={3}>AÇÕES</Table.HeaderCell>
                        </>
                      )}
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {analysis.map((a, index) => {
                      const isBefore25Day =
                        1637793334220 < new Date(a.createdAt).getTime();
                      const allRefused = analysis.every(a => a.status === BondInsuranceAnalysisStatusEnum.rejected);
                      const newInstallments = validityMonths;
                      const sendLetterEnabled =
                        (analysis[index].status ===
                          BondInsuranceAnalysisStatusEnum.approved &&
                          values.analysis[index] &&
                          (unmaskMoney(values.analysis[index].value) > 0 ||
                            a.insurer.code === InsurersCodeEnum.PortoEssencial)) ||
                        analysis[index].status ===
                        BondInsuranceAnalysisStatusEnum.rejected ||
                        analysis[index].status ===
                        BondInsuranceAnalysisStatusEnum.pending ||
                        analysis[index].status ===
                        BondInsuranceAnalysisStatusEnum.preapproved ||
                        analysis[index].status ===
                        BondInsuranceAnalysisStatusEnum.biometry;

                      let buttonText = 'Enviar Carta';
                      let buttonColor = 'blue';
                      if (a.status === BondInsuranceAnalysisStatusEnum.approved) {
                        buttonText = 'Enviar Carta de Aprovação';
                        buttonColor = 'green';
                      } else if (a.status === BondInsuranceAnalysisStatusEnum.rejected) {
                        buttonText = 'Enviar Carta de Recusa';
                        buttonColor = 'red';
                      } else if (a.status === BondInsuranceAnalysisStatusEnum.pending) {
                        buttonText = 'Enviar Carta de Pendência';
                        buttonColor = 'yellow';
                      } else if (a.status === BondInsuranceAnalysisStatusEnum.biometry) {
                        buttonText = 'Enviar Status Biometria';
                        buttonColor = 'orange';
                      } else if (
                        a.status === BondInsuranceAnalysisStatusEnum.preapproved
                      ) {
                        buttonText = 'Enviar Status Pré-aprovado';
                        buttonColor = 'teal';
                      }

                      return (
                        <>
                          <Table.Row key={index}>
                            {!user.role!.includes('ESTATE') && (
                              <Table.Cell>
                                <Icon
                                  name="circle"
                                  style={{ color: getStatusColor(a.status) }}
                                ></Icon>
                                {a.user && (
                                  <Popup
                                    trigger={<Icon name="user" color="blue" />}
                                    content={
                                      a.user.name +
                                      ' - ' +
                                      moment(a.updatedAt).format('DD/MM/YYYY HH:mm:ss')
                                    }
                                  />
                                )}
                              </Table.Cell>
                            )}
                            <Table.Cell>
                              {
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                  <div>
                                    {a.insurer.name}  <br />
                                    <Popup
                                      content="Data e hora de realização da analise"
                                      trigger={
                                        <span
                                          style={{ color: '#afafaf', fontSize: '12px' }}
                                        >
                                          &gt;{' '}
                                          {format(
                                            new Date(a.createdAt),
                                            'dd/MM/yyyy HH:mm',
                                            { locale: ptBR }
                                          )}
                                        </span>
                                      }
                                    />
                                  </div>

                                  <div>
                                    {

                                    user.role === 'BROKER_ADMIN' &&
                                    (a.offers.length > 0 && a.status !== BondInsuranceAnalysisStatusEnum.rejected) &&
                                      <EditPlansModal analysis={a} closeModal={setIsOpen} />}
                                  </div>
                                </div>
                              }
                            </Table.Cell>
                            <Table.Cell>
                              {user.role!.includes('ESTATE') && (
                                <>
                                  {a.status ===
                                    BondInsuranceAnalysisStatusEnum.biometry ? (
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'flex-start'
                                      }}
                                    >
                                      <IconText
                                        iconName={'circle'}
                                        iconColor={getStatusColor(a.status)}
                                        text={getStatusText(a.status)}
                                      />
                                      <Popup
                                        trigger={
                                          <Icon
                                            name="warning sign"
                                            color="yellow"
                                            style={{ marginLeft: '16px' }}
                                          />
                                        }
                                        content={
                                          <div>
                                            <p>
                                              <strong>Atenção</strong>: este status de
                                              Biometria é um status intermediário
                                              criado para controlar o estágio do fiança
                                              que a seguradora solicita o reconhecimento
                                              da biometria facial para confirmar a
                                              aprovação do seguro. Sendo que neste
                                              estágio, poderá ocorrer Recusa e/ou
                                              alteração de valor original da análise
                                              (ainda que muito pequena) quando mudar de
                                              biometria para aprovado, após a
                                              confirmação da biometria facial pelo
                                              cliente.
                                            </p>
                                          </div>
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <IconText
                                      iconName={'circle'}
                                      iconColor={getStatusColor(a.status)}
                                      text={getStatusText(a.status)}
                                    />
                                  )}
                                </>
                              )}
                              {!user.role!.includes('ESTATE') && (
                                <>
                                  {a.status ===
                                    BondInsuranceAnalysisStatusEnum.biometry ? (
                                    <Popup
                                      style
                                      trigger={
                                        <Field
                                          name={`analysis[${index}].status`}
                                          value={a.status}
                                          defaultValue={
                                            a.status ===
                                              BondInsuranceAnalysisStatusEnum.error
                                              ? null
                                              : a.status
                                          }
                                          completeValue={true}
                                          placeholder={'Selecionar...'}
                                          options={statusOptions}
                                          component={StatusDropdown}
                                          disabled={!user.role!.includes('BROKER_ADMIN')}
                                          onChange={(_: SyntheticEvent, data: any) => {
                                            setHasChange(true);
                                            if (
                                              data.value ===
                                              BondInsuranceAnalysisStatusEnum.approved &&
                                              a.status ===
                                              BondInsuranceAnalysisStatusEnum.preapproved &&
                                              a.insurer.code.includes('PORTO')
                                            ) {
                                              setFieldValue(
                                                `analysis[${index}].value`,
                                                maskMoney('0', true)
                                              );
                                              setFieldValue(
                                                `analysis[${index}].userId`,
                                                user.id
                                              );
                                              setIsRequired(true);
                                            }

                                            setAnalysisChanged(
                                              analysisChanged.concat(index)
                                            );
                                            setFieldValue(
                                              `analysis[${index}].status`,
                                              data.value
                                            );

                                            setFieldValue(
                                              `analysis[${index}].userId`,
                                              user.id
                                            );
                                          }}
                                          onClose={() =>
                                            setFieldTouched(
                                              `analysis[${index}].status`,
                                              true
                                            )
                                          }
                                          fluid
                                        />
                                      }
                                      content={
                                        <div>
                                          <p>
                                            <strong>Atenção</strong>: este status de
                                            Biometria é um status intermediário criado
                                            para controlar o estágio do fiança que a
                                            seguradora solicita o reconhecimento da
                                            biometria facial para confirmar a aprovação do
                                            seguro. Sendo que neste estágio, poderá
                                            ocorrer Recusa e/ou alteração de valor
                                            original da análise (ainda que muito pequena)
                                            quando mudar de pbiometria para aprovado,
                                            após a confirmação da biometria facial pelo
                                            cliente.
                                          </p>
                                        </div>
                                      }
                                    />
                                  ) : (
                                    <Field
                                      name={`analysis[${index}].status`}
                                      value={a.status}
                                      defaultValue={
                                        a.status === BondInsuranceAnalysisStatusEnum.error
                                          ? null
                                          : a.status
                                      }
                                      completeValue={true}
                                      placeholder={'Selecionar...'}
                                      options={statusOptions}
                                      component={StatusDropdown}
                                      disabled={!canEdit} //{!user.role!.includes('BROKER_ADMIN')}
                                      onChange={(_: SyntheticEvent, data: any) => {
                                        setHasChange(true);
                                        setAnalysisChanged(analysisChanged.concat(index));
                                        setFieldValue(
                                          `analysis[${index}].status`,
                                          data.value
                                        );

                                        setFieldValue(
                                          `analysis[${index}].userId`,
                                          user.id
                                        );
                                      }}
                                      onClose={() =>
                                        setFieldTouched(`analysis[${index}].status`, true)
                                      }
                                      fluid
                                    />
                                  )}
                                </>
                              )}
                            </Table.Cell>
                            {!user.role!.includes('ESTATE') && (
                              <Table.Cell>
                                <Field
                                  name={`analysis[${index}].observations`}
                                  rows={1}
                                  disabled={!canEdit}
                                  value={a.observations}
                                  onChange={(_: SyntheticEvent, data: any) => {
                                    setHasChange(true);
                                    setAnalysisChanged(analysisChanged.concat(index));
                                    setFieldValue(
                                      `analysis[${index}].observations`,
                                      data.value
                                    );
                                  }}
                                  onBlur={() =>
                                    setFieldTouched(
                                      `analysis[${index}].observations`,
                                      true
                                    )
                                  }
                                  component={TextArea}
                                  fluid
                                />
                              </Table.Cell>
                            )}

                            {a.status === 'PREAPPROVED' ? (<Table.Cell>
                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '8px' }}>
                                  <Icon name='warning sign' color='yellow' size='small' />
                                  <p style={{ fontSize: 12, fontWeight: 600, textDecoration: 'line-through', color: '#A3A3A3' }}>R$ {Number(props.property.total_amount).toFixed(2).replace('.', ',')}</p>
                                </div>
                                <p style={{ fontSize: 12, fontWeight: 700, textAlign: 'center', color: '#393939', margin: 0, paddingBottom: '4px' }}>R$ {Number(a.preapprovedValue).toFixed(2).replace('.', ',') ?? 0}</p>
                                <p style={{ fontSize: 12, fontWeight: 400, textAlign: 'center', color: '#808080', margin: 0 }}>Aprovado com valor reduzido.</p>
                              </div>
                            </Table.Cell>) : (<Table.Cell>
                              <p style={{ fontSize: 12, fontWeight: 700, textAlign: 'center', color: '#393939', margin: 0, paddingBottom: '4px' }}>R$ {Number(props.property.total_amount).toFixed(2).replace('.', ',')}</p>
                            </Table.Cell>)}


                            {!user.role!.includes('ESTATE') && (
                              <Table.Cell>
                                <Grid style={{ padding: '10px 0 10px 0' }}>
                                  {a.offers.length > 0 &&
                                    (a.status !== BondInsuranceAnalysisStatusEnum.rejected && a.status !== BondInsuranceAnalysisStatusEnum.preapproved)
                                    ? a.offers.map((c: any, i: number) => {
                                      if (
                                        !c ||
                                        !c.quantidadeParcela ||
                                        !c.valorParcela ||
                                        !c.nomeSeguro
                                      ) {
                                        return;
                                      }
                                      return (
                                        <Popup
                                          trigger={
                                            <Grid.Row
                                              style={{
                                                alignItems: 'center',
                                                margin: '2px',
                                                padding: '2px'
                                              }}
                                            >
                                              <Grid.Column width={1}>
                                                {`${c.quantidadeParcela}x`}
                                              </Grid.Column>

                                              <Grid.Column width={13}>
                                                <Field
                                                  name={`analysis[${index}].offers[${i}].value`}
                                                  disabled={true}
                                                  value={c.valorParcela.toLocaleString(
                                                    'pt-BR',
                                                    {
                                                      style: 'currency',
                                                      currency: 'BRL'
                                                    }
                                                  )}
                                                  component={Input}
                                                  fluid
                                                />
                                              </Grid.Column>
                                            </Grid.Row>
                                          }
                                          content={`${c.nomeSeguro} ${InsurersCodeEnum.Garantti === a.insurer.code
                                            ? `${c.residentialFee || c.commercialFee
                                            }%`
                                            : ''
                                            }`}
                                          position={'left center'}
                                          key={c.id}
                                        />
                                      );
                                    })
                                    : (a.status !== BondInsuranceAnalysisStatusEnum.rejected &&
                                      a.status !== BondInsuranceAnalysisStatusEnum.preapproved) && (
                                      <Grid.Row
                                        style={{
                                          alignItems: 'center',
                                          marginBottom: '10px'
                                        }}
                                      >
                                        <Grid.Column width={1}>
                                          {`${newInstallments}x`}
                                        </Grid.Column>

                                        <Grid.Column width={13}>
                                          <Field
                                            name={`analysis[${index}].value`}
                                            required={isRequired}
                                            disabled={
                                              // analysis[index].status !==
                                              // BondInsuranceAnalysisStatusEnum.approved &&
                                              // analysis[index].status !==
                                              // BondInsuranceAnalysisStatusEnum.preapproved
                                              !canEdit
                                            }
                                            value={analysis[index].value}
                                            onChange={(
                                              _: SyntheticEvent,
                                              data: any
                                            ) => {
                                              setHasChange(true);
                                              setAnalysisChanged(
                                                analysisChanged.concat(index)
                                              );
                                              setFieldValue(
                                                `analysis[${index}].value`,
                                                formatAmount(data.value)
                                              );
                                              setFieldValue(
                                                `analysis[${index}].userId`,
                                                user.id
                                              );
                                            }}
                                            onBlur={() => {
                                              setFieldTouched(
                                                `analysis[${index}].value`,
                                                true
                                              );
                                            }}
                                            component={Input}
                                            fluid
                                          />
                                        </Grid.Column>
                                      </Grid.Row>
                                    )}
                                </Grid>
                              </Table.Cell>
                            )}
                            {user.role!.includes('ESTATE') && (
                              <Table.Cell>
                                {a.offers.length > 0 &&
                                  (a.status !== BondInsuranceAnalysisStatusEnum.rejected
                                    && a.status !== BondInsuranceAnalysisStatusEnum.preapproved
                                  ) ? (
                                  a.offers.map((c: any) => {
                                    if (
                                      !c ||
                                      !c.quantidadeParcela ||
                                      !c.valorParcela ||
                                      !c.nomeSeguro
                                    ) {
                                      return;
                                    }
                                    return (
                                      <Popup
                                        trigger={
                                          <p>
                                            {`${c.quantidadeParcela} x R$ ${maskMoney(
                                              `${c.valorParcela}`,
                                              true,
                                              2
                                            )}`}
                                          </p>
                                        }
                                        content={`${c.nomeSeguro} ${InsurersCodeEnum.Garantti === a.insurer.code
                                          ? `${c.residentialFee || c.commercialFee}%`
                                          : ''
                                          }`}
                                        position={'left center'}
                                        key={c.id}
                                      />
                                    );
                                  })
                                ) : a.value &&
                                  unmaskMoney(a.value) > 0 &&
                                  (a.status !== BondInsuranceAnalysisStatusEnum.rejected &&
                                    a.status !== BondInsuranceAnalysisStatusEnum.preapproved) ? (
                                  <div
                                    style={{ display: 'flex', alignItems: 'flex-start' }}
                                  >
                                    <p style={{ margin: 0 }}>
                                      {`${newInstallments} x R$ ${maskMoney(
                                        `${a.value}` || '0'
                                      )}`}
                                    </p>
                                  </div>
                                ) : (
                                  <p></p>
                                )}
                              </Table.Cell>
                            )}

                            <Table.Cell width={3}>
                              <RowButtons>
                                {a.status ===
                                  BondInsuranceAnalysisStatusEnum.biometry && (
                                    <HireModal analysis={a} />
                                  )}
                                {a.status === BondInsuranceAnalysisStatusEnum.approved &&
                                  (InsurersCodeEnum.PortoEssencial === a.insurer.code ||
                                    InsurersCodeEnum.Garantti === a.insurer.code ||
                                    initialParcel[index] !== 0) &&
                                  isBefore25Day && <HireModal analysis={a} />}
                                {a.status === BondInsuranceAnalysisStatusEnum.rejected &&
                                  a.insurer.code === InsurersCodeEnum.Garantti &&
                                  allRefused && (
                                    <GeneralReanalysisModal
                                      bondInsurance={bondInsurance}
                                      nominalTotalPremiumAmount={0}
                                      callback={callback}
                                      close={close}
                                    />
                                  )}
                                {a.status !== BondInsuranceAnalysisStatusEnum.approved &&
                                  !user.role!.includes('ESTATE') && (
                                    <AuthorizationContainer
                                      action={ActionEnum.send}
                                      feature={FeatureEnum.letters}
                                    >
                                      <Popup
                                        content={
                                          !openHireProposalModal &&
                                            a.status ===
                                            BondInsuranceAnalysisStatusEnum.approved
                                            ? 'Necessário informar o valor do seguro para habilitar o envio da carta de aprovação'
                                            : null
                                        }
                                        disabled={
                                          a.status !==
                                          BondInsuranceAnalysisStatusEnum.approved
                                        }
                                        trigger={
                                          <Button
                                            color={buttonColor as SemanticCOLORS}
                                            loading={isLoading}
                                            fluid
                                            disabled={!sendLetterEnabled}
                                            onClick={async () => {
                                              setIsLoading(true);
                                              try {
                                                if (!hasChange) await sendAnyLetter(a.id);
                                                else {
                                                  await updateAnalysis(analysis);
                                                  await sendAnyLetter(a.id);
                                                }
                                                successNotification(
                                                  'Sucesso',
                                                  'Carta enviada!'
                                                );
                                                close();
                                                callback();
                                              } catch (e) {
                                                dangerNotification(
                                                  'Oops...',
                                                  'Ocorreu um erro ao enviar a carta'
                                                );
                                              }
                                              setIsLoading(false);
                                            }}
                                          >
                                            {buttonText}
                                          </Button>
                                        }
                                      />
                                    </AuthorizationContainer>
                                  )}
                                {[
                                  BondInsuranceAnalysisStatusEnum.approved,
                                  BondInsuranceAnalysisStatusEnum.preapproved
                                ].includes(a.status) && (
                                    <Dropdown
                                      upward
                                      trigger={
                                        <Button icon>
                                          <Icon name="ellipsis vertical" />
                                        </Button>
                                      }
                                      icon={null}
                                      direction="left"
                                      floating
                                    >
                                      <Dropdown.Menu>
                                        <AuthorizationContainer
                                          action={ActionEnum.send}
                                          feature={FeatureEnum.letters}
                                        >
                                          <Dropdown.Item
                                            text={buttonText}
                                            icon="print"
                                            onClick={async () => {
                                              setIsLoading(true);
                                              try {
                                                if (!hasChange) await sendAnyLetter(a.id);
                                                else {
                                                  await updateAnalysis(analysis);
                                                }
                                                successNotification(
                                                  'Sucesso',
                                                  'Carta enviada!'
                                                );
                                                close();
                                                callback();
                                              } catch (e) {
                                                dangerNotification(
                                                  'Oops...',
                                                  'Ocorreu um erro ao enviar a carta'
                                                );
                                              }
                                              setIsLoading(false);
                                            }}
                                          ></Dropdown.Item>
                                        </AuthorizationContainer>
                                        <AuthorizationContainer
                                          action={ActionEnum.download}
                                          feature={FeatureEnum.letters}
                                        >
                                          <Dropdown.Item
                                            text="Download da carta"
                                            icon="download"
                                            onClick={async () => {
                                              setIsLoading(true);
                                              try {
                                                await downloadLetter(a.id, tenant_name);
                                                successNotification(
                                                  'Sucesso',
                                                  'Download realizado com sucesso!'
                                                );
                                              } catch (e) {
                                                dangerNotification(
                                                  'Oops...',
                                                  'Ocorreu um erro ao realizar o download carta'
                                                );
                                              }
                                              setIsLoading(false);
                                            }}
                                          ></Dropdown.Item>
                                        </AuthorizationContainer>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  )}
                              </RowButtons>
                            </Table.Cell>
                          </Table.Row>
                        </>
                      );
                    })}
                  </Table.Body>
                </Table>
              </ConfirmationModalContent>

              {
                <Modal.Actions>
                  {[
                    !user.role!.includes('ESTATE') && (
                      // <AuthorizationContainer action={ActionEnum.update} feature={FeatureEnum.analysis_value}>
                      <Button
                        color={'blue'}
                        loading={isLoading}
                        disabled={!canEdit}
                        onClick={async () => {
                          setIsLoading(true);
                          const verifyEmptyValue = analysis.filter(
                            a =>
                              a.status === BondInsuranceAnalysisStatusEnum.approved &&
                              a.value === '0,00' &&
                              a.insurer.code.includes('PORTO')
                          );
                          if (isRequired && verifyEmptyValue.length > 0) {
                            warningNotification(
                              'Aviso',
                              `O valor da análise da ${verifyEmptyValue[0].insurer.name} deve ser preenchido.`
                            );
                            setIsLoading(false);
                            return;
                          }
                          try {
                            await updateAnalysis(analysis);
                            successNotification(
                              'Sucesso',
                              'Análises atualizadas com sucesso!'
                            );
                            callback();
                          } catch (e) {
                            dangerNotification(
                              'Oops...',
                              'Ocorreu um erro ao atualizar as análises'
                            );
                          }
                          setIsLoading(false);
                          setIsOpen(false);
                        }}
                      >
                        <Icon name={'save'} />
                        Salvar
                      </Button>
                      //</AuthorizationContainer>
                    )
                  ]}
                </Modal.Actions>
              }
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
export default connect()(AnalysisProgressModal);
