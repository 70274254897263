import React from 'react';
import { Grid } from 'semantic-ui-react';
import { BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO } from '../../../../../dtos/bond-insurance-proposal-list/get-complete-bond-insurance-proposal-by-id-response.dto';
import { Info } from '../../../../../components/Info';
import { DateToStringDate } from '../../../../../services/date';
import { maskCpfOrCnpj, maskMoney, maskPhoneNumber } from '../../../../../services/masks';
import { FieldLabel } from '../../../../../styles';

export type Props = {
  bondInsurance: BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO;
};

export const BondInsuranceNaturalTenantDetails = (props: Props) => {
  const { naturalTenant } = props.bondInsurance;

  return (
    <>
      <Grid.Row>
        <Info name={'NOME'} value={naturalTenant.main_tenant_name} width={4} />
        {naturalTenant.has_social_name ?
        (<>
          <Info name={'NOME SOCIAL'} value={naturalTenant.main_tenant_social_name} width={3} />
          <Info name={'GÊNERO'} value={naturalTenant.main_tenant_gender} width={3} />
          <Info name={'CPF'} value={maskCpfOrCnpj(naturalTenant.main_tenant_cpf)} width={3} />
          <Info name={'DATA DE NASCIMENTO'} value={DateToStringDate(naturalTenant.main_tenant_birth_date)} width={3} />
        </>) :
        (<>
          <Info name={'GÊNERO'} value={naturalTenant.main_tenant_gender} width={4} />
          <Info name={'CPF'} value={maskCpfOrCnpj(naturalTenant.main_tenant_cpf)} width={4} />
          <Info name={'DATA DE NASCIMENTO'} value={DateToStringDate(naturalTenant.main_tenant_birth_date)} width={4} />
        </>)}
      </Grid.Row>
      <Grid.Row>
        <Info name={'E-MAIL'} value={naturalTenant.main_tenant_email} width={4} />
        <Info
          name={'TELEFONE'}
          value={
            naturalTenant.main_tenant_phone
              ? maskPhoneNumber(naturalTenant.main_tenant_phone)
              : 'Não informado'
          }
          width={4}
        />
        <Info
          name={'NOME DA MÃE'}
          value={naturalTenant.main_tenant_mother_name}
          width={4}
        />
        <Info
          name={'ESTADO CIVIL'}
          value={naturalTenant.main_tenant_marital_status}
          width={4}
        />
      </Grid.Row>
      <Grid.Row>
        <Info
          name={'CÔNJUGE COMPÕE RENDA'}
          value={naturalTenant.partner_rent_composition ? 'Sim' : 'Não'}
          width={4}
        />
        {naturalTenant.partner_rent_composition && (
          <Info name={'NOME (CÔNJUGE)'} value={naturalTenant.partner_name} width={4} />
        )}
        {naturalTenant.partner_rent_composition && (
          <Info
            name={'CPF (CÔNJUGE)'}
            value={maskCpfOrCnpj(naturalTenant.partner_cpf)}
            width={4}
          />
        )}
      </Grid.Row>
      <Grid.Row>
        <Info
          name={'VÍNCULO EMPREGATÍCIO'}
          value={naturalTenant.employment_relationship}
          width={4}
        />
        <Info
          name={'DATA DE ADMISSÃO'}
          value={
            naturalTenant.employment_admission_date
              ? DateToStringDate(naturalTenant.employment_admission_date)
              : ''
          }
          width={4}
        />
      </Grid.Row>
      {/* <Grid.Row>
        <Info name={'PROFISSÃO'} value={naturalTenant.main_tenant_profession ? naturalTenant.main_tenant_profession : 'Indisponível'} width={4} />
        <Info name={'RENDA MENSAL'} value={naturalTenant.main_tenant_monthly_fixed_income ?
          maskMoney(`${naturalTenant.main_tenant_monthly_fixed_income}`, true) : 'Indisponível'} width={4} />
        <Info name={'OUTRAS RENDAS'} value={maskMoney(`${naturalTenant.main_tenant_other_incomes || 0}`, true)} width={4} />
      </Grid.Row> */}
      {!naturalTenant.resident && (
        <Grid.Row>
          <Info
            name={'RESIDIRÁ NO IMÓVEL?'}
            value={naturalTenant.resident ? 'Sim' : 'Não'}
            width={4}
          />
          <Info
            name={'NOME DO RESIDENTE'}
            value={naturalTenant.resident_name}
            width={4}
          />
          <Info
            name={'DATA DE NASC. DO RESIDENTE'}
            value={DateToStringDate(naturalTenant.resident_birth_date)}
            width={4}
          />
          <Info
            name={'CPF DO RESIDENTE'}
            value={
              naturalTenant.resident_cpf
                ? maskCpfOrCnpj(naturalTenant.resident_cpf)
                : 'Não informado'
            }
            width={4}
          />
        </Grid.Row>
      )}
      {naturalTenant.include_sympathise_tenants
        ? naturalTenant.sympathise_tenants.map((tenant, index) => {
            return (
              <>
                <Grid.Row style={{ paddingLeft: '15px' }}>
                  <FieldLabel style={{ color: '#364445', marginTop: '0px' }}>{`${
                    index + 1
                  }º LOCATÁRIO SOLIDÁRIO`}</FieldLabel>
                </Grid.Row>

                <Grid.Row>
                  <Info name={'NOME'} value={tenant.name} width={4} />
                  <Info
                    name={'DATA DE NASCIMENTO'}
                    value={
                      tenant.birth_date
                        ? DateToStringDate(new Date(tenant.birth_date))
                        : 'Indisponível'
                    }
                    width={4}
                  />
                  <Info name={'CPF'} value={maskCpfOrCnpj(tenant.cpf)} width={4} />
                  <Info
                    name={'VÍNCULO'}
                    value={tenant.employment_relationship}
                    width={4}
                  />
                </Grid.Row>

                <Grid.Row>
                  <Info
                    name={'VÍNCULO EMPREGATÍCIO'}
                    value={tenant.employment_relationship}
                    width={4}
                  />
                  <Info
                    name={'PROFISSÃO'}
                    value={tenant.profession ? tenant.profession : 'Indisponível'}
                    width={4}
                  />
                  <Info
                    name={'RENDA MENSAL'}
                    value={
                      tenant.monthly_fixed_income
                        ? maskMoney(`${tenant.monthly_fixed_income}`, true)
                        : 'Indisponível'
                    }
                    width={4}
                  />
                  <Info
                    name={'OUTRAS RENDAS'}
                    value={maskMoney(`${tenant.other_incomes || 0}`, true)}
                    width={4}
                  />
                </Grid.Row>
              </>
            );
          })
        : null}

      {naturalTenant.partners &&
        naturalTenant.partners.map((partner, index) => {
          return (
            <>
              <Grid.Row style={{ paddingLeft: '15px' }}>
                <FieldLabel style={{ color: '#364445', marginTop: '0px' }}>
                  {`${index + 1}º SÓCIO`}
                </FieldLabel>
              </Grid.Row>

              <Grid.Row>
                <Info name={'NOME'} value={partner.name} width={4} />
                <Info name={'CPF'} value={maskCpfOrCnpj(partner.document)} width={4} />
                <Info
                  name={'RENDA'}
                  value={partner.percent ? `${partner.percent} %` : 'NÃO INFORMADO'}
                  width={4}
                />
                <Info
                  name={'DATA DE ENTRADA'}
                  value={DateToStringDate(partner.date_of_entry)}
                  width={4}
                />
              </Grid.Row>
            </>
          );
        })}
    </>
  );
};
