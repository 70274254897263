import { Grid, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #ddd;

  h3 {
    color: #393939;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 5px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
  }
`;

export const CustomGridRow = styled(Grid.Row)`
  background-color: #ebebeb;
  margin-bottom: 10px;
  font-weight: 800;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  height: 110px;
  flex-wrap: wrap;
  align-items: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: 10px;
  h3 {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
  }

  small {
    color: #808080;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
  }
`;

export const ImageLinkContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
  align-items: baseline;
  margin-top: 5px;
  span {
    margin-right: 20px;
  }
`;

export const PlaceholderNumber = styled.span`
  color: #dddddd;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  text-align: left;
`;

export const Image = styled.img`
  height: 69px;
  min-width: 100px;
  width: auto;
  aspect-ratio: auto;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

export const CardGridRow = styled(Grid.Row)`
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
`;

export const ActionContainer = styled.div`
  display: flex;
  height: 110px;
  width: 100%;
  align-items: end;
  flex-direction: column;
  small {
    color: #999;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  input {
    color: #ddd;
  }
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 18px;
  justify-content: space-between;
`;
