import { withFormik } from "formik";
import SurtyPottencialInsuranceForm from "../Steps/SurtyInsuraceFormStep";
// import SurtyInsuranceForm from "../Steps/SurtyInsuraceFormStep";
import { SurtyPottencialInsuraceinitialValues } from "./initialStatesSurty";

export const SurtyPottencialInsuranceFormik = withFormik<any, typeof SurtyPottencialInsuraceinitialValues>({

  mapPropsToValues: () => SurtyPottencialInsuraceinitialValues,

  /* @To Do  Validation Schema  Ex.:
    validationSchema: (props: StepProps) => {
      const { bondInsuranceForm } = props;
        if (props.bondInsuranceForm.currentPage != 2) {
      return validationSchemas[props.bondInsuranceForm.currentPage]
    }*/

  handleSubmit: (values, bag) => true,

})(SurtyPottencialInsuranceForm);
