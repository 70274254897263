import React, { useEffect, useState } from 'react';
import { BondInsurance } from '../../../types/BondInsurance';
import PrintableModal from '../../../components/PrintableModal';
import { Dimmer, Grid, Loader, Message, Segment } from 'semantic-ui-react';
import SectionLabel from '../../../components/SectionLabel';
import { BondInsuranceForGetBondInsurancesResponseCamelCaseDTO } from "../../../dtos/bond-insurance-list/get-bond-insurances-response.dto";
import { GetCanceledBondInsurancesResponseDTO } from '../../../dtos/canceled-bond-insurance/get-canceled-bond-insurances-response.dto';
import { getExternalCancelation } from '../../../services/canceled-bond-insurance'
import { Info } from '../../../components/Info';

import { connect } from 'react-redux'
import { iRootDispatch, iRootState } from '../../../store';

import { CanceledStatusEnum } from '../../../enums/status-canceled-bond-insurances';
import { DateToStringDate } from '../../../services/date';
import { maskCpfOrCnpj } from '../../../services/masks';
import NotificationEmailsModal from './NotificationEmails/Modal';
import { Canceled } from './AnalysisProgressModal/types/ConfirmCancelModalProps';
import { UserRolesEnum } from '../../../enums/user-roles.enum';
import GenericLogModal from '../../../components/GenericLogModal/LogModal';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapState = (state: iRootState) => ({
  user: state.user,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatch = (dispatch: iRootDispatch) => ({
})

export type Props = {
  canceledBondInsurance?: GetCanceledBondInsurancesResponseDTO;
  bondInsurance?: BondInsuranceForGetBondInsurancesResponseCamelCaseDTO;
  isOpen: boolean;
  id: string;
  bondInsuranceId: string;
  changeStatus: (canceledId: string, status: string) => void;
  onClose: React.ReactEventHandler<{}>;
} & ReturnType<typeof mapState>;


const emptyCanceled: Canceled = {
  bondInsurance: {
    id: '',
    estate: {
      name: ''
    },
    property: {
      city: '',
      number: '',
      state: '',
      street: '',
    },
    tenant: {
      name: '',
      document: ''
    }
  },
  status: '',
  updatedAt: ''
}

const BondInsuranceDetailsComponent = (props: Props) => {
  const [cancelledBondInsurance, setCancelledBondInsurance] = useState<any>(null)
  const [isReady, setIsReady] = useState<boolean>(false)

  const { bondInsuranceId, user, isOpen, onClose, id, changeStatus } = props;
  const external = cancelledBondInsurance ? cancelledBondInsurance.externalCancelation : null;

  const [openEmailsModal, setOpenEmailsModal] = useState(false);

  const getData = async () => {
    setIsReady(false)
    getExternalCancelation(id).then(res => {
      setCancelledBondInsurance(res);
      setIsReady(true)
      changeStatus(id, res.status)
    }).catch(err => {
      console.error({ err })
    })
  }

  useEffect(() => {
    if (!!id.length) {
      getData()
    }
  }, [id])

  useEffect(() => {
    if (cancelledBondInsurance) {
      console.log(cancelledBondInsurance)
    } else {
      setIsReady(false)
    }
  }, [cancelledBondInsurance])

  const closeModal = (e: any) => {
    setCancelledBondInsurance(null)
    onClose(e);
  };

  return (
    <>
      <PrintableModal
        isOpen={isOpen}
        onClose={closeModal}
        header={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
           Seguro Fiança Cancelado - EXTERNO
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
            {user.role === UserRolesEnum.brokerAdmin && (
                 <GenericLogModal
                    createdAt={cancelledBondInsurance?.createdAt}
                    updatedAt={cancelledBondInsurance?.updatedAt}
                    relatedIds={
                      [
                        cancelledBondInsurance?.id,
                        cancelledBondInsurance?.externalCancelationId,
                      ]}
                />
              )}
            </div>
          </div>
      }
        bondInsuranceId={bondInsuranceId}
        content={
          <Grid>
            <Dimmer active={!isReady}>
              <Loader content={'Carregando dados'} />
            </Dimmer>

            {
              cancelledBondInsurance !== null && (
                <>
                  {
                    (
                      <Segment style={{ width: '100%', backgroundColor: '#f7f7f7', boxShadow: '0px 0px 17px 7px rgba(0,0,0,0.2)' }}>
                        {
                          (cancelledBondInsurance.observations || cancelledBondInsurance.confirmObservations) && (
                            <Grid padded>
                              <Grid.Row columns={'equal'} >
                                {
                                  cancelledBondInsurance.observations && (
                                    <Grid.Column width={'8'}>
                                      <Message info>
                                        <Message.Header>Observações - Solicitação do Cancelamento</Message.Header>
                                        <span>
                                          {cancelledBondInsurance.observations}
                                        </span>
                                      </Message>
                                    </Grid.Column>
                                  )
                                }

                                {
                                  cancelledBondInsurance.pendingObservations && (
                                    <Grid.Column width={'8'}>
                                      <Message info>
                                        <Message.Header>Observações - Cancelamento Pendente</Message.Header>
                                        <span>
                                          {cancelledBondInsurance.pendingObservations}
                                        </span>
                                      </Message>
                                    </Grid.Column>
                                  )
                                }

                                {
                                  cancelledBondInsurance.confirmObservations && (
                                    <Grid.Column width={'8'}>
                                      <Message info>
                                        <Message.Header>Observações - Confirmação do Cancelamento</Message.Header>
                                        <span>
                                          {cancelledBondInsurance.confirmObservations}
                                        </span>
                                      </Message>
                                    </Grid.Column>
                                  )
                                }
                              </Grid.Row>
                            </Grid>
                          )
                        }

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'  }} >
                          <NotificationEmailsModal
                              closeModal={() => setOpenEmailsModal(false)}
                              setOpen={() => setOpenEmailsModal(true)}
                              canceledId={cancelledBondInsurance.id}
                              openModal={openEmailsModal || false}
                              callback={getData}
                              emails={cancelledBondInsurance.notificationEmails}
                              triggerOptions={{
                                floated: 'right',
                                color: 'blue'
                              }}
                            />
                        </div>

                      </Segment>
                    )
                  }

                  <SectionLabel text='DADOS DO CANCELAMENTO' />
                  <Grid.Row>
                    <Info name={'ID'} value={cancelledBondInsurance?.id} width={8} />
                    <Info name={'DATA DE CANCELAMENTO'} value={DateToStringDate(cancelledBondInsurance?.createdAt)} width={8} />
                  </Grid.Row>
                  <Grid.Row>
                    <Info name={'MOTIVO'} value={cancelledBondInsurance?.cancellation_reason} width={8} />
                    <Info name={'CANCELADA POR'} value={cancelledBondInsurance?.user.name} width={8} />
                  </Grid.Row>
                  <Grid.Row>
                    <Info name={'OBSERVAÇÕES CANCELAMENTO'} value={cancelledBondInsurance?.observations} width={8} />
                    {
                    cancelledBondInsurance?.status === CanceledStatusEnum.CANCELED &&
                      <Info name={'STATUS ATUAL'} value="CANCELAMENTO CONFIRMADO"  width={8}/>
                    }
                    {
                      cancelledBondInsurance?.status === CanceledStatusEnum.CANCELLATION_IN_PROGRESS &&
                        <Info name={'STATUS ATUAL'} value="CANCELAMENTO EM ANDAMENTO"  width={8}/>
                    }
                  </Grid.Row>
                  {
                    cancelledBondInsurance?.status === CanceledStatusEnum.CANCELED &&
                    <Grid.Row>
                      <Info name={'OBSERVAÇÕES CONFIRMAÇÃO CANCELAMENTO'} value={cancelledBondInsurance?.confirmObservations} width={8} />
                    </Grid.Row>
                  }
                  <Grid.Row>
                    <Info name={'APÓLICE'} value={external.policyNumber ? external.policyNumber : ''} width={8} />
                    <Info name={'RESPONSÁVEL PELO CANCELAMENTO'} value={cancelledBondInsurance.responsible?.name ?? ''} width={8} />
                  </Grid.Row>

                  <SectionLabel text='DADOS DO IMÓVEL' />
                  <Grid.Row>
                    <Info name={'LOGRADOURO'} value={external.property.street} width={4} />
                    <Info name={'NÚMERO'} value={external.property.number} width={4} />
                    <Info name={'COMPLEMENTO'} value={external.property.complement} width={4} />
                    <Info name={'BAIRRO'} value={external.property.district} width={4} />
                  </Grid.Row>
                  <Grid.Row>
                    <Info name={'CIDADE'} value={external.property.city} width={4} />
                    <Info name={'ESTADO'} value={external.property.state} width={4} />
                    <Info name={'CEP'} value={external.property.zipCode} width={4} />
                    <Info name={'TIPO DE LOCAÇÃO'} value={''} width={4} />
                  </Grid.Row>

                  <SectionLabel text='DADOS DO LOCATÁRIO' />
                  <Grid.Row>
                    <Info name={'NOME'} value={external.tenant.fullName} width={8} />
                    <Info name={'CPF'} value={maskCpfOrCnpj(external.tenant.document)} width={8} />
                  </Grid.Row>
                </>
              )
            }
          </Grid>
        }
        callback={(): Promise<void | BondInsurance> => {
          return {} as Promise<BondInsurance>;
        }}
      />
    </>
  );
}

export const ExternalBondInsuranceDetails = connect(mapState, mapDispatch)(BondInsuranceDetailsComponent);
