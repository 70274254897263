import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';

import { styles } from '../styles';
import TableHeader from './TableHeader';

interface Coverages {
  data: Array<{
    coverageName: string;
    totalPremium: string;
    value: string;
    description: string;
  }>;
};

const CoveragesDataTable = ({ data }: Coverages) => {
  const rows = data.map((item, index) => {
    return (
      <View style={styles.row} key={index}>
        <View style={styles.column}>
          <Text style={styles.value}>{item.coverageName}</Text>
          {item.description ? <Text style={styles.description}>{`Franquia: ${item.description}`}</Text> : null}
        </View>

        <View style={styles.column}>
          <Text style={styles.value}>{item.value}</Text>
        </View>

        <View style={styles.column}>
          <Text style={styles.value}>{item.totalPremium}</Text>
        </View>
      </View>
    );
  });

  return (
    <>
      <View style={styles.tableContainer}>
        <TableHeader title={'COBERTURAS'} />

        <View style={styles.subRow}>
          <View style={styles.subColumn}>
            <Text style={styles.label}>{'TIPO'}</Text>
          </View>

          <View style={styles.subColumn}>
            <Text style={styles.label}>{'VALOR'}</Text>
          </View>

          <View style={styles.subColumn}>
            <Text style={styles.label}>{'PRÊMIO'}</Text>
          </View>
        </View>

        <Fragment>{rows}</Fragment>
      </View>
    </>
  );
};

export default CoveragesDataTable;
