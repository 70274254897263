import { post, get, put } from './request';
import { MessageType } from '../components/GeneralFollowUp';

export type GetFollowUpFiltersType = {
  id: string;
  context: string;
  isInternal: boolean;
};
export async function getMessages(filters: GetFollowUpFiltersType): Promise<any> {
  let url = `/follow-ups/messages`;
  let query = '';

  if (filters) {
    Object.entries(filters).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });

    url = `${url}?${query}`;
  }
  return await get(url);
}

export type Message = {
  relatedId: string;
  userId: string;
  text: string;
  senderName: string;
  context?: string;
  involved?: string;
  viewedBy?: string;
  bondInsuranceId?: string;
};

export async function sendMessage(
  relatedId: string,
  userId: string,
  text: string,
  senderName: string,
  context: string,
  involved: string | null,
  viewedBy: string | null,
  bondInsuranceId: string,
  isInternal: boolean,
): Promise<MessageType> {
  const payload = {
    related_id: relatedId,
    sender_id: userId,
    message: text,
    sender_name: senderName,
    context: context,
    involved: involved,
    viewed_by: viewedBy,
    bond_insurance_id: bondInsuranceId,
    isInternal
  };

  // console.log('PAY', payload);

  const response = await post('/follow-ups/save-message', payload);
  return response;
}

export async function updateViewed(id: string, viewed_by: string): Promise<any> {
  const payload = {
    viewed_by
  };
  const response = await put(`/follow-ups/update-message/${id}`, payload);
  return response;
}
