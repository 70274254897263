import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { LoginFormProps, FormValues } from './types';
import { iRootDispatch } from '../../store';
import * as Yup from 'yup';
import LoginForm from './LoginForm';
import { dangerNotification, warningNotification } from '../../services/notification';
import { CurrentPageState, CurrentToggledMenuState } from "../../store/types/temp-types";
import { UserState } from "../../types/UserState";
import { oAuthSignIn, redirectToCapturePage, redirectToOAuthLogin, checkUserExists } from '../../services/oauth';

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateUser: (user: UserState) => dispatch.user.updateUser(user),
  updateCurrentPage: (currentPage: CurrentPageState) => dispatch.currentPage.updateCurrentPage(currentPage),
  updateCurrentToggledMenu: (state: CurrentToggledMenuState) => dispatch.currentToggledMenu.updateCurrentToggledMenu(state)
});

export type LoginProps = ReturnType<typeof mapDispatch> & RouteComponentProps & LoginFormProps;

const Login = withFormik<LoginProps, FormValues>({
  mapPropsToValues: loginFormProps => ({
    email: loginFormProps.initialEmail || '',
    password: loginFormProps.initialPassword || ''
  }),

  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("O e-mail informado não é válido")
      .required("Favor informar o e-mail"),
  }),

  async handleSubmit({ email, password }: FormValues, { props, setStatus, setSubmitting }) {
    const normalizedEmail = email.toLowerCase();
    oAuthSignIn(normalizedEmail, password).then(async (response) => {
      if (response) {
        redirectToOAuthLogin(normalizedEmail);
      } else {
        const userExists = await checkUserExists(normalizedEmail);
        if (userExists && !userExists.migrated) {
          props.history.push(`/login#email=${normalizedEmail}`);
          warningNotification('Oops...', 'Você ainda não está cadastrado no Grupo RV!');
        } else {
          dangerNotification('Oops...', 'Ocorreu um erro na tentativa de login, por favor contate o suporte!');
          setSubmitting(false);
        }
      }
    }).catch((e) => {
      redirectToCapturePage();
    })
  }
})(LoginForm);

export default connect(null, mapDispatch)(Login);
