import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Dimmer, Dropdown, DropdownProps, Grid, Header, Input, Loader, Modal } from 'semantic-ui-react';

import { FieldLabel } from '../../../../styles';
import { ObservationsText } from './styles';
import { EditCancelPolicyModalProps } from './interfaces/cancel-policy.interface';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import AppMaskedInput from '../../../../components/MaskedInput';
import { DATE_INPUT_MASK, numberToMoneyString } from '../../../../services/masks';
import { successNotification, warningNotification } from '../../../../services/notification';
import { cancelProposalRefundInfo, getProposalRefundInfo } from '../../../../services/fire-insurance-proposal';
import { get } from '../../../../services/storage';

export const CANCELLATION_OPTIONS = [
  {
    value: 'CANCELATION_REQUEST',
    key: 'CANCELATION_REQUEST',
    text: 'Cancelamento Solicitado',
  },
  {
    value: 'CANCELED',
    key: 'CANCELED',
    text: 'Cancelada',
  },
];

const EditCancelPolicyModal = (props: EditCancelPolicyModalProps) => {
  const history = useHistory();
  const user_role = get('role');

  const { open, close, data } = props;
  const [status, setStatus] = useState<string>(data.status);
  const [refundValue, setRefundValue] = useState<number | undefined>(undefined);
  const [expectedPaymentDate, setExpectedPaymentDate] = useState<string | Date | undefined>('');
  const [refundObservations, setRefundObservations] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOnChangeStatus = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    setStatus(data.value as string);
  }

  const handleOnChangeRefundValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const onlyNumber = event.target.value.replace(/\D/g, '');
    const value = Number(onlyNumber) / 100;

    setRefundValue(value);
  }

  const handleOnChangeExpectedPaymentDate = (date: Date) => {
    setExpectedPaymentDate(date);
  }

  const handleOnChangeRefundObservations = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRefundObservations(event.target.value);
  }

  const handleOnClickClose = () => {
    setRefundValue(undefined);
    setExpectedPaymentDate(undefined);
    setRefundObservations(undefined);

    close();
  }

  const handleOnClickSave = async () => {
    setIsLoading(true);

    if (!status ||
      typeof refundValue !== 'number' ||
      typeof expectedPaymentDate !== 'object'
    ) {
      setIsLoading(false);

      return warningNotification(
        'Opps...',
        'por favor, preencha os campos obrigatórios.'
      );
    }

    const payload = {
      policyId: data.policyId,
      analysisId: data.analysisId,
      status,
      refundValue,
      expectedPaymentDate,
      refundObservations,
    };

    try {
      const response = await cancelProposalRefundInfo(payload);

      if (response.status === 200) {
        successNotification(
          'Sucesso',
          'Dados da restituição salvos com sucesso.'
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      history.push('/admin/fire-insurance-policies');
    }
  }

  async function getCancelData(analysisId: string) {
    setIsLoading(true);
    try {
      const response = await getProposalRefundInfo(analysisId);

      if (response) {
        response?.status && setStatus(response.status);
        response?.refundValue && setRefundValue(Number(response.refundValue));
        response?.expectedPaymentDate && setExpectedPaymentDate(new Date(response.expectedPaymentDate));
        response?.refundObservations && setRefundObservations(response.refundObservations);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const getData = async (analysisId: string) => {
      getCancelData(analysisId)
    }
    data.analysisId && getData(data.analysisId);
  }, [data.analysisId]);

  return (
    <Modal
      open={open}
      onClose={close}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Header icon='edit' content='Editar cancelamento' />

      <Modal.Content scrolling>
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>

        <Grid style={{padding: 5}} columns={'equal'}>
          <Grid.Row>
            <Grid.Column>
              <FieldLabel required>Status</FieldLabel>
              <Dropdown
                fluid
                search
                clearable
                selection
                options={CANCELLATION_OPTIONS}
                value={status}
                onChange={handleOnChangeStatus}
                placeholder='Selecione o status do cancelamento'
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <FieldLabel required>Valor da restituição</FieldLabel>
              <Input
                fluid
                disabled={user_role?.includes('ESTATE')}
                value={numberToMoneyString(refundValue ?? 0)}
                onChange={handleOnChangeRefundValue}
              />
            </Grid.Column>

            <Grid.Column>
              <FieldLabel required>Data prevista do pagamento</FieldLabel>
              <CustomDatePicker
                fluid
                disabled={user_role?.includes('ESTATE')}
                type={'date'}
                placeholder={'Data prevista do pagamento'}
                selected={expectedPaymentDate}
                onChange={handleOnChangeExpectedPaymentDate}
                customInput={<AppMaskedInput mask={DATE_INPUT_MASK} />}
                dateFormat={'dd/MM/yyyy'}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <FieldLabel>Oberservações da restituição</FieldLabel>
              <ObservationsText
                fluid={'true'}
                rows={8}
                value={refundObservations}
                onChange={handleOnChangeRefundObservations}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{ margin: 0, padding: 0 }}>
            <Grid.Column>
              <FieldLabel>
                * Os valores podem sofrer alterações conforme período proporcional aos dias corridos para o cálculo do valor a ser restituído.
              </FieldLabel>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>

      <Modal.Actions>
        <Button
          color='red'
          disabled={isLoading}
          onClick={handleOnClickClose}
        >
          Cancelar
        </Button>
        <Button
          color='green'
          loading={!(status && refundValue && expectedPaymentDate) && isLoading}
          disabled={!(status && refundValue && expectedPaymentDate) || isLoading}
          onClick={handleOnClickSave}
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default EditCancelPolicyModal;
