import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Grid } from 'semantic-ui-react';
import { CustoDropDownItemProps } from '../../../components/types';
import { iRootDispatch, iRootState } from '../../../store';
import { FieldLabel } from '../../../styles';
import PottencialBudgetForm from './forms-based-in-insurer/Pottencial';
import useEffectOnce from '../../../services/UseEffectOnce';
import { findObjectInArray } from '../../../util';
import { AvailableBrokersAndEstatesState } from '../../../store/types/temp-types';
import { CustomDropDownOptions } from '../../../types';
import { InsurersCodeEnum } from '../../../enums/insurers-code.enum';
import SegimobBudgetForm from './forms-based-in-insurer/Segimob';
import PortoSeguroBudgetForm from './forms-based-in-insurer/PortoSeguro';
import AlfaBudgetForm, { BudgetContext } from './forms-based-in-insurer/Alfa';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { successNotification } from '../../../services/notification';
import GeneralCondition from './GeneralConditionModal';
import { FireInsuranceActiveInsurersResponseDTO } from '../../../dtos/fire-insurance-budget/fire-insurance-active-insurers-response.dto';
import { getFireInsuranceActiveInsurers } from '../../../services/insurer';
import GenericLogModal from '../../../components/GenericLogModal/LogModal';
import { UserRolesEnum } from '../../../enums/user-roles.enum';

export type FireInsuranceBudgetFormProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const FireInsuranceBudgetForm = (props: FireInsuranceBudgetFormProps) => {
  const { id: fireInsuranceAnalysisId } = useParams<{ id?: string }>();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let context = queryParams.get('context') as BudgetContext;
  const estateParamId = queryParams.get('estateId');
  const insurerParamId = queryParams.get('insurerId');
  const policyOriginParam = queryParams.get('policyOrigin');

  const user = props.user;
  const [estateOptions, setEstateOptions] = useState<CustoDropDownItemProps[]>([]);
  const [selectedEstate, setSelectedEstate] = useState<any | null>(null);
  const [insurerOptions, setInsurerOptions] = useState<CustoDropDownItemProps[]>([]);
  const [LoadingInsurerOptions, LoadingSetInsurerOptions] = useState<boolean>(false);
  const [selectedInsurer, setSelectedInsurer] = useState<any | null>(null);
  const [availableInsurers, setAvailableInsurers] = useState<any[] | null>(null);
  const [insurerId, setInsurerId] = useState<string | null>(null);
  const [commissionPercentage, setCommissionPercentage] = useState<number>(0);
  const [openConditions, setOpenConditions] = useState<boolean>(false);
  const [dropDownSelectEstateIsOpen, setDropDownSelectEstateIsOpen] = useState<boolean>(false);

  const getEnabledInsurer = async (selectedEstateId: string) => {
    LoadingSetInsurerOptions(true);

    try {
      const response: FireInsuranceActiveInsurersResponseDTO = await getFireInsuranceActiveInsurers(selectedEstateId)
      setAvailableInsurers(response.fire_insurance_active_insurers);

      const insurers = response.fire_insurance_active_insurers.map((insurer: any) => ({
        key: insurer.id,
        text: insurer.name,
        value: insurer,
        image: insurer.image_url,
      }));

      setInsurerOptions(insurers);
    } catch (error) {
      console.log(error);
    } finally {
      LoadingSetInsurerOptions(false);
    }
  };

  useEffectOnce(() => {
    if (props.availableBrokersAndEstates) {
      setEstateOptions(props.availableBrokersAndEstates.estates);
    }
  });

  useEffect(() => {
    async function loadData() {
      if (fireInsuranceAnalysisId) {
        try {
          if (fireInsuranceAnalysisId.length !== 36) {
            // apagar código
            history.push('/admin/fire-insurance-budget');
            return;
          }

          const estate = props.availableBrokersAndEstates.estates?.find(
            c => c.value.id === estateParamId
          );

          if (estate) {
            handleSelectEstate(estate.value);
          }
        } catch (err) {
          console.log(err);
        }
      }
    }

    loadData();
  }, [fireInsuranceAnalysisId, history, props.availableBrokersAndEstates.estates]);

  useEffect(() => {
    if (estateOptions.length === 1) {
      const estate = estateOptions[0].value;
      setSelectedEstate(estate);
      setDropDownSelectEstateIsOpen(true);
    }
  }, [estateOptions]);

  useEffect(() => {
    if (selectedEstate !== null) {
      getEnabledInsurer(selectedEstate.id);
    } else {
      if (selectedEstate === '') {
        setSelectedEstate(null);
        setAvailableInsurers(null);
        setDropDownSelectEstateIsOpen(false);
      }
    }
  }, [selectedEstate]);

  useEffect(() => {
    if (availableInsurers) {
      setSelectedInsurer(null);
      setInsurerId(null);
      if (availableInsurers.length === 1) {
        setSelectedInsurer(availableInsurers[0].code);
        setInsurerId(availableInsurers[0].id);
      }
    }
  }, [availableInsurers]);

  useEffect(() => {
    if (insurerOptions.length === 1) {
      setSelectedInsurer(insurerOptions[0].value);
      setInsurerId(insurerOptions[0].value.id);
    }
  }, [selectedInsurer, insurerOptions]);

  const renderForm = () => {
    if (selectedEstate !== null && selectedInsurer.code !== null) {
      if (!context) {
        context = BudgetContext.new;
      }

      switch (selectedInsurer.code) {
        case InsurersCodeEnum.Pottencial:
          return (
            <PottencialBudgetForm
              estateId={selectedEstate.id}
              insurerId={insurerId!}
              userId={user.id}
              percentage={commissionPercentage}
            />
          );
        case InsurersCodeEnum.TokioMarineSegimob:
          return (
            <SegimobBudgetForm
              estate={selectedEstate}
              insurerId={insurerId!}
              userId={user.id}
              percentage={commissionPercentage}
            />
          );
        case InsurersCodeEnum.Porto:
          return (
            <PortoSeguroBudgetForm
              estate={selectedEstate}
              insurerId={insurerId!}
              userId={user.id}
              percentage={commissionPercentage}
            />
          );
        case InsurersCodeEnum.alfa:
          return (
            <AlfaBudgetForm
              estate={selectedEstate}
              insurerId={insurerId!}
              userId={user.id}
              context={context}
              policyOrigin={policyOriginParam}
              budgetId={fireInsuranceAnalysisId}
              percentage={commissionPercentage}
            />
          );
        default:
          return (
            <h1> Acesso negado! </h1>
          );
      }
    }
  };

  const handleSelectEstate = async (estate: any) => {
    setSelectedEstate(estate);

    if (Array.isArray(estate.fireInsurers)) {
      const insurers = estate.fireInsurers.map((insurer: any) => ({
        key: insurer.id,
        text: insurer.name,
        value: insurer,
        image: insurer.imageUrl
      }));

      if (insurerParamId) {
        const insurer = insurers.find((insurer: any) => insurer.value.id === insurerParamId);
        if (insurer) {
          successNotification('Seguradora selecionada', `Seguradora ${insurer?.text} selecionada`)
          setInsurerOptions([insurer]);
          return;
        }
      }

      setInsurerOptions(insurers);
    }

    setDropDownSelectEstateIsOpen(true);
  };

  const handleSelectInsurer = async (insurer: any) => {
    if (!!props.availableBrokersAndEstates.estates.length) {
      const getThisEstate = props.availableBrokersAndEstates.estates.find(
        (estate: CustomDropDownOptions) => estate.value.id === selectedEstate.id
      )?.value;

      const getThisInsurer = findObjectInArray(
        getThisEstate.insurer_data,
        'insurer_id',
        insurer.id
      );

      const percentage = Number(getThisInsurer?.commission_percentage) || 0;
      setCommissionPercentage(percentage);
    }

    setSelectedInsurer(insurer);
    setInsurerId(insurer.id);
  };

  const w100 = {
    width: '100%'
  };

  const columnDistance = {
    marginBottom: 20
  };

  const toggleOpenConditions = () => {
    setOpenConditions(true);
  };

  const toggleCloseConditions = () => {
    setOpenConditions(false);
  };

  return (
    <>
      <Grid style={w100} centered>
        <Grid.Row style={{ textAlign: 'right', justifyContent: 'right', gap: 20 }}>
          {user.role === UserRolesEnum.brokerAdmin && selectedEstate &&
            <GenericLogModal
              relatedIds={[selectedEstate?.id]}
              createdAt={new Date(new Date().setDate(new Date().getDate() - 30)).toISOString()}
              context={'FIRE_INSURANCE'}
            />
          }
          <GeneralCondition
            openModal={openConditions}
            toggleOpen={toggleOpenConditions}
            toggleClose={toggleCloseConditions}
          />
        </Grid.Row>

        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column only="mobile" width={16} style={columnDistance}>
            <h3>Novo orçamento de seguro incêndio</h3>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={6} computer={6} style={columnDistance}>
            <FieldLabel>Imobiliária</FieldLabel>
            <Dropdown
              fluid
              search
              clearable
              selection
              options={estateOptions}
              placeholder="Selecione a imobiliária..."
              disabled={estateOptions.length === 1}
              value={selectedEstate}
              onChange={(_, { value }) => handleSelectEstate(value)}
              onOpen={() => setDropDownSelectEstateIsOpen(false)}
              onClose={() => setDropDownSelectEstateIsOpen(true)}
            />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={6} computer={6} style={columnDistance}>
            <FieldLabel>Seguradora</FieldLabel>
            <Dropdown
              fluid
              search
              clearable
              options={insurerOptions}
              selection
              placeholder="Selecione a seguradora..."
              disabled={!selectedEstate}
              value={selectedInsurer}
              loading={LoadingInsurerOptions}
              onChange={(_, { value }) => handleSelectInsurer(value)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {selectedInsurer !== null &&
        selectedEstate !== null &&
        ([BudgetContext.editing, BudgetContext.copyCanceled].includes(context) || dropDownSelectEstateIsOpen) ?
        renderForm() : null}
    </>
  );
};

const mapStateToProps = (state: iRootState) => ({
  scroll: state.scroll,
  user: state.user,
  insurers: state.insurers,
  availableBrokersAndEstates: state.availableBrokersAndEstates
});

const mapDispatchToProps = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

export default connect(mapStateToProps, mapDispatchToProps)(FireInsuranceBudgetForm);
