import { Blacklist } from '../../types/Blacklist';

export interface BlacklistResponseDTO {
  data: Blacklist[];
  count: number;
  current: number;
  page: number;
}

export interface FormErrorsMessages {
  name: string;
  document: string;
  status: string;
  responsible_name: string;
  responsible_email: string;
  responsible_phone: string;
  phone: string;
  email: string;
  zipcode: string;
  street: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  state: string;
  user_id: string;
}

export const typeOptions = [
  {
    index: 1,
    value: 'document',
    text: 'CPF ou CNPJ'
  },
  {
    index: 2,
    value: 'name',
    text: 'Nome'
  },
];

export const statusOptions = [
  {
    value: 'ACTIVE',
    key: 0,
    text: 'Ativo'
  },
  {
    value: 'INACTIVE',
    key: 1,
    text: 'Inativo'
  },
];
