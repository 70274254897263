import React from "react";
import { Grid } from "semantic-ui-react";
import Table from "./components/Table";
import { connect } from "react-redux";
import { iRootDispatch, iRootState } from "../../store";
import { AvailableBrokersAndEstatesState } from "../../store/types/temp-types";

const FindRecordIndex = (props: any) => {
  return (
    <>
      <Grid>
        <Grid.Row>
          <Table />
        </Grid.Row>
      </Grid>
    </>
  );
};

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user,
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (
    availableBrokers: AvailableBrokersAndEstatesState
  ) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(
      availableBrokers
    ),
});

export default connect(mapState, mapDispatch)(FindRecordIndex);
