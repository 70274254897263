import React from 'react';
import styled from 'styled-components';
import { Grid, Input, Segment } from 'semantic-ui-react';
import moment from 'moment';
import { FieldLabel } from '../../../../../styles';
import { maskMoney } from '../../../../../services/masks';
import { turnUFIntoEstate } from '../../../util';
import { TokioMarineSegimobFireInsurancePolicyType } from './types';
interface TokioMarineSegimobViewInterface {
  fireInsurance: TokioMarineSegimobFireInsurancePolicyType;
  showJson: boolean;
}

const HeaderAndDviver = styled.h3`
  border-bottom: 1px solid #3498db;
  color: #3498db;
  width: 100%;
  margin-top: 20px !important;
  padding-bottom: 5px;
  letter-spacing: 0.4px;
`;

const DetailInput = styled(Input)`
  cursor: default !important;
  pointer-events: none !important;
  opacity: 0.7;
`;

const DetailField = ({ label, value }: { label: string; value: string | number }) => {
  return (
    <>
      <FieldLabel>{label}</FieldLabel>
      <DetailInput fluid value={value} />
    </>
  );
};

export const SegimobAssistanceMap = [
  {
    id: '1',
    nome: 'SEM ASSISTÊNCIA 24H'
  },
  {
    id: '2',
    nome: 'ESSENCIAL'
  },
  {
    id: '3',
    nome: 'MAIS'
  },
  {
    id: '4',
    nome: 'SUPER'
  },
  {
    id: '5',
    nome: 'COM ASSISTÊNCIA 24H'
  }
];
const TokioMarineSegimobView = (props: TokioMarineSegimobViewInterface) => {
  const { fireInsurance, showJson } = props;

  const coverages = fireInsurance.coverages.filter(coverage => coverage.enabled);

  return (
    <>
      <Grid style={{ padding: 5 }} columns={'equal'}>
        <HeaderAndDviver>Dados da Cotação</HeaderAndDviver>
        <Grid.Row>
          <Grid.Column>
            <DetailField label={'ID'} value={fireInsurance?.id} />
          </Grid.Column>
          <Grid.Column>
            <DetailField
              label={'Criado em'}
              value={moment(fireInsurance?.created_at).format('DD/MM/YYYY HH:mm')}
            />
          </Grid.Column>
          <Grid.Column>
            <DetailField
              label={'Aprovado em'}
              value={
                fireInsurance?.approvedAt
                  ? moment(fireInsurance?.approvedAt).format('DD/MM/YYYY HH:mm')
                  : 'Ainda não foi aprovado'
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <DetailField
              label={'Data de Início'}
              value={moment(fireInsurance?.policyPeriodStart).format('DD/MM/YYYY')}
            />
          </Grid.Column>
          <Grid.Column>
            <DetailField
              label={'Data de Término'}
              value={moment(fireInsurance?.policyPeriodEnd).format('DD/MM/YYYY')}
            />
          </Grid.Column>
          <Grid.Column>
            <DetailField
              label={'Quantidade de Parcelas'}
              value={fireInsurance.installments}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <DetailField label={'Seguradora'} value={fireInsurance?.insurer.name} />
          </Grid.Column>
          <Grid.Column>
            <DetailField
              label={'Imobiliária'}
              value={fireInsurance?.fireInsurance.estate.name}
            />
          </Grid.Column>
          <Grid.Column>
            <DetailField
              label={'Criado por'}
              value={fireInsurance?.fireInsurance.createdBy.name}
            />
          </Grid.Column>
        </Grid.Row>

        <HeaderAndDviver>Dados do Seguro</HeaderAndDviver>
        <Grid.Row>
          <Grid.Column>
            <DetailField
              label={'Valor do Aluguel'}
              value={`R$ ${maskMoney(
                String(fireInsurance?.fireInsurance.rentValue || 0),
                true,
                2
              )}`}
            />
          </Grid.Column>
          <Grid.Column>
            <DetailField
              label={'Assistência'}
              value={
                fireInsurance.assistance
                  ? SegimobAssistanceMap.find(ass => ass.id == fireInsurance.assistance)
                      ?.nome || ''
                  : ''
              }
            />
          </Grid.Column>
        </Grid.Row>
        <HeaderAndDviver>Coberturas</HeaderAndDviver>
        {coverages.map((coverage: any) => (
          <Grid.Row key={coverage.id}>
            <Grid.Column>
              <DetailField label={'Cobertura'} value={coverage.coverageName} />
            </Grid.Column>
            <Grid.Column>
              <DetailField
                label={'Valor'}
                value={`R$ ${maskMoney(String(coverage.value || 0), true, 2)}`}
              />
            </Grid.Column>
            <Grid.Column>
              <DetailField
                label={'Prêmio'}
                value={`R$ ${maskMoney(String(coverage.totalPremium || 0), true, 2)}`}
              />
            </Grid.Column>
          </Grid.Row>
        ))}

        <HeaderAndDviver>Dados do Imóvel</HeaderAndDviver>
        <Grid.Row>
          <Grid.Column>
            <DetailField
              label={'CEP'}
              value={fireInsurance?.fireInsurance.propertyAddresses.zipcode}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <DetailField
              label={'Logradouro'}
              value={fireInsurance?.fireInsurance.propertyAddresses.street}
            />
          </Grid.Column>
          <Grid.Column>
            <DetailField
              label={'Número'}
              value={fireInsurance?.fireInsurance.propertyAddresses.number}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <DetailField
              label={'Complemento'}
              value={
                !!fireInsurance?.fireInsurance.propertyAddresses.complement
                  ? fireInsurance?.fireInsurance.propertyAddresses.complement
                  : 'SEM COMPLEMENTO'
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <DetailField
              label={'Bairro'}
              value={fireInsurance?.fireInsurance.propertyAddresses.district}
            />
          </Grid.Column>
          <Grid.Column>
            <DetailField
              label={'Cidade'}
              value={fireInsurance?.fireInsurance.propertyAddresses.city}
            />
          </Grid.Column>
          <Grid.Column>
            <DetailField
              label={'Estado'}
              value={turnUFIntoEstate(
                fireInsurance?.fireInsurance.propertyAddresses.state
              )}
            />
          </Grid.Column>
        </Grid.Row>

        <HeaderAndDviver>Dados do Inquilino</HeaderAndDviver>
        <Grid.Row>
          <Grid.Column>
            <DetailField
              label={'Nome/Razão Social'}
              value={fireInsurance?.fireInsurance.tenantName}
            />
          </Grid.Column>
          <Grid.Column>
            <DetailField
              label={'CPF/CNPJ'}
              value={fireInsurance?.fireInsurance.tenantDocument}
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <DetailField
              label={'E-mail'}
              value={fireInsurance?.fireInsurance.tenantEmail}
            />
          </Grid.Column>
          <Grid.Column>
            <DetailField
              label={'Telefone'}
              value={fireInsurance?.fireInsurance.tenantCellphone || 'Não informado'}
            />
          </Grid.Column>
        </Grid.Row>

        <HeaderAndDviver>Dados do Proprietário</HeaderAndDviver>
        <Grid.Row>
          <Grid.Column>
            <DetailField
              label={'Nome/Razão Social'}
              value={fireInsurance?.fireInsurance.insuredName}
            />
          </Grid.Column>
          <Grid.Column>
            <DetailField
              label={'CPF/CNPJ'}
              value={fireInsurance?.fireInsurance.insuredDocument}
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <DetailField
              label={'E-mail'}
              value={fireInsurance?.fireInsurance.insuredEmail}
            />
          </Grid.Column>
          <Grid.Column>
            <DetailField
              label={'Telefone'}
              value={fireInsurance?.fireInsurance.insuredCellphone || 'Não informado'}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Segment style={{ display: showJson ? 'block' : 'none' }}>
        <pre style={{ width: '100%', height: '40vh', overflowX: 'auto' }}>
          {JSON.stringify(fireInsurance, null, 2)}
        </pre>
      </Segment>
    </>
  );
};

export default TokioMarineSegimobView;
