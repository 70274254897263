import * as Storage from './storage';
import { post, get, put } from './request';
import { MessageType } from '../components/FollowUp';

export async function getMessages(id: string): Promise<any> {
  const response = await get(`/claims/messages/${id}`);
  return response;
}

export async function sendMessage(claimId: string, userId: string, text: string, senderName: string): Promise<MessageType> {
  const payload = {
    claim_estate_id: claimId,
    sender_id: userId,
    text,
    sender_name: senderName
  }

  const response = await post('/claims/save-message', payload);
  return response;
}

export async function updateViewed(id: string, viewer_id: string): Promise<any> {
  const payload = {
    viewer_id
  }
  const response = await put(`/claims/update-message/${id}`, payload);
  return response;
}
