import React from 'react';
import { Grid } from 'semantic-ui-react';
import { BondInsuranceAnalysisForGetCompleteBondInsuranceProposalByIdResponseDTO, BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO } from "../../../../../dtos/bond-insurance-proposal-list/get-complete-bond-insurance-proposal-by-id-response.dto";
import { Info } from "../../../../../components/Info";
import { DateToStringDate } from "../../../../../services/date";
import { PurposesEnum } from "../../../../../enums/purposes.enum";
import { AnalysisAssuranceEnum } from "../../../../../enums/analysis-assurance.enum";
import { PersonTypesEnum } from "../../../../../enums/person-types.enum";
import { BondInsuranceAnalysis } from '../../../../../types/BondInsuranceAnalysis';

export type Props = {
  bondInsurance: BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO;
  bondInsuranceAnalysis: BondInsuranceAnalysisForGetCompleteBondInsuranceProposalByIdResponseDTO;
};

export const BondInsuranceMainInfoDetails = (props: Props) => {
  const {
    bondInsurance,
    bondInsuranceAnalysis
  } = props;
  const { property } = bondInsurance;

  return (
    <>
      <Grid.Row>
        <Info name={'ID'} value={bondInsurance.id} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'DATA DA ANALISE '} value={DateToStringDate(bondInsurance.createdAt)} width={8} />
        <Info name={'CRIADA POR'} value={bondInsurance.user.name} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'CORRETORA'} value={bondInsurance.broker?.name ? bondInsurance.broker.name : ''} width={8} />
        <Info name={'IMOBILIÁRIA'} value={bondInsurance.estate?.name} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'FINALIDADE'} value={bondInsurance.purpose === PurposesEnum.commercial ? 'Comercial' : 'Residencial'} width={8} />
        <Info name={'TIPO DE LOCATÁRIO'} value={bondInsurance.tenantType === PersonTypesEnum.legal ? 'Pessoa Jurídica' : 'Pessoa Física'} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'MOTIVO DA LOCAÇÃO'} value={property.reason} width={8} />
        <Info name={'GESTOR DE CONTAS'} value={bondInsurance.user.name} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'GARANTIA DA ANÁLISE'} value={(() => {  return (bondInsuranceAnalysis.analysisAssurance ?? bondInsurance.analysisAssurance)})() === AnalysisAssuranceEnum.complete ? 'Completa' : 'Simples'} width={8} />
        <Info name={'VIGÊNCIA DO CONTRATO'} value={`${property.validity} MESES`} width={8} />
      </Grid.Row>
      {property.location_in_progress &&
        <Grid.Row>
          <Info name={'CONTRATO DE LOCAÇÃO VIGENTE'} value={`${property.location_in_progress ? 'SIM' : 'NÃO'}`} width={8} />
        </Grid.Row>
      }
    </>
  )
}
