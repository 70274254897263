import * as Yup from 'yup';
import {
  BondInsuranceForGetBondInsurancesResponseDTO,
  // PropertyOwnerDTO
} from '../../../../../../../dtos/bond-insurance-list/get-bond-insurances-response.dto';

export const initialValues = (bondInsurance: BondInsuranceForGetBondInsurancesResponseDTO) => {
  const { property, natural_tenant, /*property_owner_natural_person , */ estate } = bondInsurance;

  const sympathiseTenants = natural_tenant?.sympathise_tenants?.map(sympathiseTenant => ({
    name: sympathiseTenant.name,
    cpf: sympathiseTenant.cpf,
    birthDate: sympathiseTenant.birth_date,
    gender: sympathiseTenant.gender,
    maritalStatus: sympathiseTenant.marital_status,
    nationality: sympathiseTenant.nationality,
    motherName: sympathiseTenant.mother_name,
    email: sympathiseTenant.email,
    phone: sympathiseTenant.phone,
    resident: sympathiseTenant.resident,
    politicallyExposedPerson: sympathiseTenant.politically_exposed_person,
    closeToPoliticallyExposedPerson: {
      politicallyExposedPersonName: sympathiseTenant.close_to_politically_exposed_person?.politically_exposed_person_name,
      politicallyExposedPersonCpf: sympathiseTenant.close_to_politically_exposed_person?.politically_exposed_person_cpf,
      degreeOfKinshipToPoliticallyExposedPerson: sympathiseTenant.close_to_politically_exposed_person?.degree_of_kinship_to_politically_exposed_person,
    },
    employmentRelationship: sympathiseTenant.employment_relationship,
    profession: sympathiseTenant.profession,
    monthlyFixedIncome: sympathiseTenant.monthly_fixed_income,
    otherIncomes: sympathiseTenant.other_incomes,
  })) ?? [];

  const baseEstate = estate as any;

  // const owner: PropertyOwnerDTO = {
  //   street: property_owner_natural_person?.street ?? baseEstate?.estate_street,
  //   number: property_owner_natural_person?.number ?? baseEstate?.estate_number,
  //   district: property_owner_natural_person?.district ?? baseEstate?.estate_district,
  //   complement: property_owner_natural_person?.complement ?? baseEstate?.estate_complement,
  //   city: property_owner_natural_person?.city ?? baseEstate?.estate_city,
  //   state: property_owner_natural_person?.state ?? baseEstate?.estate_state,
  //   zip_code: property_owner_natural_person?.zip_code ?? baseEstate?.estate_zip_code,
  //   cnpj: property_owner_natural_person?.cnpj ?? '',
  //   cpf: property_owner_natural_person?.cpf ?? '',
  //   document: property_owner_natural_person?.document ?? '',
  //   name: property_owner_natural_person?.name ?? '',
  //   email: property_owner_natural_person?.email ?? estate?.email,
  //   phone: property_owner_natural_person?.phone ?? baseEstate.responsible_phone
  // };

  return {
    bondInsuranceId: bondInsurance.id,
    reason: property?.reason,
    validity: property?.validity,
    purpose: bondInsurance?.purpose,
    analysisValue: 0,
    property: bondInsurance?.property,
    naturalTenant: bondInsurance?.natural_tenant,
    legalTenant: bondInsurance?.legal_tenant,
    analysisAssurance: bondInsurance?.analysis_assurance,
    zipCode: property.zip_code,
    street: property.street,
    number: property.number,
    district: property.district,
    city: property.city,
    state: property.state,
    complement: property.complement,
    rentValue: property.rent_value,
    condominiumFee: property.condominium_fee,
    taxesValue: property.taxes_value,
    energyBill: property.energy_bill,
    waterBill: property.water_bill,
    gasBill: property.gas_bill,
    sympathiseTenants,
    onlyGarantti: false,
    // propertyOwner: owner
  };
};

export const makePayload = (values: any, garanttiException: boolean) => {
  const total =
    (Number(values.rentValue) || 0)
    + (Number(values.taxesValue) || 0)
    + (Number(values.condominiumFee) || 0)
    + (Number(values.energyBill) || 0)
    + (Number(values.gasBill) || 0)
    + (Number(values.waterBill) || 0);

  return {
    reason: values.reason,
    validity: values.validity,
    zipCode: values.zipCode,
    street: values.street,
    district: values.district,
    city: values.city,
    state: values.state,
    number: values.number,
    complement: values.complement,
    rentValue: values.rentValue,
    condominiumFee: values.condominiumFee,
    taxesValue: values.taxesValue,
    energyBill: values.energyBill,
    waterBill: values.waterBill,
    gasBill: values.gasBill,
    contractPenaltiesValue: values.contractPenaltiesValue,
    interiorPaintingValue: values.interiorPaintingValue,
    exteriorPaintingValue: values.exteriorPaintingValue,
    furnitureDamageValue: values.furnitureDamageValue,
    propertyDamageValue: values.propertyDamageValue,
    totalAmount: total,
    bondInsuranceId: values.bondInsuranceId,
    sympathiseTenants: values.sympathiseTenants,
    onlyGarantti: garanttiException,
    propertyOwner: values.propertyOwner
  };
};

export const newSympathiseTenants = {
  name: '',
  cpf: '',
  birthDate: '',
  gender: '',
  maritalStatus: '',
  nationality: '',
  motherName: '',
  email: '',
  phone: '',
  resident: false,
  politicallyExposedPerson: 'NO',
  closeToPoliticallyExposedPerson: {
    politicallyExposedPersonName: '',
    politicallyExposedPersonCpf: '',
    degreeOfKinshipToPoliticallyExposedPerson: '',
  },
  employmentRelationship: '',
  profession: '',
  monthlyFixedIncome: 0,
  otherIncomes: 0,
};

export const validationSchema = Yup.object().shape({
  zipCode: Yup.string().required(),
  street: Yup.string().required(),
  number: Yup.string().required(),
  district: Yup.string().required(),
  state: Yup.string().required(),
  city: Yup.string().required(),
  rentValue: Yup.string().greaterThanFiveHundredReals('O valor do aluguel deve ser maior que R$ 500,00.'),
  sympathiseTenants: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(),
      cpf: Yup.string().required(),
      birthDate: Yup.string().required(),
      gender: Yup.string().required(),
      maritalStatus: Yup.string().required(),
      nationality: Yup.string().required(),
      motherName: Yup.string().required(),
      email: Yup.string().required(),
      phone: Yup.string().required(),
      employmentRelationship: Yup.string().required(),
      profession: Yup.string().required(),
    })
  ),
});

function normalizeSympathiseTenants(obj: any) {
  function normalizeCloseToPoliticallyExposedPerson(cpObj: any) {
    return {
      politicallyExposedPersonName: cpObj.politicallyExposedPersonName || cpObj.politically_exposed_person_name || '',
      politicallyExposedPersonCpf: cpObj.politicallyExposedPersonCpf || cpObj.politically_exposed_person_cpf || '',
      degreeOfKinshipToPoliticallyExposedPerson: cpObj.degreeOfKinshipToPoliticallyExposedPerson || cpObj.degree_of_kinship_to_politically_exposed_person || ''
    };
  }
  return {
    name: obj.name || obj.name || obj.name,
    cpf: obj.cpf || obj.cpf || obj.cpf,
    birthDate: obj.birthDate || obj.birth_date || obj.birthDate,
    gender: obj.gender || obj.gender || obj.gender,
    maritalStatus: obj.maritalStatus || obj.marital_status || obj.maritalStatus,
    nationality: obj.nationality || obj.nationality || obj.nationality,
    motherName: obj.motherName || obj.mother_name || obj.motherName,
    email: obj.email || obj.email || obj.email,
    phone: obj.phone || obj.phone || obj.phone,
    resident: obj.resident || obj.resident || obj.resident,
    politicallyExposedPerson: obj.politicallyExposedPerson || obj.politically_exposed_person || obj.politicallyExposedPerson,
    closeToPoliticallyExposedPerson: normalizeCloseToPoliticallyExposedPerson(obj.closeToPoliticallyExposedPerson || obj.close_to_politically_exposed_person || {}),
    employmentRelationship: obj.employmentRelationship || obj.employment_relationship || obj.employmentRelationship,
    profession: obj.profession || obj.profession || obj.profession,
    monthlyFixedIncome: obj.monthlyFixedIncome || obj.monthly_fixed_income || obj.monthlyFixedIncome,
    otherIncomes: obj.otherIncomes || obj.other_incomes || obj.otherIncomes || 0
  };
}

function checkEqual (a: any, b: any) {
  if (a === b) return true;

  if (typeof a !== 'object' || a === null ||
      typeof b !== 'object' || b === null) {
    return false;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) return false;

  for (const key of keysA) {
    if (!keysB.includes(key) || !checkEqual(a[key], b[key])) {
      return false;
    }
  }

  return true;
}

function verifyArrays(arr1: any, arr2: any) {
  if (arr1.length !== arr2.length) return false;

  const normalizedArr1 = arr1.map(normalizeSympathiseTenants);
  const normalizedArr2 = arr2.map(normalizeSympathiseTenants);

  for (let i = 0; i < normalizedArr1.length; i++) {
    if (!checkEqual(normalizedArr1[i], normalizedArr2[i])) return false;
  }

  return true;
}

export const hasNotChanged = (previous: any, newValues: any) => {
  let sameSympathiseTenants =  !((previous.natural_tenant?.sympathise_tenants && !newValues?.sympathiseTenants) || (!previous.natural_tenant?.sympathise_tenants && newValues?.sympathiseTenants));
  if(previous.natural_tenant.sympathise_tenants && newValues.sympathiseTenants) {
    sameSympathiseTenants = verifyArrays(previous.natural_tenant.sympathise_tenants, newValues.sympathiseTenants);
  }

  return (
    sameSympathiseTenants  &&
    newValues.validity === previous.property.validity &&
    newValues.zipCode === previous.property.zip_code &&
    newValues.street === previous.property.street &&
    newValues.number === previous.property.number &&
    newValues.district === previous.property.district &&
    newValues.city === previous.property.city &&
    newValues.state === previous.property.state &&
    String(newValues.complement || '') === String(previous.property.complement || '') &&
    (Number(newValues.rentValue) || 0) === (Number(previous.property.rent_value) || 0) &&
    (Number(newValues.taxesValue) || 0) === (Number(previous.property.taxes_value) || 0) &&
    (Number(newValues.condominiumFee) || 0) === (Number(previous.property.condominium_fee) || 0) &&
    (Number(newValues.energyBill) || 0) === (Number(previous.property.energy_bill) || 0) &&
    (Number(newValues.gasBill) || 0) === (Number(previous.property.gas_bill) || 0) &&
    (Number(newValues.waterBill) || 0) === (Number(previous.property.water_bill) || 0)
  );
};
