import React from "react";
import { ReactDatePickerProps, registerLocale } from "react-datepicker";
/*import { useFormikContext } from "formik";*/
import { CustomDatePickerStyle } from "../pages/fire-insurance-budget-form/components/styled-components";
import ptBr from "date-fns/locale/pt-BR"; // the locale you want
registerLocale("ptBr", ptBr); // register it with the name you want

const CustomDatePicker = (props: ReactDatePickerProps & any) => {
  return (
    <>
      <CustomDatePickerStyle
        {...props}
        autoComplete={"off"}
        locale="ptBr"
      />
    </>
  );
};

export default CustomDatePicker;
