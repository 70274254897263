import * as Storage from "./storage";
import { post, get } from "./request";
import { UserState } from "../types/UserState";
import { getPersistor } from "@rematch/persist";

export const isAuthenticated = () => null !== Storage.get("uid");

export const cleanUserSession = () => {
  Storage.clear();
  getPersistor().purge();

  window.location.reload();
};

export async function oAuthSignIn(
  email: string,
  password: string
): Promise<UserState> {
  const payload: any = {
    email: email,
    password: password,
  };

  const response = await post(`/oauth/verify-user`, payload);
  return response;
}

export function redirectToOAuthLogin(email: string): void {
  // console.log("redirectToOAuthLogin")
  const isDevelop = window.location.hostname === 'app.dev.4seg.com.br' || window.location.hostname === 'localhost';
  const redirectAddress = window.location.protocol + '//' + window.location.hostname + (window.location.port ? (':' + window.location.port) : '');
  const url = !isDevelop
    ? `https://auth.redevistorias.com.br/login?response_type=token&username=${email}&email=${email}&redirect_uri=https://4seg.com.br/auth-validate&scope=users%20order:*%20client:*%20device:*%20financial:*%20package:*%20entity:*%20integration:*&client_id=4seg`
    : `https://auth.dev.redevistorias.com.br/login?response_type=token&username=${email}&email=${email}&redirect_uri=${redirectAddress}/auth-validate&scope=users%20order:*%20client:*%20device:*%20financial:*%20package:*%20entity:*%20integration:*&client_id=4seg`;
  // const url = enviroment.includes('https://4seg.com.br') ? `https://auth.redevistorias.com.br/login?response_type=token&username=${email}&email=${email}&redirect_uri=https://4seg.com.br/auth-validate&scope=users%20order:*%20client:*%20device:*%20financial:*%20package:*%20entity:*%20integration:*&client_id=4seg` : `https://auth.dev.redevistorias.com.br/login?response_type=token&username=${email}&email=${email}&redirect_uri=http://four-seg-web-develop.s3-website-us-east-1.amazonaws.com/auth-validate&scope=users%20order:*%20client:*%20device:*%20financial:*%20package:*%20entity:*%20integration:*&client_id=4seg`;
  // console.log("url: ", url)
  window.location.replace(url);
}

export function redirectToOAuthCreate(email: string): void {
  const isDevelop = window.location.hostname === 'app.dev.4seg.com.br' || window.location.hostname === 'localhost';
  const redirectAddress = window.location.protocol + '//' + window.location.hostname + (window.location.port ? (':' + window.location.port) : '');
  const url = !isDevelop
    ? `https://auth.redevistorias.com.br/account-manager/create-account?client_id=4seg&redirect_uri=https://4seg.com.br/auth-validate&response_type=token&username=${email}&email=${email}&origin=4SEG&scope=users%20inspections%20devices`
    : `http://auth.dev.redevistorias.com.br/account-manager/create-account?client_id=4seg&redirect_uri=${redirectAddress}/auth-validate&response_type=token&username=${email}&email=${email}&origin=4SEG&scope=users%20inspections%20devices`;
  // const url = enviroment.includes('https://4seg.com.br') ? `https://auth.redevistorias.com.br/account-manager/create-account?client_id=4seg&redirect_uri=https://4seg.com.br/auth-validate&response_type=token&username=${email}&email=${email}&origin=4SEG&scope=users%20inspections%20devices` : `http://auth.dev.redevistorias.com.br/account-manager/create-account?client_id=4seg&redirect_uri=http://four-seg-web-develop.s3-website-us-east-1.amazonaws.com/auth-validate&response_type=token&username=${email}&email=${email}&origin=4SEG&scope=users%20inspections%20devices`;
  window.location.replace(url);
}

export async function checkUserExists(email: string): Promise<any> {
  const payload: any = {
    email: email,
  };

  const response = await post(`/oauth/verify`, payload);
  return response;
}

export async function authenticateUser(
  email: string,
  password: string
): Promise<boolean> {
  const payload: any = {
    email: email,
    password: password,
  };

  const response = await post(`/oauth/authenticate`, payload);
  return response;
}

export function redirectToCapturePage(): void {
  window.location.href = "https://conteudo.somosgruporv.com.br/solucoes-gruporv?redirectApp=redeConfiax"
}

export async function validateUser(accessToken: string): Promise<any> {
  const payload: any = {
    accessToken: accessToken,
  };

  const response = await post(`/oauth/validate`, payload);
  return response;
}
