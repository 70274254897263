import { FireInsuranceCancelReasonEnum } from "../../../enums/fire-insurance-cancel-reason.enum";

export const ABLE_TO_CANCEL = [
  'POLICY_CREATED'
];

export const CANCELLATION_REASON_OPTIONS = [
  {
    value: FireInsuranceCancelReasonEnum.leaseGaveUp,
    key: FireInsuranceCancelReasonEnum.leaseGaveUp,
    text: 'Cliente desistiu da locação',
  },
  {
    value: FireInsuranceCancelReasonEnum.endOfLease,
    key: FireInsuranceCancelReasonEnum.endOfLease,
    text: 'Término da locação / contrato',
  },
  {
    value: FireInsuranceCancelReasonEnum.others,
    key: FireInsuranceCancelReasonEnum.others,
    text: 'Outros',
  },
];
