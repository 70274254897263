import React, { useState } from 'react';
import {
  Button,
  Dimmer,
  Dropdown,
  Grid,
  Icon,
  Input,
  Loader,
  Modal
} from 'semantic-ui-react';
import { ConfirmRenewRequestHiredData, ConfirmRenewRequestModalProps } from './types';
import { Line, FormContainer, FormTitle, Form } from './styles';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import AppMaskedInput from '../../../../components/MaskedInput';
import { DATE_INPUT_MASK, formatAmount, maskMoney } from '../../../../services/masks';
import {
  dangerNotification,
  successNotification,
  warningNotification
} from '../../../../services/notification';
import { iRootState } from '../../../../store';
import { connect } from 'react-redux';
import { DateToStringDate } from '../../../../services/date';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { confirmRenewBondInsurance } from '../../../../services/bond-insurance-policy';
import { getUsers } from '../../../../services/broker';
import { UsersToCustomDropDownOptions } from '../../../../util';
import { useQuery } from '@tanstack/react-query';
import { getMonthsOptions } from '../../../../utils/getMonths';
import ModalAttachFiles from '../../../../components/ModalAttachFiles';

const mapState = (state: iRootState) => ({
  user: state.user
});

export const FileIdentifiers = [
  {
    name: 'ADITIVO DE CONTRATO',
    key: 'CONTRACT_ADDITIVE',
    required: true
  },
  {
    name: 'Outros',
    key: 'OTHER',
    required: false
  }
];

const ConfirmRenewRequestModal: React.FC<
  ConfirmRenewRequestModalProps & ReturnType<typeof mapState>
> = (props: ConfirmRenewRequestModalProps & ReturnType<typeof mapState>) => {
  const { isOpen, onClose, confirmHiredData, bondInsuranceId, policy } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedAccountManager, setSelectedAccountManager] = useState<any>();

  const getAccountManagers = async () => {
    const result = await getUsers(props?.user?.broker?.id, [{ isDropdown: true}]);
    return UsersToCustomDropDownOptions(
      false,
      result.map(user => ({
        id: user.id,
        name: user.name
      }))
    );
  };

  const { data: accountManagerOptions, isFetching: isAccountManagersLoading } = useQuery(
    ['getAccountManagers'],
    getAccountManagers,
    {
      keepPreviousData: true,
      enabled: !!props?.user?.broker?.id
    }
  );

  const handleFormSubmit = async (formValues: ConfirmRenewRequestHiredData) => {
    setIsLoading(true);

    try {
      await confirmRenewBondInsurance(formValues?.bondInsuranceHiredDataId, formValues);
      successNotification(
        'Sucesso!',
        'Sua solicitação de renovação foi confirmada com sucesso.'
      );
      onClose();
    } catch (error) {
      dangerNotification(
        'Erro!',
        'Erro ao confirmar solicitação de renovação de apólice.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const initialValuesObject = () => {
    const data: ConfirmRenewRequestHiredData = {
      bondInsuranceHiredDataId: confirmHiredData.bondInsuranceHiredDataId,
      rentalPeriodStartDate: confirmHiredData?.rentalPeriodStartDate
        ? new Date(confirmHiredData?.rentalPeriodStartDate + ' 00:00:00')
        : undefined,
      rentalPeriodEndDate: confirmHiredData?.rentalPeriodEndDate
        ? new Date(confirmHiredData?.rentalPeriodEndDate + ' 00:00:00')
        : undefined,
      rentInsuranceContractStartDate: confirmHiredData?.rentInsuranceContractStartDate
        ? new Date(confirmHiredData?.rentInsuranceContractStartDate + ' 00:00:00')
        : undefined,
      rentInsuranceContractEndDate: confirmHiredData?.rentInsuranceContractEndDate
        ? new Date(confirmHiredData?.rentInsuranceContractEndDate + ' 00:00:00')
        : undefined,
      renewalPeriod: +confirmHiredData?.renewalPeriod,
      policyNumber: '',
      installments: confirmHiredData?.installments,
      installmentValue: formatAmount(+confirmHiredData?.installmentValue * 100) ?? '0,00',
      totalPremium: String(
        Number(confirmHiredData?.installments) *
          Number(confirmHiredData?.installmentValue)
      ),
      rentValue: formatAmount(+confirmHiredData?.rentValue * 100) ?? '0,00',
      condominiumFee: formatAmount(+confirmHiredData?.condominiumFee * 100) ?? '0,00',
      taxesValue: formatAmount(+confirmHiredData?.taxesValue * 100) ?? '0,00',
      energyBill: formatAmount(+confirmHiredData?.energyBill * 100) ?? '0,00',
      waterBill: formatAmount(+confirmHiredData?.waterBill * 100) ?? '0,00',
      gasBill: formatAmount(+confirmHiredData?.gasBill * 100) ?? '0,00',
      insurerPolicyCreatedAt: confirmHiredData?.insurerPolicyCreatedAt
        ? new Date(confirmHiredData?.insurerPolicyCreatedAt + ' 00:00:00')
        : undefined,
      renewalResponsible: confirmHiredData?.renewalResponsible
    };

    return data;
  };

  const validationSchema = Yup.object().shape({
    rentValue: Yup.string().test('rentValueTest', 'error', value => {
      if (!value || value === '0,0') {
        warningNotification(
          'O campo valor do aluguel é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    rentInsuranceContractStartDate: Yup.string().test(
      'ContractStartDateTest',
      'error',
      value => {
        if (!value) {
          warningNotification(
            'O campo inicio do contrato é obrigatório',
            'Por favor, preencha o campo corretamente.'
          );
          return false;
        }

        return true;
      }
    ),
    rentInsuranceContractEndDate: Yup.string().test(
      'ContractEndDateTest',
      'error',
      value => {
        if (!value) {
          warningNotification(
            'O campo fim do contrato é obrigatório',
            'Por favor, preencha o campo corretamente.'
          );
          return false;
        }

        return true;
      }
    ),
    rentalPeriodStartDate: Yup.string().test('PeriodStartDateTest', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo inicio da vigência é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    rentalPeriodEndDate: Yup.string().test('PeriodEndDateTest', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo fim da vigência é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    policyNumber: Yup.string().test('policyNumberTest', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo número da apólice é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      if (value === confirmHiredData.policyNumber) {
        warningNotification(
          'ERRO: NÚMERO DA APÓLICE',
          'O número da nova apólice deve ser diferente da atual.'
        );
        return false;
      }

      return true;
    }),
    installments: Yup.string().test('installmentsTest', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo número de parcelas é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    installmentValue: Yup.string().test('installmentValueTest', 'error', value => {
      if (!value || value === '0,0') {
        warningNotification(
          'O campo valor das parcelas é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    totalPremium: Yup.string().test('totalPremiumTest', 'error', value => {
      if (!value || value === '0,0') {
        warningNotification(
          'O campo valor do premio é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    })
  });

  return (
    <Modal open={isOpen} onClose={onClose} closeIcon size="large">
      <Modal.Header>
        <Icon name="refresh" /> Confirmar renovação de apólice
      </Modal.Header>
      <Line />
      <Modal.Content scrolling style={{ padding: 0 }}>
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
        <Formik
          initialValues={initialValuesObject()}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            setFieldError,
            touched,
            setFieldTouched,
            handleChange,
            handleSubmit,
            setFieldValue
          }) => (
            <Form id={'ConfirmRenewRequestForm'} onSubmit={handleSubmit}>
              <FormContainer>
                <FormTitle>DADOS PARA RENOVAÇÃO</FormTitle>
                <Line />
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <label>
                        VALOR DO ALUGUEL*
                        <Input
                          name="rentValue"
                          placeholder="Valor do Aluguel"
                          onChange={(_, data) => {
                            setFieldValue('rentValue', formatAmount(data?.value));
                          }}
                          value={values.rentValue}
                          error={touched.rentValue && errors.rentValue === 'error'}
                          fluid
                        />
                        <small>
                          Atual: {maskMoney(String(confirmHiredData?.rentValue), true, 2)}
                        </small>
                      </label>
                    </Grid.Column>

                    <Grid.Column width={8}>
                      <label>
                        IPTU MENSAL
                        <Input
                          name="taxesValue"
                          placeholder="IPTU Mensal"
                          onChange={(_, data) => {
                            setFieldValue('taxesValue', formatAmount(data?.value));
                          }}
                          value={values.taxesValue}
                          fluid
                        />
                        <small>
                          Atual:{' '}
                          {maskMoney(String(confirmHiredData?.taxesValue), true, 2)}
                        </small>
                      </label>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={8}>
                      <label>
                        CONDOMÍNIO
                        <Input
                          name="condominiumFee"
                          placeholder="Condomínio"
                          onChange={(_, data) => {
                            setFieldValue('condominiumFee', formatAmount(data?.value));
                          }}
                          fluid
                          value={values.condominiumFee}
                        />
                        <small>
                          Atual:{' '}
                          {maskMoney(String(confirmHiredData?.condominiumFee), true, 2)}
                        </small>
                      </label>
                    </Grid.Column>

                    <Grid.Column width={8}>
                      <label>
                        ENERGIA
                        <Input
                          name="energyBill"
                          placeholder="Energia"
                          onChange={(_, data) => {
                            setFieldValue('energyBill', formatAmount(data?.value));
                          }}
                          fluid
                          value={values.energyBill}
                        />
                        <small>
                          Atual:{' '}
                          {maskMoney(String(confirmHiredData?.energyBill), true, 2)}
                        </small>
                      </label>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={8}>
                      <label>
                        ÁGUA
                        <Input
                          name="waterBill"
                          placeholder="Água"
                          onChange={(_, data) => {
                            setFieldValue('waterBill', formatAmount(data?.value));
                          }}
                          value={values.waterBill}
                          fluid
                        />
                        <small>
                          Atual: {maskMoney(String(confirmHiredData?.waterBill), true, 2)}
                        </small>
                      </label>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <label>
                        GÁS
                        <Input
                          name="gasBill"
                          placeholder="Gás"
                          onChange={(_, data) => {
                            setFieldValue('gasBill', formatAmount(data?.value));
                          }}
                          fluid
                          value={values.gasBill}
                        />
                        <small>
                          Atual: {maskMoney(String(confirmHiredData?.gasBill), true, 2)}
                        </small>
                      </label>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={8}>
                      <label>INÍCIO DO CONTRATO*</label>
                      <CustomDatePicker
                        name="rentInsuranceContractStartDate"
                        selected={values.rentInsuranceContractStartDate ?? undefined}
                        onChange={(date: Date) => {
                          setFieldValue(
                            'rentInsuranceContractStartDate',
                            date ?? undefined
                          );
                          setFieldError('rentInsuranceContractStartDate', '');
                        }}
                        value={values.rentInsuranceContractStartDate}
                        customInput={
                          <AppMaskedInput
                            error={errors.rentInsuranceContractStartDate === 'error'}
                            mask={DATE_INPUT_MASK}
                          />
                        }
                        dateFormat={'dd/MM/yyyy'}
                        style={{ marginTop: 0 }}
                      />
                      <small>
                        Atual:{' '}
                        {confirmHiredData?.rentInsuranceContractStartDate
                          ? DateToStringDate(
                              confirmHiredData?.rentInsuranceContractStartDate
                            )
                          : 'Indisponível'}
                      </small>
                    </Grid.Column>

                    <Grid.Column width={8}>
                      <label>FIM DO CONTRATO*</label>
                      <CustomDatePicker
                        name="rentInsuranceContractEndDate"
                        selected={values.rentInsuranceContractEndDate ?? undefined}
                        minDate={values.rentInsuranceContractStartDate}
                        onChange={(date: Date) => {
                          setFieldValue(
                            'rentInsuranceContractEndDate',
                            date ?? undefined
                          );
                          setFieldError('rentInsuranceContractEndDate', '');
                        }}
                        value={values.rentInsuranceContractEndDate ?? null}
                        customInput={
                          <AppMaskedInput
                            error={errors.rentInsuranceContractEndDate === 'error'}
                            mask={DATE_INPUT_MASK}
                          />
                        }
                        dateFormat={'dd/MM/yyyy'}
                        style={{ marginTop: 0 }}
                      />
                      <small>
                        Atual:{' '}
                        {confirmHiredData?.rentInsuranceContractEndDate
                          ? DateToStringDate(
                              confirmHiredData?.rentInsuranceContractEndDate
                            )
                          : 'Indisponível'}
                      </small>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={8}>
                      <label>INÍCIO DA VIGÊNCIA*</label>
                      <CustomDatePicker
                        name="rentalPeriodStartDate"
                        selected={values.rentalPeriodStartDate ?? undefined}
                        onChange={(date: Date) => {
                          setFieldValue('rentalPeriodStartDate', date ?? undefined);
                          setFieldError('rentalPeriodStartDate', '');
                        }}
                        customInput={
                          <AppMaskedInput
                            error={errors.rentalPeriodStartDate === 'error'}
                            mask={DATE_INPUT_MASK}
                          />
                        }
                        dateFormat={'dd/MM/yyyy'}
                        style={{ marginTop: 0 }}
                      />
                      <small>
                        Atual:{' '}
                        {confirmHiredData?.rentalPeriodStartDate
                          ? DateToStringDate(confirmHiredData?.rentalPeriodStartDate)
                          : 'Indisponível'}
                      </small>
                    </Grid.Column>

                    <Grid.Column width={8}>
                      <label>FIM DA VIGÊNCIA*</label>
                      <CustomDatePicker
                        name="rentalPeriodEndDate"
                        selected={values.rentalPeriodEndDate ?? undefined}
                        minDate={values.rentalPeriodEndDate}
                        onChange={(date: Date) => {
                          setFieldValue('rentalPeriodEndDate', date ?? undefined);
                          setFieldError('rentalPeriodEndDate', '');
                        }}
                        customInput={
                          <AppMaskedInput
                            error={errors.rentalPeriodEndDate === 'error'}
                            mask={DATE_INPUT_MASK}
                          />
                        }
                        dateFormat={'dd/MM/yyyy'}
                        style={{ marginTop: 0 }}
                      />
                      <small>
                        Atual:{' '}
                        {confirmHiredData?.rentalPeriodEndDate
                          ? DateToStringDate(confirmHiredData?.rentalPeriodEndDate)
                          : 'Indisponível'}
                      </small>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </FormContainer>

              <FormContainer>
                <FormTitle>CONFIRMAÇÃO DA RENOVAÇÃO</FormTitle>
                <Line />
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <label>NÚMERO DA APÓLICE</label>
                      <Input
                        name="policyNumber"
                        placeholder="Número da apólice"
                        onChange={e => {
                          handleChange(e);
                          setFieldTouched('policyNumber', false, false);
                        }}
                        value={values.policyNumber}
                        error={touched.policyNumber && errors.policyNumber === 'error'}
                        fluid
                      />
                      <small>Atual: {confirmHiredData.policyNumber}</small>
                    </Grid.Column>

                    <Grid.Column width={8}>
                      <label>NÚMERO DE PARCELAS*</label>
                      <Input
                        name="installments"
                        type="number"
                        placeholder="Número de parcelas"
                        onChange={e => {
                          handleChange(e);
                          setFieldTouched('installments', false, false);
                        }}
                        max={60}
                        value={(+values.installments)?.toFixed(0)}
                        error={touched.installments && errors.installments === 'error'}
                        fluid
                      />
                      <small>Atual: {(+confirmHiredData.installments)?.toFixed(0)}</small>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={8}>
                      <label>VALOR DAS PARCELAS*</label>
                      <Input
                        name="installmentValue"
                        placeholder="Valor das parcelas"
                        onChange={(_, data) => {
                          setFieldValue('installmentValue', formatAmount(data?.value));
                          setFieldTouched('installmentValue', false, false);
                        }}
                        value={values.installmentValue}
                        error={
                          touched.installmentValue && errors.installmentValue === 'error'
                        }
                        fluid
                      />
                      <small>
                        Atual: {maskMoney(confirmHiredData.installmentValue, true, 2)}
                      </small>
                    </Grid.Column>

                    <Grid.Column width={8}>
                      <label>VALOR DO PRÊMIO*</label>
                      <Input
                        name="totalPremium"
                        placeholder="Valor do prêmio"
                        onChange={(_, data) => {
                          setFieldValue('totalPremium', formatAmount(data?.value));
                          setFieldTouched('totalPremium', false, false);
                        }}
                        value={formatAmount(+values.totalPremium * 100)}
                        error={touched.totalPremium && errors.totalPremium === 'error'}
                        fluid
                      />
                      <small>
                        Atual:{' '}
                        {maskMoney(
                          confirmHiredData.totalPremium !== ('0.00' || '0,00')
                            ? confirmHiredData.totalPremium
                            : String(
                                +confirmHiredData.installmentValue *
                                  +confirmHiredData.installments
                              ),
                          true,
                          2
                        )}
                      </small>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <label>DATA DA EMISSÃO DA APÓLICE</label>
                      <CustomDatePicker
                        name="insurerPolciyCreatedAt"
                        selected={values.insurerPolicyCreatedAt ?? undefined}
                        onChange={(date: Date) => {
                          setFieldValue('insurerPolicyCreatedAt', date ?? undefined);
                          setFieldError('insurerPolicyCreatedAt', '');
                        }}
                        value={values.insurerPolicyCreatedAt}
                        customInput={<AppMaskedInput mask={DATE_INPUT_MASK} />}
                        dateFormat={'dd/MM/yyyy'}
                        style={{ marginTop: 0 }}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <label style={{ paddingBottom: '6px' }}>
                        RESPONSÁVEL PELA RENOVAÇÂO
                      </label>
                      <Dropdown
                        style={{ width: '100%' }}
                        value={selectedAccountManager}
                        name={`accountManager`}
                        options={accountManagerOptions}
                        disabled={isLoading || isAccountManagersLoading}
                        loading={isAccountManagersLoading}
                        placeholder={'Selecionar...'}
                        search
                        selection
                        onChange={(e, { value }) => {
                          setSelectedAccountManager(value);
                          setFieldValue('renewalResponsible', value);
                        }}
                        clearable
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <label>PERÍODO DE RENOVAÇÃO*</label>
                      <div
                        style={{
                          width: '100%',
                          marginTop: '.5rem'
                        }}
                      >
                        <Dropdown
                          search
                          selection
                          clearable
                          name="renewalPeriod"
                          value={`${values.renewalPeriod}`}
                          options={getMonthsOptions(+policy.insurer.code)}
                          placeholder={'Selecionar...'}
                          style={{
                            width: '100%'
                          }}
                          onChange={(_, { value }) => {
                            setFieldValue('renewalPeriod', +`${value}`);
                          }}
                        />
                      </div>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <label style={{ marginBottom: 5 }}>ADITIVO DE CONTRATO</label>
                      <ModalAttachFiles
                        bondInsuranceId={bondInsuranceId}
                        tableName="bond_insurance"
                        message=""
                        hideModels
                        fileIdentifiers={FileIdentifiers}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </FormContainer>
            </Form>
          )}
        </Formik>
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="submit"
          color="green"
          floated="right"
          disabled={isLoading}
          style={{ marginBottom: '10px' }}
          form={'ConfirmRenewRequestForm'}
        >
          Confirmar renovação
        </Button>
        <Button
          type="button"
          color="red"
          onClick={onClose}
          floated="right"
          disabled={isLoading}
          style={{ marginBottom: '10px' }}
        >
          Cancelar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default connect(mapState)(ConfirmRenewRequestModal);
