import { post, get, remove } from './request';
import { FireInsuranceComboTooRequestDTO, FireInsuranceCreateQuotationRequestDTO } from "../dtos/fire-insurance-budget/fire-insurance-create-quotation-request-dto";
import { FireInsuranceCreateQuotationResponseDTO } from "../dtos/fire-insurance-budget/fire-insurance-create-quotation-response-dto";
import { CancelAlfaSeguroBudgetDTO, GetCoveragesProps, GetCoveragesPropsResponse } from '../pages/fire-insurance-proposal-list/types';
import { FilterData } from '../pages/fire-insurance-budget-list/types';

export const quote = async (queryData: FireInsuranceCreateQuotationRequestDTO | FireInsuranceComboTooRequestDTO): Promise<FireInsuranceCreateQuotationResponseDTO> => {
  const baseRoute = `/fire-insurance-quotations`;
  let body: object;
  let response: FireInsuranceCreateQuotationResponseDTO;

  body = { quote: queryData }

  try {
    response = await post(baseRoute, body);
    return response;
  } catch (e) {
    /*response = { value: 0, insurer_gain: 0, installments: 0, coverage: [{} as CoverageState], broker_percent: 0, estate_percent: 0 };*/
    throw e;
  }
};

export const cancelRequest = async (payload: any): Promise<any> => {
  const route = `/fire-insurance-quotations/cancel`;

  try {
    const response = await post(route, payload);

    return response;
  } catch (e) {
    console.log('ERRRRRRO', e);
    throw e;
  }
}

export const getCanceleds = async (filter?: FilterData): Promise<any> => {

  let response;
  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`
      }
    })
  }

  const route = `/fire-insurance-quotations/canceleds?${query}`;

  try {
    response = await get(route);
  } catch (e) {
    console.log('Aconteceu um erro:', e);
    response = null;
  }

  return response;
}

export const getCanceled = async (id: string): Promise<any> => {
  const route = `/fire-insurance-quotations/canceled/${id}`;

  try {
    return get(route);
  } catch (e) {
    console.log('Aconteceu um erro:', e);
  }
}

export const getQuoteParams = async (propertyId: string, userId: string): Promise<any> => {
  const baseRoute = `/fire-insurance-quotations/params?propertyId=${propertyId}&userId=${userId}`;
  try {
    return await get(baseRoute);
  } catch (e) {
    console.error(e);
    return { estate_percent: 0, broker_percent: 0 };
  }
};

export const getCoverages = async (data: GetCoveragesProps): Promise<GetCoveragesPropsResponse> => {
  {
    let response;

    try {
      response = await get(
        `/fire-insurance-quotations/coverages?insurerCode=${data.insurer_code}`
      );
    } catch (e) {
      response = [];
    }
    return response;
  };
};

export const CancelAlfaSegurosBudget = async (dto: CancelAlfaSeguroBudgetDTO): Promise<any> => {
  try {
    return post(`/fire-insurance-quotations/alfa-seguros/cancel-budget`, dto);
  } catch (e) {
    return [];
  }
};
