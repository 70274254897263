import { CreateCapitalizationType } from '../pages/capitalization/components/create-capitalization-modal/Formik/types/CreateCapitalizationInitialValuesTypes';
import { ListCapitalizationInterface } from '../pages/capitalization/ListCapitalizationInitial.interface';
import { post, get, put } from './request';

export const createCapitalization = async (capitalization: CreateCapitalizationType): Promise<any> => {
  const route = `/capitalization/create`;
  return await post(route, capitalization);
}

export const updateCapitalization = async (capitalization: CreateCapitalizationType): Promise<any> => {
  const route = `/capitalization/update/${capitalization.id}`;
  return await put(route, capitalization);
}

interface listTypeResponse {
  data: ListCapitalizationInterface[];
  count: number;
}

export const getCapitalizations = async (filters: any): Promise<listTypeResponse> => {
  let url = `/capitalization/list`;
  let query = '';

  if (filters) {
    Object.entries(filters).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`
      }
    })

    url = `${url}?${query}`;
  }
  return await get(url);
}

export const getProfessions = async (): Promise<any> => {
  const route = `/capitalization/professions`;
  return await get(route);
}

