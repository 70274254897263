import Excel from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment';

import { maskCpfOrCnpj, numberToMoneyString } from '../../masks';
import { translateInvoiceStatus } from '../../../pages/fire-insurance/utils/AlfaSeguros';

export const AlfaInvoiceDetailsReport = async (invoice: any, invoiceDetails: any[]) => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Detalhes da Fatura', {
    views: [{
      state: 'frozen',
      ySplit: 1,
    }],
  });

  const cellHeaderStyle: Partial<Excel.Style> = {
    alignment: {
      horizontal: 'center',
      vertical: 'middle',
    },
    border: {
      right: {
        style: 'thin',
        color: { argb: 'FFFFFF' },
      },
      bottom: {
        style: 'thin',
        color: { argb: 'FFFFFF' },
      },
    },
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '1678C2' },
    },
    font: {
      size: 8,
      bold: true,
      color: { argb: 'FFFFFF' },
    }
  };

  const cellFooterStyle: Partial<Excel.Style> = {
    alignment: {
      horizontal: 'right',
    },
    font: {
      size: 8,
      bold: true,
    }
  };

  const cellStyle: Partial<Excel.Style> = {
    alignment: {
      horizontal: 'left',
      vertical: 'bottom',
    },
    font: {
      size: 8,
    },
  };

  worksheet.autoFilter = 'A1:A6';

  worksheet.getColumn('A').width = 30;
  worksheet.getColumn('A').style = cellStyle;
  worksheet.getColumn('A').key = 'estateName';
  worksheet.getCell('A1').style = cellHeaderStyle;
  worksheet.getCell('A1').value = 'Nome imobiliária';

  worksheet.getColumn('B').width = 30;
  worksheet.getColumn('B').style = cellStyle;
  worksheet.getColumn('B').key = 'insurerName';
  worksheet.getCell('B1').style = cellHeaderStyle;
  worksheet.getCell('B1').value = 'Seguradora';

  worksheet.getColumn('C').width = 12;
  worksheet.getColumn('C').style = cellStyle;
  worksheet.getColumn('C').key = 'policyNumber';
  worksheet.getCell('C1').style = cellHeaderStyle;
  worksheet.getCell('C1').value = 'Nº da Apólice';

  worksheet.getColumn('D').width = 55;
  worksheet.getColumn('D').style = cellStyle;
  worksheet.getColumn('D').key = 'address';
  worksheet.getCell('D1').style = cellHeaderStyle;
  worksheet.getCell('D1').value = 'Endereço';

  worksheet.getColumn('E').width = 30;
  worksheet.getColumn('E').style = cellStyle;
  worksheet.getColumn('E').key = 'tenantName';
  worksheet.getCell('E1').style = cellHeaderStyle;
  worksheet.getCell('E1').value = 'Locatário';

  worksheet.getColumn('F').width = 20;
  worksheet.getColumn('F').style = cellStyle;
  worksheet.getColumn('F').key = 'tenantDocument';
  worksheet.getCell('F1').style = cellHeaderStyle;
  worksheet.getCell('F1').value = 'CPF/CNPJ Locatário';

  worksheet.getColumn('G').width = 15;
  worksheet.getColumn('G').style = cellStyle;
  worksheet.getColumn('G').key = 'installmentNumber';
  worksheet.getCell('G1').style = cellHeaderStyle;
  worksheet.getCell('G1').value = 'Nº da Parleca';

  worksheet.getColumn('H').width = 15;
  worksheet.getColumn('H').style = cellStyle;
  worksheet.getColumn('H').key = 'installmentValue';
  worksheet.getCell('H1').style = cellHeaderStyle;
  worksheet.getCell('H1').value = 'Valor da Parcela';

  let ctrl = 4;

  for (const item of invoiceDetails) {
    const row = {
      estateName: invoice?.estate?.name || '',
      insurerName: invoice?.insurer?.name || '',
      policyNumber: item.policyNumber,
      address: String(item.address).replace('\n              ', ' '),
      tenantName: item.tenantName,
      tenantDocument: maskCpfOrCnpj(item.tenantDocument),
      installmentNumber: item.installmentNumber,
      installmentValue: numberToMoneyString(Number(item.installmentValue)),
    };

    worksheet.addRow(row);

    ctrl++;
  }

  worksheet.getCell(`A${ctrl}`).style = cellFooterStyle;
  worksheet.getCell(`A${ctrl}`).value = 'Valor total da fatura:';
  worksheet.getCell(`B${ctrl}`).value = numberToMoneyString(Number(invoice.amount));

  ctrl++;

  worksheet.getCell(`A${ctrl}`).style = cellFooterStyle;
  worksheet.getCell(`A${ctrl}`).value = 'Data de vencimento:';
  worksheet.getCell(`B${ctrl}`).value = moment(invoice.dueDate).format('DD/MM/YYYY');

  ctrl++;

  worksheet.getCell(`A${ctrl}`).style = cellFooterStyle;
  worksheet.getCell(`A${ctrl}`).value = 'Situação:';
  worksheet.getCell(`B${ctrl}`).value = translateInvoiceStatus(invoice.status);

  const filename = `Relatótio_detalhes_fatura_${new Date().getTime()}.xlsx`;

  workbook.xlsx.writeBuffer().then(data => {
    const file = new Blob(
      [data],
      { type: 'appplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' }
    );

    saveAs(file, filename);
  });
}
