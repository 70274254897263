import {ScrollReducer, ScrollState} from "../types/temp-types";

type ScrollRematchModel = {
  state: ScrollState;
  reducers: ScrollReducer;
}

const scroll: ScrollRematchModel = {
  state: {
    posY: 1
  },
  reducers: {
    updateScroll: (state: ScrollState, payload: ScrollState): ScrollState => {
      return payload;
    }
  }
};

export default scroll;
