import React from 'react';
import { BondInsurance } from '../../../types/BondInsurance';
import PrintableModalWithoutAttach from '../../../components/PrintableModalWithoutAttach';
import { Grid } from 'semantic-ui-react';
import { BondInsuranceMainInfoDetails } from './BondInsuranceMainInfoDetails';
import { BondInsurancePropertyDetails } from './BondInsurancePropertyDetails';
import { PersonTypesEnum } from '../../../enums/person-types.enum';
import { BondInsuranceNaturalTenantDetails } from './BondInsuranceNaturalTenantDetails';
import { BondInsuranceLegalTenantDetails } from './BondInsuranceLegalTenantDetails';
import { BondInsuranceForGetBondInsurancesResponseDTO } from "../../../dtos/bond-insurance-list/get-bond-insurances-response.dto";
import { EstateInsurerDataForGetEstatesResponseDTO, InsurerForGetEstateResponseDTO } from '../../../dtos/estate/estate-for-get-estates-response.dto';

export type Props = {
  bondInsurance: BondInsuranceForGetBondInsurancesResponseDTO;
  isOpen: boolean;
  onClose: React.ReactEventHandler<{}>;
  insurersData: EstateInsurerDataForGetEstatesResponseDTO[];
  insurers: InsurerForGetEstateResponseDTO[];
};

export const BondInsuranceDetails = (props: Props) => {

  const { bondInsurance, insurersData, isOpen, onClose, insurers } = props;


  return (
    <>
      <PrintableModalWithoutAttach
        isOpen={isOpen}
        onClose={onClose}
        header={'Seguro Fiança'}
        bondInsuranceId={bondInsurance.id}
        content={
          <Grid>
            <BondInsuranceMainInfoDetails bondInsurance={bondInsurance} insurersData={insurersData} insurers={insurers}/>
            <BondInsurancePropertyDetails bondInsurance={bondInsurance} />
            {bondInsurance.tenant_type === PersonTypesEnum.natural && (
              <BondInsuranceNaturalTenantDetails bondInsurance={bondInsurance} />
            )}
            {bondInsurance.tenant_type === PersonTypesEnum.legal && (
              <BondInsuranceLegalTenantDetails bondInsurance={bondInsurance} />
            )}
          </Grid>
        }
        callback={(): Promise<void | BondInsurance> => {
          return {} as Promise<BondInsurance>;
        }
        } />
    </>
  );
}
