import React from "react";
import { FormikProps, FormikErrors } from "formik";
import {
  FireInsuranceBudgetFormData,
  SimulatorProps,
  BudgetProps,
  sectionExceptions,
} from "../../types";
import { createBudget } from "../../../../services/fire-insurance-budget";
import {
  successNotification,
  dangerNotification,
} from "../../../../services/notification";
import { createProposalFromBudget } from "../../../../services/fire-insurance-proposal";
import { Grid, Button } from "semantic-ui-react";
import { iRootState } from "../../../../store";
import { allDataAreFilled } from "../../../../util";
import { connect } from "react-redux";
import {
  FireInsuranceBudget,
  FireInsuranceCoverageState,
  ConnectorPropertiesForFireInsuranceQuotationState,
} from "../../../../types/FireInsurance";
import moment from "moment-timezone";
import {
  unmaskCpfOrCnpj,
  unmaskMoney,
  formatAmount,
  unmaskPhoneNumber,
} from "../../../../services/masks";
import { ActionsWrapper } from "../styled-components";
import { initialSimulator, initialFireInsuranceBudget } from "../../constants";
import { QuotePayload } from "../../FireInsuranceBudgetForm";
import { FormatDateYMD } from "../../../../services/date";
import { UserState } from "../../../../types/UserState";

type ActionProps = {
  user: UserState;
} & { setIsLoading: React.Dispatch<React.SetStateAction<boolean>> } & {
  setQuotePayload: React.Dispatch<React.SetStateAction<QuotePayload>>;
} & FormikProps<FireInsuranceBudgetFormData> &
  ReturnType<typeof mapState>;

const mapState = (state: iRootState) => ({
  user: state.user,
  budgetConnectorData: state.fireInsuranceBudget.connectorIdData,
});

const isAbleToPersist = (
  budget: BudgetProps,
  simulator: SimulatorProps,
  errors: FormikErrors<FireInsuranceBudgetFormData>
) => {
  const { insurance, property, owner, tenant } = budget;
  return (
    allDataAreFilled(insurance) &&
    allDataAreFilled(property, sectionExceptions["property"]) &&
    allDataAreFilled(owner, sectionExceptions["owner"]) &&
    allDataAreFilled(tenant, sectionExceptions["tenant"]) &&
    allDataAreFilled(simulator) &&
    !errors.simulator &&
    !errors.budget &&
    simulator.value != 0
  );
};

const Actions = (props: ActionProps) => {
  const {
    user,
    setIsLoading,
    setValues,
    setFieldValue,
    setQuotePayload,
    setErrors,
    setTouched,
    errors,
    budgetConnectorData,
  } = props;

  const { simulator, budget } = props.values;

  const cleanForm = () => {
    setValues({
      simulator: initialSimulator,
      budget: initialFireInsuranceBudget,
    });
    setQuotePayload({} as QuotePayload);
    setFieldValue("budget.insurance.rentValue", "0");
    setErrors({});
    setTouched({});
  };

  const handleSaveBudget = async () => {
    try {
      setIsLoading(true);

      await createBudget(budget, simulator, user.id, budgetConnectorData);
      cleanForm();
      successNotification("Sucesso", "Orçamento salvo com sucesso");
    } catch (e) {
      dangerNotification("Oops...", "Ocorreu um erro ao salvar o orçamento");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveProposal = async () => {
    try {
      setIsLoading(true);
      const insurerValue = simulator.value;
      const budgetData: FireInsuranceBudget = {
        value: insurerValue,
        cover_value:
          simulator.coverValue === 0
            ? simulator.value
            : Number(unmaskMoney(`${simulator.coverValue}`)),
        installments: simulator.installments,
        code: budget.insurance.insurer.code,
        start_date: moment(simulator.startDate).format("YYYY-MM-DD"),
        end_date: moment(simulator.endDate).format("YYYY-MM-DD"),
        validity: simulator.validity,
        iof: simulator.iof,
        user_id: user.id,
        owner_document: unmaskCpfOrCnpj(budget.owner.document),
        owner_name: budget.owner.name,
        owner_email: budget.owner.email,
        tenant_document: unmaskCpfOrCnpj(budget.tenant.document),
        tenant_name: budget.tenant.name,
        tenant_email: budget.tenant.email,
        tenant_birth_date: budget.tenant.birthDate
          ? FormatDateYMD(budget.tenant.birthDate)
          : "",
        tenant_telephone: budget.tenant.telephone
          ? unmaskPhoneNumber(budget.tenant.telephone)
          : undefined,
        property_id_address: budget.property.idAddress,
        property_id_city: budget.property.idCity,
        property_id_state: budget.property.idState,
        property_zipcode: budget.property.zipCode,
        property_street: budget.property.street,
        property_number: budget.property.number,
        property_district: budget.property.district,
        property_city: budget.property.city,
        property_state: budget.property.state,
        property_complement: budget.property.complement || "",
        property_code: budget.property.externalCode || "",
        assurance_rent_value: Number(unmaskMoney(budget.insurance.rentValue)),
        estate_id: budget.insurance.estate.id!,
        brokerage_fee: budget.insurance.broker.fee,
        profit_share: budget.insurance.estate.profitShare,
        occupation_id: budget.insurance.occupation.id,
        assistance_id: budget.insurance.assistance.id,
        quotation_request: budget.quotationRequest,
        estate_percent: budget.insurance.estatePercent,
        broker_percent: budget.insurance.brokerPercent,
        coverage: budget.coverage
          .filter((f) => f !== undefined)
          .map((c, i) => {
            return {
              damageAmount: unmaskMoney(
                formatAmount(c.damageAmount)
              ).toString(),
              rent: unmaskMoney(c.rent).toString(),
              name: c.name,
              idCoverage: c.idCoverage,
              idStateParamFireCoverage: c.idStateParamFireCoverage,
              iof: c.iof,
              maxValue: c.maxValue,
              minValue: c.minValue,
              netPrize: c.netPrize,
              totalPrize: c.totalPrize,
              isActive: c.isActive === undefined ? true : c.isActive,
            } as FireInsuranceCoverageState;
          }),
        insurer_gain: simulator.insurerGain,
        confiax_comission: budget.confiaxComission,
        insurer_name: budget.insurance.insurer.name,
      };

      if (budgetConnectorData) {
        budgetData.connector_details = {
          quotationHeadId: budgetConnectorData.quotationHeadId,
          fluidResponseId: budgetConnectorData.fluidResponseId,
        };
      }

      await createProposalFromBudget(budgetData, budget.insurance.insurer.id);
      cleanForm();
      successNotification("Sucesso", "Proposta salva com sucesso");
    } catch (e) {
      if ((e as any).message) {
        dangerNotification("Oops...", (e as any).message);
      } else {
        dangerNotification("Oops...", "Ocorreu um erro ao salvar a proposta");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ActionsWrapper>
        <Grid.Row>
          <Grid.Column>
            <Button
              type="button"
              fluid
              disabled={!isAbleToPersist(budget, simulator, errors)}
              onClick={handleSaveProposal}
              color="green"
            >
              Realizar Cotação
            </Button>
          </Grid.Column>
        </Grid.Row>
        {/* <Grid.Row>
          <Grid.Column>
            <Button
              type="button"
              fluid
              basic
              disabled={!isAbleToPersist(budget, simulator, errors)}
              onClick={handleSaveBudget}
              color="blue"
            >
              Salvar Seguro
            </Button>
          </Grid.Column>
        </Grid.Row> */}
      </ActionsWrapper>
    </>
  );
};

export default connect(mapState)(Actions);
