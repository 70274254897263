import styled from 'styled-components';

export const ChecklistContainer = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 10px;
  min-width: 150px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 6px;
`;

export const PreviousHiredButton = styled.button`
  background-color: transparent;
  border: none;
  color: #2185d0;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
`;
