import styled from 'styled-components';
import { ErrorMessage } from 'formik';

export const FormikErrorMessage = styled(ErrorMessage)`
  color: #E3242B;
  padding: 0.1em;
`;

export const DivErrorMessage = styled.div<{ clickable?: boolean }>`
  color: #E3242B;
  padding: 0.1em;
  cursor: ${props => props.clickable ? 'pointer': 'initial' };
`;


