import React, { useState, useEffect } from 'react';
import { Table, Dimmer, Segment, Loader, Menu, Icon, Dropdown } from 'semantic-ui-react';
import IconButton from '../../../../components/IconButton';
import { ClickableTableCell, SelectableTableRow } from '../../../../styles';
import { maskCpfOrCnpj } from '../../../../services/masks';
import { Header, NotFoundWrapper, TableComponent, TableWrapper } from './styles';
import { FeatureEnum } from '../../../../enums/authz-feature.enum';
import { ActionEnum } from '../../../../enums/authz-action.enum';
import { Blacklist } from '../../../../types/Blacklist';
import { statusOptions } from '../../types';
import AuthorizationContainer from '../../../../components/AuthorizationContainer';
import { DateToStringDateTime } from '../../../../services/date';

type Props = {
  blacklist: Blacklist[];
  isLoading: boolean;
  getData: Function;
  currentPage: number;
  setCurrentPage: Function;
  dataCount: number;
  handleSelectItem: (item: Blacklist) => void;
  handleEditItem: (itemId: string) => void;
};

const BlacklistTable = (props: Props): JSX.Element => {
  const {
    blacklist,
    isLoading,
    currentPage,
    setCurrentPage,
    dataCount,
    handleSelectItem,
    handleEditItem
  } = props;

  const [listData, setListData] = useState<Blacklist[]>([]);
  const lastPage = Math.floor(dataCount / 10) + 1;

  useEffect(() => {
    setListData(blacklist);
  }, [blacklist]);

  function renderTableData(blacklist: Array<Blacklist>): Array<JSX.Element> {
    const trigger = (
      <AuthorizationContainer action={ActionEnum.read} feature={FeatureEnum.preregisters}>
        <IconButton color="blue" name="ellipsis vertical" />
      </AuthorizationContainer>
    );
    return blacklist.map((item: Blacklist) => {
      return (
        <SelectableTableRow key={item?.id}>
          <Table.Cell>
            {DateToStringDateTime(item?.createdAt || new Date(0, 0, 0))}
          </Table.Cell>

          <ClickableTableCell onClick={() => handleSelectItem(item)}>
            {item?.name}
          </ClickableTableCell>

          <ClickableTableCell onClick={() => handleSelectItem(item)}>
            {maskCpfOrCnpj(item?.document)}
          </ClickableTableCell>

          <ClickableTableCell onClick={() => handleSelectItem(item)}>
            {statusOptions.find(status => status.value === item?.status)?.text}
          </ClickableTableCell>

          <AuthorizationContainer
            action={ActionEnum.update}
            feature={FeatureEnum.blacklist}
          >
            <ClickableTableCell width={1} textAlign={'right'}>
              <Dropdown trigger={trigger} icon={null} direction="left">
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="pencil"
                    text={'Editar Item'}
                    onClick={() => {
                      if (item?.id) handleEditItem(item.id);
                    }}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </ClickableTableCell>
          </AuthorizationContainer>
        </SelectableTableRow>
      );
    });
  }

  return (
    <TableComponent>
      <Dimmer.Dimmable as={Segment} dimmed={isLoading}>
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
        {listData && listData.length > 0 ? (
          <TableWrapper>
            <Table>
              <Header>
                <Table.Row>
                  <Table.HeaderCell>CRIADO EM</Table.HeaderCell>
                  <Table.HeaderCell>NOME</Table.HeaderCell>
                  <Table.HeaderCell>DOCUMENTO</Table.HeaderCell>
                  <Table.HeaderCell>STATUS</Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign={'right'}>
                    <IconButton color="blue" name="setting" />
                  </Table.HeaderCell>
                </Table.Row>
              </Header>
              <Table.Body>{renderTableData(blacklist)}</Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="17">
                    <Menu floated="right" pagination>
                      <Menu.Item>
                        <p>{`${currentPage * 10 - 9} - ${
                          dataCount < currentPage * 10 ? dataCount : currentPage * 10
                        }  de ${dataCount}`}</p>
                      </Menu.Item>
                      <Menu.Item
                        disabled={currentPage === 1}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(1)}
                      >
                        <Icon name="angle double left" />
                      </Menu.Item>
                      <Menu.Item
                        disabled={currentPage === 1}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item as="a">{currentPage}</Menu.Item>
                      <Menu.Item
                        disabled={currentPage === lastPage || blacklist.length < 10}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                      <Menu.Item as="a" icon>
                        <Icon
                          disabled={currentPage === lastPage || blacklist.length < 10}
                          name="angle double right"
                          onClick={() => setCurrentPage(lastPage)}
                        />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </TableWrapper>
        ) : (
          <NotFoundWrapper>
            <p>Blacklist não encontrada</p>
          </NotFoundWrapper>
        )}
      </Dimmer.Dimmable>
    </TableComponent>
  );
};

export default BlacklistTable;
