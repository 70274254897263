import React, { useEffect, useState } from 'react';
import { Grid, Input, Dropdown as DropdownNativo, Icon, Popup } from 'semantic-ui-react';
import { Field, FormikProps } from 'formik';
import SectionLabel from '../../../../../../components/SectionLabel';
import { CustoDropDownItemProps } from '../../../../../../components/types';
import { CreateCapitalizationType } from '../../Formik/types/CreateCapitalizationInitialValuesTypes';
import { FieldLabel } from '../../../../../../styles';
import { maskCpfOrCnpj } from '../../../../../../services/masks';
import { getDataByDocument } from '../../../../../../services/documents';
import { FormatCurrency, UnMaskCurrency } from '../../../../../../util';
import { CapitalizationStatusEnum } from '../../../../enums/capitalization-status.enum';
import { translateStatusCapitalization } from '../../../../util';
import { DropdownOptions } from '../../../create-capitalization-modal/Steps/CapitalizationInfoStep';
import {
  CapitalizationMultipleOptions,
  CapitalizationPlanOptions
} from '../../../create-capitalization-modal/capitalization-options';
import './styles.css';
import CustomDatePicker from '../../../../../../components/CustomDatePicker';
import AppMaskedInput from '../../../../../../components/MaskedInput';

type CapitalizationProps = {
  estates: CustoDropDownItemProps[] | null;
  insurers: DropdownOptions[] | null;
};

const CapitalizationInfoStep = ({
  values,
  insurers,
  estates,
  setFieldValue
}: FormikProps<CreateCapitalizationType> & CapitalizationProps) => {
  const [statusOptions, setStatusOptions] = useState<DropdownOptions[]>([]);
  const [insurer, setInsurer] = useState<any>(null);
  const [estate, setEstate] = useState<any>(null);
  const [status, setStatus] = useState<string | undefined>(values.status);
  const [planSelectPopup, setPlanSelectPopup] = useState<boolean>(false);
  const [disableNameField, setDisabledNameField] = useState<boolean>(true);

  const [loadDocument, setLoadDocument] = useState(false);

  useEffect(() => {
    if (estates) {
      const selected = estates.find(e => e.value.id === values.estateId);
      setEstate(selected?.value);
    }
  }, [estates]);

  useEffect(() => {
    if (insurers) {
      const selected = insurers.find(e => e.value === values.insurerId);
      setInsurer(selected?.value);
    }
  }, [insurers]);

  useEffect(() => {
    if (statusOptions) {
      const st = Object.keys(CapitalizationStatusEnum).find(key => {
        return (CapitalizationStatusEnum as any)[key] === values.status;
      });
      setStatus(st ?? CapitalizationStatusEnum.inIssue);
    }
  }, [statusOptions]);

  useEffect(() => {
    const statusDropdownOptions = Object.keys(CapitalizationStatusEnum).map(
      (key, index) => ({
        index,
        value: key,
        text: translateStatusCapitalization(
          (CapitalizationStatusEnum as any)[key] as string
        )
      })
    );
    setStatusOptions(statusDropdownOptions);
  }, []);

  async function getLessorByDocument(document: string) {
    try {
      setLoadDocument(true);
      const unformatedDoc = document.replace(/\D/g, '');
      const data = await getDataByDocument(unformatedDoc);
      const isCPF = unformatedDoc.length === 11;
      if (isCPF) {
        setDisabledNameField(true);
        setFieldValue('lessorName', data.name);
      } else {
        setDisabledNameField(false);
        setFieldValue('lessorName', data.company);
      }
    } catch (err) {
      setFieldValue('lessorName', '');
      setDisabledNameField(false);
    } finally {
      setLoadDocument(false);
    }
  }

  return (
    <div style={{ padding: '0 0 0 10px' }}>
      <SectionLabel text="Informações Gerais" />
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column width={4}>
            <FieldLabel>Imobiliária</FieldLabel>
            <DropdownNativo
              fluid
              search
              clearable
              selection
              value={estate}
              options={estates || []}
              placeholder={'Selecionar...'}
              onChange={(e, { value }) => {
                setEstate(value);
                setFieldValue('estateId', value);
              }}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <FieldLabel>Seguradora emissora</FieldLabel>
            <DropdownNativo
              fluid
              search
              selection
              clearable
              value={insurer}
              options={insurers || []}
              placeholder={'Selecionar...'}
              onChange={(e, { value }) => {
                setInsurer(value);
                setFieldValue('insurerId', value);
              }}
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <FieldLabel>Status</FieldLabel>
            <DropdownNativo
              fluid
              search
              selection
              clearable
              value={status}
              options={statusOptions}
              placeholder={'Selecionar...'}
              onChange={(e, { value }) => {
                setStatus((value as string) || undefined);
                setFieldValue('status', value);
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <SectionLabel text="Informações do proprietário" />
        <Grid.Row>
          <Grid.Column width={4}>
            <FieldLabel>Documento do proprietário</FieldLabel>
            <Field
              name="lessorDocument"
              value={values.lessorDocument}
              component={Input}
              fluid
              loading={loadDocument}
              onChange={(_e: any, { value }: { value: string }) => {
                setFieldValue('lessorDocument', maskCpfOrCnpj(value));
              }}
              onBlur={(e: any) => {
                getLessorByDocument(e.target.value);
              }}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <FieldLabel>Nome do proprietário</FieldLabel>
            <Field
              name="lessorName"
              value={values.lessorName}
              component={Input}
              fluid
              loading={loadDocument}
              disabled={disableNameField}
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('lessorName', value)
              }
            />
          </Grid.Column>
        </Grid.Row>
        <SectionLabel text="Valores da locação e do título" />
        <Grid.Row>
          <Grid.Column width={4}>
            <FieldLabel>Valor do aluguel</FieldLabel>
            <Field
              name="rentalValue"
              value={FormatCurrency(values.rentalValue)}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) => {
                setFieldValue('rentalValue', UnMaskCurrency(value));
                setFieldValue(
                  'totalValue',
                  ((values.taxesValue || 0) +
                    (UnMaskCurrency(value) || 0) +
                    (values.gasBill || 0) +
                    (values.energyBill || 0) +
                    (values.condominiumFee || 0) +
                    (values.waterBill || 0)) *
                    (values.multiplier || 0)
                );
              }}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <FieldLabel>Valor do condomínio</FieldLabel>
            <Field
              name="condominiumFee"
              value={FormatCurrency(values.condominiumFee)}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) => {
                setFieldValue('condominiumFee', UnMaskCurrency(value));
                setFieldValue(
                  'totalValue',
                  ((values.taxesValue || 0) +
                    (UnMaskCurrency(value) || 0) +
                    (values.gasBill || 0) +
                    (values.energyBill || 0) +
                    (values.rentalValue || 0) +
                    (values.waterBill || 0)) *
                    (values.multiplier || 0)
                );
              }}
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Início da Vigência</FieldLabel>
            <CustomDatePicker
              placeholderText={'dd/mm/aaaa'}
              name="startVigency"
              selected={values.startVigency}
              onChange={(date: Date) => setFieldValue('startVigency', date)}
              customInput={
                <AppMaskedInput
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/
                  ]}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              style={{
                marginTop: 0
              }}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <FieldLabel>Fim da Vigência</FieldLabel>
            <CustomDatePicker
              placeholderText={'dd/mm/aaaa'}
              name="endVigency"
              selected={values.endVigency}
              onChange={(date: Date) => setFieldValue('endVigency', date)}
              customInput={
                <AppMaskedInput
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/
                  ]}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              style={{
                marginTop: 0
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <FieldLabel>Valor IPTU</FieldLabel>
            <Field
              name="taxesValue"
              value={FormatCurrency(values.taxesValue)}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) => {
                setFieldValue('taxesValue', UnMaskCurrency(value));
                setFieldValue(
                  'totalValue',
                  ((values.condominiumFee || 0) +
                    (UnMaskCurrency(value) || 0) +
                    (values.gasBill || 0) +
                    (values.energyBill || 0) +
                    (values.rentalValue || 0) +
                    (values.waterBill || 0)) *
                    (values.multiplier || 0)
                );
              }}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <FieldLabel>Valor da Energia</FieldLabel>
            <Field
              name="energyBill"
              value={FormatCurrency(values.energyBill)}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) => {
                setFieldValue('energyBill', UnMaskCurrency(value));
                setFieldValue(
                  'totalValue',
                  ((values.condominiumFee || 0) +
                    (UnMaskCurrency(value) || 0) +
                    (values.gasBill || 0) +
                    (values.taxesValue || 0) +
                    (values.rentalValue || 0) +
                    (values.waterBill || 0)) *
                    (values.multiplier || 0)
                );
              }}
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Valor da Agua</FieldLabel>
            <Field
              name="waterBill"
              value={FormatCurrency(values.waterBill)}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) => {
                setFieldValue('waterBill', UnMaskCurrency(value));
                setFieldValue(
                  'totalValue',
                  ((values.condominiumFee || 0) +
                    (values.energyBill || 0) +
                    (values.gasBill || 0) +
                    (values.taxesValue || 0) +
                    (values.rentalValue || 0) +
                    (UnMaskCurrency(value) || 0)) *
                    (values.multiplier || 0)
                );
              }}
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Valor do gás</FieldLabel>
            <Field
              name="gasBill"
              value={FormatCurrency(values.gasBill)}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) => {
                setFieldValue('gasBill', UnMaskCurrency(value));
                setFieldValue(
                  'totalValue',
                  ((values.condominiumFee || 0) +
                    (values.energyBill || 0) +
                    (UnMaskCurrency(value) || 0) +
                    (values.rentalValue || 0) +
                    (values.taxesValue || 0) +
                    (values.waterBill || 0)) *
                    (values.multiplier || 0)
                );
              }}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={3}>
            <FieldLabel>
              Selecione o Plano
              <Popup
                on="click"
                open={planSelectPopup}
                onOpen={() => setPlanSelectPopup(true)}
                onClose={() => setPlanSelectPopup(false)}
                trigger={
                  <span
                    style={{
                      color: '#2185d0',
                      fontWeight: 'bold',
                      fontSize: '10px',
                      cursor: 'pointer'
                    }}
                  >
                    <Icon
                      onClick={() => setPlanSelectPopup(true)}
                      circular
                      inverted
                      size={'small'}
                      name={'info'}
                      color={'blue'}
                      style={{
                        cursor: 'pointer',
                        marginLeft: '5px',
                        marginBottom: '5px'
                      }}
                      on
                    />
                  </span>
                }
                content={
                  <table className="tg">
                    <thead>
                      <tr>
                        <th className="tg-0l47" colSpan={5}>
                          PORTO SEGURO
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="tg-9mqn">12 MESES</td>
                        <td className="tg-9mqn">RESGATE 100% NO FINAL DA VIGÊNCIA</td>
                        <td className="tg-9mqn"></td>
                        <td className="tg-9mqn">15 MESES</td>
                        <td className="tg-9mqn">RESGATE 100% NO FINAL DA VIGÊNCIA</td>
                      </tr>

                      <tr>
                        <td className="tg-9mqn">18 MESES</td>
                        <td className="tg-9mqn">RESGATE 100% NO FINAL DA VIGÊNCIA</td>
                        <td className="tg-9mqn"></td>
                        <td className="tg-9mqn">24 MESES</td>
                        <td className="tg-9mqn">RESGATE 100% NO FINAL DA VIGÊNCIA</td>
                      </tr>

                      <tr>
                        <td className="tg-jbdd" colSpan={5}>
                          30 MESES: RESGATE 100% NO FINAL DA VIGÊNCIA
                        </td>
                      </tr>

                      <tr>
                        <td className="tg-sq3k" colSpan={5}>
                          A Porto Seguro disponibiliza o serviço de{' '}
                          <span
                            style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                          >
                            assistência jurídica gratuita{' '}
                          </span>
                          (cobrança e ação de despejo) para títulos com valores
                          <br />
                          superiores a{' '}
                          <span
                            style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                          >
                            6 mil reais
                          </span>
                          , arcando com as custas processuais e honorários advocatícios.{' '}
                        </td>
                      </tr>

                      <tr>
                        <td className="tg-ohj1" colSpan={5}>
                          ICATU
                        </td>
                      </tr>

                      <tr>
                        <td className="tg-9yt2">12 MESES</td>
                        <td className="tg-9yt2">RESGATE 100% NO FINAL DA VIGÊNCIA</td>
                        <td className="tg-9yt2"></td>
                        <td className="tg-9yt2">15 MESES</td>
                        <td className="tg-9yt2">RESGATE 100% NO FINAL DA VIGÊNCIA</td>
                      </tr>

                      <tr>
                        <td className="tg-9yt2">18 MESES</td>
                        <td className="tg-9yt2">RESGATE 100% NO FINAL DA VIGÊNCIA</td>
                        <td className="tg-9yt2"></td>
                        <td className="tg-9yt2">30 MESES</td>
                        <td className="tg-9yt2">RESGATE 100% NO FINAL DA VIGÊNCIA</td>
                      </tr>

                      <tr>
                        <td className="tg-addw" colSpan={5}>
                          A ICATU disponibiliza o serviço de{' '}
                          <span
                            style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                          >
                            assistência jurídica gratuita{' '}
                          </span>
                          (cobrança e ação de despejo) para títulos com valores
                          <br />
                          superiores a{' '}
                          <span
                            style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                          >
                            5 mil reais
                          </span>
                          , todavia, as custas processuais ocorrerão por conta do LOCADOR,
                          podendo ele efeturar o pagamento
                          <br />
                          dos boletos ou deduzir o custo do valor do título. Os honorários
                          advocatícios correrão por conta da Seguradora.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
                position={'left center'}
              />
            </FieldLabel>
            <div style={{ width: '100%' }}>
              <DropdownNativo
                upward
                style={{ width: '100%' }}
                name={`plan`}
                options={CapitalizationPlanOptions}
                value={values.plan}
                placeholder={'Selecionar...'}
                selection
                onChange={(e, { value }) => setFieldValue('plan', value)}
                clearable
              />
            </div>
          </Grid.Column>

          <Grid.Column width={3}>
            <FieldLabel
              style={{
                marginBottom: '5px'
              }}
            >
              Multiplicador
            </FieldLabel>
            <div style={{ width: '100%' }}>
              <DropdownNativo
                upward
                selection
                value={values.multiplier}
                options={CapitalizationMultipleOptions}
                placeholder={'Selecionar...'}
                onChange={(e, { value }) => {
                  setFieldValue('multiplier', value);
                  setFieldValue(
                    'totalValue',
                    ((values.condominiumFee || 0) +
                      (values.energyBill || 0) +
                      (values.gasBill || 0) +
                      (values.rentalValue || 0) +
                      (values.taxesValue || 0) +
                      (values.waterBill || 0)) *
                      (value as number)
                  );
                }}
                clearable
              />
            </div>
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel
              style={{
                marginBottom: '5px'
              }}
            >
              Valor do Título
            </FieldLabel>
            <Field
              name="totalValue"
              value={FormatCurrency(values.totalValue)}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('totalValue', UnMaskCurrency(value))
              }
            />
            <span style={{ width: '400px' }}>
              {`Valor Sugerido: R$ ${FormatCurrency(
                ((values.condominiumFee || 0) +
                  (values.energyBill || 0) +
                  (values.gasBill || 0) +
                  (values.taxesValue || 0) +
                  (values.rentalValue || 0) +
                  (values.waterBill || 0)) *
                  (values.multiplier || 0)
              )}`}
            </span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Column width={6}>
          <FieldLabel style={{ color: 'red', marginTop: '-35px' }}>Atenção</FieldLabel>
          <div style={{ color: 'red', border: '1px solid red', borderRadius: '5px' }}>
            <p style={{ color: 'red', padding: '5px' }}>
              O resgate integral só é feito no aniversário conforme plano selecionado (ao
              final da vigência do título).
            </p>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default CapitalizationInfoStep;
