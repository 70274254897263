import React from 'react';
import { CheckboxProps, Checkbox } from "semantic-ui-react";
import styled from 'styled-components';

const StyledCheckbox = styled(Checkbox)`
  padding-top: 10px;
`

const Toggle = (props: CheckboxProps) => {

  return (
    <>
      <StyledCheckbox toggle {...props} />
    </>
  )
}

export default Toggle;