import { withFormik } from "formik";
import { NotificationEmailsValues } from "./initialStates";
import NotificationEmailsForm from '../Steps/NotificationEmailsFormStep';
export const NotificationEmailsFormik = withFormik<any, typeof NotificationEmailsValues>({

  mapPropsToValues: () => NotificationEmailsValues,

  handleSubmit: (values, bag) => true,

}) ( NotificationEmailsForm );
