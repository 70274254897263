import styled from 'styled-components';

export const Container = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  min-height: 250px;
`;

export const ContainerAsADiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  min-height: 250px;
`;
