import React, { useState } from "react";
import {
  Button,
  Header,
  Icon,
  Modal,
  Step,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import { NotificationEmailsFormik } from "../Formik/NotificationEmailsFormFormik";
import { put } from "../../../../../services/request";
import { updateNotificationEmails } from "../../../../../services/canceled-bond-insurance";
import {
  dangerNotification,
  successNotification,
  warningNotification,
} from "../../../../../services/notification";
import { NotificationEmailsModalProps } from '../types/NotificationEmailsModalProps';
import { isEmail } from "../../../../../util";

const NotificationEmailsModal = ({
  openModal,
  closeModal,
  setOpen,
  canceledId,
  callback,
  triggerOptions,
  emails
}: NotificationEmailsModalProps) => {
  const [flagSubmitFrom, setFlagSubmitFrom] = useState(false);
  const [stepOfEmailsModal, setStepOfEmailsModal] = useState(
    "confirmation"
  );

  const [loading, setLoading] = useState(false);

  async function handleSubmit(payload: any) {
    // console.log('PAYLOAD: ', payload);
    let error = false;
    const validEmails = payload.emails.reduce((validEmails: string[], email: string) => {
      if (email !== '') {
        validEmails.push(email);
      }
      return validEmails;
    }, []);
    validEmails.forEach((email: string) => {
      if(!isEmail(email)) {
        warningNotification('Oops...', `O email ${email} é inválido, por favor corrija-o.`);
        setLoading(false);
        error = true;
      }
    })
    const emails = payload.emails.join(';')
    if(!error) {
      try {
        setLoading(true);
        const response = await updateNotificationEmails(canceledId, emails, payload.userId); // put(`/canceled-bond-insurances/emails/${canceledId}`, payload);
        // console.log('RESPOSTA DO SUBMIT', response);
        successNotification("Sucesso", "Emails de Notificação atualizados com sucesso.");
      } catch (err) {
        dangerNotification("Erro", "Erro ao efetuar solicitação.", 4000);
        console.log(err);
      } finally {
        if (callback) callback();
        setLoading(false);
        setFlagSubmitFrom(false);
        closeModal();
      }
    }
  }

  return (
    <div style={{ outline: 'none' }}>

      <Modal
        closeIcon
        open={openModal}
        onClose={() => closeModal()}
        trigger={
          <Button style={{ backgroundColor: typeof triggerOptions === 'undefined' && "#fff" }} {...triggerOptions} >
            <Icon name={"envelope"} />
            Emails de Notificação
          </Button>
        }
        onOpen={() => setOpen()}
      >
        <Header icon="file alternate outline" content={`Emails de Notificação`} />
        <Modal.Content>
          <Dimmer active={loading}>
            <Loader indeterminate> Atualizando emails... </Loader>
          </Dimmer>
          <Step.Group>
            <Step
              onClick={() => setStepOfEmailsModal("confirmation")}
              active={stepOfEmailsModal === "confirmation"}
              title={`Gerenciar Emails para Notificação`}
              description=""
            />
          </Step.Group>
          {stepOfEmailsModal === "confirmation" && (
            <NotificationEmailsFormik
              flagSubmit={flagSubmitFrom}
              setFlagSubmit={setFlagSubmitFrom}
              callbackSubmit={handleSubmit}
              canceledId={canceledId}
              emails={emails}
              actionText={"Adicione, remova ou altere os emails de notificação deste cancelamento:"}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={() => closeModal()}>
            <Icon name="remove" />
            Sair
          </Button>
          <Button color="green" onClick={() => setFlagSubmitFrom(true)}>
            <Icon name="checkmark" />
            Atualizar
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
export default NotificationEmailsModal;
