/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import { connect } from "react-redux";
import {
  Modal,
  Button,
  Form,
  Table,
  Icon,
  TextArea,
  Grid,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import styled from "styled-components";
import { ModalProps } from "../../../../components/types";
import { BondInsuranceAnalysisStatusEnum } from "../../../../enums/bond-insurance-analysis-status.enum";
import { statusOptions } from "../../types";
import StatusDropdown from "../../../../components/StatusDropdown";
import Input from "../../../../components/Input";

import {
  successNotification,
  dangerNotification,
  warningNotification,
} from "../../../../services/notification";
import { IconText } from "../../../../components/IconText";
import {
  maskMoney,
  unmaskMoney,
} from "../../../../services/masks";
import { CalculationModeEnum } from "../../../../enums/calculation-mode.enum";
import { UserState } from "../../../../types/UserState";
import {
  BondInsuranceAnalysisForGetBondInsurancesResponseDTO,
  BondInsuranceForGetBondInsurancesResponseDTO,
  BondInsurancePropertyForGetBondInsurancesResponseDTO,
  NaturalTenantForGetBondInsurancesResponseDTO,
} from "../../../../dtos/bond-insurance-list/get-bond-insurances-response.dto";
import FileUploader, {
  FileAttachmentProps,
} from "../../../../components/UploadFiles";
import { EstateState } from "../../../../types/EstateState";
import { getFilesLinkAxios } from "../../../../services/file";
import { get } from "../../../../services/request";

export const ConfirmationModalContent = styled(Modal.Content)`
  line-height: 1.8rem;
  div:last-child:not(:first-child) {
    font-weight: bold;
  }
`;

export type AnalysisDetailsModal = {
  initialParcel: number[];
  bondInsurance: BondInsuranceForGetBondInsurancesResponseDTO;
  analysisDetails: BondInsuranceAnalysisForGetBondInsurancesResponseDTO[];
  naturalTentant: NaturalTenantForGetBondInsurancesResponseDTO;
  validityMonths: number;
  user: UserState;
  totalAmount: number;
  rentValue: number;
  calculationMode: CalculationModeEnum;
  estate?: EstateState;
  property: BondInsurancePropertyForGetBondInsurancesResponseDTO;
  bondInsuranceId: string;
} & ModalProps;

type PersonalInformationProps = {
  cpf: string;
  name: string;
  birthDate: string;
  rg: string;
  issuingAgency: string;
  email: string;
  cellphone: string;
  maritalStatus: string;
  nationality: string;
};

const AnalysisProgressModal = (props: AnalysisDetailsModal): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = (): void => {
    setIsOpen(true);
  };
  const close = (): void => {
    setIsOpen(false);
  };

  const { header, trigger, callback, user } = props;

  const {
    analysisDetails,
    validityMonths,
    bondInsuranceId,
  } = props;

  const analysisFormData: any[] = [];

  analysisDetails.forEach((analysis) => {
    const { id, status, observations } = analysis;
    const maximumInstallmentOption = analysis.complete_values?.installments_data.find(
      (i) => {
        return (
          i.installments_amount ===
          analysis.complete_values.maximum_installments_amount
        );
      }
    );
    let maximumFormattedInstallmentValue: string;

    if (maximumInstallmentOption) {
      maximumFormattedInstallmentValue = maskMoney(
        `${maximumInstallmentOption.total_premium_amount}`,
        true
      );
    } else {
      maximumFormattedInstallmentValue = analysis.value
        ? maskMoney(`${analysis.value}`, true)
        : "0,00";
    }

    analysisFormData.push({
      id,
      status,
      observations,
      insurer: analysis.insurer,
      value: maximumFormattedInstallmentValue,
      completeValues: analysis.complete_values,
      createdAt: analysis.created_at
    });
  });

  const getStatusColor = (status: string): string => {
    switch (status) {
      case BondInsuranceAnalysisStatusEnum.pending:
        return "#FBBD08";
      case BondInsuranceAnalysisStatusEnum.proposal:
        return "#710193";
      case BondInsuranceAnalysisStatusEnum.analysis:
        return "#2185D0";
      case BondInsuranceAnalysisStatusEnum.approved:
        return "#21BA45";
      case BondInsuranceAnalysisStatusEnum.rejected:
        return "#DB2828";
      case BondInsuranceAnalysisStatusEnum.biometry:
        return "#FE9A76";
      case BondInsuranceAnalysisStatusEnum.waiting:
        return "grey";
      case BondInsuranceAnalysisStatusEnum.error:
        return "black";
      default:
        return "grey";
    }
  };

  const getStatusText = (status: string): string => {
    switch (status) {
      case BondInsuranceAnalysisStatusEnum.waiting:
        return "EM ESPERA";
      case BondInsuranceAnalysisStatusEnum.pending:
        return "PENDENCIADO";
      case BondInsuranceAnalysisStatusEnum.proposal:
        return "EM PROPOSTA";
      case BondInsuranceAnalysisStatusEnum.analysis:
        return "EM ANÁLISE";
      case BondInsuranceAnalysisStatusEnum.approved:
        return "APROVADO";
      case BondInsuranceAnalysisStatusEnum.rejected:
        return "REPROVADO";
      case BondInsuranceAnalysisStatusEnum.biometry:
        return "BIOMETRIA";
      default:
        return "INDEFINIDO";
    }
  };

  return (
    <Modal
      style={{ width: "1200px" }}
      as={Form}
      open={isOpen}
      onOpen={open}
      onClose={close}
      trigger={trigger}
    >
      <Modal.Header>{header}</Modal.Header>
      <Formik
        initialValues={{ analysis: analysisFormData }}
        validationSchema={{}}
        onSubmit={() => { }}
      >
        {(innerProps) => {
          const { setFieldValue, setFieldTouched, values } = innerProps;
          const { analysis } = values;

          return (
            <>

              <ConfirmationModalContent>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      {!user.role!.includes("ESTATE") && (
                        <>
                          <Table.HeaderCell width={1}>#</Table.HeaderCell>
                          <Table.HeaderCell width={3}>
                            SEGURADORA
                          </Table.HeaderCell>
                          <Table.HeaderCell width={2}>
                            STATUS DA ANÁLISE
                          </Table.HeaderCell>
                          <Table.HeaderCell width={3}>
                            OBSERVAÇÕES
                          </Table.HeaderCell>
                          <Table.HeaderCell width={2}>
                            VALOR DA PARCELA
                          </Table.HeaderCell>
                        </>
                      )}
                      {user.role!.includes("ESTATE") && (
                        <>
                          <Table.HeaderCell width={8}>
                            SEGURADORA
                          </Table.HeaderCell>
                          <Table.HeaderCell width={5}>
                            STATUS DA ANÁLISE
                          </Table.HeaderCell>
                          <Table.HeaderCell width={3}>
                            VALOR DA PARCELA
                          </Table.HeaderCell>
                        </>
                      )}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {analysis.map((a, index) => {
                      const newInstallments = validityMonths;

                      return (
                        <>
                          <Table.Row key={index}>
                            {!user.role!.includes("ESTATE") && (
                              <Table.Cell>
                                <Icon
                                  name="circle"
                                  style={{ color: getStatusColor(a.status) }}
                                ></Icon>
                              </Table.Cell>
                            )}
                            <Table.Cell>{a.insurer.name}</Table.Cell>
                            <Table.Cell>
                              {user.role!.includes("ESTATE") && (
                                <IconText
                                  iconName={"circle"}
                                  iconColor={getStatusColor(a.status)}
                                  text={getStatusText(a.status)}
                                />
                              )}
                              {!user.role!.includes("ESTATE") && (
                                <Field
                                  name={`analysis[${index}].status`}
                                  value={a.status}
                                  defaultValue={
                                    a.status ===
                                      BondInsuranceAnalysisStatusEnum.error
                                      ? null
                                      : a.status
                                  }
                                  completeValue={true}
                                  placeholder={"Selecionar..."}
                                  options={statusOptions}
                                  component={StatusDropdown}
                                  disabled={true}
                                  onChange={() => { }}
                                  onClose={() =>
                                    setFieldTouched(
                                      `analysis[${index}].status`,
                                      true
                                    )
                                  }
                                  fluid
                                />
                              )}
                            </Table.Cell>
                            {!user.role!.includes("ESTATE") && (
                              <Table.Cell>
                                <Field
                                  name={`analysis[${index}].observations`}
                                  rows={1}
                                  value={a.observations}
                                  disabled={true}
                                  onChange={() => { }}
                                  onBlur={() =>
                                    setFieldTouched(
                                      `analysis[${index}].observations`,
                                      true
                                    )
                                  }
                                  component={TextArea}
                                  fluid
                                />
                              </Table.Cell>
                            )}
                            {!user.role!.includes("ESTATE") && (
                              <Table.Cell>
                                <Grid>
                                  <Grid.Row style={{ alignItems: "center" }}>
                                    <Grid.Column width={1}>
                                      {`${newInstallments}x`}
                                    </Grid.Column>
                                    <Grid.Column width={13}>
                                      <Field
                                        name={`analysis[${index}].value`}
                                        disabled={true}
                                        value={analysis[index].value}
                                        onChange={() => { }}
                                        onBlur={() =>
                                          setFieldTouched(
                                            `analysis[${index}].value`,
                                            true
                                          )
                                        }
                                        component={Input}
                                        fluid
                                      />
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>
                              </Table.Cell>
                            )}
                            {user.role!.includes("ESTATE") && (
                              <Table.Cell>
                                <p>
                                  {a.value && unmaskMoney(a.value) > 0
                                    ? `${newInstallments} x R$ ${maskMoney(
                                      `${a.value}` || "0"
                                    )}`
                                    : ""}
                                </p>
                              </Table.Cell>
                            )}

                          </Table.Row>
                        </>
                      );
                    })}
                  </Table.Body>
                </Table>
              </ConfirmationModalContent>

              {
                <Modal.Actions>
                  <Button
                    color={"red"}
                    onClick={async () => {
                      setIsOpen(false);
                    }}
                  >
                    <Icon name={"close"} />
                    Fechar
                  </Button>
                </Modal.Actions>
              }
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
export default connect()(AnalysisProgressModal);
