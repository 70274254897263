import React, { useEffect, useState } from 'react';
import { Button, Confirm, Dimmer, Icon, Loader, Modal, Popup } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import PottencialView from './Pottencial';
import { hirePottencial } from '../../../../services/fire-insurance-policy';

import { FireInsuranceQuotation } from '../../../../types/FireInsuranceQuotation';
import { successNotification } from '../../../../services/notification';
import { InsurersCodeEnum } from '../../../../enums/insurers-code.enum';
import TokioMarineSegimobView from './TokioMarineSegimob';
import { post } from '../../../../services/request';
import PortoSeguroView from './PortoSeguro';
import { budgetIsExpired } from '../../utils/PortoSeguro';
import AlfaCreatePolicyModal from '../../budget/forms-based-in-insurer/Alfa/components/CreatePolicyModal';
import { get } from '../../../../services/storage';
import { BudgetContext } from '../../budget/forms-based-in-insurer/Alfa';
interface DetailModalInterface {
  open: boolean;
  fireInsuranceAnalysis: FireInsuranceQuotation;
  close: () => void;
  hire?: boolean;
}

const DetailModal = (props: DetailModalInterface) => {
  const history = useHistory();
  const { fireInsuranceAnalysis, close, open } = props;
  const [showJson, setShowJson] = useState<boolean>(false);
  const [isHiring, setIsHiring] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const userId = get('uid');
  const role = get('role');

  const budgetExpired = budgetIsExpired(fireInsuranceAnalysis?.created_at);

  const handleConfirmCancel = () => {
    setConfirmOpen(false);
  };

  const handleConfirmConfirm = () => {
    setConfirmOpen(false);
    hire();
  };

  useEffect(() => {
    if (open) {
      if (props.hire) {
        setConfirmOpen(true);
      }
    }
  }, [open, props.hire]);

  const hire = async () => {
    setIsHiring(true);
    switch (fireInsuranceAnalysis?.insurer.code) {
      case 'POTTENCIAL':
        {
          const dto = {
            fireInsuranceAnalysisId: fireInsuranceAnalysis.id,
            userId: String(userId)
          };

          try {
            console.log('HIRE', dto);
            const data = await hirePottencial(dto);

            if (data.success) {
              successNotification(
                'Solicitação de Contratação',
                'Solicitação de contratação encaminhada com sucesso'
              );
              history.push('/admin/fire-insurance-policies');
            }
          } catch (err) {
            console.error('Erro ao realizar contratação!');
            alert((err as any).message);
          }
        }
        break;
      case InsurersCodeEnum.TokioMarineSegimob:
        console.log('OK');
        try {
          const data = await post('/fire-insurance-proposals/hire/segimob', {
            fireInsuranceAnalysisId: fireInsuranceAnalysis.id
          });

          if (data.success) {
            successNotification(
              'Solicitação de Contratação',
              'Solicitação de contratação encaminhada com sucesso'
            );
            history.push('/admin/fire-insurance-policies');
          }
        } catch (err) {
          console.log(err);
          console.error('Erro ao realizar contratação! ', err);
        }
        break;
      case InsurersCodeEnum.Porto:
        try {
          await post('/fire-insurance-proposals/porto-seguro/proposal', {
            fireInsuranceAnalysisId: fireInsuranceAnalysis.id
          });

          successNotification(
            'Solicitação de Contratação',
            'Solicitação de contratação encaminhada com sucesso'
          );

          history.push('/admin/fire-insurance-policies');
        } catch (err) {
          console.log(err);
          console.error('Erro ao realizar contratação! ', err);
        }
        break;
      default:
        alert('Não implementado');
        break;
    }
    setIsHiring(false);
  };

  const renderView = (insurerCode: string) => {
    switch (insurerCode) {
      case 'POTTENCIAL':
        return (
          <PottencialView fireInsurance={fireInsuranceAnalysis} showJson={showJson} />
        );
      case InsurersCodeEnum.TokioMarineSegimob:
        return (
          <TokioMarineSegimobView
            fireInsurance={fireInsuranceAnalysis as any}
            showJson={showJson}
          />
        );
      case InsurersCodeEnum.Porto:
        return (
          <PortoSeguroView
            fireInsurance={fireInsuranceAnalysis as any}
            showJson={showJson}
            budgetExpired={budgetExpired}
          />
        );
      case InsurersCodeEnum.alfa:
        return (
          <AlfaCreatePolicyModal
            open={true}
            onClose={() => {close()}}
            isHireRequest={true}
            fireInsuranceAnalysisId={fireInsuranceAnalysis.id}
            context={BudgetContext.detail}
          />
        );

      default:
        return <div>Não implementado</div>;
    }
  };

  return (
    <>
      <Dimmer active={isHiring} inverted>
        <Loader />
      </Dimmer>

      {fireInsuranceAnalysis?.insurer.code === InsurersCodeEnum.alfa ? (
        renderView(fireInsuranceAnalysis?.insurer.code)
      ) : (
        <Modal open={open} onClose={close}>
          <Modal.Header>
            Detalhes da Cotação de Seguro Incêndio
            {role?.includes('BROKER') && (
              <Popup
                trigger={
                  <Icon
                    name={showJson ? 'eye' : 'eye slash'}
                    onClick={() => setShowJson(!showJson)}
                    style={{ marginLeft: 10, color: showJson ? '#333' : '#999' }}
                  />
                }
                content={'Mostrar JSON'}
              />
            )}
          </Modal.Header>
          <Modal.Content scrolling>
            {renderView(fireInsuranceAnalysis?.insurer.code)}
            <Confirm
              open={confirmOpen}
              onCancel={handleConfirmCancel}
              onConfirm={handleConfirmConfirm}
              content={'Tem certeza que deseja contratar o Seguro Incêndio?'}
              header={'Contratar Seguro Incêndio'}
              confirmButton={'Contratar'}
              cancelButton={'Cancelar'}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button icon labelPosition={'left'} onClick={close}>
              <Icon name={'arrow left'} />
              Voltar
            </Button>

            {fireInsuranceAnalysis?.status === 'APPROVED' && (
              <Button
                positive
                icon
                labelPosition={'right'}
                onClick={() => setConfirmOpen(true)}
                loading={isHiring}
                disabled={isHiring || budgetExpired}
              >
                <Icon name={'checkmark'} />
                Contratar
              </Button>
            )}
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

export default DetailModal;
