import LZString from 'lz-string';

// Função para obter dados descomprimidos do localStorage
export const get = (key: string): string | null => {
  try {
    const compressedData = localStorage.getItem(key);
    if (compressedData) {
      const decompressedData = LZString.decompress(compressedData);
      if (decompressedData) {
        return decompressedData;
      }
    }
  } catch (error) {
    console.error(`Erro ao descomprimir dados para a chave ${key}:`, error);
  }
  return null;
};

// Função para verificar existência de uma chave no localStorage
export const exists = (key: string): boolean => get(key) !== null;

// Função para salvar dados comprimidos no localStorage
export const set = (key: string, value: string): void => {
  try {
    const compressedData = LZString.compress(value);
    localStorage.setItem(key, compressedData);
  } catch (error) {
    console.error(`Erro ao comprimir e salvar dados para a chave ${key}:`, error);
  }
};

// Função para limpar todo o localStorage
export const clear = (): void => localStorage.clear();

// Função para remover um item específico do localStorage
export const remove = (key: string): void => localStorage.removeItem(key);
