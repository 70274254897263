import React, { useState, SyntheticEvent, useEffect } from 'react';
import Input from '../../../components/Input';
import BrokersDropdown from '../../../components/BrokersDropdown';
import SectionLabel from '../../../components/SectionLabel';
import {
  Grid,
  Dropdown as DropdownNativo,
  InputOnChangeData,
  Icon,
  Button,
  SemanticWIDTHS,
  TextArea
} from 'semantic-ui-react';
import { Field } from 'formik';
import {
  unmaskCpfOrCnpj,
  maskCnpj,
  maskZipCode,
  maskPhoneNumber,
  maskCpfOrCnpj
} from '../../../services/masks';
import { docValidator } from '../../../services/validators';
import { FormikErrorMessage, DivErrorMessage } from '../../../components/ErrorMessage';
import { Dropdown } from 'semantic-ui-react';
import { FieldLabel } from '../../../styles';
import { EstateFormProps } from '../EstateForm';
import { getUsers } from '../../../services/broker';
import { dangerNotification } from '../../../services/notification';
import { CustoDropDownItemProps } from '../../../components/types';
import { isEmail, UsersToCustomDropDownOptions } from '../../../util';
import { UserForEstateForm } from '../types';
import { AddressService } from '../../../services/address';
import styled from 'styled-components';
import { categories, classifications, options, optionsSource, companyOptions } from './constants';
import { PartnerHeader, PartnerLabel } from '../styles';
import { EstatePartner } from '../../../types/EstatePartner';
import { deleteByID } from '../../../services/estate-partner';

const AdditionalEmailWrapper = styled.div`
  display: flex;
  gap: 10px;

  > div:nth-child(1) {
    width: 50%;
  }

  > div:nth-child(2) {
    width: 40%;
  }

  > div:nth-child(3) {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 5px;

  > textarea {
    background-color: #f5f8fa;
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    padding: 10px 4px;
    outline: 0;

    &:focus {
      border-color: #85b7d9;
    }
  }

  > label {
    // font-weight: bold;
    margin-bottom: 5px;
  }
`;

export const EstateData = (props: EstateFormProps) => {
  const {
    errors,
    touched,
    handleInputChange,
    values,
    setFieldValue,
    availableBrokersAndEstates,
    initialValues
  } = props;
  const [isValidCnpj, setValidCnpj] = useState<boolean>(true);
  const [isAnalystsLoading, setIsAnalystsLoading] = useState<boolean>(true);
  const [analystsOptions, setAnalystsOptions] = useState<CustoDropDownItemProps[]>([]);
  const [cepLoading, setCepLoading] = useState<boolean>(false);
  const [additionalEmailList, setAdditionalEmailList] = useState<string[]>(['']);
  const [isCpf, setIsCpf] = useState(false);
  const [columnSize, setColumnSize] = useState<SemanticWIDTHS | undefined>(5);
  const [classification, setClassification] = useState<string | undefined>(undefined);
  const [averageProperties, setAverageProperties] = useState<number>(0);
  const [estatePartners, setEstatePartners] = useState<EstatePartner[]>([]);

  const franchises = availableBrokersAndEstates.franchises;

  const onChangeCep = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const maskedValue = maskZipCode(e.target.value);

    setFieldValue(`estate_zipcode`, maskedValue);
    if (maskedValue.length === 9) {
      setCepLoading(true);

      AddressService.getAddressByZipCode(e.target.value)
        .then(address => {
          setFieldValue('estate_city', address.city);
          setFieldValue('estate_district', address.district);
          setFieldValue('estate_state', address.state);
          setFieldValue('estate_street', address.street);

          setCepLoading(false);
        })
        .catch(e => {
          console.log('address error:', e);
        });
    }
  };

  useEffect(() => {
    setFieldValue('name', initialValues.name);
    setFieldValue('document', initialValues.cnpj);
    setFieldValue('email', initialValues.email);
    setFieldValue('estate_zipcode', initialValues.estate_zipcode);
    setFieldValue('estate_city', initialValues.estate_city);
    setFieldValue('estate_state', initialValues.estate_state);
    setFieldValue('estate_number', initialValues.estate_number);
    setFieldValue('estate_street', initialValues.estate_street);
    setFieldValue('estate_district', initialValues.estate_district);
    setFieldValue('estate_complement', initialValues.estate_complement);
    setFieldValue('responsible_name', initialValues.responsible_name);
    setFieldValue('responsible_email', initialValues.responsible_email);
    setFieldValue('responsible_phone', initialValues.responsible_phone);
    setFieldValue('preRegisterId', initialValues.preRegisterId);
    setFieldValue('franchiseId', initialValues.franchiseId);
    setFieldValue('observations', initialValues.observations);
  }, [initialValues.preRegisterId]);

  useEffect(() => {
    const arrayJoined = additionalEmailList.join(';');
    if (arrayJoined.length > 0) {
      setFieldValue('additional_emails', arrayJoined);
    }
  }, [additionalEmailList]);

  useEffect(() => {
    if (averageProperties < 100) {
      setClassification(classifications[0].value);
    } else if (averageProperties < 200) {
      setClassification(classifications[1].value);
    } else if (averageProperties < 300) {
      setClassification(classifications[2].value);
    } else {
      setClassification(classifications[3].value);
    }
  }, [averageProperties]);

  useEffect(() => {
    setFieldValue('classification', classification);
  }, [classification]);

  const verifyCnpj = () => {
    const cnpj = unmaskCpfOrCnpj(values.cnpj);
    if (cnpj.length === 11) {
      setIsCpf(true);
      setColumnSize(3);
    } else {
      setIsCpf(false);
      setColumnSize(5);
    }
  };

  useEffect(() => {
    if (values.broker?.id) {
      setIsAnalystsLoading(true);
      getUsers(values.broker.id)
        .then(result => {
          const convertedValues: UserForEstateForm[] = result.map((user: any) => {
            const userForForm: UserForEstateForm = {
              id: user.id,
              name: user.name
            };

            return userForForm;
          });

          const analystsOptions = UsersToCustomDropDownOptions(true, convertedValues);
          setAnalystsOptions(analystsOptions);
        })
        .catch(error => {
          dangerNotification(
            'Oops...',
            'Não foi possível encontrar os analistas disponíveis'
          );
        })
        .finally(() => {
          setIsAnalystsLoading(false);
        });
    }
  }, [values.broker]);

  useEffect(() => {
    if (values?.partners?.length > 0) {
      setEstatePartners(
        values?.partners?.map((partner: EstatePartner) => ({
          ...partner,
          document: maskCpfOrCnpj(partner.document)
        })) || []
      );
    }
  }, [values.partners]);

  const handleChangePartner = (
    e: React.ChangeEvent<HTMLInputElement>,
    data: any,
    estatePartner: EstatePartner
  ) => {
    const partnerIndex = estatePartners.findIndex(
      item => item?.document === estatePartner?.document
    );
    if (partnerIndex >= 0) {
      const newEstatePartners =
        estatePartners?.map(partner => {
          if (
            partner.name === estatePartner.name &&
            partner.document === estatePartner.document
          ) {
            return {
              ...partner,
              [e.target.name]: data.toString()
            };
          }
          return partner;
        }) || [];
      setEstatePartners(newEstatePartners);
    }
  };

  const handleRemovePartner = async (estatePartner: EstatePartner) => {
    // TODO: Add a warning toast before delete the item
    const newPartners = estatePartners.filter(
      item =>
        estatePartner.document !== item?.document || estatePartner.name !== item?.name
    );
    setEstatePartners(newPartners);
    setFieldValue('partners', newPartners);
  };

  return (
    <>
      <SectionLabel text="DADOS DA IMOBILIÁRIA" />
      <Grid.Row>
        <Grid.Column width={6}>
          <FieldLabel>Corretora*</FieldLabel>
          <Field
            name="broker"
            completeValue={true}
            className={
              errors && errors.broker && touched && touched.broker
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={BrokersDropdown}
            fluid
          />
          <FormikErrorMessage component="div" name="brokerId" />
        </Grid.Column>

        <Grid.Column width={5}>
          <FieldLabel>Email Principal*</FieldLabel>
          <Field
            name="email"
            component={Input}
            className={
              errors && errors.email && touched && touched.email
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            fluid
            value={values.email}
          />

          <FormikErrorMessage component="div" name="email" />
        </Grid.Column>

        <Grid.Column width={5} style={{ display: 'flex', flexDirection: 'column' }}>
          <FieldLabel>Origem do contato*</FieldLabel>
          <DropdownNativo
            value={values.contact_source}
            name="contact_source"
            clearable
            search
            options={optionsSource}
            selection
            onChange={(e, { value }) => setFieldValue(`contact_source`, value)}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row stretched>
        <Grid.Column width={6}>
          <FieldLabel>Nome da Imobiliária*</FieldLabel>
          <Field
            name="name"
            component={Input}
            className={
              errors && errors.name && touched && touched.name
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            fluid
            value={values.name}
          />
          <FormikErrorMessage component="div" name="name" />
        </Grid.Column>
        <Grid.Column width={5}>
          <FieldLabel>Razão Social</FieldLabel>
          <Field
            name="company_name"
            component={Input}
            className={
              errors && errors.company_name && touched && touched.company_name
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            fluid
            value={values.company_name}
          />
          <FormikErrorMessage component="div" name="company_name" />
        </Grid.Column>
        <Grid.Column width={columnSize}>
          <FieldLabel>CNPJ*</FieldLabel>
          <Field
            name="cnpj"
            className={
              (errors && errors.cnpj && touched && touched.cnpj) || !isValidCnpj
                ? 'text-input error'
                : 'text-input'
            }
            component={Input}
            onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
              data.value = maskCnpj(data.value);
              handleInputChange(data, props);
            }}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
              const document = unmaskCpfOrCnpj(e.target.value);
              setValidCnpj(docValidator(document));
              verifyCnpj();
            }}
            fluid
            value={values.cnpj}
          />
          {isValidCnpj ? null : <DivErrorMessage>CNPJ inválido</DivErrorMessage>}
          <FormikErrorMessage component="div" name="cnpj" />
        </Grid.Column>
        {isCpf && (
          <Grid.Column width={2}>
            <FieldLabel>CRECI</FieldLabel>
            <Field
              name="creci"
              component={Input}
              className={
                errors && errors.creci && touched && touched.creci
                  ? 'text-input error'
                  : 'text-input'
              }
              onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
              fluid
              value={values.creci}
            />
            <FormikErrorMessage component="div" name="creci" />
          </Grid.Column>
        )}
      </Grid.Row>

      <Grid.Row stretched>
        <Grid.Column width={6}>
          <FieldLabel>Nome do Responsável*</FieldLabel>
          <Field
            name="responsible_name"
            component={Input}
            className={
              errors && errors.responsible_name && touched && touched.responsible_name
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            fluid
          />
          <FormikErrorMessage component="div" name="responsible_name" />
        </Grid.Column>
        <Grid.Column width={5}>
          <FieldLabel>Email do Responsável*</FieldLabel>
          <Field
            name="responsible_email"
            className={
              errors && errors.responsible_email && touched && touched.responsible_email
                ? 'text-input error'
                : 'text-input'
            }
            component={Input}
            onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
              handleInputChange(data, props);
            }}
            fluid
          />
          <FormikErrorMessage component="div" name="responsible_email" />
        </Grid.Column>
        <Grid.Column width={5}>
          <FieldLabel>Telefone do Responsável*</FieldLabel>
          <Field
            name="responsible_phone"
            component={Input}
            className={
              errors && errors.responsible_phone && touched && touched.responsible_phone
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => {
              data.value = maskPhoneNumber(data.value);
              handleInputChange(data, props);
            }}
            fluid
          />
          <FormikErrorMessage component="div" name="responsible_phone" />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row stretched>
        <Grid.Column width={6}>
          <FieldLabel>Responsável Financeiro*</FieldLabel>
          <Field
            name="financial_responsible"
            component={Input}
            className={
              errors &&
              errors.financial_responsible &&
              touched &&
              touched.financial_responsible
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            fluid
          />
          <FormikErrorMessage component="div" name="financial_responsible" />
        </Grid.Column>
        <Grid.Column width={5}>
          <FieldLabel>Email do Financeiro*</FieldLabel>
          <Field
            name="financial_email"
            className={
              errors && errors.financial_email && touched && touched.financial_email
                ? 'text-input error'
                : 'text-input'
            }
            component={Input}
            onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
              handleInputChange(data, props);
            }}
            fluid
          />
          <FormikErrorMessage component="div" name="financial_email" />
        </Grid.Column>
        <Grid.Column width={5}>
          <FieldLabel>Status*</FieldLabel>
          <Dropdown
            defaultValue="active"
            value={values.status}
            name="status"
            clearable
            search
            options={options}
            selection
            onChange={(e, { value }) => setFieldValue(`status`, value)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row stretched>
        <Grid.Column width={6}>
          <FieldLabel>Gestor de Contas*</FieldLabel>
          <Field
            name="estateAnalystUser"
            search
            loading={isAnalystsLoading}
            options={analystsOptions}
            className={
              errors && errors.estateAnalystUser && touched && touched.estateAnalystUser
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Dropdown}
            selection
            clearable
            compact
            basic
            fluid
            selected={values.estateAnalystUser.id}
          />
          <FormikErrorMessage component="div" name="estateAnalystUser" />
        </Grid.Column>

        <Grid.Column width={5}>
          <FieldLabel>Gestor de Contas (Adicional)</FieldLabel>
          <Field
            name="additionalAccountManager"
            loading={isAnalystsLoading}
            options={analystsOptions}
            search
            clearable
            className={
              errors &&
              errors.additionalAccountManager &&
              touched &&
              touched.additionalAccountManager
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Dropdown}
            selection
            compact
            basic
            fluid
            selected={values.additionalAccountManager?.id}
          />
          <FormikErrorMessage component="div" name="additionalAccountManager" />
        </Grid.Column>

        <Grid.Column width={5}>
          <Grid columns={'equal'}>
            <Grid.Column>
              <FieldLabel>Média de Imóveis na Carteira*</FieldLabel>
              <Field
                name="averagePropertiesWallet"
                component={Input}
                onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
                  console.log(data);
                  setAverageProperties(Number(data.value));
                  handleInputChange(data, props);
                }}
                fluid
                className={
                  values.averagePropertiesWallet === 0 ? 'text-input error' : 'text-input'
                }
              />
              {values.averagePropertiesWallet !== 0 ? null : (
                <DivErrorMessage>O valor tem que ser maior que 0</DivErrorMessage>
              )}
            </Grid.Column>

            <Grid.Column verticalAlign="bottom">
              <FieldLabel>Classificação</FieldLabel>
              <DropdownNativo
                value={classification}
                name="classification"
                clearable
                options={classifications}
                selection
                onChange={(e, { value }) => setClassification(String(value))}
                fluid
              />
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={6}>
          <FieldLabel>Franquia</FieldLabel>
          <Field
            name="franchiseId"
            options={franchises}
            value={values.franchiseId}
            search
            clearable
            className={
              errors && errors.franchiseId && touched && touched.franchiseId
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Dropdown}
            selection
            compact
            basic
            fluid
          />
        </Grid.Column>

        <Grid.Column width={5}>
          <FieldLabel>Responsável por Sinistros</FieldLabel>
          <Field
            name="claimResponsible"
            loading={isAnalystsLoading}
            options={analystsOptions}
            search
            clearable
            className={
              errors && errors.claimResponsible && touched && touched.claimResponsible
                ? 'text-input error'
                : 'text-input'
            }
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Dropdown}
            selection
            compact
            basic
            fluid
            selected={values.claimResponsible?.id}
          />
          <FormikErrorMessage component="div" name="claimResponsible" />
        </Grid.Column>

        <Grid.Column width={5} style={{ display: 'flex', flexDirection: 'column' }}>
          <FieldLabel>Empresa Responsável*</FieldLabel>
          <DropdownNativo
            value={values.responsible_company}
            name="responsible_company"
            clearable
            search
            options={companyOptions}
            selection
            onChange={(e, { value }) => setFieldValue(`responsible_company`, value)}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column widths={'equal'}>
          <TextAreaWrapper>
            <FieldLabel>Observações da Parceria</FieldLabel>
            <TextArea
              value={values.observations}
              onChange={(e, data) => setFieldValue('observations', data.value)}
            />
          </TextAreaWrapper>
        </Grid.Column>
      </Grid.Row>

      <SectionLabel text="QUADRO DE SÓCIOS" />
      {estatePartners.map((estatePartner, index) => {
        return (
          <Grid.Row key={estatePartner?.id} streched>
            {index >= 0 && (
              <Grid.Column width={16}>
                <PartnerHeader>
                  <PartnerLabel>{index + 1}. SÓCIO ADICIONAL</PartnerLabel>
                  <Button
                    color="red"
                    basic
                    type="button"
                    size="mini"
                    onClick={() => handleRemovePartner(estatePartner)}
                  >
                    <Icon name="cancel" /> Remover
                  </Button>
                </PartnerHeader>
              </Grid.Column>
            )}
            <Grid.Column width={8}>
              <FieldLabel>Nome</FieldLabel>
              <Field
                name="name"
                component={Input}
                onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
                  handleChangePartner(e, data.value, estatePartner);
                }}
                fluid
                onBlur={() => setFieldValue('partners', estatePartners)}
                value={estatePartner.name}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <FieldLabel>CPF</FieldLabel>
              <Field
                name="document"
                component={Input}
                onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
                  handleChangePartner(e, maskCpfOrCnpj(data.value), estatePartner);
                }}
                fluid
                onBlur={() => setFieldValue('partners', estatePartners)}
                value={estatePartner.document}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <FieldLabel>E-MAIL</FieldLabel>
              <Field
                name="email"
                component={Input}
                onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
                  handleChangePartner(e, data.value, estatePartner);
                }}
                fluid
                onBlur={() => setFieldValue('partners', estatePartners)}
                value={estatePartner.email}
              />
            </Grid.Column>
          </Grid.Row>
        );
      })}
      <Grid.Row>
        <Button
          style={{ marginTop: 10 }}
          primary
          onClick={() => {
            if (estatePartners.length > 0) {
              const lastPartner = estatePartners[estatePartners.length - 1];
              if (lastPartner?.name?.length > 0 && lastPartner?.document?.length > 0)
                setEstatePartners(estatePartner => [
                  ...estatePartner,
                  { name: '', document: '', email: '' }
                ]);
            } else {
              setEstatePartners([{ name: '', document: '', email: '' }]);
            }
          }}
        >
          <Icon name={'plus circle'} />
          Adicionar Sócio
        </Button>
      </Grid.Row>

      <SectionLabel text="E-MAILS ADICIONAIS" />

      <Grid.Row streched>
        <Grid.Column width={16}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <div>
              <Icon name="info circle" color="blue" /> Preencha os campos abaixo caso
              deseje receber nossas notificações em outros e-mails.{' '}
            </div>
            {additionalEmailList.map((additionalEmail, index) => {
              return (
                <AdditionalEmailWrapper key={index}>
                  <div>
                    <FieldLabel>Email</FieldLabel>
                    <Field
                      name={`additional_email[${index}].email`}
                      placeholder={'E-mail adicional'}
                      component={Input}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
                        setAdditionalEmailList(additionalEmailList => [
                          ...additionalEmailList.slice(0, index),
                          data.value,
                          ...additionalEmailList.slice(index + 1)
                        ]);
                        setFieldValue(`emails[${index}].email`, data.value);
                      }}
                      fluid
                      value={additionalEmail}
                      onBlur={(e: { target: { value: string } }) => {
                        if (
                          e.target.value &&
                          e.target.value.length > 0 &&
                          !isEmail(e.target.value)
                        ) {
                          dangerNotification(
                            'Ops.. ',
                            'Você está tentando adicionar um e-mail inválido.'
                          );
                          return;
                        }
                      }}
                    />
                  </div>
                  <div>
                    <FieldLabel>Categoria</FieldLabel>
                    <DropdownNativo
                      clearable
                      multiple
                      selection
                      fluid
                      name={`category_${index}`}
                      options={categories}
                      onChange={(e, { value }) => {
                        setFieldValue(`emails[${index}].categoryArray`, value);
                      }}
                    />
                  </div>
                  <div>
                    <Icon
                      name={'remove circle'}
                      color={'grey'}
                      style={{ cursor: 'pointer' }}
                      size={'large'}
                      onClick={() => {
                        const arr1 = additionalEmailList.slice(0, index);
                        const arr2 = additionalEmailList.slice(index + 1);
                        const newArr = [...arr1, ...arr2];

                        setAdditionalEmailList(additionalEmailList => newArr);
                      }}
                    />
                  </div>
                </AdditionalEmailWrapper>
              );
            })}
          </div>
          <Button
            style={{ marginTop: 10 }}
            primary
            onClick={() => {
              if (additionalEmailList.length) {
                const lastEmail = additionalEmailList[additionalEmailList.length - 1];
                if (lastEmail.length > 0)
                  if (!isEmail(lastEmail)) {
                    dangerNotification(
                      'Ops.. ',
                      'Você está tentando adicionar um e-mail inválido.'
                    );
                    return;
                  }
                setAdditionalEmailList(additionalEmailList => [
                  ...additionalEmailList,
                  ''
                ]);
              } else {
                setAdditionalEmailList(additionalEmailList => ['']);
              }
            }}
          >
            <Icon name={'plus circle'} />
            Adicionar E-mail
          </Button>
        </Grid.Column>
      </Grid.Row>

      <SectionLabel text="ENDEREÇO DA IMOBILIARIA" />
      <Grid.Row>
        <Grid.Column width={4}>
          <FieldLabel>CEP</FieldLabel>
          <Field
            name="estate_zipcode"
            value={values.estate_zipcode}
            component={Input}
            loading={cepLoading}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
              data: InputOnChangeData
            ): void => {
              onChangeCep(e);
              data.value = maskZipCode(e.target.value);
            }}
            maxLength={9}
            fluid
          />
          <FormikErrorMessage component="div" name="estate_zipcode" />
        </Grid.Column>

        <Grid.Column width={6}>
          <FieldLabel>LOGRADOURO*</FieldLabel>
          <Field
            name={`estate_street`}
            value={values.estate_street}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`estate_street`} />
        </Grid.Column>

        <Grid.Column width={5}>
          <FieldLabel>BAIRRO*</FieldLabel>
          <Field
            name={`estate_district`}
            value={values.estate_district}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`estate_district`} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={4}>
          <FieldLabel>CIDADE*</FieldLabel>
          <Field
            name={`estate_city`}
            value={values.estate_city}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`estate_city`} />
        </Grid.Column>

        <Grid.Column width={5}>
          <FieldLabel>UF*</FieldLabel>
          <Field
            name={`estate_state`}
            value={values.estate_state}
            component={Input}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            fluid
          />
          <FormikErrorMessage component="div" name={`estate_state`} />
        </Grid.Column>
        <Grid.Column width={4}>
          <FieldLabel>NÚMERO*</FieldLabel>
          <Field
            name={`estate_number`}
            value={values.estate_number}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`estate_number`} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={6}>
          <FieldLabel>COMPLEMENTO</FieldLabel>
          <Field
            name={`estate_complement`}
            value={values.estate_complement}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Input}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
    </>
  );
};
