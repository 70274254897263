import { TextArea } from 'semantic-ui-react';
import styled from 'styled-components';

export const ObservationsText = styled(TextArea)`
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #fff;
  resize: none;
  opacity: .7;
  &:focus {
    outline: none ! important;
    border-color: #719ECE;
  }
`;
