import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { styles } from '../styles';
import TableHeader from './TableHeader';

const BudgetDataTable = ({ data }: any) => {
  return (
    <View style={styles.tableContainer}>
      <TableHeader title={'DADOS DO ORÇAMENTO'} />

      <View style={styles.row}>
        <View style={styles.column}>
          <Text style={styles.label}>{'FINALIDADE DO ORÇAMENTO'}</Text>
          <Text style={styles.value}>{data.locationType}</Text>
        </View>

        <View style={styles.column}>
          <Text style={styles.label}>{'TIPO DO IMÓVEL'}</Text>
          <Text style={styles.value}>{data.propertyType}</Text>
        </View>

        <View style={styles.column}>
          <Text style={styles.label}>{'OCUPAÇÃO'}</Text>
          <Text style={styles.value}>{data.propertyUseType}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.column}>
          <Text style={styles.label}>{'INÍCIO DA VIGÊNCIA'}</Text>
          <Text style={styles.value}>{data.policyPeriodStart}</Text>
        </View>

        <View style={styles.column}>
          <Text style={styles.label}>{'FINAL DA VIGÊNCIA'}</Text>
          <Text style={styles.value}>{data.policyPeriodEnd}</Text>
        </View>

        <View style={styles.column}>
          <Text style={styles.label}>{'ASSISTÊNCIA'}</Text>
          <Text style={styles.value}>{'---'}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.column}>
          <Text style={styles.label}>{'FORMA DE PAGAMENTO'}</Text>
          <Text style={styles.value}>{'---'}</Text>
        </View>
      </View>
    </View>
  );
};

export default BudgetDataTable;
