import { ListRecordsType } from '../pages/find-record/types/options-find.type';
import { get } from './request';

interface listTypeResponse {
  data: ListRecordsType[];
  count: number;
  current: number;
  page: number;
}

export const getRecords = async (filters: any): Promise<listTypeResponse> => {
  let url = `/register-search`;
  let query = '';

  if (filters) {
    Object.entries(filters).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`
      }
    })

    url = `${url}?${query}`;
  }
  return await get(url);
}

export const getRecord = async (recordId: string, origin: string): Promise<any> => {
  const url = `/register-search/${recordId}?origin=${origin}`;

  return await get(url);
}
