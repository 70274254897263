import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';

import { styles } from '../styles';
import logo4seg from '../../../../resources/imgs/logo.png';

const BudgetFooter = () => (
  <View style={styles.footerContainer} wrap={false}>
    <View style={styles.footerMessage}>
      <Text>Atenção: (a) este orçamento não garante cobertura, sendo meramente para exibir e detalhar as informações de segurado, beneficiário, coberturas e valores orçados. Somente a contratação/transmissão deste orçamento vai gerar a apólice e garantir a cobertura. (b) o preenchimento correto das informações é obrigatório; a veracidade das informações é de inteira responsabilidasde do usuário operador; informações inverídicas ou desatualizadas poderão acarretar a perda de direito do Segurado/Proponente ou cancelamento do seguro sem prévia comunicação e imputação de responsabilidade civil ao responsável pelo preenchimento inválido.</Text>
    </View>

    <View style={styles.footerCopyrigth}>
      <Image style={styles.logo4seg} src={logo4seg}/>
      <Text style={styles.footerCopyrigthText}>Powered By 4Seg - Rede Confiax - 2024</Text>
    </View>
  </View>
);

export default BudgetFooter;
