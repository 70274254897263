import {DefaultCoverageQuotationValueStrategyEnum} from "../pages/fire-insurance-budget-form/enums/default-coverage-quotation-value-strategy.enum";

export type ConnectorCoverageStrategyOptions = {
  rentValue: number;
  estateMultiplier: number;
}

export const getDefaultInsuredAmountByStrategy = (strategy: string | undefined, options: ConnectorCoverageStrategyOptions) => {
  let value = 0;

  switch(strategy) {
      case DefaultCoverageQuotationValueStrategyEnum.ESTATE_MULTIPLIER_TIMES_RENT_VALUE:
        value = options.estateMultiplier * options.rentValue;

        break;
      case DefaultCoverageQuotationValueStrategyEnum.ONE_TENTH_ESTATE_MULTIPLIER_TIMES_RENT_VALUE:
        value = options.estateMultiplier / 10 * options.rentValue;

        break;
  }

  return value;
}
