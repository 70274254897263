export enum BondInsuranceHiredDataStatusEnum {
  waiting = "WAITING",
  insurerHireRelatedInformationPending = "INSURER_HIRE_RELATED_INFORMATION_PENDING",
  hireRelatedInformationError = "HIRE_RELATED_INFORMATION_ERROR",
  insurerReadyToHire = "INSURER_READY_TO_HIRE",
  insurerHirePending = "INSURER_HIRE_PENDING",
  insurerHirePendingIntegred = "INSURER_HIRE_PENDING_INTEGRED",
  hireError = "HIRE_ERROR",
  insurerPolicyPending = "INSURER_POLICY_PENDING",
  policyError = "POLICY_ERROR",
  insurerPolicyCreated = "INSURER_POLICY_CREATED",
  cancelled = "CANCELLED",
  cancelledInProgress = "CANCELLED_IN_PROGRESS",
  originalRenewal = 'ORIGINAL_UNDER_RENEWAL',
  renewed = 'POLICY_RENEWED',
  underRenewal = 'POLICY_UNDER_RENEWAL',
  renewedCreated = 'RENEWED_POLICY_CREATED',
  notRenewed = 'POLICY_NOT_RENEWED',
  overdue = 'OVERDUE',
  overdueIssued = 'OVERDUE_ISSUED',
  expired = 'EXPIRED',
  renewCanceled = 'RENEW_CANCELED'
}
