import { post, get, put } from './request';
import { CreateCanceledBondInsuranceRequest } from '../interfaces/create-canceled-bond-insurance.request';
import { BondInsurance } from '../types/BondInsurance';
import axiosRequest from "./axios-instance";
import moment from 'moment-timezone';
import {
  GetBondInsurancesResponseDTO
} from "../dtos/bond-insurance-list/get-bond-insurances-response.dto";

import { CanceledBondInsurance } from '../types/CanceledBondInsurance'
import { AxiosError } from 'axios';

export interface BondInsuranceWithHiredData extends BondInsurance {
  bondInsuranceHiredData?: any;
}

export interface CanceledBondInsuranceFull extends CanceledBondInsurance {
  bondInsurance: BondInsuranceWithHiredData;
}

export const createCancelation = async (cancelation: any): Promise<any> => {
  const route = `/canceled-bond-insurances/external`;
  return await post(route, cancelation);
}

export const updateCancelation = async (payload: any): Promise<any> => {
  return await put(
    `/canceled-bond-insurances/update-status/${payload.canceledId}`,
    payload
  );
}

export const getExternalCancelation = async (id: string): Promise<any> => {
  const response = getCanceledBondInsuranceByID(id)
  return response;
}

export const updateNotificationEmails = async (id: string, emails: string, userId: string): Promise<any> => {
  const payload = {
    emails: emails,
    userId: userId
  }

  return await put(`/canceled-bond-insurances/emails/${id}`, payload);
}

export const updateViewedByIds = async (messageId: string, viewedBy: string): Promise<any> => {
  return await put(`/canceled-bond-insurances/viewed-by/${messageId}`, { viewedBy });
}

export const save = async (data: CreateCanceledBondInsuranceRequest, user_id: string, bond_insurance_id: string): Promise<any> => {
  const route = `/canceled-bond-insurances`;
  const { cancellation_reason, observations } = data;
  const payload = {
    bond_insurance_id,
    user_id,
    cancellation_reason,
    observations
  } as CreateCanceledBondInsuranceRequest;

  return await post(route, payload);
};

export const getCanceledBondInsurances = async (page: number, filter?: any): Promise<any> => {
  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    })
  }

  return await get(`/canceled-bond-insurances?${query}&page=${page}`);
}

export const getCanceledBondInsurancesReport = async (filter?: any): Promise<number | undefined> => {
  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    })
  }

  try {
    const response = await axiosRequest.get(`/canceled-bond-insurances/report-excel?${query}`);
    return response.status;
  } catch (error) {
    const { response } = error as AxiosError;
    return response?.status;
  }
}

export const getCanceledBondInsuranceByID = async (ID: string): Promise<any> => {
  return await get(`/canceled-bond-insurances/id/${ID}`);
}

export const getBondInsurances = async (page: number, filter?: any): Promise<GetBondInsurancesResponseDTO> => {

  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`
      }
    })
  }

  let response;
  try {
    response = await get(`/bond-insurances?${query}&page=${page}`);
  } catch (e) {
    console.error(e);
    response = [];
  }
  return response;
}

export function sortBondInsurances<T>(data: Array<MatchBondInsurance<T>>, ascending: boolean): Array<MatchBondInsurance<T>> {
  return data.sort((p1: MatchBondInsurance<T>, p2: MatchBondInsurance<T>): number => {
    const p1CreatedAt = moment(p1.created_at || new Date(0, 0, 0));
    const p2CreatedAt = moment(p2.created_at || new Date(0, 0, 0));
    if (p1CreatedAt.isSame(p2CreatedAt)) {
      return 0
    } else {
      return (ascending === p1CreatedAt.isAfter(p2CreatedAt)) ? -1 : 1;
    }
  });
};

export async function uncancel(canceledBondInsuranceId: string, userId: string): Promise<boolean> {
  let wasUncanceled = false;

  try {
    const response = await post(`/canceled-bond-insurances/uncancel/${canceledBondInsuranceId}`, { userId });
    wasUncanceled = response;
  } catch (e) {
    console.error(e);
    wasUncanceled = false;
  }

  return wasUncanceled;
}

type MatchBondInsurance<T> = T & { created_at: Date };
