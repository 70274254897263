import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button,
  Dimmer,
  Grid,
  GridColumn,
  Header,
  Icon,
  Loader,
  Modal,
  ModalContent,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'semantic-ui-react';

import { numberToMoneyString } from '../../../../services/masks';
import { getProLaboreDetails } from '../../../../services/fire-insurance-invoices';
import { Info } from '../../../../components/Info';

interface ProLaboreDetailProps {
  open: boolean;
  onClose: () => void;
  contributionNumber: string;
};

const ProLaboreDetailModal = (props: ProLaboreDetailProps) => {
  const { open, onClose, contributionNumber } = props;

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [proLaboreDetailsData, setProLaboreDetailsData] = useState<any>([]);

  const getProLaboreDetailsData = async (contributionNumber: string) => {
    setIsLoadingData(true);

    try {
      const proLaboreDetails = await getProLaboreDetails(contributionNumber);
      setProLaboreDetailsData(proLaboreDetails?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    contributionNumber && getProLaboreDetailsData(contributionNumber);
  }, [contributionNumber]);

  return (
    <Modal
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={open}
      onClose={onClose}
      size='large'
      centered
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '95%'
      }}
    >
      <Header icon='shield alternate' content='Detalhes do Pro-Labore' />
      <ModalContent scrolling>
        <Grid>
          <Grid.Row>
            <Info name={'Nome'} value={proLaboreDetailsData[0]?.estateName || ''} width={4} />
            <Info name={'Data de Emissão'} value={moment(proLaboreDetailsData[0]?.emissionDate).format('DD/MM/YYYY') || ''} width={4} />
            <Info name={'Número do Recibo'} value={proLaboreDetailsData[0]?.receiptNumber} width={4} />
          </Grid.Row>

          <Grid.Row>
            <Info name={'Sucursal'} value={proLaboreDetailsData[0]?.estateLocation} width={4} />
            <Info name={'Colaborador'} value={proLaboreDetailsData[0]?.collaborator} width={4} />
            <Info name={'CNPJ/CPF Corretor'} value={proLaboreDetailsData[0]?.estateDocument} width={4} />
          </Grid.Row>
        </Grid>

        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell textAlign={'center'}>Nome do Segurado</TableHeaderCell>
              <TableHeaderCell textAlign={'center'}>Documento</TableHeaderCell>
              <TableHeaderCell textAlign={'center'}>Vencimento</TableHeaderCell>
              <TableHeaderCell textAlign={'center'}>Parcela</TableHeaderCell>
              <TableHeaderCell textAlign={'center'}>Cobertura</TableHeaderCell>
              <TableHeaderCell textAlign={'center'}>Pro-Labore (%)</TableHeaderCell>
              <TableHeaderCell textAlign={'center'}>Prêm. Liquido</TableHeaderCell>
              <TableHeaderCell textAlign={'center'}>Pro-Labore</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <Dimmer.Dimmable as={TableBody} dimmed={isLoadingData}>
            {isLoadingData && (
              <Dimmer active inverted>
                <Loader />
              </Dimmer>
            )}

            {proLaboreDetailsData && proLaboreDetailsData.length ? (
              proLaboreDetailsData.map((prolabore: any) => {
                return (
                  <TableRow key={prolabore.id}>
                    <TableCell textAlign={'center'}>
                      {prolabore.insurerName}
                    </TableCell>

                    <TableCell textAlign={'center'}>
                      {prolabore.insurerDocument}
                    </TableCell>

                    <TableCell textAlign={'center'}>
                      {moment(prolabore.dueDate).format('DD/MM/YYYY')}
                    </TableCell>

                    <TableCell textAlign={'center'}>
                      {prolabore.installment}
                    </TableCell>

                    <TableCell textAlign={'center'}>
                      {prolabore.coverageDate}
                    </TableCell>

                    <TableCell textAlign={'center'}>
                      {prolabore.prolaborePercentage}
                    </TableCell>

                    <TableCell textAlign={'center'}>
                      {numberToMoneyString(Number(prolabore.proLaboreNetValue))}
                    </TableCell>

                    <TableCell textAlign={'center'}>
                      {numberToMoneyString(Number(prolabore.proLaboreValue))}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={9} textAlign={'center'}>
                  Nenhum registro encontrado
                </TableCell>
              </TableRow>
            )}
          </Dimmer.Dimmable>
        </Table>
      </ModalContent>
      <Modal.Actions>
        <Grid>
          <GridColumn floated='right' width={2}>
            <Button icon labelPosition={'left'} onClick={onClose}>
              <Icon name={'arrow left'} />
              Voltar
            </Button>
          </GridColumn>
        </Grid>
      </Modal.Actions>
    </Modal>
  );
};

export default ProLaboreDetailModal;
