import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Radio,
  Grid,
  Input,
  Button,
  Icon,
  Dropdown as DropdownNativo,
  Popup
} from 'semantic-ui-react';
import { Field, FormikProps } from 'formik';
import SectionLabel from '../../../../../../components/SectionLabel';
import { FieldLabel } from '../../../../../../styles';
import { EditClaimEstateInitialInitialValuesType } from '../../Formik/types/CreateClaimEstateInitialValuesTypes';
import { InsurerState } from '../../../../../../types/InsurerState';
import { CustoDropDownItemProps } from '../../../../../../components/types';
import CustomDatePicker from '../../../../../../components/CustomDatePicker';
import {
  DATE_INPUT_MASK,
  maskCpfOrCnpj,
  maskPhoneNumber,
  maskZipCode
} from '../../../../../../services/masks';
import { AddressService } from '../../../../../../services/address';
import FormatedTextArea from '../../../../../../components/FormatedTextArea';
import { ClaimPropertyStatusEnum } from '../../../../../../enums/claim-property-status.enum';
import { getUsers } from '../../../../../../services/broker';
import { UserForUpdateEstateForm } from '../../../../../estates-list/types';
import { UsersToCustomDropDownOptions } from '../../../../../../util';
import { dangerNotification } from '../../../../../../services/notification';
import { UserState } from '../../../../../../types/UserState';
import { FormatDateYMD_UTC_IGNORE } from '../../../../../../services/date';
import { getDataByDocument } from '../../../../../../services/documents';
import AppMaskedInput from '../../../../../../components/MaskedInput';

type ClaimEstatesProps = {
  estates: CustoDropDownItemProps[] | null;
  insurers: InsurerState[] | null;
  users: UserState[];
};

interface DropdownOptions {
  index: number;
  value: number | string;
  text: string;
}

const ClaimEstateInfoStep = ({
  values,
  insurers,
  estates,
  users,
  setFieldValue
}: FormikProps<EditClaimEstateInitialInitialValuesType> & ClaimEstatesProps) => {
  const [isAnalystsLoading, setIsAnalystsLoading] = useState(false);
  const [additionalAccountManagerOtions, setAdditionalAccountManagerOptions] = useState<
    CustoDropDownItemProps[]
  >([]);
  const [cepLoading, setCepLoading] = useState(false);
  const [selectableInsurers, setSelectableInsurers] = useState<DropdownOptions[] | any[]>(
    []
  );
  const [selectableEstates, setSelectableEstates] = useState<DropdownOptions[] | any[]>(
    []
  );
  const [disableNameField, setDisabledNameField] = useState<boolean>(true);
  const [loadDocument, setLoadDocument] = useState(false);

  const claimEstatesStatusEnum = [
    {
      index: 10,
      value: 'RETURN_BILL',
      text: 'Boleto Devolutivo'
    },
    {
      index: 5,
      value: 'DEBT_CONFESSION',
      text: 'Confissão de Dívida'
    },
    {
      index: 0,
      value: 'CREATED',
      text: 'Em aberto'
    },
    {
      index: 6,
      value: 'IN_ACCORD',
      text: 'Em acordo'
    },
    {
      index: 1,
      value: 'IN_ANALYSIS',
      text: 'Em análise'
    },
    {
      index: 2,
      value: 'IN_PROGRESS',
      text: 'Em andamento'
    },
    {
      index: 4,
      value: 'FINISHED',
      text: 'Finalizado'
    },
    {
      index: 7,
      value: 'MONTHLY_INDEMNITY',
      text: 'Indenizado mensalmente'
    },
    {
      index: 8,
      value: 'LMI_LMG_REACHED',
      text: 'LMI/LMG atingido'
    },
    {
      index: 3,
      value: 'PENDING',
      text: 'Pendenciado'
    },
    {
      index: 9,
      value: 'PRE_ACCIDENT',
      text: 'Pré-sinistro'
    },
  ];

  useEffect(() => {
    if (insurers && insurers.length) {
      setSelectableInsurers(
        insurers.map(insurer => ({
          index: insurer.id,
          value: insurer.id,
          text: insurer.name
        }))
      );
    }
  }, [insurers]);

  useEffect(() => {
    if (estates && estates.length) {
      setSelectableEstates(
        estates.map(estate => ({
          index: estate.value.id,
          value: estate.value.id,
          text: estate.value.name
        }))
      );

      if (estates.length === 1) {
        setFieldValue('claimEstate.estateId', estates[0].value.id);
      }
    }
  }, [estates]);

  const onChangeCep = (zipCode: string): void => {
    const maskedValue = maskZipCode(zipCode);

    setFieldValue('claimProperty.zipCode', maskedValue);
    if (maskedValue.length >= 9) {
      setCepLoading(true);

      AddressService.getAddressByZipCode(zipCode)
        .then(address => {
          setFieldValue('claimProperty.city', address.city);
          setFieldValue('claimProperty.district', address.district);
          setFieldValue('claimProperty.state', address.state);
          setFieldValue('claimProperty.street', address.street);
          setCepLoading(false);
        })
        .catch(e => {
          console.log('address error:', e);
        });
    }
  };

  async function getTenantByDocument(document: string) {
    try {
      setLoadDocument(true);
      const unformatedDoc = document.replace(/\D/g, '');
      const isCPF = unformatedDoc.length === 11;
      const data = await getDataByDocument(unformatedDoc);
      if (isCPF) {
        setFieldValue('claimTenant.fullName', data.name);
      } else {
        setFieldValue('claimTenant.fullName', data.company);
        setFieldValue('claimTenant.cellphone', data.phone);
        setFieldValue('claimTenant.email', data.email);
        setDisabledNameField(false);
      }
    } catch (err) {
      console.log(err);
      setDisabledNameField(false);
    } finally {
      setLoadDocument(false);
    }
  }
  useEffect(() => {
    const managers: CustoDropDownItemProps[] = [];
    users.forEach((c, index) => managers.push({ value: c.id, key: index, text: c.name }));
    setAdditionalAccountManagerOptions(managers);
  }, []);

  return (
    <div>
      <SectionLabel text="Informações do locatário" />

      <Grid columns={16}>
        <Grid.Row>
          <Grid.Column width={4}>
            <FieldLabel>CPF/CNPJ do Locatário*</FieldLabel>
            <Field
              value={maskCpfOrCnpj(values.claimTenant.document)}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimTenant.document', maskCpfOrCnpj(value))
              }
              onBlur={(e: any) => {
                getTenantByDocument(e.target.value);
              }}
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Nome do Locatário*</FieldLabel>
            <Field
              value={values.claimTenant.fullName}
              component={Input}
              fluid
              disabled={disableNameField}
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimTenant.fullName', value)
              }
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Email do Locatário*</FieldLabel>
            <Field
              value={values.claimTenant.email}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimTenant.email', value)
              }
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Telefone do Locatário*</FieldLabel>
            <Field
              value={maskPhoneNumber(values.claimTenant.cellphone)}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimTenant.cellphone', maskPhoneNumber(value))
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <FieldLabel>STATUS:</FieldLabel>
            <div style={{ width: '100%' }}>
              <DropdownNativo
                style={{ width: '100%' }}
                value={values.claimEstate.status}
                name={`claimEstate.status`}
                defaultValue={
                  claimEstatesStatusEnum.length === 1
                    ? claimEstatesStatusEnum[0].value
                    : '' || ''
                }
                disabled={claimEstatesStatusEnum.length === 1 || false}
                options={claimEstatesStatusEnum || []}
                placeholder={'Selecionar...'}
                selection
                onChange={(e, { value }) => setFieldValue('claimEstate.status', value)}
                clearable
              />
            </div>
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Responsável:</FieldLabel>
            <div style={{ width: '100%' }}>
              <DropdownNativo
                name={`claimEstate.responsibleId`}
                loading={isAnalystsLoading}
                value={values.claimEstate.responsibleId || undefined}
                search
                clearable
                placeholder={isAnalystsLoading ? 'Aguarde...' : 'Selecione'}
                options={additionalAccountManagerOtions || []}
                onChange={(e: SyntheticEvent, { value }) =>
                  setFieldValue('claimEstate.responsibleId', value)
                }
                component={DropdownNativo}
                selection
                // basic
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <SectionLabel text="Informações do Seguro" />
      <Grid>
        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>
              SEGURADORA / GARANTIDORA:
              <Popup
                content={
                  'Em caso de dúvidas, verifique a cláusula da garantia no contrato de locação.'
                }
                position={'bottom right'}
                trigger={
                  <Icon
                    name={'info circle'}
                    color={'blue'}
                    style={{ cursor: 'help', marginLeft: 5 }}
                  />
                }
              />
            </FieldLabel>
            <div style={{ width: '100%' }}>
              <DropdownNativo
                style={{ width: '100%' }}
                name={`claimEstate.insurer`}
                options={selectableInsurers || []}
                value={values.claimEstate.insurerId}
                placeholder={'Selecionar...'}
                search
                selection
                clearable
                onChange={(e, { value }) => setFieldValue('claimEstate.insurerId', value)}
              />
            </div>
          </Grid.Column>

          {selectableEstates.length > 1 && (
            <Grid.Column width={5}>
              <FieldLabel>IMOBILIARIA*:</FieldLabel>
              <div style={{ width: '100%' }}>
                <DropdownNativo
                  style={{ width: '100%' }}
                  name={`claimEstate.estate`}
                  options={selectableEstates || []}
                  value={values.claimEstate.estateId}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  clearable
                  onChange={(e, { value }) =>
                    setFieldValue('claimEstate.estateId', value)
                  }
                />
              </div>
            </Grid.Column>
          )}

          <Grid.Column width={3}>
            <div style={{ paddingTop: '1rem' }}>
              <Radio
                label="Imóvel Ocupado"
                name="propertyStatus"
                value={ClaimPropertyStatusEnum.occupied}
                checked={values.claimProperty.status === ClaimPropertyStatusEnum.occupied}
                onChange={() =>
                  setFieldValue('claimProperty.status', ClaimPropertyStatusEnum.occupied)
                }
              />
              <Radio
                label="Imóvel Desocupado"
                name="propertyStatus"
                value={ClaimPropertyStatusEnum.vacant}
                checked={values.claimProperty.status === ClaimPropertyStatusEnum.vacant}
                onChange={() =>
                  setFieldValue('claimProperty.status', ClaimPropertyStatusEnum.vacant)
                }
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <FieldLabel>Número da Apólice</FieldLabel>
            <Field
              value={values.claimEstate.policyNumber}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimEstate.policyNumber', value)
              }
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Código do Sinistro</FieldLabel>
            <Field
              value={values.claimEstate.claimCode}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimEstate.claimCode', value)
              }
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Número do Processo</FieldLabel>
            <Field
              value={values.claimEstate.processNumber}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimEstate.processNumber', value)
              }
            />
          </Grid.Column>

          {values.claimProperty.status === ClaimPropertyStatusEnum.vacant && (
            <Grid.Column width={5}>
              <FieldLabel>DATA DE ENTREGA DAS CHAVES* </FieldLabel>
              <Field
                name={`claimProperty.deliveryKeysDate`}
                selected={
                  values.claimProperty.deliveryKeysDate
                    ? new Date(values.claimProperty.deliveryKeysDate)
                    : undefined
                }
                component={CustomDatePicker}
                customInput={
                  <AppMaskedInput
                    mask={DATE_INPUT_MASK}
                  />
                }
                dateFormat={'dd/MM/yyyy'}
                onChange={(date: Date, _e: SyntheticEvent) => {
                  setFieldValue(
                    'claimProperty.deliveryKeysDate',
                    FormatDateYMD_UTC_IGNORE(date)
                  );
                }}
              />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>

      <SectionLabel text="Endereço do imóvel" />
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column width={3}>
            <FieldLabel>CEP*</FieldLabel>
            <Field
              name="claimProperty.zipCode"
              value={values.claimProperty.zipCode}
              loading={cepLoading}
              component={Input}
              maxLength={9}
              fluid
              onChange={(_e: any, { value }: { value: string }) => onChangeCep(value)}
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <FieldLabel>Logradouro*</FieldLabel>
            <Field
              name="claimProperty.street"
              value={values.claimProperty.street}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimProperty.street', value)
              }
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <FieldLabel>Bairro*</FieldLabel>
            <Field
              name="claimProperty.district"
              value={values.claimProperty.district}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimProperty.district', value)
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>Cidade*</FieldLabel>
            <Field
              name="city"
              value={values.claimProperty.city}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimProperty.city', value)
              }
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <FieldLabel>UF*</FieldLabel>
            <Field
              name="values.claimProperty.state"
              value={values.claimProperty.state}
              component={Input}
              fluid
              maxLength={2}
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimProperty.state', value)
              }
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <FieldLabel>Número*</FieldLabel>
            <Field
              name="values.claimProperty.number"
              value={values.claimProperty.number}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimProperty.number', value)
              }
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Complemento</FieldLabel>
            <Field
              name="values.claimProperty.complement"
              value={values.claimProperty.complement}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimProperty.complement', value)
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <SectionLabel text="Informe os e-mails para receberem notificações do sinistro:" />
      <Grid columns="16" style={{ paddingLeft: '1em' }}>
        {(values.claimEstate?.notificationEmails as string[]).map(
          (notificationEmail, index) => {
            return (
              <Grid.Row>
                <Grid.Column width={4}>
                  <Field
                    value={notificationEmail}
                    component={Input}
                    fluid
                    onChange={(e: any, { value }: { value: string }) => {
                      setFieldValue(`claimEstate.notificationEmails[${index}]`, value);
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={1}>
                  <Icon
                    name={'remove circle'}
                    color={'grey'}
                    style={{ cursor: 'pointer', paddingTop: '5px' }}
                    size={'large'}
                    onClick={() => {
                      if (values.claimEstate.notificationEmails?.length === 1) {
                        setFieldValue(`claimEstate.notificationEmails`, ['']);
                      } else {
                        const arr1 = values.claimEstate.notificationEmails?.slice(
                          0,
                          index
                        );
                        const arr2 = values.claimEstate.notificationEmails?.slice(
                          index + 1
                        );
                        const newArr = [...(arr1 as string[]), ...(arr2 as string[])];
                        setFieldValue(`claimEstate.notificationEmails`, newArr);
                      }
                    }}
                  />
                </Grid.Column>
                {index + 1 === values.claimEstate.notificationEmails?.length && (
                  <Grid.Column width={5}>
                    <Button
                      primary
                      onClick={() => {
                        if (values.claimEstate.notificationEmails?.length) {
                          const lastEmail =
                            values.claimEstate.notificationEmails[
                              values.claimEstate.notificationEmails.length - 1
                            ];
                          if (lastEmail.length > 0)
                            setFieldValue(`claimEstate.notificationEmails`, [
                              ...(values.claimEstate.notificationEmails as string[]),
                              ''
                            ]);
                        } else {
                          setFieldValue(
                            `claimEstate.notificationEmails[${index + 1}]`,
                            ''
                          );
                        }
                      }}
                    >
                      <Icon name={'plus circle'} />
                      Adicionar E-mail
                    </Button>
                  </Grid.Column>
                )}
              </Grid.Row>
            );
          }
        )}
      </Grid>
      <SectionLabel text="Observações" />
      <Grid columns="16" style={{ paddingLeft: '1em' }}>
        <Grid.Row>
          <Grid.Column width={16}>
            <Field
              name={`claimEstate.observations`}
              rows={3}
              value={values.claimEstate.observations}
              onChange={(_: SyntheticEvent, data: any) => {
                setFieldValue(`claimEstate.observations`, data.value);
              }}
              component={FormatedTextArea}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default ClaimEstateInfoStep;
