import moment from 'moment-timezone';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { formatAmount } from '../../../../../services/masks';
import { FieldLabel } from '../../../../../styles';
import { translateProposalStatus } from '../../../utils/Pottencial';

const HeaderAndDivider = styled.h3`
  border-bottom: 1px solid #3498db;
  color: #3498db;
  width: 100%;
  margin-top: 20px !important;
  padding-bottom: 5px;
  letter-spacing: 0.4px;
`;

interface DetailPottencialInterface {
  fireInsurancePolicy: any;
}

interface ControledFieldInterface {
  label: string;
  value: any;
}

const ControledFields = (props: ControledFieldInterface) => {
  const { label, value } = props;
  return (
    <Grid.Column>
      <FieldLabel>{label}</FieldLabel>
      <span>{value}</span>
    </Grid.Column>
  );
};

const DetailPottencial = (props: DetailPottencialInterface) => {
  const { fireInsurancePolicy } = props;

  console.log(fireInsurancePolicy);
  return (
    <Grid columns={'equal'}>
      <HeaderAndDivider>Dados da Apólice</HeaderAndDivider>

      <Grid.Row>
        <ControledFields label={'ID da Apólice'} value={fireInsurancePolicy.id} />

        <ControledFields
          label={'Data de Criação'}
          value={moment(fireInsurancePolicy.createdAt).format('DD/MM/YYYY HH:mm:ss')}
        />

        <ControledFields
          label={'Última Atualização'}
          value={
            fireInsurancePolicy.updatedAt ? (
              moment(fireInsurancePolicy.updatedAt).format('DD/MM/YYYY HH:mm:ss')
            ) : (
              <span style={{ color: '#ccc' }}>Indisponível</span>
            )
          }
        />
      </Grid.Row>
      <Grid.Row>
        <ControledFields
          label={'Tipo de Faturamento'}
          value={fireInsurancePolicy.paymentType}
        />

        <ControledFields
          label={'Data de Início'}
          value={moment(fireInsurancePolicy.policyPeriodStart).format('DD/MM/YYYY')}
        />

        <ControledFields
          label={'Data de Término'}
          value={moment(fireInsurancePolicy.policyPeriodEnd).format('DD/MM/YYYY')}
        />
      </Grid.Row>
      <Grid.Row>
        <ControledFields
          label={'Nº de Parcelas'}
          value={fireInsurancePolicy.installments}
        />

        <ControledFields
          label={'Valor da Parcela'}
          value={`R$ ${formatAmount(
            String(
              Number(fireInsurancePolicy.fireInsuranceAnalysis.grossPremium) /
                fireInsurancePolicy.installments
            )
          )}`}
        />

        <ControledFields
          label={'Prêmio Total Cotado'}
          value={`R$ ${formatAmount(
            String(
              Number(fireInsurancePolicy.fireInsuranceAnalysis.grossPremium) *
                fireInsurancePolicy.installments
            )
          )}`}
        />
      </Grid.Row>
      <Grid.Row>
        <ControledFields
          label={'Vigência do Contrato'}
          value={`${moment(fireInsurancePolicy.policyPeriodEnd).diff(
            moment(fireInsurancePolicy.policyPeriodStart),
            'months'
          )} meses`}
        />

        <ControledFields
          label={'Status'}
          value={translateProposalStatus(fireInsurancePolicy.status)}
        />

        <ControledFields
          label={'Seguradora'}
          value={fireInsurancePolicy?.fireInsuranceAnalysis?.insurer?.name || ''}
        />
      </Grid.Row>
    </Grid>
  );
};

export default DetailPottencial;
