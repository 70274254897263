import { CustoDropDownItemProps } from "../../../../components/types";
import { AnalysisAssuranceEnum } from "../../../../enums/analysis-assurance.enum";
import { PersonTypesEnum } from "../../../../enums/person-types.enum";
import { PurposesEnum } from "../../../../enums/purposes.enum";
import { AddressService } from "../../../../services/address";
import { maskZipCode } from "../../../../services/masks";

export const analysisOptions: CustoDropDownItemProps[] = [
  {
    key: 1,
    text: 'Plano Seguro Completo (Garantido)',
    value: AnalysisAssuranceEnum.complete
  },
  {
    key: 2,
    text: 'Plano Seguro Simples (Básico)',
    value: AnalysisAssuranceEnum.simple
  }
];

export const purposeOptions: CustoDropDownItemProps[] = [
  {
    key: 1,
    text: 'Residencial',
    value: PurposesEnum.residential
  },
  {
    key: 2,
    text: 'Comercial',
    value: PurposesEnum.commercial
  }
];

export const tenantTypeOptions: CustoDropDownItemProps[] = [
  {
    key: 1,
    text: 'Pessoa Física',
    value: PersonTypesEnum.natural
  },
  {
    key: 2,
    text: 'Pessoa Jurídica',
    value: PersonTypesEnum.legal
  }
];

export const onChangeCep = (
  setFieldValue: (field: string, value: any) => void,
  path: string,
  e: React.ChangeEvent<HTMLInputElement>,
  setCepLoading: (loading: boolean) => void
): void => {
  const maskedValue = maskZipCode(e.target.value);

  setFieldValue(`${path}.zip_code`, maskedValue);
  if (maskedValue.length === 9) {
    setCepLoading(true);

    AddressService.getAddressByZipCode(e.target.value)
      .then(address => {
        setFieldValue(`${path}.city`, address.city);
        setFieldValue(`${path}.district`, address.district);
        setFieldValue(`${path}.state`, address.state);
        setFieldValue(`${path}.street`, address.street);
        setCepLoading(false);
      })
      .catch(e => {
        console.log('address error:', e);
      });
  }
};
