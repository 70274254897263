import styled from 'styled-components';

export const MessageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction:column;
  grid-gap: 8px;
  padding: 16px;
  .quilljs{
    width: 100%;
  }
`;

export const MessageInput = styled.textarea`
border-radius: 5px;
border: 1px solid #c2c2c2;
transition: .25s ease;
outline: none;
padding: 8px;
max-width: 100%;

width: calc(50vw - 76px);
resize: vertical;
overflow:auto;

&:hover,
&:focus {
  border-color: #5C6BC0;
}`;
