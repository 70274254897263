import React, { useState } from 'react';
import {
  Modal,
  Button,
  Table,
  Grid,
  Tab,
  TableCell
} from "semantic-ui-react";
import { SelectableModalProps } from "../../../components/types";
import styled from 'styled-components';
import { AnalysisResponse, PolicyResponse, ProposalResponse } from '../../../services/report';

import * as XLSX from 'xlsx';
import { DateToStringDate } from '../../../services/date';

const Header = styled(Table.Header)`
  background-color: #E1E8ED;
`;

const TableWrapper = styled.div`
  font-size:0.85em;
  font-weight:bold;

  .ui.table{
    border:none;
    text-align:center;
    color: #364445;
  }
`;

const NotFoundWrapper = styled.div`
  width: 100%;
  text-align:center;
  color: #364445;
  font-size:1.2em;
`;

export type ReportModal = {
  isAnalysisSelected: boolean;
  isProposalSelected: boolean;
  isPolicySelected: boolean;
  analysis: any[];
  proposals: any[];
  policies: any[];
  onClose: () => any;
  onOpen: () => any;
} & SelectableModalProps;

const ReportModal = (props: ReportModal) => {
  const { header, onClose, onOpen, isOpen } = props;

  const panes: any[] = [];
  const analysisIterable = props.analysis.length > 20 ? props.analysis.slice(0, 20) : props.analysis;

  const analysis = {
    menuItem: 'Análises', render: () =>
      <Tab.Pane>
        {props.isAnalysisSelected ?
          <div style={{ overflowY: 'scroll' }} >
            <Grid.Row
              style={{
                float: "right",
                paddingBottom: "8px",
                marginRight: "8px"
              }}
            >
              <Grid.Column width={16}>
                <p>Exibindo: <strong>{analysisIterable.length}</strong> de <strong>{props.analysis.length}</strong></p>
              </Grid.Column>
            </Grid.Row>
            <TableWrapper>
              <Table >
                <Header>
                  <Table.Row >
                    <Table.HeaderCell width={2}>IMOBILIÁRIA</Table.HeaderCell>
                    <Table.HeaderCell width={2}>LOCATÁRIO</Table.HeaderCell>
                    <Table.HeaderCell width={2}>DOCUMENTO</Table.HeaderCell>
                    <Table.HeaderCell width={2}>SEGURADORA</Table.HeaderCell>
                    <Table.HeaderCell width={2}>CRIADO EM</Table.HeaderCell>
                    <Table.HeaderCell width={3}>GESTOR DE CONTAS</Table.HeaderCell>
                    <Table.HeaderCell width={2}>STATUS DA ANÁLISE</Table.HeaderCell>
                    <Table.HeaderCell width={2}>ALUGUEL</Table.HeaderCell>
                    <Table.HeaderCell width={2}>CONDOMÍNIO</Table.HeaderCell>
                    <Table.HeaderCell width={2}>GÁS</Table.HeaderCell>
                    <Table.HeaderCell width={2}>ÁGUA</Table.HeaderCell>
                    <Table.HeaderCell width={2}>ENERGIA</Table.HeaderCell>
                    <Table.HeaderCell width={2}>IPTU MENSAL</Table.HeaderCell>
                    <Table.HeaderCell width={2}> - </Table.HeaderCell>
                  </Table.Row>
                </Header>
                <Table.Body>
                  {analysisIterable.map((bondInsurance: AnalysisResponse) => {
                    return (
                      <Table.Row>
                        <TableCell>{bondInsurance.estate}</TableCell>
                        <TableCell>{bondInsurance.tenant}</TableCell>
                        <TableCell>{bondInsurance.document}</TableCell>
                        <TableCell>{bondInsurance.insurer}</TableCell>
                        <TableCell>{bondInsurance.createdAt}</TableCell>
                        <TableCell>{bondInsurance.accountManager}</TableCell>
                        <TableCell>{bondInsurance.analysisStatus}</TableCell>
                        <TableCell>{bondInsurance.rent}</TableCell>
                        <TableCell>{bondInsurance.condominium}</TableCell>
                        <TableCell>{bondInsurance.gas_bill}</TableCell>
                        <TableCell>{bondInsurance.water_bill}</TableCell>
                        <TableCell>{bondInsurance.energy_bill}</TableCell>
                        <TableCell>{bondInsurance.taxes}</TableCell>
                        <TableCell>{bondInsurance.status}</TableCell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </TableWrapper>
          </div>
          : <NotFoundWrapper><p>Análises não selecionadas.</p></NotFoundWrapper>
        }
      </Tab.Pane>
  }

  const proposalsIterable = props.proposals.length > 20 ? props.proposals.slice(0, 20) : props.proposals;

  const proposals = {
    menuItem: 'Propostas', render: () =>
      <Tab.Pane>
        {props.isProposalSelected ?
          <div style={{ overflowY: 'scroll' }} >
            <Grid.Row
              style={{
                float: "right",
                paddingBottom: "8px",
                marginRight: "8px"
              }}
            >
              <Grid.Column width={16}>
                <p>Exibindo: <strong>{proposalsIterable.length}</strong> de <strong>{props.proposals.length}</strong></p>
              </Grid.Column>
            </Grid.Row>
            <TableWrapper>
              <Table >
                <Header>
                  <Table.Row >
                    <Table.HeaderCell width={2}>IMOBILIÁRIA</Table.HeaderCell>
                    <Table.HeaderCell width={2}>GESTOR DE CONTAS</Table.HeaderCell>
                    <Table.HeaderCell width={2}>LOCATÁRIO</Table.HeaderCell>
                    <Table.HeaderCell width={2}>DOCUMENTO</Table.HeaderCell>
                    <Table.HeaderCell width={2}>SEGURADORA</Table.HeaderCell>
                    <Table.HeaderCell width={2}>CRIADO EM</Table.HeaderCell>
                    <Table.HeaderCell width={2}>STATUS DA PROPOSTA</Table.HeaderCell>
                    <Table.HeaderCell width={2}>TOTAL DE PARCELAS</Table.HeaderCell>
                    <Table.HeaderCell width={2}>VALOR DA PARCELA</Table.HeaderCell>
                    <Table.HeaderCell width={2}>PRÊMIO TOTAL</Table.HeaderCell>
                  </Table.Row>
                </Header>
                <Table.Body>
                  {proposalsIterable.map((proposal: ProposalResponse) => {
                    return (
                      <Table.Row>
                        <TableCell>{proposal.estate}</TableCell>
                        <TableCell>{proposal.accountManager}</TableCell>
                        <TableCell>{proposal.tenant}</TableCell>
                        <TableCell>{proposal.document}</TableCell>
                        <TableCell>{proposal.insurer}</TableCell>
                        <TableCell>{proposal.createdAt}</TableCell>
                        <TableCell>{proposal.proposalStatus}</TableCell>
                        <TableCell>{proposal.totalInstallments}</TableCell>
                        <TableCell>{proposal.installmentValue}</TableCell>
                        <TableCell>{proposal.totalPremiumAmount}</TableCell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </TableWrapper>
          </div>
          : <NotFoundWrapper><p>Propostas não selecionadas.</p></NotFoundWrapper>
        }
      </Tab.Pane>
  }

  const policiesIterable = props.policies.length > 20 ? props.policies.slice(0, 20) : props.policies;

  const policies = {
    menuItem: 'Apólices', render: () =>
      <Tab.Pane>
        {props.isPolicySelected ?
          <div style={{ overflowY: 'scroll' }} >
            <Grid.Row
              style={{
                float: "right",
                paddingBottom: "8px",
                marginRight: "8px"
              }}
            >
              <Grid.Column width={16}>
                <p>Exibindo: <strong>{policiesIterable.length}</strong> de <strong>{props.policies.length}</strong></p>
              </Grid.Column>
            </Grid.Row>
            <TableWrapper>
              <Table >
                <Header>
                  <Table.Row >
                    <Table.HeaderCell width={1}>IMOBILIÁRIA</Table.HeaderCell>
                    <Table.HeaderCell width={1}>GESTOR DE CONTAS</Table.HeaderCell>
                    <Table.HeaderCell width={1}>LOCATÁRIO</Table.HeaderCell>
                    <Table.HeaderCell width={1}>DOCUMENTO</Table.HeaderCell>
                    <Table.HeaderCell width={1}>SEGURADORA</Table.HeaderCell>
                    <Table.HeaderCell width={1}>PLANO SELECIONADO</Table.HeaderCell>
                    <Table.HeaderCell width={1}>EMITIDA EM</Table.HeaderCell>
                    <Table.HeaderCell width={1}>CÓDIGO</Table.HeaderCell>
                    <Table.HeaderCell width={2}>STATUS DA APÓLICE</Table.HeaderCell>
                    <Table.HeaderCell width={1}>TOTAL DE PARCELAS</Table.HeaderCell>
                    <Table.HeaderCell width={1}>VALOR DA PARCELA</Table.HeaderCell>
                    <Table.HeaderCell width={1}>PRÊMIO TOTAL</Table.HeaderCell>
                    <Table.HeaderCell width={1}>ALUGUEL</Table.HeaderCell>
                    <Table.HeaderCell width={1}>CONDOMÍNIO</Table.HeaderCell>
                    <Table.HeaderCell width={1}>GÁS</Table.HeaderCell>
                    <Table.HeaderCell width={1}>ÁGUA</Table.HeaderCell>
                    <Table.HeaderCell width={1}>ENERGIA</Table.HeaderCell>
                    <Table.HeaderCell width={1}>IPTU MENSAL</Table.HeaderCell>
                    <Table.HeaderCell width={1}> ORIGEM </Table.HeaderCell>
                    <Table.HeaderCell width={1}> - </Table.HeaderCell>
                  </Table.Row>
                </Header>
                <Table.Body>
                  {policiesIterable.map((policy: PolicyResponse) => {
                    return (
                      <Table.Row>
                        <TableCell>{policy.estate}</TableCell>
                        <TableCell>{policy.accountManager}</TableCell>
                        <TableCell>{policy.tenant}</TableCell>
                        <TableCell>{policy.document}</TableCell>
                        <TableCell>{policy.insurer}</TableCell>
                        <TableCell>{policy.selectedPlan}</TableCell>
                        <TableCell>{policy.createdAt}</TableCell>
                        <TableCell>{policy.code}</TableCell>
                        <TableCell>{policy.policyStatus}</TableCell>
                        <TableCell>{policy.totalInstallments}</TableCell>
                        <TableCell>{policy.installmentValue}</TableCell>
                        <TableCell>{policy.totalPremiumAmount}</TableCell>
                        <TableCell>{policy.rent}</TableCell>
                        <TableCell>{policy.condominium}</TableCell>
                        <TableCell>{policy.gas_bill}</TableCell>
                        <TableCell>{policy.water_bill}</TableCell>
                        <TableCell>{policy.energy_bill}</TableCell>
                        <TableCell>{policy.taxes}</TableCell>
                        <TableCell>{policy.origin}</TableCell>
                        <TableCell>{policy.status}</TableCell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </TableWrapper>
          </div>
          : <NotFoundWrapper><p>Apólices não selecionadas</p></NotFoundWrapper>
        }
      </Tab.Pane>
  }

  const handleExport = () => {
    const date = DateToStringDate(new Date());
    const analysisHeader = [['IMOBILIÁRIA', 'LOCATÁRIO', 'DOCUMENTO', 'SEGURADORA', 'CRIADO EM', 'GESTOR DE CONTAS', 'STATUS DA ANÁLISE', 'ALUGUEL', 'CONDOMÍNIO', 'GÁS', 'ÁGUA', 'ENERGIA', 'IPTU MENSAL', ' - ']];
    const proposalHeader = [['IMOBILIÁRIA', 'GESTOR DE CONTAS', 'LOCATÁRIO', 'DOCUMENTO', 'SEGURADORA', 'CRIADO EM', 'STATUS DA PROPOSTA', 'TOTAL DE PARCELAS', 'VALOR DA PARCELA', 'PRÊMIO TOTAL']];
    const policyHeader = [['IMOBILIÁRIA', 'GESTOR DE CONTAS', 'LOCATÁRIO', 'DOCUMENTO', 'SEGURADORA', 'PLANO SELECIONADO', 'EMITIDA EM', 'CÓDIGO', 'STATUS DA APÓLICE', 'TOTAL DE PARCELAS', 'VALOR DA PARCELA', 'PRÊMIO TOTAL', 'ALUGUEL', 'CONDOMÍNIO', 'GÁS', 'ÁGUA', 'ENERGIA', 'IPTU MENSAL', ' ORIGEM ', ' - ']];

    const workbook = XLSX.utils.book_new();

    if (props.isAnalysisSelected) {
      const analysisSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(analysisSheet, analysisHeader);
      XLSX.utils.sheet_add_json(analysisSheet, props.analysis, { origin: 'A2', skipHeader: true });
      XLSX.utils.book_append_sheet(workbook, analysisSheet, 'Análises');
    }

    if (props.isProposalSelected) {
      const proposalSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(proposalSheet, proposalHeader);
      XLSX.utils.sheet_add_json(proposalSheet, props.proposals, { origin: 'A2', skipHeader: true });
      XLSX.utils.book_append_sheet(workbook, proposalSheet, 'Propostas');
    }


    if (props.isPolicySelected) {
      const policySheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(policySheet, policyHeader);
      XLSX.utils.sheet_add_json(policySheet, props.policies, { origin: 'A2', skipHeader: true });
      XLSX.utils.book_append_sheet(workbook, policySheet, 'Apólices');
    }

    XLSX.writeFile(workbook, `Report4seg-${date}.xlsx`);
  }

  props.isAnalysisSelected && panes.push(analysis);
  props.isProposalSelected && panes.push(proposals);
  props.isPolicySelected   && panes.push(policies);

  return (
    <Modal
      style={{ width: "95%" }}
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      closeOnDimmerClick={false}
      closeIcon
    >
      <Modal.Header>
        <Grid.Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid.Column>
            {header}
          </Grid.Column>
        </Grid.Row>
      </Modal.Header>
      <Modal.Content>
        <Tab panes={panes} />
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Exportar Relatório"
          labelPosition='left'
          icon='download'
          onClick={handleExport}
          color='blue'
        />
      </Modal.Actions>
    </Modal>
  );
}

export default ReportModal;
