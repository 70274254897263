import React from 'react';
import styled from 'styled-components';
import { Icon, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';

const IconTextWrapper = styled.div`
  display: flex;
  flex-direction:row;
  align-items: flex-start;
`;

type IconTextProps = {
  iconName: SemanticICONS;
  iconColor: string;
  text: string;
}

export const IconText = (props: IconTextProps) => {

  const { iconName, iconColor, text } = props;

  return (
    <IconTextWrapper>
      <Icon name={iconName} style={{ color: iconColor }} />
      {text}
    </IconTextWrapper>
  );
}
