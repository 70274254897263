import React from 'react';
import { InputOnChangeData } from 'semantic-ui-react';
import {
  SimulatorWrapper,
  ContainerWrapper,
  HeaderContentWrapper
} from './styled-components';
import { FireInsuranceBudgetFormProps, QuotePayload } from '../FireInsuranceBudgetForm';
import Header from './simulator/Header';
import Content from './simulator/Content';
import Actions from './simulator/Actions';

type SimulatorProps =
  FireInsuranceBudgetFormProps
  & { fireInsuranceBudgetFormProps: FireInsuranceBudgetFormProps }
  & { setIsLoading: React.Dispatch<React.SetStateAction<boolean>> }
  & { setQuotePayload: React.Dispatch<React.SetStateAction<QuotePayload>> }
  & { handleInputChange: (_: any, data: InputOnChangeData) => void };

const Simulator = (props: any) => {
  const { handleInputChange, setIsLoading, setQuotePayload, fireInsuranceBudgetFormProps } = props;
  const { simulator } = props.values;

  return (
    <>
      <SimulatorWrapper>
        <ContainerWrapper>
          <HeaderContentWrapper>
            <Header simulator={simulator} />
            <Content {...props}
            setIsLoading={setIsLoading}
            setQuotePayload={setQuotePayload}
            fireInsuranceBudgetFormProps={fireInsuranceBudgetFormProps}
            handleInputChange={handleInputChange} />
          </HeaderContentWrapper>
          <Actions
          {...props}
          setQuotePayload={setQuotePayload}
          setIsLoading={setIsLoading} />
        </ContainerWrapper>
      </SimulatorWrapper>
    </>
  );
};

export default Simulator;
