import React from 'react';

import { FireInsuranceQuotation } from '../../../../types/FireInsuranceQuotation';
import PottencialCancel from './Pottencial'
import AlfaSeguroCancelModal from './AlfaSeguros';

interface CancelModalInterface {
  fireInsurance: FireInsuranceQuotation;
  open: boolean;
  onClose: () => void;
};

const CancelModal = (props: CancelModalInterface): JSX.Element | null => {
  const { fireInsurance, open, onClose } = props;

  const renderView = (insurerCode: string) => {
    switch (insurerCode) {
      case 'POTTENCIAL':
        return <PottencialCancel fireInsurance={fireInsurance} close={onClose} />;
      case 'ALFA_SEGUROS':
        return <AlfaSeguroCancelModal budgetId={fireInsurance.id} open={open} onClose={onClose} />
      default:
        return null;
    }
  }

  return renderView(fireInsurance?.insurer.code);
}

export default CancelModal;
