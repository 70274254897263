import Swal from 'sweetalert2'

import React, { useEffect, useState } from "react";
import { Button, Header, Icon, Modal, Step, Loader, Dimmer, } from "semantic-ui-react";
import { LogModalProps } from "../../../types/LogModalProps";
import LogTable from '../LogTable';

const LogModal = ({
  closeModal,
  setOpen,
  analysisID,
  createdAt,
  updatedAt
}: LogModalProps) => {
  const [openLogModal, setOpenLogModal] = useState(false);

  useEffect(() => {
    setOpen && setOpen(openLogModal)
  }, [openLogModal])

  return (
    <div style={{ outline: 'none' }}>
      <Modal
        closeIcon
        open={openLogModal}
        onClose={
          () => setOpenLogModal(false)
        }
        trigger={
          <Button
            className="ui button"
            onClick={() => setOpenLogModal(true)}
          >
            <Icon name={"file alternate outline"} />
            Abrir Logs
          </Button>
        }
      >
        <Header icon="file alternate outline" content={`Logs`} />
        <Modal.Content scrolling>
          <LogTable
            analysisID={analysisID}
            createdAt={createdAt}
            updatedAt={updatedAt}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={() => setOpenLogModal(false)}>
            <Icon name="remove" /> Sair
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
export default LogModal;
