import {EMPTY_DATE} from "../../util";
import {SimulatorState, SimulatorReducer} from "../types/temp-types";

type SimulatorRematchModel = {
  state: SimulatorState;
  reducers: SimulatorReducer;
}

const initialState = {
  profitShare: 0,
  brokerageFee: 0,
  installments: 12,
  installmentsData: [],
  value: 0,
  coverValue: 0,
  validity: 1,
  startDate: EMPTY_DATE,
  endDate: EMPTY_DATE,
  covered: false,
  insurerGain: 0
}

const simulator: SimulatorRematchModel = {
  state: initialState,
  reducers: {
    updateSimulator: (state: SimulatorState, payload: Partial<SimulatorState>): SimulatorState => {
      return {
        ...state,
        ...payload
      }
    },
    updateSimulatorFields: (state: SimulatorState, payload: Partial<SimulatorState>): SimulatorState => {
      return {
        ...state,
        ...payload
      };
    },
    resetSimulatorFields: (state: SimulatorState): SimulatorState => {
      return initialState;
    },
  }
};

export default simulator;
