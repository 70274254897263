import React, { SyntheticEvent } from 'react';
import { Form, Grid, Checkbox, Message } from 'semantic-ui-react';
import { FormikProps } from 'formik';
import { FormValues } from './types';
import { LoginProps } from './OAuthLogin';
import Input from '../../components/Input';
import Button from '../../components/Button';
import background from '../../resources/imgs/background-4seg-new.png';
import Loader from '../../components/Loader';
import logo from '../../resources/imgs/logo.png';
import confiax from '../../resources/imgs/logoconfiax.svg'
import { FormikErrorMessage } from '../../components/ErrorMessage';
import { Pushable, SideBarGrid, Logo, FormContainer, WelcomeText, InfoText, Pusher, ImageBackgroundCover, ImageBackgroundText, ImageBackground, Confiax, Imag } from './styles';
import { FieldLabel } from '../../styles';
import { useLocation } from 'react-router-dom';

const OAuthLoginForm = (props: LoginProps & FormikProps<FormValues>) => {
  const {
    values,
    handleSubmit,
    handleChange,
    isSubmitting
  } = props;

  const { hash } = useLocation();
  values.email = hash.replace('#email=', '');

  if (isSubmitting) {
    return (
      <>
        <Loader />
      </>
    );
  }
  return (
    <>
      <Pushable>
        <SideBarGrid>
          <Logo src={logo} />
          <FormContainer>
            <div>
              <WelcomeText>Olá!</WelcomeText>
              <InfoText>Estamos mudando para lhe atender melhor!<br />
                <strong style={{display: 'block', fontSize: 30, lineHeight: 1.3}}>Por favor, confirme seu</strong>
                <strong style={{display: 'block', fontSize: 30, lineHeight: 1.3}}>E-mail e senha atuais</strong>
              </InfoText>
              <Form
                initialValues={values}
                onSubmit={handleSubmit}>
                <FieldLabel>E-MAIL:</FieldLabel>
                <Input
                  name='email'
                  type='email'
                  icon={{ name: 'users', color: 'blue' }}
                  iconPosition='left'
                  fluid
                  onChange={handleChange}
                  value={values.email} />
                <FormikErrorMessage component="div" name="email" />
                <p />

                <FieldLabel>SENHA:</FieldLabel>
                <Input
                  name='password'
                  type='password'
                  icon={{ name: 'lock', color: 'blue' }}
                  iconPosition='left'
                  fluid
                  onChange={handleChange}
                  value={values.password} />
                <FormikErrorMessage component="div" name="password" />

                <p />
                <Grid columns={2}>
                  <Grid.Row>
                    <Grid.Column>
                      <a href="/recovery-password"
                        onClick={(e: SyntheticEvent) => { props.history.push('/recovery-password'); }}>
                        Esqueci minha senha
                      </a>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column textAlign='center' width={16}>
                      <Button text="Confirmar Login"
                        color='blue'
                        size='large'
                        type='submit' />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column textAlign='center' width={16}>
                      <Confiax>
                        <Imag src={confiax} />
                      </Confiax>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </div>
          </FormContainer>
        </SideBarGrid>

        <Pusher>
          <ImageBackground src={background} />
        </Pusher>
      </Pushable>
    </>
  );
}

export default OAuthLoginForm;
