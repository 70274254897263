import { withFormik } from "formik";
import { CancellationInitialValues } from "./initialStatesCancel";
import ChangingStatusForm from '../Steps/ChangingStatusFormStep';
export const ChangingFormik = withFormik<any, typeof CancellationInitialValues>({

  mapPropsToValues: () => CancellationInitialValues,

  handleSubmit: (values, bag) => true,

})(ChangingStatusForm);
