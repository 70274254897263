import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import PoliciesTable from './PoliciesTable';
import Filter from './Filter';
import { connect } from 'react-redux';
import { iRootState } from '../../store';
import { FilterData } from './types';
import { FormikProps } from 'formik';

const ListWrapper = styled.div`
  display: flex;
  flex-direction:column;
  padding: 1em;
`;

const mapState = (state: iRootState) => ({
  policiesData: state.fireInsurancePoliciesList
});

export type Props = ReturnType<typeof mapState> & RouteComponentProps & FormikProps<FilterData>;

const Policies = (props: Props) => {

  return (
    <>
      <ListWrapper>
        <Filter {...props}/>
        <PoliciesTable {...props} />
      </ListWrapper >
    </>
  );
}

export default connect(mapState, null)(Policies);
