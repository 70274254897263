import styled from 'styled-components';

export const Title = styled.h5`
  font-size: 16px;
  margin-left: 26px;
  margin-bottom: 6px;
  padding-top: 32px;
`

export const Image = styled.img`
  width: 118px;
  height: 74px;
`

export const ButtonIcon = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
`

export const Divider = styled.hr`
	border: 0;
	border-top: 1px solid #D0D0D0;
  margin-bottom: 24px;
`

export const DropdownContainer = styled.div`
    margin-top: 10px;
    background: #fff;
    border-radius: 15px;
    width: 324px;
    height: 368px;

    position: relative;

    &:after{

      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #fff;

      top: -40px;
      right: 0;
    }
`

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 32px;
  margin-bottom: 32px;
`

export const IconContainer = styled.div`
  float: right;
  margin-right: 22px;
`
