import React, { useEffect, useState } from 'react';
import {
  Grid,
  InputOnChangeData,
  List,
  Radio,
  Dropdown,
  DropdownItemProps,
  Icon,
  Popup
} from 'semantic-ui-react';
import { Field, FormikProps } from 'formik';
import SectionLabel from '../../../../../../../../components/SectionLabel';
import { FieldLabel } from '../../../../../../../../styles';
import {
  formatAmount,
  maskCpfOrCnpj,
  maskMoney,
  maskZipCode,
  numberToMoneyString
} from '../../../../../../../../services/masks';
import Input from '../../../../../../../../components/Input';
import { AddressService } from '../../../../../../../../services/address';
import {
  HireProposalModalOffer,
  HireProposalModalProps,
  HireProposalModalPropsForm
} from '../../../../types/ModalHireProposalProps';
import CustomDatePicker from '../../../../../../../../components/CustomDatePicker';
import { SyntheticEvent } from 'react-router/node_modules/@types/react';
import AppMaskedInput from '../../../../../../../../components/MaskedInput';
import Toggle from '../../../../../../../../components/Toggle';
import {
  cnpjValidity,
  cpfValidity,
  dateDiffInYears,
  monthDiff,
  daysDiff,
  addMonthToDate
} from '../../../../../../../../util';
import { SurtyGeneralInsuraceInitialValues } from '../../Formik/initialStatesSurty';
import { PurposesEnum } from '../../../../../../../../enums/purposes.enum';
import {
  CustomDropDownOption,
  genderOptions,
  hirePropertyTypeOptions,
  legalCommercialResidentialReasonOptions,
  maritalStatusOptions,
  naturalCommercialReasonOptions,
  naturalResidentialReasonOptions
} from '../../../../../../../bond-insurance-budget-form/types';
import { PersonTypesEnum } from '../../../../../../../../enums/person-types.enum';
import { CustoDropDownItemProps } from '../../../../../../../../components/types';
import { AnalysisAssuranceEnum } from '../../../../../../../../enums/analysis-assurance.enum';
import _ from 'lodash';
import { warningNotification } from '../../../../../../../../services/notification';
import { PoliticallyExposedPersonEnum } from '../../../../../../../../enums/politically-exposed-person.enum';
import FormatedTextArea from '../../../../../../../../components/FormatedTextArea';
import { GrayableGridColumn } from '../../../../../../../estates-form/styles';
import moment from 'moment-timezone';
import { DateToStringDate } from '../../../../../../../../services/date';
import AddressWarningBanner from '../../../AddressWarningBanner';
import { Card, CardContainer, ErrorLabel } from './styles';
import { getDataByDocument } from '../../../../../../../../services/documents';
import { getMonthsOptions } from '../../../../../../../../utils/getMonths';

const SurtyGeneralInsuranceForm = ({
  values,
  setFieldValue,
  maxInstallments,
  nominal_total_premium_amount,
  flagSubmit,
  callbackSubmit,
  setFlagSubmit,
  bondInsurance,
  analysisID,
  approvedAt,
  offers
}: FormikProps<typeof SurtyGeneralInsuraceInitialValues> &
  HireProposalModalProps &
  HireProposalModalPropsForm) => {
  const [reasonsOptions, setReasonsOptions] = useState<Array<DropdownItemProps>>([]);
  const [cepLoading, setCepLoading] = useState<boolean>(false);
  const [maxRent, setMaxRent] = useState<number>(0);
  const [noComplement, setNoComplement] = useState<boolean>(false);
  const [documentError, setDocumentError] = useState(false);
  const [offerSelected, setOfferSelected] = useState<HireProposalModalOffer | null>(null);
  const [loadDocument, setLoadDocument] = useState(false);
  const [disableNameField, setDisabledNameField] = useState<boolean>(true);

  function getBirthDate() {
    const data = moment(bondInsurance?.natural_tenant?.main_tenant_birth_date).toDate();
    return data;
  }

  const getInfoIconText = (offer: HireProposalModalOffer) =>
    `LMI de ${offer?.lmi}x o aluguel para utilizar:
    - Aluguel
    - Condomínio
    - IPTU
    - Água
    - Luz
  Multa rescisória (limitado a 3 vezes o valor do aluguel proporcional ao período restante do contrato)
  Valor final da locação PINTURA e DANOS AO IMÓVEL (limitado a 6 vezes o valor do aluguel, podendo utilizar até 3 vezes para pintura e o restante em Danos)`;

  const handleToggle = () => {
    setNoComplement(!noComplement);
  };

  const difMonths = (initial: any, final: any) => {
    const start = moment(initial);
    const end = moment(final);

    return end.diff(start, 'months');
  };

  const onChangeCep = (zipCode: string, isProperty?: boolean): void => {
    const maskedValue = maskZipCode(zipCode);

    setFieldValue('zipCode', maskedValue);
    if (maskedValue.length >= 9) {
      setCepLoading(true);
      isProperty
        ? AddressService.getAddressByZipCode(zipCode)
            .then(address => {
              setFieldValue('property.city', address.city);
              setFieldValue('property.district', address.district);
              setFieldValue('property.state', address.state);
              setFieldValue('property.street', address.street);
              setCepLoading(false);
            })
            .catch(e => {
              console.log('address error:', e);
            })
        : AddressService.getAddressByZipCode(zipCode)
            .then(address => {
              setFieldValue('estate.estateCity', address.city);
              setFieldValue('estate.estateDistrict', address.district);
              setFieldValue('estate.estateState', address.state);
              setFieldValue('estate.estateStreet', address.street);
              setCepLoading(false);
            })
            .catch(e => {
              console.log('address error:', e);
            });
    }
  };

  useEffect(() => {
    if (values.estate.estateZipCode) {
    }
    if (values.estate.estateZipCode && values.estate.estateZipCode.length >= 8) {
      onChangeCep(values.estate.estateZipCode);
    }
  }, [values.estate.estateZipCode]);

  const analysisOptions: CustoDropDownItemProps[] = [
    {
      key: 1,
      text: 'Plano Seguro Completo (Garantido)',
      value: AnalysisAssuranceEnum.complete
    },
    {
      key: 2,
      text: 'Plano Seguro Simples (Básico)',
      value: AnalysisAssuranceEnum.simple
    }
  ];

  useEffect(() => {
    if (
      bondInsurance?.purpose === PurposesEnum.commercial &&
      bondInsurance?.tenant_type === PersonTypesEnum.natural
    ) {
      setReasonsOptions(naturalCommercialReasonOptions);
    } else if (
      bondInsurance?.purpose === PurposesEnum.residential &&
      bondInsurance?.tenant_type === PersonTypesEnum.natural
    ) {
      setReasonsOptions(naturalResidentialReasonOptions);
    } else {
      setReasonsOptions(legalCommercialResidentialReasonOptions);
    }
  }, [bondInsurance]);

  useEffect(() => {
    setFieldValue('analysis_assurance', bondInsurance?.analysis_assurance);
    setFieldValue('estate', {
      estateZipCode: (bondInsurance?.estate as any)?.estate_zip_code,
      estateStreet: (bondInsurance?.estate as any).estate_street,
      estateDistrict: (bondInsurance?.estate as any).estate_district,
      estateCity: (bondInsurance?.estate as any).estate_city,
      estateState: (bondInsurance?.estate as any).estate_state,
      estateNumber: (bondInsurance?.estate as any).estate_number,
      estateComplement: (bondInsurance?.estate as any).estate_complement
    });
    setFieldValue('legal_tenant', bondInsurance?.legal_tenant);
    setFieldValue('natural_tenant', bondInsurance?.natural_tenant);
    delete bondInsurance?.property.rental_period_start_date;
    delete bondInsurance?.property.rental_period_end_date;
    delete bondInsurance?.property.rent_insurance_contract_start_date;
    delete bondInsurance?.property.rent_insurance_contract_end_date;
    setFieldValue('property', bondInsurance?.property);
    setFieldValue('purpose', bondInsurance?.purpose);
    setFieldValue('property.type', bondInsurance?.property.property_type);
    setMaxRent(Number(bondInsurance?.property.rent_value || 0));
    setFieldValue(
      'property.formatted_rent_value',
      maskMoney(String(Number(bondInsurance?.property.rent_value || 0)), true, 2)
    );

    setFieldValue('property.rent_value', Number(bondInsurance?.property.rent_value) || 0);
    setFieldValue(
      'formattedValues.rent_value',
      maskMoney(String(bondInsurance?.property.rent_value), true, 2)
    );

    setFieldValue(
      'property.condominium_fee',
      Number(bondInsurance?.property.condominium_fee) || 0
    );
    setFieldValue(
      'formattedValues.condominium_fee',
      maskMoney(String(bondInsurance?.property.condominium_fee), true, 2)
    );

    setFieldValue(
      'property.taxes_value',
      Number(bondInsurance?.property.taxes_value) || 0
    );
    setFieldValue(
      'formattedValues.taxes_value',
      maskMoney(String(bondInsurance?.property.taxes_value), true, 2)
    );

    setFieldValue('property.water_bill', Number(bondInsurance?.property.water_bill) || 0);
    setFieldValue(
      'formattedValues.water_bill',
      maskMoney(String(bondInsurance?.property.water_bill), true, 2)
    );

    setFieldValue(
      'property.energy_bill',
      Number(bondInsurance?.property.energy_bill) || 0
    );
    setFieldValue(
      'formattedValues.energy_bill',
      maskMoney(String(bondInsurance?.property.energy_bill), true, 2)
    );

    setFieldValue('property.gas_bill', Number(bondInsurance?.property.gas_bill) || 0);
    setFieldValue(
      'formattedValues.gas_bill',
      maskMoney(String(bondInsurance?.property.gas_bill), true, 2)
    );

    setFieldValue(
      'property.',
      maskMoney(String(bondInsurance?.property.gas_bill), true, 2)
    );

    // if (bondInsurance?.property_owner_natural_person?.name) {
    //   setFieldValue(
    //     'owner_data.name',
    //     bondInsurance?.property_owner_natural_person?.name
    //   );
    // }
    // if (bondInsurance?.property_owner_natural_person?.document) {
    //   setFieldValue(
    //     'owner_data.public_document_id',
    //     maskCpfOrCnpj(bondInsurance?.property_owner_natural_person?.document)
    //   );
    // }
  }, [bondInsurance]);

  useEffect(() => {
    function submitForm() {
      let error = false;
      setFlagSubmit && setFlagSubmit(false);

      if (!values.property.rent_value || Number(values.property.rent_value) > maxRent) {
        warningNotification('Ops...', 'Valor do aluguel inválido.');
        error = true;
      }

      if (!offerSelected) {
        warningNotification(
          'Ops...',
          'Você precisa selecionar um plano para prosseguir.'
        );
        error = true;
      }

      if (!values.property.reason) {
        warningNotification('Ops...', 'Por favor, preencha o motivo da locação!');
        error = true;
      }

      if (!values.property.zip_code) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço do imóvel!'
        );
        error = true;
      }
      if (!values.property.street) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço do imóvel!'
        );
        error = true;
      }
      if (!values.property.district) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço do imóvel!'
        );
        error = true;
      }
      if (!values.property.city) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço do imóvel!'
        );
        error = true;
      }
      if (!values.property.state) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço do imóvel!'
        );
        error = true;
      }
      if (!values.property.number) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço do imóvel!'
        );
        error = true;
      }

      if (!values.property.complement && !noComplement) {
        warningNotification(
          'Ops...',
          'Por favor, preencha o complemento do imóvel! Caso não tenha complemento, por favor marque ao lado!'
        );
        error = true;
      }

      if (!values.property.type) {
        warningNotification('Ops...', 'Por favor, preencha o Tipo do imóvel!');
        error = true;
      }

      if (!values.estate.estateZipCode) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço da imobiliaria!'
        );
        error = true;
      }
      if (!values.estate.estateStreet) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço da imobiliaria!'
        );
        error = true;
      }
      if (!values.estate.estateDistrict) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço da imobiliaria!'
        );
        error = true;
      }
      if (!values.estate.estateCity) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço da imobiliaria!'
        );
        error = true;
      }
      if (!values.estate.estateState) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço da imobiliaria!'
        );
        error = true;
      }
      if (!values.estate.estateNumber) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço da imobiliaria!'
        );
        error = true;
      }

      if (!values.owner_data.public_document_id) {
        warningNotification('Ops...', 'Por favor, preencha o Documento do Proprietário!');
        error = true;
      }
      if (!values.owner_data.name) {
        warningNotification('Ops...', 'Por favor, preencha o Nome do Proprietário!');
        error = true;
      }

      if (
        values.owner_data.public_document_id.replace(/\D/g, '') ===
        bondInsurance?.natural_tenant?.main_tenant_cpf.replace(/\D/g, '')
      ) {
        warningNotification(
          'Erro...',
          'O documento do Proprietário deve ser DIFERENTE do documento do Locatário!'
        );
        error = true;
      }

      if (!values.property.rent_insurance_contract_start_date) {
        warningNotification(
          'Ops...',
          'Por favor, preencha o Início da Vigência da Garantia!'
        );
        error = true;
      }

      if (!values.property.rental_period_start_date) {
        warningNotification(
          'Ops...',
          'Por favor, preencha o Início da Vigência de Contrato de Locação!'
        );
        error = true;
      }

      if (!values.property.rental_period_end_date) {
        warningNotification(
          'Ops...',
          'Por favor, preencha o Fim da Vigência de Contrato de Locação!'
        );
        error = true;
      }

      if (
        values.property.rent_insurance_contract_start_date &&
        values.property.rent_insurance_contract_end_date
      ) {
        const dif = difMonths(
          values.property.rent_insurance_contract_start_date,
          values.property.rent_insurance_contract_end_date
        );

        if (dif < 12) {
          warningNotification(
            'Ops...',
            'A vigência da garantia deve ser de no mínimo 1 ano completo!'
          );
          error = true;
        }
      }

      if (
        values.property.rental_period_start_date &&
        values.property.rental_period_end_date
      ) {
        const dif = difMonths(
          values.property.rental_period_start_date,
          values.property.rental_period_end_date
        );

        if (dif > 60) {
          warningNotification(
            'Ops...',
            'A diferença máxima entre as datas de inicio e fim do contrato de locação é de 5 anos.'
          );
          error = true;
        }

        // let base = new Date( approvedAt! ).setHours(0,0,0)
        // const compare = new Date(values.property.rent_insurance_contract_start_date);

        // const result = moment(compare).isSameOrAfter(base);
        // if (!result) {
        //   warningNotification(
        //     "Ops...",
        //     "A data de início do contrato deve ser maior que a data de Aprovação da Análise."
        //   );
        //   error = true;
        // }

        const base = new Date(approvedAt!).setHours(0, 0, 0);
        const compare = new Date(values.property.rent_insurance_contract_start_date);
        const result = moment(compare).isSameOrAfter(base);

        if (!result) {
          warningNotification(
            'Ops...',
            `A data de inicio da garantia precisa ser posterior da data de aprovação da análise. (${DateToStringDate(
              approvedAt!
            )})`
          );
          error = true;
        }
      }

      if (values.owner_data.public_document_id.length === 14) {
        if (!cpfValidity(values.owner_data.public_document_id)) {
          warningNotification('Ops. ', 'CPF inválido');
          setDocumentError(true);
          error = true;
        }
      } else {
        if (!cnpjValidity(values.owner_data.public_document_id)) {
          warningNotification('Ops. ', 'CNPJ inválido');
          setDocumentError(true);
          error = true;
        }
      }

      if (error) {
        return;
      }

      const payload = {
        bondInsuranceAnalysisId: analysisID,
        purpose: values.purpose,
        analysis_assurance: values.analysis_assurance,
        observations: values.observations,
        property: {
          reason: values.property.reason,
          zip_code: values.property.zip_code,
          street: values.property.street,
          district: values.property.district,
          city: values.property.city,
          complement: values.property.complement,
          state: values.property.state,
          number: values.property.number,
          rent_insurance_contract_start_date:
            values.property.rent_insurance_contract_start_date,
          rent_insurance_contract_end_date:
            values.property.rent_insurance_contract_end_date,
          rental_period_start_date: values.property.rental_period_start_date,
          rental_period_end_date: values.property.rental_period_end_date,
          rent_value: values.property.rent_value,
          type: values.property.type,
          condominium_fee: values.property.condominium_fee,
          taxes_value: values.property.taxes_value,
          energy_bill: values.property.energy_bill,
          water_bill: values.property.water_bill,
          gas_bill: values.property.gas_bill,
          validity: values?.property?.validity || 12
        },
        estate: {
          estateZipCode: values.estate.estateZipCode,
          estateStreet: values.estate.estateStreet,
          estateDistrict: values.estate.estateDistrict,
          estateCity: values.estate.estateCity,
          estateState: values.estate.estateState,
          estateNumber: values.estate.estateNumber,
          estateComplement: values.estate.estateComplement
        },
        owner_data: {
          public_document_id: values.owner_data.public_document_id,
          name: values.owner_data.name
        },
        hiredInstallments: values.hiredInstallments,
        hiredInstallmentsValue: nominal_total_premium_amount / maxInstallments,
        selectedPlan: {
          quantidadeParcela: offerSelected?.quantidadeParcela,
          valorParcela: offerSelected?.valorParcela,
          nomeSeguro: offerSelected?.planoSelecionado
            ? offerSelected?.planoSelecionado.toLowerCase()
            : offerSelected?.nomeSeguro.toLowerCase(),
          id: offerSelected?.id,
          lmi: offerSelected?.lmi,
          residentialFee: offerSelected?.residentialFee || 0,
          commercialFee: offerSelected?.commercialFee || 0
        }
      };
      callbackSubmit(payload);
    }
    flagSubmit && submitForm();
  }, [flagSubmit]);

  useEffect(() => {
    if (values.property.validity >= 12) {
      if (values.property.rental_period_start_date) {
        const endContractDate = addMonthToDate(
          new Date(values.property.rental_period_start_date),
          values.property.validity
        );
        setFieldValue(
          'property.rent_insurance_contract_end_date',
          new Date(endContractDate)
        );
        setFieldValue('property.rental_period_end_date', new Date(endContractDate));
      }
    }
  }, [setFieldValue, values.property.rental_period_start_date, values.property.validity]);

  useEffect(() => {
    const diff = monthDiff(
      new Date(values.property.rent_insurance_contract_start_date || Date.now()),
      new Date(
        values.property.rent_insurance_contract_end_date ||
          new Date().setMonth(new Date().getMonth() + maxInstallments)
      )
    );
    setFieldValue('hiredInstallments', diff);
  }, [
    maxInstallments,
    setFieldValue,
    values.property.rent_insurance_contract_end_date,
    values.property.rent_insurance_contract_start_date
  ]);

  const handleChangeOffer = (offer: HireProposalModalOffer) => {
    setOfferSelected(offer);
  };

  async function getOwnerByDocument(document: string) {
    try {
      setLoadDocument(true);
      const unformatedDoc = document.replace(/\D/g, '');
      const isCPF = unformatedDoc.length === 11;
      const data = await getDataByDocument(unformatedDoc);
      if (isCPF) {
        setDisabledNameField(true);
        setFieldValue('owner_data.name', data.name);
      } else {
        setDisabledNameField(false);
        setFieldValue('owner_data.name', data.company);
        setFieldValue('owner_data.phone', data.phone);
        setFieldValue('owner_data.email', data.email);
      }
    } catch (err) {
      console.log(err);
      setDisabledNameField(false);
    } finally {
      setLoadDocument(false);
    }
  }

  return (
    <div>
      <Grid columns="equal">
        <SectionLabel text="INFORMAÇÕES DA LOCAÇÃO GARANTTI" />
        <Grid.Row>
          <Grid.Column width={8}>
            <Radio
              label="Residencial"
              name={`purpose`}
              value={PurposesEnum.residential}
              checked={values.purpose === PurposesEnum.residential}
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <Radio
              label="Comercial"
              name={`bondInsurance.purpose`}
              value={PurposesEnum.commercial}
              checked={values.purpose === PurposesEnum.commercial}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel>MOTIVO DA LOCAÇÃO* </FieldLabel>
            <Dropdown
              name={`bondInsurance.reason`}
              value={values.property.reason}
              options={reasonsOptions}
              onChange={(e, { value }) => setFieldValue('property.reason', value)}
              floating
              search
              selection
              fluid
              placeholder="Selecionar..."
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <FieldLabel>TIPO DO IMÓVEL* </FieldLabel>
            <Dropdown
              name={`bondInsurance.propertyType`}
              value={values.property.type}
              options={hirePropertyTypeOptions}
              onChange={(e, { value }) => setFieldValue('property.type', value)}
              floating
              search
              selection
              fluid
              placeholder="Selecionar..."
            />
          </Grid.Column>
        </Grid.Row>

        <SectionLabel text="ENDEREÇO DO IMÓVEL" />
        <Grid.Row>
          <AddressWarningBanner />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>CEP*</FieldLabel>
            <Field
              name={`bondInsurance.zip_code`}
              value={values.property.zip_code}
              loading={cepLoading}
              onChange={(_: any, data: InputOnChangeData) => {
                onChangeCep(data.value, true);
                setFieldValue('property.zip_code', maskZipCode(data.value));
              }}
              component={Input}
              maxLength={9}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column width={11}>
            <FieldLabel>LOGRADOURO*</FieldLabel>
            <Field
              name={`bondInsurance.street`}
              value={values.property.street}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('property.street', data.value)
              }
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>BAIRRO*</FieldLabel>
            <Field
              name={`bondInsurance.district`}
              value={values.property.district}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('property.district', data.value)
              }
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <FieldLabel>CIDADE*</FieldLabel>
            <Field
              name={`bondInsurance.city`}
              value={values.property.city}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('property.city', data.value)
              }
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <FieldLabel>UF*</FieldLabel>
            <Field
              name={`bondInsurance.state`}
              value={values.property.state}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('property.state', data.value)
              }
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>NÚMERO*</FieldLabel>
            <Field
              name={`bondInsurance.number`}
              value={values.property.number}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('property.number', data.value)
              }
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>

          <GrayableGridColumn width={6}>
            <FieldLabel
              style={
                !noComplement
                  ? {
                      opacity: '1'
                    }
                  : {
                      opacity: '0.5'
                    }
              }
            >
              COMPLEMENTO*
            </FieldLabel>
            <Field
              name={`bondInsurance.complement`}
              value={values.property.complement}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('property.complement', data.value)
              }
              component={Input}
              fluid
              disabled={noComplement}
              style={
                !noComplement
                  ? {
                      opacity: '1'
                    }
                  : {
                      opacity: '0.5'
                    }
              }
            />
          </GrayableGridColumn>

          <Grid.Column width={5}>
            <Toggle
              style={{ height: '25%', flex: 'none' }}
              label={noComplement ? 'SEM COMPLEMENTO' : 'COM COMPLEMENTO'}
              checked={noComplement ? true : false}
              onChange={handleToggle}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <SectionLabel text="INFORMAÇÕES DO PROPRIETÁRIO" />
        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>CPF/CNPJ*</FieldLabel>
            <Field
              name={`owner_data.public_document_id`}
              value={maskCpfOrCnpj(values.owner_data.public_document_id)}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('owner_data.public_document_id', data.value)
              }
              onBlur={(e: { target: { value: string | any[] } }) => {
                const doc = e.target.value as string;
                if (doc.length === 14) {
                  if (!cpfValidity(doc)) {
                    warningNotification('Ops. ', 'CPF inválido');
                    setDocumentError(true);
                    setDisabledNameField(false);
                    return;
                  }
                  setDocumentError(false);
                  getOwnerByDocument(doc);
                } else {
                  if (!cnpjValidity(doc)) {
                    warningNotification('Ops. ', 'CNPJ inválido');
                    setDocumentError(true);
                    setDisabledNameField(false);
                    return;
                  }
                  setDocumentError(false);
                  getOwnerByDocument(doc);
                }
              }}
              component={Input}
              fluid
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <FieldLabel>NOME*</FieldLabel>
            <Field
              name={`owner_data.name`}
              value={values.owner_data.name}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('owner_data.name', data.value)
              }
              loading={loadDocument}
              disabled={disableNameField}
              component={Input}
              fluid
            />
          </Grid.Column>
        </Grid.Row>

        <SectionLabel text="ENDEREÇO DA IMOBILIARIA OU PROPRIETÁRIO" />
        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>CEP*</FieldLabel>
            <Field
              name={`bondInsurance.zip_code`}
              value={values.estate?.estateZipCode}
              loading={cepLoading}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateZipCode', maskZipCode(data.value))
              }
              component={Input}
              maxLength={9}
              fluid
            />
          </Grid.Column>

          <Grid.Column width={11}>
            <FieldLabel>LOGRADOURO*</FieldLabel>
            <Field
              name={`bondInsurance.street`}
              value={values.estate?.estateStreet}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateStreet', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>BAIRRO*</FieldLabel>
            <Field
              name={`bondInsurance.district`}
              value={values.estate?.estateDistrict}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateDistrict', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <FieldLabel>CIDADE*</FieldLabel>
            <Field
              name={`bondInsurance.city`}
              value={values.estate?.estateCity}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateCity', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <FieldLabel>UF*</FieldLabel>
            <Field
              name={`bondInsurance.state`}
              value={values.estate?.estateState}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateState', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>NÚMERO*</FieldLabel>
            <Field
              name={`bondInsurance.number`}
              value={values.estate?.estateNumber}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateNumber', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <FieldLabel>COMPLEMENTO</FieldLabel>
            <Field
              name={`bondInsurance.complement`}
              value={values.estate?.estateComplement}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateComplement', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <SectionLabel text="DADOS DO PROPONENTE (Dados do Locatário)" />
        {bondInsurance?.tenant_type === PersonTypesEnum.legal ? (
          <>
            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>CNPJ*</FieldLabel>
                <Field
                  name={`legalTenant.document`}
                  value={bondInsurance?.legal_tenant.document}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={8}>
                <FieldLabel>EMPRESA*</FieldLabel>
                <Field
                  name={`legalTenant.company`}
                  value={bondInsurance?.legal_tenant.company}
                  customInput={<Input />}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>TELEFONE*</FieldLabel>
                <Field
                  name={`legalTenant.phone`}
                  value={bondInsurance?.legal_tenant.phone}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>EMAIL*</FieldLabel>
                <Field
                  name={`legalTenant.email`}
                  value={bondInsurance?.legal_tenant.email}
                  customInput={<Input />}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>ÚLT. ALT. CONTRATUAL*</FieldLabel>
                <Field
                  name={`legalTenant.last_contractual_alteration_date`}
                  value={bondInsurance?.legal_tenant.last_contractual_alteration_date}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>CAPITAL SOCIAL*</FieldLabel>
                <Field
                  name={`legalTenant.share_capital`}
                  value={bondInsurance?.legal_tenant.share_capital}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>CAPITAL INTEGRALIZADO*</FieldLabel>
                <Field
                  name={`legalTenant.paid_in_capital`}
                  value={bondInsurance?.legal_tenant.paid_in_capital}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>FATURAMENTO ANUAL*</FieldLabel>
                <Field
                  name={`legalTenant.anual_revenue`}
                  value={bondInsurance?.legal_tenant.anual_revenue}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>CONTATO (NOME)*</FieldLabel>
                <Field
                  name={`legalTenant.contact_name`}
                  value={bondInsurance?.legal_tenant.contact_name}
                  customInput={<Input />}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>CONTATO (TELEFONE)*</FieldLabel>
                <Field
                  name={`legalTenant.contact_phone`}
                  value={bondInsurance?.legal_tenant.phone}
                  customInput={<Input />}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>
            </Grid.Row>
          </>
        ) : (
          <>
            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>CPF*</FieldLabel>
                <Field
                  name={`naturalPerson.main_tenant_cpf`}
                  value={bondInsurance?.natural_tenant?.main_tenant_cpf}
                  component={Input}
                  fluid
                  disabled
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>NOME DO LOCATÁRIO*</FieldLabel>
                <Field
                  autoCorrect="off"
                  autoCapitalize="none"
                  spellCheck="false"
                  autoComplete="new-password"
                  name={`naturalPerson.main_tenant_name`}
                  value={bondInsurance?.natural_tenant?.main_tenant_name}
                  customInput={<Input />}
                  component={Input}
                  fluid
                  disabled
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel paddingTop={true}>GÊNERO* </FieldLabel>
                <Field
                  name={`naturalPerson.main_tenant_gender`}
                  value={bondInsurance?.natural_tenant?.main_tenant_gender}
                  completeValue={true}
                  placeholder={'Selecionar...'}
                  options={genderOptions}
                  component={Dropdown}
                  fluid
                  disabled
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>DATA DE NASCIMENTO* </FieldLabel>
                <Field
                  name={`naturalPerson.main_tenant_birth_date`}
                  selected={getBirthDate()}
                  component={CustomDatePicker}
                  customInput={
                    <AppMaskedInput
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/
                      ]}
                    />
                  }
                  dateFormat={'dd/MM/yyyy'}
                  disabled
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>NOME DA MÃE*</FieldLabel>
                <Field
                  autoCorrect="off"
                  autoCapitalize="none"
                  spellCheck="false"
                  autoComplete="new-password"
                  name={`naturalPerson.main_tenant_mother_name`}
                  value={bondInsurance?.natural_tenant?.main_tenant_mother_name}
                  component={Input}
                  fluid
                  disabled
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>EMAIL*</FieldLabel>
                <Field
                  autoCorrect="off"
                  autoCapitalize="none"
                  spellCheck="false"
                  autoComplete="new-password"
                  name={`naturalPerson.main_tenant_email`}
                  value={bondInsurance?.natural_tenant?.main_tenant_email}
                  customInput={<Input />}
                  component={Input}
                  fluid
                  disabled
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>TELEFONE*</FieldLabel>
                <Field
                  name={`naturalPerson.main_tenant_phone`}
                  value={bondInsurance?.natural_tenant?.main_tenant_phone}
                  component={Input}
                  fluid
                  disabled
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel paddingTop={true}>ESTADO CIVIL* </FieldLabel>
                <Field
                  class={'dropdown'}
                  autoCorrect="off"
                  autoCapitalize="none"
                  spellCheck="false"
                  autoComplete="off"
                  search={false}
                  name={`naturalPerson.main_tenant_marital_status`}
                  value={bondInsurance?.natural_tenant?.main_tenant_marital_status}
                  completeValue={true}
                  placeholder={'Selecionar...'}
                  options={maritalStatusOptions}
                  component={Dropdown}
                  fluid
                  disabled
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>NACIONALIDADE*</FieldLabel>
                <Field
                  name={`naturalPerson.main_tenant_nationality`}
                  value={bondInsurance?.natural_tenant?.main_tenant_nationality}
                  component={Input}
                  fluid
                  disabled
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel paddingTop={true}>PESSOA POLITICAMENTE EXPOSTA? </FieldLabel>
                <Grid.Row>
                  <Grid.Column
                    width={5}
                    style={{
                      paddingTop: '10px'
                    }}
                  >
                    <Radio
                      style={{ marginRight: '1rem' }}
                      label="Sim"
                      name={`naturalPerson.main_tenant_politically_exposed_person`}
                      value={PoliticallyExposedPersonEnum.yes}
                      checked={
                        bondInsurance?.natural_tenant
                          ?.main_tenant_politically_exposed_person ===
                        PoliticallyExposedPersonEnum.yes
                      }
                      disabled
                    />

                    <Radio
                      style={{ marginRight: '1rem' }}
                      label="Não"
                      name={`naturalPerson.main_tenant_politically_exposed_person`}
                      value={PoliticallyExposedPersonEnum.no}
                      checked={
                        bondInsurance?.natural_tenant
                          ?.main_tenant_politically_exposed_person ===
                        PoliticallyExposedPersonEnum.no
                      }
                      disabled
                    />

                    <Radio
                      label="Próximo"
                      name={`naturalPerson.main_tenant_politically_exposed_person`}
                      value={PoliticallyExposedPersonEnum.closeTo}
                      checked={
                        bondInsurance?.natural_tenant
                          ?.main_tenant_politically_exposed_person ===
                        PoliticallyExposedPersonEnum.closeTo
                      }
                      disabled
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>

              {bondInsurance?.natural_tenant?.main_tenant_politically_exposed_person ===
                PoliticallyExposedPersonEnum.closeTo && (
                <>
                  <Grid.Column width={4}>
                    <FieldLabel>NOME DA PESSOA*</FieldLabel>
                    <Field
                      autoCorrect="off"
                      autoCapitalize="none"
                      spellCheck="false"
                      autoComplete="new-password"
                      name={`naturalPerson.main_tenant_close_to_politically_exposed_person.politically_exposed_person_name`}
                      customInput={<Input />}
                      component={Input}
                      fluid
                      disabled
                    />
                  </Grid.Column>

                  <Grid.Column width={4}>
                    <FieldLabel>CPF DA PESSOA*</FieldLabel>
                    <Field
                      name={`naturalPerson.main_tenant_close_to_politically_exposed_person.politically_exposed_person_cpf`}
                      value={
                        bondInsurance?.natural_tenant
                          ?.main_tenant_close_to_politically_exposed_person
                          ?.politically_exposed_person_cpf
                      }
                      customInput={<Input />}
                      component={Input}
                      fluid
                      disabled
                    />
                  </Grid.Column>

                  <Grid.Column width={4}>
                    <FieldLabel>GRAU DE PARENTESCO*</FieldLabel>
                    <Field
                      autoCorrect="off"
                      autoCapitalize="none"
                      spellCheck="false"
                      autoComplete="new-password"
                      name={`naturalPerson.main_tenant_close_to_politically_exposed_person.degree_of_kinship_to_politically_exposed_person`}
                      value={
                        bondInsurance?.natural_tenant
                          ?.main_tenant_close_to_politically_exposed_person
                          ?.degree_of_kinship_to_politically_exposed_person
                      }
                      customInput={<Input />}
                      component={Input}
                      fluid
                      disabled
                    />
                  </Grid.Column>
                </>
              )}
            </Grid.Row>

            {/* <Grid.Row>
              {bondInsurance?.natural_tenant?.main_tenant_marital_status ===
                "CASADO" && (
                  <Grid.Column width={4}>
                    <FieldLabel paddingTop={true}>
                      CÔNJUGE COMPÕE RENDA?{" "}
                    </FieldLabel>
                    <Grid.Row>
                      <Grid.Column width={5} style={{ paddingTop: "10px" }}>
                        <Radio
                          style={{ marginRight: "1rem" }}
                          label="Sim"
                          name={`naturalPerson.partner_rent_composition`}
                          checked={
                            bondInsurance?.natural_tenant
                              ?.partner_rent_composition
                          }
                          disabled
                        />
                        <Radio
                          style={{ marginRight: "1rem" }}
                          label="Não"
                          name={`naturalPerson.partner_rent_composition`}
                          checked={
                            !bondInsurance?.natural_tenant
                              ?.partner_rent_composition
                          }
                          disabled
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid.Column>
                )}
            </Grid.Row> */}
          </>
        )}
      </Grid>
      <Grid>
        <SectionLabel text="VIGÊNCIA DA LOCAÇÃO E DA GARANTIA" />
        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel>MESES DE VIGÊNCIA*</FieldLabel>
            <Dropdown
              name={`validity`}
              value={`${values.property.validity}`}
              options={(getMonthsOptions(12, 60) as CustomDropDownOption<any>[]) || []}
              onChange={(_, { value }) => setFieldValue('property.validity', value)}
              floating
              search
              selection
              fluid
              placeholder="Selecionar..."
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="equal">
          <Grid.Column>
            <FieldLabel>INICIO DA VIGÊNCIA DO CONTRATO DE LOCAÇÃO*</FieldLabel>
            <Field
              name={`rental_period_start_date`}
              selected={values.property.rental_period_start_date}
              component={CustomDatePicker}
              disabled={!values.property.validity || values.property.validity < 12}
              maxDate={new Date(new Date().setDate(new Date(approvedAt!).getDate() + 30))}
              customInput={
                <AppMaskedInput
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/
                  ]}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              onChange={(date: Date) => {
                setFieldValue('property.rental_period_start_date', date);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <FieldLabel>FIM DA VIGÊNCIA DO CONTRATO DE LOCAÇÃO*</FieldLabel>
            <Field
              name={`rental_period_end_date`}
              selected={values.property.rental_period_end_date}
              component={CustomDatePicker}
              disabled
              customInput={
                <AppMaskedInput
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/
                  ]}
                />
              }
              dateFormat={'dd/MM/yyyy'}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel>INICIO DA VIGÊNCIA*</FieldLabel>
            <Field
              name={`rent_insurance_contract_start_date`}
              selected={values.property.rent_insurance_contract_start_date}
              component={CustomDatePicker}
              disabled={!values.property.validity || values.property.validity < 12}
              minDate={new Date(approvedAt!)}
              maxDate={new Date(new Date().setDate(new Date(approvedAt!).getDate() + 30))}
              customInput={
                <AppMaskedInput
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/
                  ]}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              onChange={(date: Date) => {
                setFieldValue('property.rent_insurance_contract_start_date', date);
              }}
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <FieldLabel>FIM DA VIGÊNCIA* </FieldLabel>
            <Field
              name={`rent_insurance_contract_end_date`}
              selected={values.property.rent_insurance_contract_end_date}
              component={CustomDatePicker}
              disabled
              customInput={
                <AppMaskedInput
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/
                  ]}
                />
              }
              dateFormat={'dd/MM/yyyy'}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <p>
              {' '}
              Atenção: Informe as datas de início e fim da vigência, conforme o contrato
              de locação. Lembramos que a vigência mínima é de 12 meses.
            </p>
          </Grid.Column>
        </Grid.Row>
        <SectionLabel text="Valores a serem contratados" />
        <Grid.Row>
          <Grid.Column width={16}>
            <CardContainer>
              {offers?.map(offer => (
                <Card onClick={() => handleChangeOffer(offer)}>
                  <input
                    type="checkbox"
                    onChange={() => handleChangeOffer(offer)}
                    checked={offerSelected?.id === offer.id}
                  />
                  <span>{offer.nomeSeguro}</span>
                  <Icon
                    className="info-icon"
                    name="info circle"
                    title={getInfoIconText(offer)}
                  />
                  <ul>
                    <li>
                      LMI: <b>{offer.lmi}x</b>
                    </li>
                    <li>
                      Tx. Residencial: <b>{offer.residentialFee}%</b>
                    </li>
                    <li>
                      Tx. Comercial: <b>{offer.commercialFee}%</b>
                    </li>
                    <li>
                      Valor da parcela: <b>{numberToMoneyString(offer.valorParcela)}</b>
                    </li>
                    <li>
                      Prêmio Total:{' '}
                      <b>
                        {numberToMoneyString(
                          offer.valorParcela * offer.quantidadeParcela
                        )}
                      </b>
                    </li>
                  </ul>
                </Card>
              ))}
            </CardContainer>
            {!offerSelected && (
              <ErrorLabel>
                *Para prosseguir, selecione uma das opções a serem contratadas.
              </ErrorLabel>
            )}
          </Grid.Column>
        </Grid.Row>
        <SectionLabel text="PLANO DE COBERTURA" />
        <Grid.Column width={8}>
          <FieldLabel paddingTop={true}>
            GARANTIA DA ANÁLISE*
            <Popup
              trigger={<Icon name="help circle" style={{ color: '#364445' }} />}
              inverted
            >
              <Popup.Content>
                <List bulleted>
                  <List.Item>
                    <b>Plano Seguro Completo (Garantido)</b>
                    <List.List>
                      <List.Item>
                        Cobertura: Aluguel; Encargos declarados na análise; Danos ao
                        imóvel; Multa por rescisão contratual e Pintura.
                      </List.Item>
                    </List.List>
                  </List.Item>
                  <List.Item>
                    <b>Plano Seguro Simples (Básico)</b>
                    <List.List>
                      <List.Item>
                        Cobertura: Aluguel; Encargos declarados pela imobiliária.
                      </List.Item>
                      <List.Item>
                        Não possui cobertura: Danos ao imóvel; Pintura e Multa por
                        rescisão contratual.
                      </List.Item>
                    </List.List>
                  </List.Item>
                </List>
              </Popup.Content>
            </Popup>
          </FieldLabel>
          <Field
            name={`bondinsurance.analysis_assurance`}
            value={values.analysis_assurance}
            defaultValue={null}
            completeValue={true}
            placeholder={'Selecionar...'}
            disabled={true}
            options={analysisOptions}
            component={Dropdown}
            fluid
          />
        </Grid.Column>

        <Grid>
          <SectionLabel text="" />
          <Grid.Row>
            <Grid.Column width={4}>
              <FieldLabel>VALOR DO ALUGUEL</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.rent_value`}
                value={values.formattedValues.rent_value}
                component={Input}
                fluid
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <FieldLabel>VALOR DO CONDOMÍNIO</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.condominium_fee`}
                value={values.formattedValues.condominium_fee}
                component={Input}
                fluid
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <FieldLabel>VALOR DO IPTU</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.taxes_value`}
                value={values.formattedValues.taxes_value}
                component={Input}
                fluid
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={4}>
              <FieldLabel>VALOR DA ENERGIA</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.energy_bill`}
                value={values.formattedValues.energy_bill}
                component={Input}
                fluid
              />
            </Grid.Column>

            <Grid.Column width={4}>
              <FieldLabel>VALOR DA ÁGUA</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.water_bill`}
                value={values.formattedValues.water_bill}
                component={Input}
                fluid
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <FieldLabel>VALOR DO GÁS CANALIZADO</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.gas_bill`}
                value={values.formattedValues.gas_bill}
                component={Input}
                fluid
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {/* )} */}
        <SectionLabel text="Valores cotados" />
        <Grid.Row>
          <Grid.Column width={4}>
            <FieldLabel>Parcelas cotadas</FieldLabel>
            <Field value={values?.hiredInstallments} component={Input} fluid disabled />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Valor da parcela</FieldLabel>
            <Field
              value={offerSelected && formatAmount(offerSelected?.valorParcela * 100)}
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Prêmio total cotado</FieldLabel>
            <Field
              value={
                offerSelected &&
                formatAmount(
                  offerSelected?.valorParcela * offerSelected?.quantidadeParcela * 100
                )
              }
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>
        </Grid.Row>

        <SectionLabel text="Observações" />
        <Grid.Row>
          <Grid.Column width={16}>
            <Field
              name={`bondInsurance.observations`}
              rows={8}
              value={values.observations}
              onChange={(_: SyntheticEvent, data: any) => {
                setFieldValue(`observations`, data.value);
              }}
              component={FormatedTextArea}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
export default SurtyGeneralInsuranceForm;
