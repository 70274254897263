import { ClaimPropertyStatusEnum } from '../enums/claim-property-status.enum';
import { ClaimValuesTypesEnum } from '../pages/claim_estates/enums/ClaimValues.enum';
import { post, put } from './request';

type ClaimEstateDTO = {
  id?: string;
  status?: string;
  observations?: string;
  responsibleId: string | null;
  createdBy: string;
  insurerId?: string | null;
  estateId: string;
  bondInsuranceHiredData?: string;
  policyNumber: string | null;
  notificationEmails?: string[] | string;
};

type ClaimPropertyDTO = {
  id?: string;
  country: string;
  state: string;
  city: string;
  district: string;
  street: string;
  number: string;
  complement: string;
  zipCode: string;
  deliveryKeysDate?: string;
  status: ClaimPropertyStatusEnum;
};

type ClaimTenantDTO = {
  id?: string;
  document?: string;
  fullName: string;
  email: string;
  cellphone: string;
};

type ClaimValueDTO = {
  id?: string;
  dueDate: Date;
  value: number;
  totalInstallments?: number;
  numberInstallment?: number;
  valueType: ClaimValuesTypesEnum;
};

export type claimSubmitProps = {
  claimEstate: ClaimEstateDTO;
  claimProperty: ClaimPropertyDTO;
  claimTenant: ClaimTenantDTO;
  claimValues: ClaimValueDTO[];
  userId?: string;
};

export const createClaimEstate = async (claim: claimSubmitProps): Promise<any> => {
  const route = `/claims`;
  return await post(route, claim);
};

export const udateClaimEstate = async (
  id: string,
  claim: claimSubmitProps
): Promise<any> => {
  const route = `/claims/${id}`;
  return await put(route, claim);
};
