
export const CreateCancelationInitialInfo =
{
  cancelation: {
    cancellationReason: '',
    status: "CREATED",
    observations: "",
    estateId: "",
    insurerId: "",
    createdBy: "",
    policyNumber: null,
    notificationEmails: ['']
  },
  property: {
    zipCode: "",
    city: "",
    complement: "",
    district: "",
    number: "",
    state: "",
    street: ""
  },
  tenant: {
    document: "",
    fullName: "",
  },
  files: []
};
