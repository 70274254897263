import React, { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { Info } from '../../../components/Info';
import { DateToStringDate } from '../../../services/date';
import { maskCpfOrCnpj, maskMoney, maskPhoneNumber } from '../../../services/masks';
import { FieldLabel } from '../../../styles';
import { BondInsuranceForGetBondInsurancesResponseDTO } from "../../../dtos/bond-insurance-list/get-bond-insurances-response.dto";
import { getPoliticallyExposedPersonLabel } from "../../../services/bond-insurance";

export type Props = {
  bondInsurance: BondInsuranceForGetBondInsurancesResponseDTO;
};

export const BondInsuranceNaturalTenantDetails = (props: Props) => {

  const { natural_tenant } = props.bondInsurance;
  console.log(natural_tenant);

  return (

    <>
      <Grid.Row>
        <Info name={'NOME'} value={natural_tenant.main_tenant_name} width={4} />
        <Info name={'GÊNERO'} value={natural_tenant.main_tenant_gender} width={4} />
        <Info name={'DATA DE NASCIMENTO'} value={DateToStringDate(natural_tenant.main_tenant_birth_date)} width={4} />
        <Info name={'CPF'} value={maskCpfOrCnpj(natural_tenant.main_tenant_cpf)} width={4} />
      </Grid.Row>
      <Grid.Row>
        {/* <Info name={'RG'} value={natural_tenant.main_tenant_rg ? natural_tenant.main_tenant_rg : 'Indisponível'} width={4} />
        <Info name={'DATA DE EMISSÃO DO RG'} value={natural_tenant.main_tenant_rg_emission_date ? DateToStringDate(natural_tenant.main_tenant_rg_emission_date) : 'Indisponível'} width={4} />
        <Info name={'ÓRGÃO EXPEDITOR DO RG'} value={natural_tenant.main_tenant_rg_expeditor_organ ? natural_tenant.main_tenant_rg_expeditor_organ : 'Indisponível'} width={4} /> */}
        <Info name={'NACIONALIDADE'} value={natural_tenant.main_tenant_nationality ? natural_tenant.main_tenant_nationality : 'Indisponível'} width={4} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'E-MAIL'} value={natural_tenant.main_tenant_email} width={4} />
        <Info name={'TELEFONE'} value={maskPhoneNumber(natural_tenant.main_tenant_phone)} width={4} />
        <Info name={'NOME DA MÃE'} value={natural_tenant.main_tenant_mother_name} width={4} />
        <Info name={'ESTADO CIVIL'} value={natural_tenant.main_tenant_marital_status} width={4} />
      </Grid.Row>
      {/* <Grid.Row>
        <Info name={'CEP DO ENDEREÇO ATUAL'} value={natural_tenant.main_tenant_current_address_zipcode
          ? natural_tenant.main_tenant_current_address_zipcode : 'Indisponível'} width={4} />
      </Grid.Row> */}
      <Grid.Row>
        <Info name={'PESSOA POLITICAMENTE EXPOSTA'} value={getPoliticallyExposedPersonLabel(natural_tenant.main_tenant_politically_exposed_person)} width={8} />
        {
          natural_tenant.main_tenant_politically_exposed_person && natural_tenant.main_tenant_politically_exposed_person === "CLOSE_TO" &&
          <>
            <Info name={'NOME DA PESSOA POLITICAMENTE EXPOSTA'} value={natural_tenant.main_tenant_close_to_politically_exposed_person?.politically_exposed_person_name} width={8} />
          </>
        }
      </Grid.Row>
      {
        natural_tenant.main_tenant_politically_exposed_person && natural_tenant.main_tenant_politically_exposed_person === "CLOSE_TO" &&
        <>
          <Grid.Row>
            <Info name={'CPF DA PESSOA POLITICAMENTE EXPOSTA'} value={natural_tenant.main_tenant_close_to_politically_exposed_person?.politically_exposed_person_cpf ?
              maskCpfOrCnpj(natural_tenant.main_tenant_close_to_politically_exposed_person?.politically_exposed_person_cpf) : ''} width={8} />
            <Info name={'GRAU DE PARENTESCO DA PESSOA POLITICAMENTE EXPOSTA'} value={natural_tenant.main_tenant_close_to_politically_exposed_person?.degree_of_kinship_to_politically_exposed_person} width={8} />
          </Grid.Row>
        </>
      }
      <Grid.Row>
        <Info name={'CÔNJUGE COMPÕE RENDA'} value={natural_tenant.partner_rent_composition ? 'Sim' : 'Não'} width={4} />
        {natural_tenant.partner_rent_composition && <Info name={'NOME (CÔNJUGE)'} value={natural_tenant.partner_name} width={4} />}
        {natural_tenant.partner_rent_composition && <Info name={'CPF (CÔNJUGE)'} value={maskCpfOrCnpj(natural_tenant.partner_cpf)} width={4} />}
      </Grid.Row>
      <Grid.Row>
        <Info name={'VÍNCULO EMPREGATÍCIO'} value={natural_tenant.employment_relationship} width={4} />
        <Info name={'DATA DE ADMISSÃO'} value={natural_tenant.employment_admission_date ? DateToStringDate(natural_tenant.employment_admission_date) : ''} width={4} />
      </Grid.Row>
      <Grid.Row>
        {natural_tenant.main_tenant_profession && (<Info name={'PROFISSÃO'} value={natural_tenant.main_tenant_profession ? natural_tenant.main_tenant_profession : 'Indisponível'} width={4} />)}
        {natural_tenant.main_tenant_monthly_fixed_income && (<Info name={'RENDA MENSAL'} value={natural_tenant.main_tenant_monthly_fixed_income ?
          maskMoney(`${natural_tenant.main_tenant_monthly_fixed_income}`, true) : 'Indisponível'} width={4} />)}
        {natural_tenant.main_tenant_other_incomes && (<Info name={'OUTRAS RENDAS'} value={maskMoney(`${natural_tenant.main_tenant_other_incomes || 0}`, true)} width={4} />)}
      </Grid.Row>
      {!natural_tenant.resident && (
        <Grid.Row>
          <Info name={'RESIDIRÁ NO IMÓVEL?'} value={natural_tenant.resident ? 'Sim' : 'Não'} width={4} />
          <Info name={'NOME DO RESIDENTE'} value={natural_tenant.resident_name} width={4} />
          <Info name={'DATA DE NASC. DO RESIDENTE'} value={DateToStringDate(natural_tenant.resident_birth_date)} width={4} />
          <Info name={'CPF DO RESIDENTE'} value={maskCpfOrCnpj(natural_tenant.resident_cpf)} width={4} />
        </Grid.Row>
      )}
      {natural_tenant.include_sympathise_tenants ? natural_tenant.sympathise_tenants.map((tenant, index
      ) => {
        return (
          <>
            <Grid.Row style={{ paddingLeft: '15px' }}>
              <FieldLabel style={{ color: '#364445', marginTop: '0px' }}>{`${index + 1}º LOCATÁRIO SOLIDÁRIO`}</FieldLabel>
            </Grid.Row>

            <Grid.Row>
              <Info name={'NOME'} value={tenant.name} width={4} />
              <Info name={'DATA DE NASCIMENTO'} value={tenant.birth_date ? DateToStringDate(new Date(tenant.birth_date)) : 'Indisponível'} width={4} />
              <Info name={'CPF'} value={maskCpfOrCnpj(tenant.cpf)} width={4} />
              <Info name={'RG'} value={tenant.rg ? tenant.rg : 'Indisponível'} width={4} />
            </Grid.Row>

            <Grid.Row>
              <Info name={'VÍNCULO EMPREGATÍCIO'} value={tenant.employment_relationship} width={4} />
              <Info name={'PROFISSÃO'} value={tenant.profession ? tenant.profession : 'Indisponível'} width={4} />
              <Info name={'RENDA MENSAL'} value={tenant.monthly_fixed_income ?
                maskMoney(`${tenant.monthly_fixed_income}`, true) : 'Indisponível'} width={4} />
              <Info name={'OUTRAS RENDAS'} value={maskMoney(`${tenant.other_incomes || 0}`, true)} width={4} />
            </Grid.Row>

            <Grid.Row>
              <Info name={'PESSOA POLITICAMENTE EXPOSTA'} value={getPoliticallyExposedPersonLabel(tenant.politically_exposed_person)} width={8} />
              {
                tenant.politically_exposed_person && tenant.politically_exposed_person === "CLOSE_TO" &&
                <>
                  <Info name={'NOME DA PESSOA POLITICAMENTE EXPOSTA'} value={tenant.close_to_politically_exposed_person?.politically_exposed_person_name} width={8} />
                </>
              }
            </Grid.Row>
            {
              tenant.politically_exposed_person && tenant.politically_exposed_person === "CLOSE_TO" &&
              <>
                <Grid.Row>
                  <Info name={'CPF DA PESSOA POLITICAMENTE EXPOSTA'} value={tenant.close_to_politically_exposed_person?.politically_exposed_person_cpf ? maskCpfOrCnpj(tenant.close_to_politically_exposed_person?.politically_exposed_person_cpf) : ''} width={8} />
                  <Info name={'GRAU DE PARENTESCO DA PESSOA POLITICAMENTE EXPOSTA'} value={tenant.close_to_politically_exposed_person?.degree_of_kinship_to_politically_exposed_person} width={8} />
                </Grid.Row>
              </>
            }

            <Grid.Row>
              <Info name={'NOME'} value={tenant.name} width={4} />
              <Info name={'CPF'} value={maskCpfOrCnpj(tenant.cpf)} width={4} />
              <Info name={'VÍNCULO'} value={tenant.employment_relationship} width={4} />
            </Grid.Row>
          </>
        )
      }) : null}

      {natural_tenant.partners && natural_tenant.partners.map((partner, index
      ) => {
        return (
          <>
            <Grid.Row style={{ paddingLeft: '15px' }}>
              <FieldLabel style={{ color: '#364445', marginTop: '0px' }}>
                {`${index + 1}º SÓCIO`}
              </FieldLabel>
            </Grid.Row>

            <Grid.Row>
              <Info name={'NOME'} value={partner.name} width={4} />
              <Info name={'CPF'} value={maskCpfOrCnpj(partner.document)} width={4} />
              <Info name={'RENDA'} value={partner.percent ? `${partner.percent} %` : 'NÃO INFORMADO'} width={4} />
              <Info name={'DATA DE ENTRADA'} value={DateToStringDate(partner.date_of_entry)} width={4} />
            </Grid.Row>
          </>
        )
      })}
    </>
  )
}
