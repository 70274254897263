import { Button, Checkbox, Grid, Icon, Input, InputOnChangeData, Modal } from 'semantic-ui-react';
import {
  ChannelToggleContent,
  CoverageRow,
  CoverageTitleRow,
  FireInsurersRow,
  InsurerCard,
  InsurerCardContent,
  InsurerCardContentTitle
} from '../../../styles';
import { useEffect, useState } from 'react';
import { get } from '../../../../../services/request';
import React from 'react';
import Toggle from '../../../../../components/Toggle';
import { Field } from 'formik';
import { formatAmount } from '../../../../../services/masks';
import { AlfaFireInsuranceConfigProps } from '..';
import Loader from '../../../../../components/Loader';

export enum ModalitiesEnum {
  Residencial = 'Residencial',
  Comercial = 'Comercial'
};

interface ChannelInterface {
  id: string;
  name: string;
  code: string;
  modality: ModalitiesEnum;
  selected: boolean;
  active: boolean;
};

interface CoverageInterface {
  id: string;
  insurerId: string;
  channelId: string;
  name: string;
  code: string;
  maxValue: string;
  minValue: string;
  selectedMinValue: string;
  selectedMaxValue: string;
  multiplier: number;
  maxLmi: number;
  minLmi: number;
  selected: boolean;
  value: string;
  isDefault: boolean;
  isRequired: boolean;
};

const CoveragesConfigModal = ({ values, ...props }: AlfaFireInsuranceConfigProps) => {
  const [channels, setChannels] = useState<ChannelInterface[]>([]);
  const [coverages, setCoverages] = useState<CoverageInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchChannels = async () => {
      setIsLoading(true);

      const response = await get('/alfa-seguros/channels');

      if (response.length > 0) {
        const data = response.map((channel: ChannelInterface) => {
          return {
            ...channel,
            selected: true,
            active: false
          };
        });

        setChannels(data);
        props.setFieldValue(`insurerSpecificData.alfa.fireInsurance.channels`, data);
      }

      setIsLoading(false);
    };

    const fetchCoverages = async () => {
      setIsLoading(true);

      const response = await get('/alfa-seguros/coverage');

      if (response.length > 0) {
        const data = response.map((coverage: CoverageInterface) => {
          const isFireCoverage = coverage.name.includes('Incêndio');
          const isRentCoverage = coverage.name.includes('Aluguel');

          if (values.insurerSpecificData?.alfa?.fireInsurance?.coverages?.length > 0) {
            const selectedCoverage =
              values.insurerSpecificData?.alfa?.fireInsurance?.coverages?.find((selectedCoverage: any) =>
              selectedCoverage.id === coverage.id);

            if (selectedCoverage) {
              return {
                ...coverage,
                selected: selectedCoverage.enabled,
                isRequired: selectedCoverage.isRequired,
                selectedMinValue: formatAmount(Number(selectedCoverage.minValue).toFixed(2) || ''),
                selectedMaxValue: formatAmount(Number(selectedCoverage.maxValue).toFixed(2) || ''),
                multiplier: selectedCoverage.multiplier,
              };
            }
          }

          return {
            ...coverage,
            selected: true,
            isRequired: (isFireCoverage || isRentCoverage) ? true : false,
            selectedMinValue: formatAmount(Number(coverage.minValue).toFixed(2) || ''),
            selectedMaxValue: formatAmount(Number(coverage.maxValue).toFixed(2) || ''),
          };
        });

        setCoverages(data);
        props.setFieldValue(`insurerSpecificData.alfa.fireInsurance.coverages`, data);
      }

      setIsLoading(false);
    };

    fetchChannels();
    fetchCoverages();
  }, []);

  useEffect(() => {
    values.insurerSpecificData?.alfa?.fireInsurance?.channels?.forEach((channel: any) => {
      const channelIndex = channels.findIndex((c: any) => c.id === channel.id);
      props.setFieldValue(
        `insurerSpecificData.alfa.fireInsurance.channels[${channelIndex}]`,
        channels[channelIndex]
      );
    });
  }, [channels]);

  useEffect(() => {
    values.insurerSpecificData?.alfa?.fireInsurance?.coverages?.forEach((coverage: any) => {
      const coverageIndex = coverages.findIndex((c: any) => c.id === coverage.id);

      props.setFieldValue(
        `insurerSpecificData.alfa.fireInsurance.coverages[${coverageIndex}]`,
        coverages[coverageIndex]
      );
    });
  }, [coverages]);

  return (
    <Modal
      style={{ width: '1300px' }}
      trigger={
        <Button color="blue" fluid type="button" size={'large'}
          disabled={isLoading || !values.insurerSpecificData?.alfa?.fireInsurance?.plans?.length}
        >
          Gerenciar Coberturas
        </Button>
      }
    >
      <Modal.Header>
        <h3>
          <Icon name="shield" />
          Configurar coberturas
        </h3>
      </Modal.Header>

      <Modal.Content>
        <Loader active={isLoading} />
        <FireInsurersRow>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <h4 style={{ marginBottom: '10px' }}>CANAIS/MODALIDADES DISPONÍVEIS</h4>
            {values.insurerSpecificData?.alfa?.fireInsurance?.channels?.filter(
              (channel: any) =>  values.insurerSpecificData?.alfa?.fireInsurance?.plans?.find((g: any) => g.channelId === channel.id)
            )?.map(
              (channel: any) => {
                return (
                  <InsurerCard
                    key={channel.id}
                    isSelected={channel.active}
                    onClick={() =>
                      setChannels(
                        channels.map(c => {
                          if (c.id === channel.id) {
                            c.active = true;
                          } else {
                            c.active = false;
                          }
                          return c;
                        })
                      )
                    }
                  >
                    <InsurerCardContent>
                      <InsurerCardContentTitle>{channel.name}</InsurerCardContentTitle>
                    </InsurerCardContent>
                    {/* <ChannelToggleContent> */}
                     {
                     /*<Toggle
                        name={channel.id}
                        id={`channel-${channel.id}`}
                        checked={channel.selected}
                        onChange={
                          () =>
                            setChannels(
                              channels.map(c => {
                                if (c.id === channel.id) {
                                  c.selected = !c.selected;
                                }
                                return c;
                              })
                            )
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      />*/}
                    {/* </ChannelToggleContent> */}
                  </InsurerCard>
                );
              }
            )}
          </Grid.Column>

          {coverages.filter(cov => channels.find(c => c.active)?.id === cov.channelId).length > 0 && (
            <Grid.Column
              style={{ marginLeft: '40px' }}
              mobile={16}
              tablet={8}
              computer={8}
            >
              <CoverageTitleRow>
                <Grid.Column style={{ minWidth: '350px' }}>
                  <span>
                    <h4>TIPO</h4>
                  </span>
                </Grid.Column>

                <Grid.Column>
                  <span>
                    <h4 style={{ minWidth: '145px' }}>VALOR MÍNIMO (R$)</h4>
                  </span>
                </Grid.Column>

                <Grid.Column>
                  <span>
                    <h4 style={{ minWidth: '145px' }}>VALOR MÁXIMO (R$)</h4>
                  </span>
                </Grid.Column>

                <Grid.Column>
                  <span>
                    <h4 style={{ minWidth: '145px' }}>MULTIPLICADOR</h4>
                  </span>
                </Grid.Column>

                <Grid.Column>
                  <span>
                    <h4 style={{ minWidth: '145px' }}>OBRIGATÓRIO</h4>
                  </span>
                </Grid.Column>
              </CoverageTitleRow>

              {coverages.filter(cov => channels.find(c => c.active)?.id === cov.channelId).map(coverage => {
                const isFireCoverage = coverage.name.includes('Incêndio');

                return (
                  <CoverageRow key={coverage.id} isSelected={coverage.selected}>
                    <InsurerCardContent style={{ minWidth: '350px' }}>
                      <Toggle
                        name={coverage.id}
                        id={`coverage-${coverage.id}`}
                        checked={coverage?.selected || false}
                        onChange={() =>
                          setCoverages(
                            coverages.map(c => {
                              if (c.id === coverage.id) {
                                c.selected = !c.selected;
                              }
                              return c;
                            })
                          )
                        }
                        label={<label><InsurerCardContentTitle>{coverage.name}</InsurerCardContentTitle></label>}
                      />
                    </InsurerCardContent>

                    <Grid.Column>
                      <Field
                        style={{ minWidth: '145px' }}
                        name={`insurerSpecificData.alfa.fireInsurance.coverages.${coverage.id}.minValue`}
                        value={coverage?.selectedMinValue || ''}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                          data: InputOnChangeData
                        ) => {
                          data.value = formatAmount(data.value);
                          setCoverages(
                            coverages.map(c => {
                              if (c.id === coverage.id) {
                                c.selectedMinValue = data.value;
                              }
                              return c;
                            })
                          );
                        }}
                        component={Input}
                        fluid
                      />
                    </Grid.Column>

                    <Grid.Column style={{ verticalAlign: 'middle' }}>
                      <Field
                        style={{ minWidth: '145px', marginLeft: '5px' }}
                        name={`insurerSpecificData.alfa.fireInsurance.coverages.${coverage.id}.maxValue`}
                        value={coverage?.selectedMaxValue || ''}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                          data: InputOnChangeData
                        ) => {
                          data.value = formatAmount(data.value);
                          setCoverages(
                            coverages.map(c => {
                              if (c.id === coverage.id) {
                                c.selectedMaxValue = data.value;
                              }
                              return c;
                            })
                          );
                        }}
                        component={Input}
                        fluid
                      />
                    </Grid.Column>

                    <Grid.Column>
                      <Field
                        style={{ minWidth: '145px', marginLeft: '5px' }}
                        name={`insurerSpecificData.alfa.fireInsurance.coverages.${coverage.id}.multiplier`}
                        value={coverage?.multiplier || ''}
                        type="number"
                        min="1"
                        max={coverage?.name.includes('Aluguel') ? 6 : null}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                          data: InputOnChangeData
                        ) => {
                          setCoverages(
                            coverages.map(c => {
                              if (c.id === coverage.id) {
                                c.multiplier = Number(data.value);
                              }
                              return c;
                            })
                          );
                        }}
                        component={Input}
                        fluid
                      />
                    </Grid.Column>

                    <Grid.Column style={{ minWidth: '145px', marginLeft: '5px' }}>
                      <Checkbox
                        fluid
                        name={`insurerSpecificData.alfa.fireInsurance.coverages.${coverage.id}.isRequired`}
                        checked={coverage.isRequired}
                        onChange={() => {
                          setCoverages(
                            coverages.map(c => {
                              if (c.id === coverage.id) {
                                c.isRequired = !coverage.isRequired;
                              }
                              return c;
                            })
                          );
                        }}
                        disabled={isFireCoverage}
                      />
                    </Grid.Column>
                  </CoverageRow>
                );
              })}
            </Grid.Column>
          )}
        </FireInsurersRow>
      </Modal.Content>
    </Modal>
  );
};

export default CoveragesConfigModal;
