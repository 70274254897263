import {FormikReducer, FormikState, FormikStateActions} from "../types/temp-types";

type FormikRematchModel = {
  state: FormikState;
  reducers: FormikReducer;
}

const initialState: FormikState = {
  actions: {
    setFieldTouched: null,
    setFieldValue: null,
    handleBlur: null
    }
};

const formik: FormikRematchModel = {
  state: initialState,
  reducers: {
    setFormikActions: (state: FormikState, payload: Partial<FormikStateActions>): FormikState => {
      return {
        ...state,
        actions: {
          ...state.actions,
          ...payload
        }
      }
    }
  }
};

export default formik;
