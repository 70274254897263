import { iRootState, iRootDispatch } from '../../store';
import { Formik, FormikProps, withFormik } from 'formik';
import { estateFormInitialValues, EstateFormValues } from './types';
import EstateFormContent from './EstateFormContent';
import { createEstateSchema } from './validators';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { post } from '../../services/request';
import { dangerNotification, successNotification } from '../../services/notification';
import {
  CreateEstateRequestDTO,
  EstateInsurerDataForCreateEstateRequestDTO
} from '../../dtos/estate/create-estate-request.dto';
import { unmaskCpfOrCnpj, unmaskMoney } from '../../services/masks';
import { useHistory } from 'react-router-dom';
import { InsurersCodeEnum } from '../../enums/insurers-code.enum';
import { EstatesToCustomDropDownOptions, cnpjValidity, cpfValidity } from '../../util';
import { AxiosError } from 'axios';
import { getPreRegisterEstate } from '../../services/estate';
import { get, remove } from '../../services/storage';
import { isValidEmail } from '../../utils/emailValidator';
import { getEstates, getEstatesObjectsFromGetEstatesDTO } from '../../services/broker';
import { AvailableBrokersAndEstatesState } from '../../store/types/temp-types';
import { create as createPartner } from '../../services/estate-partner';
import { ResponsibleCompanyEnum } from '../../enums/responsible-company.enum';

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  setLoading: (loading: boolean) => dispatch.loading.setLoading(loading),
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

export type EstateFormProps = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch> &
  FormikProps<any> & {
    handleInputChange: Function;
    onSubmit: Function;
    isLoading: boolean;
  };

const EstateForm: React.FC<EstateFormProps> = (props: EstateFormProps) => {
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { user } = props;

  const [preRegisterId, setPreRegisterId] = useState<string>();

  useEffect(() => {
    const id = get('preRegisterId');
    if (id) {
      setPreRegisterId(id);
    } else {
      estateFormInitialValues.estate_city = '';
      estateFormInitialValues.estate_complement = '';
      estateFormInitialValues.estate_district = '';
      estateFormInitialValues.estate_number = '';
      estateFormInitialValues.estate_state = '';
      estateFormInitialValues.estate_street = '';
      estateFormInitialValues.estate_zipcode = '';
      estateFormInitialValues.responsible_name = '';
      estateFormInitialValues.responsible_email = '';
      estateFormInitialValues.responsible_phone = '';
      estateFormInitialValues.email = '';
      estateFormInitialValues.name = '';
      estateFormInitialValues.cnpj = '';
      estateFormInitialValues.observations = '';
      estateFormInitialValues.responsible_company = ResponsibleCompanyEnum.confiax;
      delete estateFormInitialValues.preRegisterId;
    }
  }, []);

  const getPreRegister = async (id: string) => {
    if (id) {
      getPreRegisterEstate(id)
        .then(data => {
          if (data) {
            estateFormInitialValues.estate_city = data.city;
            estateFormInitialValues.estate_complement = data.complement;
            estateFormInitialValues.estate_district = data.district;
            estateFormInitialValues.estate_number = data.number;
            estateFormInitialValues.estate_state = data.state;
            estateFormInitialValues.estate_street = data.street;
            estateFormInitialValues.estate_zipcode = data.zipcode;
            estateFormInitialValues.responsible_name = data.responsible_name;
            estateFormInitialValues.responsible_email = data.responsible_email;
            estateFormInitialValues.responsible_phone = data.responsible_phone;
            estateFormInitialValues.email = data.email;
            estateFormInitialValues.name = data.name;
            estateFormInitialValues.cnpj = data.document;
            estateFormInitialValues.preRegisterId = id;
            estateFormInitialValues.franchiseId = data.franchise_id;
            estateFormInitialValues.observations = data.observations;
          }
        })
        .catch(error => {
          dangerNotification(
            'Opss..',
            'Ocorreu um erro ao buscar os dados de pré-cadastro.'
          );
          console.log('ERRO: ', error);
        })
        .finally(() => {
          setPreRegisterId(undefined);
          remove('preRegisterId');
        });
    }
  };

  useEffect(() => {
    if (preRegisterId) getPreRegister(preRegisterId);
  }, [preRegisterId]);

  const getPayloadFormData = (values: EstateFormValues) => {
    const { bankAccount } = values;

    const payload: CreateEstateRequestDTO = {
      has_preference_bond_insurance: values.hasPreferenceBondInsurance || false,
      name: values.name,
      email: values.email.trim().toLowerCase(),
      cnpj: unmaskCpfOrCnpj(values.cnpj),
      creci: values.creci ? values.creci : undefined,
      franchiseId: values.franchiseId ? values.franchiseId : undefined,
      preRegisterId: values.preRegisterId ? values.preRegisterId : undefined,
      company_name: values.company_name,
      responsible_company: values.responsible_company,
      additional_emails: values.additional_emails?.trim().toLowerCase(),
      emails: values.emails,
      broker_id: values.broker.id,
      user_id: values.estateAnalystUser.id,
      additional_account_manager: values.additionalAccountManager
        ? values.additionalAccountManager.id
        : null,
      claimResponsible: values.claimResponsible ? values.claimResponsible.id : null,
      bank_code: bankAccount.bankCode,
      agency_number: bankAccount.agencyNumber,
      agency_dv: bankAccount.agencyDv,
      account_number: bankAccount.accountNumber,
      account_dv: bankAccount.accountDv,
      estate_zipcode: values.estate_zipcode,
      estate_street: values.estate_street,
      estate_number: values.estate_number,
      estate_district: values.estate_district,
      estate_complement: values.estate_complement,
      estate_city: values.estate_city,
      estate_state: values.estate_state,
      financial_email: values.financial_email?.trim().toLowerCase(),
      financial_responsible: values.financial_responsible,
      responsible_name: values.responsible_name ? values.responsible_name : undefined,
      responsible_email: values.responsible_email
        ? values.responsible_email.trim().toLowerCase()
        : undefined,
      responsible_phone: values.responsible_phone ? values.responsible_phone : undefined,
      contact_source: values.contact_source,
      status: values.status,
      porto_code: values.portoCode,
      monthly_tenantry_capacity: values.monthlyTenantryCapacity
        ? Number(values.monthlyTenantryCapacity)
        : 0,
      average_properties_wallet: values.averagePropertiesWallet
        ? Number(values.averagePropertiesWallet)
        : 0,
      insurers: values.bondInsurers.map(insurer => {
        return {
          id: insurer.id,
          code: insurer.code,
          name: insurer.name
        };
      }),
      fire_insurers: values.fireInsurers.map(insurer => {
        return {
          id: insurer.id,
          code: insurer.code,
          name: insurer.name
        };
      }),
      capitalizations: values.fireInsurers.map(capitalization => {
        return {
          id: capitalization.id,
          code: capitalization.code,
          name: capitalization.name
        };
      }),
      createdBy: user.id,
      partners:
        values?.partners?.map(partner => ({
          id: partner?.id,
          name: partner.name,
          email: partner.email,
          document: unmaskCpfOrCnpj(partner.document)
        })) || []
    };

    if (!values.broker.id) {
      dangerNotification('Corretora', 'O campo Corretora não foi preenchido.');
      return;
    }

    if (!values.email) {
      dangerNotification(
        'E-mail Principal',
        'O campo E-mail Principal não foi preenchido.'
      );
      return;
    }

    if (values.isPortoTradicionalCapitalizationSelected && !values.portoCode) {
      dangerNotification(
        'Código da Seguradora',
        'O campo Código da Seguradora não foi preenchido'
      );
      return;
    }

    if (!isValidEmail(payload.email)) {
      dangerNotification(
        'E-mail Principal',
        'O campo E-mail Principal é inválido, verifique e tente novamente.'
      );
      return;
    }

    if (!payload.financial_email || !isValidEmail(payload.financial_email)) {
      dangerNotification(
        'E-mail Financeiro',
        'O campo E-mail do responsável financeiro é inválido, verifique e tente novamente.'
      );
      return;
    }

    if (!payload.responsible_email || !isValidEmail(payload.responsible_email)) {
      dangerNotification(
        'E-mail Responsável',
        'O campo E-mail do responsável é inválido, verifique e tente novamente.'
      );
      return;
    }

    if (payload.additional_emails) {
      const additionalEmails = payload.additional_emails.split(';');
      for (let i = 0; i < additionalEmails.length; i++) {
        if (!isValidEmail(additionalEmails[i]) && additionalEmails[i].length !== 0) {
          dangerNotification(
            'E-mail Adicional',
            "O campo E-mail Adicional '" +
              additionalEmails[i] +
              "' é inválido, verifique e tente novamente."
          );
          return;
        }
      }
    }

    if (!values.estateAnalystUser.id) {
      dangerNotification(
        'Analista Responsável',
        'O campo Analista Responsável não foi preenchido.'
      );
      return;
    }
    if (!values.name) {
      dangerNotification(
        'Nome da Imobiliária',
        'O campo Nome da Imobiliária não foi preenchido.'
      );
      return;
    }
    if (!values.company_name) {
      dangerNotification('Razão Social', 'O campo Razão Social não foi preenchido.');
      return;
    }
    if (!values.cnpj) {
      dangerNotification('CNPJ', 'O campo CNPJ não foi preenchido.');
      return;
    }
    if (unmaskCpfOrCnpj(values.cnpj).length === 11 && !values.creci) {
      dangerNotification('CRECI', 'O campo CRECI não foi preenchido.');
      return;
    }
    if (!values.cnpj) {
      dangerNotification('Nome Fantasia', 'O campo Nome Fantasia não foi preenchido.');
      return;
    }
    if (!values.contact_source) {
      dangerNotification(
        'Origem do Contato',
        'O campo Origem do Contato não foi preenchido'
      );
      return;
    }
    if (!values.financial_responsible) {
      dangerNotification(
        'Responsável Financeiro',
        'O campo Responsável Financeiro não foi preenchido'
      );
      return;
    }
    if (!values.financial_email) {
      dangerNotification(
        'Email do Financeiro',
        'O campo Email do Financeiro não foi preenchido'
      );
      return;
    }

    if (!values.averagePropertiesWallet) {
      dangerNotification(
        'Média de Imóveis na Carteira',
        'O campo Média de Imóveis na Carteira não foi preenchido'
      );
      return;
    } else if (values.averagePropertiesWallet === 0) {
      dangerNotification(
        'Média de Imóveis na Carteira',
        'O campo Média de Imóveis na Carteira não pode ser igual a 0!'
      );
      return;
    }

    if (!values.estate_zipcode) {
      dangerNotification('CEP', 'O campo CEP não foi preenchido');
      return;
    }
    if (!values.estate_street) {
      dangerNotification('Logradouro', 'O campo Logradouro não foi preenchido');
      return;
    }
    if (!values.estate_district) {
      dangerNotification('Bairro', 'O campo Bairro não foi preenchido');
      return;
    }
    if (!values.estate_city) {
      dangerNotification('Cidade', 'O campo Cidade não foi preenchido');
      return;
    }
    if (!values.estate_number) {
      dangerNotification('Número', 'O campo Número no Endereço não foi preenchido');
      return;
    }
    if (!values.estate_state) {
      dangerNotification('Estado', 'O campo Estado não foi preenchido');
      return;
    }

    if (
      values.isPottencialBondInsuranceSelected &&
      !values.insurerSpecificData.pottencial.bondInsurance.multiple
    ) {
      dangerNotification(
        'Multiplo Pottencial',
        'O campo Multiplo Pottencial não foi preenchido.'
      );
      return;
    }

    if (
      values.isPottencialBondInsuranceSelected &&
      !values.insurerSpecificData.pottencial.bondInsurance.assurance
    ) {
      dangerNotification(
        'Cobertura Pottencial',
        'O campo Tipo de Coberturas - Pottencial não foi preenchido.'
      );
      return;
    }

    if (values.isPortoEssencialBondInsuranceSelected) {
      if (!values.portoCode) {
        dangerNotification(
          'Código Porto',
          'O Código da Seguradora Porto não foi preenchido. Campo obrigatório para Porto Essencial.'
        );
        return;
      } else {
        if (values.portoCode.length !== 6) {
          dangerNotification(
            'Código Porto',
            'O Código da Seguradora Porto deve ter 6 dígitos. Campo obrigatório para Porto Essencial.'
          );
          return;
        }
      }
    }

    if (!bankAccount.bankCode) {
      dangerNotification('Banco', 'O campo Banco não foi preenchido.');
      return;
    }
    if (!bankAccount.agencyNumber) {
      dangerNotification('Agência', 'O campo Agência não foi preenchido.');
      return;
    }
    if (!bankAccount.accountNumber) {
      dangerNotification('Conta', 'O campo Conta não foi preenchido.');
      return;
    }

    if (values.estateAnalystUser.id === values.additionalAccountManager?.id) {
      dangerNotification(
        'Analista Responsável (Adicional)',
        'O analista responsável adicional não pode ser o mesmo analista responsável principal.'
      );
      return;
    }

    if (unmaskCpfOrCnpj(values.cnpj).length === 11) {
      if (!cpfValidity(values.cnpj)) {
        dangerNotification('Ops. ', 'CPF inválido');
        return;
      }
    } else {
      if (!cnpjValidity(values.cnpj)) {
        dangerNotification('Ops. ', 'CNPJ inválido');
        return;
      }
    }

    const insurerSpecificData: EstateInsurerDataForCreateEstateRequestDTO[] = [];

    if (values.isLibertyFireInsuranceSelected) {
      const libertyInsurer = values.fireInsurers.find(insurer => {
        return insurer.code === InsurersCodeEnum.Liberty;
      });

      if (libertyInsurer) {
        const offerLetter: string | undefined = values.insurerSpecificData.liberty
          .fireInsurance.offerLetter
          ? values.insurerSpecificData.liberty.fireInsurance.offerLetter
          : undefined;
        insurerSpecificData.push({
          insurerId: libertyInsurer.id,
          insuranceType: 'FIRE_INSURANCE',
          offerLetter
        });
      }
    }

    if (values.isPottencialFireInsuranceSelected) {
      const pottencialInsurer = values.fireInsurers.find(insurer => {
        return insurer.code === InsurersCodeEnum.Pottencial;
      });

      if (pottencialInsurer) {
        const commissionPercentage: number | undefined = values.insurerSpecificData
          .pottencial.fireInsurance.commissionPercentage
          ? values.insurerSpecificData.pottencial.fireInsurance.commissionPercentage
          : undefined;

        const multiplier: number | undefined = values.insurerSpecificData.pottencial
          .fireInsurance.multiplier
          ? values.insurerSpecificData.pottencial.fireInsurance.multiplier
          : undefined;

        insurerSpecificData.push({
          insurerId: pottencialInsurer.id,
          insuranceType: 'FIRE_INSURANCE',
          commissionPercentage: commissionPercentage,
          multiplier: multiplier
        });
      }
    }

    if (values.isTooSegurosBondInsuranceSelected) {
      if (!values.insurerSpecificData.tooSeguros.bondInsurance.commissionPercentage) {
        dangerNotification(
          'Too Seguros',
          'Porcentagem da comissão não preenchida. Favor inserir um número válido.'
        );
        return;
      }

      const tooSegurosInsurer = values.bondInsurers.find(insurer => {
        return insurer.code === InsurersCodeEnum.Too;
      });

      if (tooSegurosInsurer) {
        const pfResidentialTax: number | undefined =
          values.insurerSpecificData.tooSeguros.bondInsurance.pfResidentialTax &&
          values.insurerSpecificData.tooSeguros.bondInsurance.pfResidentialTax !== '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.tooSeguros.bondInsurance.pfResidentialTax
              ) / 100
            : undefined;

        const pfComercialTax: number | undefined =
          values.insurerSpecificData.tooSeguros.bondInsurance.pfComercialTax &&
          values.insurerSpecificData.tooSeguros.bondInsurance.pfComercialTax !== '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.tooSeguros.bondInsurance.pfComercialTax
              ) / 100
            : undefined;

        const lmi: number = values.insurerSpecificData.tooSeguros.bondInsurance.lmi;

        const commissionPercentage: number | undefined =
          values.insurerSpecificData.tooSeguros.bondInsurance.commissionPercentage &&
          values.insurerSpecificData.tooSeguros.bondInsurance.commissionPercentage !==
            '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.tooSeguros.bondInsurance.commissionPercentage
              ) / 100
            : undefined;

        const assurance = values.insurerSpecificData.tooSeguros.bondInsurance.assurance;
        insurerSpecificData.push({
          commissionPercentage: commissionPercentage ? commissionPercentage / 100 : 0,
          pfResidentialTax: pfResidentialTax ? pfResidentialTax / 100 : 0,
          pfComercialTax: pfComercialTax ? pfComercialTax / 100 : 0,
          insurerId: tooSegurosInsurer.id,
          insuranceType: 'BOND_INSURANCE',
          lmi,
          orderPreference: Number(
            values.insurerSpecificData.tooSeguros.bondInsurance.orderPreference
          ),
          assurance
        });
      }
    }

    if (values.isTokioMarineBondInsuranceSelected) {
      if (!values.insurerSpecificData.tokioMarine.bondInsurance.commissionPercentage) {
        dangerNotification(
          'Tokio Marine',
          'Porcentagem da comissão não preenchida. Favor inserir um número válido.'
        );
        return;
      }

      const tokioMarineInsurer = values.bondInsurers.find(insurer => {
        return insurer.code === InsurersCodeEnum.TokioMarine;
      });

      if (tokioMarineInsurer) {
        const pfResidentialTax: number | undefined =
          values.insurerSpecificData.tokioMarine.bondInsurance.pfResidentialTax &&
          values.insurerSpecificData.tokioMarine.bondInsurance.pfResidentialTax !== '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.tokioMarine.bondInsurance.pfResidentialTax
              ) / 100
            : undefined;

        const pfComercialTax: number | undefined =
          values.insurerSpecificData.tokioMarine.bondInsurance.pfComercialTax &&
          values.insurerSpecificData.tokioMarine.bondInsurance.pfComercialTax !== '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.tokioMarine.bondInsurance.pfComercialTax
              ) / 100
            : undefined;

        const lmi: number = values.insurerSpecificData.tokioMarine.bondInsurance.lmi;

        const commissionPercentage: number | undefined =
          values.insurerSpecificData.tokioMarine.bondInsurance.commissionPercentage &&
          values.insurerSpecificData.tokioMarine.bondInsurance.commissionPercentage !==
            '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.tokioMarine.bondInsurance.commissionPercentage
              ) / 100
            : undefined;

        const assurance = values.insurerSpecificData.tokioMarine.bondInsurance.assurance;
        insurerSpecificData.push({
          commissionPercentage: commissionPercentage ? commissionPercentage / 100 : 0,
          pfResidentialTax: pfResidentialTax ? pfResidentialTax / 100 : 0,
          pfComercialTax: pfComercialTax ? pfComercialTax / 100 : 0,
          insurerId: tokioMarineInsurer.id,
          insuranceType: 'BOND_INSURANCE',
          lmi,
          orderPreference: Number(
            values.insurerSpecificData.tokioMarine.bondInsurance.orderPreference
          ),
          assurance
        });
      }
    }

    if (values.isGaranttiBondInsuranceSelected) {
      if (!values.insurerSpecificData.garantti.bondInsurance.commissionPercentage) {
        dangerNotification(
          'Garantti',
          'Porcentagem da comissão não preenchida. Favor inserir um número válido.'
        );
        return;
      }
      const garanttiInsurer = values.bondInsurers.find(insurer => {
        return insurer.code === InsurersCodeEnum.Garantti;
      });

      if (garanttiInsurer) {
        const pfResidentialTax: number | undefined =
          values.insurerSpecificData.garantti.bondInsurance.pfResidentialTax &&
          values.insurerSpecificData.garantti.bondInsurance.pfResidentialTax !== '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.garantti.bondInsurance.pfResidentialTax
              ) / 100
            : undefined;

        const pfComercialTax: number | undefined =
          values.insurerSpecificData.garantti.bondInsurance.pfComercialTax &&
          values.insurerSpecificData.garantti.bondInsurance.pfComercialTax !== '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.garantti.bondInsurance.pfComercialTax
              ) / 100
            : undefined;

        const commissionPercentage: number | undefined =
          values.insurerSpecificData.garantti.bondInsurance.commissionPercentage &&
          values.insurerSpecificData.garantti.bondInsurance.commissionPercentage !==
            '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.garantti.bondInsurance.commissionPercentage
              ) / 100
            : undefined;

        const lmi: number = values.insurerSpecificData.garantti.bondInsurance.lmi;

        const garanttiPlan: string =
          values.insurerSpecificData.garantti.bondInsurance.garanttiPlan;

        const garanttiBonus: boolean =
          values.insurerSpecificData.garantti.bondInsurance.garanttiBonus;

        const assurance = values.insurerSpecificData.garantti.bondInsurance.assurance;

        insurerSpecificData.push({
          insurerId: garanttiInsurer.id,
          insuranceType: 'BOND_INSURANCE',
          lmi,
          garanttiPlan,
          garanttiBonus,
          orderPreference: Number(
            values.insurerSpecificData.garantti.bondInsurance.orderPreference
          ),
          assurance,
          commissionPercentage: commissionPercentage ? commissionPercentage / 100 : 0,
          pfResidentialTax: pfResidentialTax ? pfResidentialTax / 100 : 0,
          pfComercialTax: pfComercialTax ? pfComercialTax / 100 : 0
        });
      }
    }

    if (values.isPottencialBondInsuranceSelected) {
      if (!values.insurerSpecificData.pottencial.bondInsurance.commissionPercentage) {
        dangerNotification(
          'Pottencial',
          'Porcentagem da comissão não preenchida. Favor inserir um número válido.'
        );
        return;
      }
      const pottencialInsurer = values.bondInsurers.find(insurer => {
        return insurer.code === InsurersCodeEnum.Pottencial;
      });

      if (pottencialInsurer) {
        const multiple: number | undefined =
          values.insurerSpecificData.pottencial.bondInsurance.multiple;
        const assurance = values.insurerSpecificData.pottencial.bondInsurance.assurance;

        const pfResidentialTax: number | undefined =
          values.insurerSpecificData.pottencial.bondInsurance.pfResidentialTax &&
          values.insurerSpecificData.pottencial.bondInsurance.pfResidentialTax !== '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.pottencial.bondInsurance.pfResidentialTax
              ) / 100
            : undefined;

        const pfComercialTax: number | undefined =
          values.insurerSpecificData.pottencial.bondInsurance.pfComercialTax &&
          values.insurerSpecificData.pottencial.bondInsurance.pfComercialTax !== '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.pottencial.bondInsurance.pfComercialTax
              ) / 100
            : undefined;

        const commissionPercentage: number | undefined =
          values.insurerSpecificData.pottencial.bondInsurance.commissionPercentage &&
          values.insurerSpecificData.pottencial.bondInsurance.commissionPercentage !==
            '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.pottencial.bondInsurance.commissionPercentage
              ) / 100
            : undefined;

        if (!multiple || multiple < 1) {
          dangerNotification(
            'Pottencial',
            'Multiplo não preenchido. Favor selecionar uma opção.'
          );
          return;
        }

        if (!assurance) {
          dangerNotification(
            'Pottencial',
            'Tipo de cobertura não preenchida. Favor selecionar uma opção.'
          );
          return;
        }

        if (!pfResidentialTax || pfResidentialTax < 1) {
          dangerNotification(
            'Pottencial',
            'Taxa Residêncial não preenchida. Por favor, insira uma porcentagem válida.'
          );
          return;
        }

        if (!pfComercialTax || pfComercialTax < 1) {
          dangerNotification(
            'Pottencial',
            'Taxa Comercial não preenchida. Por favor, insira uma porcentagem válida.'
          );
          return;
        }

        insurerSpecificData.push({
          insurerId: pottencialInsurer.id,
          insuranceType: 'BOND_INSURANCE',
          multiple,
          assurance,
          orderPreference: Number(
            values.insurerSpecificData.pottencial.bondInsurance.orderPreference
          ),
          commissionPercentage: commissionPercentage ? commissionPercentage / 100 : 0,
          pfResidentialTax: pfResidentialTax ? pfResidentialTax / 100 : 0,
          pfComercialTax: pfComercialTax ? pfComercialTax / 100 : 0
        });
      }
    }

    if (values.isPortoEssencialBondInsuranceSelected) {
      if (!values.insurerSpecificData.portoEssencial.bondInsurance.commissionPercentage) {
        dangerNotification(
          'Porto Essencial',
          'Porcentagem da comissão não preenchida. Favor inserir um número válido.'
        );
        return;
      }
      const portoEssencialInsurer = values.bondInsurers.find(insurer => {
        return insurer.code === InsurersCodeEnum.PortoEssencial;
      });

      if (portoEssencialInsurer) {
        const orderPreference: number | undefined =
          values.insurerSpecificData.portoEssencial.bondInsurance.orderPreference;

        const pfResidentialTax: number | undefined =
          values.insurerSpecificData.portoEssencial.bondInsurance.pfResidentialTax &&
          values.insurerSpecificData.portoEssencial.bondInsurance.pfResidentialTax !==
            '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.portoEssencial.bondInsurance.pfResidentialTax
              ) / 100
            : undefined;

        const pfComercialTax: number | undefined =
          values.insurerSpecificData.portoEssencial.bondInsurance.pfComercialTax &&
          values.insurerSpecificData.portoEssencial.bondInsurance.pfComercialTax !==
            '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.portoEssencial.bondInsurance.pfComercialTax
              ) / 100
            : undefined;

        const commissionPercentage: number | undefined =
          values.insurerSpecificData.portoEssencial.bondInsurance.commissionPercentage &&
          values.insurerSpecificData.portoEssencial.bondInsurance.commissionPercentage !==
            '0,00'
            ? unmaskMoney(
                values.insurerSpecificData.portoEssencial.bondInsurance
                  .commissionPercentage
              ) / 100
            : undefined;
        const lmi: number = values.insurerSpecificData.portoEssencial.bondInsurance.lmi;

        const assurance =
          values.insurerSpecificData.portoEssencial.bondInsurance.assurance;
        insurerSpecificData.push({
          insurerId: portoEssencialInsurer.id,
          insuranceType: 'BOND_INSURANCE',
          orderPreference,
          assurance,
          commissionPercentage: commissionPercentage ? commissionPercentage / 100 : 0,
          pfResidentialTax: pfResidentialTax ? pfResidentialTax / 100 : 0,
          pfComercialTax: pfComercialTax ? pfComercialTax / 100 : 0,
          lmi
        });
      }

      if (values.isPortoTradicionalBondInsuranceSelected) {
        const portoTradicionalInsurer = values.bondInsurers.find(insurer => {
          return insurer.code === InsurersCodeEnum.Porto;
        });

        if (portoTradicionalInsurer) {
          const pfResidentialTax: number | undefined =
            values.insurerSpecificData.portoTradicional.bondInsurance.pfResidentialTax &&
            values.insurerSpecificData.portoTradicional.bondInsurance.pfResidentialTax !==
              '0,00'
              ? unmaskMoney(
                  values.insurerSpecificData.portoTradicional.bondInsurance
                    .pfResidentialTax
                ) / 100
              : undefined;

          const pfComercialTax: number | undefined =
            values.insurerSpecificData.portoTradicional.bondInsurance.pfComercialTax &&
            values.insurerSpecificData.portoTradicional.bondInsurance.pfComercialTax !==
              '0,00'
              ? unmaskMoney(
                  values.insurerSpecificData.portoTradicional.bondInsurance.pfComercialTax
                ) / 100
              : undefined;

          const commissionPercentage: number | undefined =
            values.insurerSpecificData.portoTradicional.bondInsurance
              .commissionPercentage &&
            values.insurerSpecificData.portoTradicional.bondInsurance
              .commissionPercentage !== '0,00'
              ? unmaskMoney(
                  values.insurerSpecificData.portoTradicional.bondInsurance
                    .commissionPercentage
                ) / 100
              : undefined;

          const lmi: number =
            values.insurerSpecificData.portoTradicional.bondInsurance.lmi;

          const assurance =
            values.insurerSpecificData.portoTradicional.bondInsurance.assurance;
          insurerSpecificData.push({
            insurerId: portoTradicionalInsurer.id,
            insuranceType: 'BOND_INSURANCE',
            orderPreference: Number(
              values.insurerSpecificData.portoTradicional.bondInsurance.orderPreference
            ),
            assurance,
            lmi,
            commissionPercentage: commissionPercentage ? commissionPercentage / 100 : 0,
            pfResidentialTax: pfResidentialTax ? pfResidentialTax / 100 : 0,
            pfComercialTax: pfComercialTax ? pfComercialTax / 100 : 0
          });
        }
      }
    }

    if(values.fireInsurers.find(insurer => insurer.code === InsurersCodeEnum.alfa)) {
      const alfaInsurer = values.fireInsurers.find(insurer => {
        return insurer.code === InsurersCodeEnum.alfa;
      });

      if (alfaInsurer) {
        const alfaData = (values.insurerSpecificData as any).alfa?.fireInsurance;

        const brokerCommission = +alfaData?.brokerCommission || 0;
        const proLabore = +alfaData?.proLabore || 0;

        if ((brokerCommission + proLabore) !== 70) {
          dangerNotification(
            'Erro parametrização Alfa Seguros',
            'O somatório do Pro-labore da Imobiliária e da Corretora deve somar 70%.'
          );
          return;
        }

        insurerSpecificData.push({
          insurerId: alfaInsurer.id,
          insuranceType: 'FIRE_INSURANCE',
          login: alfaData.login,
          password: alfaData.password,
          realEstateCode: alfaData.realEstateCode,
          brokerCommission: Number(alfaData.brokerCommission),
          proLabore: Number(alfaData.proLabore),
          closingDay: Number(alfaData.lotClosingDay),
          transferBy: alfaData.transferBy,
          residentialDiscount: Number(alfaData.residentialDiscount),
          commercialDiscount: Number(alfaData.commercialDiscount),
          residentialGrievance: Number(alfaData.residentialSurcharge),
          commercialGrievance: Number(alfaData.commercialSurcharge),
          plans: alfaData?.plans?.map((plan: any) => (plan.id)),
          installmentOptions: alfaData?.installmentOptions,
          coverages: alfaData?.coverages?.map((coverage: any) => ({
            id: coverage.id,
            selectedMinValue: unmaskMoney(coverage.selectedMinValue),
            selectedMaxValue: unmaskMoney(coverage.selectedMaxValue),
            enabled: coverage.selected,
            isRequired: coverage.isRequired,
            multiplier: coverage.multiplier,
          }))
        } as any);
      }
    }

    payload.insurerSpecificData = insurerSpecificData;
    if (payload.insurerSpecificData.length === 0) {
      dangerNotification('Ops.', 'Você precisa selecionar ao menos uma Seguradora.');
      return;
    }

    return payload;
  };

  const createEstate = async (payload: any) => {
    if (!payload?.partners) return;

    const partners = [...payload.partners];
    setLoading(true);
    try {
      delete payload.partners;
      const estateCreated = await post(`/estates`, payload);
      const DTO = await getEstates(payload.broker_id);
      const data = getEstatesObjectsFromGetEstatesDTO(DTO);
      props.updateAvailableBrokersAndEstates({
        ...props.availableBrokersAndEstates,
        estates: EstatesToCustomDropDownOptions(true, data || [])
      });
      // Create the partners
      partners.forEach(async partner => {
        if (partner?.name === '' || partner?.document === '') return;

        const partnerToUpdate = {
          ...partner,
          estateId: estateCreated.id
        };
        await createPartner(partnerToUpdate);
      });
      successNotification('Sucesso', 'Imobiliária cadastrada com sucesso');

      history.push('/admin/estates');
    } catch (error) {
      console.log({ error });
      if ((error as any).message) {
        const errorMessage = (error as any).message;
        if (errorMessage.includes('Duplicate entry')) {
          const duplicado = errorMessage.split(' ')[2];
          dangerNotification(
            'Valor Duplicado',
            `O valor ${duplicado} já foi cadastrado anteriormente.`
          );
        } else {
          dangerNotification('Ops...', errorMessage);
        }
      } else {
        dangerNotification(
          'Oops...',
          (error as AxiosError).response?.data?.message || 'Erro ao criar imobiliária'
        );
      }
    }
    setLoading(false);

    // return false;
  };

  return (
    <Formik
      initialValues={estateFormInitialValues}
      validationSchema={createEstateSchema}
      onSubmit={(e: any) => {
        e.preventDefault();
        return false;
      }}
    >
      {innerProps => {
        return (
          <EstateFormContent
            {...props}
            {...innerProps}
            onSubmit={(values: any) => {
              const payload = getPayloadFormData(values);
              if (payload) createEstate(payload);
            }}
            isLoading={isLoading}
          />
        );
      }}
    </Formik>
  );
};

export default connect(mapState, mapDispatch)(EstateForm);
