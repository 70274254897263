import styled from "styled-components";
import { Grid, Divider } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../resources/css/styles.css";

export const ContainerWrapper = styled.div``;

export const HeaderWrapper = styled(Grid)`
  background-color: #1667FA;
  color: #fff;
  padding: 0 0.5em !important;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: 0 !important;

  & h3 {
    font-size: 13px;
  }

  & h4 {
    font-size: 12px;
  }

  & h5 {
    font-size: 10px;
  }
`;

export const HeaderContentWrapper = styled.div`
  border: 1px solid #1667FA;
  border-radius: 5px;
`;

export const HeaderDividerContainer = styled(Grid.Column)`
  padding-right: 0 !important;
  padding-left: 5px !important;
`;

export const HeaderDivider = styled.div`
  background-color: #fff !important;
  height: 35px;
  width: 2px !important;
`;

export const ContentWrapper = styled(Grid)`
  padding: 0em 0.5em !important;
  margin: 0 !important;
  font-size: 11px;

  & .row {
    margin: 0 !important;
    padding: 0.6em 0em !important;
  }
`;

export const ContentSteps = styled(ContentWrapper)`
  line-height: 26px !important;
  margin-bottom: 10px !important;

  & .row {
    padding: 0 !important;
    margin: 0 !important;
  }
`;

export const AmountWithInstallments = styled.h3`
  font-size: 15px !important;
`;

export const ContentDivider = styled(Divider)`
  margin: 0 !important;
`;

export const ActionsWrapper = styled(Grid)`
  margin-top: 0.6em !important;

  & .row {
    margin-bottom: 0.5em !important;
    padding: 0 !important;
  }
`;

export const SimulatorWrapper = styled.div`
  padding-right: 2em;
  & * {
    text-transform: uppercase;
  }

  & label {
    font-weight: bold;
  }

  & input {
    background-color: #eee !important;
  }
`;

export const CustomDatePickerStyle = styled(DatePicker)`
padding-top: 5px;
  &.ui.input {
    width: 100%;
  }

  &:disabled {
    opacity: 0.5;
  }
`;
