import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionTitleProps,
  Dimmer,
  Icon,
  List,
  ListItem,
  Loader,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from 'semantic-ui-react';

import { get } from '../../../../services/request';
import { dangerNotification } from '../../../../services/notification';
import AlfaCoveragesDetailsModal from './AlfaCoveragesDetails';
import { CloseButton, ModalWrapper, TaxesInfo, InsurerCard, ModalBackdrop, CoveragesInfo } from './styles';
import { formatAmount } from '../../../../services/masks';

interface ModalProps {
  onClose: () => void;
  fireInsurerData: any;
};

const AlfaFireInsuranceDetails: React.FC<ModalProps> = ({ onClose, fireInsurerData }) => {
  const [isLoadData, setIsLoadData] = useState<boolean>(true);
  const [openCoverageModal, setOpenCoverageModal] = useState<boolean>(false);
  const [accordionActiveIndex, setAccordionActiveIndex] = useState<number>(-1);
  const [data, setData] = useState<any>(null);

  const getFireInsurerEstateData = async (estateId: string, insurerId: string) => {
    setIsLoadData(true);

    try {
      const response = await get(`/estates/fire-active-insurer/alfa/details/${estateId}/${insurerId}/`);

      if (response) {
        setData({
          insurerName: fireInsurerData?.insurer?.name || '',
          insurerCode: fireInsurerData?.insurer?.code || '',
          insurerImage: fireInsurerData?.insurer?.image || '',
          enabled: response?.enabled || '',
          brokerCommission: response?.brokerCommission || '',
          compensation: response?.compensation || '',
          estateCode: response?.code || '',
          closingDay: response?.closingDay || '',
          transferBy: response?.transferBy || '',
          installmentOptions: response?.installmentOptions || '',
          residentialDiscount: response?.residentialDiscount || '',
          commercialDiscount: response?.commercialDiscount || '',
          commercialGrievance: response?.commercialGrievance || '',
          residentialGrievance: response?.residentialGrievance || '',
          plans: response?.plans || [],
          coverages: response?.coverages || [],
        });
      } else {
        dangerNotification(
          'Erro ao buscar detalhes da imobiliária',
          'Se o erro persistir, entre em contato com o suporte.'
        );
      }
    } catch (error) {
      dangerNotification(
        'Erro ao buscar detalhes da imobiliária',
        'Se o erro persistir, entre em contato com o suporte.'
      );
    } finally {
      setIsLoadData(false);
    }
  };

  const handleClickAccordion = (_e: React.MouseEvent<HTMLDivElement>, data: AccordionTitleProps): void => {
    const { index } = data;
    const selectedIndex = index === accordionActiveIndex ? -1 : Number(index);

    setAccordionActiveIndex(selectedIndex);
  }

  useEffect(() => {
    fireInsurerData && getFireInsurerEstateData(fireInsurerData.estate_id, fireInsurerData.insurer_id);
  }, [fireInsurerData]);

  return (
    <ModalBackdrop>
      <ModalWrapper>
        <Dimmer active={isLoadData} inverted>
          <Loader />
        </Dimmer>

        {data && (
          <>
            <InsurerCard>
                <CloseButton onClick={onClose}>&times;</CloseButton>
                <img src={data.insurerImage} className="logo" alt="Insurer logo" />
                <div className="insurer-content">
                  <span className="insurer-name">{data.insurerName}</span>
                  <small>{data.enabled ? 'Ativa' : 'Não Ativa'}</small>
                </div>
            </InsurerCard>

            <TaxesInfo colSpan={3}>
              <>
                <div>
                  <p><b>Código da imobiliária</b></p>
                  <span>{data.estateCode}</span>
                </div>
                <div>
                  <p><b>Pró-labore (%)</b></p>
                  <span>{data.compensation}</span>
                </div>
                <div>
                  <p><b>Dia de fechamento do lote</b></p>
                  <span>{data.closingDay}</span>
                </div>
                <div>
                  <p><b>Tipo de repasse</b></p>
                  <span>{data.transferBy}</span>
                </div>
                <div>
                  <p><b>Parcelas configuradas</b></p>
                  <span>{data.installmentOptions}</span>
                </div>
                <div>
                  <p><b>Agravo residencial (%)</b></p>
                  <span>{data.residentialGrievance}</span>
                </div>
                <div>
                  <p><b>Agravo comercial (%)</b></p>
                  <span>{data.commercialGrievance}</span>
                </div>
                <div>
                  <p><b>Desconto residencial (%)</b></p>
                  <span>{data.residentialDiscount}</span>
                </div>
                <div>
                  <p><b>Desconto comercial (%)</b></p>
                  <span>{data.commercialDiscount}</span>
                </div>
                <div>
                  <p><b>Planos selecionados</b></p>

                  {data.plans.length ? (
                    <List bulleted>
                      {data.plans.map((plan: string, index: number) => (
                        <ListItem key={index}>{plan}</ListItem>
                      ))}
                    </List>
                  ) : (
                    <span>Não há plano cadastrado.</span>
                  )}
                </div>
              </>
            </TaxesInfo>

            {Object.keys(data.coverages).length ? (
              <CoveragesInfo>
                <p><b>Coberturas selecionadas</b></p>

                {Object.keys(data.coverages).map((key, index) => {
                  return (
                    <Accordion styled fluid key={index} >
                      <Accordion.Title
                        index={index}
                        active={accordionActiveIndex === index}
                        onClick={handleClickAccordion}
                      >
                        <Icon name='dropdown' color='blue' size='big' />
                        <b>{key}</b>
                      </Accordion.Title>
                      <Accordion.Content active={accordionActiveIndex === index}>
                        <Table singleLine>
                          <TableHeader>
                            <TableRow>
                              <TableHeaderCell>Tipo</TableHeaderCell>
                              <TableHeaderCell>Valor Mín. (R$)</TableHeaderCell>
                              <TableHeaderCell>Valor Máx. (R$)</TableHeaderCell>
                              <TableHeaderCell>Multiplicador</TableHeaderCell>
                              <TableHeaderCell>Obrigatório</TableHeaderCell>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {data.coverages[key].length ? data.coverages[key].map((item: any) => (
                              <TableRow key={item.coverageName}>
                                <TableCell>{item.coverageName}</TableCell>
                                <TableCell>{formatAmount(item.maxValue)}</TableCell>
                                <TableCell>{formatAmount(item.minValue)}</TableCell>
                                <TableCell>{item.multiplier}</TableCell>
                                <TableCell>{item.isRequired ? 'Sim' : 'Não'}</TableCell>
                              </TableRow>
                            )) : null}
                          </TableBody>
                        </Table>
                      </Accordion.Content>
                    </Accordion>
                  );
                })}
              </CoveragesInfo>
            ) : (
              <span>Não há cobertura cadastrada.</span>
            )}
          </>
        )}
      </ModalWrapper>

      {openCoverageModal && (
        <AlfaCoveragesDetailsModal
          estateId={fireInsurerData.estate_id}
          open={true}
          onClose={() => setOpenCoverageModal(false)}
        />
      )}
    </ModalBackdrop>
  );
};

export default AlfaFireInsuranceDetails;
