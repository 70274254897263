import { iRootState, iRootDispatch } from "../../store";
import { Formik, FormikProps } from "formik";
import {
  reportFormInitialValues,
  ReportFormValues,
} from "./types";
import ReportFormContent from "./ReportFormContent";
import { createReportSchema } from "./validators";
import { connect } from "react-redux";
import React, { useState } from "react";
import { RouteComponentProps } from 'react-router';
import ReportModal from './components/ReportModal';

import { getAnalysisData, getPolicyData, getProposalData } from '../../services/report';
import { useHistory } from "react-router-dom";

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  setLoading: (loading: boolean) => dispatch.loading.setLoading(loading),
});

export type ReportFormProps = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch> &
  RouteComponentProps &
  FormikProps<any> & {
    handleInputChange: Function;
    onSubmit: Function;
    onReset: Function;
    isLoading: boolean;
  };

const ReportForm: React.FC<ReportFormProps> = (props: ReportFormProps) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [analysisR, setAnalysisR] = useState<any[]>([]);
  const [proposalR, setProposalR] = useState<any[]>([]);
  const [policyR, setPolicyR] = useState<any[]>([]);
  const [isAnalysisSelected, setIsAnalysisSelected] = useState<boolean>(false);
  const [isProposalSelected, setIsProposalSelected] = useState<boolean>(false);
  const [isPolicySelected, setIsPolicySelected] = useState<boolean>(false);


  let analysisResponse: any[] = [];
  let proposalResponse: any[] = [];
  let policyResponse: any[] = [];

  const open = (): void => {
    setIsOpen(true);
  };
  const close = (): void => {
    setIsOpen(false);
    history.push('reports-form');
  };

  const getReportData = async (payload: ReportFormValues) => {
    setIsLoading(true);
    const { analysis, proposal, policy, general, isAnalysisSelected, isPolicySelected, isProposalSelected } = payload;

    if (isAnalysisSelected) {
      setIsAnalysisSelected(true);
      analysisResponse = await getAnalysisData(general, analysis);
      setAnalysisR(analysisResponse);
    }

    if (isProposalSelected) {
      setIsProposalSelected(true);
      proposalResponse = await getProposalData(general, proposal);
      setProposalR(proposalResponse);
    }

    if (isPolicySelected) {
      setIsPolicySelected(true);
      policyResponse = await getPolicyData(general, policy);
      setPolicyR(policyResponse);
    }
    setIsLoading(false);
  }

  if (isOpen) {
    return (
      <>
        <ReportModal
          header={'Relatórios'}
          isAnalysisSelected={isAnalysisSelected}
          isProposalSelected={isProposalSelected}
          isPolicySelected={isPolicySelected}
          analysis={analysisR}
          proposals={proposalR}
          policies={policyR}
          onClose={close}
          onOpen={open}
          isOpen={true}
        />
      </>
    )
  } else {
    return (
      <Formik
        initialValues={reportFormInitialValues}
        validationSchema={createReportSchema}
        onSubmit={(e: any) => {
          e.preventDefault();
          return false;
        }}
        onReset={(e: any) => {
          e.preventDefault();
          return false;
        }}

      >
        {(innerProps) => {
          return (
            <>
              <ReportFormContent
                {...props}
                {...innerProps}
                onSubmit={(values: any) => {
                  getReportData(values).then(() => {
                    setIsLoading(false);
                    setIsOpen(true);
                  });
                }}
                onReset={(values: any) => {
                  console.log('reset');
                }}
                isLoading={isLoading}
              />
            </>
          );
        }}
      </Formik>
    );
  }
};

export default connect(mapState, mapDispatch)(ReportForm);
