import React, { FormEvent, useState } from 'react';
import Input from '../../components/Input';
import SectionLabel from '../../components/SectionLabel';
import { Grid, Button } from 'semantic-ui-react';
import { Field, Formik } from 'formik';
import { FormikErrorMessage } from '../../components/ErrorMessage';
import { estateValidationSchema } from './validators';
import { getErrorFormik } from '../../services/errors';
import { changeEstateUserEmail } from '../../services/user';
import { successNotification, dangerNotification } from '../../services/notification';
import Loader from '../../components/Loader';
import { FieldLabel } from '../../styles';
import { useParams } from 'react-router-dom';

const ChangeEmailForm = (props: any) => {
  const estate = (props.location && props.location.state) || {};

  const [isLoading, setLoading] = useState<boolean>(false);
  const params: any = useParams();

  const update = async (payload: any) => {
    setLoading(true);
    try {
      payload = {
        newEmail: payload.email
      }
      await changeEstateUserEmail(params.id, payload);
      successNotification('Sucesso!', 'O E-mail foi alterado.');
    } catch (error) {
      dangerNotification('Oops...', (error as Error).message);
    }
    setLoading(false);
    props.history.push('/admin/estates');
  }

  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  const initialValuesChangeEmailForm = {
    currentEmail: estate.email,
    email: '',
  };

  return (
    <Formik
      initialValues={initialValuesChangeEmailForm}
      validationSchema={estateValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={(values) => {
        update(values);
       }}>
      {innerProps => {

        const { handleChange, handleBlur, errors, touched, handleSubmit } = innerProps;

        const validateErros = (field: string) => getErrorFormik(errors, touched, 'estate', field);

        const onSubmit = (e: FormEvent) => { };
        return (
          <form onSubmit={onSubmit}>
            <Grid>
              <SectionLabel text={'DADOS DO USUÁRIO'} />
              <Grid.Row>
                <Grid.Column width={8}>
                  <FieldLabel>E-MAIL*</FieldLabel>
                  <Field name={`currentEmail`}
                    disabled={true}
                    onBlur={handleBlur}
                    error={validateErros('currentEmail')}
                    component={Input}
                    fluid />
                  <FormikErrorMessage component="div" name={`currentEmail`}/>
                </Grid.Column>
                <Grid.Column width={8}>
                  <FieldLabel>NOVO E-MAIL*</FieldLabel>
                  <Field name={`email`}
                    component={Input}
                    error={validateErros('email')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fluid
                    />
                    <FormikErrorMessage component="div" name={`email`}/>

                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign='left' width={16}>
                  <Button color='green' onClick={(e) => {
                    e.preventDefault()
                    handleSubmit()
                  } } type='submit'>
                    Atualizar
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </form>
        )
      }}

    </Formik>
  );
}

export default ChangeEmailForm;
