import { Grid, Input, InputOnChangeData, StepProps } from 'semantic-ui-react';
import { Field } from 'formik';
import React, { ReactElement, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import "../../../../../../resources/css/styles.css";
import { getErrorFormik } from '../../../../../../services/errors';
import SectionLabel from '../../../../../../components/SectionLabel';
import { FieldLabel } from '../../../../../../styles';
import { FormikErrorMessage } from '../../../../../../components/ErrorMessage';
import { maskCpf, unmaskCpfOrCnpj } from '../../../../../../services/masks';
import { NaturalTenant } from '../../../../../../types/NaturalTenant';
import { getDataByDocument } from '../../../../../../services/documents';
import { dangerNotification } from '../../../../../../services/notification';

const SECTION_NAME = 'tenant_info';

export const MaritalPartnerSection = (props: StepProps): ReactElement => {

  const {
    errors,
    touched,
    handleInputChange,
    values,
    setFieldTouched,
    setFieldValue,
  } = props;

  const tenant_info = values.tenant_info as NaturalTenant;

  const [cpfIsLoading, setCpfIsLoading] = useState<boolean>(false);

  const onChangeCpf = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const unmaskedValue = unmaskCpfOrCnpj(e.target.value);

    if (unmaskedValue.length >= 11) {
      setCpfIsLoading(true);

      getDataByDocument(unmaskedValue).then((data) => {
        setFieldValue(`${SECTION_NAME}.partner_name`, data.name);
      }).catch((e) => {
        dangerNotification('Oops...', 'Não foi possível encontrar dados associados ao documento informado')
      }).finally(() => {
        setCpfIsLoading(false);
      })
    }
  };

  const validateErros = (field: string) => getErrorFormik(errors, touched, SECTION_NAME, field);

  return (
    <>
      <SectionLabel text='INFORMAÇÕES DO CÔNJUGE' />
      <Grid.Row >

        <Grid.Column width={4}>
          <FieldLabel>CPF*</FieldLabel>
          <Field
            name={`${SECTION_NAME}.partner_cpf`}
            value={tenant_info.partner_cpf}
            loading={cpfIsLoading}
            onChange={(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
              data.value = maskCpf(data.value);
              handleInputChange(e, data);
            }}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
              setFieldTouched(`${SECTION_NAME}.partner_cpf`, true)
              onChangeCpf(e);
            }}
            error={validateErros('partner_cpf')}
            component={Input}
            fluid />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.partner_cpf`} />
        </Grid.Column>

        <Grid.Column width={7}>
          <FieldLabel>NOME*</FieldLabel>
          <Field
            name={`${SECTION_NAME}.partner_name`}
            value={tenant_info.partner_name}
            onChange={handleInputChange}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.partner_name`, true)}
            customInput={<Input />}
            error={validateErros('partner_name')}
            component={Input}
            fluid />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.partner_name`} />
        </Grid.Column>
      </Grid.Row>

      {/* <Grid.Row>
        <Grid.Column width={3}>
          <FieldLabel paddingTop={true}>TIPO DE DOCUMENTO </FieldLabel>
          <Field
            name={`${SECTION_NAME}.partner_document_type`}
            value={tenant_info.partner_document_type}
            completeValue={true}
            placeholder={'Selecionar...'}
            options={documentOptions}
            component={Dropdown}
            onChange={handleInputChange}
            error={validateErros('partner_document_type')}
            fluid />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.partner_document_type`} />
        </Grid.Column>

        <Grid.Column width={2}>
          <FieldLabel>Nº DOCUMENTO</FieldLabel>
          <Field
            name={`${SECTION_NAME}.partner_document`}
            value={tenant_info.partner_document}
            onChange={handleInputChange}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.partner_document`, true)}
            customInput={<Input />}
            error={validateErros('partner_document')}
            component={Input}
            fluid />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.partner_document`} />
        </Grid.Column>

        <Grid.Column width={2}>
          <FieldLabel>ÓRGÃO EMISSOR</FieldLabel>
          <Field
            name={`${SECTION_NAME}.partner_dispatcher`}
            value={tenant_info.partner_dispatcher}
            onChange={handleInputChange}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.partner_dispatcher`, true)}
            customInput={<Input />}
            error={validateErros('partner_dispatcher')}
            component={Input}
            fluid />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.partner_dispatcher`} />
        </Grid.Column>

        <Grid.Column width={3}>
          <FieldLabel>DATA DE EMISSÃO </FieldLabel>
          <Field
            name={`${SECTION_NAME}.partner_date_of_dispatch`}
            error={errors.tenant_info?.partner_date_of_dispatch}
            selected={tenant_info.partner_date_of_dispatch}
            component={CustomDatePicker}
            customInput={<Input />}
            dateFormat={'dd/MM/yyyy'}
            onChange={(date: Date, e: SyntheticEvent) => {
              handleDateChange(`${SECTION_NAME}.partner_date_of_dispatch`, date, e)
            }}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.partner_date_of_dispatch`, true)}
            className={validateErros('partner_date_of_dispatch')}
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.partner_date_of_dispatch`} />
        </Grid.Column>
      </Grid.Row> */}
    </>
  )
};
