import { post, put, get } from './request';
import {
  FireInsuranceBudget,
  FireInsuranceProposal,
  FireInsurancePolicy
} from '../types/FireInsurance';
import { BondInsurance } from '../types/BondInsurance';
import { GetBondInsuranceProposalsListResponseDTO } from '../dtos/bond-insurance-proposal-list/get-bond-insurance-proposals-list-response-dto';
import { GetBondInsurancePoliciesListResponseDTO } from '../dtos/bond-insurance-policy-list/get-bond-insurance-policies-list-response-dto';
import { Payload } from '../types/Request';
import { UserState } from '../types/UserState';

export async function createUser(payload: any) {
  const response = await post(`/users`, payload);

  return response;
}

export async function updateUser(userId: string, payload: any) {
  const response = await put(`/users/${userId}`, payload);

  return response;
}

// export async function sendWelcomeEmail(userId: string) {
//   const response = await post(`/users/${userId}/welcome-email`, undefined);

//   return response;
// }

export const getUserFireInsuranceBudgets = async (
  userID: string,
  filter?: any
): Promise<Array<FireInsuranceBudget>> => {
  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  let response;

  try {
    response = await get(`/users/${userID}/fire-insurance-budgets?${query}`);
  } catch (e) {
    response = [];
  }
  return response;
};

export const getUserFireInsuranceProposals = async (
  userID: string,
  filter?: any
): Promise<Array<FireInsuranceProposal>> => {
  let response: Array<FireInsuranceProposal>;

  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  try {
    response = await get(`/users/${userID}/fire-insurance-proposals?${query}`);
  } catch (e) {
    console.error(e);
    response = [];
  }
  return response;
};

export const getUserFireInsurancePolicies = async (
  userID: string,
  filter?: any
): Promise<Array<FireInsurancePolicy>> => {
  let response: Array<FireInsurancePolicy>;

  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  try {
    response = await get(`/users/${userID}/fire-insurance-policies?${query}`);
  } catch (e) {
    console.error(e);
    response = [];
  }
  return response;
};

export const getUserBondInsurances = async (
  userID: string,
  filter?: any
): Promise<BondInsurance[]> => {
  let response: BondInsurance[];

  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  try {
    response = await get(`/users/${userID}/bond-insurances?${query}`);
  } catch (e) {
    console.error(e);
    response = [];
  }
  return response;
};

export const getUsers = async (
  id?: string,
  page?: number,
  filter?: any
): Promise<Payload<UserState>> => {
  let response: Payload<UserState>;
  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  try {
    response = await get(`/users?id=${id}&page=${page}&${query}`);
  } catch (e) {
    console.error(e);
    response = { data: [], count: 0 };
  }
  return response;
};

export const getUser = async (id: string): Promise<any> => {
  try {
    const user = await get(`/users/${id}`);

    return user;
  } catch (e) {
    console.error(e);
    return { e };
  }
};

export const getUserBondInsuranceProposals = async (
  userId: string,
  page: number,
  filter?: any
): Promise<GetBondInsuranceProposalsListResponseDTO> => {
  let response: GetBondInsuranceProposalsListResponseDTO;
  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        if (query) {
          query += `&${map[0]}=${map[1]}`;
        } else {
          query += `${map[0]}=${map[1]}`;
        }
      }
    });
  }

  if (query) {
    query += `&page=${page}`;
  } else {
    query += `page=${page}`;
  }

  try {
    response = await get(`/bond-insurance/proposals/by-user/${userId}?${query}`);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getUserBondInsurancePolicies = async (
  userId: string,
  page: number,
  filter?: any
): Promise<GetBondInsurancePoliciesListResponseDTO> => {
  let response: GetBondInsurancePoliciesListResponseDTO;
  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        if (query) {
          query += `&${map[0]}=${map[1]}`;
        } else {
          query += `${map[0]}=${map[1]}`;
        }
      }
    });
  }

  if (query) {
    query += `&page=${page}`;
  } else {
    query += `page=${page}`;
  }

  try {
    response = await get(`/bond-insurance/policies/by-user/${userId}?${query}`);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const changeEstateUserEmail = async (estateID: string, payload: any) => {
  return await put(`/estates/${estateID}/change-email`, payload);
};
