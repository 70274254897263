import { ResponsibleCompanyEnum } from "../../enums/responsible-company.enum";

export type UserForUpdateEstateForm = {
  id: string;
  name: string;
};

export const typeOptions = [
  {
    index: 1,
    value: 'name',
    text: 'Nome'
  },
  {
    index: 2,
    value: 'document',
    text: 'CPF ou CNPJ'
  }
  // {
  //   index: 3,
  //   value: 'address',
  //   text: 'Endereço do imóvel'
  // }
];

export const companyOptions = [
  {
    index: 0,
    value: 'ALL',
    text: 'Todas'
  },
  {
    key: 1,
    text: 'Rede Confiax',
    value: ResponsibleCompanyEnum.confiax
  },
  {
    key: 2,
    text: 'Rede Vistorias',
    value: ResponsibleCompanyEnum.vistorias
  }
];

export const statusList = [
  {
    index: 0,
    value: 'ALL',
    text: 'Todos'
  },
  { index: 1, text: 'Pendente', value: 'PENDING' },
  { index: 2, text: 'Ativo', value: 'ACTIVE' },
  { index: 3, text: 'Inativo', value: 'INACTIVE' },
  { index: 4, text: 'Bloqueado', value: 'BLOCKED' },
  { index: 5, text: 'Update', value: 'UPDATE' },
];

export const stateOptions = [
  { key: 'AC', text: 'Acre', value: 'AC' },
  { key: 'AL', text: 'Alagoas', value: 'AL' },
  { key: 'AP', text: 'Amapá', value: 'AP' },
  { key: 'AM', text: 'Amazonas', value: 'AM' },
  { key: 'BA', text: 'Bahia', value: 'BA' },
  { key: 'CE', text: 'Ceará', value: 'CE' },
  { key: 'DF', text: 'Distrito Federal', value: 'DF' },
  { key: 'ES', text: 'Espírito Santo', value: 'ES' },
  { key: 'GO', text: 'Goiás', value: 'GO' },
  { key: 'MA', text: 'Maranhão', value: 'MA' },
  { key: 'MT', text: 'Mato Grosso', value: 'MT' },
  { key: 'MS', text: 'Mato Grosso do Sul', value: 'MS' },
  { key: 'MG', text: 'Minas Gerais', value: 'MG' },
  { key: 'PA', text: 'Pará', value: 'PA' },
  { key: 'PB', text: 'Paraíba', value: 'PB' },
  { key: 'PR', text: 'Paraná', value: 'PR' },
  { key: 'PE', text: 'Pernambuco', value: 'PE' },
  { key: 'PI', text: 'Piauí', value: 'PI' },
  { key: 'RJ', text: 'Rio de Janeiro', value: 'RJ' },
  { key: 'RN', text: 'Rio Grande do Norte', value: 'RN' },
  { key: 'RS', text: 'Rio Grande do Sul', value: 'RS' },
  { key: 'RO', text: 'Rondônia', value: 'RO' },
  { key: 'RR', text: 'Roraima', value: 'RR' },
  { key: 'SC', text: 'Santa Catarina', value: 'SC' },
  { key: 'SP', text: 'São Paulo', value: 'SP' },
  { key: 'SE', text: 'Sergipe', value: 'SE' },
  { key: 'TO', text: 'Tocantins', value: 'TO' }
];
