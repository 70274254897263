import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Dimmer, Grid, Input, Loader, Modal } from 'semantic-ui-react';
import { useQuery } from '@tanstack/react-query';
import {
  dangerNotification,
  successNotification
} from '../../../../services/notification';
import Toggle from '../../../../components/Toggle';
import { Container, ModalToggleContainer } from './styles';
import { Blacklist, BlacklistStatusEnum } from '../../../../types/Blacklist';
import { create, getById, update } from '../../../../services/blacklist';
import { maskCpfOrCnpj, unmaskCpfOrCnpj } from '../../../../services/masks';
import { get } from '../../../../services/storage';

type Callback = () => void;

interface ModalProps {
  id: string;
  isOpen: boolean;
  closeModal: Callback;
}

const INITIAL_STATE = {
  name: '',
  document: '',
  observations: '',
  status: BlacklistStatusEnum.ACTIVE
};

const EditModal = ({ id, isOpen, closeModal }: ModalProps) => {
  const [form, setForm] = useState<Blacklist>(INITIAL_STATE);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validateSubmit, setValidateSubmit] = useState<boolean>(false);
  const modalStyle = {
    marginTop: '2rem',
    padding: 10
  };
  const actionButtonContainerStyle = {
    display: 'flex',
    justifyContent: 'end',
    marginTop: 40
  };
  const { data: blacklist, isFetching: isBlacklistLoading } = useQuery<Blacklist>(
    ['getBlacklistsByID', id],
    () => getById(id),
    {
      keepPreviousData: true,
      enabled: id !== 'new'
    }
  );

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const userId = get('uid');
    setValidateSubmit(true);
    setIsLoading(true);
    if (form?.name) {
      try {
        const body: Blacklist = {
          ...form,
          document: unmaskCpfOrCnpj(form.document),
          createdBy: userId || undefined
        };
        id == 'new' ? await create(body) : await update(id, body);
        successNotification('Sucesso!', 'Blacklist atualizada com sucesso.');
        closeModal();
      } catch (err) {
        dangerNotification('Oops...', 'Ocorreu um erro ao tentar salvar as informações');
      } finally {
        setIsLoading(false);
      }
    } else {
      dangerNotification('Error', 'Preencha os campos obrigatórios.');
    }
  };

  useEffect(() => {
    setValidateSubmit(false);
    setForm(INITIAL_STATE);
    if (id !== 'new' && blacklist?.id) {
      setForm({
        name: blacklist?.name,
        observations: blacklist?.observations,
        document: maskCpfOrCnpj(blacklist?.document),
        status: blacklist?.status
      });
    }
  }, [id, blacklist, isOpen]);

  useEffect(() => {
    if (validateSubmit) setValidateSubmit(true);
  }, [form, validateSubmit]);

  return (
    <Modal
      style={modalStyle}
      size="large"
      closeIcon
      open={isOpen}
      onClose={() => closeModal()}
      onOpen={() => closeModal()}
      loading={true}
    >
      <Modal.Header>
        <Grid>
          <Grid.Row>
            <Grid.Column width={12}>
              <h3>{id === 'new' ? 'Adicionar à' : 'Editar'} blacklist</h3>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right">
              <ModalToggleContainer>
                <label>
                  {form?.status === BlacklistStatusEnum.ACTIVE ? 'Ativo' : 'Inativo'}
                </label>
                <Toggle
                  checked={form?.status === BlacklistStatusEnum.ACTIVE}
                  onChange={(_, data) => {
                    setForm(prevValue => ({
                      ...prevValue,
                      status: data.checked
                        ? BlacklistStatusEnum.ACTIVE
                        : BlacklistStatusEnum.INACTIVE
                    }));
                  }}
                />
              </ModalToggleContainer>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Content>
          <Dimmer active={isBlacklistLoading || isLoading} inverted>
            <Loader />
          </Dimmer>
          <Container>
            <Grid>
              <Grid.Row>
                <Grid.Column computer={8} tablet={12} mobile={12}>
                  <label>Nome</label>
                  <Input
                    placeholder="Nome"
                    fluid
                    onChange={(_, { value }) =>
                      setForm(prevValue => ({
                        ...prevValue,
                        name: value as string
                      }))
                    }
                    value={form.name}
                    error={validateSubmit && form?.name === ''}
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={12} mobile={12}>
                  <label>Documento</label>
                  <Input
                    placeholder="Documento"
                    fluid
                    onChange={(_, { value }) =>
                      setForm(prevValue => ({
                        ...prevValue,
                        document: maskCpfOrCnpj(value as string)
                      }))
                    }
                    value={form.document}
                    error={validateSubmit && form?.document === ''}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <label>Observações</label>
                  <Input
                    placeholder="Observações"
                    fluid
                    onChange={(_, { value }) =>
                      setForm(prevValue => ({
                        ...prevValue,
                        observations: value as string
                      }))
                    }
                    value={form.observations}
                    error={validateSubmit && form?.observations === ''}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Modal.Content>
        <Modal.Actions style={actionButtonContainerStyle}>
          <Button color="grey" type="button" onClick={closeModal}>
            Cancelar
          </Button>
          <Button color="blue" type="submit">
            Salvar
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
};

export default EditModal;
