import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  Grid,
  Icon,
  Menu,
  Input,
  Loader,
  Popup,
  Table,
  GridRow,
  GridColumn,
  Item
} from 'semantic-ui-react';
import { differenceInHours } from 'date-fns';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import CustomDatePicker from '../../../components/CustomDatePicker';
import AppMaskedInput from '../../../components/MaskedInput';
import ModalAttachFiles from '../../../components/ModalAttachFiles';
import {
  getFireInsuranceEstatesReport,
  getFireInsuranceModelAlfaReport,
  getFireInsuranceReport,
  getPolicies,
  getPolicyInfo,
  getPolicyInfoAwaitingBilling
} from '../../../services/fire-insurance-policy';
import {
  DATE_INPUT_MASK,
  maskCpfOrCnpj,
  maskMoney,
  numberToMoneyString
} from '../../../services/masks';
import { FieldLabel } from '../../../styles';
import {
  ExpireInList,
  fileIdentifiers,
  proposalStatusList,
  translateProposalStatus
} from '../utils/Pottencial';
import DetailModal from './Details';
import { connect } from 'react-redux';
import { iRootDispatch, iRootState } from '../../../store';
import {
  AvailableBrokersAndEstatesState,
  CurrentPageState
} from '../../../store/types/temp-types';
import { CustoDropDownItemProps } from '../../../components/types';
import AuthorizationContainer from '../../../components/AuthorizationContainer';
import { ActionEnum } from '../../../enums/authz-action.enum';
import { FeatureEnum } from '../../../enums/authz-feature.enum';
import CancelPolicyModal from '../budget-list/Cancel/CancelPolicyModal';
import IconButton from '../../../components/IconButton';
import { useHistory } from 'react-router-dom';
import EditCancelPolicyModal from '../budget-list/Cancel/EditCancelPolicyModal';
import { getCanceled } from '../../../services/fire-insurance-quotation';
import { dangerNotification, warningNotification } from '../../../services/notification';
import { ReportModal } from '../../../components/ReportModal';
import { isUuid } from 'uuidv4';
import FireGeneralCondition from '../budget/GeneralConditionModal';
import { adjustDateForTimezone } from '../../../utils/AjustDates';
import { get } from '../../../services/storage';
import { typeOptions } from './types';
import { UserState } from '../../../types/UserState';
import { getUsers } from '../../../services/broker';
import { UsersToCustomDropDownOptions } from '../../../util';
import { SSL_OP_SSLREF2_REUSE_CERT_TYPE_BUG } from 'constants';
import { BudgetContext } from '../budget/forms-based-in-insurer/Alfa';

type SearchType = 'document' | 'name' | 'address';

const ClickableTableCell = styled(Table.Cell)`
  cursor: pointer;
  position: relative;
`;

const ClickableTableRow = styled(Table.Row)`
  :hover {
    background-color: #f0f0f0;
  }
`;

const FireInsurancePolicy = (props: {
  user: UserState;
  availableBrokersAndEstates: AvailableBrokersAndEstatesState;
  updateCurrentPage: Function;
}) => {
  const { availableBrokersAndEstates, user } = props;
  const userRole = get('role');

  const estateOptions: CustoDropDownItemProps[] = availableBrokersAndEstates.estates;
  const insurerOptions: CustoDropDownItemProps[] =
    availableBrokersAndEstates.insurers.filter(insurer =>
      insurer.value.availability.includes('FIRE_INSURANCE')
    );

  const history = useHistory();
  const pathParts = history.location.pathname.split('/');
  const fireInsuranceAnalysisId = isUuid(pathParts[pathParts.length - 1])
    ? pathParts[pathParts.length - 1]
    : null;
  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [status, setStatus] = useState<any>('');
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [vigency, setVigency] = useState<any>('');
  const [selectedEstate, setSelectedEstate] = useState<any>();
  const [policyCancelation, setPolicyCancelation] = useState<any>(null);
  const [selectedInsurer, setSelectedInsurer] = useState<any>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [openEditCancelModal, setOpenEditCancelModal] = useState<boolean>(false);
  const [editCancelData, setEditCancelData] = useState<any>(undefined);
  const [currentPolicyId, setCurrentPolicyId] = useState<string>('');
  const [currentInsurerCode, setCurrentInsurerCode] = useState<string>('');
  const [currentDetailPolicy, setCurrentDetailPolicy] = useState<any>(null);
  const [infoAwaitingBilling, setInfoAwaitingBilling] = useState<any>();
  const [popupString, setPopupString] = useState<string>('');
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [reportModalIsOpen, setReportModalIsOpen] = useState<boolean>(false);
  const [reportState, setReportState] = useState<number | undefined>(undefined);
  const [openConditions, setOpenConditions] = useState<boolean>(false);
  const [reportStartDate, setReportStartDate] = useState<boolean>(false);
  const [reportEndDate, setReportEndDate] = useState<boolean>(false);
  const toggleOpenConditions = () => {
    setOpenConditions(true);
  };
  const [type, setType] = useState<SearchType>('document');
  const [argument, setArgument] = useState('');
  const [selectedAccountManager, setSelectedAccountManager] = useState('');

  const toggleCloseConditions = () => {
    setOpenConditions(false);
  };

  const getData = async () => {
    setIsLoadingData(true);

    let searchTerm = argument;

    if (type === 'document' && searchTerm) {
      searchTerm = searchTerm.replace(/[^\d]+/g, '');
    }

    const filter = {
      id: fireInsuranceAnalysisId || undefined,
      page: currentPage,
      start: start ? moment(start).format('YYYY-MM-DD') : undefined,
      end: end ? moment(end).format('YYYY-MM-DD') : undefined,
      insurerId: selectedInsurer?.id || undefined,
      estateId: selectedEstate?.id || undefined,
      status: status || undefined,
      vigency: vigency || undefined,
      accountManager: selectedAccountManager || undefined,
      [type]: searchTerm || undefined
    };

    const data = await getPolicies(filter);

    setData(data?.data);
    setCount(data?.quantity);
    setIsLoadingData(false);
  };

  const getDataPolicyInfoAwaitingBilling = async (estateId: string) => {
    const response = await getPolicyInfoAwaitingBilling(estateId);

    if (response) {
      setInfoAwaitingBilling({
        qty: response.qty,
        amount: numberToMoneyString(response.amount),
        batchClosingDay: response.batchClosingDay
      });
    } else {
      setInfoAwaitingBilling({
        qty: '-',
        amount: '-',
        batchClosingDay: []
      });
    }
  };

  const handleCloseModal = () => {
    setCurrentDetailPolicy(null);
    setPolicyCancelation(null);
    setOpenModal(false);
  };

  const handleCloseEditCancelModal = () => {
    setOpenEditCancelModal(false);
  };

  const getCancelation = async (id: string) => {
    let response: any;

    try {
      response = await getCanceled(id);
    } catch (err) {
      console.log({ err });
    } finally {
      setPolicyCancelation(response);
    }
  };

  const getDetails = async (id: string) => {
    let response: any;

    try {
      response = await getPolicyInfo(id);
    } catch (err) {
      console.log({ err });
    } finally {
      return response;
    }
  };

  const renderDetailModal = async (item: any) => {
    if (['CANCELED', 'CANCELATION_REQUEST'].includes(item.status)) {
      await getCancelation(item.fireInsuranceAnalysis.id);
    }

    const policy = await getDetails(item.id);
    setCurrentDetailPolicy(policy);
    setOpenModal(true);
  };

  const handleNewChangeEstate = (_e: any, { value }: any) => {
    setSelectedEstate(value);
  };

  const handleNewChangeInsurer = (_e: any, { value }: any) => {
    setSelectedInsurer(value);
  };

  const handleOpenCancelModal = (item: any) => {
    setCurrentPolicyId(item.id);
    setCurrentInsurerCode(item.fireInsuranceAnalysis.insurer.code);
    setOpenCancelModal(true);
  };

  const handleOpenEditCancelModal = (item: any) => {
    const data = {
      status: item.status,
      policyId: item.id,
      analysisId: item.fireInsuranceAnalysis.id,
      insurerCode: item.fireInsuranceAnalysis.insurer.code
    };

    setEditCancelData(data);
    setOpenEditCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setCurrentPolicyId('');
    setCurrentInsurerCode('');
    setOpenCancelModal(false);
  };

  const handleExportData = async () => {
    setIsLoadingData(true);

    try {
      if (!start || !end) {
        warningNotification(
          'Opss...',
          'Por favor, selecione o periodo que deseja gerar o relatório.'
        );

        !start && setReportStartDate(true);
        !end && setReportEndDate(true);

        return;
      }

      let searchTerm = argument;

      if (type === 'document' && searchTerm) {
        searchTerm = searchTerm.replace(/[^\d]+/g, '');
      }

      const filters = {
        page: currentPage,
        start: start ? moment(start).format('YYYY-MM-DD') : undefined,
        end: end ? moment(end).format('YYYY-MM-DD') : undefined,
        insurerId: selectedInsurer?.id || undefined,
        estateId: selectedEstate?.id || undefined,
        status: status || undefined,
        accountManager: selectedAccountManager || undefined,
        [type]: searchTerm || undefined
      };

      const response = await getFireInsuranceReport(filters);

      if (!response) {
        dangerNotification(
          'Ops...',
          'Erro ao solicitar relatório de apólices de seguro incêndio.'
        );
      } else {
        setReportState(response);
        setReportModalIsOpen(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleSyntheticReport = async () => {
    setIsLoadingData(true);

    try {
      if (!start || !end) {
        warningNotification(
          'Opss...',
          'Por favor, selecione o periodo que deseja gerar o relatório.'
        );

        !start && setReportStartDate(true);
        !end && setReportEndDate(true);

        return;
      }

      const filters = {
        insurerId: selectedInsurer?.id || undefined,
        estateId: selectedEstate?.id || undefined,
        start: start ? moment(start).format('YYYY-MM-DD') : undefined,
        end: end ? moment(end).format('YYYY-MM-DD') : undefined
      };

      const response = await getFireInsuranceEstatesReport(filters);

      if (!response) {
        dangerNotification(
          'Ops...',
          'Erro ao solicitar relatório de apólices de seguro incêndio.'
        );
      } else {
        setReportState(response);
        setReportModalIsOpen(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleNewChangeAccountManager = (accountManagerKey: string) => {
    setSelectedAccountManager(!!accountManagerKey?.length ? accountManagerKey : '');
  };

  const handleReportModalClose = () => {
    setReportModalIsOpen(false);
  };

  const getAccountManagers = async () => {
    const result = await getUsers(user?.broker?.id, [{ isDropdown: true }]);

    return UsersToCustomDropDownOptions(
      false,
      result.map(user => ({
        id: user.id,
        name: user.name
      }))
    );
  };

  const { data: accountManagerOptions, isFetching: isAccountManagersLoading } = useQuery(
    ['getAccountManagers'],
    getAccountManagers,
    {
      keepPreviousData: false,
      enabled: !!user?.broker?.id
    }
  );

  useEffect(() => {
    getData();
  }, [currentPage]);

  useEffect(() => {
    if (selectedEstate) {
      getDataPolicyInfoAwaitingBilling(selectedEstate.id);
    } else {
      setInfoAwaitingBilling({
        qty: '-',
        amount: '-',
        batchClosingDay: []
      });
    }
  }, [selectedEstate]);

  async function handleAlfaModelExport() {
    setIsLoadingData(true);
    try {
      if (selectedEstate.code === 'ALFA') {
        warningNotification(
          'Opss...',
          'Não é possível exportar para o modelo Alfa com seguradora ALFA selecionada.'
        );
        return;
      }

      if (!selectedEstate) {
        warningNotification(
          'Opss...',
          'Precisa selecionar uma seguradora para exportar com modelo Alfa.'
        );
        return;
      }

      let searchTerm = argument;

      if (type === 'document' && searchTerm) {
        searchTerm = searchTerm.replace(/[^\d]+/g, '');
      }

      const filters = {
        page: currentPage,
        start: start ? moment(start).format('YYYY-MM-DD') : undefined,
        end: end ? moment(end).format('YYYY-MM-DD') : undefined,
        insurerId: selectedInsurer?.id || undefined,
        estateId: selectedEstate?.id || undefined,
        status: status || undefined,
        accountManager: selectedAccountManager || undefined,
        [type]: searchTerm || undefined
      };

      const response = await getFireInsuranceModelAlfaReport(filters);

      if (!response) {
        dangerNotification(
          'Ops...',
          'Erro ao solicitar relatório de apólices de seguro incêndio.'
        );
      }
      const link = document.createElement('a');
      link.href = response.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingData(false);
    }
  }

  return (
    <>
      {reportModalIsOpen && reportState === 200 ? (
        <ReportModal
          isOpen={reportModalIsOpen}
          header="Relatório de Seguro Incêndio"
          message={`
              O relatório será gerado em background e será enviado o link para download via e-mail.
              Atenção, só será possível gerar um novo relatório após a finalização desse.
            `}
          onClose={handleReportModalClose}
        />
      ) : (
        <ReportModal
          isOpen={reportModalIsOpen}
          header="Relatório de Seguro Incêndio"
          message="Já existe um relatório na fila para ser gerado, aguarde a notificação via email."
          onClose={handleReportModalClose}
        />
      )}

      <Grid columns={'equal'}>
        <Grid.Row
          style={{ textAlign: 'right', justifyContent: 'right', paddingRight: 10 }}
        >
          <FireGeneralCondition
            openModal={openConditions}
            toggleOpen={toggleOpenConditions}
            toggleClose={toggleCloseConditions}
          />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}>
            <FieldLabel>Pesquisar por</FieldLabel>
            <div style={{ width: '100%' }}>
              <Dropdown
                style={{ width: '100%' }}
                value={type}
                name="type"
                options={typeOptions}
                placeholder="Selecionar..."
                disabled={isLoadingData}
                onChange={(_, { value }) => {
                  if (['document', 'name', 'address'].includes(value as string)) {
                    setType(value as SearchType);
                  }
                }}
                search
                selection
              />
            </div>
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Termo de pesquisa:</FieldLabel>
            <Input
              name="argument"
              onChange={(e, { value }) => setArgument(value)}
              disabled={isLoadingData}
              value={argument}
              fluid
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>Imobiliária</FieldLabel>
            <Dropdown
              fluid
              search
              clearable
              selection
              name={'estate'}
              placeholder={'Selecionar'}
              options={estateOptions || []}
              onChange={handleNewChangeEstate}
              value={selectedEstate}
              defaultValue={
                availableBrokersAndEstates.estates.length === 1
                  ? availableBrokersAndEstates.estates[0].value
                  : '' || ''
              }
              disabled={availableBrokersAndEstates.estates.length === 1 || false}
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>Status</FieldLabel>
            <Dropdown
              fluid
              search
              clearable
              selection
              placeholder={'Status'}
              options={proposalStatusList}
              onChange={(e, { name, value }) => setStatus(value)}
              value={status}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <FieldLabel>Seguradora</FieldLabel>
            <Dropdown
              fluid
              search
              clearable
              selection
              name={'insurer'}
              placeholder={'Selecionar'}
              options={insurerOptions || []}
              onChange={handleNewChangeInsurer}
              value={selectedInsurer}
              defaultValue={
                availableBrokersAndEstates.insurers.length === 1
                  ? availableBrokersAndEstates.insurers[0].value
                  : '' || ''
              }
              disabled={availableBrokersAndEstates.insurers.length === 1 || false}
            />
          </Grid.Column>

          {!user.role.includes('ESTATE') && (
            <Grid.Column width={3}>
              <FieldLabel>Gestor de Contas:</FieldLabel>
              <Dropdown
                style={{ width: '100%' }}
                value={selectedAccountManager}
                name="accountManager"
                options={accountManagerOptions}
                disabled={isLoadingData || isAccountManagersLoading}
                loading={isLoadingData || isAccountManagersLoading}
                placeholder="Selecionar..."
                onChange={(e, { value }) =>
                  handleNewChangeAccountManager(value as string)
                }
                clearable
                search
                selection
              />
            </Grid.Column>
          )}

          <Grid.Column>
            <FieldLabel>Data de Início</FieldLabel>
            <CustomDatePicker
              fluid
              selected={start}
              maxDate={end}
              type={'date'}
              placeholder={'Data de início'}
              onChange={(date: any) => {
                setStart(date);
                setReportStartDate(false);
              }}
              customInput={
                <AppMaskedInput mask={DATE_INPUT_MASK} error={reportStartDate} />
              }
              dateFormat={'dd/MM/yyyy'}
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>Fim</FieldLabel>
            <CustomDatePicker
              fluid
              selected={end}
              minDate={start}
              type={'date'}
              placeholder={'Data de Fim'}
              onChange={(date: any) => {
                setEnd(date);
                setReportEndDate(false);
              }}
              customInput={
                <AppMaskedInput mask={DATE_INPUT_MASK} error={reportEndDate} />
              }
              dateFormat={'dd/MM/yyyy'}
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>Expirar em</FieldLabel>
            <Dropdown
              fluid
              search
              clearable
              selection
              placeholder={'Selecionar'}
              options={ExpireInList}
              onChange={(e, { name, value }) => setVigency(value)}
              value={vigency}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row verticalAlign="middle" style={{ padding: 0, margin: '15px 0' }}>
          {selectedEstate && (
            <Grid columns={16} divided>
              <GridRow style={{ padding: 0, margin: 0 }}>
                <GridColumn
                  width={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    margin: '0 14px'
                  }}
                >
                  <Icon name="pie graph" size="big" color="blue" />
                  <Item style={{ paddingLeft: '10px', margin: 0 }}>
                    <FieldLabel>Fechamento do lote</FieldLabel>
                    {infoAwaitingBilling.batchClosingDay.length ? (
                      infoAwaitingBilling.batchClosingDay.map((closingDay: any) => {
                        return (
                          <>
                            <FieldLabel
                              key={closingDay.name}
                              style={{ color: '#1678c2' }}
                            >
                              {closingDay.name}: Dia {closingDay.closingDay}
                            </FieldLabel>
                          </>
                        );
                      })
                    ) : (
                      <FieldLabel style={{ color: '#1678c2' }}>-</FieldLabel>
                    )}
                  </Item>
                </GridColumn>

                <GridColumn width={4}>
                  <FieldLabel>Total de itens a faturar</FieldLabel>
                  <FieldLabel style={{ color: '#1678c2' }}>
                    {infoAwaitingBilling.qty}
                  </FieldLabel>
                </GridColumn>

                <GridColumn width={4}>
                  <FieldLabel>Valor total a faturar</FieldLabel>
                  <FieldLabel style={{ color: '#1678c2' }}>
                    {infoAwaitingBilling.amount}
                  </FieldLabel>
                </GridColumn>
              </GridRow>
            </Grid>
          )}

          <Grid.Column
            width={userRole === 'BROKER_ADMIN' ? 8 : 4}
            verticalAlign={'bottom'}
            textAlign={'right'}
            floated="right"
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            {userRole === 'BROKER_ADMIN' && selectedInsurer && (
              <Button
                fluid
                disabled={isLoadingData}
                loading={isLoadingData}
                onClick={handleSyntheticReport}
                content={'Relatório Sintético'}
              />
            )}

            {userRole === 'BROKER_ADMIN' && selectedInsurer && (
              <Button
                fluid
                disabled={isLoadingData}
                loading={isLoadingData}
                onClick={handleAlfaModelExport}
                content={'Exportar c/ Modelo Alfa'}
              />
            )}

            <Button
              fluid
              disabled={isLoadingData}
              loading={isLoadingData}
              onClick={handleExportData}
              content={'Exportar'}
            />

            <Button
              fluid
              primary
              disabled={isLoadingData}
              loading={isLoadingData}
              onClick={() => getData()}
              content={'Pesquisar'}
            />

            <Button
              fluid
              basic
              disabled={isLoadingData}
              loading={isLoadingData}
              onClick={() => {
                setStatus('');
                setStart(undefined);
                setEnd(undefined);
                setSelectedEstate('');
                setSelectedInsurer('');
                setIsFirstLoading(false);
                setType('name');
                setArgument('');
                setSelectedAccountManager('');
                /// remover idpath
                history.push('/admin/fire-insurance-policies');
              }}
              content={'Limpar'}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Table celled>
        <Table.Header fullWidth>
          <Table.HeaderCell textAlign={'center'}>Criado em</Table.HeaderCell>
          <Table.HeaderCell textAlign={'center'}>Seguradora</Table.HeaderCell>
          <Table.HeaderCell textAlign={'center'}>Imobiliária</Table.HeaderCell>
          <Table.HeaderCell textAlign={'center'}>Inquilino</Table.HeaderCell>
          <Table.HeaderCell textAlign={'center'}>Endereço</Table.HeaderCell>
          <Table.HeaderCell textAlign={'center'}>Vigência</Table.HeaderCell>
          <Table.HeaderCell textAlign={'center'}>Status</Table.HeaderCell>
          <Table.HeaderCell textAlign={'center'}>Prêmio</Table.HeaderCell>
          <Table.HeaderCell textAlign={'center'}>Ações</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {isLoadingData ? (
            <Table.Row>
              <Table.Cell textAlign={'center'} colSpan={9}>
                <Loader active inline />
              </Table.Cell>
            </Table.Row>
          ) : (
            <>
              {data.length ? (
                data.map((item: any) => {
                  const today = new Date();
                  const lastUpdate = new Date(new Date(item.updatedAt).toISOString());
                  const { fireInsuranceAnalysis } = item;
                  const { fireInsurance } = fireInsuranceAnalysis;
                  const address = fireInsurance.propertyAddresses;
                  const [date, hour] =
                    item.createdAt &&
                    moment(item.createdAt).format('DD/MM/YYYY HH:mm').split(' ');

                  return (
                    <ClickableTableRow key={item.id}>
                      <ClickableTableCell
                        textAlign={'center'}
                        onClick={() => renderDetailModal(item)}
                      >
                        {date}
                        <br />
                        {hour}
                      </ClickableTableCell>

                      <ClickableTableCell
                        textAlign={'center'}
                        onClick={() => renderDetailModal(item)}
                      >
                        {fireInsuranceAnalysis.insurer.name}
                      </ClickableTableCell>

                      <ClickableTableCell
                        textAlign={'center'}
                        onClick={() => renderDetailModal(item)}
                      >
                        {fireInsurance.estate.name}
                      </ClickableTableCell>

                      <ClickableTableCell
                        textAlign={'center'}
                        onClick={() => renderDetailModal(item)}
                      >
                        {fireInsurance.tenantName}
                        <br />
                        <span style={{ color: '#808080' }}>
                          ({maskCpfOrCnpj(fireInsurance.tenantDocument)})
                        </span>
                      </ClickableTableCell>

                      <ClickableTableCell
                        textAlign={'center'}
                        onClick={() => renderDetailModal(item)}
                      >
                        {address.street}, {address.number}
                        <br />
                        {address.district} - {address.city}, {address.state}
                      </ClickableTableCell>

                      <ClickableTableCell
                        width={1}
                        textAlign={'center'}
                        onClick={() => renderDetailModal(item)}
                      >
                        <p style={{ color: 'gray', padding: 0, margin: 0 }}>
                          {item.policyPeriodStart
                            ? moment(
                                adjustDateForTimezone(item.policyPeriodStart)
                              ).format('DD/MM/YYYY')
                            : null}
                        </p>
                        <p style={{ color: '#964800', padding: 0, margin: 0 }}>
                          {item.policyPeriodEnd
                            ? moment(adjustDateForTimezone(item.policyPeriodEnd)).format(
                                'DD/MM/YYYY'
                              )
                            : null}
                        </p>
                      </ClickableTableCell>

                      <ClickableTableCell
                        textAlign={'center'}
                        onClick={() => renderDetailModal(item)}
                      >
                        {item.status && translateProposalStatus(item.status)}
                      </ClickableTableCell>

                      <ClickableTableCell
                        width={1}
                        textAlign={'center'}
                        onClick={() => renderDetailModal(item)}
                      >
                        <strong>
                          R$: {maskMoney(fireInsuranceAnalysis.grossPremium, true)}
                        </strong>
                        <br />
                        <span style={{ color: '#808080', fontSize: '11px' }}>
                          ({fireInsuranceAnalysis.installments}x) R$:{' '}
                          {fireInsuranceAnalysis.value}
                        </span>
                      </ClickableTableCell>

                      <Table.Cell textAlign={'center'}>
                        <Menu compact>
                          <Menu.Item>
                            <AuthorizationContainer
                              action={ActionEnum.download}
                              feature={FeatureEnum.files}
                            >
                              <Popup
                                content={'Gerenciar Anexos'}
                                open={popupString === item.id}
                                trigger={
                                  <ModalAttachFiles
                                    bondInsuranceId={
                                      item.fireInsuranceAnalysis.fireInsuranceId
                                    }
                                    tableName={'fire_insurance'}
                                    fileIdentifiers={fileIdentifiers}
                                    brokerOnly={true}
                                    message={''}
                                    trigger={
                                      <Button
                                        icon
                                        color="teal"
                                        onMouseEnter={() => setPopupString(item.id)}
                                        onMouseLeave={() => setPopupString('')}
                                      >
                                        <Icon name={'attach'} />
                                      </Button>
                                    }
                                  />
                                }
                              />
                            </AuthorizationContainer>
                          </Menu.Item>

                          <Menu.Item>
                            <Dropdown
                              trigger={
                                <IconButton name="ellipsis vertical" color="blue" />
                              }
                              icon={null}
                              direction="left"
                            >
                              <Dropdown.Menu style={{ zIndex: 1000 }}>
                                <Dropdown.Item
                                  icon="refresh"
                                  text={'Solicitar Renovação'}
                                  onClick={() => {
                                    props.updateCurrentPage({
                                      id: 4,
                                      clickHandler: (e: any, data: any) => {},
                                      name: 'fire-insurance-budget',
                                      route: 'fire-insurance-budget',
                                      text: 'Cotação',
                                      title: 'Novo orçamento de seguro incêndio'
                                    });
                                    history.push(
                                      `/admin/fire-insurance-budget/${item.fireInsuranceAnalysis?.id}?context=${BudgetContext.renewal}&policyOrigin=${fireInsuranceAnalysis.insurer.code}&estateId=${fireInsurance.estateId}`
                                    );
                                  }}
                                />
                                {/* <AuthorizationContainer
                                  action={ActionEnum.update}
                                  feature={FeatureEnum.fireinsurances}
                                > */}
                                <Dropdown.Item
                                  icon="trash"
                                  text={'Solicitar Cancelamento'}
                                  disabled={
                                    item.updatedAt === null ||
                                    item.status === 'CANCELED' ||
                                    item.status === 'CANCELATION_REQUEST' ||
                                    differenceInHours(today, lastUpdate) < -1
                                  }
                                  onClick={() => handleOpenCancelModal(item)}
                                />
                                {/* </AuthorizationContainer> */}

                                {(item.status === 'CANCELED' ||
                                  item.status === 'CANCELATION_REQUEST') && (
                                  <AuthorizationContainer
                                    action={ActionEnum.update}
                                    feature={FeatureEnum.fireinsurances}
                                  >
                                    <Dropdown.Item
                                      icon="edit"
                                      text={'Editar Cancelamento'}
                                      disabled={
                                        item.status !== 'CANCELED' &&
                                        item.status !== 'CANCELATION_REQUEST'
                                      }
                                      onClick={() => handleOpenEditCancelModal(item)}
                                    />
                                  </AuthorizationContainer>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </Menu.Item>
                        </Menu>
                      </Table.Cell>
                    </ClickableTableRow>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={9} textAlign={'center'}>
                    Nenhum registro encontrado
                  </Table.Cell>
                </Table.Row>
              )}
            </>
          )}
        </Table.Body>

        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan={9} textAlign={'right'}>
              {!isLoadingData && (
                <Menu floated="right" pagination>
                  <Menu.Item>
                    <p>{`${data.length ? currentPage * 10 - 9 : 0} - ${
                      count < currentPage * 10 ? count : currentPage * 10
                    } de ${count}`}</p>
                  </Menu.Item>
                  <Menu.Item
                    disabled={currentPage === 1}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(1)}
                  >
                    <Icon name="angle double left" />
                  </Menu.Item>
                  <Menu.Item
                    disabled={currentPage === 1}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item as="a">{currentPage}</Menu.Item>
                  <Menu.Item
                    disabled={
                      currentPage === Math.floor(count / 10) + 1 || data.length < 10
                    }
                    as="a"
                    icon
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                  <Menu.Item as="a" icon>
                    <Icon
                      disabled={
                        currentPage === Math.floor(count / 10) + 1 || data.length < 10
                      }
                      name="angle double right"
                      onClick={() => setCurrentPage(Math.floor(count / 10) + 1)}
                    />
                  </Menu.Item>
                </Menu>
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>

      <DetailModal
        fireInsurancePolicy={{ ...currentDetailPolicy, policyCancelation }}
        open={openModal}
        onClose={handleCloseModal}
        key={'detailPolicy'}
      />

      <CancelPolicyModal
        open={openCancelModal}
        close={handleCloseCancelModal}
        policyId={currentPolicyId}
        insurerCode={currentInsurerCode}
      />

      {openEditCancelModal && (
        <EditCancelPolicyModal
          open={openEditCancelModal}
          close={handleCloseEditCancelModal}
          data={editCancelData}
        />
      )}
    </>
  );
};

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user,
  currentPage: state.currentPage
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(
      availableBrokers
    ),
  updateCurrentPage: (state: CurrentPageState) =>
    dispatch.currentPage.updateCurrentPage(state)
});

export default connect(mapState, mapDispatch)(FireInsurancePolicy);
