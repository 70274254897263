import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Grid, Image } from 'semantic-ui-react';
import { Field } from 'formik';
import { EstateFormProps } from '../UpdateEstateForm';
import { list } from '../../../services/insurer';
import { dangerNotification } from '../../../services/notification';
import Input from '../../../components/Input';
import Toggle from '../../../components/Toggle';
import SectionLabel from '../../../components/SectionLabel';
import { FormikErrorMessage } from '../../../components/ErrorMessage';
import { FieldLabel } from '../../../styles';
import {
  InsurersImageWrapper,
  FireInsurersRow,
  InsurerCard,
  InsurerCardContent,
  InsurerCardContentTitle,
  InsurerCardIcon,
  InsurerCardToggleContent,
  NoParamsMessage
} from '../styles';
import { InsurersCodeEnum } from '../../../enums/insurers-code.enum';
import styled from 'styled-components';
import AlfaFireInsuranceConfig from '../../estates-form/components/AlfaFireInsuranceConfig';

export const FireInsuranceData = (props: EstateFormProps) => {
  const { errors, touched, handleInputChange, values, setFieldValue } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [availableInsurers, setAvailableInsurers] = useState<any[]>([]);
  const [isLibertySelected, setIsLibertySelected] = useState<boolean>(false);
  const [isPottencialSelected, setIsPottencialSelected] = useState<boolean>(
    values.isPottencialFireInsuranceSelected
  );
  const [isTokioMarineSegimobSelected, setIsTokioMarineSegimobSelected] =
    useState<boolean>(values.isTokioMarineSegimobSelected);
  const [insurerSelected, setInsurerSelected] = useState<any>();

  useEffect(() => {
    setIsLoading(true);

    list({ availability: 'FIRE_INSURANCE' })
      .then(result => {
        setAvailableInsurers(result);
      })
      .catch(error => {
        dangerNotification(
          'Oops...',
          'Não foi possível buscar as seguradoras disponíveis'
        );
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLibertySelected(
      props.values.isLibertyFireInsuranceSelected ||
        values?.fireInsurers?.find((i: any) => i.code === InsurersCodeEnum.Liberty)
    );
  }, []);

  const handleToggle = async (e: SyntheticEvent, value: any) => {
    if (value) {
      const insurer = availableInsurers.find(i => i.id == value.name);
      if (value.checked && insurer) {
        if (value.id.includes(InsurersCodeEnum.Liberty)) {
          setIsLibertySelected(true);
          setFieldValue('isLibertyFireInsuranceSelected', true);
        }

        if (value.id.includes(InsurersCodeEnum.Pottencial)) {
          setFieldValue('isPottencialFireInsuranceSelected', true);
          setIsPottencialSelected(true);
        }

        if (value.id.includes(InsurersCodeEnum.TokioMarineSegimob)) {
          setFieldValue('isTokioMarineSegimobSelected', true);
          setIsTokioMarineSegimobSelected(true);
        }

        values.fireInsurers.push({
          id: insurer.id,
          code: insurer.code,
          name: insurer.name,
          imageUrl: insurer.image
        });
      } else if (!value.checked && insurer) {
        if (value.id.includes(InsurersCodeEnum.Liberty)) {
          setIsLibertySelected(false);
          setFieldValue('isLibertyFireInsuranceSelected', false);
        }

        if (value.id.includes(InsurersCodeEnum.Pottencial)) {
          setFieldValue('isPottencialFireInsuranceSelected', false);
          setIsPottencialSelected(false);
        }

        if (value.id.includes(InsurersCodeEnum.TokioMarineSegimob)) {
          setFieldValue('isTokioMarineSegimobSelected', false);
          setIsTokioMarineSegimobSelected(false);
        }

        values.fireInsurers = values.fireInsurers.filter((i: any) => i.id != value.name);
      }
      setFieldValue(`values.fireInsurers`, values.fireInsurers);
    }
  };

  return (
    <>
      <SectionLabel text="SEGURO INCÊNDIO" />
      <FireInsurersRow>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          {availableInsurers.map((insurerOption: any) => (
            <InsurerCard
              key={insurerOption.id}
              isSelected={insurerSelected?.id === insurerOption.id}
              onClick={() => setInsurerSelected(insurerOption)}
            >
              <InsurerCardContent>
                <InsurerCardIcon src={insurerOption.image} />
                <InsurerCardContentTitle>{insurerOption.name}</InsurerCardContentTitle>
              </InsurerCardContent>
              <InsurerCardToggleContent>
                <Toggle
                  name={insurerOption.id}
                  id={`updateEstate-fireInsurance-${insurerOption.code}`}
                  label={
                    values.fireInsurers.find((i: any) => i.id === insurerOption.id)
                      ? 'ATIVA'
                      : 'INATIVA'
                  }
                  checked={
                    !!values.fireInsurers.find((i: any) => i.id === insurerOption.id)
                  }
                  onChange={handleToggle}
                />
              </InsurerCardToggleContent>
            </InsurerCard>
          ))}
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <FieldLabel>CONFIGURAÇÕES DA SEGURADORA</FieldLabel>
          <p style={{ padding: 0, color: 'rgba(54, 68, 69, 0.5)' }}>
            Altere os parâmetros de configuração da seguradora.{' '}
          </p>
          {insurerSelected?.code.includes(InsurersCodeEnum.Pottencial) ? (
            <Grid>
              <Grid.Row columns={'equal'}>
                <Grid.Column>
                  <FieldLabel>Comissão Padrão (Porcentagem)* </FieldLabel>
                  <Field
                    name="insurerSpecificData.pottencial.fireInsurance.commissionPercentage"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) => {
                      handleInputChange(data, props);
                    }}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e.target.value
                        .replace(/,/g, '.')
                        .replace(/[^\d.]/g, '');

                      if (Number(value) > 100) {
                        setFieldValue(
                          'insurerSpecificData.pottencial.fireInsurance.commissionPercentage',
                          Number(100).toFixed(2)
                        );
                        return;
                      }

                      if (Number(value) < 0) {
                        setFieldValue(
                          'insurerSpecificData.pottencial.fireInsurance.commissionPercentage',
                          0
                        );
                        return;
                      }
                      setFieldValue(
                        'insurerSpecificData.pottencial.fireInsurance.commissionPercentage',
                        Number(value).toFixed(2)
                      );
                    }}
                    type="number"
                    max={100.0}
                    min={1.0}
                    component={Input}
                    className={
                      errors &&
                      errors.pottencialfireInsuranceCommissionPercentage &&
                      touched &&
                      touched.pottencialfireInsuranceCommissionPercentage
                        ? 'text-input error'
                        : 'text-input'
                    }
                    fluid
                  />
                  <FormikErrorMessage
                    component="div"
                    name="insurerSpecificData.pottencial.fireInsurance.commissionPercentage"
                  />
                </Grid.Column>
                <Grid.Column>
                  <FieldLabel>Multiplicador*</FieldLabel>
                  <Field
                    name="insurerSpecificData.pottencial.fireInsurance.multiplier"
                    component={Input}
                    className={
                      errors &&
                      errors.pottencialfireInsuranceCommissionMultiplier &&
                      touched &&
                      touched.pottencialfireInsuranceCommissionMultiplier
                        ? 'text-input error'
                        : 'text-input'
                    }
                    onChange={(e: SyntheticEvent, data: any) =>
                      handleInputChange(data, props)
                    }
                    fluid
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = Number(e.target.value.replace(/\D/g, ''));
                      if (Number(value) > 500) {
                        setFieldValue(
                          'insurerSpecificData.pottencial.fireInsurance.multiplier',
                          500
                        );
                        return;
                      }

                      if (Number(value) < 100) {
                        setFieldValue(
                          'insurerSpecificData.pottencial.fireInsurance.multiplier',
                          100
                        );
                        return;
                      }
                      setFieldValue(
                        'insurerSpecificData.pottencial.fireInsurance.multiplier',
                        value
                      );
                    }}
                    type="number"
                    max={500}
                    min={100}
                  />
                  <FormikErrorMessage
                    component="div"
                    name="insurerSpecificData.pottencial.fireInsurance.multiplier"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ): insurerSelected?.code.includes(InsurersCodeEnum.alfa) ? (
            <AlfaFireInsuranceConfig
              {...props}
            />
          ) : null}
        {insurerSelected &&
            ![InsurersCodeEnum.Pottencial, InsurersCodeEnum.alfa].includes(
              insurerSelected?.code
            ) && (
            <NoParamsMessage>
              *Não há parâmetros de configuração disponíveis para a seguradora
              selecionada.
            </NoParamsMessage>
          )}
        </Grid.Column>
      </FireInsurersRow>
    </>
  );
};
