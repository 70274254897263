import * as Storage from './storage';
import { get, post } from './request';
import { DateToStringDate, DateToStringTime } from './date';
import { maskMoney, formatAmount } from './masks';
import { BondInsuranceAnalysisStatusEnum } from '../enums/bond-insurance-analysis-status.enum';
import { getBondInsuranceProposalStatusLabel } from './bond-insurance-proposal';
import { getBondInsurancePolicyStatusLabel } from './bond-insurance-policy';
import { BondInsuranceStatusEnum } from '../enums/bond-insurance-status.enum';

type AnalysisPayload = {
  start?: string;
  end?: string;
  initialValue?: number;
  finalValue?: number;
  analysisStatus?: string;
  estate_id?: string;
  insurer?: string;
  accountManager?: string;
  expiresInDays?: number;
  isReport?: boolean;
}

const brokerId = window.location.hostname.includes('4seg.com.br') ? '7554f468-bce2-4e41-91c1-de527fb01f47' : '7554f468-bce2-4e41-91c1-de527fb01f47'

export type AnalysisResponse = {
  estate?: string;
  tenant?: string;
  document?: string;
  insurer?: string;
  createdAt?: string;
  accountManager?: string;
  analysisStatus?: string;
  rent?: string;
  condominium?: string;
  gas_bill?: string;
  water_bill?: string;
  energy_bill?: string;
  taxes?: string;
  status?: string;
}

type ProposalPayload = {
  estate_id?: string;
  start?: string;
  end?: string;
  status?: number;
  insurer?: string;
  initialValue?: number;
  finalValue?: number;
  accountManager?: string;
  isReport?: boolean;
}

// IMOBILIÁRIA	LOCATÁRIO	SEGURADORA	CRIADO EM	GESTOR DE CONTAS	STATUS DA ANÁLISE	ALUGUEL	CONDOMÍNIO	GÁS	ÁGUA	ENERGIA	TAXAS
//IMOBILIÁRIA	LOCATÁRIO	SEGURADORA	CRIADO EM	STATUS DA PROPOSTA	TOTAL DE PARCELAS	VALOR DA PARCELA	PRÊMIO TOTAL
export type ProposalResponse = {
  estate?: string;
  tenant?: string;
  document?: string;
  insurer?: string;
  createdAt?: string;
  proposalStatus?: string;
  totalInstallments?: string;
  installmentValue?: string;
  totalPremiumAmount?: string;
  accountManager?: string;
}

type PolicyPayload = {
  estateId?: string;
  start?: string;
  end?: string;
  status?: number;
  insurer?: string;
  initialValue?: number;
  finalValue?: number;
  accountManager?: string;
  isReport?: boolean;
}

// IMOBILIÁRIA	LOCATÁRIO	SEGURADORA	EMITIDA EM	CÓDIGO	STATUS DA APÓLICE	TOTAL DE PARCELAS	VALOR DA PARCELA	PRÊMIO TOTAL
export type PolicyResponse = {
  estate?: string;
  tenant?: string;
  document?: string;
  insurer?: string;
  selectedPlan?: string;
  createdAt?: string;
  code: string;
  policyStatus?: string;
  totalInstallments?: string;
  installmentValue?: string;
  totalPremiumAmount?: string;
  rent?: string;
  condominium?: string;
  gas_bill?: string;
  water_bill?: string;
  energy_bill?: string;
  taxes?: string;
  accountManager?: string;
  status?: string;
  origin?: string;
}

export const getAnalysisData = async (generalInfo: any, payload: any): Promise<any[]> => {

  const formatedResponse: AnalysisResponse[] = [];

  const filters = await getAnalysisPayload(generalInfo, payload);

  let query = '';

  if (filters) {
    Object.entries(filters).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`
      }
    })
  }

  let response;
  try {
    response = await get(`/bond-insurances?broker_id=${brokerId}&status=CREATED&${query}page=1`);
  } catch (e) {
    console.error(e);
    response = [];
  }

  response.data.forEach((bondInsurance: any, index: any) => {


    if (bondInsurance.bond_insurance_analysis.length === 0) {
      const tenant = bondInsurance.legal_tenant === null ? bondInsurance.natural_tenant.main_tenant_name : bondInsurance.legal_tenant.company;
      const document = bondInsurance.legal_tenant === null ? bondInsurance.natural_tenant.main_tenant_cpf : bondInsurance.legal_tenant.document;
      const analysisItem: AnalysisResponse = {
        estate: bondInsurance.estate?.name,
        tenant: tenant,
        document: document,
        insurer: 'Sem análises processadas',
        createdAt: DateToStringDate(bondInsurance.created_at),
        accountManager: bondInsurance.estate?.user.name,
        analysisStatus: getStatusText('undefined'),
        rent: maskMoney(bondInsurance.property.rent_value, true, 2),
        condominium: maskMoney(bondInsurance.property.condominium_fee, true, 2),
        gas_bill: maskMoney(bondInsurance.property.gas_bill, true, 2),
        water_bill: maskMoney(bondInsurance.property.water_bill, true, 2),
        energy_bill: maskMoney(bondInsurance.property.energy_bill, true, 2),
        taxes: maskMoney(bondInsurance.property.taxes_value, true, 2),
        status: getBondStatus(bondInsurance.status)
      };
      formatedResponse.push(analysisItem);

    } else {
      const tenant = bondInsurance.legal_tenant === null ? bondInsurance.natural_tenant.main_tenant_name : bondInsurance.legal_tenant.company;
      const document = bondInsurance.legal_tenant === null ? bondInsurance.natural_tenant.main_tenant_cpf : bondInsurance.legal_tenant.document;
      const estate = bondInsurance.estate?.name;
      bondInsurance.bond_insurance_analysis.forEach((analysis: any) => {
        const analysisItem: AnalysisResponse = {
          estate: estate,
          tenant: tenant,
          document: document,
          insurer: analysis.insurer.name,
          createdAt: DateToStringDate(bondInsurance.created_at),
          accountManager: bondInsurance.estate?.user.name,
          analysisStatus: getStatusText(analysis.status),
          rent: maskMoney(bondInsurance.property.rent_value, true, 2),
          condominium: maskMoney(bondInsurance.property.condominium_fee, true, 2),
          gas_bill: maskMoney(bondInsurance.property.gas_bill, true, 2),
          water_bill: maskMoney(bondInsurance.property.water_bill, true, 2),
          energy_bill: maskMoney(bondInsurance.property.energy_bill, true, 2),
          taxes: maskMoney(bondInsurance.property.taxes_value, true, 2),
          status: getBondStatus(bondInsurance.status)
        }
        formatedResponse.push(analysisItem);
      });
    }
  });

  return formatedResponse;
};

export const getProposalData = async (generalInfo: any, payload: any): Promise<any[]> => {

  const formatedResponse: ProposalResponse[] = [];

  const filters = await getProposalPayload(generalInfo, payload);

  let query = '';

  if (filters) {
    Object.entries(filters).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`
      }
    })
  }

  let response;
  try {
    response = await get(`/bond-insurance/proposals/?${query}page=1`);
  } catch (e) {
    console.error(e);
    response = [];
  }

  response.proposals.forEach((proposal: any) => {
    const status = getBondInsuranceProposalStatusLabel(proposal.hiredDataStatus);
    const proposalItem: ProposalResponse = {
      estate: proposal.estate.companyName,
      accountManager: proposal.estate.accountManager.name,
      tenant: proposal.tenant.name,
      document: proposal.tenant.document,
      insurer: proposal.insurer.name,
      createdAt: DateToStringDate(proposal.insurerProposalCreatedAt),
      proposalStatus: status,
      totalInstallments: proposal.values.hiredInstallments,
      installmentValue: maskMoney(proposal.values.hiredInstallmentsValue, true, 2),
      totalPremiumAmount: maskMoney(proposal.values.totalPremiumAmount, true, 2),
    };

    formatedResponse.push(proposalItem);
  });

  return formatedResponse;
};

export const getPolicyData = async (generalInfo: any, payload: any): Promise<any[]> => {
  const knownOrigins = ['MANUAL', 'QUIVER']

  const formatedResponse: PolicyResponse[] = [];

  const userId = Storage.get('uid');

  const filters = await getPolicyPayload(generalInfo, payload);

  let query = '';

  if (filters) {
    Object.entries(filters).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`
      }
    })
  }

  // if (query.includes('&status=ALL')) {
  //   query = query.replace('&status=ALL', '');
  // }

  // console.log('QUERY: ', query);
  // console.log(`/bond-insurance/policies/?userId=${userId}&brokerId=7554f468-bce2-4e41-91c1-de527fb01f47&${query}page=1`);

  let response;
  try {
    response = await get(`/bond-insurance/policies/?userId=${userId}&brokerId=${brokerId}&${query}page=1`);
  } catch (e) {
    console.error(e);
    response = [];
  }


  response.policies.forEach((policy: any) => {
    const status = getBondInsurancePolicyStatusLabel(policy.status);
    const totalAmount = maskMoney(String(policy.values.hiredInstallments * Number(policy.values.hiredInstallmentsValue)), true, 2);
    const policyItem: PolicyResponse = {
      estate: policy.estate.companyName,
      accountManager: policy.estate.accountManager.name,
      tenant: policy.tenant.name,
      document: policy.tenant.document,
      insurer: policy.insurer.name,
      selectedPlan: policy.selectedPlan,
      createdAt: DateToStringDate(policy.insurerPolicyCreatedAt),
      code: policy.insurerPolicyId,
      policyStatus: status,
      totalInstallments: policy.values.hiredInstallments,
      installmentValue: maskMoney(String(policy.values.hiredInstallmentsValue), true, 2),
      totalPremiumAmount: totalAmount,
      rent: maskMoney(policy.property.rent_value, true, 2),
      condominium: maskMoney(policy.property.condominium_fee, true, 2),
      gas_bill: maskMoney(policy.property.gas_bill, true, 2),
      water_bill: maskMoney(policy.property.water_bill, true, 2),
      energy_bill: maskMoney(policy.property.energy_bill, true, 2),
      taxes: maskMoney(policy.property.taxes_value, true, 2),
      origin: knownOrigins.includes(policy.code) ? policy.code : '4SEG',
      status: policy.bondInsurance.status === 'CANCELED' ? 'CANCELADO' : ''
    };

    formatedResponse.push(policyItem);
  });


  return formatedResponse;
};

const getAnalysisPayload = async (generalInfo: any, payload: any): Promise<AnalysisPayload> => {
  const filters: AnalysisPayload = {};

  filters.isReport = true;

  if (payload.status !== 'ALL' && payload.status !== '') {
    filters.analysisStatus = payload.status;
  }

  if (payload.startDate !== '') {
    filters.start = payload.startDate;
  }

  if (payload.endDate !== '') {
    filters.end = payload.endDate;
  }

  if (generalInfo.estate !== '') {
    filters.estate_id = generalInfo.estate;
  }

  if (generalInfo.insurer !== '') {
    filters.insurer = generalInfo.insurer;
  }

  if (generalInfo.accountManager !== '') {
    filters.accountManager = generalInfo.accountManager;
  }

  if (payload.initialValue !== 0) {
    filters.initialValue = payload.initialValue;
  }

  if (payload.finalValue !== 0) {
    filters.finalValue = payload.finalValue;
  }

  if (payload.expiresInDays !== 0) {
    filters.expiresInDays = payload.expiresInDays;
  }

  return filters;
}

const getProposalPayload = async (generalInfo: any, payload: any): Promise<ProposalPayload> => {
  const filters: ProposalPayload = {};

  filters.isReport = true;

  if (payload.status !== '') {
    filters.status = payload.status;
  }

  if (payload.startDate !== '') {
    filters.start = payload.startDate;
  }

  if (payload.endDate !== '') {
    filters.end = payload.endDate;
  }

  if (generalInfo.accountManager !== '') {
    filters.accountManager = generalInfo.accountManager;
  }

  if (payload.initialValue !== 0) {
    filters.initialValue = payload.initialValue;
  }

  if (payload.finalValue !== 0) {
    filters.finalValue = payload.finalValue;
  }

  if (generalInfo.estate !== '') {
    filters.estate_id = generalInfo.estate;
  }

  if (generalInfo.insurer !== '') {
    filters.insurer = generalInfo.insurer;
  }

  return filters;
}

const getPolicyPayload = async (generalInfo: any, payload: any): Promise<PolicyPayload> => {
  const filters: PolicyPayload = {};

  filters.isReport = true;

  if (payload.status !== '') {
    filters.status = payload.status;
  }

  if (payload.startDate !== '') {
    filters.start = payload.startDate;
  }

  if (payload.endDate !== '') {
    filters.end = payload.endDate;
  }

  if (generalInfo.accountManager !== '') {
    filters.accountManager = generalInfo.accountManager;
  }

  if (payload.initialValue !== 0) {
    filters.initialValue = payload.initialValue;
  }

  if (payload.finalValue !== 0) {
    filters.finalValue = payload.finalValue;
  }

  if (generalInfo.estate !== '') {
    filters.estateId = generalInfo.estate;
  }

  if (generalInfo.insurer !== '') {
    filters.insurer = generalInfo.insurer;
  }

  return filters;
}

const getStatusText = (status: string): string => {
  switch (status) {
    case BondInsuranceAnalysisStatusEnum.waiting:
      return "EM ESPERA";
    case BondInsuranceAnalysisStatusEnum.pending:
      return "PENDENCIADO";
    case BondInsuranceAnalysisStatusEnum.proposal:
      return "EM PROPOSTA";
    case BondInsuranceAnalysisStatusEnum.analysis:
      return "EM ANÁLISE";
    case BondInsuranceAnalysisStatusEnum.approved:
      return "APROVADO";
    case BondInsuranceAnalysisStatusEnum.rejected:
      return "REPROVADO";
    case BondInsuranceAnalysisStatusEnum.biometry:
      return "BIOMETRIA";
    default:
      return "INDEFINIDO";
  }
};

const getBondStatus = (status: string): string => {
  switch (status) {
    case BondInsuranceStatusEnum.created:
      return " ";
    case BondInsuranceStatusEnum.canceled:
      return "CANCELADO";
    case BondInsuranceStatusEnum.expired:
      return "EXPIRADO";
    default:
      return "INDEFINIDO";
  }
};
