import { BondInsuranceAnalysisStatusEnum } from "../../enums/bond-insurance-analysis-status.enum";

export type BondInsurancesTableProps = {
}

export type FilterData = {
  broker_id?: string;
  estate_id?: string;
  user_id?: string;
  address?: string;
  name?: string;
  status?: string;
  document?: string;
  start?: string;
  end?: string;
  bondInsuranceId?: string;
}
export interface ListingRouteInfo {
  id: string;
}

export const statusOptions = [
  {
    key: BondInsuranceAnalysisStatusEnum.waiting,
    text: 'EM ESPERA',
    value: BondInsuranceAnalysisStatusEnum.waiting,
    label: { color: 'grey', empty: true, circular: true },
  },
  {
    key: BondInsuranceAnalysisStatusEnum.proposal,
    text: 'EM PROPOSTA',
    value: BondInsuranceAnalysisStatusEnum.proposal,
    label: { color: 'violet', empty: true, circular: true },
  },
  {
    key: BondInsuranceAnalysisStatusEnum.analysis,
    text: 'EM ANÁLISE',
    value: BondInsuranceAnalysisStatusEnum.analysis,
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: BondInsuranceAnalysisStatusEnum.approved,
    text: 'APROVADA',
    value: BondInsuranceAnalysisStatusEnum.approved,
    label: { color: 'green', empty: true, circular: true },
  },
  {
    key: BondInsuranceAnalysisStatusEnum.pending,
    text: 'PENDENCIADO',
    value: BondInsuranceAnalysisStatusEnum.pending,
    label: { color: 'yellow', empty: true, circular: true },
  },
  {
    key: BondInsuranceAnalysisStatusEnum.rejected,
    text: 'RECUSADO',
    value: BondInsuranceAnalysisStatusEnum.rejected,
    label: { color: 'red', empty: true, circular: true },
  },
  {
    key: BondInsuranceAnalysisStatusEnum.biometry,
    text: 'BIOMETRIA',
    value: BondInsuranceAnalysisStatusEnum.biometry,
    label: { color: 'orange', empty: true, circular: true },
  },
];
