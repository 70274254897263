import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { styles } from '../styles';
import TableHeader from './TableHeader';

const TenantDataTable = ({ data }: any) => {
  return (
    <>
      <View style={styles.tableContainer}>
        <TableHeader title={'DADOS DO SEGURADO'} />

        <View style={styles.row}>
          <View style={styles.column}>
            <Text style={styles.label}>{'INQUILINO'}</Text>
            <Text style={styles.value}>{data.tenantName}</Text>
          </View>

          <View style={styles.column}>
            <Text style={styles.label}>{data.tenantIsPJ ? 'CNPJ' : 'CPF'}</Text>
            <Text style={styles.value}>{data.tenantDocument}</Text>
          </View>

          <View style={styles.column}>
            <Text style={styles.label}>{'SEXO'}</Text>
            <Text style={styles.value}>{data.tenantGender}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.column}>
            <Text style={styles.label}>{'DATA DE NASCIMENTO'}</Text>
            <Text style={styles.value}>{data.tenantBirthdate}</Text>
          </View>

          <View style={styles.column}>
            <Text style={styles.label}>{'E-MAIL'}</Text>
            <Text style={styles.value}>{data.tenantEmail}</Text>
          </View>

          <View style={styles.column}>
            <Text style={styles.label}>{'TELEFONE'}</Text>
            <Text style={styles.value}>{data.tenantCellphone}</Text>
          </View>
        </View>
      </View>
    </>
  );
};

export default TenantDataTable;
