import React from 'react';
import {
  CloseButton,
  ModalWrapper,
  TaxesInfo,
  InsurerCard,
  ModalBackdrop
} from './styles';
import { EstateInsurerDataForGetEstatesResponseDTO } from '../../../../dtos/estate/estate-for-get-estates-response.dto';
import useClickOutside from '../../../../hooks/useClickOutside';
import { AnalysisAssuranceEnum } from '../../../../enums/analysis-assurance.enum';
import { InsurersCodeEnum } from '../../../../enums/insurers-code.enum';

interface ModalProps {
  onClose: () => void;
  insurerData: EstateInsurerDataForGetEstatesResponseDTO;
}

const InsurerTaxesModal: React.FC<ModalProps> = ({ onClose, insurerData }) => {
  const ref = useClickOutside<HTMLDivElement>(() => {
    onClose();
  });

  const formatPercent = (value: string): string => (parseFloat(value) * 100).toFixed(2);

  return (
    <ModalBackdrop>
      <ModalWrapper ref={ref}>
        <InsurerCard>
          <CloseButton onClick={onClose}>&times;</CloseButton>
          <img src={insurerData?.insurer?.image} className="logo" alt="Insurer logo" />
          <div className="insurer-content">
            <span className="insurer-name">{insurerData?.insurer?.name}</span>
            <small>{insurerData?.insurer?.availability ? 'Ativa' : 'Não ativa'}</small>
          </div>
        </InsurerCard>
        <TaxesInfo
          colSpan={InsurersCodeEnum.Garantti === insurerData?.insurer?.code ? 2 : 3}
        >
          {InsurersCodeEnum.Garantti === insurerData?.insurer?.code && (
            <>
              {insurerData?.insurer?.id && (
                <div>
                  <p>
                    <b>Garantti code</b>
                  </p>
                  <span>{insurerData?.insurer?.id}</span>
                </div>
              )}
              {insurerData?.commission_percentage && (
                <div>
                  <p>
                    <b>Taxa comissão</b>
                  </p>
                  <span>{formatPercent(insurerData?.commission_percentage)}%</span>
                </div>
              )}
            </>
          )}
          {InsurersCodeEnum.Garantti !== insurerData?.insurer?.code && (
            <>
              <div>
                <p>
                  <b>Tipo de cobertura</b>
                </p>
                <span>
                  {insurerData?.assurance === AnalysisAssuranceEnum.simple
                    ? 'SIMPLES'
                    : 'COMPLETA'}
                </span>
              </div>
              {insurerData?.lmi >= 0 && (
                <div>
                  <p>
                    <b>LMI</b>
                  </p>
                  <span>{insurerData?.lmi}X</span>
                </div>
              )}
              {insurerData?.multiple && (
                <div>
                  <p>
                    <b>Múltiplo</b>
                  </p>
                  <span>{insurerData?.multiple}</span>
                </div>
              )}
              {insurerData?.insurer?.code && (
                <div>
                  <p>
                    <b>Código da seguradora</b>
                  </p>
                  <span>{insurerData?.insurer?.code}</span>
                </div>
              )}
              {insurerData?.commission_percentage && (
                <div>
                  <p>
                    <b>Taxa comissão</b>
                  </p>
                  <span>{formatPercent(insurerData?.commission_percentage)}%</span>
                </div>
              )}
              {insurerData?.pf_residential_tax && (
                <div>
                  <p>
                    <b>Taxa residencial</b>
                  </p>
                  <span>{formatPercent(insurerData?.pf_residential_tax)}%</span>
                </div>
              )}
              {insurerData?.pf_comercial_tax && (
                <div>
                  <p>
                    <b>Taxa comercial</b>
                  </p>
                  <span>{formatPercent(insurerData?.pf_comercial_tax)}%</span>
                </div>
              )}
            </>
          )}
        </TaxesInfo>
      </ModalWrapper>
    </ModalBackdrop>
  );
};

export default InsurerTaxesModal;
