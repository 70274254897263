import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Info } from "../../../../components/Info";
import { DateToStringDate } from "../../../../services/date";
import { getBondInsurancePolicyStatusLabel } from "../../../../services/bond-insurance-policy";
import SectionLabel from "../../../../components/SectionLabel";
import { formatAmount, maskMoney } from "../../../../services/masks";
import { AnalysisResponseDTO, HiredDataResponseDTO } from '../../types/bondinsurance-interfaces';

export type Props = {
  analysis: AnalysisResponseDTO;
  hiredData: HiredDataResponseDTO;
};

export const BondInsurancePolicyMainInfoDetails = (props: Props) => {
  const { analysis, hiredData} = props;
  return (
    <>
      <SectionLabel text='DADOS DA APÓLICE' />
      <Grid.Row>
        <Info name={'ID'} value={hiredData.id} width={8} />
        <Info name={'STATUS'} value={getBondInsurancePolicyStatusLabel(hiredData.status)}
          width={8}
        />
      </Grid.Row>
      <Grid.Row>
        <Info name={'CÓD. DA APÓLICE'}
          value={
            hiredData.insurerPolicyId ?
              hiredData.insurerPolicyId :
              "Apólice não emitida"
          } width={8}
        />
        <Info name={'DATA DE EMISSÃO DA APÓLICE'}
          value={hiredData.insurerPolicyCreatedAt ?
            DateToStringDate(hiredData.insurerPolicyCreatedAt) :
            "Indisponível"} width={8}
        />
      </Grid.Row>
      <Grid.Row>
        <Info name={'Nº DE PARCELAS CONTRATADAS'} value={Number(hiredData.hiredInstallments)}
          width={8}
        />
        <Info name={'VALOR DA PARCELA (R$)'} value={maskMoney(hiredData.hiredInstallmentsValue, true, 2)} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'FORMA DE PAGAMENTO'} value={hiredData.paymentWay} width={8} />
      </Grid.Row>

    {analysis.observations && <Grid.Row>
        <Info name={'Observações análise'} value={analysis.observations} width={16} />
      </Grid.Row>}
      {hiredData.observations && <Grid.Row>
        <Info name={'Observações Contratação'} value={hiredData.observations} width={16} />
      </Grid.Row>}

      {hiredData.nonRenewalReason &&<Grid.Row>
        <Info name={'Motivo De Não Renovação'} value={hiredData.nonRenewalReason} width={16} />
      </Grid.Row>}
    </>
  )
}
