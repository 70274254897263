import React, { SyntheticEvent } from 'react';

import { SubMenuItemProps, SubMenuItemsProps, MenuItemProps } from './types';

import { Icon, List } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { iRootState, iRootDispatch } from '../store';

import styled from 'styled-components';
import { CurrentPageState, CurrentToggledMenuState } from '../store/types/temp-types';
import AuthorizationContainer from '../components/AuthorizationContainer';
import { ActionEnum } from '../enums/authz-action.enum';
import { FeatureEnum } from '../enums/authz-feature.enum';

const SubMenuHeaderItem = styled(List.Item)`
  .sub-item-header {
    line-height: 15px !important;
    color: #3498db;
    text-decoration: underline;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 400 !important;
  }
`;

const SubMenuGroupList = styled(List)`
  margin-bottom: 20px !important;
  & .item {
    color: red !important;
    font-size: 10px !important;
    padding-left: 14px !important;

    &.link {
      color: #3498db !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      margin: 10px 0 !important;
      padding-left: 10px !important;
    }

    &.link:hover {
      font-weight: bold;
    }
  }

  & .item > * {
    font-size: 14px !important;
    color: #3498db !important;
  }

  .active > * {
    background-color: red !important;
    color: #ffffff !important;
  }
`;

const mapState = (state: iRootState) => ({
  currentPage: state.currentPage
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateCurrentToggledMenu: (state: CurrentToggledMenuState) =>
    dispatch.currentToggledMenu.updateCurrentToggledMenu(state),
  updateCurrentPage: (state: CurrentPageState) =>
    dispatch.currentPage.updateCurrentPage(state)
});

type Props = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch> &
  RouteComponentProps &
  SubMenuItemsProps & {
    parent: MenuItemProps;
  };

const SubMenuItem: React.FC<Props> = (submenuItem: Props) => {
  const { updateCurrentPage } = submenuItem;

  const currentPage: CurrentPageState = submenuItem.currentPage;
  const parent: MenuItemProps = submenuItem.parent;
  const history: any = submenuItem.history;
  const match: any = submenuItem.match;

  const handleClick = (
    e: SyntheticEvent,
    item: SubMenuItemProps,
    parent: MenuItemProps
  ) => {
    e.stopPropagation();

    if (item.id !== currentPage.id) {
      updateCurrentPage({
        id: item.id,
        name: item.name,
        text: item.text,
        title: item.title,
        parent: {
          id: parent.id,
          name: parent.name
        }
      });

      history.push(`${match.url}/${item.route}`);
    }
  };

  const handlePermissions = (
    subMenuName: string
  ): { action: string; feature: string } => {
    switch (subMenuName) {
      case 'Novo Usuário':
        return { action: ActionEnum.create, feature: FeatureEnum.users };
      case 'Usuários':
        return { action: ActionEnum.read, feature: FeatureEnum.users };
      case 'Nova Imobiliária':
        return { action: ActionEnum.create, feature: FeatureEnum.estates };
      case 'Imobiliárias':
        return { action: ActionEnum.read, feature: FeatureEnum.estates };
      case 'Cotação':
        return { action: ActionEnum.create, feature: FeatureEnum.fireinsurances };
      case 'Nova Análise':
        return { action: ActionEnum.create, feature: FeatureEnum.analysis };
      case 'Novo Pré-cadastro':
        return { action: ActionEnum.create, feature: FeatureEnum.preregisters };
      case 'Pré-cadastros':
        return { action: ActionEnum.read, feature: FeatureEnum.preregisters };
      case 'Gerenciar responsáveis':
        return { action: ActionEnum.changeResponsible, feature: FeatureEnum.claims };
      default:
        return { action: ActionEnum.manage, feature: FeatureEnum.analysis };
    }
  };

  return (
    <>
      <SubMenuHeaderItem>
        <strong id={submenuItem.id + ''} className="sub-item-header">
          {submenuItem.text}
        </strong>
        {submenuItem.submenus && submenuItem.submenus.length > 0 && (
          <SubMenuGroupList>
            {submenuItem.submenus.map((submenu: SubMenuItemProps, index: number) => {
              const { action, feature } = handlePermissions(submenu.text);
              return (
                <AuthorizationContainer
                  action={action}
                  feature={feature}
                  key={submenu.id}
                >
                  <List.Item
                    id={index}
                    key={submenu.id}
                    className="link"
                    onClick={(e: SyntheticEvent) => handleClick(e, submenu, parent)}
                  >
                    {currentPage.name === submenu.name ? (
                      <strong>{submenu.text}</strong>
                    ) : (
                      submenu.text
                    )}
                    <Icon name="angle right" />
                  </List.Item>
                </AuthorizationContainer>
              );
            })}
          </SubMenuGroupList>
        )}
      </SubMenuHeaderItem>
    </>
  );
};

export default withRouter(connect(mapState, mapDispatch)(SubMenuItem));
