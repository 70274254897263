import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonProps,
  Checkbox,
  Divider,
  Dropdown,
  DropdownItemProps,
  Grid,
  Icon,
  Input,
  InputOnChangeData,
  Modal,
  Radio
} from 'semantic-ui-react';
import { EditModalProps, FormProps } from './types';
import { Container, FormContainer, Form, Header, Line, FormTitle } from './styles';
import {
  dangerNotification,
  warningNotification
} from '../../../../services/notification';
import { BondInsuranceHiredDataStatusEnum } from '../../../../enums/bond-insurance-hired-data-status.enum';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import AppMaskedInput from '../../../../components/MaskedInput';
import { getBondInsuranceProposalStatusLabel } from '../../../../services/bond-insurance-proposal';
import { PersonTypesEnum } from '../../../../enums/person-types.enum';
import { PurposesEnum } from '../../../../enums/purposes.enum';
import { AnalysisAssuranceEnum } from '../../../../enums/analysis-assurance.enum';
import { CustoDropDownItemProps } from '../../../../components/types';
import {
  DATE_INPUT_MASK,
  formatAmount,
  maskCpf,
  maskCpfOrCnpj,
  maskPhoneNumber,
  unmaskCpfOrCnpj,
  unmaskMoney
} from '../../../../services/masks';
import IconButton from '../../../../components/IconButton';
import { getUsers } from '../../../../services/broker';
import { UsersToCustomDropDownOptions } from '../../../../util';
import { get } from '../../../../services/storage';
import { useQuery } from '@tanstack/react-query';
import {
  legalCommercialResidentialReasonOptions,
  naturalCommercialReasonOptions,
  naturalResidentialReasonOptions,
  validityOptions,
  genderOptions,
  employmentRelationshipOptions,
  maritalStatusOptions,
  hirePropertyTypeOptions
} from '../../../bond-insurance-budget-form/types';
import { tenantTypeOptions } from '../CreatePolicyModal/utils';
import { PoliticallyExposedPersonEnum } from '../../../../enums/politically-exposed-person.enum';
import { FieldLabel } from '../../../../styles';
import {
  SympathiseTenantHeader,
  SympathiseTenantLabel
} from '../../../bond-insurance-budget-form/style';
import { SympathiseTenantForGetBondInsurancesResponseDTO } from '../../../../dtos/bond-insurance-list/get-bond-insurances-response.dto';
import { getDataByDocument } from '../../../../services/documents';
import { FormatDateYMD } from '../../../../services/date';

const analysisOptions: CustoDropDownItemProps[] = [
  {
    key: 1,
    text: 'Plano Seguro Completo (Garantido)',
    value: AnalysisAssuranceEnum.complete
  },
  {
    key: 2,
    text: 'Plano Seguro Simples (Básico)',
    value: AnalysisAssuranceEnum.simple
  }
];

const purposeOptions: CustoDropDownItemProps[] = [
  {
    key: 1,
    text: 'Residencial',
    value: PurposesEnum.residential
  },
  {
    key: 2,
    text: 'Comercial',
    value: PurposesEnum.commercial
  }
];

const policyStatusOptions = [
  {
    index: 7,
    value: BondInsuranceHiredDataStatusEnum.insurerPolicyPending,
    text: 'Emissão de Apólice Pendente pela Seguradora'
  },
  {
    index: 9,
    value: BondInsuranceHiredDataStatusEnum.underRenewal,
    text: 'Em renovação'
  },
  {
    index: 10,
    value: BondInsuranceHiredDataStatusEnum.renewedCreated,
    text: 'Renovada'
  },
  {
    index: 11,
    value: BondInsuranceHiredDataStatusEnum.notRenewed,
    text: 'Não Renovada'
  },
  {
    index: 12,
    value: BondInsuranceHiredDataStatusEnum.insurerPolicyCreated,
    text: 'Emitida'
  },
  {
    index: 13,
    value: BondInsuranceHiredDataStatusEnum.renewCanceled,
    text: 'Renovação Cancelada'
  },
  {
    index: 14,
    value: BondInsuranceHiredDataStatusEnum.overdue,
    text: 'Vencida'
  },
  {
    index: 15,
    value: BondInsuranceHiredDataStatusEnum.overdueIssued,
    text: 'Vencida Emitida'
  },
  {
    index: 16,
    value: BondInsuranceHiredDataStatusEnum.expired,
    text: 'Expirada'
  }
];

const sympathiseTenantBlankInfo: any = {
  name: '',
  cpf: '',
  rg: '',
  rg_expeditor_organ: '',
  gender: '',
  mother_name: '',
  marital_status: '',
  nationality: '',
  profession: '',
  current_address_zipcode: '',
  monthly_fixed_income: '',
  other_incomes: '',
  document: '',
  resident: false,
  politically_exposed_person: PoliticallyExposedPersonEnum.no,
  close_to_politically_exposed_person: {
    politically_exposed_person_name: '',
    politically_exposed_person_cpf: '',
    degree_of_kinship_to_politically_exposed_person: ''
  }
};

const EditModal: React.FC<EditModalProps> = (props: EditModalProps) => {
  const {
    isOpen,
    onClose,
    handleSubmit,
    bondInsurance,
    bondInsuranceAnalysis,
    bondInsuranceHiredData,
    insurersOptions,
    estatesOptions
  } = props;
  const [sympathiseTenants, setSympathiseTenants] = useState<
    SympathiseTenantForGetBondInsurancesResponseDTO[]
  >([]);
  const [form, setForm] = useState<FormProps>({
    analysis: {
      id: '',
      value: '',
      code: undefined,
      insurerProposalId: undefined,
      insurerProposalCreatedAt: undefined,
      createdAt: undefined,
      insurerId: '',
      bondInsurance: {
        id: '',
        analysisAssurance: '',
        purpose: '',
        tenantType: '',
        tenant: {
          name: '',
          document: '',
          email: '',
          phone: '',
          gender: undefined,
          maritalStatus: undefined,
          employee: undefined,
          birthDate: undefined,
          foundation_date: undefined,
          last_contractual_alteration_date: undefined,
          share_capital: undefined,
          paid_in_capital: undefined,
          include_sympathise_tenants: false,
          sympathise_tenants: undefined
        },
        property: {
          rental_period_end_date: undefined,
          rental_period_start_date: undefined,
          rent_insurance_contract_end_date: undefined,
          rent_insurance_contract_start_date: undefined,
          rent_due_date: undefined,
          reason: undefined,
          validity: undefined,
          property_type: undefined,
          zip_code: undefined,
          street: undefined,
          city: undefined,
          district: undefined,
          number: undefined,
          state: undefined,
          complement: undefined,
          energy_bill: undefined,
          gas_bill: undefined,
          water_bill: undefined,
          condominium_fee: undefined,
          taxes_value: undefined,
          rent_value: '',
          contract_penalties_value: undefined,
          exterior_painting_value: undefined,
          furniture_damage_value: undefined,
          interior_painting_value: undefined,
          property_damage_value: undefined
        },
        estateId: '',
        ownerData: {
          name: '',
          document: '',
          address: {
            complement: undefined,
            city: undefined,
            district: undefined,
            number: undefined,
            state: undefined,
            street: undefined,
            zip_code: undefined
          }
        }
      }
    },
    hiredData: {
      id: '',
      insurerPolicyId: '',
      insurerPolicyCreatedAt: undefined,
      totalPremiumAmount: '',
      hiredInstallments: '',
      hiredInstallmentsValue: '',
      observations: '',
      validationMessage: '',
      validationStatus: '',
      status: ''
    }
  });
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [renewalResponsible, setRenewalResponsible] = useState<any>(
    bondInsuranceHiredData?.responsible
  );
  const [accountManager, setAccountManager] = useState<any>(undefined);
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>(undefined);
  const [isCpfLoading, setIsCpfLoading] = useState<boolean>(false);

  const [isQuiver, setIsQuiver] = useState<boolean>(false);
  const [isAbleToEditStatus, setIsAbleToEditStatus] = useState<boolean>(false);

  const [statusOptions, setStatusOptions] = useState<any>(policyStatusOptions)

  const userId = get('uid');

  const [reasonsOptions, setReasonsOptions] = useState<Array<DropdownItemProps>>([]);

  const ableToSetResponsible = [
    BondInsuranceHiredDataStatusEnum.renewedCreated,
    BondInsuranceHiredDataStatusEnum.underRenewal
  ];

  const ableToEditStatus = [
    BondInsuranceHiredDataStatusEnum.overdueIssued,
    BondInsuranceHiredDataStatusEnum.expired,
  ];

  const isAbleStatus = (quiver: boolean, status: BondInsuranceHiredDataStatusEnum) => {
    if (quiver) {
      setIsAbleToEditStatus(true);
      setStatusOptions(policyStatusOptions);
    } else {
      const isAble = ableToEditStatus.includes(status);
      setIsAbleToEditStatus(isAble);
      if (isAble) {
        const options = status === BondInsuranceHiredDataStatusEnum.overdueIssued ? [
          {
            index: 12,
            value: BondInsuranceHiredDataStatusEnum.insurerPolicyCreated,
            text: 'Emitida'
          },
          {
            index: 15,
            value: BondInsuranceHiredDataStatusEnum.overdueIssued,
            text: 'Vencida Emitida'
          }
        ] : [
          {
            index: 12,
            value: BondInsuranceHiredDataStatusEnum.insurerPolicyCreated,
            text: 'Emitida'
          },
          {
            index: 16,
            value: BondInsuranceHiredDataStatusEnum.expired,
            text: 'Expirada'
          }
        ];

        setStatusOptions(options);
      }
    }
  }

  const getAccountManagers = async () => {
    const result = await getUsers(userId ?? '', [{ isDropdown: true }]);
    return UsersToCustomDropDownOptions(
      false,
      result.map((user, index) => {
        return {
          id: user.id,
          name: user.name
        };
      })
    );
  };

  const { data: accountManagerOptions, isFetching: isAccountManagersLoading } = useQuery(
    ['getAccountManagers'],
    getAccountManagers,
    {
      keepPreviousData: true,
      enabled: !!userId
    }
  );

  useEffect(() => {
    modalRef?.current?.scrollIntoView({ behavior: 'auto' });
  }, [isOpen]);

  useEffect(() => {
    if (accountManagerOptions && bondInsuranceAnalysis) {
      const accManager = accountManagerOptions.find(
        acc => acc.text === bondInsuranceAnalysis?.bondInsurance.user.name
      );
      setAccountManager(accManager?.value);
    }
  }, [accountManagerOptions, bondInsuranceAnalysis]);

  useEffect(() => {
    if (bondInsuranceAnalysis) {
      if (bondInsuranceAnalysis.bondInsurance.tenantType === PersonTypesEnum.natural) {
        bondInsuranceAnalysis.bondInsurance.purpose === PurposesEnum.commercial
          ? setReasonsOptions(naturalCommercialReasonOptions)
          : setReasonsOptions(naturalResidentialReasonOptions);
      } else {
        setReasonsOptions(legalCommercialResidentialReasonOptions);
      }

      setForm((prevValue: FormProps) => ({
        ...prevValue,
        analysis: {
          ...prevValue.analysis,
          insurerProposalCreatedAt: bondInsuranceAnalysis?.insurerProposalCreatedAt
            ? new Date(bondInsuranceAnalysis?.insurerProposalCreatedAt)
            : undefined,
          id: bondInsuranceAnalysis?.id,
          analysisAssurance: bondInsuranceAnalysis?.analysisAssurance || '',
          value: formatAmount(+bondInsuranceAnalysis.value * 100),
          insurerId: bondInsuranceAnalysis?.insurer?.id,
          createdAt: new Date(bondInsuranceAnalysis?.createdAt),
          code: bondInsuranceAnalysis.code || undefined,
          insurerProposalId: bondInsuranceAnalysis.insurerProposalId || undefined
        }
      }));
    }
  }, [bondInsuranceAnalysis]);

  useEffect(() => {
    if (bondInsuranceHiredData) {
      if (bondInsuranceHiredData.code === 'QUIVER') setIsQuiver(true);
      setSelectedStatus(bondInsuranceHiredData?.status);
      isAbleStatus(bondInsuranceHiredData.code === 'QUIVER', bondInsuranceHiredData?.status);
      setForm((prevValue: FormProps) => {
        return {
          ...prevValue,
          hiredData: {
            ...prevValue.hiredData,
            id: bondInsuranceHiredData?.id,
            observations: bondInsuranceHiredData?.observations || '',
            hiredInstallments:
              bondInsuranceHiredData?.hiredInstallments?.toString() || '',
            hiredInstallmentsValue:
              bondInsuranceHiredData?.hiredInstallmentsValue?.toString() || '',
            insurerPolicyId: bondInsuranceHiredData?.insurerPolicyId || '',
            insurerPolicyCreatedAt: bondInsuranceHiredData?.insurerPolicyCreatedAt
              ? new Date(bondInsuranceHiredData?.insurerPolicyCreatedAt)
              : undefined,
            totalPremiumAmount:
              bondInsuranceHiredData?.totalPremiumAmount?.toString() || '',
            validationMessage: bondInsuranceHiredData?.validationMessage || '',
            validationStatus: bondInsuranceHiredData?.validationStatus || '',
            status: bondInsuranceHiredData?.status.toString() || ''
          }
        };
      });
    }
  }, [bondInsuranceHiredData]);

  useEffect(() => {
    if (bondInsurance) {
      const purpose = bondInsurance?.purpose
        ? bondInsurance?.purpose === ''
          ? purposeOptions[0].value
          : bondInsurance?.purpose
        : purposeOptions[0].value;
      setForm((prevValue: FormProps) => {
        return {
          ...prevValue,
          analysis: {
            ...prevValue.analysis,
            bondInsurance: {
              ...prevValue.analysis?.bondInsurance,
              id: bondInsurance.id || '',
              analysisAssurance: bondInsurance?.analysisAssurance ?? 'COMPLETE',
              purpose: purpose,
              tenantType: bondInsurance?.tenantType ?? tenantTypeOptions[0].value,
              tenant: {
                ...prevValue.analysis?.bondInsurance?.tenant,
                name:
                  bondInsurance.naturalTenant?.main_tenant_name ||
                  bondInsurance.legalTenant?.company ||
                  '',
                document:
                  bondInsurance.naturalTenant?.main_tenant_cpf ||
                  bondInsurance.legalTenant?.document ||
                  '',
                email:
                  bondInsurance.naturalTenant?.main_tenant_email ||
                  bondInsurance.legalTenant?.email ||
                  '',
                phone:
                  bondInsurance.naturalTenant?.main_tenant_phone ||
                  bondInsurance.legalTenant?.phone ||
                  '',
                gender: bondInsurance.naturalTenant?.main_tenant_gender || '',
                maritalStatus:
                  bondInsurance.naturalTenant?.main_tenant_marital_status || '',
                employee: bondInsurance.naturalTenant?.employment_relationship || '',
                birthDate: bondInsurance.naturalTenant?.main_tenant_birth_date
                  ? new Date(bondInsurance.naturalTenant?.main_tenant_birth_date)
                  : undefined,
                foundation_date: bondInsurance.legalTenant?.foundation_date
                  ? new Date(bondInsurance.legalTenant?.foundation_date)
                  : undefined,
                last_contractual_alteration_date: bondInsurance.legalTenant
                  ?.last_contractual_alteration_date
                  ? new Date(bondInsurance.legalTenant?.last_contractual_alteration_date)
                  : undefined,
                share_capital: bondInsurance.legalTenant?.share_capital || undefined,
                paid_in_capital: bondInsurance.legalTenant?.paid_in_capital || undefined,
                include_sympathise_tenants:
                  bondInsurance?.naturalTenant?.include_sympathise_tenants || false,
                sympathise_tenants:
                  bondInsurance?.naturalTenant?.sympathise_tenants?.map(tenant => ({
                    ...tenant,
                    cpf: maskCpf(tenant.cpf),
                    phone: tenant.phone ? maskPhoneNumber(tenant.phone) : '',
                    birth_date: tenant.birth_date
                      ? new Date(tenant.birth_date)
                      : undefined
                  })) || undefined
              },
              property: {
                rental_period_end_date: bondInsurance.property?.rental_period_end_date
                  ? new Date(bondInsurance.property?.rental_period_end_date)
                  : undefined,
                rental_period_start_date: bondInsurance.property?.rental_period_start_date
                  ? new Date(bondInsurance.property?.rental_period_start_date)
                  : undefined,
                rent_insurance_contract_end_date: bondInsurance.property
                  ?.rent_insurance_contract_end_date
                  ? new Date(bondInsurance.property?.rent_insurance_contract_end_date)
                  : undefined,
                rent_insurance_contract_start_date: bondInsurance.property
                  ?.rent_insurance_contract_start_date
                  ? new Date(bondInsurance.property?.rent_insurance_contract_start_date)
                  : undefined,
                rent_due_date:
                  (bondInsurance.property?.rent_due_date &&
                    +bondInsurance.property?.rent_due_date) ||
                  undefined,
                reason: bondInsurance.property.reason || 'Imóvel próximo de familiares',
                validity: bondInsurance.property.validity ?? undefined,
                property_type: bondInsurance.property.property_type ?? undefined,
                zip_code: bondInsurance.property.zip_code ?? '',
                street: bondInsurance.property.street ?? '',
                city: bondInsurance.property.city ?? '',
                district: bondInsurance.property.district ?? '',
                number: bondInsurance.property.number ?? '',
                state: bondInsurance.property.state ?? '',
                complement: bondInsurance.property.complement ?? '',
                rent_value: formatAmount(+bondInsurance.property.rent_value * 100) ?? '',
                energy_bill: formatAmount(+bondInsurance.property.energy_bill * 100) ?? '',
                gas_bill: formatAmount(+bondInsurance.property.gas_bill * 100) ?? '',
                water_bill: formatAmount(+bondInsurance.property.water_bill * 100) ?? '',
                condominium_fee: formatAmount(+bondInsurance.property.condominium_fee * 100) ?? '',
                taxes_value: formatAmount(+bondInsurance.property.taxes_value * 100) ?? '',
                contract_penalties_value:  formatAmount(+(bondInsurance.property.contract_penalties_value || 0) * 100) ?? '',
                exterior_painting_value: formatAmount(+(bondInsurance.property.exterior_painting_value || 0) * 100) ?? '',
                furniture_damage_value: formatAmount(+(bondInsurance.property.furniture_damage_value || 0) * 100) ?? '',
                interior_painting_value: formatAmount(+(bondInsurance.property.interior_painting_value || 0) * 100) ?? '',
                property_damage_value: formatAmount(+(bondInsurance.property.property_damage_value || 0) * 100) ?? ''
              },
              estateId: bondInsurance.estate.id,
              ownerData: {
                ...prevValue.analysis?.bondInsurance?.ownerData,
                name: bondInsurance.ownerData?.name || '',
                document: maskCpfOrCnpj(
                  bondInsurance.ownerData?.cpf || bondInsurance.ownerData?.cnpj || ''
                ),
                address: {
                  zip_code: bondInsurance.ownerData?.address
                    ? bondInsurance.ownerData?.address.zip_code
                    : '',
                  street: bondInsurance.ownerData?.address
                    ? bondInsurance.ownerData?.address.street
                    : '',
                  state: bondInsurance.ownerData?.address
                    ? bondInsurance.ownerData?.address.state
                    : '',
                  district: bondInsurance.ownerData?.address
                    ? bondInsurance.ownerData?.address.district
                    : '',
                  number: bondInsurance.ownerData?.address
                    ? bondInsurance.ownerData?.address.number
                    : '',
                  city: bondInsurance.ownerData?.address
                    ? bondInsurance.ownerData?.address.city
                    : '',
                  complement: bondInsurance.ownerData?.address
                    ? bondInsurance.ownerData?.address.complement
                    : ''
                }
              }
            }
          }
        };
      });
    }
  }, [bondInsurance]);

  useEffect(() => {
    if (form?.analysis?.bondInsurance?.tenant?.sympathise_tenants) {
      const newSympathiseTenants =
        form.analysis.bondInsurance?.tenant?.sympathise_tenants?.map(
          (sympathiseTenant: SympathiseTenantForGetBondInsurancesResponseDTO) => ({
            ...sympathiseTenant,
            cpf: maskCpfOrCnpj(sympathiseTenant.cpf),
            phone: sympathiseTenant.phone ? maskPhoneNumber(sympathiseTenant.phone) : ''
          })
        );
      setSympathiseTenants(newSympathiseTenants || []);
    } else if (form.analysis.bondInsurance?.tenant?.sympathise_tenants?.length === 0) {
      // Start the form with the empty fields
      setSympathiseTenants([sympathiseTenantBlankInfo]);
    }
  }, [form.analysis.bondInsurance?.tenant?.sympathise_tenants]);

  if (!isOpen) {
    return null;
  }

  const renderActionButtons = (): React.ReactNode => {
    return (
      <Modal.Actions>
        <Button type="button" color="red" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          type="submit"
          color="green"
          form="policy-form"
          onClick={e => {
            e.preventDefault();
            console.log('FORM', form)
            handleSubmit({
              ...form,
              analysis: {
                ...form.analysis,
                bondInsurance: {
                  ...form.analysis.bondInsurance,
                  property: {
                    ...form.analysis.bondInsurance.property,
                    rent_value: String(unmaskMoney(form.analysis.bondInsurance.property.rent_value)),
                    energy_bill: form.analysis.bondInsurance.property.energy_bill ? String(unmaskMoney(form.analysis.bondInsurance.property.energy_bill)) : '',
                    gas_bill: form.analysis.bondInsurance.property.gas_bill ? String(unmaskMoney(form.analysis.bondInsurance.property.gas_bill)) : '',
                    water_bill: form.analysis.bondInsurance.property.water_bill ? String(unmaskMoney(form.analysis.bondInsurance.property.water_bill)) : '',
                    condominium_fee: form.analysis.bondInsurance.property.condominium_fee ? String(unmaskMoney(form.analysis.bondInsurance.property.condominium_fee)) : '',
                    taxes_value: form.analysis.bondInsurance.property.taxes_value ? String(unmaskMoney(form.analysis.bondInsurance.property.taxes_value)) : '',
                    contract_penalties_value: form.analysis.bondInsurance.property.contract_penalties_value ? String(unmaskMoney(form.analysis.bondInsurance.property.contract_penalties_value)) : '',
                    exterior_painting_value: form.analysis.bondInsurance.property.exterior_painting_value ? String(unmaskMoney(form.analysis.bondInsurance.property.exterior_painting_value)) : '',
                    furniture_damage_value: form.analysis.bondInsurance.property.furniture_damage_value ? String(unmaskMoney(form.analysis.bondInsurance.property.furniture_damage_value)) : '',
                    interior_painting_value: form.analysis.bondInsurance.property.interior_painting_value ? String(unmaskMoney(form.analysis.bondInsurance.property.interior_painting_value)) : '',
                    property_damage_value: form.analysis.bondInsurance.property.property_damage_value ? String(unmaskMoney(form.analysis.bondInsurance.property.property_damage_value)) : ''

                  },
                  tenant: {
                    ...form.analysis.bondInsurance.tenant,
                    document: unmaskCpfOrCnpj(form.analysis.bondInsurance.tenant.document),
                    include_sympathise_tenants: sympathiseTenants
                      ? sympathiseTenants.length > 0
                      : false,
                    sympathise_tenants:
                      sympathiseTenants?.map(sympTenant => ({
                        ...sympTenant,
                        monthly_fixed_income: sympTenant?.monthly_fixed_income
                          ? unmaskMoney(`${sympTenant.monthly_fixed_income}`)
                          : 0,
                        other_incomes: sympTenant?.other_incomes
                          ? unmaskMoney(`${sympTenant.other_incomes}`)
                          : 0,
                        birth_date: sympTenant.birth_date
                          ? FormatDateYMD(new Date(sympTenant.birth_date))
                          : undefined
                      })) || []
                  }
                }
              }
            });
          }}
        >
          Salvar
        </Button>
      </Modal.Actions>
    );
  };

  const renderPolicyForm = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>ID</label>
            <Input disabled fluid value={bondInsuranceHiredData?.id} />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>STATUS</label>
            <Dropdown
              fluid
              search
              clearable
              selection
              value={selectedStatus}
              options={statusOptions}
              placeholder={'Selecionar...'}
              disabled={!isAbleToEditStatus}
              onChange={(e, { value }) => {
                setSelectedStatus(value?.toString());
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  hiredData: {
                    ...prevValue.hiredData,
                    status: value?.toString() || ''
                  }
                }));
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>COD. DA APÓLICE</label>
            <Input
              placeholder="Código da Apólice"
              onChange={(_, data) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  hiredData: {
                    ...prevValue.hiredData,
                    insurerPolicyId: data?.value
                  }
                }))
              }
              error={isValidForm && form.hiredData?.insurerPolicyId === ''}
              fluid
              value={form.hiredData?.insurerPolicyId}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>DATA DE EMISSÃO DA APÓLICE</label>
            <CustomDatePicker
              selected={form.hiredData?.insurerPolicyCreatedAt ?? undefined}
              onChange={(date: Date) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  hiredData: {
                    ...prevValue.hiredData,
                    insurerPolicyCreatedAt: date
                  }
                }))
              }
              customInput={
                <AppMaskedInput
                  error={isValidForm && !form.hiredData?.insurerPolicyCreatedAt}
                  mask={DATE_INPUT_MASK}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              style={{
                marginTop: 0
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>NÚMERO DE PARCELAS CONTRATADAS</label>
            <Input
              type="number"
              placeholder="Número de parcelas contratadas"
              min={1}
              max={60}
              onChange={(_, data) => {
                if (data?.value && +data?.value > 60) {
                  warningNotification('Atenção', 'O número máximo de parcelas é 60');
                  return;
                }

                if (data?.value && +data?.value < 1) {
                  warningNotification('Atenção', 'O número mínimo de parcelas é 1');
                  return;
                }

                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  hiredData: {
                    ...prevValue.hiredData,
                    hiredInstallments: data?.value as string
                  }
                }));
              }}
              error={isValidForm && !form.hiredData?.hiredInstallments}
              fluid
              value={+form.hiredData?.hiredInstallments}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <label>VALOR PARCELA CONTRATADA (R$)</label>
            <Input
              placeholder="Valor da Parcela"
              value={formatAmount(form.hiredData?.hiredInstallmentsValue)}
              onChange={(_, data) =>
                setForm((prevValue: FormProps) => {
                  return {
                    ...prevValue,
                    hiredData: {
                      ...prevValue.hiredData,
                      hiredInstallmentsValue: data?.value
                    }
                  };
                })
              }
              error={isValidForm && !form.hiredData?.hiredInstallmentsValue}
              fluid
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <label>FORMA DE PAGAMENTO</label>
            <Input
              placeholder="Forma de pagamento"
              value={bondInsuranceHiredData?.paymentWay || 'FATURA'}
              disabled
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        {ableToSetResponsible.includes(bondInsuranceHiredData!.status) && (
          <Grid.Row>
            <Grid.Column width={8}>
              <label style={{ paddingBottom: '6px' }}>Responsável pela Renovação:</label>
              <Dropdown
                fluid
                value={renewalResponsible}
                name={`accountManager`}
                placeholder={'Selecionar...'}
                options={accountManagerOptions}
                disabled={isAccountManagersLoading}
                loading={isAccountManagersLoading}
                search
                selection
                onChange={(e, { value }) => {
                  setRenewalResponsible(value);
                  setForm((prevValue: FormProps) => ({
                    ...prevValue,
                    hiredData: {
                      ...prevValue.hiredData,
                      renewalResponsible: value
                    }
                  }));
                }}
                clearable
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  };

  const renderProposalForm = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>ID</label>
            <Input disabled fluid value={bondInsuranceAnalysis?.id} />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>DATA DE EMISSÃO</label>
            <CustomDatePicker
              selected={form.analysis?.insurerProposalCreatedAt ?? undefined}
              onChange={(date: Date) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    insurerProposalCreatedAt: date
                  }
                }))
              }
              customInput={
                <AppMaskedInput
                  error={isValidForm && !form.analysis?.insurerProposalCreatedAt}
                  mask={DATE_INPUT_MASK}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              style={{
                marginTop: 0
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>SEGURADORA</label>
            <div
              style={{
                width: '100%',
                marginTop: '.5rem'
              }}
            >
              <Dropdown
                fluid
                search
                clearable
                selection
                value={form.analysis?.insurerId}
                options={insurersOptions?.map(item => ({
                  ...item,
                  value: item.value?.id
                }))}
                placeholder={'Selecionar...'}
                error={isValidForm && form.analysis?.insurerId === ''}
                onChange={(_, { value }) =>
                  setForm((prevValue: FormProps) => ({
                    ...prevValue,
                    analysis: {
                      ...prevValue.analysis,
                      insurerId: value?.toString() || ''
                    }
                  }))
                }
              />
            </div>
          </Grid.Column>
          <Grid.Column width={8}>
            <label>COD. DA COTAÇÃO</label>
            <Input
              placeholder="Código da Cotação"
              fluid
              value={form.analysis.code ?? ''}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    code: value?.toString() || ''
                  }
                }))
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>COD. DA PROPOSTA</label>
            <Input
              placeholder="Código da proposta"
              fluid
              value={form.analysis.insurerProposalId ?? ''}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    insurerProposalId: value?.toString() || ''
                  }
                }))
              }
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>STATUS</label>
            <Input
              placeholder="STATUS"
              disabled
              fluid
              value={
                getBondInsuranceProposalStatusLabel(
                  bondInsuranceHiredData?.status as BondInsuranceHiredDataStatusEnum
                ) || ''
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>VIGÊNCIA INFORMADA NA ANÁLISE</label>
            <Input
              placeholder="Vigência informada na análise"
              fluid
              value={
                form.hiredData?.hiredInstallments ?? ''
              }
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  hiredData: {
                    ...prevValue.hiredData,
                    hiredInstallments: value.toString() || ''
                  }
                }))
              }
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <label>VALOR COTADO</label>
            <Input
              placeholder="Valor Cotado"
              fluid
              value={formatAmount(form.analysis?.value || 0)}
              error={isValidForm && form.analysis?.value === ''}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    value: value || ''
                  }
                }))
              }
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <label>PRÊMIO TOTAL</label>
            <Input
              placeholder="Prêmio Total"
              fluid
              value={formatAmount(form.hiredData?.totalPremiumAmount || 0)}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  hiredData: {
                    ...prevValue.hiredData,
                    totalPremiumAmount: value
                  }
                }))
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>INÍCIO VIGÊNCIA CONTRATO LOCAÇÃO</label>
            <CustomDatePicker
              selected={
                form.analysis.bondInsurance?.property?.rental_period_start_date ??
                undefined
              }
              onChange={(date: Date) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance.property,
                        rental_period_start_date: date
                      }
                    }
                  }
                }))
              }
              customInput={
                <AppMaskedInput
                  error={
                    isValidForm &&
                    !form.analysis.bondInsurance?.property?.rental_period_start_date
                  }
                  mask={DATE_INPUT_MASK}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              style={{
                marginTop: 0
              }}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>FIM VIGÊNCIA CONTRATO LOCAÇÃO</label>
            <CustomDatePicker
              selected={
                form.analysis.bondInsurance?.property?.rental_period_end_date ?? undefined
              }
              onChange={(date: Date) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance.property,
                        rental_period_end_date: date
                      }
                    }
                  }
                }))
              }
              customInput={
                <AppMaskedInput
                  error={
                    isValidForm &&
                    !form.analysis.bondInsurance?.property?.rental_period_end_date
                  }
                  mask={DATE_INPUT_MASK}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              style={{
                marginTop: 0
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>INÍCIO VIGÊNCIA DA GARANTIA</label>
            <CustomDatePicker
              selected={
                form.analysis.bondInsurance?.property
                  ?.rent_insurance_contract_start_date ?? undefined
              }
              onChange={(date: Date) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance.property,
                        rent_insurance_contract_start_date: date
                      }
                    }
                  }
                }))
              }
              customInput={
                <AppMaskedInput
                  error={
                    isValidForm &&
                    !form.analysis.bondInsurance?.property
                      ?.rent_insurance_contract_start_date
                  }
                  mask={DATE_INPUT_MASK}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              style={{
                marginTop: 0
              }}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>FIM VIGÊNCIA DA GARANTIA</label>
            <CustomDatePicker
              selected={
                form.analysis.bondInsurance?.property?.rent_insurance_contract_end_date ??
                undefined
              }
              onChange={(date: Date) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance.property,
                        rent_insurance_contract_end_date: date
                      }
                    }
                  }
                }))
              }
              customInput={
                <AppMaskedInput
                  error={
                    isValidForm &&
                    !form.analysis.bondInsurance?.property
                      ?.rent_insurance_contract_end_date
                  }
                  mask={DATE_INPUT_MASK}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              style={{
                marginTop: 0
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <label>DIA DE VENCIMENTO DO ALUGUEL</label>
            <Input
              value={form.analysis.bondInsurance?.property?.rent_due_date}
              placeholder="Dia de vencimento do aluguel"
              type="number"
              min={1}
              max={31}
              onChange={(_, data) => {
                if (data?.value && +data?.value > 31) {
                  warningNotification('Atenção', 'O dia máximo de vencimento é 31');
                  return;
                }

                if (data?.value && +data?.value < 1) {
                  warningNotification('Atenção', 'O dia mínimo de vencimento é 1');
                  return;
                }

                setForm((prevValue: FormProps) => {
                  return {
                    ...prevValue,
                    analysis: {
                      ...prevValue.analysis,
                      bondInsurance: {
                        ...prevValue.analysis.bondInsurance,
                        property: {
                          ...prevValue.analysis.bondInsurance.property,
                          rent_due_date: +data?.value
                        }
                      }
                    }
                  };
                });
              }}
              error={isValidForm && !form.analysis.bondInsurance?.property?.rent_due_date}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <label>OBSERVAÇÕES DA CONTRATAÇÃO</label>
            <Input
              placeholder="Observações da Contratação"
              onChange={(_, data) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  hiredData: {
                    ...prevValue.hiredData,
                    observations: data?.value
                  }
                }))
              }
              error={isValidForm && form.hiredData?.observations === ''}
              fluid
              value={form.hiredData?.observations}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const renderAnalysisForm = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>ID</label>
            <Input disabled fluid value={bondInsuranceAnalysis?.bondInsurance?.id} />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>DATA DA ANÁLISE</label>
            <CustomDatePicker
              selected={form.analysis?.createdAt ?? undefined}
              onChange={(date: Date) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    createdAt: date
                  }
                }))
              }
              customInput={
                <AppMaskedInput
                  error={isValidForm && !form.analysis?.insurerProposalCreatedAt}
                  mask={DATE_INPUT_MASK}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              style={{
                marginTop: 0
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>CORRETORA</label>
            <Input
              placeholder="Corretora"
              fluid
              value={bondInsurance?.broker?.name}
              disabled
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>IMOBILIÁRIA</label>
            <Dropdown
              fluid
              search
              clearable
              selection
              value={form.analysis?.bondInsurance?.estateId}
              options={estatesOptions?.map(item => ({
                ...item,
                value: item.value?.id
              }))}
              placeholder={'Selecionar...'}
              error={isValidForm && form.analysis?.bondInsurance?.estateId === ''}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      estateId: value?.toString() || ''
                    }
                  }
                }))
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>FINALIDADE</label>
            <Dropdown
              fluid
              search
              clearable
              selection
              value={form.analysis.bondInsurance.purpose}
              options={purposeOptions}
              placeholder={'Selecionar...'}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      purpose: value?.toString() || ''
                    }
                  }
                }))
              }
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>TIPO DE LOCATÁRIO</label>
            <Dropdown
              fluid
              search
              clearable
              selection
              value={form.analysis.bondInsurance.tenantType}
              options={tenantTypeOptions}
              placeholder={'Selecionar...'}
              disabled
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      tenantType: value?.toString() || ''
                    }
                  }
                }))
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>MOTIVO DA LOCAÇÃO</label>
            <Dropdown
              fluid
              search
              clearable
              selection
              value={form.analysis.bondInsurance.property.reason}
              options={reasonsOptions}
              placeholder={'Selecionar...'}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance.property,
                        reason: value?.toString() || ''
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>GESTOR DE CONTAS</label>
            <Dropdown
              fluid
              search
              clearable
              selection
              value={accountManager}
              options={accountManagerOptions}
              placeholder={'Selecionar...'}
              disabled
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>GARANTIA DA ANÁLISE</label>
            <Dropdown
              fluid
              search
              clearable
              selection
              value={form.analysis.bondInsurance.analysisAssurance}
              options={analysisOptions}
              placeholder={'Selecionar...'}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      analysisAssurance: value?.toString() || ''
                    }
                  }
                }))
              }
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>VIGÊNCIA DO CONTRATO</label>
            <Dropdown
              fluid
              search
              clearable
              selection
              placeholder="Vigência do contrato"
              value={form.analysis?.bondInsurance?.property?.validity}
              options={validityOptions}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance.property,
                        validity: value ? Number(value) : undefined
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>CRIADA POR</label>
            <Input
              placeholder="Criada por"
              disabled
              fluid
              value={bondInsuranceAnalysis?.bondInsurance?.user?.name}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const renderOwnerForm = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>NOME</label>
            <Input
              placeholder="Nome"
              onChange={(_, data) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      ownerData: {
                        ...prevValue.analysis.bondInsurance?.ownerData,
                        name: data?.value?.toString() || ''
                      }
                    }
                  }
                }))
              }
              error={isValidForm && form.analysis?.bondInsurance?.ownerData?.name === ''}
              fluid
              value={form.analysis?.bondInsurance?.ownerData?.name}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>CPF/CNPJ</label>
            <Input
              placeholder="CPF/CNPJ"
              onChange={(_, data) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      ownerData: {
                        ...prevValue.analysis.bondInsurance?.ownerData,
                        cpf: maskCpfOrCnpj(data?.value?.toString() || '')
                      }
                    }
                  }
                }))
              }
              error={
                isValidForm && form.analysis?.bondInsurance?.ownerData?.document === ''
              }
              fluid
              value={form.analysis?.bondInsurance?.ownerData?.document}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>CEP</label>
            <Input
              placeholder="CEP"
              fluid
              value={form.analysis?.bondInsurance?.ownerData?.address.zip_code}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      ownerData: {
                        ...prevValue.analysis.bondInsurance?.ownerData,
                        address: {
                          ...prevValue.analysis.bondInsurance?.ownerData.address,
                          zip_code: value?.toString() || ''
                        }
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>RUA</label>
            <Input
              placeholder="Rua"
              fluid
              value={form.analysis?.bondInsurance?.ownerData?.address.street}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      ownerData: {
                        ...prevValue.analysis.bondInsurance?.ownerData,
                        address: {
                          ...prevValue.analysis.bondInsurance?.ownerData.address,
                          street: value?.toString() || ''
                        }
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>BAIRRO</label>
            <Input
              placeholder="Bairro"
              fluid
              value={form.analysis?.bondInsurance?.ownerData?.address.district}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      ownerData: {
                        ...prevValue.analysis.bondInsurance?.ownerData,
                        address: {
                          ...prevValue.analysis.bondInsurance?.ownerData.address,
                          district: value?.toString() || ''
                        }
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>CIDADE</label>
            <Input
              placeholder="Cidade"
              fluid
              value={form.analysis?.bondInsurance?.ownerData?.address.city}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      ownerData: {
                        ...prevValue.analysis.bondInsurance?.ownerData,
                        address: {
                          ...prevValue.analysis.bondInsurance?.ownerData.address,
                          city: value?.toString() || ''
                        }
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <label>NÚMERO</label>
            <Input
              placeholder="Número"
              fluid
              value={form.analysis?.bondInsurance?.ownerData?.address.number}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      ownerData: {
                        ...prevValue.analysis.bondInsurance?.ownerData,
                        address: {
                          ...prevValue.analysis.bondInsurance?.ownerData.address,
                          number: value?.toString() || ''
                        }
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <label>UF</label>
            <Input
              placeholder="UF"
              fluid
              value={form.analysis?.bondInsurance?.ownerData?.address.state}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      ownerData: {
                        ...prevValue.analysis.bondInsurance?.ownerData,
                        address: {
                          ...prevValue.analysis.bondInsurance?.ownerData.address,
                          state: value?.toString() || ''
                        }
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>COMPLEMENTO</label>
            <Input
              placeholder="Complemento"
              fluid
              value={form.analysis?.bondInsurance?.ownerData?.address.complement}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      ownerData: {
                        ...prevValue.analysis.bondInsurance?.ownerData,
                        address: {
                          ...prevValue.analysis.bondInsurance?.ownerData.address,
                          complement: value?.toString() || ''
                        }
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const renderPropertyForm = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>CEP</label>
            <Input
              placeholder="CEP"
              fluid
              value={form.analysis?.bondInsurance?.property?.zip_code}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance?.property,
                        zip_code: value?.toString() || ''
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>RUA</label>
            <Input
              placeholder="Rua"
              fluid
              value={form.analysis?.bondInsurance?.property?.street}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance?.property,
                        street: value?.toString() || ''
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>BAIRRO</label>
            <Input
              placeholder="Bairro"
              fluid
              value={form.analysis?.bondInsurance?.property?.district}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance?.property,
                        district: value?.toString() || ''
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>CIDADE</label>
            <Input
              placeholder="Cidade"
              fluid
              value={form.analysis?.bondInsurance?.property?.city}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance?.property,
                        city: value?.toString() || ''
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <label>NÚMERO</label>
            <Input
              placeholder="Número"
              fluid
              value={form.analysis?.bondInsurance?.property?.number}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance?.property,
                        number: value?.toString() || ''
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <label>UF</label>
            <Input
              placeholder="UF"
              fluid
              value={form.analysis?.bondInsurance?.property?.state}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance?.property,
                        state: value?.toString() || ''
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>COMPLEMENTO</label>
            <Input
              placeholder="Complemento"
              fluid
              value={form.analysis?.bondInsurance?.property?.complement}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance?.property,
                        complement: value?.toString() || ''
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>TIPO DE IMÓVEL</label>
            <Dropdown
              fluid
              search
              clearable
              selection
              value={form.analysis.bondInsurance.property.property_type}
              options={hirePropertyTypeOptions}
              placeholder={'Selecionar...'}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance.property,
                        property_type: value?.toString() || ''
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>TIPO DE LOCAÇÃO</label>
            <Dropdown
              fluid
              search
              clearable
              selection
              value={form.analysis.bondInsurance.purpose}
              options={purposeOptions}
              placeholder={'Selecionar...'}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      purpose: value?.toString() || ''
                    }
                  }
                }))
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>ALUGUEL</label>
            <Input
              placeholder="Aluguel"
              fluid
              value={form.analysis?.bondInsurance?.property?.rent_value}
              onChange={(_, { value }) =>{
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance?.property,
                        rent_value: formatAmount(value)
                      }
                    }
                  }
                }))
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>CONDOMÍNIO (R$)</label>
            <Input
              placeholder="Condomínio"
              fluid
              value={
                form.analysis?.bondInsurance?.property?.condominium_fee || 0
              }
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance?.property,
                        condominium_fee: formatAmount(value)
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>IPTU (R$)</label>
            <Input
              placeholder="IPTU"
              fluid
              value={
                form.analysis?.bondInsurance?.property?.taxes_value || 0
              }
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance?.property,
                        taxes_value: formatAmount(value)
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>ENERGIA (R$)</label>
            <Input
              placeholder="Energia"
              fluid
              value={
                form.analysis?.bondInsurance?.property?.energy_bill || 0
              }
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance?.property,
                        energy_bill: formatAmount(value)
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>ÁGUA (R$)</label>
            <Input
              placeholder="Água"
              fluid
              value={
                form.analysis?.bondInsurance?.property?.water_bill || 0
              }
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance?.property,
                        water_bill: formatAmount(value)
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>GÁS CANALIZADO (R$)</label>
            <Input
              placeholder="Gás canalizado"
              fluid
              value={form.analysis?.bondInsurance?.property?.gas_bill || 0}
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance?.property,
                        gas_bill: formatAmount(value)
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>MULTAS CONTRATUAIS (R$)</label>
            <Input
              placeholder="Multas contratuais"
              fluid
              value={
                form.analysis?.bondInsurance?.property?.contract_penalties_value || 0
              }
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance?.property,
                        contract_penalties_value: formatAmount(value)
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>PINTURA INTERNA (R$)</label>
            <Input
              placeholder="Pintura interna"
              fluid
              value={
                form.analysis?.bondInsurance?.property?.interior_painting_value || 0
              }
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance?.property,
                        interior_painting_value: formatAmount(value)
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>PINTURA EXTERNA (R$)</label>
            <Input
              placeholder="Pintura externa"
              fluid
              value={
                form.analysis?.bondInsurance?.property?.exterior_painting_value || 0
              }
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance?.property,
                        exterior_painting_value: formatAmount(value)
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>DANOS A MÓVEIS (R$)</label>
            <Input
              placeholder="Dados a móveis"
              fluid
              value={
                form.analysis?.bondInsurance?.property?.furniture_damage_value || 0
              }
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance?.property,
                        furniture_damage_value: formatAmount(value)
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>DANOS AO IMÓVEL (R$)</label>
            <Input
              placeholder="Danos ao imóvel"
              fluid
              value={
                form.analysis?.bondInsurance?.property?.property_damage_value || 0
              }
              onChange={(_, { value }) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      property: {
                        ...prevValue.analysis.bondInsurance?.property,
                        property_damage_value: formatAmount(value)
                      }
                    }
                  }
                }))
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const handleChangeSympathiseTenant = (
    e: React.ChangeEvent<HTMLInputElement> | string,
    data: any,
    index: number
  ) => {
    const newSympathiseTenants =
      sympathiseTenants?.map((tenant, i) => {
        if (i === index) {
          return {
            ...tenant,
            [typeof e === 'string' ? e : e.target.name]: data
          };
        }
        return tenant;
      }) || [];
    setSympathiseTenants(newSympathiseTenants);
  };

  const onChangeCpf = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const unmaskedValue = unmaskCpfOrCnpj(e.target.value);

    if (unmaskedValue.length >= 11) {
      setIsCpfLoading(true);

      getDataByDocument(unmaskedValue)
        .then(data => {
          const birthDate = new Date(data.birth_date);
          birthDate.setTime(
            birthDate.getTime() + birthDate.getTimezoneOffset() * 60 * 1000
          );

          const newSympathiseTenants =
            sympathiseTenants?.map((tenant, i) => {
              if (i === index) {
                return {
                  ...tenant,
                  name: data?.name || '',
                  birth_date: data.birth_date ? birthDate : undefined,
                  gender: data?.gender || '',
                  mother_name: data?.mother_name || ''
                };
              }
              return tenant;
            }) || [];
          setSympathiseTenants(newSympathiseTenants);
        })
        .catch(e => {
          dangerNotification(
            'Oops...',
            'Não foi possível encontrar dados associados ao documento informado'
          );
        })
        .finally(() => {
          setIsCpfLoading(false);
        });
    }
  };

  const handleRemoveSympathiseTenant = async (
    tenant: SympathiseTenantForGetBondInsurancesResponseDTO
  ) => {
    setSympathiseTenants(prevValue =>
      prevValue.filter(item => tenant.document !== item?.document)
    );
  };

  const renderNaturalPersonForm = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>NOME</label>
            <Input
              placeholder="Nome"
              fluid
              value={form.analysis.bondInsurance.tenant.name}
              onChange={(_, { value }) => {
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      tenant: {
                        ...prevValue.analysis.bondInsurance.tenant,
                        name: value?.toString() || ''
                      }
                    }
                  }
                }));
              }}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>GÊNERO</label>
            <Dropdown
              fluid
              search
              clearable
              selection
              placeholder="Gênero"
              options={genderOptions}
              value={form.analysis.bondInsurance.tenant.gender}
              onChange={(_, { value }) => {
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      tenant: {
                        ...prevValue.analysis.bondInsurance.tenant,
                        gender: value?.toString() || ''
                      }
                    }
                  }
                }));
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>DATA DE NASCIMENTO</label>
            <CustomDatePicker
              selected={form.analysis?.bondInsurance.tenant.birthDate ?? undefined}
              onChange={(date: Date) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      tenant: {
                        ...prevValue.analysis.bondInsurance.tenant,
                        birthDate: date
                      }
                    }
                  }
                }))
              }
              customInput={
                <AppMaskedInput
                  error={isValidForm && !form.hiredData?.insurerPolicyCreatedAt}
                  mask={DATE_INPUT_MASK}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              style={{
                marginTop: 0
              }}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>CPF</label>
            <Input
              placeholder="CPF"
              fluid
              value={form.analysis.bondInsurance.tenant.document}
              onChange={(_, { value }) => {
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      tenant: {
                        ...prevValue.analysis.bondInsurance.tenant,
                        document: value?.toString() || ''
                      }
                    }
                  }
                }));
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>E-MAIL</label>
            <Input
              placeholder="E-mail"
              fluid
              value={form.analysis.bondInsurance.tenant.email}
              onChange={(_, { value }) => {
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      tenant: {
                        ...prevValue.analysis.bondInsurance.tenant,
                        email: value?.toString() || ''
                      }
                    }
                  }
                }));
              }}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>TELEFONE</label>
            <Input
              placeholder="Telefone"
              fluid
              value={form.analysis.bondInsurance.tenant.phone}
              onChange={(_, { value }) => {
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      tenant: {
                        ...prevValue.analysis.bondInsurance.tenant,
                        phone: maskPhoneNumber(value?.toString()) || ''
                      }
                    }
                  }
                }));
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>ESTADO CIVIL</label>
            <Dropdown
              fluid
              search
              clearable
              selection
              placeholder="Estado Civil"
              options={maritalStatusOptions}
              value={form.analysis.bondInsurance.tenant.maritalStatus}
              onChange={(_, { value }) => {
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      tenant: {
                        ...prevValue.analysis.bondInsurance.tenant,
                        maritalStatus: value?.toString() || ''
                      }
                    }
                  }
                }));
              }}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>CÔNJUGE COMPÕE RENDA</label>
            <Input
              placeholder="Cônjuge compõe renda"
              fluid
              disabled
              value={
                bondInsurance?.naturalTenant?.partner_rent_composition ? 'Sim' : 'Não'
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>VÍNCULO EMPREGATÍCIO</label>
            <Dropdown
              fluid
              search
              clearable
              selection
              placeholder="Vínculo empregatício"
              options={employmentRelationshipOptions}
              value={form.analysis.bondInsurance.tenant.employee}
              onChange={(_, { value }) => {
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      tenant: {
                        ...prevValue.analysis.bondInsurance.tenant,
                        employee: value?.toString() || ''
                      }
                    }
                  }
                }));
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const renderSympathiseTenants = () => {
    return (
      <Grid>
        {sympathiseTenants?.map((tenant, index) => {
          return (
            <>
              {index >= 0 && (
                <Grid.Row>
                  <Grid.Column width={16}>
                    <SympathiseTenantHeader>
                      <SympathiseTenantLabel>
                        {index + 1}. LOCATÁRIO SOLIDÁRIO
                      </SympathiseTenantLabel>
                      <Button
                        color="red"
                        basic
                        type="button"
                        size="mini"
                        onClick={() => handleRemoveSympathiseTenant(tenant)}
                      >
                        <Icon name="cancel" /> Remover
                      </Button>
                    </SympathiseTenantHeader>
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row>
                <Grid.Column width={4}>
                  <label>CPF*</label>
                  <Input
                    value={tenant.cpf}
                    name="cpf"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ) => {
                      handleChangeSympathiseTenant(e, maskCpfOrCnpj(data.value), index);
                    }}
                    onBlur={(e: any) => onChangeCpf(e, index)}
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={4}>
                  <label>NOME*</label>
                  <Input
                    name="name"
                    value={tenant.name}
                    loading={isCpfLoading}
                    disabled={isCpfLoading}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ): void => {
                      handleChangeSympathiseTenant(e, data.value, index);
                    }}
                    fluid
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <label>GÊNERO* </label>
                  <Dropdown
                    name="gender"
                    loading={isCpfLoading}
                    disabled={isCpfLoading}
                    value={tenant.gender}
                    completeValue={true}
                    onChange={(e, { value }) => {
                      handleChangeSympathiseTenant(e as any, value, index);
                    }}
                    placeholder={'Selecionar...'}
                    options={genderOptions}
                    search
                    clearable
                    selection
                    fluid
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <label>DATA DE NASCIMENTO* </label>
                  <CustomDatePicker
                    selected={tenant.birth_date}
                    onChange={(date: Date) => {
                      handleChangeSympathiseTenant('birth_date', date, index);
                    }}
                    customInput={
                      <AppMaskedInput name="birth_date" mask={DATE_INPUT_MASK} />
                    }
                    loading={isCpfLoading}
                    disabled={isCpfLoading}
                    dateFormat={'dd/MM/yyyy'}
                    style={{
                      marginTop: 0
                    }}
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <label>NOME DA MÃE*</label>
                  <Input
                    name="mother_name"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ): void => {
                      handleChangeSympathiseTenant(e, data.value, index);
                    }}
                    loading={isCpfLoading}
                    disabled={isCpfLoading}
                    value={tenant.mother_name}
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={4}>
                  <label>EMAIL*</label>
                  <Input
                    name="email"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ): void => {
                      handleChangeSympathiseTenant(e, data.value, index);
                    }}
                    value={tenant.email}
                    fluid
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <label>TELEFONE*</label>
                  <Input
                    name="phone"
                    value={tenant.phone}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ): void => {
                      handleChangeSympathiseTenant(e, maskPhoneNumber(data.value), index);
                    }}
                    fluid
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <label>ESTADO CIVIL* </label>
                  <Dropdown
                    name="marital_status"
                    onChange={(e, data): void => {
                      handleChangeSympathiseTenant('marital_status', data.value, index);
                    }}
                    value={tenant.marital_status}
                    completeValue={true}
                    placeholder={'Selecionar...'}
                    options={maritalStatusOptions}
                    search
                    clearable
                    selection
                    fluid
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <label>NACIONALIDADE*</label>
                  <Input
                    name="nationality"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ): void => {
                      handleChangeSympathiseTenant(e, data.value, index);
                    }}
                    value={tenant.nationality}
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={4}>
                  <label>RESIDENTE?*</label>
                  <Grid.Row>
                    <Grid.Column width={3} style={{ paddingTop: '10px' }}>
                      <Radio
                        style={{ paddingLeft: '0px' }}
                        label="Sim"
                        checked={tenant.resident}
                        onChange={(_, data) => {
                          handleChangeSympathiseTenant('resident', true, index);
                        }}
                      />
                      <Radio
                        style={{ paddingLeft: '30px' }}
                        label="Não"
                        checked={!tenant.resident}
                        onChange={(_, data) => {
                          handleChangeSympathiseTenant('resident', false, index);
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={4}>
                  <FieldLabel paddingTop={true}>PESSOA POLITICAMENTE EXPOSTA?</FieldLabel>
                  <Grid.Row>
                    <Grid.Column
                      width={5}
                      style={{
                        paddingTop: '10px'
                      }}
                    >
                      <Radio
                        style={{ marginRight: '1rem' }}
                        label="Sim"
                        value={PoliticallyExposedPersonEnum.yes}
                        checked={
                          tenant.politically_exposed_person ===
                          PoliticallyExposedPersonEnum.yes
                        }
                        onChange={(_, data) => {
                          handleChangeSympathiseTenant(
                            'politically_exposed_person',
                            data.value
                              ? PoliticallyExposedPersonEnum.yes
                              : PoliticallyExposedPersonEnum.no,
                            index
                          );
                        }}
                      />

                      <Radio
                        style={{ marginRight: '1rem' }}
                        label="Não"
                        value={PoliticallyExposedPersonEnum.no}
                        checked={
                          tenant.politically_exposed_person ===
                          PoliticallyExposedPersonEnum.no
                        }
                        onChange={(_, data) => {
                          handleChangeSympathiseTenant(
                            'politically_exposed_person',
                            data.value
                              ? PoliticallyExposedPersonEnum.no
                              : PoliticallyExposedPersonEnum.no,
                            index
                          );
                        }}
                      />

                      <Radio
                        label="Próximo"
                        value={PoliticallyExposedPersonEnum.closeTo}
                        checked={
                          tenant.politically_exposed_person ===
                          PoliticallyExposedPersonEnum.closeTo
                        }
                        onChange={(_, data) => {
                          handleChangeSympathiseTenant(
                            'politically_exposed_person',
                            data.value
                              ? PoliticallyExposedPersonEnum.closeTo
                              : PoliticallyExposedPersonEnum.no,
                            index
                          );
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid.Column>

                {tenant?.politically_exposed_person ===
                  PoliticallyExposedPersonEnum.closeTo && (
                    <>
                      <Grid.Column width={4}>
                        <label>NOME DA PESSOA*</label>
                        <Input
                          name="close_to_politically_exposed_person.politically_exposed_person_name"
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                            data: InputOnChangeData
                          ): void => {
                            handleChangeSympathiseTenant(e, data.value, index);
                          }}
                          value={
                            tenant?.close_to_politically_exposed_person
                              ?.politically_exposed_person_name
                          }
                          fluid
                        />
                      </Grid.Column>

                      <Grid.Column width={4}>
                        <label>CPF DA PESSOA*</label>
                        <Input
                          name="close_to_politically_exposed_person.politically_exposed_person_cpf"
                          value={
                            tenant?.close_to_politically_exposed_person
                              ?.politically_exposed_person_cpf
                          }
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                            data: InputOnChangeData
                          ): void => {
                            handleChangeSympathiseTenant(
                              e,
                              maskCpfOrCnpj(data.value),
                              index
                            );
                          }}
                          fluid
                        />
                      </Grid.Column>

                      <Grid.Column width={4}>
                        <label>GRAU DE PARENTESCO*</label>
                        <Input
                          name="close_to_politically_exposed_person.degree_of_kinship_to_politically_exposed_person"
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                            data: InputOnChangeData
                          ): void => {
                            handleChangeSympathiseTenant(e, data.value, index);
                          }}
                          value={
                            tenant?.close_to_politically_exposed_person
                              ?.degree_of_kinship_to_politically_exposed_person
                          }
                          fluid
                        />
                      </Grid.Column>
                    </>
                  )}
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={4}>
                  <label>VÍNCULO EMPREGATÍCIO*</label>
                  <Dropdown
                    name="employment_relationship"
                    onChange={(e, { value }) => {
                      handleChangeSympathiseTenant(
                        'employment_relationship',
                        value,
                        index
                      );
                    }}
                    value={tenant.employment_relationship}
                    completeValue={true}
                    placeholder={'Selecionar...'}
                    options={employmentRelationshipOptions}
                    search
                    clearable
                    selection
                    fluid
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <label>PROFISSÃO*</label>
                  <Input
                    name="profession"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ): void => {
                      handleChangeSympathiseTenant(e, data.value, index);
                    }}
                    value={tenant.profession}
                    fluid
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <label>RENDA MENSAL*</label>
                  <Input
                    name="monthly_fixed_income"
                    value={tenant.monthly_fixed_income}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ): void => {
                      handleChangeSympathiseTenant(e, formatAmount(data.value), index);
                    }}
                    fluid
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <label>OUTRAS RENDAS</label>
                  <Input
                    name="other_incomes"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ): void => {
                      handleChangeSympathiseTenant(e, formatAmount(data.value), index);
                    }}
                    value={tenant.other_incomes}
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            </>
          );
        })}
        <Grid.Row>
          <Grid.Column width={16}>
            <Button
              style={{ marginTop: 10 }}
              primary
              color="green"
              onClick={() => {
                if (sympathiseTenants.length > 0) {
                  const lastTenant = sympathiseTenants[sympathiseTenants.length - 1];
                  if (lastTenant?.name?.length > 0 && lastTenant?.cpf?.length > 0)
                    setSympathiseTenants(tenant => [
                      ...tenant,
                      sympathiseTenantBlankInfo
                    ]);
                } else {
                  setSympathiseTenants([sympathiseTenantBlankInfo]);
                }
              }}
              type="button"
            >
              Adicionar Locatário
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const renderLegalPersonForm = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>NOME</label>
            <Input
              placeholder="Nome"
              fluid
              value={form.analysis.bondInsurance.tenant.name}
              onChange={(_, { value }) => {
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      tenant: {
                        ...prevValue.analysis.bondInsurance.tenant,
                        name: value?.toString() || ''
                      }
                    }
                  }
                }));
              }}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>CNPJ</label>
            <Input
              placeholder="CNPJ"
              fluid
              value={form.analysis.bondInsurance.tenant.document}
              onChange={(_, { value }) => {
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      tenant: {
                        ...prevValue.analysis.bondInsurance.tenant,
                        document: value?.toString() || ''
                      }
                    }
                  }
                }));
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>E-MAIL</label>
            <Input
              placeholder="E-mail"
              fluid
              value={form.analysis.bondInsurance.tenant.email}
              onChange={(_, { value }) => {
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      tenant: {
                        ...prevValue.analysis.bondInsurance.tenant,
                        email: value?.toString() || ''
                      }
                    }
                  }
                }));
              }}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>TELEFONE</label>
            <Input
              placeholder="Telefone"
              fluid
              value={form.analysis.bondInsurance.tenant.phone}
              onChange={(_, { value }) => {
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      tenant: {
                        ...prevValue.analysis.bondInsurance.tenant,
                        phone: maskPhoneNumber(value?.toString()) || ''
                      }
                    }
                  }
                }));
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>DATA DE CONSTITUIÇÃO</label>
            <CustomDatePicker
              selected={form.analysis?.bondInsurance.tenant.foundation_date ?? undefined}
              onChange={(date: Date) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      tenant: {
                        ...prevValue.analysis.bondInsurance.tenant,
                        foundation_date: date
                      }
                    }
                  }
                }))
              }
              customInput={
                <AppMaskedInput
                  error={isValidForm && !form.hiredData?.insurerPolicyCreatedAt}
                  mask={DATE_INPUT_MASK}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              style={{
                marginTop: 0
              }}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>ÚLT. ALT. CONTRATUAL</label>
            <CustomDatePicker
              selected={
                form.analysis?.bondInsurance.tenant.last_contractual_alteration_date ??
                undefined
              }
              onChange={(date: Date) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      tenant: {
                        ...prevValue.analysis.bondInsurance.tenant,
                        last_contractual_alteration_date: date
                      }
                    }
                  }
                }))
              }
              customInput={
                <AppMaskedInput
                  error={isValidForm && !form.hiredData?.insurerPolicyCreatedAt}
                  mask={DATE_INPUT_MASK}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              style={{
                marginTop: 0
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>CAPITAL SOCIAL (R$)</label>
            <Input
              placeholder="Capital social"
              fluid
              value={form.analysis.bondInsurance.tenant.share_capital ?? ''}
              onChange={(_, { value }) => {
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      tenant: {
                        ...prevValue.analysis.bondInsurance.tenant,
                        share_capital: value?.toString() || ''
                      }
                    }
                  }
                }));
              }}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <label>CAPITAL INTEGRALIZADO (R$)</label>
            <Input
              placeholder="Capital integralizado"
              fluid
              value={form.analysis.bondInsurance.tenant.paid_in_capital ?? ''}
              onChange={(_, { value }) => {
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  analysis: {
                    ...prevValue.analysis,
                    bondInsurance: {
                      ...prevValue.analysis.bondInsurance,
                      tenant: {
                        ...prevValue.analysis.bondInsurance.tenant,
                        paid_in_capital: value?.toString() || ''
                      }
                    }
                  }
                }));
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  return (
    <Modal open={isOpen} onClose={onClose} closeIcon size="large">
      <Container>
        <Header>
          <h3>Editar Apólice de Seguro Fiança</h3>
        </Header>
        <Line />
        <Modal.Content scrolling style={{ padding: 0 }}>
          <Container ref={modalRef}>
            <Form id="policy-form">
              <FormContainer>
                <FormTitle>DADOS DA APÓLICE</FormTitle>
                <Line />
                {renderPolicyForm()}
              </FormContainer>
              <FormContainer>
                <FormTitle>DADOS DA PROPOSTA</FormTitle>
                <Line />
                {renderProposalForm()}
              </FormContainer>
              <FormContainer>
                <FormTitle>DADOS DA ANÁLISE</FormTitle>
                <Line />
                {renderAnalysisForm()}
              </FormContainer>
              <FormContainer>
                <FormTitle>DADOS DO PROPRIETÁRIO</FormTitle>
                <Line />
                {renderOwnerForm()}
              </FormContainer>
              <FormContainer>
                <FormTitle>DADOS DO IMÓVEL</FormTitle>
                <Line />
                {renderPropertyForm()}
              </FormContainer>
              <FormContainer>
                <FormTitle>DADOS DO LOCATÁRIO</FormTitle>
                <Line />
                {bondInsurance?.tenantType === PersonTypesEnum.natural &&
                  renderNaturalPersonForm()}
                {bondInsurance?.tenantType === PersonTypesEnum.legal &&
                  renderLegalPersonForm()}
              </FormContainer>
              {bondInsurance?.tenantType === PersonTypesEnum.natural && (
                <FormContainer>
                  <FormTitle>LOCATÁRIO SOLIDÁRIO</FormTitle>
                  <Line />
                  {renderSympathiseTenants()}
                </FormContainer>
              )}
              {
                <FormContainer>
                  <FormTitle>
                    <IconButton color="yellow" name="warning sign" /> Inconsistências
                    encontradas
                  </FormTitle>
                  <Line />
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Checkbox
                          label={' Apólice inconsistente'}
                          style={{ fontSize: 14 }}
                          checked={
                            form.hiredData.validationStatus === 'warning' ||
                            form.hiredData.validationStatus === 'error'
                          }
                          onChange={(e, { checked }) => {
                            setForm((prevValue: FormProps) => ({
                              ...prevValue,
                              hiredData: {
                                ...prevValue.hiredData,
                                validationStatus: checked ? 'warning' : 'fixed'
                              }
                            }));
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Input
                          placeholder="Nenhuma mensagem de inconsistência encontrada"
                          fluid
                          value={form.hiredData.validationMessage}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </FormContainer>
              }
            </Form>
          </Container>
        </Modal.Content>
      </Container>
      {renderActionButtons()}
    </Modal>
  );
};

export default EditModal;
