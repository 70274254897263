import React, { useState } from 'react';
import { Radio, Grid, Input, Dropdown, Checkbox } from 'semantic-ui-react';
import { Field, FormikProps } from 'formik';
import SectionLabel from '../../../../../../components/SectionLabel';
import { FieldLabel } from '../../../../../../styles';
import CustomDatePicker from '../../../../../../components/CustomDatePicker';
import AppMaskedInput from '../../../../../../components/MaskedInput';
import { FormatCurrency, UnMaskCurrency } from '../../../../../../util';
import { CreateCapitalizationType } from '../../Formik/types/CreateCapitalizationInitialValuesTypes';
import {
  maskCpf,
  maskCpfOrCnpj,
  maskMoney,
  maskPhoneNumber
} from '../../../../../../services/masks';
import { maritalStatusOptions } from '../../../../../bond-insurance-budget-form/types';
import { PoliticallyExposedPersonEnum } from '../../../../../../enums/politically-exposed-person.enum';
import { getDataByDocument } from '../../../../../../services/documents';
// import { FormatDateYMD, FormatDateYMD_UTC_IGNORE } from "../../../../../../services/date";
import axios from 'axios';
import { getProfessions } from '../../../../../../services/capitalization';
import { incomeBracketsOptions } from '../../../../types/incomeBracketsOptions';

const UFOptions = [
  { key: 'AC', text: 'Acre', value: 'AC' },
  { key: 'AL', text: 'Alagoas', value: 'AL' },
  { key: 'AP', text: 'Amapá', value: 'AP' },
  { key: 'AM', text: 'Amazonas', value: 'AM' },
  { key: 'BA', text: 'Bahia', value: 'BA' },
  { key: 'CE', text: 'Ceará', value: 'CE' },
  { key: 'DF', text: 'Distrito Federal', value: 'DF' },
  { key: 'ES', text: 'Espírito Santo', value: 'ES' },
  { key: 'GO', text: 'Goiás', value: 'GO' },
  { key: 'MA', text: 'Maranhão', value: 'MA' },
  { key: 'MT', text: 'Mato Grosso', value: 'MT' },
  { key: 'MS', text: 'Mato Grosso do Sul', value: 'MS' },
  { key: 'MG', text: 'Minas Gerais', value: 'MG' },
  { key: 'PA', text: 'Pará', value: 'PA' },
  { key: 'PB', text: 'Paraíba', value: 'PB' },
  { key: 'PR', text: 'Paraná', value: 'PR' },
  { key: 'PE', text: 'Pernambuco', value: 'PE' },
  { key: 'PI', text: 'Piauí', value: 'PI' },
  { key: 'RJ', text: 'Rio de Janeiro', value: 'RJ' },
  { key: 'RN', text: 'Rio Grande do Norte', value: 'RN' },
  { key: 'RS', text: 'Rio Grande do Sul', value: 'RS' },
  { key: 'RO', text: 'Rondônia', value: 'RO' },
  { key: 'RR', text: 'Roraima', value: 'RR' },
  { key: 'SC', text: 'Santa Catarina', value: 'SC' },
  { key: 'SP', text: 'São Paulo', value: 'SP' },
  { key: 'SE', text: 'Sergipe', value: 'SE' },
  { key: 'TO', text: 'Tocantins', value: 'TO' }
];

const CapitalizationTenantStep = ({
  values,
  setFieldValue
}: FormikProps<CreateCapitalizationType>) => {
  // const [profissoes, setProfissoes] = useState<DropdownItemProps[]>([]);

  const [loadDocument, setLoadDocument] = useState(false);
  // const [loadResponsibleDocument, setLoadResponsibleDocument] = useState(false);
  const [disableNameField, setDisabledNameField] = useState<boolean>(true);
  const [disableResponsibleField, setDisabledResponsibleField] = useState<boolean>(true);

  async function getTenantByDocument(document: string, isResponsible: boolean) {
    try {
      const unformatedDoc = document.replace(/\D/g, '');
      const isCPF = unformatedDoc.length === 11;
      if (isResponsible) {
        // setLoadResponsibleDocument(true);
        setDisabledResponsibleField(true);
        const data = await getDataByDocument(unformatedDoc);
        setFieldValue('tenant.companyRepresentativeName', data.name);
        setFieldValue('tenant.companyRepresentativeCellphone', data.phone);
        setFieldValue('tenant.companyRepresentativeEmail', data.email);
      } else {
        setLoadDocument(true);
        const data = await getDataByDocument(unformatedDoc);
        if (isCPF) {
          const birthDate = new Date(data.birth_date);
          birthDate.setTime(
            birthDate.getTime() + birthDate.getTimezoneOffset() * 60 * 1000
          );
          setFieldValue('tenant.name', data.name);
          setFieldValue('tenant.birthDate', birthDate);
          setFieldValue('tenant.gender', data.gender);
          setFieldValue('tenant.motherName', data.mother_name);
          setDisabledNameField(true);
        } else {
          setDisabledNameField(false);
          setFieldValue('tenant.name', data.company);
          setFieldValue('tenant.cellphone', data.phone);
          setFieldValue('tenant.email', data.email);
        }
      }
    } catch (err) {
      console.log(err);
      if (isResponsible) {
        setDisabledResponsibleField(false);
      } else {
        setDisabledNameField(false);
      }
    } finally {
      setLoadDocument(false);
      // setLoadResponsibleDocument(false);
    }
  }

  // useEffect(() => {
  //   async function loadProfissoes() {
  //     try {
  //       const professions = await getProfessions();
  //       const profissoesOptions = professions.data.map(
  //         (profissao: { CODIGO: any; TITULO: any }) => ({
  //           key: profissao.CODIGO,
  //           text: profissao.TITULO,
  //           value: profissao.TITULO,
  //         })
  //       );
  //       console.log(profissoesOptions);
  //       setProfissoes(profissoesOptions);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   loadProfissoes();
  // }, []);

  return (
    <div style={{ padding: '0 0 0 10px' }}>
      <SectionLabel text="Informações do locatário" />
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column>
            <FieldLabel>CPF/CNPJ</FieldLabel>
            <Field
              name={'tenant.document'}
              value={values.tenant.document}
              customInput={<Input />}
              component={Input}
              onChange={(e: any) => {
                setFieldValue('tenant.document', maskCpfOrCnpj(e.target.value));
              }}
              fluid
              onBlur={(e: any) => {
                getTenantByDocument(e.target.value, false);
              }}
              loading={loadDocument}
            />
          </Grid.Column>
          <Grid.Column>
            <FieldLabel>
              {values.tenant.document.length > 14 ? 'Razão Social' : 'Nome'}{' '}
            </FieldLabel>
            <Field
              name={'tenant.name'}
              value={values.tenant.name}
              customInput={<Input />}
              component={Input}
              disabled={disableNameField}
              onChange={(e: any) => setFieldValue('tenant.name', e.target.value)}
              fluid
              loading={loadDocument}
            />
          </Grid.Column>
          {values.tenant.document.length < 15 ? (
            <Grid.Column>
              <FieldLabel>Data de nascimento</FieldLabel>
              <Field
                name={`tenant.birthDate`}
                selected={values.tenant.birthDate}
                component={CustomDatePicker}
                fluid
                onChange={(date: Date, e: any) => {
                  setFieldValue('tenant.birthDate', date);
                }}
                customInput={
                  <AppMaskedInput
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/
                    ]}
                  />
                }
                dateFormat={'dd/MM/yyyy'}
              />
            </Grid.Column>
          ) : (
            <Grid.Column>
              <FieldLabel>Faturamento Anual</FieldLabel>
              <Field
                name={`tenant.companyAnnualRevenue`}
                value={FormatCurrency(values.tenant.companyAnnualRevenue)}
                onChange={(e: any) =>
                  setFieldValue(
                    `tenant.companyAnnualRevenue`,
                    UnMaskCurrency(e.target.value)
                  )
                }
                customInput={<Input />}
                component={Input}
                fluid
              />
            </Grid.Column>
          )}
        </Grid.Row>

        {values.tenant.document && values.tenant.document.length >= 15 && (
          <Grid.Row>
            <Grid.Column>
              <FieldLabel>Data de Constituição</FieldLabel>
              <Field
                name={`tenant.constitutionDate`}
                selected={
                  values.tenant.constitutionDate &&
                  new Date(values.tenant.constitutionDate)
                }
                component={CustomDatePicker}
                fluid
                onChange={(date: Date, e: any) => {
                  setFieldValue('tenant.constitutionDate', date);
                }}
                customInput={
                  <AppMaskedInput
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/
                    ]}
                  />
                }
                dateFormat={'dd/MM/yyyy'}
              />
            </Grid.Column>
            <Grid.Column>
              <FieldLabel>Inscrição Municipal</FieldLabel>
              <Field
                name={`tenant.municipalRegistration`}
                value={values.tenant.municipalRegistration}
                onChange={(e: any) =>
                  setFieldValue(`tenant.municipalRegistration`, e.target.value)
                }
                customInput={<Input />}
                component={Input}
                disabled={values.tenant.hasMunicipalRegistration}
                fluid
              />
              <Checkbox
                label="Isento"
                checked={values.tenant.hasMunicipalRegistration}
                onChange={(e: any, data) => {
                  setFieldValue('tenant.hasMunicipalRegistration', data.checked);
                }}
                style={{
                  marginTop: 5
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <FieldLabel>Inscrição Estadual</FieldLabel>
              <Field
                name={`tenant.stateRegistration`}
                value={values.tenant.stateRegistration}
                onChange={(e: any) =>
                  setFieldValue(`tenant.stateRegistration`, e.target.value)
                }
                customInput={<Input />}
                component={Input}
                disabled={values.tenant.hasStateRegistration}
                fluid
              />
              <Checkbox
                label="Isento"
                checked={values.tenant.hasStateRegistration}
                onChange={(e: any, data) => {
                  setFieldValue('tenant.hasStateRegistration', data.checked);
                }}
                style={{
                  marginTop: 5
                }}
              />
            </Grid.Column>
          </Grid.Row>
        )}

        {values.tenant.document.length >= 15 && (
          <Grid.Row>
            <Grid.Column>
              <FieldLabel>CPF do representante</FieldLabel>
              <Field
                name={`tenant.companyRepresentativeDocument`}
                value={values.tenant.companyRepresentativeDocument}
                onChange={(e: any) => {
                  setFieldValue(
                    `tenant.companyRepresentativeDocument`,
                    maskCpf(e.target.value)
                  );
                }}
                onBlur={(e: any) => {
                  getTenantByDocument(e.target.value, true);
                }}
                customInput={<Input />}
                component={Input}
                fluid
              />
            </Grid.Column>

            <Grid.Column>
              <FieldLabel>Nome do representante</FieldLabel>
              <Field
                name={`tenant.companyRepresentativeName`}
                value={values.tenant.companyRepresentativeName}
                onChange={(e: any) =>
                  setFieldValue(`tenant.companyRepresentativeName`, e.target.value)
                }
                disabled={disableResponsibleField}
                customInput={<Input />}
                component={Input}
                fluid
              />
            </Grid.Column>

            <Grid.Column>
              <FieldLabel>Telefone do representante</FieldLabel>
              <Field
                name={`tenant.companyRepresentativeCellphone`}
                value={values.tenant.companyRepresentativeCellphone}
                onChange={(e: any) =>
                  setFieldValue(
                    `tenant.companyRepresentativeCellphone`,
                    maskPhoneNumber(e.target.value)
                  )
                }
                customInput={<Input />}
                component={Input}
                fluid
              />
            </Grid.Column>

            <Grid.Column>
              <FieldLabel>Email do representante</FieldLabel>
              <Field
                name={`tenant.companyRepresentativeEmail`}
                value={values.tenant.companyRepresentativeEmail}
                onChange={(e: any) =>
                  setFieldValue(`tenant.companyRepresentativeEmail`, e.target.value)
                }
                customInput={<Input />}
                component={Input}
                fluid
              />
            </Grid.Column>
          </Grid.Row>
        )}

        <Grid.Row>
          <Grid.Column>
            <FieldLabel>Email</FieldLabel>
            <Field
              name={`tenant.email`}
              value={values.tenant.email}
              onChange={(e: any) => setFieldValue(`tenant.email`, e.target.value)}
              customInput={<Input />}
              component={Input}
              fluid
            />
          </Grid.Column>
          <Grid.Column>
            <FieldLabel>Telefone</FieldLabel>
            <Field
              name={`tenant.cellphone`}
              value={maskPhoneNumber(values.tenant.cellphone)}
              onChange={(e: any) => setFieldValue(`tenant.cellphone`, e.target.value)}
              customInput={<Input />}
              component={Input}
              fluid
            />
          </Grid.Column>

          {values.tenant.document.length < 15 && (
            <>
              <Grid.Column width={4}>
                <FieldLabel>Estado Civil </FieldLabel>
                <Dropdown
                  upward
                  style={{ width: '100%' }}
                  name={`tenant.maritalStatus`}
                  value={values.tenant.maritalStatus}
                  completeValue={true}
                  placeholder={'Selecionar...'}
                  options={maritalStatusOptions}
                  onChange={(_e, { value }) => {
                    setFieldValue('tenant.maritalStatus', value);
                  }}
                  clearable
                  selection
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>Nacionalidade</FieldLabel>
                <Field
                  name={`tenant.nacionality`}
                  value={values.tenant.nationality}
                  onChange={(e: any) =>
                    setFieldValue('tenant.nacionality', e.target.value)
                  }
                  component={Input}
                  customInput={<Input />}
                  fluid
                />
              </Grid.Column>
            </>
          )}
        </Grid.Row>

        {values.tenant.document.length < 15 && (
          <>
            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>Identidade</FieldLabel>
                <Field
                  name={`tenant.identityDocument`}
                  value={values.tenant.identityDocument}
                  onChange={(e: any) =>
                    setFieldValue('tenant.identityDocument', e.target.value)
                  }
                  component={Input}
                  customInput={<Input />}
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>Órgão Emissor</FieldLabel>
                <Field
                  name={`tenant.identityDocument`}
                  value={values.tenant.identityDocumentIssuance}
                  onChange={(e: any) =>
                    setFieldValue('tenant.identityDocumentIssuance', e.target.value)
                  }
                  component={Input}
                  customInput={<Input />}
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>UF</FieldLabel>
                <Dropdown
                  upward
                  clearable
                  style={{ width: '100%', marginRight: 10 }}
                  search
                  value={values.tenant.identityDocumentUf}
                  options={UFOptions}
                  selection
                  onChange={(_e, { value }) => {
                    setFieldValue('tenant.identityDocumentUf', value);
                  }}
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>Data de Emissão</FieldLabel>
                <Field
                  name={`tenant.identityDocumentDate`}
                  selected={values.tenant.identityDocumentDate}
                  component={CustomDatePicker}
                  fluid
                  onChange={(date: Date, e: any) => {
                    setFieldValue('tenant.identityDocumentDate', date);
                  }}
                  customInput={
                    <AppMaskedInput
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/
                      ]}
                    />
                  }
                  dateFormat={'dd/MM/yyyy'}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={8}>
                <FieldLabel>Profissão</FieldLabel>
                <Field
                  name={`tenant.profession`}
                  value={values.tenant.profession}
                  onChange={(e: any) =>
                    setFieldValue('tenant.profession', e.target.value)
                  }
                  component={Input}
                  customInput={<Input />}
                  fluid
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <FieldLabel>Renda mensal</FieldLabel>
                <Dropdown
                  upward
                  clearable
                  style={{ width: '100%', marginRight: 10 }}
                  search
                  value={`${values.tenant.incomeBracket}`}
                  options={incomeBracketsOptions}
                  selection
                  onChange={(_e, { value }) => {
                    setFieldValue('tenant.incomeBracket', value);
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel paddingTop={true}>Pessoa politicamente exposta?</FieldLabel>
                <Grid.Row>
                  <Grid.Column
                    width={5}
                    style={{
                      paddingTop: '10px'
                    }}
                  >
                    <Radio
                      style={{ marginRight: '1rem' }}
                      label="Sim"
                      name={`tenant.politicallyExposedPerson`}
                      value={`tenant.politicallyExposedPerson`}
                      checked={
                        values.tenant.politicallyExposedPerson ===
                        PoliticallyExposedPersonEnum.yes
                      }
                      onChange={(_, data) => {
                        setFieldValue(
                          data.name!,
                          data.value
                            ? PoliticallyExposedPersonEnum.yes
                            : PoliticallyExposedPersonEnum.no
                        );
                      }}
                    />

                    <Radio
                      style={{ marginRight: '1rem' }}
                      label="Não"
                      name={`tenant.politicallyExposedPerson`}
                      value={`tenant.politicallyExposedPerson`}
                      checked={
                        values.tenant.politicallyExposedPerson ===
                        PoliticallyExposedPersonEnum.no
                      }
                      onChange={(_, data) => {
                        setFieldValue(
                          data.name!,
                          data.value
                            ? PoliticallyExposedPersonEnum.no
                            : PoliticallyExposedPersonEnum.yes
                        );
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
            {values.tenant.politicallyExposedPerson ===
              PoliticallyExposedPersonEnum.yes && (
              <Grid.Row>
                <Grid.Column>
                  <FieldLabel>Nome Completo</FieldLabel>
                  <Field
                    name={`tenant.politicallyExposedPersonName`}
                    value={values.tenant.politicallyExposedPersonName}
                    onChange={(e: any) =>
                      setFieldValue('tenant.politicallyExposedPersonName', e.target.value)
                    }
                    component={Input}
                    customInput={<Input />}
                    fluid
                  />
                </Grid.Column>
                <Grid.Column>
                  <FieldLabel>CPF</FieldLabel>
                  <Field
                    name={`tenant.politicallyExposedPersonCpf`}
                    value={values.tenant.politicallyExposedPersonCpf}
                    onChange={(e: any) =>
                      setFieldValue(
                        'tenant.politicallyExposedPersonCpf',
                        maskCpfOrCnpj(e.target.value)
                      )
                    }
                    component={Input}
                    customInput={<Input />}
                    fluid
                  />
                </Grid.Column>
                <Grid.Column>
                  <FieldLabel>Grau de parentesco</FieldLabel>
                  <Field
                    name={`tenant.politicallyExposedPersonDegree`}
                    value={values.tenant.politicallyExposedPersonDegree}
                    onChange={(e: any) =>
                      setFieldValue(
                        'tenant.politicallyExposedPersonDegree',
                        e.target.value
                      )
                    }
                    component={Input}
                    customInput={<Input />}
                    type="number"
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            )}
          </>
        )}
      </Grid>
    </div>
  );
};

export default CapitalizationTenantStep;
