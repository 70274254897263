import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { Icon } from "semantic-ui-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { Timeline, TimelineEvent } from "react-event-timeline";
import {
  getMessages,
  sendMessage,
  updateViewed,
} from "../../services/followup";
import Button from "../Button";
import { get } from "../../services/storage";

import { MessageContainer, MessageInput } from "./styles";
import {
  dangerNotification,
  successNotification,
  warningNotification,
} from "../../services/notification";
import { UserRolesEnum } from "../../enums/user-roles.enum";
import styled from "styled-components";
import { hasPermission } from "../AuthorizationContainer";
import { ActionEnum } from "../../enums/authz-action.enum";
import { FeatureEnum } from "../../enums/authz-feature.enum";

export type MessageType = {
  id?: string;
  text: string;
  senderId: {
    name:
      | boolean
      | React.ReactChild
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined;
    role: string;
  };
  createdAt: moment.MomentInput;
};

const Div = styled.div`
  strong {
    font-weight: bold;
  }
`;

const FollowUp = (props: {
  messages: MessageType[];
  id: string;
  isBlocked?: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState(props.messages);
  const [message, setMessage] = useState("");
  const id = props.id;
  const claim_id = id;
  const user_id = get("uid");
  const userName = get("name");
  const userRole = get("role");

  const [userCanSendMessage, setUserCanSendMessage] = useState<boolean>(false);

  useEffect(() => {
    const has = hasPermission(ActionEnum.create, FeatureEnum.followups);
    setUserCanSendMessage(has);
  }, []);

  useEffect(() => {
    const notSeen = messages.filter((message: any) => !message.viewed);
    console.log(messages);
    let willUpdate = [];
    if (userRole?.includes("ESTATE")) {
      willUpdate = notSeen.filter((message: any) =>
        message.senderId.role.includes("BROKER")
      );
    } else {
      console.log("IS BROKER: ", user_id);
      console.log("Não Lidos: " + notSeen.length);
      console.log(notSeen);
      willUpdate = notSeen.filter(
        (message: any) => {
          if (userRole === UserRolesEnum.brokerAdmin && user_id !== message.claimEstateId.responsibleId) {
            return;
          } else {
            if (message.senderId.id !== user_id && message.claimEstateId.responsibleId === user_id) {
            return message;
          }
        }}
      );
    }

    willUpdate.length > 0 &&
      successNotification(
        "Lido",
        `${willUpdate.length} mensagens marcadas como lidas!`
      );
    for (const message of willUpdate) {
      if (message.id && user_id) {
        updateViewed(message.id, user_id);
      }
    }
  }, [messages]);

  const handleSubmit = async () => {
    if (!message.trim() || !message || message.length < 1) {
      warningNotification(
        "Oops...",
        "Por favor, digite uma mensagem antes de enviar."
      );
    } else {
      try {
        setLoading(true);
        await sendMessage(claim_id, user_id!, message, userName!);
        const response = await getMessages(id);
        setMessages([...response]);
        successNotification("Sucesso!", "Mensagem enviada!");
      } catch (error) {
        console.log("ERRO AO ENVIAR MENSAGEM:", error);
        dangerNotification(
          "Oops...",
          "Não foi possível enviar a mensagem, tente novamente mais tarde."
        );
      } finally {
        setMessage("");
        setLoading(false);
      }
    }
  };

  const modules = {
    toolbar: [
      [{ align: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ size: ["normal", "large", "huge"] }],
      [{ color: [] }, { background: [] }],
    ],
  };

  return (
    <Div>
      <MessageContainer>
        <div className="quilljs">
          <ReactQuill
            theme="snow"
            modules={modules}
            value={message}
            onChange={(value) => setMessage(value)}
            readOnly={loading || !userCanSendMessage || props.isBlocked}
          />
        </div>
        <div>
          <Button
            primary
            content="Enviar"
            labelPosition="right"
            icon="send"
            onClick={handleSubmit}
            loading={loading}
            disabled={loading || !userCanSendMessage || props.isBlocked}
          />
        </div>
      </MessageContainer>

      <div style={{ overflowY: "scroll" }}>
        <Timeline>
          {messages.map((message: MessageType) => {
            return (
              <TimelineEvent
                key={message.id}
                title={message.senderId.name ? message.senderId.name : userName}
                createdAt={moment(message.createdAt).format("DD/MM/YY - HH:mm")}
                icon={<Icon name="envelope open outline" size="large" />}
                iconColor="#5C6BC0"
                style={{
                  backgroundColor: "#fff",
                  padding: 10,
                  boxShadow: "0 0 3px 1px #5C6BC0",
                }}
                iconStyle={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 2,
                }}
                contentStyle={{
                  backgroundColor: "#FEFEFE",
                  color: "#5C6BC0",
                }}
              >
                <ReactQuill
                  theme="bubble"
                  value={message.text}
                  readOnly={true}
                />
              </TimelineEvent>
            );
          })}
        </Timeline>
      </div>
    </Div>
  );
};

export default FollowUp;

{
  /* <Item.Group>
      {messages.map((message: MessageType) => {
        return (
          <Item>
            <Item.Content>
              <Item.Header>{message.text}</Item.Header>
              <ContainerInfo>
                <Item.Meta><span>{moment(message.createdAt).format('DD/MM/YY - HH:mm:ss')}</span></Item.Meta>
                <Item.Description><p>{message.senderId.name}</p></Item.Description>
              </ContainerInfo>
            </Item.Content>
          </Item>
        )
      })}
    </Item.Group> */
}
