import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Grid,
  InputOnChangeData,
  List,
  Radio,
  Dropdown,
  DropdownItemProps,
  Icon,
  Popup,
  TextArea
} from 'semantic-ui-react';
import { Field, FormikProps } from 'formik';
import SectionLabel from '../../../../../../../../components/SectionLabel';
import { FieldLabel } from '../../../../../../../../styles';
import {
  DATE_INPUT_MASK,
  formatAmount,
  maskCpf,
  maskCpfOrCnpj,
  maskDate,
  maskMoney,
  maskPhoneNumber,
  maskZipCode,
  numberToMoneyString
} from '../../../../../../../../services/masks';
import Input from '../../../../../../../../components/Input';
import { AddressService } from '../../../../../../../../services/address';
import {
  HireProposalModalProps,
  HireProposalModalPropsForm
} from '../../../../types/ModalHireProposalProps';
import { DateToStringDate } from '../../../../../../../../services/date';
import CustomDatePicker from '../../../../../../../../components/CustomDatePicker';
import { SyntheticEvent } from 'react-router/node_modules/@types/react';
import DatePicker from 'react-date-picker';
import AppMaskedInput from '../../../../../../../../components/MaskedInput';
import { CoveragesDTO } from '../../../../../../../fire-insurance-proposal-list/types';
import { getCoverages } from '../../../../../../../../services/fire-insurance-quotation';
import { InsurersCodeEnum } from '../../../../../../../../enums/insurers-code.enum';
import Toggle from '../../../../../../../../components/Toggle';
import {
  cnpjValidity,
  cpfValidity,
  dateDiffInYears,
  FormatCurrency,
  monthDiff
} from '../../../../../../../../util';
import { GetCoveragesDTO } from '../../../../types/GetCoverages';
import { SurtyGeneralInsuraceInitialValues } from '../../Formik/initialStatesSurty';
import { PurposesEnum } from '../../../../../../../../enums/purposes.enum';
import {
  genderOptions,
  hirePropertyTypeOptions,
  legalCommercialResidentialReasonOptions,
  maritalStatusOptions,
  naturalCommercialReasonOptions,
  naturalResidentialReasonOptions
} from '../../../../../../../bond-insurance-budget-form/types';
import { PersonTypesEnum } from '../../../../../../../../enums/person-types.enum';
import { CustoDropDownItemProps } from '../../../../../../../../components/types';
import { AnalysisAssuranceEnum } from '../../../../../../../../enums/analysis-assurance.enum';
import _ from 'lodash';
import { warningNotification } from '../../../../../../../../services/notification';
import { PoliticallyExposedPersonEnum } from '../../../../../../../../enums/politically-exposed-person.enum';
import FormatedTextArea from '../../../../../../../../components/FormatedTextArea';
import { GrayableGridColumn } from '../../../../../../../estates-form/styles';
import moment from 'moment-timezone';
import AddressWarningBanner from '../../../AddressWarningBanner';
import { getDataByDocument } from '../../../../../../../../services/documents';

const SurtyGeneralInsuranceForm = ({
  values,
  setFieldValue,
  maxInstallments,
  nominal_total_premium_amount,
  flagSubmit,
  callbackSubmit,
  setFlagSubmit,
  naturalTentant,
  fireQuotationValue,
  property,
  bondInsurance,
  rentOnly,
  analysisID
}: FormikProps<typeof SurtyGeneralInsuraceInitialValues> &
  HireProposalModalProps &
  HireProposalModalPropsForm) => {
  const [reasonsOptions, setReasonsOptions] = useState<Array<DropdownItemProps>>([]);
  const [cepLoading, setCepLoading] = useState<boolean>(false);
  const [maxRent, setMaxRent] = useState<number>(0);
  const [noComplement, setNoComplement] = useState<boolean>(false);
  const [documentError, setDocumentError] = useState(false);
  const [loadDocument, setLoadDocument] = useState(false);
  const [disableNameField, setDisabledNameField] = useState<boolean>(true);

  const [rentStart, setRentStart] = useState<Date>();
  const [rentEnd, setRentEnd] = useState<Date>();

  function getBirthDate() {
    const data = moment(bondInsurance?.natural_tenant?.main_tenant_birth_date).toDate();
    return data;
  }

  const handleToggle = () => {
    setNoComplement(!noComplement);
  };

  const onChangeCep = (zipCode: string, isProperty?: boolean): void => {
    const maskedValue = maskZipCode(zipCode);

    setFieldValue('zipCode', maskedValue);
    if (maskedValue.length >= 9) {
      setCepLoading(true);
      isProperty
        ? AddressService.getAddressByZipCode(zipCode)
            .then(address => {
              setFieldValue('property.city', address.city);
              setFieldValue('property.district', address.district);
              setFieldValue('property.state', address.state);
              setFieldValue('property.street', address.street);
              setCepLoading(false);
            })
            .catch(e => {
              console.log('address error:', e);
            })
        : AddressService.getAddressByZipCode(zipCode)
            .then(address => {
              setFieldValue('estate.estateCity', address.city);
              setFieldValue('estate.estateDistrict', address.district);
              setFieldValue('estate.estateState', address.state);
              setFieldValue('estate.estateStreet', address.street);
              setCepLoading(false);
            })
            .catch(e => {
              console.log('address error:', e);
            });
    }
  };

  useEffect(() => {
    if (values.estate.estateZipCode) {
    }
    if (values.estate.estateZipCode && values.estate.estateZipCode.length >= 8) {
      onChangeCep(values.estate.estateZipCode);
    }
  }, [values.estate.estateZipCode]);

  const analysisOptions: CustoDropDownItemProps[] = [
    {
      key: 1,
      text: 'Plano Seguro Completo (Garantido)',
      value: AnalysisAssuranceEnum.complete
    },
    {
      key: 2,
      text: 'Plano Seguro Simples (Básico)',
      value: AnalysisAssuranceEnum.simple
    }
  ];

  useEffect(() => {
    if (
      bondInsurance?.purpose === PurposesEnum.commercial &&
      bondInsurance?.tenant_type === PersonTypesEnum.natural
    ) {
      setReasonsOptions(naturalCommercialReasonOptions);
    } else if (
      bondInsurance?.purpose === PurposesEnum.residential &&
      bondInsurance?.tenant_type === PersonTypesEnum.natural
    ) {
      setReasonsOptions(naturalResidentialReasonOptions);
    } else {
      setReasonsOptions(legalCommercialResidentialReasonOptions);
    }
  }, [bondInsurance]);

  useEffect(() => {
    setFieldValue('analysis_assurance', bondInsurance?.analysis_assurance);
    setFieldValue('estate', {
      estateZipCode: (bondInsurance?.estate as any)?.estate_zip_code,
      estateStreet: (bondInsurance?.estate as any).estate_street,
      estateDistrict: (bondInsurance?.estate as any).estate_district,
      estateCity: (bondInsurance?.estate as any).estate_city,
      estateState: (bondInsurance?.estate as any).estate_state,
      estateNumber: (bondInsurance?.estate as any).estate_number,
      estateComplement: (bondInsurance?.estate as any).estate_complement
    });
    setFieldValue('legal_tenant', bondInsurance?.legal_tenant);
    setFieldValue('natural_tenant', bondInsurance?.natural_tenant);
    if (bondInsurance?.property.location_in_progress) {
      setRentStart(moment(bondInsurance.property.rental_period_start_date).toDate());
      setRentEnd(moment(bondInsurance.property.rental_period_end_date).toDate());
    } else {
      delete bondInsurance?.property.rental_period_start_date;
      delete bondInsurance?.property.rental_period_end_date;
    }
    delete bondInsurance?.property.rent_insurance_contract_start_date;
    delete bondInsurance?.property.rent_insurance_contract_end_date;
    setFieldValue('property', bondInsurance?.property);
    setFieldValue('purpose', bondInsurance?.purpose);
    setFieldValue('property.type', bondInsurance?.property.property_type);
    setMaxRent(Number(bondInsurance?.property.rent_value || 0));
    setFieldValue(
      'property.formatted_rent_value',
      maskMoney(String(Number(bondInsurance?.property.rent_value || 0)), true, 2)
    );

    setFieldValue('property.rent_value', Number(bondInsurance?.property.rent_value) || 0);
    setFieldValue(
      'formattedValues.rent_value',
      maskMoney(String(bondInsurance?.property.rent_value), true, 2)
    );

    setFieldValue(
      'property.condominium_fee',
      Number(bondInsurance?.property.condominium_fee) || 0
    );
    setFieldValue(
      'formattedValues.condominium_fee',
      maskMoney(String(bondInsurance?.property.condominium_fee), true, 2)
    );

    setFieldValue(
      'property.taxes_value',
      Number(bondInsurance?.property.taxes_value) || 0
    );
    setFieldValue(
      'formattedValues.taxes_value',
      maskMoney(String(bondInsurance?.property.taxes_value), true, 2)
    );

    setFieldValue('property.water_bill', Number(bondInsurance?.property.water_bill) || 0);
    setFieldValue(
      'formattedValues.water_bill',
      maskMoney(String(bondInsurance?.property.water_bill), true, 2)
    );

    setFieldValue(
      'property.energy_bill',
      Number(bondInsurance?.property.energy_bill) || 0
    );
    setFieldValue(
      'formattedValues.energy_bill',
      maskMoney(String(bondInsurance?.property.energy_bill), true, 2)
    );

    setFieldValue('property.gas_bill', Number(bondInsurance?.property.gas_bill) || 0);
    setFieldValue(
      'formattedValues.gas_bill',
      maskMoney(String(bondInsurance?.property.gas_bill), true, 2)
    );

    // if (bondInsurance?.property_owner_natural_person?.name) {
    //   setFieldValue(
    //     'owner_data.name',
    //     bondInsurance?.property_owner_natural_person?.name
    //   );
    // }
    // if (bondInsurance?.property_owner_natural_person?.document) {
    //   setFieldValue(
    //     'owner_data.public_document_id',
    //     maskCpfOrCnpj(bondInsurance?.property_owner_natural_person?.document)
    //   );
    // }
  }, [bondInsurance]);

  useEffect(() => {
    const diff = monthDiff(
      new Date(values.property.rent_insurance_contract_start_date || Date.now()),
      new Date(
        values.property.rent_insurance_contract_end_date ||
          new Date().setMonth(new Date().getMonth() + maxInstallments)
      )
    );
    setFieldValue('hiredInstallments', diff);
  }, [values.property.rent_insurance_contract_end_date]);

  useEffect(() => {
    function submitForm() {
      let error = false;
      setFlagSubmit && setFlagSubmit(false);
      if (!values.property.rent_value || Number(values.property.rent_value) > maxRent) {
        warningNotification('Ops...', 'Valor do aluguel inválido.');
        error = true;
      }
      if (!values.property.reason) {
        warningNotification('Ops...', 'Por favor, preencha o motivo da locação!');
        error = true;
      }

      if (!values.property.zip_code) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço do imóvel!'
        );
        error = true;
      }
      if (!values.property.street) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço do imóvel!'
        );
        error = true;
      }
      if (!values.property.district) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço do imóvel!'
        );
        error = true;
      }
      if (!values.property.city) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço do imóvel!'
        );
        error = true;
      }
      if (!values.property.state) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço do imóvel!'
        );
        error = true;
      }
      if (!values.property.number) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço do imóvel!'
        );
        error = true;
      }
      // if (typeof values.property.number !== 'number') {
      //   warningNotification(
      //     "Ops...",
      //     "O campo número do endereço do imóvel deve ter apenas números!"
      //   );
      //   error = true;
      // }

      if (!values.property.complement && !noComplement) {
        warningNotification(
          'Ops...',
          'Por favor, preencha o complemento do imóvel! Caso não tenha complemento, por favor marque ao lado!'
        );
        error = true;
      }

      if (!values.property.type) {
        warningNotification('Ops...', 'Por favor, preencha o Tipo do imóvel!');
        error = true;
      }

      if (!values.estate.estateZipCode) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço da imobiliaria!'
        );
        error = true;
      }
      if (!values.estate.estateStreet) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço da imobiliaria!'
        );
        error = true;
      }
      if (!values.estate.estateDistrict) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço da imobiliaria!'
        );
        error = true;
      }
      if (!values.estate.estateCity) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço da imobiliaria!'
        );
        error = true;
      }
      if (!values.estate.estateState) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço da imobiliaria!'
        );
        error = true;
      }
      if (!values.estate.estateNumber) {
        warningNotification(
          'Ops...',
          'Por favor, preencha corretamente o endereço da imobiliaria!'
        );
        error = true;
      }

      if (!values.owner_data.public_document_id) {
        warningNotification('Ops...', 'Por favor, preencha o Documento do Proprietário!');
        error = true;
      }
      if (!values.owner_data.name) {
        warningNotification('Ops...', 'Por favor, preencha o Nome do Proprietário!');
        error = true;
      }

      if (
        values.owner_data.public_document_id.replace(/\D/g, '') ===
        bondInsurance?.natural_tenant?.main_tenant_cpf.replace(/\D/g, '')
      ) {
        warningNotification(
          'Erro...',
          'O documento do Proprietário deve ser DIFERENTE do documento do Locatário!'
        );
        error = true;
      }

      if (!values.property.rent_insurance_contract_start_date) {
        warningNotification(
          'Ops...',
          'Por favor, preencha o Início da Vigência de Contrato de Locação!'
        );
        error = true;
      }

      if (!values.property.rent_insurance_contract_end_date) {
        warningNotification(
          'Ops...',
          'Por favor, preencha o Fim da Vigência de Contrato de Locação!'
        );
        error = true;
      }

      if (
        values.property.rent_insurance_contract_start_date &&
        values.property.rent_insurance_contract_end_date
      ) {
        if (
          dateDiffInYears(
            values.property.rent_insurance_contract_start_date,
            values.property.rent_insurance_contract_end_date
          ) < 1
        ) {
          warningNotification(
            'Ops...',
            'A vigência do contrato de locação deve ser de no mínimo 1 ano completo!'
          );
          error = true;
        }

        const garanttiAnalisys = bondInsurance?.bond_insurance_analysis.find(
          analisys =>
            analisys.status === 'APPROVED' && analisys.insurer.name.includes('Garantti')
        );

        if (garanttiAnalisys) {
          const base = garanttiAnalisys.created_at
            ? new Date(garanttiAnalisys.created_at).setHours(0, 0, 0)
            : new Date();
          const compare = new Date(values.property.rent_insurance_contract_start_date);

          const result = moment(compare).isSameOrAfter(base);
          if (!result) {
            console.log(result, base, compare, garanttiAnalisys);
            warningNotification(
              'Ops...',
              'A data de início do contrato deve ser maior que a data de Aprovação da Análise.'
            );
            error = true;
          }
        }

        if (
          monthDiff(
            values.property.rent_insurance_contract_start_date,
            values.property.rent_insurance_contract_end_date
          ) > 60
        ) {
          warningNotification(
            'Ops...',
            'A diferença máxima entre as datas de inicio e fim do contrato de locação é de 5 anos.'
          );
          error = true;
        }
      }

      if (values.owner_data.public_document_id.length === 14) {
        if (!cpfValidity(values.owner_data.public_document_id)) {
          warningNotification('Ops. ', 'CPF inválido');
          setDocumentError(true);
          error = true;
        }
      } else {
        if (!cnpjValidity(values.owner_data.public_document_id)) {
          warningNotification('Ops. ', 'CNPJ inválido');
          setDocumentError(true);
          error = true;
        }
      }

      if (error) {
        return;
      }
      // if(rentOnly && Number(values.property.rent_value) > maxRent) {
      //   warningNotification("Ops.", "Para o valor informado, será necessário realizar uma reanalise. ")
      //   return
      // }
      // if(!rentOnly) {
      //   const total =
      //       (Number(values.property.rent_value) || 0)
      //     + (Number(values.property.taxes_value) || 0)
      //     + (Number(values.property.condominium_fee) || 0)
      //     + (Number(values.property.energy_bill) || 0)
      //     + (Number(values.property.gas_bill) || 0)
      //     + (Number(values.property.water_bill) || 0);
      // if(total > maxRent) {
      //     warningNotification("Ops...", "Para o valor informado, será necessário realizar a reanalise. ")

      //     setFieldValue("property.rent_value", originalRentValue);
      //     setFieldValue("formattedValues.rent_value",maskMoney(String(originalRentValue), true, 2))

      //     setFieldValue("property.condominium_fee", originalCondominium);
      //     setFieldValue("formattedValues.condominium_fee", maskMoney(String(originalCondominium), true, 2));

      //     setFieldValue("property.taxes_value", originalTaxesValue);
      //     setFieldValue("formattedValues.taxes_value", maskMoney(String(originalTaxesValue), true, 2));

      //     setFieldValue("property.water_bill", originalWaterBill);
      //     setFieldValue("formattedValues.water_bill", maskMoney(String(originalWaterBill), true, 2));

      //     setFieldValue("property.energy_bill", originalEnergyBill);
      //     setFieldValue("formattedValues.energy_bill", maskMoney(String(originalEnergyBill), true, 2));

      //     setFieldValue("property.gas_bill", originalGasBill);
      //     setFieldValue("formattedValues.gas_bill", maskMoney(String(originalGasBill), true, 2));
      //     return
      //   }}
      const payload = {
        bondInsuranceAnalysisId: analysisID,
        purpose: values.purpose,
        analysis_assurance: values.analysis_assurance,
        observations: values.observations,
        property: {
          reason: values.property.reason,
          zip_code: values.property.zip_code,
          street: values.property.street,
          district: values.property.district,
          city: values.property.city,
          complement: values.property.complement,
          state: values.property.state,
          number: values.property.number,
          rent_insurance_contract_start_date:
            values.property.rent_insurance_contract_start_date,
          rent_insurance_contract_end_date:
            values.property.rent_insurance_contract_end_date,
          rental_period_start_date: values.property.rental_period_start_date,
          rental_period_end_date: values.property.rental_period_end_date,
          rent_value: values.property.rent_value,
          type: values.property.type,
          condominium_fee: values.property.condominium_fee,
          taxes_value: values.property.taxes_value,
          energy_bill: values.property.energy_bill,
          water_bill: values.property.water_bill,
          gas_bill: values.property.gas_bill
        },
        estate: {
          estateZipCode: values.estate.estateZipCode,
          estateStreet: values.estate.estateStreet,
          estateDistrict: values.estate.estateDistrict,
          estateCity: values.estate.estateCity,
          estateState: values.estate.estateState,
          estateNumber: values.estate.estateNumber,
          estateComplement: values.estate.estateComplement
        },
        owner_data: {
          public_document_id: values.owner_data.public_document_id,
          name: values.owner_data.name
        },
        hiredInstallments: values.hiredInstallments,
        hiredInstallmentsValue: nominal_total_premium_amount / maxInstallments
      };
      callbackSubmit(payload);
    }
    flagSubmit && submitForm();
  }, [flagSubmit]);

  async function getOwnerByDocument(document: string) {
    try {
      setLoadDocument(true);
      const unformatedDoc = document.replace(/\D/g, '');
      const isCPF = unformatedDoc.length === 11;
      const data = await getDataByDocument(unformatedDoc);
      if (isCPF) {
        setDisabledNameField(true);
        setFieldValue('owner_data.name', data.name);
      } else {
        setDisabledNameField(false);
        setFieldValue('owner_data.name', data.company);
        setFieldValue('owner_data.phone', data.phone);
        setFieldValue('owner_data.email', data.email);
      }
    } catch (err) {
      console.log(err);
      setDisabledNameField(false);
    } finally {
      setLoadDocument(false);
    }
  }

  return (
    <div>
      <SectionLabel text="INFORMAÇÕES DA LOCAÇÃO" />
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column width={5}>
            <Radio
              label="Residencial"
              name={`purpose`}
              value={PurposesEnum.residential}
              checked={values.purpose === PurposesEnum.residential}
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <Radio
              label="Comercial"
              name={`bondInsurance.purpose`}
              value={PurposesEnum.commercial}
              checked={values.purpose === PurposesEnum.commercial}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel>MOTIVO DA LOCAÇÃO* </FieldLabel>
            <Dropdown
              name={`bondInsurance.reason`}
              value={values.property.reason}
              options={reasonsOptions}
              onChange={(e, { value }) => setFieldValue('property.reason', value)}
              floating
              search
              selection
              fluid
              placeholder="Selecionar..."
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <FieldLabel>TIPO DO IMÓVEL* </FieldLabel>
            <Dropdown
              name={`bondInsurance.propertyType`}
              value={values.property.type}
              options={hirePropertyTypeOptions}
              onChange={(e, { value }) => setFieldValue('property.type', value)}
              floating
              search
              selection
              fluid
              placeholder="Selecionar..."
            />
          </Grid.Column>
        </Grid.Row>

        <SectionLabel text="ENDEREÇO DO IMÓVEL" />
        <Grid.Row>
          <AddressWarningBanner />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>CEP*</FieldLabel>
            <Field
              name={`bondInsurance.zip_code`}
              value={values.property.zip_code}
              loading={cepLoading}
              onChange={(_: any, data: InputOnChangeData) => {
                onChangeCep(data.value, true);
                setFieldValue('property.zip_code', maskZipCode(data.value));
              }}
              component={Input}
              maxLength={9}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column width={11}>
            <FieldLabel>LOGRADOURO*</FieldLabel>
            <Field
              name={`bondInsurance.street`}
              value={values.property.street}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('property.street', data.value)
              }
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>BAIRRO*</FieldLabel>
            <Field
              name={`bondInsurance.district`}
              value={values.property.district}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('property.district', data.value)
              }
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <FieldLabel>CIDADE*</FieldLabel>
            <Field
              name={`bondInsurance.city`}
              value={values.property.city}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('property.city', data.value)
              }
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <FieldLabel>UF*</FieldLabel>
            <Field
              name={`bondInsurance.state`}
              value={values.property.state}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('property.state', data.value)
              }
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>NÚMERO*</FieldLabel>
            <Field
              name={`bondInsurance.number`}
              value={values.property.number}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('property.number', data.value)
              }
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>

          <GrayableGridColumn width={6}>
            <FieldLabel
              style={
                !noComplement
                  ? {
                      opacity: '1'
                    }
                  : {
                      opacity: '0.5'
                    }
              }
            >
              COMPLEMENTO*
            </FieldLabel>
            <Field
              name={`bondInsurance.complement`}
              value={values.property.complement}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('property.complement', data.value)
              }
              component={Input}
              fluid
              disabled={noComplement}
              style={
                !noComplement
                  ? {
                      opacity: '1'
                    }
                  : {
                      opacity: '0.5'
                    }
              }
            />
          </GrayableGridColumn>

          <Grid.Column width={5}>
            <Toggle
              style={{ height: '25%', flex: 'none' }}
              label={noComplement ? 'SEM COMPLEMENTO' : 'COM COMPLEMENTO'}
              checked={noComplement ? true : false}
              onChange={handleToggle}
            />
          </Grid.Column>
          {/* <Grid.Column width={6}>
            <Grid.Row>
              <FieldLabel>
                Confirmação de Complemento
              </FieldLabel>
            </Grid.Row>

            <p>
              <Radio
                style={{ marginRight: '20px' }}
                label='Com Complemento'
                name={`complement`}
                value={'withComplement'}
                checked={values.property.complement === ComplementEnum.noComplement}
                onChange={(_, data) => {
                  setFieldValue(data.name!, data.value ? ComplementEnum.noComplement : ComplementEnum.withComplement);
                }}
              />

              <Radio
                label='Sem Complemento'
                name={`complement`}
                value={'noComplement'}
                checked={values.property.complement !== ComplementEnum.noComplement}
                onChange={(_, data) => {
                  setFieldValue(data.name!, data.value ? ComplementEnum.withComplement : ComplementEnum.noComplement);
                }} />
            </p>
          </Grid.Column> */}
        </Grid.Row>
      </Grid>
      <SectionLabel text="INFORMAÇÕES DO PROPRIETÁRIO" />
      <Grid>
        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>CPF/CNPJ*</FieldLabel>
            <Field
              name={`bondInsurance.number`}
              value={maskCpfOrCnpj(values.owner_data.public_document_id)}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('owner_data.public_document_id', data.value)
              }
              onBlur={(e: { target: { value: string | any[] } }) => {
                const doc = e.target.value as string;
                if (doc.length === 14) {
                  if (!cpfValidity(doc)) {
                    warningNotification('Ops. ', 'CPF inválido');
                    setDocumentError(true);
                    setDisabledNameField(false);
                    return;
                  }
                  setDocumentError(false);
                  getOwnerByDocument(doc);
                } else {
                  if (!cnpjValidity(doc)) {
                    warningNotification('Ops. ', 'CNPJ inválido');
                    setDocumentError(true);
                    setDisabledNameField(false);
                    return;
                  }
                  setDocumentError(false);
                  getOwnerByDocument(doc);
                }
              }}
              component={Input}
              fluid
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <FieldLabel>NOME*</FieldLabel>
            <Field
              name={`bondInsurance.complement`}
              value={values.owner_data.name}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('owner_data.name', data.value)
              }
              loading={loadDocument}
              disabled={disableNameField}
              component={Input}
              fluid
            />
          </Grid.Column>
        </Grid.Row>

        <SectionLabel text="ENDEREÇO DA IMOBILIARIA OU PROPRIETÁRIO" />
        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>CEP*</FieldLabel>
            <Field
              name={`bondInsurance.zip_code`}
              value={values.estate?.estateZipCode}
              loading={cepLoading}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateZipCode', maskZipCode(data.value))
              }
              component={Input}
              maxLength={9}
              fluid
            />
          </Grid.Column>

          <Grid.Column width={11}>
            <FieldLabel>LOGRADOURO*</FieldLabel>
            <Field
              name={`bondInsurance.street`}
              value={values.estate?.estateStreet}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateStreet', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>BAIRRO*</FieldLabel>
            <Field
              name={`bondInsurance.district`}
              value={values.estate?.estateDistrict}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateDistrict', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <FieldLabel>CIDADE*</FieldLabel>
            <Field
              name={`bondInsurance.city`}
              value={values.estate?.estateCity}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateCity', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <FieldLabel>UF*</FieldLabel>
            <Field
              name={`bondInsurance.state`}
              value={values.estate?.estateState}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateState', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>NÚMERO*</FieldLabel>
            <Field
              name={`bondInsurance.number`}
              value={values.estate?.estateNumber}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateNumber', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <FieldLabel>COMPLEMENTO</FieldLabel>
            <Field
              name={`bondInsurance.complement`}
              value={values.estate?.estateComplement}
              onChange={(_: any, data: InputOnChangeData) =>
                setFieldValue('estate.estateComplement', data.value)
              }
              component={Input}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <SectionLabel text="DADOS DO PROPONENTE (Dados do Locatário)" />
      <Grid>
        {bondInsurance?.tenant_type === PersonTypesEnum.legal ? (
          <>
            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>CNPJ*</FieldLabel>
                <Field
                  name={`legalTenant.document`}
                  value={bondInsurance?.legal_tenant.document}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={8}>
                <FieldLabel>EMPRESA*</FieldLabel>
                <Field
                  name={`legalTenant.company`}
                  value={bondInsurance?.legal_tenant.company}
                  customInput={<Input />}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>TELEFONE*</FieldLabel>
                <Field
                  name={`legalTenant.phone`}
                  value={bondInsurance?.legal_tenant.phone}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>EMAIL*</FieldLabel>
                <Field
                  name={`legalTenant.email`}
                  value={bondInsurance?.legal_tenant.email}
                  customInput={<Input />}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>ÚLT. ALT. CONTRATUAL*</FieldLabel>
                <Field
                  name={`legalTenant.last_contractual_alteration_date`}
                  value={bondInsurance?.legal_tenant.last_contractual_alteration_date}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>CAPITAL SOCIAL*</FieldLabel>
                <Field
                  name={`legalTenant.share_capital`}
                  value={bondInsurance?.legal_tenant.share_capital}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>CAPITAL INTEGRALIZADO*</FieldLabel>
                <Field
                  name={`legalTenant.paid_in_capital`}
                  value={bondInsurance?.legal_tenant.paid_in_capital}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>FATURAMENTO ANUAL*</FieldLabel>
                <Field
                  name={`legalTenant.anual_revenue`}
                  value={bondInsurance?.legal_tenant.anual_revenue}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>CONTATO (NOME)*</FieldLabel>
                <Field
                  name={`legalTenant.contact_name`}
                  value={bondInsurance?.legal_tenant.contact_name}
                  customInput={<Input />}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>CONTATO (TELEFONE)*</FieldLabel>
                <Field
                  name={`legalTenant.contact_phone`}
                  value={bondInsurance?.legal_tenant.phone}
                  customInput={<Input />}
                  component={Input}
                  disabled
                  fluid
                />
              </Grid.Column>
            </Grid.Row>
          </>
        ) : (
          <>
            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>CPF*</FieldLabel>
                <Field
                  name={`naturalPerson.main_tenant_cpf`}
                  value={bondInsurance?.natural_tenant?.main_tenant_cpf}
                  component={Input}
                  fluid
                  disabled
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>NOME DO LOCATÁRIO*</FieldLabel>
                <Field
                  autoCorrect="off"
                  autoCapitalize="none"
                  spellCheck="false"
                  autoComplete="new-password"
                  name={`naturalPerson.main_tenant_name`}
                  value={bondInsurance?.natural_tenant?.main_tenant_name}
                  customInput={<Input />}
                  component={Input}
                  fluid
                  disabled
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel paddingTop={true}>GÊNERO* </FieldLabel>
                <Field
                  name={`naturalPerson.main_tenant_gender`}
                  value={bondInsurance?.natural_tenant?.main_tenant_gender}
                  completeValue={true}
                  placeholder={'Selecionar...'}
                  options={genderOptions}
                  component={Dropdown}
                  fluid
                  disabled
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>DATA DE NASCIMENTO* </FieldLabel>
                <Field
                  name={`naturalPerson.main_tenant_birth_date`}
                  selected={getBirthDate()}
                  component={CustomDatePicker}
                  customInput={
                    <AppMaskedInput
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/
                      ]}
                    />
                  }
                  dateFormat={'dd/MM/yyyy'}
                  disabled
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>NOME DA MÃE*</FieldLabel>
                <Field
                  autoCorrect="off"
                  autoCapitalize="none"
                  spellCheck="false"
                  autoComplete="new-password"
                  name={`naturalPerson.main_tenant_mother_name`}
                  value={bondInsurance?.natural_tenant?.main_tenant_mother_name}
                  component={Input}
                  fluid
                  disabled
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>EMAIL*</FieldLabel>
                <Field
                  autoCorrect="off"
                  autoCapitalize="none"
                  spellCheck="false"
                  autoComplete="new-password"
                  name={`naturalPerson.main_tenant_email`}
                  value={bondInsurance?.natural_tenant?.main_tenant_email}
                  customInput={<Input />}
                  component={Input}
                  fluid
                  disabled
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>TELEFONE*</FieldLabel>
                <Field
                  name={`naturalPerson.main_tenant_phone`}
                  value={bondInsurance?.natural_tenant?.main_tenant_phone}
                  component={Input}
                  fluid
                  disabled
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel paddingTop={true}>ESTADO CIVIL* </FieldLabel>
                <Field
                  class={'dropdown'}
                  autoCorrect="off"
                  autoCapitalize="none"
                  spellCheck="false"
                  autoComplete="off"
                  search={false}
                  name={`naturalPerson.main_tenant_marital_status`}
                  value={bondInsurance?.natural_tenant?.main_tenant_marital_status}
                  completeValue={true}
                  placeholder={'Selecionar...'}
                  options={maritalStatusOptions}
                  component={Dropdown}
                  fluid
                  disabled
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>NACIONALIDADE*</FieldLabel>
                <Field
                  name={`naturalPerson.main_tenant_nationality`}
                  value={bondInsurance?.natural_tenant?.main_tenant_nationality}
                  component={Input}
                  fluid
                  disabled
                />
              </Grid.Column>
            </Grid.Row>

            {/* <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel>CEP DO ENDEREÇO ATUAL*</FieldLabel>
                <Field
                  name={`naturalPerson.main_tenant_current_address_zipcode`}
                  value={
                    bondInsurance?.natural_tenant
                      ?.main_tenant_current_address_zipcode
                  }
                  component={Input}
                  maxLength={9}
                  fluid
                  disabled
                />
              </Grid.Column>
            </Grid.Row> */}
            <Grid.Row>
              <Grid.Column width={4}>
                <FieldLabel paddingTop={true}>PESSOA POLITICAMENTE EXPOSTA? </FieldLabel>
                <Grid.Row>
                  <Grid.Column
                    width={5}
                    style={{
                      paddingTop: '10px'
                    }}
                  >
                    <Radio
                      style={{ marginRight: '1rem' }}
                      label="Sim"
                      name={`naturalPerson.main_tenant_politically_exposed_person`}
                      value={PoliticallyExposedPersonEnum.yes}
                      checked={
                        bondInsurance?.natural_tenant
                          ?.main_tenant_politically_exposed_person ===
                        PoliticallyExposedPersonEnum.yes
                      }
                      disabled
                    />

                    <Radio
                      style={{ marginRight: '1rem' }}
                      label="Não"
                      name={`naturalPerson.main_tenant_politically_exposed_person`}
                      value={PoliticallyExposedPersonEnum.no}
                      checked={
                        bondInsurance?.natural_tenant
                          ?.main_tenant_politically_exposed_person ===
                        PoliticallyExposedPersonEnum.no
                      }
                      disabled
                    />

                    <Radio
                      label="Próximo"
                      name={`naturalPerson.main_tenant_politically_exposed_person`}
                      value={PoliticallyExposedPersonEnum.closeTo}
                      checked={
                        bondInsurance?.natural_tenant
                          ?.main_tenant_politically_exposed_person ===
                        PoliticallyExposedPersonEnum.closeTo
                      }
                      disabled
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>

              {bondInsurance?.natural_tenant?.main_tenant_politically_exposed_person ===
                PoliticallyExposedPersonEnum.closeTo && (
                <>
                  <Grid.Column width={4}>
                    <FieldLabel>NOME DA PESSOA*</FieldLabel>
                    <Field
                      autoCorrect="off"
                      autoCapitalize="none"
                      spellCheck="false"
                      autoComplete="new-password"
                      name={`naturalPerson.main_tenant_close_to_politically_exposed_person.politically_exposed_person_name`}
                      customInput={<Input />}
                      component={Input}
                      fluid
                      disabled
                    />
                  </Grid.Column>

                  <Grid.Column width={4}>
                    <FieldLabel>CPF DA PESSOA*</FieldLabel>
                    <Field
                      name={`naturalPerson.main_tenant_close_to_politically_exposed_person.politically_exposed_person_cpf`}
                      value={
                        bondInsurance?.natural_tenant
                          ?.main_tenant_close_to_politically_exposed_person
                          ?.politically_exposed_person_cpf
                      }
                      customInput={<Input />}
                      component={Input}
                      fluid
                      disabled
                    />
                  </Grid.Column>

                  <Grid.Column width={4}>
                    <FieldLabel>GRAU DE PARENTESCO*</FieldLabel>
                    <Field
                      autoCorrect="off"
                      autoCapitalize="none"
                      spellCheck="false"
                      autoComplete="new-password"
                      name={`naturalPerson.main_tenant_close_to_politically_exposed_person.degree_of_kinship_to_politically_exposed_person`}
                      value={
                        bondInsurance?.natural_tenant
                          ?.main_tenant_close_to_politically_exposed_person
                          ?.degree_of_kinship_to_politically_exposed_person
                      }
                      customInput={<Input />}
                      component={Input}
                      fluid
                      disabled
                    />
                  </Grid.Column>
                </>
              )}
            </Grid.Row>

            {/* <Grid.Row>
              {bondInsurance?.natural_tenant?.main_tenant_marital_status ===
                "CASADO" && (
                  <Grid.Column width={4}>
                    <FieldLabel paddingTop={true}>
                      CÔNJUGE COMPÕE RENDA?{" "}
                    </FieldLabel>
                    <Grid.Row>
                      <Grid.Column width={5} style={{ paddingTop: "10px" }}>
                        <Radio
                          style={{ marginRight: "1rem" }}
                          label="Sim"
                          name={`naturalPerson.partner_rent_composition`}
                          checked={
                            bondInsurance?.natural_tenant
                              ?.partner_rent_composition
                          }
                          disabled
                        />
                        <Radio
                          style={{ marginRight: "1rem" }}
                          label="Não"
                          name={`naturalPerson.partner_rent_composition`}
                          checked={
                            !bondInsurance?.natural_tenant
                              ?.partner_rent_composition
                          }
                          disabled
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid.Column>
                )}
            </Grid.Row> */}
          </>
        )}
      </Grid>
      <Grid>
        <SectionLabel text="VIGÊNCIA DA LOCAÇÃO" />
        <Grid.Row>
          {
            <Grid.Column width={8}>
              <FieldLabel>INICIO DA VIGÊNCIA DO CONTRATO DE LOCAÇÃO* </FieldLabel>
              <Field
                name={`rental_period_start_date`}
                selected={rentStart}
                component={CustomDatePicker}
                customInput={<AppMaskedInput mask={DATE_INPUT_MASK} />}
                dateFormat={'dd/MM/yyyy'}
                onChange={(date: Date, e: SyntheticEvent) => {
                  setRentStart(date);
                  setFieldValue('property.rent_insurance_contract_start_date', date);
                  setFieldValue('property.rental_period_start_date', date);
                }}
              />
            </Grid.Column>
          }
          <Grid.Column width={8}>
            <FieldLabel>FIM DA VIGÊNCIA DO CONTRATO DE LOCAÇÃO* </FieldLabel>
            <Field
              name={`rental_period_end_date`}
              selected={rentEnd}
              component={CustomDatePicker}
              customInput={<AppMaskedInput mask={DATE_INPUT_MASK} />}
              dateFormat={'dd/MM/yyyy'}
              onChange={(date: Date, e: SyntheticEvent) => {
                setRentEnd(date);
                setFieldValue('property.rent_insurance_contract_end_date', date);
                setFieldValue('property.rental_period_end_date', date);
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <p>
              {' '}
              Atenção: Informe as datas de início e fim da vigência, conforme o contrato
              de locação. Lembramos que a vigência mínima é de 12 meses.
            </p>
          </Grid.Column>
        </Grid.Row>
        <SectionLabel text="Valores a serem contratados" />
        <Grid.Row>
          <Grid.Column width={4}>
            <FieldLabel>Parcelas a serem contratadas</FieldLabel>
            <Field
              name={`hiredInstallments`}
              value={values.hiredInstallments}
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Valor da parcela</FieldLabel>
            <Field
              name={`bondInsurance.analysisValue`}
              value={maskMoney(
                String(nominal_total_premium_amount / maxInstallments),
                true,
                2
              )}
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Prêmio total a ser contratado</FieldLabel>
            <Field
              name={`bondInsurance.analysisValue`}
              value={maskMoney(
                String(
                  (nominal_total_premium_amount / maxInstallments) *
                    values.hiredInstallments
                ),
                true,
                2
              )}
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>
        </Grid.Row>
        <SectionLabel text="PLANO DE COBERTURA" />
        <Grid.Column width={8}>
          <FieldLabel paddingTop={true}>
            GARANTIA DA ANÁLISE*
            <Popup
              trigger={<Icon name="help circle" style={{ color: '#364445' }} />}
              inverted
            >
              <Popup.Content>
                <List bulleted>
                  <List.Item>
                    <b>Plano Seguro Completo (Garantido)</b>
                    <List.List>
                      <List.Item>
                        Cobertura: Aluguel; Encargos declarados na análise; Danos ao
                        imóvel; Multa por rescisão contratual e Pintura.
                      </List.Item>
                    </List.List>
                  </List.Item>
                  <List.Item>
                    <b>Plano Seguro Simples (Básico)</b>
                    <List.List>
                      <List.Item>
                        Cobertura: Aluguel; Encargos declarados pela imobiliária.
                      </List.Item>
                      <List.Item>
                        Não possui cobertura: Danos ao imóvel; Pintura e Multa por
                        rescisão contratual.
                      </List.Item>
                    </List.List>
                  </List.Item>
                </List>
              </Popup.Content>
            </Popup>
          </FieldLabel>
          <Field
            name={`bondinsurance.analysis_assurance`}
            value={values.analysis_assurance}
            defaultValue={null}
            completeValue={true}
            placeholder={'Selecionar...'}
            disabled={true}
            options={analysisOptions}
            component={Dropdown}
            fluid
          />
        </Grid.Column>
        {/*
        <SectionLabel text="" />
        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>VALOR DO ALUGUEL</FieldLabel>
            <Field
              name={`bondInsurance.property.rent_value`}
              value={values.property.formatted_rent_value}
              component={Input}
              fluid
              onChange={(_: any, data: InputOnChangeData) => {
                const rent = Number(data.value.replace(/\D/g, "") || 0) / 100;
                if (rent > maxRent) {
                  warningNotification(
                    "Ops!",
                    `Este valor não pode ser alterado para mais de ${maskMoney(
                      String(maxRent),
                      true,
                      2
                    )}`
                  );
                  setFieldValue("property.rent_value", maxRent);
                  return;
                }
                setFieldValue("property.rent_value", rent);
                setFieldValue(
                  "property.formatted_rent_value",
                  maskMoney(String(rent), true, 2)
                );
              }}
            />
          </Grid.Column>
        </Grid.Row> */}

        {/* {rentOnly ? (
                    <Grid>
                      <SectionLabel text="" />
                      <Grid.Row>
                        <Grid.Column width={4}>
                          <FieldLabel>VALOR DO ALUGUEL</FieldLabel>
                          <Field
                            disabled
                            name={`bondInsurance.property.rent_value`}
                            value={values.formattedValues.rent_value}
                            // onChange={(_: any, data: InputOnChangeData) => {
                            //   const rent =
                            //     Number(data.value.replace(/\D/g, "") || 0) /
                            //     100;

                            //   setFieldValue("property.rent_value", rent);
                            //   setFieldValue(
                            //     "formattedValues.rent_value",
                            //     maskMoney(String(rent), true, 2)
                            //   );
                            // }}
                            // onBlur={(e: React.FocusEvent<any>) => {
                            //   const rent =
                            //     Number(e.target.value.replace(/\D/g, "") || 0) /
                            //     100;

                            //   if (rent > maxRent) {
                            //     warningNotification(
                            //       "Ops. ",
                            //       "Para recalculo, o valor só poderá ser alterado para menor. "
                            //     );
                            //     setFieldValue("property.rent_value", originalRentValue);
                            //     setFieldValue(
                            //       "formattedValues.rent_value",
                            //       maskMoney(String(originalRentValue), true, 2)
                            //     );
                            //     return;
                            //   }
                            // }}
                            component={Input}
                            fluid
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  ) : ( */}
        <Grid>
          <SectionLabel text="" />
          <Grid.Row>
            <Grid.Column width={4}>
              <FieldLabel>VALOR DO ALUGUEL</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.rent_value`}
                value={values.formattedValues.rent_value}
                // onChange={(_: any, data: InputOnChangeData) => {
                //   const rent =
                //     Number(data.value.replace(/\D/g, "") || 0) /
                //     100;

                //   setFieldValue("property.rent_value", rent);
                //   setFieldValue(
                //     "formattedValues.rent_value",
                //     maskMoney(String(rent), true, 2)
                //   );
                // }}
                component={Input}
                fluid
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <FieldLabel>VALOR DO CONDOMÍNIO</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.condominium_fee`}
                value={values.formattedValues.condominium_fee}
                // onChange={(_: any, data: InputOnChangeData) => {
                //   const condominium_fee =
                //     Number(data.value.replace(/\D/g, "") || 0) /
                //     100;
                //   setFieldValue(
                //     "property.condominium_fee",
                //     condominium_fee
                //   );
                //   setFieldValue(
                //     "formattedValues.condominium_fee",
                //     maskMoney(String(condominium_fee), true, 2)
                //   );
                // }}
                component={Input}
                fluid
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <FieldLabel>VALOR DO IPTU</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.taxes_value`}
                value={values.formattedValues.taxes_value}
                component={Input}
                // onChange={(_: any, data: InputOnChangeData) => {
                //   const taxes_value =
                //     Number(data.value.replace(/\D/g, "") || 0) /
                //     100;
                //   setFieldValue(
                //     "property.taxes_value",
                //     taxes_value
                //   );
                //   setFieldValue(
                //     "formattedValues.taxes_value",
                //     maskMoney(String(taxes_value), true, 2)
                //   );
                // }}
                fluid
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={4}>
              <FieldLabel>VALOR DA ENERGIA</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.energy_bill`}
                value={values.formattedValues.energy_bill}
                // onChange={(_: any, data: InputOnChangeData) => {
                //   const energy_bill =
                //     Number(data.value.replace(/\D/g, "") || 0) /
                //     100;
                //   setFieldValue(
                //     "property.energy_bill",
                //     energy_bill
                //   );
                //   setFieldValue(
                //     "formattedValues.energy_bill",
                //     maskMoney(String(energy_bill), true, 2)
                //   );
                // }}
                component={Input}
                fluid
              />
            </Grid.Column>

            <Grid.Column width={4}>
              <FieldLabel>VALOR DA ÁGUA</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.water_bill`}
                value={values.formattedValues.water_bill}
                // onChange={(_: any, data: InputOnChangeData) => {
                //   const water_bill =
                //     Number(data.value.replace(/\D/g, "") || 0) /
                //     100;
                //   setFieldValue("property.water_bill", water_bill);
                //   setFieldValue(
                //     "formattedValues.water_bill",
                //     maskMoney(String(water_bill), true, 2)
                //   );
                // }}
                component={Input}
                fluid
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <FieldLabel>VALOR DO GÁS CANALIZADO</FieldLabel>
              <Field
                disabled
                name={`bondInsurance.property.gas_bill`}
                value={values.formattedValues.gas_bill}
                // onChange={(_: any, data: InputOnChangeData) => {
                //   const gas_bill =
                //     Number(data.value.replace(/\D/g, "") || 0) /
                //     100;
                //   setFieldValue("property.gas_bill", gas_bill);
                //   setFieldValue(
                //     "formattedValues.gas_bill",
                //     maskMoney(String(gas_bill), true, 2)
                //   );
                // }}
                component={Input}
                fluid
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {/* )} */}
        <SectionLabel text="Valores cotados" />
        <Grid.Row>
          <Grid.Column width={4}>
            <FieldLabel>Parcelas cotadas</FieldLabel>
            <Field
              name={`bondInsurance.property.validity`}
              value={maxInstallments}
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Valor da parcela</FieldLabel>
            <Field
              name={`bondInsurance.analysisValue`}
              value={maskMoney(
                String(nominal_total_premium_amount / maxInstallments),
                true,
                2
              )}
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Prêmio total cotado</FieldLabel>
            <Field
              name={`bondInsurance.analysisValue`}
              value={maskMoney(String(nominal_total_premium_amount), true, 2)}
              component={Input}
              fluid
              disabled
            />
          </Grid.Column>
        </Grid.Row>

        <SectionLabel text="Observações" />
        <Grid.Row>
          <Grid.Column width={16}>
            <Field
              name={`bondInsurance.observations`}
              rows={8}
              value={values.observations}
              onChange={(_: SyntheticEvent, data: any) => {
                setFieldValue(`observations`, data.value);
              }}
              component={FormatedTextArea}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
export default SurtyGeneralInsuranceForm;
