import React from 'react';
import { CreatePolicyProps } from '../../types';
import { Grid, Input, TextArea } from 'semantic-ui-react';
import { warningNotification } from '../../../../../../services/notification';
import { formatAmount } from '../../../../../../services/masks';
import CustomDatePicker from '../../../../../../components/CustomDatePicker';
import AppMaskedInput from '../../../../../../components/MaskedInput';
import { TextAreaWrapper } from '../../styles';

export const RenderPolicyForm = ({
  values,
  setFieldValue
}: {
  values: CreatePolicyProps;
  setFieldValue: (field: string, value: any) => void;
}) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={5}>
          <label>NÚMERO DE PARCELAS CONTRATADAS</label>
          <Input
            type="number"
            value={values?.hiredData?.installments}
            placeholder="Digite..."
            min={1}
            max={60}
            onChange={(_, data) => {
              if (data?.value && +data?.value > 60) {
                warningNotification('Atenção', 'O número máximo de parcelas é 60');
                return;
              }

              if (data?.value && +data?.value < 1) {
                warningNotification('Atenção', 'O número mínimo de parcelas é 1');
                return;
              }
              setFieldValue('hiredData.installments', data?.value);
            }}
            fluid
          />
        </Grid.Column>

        <Grid.Column width={6}>
          <label>VALOR DA PARCELA</label>
          <Input
            placeholder="R$ 0,00"
            fluid
            value={
              values?.hiredData?.installmentsAmount
                ? formatAmount(values?.hiredData?.installmentsAmount)
                : ''
            }
            onChange={e => {
              setFieldValue('hiredData.installmentsAmount', e.target.value);
            }}
          />
        </Grid.Column>

        <Grid.Column width={5}>
          <label>PRÊMIO TOTAL</label>
          <Input
            placeholder="R$ 0,00"
            fluid
            value={
              values?.hiredData?.totalPremiumAmount
                ? formatAmount(values?.hiredData?.totalPremiumAmount)
                : ''
            }
            onChange={e => {
              setFieldValue('hiredData.totalPremiumAmount', e.target.value);
            }}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={8}>
          <label>NÚMERO DA APÓLICE</label>
          <Input
            fluid
            value={values?.hiredData?.insurerPolicyId}
            onChange={e => {
              setFieldValue('hiredData.insurerPolicyId', e.target.value);
            }}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <label style={{ paddingBottom: '5px' }}>DATA DE EMISSÃO</label>
          <CustomDatePicker
            fluid
            name={'birth_date'}
            placeholder={'--/--/--'}
            type={'date'}
            customInput={
              <AppMaskedInput
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/
                ]}
              />
            }
            dateFormat={'dd/MM/yyyy'}
            style={{
              marginTop: 0
            }}
            selected={values?.hiredData?.insurerPolicyCreatedAt}
            onChange={(date: Date, _e: any) => {
              setFieldValue('hiredData.insurerPolicyCreatedAt', date);
            }}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          <label>OBSERVAÇÃO</label>
          <TextAreaWrapper>
            <TextArea
              value={values?.hiredData?.observations}
              onChange={(e, { value }) => {
                setFieldValue('hiredData.observations', value);
              }}
            />
          </TextAreaWrapper>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
