import { FranchiseListResponseDTO } from '../pages/franchises-list/types';
import { FranchiseBankAccountState, FranchiseState } from '../types/FranchiseState';
import { get, post, put } from './request';

export const getFranchisesList = async (
  filter?: any
): Promise<FranchiseListResponseDTO> => {
  let response;
  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  try {
    response = await get(`/franchises?${query}`);
  } catch (e) {
    throw e;
  }

  return response;
};

export const getAllFranchises = async (): Promise<FranchiseState[]> => {
  let response;
  try {
    response = await get(`/franchises/find-all`);
  } catch (e) {
    throw e;
  }

  return response;
};

export const getFranchiseById = async (id: string): Promise<FranchiseState> => {
  let response;
  try {
    response = await get(`/franchises/id/${id}`);
  } catch (e) {
    throw e;
  }

  return response;
};

export const getFranchiseByLoggedUser = async (): Promise<FranchiseState> => {
  let response;
  try {
    response = await get(`/franchises/by-user`);
  } catch (e) {
    throw e;
  }

  return response;
};

export const getBanks = async (): Promise<FranchiseBankAccountState[]> => {
  let response;
  try {
    response = await get(`/banks`);
  } catch (e) {
    throw e;
  }

  return response;
};

export const createFranchise = async (
  franchise: FranchiseState
): Promise<FranchiseState> => {
  let response;
  try {
    response = await post(`/franchises`, franchise);
  } catch (e) {
    throw e;
  }

  return response;
};

export const updateFranchiseById = async (
  id: string,
  franchise: FranchiseState
): Promise<FranchiseState> => {
  let response;
  try {
    response = await put(`/franchises/${id}`, franchise);
  } catch (e) {
    throw e;
  }

  return response;
};
