import * as Yup from 'yup';
import { addMethod } from "yup";

const REQUIRED_FIELD_MESSAGE = 'Campo Obrigatório!';
const GREATER_THAN_ZERO_MESSAGE = "Valor deve ser maior que zero!";

declare module "yup" {
  interface StringSchema {
    greaterThanZero(errorMessage: string): StringSchema;
    lessThanMaximum(errorMessage: string): StringSchema;
  }
}

addMethod(Yup.string, "greaterThanZero", function (errorMessage) {
  return this.test("test-greater-than-zero", errorMessage, function (value) {
    const { path, createError } = this;

    return value !== "0,00";
  });
});

export const createReportSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  cnpj: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  trading_name: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  estate_zipcode: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  estate_street: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  estate_number: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  estate_complement: Yup.string(),
  estate_district: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  estate_city: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  estate_state: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  financial_responsible: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  financial_email: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  contact_source: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  status: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  batchClosingDay: Yup.number().transform((cv, ov) => {
    return ov === '' ? undefined : cv;
  }).required(REQUIRED_FIELD_MESSAGE),
  broker: Yup.object().required(REQUIRED_FIELD_MESSAGE),
  fireInsurer: Yup.object().required(REQUIRED_FIELD_MESSAGE),
  email: Yup.string().required(REQUIRED_FIELD_MESSAGE).email('E-mail inválido'),
  profitShare: Yup.number().transform((cv, ov) => {
    return ov === '' ? undefined : cv;
  }).required(REQUIRED_FIELD_MESSAGE)
    .integer("O valor deve ser inteiro"),
  multiplier: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  bankAccount: Yup.object().shape({
    bankCode: Yup.string().required(REQUIRED_FIELD_MESSAGE),
    agencyNumber: Yup.string().required(REQUIRED_FIELD_MESSAGE),
    accountNumber: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  })
});
