import React from 'react';
import { Container } from './styles';
import { Icon } from 'semantic-ui-react';

const InfoBanner: React.FC = () => {
  return (
    <Container>
      <div className="icon">
        <Icon name="exclamation" />
      </div>
      <div>
        <p>
          <b>Atenção -</b> A seguradora avaliará a inclusão de encargos não declarados na apólice original.
        </p>
      </div>
    </Container>
  );
};

export default InfoBanner;
