import React, { useEffect, useState, SyntheticEvent } from "react";
import {
  Table,
  Dimmer,
  Segment,
  Loader,
  Dropdown,
  Icon,
  Button,
  Label,
  Item,
} from "semantic-ui-react";
import { PoliciesTableProps, FilterData } from "./types";
import { connect } from "react-redux";
import styled from "styled-components";
import IconButton from "../../components/IconButton";
import { FireInsurancePolicy } from "../../types/FireInsurance";
import { maskMoney } from "../../services/masks";
import { getPolicies } from "../../services/broker";
import { iRootState, iRootDispatch } from "../../store";
import { FireInsurancePoliciesListState } from "../../store/types/temp-types";
import { DateToStringDate } from "../../services/date";
import ConfirmationModal, {
  ConfirmationModalContent,
} from "../../components/ConfirmationModal";
import TableItemOption from "../../components/TableItemOption";
import {
  fireInsurancePolicyStatusLabel,
  sortPolicies,
  cancelPolicy,
  printPolicy,
} from "../../services/fire-insurance-policy";
import {
  successNotification,
  dangerNotification,
  warningNotification,
} from "../../services/notification";
import { CustoDropDownItemProps } from "../../components/types";
import { getUserFireInsurancePolicies } from "../../services/user";
import { UserRolesEnum } from "../../enums/user-roles.enum";

const PoliciesTableComponent = styled.div`
  margin-top: 1rem;
`;

const Header = styled(Table.Header)`
  background-color: #e1e8ed;
`;

const TableWrapper = styled.div`
  font-size: 0.85em;
  font-weight: bold;

  .ui.table {
    border: none;
    text-align: center;
    color: #364445;
  }
`;

const NotFoundWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #364445;
  font-size: 1.2em;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapState = (state: iRootState) => ({
  user: state.user,
  policiesData: state.fireInsurancePoliciesList,
  availableBrokersAndEstates: state.availableBrokersAndEstates,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatch = (dispatch: iRootDispatch) => ({
  updatePolicy: (policy: FireInsurancePolicy) =>
    dispatch.fireInsurancePoliciesList.updateFireInsurancePolicy(policy),
  updateList: (
    state: FireInsurancePoliciesListState
  ): FireInsurancePoliciesListState =>
    dispatch.fireInsurancePoliciesList.updateFireInsurancePoliciesList(state),
});

type Props = PoliciesTableProps &
  ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch>;

const PoliciesTable = (props: Props): JSX.Element => {
  const { onceRequested, isLoading, policies, ascending } = props.policiesData;
  const { availableBrokersAndEstates, user } = props;

  const [selectedItem, setSelectedItem] = useState<string>("");

  const brokers: CustoDropDownItemProps[] = availableBrokersAndEstates.brokers;
  const estates: CustoDropDownItemProps[] = availableBrokersAndEstates.estates;

  useEffect(() => {
    (async (): Promise<void> => {
      const filter = {} as FilterData;
      if (estates.length === 1) {
        filter.estate_id = estates[0].value.id;
      }
      if (brokers.length === 1) {
        filter.broker_id = brokers[0].value.id;
      }
      props.updateList({
        onceRequested: true,
        isLoading: true,
        policies: [],
        ascending,
      });
      const data =
        user.role === UserRolesEnum.brokerAnalyst || user.role === UserRolesEnum.accountManager
          ? await getUserFireInsurancePolicies(user.id, filter)
          : await getPolicies(filter.broker_id, filter.estate_id);
      const orderedData = sortPolicies(data, ascending);
      props.updateList({
        onceRequested: true,
        isLoading: false,
        policies: orderedData,
        ascending,
      });
    })();
  }, []);

  function toggleAscending(): void {
    const toggledAscending = !ascending;
    const orderedData = sortPolicies(policies, toggledAscending);
    props.updateList({
      onceRequested,
      isLoading,
      policies: orderedData,
      ascending: toggledAscending,
    });
  }

  function cancelPolicyConfirmationContent(
    policy: FireInsurancePolicy
  ): string | JSX.Element {
    const tenantName =
      policy.fire_insurance_proposal.fire_insurance_budget.tenant_name;
    const propertyCode =
      policy.fire_insurance_proposal.fire_insurance_budget.property_code;

    const tenantIdentifier = tenantName ? ` de ${tenantName}` : "";
    const baseMessage = `Você tem certeza que deseja cancelar a apólice${tenantIdentifier}?`;

    if (propertyCode) {
      const codeIdentifier = `Código externo ${propertyCode}`;
      return (
        <ConfirmationModalContent>
          <div id="baseMessage">{baseMessage}</div>
          <div id="codeIdentifier">{codeIdentifier}</div>
        </ConfirmationModalContent>
      );
    } else {
      return baseMessage;
    }
  }

  function printPolicyConfirmationContent(
    policy: FireInsurancePolicy
  ): string | JSX.Element {
    const tenantName = policy.fire_insurance_proposal.fire_insurance_budget
      ? policy.fire_insurance_proposal.fire_insurance_budget.tenant_name
      : "";
    const propertyCode = policy.fire_insurance_proposal.fire_insurance_budget
      ? policy.fire_insurance_proposal.fire_insurance_budget.property_code
      : "";

    const tenantIdentifier = tenantName ? ` de ${tenantName}` : "";
    const baseMessage = `Deseja imprimir a apólice{tenantIdentifier}?`;

    if (propertyCode) {
      const codeIdentifier = `Código externo ${propertyCode}`;
      return (
        <ConfirmationModalContent>
          <div id="baseMessage">{baseMessage}</div>
          <div id="codeIdentifier">{codeIdentifier}</div>
        </ConfirmationModalContent>
      );
    } else {
      return baseMessage;
    }
  }
  const renderPdf = (res: any) => {
    const linkSource = `data:application/pdf;base64,${res.base64}`;
    const downloadLink = document.createElement("a");
    const fileName = res.document + ".pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const trigger = (
    <>
      <IconButton color="blue" name="ellipsis vertical" />
    </>
  );

  function renderTableData(
    policies: Array<FireInsurancePolicy>
  ): Array<JSX.Element> {
    return policies.map((policy) => {
      const budget = policy.fire_insurance_proposal?.fire_insurance_budget;

      const insurerName = budget ? budget.insurer_name : "";
      const tenantName = budget ? budget.tenant_name : "";
      const propertyCode = budget ? budget.property_code : "";

      const cancelPolicyConfModalTrigger = (
        <Dropdown.Item text="Cancelar" icon="cancel" />
      );

      return (
        <Table.Row key={policy.id}>
          <Table.Cell>
            {DateToStringDate(policy.created_at || new Date(0, 0, 0))}
          </Table.Cell>
          <Table.Cell>
            {budget?.occupation?.property_type?.insurer?.name}
          </Table.Cell>
          <Table.Cell>
            {fireInsurancePolicyStatusLabel.get(policy.status) || ""}
          </Table.Cell>
          <Table.Cell>{tenantName}</Table.Cell>
          <Table.Cell>{propertyCode}</Table.Cell>
          <Table.Cell>{`R$ ${maskMoney(
            `${budget ? budget.cover_value : 0}`,
            true
          )}`}</Table.Cell>
          <Table.Cell>{budget ? budget.installments : 0}</Table.Cell>
          <Table.Cell>{policy.external_policy_id || "Indisponível"}</Table.Cell>
          <Table.Cell width={1} textAlign={"right"}>
            <Dropdown
              trigger={trigger}
              icon={null}
              direction="left"
              onOpen={() => setSelectedItem(policy.id || "")}
              onClose={() => setSelectedItem("")}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Imprimir Apólice"
                  icon="print"
                  onClick={(): Promise<void | FireInsurancePolicy> => {
                    warningNotification("Aviso", "Baixando Apólice");
                    return printPolicy(
                      policy.id,
                      user.id,
                      budget.code,
                      budget.occupation?.property_type.insurer.id
                    )
                      .then((res) => {
                        renderPdf(res);
                        successNotification("Sucesso", "Sucesso!");
                        setSelectedItem("");
                      })
                      .catch((e) => {
                        dangerNotification(
                          "Erro",
                          "Não foi possível imprimir a apólice"
                        );
                      });
                  }}
                ></Dropdown.Item>
                {/**
                <ConfirmationModal
                  negative
                  header="Cancelar Apólice"
                  content={cancelPolicyConfirmationContent(policy)}
                  trigger={cancelPolicyConfModalTrigger}
                  callback={(): Promise<void | FireInsurancePolicy> =>
                    cancelPolicy(policy)
                      .then((newPolicy) => {
                        successNotification(
                          "Sucesso",
                          "Apólice cancelada com sucesso!"
                        );
                        policy.status = newPolicy.status;
                        props.updatePolicy(policy);
                        setSelectedItem("");
                      })
                      .catch((e) => {
                        dangerNotification(
                          "Erro",
                          "Não foi possível cancelar a apólice"
                        );
                        console.error(e);
                      })
                  }
                />
                */}
              </Dropdown.Menu>
            </Dropdown>
            <OptionsWrapper></OptionsWrapper>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return (
    <PoliciesTableComponent>
      {onceRequested ? (
        <Dimmer.Dimmable as={Segment} dimmed={isLoading}>
          <Dimmer active={isLoading} inverted>
            <Loader />
          </Dimmer>
          {policies && policies.length > 0 ? (
            <TableWrapper>
              <Table>
                <Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <p>
                        CRIADO EM
                        <IconButton
                          name={
                            ascending
                              ? "long arrow alternate down"
                              : "long arrow alternate up"
                          }
                          color="blue"
                          onClick={toggleAscending}
                        />
                      </p>
                    </Table.HeaderCell>
                    <Table.HeaderCell>SEGURADORA</Table.HeaderCell>
                    <Table.HeaderCell>ESTADO</Table.HeaderCell>
                    <Table.HeaderCell>INQUILINO</Table.HeaderCell>
                    <Table.HeaderCell>COD. EXTERNO</Table.HeaderCell>
                    <Table.HeaderCell>PRÊMIO TOTAL (R$)</Table.HeaderCell>
                    <Table.HeaderCell>Nº PARCELAS</Table.HeaderCell>
                    <Table.HeaderCell>Nº APÓLICE</Table.HeaderCell>
                    <Table.HeaderCell width={1} textAlign={"right"}>
                      <IconButton color="blue" name="setting" />
                    </Table.HeaderCell>
                  </Table.Row>
                </Header>
                <Table.Body>{renderTableData(policies)}</Table.Body>
              </Table>
            </TableWrapper>
          ) : (
            <NotFoundWrapper>
              <p>Apólices não encontradas</p>
            </NotFoundWrapper>
          )}
        </Dimmer.Dimmable>
      ) : null}
    </PoliciesTableComponent>
  );
};

export default connect(mapState, mapDispatch)(PoliciesTable);
