import { CapitalizationStatusEnum } from './enums/capitalization-status.enum';

export function translateStatus(status: string): string {
  switch (status) {
    case 'CREATED':
      return 'Em aberto';
    case 'PENDING':
      return 'Pendente';
    case 'APPROVED':
      return 'Aprovado';
    case 'REJECTED':
      return 'Reprovado';
    case 'CANCELED':
      return 'Cancelado';
    case 'IN_ANALYSIS':
      return 'Em análise';
    case 'IN_PROGRESS':
      return 'Em andamento';
    case 'FINISHED':
      return 'Finalizado';
    case 'DEBT_CONFESSION':
      return 'Confissão de Dívida';
    case 'IN_ACCORD':
      return 'Em acordo';
    case 'MONTHLY_INDEMNITY':
      return 'Indenizado mensalmente';
    default:
      console.log(status);
      return 'Indefinido';
  }
}

export function translatePropertyStatus(status: string): string {
  switch (status) {
    case 'OCCUPIED':
      return 'Ocupado';
    case 'VACANT':
      return 'Desocupado';
    default:
      return 'Indefinido';
  }
}

export function translateValuesType(type: string): string {
  switch (type) {
    case 'CONDOMINIUM_FEE':
      return 'Taxa de Condomínio';
    case 'ENERGY_BILL':
      return 'Conta de Energia';
    case 'GAS_BILL':
      return 'Conta de Gás';
    case 'PENALTY':
      return 'Multa';
    case 'PAINTING_VALUE':
      return 'Pintura';
    case 'PROPERTY_DAMAGE_VALUE':
      return 'Valor de danos ao imóvel';
    case 'RENT_VALUE':
      return 'Valor do Aluguel';
    case 'TAXES_VALUE':
      return 'IPTU';
    case 'WATER_BILL':
      return 'Conta de Água';
    default:
      return 'Indefinido';
  }
}

export function translateStatusCapitalization(type: string): string {
  switch (type) {
    case CapitalizationStatusEnum.inIssue:
      return 'Em emissão';
    case CapitalizationStatusEnum.issued:
      return 'Título Emitido';
    case CapitalizationStatusEnum.canceled:
      return 'Cancelado';
    case CapitalizationStatusEnum.pending:
      return 'Com pendências';
    case CapitalizationStatusEnum.awaiting_payment:
      return 'Aguardando Pagamento';
    case CapitalizationStatusEnum.ransom_paid:
      return 'Resgate concluído';
    case CapitalizationStatusEnum.ransom_requested:
      return 'Resgate solicitado';
    default:
      return 'Indefinido';
  }
}

// export function translateBondInsuranceProposalStatus(status: string): string {
//   switch(status) {
//     case "WAITING":
//       return "Contratação em Andamento";
//     case "INSURER_HIRE_RELATED_INFORMATION_PENDING":
//       return "Contratação em Andamento";
//     case "HIRE_RELATED_INFORMATION_ERROR":
//       return "Erro na Contratação";
//     case "INSURER_READY_TO_HIRE":
//       return "Contratação em Andamento";
//     case "INSURER_HIRE_PENDING":
//       return "Contratação em Andamento";
//     case "HIRE_ERROR":
//       return "Erro na Contratação";
//     case "INSURER_POLICY_PENDING":
//       return "Contratação em Andamento";
//     case "POLICY_ERROR":
//       return "Erro na Contratação";
//     case "INSURER_POLICY_CREATED":
//       return "Emitida";
//     case "CANCELLED":
//       return "Cancelada";
//     default:
//       return "Indefinida";
//   }
// }
