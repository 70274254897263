import React, { useEffect, useState } from 'react';
import { Button, Dimmer, Dropdown, Grid, Icon, Input, Loader, Modal } from 'semantic-ui-react';
import { EditQuotationModalProps, FormProps } from './types';
import {
  Container,
  Line,
  FormContainer,
  FormTitle,
  Form,
  ButtonContainer,
  QuotationContainer,
  QuotationHeader,
  QuotationResultContainer,
  QuotationList,
  QuotationValue,
  CoveragesRow
} from './styles';
import {
  formatAmount,
  moneyStringToNumber,
  numberToMoneyString
} from '../../../../../../services/masks';
import {
  dangerNotification,
  successNotification,
  warningNotification
} from '../../../../../../services/notification';
import { iRootState } from '../../../../../../store';
import { connect } from 'react-redux';
import TenantForm from './forms/EditQuotationModalTenantForm';
import OwnerForm from './forms/EditQuotationModalOwnerForm';
import PropertyForm from './forms/EditQuotationModalPropertyForm';
import { Coverage } from '../../../../types/fireInsuranceAnalysisType';
import { SegimobQuotedProps } from '../../../../types/segimob';
import { post } from '../../../../../../services/request';

const mapState = (state: iRootState) => ({
  user: state.user
});

const SegimobEditQuotation: React.FC<
  EditQuotationModalProps & ReturnType<typeof mapState>
> = ({
  close,
  handleSubmit,
  fireInsuranceAnalysis,
  user
}: EditQuotationModalProps & ReturnType<typeof mapState>) => {

  const sortCoverages = (coverages: Coverage[]) => {
    const index = coverages.findIndex(item => Number(item.referenceCode) === 37);
    if (index > 0) {
        const [item37] = coverages.splice(index, 1);
        coverages.unshift(item37);
    }
    return coverages;
};
  const coverages = sortCoverages(fireInsuranceAnalysis.coverages);
  const [installments, setInstallments] = useState(fireInsuranceAnalysis?.installments);
  const [value, setValue] = useState(fireInsuranceAnalysis?.value);
  const [totalValue, setTotalValue] = useState(
    fireInsuranceAnalysis?.grossPremium ||
      +(fireInsuranceAnalysis.installments || 0) * (fireInsuranceAnalysis.value || 0)
  );

  const [form, setForm] = useState<FormProps>({
    tenantIdentification: {
      name: '',
      document: '',
      birthdate: undefined,
      email: '',
      phoneNumber: ''
    },
    ownerIdentification: {
      name: '',
      document: '',
      email: ''
    },
    propertyAddress: {
      zipCode: '',
      street: '',
      city: '',
      state: '',
      neighborhood: '',
      number: '',
      complement: '',
      noComplement: false
    },
    quotation: {
      rentValue: 0,
      vigency: '',
      startDate: new Date()
    },
    coverages: []
  });
  const [validateForm, setValidateForm] = useState<boolean>(false);
  const [isLoadingCEP, setIsLoadingCEP] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editableCoverageCode, setEditableCoverageCode] = useState<number>(-2);
  const [editableCoverages, setEditableCoverages] = useState<Coverage[]>(
    coverages.map(coverage => {
      return { ...coverage, maskedValue: formatAmount((+coverage.value).toFixed(2)) };
    })
  );

  const getFormIsValid = () => {
    if(form.propertyAddress.noComplement) {
      form.propertyAddress.complement = 'SEM COMPLEMENTO';
    }
    const tenantForm = Object.values(form.tenantIdentification);
    const ownerForm = Object.values(form.ownerIdentification);
    const propertyForm = Object.values(form.propertyAddress);
    let isValid = true;
    Object.values(tenantForm).map(item => {
      if (item === '') {
        isValid = false;
      }
    });
    Object.values(ownerForm).map(item => {
      if (item === '') {
        isValid = false;
      }
    });
    Object.values(propertyForm).map(item => {
      if (item === '') {
        isValid = false;
      }
    });

    if (
      fireInsuranceAnalysis.fireInsurance.occupation === '645' &&
      !form.propertyAddress.complement
    ) {
      warningNotification('Atenção', 'Para Apartamento, o Complemento é obrigatório!');
      form.propertyAddress.noComplement = false;
      isValid = false;
    }

    return isValid;
  };

  useEffect(() => {
    if (fireInsuranceAnalysis) {
      const { fireInsurance } = fireInsuranceAnalysis;
      console.log('VALORES INIS', fireInsurance);
      const quotation: FormProps = {
        ownerIdentification: {
          name: fireInsurance.insuredName,
          document: fireInsurance.insuredDocument,
          email: fireInsurance.insuredEmail
        },
        tenantIdentification: {
          name: fireInsurance.tenantName,
          document: fireInsurance.tenantDocument,
          birthdate: fireInsurance.tenantBirthdate ?? undefined,
          email: fireInsurance.tenantEmail,
          phoneNumber: fireInsurance.tenantCellphone
        },
        propertyAddress: {
          city: fireInsurance.propertyAddresses.city,
          complement: fireInsurance.propertyAddresses.complement,
          noComplement:
            fireInsurance.propertyAddresses.complement.length > 0 ? false : true,
          neighborhood: fireInsurance.propertyAddresses.district,
          number: fireInsurance.propertyAddresses.number,
          state: fireInsurance.propertyAddresses.state,
          street: fireInsurance.propertyAddresses.street,
          zipCode: fireInsurance.propertyAddresses.zipcode
        },
        quotation: {
          rentValue: fireInsurance.rentValue,
          vigency: fireInsurance.vigency,
          startDate: fireInsurance.startDate
        },
        coverages: editableCoverages
      };
      setForm(quotation);
    }
  }, [fireInsuranceAnalysis, editableCoverages]);

  const monthsOptions = () => {
    return Array.from(Array(12).keys()).map(month => {
      return {
        key: month + 1,
        text: `${month + 1} ${month >= 1 ? 'Meses' : 'Mês'}`,
        value: month + 1
      };
    });
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      setValidateForm(true);
      if (getFormIsValid()) {
        let payload = form;
        if (form.propertyAddress.noComplement) {
          payload = {
            ...form,
            propertyAddress: {
              ...form.propertyAddress,
              complement: ''
            }
          };
        }
        await handleSubmit(payload);
      } else {
        warningNotification(
          'Campos obrigatórios',
          'Por favor, preencha todos os campos obrigatórios.'
        );
      }
    } catch (error) {
      console.log(error);
    }finally {
      setIsLoading(false);
    }
  };

  async function handleQuote(data: Coverage[]) {
    const payload = {
      estateId: fireInsuranceAnalysis.fireInsurance.estateId,
      startDate: fireInsuranceAnalysis.fireInsurance.startDate,
      assistanceId: fireInsuranceAnalysis.assistance,
      changedRentValue: false,
      vigency: fireInsuranceAnalysis.fireInsurance.vigency,
      insuranceTypeId: fireInsuranceAnalysis.fireInsurance.propertyType,
      occupationId: fireInsuranceAnalysis.fireInsurance.occupation,
      rentValue: fireInsuranceAnalysis.fireInsurance.rentValue,
      proLaborePercentageValue: fireInsuranceAnalysis.estateComissionPercentage,
      coverages: data.map(coverage => {
        return {
          id: coverage.referenceCode,
          valorImportanciaSegurada: coverage.value,
          valorPremioTotal: coverage.totalPremium,
          flagSelecionada: coverage.enabled,
        };
      })
    };
    setIsLoading(true);

    try {
      const response: SegimobQuotedProps = await post(
        '/fire-insurance-quotations/segimob/quote',
        payload
      );

      if (response.coverages && response.installments) {
        setInstallments(String(response.installments[response.installments.length - 1].numeroParcela));
        setValue(response.installments[response.installments.length - 1].valorParcela);
        setTotalValue(response.totalValue);

        const cvgs: Coverage[] = [];
        for (const coverage of editableCoverages) {
          const cvg = response.coverages.find(
            c => c.id === Number(coverage.referenceCode)
          );
          if (cvg) {
            cvgs.push({
              ...coverage,
              value: cvg.valorImportanciaSegurada,
              totalPremium: cvg.valorPremioTotal,
              enabled: cvg.flagSelecionada,
              maskedValue: numberToMoneyString(cvg.valorImportanciaSegurada)
            });
          }
        }

        setEditableCoverages(cvgs);
        successNotification('Sucesso', 'Cotação realizada com sucesso!');
      } else {
        dangerNotification('Erro', 'Não foi possível realizar a cotação!');
      }
    } catch (err) {
      console.log(err);
      dangerNotification('Erro', 'Não foi possível realizar a cotação!');
    } finally {
      setIsLoading(false);
    }
  }

  const renderQuotationForm = (): React.ReactNode => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>
              VALOR DO ALUGUEL
              <Input
                placeholder="Valor do Aluguel"
                fluid
                disabled
                value={numberToMoneyString(+form?.quotation?.rentValue)}
              />
            </label>
          </Grid.Column>
          <Grid.Column width={8}>
            <label>
              DURAÇÃO DA VIGÊNCIA
              <Dropdown
                fluid
                search
                clearable
                selection
                disabled
                placeholder={'Número de Meses'}
                options={monthsOptions()}
                value={form?.quotation?.vigency}
              />
            </label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {/* <Grid.Column width={4}>
            <label>
              INÍCIO
              <CustomDatePicker
                selected={form?.quotation?.startDate ?? undefined}
                minDate={new Date()}
                customInput={
                  <AppMaskedInput
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/
                    ]}
                  />
                }
                disabled
                dateFormat={'dd/MM/yyyy'}
                style={{
                  marginTop: 0
                }}
              />
            </label>
          </Grid.Column> */}
        </Grid.Row>
      </Grid>
    );
  };

  const renderQuotationData = (): React.ReactNode => {
    return (
      <QuotationContainer>
        <QuotationHeader>
          <h3>
            <Icon name="shield" /> Coberturas
          </h3>
        </QuotationHeader>
        <QuotationResultContainer>
          <QuotationList>
            {coverages && (
              <>
                <CoveragesRow>
                  <div>
                    <span className="insurance-name">
                      <Icon name="shield" className="low-opacity" /> Incêndio &nbsp;
                      <span className="required">(Obrigatório)</span>
                    </span>
                  </div>
                  <div>
                    {editableCoverageCode === -1 ? (
                      <div className="editable-coverages">
                        <Input
                          className="editable-coverages-input"
                          fluid
                          iconPosition={'left'}
                          value={numberToMoneyString(+editableCoverages[0].value)}
                          onChange={e => {
                            const numberValue = e.target.value;
                            setEditableCoverages(
                              editableCoverages.map((coverage, i) => {
                                if (i === 0) {
                                  return {
                                    ...coverage,
                                    maskedValue: formatAmount(moneyStringToNumber(formatAmount(numberValue)).toFixed(2)),
                                    value: moneyStringToNumber(formatAmount(numberValue))
                                  };
                                }
                                return coverage;
                              })
                            );
                          }}
                        />
                        <Icon
                          className="icon"
                          name="check"
                          color="green"
                          onClick={() => {
                            const numberValue = moneyStringToNumber(
                              editableCoverages[0].maskedValue
                            );
                            if (numberValue > editableCoverages[0].maxValue!) {
                              dangerNotification(
                                'Atenção',
                                `O valor máximo para essa cobertura é de R$ ${numberToMoneyString(
                                  editableCoverages[0].maxValue!
                                )}`
                              );
                              return;
                            }
                            if (numberValue < editableCoverages[0].minValue!) {
                              dangerNotification(
                                'Atenção',
                                `O valor mínimo para essa cobertura é de R$ ${numberToMoneyString(
                                  editableCoverages[0].minValue!
                                )}
                                )}`
                              );
                              return;
                            }
                            setEditableCoverageCode(-2);
                            handleQuote(editableCoverages);
                          }}
                        />
                        <Icon
                          className="icon"
                          name="close"
                          color="red"
                          onClick={() => {
                            setEditableCoverageCode(-2);
                            setEditableCoverages(
                              editableCoverages.map((coverage, i) => {
                                if (i === 0) {
                                  return {
                                    ...coverage,
                                    value: coverages[0].value,
                                    maskedValue: formatAmount(coverages[0].value)
                                  };
                                }
                                return coverage;
                              })
                            );
                          }}
                        />
                      </div>
                    ) : (
                      <span className="insurance-value">
                        {numberToMoneyString(+editableCoverages[0].value)}
                        <Icon
                          name="pencil"
                          color="black"
                          className="low-opacity pencil-icon"
                          onClick={() => setEditableCoverageCode(-1)}
                        />
                      </span>
                    )}
                  </div>
                </CoveragesRow>
                {editableCoverages.slice(1)?.map((coverage, index) => (
                  <CoveragesRow key={index} offwhite={index % 2 === 0}>
                    <div>
                      {coverage.coverageName && (
                        <span
                          className={
                            editableCoverageCode === index
                              ? 'slice-insurance-name'
                              : 'full-insurance-name'
                          }
                        >
                          <input
                            type="checkbox"
                            checked={coverage.enabled}
                            onClick={() => {
                              const cvgs = editableCoverages.map((coverage, i) => {
                                if (i === index + 1) {
                                  return {
                                    ...coverage,
                                    enabled: !coverage.enabled
                                  };
                                }
                                return coverage;
                              });
                              setEditableCoverageCode(-2);
                              setEditableCoverages(cvgs);
                              handleQuote(cvgs);
                            }}
                          />
                          {coverage.coverageName[0] +
                            coverage.coverageName?.toLowerCase().slice(1)}
                        </span>
                      )}
                    </div>
                    <div>
                      {editableCoverageCode !== index ? (
                        <>
                          <span className="insurance-value">
                            {numberToMoneyString(+coverage.value)}
                          </span>
                          {coverage.enabled && (
                            <Icon
                              name="pencil"
                              className="low-opacity  pencil-icon"
                              onClick={() => setEditableCoverageCode(index)}
                            />
                          )}
                        </>
                      ) : (
                        <div className="editable-coverages">
                          <Input
                            className="editable-coverages-input"
                            fluid
                            iconPosition={'left'}
                            value={numberToMoneyString(+coverage.value)}
                            onChange={e => {
                              const cvgs =
                              editableCoverages.map((coverage, i) => {
                                if (i === index + 1) {
                                  return {
                                    ...coverage,
                                    maskedValue: formatAmount(e.target.value),
                                    value: moneyStringToNumber(
                                      formatAmount(e.target.value)
                                    )
                                  };
                                }
                                return coverage;
                              })
                              setEditableCoverages(cvgs);
                            }}
                          />
                          <Icon
                            className="icon"
                            name="check"
                            color="green"
                            onClick={() => {
                              const numberValue = moneyStringToNumber(
                                coverage.maskedValue
                              );
                              if (numberValue > coverage.maxValue!) {
                                dangerNotification(
                                  'Atenção',
                                  `O valor máximo para essa cobertura é de R$ ${numberToMoneyString(
                                    coverage.maxValue!
                                  )}`
                                );
                                return;
                              }
                              if (numberValue < coverage.minValue!) {
                                dangerNotification(
                                  'Atenção',
                                  `O valor mínimo para essa cobertura é de R$ ${numberToMoneyString(
                                    coverage.minValue!
                                  )}`
                                );
                                return;
                              }
                              setEditableCoverageCode(-2);
                              handleQuote(editableCoverages);
                            }}
                          />
                          <Icon
                            className="icon"
                            name="close"
                            color="red"
                            onClick={() => {
                              setEditableCoverageCode(-2);
                              setEditableCoverages(
                                editableCoverages.map((coverage, i) => {
                                  if (i === index + 1) {
                                    return {
                                      ...coverage,
                                      value: coverages[index + 1].value,
                                      maskedValue: formatAmount(
                                        coverages[index + 1].value
                                      )
                                    };
                                  }
                                  return coverage;
                                })
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </CoveragesRow>
                ))}
              </>
            )}
          </QuotationList>
          <QuotationValue>
            <h3>Seu orçamento</h3>
            <label>
              <small>{installments}x</small> R${' '}
              {numberToMoneyString(value || 0)}
            </label>
            <small>
              Total: R${' '}
              {numberToMoneyString(
                totalValue)}
            </small>
          </QuotationValue>
        </QuotationResultContainer>
      </QuotationContainer>
    );
  };

  const renderActionButtons = (): React.ReactNode => {
    return (
      <ButtonContainer>
        <Button type="button" color="red" onClick={close}>
          Cancelar
        </Button>
        <Button type="submit" color="green">
          Confirmar Edição
        </Button>
      </ButtonContainer>
    );
  };

  return (
    <Container>
      <Dimmer active={isLoading} inverted>
        <Loader content="Atualizando cotação. " />
      </Dimmer>
      <Form onSubmit={handleFormSubmit}>
        <FormContainer>
          <FormTitle>IDENTIFICAÇÃO DO INQUILINO</FormTitle>
          <Line />
          <TenantForm form={form} setForm={setForm} validateForm={validateForm} />
        </FormContainer>
        <FormContainer>
          <FormTitle>IDENTIFICAÇÃO DO PROPRIETÁRIO</FormTitle>
          <Line />
          <OwnerForm form={form} setForm={setForm} validateForm={validateForm} />
        </FormContainer>
        <FormContainer>
          <FormTitle>ENDEREÇO DO IMÓVEL</FormTitle>
          <Line />
          <PropertyForm
            form={form}
            setForm={setForm}
            validateForm={validateForm}
            isLoadingCEP={isLoadingCEP}
            setIsLoadingCEP={setIsLoadingCEP}
            user={user}
          />
        </FormContainer>
        <FormContainer>
          <FormTitle>DADOS DA COTAÇÃO</FormTitle>
          <Line />
          {renderQuotationForm()}
        </FormContainer>
        <FormContainer>{renderQuotationData()}</FormContainer>
        <FormContainer>{renderActionButtons()}</FormContainer>
      </Form>
    </Container>
  );
};

export default connect(mapState)(SegimobEditQuotation);
