import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Info } from '../../../components/Info';
import { DateToStringDate } from '../../../services/date';
import { maskCpfOrCnpj, maskMoney, maskPhoneNumber } from '../../../services/masks';
import { FieldLabel } from '../../../styles';
import { BondInsuranceForGetBondInsurancesResponseCamelCaseDTO } from "../../../dtos/bond-insurance-list/get-bond-insurances-response.dto";
import { getPoliticallyExposedPersonLabel } from "../../../services/bond-insurance";

export type Props = {
  bondInsurance: BondInsuranceForGetBondInsurancesResponseCamelCaseDTO;
};

export const BondInsuranceNaturalTenantDetails = (props: Props) => {

  const { naturalTenant } = props.bondInsurance;
  // console.log(naturalTenant);

  return (

    <>
      <Grid.Row>
        <Info name={'NOME'} value={naturalTenant.main_tenant_name} width={4} />
        <Info name={'GÊNERO'} value={naturalTenant.main_tenant_gender} width={4} />
        <Info name={'DATA DE NASCIMENTO'} value={naturalTenant.main_tenant_birth_date ? DateToStringDate(naturalTenant.main_tenant_birth_date) : "Indisponível"} width={4} />
        <Info name={'CPF'} value={naturalTenant.main_tenant_cpf ? maskCpfOrCnpj(naturalTenant.main_tenant_cpf) : "Indisponível"} width={4} />
      </Grid.Row>
      <Grid.Row>
        {/* <Info name={'RG'} value={naturalTenant.main_tenant_rg ? naturalTenant.main_tenant_rg : 'Indisponível'} width={4} />
        <Info name={'DATA DE EMISSÃO DO RG'} value={naturalTenant.main_tenant_rg_emission_date ? DateToStringDate(naturalTenant.main_tenant_rg_emission_date) : 'Indisponível'} width={4} />
        <Info name={'ÓRGÃO EXPEDITOR DO RG'} value={naturalTenant.main_tenant_rg_expeditor_organ ? naturalTenant.main_tenant_rg_expeditor_organ : 'Indisponível'} width={4} /> */}
        <Info name={'NACIONALIDADE'} value={naturalTenant.main_tenant_nationality ? naturalTenant.main_tenant_nationality : 'Indisponível'} width={4} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'E-MAIL'} value={naturalTenant.main_tenant_email} width={4} />
        <Info name={'TELEFONE'} value={naturalTenant.main_tenant_phone ? maskPhoneNumber(naturalTenant.main_tenant_phone) : "Indisponível"} width={4} />
        <Info name={'NOME DA MÃE'} value={naturalTenant.main_tenant_other_incomes} width={4} />
        <Info name={'ESTADO CIVIL'} value={naturalTenant.main_tenant_marital_status} width={4} />
      </Grid.Row>
      {/* <Grid.Row>
        <Info name={'CEP DO ENDEREÇO ATUAL'} value={naturalTenant.main_tenant_current_address_zipcode
          ? naturalTenant.main_tenant_current_address_zipcode : 'Indisponível'} width={4} />
      </Grid.Row> */}
      <Grid.Row>
        <Info name={'PESSOA POLITICAMENTE EXPOSTA'} value={getPoliticallyExposedPersonLabel(naturalTenant.main_tenant_politically_exposed_person)} width={8} />
        {
          naturalTenant.main_tenant_politically_exposed_person && naturalTenant.main_tenant_politically_exposed_person === "CLOSETO" &&
          <>
            <Info name={'NOME DA PESSOA POLITICAMENTE EXPOSTA'} value={naturalTenant.main_tenant_close_to_politically_exposed_person?.politically_exposed_person_name} width={8} />
          </>
        }
      </Grid.Row>
      {
        naturalTenant.main_tenant_politically_exposed_person && naturalTenant.main_tenant_politically_exposed_person === "CLOSE_TO" &&
        <>
          <Grid.Row>
            <Info name={'CPF DA PESSOA POLITICAMENTE EXPOSTA'} value={naturalTenant.main_tenant_close_to_politically_exposed_person?.politically_exposed_person_cpf ?
              maskCpfOrCnpj(naturalTenant.main_tenant_close_to_politically_exposed_person?.politically_exposed_person_cpf) : ''} width={8} />
            <Info name={'GRAU DE PARENTESCO DA PESSOA POLITICAMENTE EXPOSTA'} value={naturalTenant.main_tenant_close_to_politically_exposed_person?.degree_of_kinship_to_politically_exposed_person} width={8} />
          </Grid.Row>
        </>
      }
      <Grid.Row>
        <Info name={'CÔNJUGE COMPÕE RENDA'} value={naturalTenant.partner_rent_composition ? 'Sim' : 'Não'} width={4} />
        {naturalTenant.partner_rent_composition && <Info name={'NOME (CÔNJUGE)'} value={naturalTenant.partner_name} width={4} />}
        {naturalTenant.partner_rent_composition && <Info name={'CPF (CÔNJUGE)'} value={naturalTenant.partner_cpf ? maskCpfOrCnpj(naturalTenant.partner_cpf) : "Indisponível"} width={4} />}
      </Grid.Row>
      <Grid.Row>
        <Info name={'VÍNCULO EMPREGATÍCIO'} value={naturalTenant.employment_relationship} width={4} />
        <Info name={'DATA DE ADMISSÃO'} value={naturalTenant.employment_admission_date ? DateToStringDate(naturalTenant.employment_admission_date) : ''} width={4} />
      </Grid.Row>
      <Grid.Row>
        {naturalTenant.main_tenant_profession && (<Info name={'PROFISSÃO'} value={naturalTenant.main_tenant_profession ? naturalTenant.main_tenant_profession : 'Indisponível'} width={4} />)}
        {naturalTenant.main_tenant_monthly_fixed_income && (<Info name={'RENDA MENSAL'} value={naturalTenant.main_tenant_monthly_fixed_income ?
          maskMoney(`${naturalTenant.main_tenant_monthly_fixed_income}`, true) : 'Indisponível'} width={4} />)}
        {naturalTenant.main_tenant_other_incomes && (<Info name={'OUTRAS RENDAS'} value={maskMoney(`${naturalTenant.main_tenant_other_incomes || 0}`, true)} width={4} />)}
      </Grid.Row>
      {!naturalTenant.resident && (
        <Grid.Row>
          <Info name={'RESIDIRÁ NO IMÓVEL?'} value={naturalTenant.resident ? 'Sim' : 'Não'} width={4} />
          <Info name={'NOME DO RESIDENTE'} value={naturalTenant.resident_name} width={4} />
          <Info name={'DATA DE NASC. DO RESIDENTE'} value={naturalTenant.resident_birth_date ? DateToStringDate(naturalTenant.resident_birth_date) : "Indisponível"} width={4} />
          <Info name={'CPF DO RESIDENTE'} value={naturalTenant.resident_cpf ? maskCpfOrCnpj(naturalTenant.resident_cpf) : "Indisponível"} width={4} />
        </Grid.Row>
      )}
      {naturalTenant.include_sympathise_tenants ? naturalTenant.sympathise_tenants.map((tenant, index
      ) => {
        return (
          <>
            <Grid.Row style={{ paddingLeft: '15px' }}>
              <FieldLabel style={{ color: '#364445', marginTop: '0px' }}>{`${index + 1}º LOCATÁRIO SOLIDÁRIO`}</FieldLabel>
            </Grid.Row>

            <Grid.Row>
              <Info name={'NOME'} value={tenant.name} width={4} />
              <Info name={'DATA DE NASCIMENTO'} value={tenant.birth_date ? DateToStringDate(new Date(tenant.birth_date)) : 'Indisponível'} width={4} />
              <Info name={'CPF'} value={tenant.cpf ? maskCpfOrCnpj(tenant.cpf) : "Indisponível"} width={4} />
              <Info name={'RG'} value={tenant.rg ? tenant.rg : 'Indisponível'} width={4} />
            </Grid.Row>

            <Grid.Row>
              <Info name={'VÍNCULO EMPREGATÍCIO'} value={tenant.employment_relationship} width={4} />
              <Info name={'PROFISSÃO'} value={tenant.profession ? tenant.profession : 'Indisponível'} width={4} />
              <Info name={'RENDA MENSAL'} value={tenant.monthly_fixed_income ?
                maskMoney(`${tenant.monthly_fixed_income}`, true) : 'Indisponível'} width={4} />
              <Info name={'OUTRAS RENDAS'} value={maskMoney(`${tenant.other_incomes || 0}`, true)} width={4} />
            </Grid.Row>

            <Grid.Row>
              <Info name={'PESSOA POLITICAMENTE EXPOSTA'} value={getPoliticallyExposedPersonLabel(tenant.politically_exposed_person)} width={8} />
              {
                tenant.politically_exposed_person && tenant.politically_exposed_person === "CLOSE_TO" &&
                <>
                  <Info name={'NOME DA PESSOA POLITICAMENTE EXPOSTA'} value={tenant.close_to_politically_exposed_person?.politically_exposed_person_name} width={8} />
                </>
              }
            </Grid.Row>
            {
              tenant.politically_exposed_person && tenant.politically_exposed_person === "CLOSE_TO" &&
              <>
                <Grid.Row>
                  <Info name={'CPF DA PESSOA POLITICAMENTE EXPOSTA'} value={tenant.close_to_politically_exposed_person?.politically_exposed_person_cpf ? maskCpfOrCnpj(tenant.close_to_politically_exposed_person?.politically_exposed_person_cpf) : ''} width={8} />
                  <Info name={'GRAU DE PARENTESCO DA PESSOA POLITICAMENTE EXPOSTA'} value={tenant.close_to_politically_exposed_person?.degree_of_kinship_to_politically_exposed_person} width={8} />
                </Grid.Row>
              </>
            }

            <Grid.Row>
              <Info name={'NOME'} value={tenant.name} width={4} />
              <Info name={'CPF'} value={tenant.cpf ? maskCpfOrCnpj(tenant.cpf) : "Indisponível"} width={4} />
              <Info name={'VÍNCULO'} value={tenant.employment_relationship} width={4} />
            </Grid.Row>
          </>
        )
      }) : null}

      {naturalTenant.partners && naturalTenant.partners.map((partner, index
      ) => {
        return (
          <>
            <Grid.Row style={{ paddingLeft: '15px' }}>
              <FieldLabel style={{ color: '#364445', marginTop: '0px' }}>
                {`${index + 1}º SÓCIO`}
              </FieldLabel>
            </Grid.Row>

            <Grid.Row>
              <Info name={'NOME'} value={partner.name} width={4} />
              <Info name={'CPF'} value={partner.document ? maskCpfOrCnpj(partner.document) : "Indisponível"} width={4} />
              <Info name={'RENDA'} value={partner.percent ? `${partner.percent} %` : 'NÃO INFORMADO'} width={4} />
              <Info name={'DATA DE ENTRADA'} value={partner.date_of_entry ? DateToStringDate(partner.date_of_entry) : "Indisponível"} width={4} />
            </Grid.Row>
          </>
        )
      })}
    </>
  )
}
