import { DropdownOptions } from '../pages/capitalization/components/create-capitalization-modal/Steps/CapitalizationInfoStep';

export const DaysDropdownOptions: DropdownOptions[] = [
  {
    index: 1,
    text: '01',
    value: '01'
  },
  {
    index: 2,
    text: '02',
    value: '02'
  },
  {
    index: 3,
    text: '03',
    value: '03'
  },
  {
    index: 4,
    text: '04',
    value: '04'
  },
  {
    index: 5,
    text: '05',
    value: '05'
  },
  {
    index: 6,
    text: '06',
    value: '06'
  },
  {
    index: 7,
    text: '07',
    value: '07'
  },
  {
    index: 8,
    text: '08',
    value: '08'
  },
  {
    index: 9,
    text: '09',
    value: '09'
  },
  {
    index: 10,
    text: '10',
    value: '10'
  },
  {
    index: 11,
    text: '11',
    value: '11'
  },
  {
    index: 12,
    text: '12',
    value: '12'
  },
  {
    index: 13,
    text: '13',
    value: '13'
  },
  {
    index: 14,
    text: '14',
    value: '14'
  },
  {
    index: 15,
    text: '15',
    value: '15'
  },
  {
    index: 16,
    text: '16',
    value: '16'
  },
  {
    index: 17,
    text: '17',
    value: '17'
  },
  {
    index: 18,
    text: '18',
    value: '18'
  },
  {
    index: 19,
    text: '19',
    value: '19'
  },
  {
    index: 20,
    text: '20',
    value: '20'
  },
  {
    index: 21,
    text: '21',
    value: '21'
  },
  {
    index: 22,
    text: '22',
    value: '22'
  },
  {
    index: 23,
    text: '23',
    value: '23'
  },
  {
    index: 24,
    text: '24',
    value: '24'
  },
  {
    index: 25,
    text: '25',
    value: '25'
  },
  {
    index: 26,
    text: '26',
    value: '26'
  },
  {
    index: 27,
    text: '27',
    value: '27'
  },
  {
    index: 28,
    text: '28',
    value: '28'
  },
  {
    index: 29,
    text: '29',
    value: '29'
  },
  {
    index: 30,
    text: '30',
    value: '30'
  },
  {
    index: 31,
    text: '31',
    value: '31'
  }
];
