import { DropdownItemProps } from "semantic-ui-react";
import { BrokerState } from "../../types/BrokerState";
import { EstateState } from "../../types/EstateState";
import { InsurerState } from "../../types/InsurerState";
import { OccupationState } from "../../types/OccupationState";
import { AssistanceState } from "../../types/AssistanceState";
import { FireInsuranceCoverageState } from "../../types/FireInsurance";
import { PropertyTypeState } from "../../types/PropertyTypeState";
import {FireInsuranceQuotationInstallmentData} from "../../dtos/fire-insurance-budget/fire-insurance-create-quotation-response-dto";

export const validityOptions: Array<DropdownItemProps> = [
  { value: 1, key: 1, text: "1 mês" },
  { value: 2, key: 2, text: "2 meses" },
  { value: 3, key: 3, text: "3 meses" },
  { value: 4, key: 4, text: "4 meses" },
  { value: 5, key: 5, text: "5 meses" },
  { value: 6, key: 6, text: "6 meses" },
  { value: 7, key: 7, text: "7 meses" },
  { value: 8, key: 8, text: "8 meses" },
  { value: 9, key: 9, text: "9 meses" },
  { value: 10, key: 10, text: "10 meses" },
  { value: 11, key: 11, text: "11 meses" },
  { value: 12, key: 12, text: "12 meses" },
  { value: 13, key: 13, text: "13 meses" },
  { value: 14, key: 14, text: "14 meses" },
  { value: 15, key: 15, text: "15 meses" },
  { value: 16, key: 16, text: "16 meses" },
  { value: 17, key: 17, text: "17 meses" },
  { value: 18, key: 18, text: "18 meses" },
  { value: 19, key: 19, text: "19 meses" },
  { value: 20, key: 20, text: "20 meses" },
  { value: 21, key: 21, text: "21 meses" },
  { value: 22, key: 22, text: "22 meses" },
  { value: 23, key: 23, text: "23 meses" },
  { value: 24, key: 24, text: "24 meses" },
];

export const segimobValidityOptions: Array<DropdownItemProps> = [
  { value: 1, key: 1, text: "1 mês" },
  { value: 2, key: 2, text: "2 meses" },
  { value: 3, key: 3, text: "3 meses" },
  { value: 4, key: 4, text: "4 meses" },
  { value: 5, key: 5, text: "5 meses" },
  { value: 6, key: 6, text: "6 meses" },
  { value: 7, key: 7, text: "7 meses" },
  { value: 8, key: 8, text: "8 meses" },
  { value: 9, key: 9, text: "9 meses" },
  { value: 10, key: 10, text: "10 meses" },
  { value: 11, key: 11, text: "11 meses" },
  { value: 12, key: 12, text: "12 meses" },
];

export const installmentsOptions: Array<DropdownItemProps> = [
  { value: 1, key: 1, text: "1 parcela" },
  { value: 2, key: 2, text: "2 parcelas" },
  { value: 3, key: 3, text: "3 parcelas" },
  { value: 4, key: 4, text: "4 parcelas" },
  { value: 5, key: 5, text: "5 parcelas" },
  { value: 6, key: 6, text: "6 parcelas" },
  { value: 7, key: 7, text: "7 parcelas" },
  { value: 8, key: 8, text: "8 parcelas" },
  { value: 9, key: 9, text: "9 parcelas" },
  { value: 10, key: 10, text: "10 parcelas" },
  { value: 11, key: 11, text: "11 parcelas" },
  { value: 12, key: 12, text: "12 parcelas" },
];

export type FireInsuranceBudgetFormData = {
  simulator: SimulatorProps;
  budget: BudgetProps;
};

export type SimulatorProps = {
  profitShare: number;
  brokerageFee: number;
  confiaxComission: number;
  installments: number;
  installmentsOptions: Array<DropdownItemProps>;
  value: number;
  iof?: number;
  coverValue: number;
  validity: number;
  startDate: Date;
  endDate: Date;
  covered: boolean;
  insurerGain: number;
};

export type BudgetProps = {
  insurance: Insurance;
  coverage: Array<CoverageProps>;
  property: Property;
  tenant: Tenant;
  owner: Owner;
  confiaxComission: number;
  quotationRequest: object;
};

export interface Property {
  idAddress?: number;
  idCity?: number;
  idState?: number;
  zipCode: string;
  street: string;
  district: string;
  city: string;
  state: string;
  number: string;
  complement: string;
  externalCode: string;
}

export interface Person {
  document: string;
  name: string;
  email: string;
  telephone: string;
  birthDate?: Date;
}

export type Tenant = Person

export type Owner = Person

export interface Insurance {
  broker: BrokerState;
  estate: EstateState;
  insurer: InsurerState;
  occupation: OccupationState;
  assistance: AssistanceState;
  estatePercent?: string;
  brokerPercent?: string;
  propertyType: PropertyTypeState;
  rentValue: string;
}

export type CoverageProps = FireInsuranceCoverageState

export const sectionLabels: Record<string, string> = {
  insurance: "Dados do Seguro",
  coverage: "Dados da cobertura",
  property: "Dados do Imóvel",
  tenant: "Dados do Inquilino",
  owner: "Dados do Proprietário",
};

export const sectionExceptions: Record<string, string[]> = {
  insurance: [],
  coverage: [],
  property: ["complement", "externalCode"],
  tenant: ["telephone", "email"],
  owner: ["telephone", "email"],
};
