/*

Rua
Avenida
Alameda
Estrada
Rodovia
Travessa
Outros
*/

export const PortoStreetTypeEnum = {
  // AREA: "A",
  // AEROPORTO: "AER",
  ALAMEDA: "AL",
  AVENIDA: "AV",
  // CHACARA: "CH",
  // CONJUNTO: "CJ",
  // COLONIA: "COL",
  // CONDOMINIO: "CON",
  // CAMPO: "CPO",
  // DISTRITO: "DT",
  // ESPLANADA: "ESP",
  ESTRADA: "EST",
  // ESTACAO: "ETC",
  // FAVELA: "FAV",
  // FAZENDA: "FAZ",
  // FEIRA: "FRA",
  // JARDIM: "JD",
  // LADEIRA: "LD",
  // LAGO: "LG",
  // LAGOA: "LGA",
  // LOTEAMENTO: "LOT",
  // LARGO: "LRG",
  // MORRO: "MRO",
  // NUCLEO: "NUC",
  OUTROS: "O",
  // PATIO: "PAT",
  // PRACA: "PC",
  // PARQUE: "PRQ",
  // PASSARELA: "PSA",
  // QUADRA: "Q",
  RUA: "R",
  // RECANTO: "REC",
  // RESIDENCIAL: "RES",
  RODOVIA: "ROD",
  // SITIO: "SIT",
  // SETOR: "ST",
  // TRECHO: "TR",
  // TREVO: "TRV",
  TRAVESSA: "TV",
  // VIA: "V",
  // VIADUTO: "VD",
  // VEREDA: "VER",
  // VILA: "VL",
  // VIELA: "VLA",
  // VALE: "VLE",
};

export const PortoStreetTypeOptions = () => {
  const keys = Object.keys(PortoStreetTypeEnum);
  return keys.map((c: string, i) => ({
    value: Object.values(PortoStreetTypeEnum)[i],
    key: Object.values(PortoStreetTypeEnum)[i],
    text: c,
  }));
};
