import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const CircleInitials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #2185d0;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
`

export const P = styled.p`
  font-size: 13px;
`

export const OldText = styled.span`
  font-size: 13px;
  color: #999;
  text-decoration: line-through;
  margin-left: 4px;
`

export const NewText = styled.span`
  font-size: 13px;
  color: #2185d0;
  margin-left: 4px;
`