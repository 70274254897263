import React, { useState, useEffect } from 'react';
import { Table, Dimmer, Segment, Loader, Menu, Icon, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { iRootDispatch, iRootState } from '../../../../store';
import IconButton from '../../../../components/IconButton';
import { ClickableTableCell, SelectableTableRow } from '../../../../styles';
import { maskCpfOrCnpj } from '../../../../services/masks';
import { Header, NotFoundWrapper, TableComponent, TableWrapper } from './styles';
import { FeatureEnum } from '../../../../enums/authz-feature.enum';
import { ActionEnum } from '../../../../enums/authz-action.enum';
import { useHistory } from 'react-router-dom';
import { EstatePreregisterState } from '../../../../types/EstatePreregisterState';
import { statusOptions } from '../../types';
import { PushRoute } from '../../../../util';
import { CurrentPageState } from '../../../../store/types/temp-types';
import { set } from '../../../../services/storage';
import AuthorizationContainer from '../../../../components/AuthorizationContainer';
import { DateToStringDate } from '../../../../services/date';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapState = (state: iRootState) => ({
  user: state.user,
  availableBrokersAndEstates: state.availableBrokersAndEstates
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatch = (dispatch: iRootDispatch) => ({
  updateCurrentPage: (state: CurrentPageState) =>
    dispatch.currentPage.updateCurrentPage(state)
});

type Props = {
  estates: EstatePreregisterState[] | [];
  isLoading: boolean;
  getData: Function;
  currentPage: number;
  setCurrentPage: Function;
  dataCount: number;
  handleSelectItem: (estate: EstatePreregisterState) => void;
} & ReturnType<typeof mapState>;

const EstatePreRegisterListPageTable = (props: Props): JSX.Element => {
  const {
    estates,
    isLoading,
    user,
    currentPage,
    setCurrentPage,
    dataCount,
    availableBrokersAndEstates,
    handleSelectItem
  } = props;

  const [listData, setListData] = useState<EstatePreregisterState[]>([]);
  const lastPage = Math.floor(dataCount / 10) + 1;
  const history = useHistory();

  useEffect(() => {
    setListData(estates);
  }, [estates]);

  function renderTableData(estates: Array<EstatePreregisterState>): Array<JSX.Element> {
    const trigger = (
      <AuthorizationContainer action={ActionEnum.read} feature={FeatureEnum.preregisters}>
        <IconButton color="blue" name="ellipsis vertical" />
      </AuthorizationContainer>
    );
    return estates.map((estate: EstatePreregisterState) => {
      return (
        <SelectableTableRow key={estate?.id}>
          <Table.Cell>
            {DateToStringDate(estate?.created_at || new Date(0, 0, 0))}
          </Table.Cell>

          <ClickableTableCell onClick={() => handleSelectItem(estate)}>
            {estate?.name}
          </ClickableTableCell>

          <ClickableTableCell onClick={() => handleSelectItem(estate)}>
            {maskCpfOrCnpj(estate?.document)}
          </ClickableTableCell>

          <ClickableTableCell onClick={() => handleSelectItem(estate)}>
            {estate?.street}, {estate?.city} ({estate?.state})
          </ClickableTableCell>

          <ClickableTableCell onClick={() => handleSelectItem(estate)}>
            {estate?.responsible_name}
          </ClickableTableCell>

          <ClickableTableCell onClick={() => handleSelectItem(estate)}>
            {estate?.email}
          </ClickableTableCell>

          <ClickableTableCell onClick={() => handleSelectItem(estate)}>
            {estate?.franchise?.name}
          </ClickableTableCell>

          <ClickableTableCell onClick={() => handleSelectItem(estate)}>
            {statusOptions.find(status => status.value === estate?.status)?.text}
          </ClickableTableCell>

          <ClickableTableCell width={1} textAlign={'right'}>
            <Dropdown trigger={trigger} icon={null} direction="left">
              <Dropdown.Menu>
                <AuthorizationContainer
                  action={ActionEnum.update}
                  feature={FeatureEnum.preregisters}
                >
                  <Dropdown.Item
                    icon="pencil"
                    text={'Editar Pré-cadastro'}
                    onClick={() =>
                      history.push(`/admin/estates-preregister/${estate?.id}`)
                    }
                  />
                </AuthorizationContainer>
                <AuthorizationContainer
                  action={ActionEnum.confirm}
                  feature={FeatureEnum.preregisters}
                >
                  <Dropdown.Item
                    icon="check"
                    text={'Confirmar Pré-cadastro'}
                    onClick={() => {
                      if (estate.id) {
                        set('preRegisterId', estate.id);
                        PushRoute(history, props, 'estate');
                      }
                    }}
                  />
                </AuthorizationContainer>
              </Dropdown.Menu>
            </Dropdown>
          </ClickableTableCell>
        </SelectableTableRow>
      );
    });
  }

  return (
    <TableComponent>
      <Dimmer.Dimmable as={Segment} dimmed={isLoading}>
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
        {listData && listData.length > 0 ? (
          <TableWrapper>
            <Table>
              <Header>
                <Table.Row>
                  <Table.HeaderCell>CRIADO EM</Table.HeaderCell>
                  <Table.HeaderCell>NOME / RAZÃO SOCIAL</Table.HeaderCell>
                  <Table.HeaderCell>CPF/CNPJ</Table.HeaderCell>
                  <Table.HeaderCell>ENDEREÇO</Table.HeaderCell>
                  <Table.HeaderCell>RESPONSÁVEL</Table.HeaderCell>
                  <Table.HeaderCell>E-MAIL DA UNIDADE</Table.HeaderCell>
                  <Table.HeaderCell>FRANQUIA</Table.HeaderCell>
                  <Table.HeaderCell>STATUS</Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign={'right'}>
                    <IconButton color="blue" name="setting" />
                  </Table.HeaderCell>
                </Table.Row>
              </Header>
              <Table.Body>{renderTableData(estates)}</Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="17">
                    <Menu floated="right" pagination>
                      <Menu.Item>
                        <p>{`${currentPage * 10 - 9} - ${
                          dataCount < currentPage * 10 ? dataCount : currentPage * 10
                        }  de ${dataCount}`}</p>
                      </Menu.Item>
                      <Menu.Item
                        disabled={currentPage === 1}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(1)}
                      >
                        <Icon name="angle double left" />
                      </Menu.Item>
                      <Menu.Item
                        disabled={currentPage === 1}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item as="a">{currentPage}</Menu.Item>
                      <Menu.Item
                        disabled={currentPage === lastPage || estates.length < 10}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                      <Menu.Item as="a" icon>
                        <Icon
                          disabled={currentPage === lastPage || estates.length < 10}
                          name="angle double right"
                          onClick={() => setCurrentPage(lastPage)}
                        />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </TableWrapper>
        ) : (
          <NotFoundWrapper>
            <p>Imobiliárias não encontradas</p>
          </NotFoundWrapper>
        )}
      </Dimmer.Dimmable>
    </TableComponent>
  );
};

export default connect(mapState, mapDispatch)(EstatePreRegisterListPageTable);
