import React, { useEffect, useState } from "react";
import { Table, Dimmer, Segment, Loader, Dropdown } from "semantic-ui-react";
import { ProposalsTableProps, FilterData, ProposalsRowProps } from "./types";
import { connect } from "react-redux";
import styled from "styled-components";
import IconButton from "../../components/IconButton";
import { FireInsuranceProposal } from "../../types/FireInsurance";
import { maskMoney } from "../../services/masks";
import { getProposals } from "../../services/broker";
import { getProposalStatus } from "../../services/fire-insurance-proposal";
import { iRootState, iRootDispatch } from "../../store";
import { FireInsuranceProposalsListState } from "../../store/types/temp-types";
import { DateToStringDate, DateToStringDateTime } from "../../services/date";
import ConfirmationModal, {
  ConfirmationModalContent,
} from "../../components/ConfirmationModal";
import TableItemOption from "../../components/TableItemOption";
import {
  cancelProposal,
  printProposal,
  FireInsuranceProposalStatus,
  fireInsuranceProposalStatusLabel,
  sortProposals,
} from "../../services/fire-insurance-proposal";
import {
  successNotification,
  dangerNotification,
} from "../../services/notification";
import { CustoDropDownItemProps } from "../../components/types";
import { getUserFireInsuranceProposals } from "../../services/user";
import { UserRolesEnum } from "../../enums/user-roles.enum";
import { Code } from "../../types/InsurerState";

const ProposalsTableComponent = styled.div`
  margin-top: 1rem;
`;

const Header = styled(Table.Header)`
  background-color: #e1e8ed;
`;

const TableWrapper = styled.div`
  font-size: 0.85em;
  font-weight: bold;

  .ui.table {
    border: none;
    text-align: center;
    color: #364445;
  }
`;

const NotFoundWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #364445;
  font-size: 1.2em;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const ProposalsRow = (props: ProposalsRowProps): JSX.Element => {
  const [cellStatus, setCellStatus] = useState<string>("");

  const getStatus = new Promise<string>(async (resolve, reject) => {
    try {
      if (cellStatus == "") {
        const status = (
          await getProposalStatus(
            props.user.id,
            props.proposal.id,
            Code.TokioMarine
          )
        ).status;
        if (fireInsuranceProposalStatusLabel.get(status) === undefined)
          throw Error("Failed get status");
        resolve(fireInsuranceProposalStatusLabel.get(status) as string);
      } else {
        resolve(cellStatus);
      }
    } catch (e) {
      reject("Failed get status");
    }
  });

  getStatus
    .then((status: string) => setCellStatus(status))
    .catch((e) => {
      setCellStatus("Indisponível");
    });

  return (
    <>
      {cellStatus == "" ? (
        <Segment basic>
          <Dimmer active inverted>
            <Loader size="mini">Carregando...</Loader>
          </Dimmer>
        </Segment>
      ) : (
        cellStatus
      )}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapState = (state: iRootState) => ({
  user: state.user,
  proposalsData: state.fireInsuranceProposalsList,
  availableBrokersAndEstates: state.availableBrokersAndEstates,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatch = (dispatch: iRootDispatch) => ({
  updateProposal: (
    proposal: FireInsuranceProposal
  ): FireInsuranceProposalsListState =>
    dispatch.fireInsuranceProposalsList.updateFireInsuranceProposal(proposal),
  updateList: (
    state: FireInsuranceProposalsListState
  ): FireInsuranceProposalsListState =>
    dispatch.fireInsuranceProposalsList.updateFireInsuranceProposalsList(state),
});

type Props = ProposalsTableProps &
  ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch>;

const ProposalsTable = (props: Props): JSX.Element => {
  const { availableBrokersAndEstates, user } = props;

  const {
    onceRequested,
    isLoading,
    proposals,
    ascending,
  } = props.proposalsData;

  const [selectedItem, setSelectedItem] = useState<string>("");

  const brokers: CustoDropDownItemProps[] = availableBrokersAndEstates.brokers;
  const estates: CustoDropDownItemProps[] = availableBrokersAndEstates.estates;

  useEffect(() => {
/*    (async (): Promise<void> => {
      const filter = {} as FilterData;
      if (estates.length === 1) {
        filter.estate_id = estates[0].value.id;
      }
      if (brokers.length === 1) {
        filter.broker_id = brokers[0].value.id;
      }

      props.updateList({
        onceRequested: true,
        isLoading: true,
        proposals: [],
        ascending,
      });
      const data =
        user.role === UserRolesEnum.brokerAnalyst
          ? await getUserFireInsuranceProposals(user.id, filter)
          : await getProposals(filter.broker_id, filter.estate_id);
      const orderedData = sortProposals(data, ascending);
      props.updateList({
        onceRequested: true,
        isLoading: false,
        proposals: orderedData,
        ascending,
      });
    })();*/
  }, []);

  function toggleAscending(): void {
    if (proposals) {
      const toggledAscending = !ascending;
      const orderedData = sortProposals(proposals, toggledAscending);
      props.updateList({
        onceRequested,
        isLoading,
        proposals: orderedData,
        ascending: toggledAscending,
      });
    }
  }

  function cancelProposalConfirmationContent(
    proposal: FireInsuranceProposal
  ): string | JSX.Element {
    const tenantName = proposal.fire_insurance_budget
      ? proposal.fire_insurance_budget.tenant_name
      : "";
    const propertyCode = proposal.fire_insurance_budget
      ? proposal.fire_insurance_budget.property_code
      : "";

    const tenantIdentifier = tenantName ? ` de ${tenantName}` : "";
    const baseMessage = `Você tem certeza que deseja cancelar a proposta${tenantIdentifier}?`;

    if (propertyCode) {
      const codeIdentifier = `Código externo ${propertyCode}`;
      return (
        <ConfirmationModalContent>
          <div id="baseMessage">{baseMessage}</div>
          <div id="codeIdentifier">{codeIdentifier}</div>
        </ConfirmationModalContent>
      );
    } else {
      return baseMessage;
    }
  }

  function printProposalConfirmationContent(
    proposal: FireInsuranceProposal
  ): string | JSX.Element {
    const tenantName = proposal.fire_insurance_budget
      ? proposal.fire_insurance_budget.tenant_name
      : "";
    const propertyCode = proposal.fire_insurance_budget
      ? proposal.fire_insurance_budget.property_code
      : "";

    const tenantIdentifier = tenantName ? ` de ${tenantName}` : "";
    const baseMessage = `Deseja imprimir a proposta${tenantIdentifier}?`;

    if (propertyCode) {
      const codeIdentifier = `Código externo ${propertyCode}`;
      return (
        <ConfirmationModalContent>
          <div id="baseMessage">{baseMessage}</div>
          <div id="codeIdentifier">{codeIdentifier}</div>
        </ConfirmationModalContent>
      );
    } else {
      return baseMessage;
    }
  }
  const renderPdf = (res: any) => {
    const linkSource = `data:application/pdf;base64,${res.base64}`;
    const downloadLink = document.createElement("a");
    const fileName = res.document + ".pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  function renderTableData(
    proposals: Array<FireInsuranceProposal>
  ): Array<JSX.Element> {
    return proposals.map((proposal) => {
      const budget = proposal.fire_insurance_budget;

      const tenantName = budget ? budget.tenant_name : "";
      const propertyCode = budget ? budget.property_code : "";

      const cancelProposalConfModalTrigger = (
        <Dropdown.Item
          text="Cancelar"
          disabled={
            selectedItem !== proposal.id ||
            proposal.status === FireInsuranceProposalStatus.CANCELED
          }
          icon="cancel"
        />
      );

      const trigger = (
        <>
          <IconButton color="blue" name="ellipsis vertical" />
        </>
      );

      return (
        <>
          <Table.Row key={proposal.id}>
            <Table.Cell>
              {DateToStringDateTime(proposal.created_at || new Date(0, 0, 0))}
            </Table.Cell>
            <Table.Cell>
              {
                proposal.fire_insurance_budget?.occupation?.property_type
                  .insurer.name
              }
            </Table.Cell>
            <Table.Cell>
              {proposal.fire_insurance_budget?.estate?.name
                ? proposal.fire_insurance_budget?.estate?.name
                : "Indisponível"}
            </Table.Cell>
            <Table.Cell>
              {fireInsuranceProposalStatusLabel.get(proposal.status) || ""}
            </Table.Cell>
            <Table.Cell>{tenantName}</Table.Cell>
            <Table.Cell>{propertyCode}</Table.Cell>
            <Table.Cell>{`R$ ${maskMoney(
              `${budget ? budget.cover_value : 0}`,
              true
            )}`}</Table.Cell>
            <Table.Cell>{budget ? budget.installments : 0}</Table.Cell>
            <Table.Cell>
              {proposal?.external_proposal_id || "Indisponível"}
            </Table.Cell>
            <Table.Cell width={1} textAlign={"right"}>
              <Dropdown
                trigger={trigger}
                icon={null}
                direction="left"
                onOpen={() => setSelectedItem(proposal.id)}
                onClose={() => setSelectedItem("")}
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    text="Imprimir Proposta"
                    icon="print"
                    disabled={
                      proposal.status ===
                        FireInsuranceProposalStatus.CANCELED ||
                      budget.code === Code.Fairfax
                    }
                    onClick={(): Promise<void | FireInsuranceProposal> =>
                      printProposal(
                        proposal.id,
                        user.id,
                        budget.code,
                        budget.occupation?.property_type.insurer.id
                      )
                        .then((res) => {
                          renderPdf(res);
                          successNotification("Sucesso", "Sucesso!");
                          setSelectedItem("");
                        })
                        .catch((e) => {
                          dangerNotification(
                            "Erro",
                            "Não foi possível imprimir a proposta"
                          );
                        })
                    }
                  ></Dropdown.Item>
                  {/**
                  <ConfirmationModal
                    negative
                    header="Cancelar Proposta"
                    content={cancelProposalConfirmationContent(proposal)}
                    trigger={cancelProposalConfModalTrigger}
                    callback={(): Promise<void | FireInsuranceProposal> =>
                      cancelProposal(proposal)
                        .then((_) => {
                          successNotification(
                            "Sucesso",
                            "Proposta cancelada com sucesso!"
                          );
                          proposal.status =
                            FireInsuranceProposalStatus.CANCELED;
                          props.updateProposal(proposal);
                          setSelectedItem("");
                        })
                        .catch((e) => {
                          dangerNotification(
                            "Erro",
                            "Não foi possível cancelar a proposta"
                          );
                        })
                    }
                  /> */}
                </Dropdown.Menu>
              </Dropdown>
            </Table.Cell>
          </Table.Row>
        </>
      );
    });
  }

  return (
    <ProposalsTableComponent>
      {onceRequested ? (
        <Dimmer.Dimmable as={Segment} dimmed={isLoading}>
          <Dimmer active={isLoading} inverted>
            <Loader />
          </Dimmer>
          {proposals && proposals.length > 0 ? (
            <TableWrapper>
              <Table>
                <Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <p>
                        CRIADO EM
                        <IconButton
                          name={
                            ascending
                              ? "long arrow alternate down"
                              : "long arrow alternate up"
                          }
                          color="blue"
                          onClick={toggleAscending}
                        />
                      </p>
                    </Table.HeaderCell>
                    <Table.HeaderCell>SEGURADORA</Table.HeaderCell>
                    <Table.HeaderCell>IMOBILIARIA</Table.HeaderCell>
                    <Table.HeaderCell>ESTADO</Table.HeaderCell>
                    {/* <Table.HeaderCell>TIPO SEGURO</Table.HeaderCell> */}
                    {/* <Table.HeaderCell>TIPO IMÓVEL</Table.HeaderCell> */}
                    <Table.HeaderCell>INQUILINO</Table.HeaderCell>
                    {/* <Table.HeaderCell>ENDEREÇO</Table.HeaderCell> */}
                    <Table.HeaderCell>COD. EXTERNO</Table.HeaderCell>
                    <Table.HeaderCell>PRÊMIO TOTAL (R$)</Table.HeaderCell>
                    <Table.HeaderCell>Nº PARCELAS</Table.HeaderCell>
                    <Table.HeaderCell>Nº PROPOSTA</Table.HeaderCell>
                    <Table.HeaderCell width={1} textAlign={"right"}>
                      <IconButton color="blue" name="setting" />
                    </Table.HeaderCell>
                  </Table.Row>
                </Header>
                <Table.Body>{renderTableData(proposals)}</Table.Body>
              </Table>
            </TableWrapper>
          ) : (
            <NotFoundWrapper>
              <p>Propostas não encontradas</p>
            </NotFoundWrapper>
          )}
        </Dimmer.Dimmable>
      ) : null}
    </ProposalsTableComponent>
  );
};

export default connect(mapState, mapDispatch)(ProposalsTable);
