import styled from 'styled-components';
import { Button, Grid } from 'semantic-ui-react';

export const StepIndex = styled.div`
  font-size: 20px;
  padding-left: 10px;
`;

export const StepName = styled.div`
  padding-left: 2px;
  font-size: 16px;
`;

export const Step = styled.div`
  color: ${(props: { selected: boolean }) => (props.selected ? 'white' : '#2185D0')};
  display: flex;
  flex-direction: row;
  background-color: ${(props: { selected: boolean }) =>
    props.selected ? '#2185D0' : 'white'};
  border: 1px solid
    ${(props: { selected: boolean }) => (props.selected ? 'white' : '#2185D0')};
  border-radius: 12px;
  width: 100%;
  height: 55px;
  line-height: 50px;
  text-align: center;
  margin: 4px;

  &:hover {
    background-color: #7ec3fc;
    color: white;
    border: 1px solid #7ec3fc;
  }
`;

export const Nav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 10px;
`;

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const RightAlignedButton = styled(Button)`
  float: right;
`;
export const AttachmentsWrapper = styled.div`
  border: 3px dashed #c5c5c5;
  width: 300px;
  padding: 20px;
  display: inline-block;
`;

export const AttachmentsText = styled.p`
  font-size: 20px;
  color: #c5c5c5;
  font-weight: bold;
`;

export const Disclaimer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  padding: 18px 24px;
  border-radius: 11px;
  border: 2px solid #2185d0;

  p {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0em;
  }

  time {
    color: #2185d0;
    font-weight: bold;
  }

  i {
    background: #2185d0;
    color: white;
    border-radius: 2px;
    box-shadow: 0px 0px 1px #2185d070;
  }
`;

export const FieldLabelContainer = styled.div`
  position: relative;

  .icon-button {
    position: absolute;
    top: 9px;
    right: 6px;
    i {
      color: #a3a3a3;

      &.not-required {
        color: #2185d0;
      }
    }
  }
`;

export const SympathiseTenantHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  padding: 4px 0;
`;

export const SympathiseTenantLabel = styled.label`
  font-size: 16px;
  color: #e4e4e4;
  font-weight: bold;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  gap: 16px;
  padding: 8px 12px;
`;
