import React from 'react';
import { Grid, Input } from 'semantic-ui-react';
import { maskCpf, maskPhoneNumber } from '../../../../../../../services/masks';
import { FormProps } from '../types';
import CustomDatePicker from '../../../../../../../components/CustomDatePicker';
import AppMaskedInput from '../../../../../../../components/MaskedInput';

interface TenantFormProps {
  form: FormProps;
  validateForm: boolean;
  setForm: React.Dispatch<React.SetStateAction<FormProps>>;
}

const SaveQuotationModalTenantForm: React.FC<TenantFormProps> = ({
  form,
  validateForm,
  setForm
}: TenantFormProps) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <label>
            NOME*
            <Input
              placeholder="Nome"
              fluid
              value={form?.tenantIdentification?.name}
              error={validateForm && form?.tenantIdentification?.name === ''}
              onChange={(_, data) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  tenantIdentification: {
                    ...prevValue.tenantIdentification,
                    name: data?.value
                  }
                }))
              }
            />
          </label>
        </Grid.Column>
        <Grid.Column width={4}>
          <label>
            CPF*
            <Input
              placeholder="CPF"
              fluid
              error={validateForm && form?.tenantIdentification?.document === ''}
              value={maskCpf(form?.tenantIdentification?.document)}
              onChange={(_, data) => {
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  tenantIdentification: {
                    ...prevValue.tenantIdentification,
                    document: data?.value
                  }
                }));
              }}
            />
          </label>
        </Grid.Column>
        <Grid.Column width={4}>
          <label>
            DATA DE NASCIMENTO*
            <CustomDatePicker
              placeholderText={'Data de Nascimento'}
              error={validateForm && !!form?.tenantIdentification?.birthdate}
              selected={form?.tenantIdentification?.birthdate ?  new Date(form?.tenantIdentification?.birthdate) : undefined}
              onChange={(date: Date) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  tenantIdentification: {
                    ...prevValue.tenantIdentification,
                    birthdate: date
                  }
                }))
              }
              maxDate={new Date()}
              customInput={
                <AppMaskedInput
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/
                  ]}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              style={{
                marginTop: 0
              }}
            />
          </label>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <label>
            EMAIL*
            <Input
              placeholder="Email"
              type="email"
              fluid
              error={validateForm && form?.tenantIdentification?.email === ''}
              value={form?.tenantIdentification?.email}
              onChange={(_, data) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  tenantIdentification: {
                    ...prevValue.tenantIdentification,
                    email: data?.value
                  }
                }))
              }
            />
          </label>
        </Grid.Column>
        <Grid.Column width={8}>
          <label>
            Telefone*
            <Input
              placeholder="Telefone"
              fluid
              error={validateForm && form?.tenantIdentification?.phoneNumber === ''}
              value={maskPhoneNumber(form?.tenantIdentification?.phoneNumber)}
              onChange={(_, data) =>
                setForm((prevValue: FormProps) => ({
                  ...prevValue,
                  tenantIdentification: {
                    ...prevValue.tenantIdentification,
                    phoneNumber: data?.value
                  }
                }))
              }
            />
          </label>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SaveQuotationModalTenantForm;
