import moment from 'moment-timezone';
import { CoverageEstate } from '../interfaces/alfa-coverages.interface';

const getChannel = (tokioOcupationCode: string, startVigency: Date, endVigency: Date) => {
  const isYearly = moment(endVigency).diff(startVigency, 'years');

  switch (tokioOcupationCode) {
    case '644':
    case '645':
      if (isYearly) {
        return {
          id: '20d323cc-1286-4a7d-bf9e-d595852fd2ee',
          modality: 'Residencial',
          code: '1001',
          name: 'Residencial - Anual'
        };
      } else {
        return {
          id: '76a212f2-93fe-426f-9b22-9f5d8e4cdc38',
          modality: 'Residencial',
          code: '1011',
          name: 'Residencial - Mensal'
        };
      }
    case '646':
    case '647':
    case '648':
    case '649':
    case '650':
    case '651':
      if (isYearly) {
        return {
          id: 'e7476a46-3807-446a-8eb4-00d43d567e5d',
          modality: 'Comercial',
          code: '1002',
          name: 'Empresarial - Anual'
        };
      } else {
        return {
          id: "66448d79-bf43-40bb-8d58-3d1284b52965",
          modality: "Comercial",
          code: "4004",
          name: "Empresarial - Mensal",
        };
      }
    default:
      return null;
  }
};

const getPropertyType = (tokioOcupationCode: string) => {
  /* CASE - Tokio / RETURN - Alfa */
  switch (tokioOcupationCode) {
    case '644': // CASA HABITUAL ALVENARIA
      return '1001'; // Casa
    case '645': // APARTAMENTO HABITUAL
      return '1002'; // Apartamento
    case '646': // SERVIÇOS
    case '647': // ESCRITÓRIOS
    case '648': // COMÉRCIOS
    case '649': // DEPÓSITOS
    case '650': // INDÚSTRIAS
    case '651': // DESOCUPADOS (não residencial)
      return '1099'; // Empresarial (-)
    default:
      return undefined;
  }
};

const getOccupationType = (tokioOcupationCode: string) => {
  /* CASE - Tokio / RETURN - Alfa */
  switch (tokioOcupationCode) {
    case '644': // CASA HABITUAL ALVENARIA
      return '4000'; // Casa Habitual
    case '645': // APARTAMENTO HABITUAL
      return '4070'; // Apartamento Habitual
    case '646': // SERVIÇOS
    case '647': // ESCRITÓRIOS
      return '32237'; // Serviços
    case '648': // COMÉRCIOS
    case '649': // DEPÓSITOS
    case '650': // INDÚSTRIAS
    case '651': // DESOCUPADOS (não residencial)
      return '32236'; // Comércio
    default:
      return undefined;
  }
};

const getCoverages = (coverages: CoverageEstate[], channelCode: string | undefined, rentValue: number) => {
  if (!channelCode) {
    return [];
  }

  return coverages.filter(c => c.coverage.channel.code === channelCode).map(c => {
    const multiplier = c.coverage.maxMultiplier
      ? c.coverage.maxMultiplier < c.multiplier
        ? c.coverage.maxMultiplier
        : c.multiplier
      : c.multiplier;
    let value = rentValue * multiplier;
    let onEditValue = value;

    if (value > c.maxValue) {
      value = c.maxValue;
      onEditValue = c.maxValue;
    } else if (value < c.minValue) {
      value = c.minValue;
      onEditValue = c.minValue;
    }

    return {
      id: c.id,
      code: c.coverage.code,
      checked: c.isRequired,
      value,
      name: c.coverage.name,
      onEdit: false,
      onEditValue,
      maxValue: c.maxValue,
      minValue: c.minValue,
      multiplier: c.multiplier,
      isDefault: c.coverage.isDefault,
      isRequired: c.isRequired,
      maxMultiplier: c.coverage.maxMultiplier
    }
  });
};

export const NormalizeTokioRenewalDataForAlfa = (policy: any, coverages: CoverageEstate[]) => {
  const {
    fireInsurance,
    policyPeriodStart,
    policyPeriodEnd,
    installments
  } = policy;

  const {
    occupation,
    rentValue,
    insuredName,
    insuredDocument,
    tenantName,
    tenantDocument,
    tenantEmail,
    tenantBirthdate,
    tenantCellphone,
    tenantGender,
    propertyAddresses
  } = fireInsurance;

  const channel = getChannel(occupation, policyPeriodStart, policyPeriodEnd);
  const propertyType = getPropertyType(occupation);
  const occupationType = getOccupationType(occupation);
  const tenantSexType = tenantGender ? tenantGender === 'M' ? '0' : '1' : undefined;
  const startVigency = new Date();
  const endVigency = channel?.name?.includes('Anual')
    ? moment(startVigency).add(1, 'year').toDate()
    : moment(startVigency).add(1, 'month').toDate();
  const cvgs = getCoverages(coverages, channel?.code, rentValue);

  return {
    startVigency,
    endVigency,
    coverageType: '4',
    coverages: cvgs,
    channel,
    propertyType,
    occupationType,
    propertyOwnerName: insuredName,
    propertyOwnerDocument: insuredDocument,
    tenantName,
    tenantEmail,
    tenantDocument,
    tenantBirthdate: tenantBirthdate ?? undefined,
    tenantCellphone,
    tenantGender,
    installments: installments?.toString(),
    rentValue: +rentValue,
    plans: undefined,
    tenantSexType,
    zipCode: propertyAddresses.zipcode,
    street: propertyAddresses.street,
    district: propertyAddresses.district,
    city: propertyAddresses.city,
    state: propertyAddresses.state,
    number: propertyAddresses.number,
    complement: propertyAddresses.complement,
  };
};
