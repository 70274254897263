import React from 'react';
import { Grid } from 'semantic-ui-react';
import {Info} from "../../../../components/Info";
import {maskCnpj, maskCpfOrCnpj, maskMoney} from "../../../../services/masks";
import {DateToStringDate} from "../../../../services/date";
import { FieldLabel } from '../../../../styles';
import { BondInsuranceResponseDTO } from '../../types/bondinsurance-interfaces';
import SectionLabel from '../../../../components/SectionLabel';

export type Props = {
  bondInsurance: BondInsuranceResponseDTO;
};

export const BondInsuranceLegalTenantDetails = (props: Props) => {

  const { legalTenant } = props.bondInsurance;
  return (
    <>
      <SectionLabel text='DADOS DO LOCATÁRIO' />
      <Grid.Row>
        <Info name={'EMPRESA'} value={legalTenant.company} width={4} />
        <Info name={'CNPJ'} value={maskCnpj(legalTenant.document)} width={4} />
        <Info name={'E-MAIL'} value={legalTenant.email} width={4} />
        <Info name={'TELEFONE'} value={legalTenant.phone} width={4} />
      </Grid.Row>

      <Grid.Row>
        <Info name={'DATA DE CONSTITUIÇÃO'} value={DateToStringDate(legalTenant.foundation_date)} width={4} />
        <Info name={'ÚLT. ALT. CONTRATUAL'} value={DateToStringDate(legalTenant.last_contractual_alteration_date)} width={4} />
        <Info name={'CAPITAL SOCIAL (R$)'} value={maskMoney(`${legalTenant.share_capital}`)} width={4} />
        <Info name={'CAPITAL INTERGRALIZADO (R$)'} value={maskMoney(`${legalTenant.paid_in_capital}`)} width={4} />
      </Grid.Row>

      <Grid.Row>
        <Info name={'FATURAMENTO ANUAL (R$)'} value={maskMoney(`${legalTenant.anual_revenue}`)} width={4} />
        <Info name={'NOME CONTATO'} value={legalTenant.contact_name} width={4} />
        <Info name={'TELEFONE CONTATO'} value={legalTenant.contact_phone} width={4} />
      </Grid.Row>

      <Grid.Row>
        <Info name={'LOGRADOURO'} value={legalTenant.street} width={4} />
        <Info name={'NÚMERO'} value={legalTenant.number} width={4} />
        <Info name={'COMPLEMENTO'} value={legalTenant.complement} width={4} />
        <Info name={'BAIRRO'} value={legalTenant.district} width={4} />
      </Grid.Row>

      <Grid.Row>
        <Info name={'CIDADE'} value={legalTenant.city} width={4} />
        <Info name={'ESTADO'} value={legalTenant.state} width={4} />
        <Info name={'CEP'} value={legalTenant.zip_code} width={4} />
      </Grid.Row>

      {legalTenant.partners?.map((partner, index) => {
        return (
          <>
            <Grid.Row style={{ paddingLeft: '15px' }}>
              <FieldLabel style={{ color: '#364445', marginTop: '0px' }}>
                {`${index + 1}º SÓCIO`}
              </FieldLabel>
            </Grid.Row>

            <Grid.Row>
              <Info name={'NOME'} value={partner.name} width={4} />
              <Info name={'CPF'} value={maskCpfOrCnpj(partner.document)} width={4} />
              <Info name={'RENDA'} value={`${partner.percent} %`} width={4} />
              <Info name={'DATA DE ENTRADA'} value={DateToStringDate(partner.date_of_entry)} width={4} />
            </Grid.Row>
          </>
        )
      })}
    </>
  )
}
