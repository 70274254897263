import React, { useEffect, useState } from 'react';
import { ConfigKeysEnum, getConfigKey } from '../../services/configKeys';
import { dangerNotification } from '../../services/notification';

const OperationalBIPage = () => {
  const [url, setUrl] = useState<string>('');
  useEffect(() => {
    async function getUrl() {
      const response = await getConfigKey(ConfigKeysEnum.OPERATIONAL_BI_LINK);
      if (response.success) {
        setUrl(response.value);
      } else {
        console.log(response);
        dangerNotification('Atenção!', 'Erro ao carregar link do Operational BI.');
      }
    }
    getUrl();
  }, [setUrl]);

  return (
    <div>
      {url && (
        <iframe
          title="Report Section"
          width="100%"
          height="800px"
          src={url}
          frameBorder={0}
          allowFullScreen={true}
        ></iframe>
      )}
    </div>
  );
};
export default OperationalBIPage;
