export const getType = (key: string): string => typeof sessionStorage.getItem(key);
export const exists = (key: string): boolean => null !== sessionStorage.getItem(key);
export const set = (key: string, value: string | object | number | boolean | undefined | any): void => sessionStorage.setItem(key, JSON.stringify(value));
export const clear = (): void => sessionStorage.clear();
export const remove = (key: string): void => sessionStorage.removeItem(key);

export const get = (key: string): any | null => {
  if (exists(key)) {
    return JSON.parse(sessionStorage.getItem(key) as any);
  }

  return null;
}