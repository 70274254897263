import React from 'react';
import { Modal, Button, Header, Icon } from 'semantic-ui-react';
import { AiFillCheckCircle } from "react-icons/ai";

interface ModalProps {
  message: string;
  success: boolean;
  trigger: React.ReactNode;
  handleClose: any;
  modalIsOpen: boolean;
}

export const AppModal = (props: ModalProps) => {

  const { trigger, handleClose, modalIsOpen } = props;

  return (
    <Modal
      trigger={trigger}
      open={modalIsOpen}
      onClose={handleClose}
    >
      <Header content={'Solicitação enviada com sucesso!'} />
      <Modal.Content style={{textAlign:'center'}}>
        <AiFillCheckCircle size={'100px'} color={'green'} />
        <p>Em breve nossa equipe entrará em contato</p>
      </Modal.Content>
      <Modal.Actions>
        <Button color={'blue'} onClick={handleClose}>
          <Icon name='checkmark' />Entendi
          </Button>
      </Modal.Actions>
    </Modal>
  );
}
