import React from 'react';
import { useEffect, useState } from 'react';
import { Button, Checkbox, Grid, Icon, Input, Modal } from 'semantic-ui-react';

import { get } from '../../../../../services/request';
import Toggle from '../../../../../components/Toggle';
import Loader from '../../../../../components/Loader';
import {
  CoverageRow,
  CoverageTitleRow,
  FireInsurersRow,
  InsurerCard,
  InsurerCardContent,
  InsurerCardContentTitle,
} from './styles';

export enum ModalitiesEnum {
  Residencial = 'Residencial',
  Comercial = 'Comercial'
};

interface ChannelInterface {
  id: string;
  name: string;
  code: string;
  modality: ModalitiesEnum;
  selected: boolean;
  active: boolean;
};

interface CoverageInterface {
  id: string;
  insurerId: string;
  channelId: string;
  name: string;
  code: string;
  maxValue: string;
  minValue: string;
  selectedMinValue: string;
  selectedMaxValue: string;
  multiplier: number;
  maxLmi: number;
  minLmi: number;
  selected: boolean;
  value: string;
  isDefault: boolean;
  isRequired: boolean;
};

interface ModalProps {
  estateId: string;
  open: boolean;
  onClose: () => void;
};

const AlfaCoveragesDetailsModal: React.FC<ModalProps> = ({ estateId, open, onClose }) => {
  const [channels, setChannels] = useState<ChannelInterface[]>([]);
  const [plans, setPlans] = useState<any[]>([]);
  const [coverages, setCoverages] = useState<CoverageInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    const fetchChannels = async () => {
      setIsLoading(true);

      const response = await get('/alfa-seguros/channels');

      if (response.length > 0) {
        const data = response.map((channel: ChannelInterface) => {
          return {
            ...channel,
            selected: true,
            active: false
          };
        });

        setChannels(data);
      }

      setIsLoading(false);
    };

    const fetchCoverages = async () => {
      setIsLoading(true);

      const response = await get(`/alfa-seguros/coverage-estate/${estateId}`);

      if (response.length > 0) {
        setCoverages(response);
      }

      setIsLoading(false);
    };

    const fetchPlans = async () => {
      setIsLoading(true);

      const response = await get(`/alfa-seguros/plan-estate/${estateId}`);

      if (response.length > 0) {
        setPlans(response);
      }

      setIsLoading(false);
    };

    openModal && fetchChannels();
    openModal && fetchPlans();
    openModal && fetchCoverages();
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{ width: '1300px' }}
    >
      <Modal.Header>
        <h3>
          <Icon name="shield" />
          Configurar coberturas
        </h3>
      </Modal.Header>

      <Modal.Content>
        <Loader active={isLoading} />
        <FireInsurersRow>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <h4 style={{ marginBottom: '10px' }}>CANAIS/MODALIDADES DISPONÍVEIS</h4>
            {channels?.filter(
              (channel: any) => plans?.find((g: any) => g.channelId === channel.id)
            )?.map(
              (channel: any) => {
                return (
                  <InsurerCard
                    key={channel.id}
                    isSelected={channel.active}
                  >
                    <InsurerCardContent>
                      <InsurerCardContentTitle>{channel.name}</InsurerCardContentTitle>
                    </InsurerCardContent>
                  </InsurerCard>
                );
              }
            )}
          </Grid.Column>

          {coverages && coverages.filter(cov => channels.find(c => c.active)?.id === cov.channelId).length > 0 && (
            <Grid.Column
              style={{ marginLeft: '40px' }}
              mobile={16}
              tablet={8}
              computer={8}
            >
              <CoverageTitleRow>
                <Grid.Column style={{ minWidth: '350px' }}>
                  <span>
                    <h4>TIPO</h4>
                  </span>
                </Grid.Column>

                <Grid.Column>
                  <span>
                    <h4 style={{ minWidth: '145px' }}>VALOR MÍNIMO (R$)</h4>
                  </span>
                </Grid.Column>

                <Grid.Column>
                  <span>
                    <h4 style={{ minWidth: '145px' }}>VALOR MÁXIMO (R$)</h4>
                  </span>
                </Grid.Column>

                <Grid.Column>
                  <span>
                    <h4 style={{ minWidth: '145px' }}>MULTIPLICADOR</h4>
                  </span>
                </Grid.Column>

                <Grid.Column>
                  <span>
                    <h4 style={{ minWidth: '145px' }}>OBRIGATÓRIO</h4>
                  </span>
                </Grid.Column>
              </CoverageTitleRow>

              {coverages && coverages.filter(cov => channels.find(c => c.active)?.id === cov.channelId).map(coverage => {
                // const isFireCoverage = coverage.name.includes('Incêndio');

                return (
                  <CoverageRow key={coverage.id} isSelected={coverage.selected}>
                    <InsurerCardContent style={{ minWidth: '350px' }}>
                      <Toggle
                        name={coverage.id}
                        id={`coverage-${coverage.id}`}
                        checked={coverage?.selected || false}
                        label={<label><InsurerCardContentTitle>{coverage.name}</InsurerCardContentTitle></label>}
                      />
                    </InsurerCardContent>

                    <Grid.Column>
                      <Input
                        style={{ minWidth: '145px' }}
                        name={`insurerSpecificData.alfa.fireInsurance.coverages.${coverage.id}.minValue`}
                        value={coverage?.selectedMinValue || ''}
                        fluid
                      />
                    </Grid.Column>

                    <Grid.Column style={{ verticalAlign: 'middle' }}>
                      <Input
                        style={{ minWidth: '145px', marginLeft: '5px' }}
                        name={`insurerSpecificData.alfa.fireInsurance.coverages.${coverage.id}.maxValue`}
                        value={coverage?.selectedMaxValue || ''}
                        fluid
                      />
                    </Grid.Column>

                    <Grid.Column>
                      <Input
                        style={{ minWidth: '145px', marginLeft: '5px' }}
                        name={`insurerSpecificData.alfa.fireInsurance.coverages.${coverage.id}.multiplier`}
                        value={coverage?.multiplier || ''}
                        type="number"
                        fluid
                      />
                    </Grid.Column>

                    <Grid.Column style={{ minWidth: '145px', marginLeft: '5px' }}>
                      <Checkbox
                        fluid
                        name={`insurerSpecificData.alfa.fireInsurance.coverages.${coverage.id}.isRequired`}
                        checked={coverage.isRequired}
                        disabled={true}
                      />
                    </Grid.Column>
                  </CoverageRow>
                );
              })}
            </Grid.Column>
          )}
        </FireInsurersRow>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={() => onClose()} primary>
          Fechar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AlfaCoveragesDetailsModal;
