import { FranchiseState } from '../../types/FranchiseState';

export interface FranchiseListResponseDTO {
  data: FranchiseState[];
  count: number;
  current: number;
  page: number;
}

export const typeOptions = [
  {
    index: 1,
    value: 'document',
    text: 'CPF ou CNPJ Parceiro'
  },
  {
    index: 2,
    value: 'name',
    text: 'Nome do Parceiro'
  },
  {
    index: 3,
    value: 'address',
    text: 'Endereço do Parceiro'
  }
];

export enum franchiseStatus {
  ACTIVE = 'Ativo',
  INACTIVE = 'Inativo',
  PENDING = 'Pendente'
}

export const statusOptions = [
  {
    value: 'ACTIVE',
    key: 0,
    text: 'Ativo'
  },
  {
    value: 'INACTIVE',
    key: 1,
    text: 'Inativo'
  },
  {
    value: 'PENDING',
    key: 1,
    text: 'Pendente'
  }
];

export interface FormErrorsMessages {
  name: string;
  company_name: string;
  document: string;
  status: string;
  responsible_name: string;
  responsible_email: string;
  responsible_phone: string;
  phone: string;
  email: string;
  account_manager_id: string;
  zipcode: string;
  street: string;
  number: string;
  complement?: string;
  district: string;
  city: string;
  state: string;
  user_id?: string;
  agencyDv: string;
  agencyNumber: string;
  accountDv: string;
  accountNumber: string;
  bankCode: string;
}

