import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Dropdown as DropdownNativo, Dropdown } from 'semantic-ui-react';
import Button from '../../../../components/Button';
import { iRootDispatch, iRootState } from '../../../../store';
import Input from '../../../../components/Input';
import { FieldLabel } from '../../../../styles';
import { AvailableBrokersAndEstatesState } from '../../../../store/types/temp-types';
import { FranchiseListPageProps } from '../..';
import { getUsers } from '../../../../services/broker';
import { UsersToCustomDropDownOptions } from '../../../../util';
import { ButtonsContainer } from '../../styles';
import { maskCpfOrCnpj } from '../../../../services/masks';
import FranchiseListPageTable from '../Table';
import { useQuery } from '@tanstack/react-query';
import { getFranchisesList } from '../../../../services/franchises';
import { FranchiseState } from '../../../../types/FranchiseState';
import { statusOptions, typeOptions } from '../../types';
import ViewModal from '../ViewModal';
import { dangerNotification } from '../../../../services/notification';

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

const w100 = {
  width: '100%'
};

interface FranchisesListFilter {
  document: string;
  name: string;
  address: string;
}

const FranchiseListPage = (props: FranchiseListPageProps) => {
  const { user } = props;
  const [filterContent, setFilterContent] = useState<FranchisesListFilter>({
    document: '',
    name: '',
    address: ''
  });
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [cleanInputs, setCleanInputs] = useState(false);
  const [selectedAccountManager, setSelectedAccountManager] = useState<any>();
  const [type, setType] = useState<string | null>('document');
  const [argument, setArgument] = useState<string>('');
  const [isSearchEnabled, setSearchEnabled] = useState(true);
  const [itemSelected, setItemSelected] = useState<FranchiseState | null>(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);

  const [total, setTotal] = useState(0);

  const getAccountManagers = async () => {
    if (!props.user.broker?.id) {
      throw new Error("Broker ID não disponível");
    }

    const result = await getUsers(props.user.broker.id, [{ isDropdown: true}]);
    return UsersToCustomDropDownOptions(false, result.map(user => ({
      id: user.id,
      name: user.name
    })));
  };

  const {
    data: accountManagerOptions,
    isFetching: isAccountManagerLoading,
  } = useQuery(
    ['getAccountManagers', !!props?.user?.broker?.id],
    getAccountManagers,
    {
      enabled: !!props?.user?.broker?.id,
      keepPreviousData: true
    }
  );

  const getContentByType = () => {
    if (type === 'document') {
      return filterContent.document;
    } else if (type === 'address') {
      return filterContent.address;
    }

    return filterContent.name;
  };

  const getData = async () => {
    try {
      const filters: any = {
        page: `${currentPage}`,
        type: type ?? null,
        argument: getContentByType(),
        accountManager: selectedAccountManager || null,
        status: selectedStatus
      };
      const franchises = await getFranchisesList(filters);
      if(currentPage === 1) setTotal(franchises.count);
      return franchises;
    } catch (e) {
      dangerNotification('Oops...', 'Não foi possível buscar os parceiros!');
      throw e;
    } finally {
      setCleanInputs(false);
    }
  };

  const { data, isFetching: isLoading, refetch } = useQuery(['getData', currentPage], getData, {
    enabled: true,
    keepPreviousData: true,
    refetchOnMount: 'always',
  });

  const clearInputs = () => {
    setFilterContent({
      document: '',
      name: '',
      address: ''
    });
    setSelectedAccountManager(null);
    setType(null);
    setArgument('');
    setSelectedStatus('');
    setCleanInputs(true);
  };

  useEffect(() => {
    if (cleanInputs) {
      clearInputs();
    }
  }, [cleanInputs, refetch]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case 'argument': {
        if (type === 'document') {
          const numericValue = value?.replace(/\D/g, '');
          const maskedValue = maskCpfOrCnpj(numericValue);
          setArgument(maskedValue);
          const isValidDocumentSearch =
            numericValue.length === 11 || numericValue.length === 14;
          setSearchEnabled(isValidDocumentSearch);
          break;
        }
        setSearchEnabled(true);
        setArgument(value);
        break;
      }
    }
  };

  useEffect(() => {
    if (type === 'document') {
      setFilterContent({ ...filterContent, document: argument });
    } else if (type === 'address') {
      setFilterContent({ ...filterContent, address: argument });
    } else if (type === 'name') {
      setFilterContent({ ...filterContent, name: argument });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [argument, type]);

  useEffect(() => {
    setFilterContent({
      name: '',
      document: '',
      address: ''
    });
  }, [type]);

  return (
    <>
      <Grid>
        <Grid.Row style={{ flexWrap: 'wrap' }}>
          <Grid.Column width={3}>
            <FieldLabel>Pesquisar por</FieldLabel>
            <div style={w100}>
              <Dropdown
                style={w100}
                value={type ?? ''}
                name="type"
                options={typeOptions}
                placeholder={'Selecionar...'}
                search
                selection
                onChange={(e, { value }) => setType(value as string)}
                clearable
              />
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <FieldLabel>Termo de pesquisa:</FieldLabel>
            <Input
              name="argument"
              onChange={handleInputChange}
              component={Input}
              value={argument}
              fluid
            />
          </Grid.Column>
          {!user.role.includes('ESTATE') && (
            <Grid.Column width={3}>
              <FieldLabel>Gestor de contas:</FieldLabel>
              <div style={w100}>
                <DropdownNativo
                  style={w100}
                  value={selectedAccountManager}
                  name={`accountManager`}
                  options={accountManagerOptions || []}
                  disabled={isAccountManagerLoading}
                  loading={isAccountManagerLoading}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  onChange={(e, { value }) => {
                    setSelectedAccountManager(value);
                  }}
                  clearable
                />
              </div>
            </Grid.Column>
          )}
          <Grid.Column width={3}>
            <FieldLabel>Status:</FieldLabel>
            <Dropdown
              style={{
                width: '100%'
              }}
              value={selectedStatus}
              name={`status`}
              options={statusOptions}
              placeholder={'Selecionar...'}
              search
              selection
              onChange={(_, { value }) => {
                setSelectedStatus(value as string);
              }}
              clearable
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <ButtonsContainer>
              <Button
                fluid
                type={'submit'}
                color="blue"
                disabled={!isSearchEnabled}
                text="Pesquisar"
                onClick={() => {
                  setCurrentPage(1);
                  refetch();
                }}
              />
              <Button basic fluid type={'button'} text="Limpar" onClick={clearInputs} />
            </ButtonsContainer>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <FranchiseListPageTable
        franchises={data?.data || []}
        isLoading={isLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        dataCount={total}
        handleSelectItem={(item: FranchiseState) => {
          setItemSelected(item);
          setIsViewModalOpen(true);
        }}
      />
      {itemSelected && (
        <ViewModal
          isOpen={isViewModalOpen}
          onClose={() => setIsViewModalOpen(false)}
          franchise={itemSelected}
        />
      )}
    </>
  );
};

export default connect(mapState, mapDispatch)(FranchiseListPage);
