import React, { useEffect, useState } from 'react';
import {
  Dimmer,
  Table,
  Loader,
  Menu,
  Icon,
  Grid,
  Button,
  Input,
  Dropdown,
} from 'semantic-ui-react';
import { iRootDispatch, iRootState } from '../../../store';
import { connect } from 'react-redux';
import { AvailableBrokersAndEstatesState, CurrentPageState } from '../../../store/types/temp-types';
import { maskCpfOrCnpj } from '../../../services/masks';
import styled from 'styled-components';
import { UserRolesEnum } from '../../../enums/user-roles.enum';
import { typeOptions, ListRecordsType } from "../types/options-find.type";
import { CustoDropDownItemProps } from '../../../components/types';
import { getRecords } from '../../../services/find-record';
import { DetailModal } from './RecordDetailsModal';
import { PushRoute } from '../../../util';
import { useHistory } from 'react-router-dom';
import { warningNotification } from '../../../services/notification';
import { FindRecordOriginEnum } from '../../../enums/find-record-origin.enum';

const ClickableTableCell = styled(Table.Cell)`
  cursor: pointer;
  position: relative;
`;

const Emphasis = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #2185D0;
  padding: 8px;
`;

const ClickableTableRow = styled(Table.Row)`
  :hover {
    background-color: #f0f0f0;
  }
`;

export const FieldLabel = styled.label<{ paddingTop?: boolean }>`
  font-weight: bold;
  padding-top: ${props => (props.paddingTop ? '14px' : '0px')};
  text-transform: uppercase;
  margin-left: 5px;
`;

export const FieldValue = styled.p`
  padding-left: 5px;
`;

const mapState = (state: iRootState) => {
  return {
    user: state.user,
    availableBrokersAndEstates: state.availableBrokersAndEstates
  };
};

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers),
    updateCurrentPage: (state: CurrentPageState) =>
    dispatch.currentPage.updateCurrentPage(state)
  });

const FindRecordTable = (props: any) => {
  const history = useHistory();
  const { availableBrokersAndEstates, user } = props;
  const estates: CustoDropDownItemProps[] = availableBrokersAndEstates.estates;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<ListRecordsType[]>([]);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedEstate, setSelectedEstate] = useState<any>();
  const [type, setType] = useState<any>('document');
  const [argument, setArgument] = useState<string>('');
  const lastPage = Math.floor(count / 10) + 1;
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [recordId, setRecordId] = useState<string>('');
  const [origin, setOrigin] = useState<string>('');
  const [isSearchEnabled, setSearchEnabled] = useState(true);

  const clearFilters = () => {
    if (
      user.role !== UserRolesEnum.estateAdmin &&
      user.role !== UserRolesEnum.estateUser
    ) {
      setSelectedEstate(null);
    }
    setType(null);
    setArgument('');
  };


  const redirectToRegister = (item: ListRecordsType) => {
    switch (item.origem) {
      case FindRecordOriginEnum.analysis: {
        PushRoute(history, props, 'bond-insurances', { bondInsuranceId: item.id })
        break;
      }
      case FindRecordOriginEnum.proposals: {
        PushRoute(history, props, 'bond-insurance-proposals', { bondInsuranceId: item.id })
        break;
      }

      case FindRecordOriginEnum.policies: {
        PushRoute(history, props, 'bond-insurance-policies', { bondInsuranceId: item.id })
        break;
      }

      case FindRecordOriginEnum.canceleds: {
        PushRoute(history, props, 'canceled-bond-insurances', { bondInsuranceId: item.id })
        break;
      }

      case FindRecordOriginEnum.expireds: {
        PushRoute(history, props, 'expired-bond-insurances', { bondInsuranceId: item.id })
        break;
      }

      case FindRecordOriginEnum.claims: {
        PushRoute(history, props, 'claim_estates', { claimId: item.id })
        break;
      }
    }
  }

  useEffect(() => {
    if (!!estates.length) {
      if (
        user.role === UserRolesEnum.estateAdmin ||
        user.role === UserRolesEnum.estateUser
      ) {
        setSelectedEstate(estates[0].value);
      }
    }
  }, [user, estates]);

  useEffect(() => {
    if( currentPage !== 1 ) {
      getData();
    }
  }, [currentPage]);

  const handleNewChangeEstate = (estateKey: any) => {
    const selected = estates.find(c => c.value.id === estateKey.id);
    if (!selected) {
      setSelectedEstate(0);
      return;
    }
    setSelectedEstate(estateKey);
  };

  const openDetailModal = (item: any) => {
    setRecordId(item.id);
    setOrigin(item.origem);
    setOpenDetail(true);
  };

  const handleInputChange = (e: { target: { value: any } }) => {
    const inputValue = e.target.value;

    if (type === 'document') {
      const numericValue = inputValue.replace(/\D/g, '');
      const maskedValue = maskCpfOrCnpj(numericValue);
      const isValidDocumentSearch = (numericValue.length === 11 || numericValue.length === 14);
      setSearchEnabled(isValidDocumentSearch);

      setArgument(maskedValue);
    } else {
      setSearchEnabled(true);
      setArgument(inputValue);
    }
  }

  const getData = async () => {
    let error = false;
    setIsLoading(true);
    if(!type){
      warningNotification(
        "Ops...",
        "O tipo da pequisa deve ser preenchido"
      );
      error = true;
    }
    if(!argument){
      warningNotification(
        "Ops...",
        "O termo da pequisa deve ser preenchido"
      );
      error = true;
    }
    if(error){
      setIsLoading(false);
      return;
    }

    const filters: any = {
      page: currentPage,
      estate_id:
        estates && estates.length > 0
          ? estates.length > 1
            ? selectedEstate
              ? selectedEstate.id
              : null
            : estates[0].value.id
          : null,
      type: type || null,
      argument: argument || null,
    };
    const records = await getRecords(filters);
    setData(records.data);
    setCount(records.count);
    setCurrentPage(records.page);
    setRecordsPerPage(records.current)
    setIsLoading(false);
  };

  return (
    <>
      {
        openDetail &&
        <DetailModal
          isOpen={openDetail}
          onOpen={() => setOpenDetail(true)}
          onClose={() => setOpenDetail(false)}
          header={origin}
          recordId={recordId}
          origin={origin}
          key={recordId}
        />
      }

      <Grid style={{ width: '105%', marginBottom: 20 }}>
        <Grid.Row verticalAlign={'middle'}>
          <Grid.Column width={3}>
            <FieldLabel>Pesquisar por</FieldLabel>
            <div style={{ width: '100%' }}>
              <Dropdown
                style={{ width: '100%' }}
                value={type}
                name={`type`}
                options={typeOptions}
                placeholder={'Selecionar...'}
                search
                selection
                onChange={(e, { value }) => setType(value)}
                clearable
              />
            </div>
          </Grid.Column>
          <Grid.Column width={6}>
            <FieldLabel>Termo de pesquisa</FieldLabel>
            <Input
              placeholder="Nome do inquilino"
              value={argument}
              onChange={handleInputChange}
              fluid
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <FieldLabel>Imobiliária</FieldLabel>
            <Dropdown
              style={{ width: '100%' }}
              value={selectedEstate}
              name={`estate`}
              defaultValue={
                availableBrokersAndEstates.estates.length === 1
                  ? availableBrokersAndEstates.estates[0].value
                  : '' || ''
              }
              disabled={availableBrokersAndEstates.estates.length === 1 || false}
              options={availableBrokersAndEstates.estates || []}
              placeholder={'Selecionar...'}
              search
              selection
              onChange={(e, { value }) => handleNewChangeEstate(value)}
              clearable
            />
          </Grid.Column>
          <Grid.Column
            width={3}
            verticalAlign={'bottom'}
            textAlign={'right'}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <Button
              primary
              fluid
              onClick={() => (currentPage === 1 ? getData() : setCurrentPage(1))}
              content={'Pesquisar'}
              disabled={!isSearchEnabled}
            />
             <Button fluid onClick={clearFilters} content={'Limpar'} />
          </Grid.Column>

        </Grid.Row>
      </Grid>

      {data.length > 0 ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', gap: 1 }}>
          <p><Emphasis>{count}</Emphasis>resultados para<Emphasis>{argument}</Emphasis></p>
        </div>) : (<></>)
      }
      <Table celled>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell textAlign={'center'}>Tipo de Registro</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>Imobiliária</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>Inquilino</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>CPF/CNPJ</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>Ações</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Dimmer.Dimmable as={Table.Body} dimmed={isLoading}>
          {isLoading && (
            <Dimmer active blurring>
              <Loader />
            </Dimmer>
          )}
          {data.length > 0 ? (
            <>
              {data.map((item: ListRecordsType) => {
                const {
                  id,
                  origem,
                  cnpj,
                  company_name,
                  cpf,
                  name,
                  estate
                } = item;

                const document = cpf ? cpf : (cnpj ? cnpj : '');

                return (
                  <>
                    <ClickableTableRow key={id!}>
                      <ClickableTableCell
                        onClick={() => openDetailModal(item)}
                        textAlign={'center'}
                      >
                        {origem}
                      </ClickableTableCell>
                      <ClickableTableCell
                        onClick={() => openDetailModal(item)}
                        textAlign={'center'}
                      >
                        {estate}
                      </ClickableTableCell>
                      <ClickableTableCell
                        onClick={() => openDetailModal(item)}
                        textAlign={'center'}
                      >
                        {name ? name : company_name}
                      </ClickableTableCell>
                      <ClickableTableCell
                        onClick={() => openDetailModal(item)}
                        textAlign={'center'}
                      >
                        {maskCpfOrCnpj(document)}
                      </ClickableTableCell>
                     <Table.Cell width={1}>
                        <Menu compact>
                          <Menu.Item>
                           <Button
                              color="teal"
                              icon="eye"
                              onClick={() => openDetailModal(item)}
                            />
                          </Menu.Item>
                          <Menu.Item>
                            <Button
                              primary
                              icon="arrow right"
                              onClick={() => redirectToRegister(item)}
                            />
                          </Menu.Item>
                        </Menu>
                      </Table.Cell>
                    </ClickableTableRow>
                  </>
                );
              })}
            </>
          ) : (
            <Table.Row>
              <Table.Cell colSpan={9} textAlign={'center'}>
                <span>Nenhum registro encontrado</span>
              </Table.Cell>
            </Table.Row>
          )}
        </Dimmer.Dimmable>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan={9} textAlign={'right'}>
              {!isLoading && (
                <Menu floated="right" pagination>
                  <Menu.Item
                    disabled={currentPage === 1}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(1)}
                  >
                    <Icon name="angle double left" />
                  </Menu.Item>
                  <Menu.Item
                    disabled={currentPage === 1}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item as="a">{currentPage}</Menu.Item>
                  <Menu.Item
                    disabled={currentPage === lastPage || data.length < recordsPerPage}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                  <Menu.Item as="a" icon>
                    <Icon
                      disabled={currentPage === lastPage || data.length < recordsPerPage}
                      name="angle double right"
                      onClick={() => setCurrentPage(lastPage)}
                    />
                  </Menu.Item>
                </Menu>
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
};
export default connect(mapState, mapDispatch)(FindRecordTable);
