import React from 'react';
import { PersonTypesEnum } from '../../../enums/person-types.enum';
import { PurposesEnum } from '../../../enums/purposes.enum';
import { Grid } from 'semantic-ui-react';
import { Info } from '../../../components/Info';
import { DateToStringDate } from '../../../services/date';
import { AnalysisAssuranceEnum } from '../../../enums/analysis-assurance.enum';
import { BondInsuranceForGetBondInsurancesResponseCamelCaseDTO } from "../../../dtos/bond-insurance-list/get-bond-insurances-response.dto";

export type Props = {
  bondInsurance: BondInsuranceForGetBondInsurancesResponseCamelCaseDTO;
};

export const BondInsuranceMainInfoDetails = (props: Props) => {

  const { bondInsurance } = props;
  const { property } = bondInsurance;

  return (
    <>
      <Grid.Row>
        <Info name={'ID'} value={bondInsurance.id} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'DATA DE EMISSÃO '} value={DateToStringDate(bondInsurance.createdAt)} width={8} />
        <Info name={'CRIADA POR'} value={bondInsurance.user?.name} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'CORRETORA'} value={bondInsurance.estate?.broker?.name ? bondInsurance.estate?.broker.name : ''} width={8} />
        <Info name={'IMOBILIÁRIA'} value={bondInsurance.estate?.name} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'FINALIDADE'} value={bondInsurance.purpose ? (bondInsurance.purpose === PurposesEnum.commercial ? 'Comercial' : 'Residencial') : 'Indisponível'} width={8} />
        <Info name={'TIPO DE LOCATÁRIO'} value={bondInsurance.tenantType ? (bondInsurance.tenantType === PersonTypesEnum.legal ? 'Pessoa Jurídica' : 'Pessoa Física') : "Indisponível"} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'MOTIVO DA LOCAÇÃO'} value={property.reason} width={8} />
        <Info name={'GESTOR DE CONTAS'} value={bondInsurance.estate?.user.name} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'VIGÊNCIA DO CONTRATO'} value={`${property.validity} MESES`} width={8} />
      </Grid.Row>
      {property.location_in_progress &&
        <Grid.Row>
          <Info name={'CONTRATO DE LOCAÇÃO VIGENTE'} value={`${property.location_in_progress ? 'SIM' : 'NÃO'}`} width={8} />
        </Grid.Row>
      }
    </>
  )
}
