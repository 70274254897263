import React, { useState, useEffect } from 'react';
import {
  Table,
  Dimmer,
  Segment,
  Loader,
  Menu,
  Icon,
  Dropdown,
  Popup,
  Button,
  Label,
  Confirm
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { DateToStringDateTime } from '../../../services/date';
import { maskMoney, maskCpfOrCnpj, moneyStringToNumber } from '../../../services/masks';
import { iRootDispatch, iRootState } from '../../../store';
import IconButton from '../../../components/IconButton';
import {
  dangerNotification,
  successNotification,
  warningNotification
} from '../../../services/notification';
import {
  ComboTooFireInsurancePoliciesListResponseDTO,
  PolicyForGetBondInsurancePoliciesListResponseDTO
} from '../../../dtos/bond-insurance-policy-list/interfaces/policy-for-get-bond-insurance-policies-list-response-dto';
import {
  getBondInsurancePolicy,
  getBondInsurancePolicyStatusLabel,
  printPottencialBondInsuracePolicy,
  sortBondInsurancePolicies,
  renewBondInsurance,
  nonRenewBondInsurance,
  getBondInsuranceHiredData,
  policyFollowUpType,
  cancelUnderRenewal,
  getPolicyHasClaim
} from '../../../services/bond-insurance-policy';
import {
  BondInsuranceAnalysisForGetCompleteBondInsuranceProposalByIdResponseDTO,
  BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO
} from '../../../dtos/bond-insurance-proposal-list/get-complete-bond-insurance-proposal-by-id-response.dto';
import { BondInsuranceHiredDataForGetCompleteBondInsurancePolicyByIdResponseDTO } from '../../../dtos/bond-insurance-policy-list/get-complete-bond-insurance-policy-by-id-response.dto';
import { SelectableTableRow, ClickableTableCell } from '../../../styles';
import { BondInsurancePolicyDetails } from './policy-details/BondInsurancePolicyDetails';
import { BondInsuranceHiredDataStatusEnum } from '../../../enums/bond-insurance-hired-data-status.enum';
import { printPolicyByCombo } from '../../../services/fire-insurance-policy';
import GeneralConfirmPolicyProposalModal from './HireGeneralModal/ModalHireProposal';
import ModalAttachFiles from '../../../components/ModalAttachFiles';
import { InsurersCodeEnum } from '../../../enums/insurers-code.enum';
import CancelModalPolicy from './CancellationModal/CancelModal';
import AuthorizationContainer from '../../../components/AuthorizationContainer';
import { ActionEnum } from '../../../enums/authz-action.enum';
import { FeatureEnum } from '../../../enums/authz-feature.enum';
import FollowUpModal from '../../../components/GeneralFollowUpModal';
import NonRenewRequestModal from './NonRenewRequestModal';
import RenewRequestModal from './RenewRequestModal';
import EditModal from './EditModal';
import { FormProps as EditModalFormProps } from './EditModal/types';
import {
  NonRenewBondInsurancePayload,
  FormProps as RenewRequestModalFormProps,
  HandleSubmitProps as RenewRequestModalhandleSubmitProps
} from './RenewRequestModal/types';
import { ConfirmRenewRequestHiredData } from './ConfirmRenewRequestModal/types';
import { updatePolicy } from '../../../services/bond-insurance';
import ConfirmRenewRequestModal from './ConfirmRenewRequestModal';
import { get } from '../../../services/storage';
import moment from 'moment-timezone';
import SetResponsibleModal from './SetResponsibleModal';
import { UserRolesEnum } from '../../../enums/user-roles.enum';
import CreateClaimEstateModal from '../../claim_estates/components/create-claim-modal/Modal';

const PoliciesTableComponent = styled.div`
  margin-top: 1rem;
`;

const Header = styled(Table.Header)`
  background-color: #e1e8ed;
`;

const TableWrapper = styled.div`
  font-size: 0.85em;
  font-weight: bold;

  .ui.table {
    border: none;
    text-align: center;
    color: #364445;
  }
`;

const NotFoundWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #364445;
  font-size: 1.2em;
`;

const confiaxUsers = [
  UserRolesEnum.brokerAdmin,
  UserRolesEnum.brokerAnalyst,
  UserRolesEnum.accountManager,
  UserRolesEnum.cancelationAnalyst
];

const ableToSetResponsible = [
  BondInsuranceHiredDataStatusEnum.renewedCreated,
  BondInsuranceHiredDataStatusEnum.underRenewal
];

const ableOnlyToConfiax = [
  BondInsuranceHiredDataStatusEnum.expired,
  BondInsuranceHiredDataStatusEnum.insurerPolicyPending,
  BondInsuranceHiredDataStatusEnum.notRenewed,
  BondInsuranceHiredDataStatusEnum.insurerPolicyCreated,
  BondInsuranceHiredDataStatusEnum.renewedCreated,
  BondInsuranceHiredDataStatusEnum.overdueIssued
];

const ableToRenew = [
  BondInsuranceHiredDataStatusEnum.insurerPolicyCreated,
  BondInsuranceHiredDataStatusEnum.renewedCreated,
  BondInsuranceHiredDataStatusEnum.overdue,
  BondInsuranceHiredDataStatusEnum.overdueIssued,
  BondInsuranceHiredDataStatusEnum.expired
];

const ableToCreateClaim = [
  BondInsuranceHiredDataStatusEnum.insurerPolicyCreated,
  BondInsuranceHiredDataStatusEnum.renewedCreated
];

const ableToNonRenew = [
  BondInsuranceHiredDataStatusEnum.insurerPolicyCreated,
  BondInsuranceHiredDataStatusEnum.renewedCreated,
  BondInsuranceHiredDataStatusEnum.overdue,
  BondInsuranceHiredDataStatusEnum.overdueIssued
];

const ableToEdit = [
  BondInsuranceHiredDataStatusEnum.insurerPolicyCreated,
  BondInsuranceHiredDataStatusEnum.overdueIssued,
  BondInsuranceHiredDataStatusEnum.overdue,
  BondInsuranceHiredDataStatusEnum.renewedCreated,
  BondInsuranceHiredDataStatusEnum.notRenewed,
  BondInsuranceHiredDataStatusEnum.underRenewal,
  BondInsuranceHiredDataStatusEnum.expired
];

const ableToCancel = [
  BondInsuranceHiredDataStatusEnum.insurerPolicyCreated,
  BondInsuranceHiredDataStatusEnum.renewedCreated,
  BondInsuranceHiredDataStatusEnum.overdue,
  BondInsuranceHiredDataStatusEnum.overdueIssued,
  BondInsuranceHiredDataStatusEnum.underRenewal,
  BondInsuranceHiredDataStatusEnum.notRenewed
];

const ableToConfirm = [
  BondInsuranceHiredDataStatusEnum.insurerHirePending,
  BondInsuranceHiredDataStatusEnum.insurerPolicyPending,
  BondInsuranceHiredDataStatusEnum.insurerHirePendingIntegred
];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapState = (state: iRootState) => ({
  user: state.user,
  availableBrokersAndEstates: state.availableBrokersAndEstates
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatch = (dispatch: iRootDispatch) => ({});

type Props = {
  bondInsurancePolicies: PolicyForGetBondInsurancePoliciesListResponseDTO[];
  setIsLoading: (isLoading: boolean) => any;
  isLoading: boolean;
  getData: Function;
  currentPage: number;
  setCurrentPage: Function;
  dataCount: number;
  updateList: () => void;
} & ReturnType<typeof mapState>;

const BondInsurancePoliciesTable = (props: Props): JSX.Element => {
  const {
    bondInsurancePolicies,
    updateList,
    setIsLoading,
    isLoading,
    getData,
    user,
    currentPage,
    setCurrentPage,
    dataCount,
    availableBrokersAndEstates
  } = props;

  const [listData, setListData] = useState<
    PolicyForGetBondInsurancePoliciesListResponseDTO[]
  >([]);
  const [ascendingOrder, setAscendingOrder] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] =
    useState<PolicyForGetBondInsurancePoliciesListResponseDTO | null>(null);
  const [selectedCompleteBondInsurance, setSelectedCompleteBondInsurance] =
    useState<BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO | null>(
      null
    );
  const [
    selectedCompleteBondInsuranceAnalysis,
    setSelectedCompleteBondInsuranceAnalysis
  ] =
    useState<BondInsuranceAnalysisForGetCompleteBondInsuranceProposalByIdResponseDTO | null>(
      null
    );
  const [
    selectedCompleteBondInsuranceHiredData,
    setSelectedCompleteBondInsuranceHiredData
  ] =
    useState<BondInsuranceHiredDataForGetCompleteBondInsurancePolicyByIdResponseDTO | null>(
      null
    );
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [openHirePolicy, setOpenHirePolicyModal] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [downloadPolicyControl, setDownloadPolicyControl] = useState(false);
  const [comboData, setComboData] =
    useState<ComboTooFireInsurancePoliciesListResponseDTO | null>(null);
  const [policySelected, setPolicySelected] =
    useState<PolicyForGetBondInsurancePoliciesListResponseDTO | null>(null);

  const [openFollowUp, setOpenFollowUp] = useState<boolean>(false);
  const [openSetResponsible, setOpenSetResponsible] = useState<boolean>(false);

  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openCancelRenew, setOpenCancelRenew] = useState(false);
  const lastPage = Math.floor(dataCount / 10) + 1;
  const [isOpenNonRenewalRequestModal, setIsOpenNonRenewalRequestModal] =
    useState<boolean>(false);
  const [isOpenRenewalRequestModal, setIsOpenRenewalRequestModal] =
    useState<boolean>(false);
  const [isOpenConfirmRenewalRequestModal, setIsOpenConfirmRenewalRequestModal] =
    useState<boolean>(false);
  const [isOpenCreateClaimModal, setIsOpenCreateClaimModal] = useState<boolean>(false);
  const [selectedHiredData, setSelectedHiredData] =
    useState<ConfirmRenewRequestHiredData | null>(null);

  const [hasClaim, setHasClaim] = useState(false);

  const { insurers, estates } = availableBrokersAndEstates;

  const userRole = get('role');
  const userId = get('uid');

  const confiaxUsers = [
    UserRolesEnum.brokerAdmin,
    UserRolesEnum.brokerAnalyst,
    UserRolesEnum.accountManager,
    UserRolesEnum.cancelationAnalyst
  ];

  const ableToSetResponsible = [
    BondInsuranceHiredDataStatusEnum.renewedCreated,
    BondInsuranceHiredDataStatusEnum.underRenewal
  ];

  const ableOnlyToConfiax = [
    BondInsuranceHiredDataStatusEnum.expired,
    BondInsuranceHiredDataStatusEnum.insurerPolicyPending,
    BondInsuranceHiredDataStatusEnum.notRenewed,
    BondInsuranceHiredDataStatusEnum.insurerPolicyCreated,
    BondInsuranceHiredDataStatusEnum.renewedCreated,
    BondInsuranceHiredDataStatusEnum.overdueIssued
  ];

  const ableToRenew = [
    BondInsuranceHiredDataStatusEnum.insurerPolicyCreated,
    BondInsuranceHiredDataStatusEnum.renewedCreated,
    BondInsuranceHiredDataStatusEnum.overdue,
    BondInsuranceHiredDataStatusEnum.overdueIssued,
    BondInsuranceHiredDataStatusEnum.expired
  ];

  const ableToCreateClaim = [
    BondInsuranceHiredDataStatusEnum.insurerPolicyCreated,
    BondInsuranceHiredDataStatusEnum.renewedCreated
  ];

  const ableToNonRenew = [
    BondInsuranceHiredDataStatusEnum.insurerPolicyCreated,
    BondInsuranceHiredDataStatusEnum.renewedCreated,
    BondInsuranceHiredDataStatusEnum.overdue,
    BondInsuranceHiredDataStatusEnum.overdueIssued
  ];

  const ableToEdit = [
    BondInsuranceHiredDataStatusEnum.insurerPolicyCreated,
    BondInsuranceHiredDataStatusEnum.overdueIssued,
    BondInsuranceHiredDataStatusEnum.overdue,
    BondInsuranceHiredDataStatusEnum.renewedCreated,
    BondInsuranceHiredDataStatusEnum.notRenewed,
    BondInsuranceHiredDataStatusEnum.underRenewal,
    BondInsuranceHiredDataStatusEnum.expired
  ];

  const ableToCancel = [
    BondInsuranceHiredDataStatusEnum.insurerPolicyCreated,
    BondInsuranceHiredDataStatusEnum.renewedCreated,
    BondInsuranceHiredDataStatusEnum.overdue,
    BondInsuranceHiredDataStatusEnum.overdueIssued,
    BondInsuranceHiredDataStatusEnum.underRenewal,
    BondInsuranceHiredDataStatusEnum.notRenewed
  ];

  const ableToConfirm = [
    BondInsuranceHiredDataStatusEnum.insurerHirePending,
    BondInsuranceHiredDataStatusEnum.insurerPolicyPending,
    BondInsuranceHiredDataStatusEnum.insurerHirePendingIntegred
  ];

  useEffect(() => {
    setListData(bondInsurancePolicies);
  }, [bondInsurancePolicies]);

  const canRenew = (status: BondInsuranceHiredDataStatusEnum, endVigency: Date, insurerCode: string) => {
    if(!ableToRenew.includes(status)) return false;

    const today = moment();
    const end = moment(endVigency);
    const overdueEnd = moment(endVigency).add(30, 'days');

    const diff = today.isBefore(end) ? end.diff(today, 'days') : 100;
    const overdueDiff = today.isBefore(overdueEnd)
      ? end.isBefore(today)
        ? today.diff(end, 'days')
        : end.diff(today, 'days')
      : 100;

    if (status === BondInsuranceHiredDataStatusEnum.insurerPolicyCreated) {
      return diff < 30;
    }

    if (status === BondInsuranceHiredDataStatusEnum.renewedCreated) {
      const response = confiaxUsers.includes(userRole as UserRolesEnum)
        ? true
        : diff < 30;
      return response;
    }

    if (status === BondInsuranceHiredDataStatusEnum.overdue) {
      const response = confiaxUsers.includes(userRole as UserRolesEnum)
        ? overdueDiff < 30
        : insurerCode === 'GARANTTI'
        ? overdueDiff < 30
        : overdueDiff < 20;
      return response;
    }

    if (status === BondInsuranceHiredDataStatusEnum.expired) {
      return confiaxUsers.includes(userRole as UserRolesEnum);
    }

    if (status === BondInsuranceHiredDataStatusEnum.overdueIssued) {
      return true;
    }
  }

  const canNonRenew = (status: BondInsuranceHiredDataStatusEnum, endVigency: Date) => {
    if (!ableToNonRenew.includes(status)) return false;

    const today = moment();
    const end = moment(endVigency);
    const overdueEnd = moment(endVigency).add(30, 'days');

    const diff = today.isBefore(end) ? end.diff(today, 'days') : 100;
    const overdueDiff = today.isBefore(overdueEnd)
      ? end.isBefore(today)
        ? today.diff(end, 'days')
        : end.diff(today, 'days')
      : 100;

    if (status === BondInsuranceHiredDataStatusEnum.insurerPolicyCreated) {
      return diff < 30;
    }

    if (status === BondInsuranceHiredDataStatusEnum.renewedCreated) {
      const response = confiaxUsers.includes(userRole as UserRolesEnum)
        ? true
        : diff < 30;
      return response;
    }

    if (status === BondInsuranceHiredDataStatusEnum.overdue) {
      const response = confiaxUsers.includes(userRole as UserRolesEnum)
        ? overdueDiff < 30
        : overdueDiff < 20;
      return response;
    }

    if(status === BondInsuranceHiredDataStatusEnum.overdueIssued) {
      return confiaxUsers.includes(userRole as UserRolesEnum);
    }
  };

  const canCancel = (status: BondInsuranceHiredDataStatusEnum, endVigency: Date) => {
    if (!ableToCancel.includes(status)) return false;

    const today = moment();
    const overdueEnd = moment(endVigency).add(30, 'days');
    const end = moment(endVigency);
    const overdueDiff = today.isBefore(overdueEnd)
      ? end.isBefore(today)
        ? today.diff(end, 'days')
        : end.diff(today, 'days')
      : 100;

    if (
      status === BondInsuranceHiredDataStatusEnum.insurerPolicyCreated ||
      status === BondInsuranceHiredDataStatusEnum.renewedCreated ||
      status === BondInsuranceHiredDataStatusEnum.overdueIssued
    ) {
      return true;
    }

    if (
      status === BondInsuranceHiredDataStatusEnum.underRenewal ||
      status === BondInsuranceHiredDataStatusEnum.notRenewed
    ) {
      return confiaxUsers.includes(userRole as UserRolesEnum);
    }

    if (status === BondInsuranceHiredDataStatusEnum.overdue) {
      const response = confiaxUsers.includes(userRole as UserRolesEnum)
        ? overdueDiff < 30
        : overdueDiff < 20;
      return response;
    }
  };

  const canCreateClaim = (status: BondInsuranceHiredDataStatusEnum) => {
    return ableToCreateClaim.includes(status);
  };

  const close = (): void => {
    setOpenHirePolicyModal(false);
    setPolicySelected(null);
  };

  const handleOpen = () => {
    setOpenFollowUp(true);
  };

  const handleClose = () => {
    setOpenFollowUp(false);
  };

  async function prepareToOpenDetails(
    policy: PolicyForGetBondInsurancePoliciesListResponseDTO
  ) {
    setIsLoading(true);
    try {
      const response = await getBondInsurancePolicy(policy.hiredDataTableId);

      setComboData(policy.combo || null);
      setSelectedCompleteBondInsurance(response.analysis.bondInsurance);
      setSelectedCompleteBondInsuranceAnalysis(response.analysis);
      setSelectedCompleteBondInsuranceHiredData(response.hiredData);
      setIsDetailsModalOpen(true);
    } catch (e) {
      dangerNotification('Erro!', `Erro ao abrir apólice: ${(e as Error).message}`);
      setSelectedCompleteBondInsurance(null);
      setSelectedCompleteBondInsuranceAnalysis(null);
      setSelectedCompleteBondInsuranceHiredData(null);
      setIsDetailsModalOpen(false);
    } finally {
      setIsLoading(false);
    }
  }

  async function getPreviousHiredData(policyId: string) {
    setIsLoading(true);
    try {
      const response = await getBondInsurancePolicy(policyId);

      setSelectedCompleteBondInsurance(response.analysis.bondInsurance);
      setSelectedCompleteBondInsuranceAnalysis(response.analysis);
      setSelectedCompleteBondInsuranceHiredData(response.hiredData);
      setIsDetailsModalOpen(true);
    } catch (e) {
      dangerNotification('Erro!', `Erro ao abrir apólice: ${(e as Error).message}`);
      setSelectedCompleteBondInsurance(null);
      setSelectedCompleteBondInsuranceAnalysis(null);
      setSelectedCompleteBondInsuranceHiredData(null);
      setIsDetailsModalOpen(false);
    } finally {
      setIsLoading(false);
    }
  }

  async function prepareToOpenEditModal(
    policy: PolicyForGetBondInsurancePoliciesListResponseDTO
  ) {
    setIsLoading(true);

    try {
      const response = await getBondInsurancePolicy(policy.hiredDataTableId);
      setComboData(policy.combo || null);
      setSelectedCompleteBondInsurance(response.analysis.bondInsurance);
      setSelectedCompleteBondInsuranceAnalysis(response.analysis);
      setSelectedCompleteBondInsuranceHiredData(response.hiredData);
      setIsOpenEditModal(true);
    } catch (e) {
      dangerNotification('Erro!', `Erro ao abrir apólice: ${(e as Error).message}`);
      setSelectedCompleteBondInsurance(null);
      setSelectedCompleteBondInsuranceAnalysis(null);
      setSelectedCompleteBondInsuranceHiredData(null);
      setIsOpenEditModal(false);
    } finally {
      setIsLoading(false);
    }
  }

  function handleOpenConfirmModal(
    policy: PolicyForGetBondInsurancePoliciesListResponseDTO
  ) {
    setPolicySelected(policy);
    setOpenHirePolicyModal(true);
  }

  async function prepareToOpenRenewModal(
    policy: PolicyForGetBondInsurancePoliciesListResponseDTO
  ) {
    setIsLoading(true);

    try {
      const hasClaim = await getPolicyHasClaim(policy.insurerPolicyId);
      const response = await getBondInsurancePolicy(policy.hiredDataTableId);
      setComboData(policy.combo || null);
      setSelectedCompleteBondInsurance(response.analysis.bondInsurance);
      setSelectedCompleteBondInsuranceAnalysis(response.analysis);
      setSelectedCompleteBondInsuranceHiredData(response.hiredData);
      setHasClaim(hasClaim);
      setIsOpenRenewalRequestModal(true);
    } catch (e) {
      dangerNotification('Erro!', `Erro ao buscar apólice: ${(e as Error).message}`);
      setSelectedCompleteBondInsurance(null);
      setSelectedCompleteBondInsuranceAnalysis(null);
      setSelectedCompleteBondInsuranceHiredData(null);
      setIsOpenRenewalRequestModal(false);
    } finally {
      setIsLoading(false);
    }
  }

  async function prepareToOpenNonRenewModal(
    policy: PolicyForGetBondInsurancePoliciesListResponseDTO
  ) {
    setIsLoading(true);

    try {
      const response = await getBondInsurancePolicy(policy.hiredDataTableId);
      setComboData(policy.combo || null);
      setSelectedCompleteBondInsurance(response.analysis.bondInsurance);
      setSelectedCompleteBondInsuranceAnalysis(response.analysis);
      setSelectedCompleteBondInsuranceHiredData(response.hiredData);
      setIsOpenNonRenewalRequestModal(true);
    } catch (e) {
      dangerNotification('Erro!', `Erro ao buscar apólice: ${(e as Error).message}`);
      setSelectedCompleteBondInsurance(null);
      setSelectedCompleteBondInsuranceAnalysis(null);
      setSelectedCompleteBondInsuranceHiredData(null);
      setIsOpenNonRenewalRequestModal(false);
    } finally {
      setIsLoading(false);
    }
  }

  async function prepareToOpenConfirmRenewModal(
    policy: PolicyForGetBondInsurancePoliciesListResponseDTO
  ) {
    setIsLoading(true);
    try {
      const response = await getBondInsuranceHiredData(policy.hiredDataTableId);
      setPolicySelected(policy);
      setSelectedHiredData(response);
      setIsOpenConfirmRenewalRequestModal(true);
    } catch (e) {
      dangerNotification('Erro!', `Erro ao buscar apólice: ${(e as Error).message}`);
      setPolicySelected(null);
      setIsOpenConfirmRenewalRequestModal(false);
    } finally {
      setIsLoading(false);
    }
  }

  async function prepareToOpenCreateClaimModal(
    policy: PolicyForGetBondInsurancePoliciesListResponseDTO
  ) {
    setIsLoading(true);
    try {
      const response = await getBondInsuranceHiredData(policy.hiredDataTableId);
      setPolicySelected(policy);
      setSelectedHiredData(response);
      setIsOpenCreateClaimModal(true);
    } catch (e) {
      console.log('e', e);
      dangerNotification('Erro!', `Erro ao buscar apólice: ${(e as Error).message}`);
      setPolicySelected(null);
      setIsOpenCreateClaimModal(false);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDownloadPottencialPoliciesFile(
    policy: PolicyForGetBondInsurancePoliciesListResponseDTO
  ) {
    setDownloadPolicyControl(true);
    return printPottencialBondInsuracePolicy(policy.analysisTableId)
      .then(res => {
        renderPdf(res.payload, policy.insurerProposalId);
        successNotification('Sucesso', 'Sucesso!');
      })
      .catch(e => {
        dangerNotification(
          'Atenção',
          'Essa apólice ainda não foi emitida, tente novamente mais tarde.',
          5000
        );
      })
      .finally(() => setDownloadPolicyControl(false));
  }

  const renderPdf = (base64: string, filename: string) => {
    const linkSource = `data:application/pdf;base64,${base64}`;
    const downloadLink = document.createElement('a');
    const fileName = 'apolice' + filename + '.pdf';
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setDownloadPolicyControl(false);
  };

  async function handleDownloadFirePoliciesFile(
    combo: ComboTooFireInsurancePoliciesListResponseDTO
  ) {
    warningNotification('Aguarde', 'Estamos localizando sua apólice.');
    try {
      const data = await printPolicyByCombo(combo.ticket_number);
      const linkSource = `data:application/pdf;base64,${data.attachment}`;
      const downloadLink = document.createElement('a');
      const fileName = 'apolice_incendio_' + data.filename + '.pdf';
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      successNotification('Sucesso', 'Download realizado com sucesso!');
    } catch (err) {
      warningNotification(
        'Opss..',
        'Ainda não recebemos essa apólice. A emissão da mesma pode demorar até 30 dias. Tente novamente mais tarde.'
      );
      console.log(err);
    }
  }

  function toggleAscending(): void {
    const toggledAscending = !ascendingOrder;
    setAscendingOrder(toggledAscending);
    const orderedData = sortBondInsurancePolicies(
      bondInsurancePolicies,
      toggledAscending
    );
    setListData(orderedData);
  }

  async function handleOpenEditModal(
    policy: PolicyForGetBondInsurancePoliciesListResponseDTO
  ) {
    setPolicySelected(policy);
    await prepareToOpenEditModal(policy);
    setIsOpenEditModal(true);
  }

  function handleCloseOpenEditModal(): void {
    setPolicySelected(null);
    setIsOpenEditModal(false);
  }

  const handleSubmitNonRenewRequest = async (data: NonRenewBondInsurancePayload) => {
    try {
      await nonRenewBondInsurance(data);
      setIsOpenNonRenewalRequestModal(false);
      successNotification('Sucesso!', 'Cancelamento da renovação com sucesso.');
    } catch (e) {
      dangerNotification(
        'Erro!',
        `Erro ao cancelar renovação da apólice: ${(e as Error).message}`
      );
    }
  };

  const handleSubmitRenewRequest = async (
    data: RenewRequestModalFormProps & RenewRequestModalhandleSubmitProps
  ) => {
    try {
      setIsLoading(true);
      const payload = {
        bondInsuranceHiredDataId: data.bondInsuranceHiredDataId,
        rentValue: moneyStringToNumber(data.rentValue),
        condominiumFee: moneyStringToNumber(data.condominiumFee || '0'),
        taxesValue: moneyStringToNumber(data.taxesValue || '0'),
        energyBill: moneyStringToNumber(data.energyBill || '0'),
        waterBill: moneyStringToNumber(data.waterBill || '0'),
        gasBill: moneyStringToNumber(data.gasBill || '0'),
        rentalPeriodStartDate: data.startContract || new Date(),
        rentalPeriodEndDate: data.endContract || new Date(),
        rentInsuranceContractStartDate: data.startContract || new Date(),
        rentInsuranceContractEndDate: data.endContract || new Date(),
        renewalPeriod: +data.renovationPeriod
      };

      await renewBondInsurance(payload);
      setIsOpenRenewalRequestModal(false);
      successNotification(
        'Sucesso!',
        'Sua solicitação de renovação foi enviada com sucesso.'
      );
      return true;
    } catch (e) {
      dangerNotification('Erro!', `Erro ao renovar apólice: ${(e as Error).message}`);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseConfirmRenewalRequestModal = async () => {
    setSelectedHiredData(null);
    setIsLoading(false);
    setIsOpenConfirmRenewalRequestModal(false);
  };

  const handleSubmitEditModal = async (data: EditModalFormProps) => {
    try {
      setIsLoading(true);
      await updatePolicy(data);
      setIsOpenEditModal(false);
      successNotification('Sucesso!', 'Sua apólice foi atualizada com sucesso.');
    } catch (e) {
      console.log('e', e);
      dangerNotification('Erro!', `Erro ao editar apólice: ${(e as Error).message}`);
    } finally {
      getData();
      setIsLoading(false);
    }
  };

  const handleCalcUnread = (followup: policyFollowUpType[], idUser: string) => {
    if (idUser === '') return 0;
    const systemIDs = ['50f27c06-13ab-47c7-805b-7d74b9b83799', '921a01ac-fd2e-4263-b052-d90d2fd50319']

    if (followup.length === 0) return 0;

    let count = 0;
    followup.forEach(item => {
      const sender = item.senderId ?? ''
      const expirePolicyNotificationMessage = item.context === 'HIRED_DATA' && systemIDs.includes(sender) && item.message?.includes('Notificação: atenção, sua apólice expira em');
      const dontCount = user.role.includes('ESTATE') ? item.isInternal : expirePolicyNotificationMessage;

      if (item.viewedBy !== undefined && item.viewedBy.length > 0 && !dontCount) {
        if (!item.viewedBy.includes(idUser)) count++;
      }
    });
    return count;
  };

  const cancelUnderRenew = async (policyId: string) => {
    try {
      setIsLoading(true);
      await cancelUnderRenewal(policyId);
      setOpenCancelRenew(false);
      successNotification(
        'Sucesso!',
        'Sua renovação de apólice foi cancelada com sucesso.'
      );
    } catch (e) {
      console.log('e', e);
      dangerNotification('Erro!', `${(e as Error).message}`);
    } finally {
      setOpenCancelRenew(false);
      getData();
      setIsLoading(false);
    }
  };

  function renderTableData(
    policies: Array<PolicyForGetBondInsurancePoliciesListResponseDTO>
  ): Array<JSX.Element> {
    return policies.map((policy, index) => {
      const tenantName = policy.tenant?.name ? policy.tenant.name : 'Indisponível';
      const tenantDocument = policy.tenant?.document
        ? maskCpfOrCnpj(policy.tenant.document)
        : 'Indisponível';
      const trigger = <IconButton color="blue" name="ellipsis vertical" />;
      const unread = handleCalcUnread(policy.followup ?? [], userId ?? '');
      return (
        <>
          {user.role!.includes('BROKER') && policySelected !== null && (
            <GeneralConfirmPolicyProposalModal
              updateList={updateList}
              openModal={openHirePolicy}
              closeModal={close}
              hiredDataId={policySelected.hiredDataTableId}
              observations={policySelected.observations}
              hiredInstallments={policySelected.values.hiredInstallments}
              hiredValue={policySelected.values.hiredInstallmentsValue}
              policyNumber={policySelected.insurerPolicyId}
              rentInsuranceContractEndDate={policySelected.rentInsuranceContractEndDate}
              rentInsuranceContractStartDate={
                policySelected.rentInsuranceContractStartDate
              }
              userId={user.id}
            />
          )}
          {user.role!.includes('BROKER') && policySelected !== null && (
            <EditModal
              isOpen={isOpenEditModal}
              handleSubmit={handleSubmitEditModal}
              onClose={handleCloseOpenEditModal}
              combo={comboData}
              bondInsuranceId={selectedCompleteBondInsurance?.id}
              bondInsurance={selectedCompleteBondInsurance}
              bondInsuranceAnalysis={selectedCompleteBondInsuranceAnalysis}
              bondInsuranceHiredData={selectedCompleteBondInsuranceHiredData}
              insurersOptions={insurers}
              estatesOptions={estates}
            />
          )}
          {
            <SelectableTableRow key={policy.hiredDataTableId}>
              <ClickableTableCell
                onClick={async () => {
                  setSelectedItem(policy);
                  await prepareToOpenDetails(policy);
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  {DateToStringDateTime(policy.insurerPolicyCreatedAt || new Date(0, 0, 0))}
                  {(policy.code === 'QUIVER' || policy.code === 'MANUAL') && <span style={{ color: '#808080', fontSize: '12px', fontWeight: 400 }}>{policy.code === 'QUIVER' ? 'Importada' : 'Manual'}</span>}
                </div>

              </ClickableTableCell>

              <ClickableTableCell
                onClick={async () => {
                  setSelectedItem(policy);
                  await prepareToOpenDetails(policy);
                }}
              >
                {policy.insurer.name}
              </ClickableTableCell>

              <ClickableTableCell
                onClick={async () => {
                  setSelectedItem(policy);
                  await prepareToOpenDetails(policy);
                }}
              >
                {policy.estate.name ? policy.estate.name : 'Indisponível'}
              </ClickableTableCell>

              <ClickableTableCell
                onClick={async () => {
                  setSelectedItem(policy);
                  await prepareToOpenDetails(policy);
                }}
              >
                {tenantName}
                <br />
                <span style={{ fontSize: '12px', color: '#808080' }}>
                  {tenantDocument}
                </span>
              </ClickableTableCell>

              <ClickableTableCell
                onClick={async () => {
                  setSelectedItem(policy);
                  await prepareToOpenDetails(policy);
                }}
              >
                {policy.property.street}
                <br />(
                {policy.bondInsurance.purpose
                  ? policy.bondInsurance.purpose === 'RESIDENTIAL'
                    ? 'Residencial'
                    : 'Comercial'
                  : 'Indisponível'}
                )
              </ClickableTableCell>

              <ClickableTableCell
                onClick={async () => {
                  setSelectedItem(policy);
                  await prepareToOpenDetails(policy);
                }}
              >
                {`R$ ${maskMoney(
                  `${
                    policy.values.totalPremiumAmount &&
                    policy.values.totalPremiumAmount > 0
                      ? policy.values.totalPremiumAmount
                      : policy.values.hiredInstallmentsValue *
                          policy.values.hiredInstallments || 0
                  }`,
                  true
                )}`}
                <br />
                <span style={{ fontSize: '12px', color: '#808080' }}>
                  {Number(policy.values.hiredInstallments) ||
                    policy.values.installmentsAmountChosen ||
                    'Indisponível'}
                  x
                </span>
              </ClickableTableCell>

              {/* <ClickableTableCell
                onClick={async () => {
                  setSelectedItem(policy);
                  await prepareToOpenDetails(policy);
                }}
              >
                {Number(policy.values.hiredInstallments) ||
                  policy.values.installmentsAmountChosen ||
                  'Indisponível'}
              </ClickableTableCell> */}

              <ClickableTableCell
                onClick={async () => {
                  setSelectedItem(policy);
                  await prepareToOpenDetails(policy);
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {policy.renewalResponsible && !userRole?.includes('ESTATE') && (
                    <div>
                      <Popup
                        trigger={<Icon name="user" color="blue" size="large" />}
                        content={policy.responsibleName}
                      />
                    </div>
                  )}
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    {getBondInsurancePolicyStatusLabel(policy.status) || ''}
                    {policy.status ===
                      BondInsuranceHiredDataStatusEnum.renewedCreated && (
                      <span style={{ color: '#808080', fontSize: '12px', fontWeight: 400 }}>
                        Renovada
                      </span>
                    )}
                  </div>
                </div>
              </ClickableTableCell>

              <ClickableTableCell>
                <Menu compact>
                  <Menu.Item>
                    <Button
                      primary
                      icon="chat"
                      onClick={() => {
                        setSelectedItem(policy);
                        setOpenFollowUp(true);
                      }}
                    />
                    {unread !== 0 && (
                      <Label color="red" circular floating>
                        {unread}
                      </Label>
                    )}
                    {selectedItem &&
                      selectedItem.hiredDataTableId == policy.hiredDataTableId &&
                      openFollowUp && (
                        <FollowUpModal
                          id={policy.bondInsuranceId}
                          isOpen={true}
                          onOpen={handleOpen}
                          onClose={handleClose}
                          header="Follow Up"
                          context={'HIRED_DATA'}
                          user={user}
                        />
                      )}
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      trigger={trigger}
                      icon={null}
                      direction="left"
                      onOpen={() => {
                        setSelectedItem(policy);
                      }}
                    >
                      <Dropdown.Menu style={{ zIndex: 1000 }}>
                        {ableToEdit.includes(policy.status) && (
                          <AuthorizationContainer
                            action={ActionEnum.updatePolicy}
                            feature={FeatureEnum.policies}
                          >
                            <Dropdown.Item
                              icon="pencil"
                              text={'Editar Apólice'}
                              onClick={() => handleOpenEditModal(policy)}
                            />
                          </AuthorizationContainer>
                        )}

                        {/* {policy.insurer.code === InsurersCodeEnum.Pottencial && (
                          <Dropdown.Item
                            text={
                              downloadPolicyControl ? 'Carregando...' : 'Imprimir Apólice'
                            }
                            icon="print"
                            onClick={(): Promise<void> =>
                              handleDownloadPottencialPoliciesFile(policy)
                            }
                          ></Dropdown.Item>
                        )} */}

                        {ableToConfirm.includes(policy.status) && (
                          <AuthorizationContainer
                            action={ActionEnum.contract}
                            feature={FeatureEnum.policies}
                          >
                            <Dropdown.Item
                              icon="checkmark"
                              text={'Confirmar Apólice'}
                              onClick={() => handleOpenConfirmModal(policy)}
                            />
                          </AuthorizationContainer>
                        )}

                        {policy.status ===
                          BondInsuranceHiredDataStatusEnum.underRenewal &&
                          confiaxUsers.includes(userRole as UserRolesEnum) && (
                            <AuthorizationContainer
                              action={ActionEnum.confirmRenew}
                              feature={FeatureEnum.policies}
                            >
                              <Dropdown.Item
                                icon="refresh"
                                text={'Confirmar Renovação'}
                                onClick={async () => {
                                  setSelectedItem(policy);
                                  await prepareToOpenConfirmRenewModal(policy);
                                }}
                              />
                            </AuthorizationContainer>
                          )}

                        {canRenew(
                          policy.status,
                          policy.rentInsuranceContractEndDate,
                          policy.insurer.code
                        ) && (
                          <AuthorizationContainer
                            action={ActionEnum.renew}
                            feature={FeatureEnum.policies}
                          >
                            <Dropdown.Item
                              icon="refresh"
                              text={'Solicitar Renovação'}
                              onClick={async () => {
                                setSelectedItem(policy);
                                await prepareToOpenRenewModal(policy);
                              }}
                            />
                          </AuthorizationContainer>
                        )}

                        {canNonRenew(
                          policy.status,
                          policy.rentInsuranceContractEndDate
                        ) && (
                          <AuthorizationContainer
                            action={ActionEnum.notRenew}
                            feature={FeatureEnum.policies}
                          >
                            <Dropdown.Item
                              icon="cancel"
                              text={'Solicitar Não Renovação'}
                              onClick={async () => {
                                setSelectedItem(policy);
                                await prepareToOpenNonRenewModal(policy);
                              }}
                            />
                          </AuthorizationContainer>
                        )}

                        {/* {canCreateClaim(policy.status) && (
                          <AuthorizationContainer
                            action={ActionEnum.create}
                            feature={FeatureEnum.claims}
                          >
                            <Dropdown.Item
                              icon="fire"
                              text={'Abrir Sinistro'}
                              onClick={async () => {
                                setSelectedItem(policy);
                                await prepareToOpenCreateClaimModal(policy);
                              }}
                            />
                          </AuthorizationContainer>
                        )} */}

                        {ableToSetResponsible.includes(policy.status) &&
                          confiaxUsers.includes(userRole as UserRolesEnum) && (
                            <AuthorizationContainer
                              action={ActionEnum.updatePolicy}
                              feature={FeatureEnum.policies}
                            >
                              <Dropdown.Item
                                icon="user"
                                text={'Atribuir Responsável'}
                                onClick={() => {
                                  setSelectedItem(policy);
                                  setOpenSetResponsible(true);
                                }}
                              />
                            </AuthorizationContainer>
                          )}

                        {canCancel(
                          policy.status,
                          policy.rentInsuranceContractEndDate
                        ) && (
                          <AuthorizationContainer
                            action={ActionEnum.cancel}
                            feature={FeatureEnum.policies}
                          >
                            <Dropdown.Item
                              icon="trash"
                              text={'Solicitar Cancelamento'}
                              onClick={() => setOpenCancelModal(true)}
                            />
                          </AuthorizationContainer>
                        )}

                        {policy.status ===
                          BondInsuranceHiredDataStatusEnum.underRenewal &&
                          confiaxUsers.includes(userRole as UserRolesEnum) && (
                            <AuthorizationContainer
                              action={ActionEnum.cancel}
                              feature={FeatureEnum.policies}
                            >
                              <Dropdown.Item
                                icon="cancel"
                                text={'Cancelar Renovação'}
                                onClick={() => {
                                  setSelectedItem(policy);
                                  setOpenCancelRenew(true);
                                }}
                              />
                            </AuthorizationContainer>
                          )}

                        <Dropdown.Item>
                          <ModalAttachFiles
                            bondInsuranceId={policy.bondInsuranceId}
                            tags={[
                              {
                                tag: "analysis",
                                name: "Análise",
                              }, {
                                tag: "policy",
                                name: "Apólice",
                                date: policy.insurerPolicyCreatedAt,
                              }
                            ]}
                            disabled={
                              userRole?.includes('ESTATE') &&
                              policy.status !== BondInsuranceHiredDataStatusEnum.underRenewal
                            }
                          />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    {openCancelRenew && selectedItem !== null && (
                      <Confirm
                        header="Cancelar Renovação"
                        content="Tem certeza que deseja cancelar esta Renovação?"
                        cancelButton="Desistir"
                        confirmButton="Cancelar Renovação"
                        open={openCancelRenew}
                        onCancel={() => setOpenCancelRenew(false)}
                        onConfirm={() => cancelUnderRenew(selectedItem.hiredDataTableId)}
                      />
                    )}

                    {openSetResponsible && selectedItem !== null && (
                      <SetResponsibleModal
                        bondInsuranceHiredDataId={selectedItem.hiredDataTableId}
                        isOpen={openSetResponsible}
                        onClose={() => {
                          setOpenSetResponsible(false);
                        }}
                        user={user}
                        responsible={selectedItem?.renewalResponsible}
                      />
                    )}

                    {selectedItem !== null &&
                      selectedItem.hiredDataTableId == policy.hiredDataTableId &&
                      openCancelModal && (
                        <CancelModalPolicy
                          closeModal={() => setOpenCancelModal(false)}
                          setOpen={() => setOpenCancelModal(true)}
                          hiredDataId={selectedItem.hiredDataTableId}
                          bondInsuranceId={selectedItem.bondInsuranceId}
                          callback={() => getData()}
                          openModal={openCancelModal || false}
                        />
                      )}

                    {selectedItem &&
                      isOpenNonRenewalRequestModal &&
                      selectedItem.hiredDataTableId == policy.hiredDataTableId && (
                        <NonRenewRequestModal
                          isOpen={isOpenNonRenewalRequestModal}
                          handleSubmit={handleSubmitNonRenewRequest}
                          onClose={() => setIsOpenNonRenewalRequestModal(false)}
                          bondInsuranceHiredDataId={selectedItem.hiredDataTableId}
                        />
                      )}

                    {selectedItem &&
                      isOpenRenewalRequestModal &&
                      selectedItem.hiredDataTableId == policy.hiredDataTableId && (
                        <RenewRequestModal
                          bondInsuranceHiredDataId={selectedItem.hiredDataTableId}
                          bondInsuranceId={selectedItem.bondInsuranceId}
                          isOpen={isOpenRenewalRequestModal}
                          handleSubmit={handleSubmitRenewRequest}
                          onClose={() => setIsOpenRenewalRequestModal(false)}
                          policy={selectedItem}
                          hasClaim={hasClaim}
                        />
                      )}
                    {selectedItem &&
                      isOpenCreateClaimModal &&
                      selectedItem.hiredDataTableId == policy.hiredDataTableId && (
                        <CreateClaimEstateModal
                          onlyModal
                          onClose={() => setIsOpenCreateClaimModal(false)}
                          policy={selectedItem}
                        />
                      )}

                    {selectedHiredData &&
                      selectedItem &&
                      isOpenConfirmRenewalRequestModal &&
                      selectedItem.hiredDataTableId == policy.hiredDataTableId && (
                        <ConfirmRenewRequestModal
                          isOpen={isOpenConfirmRenewalRequestModal}
                          onClose={onCloseConfirmRenewalRequestModal}
                          confirmHiredData={selectedHiredData}
                          bondInsuranceId={selectedItem.bondInsuranceId}
                          policy={selectedItem}
                        />
                      )}
                    {!user.role.includes('ESTATE') &&
                      policy.validationStatus !== undefined &&
                      policy.validationStatus !== 'pending' && (
                        <Label floating style={{ background: 'none' }}>
                          {policy.validationStatus === 'warning' ? (
                            <Popup
                              content={
                                policy.validationMessage ?? 'Possui inconsistência'
                              }
                              trigger={<IconButton color="yellow" name="warning sign" />}
                            />
                          ) : policy.validationStatus === 'error' ? (
                            <Popup
                              content="Não foi possível validar esse item"
                              trigger={<IconButton color="grey" name="warning sign" />}
                            />
                          ) : policy.validationStatus === 'success' ? (
                            <Popup
                              content="Nenhuma inconsistência encontrada"
                              trigger={<IconButton color="green" name="check circle" />}
                            />
                          ) : policy.validationStatus === 'fixed' ? (
                            <Popup
                              content="Inconsistências corrigidas"
                              trigger={<IconButton color="green" name="check circle" />}
                            />
                          ) : (
                            <></>
                          )}
                        </Label>
                      )}
                  </Menu.Item>
                </Menu>
              </ClickableTableCell>
            </SelectableTableRow>
          }
        </>
      );
    });
  }

  return (
    <PoliciesTableComponent>
      {isDetailsModalOpen && (
        <BondInsurancePolicyDetails
          callback={getData}
          combo={comboData}
          bondInsuranceId={selectedCompleteBondInsurance?.id}
          bondInsurance={selectedCompleteBondInsurance}
          bondInsuranceAnalysis={selectedCompleteBondInsuranceAnalysis}
          bondInsuranceHiredData={selectedCompleteBondInsuranceHiredData}
          isOpen={isDetailsModalOpen}
          onClose={() => {
            setSelectedCompleteBondInsurance(null);
            setSelectedCompleteBondInsuranceAnalysis(null);
            setSelectedItem(null);
            setIsDetailsModalOpen(false);
          }}
          getPrevious={getPreviousHiredData}
        />
      )}
      <Dimmer.Dimmable as={Segment} dimmed={isLoading}>
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
        {listData && listData.length > 0 ? (
          <TableWrapper>
            <Table>
              <Header>
                <Table.Row>
                  <Table.HeaderCell width={2}>
                    CRIADO EM
                    <IconButton
                      name={
                        ascendingOrder
                          ? 'long arrow alternate up'
                          : 'long arrow alternate down'
                      }
                      color="blue"
                      onClick={toggleAscending}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>SEGURADORA</Table.HeaderCell>
                  <Table.HeaderCell>IMOBILIARIA</Table.HeaderCell>
                  <Table.HeaderCell>INQUILINO</Table.HeaderCell>
                  <Table.HeaderCell>ENDEREÇO</Table.HeaderCell>
                  <Table.HeaderCell>PRÊMIO TOTAL (R$)</Table.HeaderCell>
                  <Table.HeaderCell>STATUS</Table.HeaderCell>
                  {/* <Table.HeaderCell>Nº PROPOSTA</Table.HeaderCell> */}
                  {/* <Table.HeaderCell>Nº DE PARCELAS</Table.HeaderCell> */}
                  {/* <Table.HeaderCell>Nº APÓLICE</Table.HeaderCell> */}
                  <Table.HeaderCell>AÇÕES</Table.HeaderCell>
                </Table.Row>
              </Header>
              <Table.Body>{renderTableData(bondInsurancePolicies)}</Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="17">
                    <Menu floated="right" pagination>
                      <Menu.Item>
                        <p>{`${currentPage * 10 - 9} - ${
                          dataCount < currentPage * 10 ? dataCount : currentPage * 10
                        }  de ${dataCount}`}</p>
                      </Menu.Item>
                      <Menu.Item
                        disabled={currentPage === 1}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(1)}
                      >
                        <Icon name="angle double left" />
                      </Menu.Item>
                      <Menu.Item
                        disabled={currentPage === 1}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item as="a">{currentPage}</Menu.Item>
                      <Menu.Item
                        disabled={
                          currentPage === lastPage || bondInsurancePolicies.length < 10
                        }
                        as="a"
                        icon
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                      <Menu.Item as="a" icon>
                        <Icon
                          disabled={
                            currentPage === lastPage || bondInsurancePolicies.length < 10
                          }
                          name="angle double right"
                          onClick={() => setCurrentPage(lastPage)}
                        />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </TableWrapper>
        ) : (
          <NotFoundWrapper>
            <p>Apólices não encontradas</p>
          </NotFoundWrapper>
        )}
      </Dimmer.Dimmable>
    </PoliciesTableComponent>
  );
};

export default connect(mapState, mapDispatch)(BondInsurancePoliciesTable);
