import * as Yup from "yup";
import {REQUIRED_FIELD_MESSAGE} from "../constants";

export const propertyInputSectionYupSchema: Yup.ObjectSchema<PropertyInputSectionSchema> = Yup.object().shape({
  zipCode: Yup.string().isValidMaskedCEP(),
  street: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  district: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  city: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  state: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  number: Yup.string().required(REQUIRED_FIELD_MESSAGE)
})

export type PropertyInputSectionSchema = {
  zipCode: string;
  street: string;
  district: string;
  city: string;
  state: string;
  number: string;
  complement?: string;
  externalCode?: string;
}
