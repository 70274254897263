import React, { useState } from 'react';
import { Grid, Modal, Divider, Icon, Segment, Button } from 'semantic-ui-react';
import { FieldLabel, FieldValue } from './Table';
import styled from 'styled-components';
import { translateStatusCapitalization } from '../util';

import { maskCpfOrCnpj, maskPhoneNumber } from '../../../services/masks';
import moment from 'moment-timezone';
import EventsTimeline from '../../../components/EventsTimeline';
import { ListCapitalizationInterface } from '../ListCapitalizationInitial.interface';
import { CapitalizationPlanOptions } from './create-capitalization-modal/capitalization-options';
import { PurposesEnum } from '../../../enums/purposes.enum';
import { PoliticallyExposedPersonEnum } from '../../../enums/politically-exposed-person.enum';
import { incomeBracketsOptions } from '../types/incomeBracketsOptions';

// import { Container } from './styles';
interface DetailModal {
  capitalization: ListCapitalizationInterface;
  open: boolean;
  close: () => void;
}

const LabelColumn = styled(Grid.Column)`
  padding: 0em 0em !important;
`;

const LabelText = styled.p`
  font-weight: 500;
  color: #364445;
  font-size: 20px;
  line-height: 5px;
  margin-left: 5px;
`;

interface SectionLabel {
  text: string;
}

const SectionLabel = (props: SectionLabel) => {
  return (
    <Grid.Row
      style={{
        paddingTop: '0',
        paddingBottom: '0',
        marginTop: '2em'
      }}
    >
      <LabelColumn>
        <LabelText>{props.text}</LabelText>
        <Divider />
      </LabelColumn>
    </Grid.Row>
  );
};

const DetailModal = (props: DetailModal) => {
  const { capitalization, open, close } = props;
  const isPF = capitalization?.capitalizationTenant?.document?.length <= 14;
  const { id, createdAt, updatedAt, estate, insurer, status, capitalizationProperty } =
    capitalization;
  const address = `${capitalizationProperty?.street}${
    capitalizationProperty?.number ? `, ${capitalizationProperty?.number}` : ''
  }${
    capitalizationProperty?.complement ? ` - ${capitalizationProperty?.complement}` : ''
  }`;
  const [isEventsTimelineOpen, setIsEventsTimelineOpen] = useState<boolean>(false);

  const toggleEventsTimeline = () => {
    setIsEventsTimelineOpen(!isEventsTimelineOpen);
  };

  return (
    <>
      <Modal open={open} onClose={close}>
        <Modal.Header
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <span>Detalhes da Capitalização</span>
          <Button
            icon
            circular
            onClick={toggleEventsTimeline}
            style={{ float: 'right', cursor: 'pointer' }}
          >
            <Icon name="calendar alternate outline" />
          </Button>
        </Modal.Header>
        <Modal.Content>
          <Grid style={{ padding: 5 }}>
            <Grid.Row>
              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>ID do Registro</FieldLabel>
                <FieldValue>{id}</FieldValue>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>Data de Solicitação</FieldLabel>
                <FieldValue>{moment(createdAt).format('DD/MM/YYYY HH:mm:ss')}</FieldValue>
              </Grid.Column>
              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>Última atualização</FieldLabel>
                <FieldValue>
                  {updatedAt ? (
                    moment(updatedAt).format('DD/MM/YYYY HH:mm:ss')
                  ) : (
                    <span style={{ color: '#ccc' }}>Não houve atualização</span>
                  )}
                </FieldValue>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>Criado Por</FieldLabel>
                <FieldValue>{capitalization.user?.name}</FieldValue>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>Imobiliária</FieldLabel>
                <FieldValue>{estate?.name}</FieldValue>
              </Grid.Column>
              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>Seguradora</FieldLabel>
                <FieldValue>
                  {insurer ? (
                    insurer?.name
                  ) : (
                    <span style={{ color: '#ccc' }}>Não atribuído</span>
                  )}
                </FieldValue>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>Status</FieldLabel>
                <FieldValue>{translateStatusCapitalization(status)}</FieldValue>
              </Grid.Column>
            </Grid.Row>

            <SectionLabel text="Informações do Proprietário" />
            <Grid.Row>
              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>Documento</FieldLabel>
                <FieldValue>{maskCpfOrCnpj(capitalization.lessorDocument)}</FieldValue>
              </Grid.Column>

              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>Nome</FieldLabel>
                <FieldValue>{capitalization.lessorName}</FieldValue>
              </Grid.Column>
            </Grid.Row>

            <SectionLabel text="Dados do Imóvel" />
            <Grid.Row>
              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>Endereço</FieldLabel>
                <FieldValue>{address}</FieldValue>
              </Grid.Column>
              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>Bairro</FieldLabel>
                <FieldValue>{capitalizationProperty?.district}</FieldValue>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>Cidade</FieldLabel>
                <FieldValue>{capitalizationProperty?.city}</FieldValue>
              </Grid.Column>
              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>Estado</FieldLabel>
                <FieldValue>{capitalizationProperty?.state}</FieldValue>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>CEP</FieldLabel>
                <FieldValue>{capitalizationProperty?.zipCode}</FieldValue>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>Tipo do Imóvel</FieldLabel>
                <FieldValue>{capitalizationProperty?.propertyType}</FieldValue>
              </Grid.Column>

              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>Tipo da Locação</FieldLabel>
                <FieldValue>
                  {capitalization.locationType === PurposesEnum.residential
                    ? 'RESIDENCIAL'
                    : 'COMERCIAL'}
                </FieldValue>
              </Grid.Column>
            </Grid.Row>

            <SectionLabel text="Dados do Inquilino" />
            <Grid.Row>
              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>
                  {isPF ? 'Nome' : 'Razão Social'}
                </FieldLabel>
                <FieldValue>{capitalization.capitalizationTenant?.name}</FieldValue>
              </Grid.Column>
              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>Documento</FieldLabel>
                <FieldValue>
                  {maskCpfOrCnpj(capitalization.capitalizationTenant?.document)}
                </FieldValue>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>Telefone</FieldLabel>
                <FieldValue>
                  {capitalization.capitalizationTenant?.cellphone ? (
                    maskPhoneNumber(capitalization.capitalizationTenant?.cellphone)
                  ) : (
                    <span style={{ color: '#ccc' }}>Não informado</span>
                  )}
                </FieldValue>
              </Grid.Column>
              <Grid.Column width={8}>
                <FieldLabel style={{ marginLeft: '5px' }}>Email</FieldLabel>
                <FieldValue>{capitalization.capitalizationTenant?.email}</FieldValue>
              </Grid.Column>
            </Grid.Row>
            {isPF ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <FieldLabel style={{ marginLeft: '5px' }}>Gênero</FieldLabel>
                    <FieldValue>{capitalization.capitalizationTenant?.gender}</FieldValue>
                  </Grid.Column>

                  <Grid.Column width={5}>
                    <FieldLabel style={{ marginLeft: '5px' }}>Estado Civil</FieldLabel>
                    <FieldValue>
                      {capitalization.capitalizationTenant?.maritalStatus}
                    </FieldValue>
                  </Grid.Column>

                  <Grid.Column width={6}>
                    <FieldLabel style={{ marginLeft: '5px' }}>
                      Data de Nascimento
                    </FieldLabel>
                    <FieldValue>
                      {capitalization.capitalizationTenant?.birthDate ? (
                        moment(capitalization.capitalizationTenant?.birthDate).format(
                          'DD/MM/YYYY'
                        )
                      ) : (
                        <span style={{ color: '#ccc' }}>Não houve atualização</span>
                      )}
                    </FieldValue>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>Nome da Mãe</FieldLabel>
                    <FieldValue>
                      {capitalization.capitalizationTenant?.motherName}
                    </FieldValue>
                  </Grid.Column>

                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>Nacionalidade</FieldLabel>
                    <FieldValue>
                      {capitalization.capitalizationTenant?.nationality}
                    </FieldValue>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>
                      Pessoa Politicamente exposta?
                    </FieldLabel>
                    <FieldValue>
                      {capitalization.capitalizationTenant?.politicallyExposedPerson ===
                      PoliticallyExposedPersonEnum.yes
                        ? 'Sim'
                        : 'Não'}
                    </FieldValue>
                  </Grid.Column>

                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>Profissão</FieldLabel>
                    <FieldValue>
                      {capitalization.capitalizationTenant?.profession}
                    </FieldValue>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={4}>
                    <FieldLabel style={{ marginLeft: '5px' }}>Identidade</FieldLabel>
                    <FieldValue>
                      {capitalization.capitalizationTenant?.identityDocument}
                    </FieldValue>
                  </Grid.Column>

                  <Grid.Column width={4}>
                    <FieldLabel style={{ marginLeft: '5px' }}>Orgão Emissor</FieldLabel>
                    <FieldValue>
                      {capitalization.capitalizationTenant?.identityDocumentIssuance}
                    </FieldValue>
                  </Grid.Column>

                  <Grid.Column width={2}>
                    <FieldLabel style={{ marginLeft: '5px' }}>UF</FieldLabel>
                    <FieldValue>
                      {capitalization.capitalizationTenant?.identityDocumentUf}
                    </FieldValue>
                  </Grid.Column>

                  <Grid.Column width={4}>
                    <FieldLabel style={{ marginLeft: '5px' }}>Data Emissão</FieldLabel>
                    <FieldValue>
                      {capitalization.capitalizationTenant?.identityDocumentDate ? (
                        moment(
                          capitalization.capitalizationTenant?.identityDocumentDate
                        ).format('DD/MM/YYYY')
                      ) : (
                        <span style={{ color: '#ccc' }}>Data Não Informada</span>
                      )}
                    </FieldValue>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              <>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>
                      Nome do Representante
                    </FieldLabel>
                    <FieldValue>
                      {capitalization.capitalizationTenant?.companyRepresentativeName}
                    </FieldValue>
                  </Grid.Column>

                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>
                      Documento do Representante
                    </FieldLabel>
                    <FieldValue>
                      {maskCpfOrCnpj(
                        capitalization.capitalizationTenant?.companyRepresentativeDocument
                      )}
                    </FieldValue>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>
                      Email do Representante
                    </FieldLabel>
                    <FieldValue>
                      {capitalization.capitalizationTenant
                        ?.companyRepresentativeEmail ?? (
                        <span style={{ color: '#ccc' }}>Não informado</span>
                      )}
                    </FieldValue>
                  </Grid.Column>

                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>
                      Telefone do Representante
                    </FieldLabel>
                    <FieldValue>
                      {capitalization.capitalizationTenant
                        ?.companyRepresentativeCellphone ? (
                        maskPhoneNumber(
                          capitalization.capitalizationTenant
                            ?.companyRepresentativeCellphone
                        )
                      ) : (
                        <span style={{ color: '#ccc' }}>Não informado</span>
                      )}
                    </FieldValue>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>
                      Data de Constituição
                    </FieldLabel>
                    <FieldValue>
                      {capitalization?.capitalizationTenant?.constitutionDate ? (
                        moment(
                          capitalization?.capitalizationTenant?.constitutionDate
                        ).format('DD/MM/YYYY')
                      ) : (
                        <span style={{ color: '#ccc' }}>Não informado</span>
                      )}
                    </FieldValue>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>
                      Faturamento Anual
                    </FieldLabel>
                    <FieldValue>
                      {capitalization.capitalizationTenant?.companyAnnualRevenue ? (
                        Number(
                          Number(
                            capitalization.capitalizationTenant?.companyAnnualRevenue
                          ).toFixed(2)
                        ).toLocaleString('pt-BR', {
                          currency: 'BRL',
                          style: 'currency'
                        })
                      ) : (
                        <span style={{ color: '#ccc' }}>Não informado</span>
                      )}
                    </FieldValue>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>
                      Inscrição Municipal
                    </FieldLabel>
                    <FieldValue>
                      {capitalization.capitalizationTenant?.municipalRegistration ?? (
                        <span style={{ color: '#ccc' }}>Não informado</span>
                      )}
                    </FieldValue>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <FieldLabel style={{ marginLeft: '5px' }}>
                      Inscrição Estadual
                    </FieldLabel>
                    <FieldValue>
                      {capitalization.capitalizationTenant?.stateRegistration ?? (
                        <span style={{ color: '#ccc' }}>Não informado</span>
                      )}
                    </FieldValue>
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
            <Grid.Row>
              <Grid.Column width={16}>
                <FieldLabel style={{ marginLeft: '5px' }}>
                  Faixa de Renda Mensal
                </FieldLabel>
                <FieldValue>
                  {capitalization?.capitalizationTenant?.incomeBracket
                    ? incomeBracketsOptions.find(
                        item =>
                          item.value ===
                          `${capitalization?.capitalizationTenant?.incomeBracket}`
                      )?.text
                    : ''}
                </FieldValue>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column key={id!} width={16}>
                <Segment style={{ backgroundColor: '#fafafa', borderWidth: 4 }}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <FieldLabel style={{ marginLeft: '5px' }}>
                          Valor do aluguel
                        </FieldLabel>
                        <FieldValue>
                          {Number(
                            Number(capitalization.rentalValue).toFixed(2)
                          ).toLocaleString('pt-BR', {
                            currency: 'BRL',
                            style: 'currency'
                          })}
                        </FieldValue>
                      </Grid.Column>

                      <Grid.Column width={8}>
                        <FieldLabel style={{ marginLeft: '5px' }}>
                          Valor do condomínio
                        </FieldLabel>
                        <FieldValue>
                          {Number(
                            Number(capitalization.condominiumFee).toFixed(2)
                          ).toLocaleString('pt-BR', {
                            currency: 'BRL',
                            style: 'currency'
                          })}
                        </FieldValue>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={8}>
                        <FieldLabel style={{ marginLeft: '5px' }}>
                          Valor do IPTU mensal
                        </FieldLabel>
                        <FieldValue>
                          {Number(
                            Number(capitalization.taxesValue).toFixed(2)
                          ).toLocaleString('pt-BR', {
                            currency: 'BRL',
                            style: 'currency'
                          })}
                        </FieldValue>
                      </Grid.Column>

                      <Grid.Column width={8}>
                        <FieldLabel style={{ marginLeft: '5px' }}>
                          Valor da energia
                        </FieldLabel>
                        <FieldValue>
                          {Number(
                            Number(capitalization.energyBill).toFixed(2)
                          ).toLocaleString('pt-BR', {
                            currency: 'BRL',
                            style: 'currency'
                          })}
                        </FieldValue>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={8}>
                        <FieldLabel style={{ marginLeft: '5px' }}>
                          Valor da água
                        </FieldLabel>
                        <FieldValue>
                          {Number(
                            Number(capitalization.waterBill).toFixed(2)
                          ).toLocaleString('pt-BR', {
                            currency: 'BRL',
                            style: 'currency'
                          })}
                        </FieldValue>
                      </Grid.Column>

                      <Grid.Column width={8}>
                        <FieldLabel style={{ marginLeft: '5px' }}>
                          Valor do gás canalizado
                        </FieldLabel>
                        <FieldValue>
                          {Number(
                            Number(capitalization.gasBill).toFixed(2)
                          ).toLocaleString('pt-BR', {
                            currency: 'BRL',
                            style: 'currency'
                          })}
                        </FieldValue>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <FieldLabel style={{ marginLeft: '5px' }}>
                          Multiplicador
                        </FieldLabel>
                        <FieldValue>{capitalization.multiplier}</FieldValue>
                      </Grid.Column>

                      <Grid.Column width={8}>
                        <FieldLabel style={{ marginLeft: '5px' }}>
                          Valor do título
                        </FieldLabel>
                        <FieldValue>
                          {Number(
                            Number(capitalization.totalValue).toFixed(2)
                          ).toLocaleString('pt-BR', {
                            currency: 'BRL',
                            style: 'currency'
                          })}
                        </FieldValue>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={8}>
                        <FieldLabel style={{ marginLeft: '5px' }}>
                          Plano Selecionado
                        </FieldLabel>
                        <FieldValue>
                          {CapitalizationPlanOptions.find(
                            c => c.value === capitalization.plan
                          )?.text || 'Não selecionado'}
                        </FieldValue>
                      </Grid.Column>

                      <Grid.Column width={8}>
                        <FieldLabel style={{ marginLeft: '5px' }}>
                          Seguradora Emissora
                        </FieldLabel>
                        <FieldValue>
                          {capitalization.insurer?.name || 'Indefinido'}
                        </FieldValue>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <FieldLabel style={{ marginLeft: '5px' }}>
                          Data de Início da vigência
                        </FieldLabel>
                        <FieldValue>
                          {capitalization.startVigency ? (
                            moment(capitalization.startVigency).format('DD/MM/YYYY')
                          ) : (
                            <span style={{ color: '#ccc' }}>Não informado</span>
                          )}
                        </FieldValue>
                      </Grid.Column>

                      <Grid.Column width={8}>
                        <FieldLabel style={{ marginLeft: '5px' }}>
                          Data de Fim da vigência
                        </FieldLabel>
                        <FieldValue>
                          {capitalization.endVigency ? (
                            moment(capitalization.endVigency).format('DD/MM/YYYY')
                          ) : (
                            <span style={{ color: '#ccc' }}>Não informado</span>
                          )}
                        </FieldValue>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
      <EventsTimeline
        open={isEventsTimelineOpen}
        setOpen={setIsEventsTimelineOpen}
        relatedId={id!}
      />
    </>
  );
};

export default DetailModal;
