import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Radio,
  Grid,
  Input,
  Dropdown as DropdownNativo,
  Popup,
  Icon,
  Button
} from 'semantic-ui-react';
import { Field, FormikProps } from 'formik';
import SectionLabel from '../../../../../../components/SectionLabel';
import { FieldLabel } from '../../../../../../styles';
import { CreateClaimEstateInitialInitialValuesType } from '../../Formik/types/CreateClaimEstateInitialValuesTypes';
import { InsurerState } from '../../../../../../types/InsurerState';
import { CustoDropDownItemProps } from '../../../../../../components/types';
import CustomDatePicker from '../../../../../../components/CustomDatePicker';
import {
  DATE_INPUT_MASK,
  maskCpfOrCnpj,
  maskPhoneNumber,
  maskZipCode
} from '../../../../../../services/masks';
import { AddressService } from '../../../../../../services/address';
import FormatedTextArea from '../../../../../../components/FormatedTextArea';
import { ClaimPropertyStatusEnum } from '../../../../../../enums/claim-property-status.enum';
import { getDataByDocument } from '../../../../../../services/documents';
import AppMaskedInput from '../../../../../../components/MaskedInput';

type ClaimEstatesProps = {
  estates: CustoDropDownItemProps[] | null;
  insurers: InsurerState[] | null;
  policy?: any;
};

interface DropdownOptions {
  index: number;
  value: number | string;
  text: string;
}

const ClaimEstateInfoStep = ({
  values,
  insurers,
  estates,
  setFieldValue,
  policy
}: FormikProps<CreateClaimEstateInitialInitialValuesType> & ClaimEstatesProps) => {
  const [loadingZipCode, setLoadingZipCode] = useState(false);
  const [selectableInsurers, setSelectableInsurers] = useState<DropdownOptions[] | any[]>(
    []
  );
  const [selectableEstates, setSelectableEstates] = useState<DropdownOptions[] | any[]>(
    []
  );
  const [loadDocument, setLoadDocument] = useState(false);
  const [disableNameField, setDisabledNameField] = useState<boolean>(true);

  useEffect(() => {
    if (policy) {
      // Tenant data
      if (policy?.bondInsurance?.naturalTenant) {
        setFieldValue(
          'claimTenant.cellphone',
          maskPhoneNumber(policy?.bondInsurance?.naturalTenant?.main_tenant_phone)
        );
        setFieldValue(
          'claimTenant.fullName',
          policy?.bondInsurance?.naturalTenant?.main_tenant_name
        );
        setFieldValue(
          'claimTenant.email',
          policy?.bondInsurance?.naturalTenant?.main_tenant_email
        );
        setFieldValue(
          'claimTenant.document',
          maskCpfOrCnpj(policy?.bondInsurance?.naturalTenant?.main_tenant_cpf)
        );
      } else {
        setFieldValue(
          'claimTenant.cellphone',
          maskPhoneNumber(policy?.bondInsurance?.legalTenant?.phone)
        );
        setFieldValue(
          'claimTenant.fullName',
          policy?.bondInsurance?.legalTenant?.company
        );
        setFieldValue('claimTenant.email', policy?.bondInsurance?.legalTenant?.email);
        setFieldValue(
          'claimTenant.document',
          maskCpfOrCnpj(policy?.bondInsurance?.legalTenant?.document)
        );
      }
      // Estate data
      if (policy?.estate?.id) {
        setFieldValue('claimEstate.estateId', policy?.estate?.id);
      }
      // Insurer Data
      if (policy?.insurer?.id) {
        setFieldValue('claimEstate.insurerId', policy?.insurer?.id);
      }
      setFieldValue('claimEstate.policyNumber', policy?.insurerPolicyId);
      setFieldValue(
        'claimEstate.policyId',
        policy?.bondInsurance?.bondInsuranceHiredDataId
      );
      // Property
      setFieldValue('claimProperty.zipCode', maskZipCode(policy?.property?.zip_code));
      setFieldValue('claimProperty.city', policy?.property?.city);
      setFieldValue('claimProperty.complement', policy?.property?.complement);
      setFieldValue('claimProperty.country', policy?.property?.country);
      setFieldValue('claimProperty.district', policy?.property?.district);
      setFieldValue('claimProperty.number', policy?.property?.number);
      setFieldValue('claimProperty.state', policy?.property?.state);
      setFieldValue('claimProperty.street', policy?.property?.street);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy, selectableEstates, selectableInsurers, setFieldValue]);

  useEffect(() => {
    if (insurers && insurers.length) {
      setSelectableInsurers(
        insurers.map(insurer => ({
          index: insurer.id,
          value: insurer.id,
          text: insurer.name
        }))
      );
    }
  }, [insurers]);

  useEffect(() => {
    if (estates && estates.length) {
      setSelectableEstates(
        estates.map(estate => ({
          index: estate.value.id,
          value: estate.value.id,
          text: estate.value.name
        }))
      );

      if (estates.length === 1) {
        setFieldValue('claimEstate.estateId', estates[0].value.id);
      }
    }
  }, [estates]);

  useEffect(() => {
    if (values.claimProperty.zipCode) {
    }
    if (values.claimProperty.zipCode && values.claimProperty.zipCode.length >= 8) {
      onChangeCep(values.claimProperty.zipCode);
    }
  }, [values.claimProperty.zipCode]);

  async function getTenantByDocument(document: string) {
    try {
      setLoadDocument(true);
      const unformatedDoc = document.replace(/\D/g, '');
      const isCPF = unformatedDoc.length === 11;
      const data = await getDataByDocument(unformatedDoc);
      if (isCPF) {
        setFieldValue('claimTenant.fullName', data.name);
      } else {
        setFieldValue('claimTenant.fullName', data.company);
        setFieldValue('claimTenant.cellphone', data.phone);
        setFieldValue('claimTenant.email', data.email);
        setDisabledNameField(false);
      }
    } catch (err) {
      console.log(err);
      setDisabledNameField(false);
    } finally {
      setLoadDocument(false);
    }
  }

  const onChangeCep = (zipCode: string): void => {
    const maskedValue = maskZipCode(zipCode);

    setFieldValue('claimProperty.zipCode', maskedValue);
    if (maskedValue.length >= 9) {
      setLoadingZipCode(true);

      AddressService.getAddressByZipCode(zipCode)
        .then(address => {
          setFieldValue('claimProperty.city', address.city);
          setFieldValue('claimProperty.district', address.district);
          setFieldValue('claimProperty.state', address.state);
          setFieldValue('claimProperty.street', address.street);
          setLoadingZipCode(false);
        })
        .catch(e => {
          console.log('address error:', e);
        });
    }
  };

  return (
    <div>
      <SectionLabel text="Informações do locatário" />

      <Grid columns={16}>
        <Grid.Row>
          <Grid.Column width={4}>
            <FieldLabel>CPF/CNPJ do Locatário*</FieldLabel>
            <Field
              value={values.claimTenant.document}
              component={Input}
              loading={loadDocument}
              fluid
              onChange={(_e: any, { value }: { value: string }) => {
                setFieldValue('claimTenant.document', maskCpfOrCnpj(value));
                setDisabledNameField(true);
              }}
              onBlur={(e: any) => {
                getTenantByDocument(e.target.value);
              }}
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Nome do Locatário*</FieldLabel>
            <Field
              value={values.claimTenant.fullName}
              component={Input}
              fluid
              disabled={disableNameField}
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimTenant.fullName', value)
              }
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Email do Locatário*</FieldLabel>
            <Field
              value={values.claimTenant.email}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimTenant.email', value)
              }
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Telefone do Locatário*</FieldLabel>
            <Field
              value={values.claimTenant.cellphone}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimTenant.cellphone', maskPhoneNumber(value))
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <SectionLabel text="Informações do Seguro" />
      <Grid>
        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>
              SEGURADORA / GARANTIDORA:
              <Popup
                content={
                  'Em caso de dúvidas, verifique a cláusula da garantia no contrato de locação.'
                }
                position={'bottom right'}
                trigger={
                  <Icon
                    name={'info circle'}
                    color={'blue'}
                    style={{ cursor: 'help', marginLeft: 5 }}
                  />
                }
              />
            </FieldLabel>
            <div style={{ width: '100%' }}>
              <DropdownNativo
                style={{ width: '100%' }}
                name={`claimEstate.insurerId`}
                value={values?.claimEstate?.insurerId}
                options={selectableInsurers || []}
                placeholder={'Selecionar...'}
                search
                selection
                clearable
                onChange={(e, { value }) => setFieldValue('claimEstate.insurerId', value)}
              />
            </div>
          </Grid.Column>

          {selectableEstates.length > 1 && (
            <Grid.Column width={5}>
              <FieldLabel>IMOBILIÁRIA*:</FieldLabel>
              <div style={{ width: '100%' }}>
                <DropdownNativo
                  style={{ width: '100%' }}
                  name={`claimEstate.estateId`}
                  value={values?.claimEstate?.estateId}
                  options={selectableEstates || []}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  clearable
                  onChange={(e, { value }) =>
                    setFieldValue('claimEstate.estateId', value)
                  }
                />
              </div>
            </Grid.Column>
          )}

          <Grid.Column width={3}>
            <div style={{ paddingTop: '1rem' }}>
              <Radio
                label="Imóvel Ocupado"
                name="propertyStatus"
                value={ClaimPropertyStatusEnum.occupied}
                checked={values.claimProperty.status === ClaimPropertyStatusEnum.occupied}
                onChange={() =>
                  setFieldValue('claimProperty.status', ClaimPropertyStatusEnum.occupied)
                }
              />
              <Radio
                label="Imóvel Desocupado"
                name="propertyStatus"
                value={ClaimPropertyStatusEnum.vacant}
                checked={values.claimProperty.status === ClaimPropertyStatusEnum.vacant}
                onChange={() =>
                  setFieldValue('claimProperty.status', ClaimPropertyStatusEnum.vacant)
                }
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <FieldLabel>Número da Apólice</FieldLabel>
            <Field
              value={values.claimEstate.policyNumber}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimEstate.policyNumber', value)
              }
            />
          </Grid.Column>

          {values.claimProperty.status === ClaimPropertyStatusEnum.vacant && (
            <>
              <Grid.Column width={4}>
                <FieldLabel>DATA DE ENTREGA DAS CHAVES* </FieldLabel>
                <Field
                  name={`claimProperty.deliveryKeysDate`}
                  selected={values.claimProperty.deliveryKeysDate}
                  component={CustomDatePicker}
                  customInput={
                    <AppMaskedInput
                      mask={DATE_INPUT_MASK}
                    />
                  }
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(date: Date, _e: SyntheticEvent) => {
                    setFieldValue('claimProperty.deliveryKeysDate', date);
                  }}
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>Será reclamado danos ao imóvel ou a pintura?</FieldLabel>
                <div style={{ paddingTop: '1rem' }}>
                  <Radio
                    style={{ paddingRight: '1rem' }}
                    label="Sim"
                    name="damageControl"
                    checked={values.damageControl}
                    onChange={() => setFieldValue('damageControl', true)}
                  />
                  <Radio
                    label="Não"
                    name="damageControl"
                    checked={!values.damageControl}
                    onChange={() => setFieldValue('damageControl', false)}
                  />
                </div>
              </Grid.Column>
            </>
          )}
        </Grid.Row>
      </Grid>

      <SectionLabel text="Endereço do imóvel" />
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column width={3}>
            <FieldLabel>CEP*</FieldLabel>
            <Field
              name="claimProperty.zipCode"
              value={values.claimProperty.zipCode}
              loading={loadingZipCode}
              component={Input}
              maxLength={9}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimProperty.zipCode', maskZipCode(value))
              }
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <FieldLabel>Logradouro*</FieldLabel>
            <Field
              name="claimProperty.street"
              value={values.claimProperty.street}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimProperty.street', value)
              }
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <FieldLabel>Bairro*</FieldLabel>
            <Field
              name="claimProperty.district"
              value={values.claimProperty.district}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimProperty.district', value)
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>Cidade*</FieldLabel>
            <Field
              name="city"
              value={values.claimProperty.city}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimProperty.city', value)
              }
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <FieldLabel>UF*</FieldLabel>
            <Field
              name="values.claimProperty.state"
              value={values.claimProperty.state}
              component={Input}
              fluid
              maxLength={2}
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimProperty.state', value)
              }
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <FieldLabel>Número*</FieldLabel>
            <Field
              name="values.claimProperty.number"
              value={values.claimProperty.number}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimProperty.number', value)
              }
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Complemento</FieldLabel>
            <Field
              name="values.claimProperty.complement"
              value={values.claimProperty.complement}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('claimProperty.complement', value)
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <SectionLabel text="Informe os e-mails para receberem notificações do sinistro:" />
      <Grid columns="16" style={{ paddingLeft: '1em' }}>
        {(values.claimEstate?.notificationEmails as string[]).map(
          (notificationEmail, index) => {
            return (
              <Grid.Row>
                <Grid.Column width={4}>
                  <Field
                    value={notificationEmail}
                    component={Input}
                    fluid
                    onChange={(e: any, { value }: { value: string }) => {
                      setFieldValue(`claimEstate.notificationEmails[${index}]`, value);
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={1}>
                  <Icon
                    name={'remove circle'}
                    color={'grey'}
                    style={{ cursor: 'pointer', paddingTop: '5px' }}
                    size={'large'}
                    onClick={() => {
                      if (values.claimEstate.notificationEmails?.length === 1) {
                        setFieldValue(`claimEstate.notificationEmails`, ['']);
                      } else {
                        const arr1 = values.claimEstate.notificationEmails?.slice(
                          0,
                          index
                        );
                        const arr2 = values.claimEstate.notificationEmails?.slice(
                          index + 1
                        );
                        const newArr = [...(arr1 as string[]), ...(arr2 as string[])];
                        setFieldValue(`claimEstate.notificationEmails`, newArr);
                      }
                    }}
                  />
                </Grid.Column>
                {index + 1 === values.claimEstate.notificationEmails?.length && (
                  <Grid.Column width={5}>
                    <Button
                      primary
                      onClick={() => {
                        if (values.claimEstate.notificationEmails?.length) {
                          const lastEmail =
                            values.claimEstate.notificationEmails[
                              values.claimEstate.notificationEmails.length - 1
                            ];
                          if (lastEmail.length > 0)
                            setFieldValue(`claimEstate.notificationEmails`, [
                              ...(values.claimEstate.notificationEmails as string[]),
                              ''
                            ]);
                        } else {
                          setFieldValue(
                            `claimEstate.notificationEmails[${index + 1}]`,
                            ''
                          );
                        }
                      }}
                    >
                      <Icon name={'plus circle'} />
                      Adicionar E-mail
                    </Button>
                  </Grid.Column>
                )}
              </Grid.Row>
            );
          }
        )}
      </Grid>
      <SectionLabel text="Observações" />
      <Grid columns="16" style={{ paddingLeft: '1em' }}>
        <Grid.Row>
          <Grid.Column width={16}>
            <Field
              name={`claimEstate.observations`}
              rows={3}
              value={values.claimEstate.observations}
              onChange={(_: SyntheticEvent, data: any) => {
                setFieldValue(`claimEstate.observations`, data.value);
              }}
              component={FormatedTextArea}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default ClaimEstateInfoStep;
