import { get, patch, post, remove } from './request';
import { EstatePartner } from '../types/EstatePartner';

export const getByFilters = async (
  page: number,
  filter?: any
): Promise<EstatePartner[]> => {
  let response;
  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  try {
    response = await get(`/partners?${query}&page=${page}`);
  } catch (e) {
    throw e;
  }

  return response;
};

export const getByID = async (id: string): Promise<EstatePartner> => {
  let response;
  try {
    response = await get(`/partners/${id}`);
  } catch (e) {
    throw e;
  }

  return response;
};

export const create = async (partner: EstatePartner): Promise<EstatePartner> => {
  let response;
  try {
    response = await post('/partners', partner);
  } catch (e) {
    throw e;
  }

  return response;
};

export const update = async (
  id: string,
  partner: EstatePartner
): Promise<EstatePartner> => {
  let response;
  try {
    response = await patch(`/partners/${id}`, partner);
  } catch (e) {
    throw e;
  }

  return response;
};

export const deleteByID = async (id: string): Promise<EstatePartner> => {
  let response;
  try {
    response = await remove(`/partners/${id}`);
  } catch (e) {
    throw e;
  }

  return response;
};
