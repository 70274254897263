import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import ProposalsTable from './ProposalsTable';
import Filter from './Filter';
import { connect } from 'react-redux';
import { iRootState } from '../../store';
import { FilterData } from './types';
import { FormikProps } from 'formik';

const ListWrapper = styled.div`
  display: flex;
  flex-direction:column;
  padding: 1em;
`;

const mapState = (state: iRootState) => ({
  proposalsData: state.fireInsuranceProposalsList
});

export type Props = ReturnType<typeof mapState> & RouteComponentProps & FormikProps<FilterData>;

const Proposals = (props: Props) => {

  return (
    <>
      <ListWrapper>
        <Filter {...props}/>
        <ProposalsTable {...props} />
      </ListWrapper >
    </>
  );
}

export default connect(mapState, null)(Proposals);
