import styled from "styled-components";

export const SpecificInfosWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const SmallData = styled.div`
  width: 125px;
  padding-right: 20px;
`
