import {
  ConnectorPropertiesForFireInsuranceQuotationState,
  FireInsuranceCoverageState,
  FireInsuranceBudgetReducer,
  FireInsuranceBudgetState,
  OwnerState,
  TenantState,
  FireInsuranceBudgetSelectedPropertyTypeState,
  FireInsuranceBudgetSelectedConnectorCoverages,
  FireInsuranceBudgetSelectedInsurerState,
  RentForFireInsuranceQuotationState,
  FireInsuranceBudgetSelectedEstateState,
  FireInsuranceBudgetSelectedBrokerState,
  FireInsuranceBudgetSelectedOccupationState,
  PropertyState,
  FireInsuranceBudgetSelectedAssistanceState, FireInsuranceQuotationFormSections
} from "../../types/FireInsurance";
import {InsuranceConnectorCoverageState} from "../../types/PropertyTypeState";
import {EMPTY_DATE} from "../../util";

type FireInsuranceBudgetRematchModel = {
  state: FireInsuranceBudgetState;
  reducers: FireInsuranceBudgetReducer;
}

const initialState: FireInsuranceBudgetState = {
  rent: {
    value: 0,
    touched: false
  },
  property: {
    zipCode: '',
    street: '',
    district: '',
    city: '',
    state: '',
    number: '',
    complement: '',
    externalCode: ''
  },
  tenant: {
    document: '',
    name: '',
    email: '',
    telephone: '',
    birthDate: undefined
  },
  owner: {
    document: '',
    name: '',
    email: '',
    telephone: ''
  },
  coverage: [{
    damageAmount: '',
    rent: ''
  } as FireInsuranceCoverageState],
  selected: {
    broker: {
      id: '',
      name: ''
    },
    estate: {
      id: '',
      name: '',
      multiplier: 0
    },
    insurer: {
      id: '',
      code: '',
      isConnectorEnabled: false
    },
    propertyType: {
      id: '',
      description: '',
      fromConnectorTable: false
    },
    occupation: {
      id: '',
      description: '',
      fromConnectorTable: false
    },
    assistance: {
      id: '',
      description: '',
      fromConnectorTable: false
    },
    connectorCoverages: []
  },
  connectorIdData: {
    quotationHeadId: '',
    fluidResponseId: ''
  },
  isFormValidSections: {
    insuranceDataSection: false,
    coveragesSection: false,
    propertyInputSection: false,
    tenantInputSection: false,
    ownerInputSection: false
  },
  toggleToResetFormSections: {
    insuranceDataSection: false,
    coveragesSection: false,
    propertyInputSection: false,
    tenantInputSection: false,
    ownerInputSection: false
  }
}

const fireInsuranceBudget: FireInsuranceBudgetRematchModel = {
  state: initialState,
  reducers: {
    updateBudget: (
      state: FireInsuranceBudgetState,
      payload: FireInsuranceBudgetState
    ): FireInsuranceBudgetState => {
      return payload;
    },
    updateRent: (
      state: FireInsuranceBudgetState,
      payload: Partial<RentForFireInsuranceQuotationState>
    ): FireInsuranceBudgetState => {
      return {
        ...state,
        rent: {
          ...state.rent,
          ...payload
        }
      }
    },
    updateConnectorData: (
      state: FireInsuranceBudgetState,
      payload: ConnectorPropertiesForFireInsuranceQuotationState
    ): FireInsuranceBudgetState => {
      return {
        ...state,
        connectorIdData: {
          ...state.connectorIdData,
          ...payload
        }
      }
    },
    updatePropertyInputData: (
      state: FireInsuranceBudgetState,
      payload: Partial<PropertyState>
    ): FireInsuranceBudgetState => {
      return {
        ...state,
        property: {
          ...state.property,
          ...payload
        }
      }
    },
    updateTenantInputData: (
      state: FireInsuranceBudgetState,
      payload: Partial<TenantState>
    ): FireInsuranceBudgetState => {
      return {
        ...state,
        tenant: {
          ...state.tenant,
          ...payload
        }
      }
    },
    updateOwnerInputData: (
      state: FireInsuranceBudgetState,
      payload: Partial<OwnerState>
    ): FireInsuranceBudgetState => {
      return {
        ...state,
        owner: {
          ...state.owner,
          ...payload
        }
      }
    },
    updateFormValidationStatus: (
      state: FireInsuranceBudgetState,
      payload: Partial<FireInsuranceQuotationFormSections>
    ): FireInsuranceBudgetState => {
      return {
        ...state,
        isFormValidSections: {
          ...state.isFormValidSections,
          ...payload
        }
      }
    },
    resetConnectorQuotationData: (
      state: FireInsuranceBudgetState
    ): FireInsuranceBudgetState => {
      return {
        ...state,
        connectorIdData: {
          quotationHeadId: '',
          fluidResponseId: ''
        },
        selected: {
          ...state.selected,
          connectorCoverages: []
        }
      }
    },
    resetSelectedConnectorCoverages: (
      state: FireInsuranceBudgetState
    ): FireInsuranceBudgetState => {
      return {
        ...state,
        selected: {
          ...state.selected,
          connectorCoverages: []
        }
      }
    },
    resetFormValuesFromBroker: (
      state: FireInsuranceBudgetState
    ): FireInsuranceBudgetState => {
      return {
        ...initialState,
        selected: {
          ...initialState.selected,
          broker: {
            ...(state.selected.broker)
          }
        }
      }
    },
    resetFormValuesFromInsurer: (
      state: FireInsuranceBudgetState
    ): FireInsuranceBudgetState => {
      return {
        ...initialState,
        selected: {
          ...initialState.selected,
          broker: {
            ...(state.selected.broker)
          },
          estate: {
            ...(state.selected.estate)
          }
        }
      }
    },
    resetPropertyFormSection: (
      state: FireInsuranceBudgetState
    ): FireInsuranceBudgetState => {
      return {
        ...state,
        property: {
          ...initialState.property
        }
      }
    },
    resetOwnerFormSection: (
      state: FireInsuranceBudgetState
    ): FireInsuranceBudgetState => {
      return {
        ...state,
        owner: {
          ...initialState.owner
        }
      }
    },
    resetTenantFormSection: (
      state: FireInsuranceBudgetState
    ): FireInsuranceBudgetState => {
      return {
        ...state,
        tenant: {
          ...initialState.tenant
        }
      }
    },
    toggleToResetFormSection: (
      state: FireInsuranceBudgetState,
      payload: Partial<FireInsuranceQuotationFormSections>
    ): FireInsuranceBudgetState => {
      const newToggle: FireInsuranceQuotationFormSections = {...state.toggleToResetFormSections};

      for (const propertyKey in payload) {
        if (payload.hasOwnProperty(propertyKey)) {
          newToggle[propertyKey as keyof typeof payload] = !newToggle[propertyKey as keyof typeof payload];
        }
      }
      return {
        ...state,
        toggleToResetFormSections: {
          ...newToggle
        }
      }
    },
    updateSelectedBroker: (
      state: FireInsuranceBudgetState,
      payload: Partial<FireInsuranceBudgetSelectedBrokerState>
    ): FireInsuranceBudgetState => {
      return {
        ...state,
        selected: {
          ...state.selected,
          broker: {
            ...(state.selected.broker),
            ...payload
          }
        }
      };
    },
    updateSelectedEstate: (
      state: FireInsuranceBudgetState,
      payload: Partial<FireInsuranceBudgetSelectedEstateState>
    ): FireInsuranceBudgetState => {
      return {
        ...state,
        selected: {
          ...state.selected,
          estate: {
            ...(state.selected.estate),
            ...payload
          }
        }
      };
    },
    updateSelectedInsurer: (
      state: FireInsuranceBudgetState,
      payload: Partial<FireInsuranceBudgetSelectedInsurerState>
    ): FireInsuranceBudgetState => {
      return {
        ...state,
        selected: {
          ...state.selected,
          insurer: {
            ...(state.selected.insurer),
            ...payload
          }
        }
      };
    },
    updateSelectedPropertyType: (
      state: FireInsuranceBudgetState,
      payload: Partial<FireInsuranceBudgetSelectedPropertyTypeState>
    ): FireInsuranceBudgetState => {
      return {
        ...state,
        selected: {
          ...state.selected,
          propertyType: {
            ...state.selected.propertyType,
            ...payload
          }
        }
      };
    },
    updateSelectedOccupation: (
      state: FireInsuranceBudgetState,
      payload: Partial<FireInsuranceBudgetSelectedOccupationState>
    ): FireInsuranceBudgetState => {
      return {
        ...state,
        selected: {
          ...state.selected,
          occupation: {
            ...state.selected.occupation,
            ...payload
          }
        }
      };
    },
    updateSelectedAssistance: (
      state: FireInsuranceBudgetState,
      payload: Partial<FireInsuranceBudgetSelectedAssistanceState>
    ): FireInsuranceBudgetState => {
      return {
        ...state,
        selected: {
          ...state.selected,
          assistance: {
            ...state.selected.assistance,
            ...payload
          }
        }
      };
    },
    addSelectedConnectorCoverage: (
      state: FireInsuranceBudgetState,
      coverageState: InsuranceConnectorCoverageState,
      insuredAmount: number
    ): FireInsuranceBudgetState => {
      const coverages = [...state.selected.connectorCoverages];
      const newCoverage: FireInsuranceBudgetSelectedConnectorCoverages = {
        id: coverageState.id,
        name: `${coverageState.description} (${coverageState.insuredFloorPlan})`,
        insuredAmount: insuredAmount
      };

      coverages.push(newCoverage);

      return {
        ...state,
        selected: {
          ...state.selected,
          connectorCoverages: coverages
        }
      }
    },
    removeSelectedConnectorCoverage: (
      state: FireInsuranceBudgetState,
      connectorCoverageId: string
    ): FireInsuranceBudgetState => {
      const coverages = [...state.selected.connectorCoverages];

      const coverageToBeRemovedIndex: number = coverages.findIndex((coverage) => {
        return coverage.id === connectorCoverageId;
      });

      if (coverageToBeRemovedIndex >= 0) {
        coverages.splice(coverageToBeRemovedIndex, 1);
      }

      return {
        ...state,
        selected: {
          ...state.selected,
          connectorCoverages: coverages
        }
      }
    },
    updateCoverageInsuredAmount: (
      state: FireInsuranceBudgetState,
      connectorCoverageId: string,
      insuredAmount: number
    ): FireInsuranceBudgetState => {
      const coverages = [...state.selected.connectorCoverages];
      const coverageToBeUpdatedIndex: number = coverages.findIndex((coverage) => {
        return coverage.id === connectorCoverageId;
      });

      if (coverageToBeUpdatedIndex >= 0) {
        coverages[coverageToBeUpdatedIndex] = {
          ...coverages[coverageToBeUpdatedIndex],
          insuredAmount
        }
      }

      return {
        ...state,
        selected: {
          ...state.selected,
          connectorCoverages: coverages
        }
      }
    }

  }
};

export default fireInsuranceBudget;
