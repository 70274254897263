import React, { useState, useCallback } from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import useClickOutside from '../../hooks/useClickOutside';
import { throttle } from 'lodash';
import {
  Content,
  ContentContainer,
  NavbarContainer,
  Pushable,
  SidebarPusher,
  SidebarWrapper
} from './styles';
import { Responsive } from 'semantic-ui-react';
import Navbar from '../../containers/Navbar';
import Menu from '../../containers/Menu';

const Sidebar = (props: any) => {
  const { isMobile } = useWindowSize();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useClickOutside<HTMLDivElement>(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const throttledHandleScroll = useCallback(
    throttle((scrollTop: number) => {
      if (scrollTop !== undefined && scrollTop !== null) {
        props.updateScroll({ posY: scrollTop });
      }
    }, 250),
    []
  );

  return (
    <Pushable>
      <SidebarWrapper
        animation={isMobile ? 'overlay' : 'push'}
        visible={!isMobile || isOpen}
      >
        <div ref={ref}>
          <Menu
            {...props}
            handleToggleSidebar={() => setIsOpen(prevValue => !prevValue)}
          />
        </div>
      </SidebarWrapper>
      <SidebarPusher>
        <Responsive fireOnMount>
          <ContentContainer
            onScroll={(event: any) => {
              event.persist();

              if (event.target && event.target.className.includes('page-content')) {
                throttledHandleScroll(event.target.scrollTop);
              }
            }}
            className={'page-content'}
          >
            {/* <Movidesk /> */}
            <NavbarContainer>
              <Navbar
                {...props}
                handleToggleSidebar={() => setIsOpen(prevValue => !prevValue)}
              />
            </NavbarContainer>
            <Content>{props.children}</Content>
          </ContentContainer>
        </Responsive>
      </SidebarPusher>
    </Pushable>
  );
};

export default Sidebar;
