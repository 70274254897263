import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { iRootDispatch, iRootState } from '../../store';
import SectionLabel from '../../components/SectionLabel';
import { Button, TextArea, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { createUser } from '../../services/user';
import { maskPhoneNumber, maskCpf } from '../../services/masks';
import { successNotification, dangerNotification } from '../../services/notification';
import { FieldLabel } from '../../styles';
import { UserState } from '../../types/UserState';
import { UserRolesEnum } from '../../enums/user-roles.enum';
import { UserStatusEnum } from '../../enums/user-status.enum';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { BrokerForGetBrokerResponseDTO } from '../../dtos/broker/broker-for-get-broker-response.dto';
import { getBroker, getBrokerObjectFromGetBrokerDTO } from '../../services/broker';
import { BrokerState } from '../../types/BrokerState';
import { BrokersToCustomDropDownOptions } from '../../util';
import { AvailableBrokersAndEstatesState } from '../../store/types/temp-types';

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

type OptionsType = {
  value?: string;
  text: string;
  key: number;
};

const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 5px;

  > textarea {
    background-color: #f5f8fa;
    width: 135%;
    border-radius: 4px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    padding: 10px 4px;
    outline: 0;

    &:focus {
      border-color: #85b7d9;
    }
  }

  > label {
    // font-weight: bold;
    margin-bottom: 5px;
  }
`;

export type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const UsersForm = (props: Props) => {
  const { role } = props.user;
  const { availableBrokersAndEstates } = props;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(true);
  const [previousEstate, setPreviousEstate] = useState<string>('');
  const [previousFranchise, setPreviousFranchise] = useState<string>('');
  const [options, setOptions] = useState<OptionsType[]>([]);
  const [statusOptions, setStatusOptions] = useState<OptionsType[]>([
    { key: 0, value: UserStatusEnum.active, text: 'Ativo' },
    { key: 1, value: UserStatusEnum.inactive, text: 'Inativo' },
    { key: 2, value: UserStatusEnum.pending, text: 'Pendente' }
  ]);

  const [userOptions, setUserOptions] = useState<OptionsType[]>([]);
  const franchiseOptions = availableBrokersAndEstates.franchises;
  const history = useHistory();

  useEffect(() => {
    if (options.length === 0) {
      setLoading(true);
      const estates = availableBrokersAndEstates.estates.map(estate => {
        return {
          key: estate.key,
          text: estate.text,
          value: estate.value.id
        };
      });
      setUserOptions(availableBrokersAndEstates.roles);
      setOptions(estates);
      setLoading(false);
    }
  }, [availableBrokersAndEstates.estates, availableBrokersAndEstates.roles, options.length]);


  const user: UserState = props.user;

  const create = async (payload: any) => {
    setLoading(true);
    if (
      (payload.estate === '' || payload.estate === null) &&
      payload.scope === 'Imobiliária'
    ) {
      dangerNotification('Oops...', 'Selecione a Imobiliária');
    } else {
      if (payload.name && payload.email && payload.cellphone) {
        try {
          payload = {
            ...payload,
            user_id: user.id,
            estate: payload.estate || undefined,
            franchise: payload.franchise || undefined
          };

          delete payload.scope;

          await createUser(payload);

          const brokerDTO: BrokerForGetBrokerResponseDTO = await getBroker(
            '7c98d9d9-a975-45a3-9131-cc1961250182'
          );
          const broker: BrokerState | null = brokerDTO
            ? getBrokerObjectFromGetBrokerDTO(brokerDTO)
            : null;

          props.updateAvailableBrokersAndEstates({
            ...props.availableBrokersAndEstates,
            brokers: BrokersToCustomDropDownOptions(true, broker ? [broker] : [])
          });

          successNotification(
            'Usuário cadastrado com sucesso!',
            'Um link para confirmação de cadastro foi enviado para o email informado'
          );
          history.push('/admin/users');
        } catch (error) {
          dangerNotification('Oops...', (error as any).message);
        }
      } else {
        if (!payload.email) {
          dangerNotification('Oops...', 'O e-mail precisa ser preenchido');
          (document as any).querySelector('input[name="email"]').focus();
        }

        if (!payload.name) {
          dangerNotification('Oops...', 'O nome precisa ser preenchido');
          (document as any).querySelector('input[name="name"]').focus();
        }

        if (!payload.cellphone) {
          dangerNotification('Oops...', 'O celular precisa ser preenchido');
          (document as any).querySelector('input[name="cellphone"]').focus();
        }
      }
    }
    setLoading(false);
  };

  const initialValuesUserForm = {
    name: '',
    email: '',
    role: user.role === UserRolesEnum.brokerAdmin ? UserRolesEnum.brokerAdmin : '',
    estate: user.role === UserRolesEnum.brokerAdmin ? '' : String(user.estate?.id),
    franchise:
      user.role !== UserRolesEnum.franchiseAdmin ? '' : String(user.franchise?.id),
    doc: '',
    cellphone: '',
    status: UserStatusEnum.pending,
    observation: '',
    scope: user.role === UserRolesEnum.brokerAdmin ? 'Confiax' : 'estate'
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Campo Obrigatório'),
    email: Yup.string().email('E-mail inválido').required('Campo Obrigatório'),
    role: Yup.string().required('Campo Obrigatório'),
    estate: Yup.string(),
    status: Yup.string(),
    cellphone: Yup.string()
      .matches(/^\([0-9]{2}\) [0-9]{4,5}-[0-9]{4}$/, 'Telefone inválido')
      .required('Campo Obrigatório'),
    doc: Yup.string().matches(/^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$/, 'CPF inválido'),
    observation: Yup.string()
  });

  return (
    <>
      {isReady /*&& options.length > 0*/ && (
        <Formik
          initialValues={initialValuesUserForm}
          validationSchema={validationSchema}
          onSubmit={() => {}}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <SectionLabel text={'DADOS DO USUÁRIO'} />
              {(role === UserRolesEnum.brokerAdmin ||
                role === UserRolesEnum.brokerAnalyst ||
                role === UserRolesEnum.accountManager) && (
                <Form.Group>
                  <Form.Radio
                    name={'scope'}
                    label={'Confiax'}
                    value={values.scope}
                    checked={values.scope === 'Confiax'}
                    onChange={(e, data) => {
                      setFieldValue('scope', 'Confiax');
                      setFieldValue('role', UserRolesEnum.brokerAnalyst);
                      setFieldValue('estate', null);
                      setFieldValue('franchise', null);
                    }}
                  />

                  <Form.Radio
                    name={'scope'}
                    label={'Imobiliária'}
                    value={values.scope}
                    checked={values.scope === 'Imobiliária' || values.scope === undefined}
                    onChange={(e, data) => {
                      setFieldValue('scope', 'Imobiliária');
                      setFieldValue('role', UserRolesEnum.estateUser);
                      setFieldValue('estate', previousEstate);
                      setFieldValue('franchise', null);
                    }}
                  />


                    <Form.Radio
                      name={'scope'}
                      label={'Franquia'}
                      value={values.scope}
                      checked={values.scope === 'Franquia' || values.scope === undefined}
                      onChange={(e, data) => {
                        setFieldValue('scope', 'Franquia');
                        setFieldValue('role', UserRolesEnum.franchiseAdmin);
                        setFieldValue('franchise', previousFranchise);
                        setFieldValue('estate', null);
                      }}
                    />

                </Form.Group>
              )}

              <Form.Group widths={'equal'}>
                {values.scope === 'Confiax' ? (
                  <Form.Select
                    name={'role'}
                    options={userOptions}
                    label={'Tipo de Usuário'}
                    loading={isLoading}
                    clearable={true}
                    value={values.role}
                    onChange={(e, data) => setFieldValue('role', data.value)}
                  />
                ) : values.scope === 'Imobiliária' ? (
                  <Form.Select
                    name={'estate'}
                    options={options}
                    disabled={options.length === 1 || !!!options.length}
                    label={'Imobiliária'}
                    search
                    loading={isLoading || !!!options.length}
                    readonly={true}
                    clearable={true}
                    value={options.length === 1 ? options[0].value : values.estate}
                    onChange={(e, data) => {
                      setFieldValue('estate', data.value);
                      setPreviousEstate(String(data.value));
                    }}
                  />
                ) : (
                  <Form.Select
                    name={'franchise'}
                    options={franchiseOptions}
                    disabled={franchiseOptions.length === 1 || !!!franchiseOptions.length}
                    label={'Franquia'}
                    search
                    loading={isLoading}
                    readonly={true}
                    clearable={true}
                    value={
                      franchiseOptions.length === 1
                        ? franchiseOptions[0].value
                        : values.franchise
                    }
                    onChange={(e, data) => {
                      setFieldValue('franchise', data.value);
                      setPreviousFranchise(String(data.value));
                    }}
                  />)}

                <Form.Input
                  name={'name'}
                  autocomplete={'off'}
                  label={'Nome'}
                  value={values.name}
                  onChange={e => setFieldValue('name', e.target.value)}
                />

                <Form.Input
                  name={'email'}
                  label={'E-mail'}
                  readonly={true}
                  value={values.email}
                  onChange={e => setFieldValue('email', e.target.value)}
                />
              </Form.Group>

              <Form.Group widths={'equal'}>
                <Form.Input
                  name={'doc'}
                  label={'CPF'}
                  value={values.doc}
                  onChange={e => setFieldValue('doc', maskCpf(e.target.value))}
                />

                <Form.Input
                  name={'cellphone'}
                  label={'Celular'}
                  value={values.cellphone}
                  onChange={e =>
                    setFieldValue('cellphone', maskPhoneNumber(e.target.value))
                  }
                />

                <Form.Select
                  name={'status'}
                  options={statusOptions}
                  disabled={true}
                  label={'Status'}
                  loading={isLoading}
                  clearable={true}
                  value={
                    statusOptions.length === 1 ? statusOptions[0].value : values.status
                  }
                  onChange={(e, data) => setFieldValue('status', data.value)}
                />
              </Form.Group>

              <Form.Group widths={'equal'}>
                <TextAreaWrapper>
                  <FieldLabel>Observações</FieldLabel>
                  <TextArea
                    value={values.observation}
                    onChange={(e, data) => setFieldValue('observation', data.value)}
                  />
                </TextAreaWrapper>
              </Form.Group>

              <Button onClick={() => create(values)} color="green">
                Cadastrar
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default connect(mapState, mapDispatch)(UsersForm);
