import { store as notificationStore } from "react-notifications-component";

const animationDefaultTime = 2500;

export const dangerNotification = (
  title: string,
  message: string,
  durationMs: number = animationDefaultTime,
  yDirection = "bottom"
) => {
  notificationStore.addNotification({
    title,
    message,
    type: "danger",
    insert: yDirection,
    container: `${yDirection}-right`,
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: durationMs,
    },
  });
};

export const successNotification = (
  title: string,
  message: string,
  yDirection = "bottom",
  durationMs: number = animationDefaultTime
) => {
  notificationStore.addNotification({
    title: title,
    message: message,
    type: "success",
    insert: yDirection,
    container: `${yDirection}-right`,
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    durationMs,
    dismiss: {
      duration: animationDefaultTime,
    },
    width: 300,
  });
};

export const warningNotification = (
  title: string,
  message: string,
  yDirection = "bottom",
  duration?: number
) => {
  notificationStore.addNotification({
    title: title,
    message: message,
    type: "warning",
    insert: yDirection,
    container: `${yDirection}-right`,
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: duration || 30000,
    },
    width: 300,
  });
};
