export const FileIdentifiersCapitalizationType = [
  { name: 'Icatu - boleto capitalização', key: 'BILL_ICATU' },
  { name: 'Icatu - condições capitalização', key: 'CONDITIONS_ICATU' },
  { name: 'Porto Seguro - boleto capitalização', key: 'BILL_PORTO' },
  { name: 'Porto Seguro - condições capitalização', key: 'CONDITIONS_PORTO' },
  { name: 'Proposta', key: 'PROPOSAL' },
  { name: 'Cláusula da Seguradora', key: 'INSURER_CLAUSE' },
  { name: 'Proposta Assinada', key: 'SIGNED_CONTRACT' },
  { name: 'Contrato de Locação', key: 'LOCATION_CONTRACT' },
  { name: 'Contrato de Administração', key: 'ADMINISTRATOR_CONTRACT' },
  { name: 'Documento Locatário', key: 'TENANT_DOCUMENT' },
  { name: 'Documento Administrador', key: 'ADMINISTRATOR_DOCUMENT' },
  { name: 'Carta de Resgate', key: 'RESCUE_LETTER' },
  { name: 'Carta de Resgate Assinada', key: 'SIGNED_RESCUE_LETTER' },
  { name: 'Comprovante de Pagamento', key: 'PAYMENT_VOUCHER' },
  { name: 'Outros', key: 'OTHER' }
];
