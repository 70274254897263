import { PoliciesFilterData, PoliciesListingRouteInfo} from "./types";
import { iRootState, iRootDispatch } from "../../store";
import { FormikProps, withFormik } from "formik";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import {AvailableBrokersAndEstatesState} from "../../store/types/temp-types";
import BondInsurancePoliciesPage from "./components/BondInsurancePoliciesPage";

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers),
})

export type BondInsurancePoliciesListProps =
  ReturnType<typeof mapDispatch>
  & ReturnType<typeof mapState>
  & {broker: any; estate: any}
  & FormikProps<PoliciesFilterData>
  & RouteComponentProps<PoliciesListingRouteInfo>;

const BondInsurancePoliciesForm = withFormik<BondInsurancePoliciesListProps, PoliciesFilterData>({

  mapPropsToValues: (props: BondInsurancePoliciesListProps) => {
    return {
      brokerId: props.user.broker ? props.user.broker?.id : props.availableBrokersAndEstates.brokers.length === 1
        ? props.availableBrokersAndEstates.brokers[0].value.id
        : "",
      estateId: props.availableBrokersAndEstates.estates.length === 1
        ? props.availableBrokersAndEstates.estates[0].value.id
        : "",
      userId: ''
    }
  },

  validationSchema: {},

  handleSubmit: () => {
  },

})(BondInsurancePoliciesPage);

export default connect(mapState, mapDispatch)(BondInsurancePoliciesForm);
