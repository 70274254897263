import React, { useState } from 'react';
import background from '../../resources/imgs/4seg-login-image.png';
import Loader from '../../components/Loader';
import logo from '../../resources/imgs/logo.png';
import { Pushable, SideBarGrid, Logo, Pusher, ImageBackgroundCover, ImageBackgroundText, ImageBackground } from './styles';
import { useLocation, useHistory, RouteComponentProps } from 'react-router-dom';
import { validateUser } from '../../services/oauth'
import * as Storage from '../../services/storage';
import { iRootDispatch } from '../../store';
import { UserState } from '../../types/UserState';
import { CurrentPageState, CurrentToggledMenuState } from '../../store/types/temp-types';
import { LoginFormProps } from '../oauth-login/types';
import { connect } from 'react-redux';
import { redirectToCapturePage, redirectToOAuthLogin } from '../../services/oauth';

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateUser: (user: UserState) => dispatch.user.updateUser(user),
  updateCurrentPage: (currentPage: CurrentPageState) => dispatch.currentPage.updateCurrentPage(currentPage),
  updateCurrentToggledMenu: (state: CurrentToggledMenuState) => dispatch.currentToggledMenu.updateCurrentToggledMenu(state)
});

export type LoginProps = ReturnType<typeof mapDispatch> & RouteComponentProps & LoginFormProps;

const AuthValidate = (props: LoginProps) => {
  const history = useHistory();
  const [isValidating, setIsValidanting] = useState(true);
  const { hash } = useLocation();
  const splits = hash.split('&');
  const token = splits[0].replace('#access_token=', '');
  const dateNow = new Date();

  let result = false;
  let isExpired = '';
  let email = ''

  validateUser(token).then((response) => {
    const { idToken, refreshToken, ...rest } = response;

    if (response.id && response.role && idToken && refreshToken) {
      result = true;
      Storage.set('name', response.name);
      response.email ? Storage.set('email', response.email) : Storage.set('email', 'sem email');
      Storage.set('uid', response.id);
      Storage.set('role', response.role);
      Storage.set('id_token', idToken);
      Storage.set('refresh_token', refreshToken);
      Storage.set('role_id', JSON.stringify(response.role_id));
      Storage.set('permissions', JSON.stringify(response.permissions, null, 2));
      Storage.set('login_at', JSON.stringify((dateNow).toISOString()));
      props.updateUser({ ...rest });
    } else {
      result = false;
      isExpired = response.message;
      email = response.email;
    }
  }).catch((e) => {
    console.log(e.message);
    redirectToCapturePage();
  }).finally(() => {
    setIsValidanting(false);
    if (result) {
      const bondInsuranceHash =  splits.find(c => c.includes("bondInsuranceId"))
      const analysisHash =  splits.find(c => c.includes("bondInsuranceanalysisId"))
      if(bondInsuranceHash) {
        const bondInsuranceId = bondInsuranceHash.split('=')[1]
        const bondInsuranceAnalysisId = analysisHash?.split('=')[1]
        history.push('/admin/bond-insurances', { bondInsuranceId, bondInsuranceAnalysisId });
        return;
      }
      history.push('/admin/dashboard');
    } else if (isExpired === 'jwt expired') {
      redirectToOAuthLogin(email);
    } else {
      redirectToCapturePage();
    }
  });

  if (isValidating) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <>
      <Pushable>
        <SideBarGrid>
          <Logo src={logo} />
        </SideBarGrid>

        <Pusher>
          <ImageBackgroundCover />

          <ImageBackgroundText>
            <div><b>Proteger você,</b></div>
            <div><span>é o que importa</span></div>
          </ImageBackgroundText>

          <ImageBackground src={background} />
        </Pusher>
      </Pushable>
    </>
  );
}

export default connect(null, mapDispatch)(AuthValidate);
