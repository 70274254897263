export enum TranslatedBrokerRolesEnum {
  accountManager = 'Gestor de Contas',
  brokerAdmin = 'Administrador',
  brokerAnalyst = 'Analista',
  claimAnalyst = 'Operador de Sinistros',
  cancelationAnalyst = 'Operador de Cancelamentos',
  franchiseAdmin = 'Franqueado',
  support = 'Suporte de Operações',
  estateAdmin = 'Administrador de Imobiliária',
  estateUser = 'Usuário de Imobiliária',
}

export interface RolesState {
  id: string;
  name: string;
  userId?: string;
  observations?: string;
}

export interface RolesBasicState {
  id: string;
  name: string;
}
