import { MenuItemProps, SubMenuItemProps } from '../containers/types';

export const getMenuPageInfo = (name: string): MenuItemProps => {
  let page: MenuItemProps | SubMenuItemProps = { id: 1, text: 'dashboard', name: 'dashboard' };

  const option = menuOptions.find((opt) => opt.name === name);

  if (option !== undefined) {
    page = { ...option };
  } else {
    menuOptions.forEach((opt) => {
      if (opt.submenuItems !== undefined) {
        opt.submenuItems.forEach((submenu) => {
          if (submenu.submenus !== undefined) {
            const item = submenu.submenus.find((i) => i.name === name);
            if (item !== undefined) {
              page = { ...item };
            }
          }
        })
      }
    });
  }

  return page;
}

export const menuOptions: Array<MenuItemProps> =
[
      {
        id: 1,
        icon: 'dashboard',
        route: 'dashboard',
        clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
        name: 'DASHBOARD',
        text: 'Dashboard',
        title: 'Dashboard'
      },
      {
        id: 2,
        icon: 'shield',
        clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
        name: 'ANALYSIS',
        text: 'Seguros',
        submenuItems: [
          {
            id: 3,
            text: 'Seguro incêndio',
            submenus: [
              {
                id: 4,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'fire-insurance-budget',
                route: 'fire-insurance-budget',
                text: 'Novo Orçamento',
                title: 'Novo orçamento de seguro incêndio'
              },
              {
                id: 5,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'fire-insurance-budgets',
                route: 'fire-insurance-budgets',
                text: 'Gerenciar Orçamentos',
                title: 'Gerenciar Orçamentos'
              },
              {
                id: 9,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'fire-insurance-policies',
                route: 'fire-insurance-policies',
                text: 'Gerenciar Apólices',
                title: 'Gerenciar Apólices'
              },
              {
                id: 10,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'fire-insurance-invoices',
                route: 'fire-insurance-invoices',
                text: 'Faturas',
                title: 'Faturas'
              }
            ]
          },
          {
            id: 400,
            text: 'Seguro fiança',
            submenus: [
              {
                id: 409,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'find-record',
                route: 'find-record',
                text: 'Localizar Registro',
                title: 'Localizar Registro'
              },
              {
                id: 410,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'bond-insurance',
                route: 'bond-insurance',
                text: 'Nova Análise',
                title: 'Nova Análise'
              },
              {
                id: 411,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'bond-insurances',
                route: 'bond-insurances',
                text: 'Gerenciar Análises',
                title: 'Gerenciar Análises'
              },
              {
                id: 412,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'bond-insurance-proposals',
                route: 'bond-insurance-proposals',
                text: 'Gerenciar Propostas',
                title: 'Gerenciar Propostas'
              },
              {
                id: 413,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'bond-insurance-policies',
                route: 'bond-insurance-policies',
                text: 'Gerenciar Apólices',
                title: 'Gerenciar Apólices'
              },
              {
                id: 414,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'canceled-bond-insurances',
                route: 'canceled-bond-insurances',
                text: 'Gerenciar Canceladas',
                title: 'Gerenciar Canceladas'
              },
              {
                id: 415,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'expired-bond-insurances',
                route: 'expired-bond-insurances',
                text: 'Gerenciar Expirados',
                title: 'Gerenciar Expirados'
              },
              {
                id: 416,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'reports-form',
                route: 'reports-form',
                text: 'Relatórios',
                title: 'Relatórios'
              }
            ]
          },
          {
            id: 500,
            text: 'Título de capitalização',
            submenus: [
              {
                id: 511,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'capitalizations',
                route: 'capitalizations',
                text: 'Gerenciar Capitalizações',
                title: 'Gerenciar Capitalizações'
              },
            ]
          }
        ]
      },
      {
        id: 550,
        icon: 'hotjar',
        clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
        name: 'CLAIMS',
        text: 'Sinistros',
        submenuItems: [{
          id: 551,
          text: 'Gerenciar Sinistros',
          submenus: [
            {
              id: 552,
              clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
              name: 'claim-estates',
              route: 'claim_estates',
              text: 'Sinistros ativos',
              title: 'Sinistros ativos'
            },
            {
              id: 553,
              clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
              name: 'claim-estate-responsible-transfer',
              route: 'claim-estate-responsible-transfer',
              text: 'Gerenciar responsáveis',
              title: 'Gerenciar responsáveis'
            },
          ]
        }],
      },
      {
        id: 16,
        icon: 'home',
        clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
        name: 'ESTATES',
        text: 'Imobiliárias',
        submenuItems: [
          {
            id: 17,
            text: 'Gerenciamento de Imobiliárias',
            submenus: [
              {
                id: 18,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'warehouse[estate]',
                route: 'estate',
                text: 'Nova Imobiliária',
                title: 'Nova Imobiliária'
              },
              {
                id: 19,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'warehouse[estates]',
                route: 'estates',
                text: 'Imobiliárias',
                title: 'Imobiliárias'
              },
              {
                id: 20,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'warehouse[estates-preregister]',
                route: 'estates-preregister/new',
                text: 'Novo Pré-cadastro',
                title: 'Novo Pré-cadastro'
              },
              {
                id: 21,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'warehouse[estates-preregister-form]',
                route: 'estates-preregisters',
                text: 'Pré-cadastros',
                title: 'Pré-cadastros'
              },
            ]
          }
        ],
      },
      {
        id: 22,
        icon: 'user',
        clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
        name: 'USERS',
        text: 'Usuários',
        submenuItems: [
          {
            id: 23,
            text: 'Gerenciamento de Usuários',
            submenus: [
              {
                id: 24,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'new-user',
                route: 'user',
                text: 'Novo Usuário',
                title: 'Novo Usuário'
              },
              {
                id: 25,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'list-users',
                route: 'users',
                text: 'Usuários',
                title: 'Usuários'
              },
            ]
          }
        ],
      },
      {
        id: 26,
        icon: 'users',
        clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
        name: 'FRANCHISES',
        text: 'Parceiros',
        submenuItems: [
          {
            id: 27,
            text: 'Gerenciamento de Parceiros',
            submenus: [
              {
                id: 28,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'new-franchise',
                route: 'franchise/new',
                text: 'Novo Parceiro',
                title: 'Gerenciar franqueados/parceiros'
              },
              {
                id: 29,
                clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
                name: 'list-franchises',
                route: 'franchises',
                text: 'Parceiros',
                title: 'Parceiros'
              },
            ]
          }
        ],
      },
      {
        id: 600,
        icon: 'file alternate outline',
        route: 'logs',
        clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
        name: 'LOGS',
        text: 'Logs',
        title: 'Gerenciar Logs'
      },
      {
        id: 700,
        icon: 'database',
        route: 'powerbi',
        clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
        name: 'POWERBI',
        text: 'Power BI',
        title: 'Power BI'
      },
      {
        id: 800,
        icon: 'desktop',
        route: 'banner-management',
        clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
        name: 'BANNERS',
        text: 'Gerenciar Banners',
        title: 'Gerenciar Banners'
      },
      {
        id: 900,
        icon: 'ban',
        route: 'blacklist',
        clickHandler: (e: MouseEvent, data: MenuItemProps) => { },
        name: 'BLACKLIST',
        text: 'Blacklist',
        title: 'Blacklist'
      },
    ];
