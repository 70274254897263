import { Grid, Image } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

export const InsurersRow = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem;
`;

export const FireInsurersRow = styled(Grid.Row)`
  margin: 0 auto;
`;

interface InsurersWrapperProps {
  isSelected?: boolean;
  isFluid?: boolean;
}
export const InsurersImageWrapper = styled.div<InsurersWrapperProps>`
  display: flex;
  flex-direction: row;
  width: ${props => (props.isFluid ? '100%' : 'initial')};
  height: 90px;
  padding: 0.5rem;
  align-items: center;
  justify-content: left;
  gap: 2rem;
  border-radius: 3px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  overflow-y: hidden;
  overflow-x: hidden;
  img {
    height: 86px;
    width: 100px;
    object-fit: fill;
    border-radius: 10px;
  }
  .insurer-title {
    display: flex;
    height: 90px;
    align-items: top;
    width: 200px;
    padding-top: 5px;
    cursor: pointer;
  }

  .row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    margin-left: auto;
    label {
      font-size: 14px;
    }
  }
  .icon {
    cursor: grab;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 2px solid #2185d0;
      .insurer-title {
        color: #2185d0;
        font-weight: 600;
      }
      &::after {
        content: '';
        position: absolute;
        right: 4.5px;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid rgba(33, 133, 208, 1);
      }
    `}
`;

export const PositionWrapper = styled.div<InsurersWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 8px;
  width: 24px;
  height: 90px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px 0px 0px 4px;
  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 2px solid #2185d0;
      color: #2185d0;
      font-weight: 600;
    `}
`;

export const InsurersConfigWrapper = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 1rem 2rem;
  img {
    width: 150px;
    object-fit: fill;
    border-radius: 10px;
  }
  .insurer-title {
    display: flex;
    align-items: top;
    width: 200px;
    padding-top: 1rem;
    padding-left: 1rem;
  }
`;

interface InsurerCardProps {
  isSelected?: boolean;
}
export const InsurerCard = styled.div<InsurerCardProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 54px;
  padding: 0.5rem;
  justify-content: space-between;
  gap: 2rem;
  border-radius: 4px;
  border: 1px solid #dddddd;
  margin-bottom: 10px;
  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 2px solid #2185d0;
      .insurer-title {
        color: #2185d0;
        font-weight: 600;
      }
    `}
`;

export const NoParamsMessage = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: #2185d0;
`;

export const InsurerCardContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;

export const InsurerCardContentTitle = styled.h3`
  font-size: 14px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0em;
  margin: 0px;
`;

export const InsurerCardIcon = styled(Image)`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  aspect-ratio: auto;
`;

export const InsurerCardToggleContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 130px;
  align-items: flex-start;
`;
