import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Info } from "../../../../components/Info";
import { BondInsuranceResponseDTO } from '../../types/bondinsurance-interfaces';
import SectionLabel from '../../../../components/SectionLabel';

export type Props = {
  bondInsurance: BondInsuranceResponseDTO;
};

export const BondInsuranceOwnerDetails = (props: Props) => {
  const { bondInsurance } = props;

  return (
    <>
      <SectionLabel text='DADOS DO PROPRIETÁRIO' />
      <Grid.Row>
        <Info name={'NOME'} value={bondInsurance.ownerData?.name || "Indisponível"} width={8} />
        <Info name={'CPF/CNPJ'} value={bondInsurance.ownerData ? (bondInsurance.ownerData.cpf ? bondInsurance.ownerData.cpf : (bondInsurance.ownerData.cnpj)) : "Indisponível"} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'CEP'} value={bondInsurance.ownerAddress?.zip_code || "Indisponível"} width={8} />

      </Grid.Row>
      <Grid.Row>
        <Info name={'RUA'} value={bondInsurance.ownerAddress?.street || "Indisponível"} width={6} />
        <Info name={'N°'} value={bondInsurance.ownerAddress?.number || "Indisponível"} width={2} />
        <Info name={'BAIRRO'} value={bondInsurance.ownerAddress?.district || "Indisponível"} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'CIDADE'} value={bondInsurance.ownerAddress?.city || "Indisponível"} width={8} />
        <Info name={'UF'} value={bondInsurance.ownerAddress?.state || "Indisponível"} width={8} />
      </Grid.Row>
      {bondInsurance.ownerAddress?.complement &&
        <Grid.Row>
          <Info name={'Complemento'} value={bondInsurance.ownerAddress?.complement || "Indisponível"} width={8} />
        </Grid.Row>
      }
    </>
  )
}
