import React, {useEffect, useState} from "react";
import {InsuranceConnectorCoverageState} from "../../../../types/PropertyTypeState";
import styled from "styled-components";
import ConnectorCoverageOption from "./ConnectorCoverageOption";
import SectionLabel from "../../../../components/SectionLabel";
import {connect} from "react-redux";
import {iRootDispatch, iRootState} from "../../../../store";
import {FieldLabel} from "../../../../styles";
import {MINIMUM_COVERAGES_AMOUNT} from "../../validators/coverages-section/connector-coverages-section/constants";
import {getDefaultInsuredAmountByStrategy} from "../../../../services/connector-coverage";

type ConnectorCoverageListProps = {
  coverageList: InsuranceConnectorCoverageState[];
}
& ReturnType<typeof mapDispatchToProps>
& ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: iRootState) => ({
  rentValue: state.fireInsuranceBudget.rent.value,
  estateMultiplier: state.fireInsuranceBudget.selected.estate.multiplier
});

const mapDispatchToProps = (dispatch: iRootDispatch) => ({
  addConnectorCoverage: (coverageState: InsuranceConnectorCoverageState, insuredAmount: number) =>
    dispatch.fireInsuranceBudget.addSelectedConnectorCoverage(coverageState, insuredAmount),
  removeConnectorCoverage: (connectorCoverageId: string) =>
  dispatch.fireInsuranceBudget.removeSelectedConnectorCoverage(connectorCoverageId),
  updateCoverageInsuredAmount: (connectorCoverageId: string, insuredAmount: number) =>
    dispatch.fireInsuranceBudget.updateCoverageInsuredAmount(connectorCoverageId, insuredAmount)
});

const ConnectorCoverageList = (props: ConnectorCoverageListProps) => {
  const [selectedCoveragesAmount, setSelectedCoveragesAmount] = useState(0);
  const [componentDidMount, setComponentDidMount] = useState(false);
  const {
    addConnectorCoverage,
    removeConnectorCoverage,
    updateCoverageInsuredAmount,
    coverageList,
    rentValue,
    estateMultiplier
  } = props;

  useEffect(() => {
    let selectedCoveragesAmount = 0;
    coverageList.map((coverage) => {
      if (coverage.mandatoryInQuotation) {
        selectedCoveragesAmount++;
        addConnectorCoverage(coverage, getDefaultInsuredAmountByStrategy(coverage.defaultCoverageValueStrategy, {
          rentValue,
          estateMultiplier
        }));
      }
    });

    setSelectedCoveragesAmount(selectedCoveragesAmount);
  }, [coverageList]);

  useEffect(() => {
    setComponentDidMount(true);
  }, []);

  const handleCoverageToggle = (coverage: InsuranceConnectorCoverageState, checked: boolean, insuredAmount: number): void => {
    if (checked) {
      addConnectorCoverage(coverage, insuredAmount);
      setSelectedCoveragesAmount(selectedCoveragesAmount + 1);
    } else {
      removeConnectorCoverage(coverage.id)
      setSelectedCoveragesAmount(selectedCoveragesAmount - 1);
    }
  }

  const handleInsuredAmountChange = (coverage: InsuranceConnectorCoverageState, insuredAmount: number): void => {
    updateCoverageInsuredAmount(coverage.id, insuredAmount);
  }

  useEffect(() => {
  }, [props.rentValue])

  if (coverageList.length === 0) {
    return null;
  }

  return (
    <ComponentContainer>
      <SectionLabel text='COBERTURAS' />
      <FieldLabel style={{
        transition: 'color linear 0.15s',
        alignSelf: 'center',
        fontSize: '1.2em',
          color: selectedCoveragesAmount < MINIMUM_COVERAGES_AMOUNT ? '#E3242B' : '#028A0F'
      }}>
        {`${selectedCoveragesAmount} de no mínimo ${MINIMUM_COVERAGES_AMOUNT} selecionadas`}
      </FieldLabel>
      <ListContainer>
        {coverageList.length > 0 &&
        coverageList.map((coverage) => {
          return (
            <ConnectorCoverageOption coverage={coverage}
                                     widthPercentSize={48.5}
                                     handleCoverageToggleCallback={handleCoverageToggle}
                                     handleCoverageInsuredAmountChangeCallback={handleInsuredAmountChange}
                                     context={{rentValue, estateMultiplier}}
                                     key={coverage.id}
            />
          )
        })
        }
      </ListContainer>
    </ComponentContainer>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectorCoverageList);

const ListContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 0.25em 0em 1.25em;
`

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
`
