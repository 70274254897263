export const RESIDENTIAL_FLAG = [
  {
    key: 'S',
    value: 'S',
    text: 'SIM',
  },
  {
    key: 'N',
    value: 'N',
    text: 'NÃO',
  },
];

export const PROPERTY_TYPE = [
  {
    key: 1,
    value: 1,
    text: 'CONSULTORIOS, ESCOLAS, ESCRITORIOS E HOSPITAIS',
  },
  {
    key: 2,
    value: 2,
    text: 'COMERCIO E SERVICOS',
  },
  {
    key: 3,
    value: 3,
    text: 'INDUSTRIAS',
  },
  {
    key: 4,
    value: 4,
    text: 'IMOVEIS DESOCUPADOS NAO LOCADOS',
  },
  {
    key: 5,
    value: 5,
    text: 'CASAS',
  },
  {
    key: 6,
    value: 6,
    text: 'APARTAMENTOS',
  },
  {
    key: 7,
    value: 7,
    text: 'IMOVEIS DESOCUPADOS',
  },
  {
    key: 8,
    value: 8,
    text: 'IMOVEIS DESOCUPADOS, APARTAMENTOS',
  },
  {
    key: 9,
    value: 9,
    text: 'CASA DE MADEIRA',
  },
];

export const STREET_TYPE = [
  {
    key: 'AL',
    value: 'AL',
    text: 'ALAMEDA',
  },
  {
    key: 'AV',
    value: 'AV',
    text: 'AVENIDA',
  },
  {
    key: 'EST',
    value: 'EST',
    text: 'ESTRADA',
  },
  {
    key: 'R',
    value: 'R',
    text: 'RUA',
  },
  {
    key: 'ROD',
    value: 'ROD',
    text: 'RODOVIA',
  },
  {
    key: 'TV',
    value: 'TV',
    text: 'TRAVESSA',
  },
];

export const SEX_TYPE = [
  {
    key: 'M',
    value: 'M',
    text: 'Masculino',
  },
  {
    key: 'F',
    value: 'F',
    text: 'Feminino',
  },
];

export const MARITAL_STATUS = [
  {
    key: 1,
    value: 1,
    text: 'Solteiro(a)',
  },
  {
    key: 2,
    value: 2,
    text: 'Casado(a)',
  },
  {
    key: 3,
    value: 3,
    text: 'Viúvo(a)',
  },
  {
    key: 4,
    value: 4,
    text: 'Desquitado(a)',
  },
  {
    key: 5,
    value: 5,
    text: 'Divorciado(a)',
  },
];

export const EDITABLE_COVERAGE = [
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
];

export const FORM_INITIAL_VALUES = {
  residentialFlag: '',
  flagProponentOwner: '',
  initialEffectiveDate: '',
  monthsValidity: undefined,
  rentValue: 0,
  proponentName: '',
  document: '',
  cellphone: '',
  birthdate: '',
  sexType: '',
  maritalStatus: undefined,
  proponentEmail: '',
  zipCode: '',
  streetType: '',
  street: '',
  district: '',
  city: '',
  state: '',
  number: undefined,
  complement: '',
  codePropertyType: undefined,
  fireCoverageValue: 30000,
  civilLiabilityCoverageValue: 0,
  coverageContentValue: 0,
  electricalDamageCoverageValue: 0,
  coverageImpactsAirLandVehiclesValue: 0,
  rentalLossCoverageValue: 0,
  galeCoverageValue: 0,
  riotCoverageValue: 0,
  estateId: '',
  insurerId: '',
};
