import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dimmer,
  Dropdown,
  DropdownItemProps,
  Form,
  Grid,
  Icon,
  Input,
  List,
  ListContent,
  ListDescription,
  ListHeader,
  ListItem,
  Loader,
  Popup,
  Sticky
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Formik, FormikValues, FormikActions } from 'formik';
import * as Yup from 'yup';
import * as S from './styles';
import { FieldLabel } from '../../../../../styles';
import AppMaskedInput from '../../../../../components/MaskedInput';
import CustomDatePicker from '../../../../../components/CustomDatePicker';
import { iRootDispatch, iRootState } from '../../../../../store';
import { CurrentPageState } from '../../../../../store/types/temp-types';
import {
  warningNotification
} from '../../../../../services/notification';
import {
  AlfaBudgetFormProps,
  AlfaBudgetRequest,
  AlfaBudgetResponse,
  AlfaBudgetResponseErrorResponse,
  AlfaBudgetResponseSuccessResponse
} from './interfaces/alfa.budget.interface';
import { DATE_INPUT_MASK, numberToMoneyString, unmaskCpfOrCnpj } from '../../../../../services/masks';
import { getOccupationTypeOptions, getPropertyTypeOptions, coverageTypesOptions } from './constants/form.constant';
import { get, post } from '../../../../../services/request';
import AlfaCreatePolicyModal from './components/CreatePolicyModal';
import { CoverageEstate } from './interfaces/alfa-coverages.interface';
import RangeSlider from './components/range-slider';
import moment from 'moment-timezone';
import { useLocation } from 'react-router-dom';
import CoverageDetailModal from '../../CoverageDetailsModal';
import { AlfaEstatePlansInterface } from './interfaces/alfa-plans.interface';
import { NormalizeTokioRenewalDataForAlfa } from './utils/normalize-renewal-data.util';
import { InsurersCodeEnum } from '../../../../../enums/insurers-code.enum';
import AssistanceDetailsModal from './components/AssistanceDetailsModal';

interface CustomDropDownOptions {
  value: any;
  key: number | string;
  text: string;
};

export enum BudgetContext {
  new = 'NEW',
  detail = 'DETAIL',
  editing = 'EDITING',
  renewal = 'RENEWAL',
  copyCanceled = 'COPY_CANCELED',
};

const AlfaSeguroBudgetForm = (props: AlfaBudgetFormProps) => {
  const { estate, insurerId, context, policyOrigin, budgetId } = props;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const isEdit = [BudgetContext.detail,BudgetContext.editing].includes(context);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [openCreatePolicyModal, setOpenCreatePolicyModal] = useState<boolean>(false);
  const [openAssistanceDetailsModal, setOpenAssistanceDetailsModal] = useState<boolean>(false);
  const [channels, setChannels] = useState<CustomDropDownOptions[]>([]);
  const [plans, setPlans] = useState<CustomDropDownOptions[]>([]);
  const [occupationOptions, setOccupationOptions] = useState<CustomDropDownOptions[]>([]);
  const [propertyTypeOptions, setPropertyTypeOptions] = useState<CustomDropDownOptions[]>([]);
  const [isHireRequest, setIsHireRequest] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0); //isEdit ? 1 : 0
  const [hasDiscount, setHasDiscount] = useState<boolean>(false);
  const [coverages, setCoverages] = useState<CoverageEstate[]>([]);
  const [alfaConfigs, setAlfaConfigs] = useState<any>({});
  const [installmentsOptions, setInstallmentsOptions] = useState<CustomDropDownOptions[]>([]);
  const [loadingInitialValues, setLoadingInitialValues] = useState<boolean>(true);
  const [grossPremium, setGrossPremium] = useState<number>();
  const [data, setData] = useState<any>();
  const [requestData, setRequestData] = useState<AlfaBudgetRequest>();
  const [requestResponse, setRequestResponse] = useState<AlfaBudgetResponseSuccessResponse['data']>();
  const [discount, setDiscount] = useState<number>(0);
  const [initialValues, setInitialValues] = useState<any>(null);
  const [openCoverageDetailsModal, setOpenCovarageDetailsModal] = useState<boolean>(false);
  const [savedData, setSavedData] = useState<any>();
  const [priceLabel, setPriceLabel] = useState<string>('');
  const [channelIsCompany, setChannelIsCompany] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  function hasChange() {
    if (step === 1) {
      setStep(0);
    }
  }

  useEffect(() => {
    hasChange();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estate]);

  const handleSubmit = async (
    formValues: FormikValues,
    {
      setFieldValue,
      setSubmitting
    }: FormikActions<FormikValues>
  ) => {
    try {
      const tenantIsPF = unmaskCpfOrCnpj(formValues.tenantDocument).length === 11;

      const payload: AlfaBudgetRequest = {
        estateId: estate.id,
        insurerId: insurerId,
        isEdit,
        renewalFireInsuranceId: (isEdit || context === BudgetContext.renewal) ? budgetId : undefined,
        data: {
          rentValue: formValues.rentValue,
          installments: Number(formValues.installments),
          informedCost:
            grossPremium
              ? hasDiscount && formValues.discount
                ? Number(grossPremium - (grossPremium * formValues.discount) / 100)
                : grossPremium
              : 0,
          action: 0,
          policyPeriodStart: formValues.startVigency,
          policyPeriodEnd: formValues.endVigency,
          channel: Number(formValues.channel.code),
          tenantDocument: unmaskCpfOrCnpj(formValues.tenantDocument),
          tenantName: formValues.tenantName,
          discountPercentage: hasDiscount && formValues.discount ? Number(formValues.discount) : 0,
          tenantEmail: formValues.tenantEmail,
          tenantBirthdate: (tenantIsPF && formValues.tenantBirthdate) ? moment(formValues.tenantBirthdate).format('YYYY-MM-DD') : undefined,
          tenantCellphone: formValues.tenantCellphone?.replace(/\D/g, ''),
          tenantSex: formValues.tenantSexType ?? '0',
          propertyOwnerName: formValues.propertyOwnerName,
          propertyOwnerDocument: formValues.propertyOwnerDocument
            ? unmaskCpfOrCnpj(formValues.propertyOwnerDocument)
            : undefined,
          propertyType: Number(formValues.propertyType),
          propertyUseType: Number(formValues.occupationType),
          coverageType: Number(formValues.coverageType),
          planCode: formValues.plans,
          property: {
            zipcode: formValues.zipCode?.replace(/\D/g, ''),
            city: formValues.city,
            state: formValues.state,
            street: formValues.street,
            district: formValues.district,
            number: formValues.number,
            complement: formValues.complement,
          },
          reference: formValues.reference,
          coverages: formValues.coverages
            .filter((cvrgs: any) => cvrgs.checked)
            .map((c: any) => ({
              coverageName: c.name,
              referenceCode: Number(c.code),
              value: Number(c.value),
            }))
        }
      };

      const response: AlfaBudgetResponse = await post(
        '/fire-insurance-quotations/alfa-seguros/budget',
        payload
      );

      if (response.status === 207) {
        return warningNotification(
          'Erro ao criar orçamento de seguro incendio',
          'Por favor, tente novamente!'
        );
      }

      if (response.status === 200) {
        const data = response.data as AlfaBudgetResponseSuccessResponse['data'];

        setGrossPremium(data?.maxPremium);
        setRequestResponse(data);
        setStep(1);

        const cvgs = formValues.coverages.map((c: any) => {
          const cvg = data.coverages.find(d => +d.referenceCode === +c.code);
          if (cvg) {
            return {
              ...c,
              premiumValue: cvg.premium
            }
          }
          return c;
        });

        setFieldValue('coverages', cvgs);

        setRequestData(payload);

        setData({
          id: isEdit ? budgetId : '',
          estateName: estate.name,
          channel: formValues.channel.name,
          coverages: formValues.coverages.filter((d: any) => d.checked).map((c: any) => ({
            code: c.code,
            name: c.name,
            value: c.value
          })),
          coverageType: coverageTypesOptions.find(c => c.value === formValues.coverageType)?.text || '',
          policyPeriodStart: moment(formValues.startVigency).format('DD/MM/YYYY'),
          policyPeriodEnd: moment(formValues.endVigency).format('DD/MM/YYYY'),
          propertyType:  propertyTypeOptions.find(p => p.value === formValues.propertyType)?.text || '',
          propertyUseType: occupationOptions.find(p => p.value === formValues.occupationType)?.text || '',
          assistance: plans.find(p => p.value.code === formValues.planCode)?.text || '',
          rentValue: numberToMoneyString(formValues.rentValue),
          maxPremium: data?.maxPremium || 0,
          totalPremium: data?.maxPremium || 0,
          installments: formValues.installments,
        });
      } else {
        const data2 = response.data as AlfaBudgetResponseErrorResponse['data'];

        if (data2?.message?.includes('fora do limite')) {
          warningNotification(
            'Valor de cobertura fora do limite',
            data2.message, undefined, 7000
          );
        } else {
          warningNotification(
            'Erro ao criar orçamento de seguro incendio',
            data2.message
          );
        }
      }
    } catch (error) {
      console.log(error);
      warningNotification(
        'Erro ao criar orçamento de seguro incendio',
        'Plano solicitado não disponível para o seu cadastro, contacte o seu Gestor de Contas.'
      );
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    channel: Yup.string().test('channel', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo Finalidade da Locação é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    propertyType: Yup.string().test('propertyType', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo Tipo do Imóvel é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    occupationType: Yup.string().test('occupationType', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo Ocupação é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    coverageType: Yup.string().test('coverageType', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo Tipo de Cobertura a é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    startVigency: Yup.string().test('startVigency', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo Inicio da Vigência é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    endVigency: Yup.string().test('endVigency', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo Fim de Vigência é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    plans: Yup.string().test('plans', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo Assistência é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    rentValue: Yup.string().test('rentValue', 'error', value => {
      if (Number.isNaN(Number(value)) || value === '0') {
        warningNotification(
          'O campo Valor do Aluguel é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
    installments: Yup.string().test('installments', 'error', value => {
      if (!value) {
        warningNotification(
          'O campo Nº de Parcelas é obrigatório',
          'Por favor, preencha o campo corretamente.'
        );
        return false;
      }

      return true;
    }),
  });

  const loadPlans = useCallback(async () => {
    const plansByEstate: AlfaEstatePlansInterface[] = await get(`/alfa-seguros/plan-estate/${estate.id}`);
    const plans: any[] = [];

    const result = plansByEstate.reduce((prev: any, curr) => {
      plans.push({
        key: curr.plans.code,
        text: curr.plans.name,
        value: {
          code: curr.plans.code,
          modality: curr.plans.channel.modality,
          id: curr.plans.id
        }
      });

      if (prev.ids.indexOf(curr.plans.channel.id) === -1) {
        prev.ids.push(curr.plans.channel.id);
        prev.channels.push({
          key: curr.plans.channel.id,
          text: curr.plans.channel.name,
          value: {
            id: curr.plans.channel.id,
            modality: curr.plans.channel.modality,
            code: curr.plans.channel.code,
            name: curr.plans.channel.name,
          }
        });
      }

      return prev;
    }, { channels: [], ids: [] });

    setPlans(plans);
    setChannels(result.channels);
  }, [estate.id]);

  const loadCoverages = useCallback(async () => {
    const coverages = await get(`/alfa-seguros/coverage-estate/${estate.id}`);

    const sortCoverages = coverages.sort((a: any, b: any) => {
      const awi = a.coverage.name.startsWith('Incêndio');
      const bwi = b.coverage.name.startsWith('Incêndio');

      if (awi && !bwi) return -1;
      if (!awi && bwi) return 1;

      const awa = a.coverage.name.includes('Aluguel');
      const bwa = b.coverage.name.includes('Aluguel');

      if (awa && !bwa) return -1;
      if (!awa && bwa) return 1;

      return 0
    });

    setCoverages(sortCoverages);
  }, [estate.id]);

  const loadConfigs = useCallback(async () => {
    const configs = await get(`/estates/fire-active-insurer/${estate.id}/${insurerId}`);

    setAlfaConfigs(configs);

    if (configs.installmentOptions) {
      const options = configs.installmentOptions.split(',').map((option: any) => ({
        key: option,
        text: `${option}x`,
        value: option
      }));

      setInstallmentsOptions(options);
    }
  }, [estate.id, insurerId]);

  useEffect(() => {
    loadPlans();
    loadCoverages();
    loadConfigs();
  }, [loadPlans, loadCoverages, loadConfigs]);

  const handleChangeChannel = (channel: any) => {
    if (!channel) return undefined;

    const plansByChannel: DropdownItemProps[] = [];

    plans.forEach(plan => {
      if (plan.text.includes(channel.name)) {
        plansByChannel.push({
          key: plan.key,
          text: plan.text,
          value: plan.key,
        });
      };
    });

    return plansByChannel;
  };

  async function loadDataForNewBudget() {
    setLoadingInitialValues(true);

    try {
      let estateNumber = '';

      if (estate?.estateNumber) {
        estateNumber = estate.estateNumber.replace(/[^0-9]/g, '');
        if (estateNumber === '') estateNumber = '0';
      }

      const data = {
        isEdit: false,
        coverageType: '4',
        tenantDocument: estate.cnpj,
        tenantName: estate.name,
        zipCode: estate.estateZipCode,
        street: estate.estateStreet,
        district: estate.estateDistrict,
        city: estate.estateCity,
        state: estate.estateState,
        number: estateNumber,
        complement: estate.estateComplement,
      };

      setInitialValues(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingInitialValues(false);
    }

  }

  async function loadDataForEditingOrCopy() {
    setLoadingInitialValues(true);

    const budgetId = (location.pathname).replace('/admin/fire-insurance-budget/', '');

    if (!budgetId) {
      warningNotification(
        'Erro ao carregar os dados do orçamento',
        'Se o erro persistir contacte o suporte'
      );
    }

    try {
      const data = await get(`/fire-insurance-quotations/alfa-seguros/budget/${budgetId}`);

      const { fireInsurance, channel, plan, coverages: cvrgs } = data;
      const { propertyAddresses: property } = fireInsurance;

      const selectedChannel = {
        id: channel.id,
        code: channel.code,
        name: channel.name,
        modality: channel.modality,
      };

      let selectedPropertyType, selectedOccupationType, selectedCoverages, plansByChannel;

      if (selectedChannel) {
        plansByChannel = handleChangeChannel(selectedChannel);

        const propertyTypeOptions = getPropertyTypeOptions(selectedChannel.modality);
        const occupationTypeOptions = getOccupationTypeOptions(selectedChannel.modality);

        if (propertyTypeOptions) {
          setPropertyTypeOptions(propertyTypeOptions);

          selectedPropertyType = propertyTypeOptions.find(
            c => +c.value === +fireInsurance?.propertyType
          )?.value;
        }

        if (occupationTypeOptions) {
          setOccupationOptions(occupationTypeOptions);

          selectedOccupationType = occupationTypeOptions.find(
            ot => +ot.value === +fireInsurance?.propertyUseType
          )?.value;
        }

        selectedCoverages = coverages.filter(c => c.coverage.channel.code === selectedChannel.code).map(c => {
          const hasCoverage = cvrgs?.find((cv: any) => cv.referenceCode === c.coverage.code);

          let value = 0;

          if (hasCoverage) {
            value = Number(hasCoverage.value);
          } else {
            value = (c.multiplier && c.multiplier > 0) ? c.multiplier * Number(fireInsurance?.rentValue) : 0;

            if (value > c.maxValue) {
              value = c.maxValue;
            }

            if (value < c.minValue) {
              value = c.minValue;
            }
          }

          return {
            id: c.id,
            code: c.coverage.code,
            checked: c.coverage.isDefault ? true : hasCoverage ? true : false,
            value: value,
            name: c.coverage.name,
            onEdit: false,
            onEditValue: value,
            maxValue: c.maxValue,
            minValue: c.minValue,
            multiplier: c.multiplier,
            isDefault: c.coverage.isDefault,
            isRequired: c.isRequired,
            maxMultiplier: c.coverage.maxMultiplier
          };
        });
      }

      let startDate = new Date(fireInsurance.policyPeriodStart ?? Date.now());

      if(startDate < new Date()) {
        startDate = new Date();
      }

      const endDate = selectedChannel?.name?.includes('Anual')
        ? moment(startDate).add(1, 'year').toDate()
        : moment(startDate).add(1, 'month').toDate();

      const selectedPlan = plansByChannel?.find(plan => String(plan?.key) == String(data?.assistance));

      const tenantIsPF = String(fireInsurance.tenantDocument).length === 11;

      const normalizeData = {
        estateName: estate.name,
        // Step 1
        channel: selectedChannel,
        propertyType: selectedPropertyType,
        occupationType: selectedOccupationType,
        coverageType: data.coverageType,
        startVigency: startDate,
        endVigency: endDate,
        plans: selectedPlan?.value,
        rentValue: +fireInsurance.rentValue,
        installments: String(data.installments),
        // Step 2
        action: 0,
        tenantName: fireInsurance.tenantName,
        tenantDocument: fireInsurance.tenantDocument,
        discountPercentage: +data.discountPercentage,
        tenantEmail: fireInsurance.tenantEmail,
        tenantBirthdate: tenantIsPF ? moment(fireInsurance.tenantBirthdate).format('YYYY-MM-DD') : undefined,
        tenantCellphone: fireInsurance.tenantCellphone,
        tenantSex: fireInsurance.tenantGender === 'M' ? 'Masculino' : 'Feminino',
        propertyOwnerName: fireInsurance.propertyOwnerName,
        propertyOwnerDocument: fireInsurance.propertyOwnerDocument,
        planCode: plan.code,
        zipCode: property.zipcode,
        city: property.city,
        state: property.state,
        street: property.street,
        district: property.district,
        number: property.number,
        complement: property.complement,
        reference: data.reference,
        coverages: selectedCoverages,
      };

      setInitialValues(normalizeData);
    } catch (error) {
      console.log(error);

      warningNotification(
        'Erro ao carregar os dados do orçamento',
        'Se o erro persistir contacte o suporte'
      );
    } finally {
      setLoadingInitialValues(false);
    }
  };

  async function loadDataForRenawalAlfaToAlfa() {
    setLoadingInitialValues(true);

    const budgetId = (location.pathname).replace('/admin/fire-insurance-budget/', '');

    if (!budgetId) {
      warningNotification(
        'Erro ao carregar os dados da apólice',
        'Se o erro persistir contacte o suporte'
      );
    }

    try {
      const data = await get(`/fire-insurance-quotations/alfa-seguros/budget/${budgetId}`);

      const { fireInsurance, channel, plan, coverages: cvrgs } = data;
      const { propertyAddresses: property } = fireInsurance;

      const selectedChannel = {
        id: channel.id,
        code: channel.code,
        name: channel.name,
        modality: channel.modality,
      };

      let selectedPropertyType, selectedOccupationType, selectedCoverages, plansByChannel;

      if (selectedChannel) {
        plansByChannel = handleChangeChannel(selectedChannel);

        const propertyTypeOptions = getPropertyTypeOptions(selectedChannel.modality);
        const occupationTypeOptions = getOccupationTypeOptions(selectedChannel.modality);

        if (propertyTypeOptions) {
          setPropertyTypeOptions(propertyTypeOptions);

          selectedPropertyType = propertyTypeOptions.find(
            c => +c.value === +fireInsurance?.propertyType
          )?.value;
        }

        if (occupationTypeOptions) {
          setOccupationOptions(occupationTypeOptions);

          selectedOccupationType = occupationTypeOptions.find(
            ot => +ot.value === +fireInsurance?.propertyUseType
          )?.value;
        }

        selectedCoverages = coverages.filter(c => c.coverage.channel.code === selectedChannel.code).map(c => {
          const hasCoverage = cvrgs?.find((cv: any) => cv.referenceCode === c.coverage.code);

          let value = 0;

          if (hasCoverage) {
            value = Number(hasCoverage.value);
          } else {
            value = (c.multiplier && c.multiplier > 0) ? c.multiplier * Number(fireInsurance?.rentValue) : 0;

            if (value > c.maxValue) {
              value = c.maxValue;
            }

            if (value < c.minValue) {
              value = c.minValue;
            }
          }

          return {
            id: c.id,
            code: c.coverage.code,
            checked: (c.coverage.isDefault || c.isRequired) ? true : hasCoverage ? true : false,
            value: value,
            name: c.coverage.name,
            onEdit: false,
            onEditValue: value,
            maxValue: c.maxValue,
            minValue: c.minValue,
            multiplier: c.multiplier,
            isDefault: c.coverage.isDefault,
            isRequired: c.isRequired,
            maxMultiplier: c.coverage.maxMultiplier
          };
        });
      }

      let startDate = new Date(fireInsurance.policyPeriodStart ?? Date.now());

      if(startDate < new Date()) {
        startDate = new Date();
      }

      const endDate = selectedChannel?.name?.includes('Anual')
        ? moment(startDate).add(1, 'year').toDate()
        : moment(startDate).add(1, 'month').toDate();

      const selectedPlan = plansByChannel?.find(plan => String(plan?.key) == String(data?.assistance));

      const normalizeData = {
        estateName: estate.name,
        // Step 1
        channel: selectedChannel,
        propertyType: selectedPropertyType,
        occupationType: selectedOccupationType,
        coverageType: data.coverageType,
        startVigency: startDate,
        endVigency: endDate,
        plans: selectedPlan?.value,
        rentValue: +fireInsurance.rentValue,
        installments: String(data.installments),
        // Step 2
        action: 0,
        tenantName: fireInsurance.tenantName,
        tenantDocument: fireInsurance.tenantDocument,
        discountPercentage: +data.discountPercentage,
        tenantEmail: fireInsurance.tenantEmail,
        tenantBirthdate: fireInsurance.tenantBirthdate ? moment(fireInsurance.tenantBirthdate).format('YYYY-MM-DD') : undefined,
        tenantCellphone: fireInsurance.tenantCellphone,
        tenantSex: fireInsurance.tenantGender === 'M' ? 'Masculino' : 'Feminino',
        propertyOwnerName: fireInsurance.propertyOwnerName,
        propertyOwnerDocument: fireInsurance.propertyOwnerDocument,
        planCode: plan.code,
        zipCode: property.zipcode,
        city: property.city,
        state: property.state,
        street: property.street,
        district: property.district,
        number: property.number,
        complement: property.complement,
        reference: data.reference,
        coverages: selectedCoverages,
      };

      setInitialValues(normalizeData);
    } catch (error) {
      console.log(error);

      warningNotification(
        'Erro ao carregar os dados da apólice',
        'Se o erro persistir contacte o suporte'
      );
    } finally {
      setLoadingInitialValues(false);
    }
  };

  async function loadDataForRenawalTokioToAlfa() {
    setLoadingInitialValues(true);

    const budgetId = (location.pathname).replace('/admin/fire-insurance-budget/', '');

    if (!budgetId) {
      warningNotification(
        'Erro ao carregar os dados da apólice',
        'Se o erro persistir contacte o suporte'
      );
    }

    try {
      const data = await get(`/fire-insurance-quotations/budget/${budgetId}`);

      const normalizeData = NormalizeTokioRenewalDataForAlfa(data, coverages);

      const occupationTypeOptions = getOccupationTypeOptions(normalizeData.channel?.modality);
      const propertyTypeOptions = getPropertyTypeOptions(normalizeData.channel?.modality);

      setOccupationOptions(occupationTypeOptions);
      setPropertyTypeOptions(propertyTypeOptions);

      setInitialValues(normalizeData)
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingInitialValues(false);
    }
  }

  useEffect(() => {
    if (!channels.length || !plans.length || !coverages.length) return;

    switch (context) {
      case BudgetContext.new:
        loadDataForNewBudget();
        break;
      case BudgetContext.editing:
      case BudgetContext.copyCanceled:
        loadDataForEditingOrCopy();
        break;
      case BudgetContext.renewal:
        if (policyOrigin === InsurersCodeEnum.alfa) {
          loadDataForRenawalAlfaToAlfa();
        } else if (policyOrigin === InsurersCodeEnum.TokioMarineSegimob) {
          loadDataForRenawalTokioToAlfa();
        }
        break;
      default:
        break;
    }
  }, [channels, plans, coverages]);

  const coveragesValidation = (coverages: any[]) => {
    const max = 2500000;
    const sum = coverages.reduce((acc, c) => acc + c.value, 0);
    if (sum > max) {
      warningNotification(
        'Valor total das coberturas ultrapassou o limite',
        `O valor total das coberturas não pode ultrapassar R$ ${max},00`
      );
      return false;
    }
    return true;
  };

  return (
    <>
      {/* {channels && plans && coverages.length > 0 && initialValues && ( */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldTouched,
            setFieldValue,
            submitForm,
            isSubmitting,
          }) => {
            const onChangeCoverage = (field: string, valueMask: string): void => {
              hasChange();
              const onlyNumber = valueMask.replace(/\D/g, '');
              const value = Number(onlyNumber) / 100;

              setFieldValue(field, value);
            };

            const w100 = {
              width: '100%'
            };

            const columnDistance = {
              marginBottom: 20
            };

            return (
              <>
                <Dimmer active={isSubmitting} inverted page>
                  <Loader indeterminate> Gerando orçamento... </Loader>
                </Dimmer>

                <Form>
                  <Dimmer inverted active={loadingInitialValues}>
                    <Loader content='Carregando informações...' />
                  </Dimmer>

                  <S.Container>
                    <Grid style={w100}>
                      <Grid.Column
                        computer={8}
                        mobile={16}
                        tablet={16}
                        style={columnDistance}
                      >
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={16}>
                              <S.Subtitle>Dados para cotação</S.Subtitle>
                            </Grid.Column>
                            <Grid.Column width={16}>
                              <S.Hr />
                            </Grid.Column>
                          </Grid.Row>

                          <Grid.Row>
                            <Grid.Column
                              computer={16}
                              mobile={16}
                              tablet={16}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>
                                FINALIDADE DA LOCAÇÃO
                                <Popup
                                  trigger={
                                    <Icon
                                      link
                                      name='info circle'
                                      color='blue'
                                      style={{ paddingLeft: '10px' }}
                                    />
                                  }
                                >
                                  <List size='medium'>
                                    <ListItem>
                                      <Icon name='caret right'/>
                                      <ListContent>
                                        <ListHeader>Residencial ou Comercial anual:</ListHeader>
                                        <ListDescription>
                                          A vigência será por 1 (um) ano e o parcelamento poderá ser até 11 vezes.
                                        </ListDescription>
                                      </ListContent>
                                    </ListItem>
                                    <ListItem>
                                      <Icon name='caret right'/>
                                      <ListContent>
                                        <ListHeader>Residencial ou Comercial mensalizado:</ListHeader>
                                        <ListDescription>
                                          A vigência será mensal com renovação automática e a cobrança ocorrerá mensalmente, assim, não será necessário proceder com a renovação durante a vigência do contrato de locação. No momento da rescisão contratual deverá proceder com o cancelamento.
                                        </ListDescription>
                                      </ListContent>
                                    </ListItem>
                                  </List>
                                </Popup>
                              </FieldLabel>
                              <Dropdown
                                fluid
                                search
                                clearable
                                selection
                                name={'channel'}
                                placeholder={'Selecione'}
                                options={channels}
                                value={values.channel}
                                onChange={(_event, { value }: any) => {
                                  hasChange();
                                  setFieldValue('channel', value);
                                  setFieldTouched('channel', false, false);
                                  setFieldValue('discount', 0);
                                  setHasDiscount(false);

                                  if (value?.modality) {
                                    setOccupationOptions(
                                      getOccupationTypeOptions(value?.modality)
                                    );

                                    setPropertyTypeOptions(
                                      getPropertyTypeOptions(value.modality)
                                    );

                                    if (value.modality !== 'Residencial') {
                                      setFieldValue('propertyType', '1099');
                                      setChannelIsCompany(true);
                                    } else {
                                      setFieldValue('propertyType', '');
                                      setChannelIsCompany(false);
                                    }

                                    let initDate = new Date(Date.now());

                                    if(initDate < new Date()) {
                                      initDate = new Date();
                                    }
                                    setFieldValue('startVigency', initDate);
                                    if (value.name.includes('Anual')) {
                                      setFieldValue('endVigency',
                                      moment(initDate)
                                        .add(1, 'year')
                                          .toDate());
                                      setPriceLabel(`${values.installments}x `);
                                    } else {
                                      setFieldValue('endVigency',
                                      moment(initDate)
                                        .add(1, 'month')
                                        .toDate());
                                      setPriceLabel('Mensal ');
                                    }

                                    const cvgs = coverages
                                      .filter(
                                        c =>
                                          c.coverage.channel.code === value.code
                                      )
                                      .map(c => ({
                                        id: c.id,
                                        code: c.coverage.code,
                                        checked: c.isRequired,
                                        value: 0,
                                        name: c.coverage.name,
                                        onEdit: false,
                                        onEditValue: 0,
                                        maxValue: c.maxValue,
                                        minValue: c.minValue,
                                        multiplier: c.multiplier,
                                        isDefault: c.coverage.isDefault,
                                        isRequired: c.isRequired,
                                        maxMultiplier: c.coverage.maxMultiplier
                                      }));

                                    values.coverages = cvgs;

                                    if (values.rentValue) {
                                      values.coverages.forEach((c: any, index: number) => {
                                        const multiplier = c.maxMultiplier
                                          ? c.maxMultiplier < c.multiplier
                                            ? c.maxMultiplier
                                            : c.multiplier
                                          : c.multiplier;
                                        const value = values.rentValue * multiplier;
                                        if (value > c.maxValue) {
                                          setFieldValue(
                                            `coverages[${index}].value`,
                                            c.maxValue
                                          );
                                          setFieldValue(
                                            `coverages[${index}].onEditValue`,
                                            c.maxValue
                                          );
                                          return;
                                        }
                                        if (value < c.minValue) {
                                          setFieldValue(
                                            `coverages[${index}].value`,
                                            c.minValue
                                          );
                                          setFieldValue(
                                            `coverages[${index}].onEditValue`,
                                            c.minValue
                                          );
                                          return;
                                        }
                                        setFieldValue(`coverages[${index}].value`, value);
                                        setFieldValue(
                                          `coverages[${index}].onEditValue`,
                                          value
                                        );
                                      });
                                    }

                                    setFieldValue('plans', '');
                                  }
                                }}
                                error={
                                  touched.channel &&
                                  errors.channel === 'error'
                                }
                              />
                            </Grid.Column>
                          </Grid.Row>

                          <Grid.Row>
                            {!channelIsCompany && (
                              <Grid.Column
                                computer={8}
                                mobile={16}
                                tablet={16}
                                style={columnDistance}
                              >
                                <FieldLabel paddingBottom>TIPO DO IMÓVEL</FieldLabel>
                                <Dropdown
                                  fluid
                                  search
                                  clearable
                                  selection
                                  name={'propertyType'}
                                  placeholder={'Selecione o tipo do imóvel'}
                                  disabled={values.channel?.modality !== 'Residencial'}
                                  options={propertyTypeOptions}
                                  value={values.propertyType}
                                  onChange={(_event, { value }) => {
                                    hasChange();
                                    setFieldValue('propertyType', value);
                                    setFieldTouched('propertyType', false, false);
                                    const occupationTypeOptions = getOccupationTypeOptions(
                                      values?.channel?.modality,
                                      String(value)
                                    );
                                    setOccupationOptions(occupationTypeOptions);
                                    if (occupationTypeOptions.length === 1) {
                                      values.occupationType = occupationTypeOptions[0].value;
                                    }
                                  }}
                                  error={
                                    touched.propertyType &&
                                    errors.propertyType === 'error'
                                  }
                                />
                              </Grid.Column>
                            )}

                            <Grid.Column
                              computer={8}
                              mobile={16}
                              tablet={16}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>OCUPAÇÃO</FieldLabel>
                              <Dropdown
                                fluid
                                search
                                clearable
                                selection
                                name={'occupationType'}
                                placeholder={'Selecione a ocupação'}
                                options={occupationOptions}
                                value={values.occupationType}
                                onChange={(_event, { value }) => {
                                  hasChange();
                                  setFieldValue('occupationType', value);
                                  setFieldTouched('occupationType', false, false);
                                }}
                                error={
                                  touched.occupationType &&
                                  errors.occupationType === 'error'
                                }
                              />
                            </Grid.Column>

                            {channelIsCompany && (
                              <Grid.Column
                                computer={8}
                                mobile={16}
                                tablet={16}
                                style={columnDistance}
                              >
                                <FieldLabel paddingBottom>TIPO DE COBERTURA</FieldLabel>
                                <Dropdown
                                  fluid
                                  selection
                                  name={'coverageType'}
                                  placeholder={'Selecione'}
                                  options={coverageTypesOptions}
                                  value={values.coverageType}
                                  disabled
                                />
                              </Grid.Column>
                            )}
                          </Grid.Row>

                          <Grid.Row>
                            {!channelIsCompany && (
                              <Grid.Column
                                computer={6}
                                mobile={16}
                                tablet={16}
                                style={columnDistance}
                              >
                                <FieldLabel paddingBottom>TIPO DE COBERTURA</FieldLabel>
                                <Dropdown
                                  fluid
                                  // search
                                  // clearable
                                  selection
                                  name={'coverageType'}
                                  placeholder={'Selecione'}
                                  options={coverageTypesOptions}
                                  value={values.coverageType}
                                  disabled
                                // onChange={(_event, { value }) => {
                                //   setFieldValue('coverageType', value);
                                //   setFieldTouched('coverageType', false, false);
                                // }}
                                // error={
                                //   touched.coverageType && errors.coverageType === 'error'
                                // }
                                />
                              </Grid.Column>
                            )}

                            <Grid.Column
                              computer={channelIsCompany ? 8 : 5}
                              mobile={16}
                              tablet={16}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>INÍCIO DA VIGÊNCIA</FieldLabel>
                              <S.DatePickerWrapper>
                                <CustomDatePicker
                                  name={'startVigency'}
                                  dateFormat={'dd/MM/yyyy'}
                                  placeholderText={'__/__/____'}
                                  selected={values.startVigency ?? undefined}
                                  disabled
                                  customInput={
                                    <AppMaskedInput
                                      mask={DATE_INPUT_MASK}
                                      error={errors.startVigency === 'error'}
                                    />
                                  }
                                  // onChange={(date: Date) => {
                                  //   setFieldValue('startVigency', date ?? undefined);
                                  //   setFieldError('startVigency', '');
                                  // }}
                                />
                              </S.DatePickerWrapper>
                            </Grid.Column>

                            <Grid.Column
                              computer={channelIsCompany ? 8 : 5}
                              mobile={16}
                              tablet={16}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>FINAL DA VIGÊNCIA</FieldLabel>
                              <S.DatePickerWrapper>
                                <CustomDatePicker
                                  name={'endVigency'}
                                  dateFormat={'dd/MM/yyyy'}
                                  placeholderText={'__/__/____'}
                                  disabled
                                  selected={values.endVigency ?? undefined}
                                  customInput={
                                    <AppMaskedInput
                                      mask={DATE_INPUT_MASK}
                                      error={errors.endVigency === 'error'}
                                    />
                                  }
                                  // onChange={(date: Date) => {
                                  //   setFieldValue('endVigency', date ?? undefined);
                                  //   setFieldError('endVigency', '');
                                  // }}
                                />
                              </S.DatePickerWrapper>
                            </Grid.Column>
                          </Grid.Row>

                          <Grid.Row>
                            <Grid.Column
                              computer={8}
                              mobile={16}
                              tablet={16}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>
                                ASSISTÊNCIA
                                <Icon
                                  link
                                  name='info circle'
                                  color='blue'
                                  style={{ paddingLeft: '10px' }}
                                  onClick={() => setOpenAssistanceDetailsModal(true)}
                                />
                              </FieldLabel>
                              <Dropdown
                                fluid
                                search
                                clearable
                                selection
                                name={'plans'}
                                placeholder={'Selecione uma opção...'}
                                options={handleChangeChannel(values.channel)}
                                value={values.plans}
                                onChange={(_event, { value }) => {
                                  hasChange();
                                  setFieldValue('plans', value);
                                  setFieldTouched('plans', false, false);
                                }}
                                error={
                                  touched.plans &&
                                  errors.plans === 'error'
                                }
                              />
                            </Grid.Column>

                            <Grid.Column
                              computer={8}
                              mobile={16}
                              tablet={16}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>PL(%)</FieldLabel>
                              <Input
                                fluid
                                disabled
                                name={'pl'}
                                value={alfaConfigs?.compensation || '-'}
                              />
                            </Grid.Column>
                          </Grid.Row>

                          <Grid.Row>
                          <Grid.Column
                              computer={8}
                              mobile={16}
                              tablet={16}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>VALOR DO ALUGUEL</FieldLabel>
                              <Input
                                fluid
                                name={'rentValue'}
                                placeholder={'Valor do Aluguel'}
                                value={
                                  values.rentValue
                                    ? numberToMoneyString(values.rentValue)
                                    : ''
                                }
                                onChange={event => {
                                  onChangeCoverage('rentValue', event.target.value);
                                  setFieldTouched('rentValue', false, false);
                                }}
                                onBlur={() => {
                                  values.coverages.forEach((c: any, index: number) => {
                                    const multiplier = c.maxMultiplier
                                      ? c.maxMultiplier < c.multiplier
                                        ? c.maxMultiplier
                                        : c.multiplier
                                      : c.multiplier;
                                    const value = values.rentValue * multiplier;
                                    if (value > c.maxValue) {
                                      setFieldValue(
                                        `coverages[${index}].value`,
                                        c.maxValue
                                      );
                                      setFieldValue(
                                        `coverages[${index}].onEditValue`,
                                        c.maxValue
                                      );
                                      return;
                                    }
                                    if (value < c.minValue) {
                                      setFieldValue(
                                        `coverages[${index}].value`,
                                        c.minValue
                                      );
                                      setFieldValue(
                                        `coverages[${index}].onEditValue`,
                                        c.minValue
                                      );
                                      return;
                                    }
                                    setFieldValue(`coverages[${index}].value`, value);
                                    setFieldValue(
                                      `coverages[${index}].onEditValue`,
                                      value
                                    );
                                  });
                                }}
                                error={
                                  touched.rentValue &&
                                  errors.rentValue === 'error'
                                }
                              />
                            </Grid.Column>

                            <Grid.Column
                              computer={8}
                              mobile={16}
                              tablet={16}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>Nº DE PARCELAS</FieldLabel>
                              <Dropdown
                                fluid
                                search
                                clearable
                                selection
                                name={'installments'}
                                placeholder={'Selecione uma opção...'}
                                options={
                                  values?.channel?.name.includes('Mensal') ?
                                    installmentsOptions.filter(opt => opt.value === '1') :
                                    installmentsOptions
                                }
                                value={values.installments}
                                onChange={async (_event, { value }) => {
                                  hasChange();
                                  values.installments = value;

                                  if (values?.channel?.name.includes('Anual')) {
                                    setPriceLabel(`${value}x `);
                                  }
                                  setFieldTouched('installments', false, false);
                                }}
                                error={touched.installments && errors.installments === 'error'}
                                onClose={() => submitForm()}
                              />
                            </Grid.Column>
                          </Grid.Row>

                          {/* <Grid.Row>
                            <Grid.Column width={16}>
                              <S.SecctionName>Dados do Segurado</S.SecctionName>
                            </Grid.Column>
                            <Grid.Column width={16}>
                              <S.Hr />
                            </Grid.Column>
                          </Grid.Row>

                          <Grid.Row>
                            <Grid.Column
                              computer={8}
                              mobile={16}
                              tablet={16}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>CPF/CNPJ</FieldLabel>
                              <Input
                                fluid
                                name={'tenantDocument'}
                                placeholder={'Documento do Segurado'}
                                value={maskCpfOrCnpj(values.tenantDocument)}
                                onChange={handleOnChange}
                                onBlur={() => onBlurDocument(values.tenantDocument, true)}
                                error={
                                  touched.tenantDocument &&
                                  errors.tenantDocument === 'error'
                                }
                              />
                            </Grid.Column>

                            <Grid.Column
                              computer={8}
                              mobile={16}
                              tablet={16}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>NOME</FieldLabel>
                              <Input
                                fluid
                                name="tenantName"
                                placeholder={'Nome do Segurado'}
                                value={values.tenantName}
                                onChange={handleOnChange}
                                error={
                                  touched.tenantName && errors.tenantName === 'error'
                                }
                              />
                            </Grid.Column>
                          </Grid.Row> */}

                         {/* {isCPF && (
                            <Grid.Row> */}
                              {/* <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel>
                            DATA DE NASCIMENTO
                            </FieldLabel>
                            <S.DatePickerWrapper>
                              <CustomDatePicker
                                name={'tenantBirthdate'}
                                dateFormat={'dd/MM/yyyy'}
                                placeholderText={'__/__/____'}
                                selected={values.tenantBirthdate ?? undefined}
                                customInput={
                                  <AppMaskedInput
                                    mask={DATE_INPUT_MASK}
                                    error={errors.tenantBirthdate === 'error'}
                                  />
                                }
                                onChange={(date: Date) => {
                                  setFieldValue('tenantBirthdate', date ?? undefined);
                                  setFieldError('tenantBirthdate', '');
                                }}
                              />
                            </S.DatePickerWrapper>
                          </Grid.Column> */}

                              {/* <Grid.Column
                                computer={8}
                                mobile={16}
                                tablet={16}
                                style={columnDistance}
                              >
                                <FieldLabel paddingBottom>SEXO</FieldLabel>
                                <Dropdown
                                  fluid
                                  clearable
                                  selection
                                  name={'tenantSexType'}
                                  options={SEX_TYPE}
                                  placeholder={'Selecione o sexo do Segurado'}
                                  loading={loadDocument}
                                  value={values.tenantSexType}
                                  onChange={(_event, { value }) => {
                                    setFieldValue('tenantSexType', value);
                                    setFieldTouched('tenantSexType', false, false);
                                  }}
                                  error={
                                    touched.tenantSexType &&
                                    errors.tenantSexType === 'error'
                                  }
                                />
                              </Grid.Column> */}
                            {/* </Grid.Row>
                          )} */}

                          {/* <Grid.Row>
                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>EMAIL</FieldLabel>
                            <Input
                              fluid
                              name={'tenantEmail'}
                              placeholder={'Email do Segurado'}
                              loading={loadDocument}
                              value={values.tenantEmail}
                              onChange={handleOnChange}
                              error={
                                touched.tenantEmail &&
                                errors.tenantEmail === 'error'
                              }
                            />
                          </Grid.Column>
                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>TELEFONE</FieldLabel>
                            <Input
                              fluid
                              name={'tenantCellphone'}
                              placeholder={'Telefone do Segurado'}
                              loading={loadDocument}
                              value={
                                values.tenantCellphone
                                  ? maskPhoneNumber(values.tenantCellphone)
                                  : ''
                              }
                              onChange={handleOnChange}
                              error={
                                touched.tenantCellphone &&
                                errors.tenantCellphone === 'error'
                              }
                            />
                          </Grid.Column>
                        </Grid.Row> */}

                          {/* <Grid.Row>
                          <Grid.Column width={16}>
                            <S.SecctionName>Dados do Beneficiários</S.SecctionName>
                          </Grid.Column>
                          <Grid.Column width={16}>
                            <S.Hr />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>CPF/CNPJ</FieldLabel>
                            <Input
                              fluid
                              name={'beneficiaryDocument'}
                              placeholder={'Documento do beneficiario'}
                              loading={loadDocument}
                              value={maskCpfOrCnpj(values.beneficiaryDocument)}
                              onChange={handleOnChange}
                              onBlur={() =>
                                onBlurDocument(values.beneficiaryDocument, false)
                              }
                              error={
                                touched.beneficiaryDocument &&
                                errors.beneficiaryDocument === 'error'
                              }
                            />
                          </Grid.Column>

                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={columnDistance}
                          >
                            <FieldLabel paddingBottom>NOME</FieldLabel>
                            <Input
                              fluid
                              name={'beneficiaryName'}
                              placeholder={'Nome do Beneficiario'}
                              loading={loadDocument}
                              value={values.beneficiaryName}
                              onChange={handleOnChange}
                              error={
                                touched.beneficiaryName &&
                                errors.beneficiaryName === 'error'
                              }
                            />
                          </Grid.Column>
                        </Grid.Row> */}

                          {/* <Grid.Row>
                            <Grid.Column width={16}>
                              <S.SecctionName>Endereço do imóvel</S.SecctionName>
                            </Grid.Column>
                            <Grid.Column width={16}>
                              <S.Hr />
                            </Grid.Column>
                          </Grid.Row> */}

                          {/* <Grid.Row>
                            <Grid.Column
                              computer={8}
                              mobile={16}
                              tablet={16}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>CEP</FieldLabel>
                              <Input
                                fluid
                                name={'zipCode'}
                                placeholder={'Digite o CEP'}
                                value={values.zipCode ? maskZipCode(values.zipCode) : ''}
                                onChange={(_event, data: InputOnChangeData) => {
                                  hasChange();
                                  onChangeZipCode(data.value);
                                  setFieldTouched('zipCode', false, false);
                                }}
                                error={touched.zipCode && errors.zipCode === 'error'}
                              />
                            </Grid.Column>
                          </Grid.Row> */}

                          {/* <Grid.Row>
                            <Grid.Column
                              computer={8}
                              mobile={16}
                              tablet={16}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>LOGRADOURO</FieldLabel>
                              <Input
                                fluid
                                name={'street'}
                                placeholder={'Digite o logradouro'}
                                loading={zipCodeLoading}
                                value={values.street}
                                onChange={handleOnChange}
                                error={touched.street && errors.street === 'error'}
                              />
                            </Grid.Column>

                            <Grid.Column
                              computer={8}
                              mobile={16}
                              tablet={16}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>BAIRRO</FieldLabel>
                              <Input
                                fluid
                                name={'district'}
                                placeholder={'Digite o bairro'}
                                loading={zipCodeLoading}
                                value={values.district}
                                onChange={handleOnChange}
                                error={touched.district && errors.district === 'error'}
                              />
                            </Grid.Column>
                          </Grid.Row> */}

                          {/* <Grid.Row>
                            <Grid.Column
                              computer={8}
                              mobile={16}
                              tablet={16}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>CIDADE</FieldLabel>
                              <Input
                                fluid
                                name={'city'}
                                placeholder={'Digite a cidade'}
                                loading={zipCodeLoading}
                                value={values.city}
                                onChange={handleOnChange}
                                error={touched.city && errors.city === 'error'}
                              />
                            </Grid.Column>

                            <Grid.Column
                              computer={8}
                              mobile={16}
                              tablet={16}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>ESTADO</FieldLabel>
                              <Input
                                fluid
                                name={'state'}
                                placeholder={'Digite o estado'}
                                loading={zipCodeLoading}
                                value={values.state}
                                onChange={handleOnChange}
                                error={touched.state && errors.state === 'error'}
                              />
                            </Grid.Column>
                          </Grid.Row> */}

                          {/* <Grid.Row>
                            <Grid.Column
                              computer={4}
                              mobile={16}
                              tablet={8}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>NÚMERO</FieldLabel>
                              <Input
                                fluid
                                type={'number'}
                                name={'number'}
                                placeholder={'Digite o número'}
                                value={values.number}
                                onChange={handleChange}
                              />
                            </Grid.Column>

                            <Grid.Column
                              computer={12}
                              mobile={16}
                              tablet={8}
                              style={columnDistance}
                            >
                              <FieldLabel paddingBottom>COMPLEMENTO</FieldLabel>
                              <Input
                                fluid
                                name={'complement'}
                                placeholder={'Complemento...'}
                                value={values.complement}
                                onChange={handleChange}
                              />
                            </Grid.Column>
                          </Grid.Row> */}
                        </Grid>
                      </Grid.Column>

                      <Grid.Column
                        computer={8}
                        mobile={16}
                        tablet={16}
                        style={columnDistance}
                      >
                        <Sticky offset={80}>
                          <S.PreQuoteWrapper>
                            <S.PreQuoteHeader>
                              <span className="left">
                                <Icon name="shield" />
                                Orçamento de Seguro
                              </span>
                            </S.PreQuoteHeader>

                            <Grid style={{ height: 'calc(100% - 60px)', margin: 0 }}>
                              <S.SecctionName>
                                Coberturas disponíveis
                                {values.channel && <Icon
                                  link
                                  name="info circle"
                                  onClick={() => setOpenCovarageDetailsModal(true)}
                                  style={{ paddingLeft: '10px' }}
                                />}
                              </S.SecctionName>

                              <S.CoveragesRow>
                                <div
                                  className="low-opacity"
                                  onClick={() => {
                                    hasChange();
                                    setFieldValue(
                                      'coverages',
                                      values?.coverages?.map((c: any) => ({
                                        ...c,
                                        checked: !allChecked
                                      }))
                                    );
                                  }}
                                >
                                  {/* <span style={{ cursor: 'pointer' }}>
                                    <input type="checkbox" checked={allChecked} />
                                    Habilitar/Desabilitar todas as coberturas adicionais
                                  </span> */}
                                </div>
                              </S.CoveragesRow>
                              {values?.coverages?.map((c: any, index: number) => {
                                return (
                                <S.CoveragesRow key={c.id}>
                                  <div>
                                    <span
                                      className={'full-insurance-name'}
                                      style={{ color: c.checked ? '#000000' : '#A3A3A3' }}
                                    >
                                      <input
                                        type="checkbox"
                                        disabled={c.isRequired}
                                        onClick={() => {
                                          if (c.isRequired) {
                                            return;
                                          }
                                          setFieldValue(
                                            `coverages[${index}].checked`,
                                            !c.checked
                                          );
                                          hasChange();
                                        }}
                                        checked={c.checked || false}
                                      />
                                      {c.name}
                                    </span>
                                  </div>

                                  <div>
                                    {!c.onEdit ? (
                                      <div className='noEditContainer'>
                                        <div className='noEdit'>
                                          <span
                                            className="insurance-value"
                                            style={{ color: c.checked ? '#2185d0' : '#A3A3A3' }}
                                          >
                                            {c.value ? numberToMoneyString(c.value) : ''}
                                          </span>
                                          {/* <span className="insurance-premium">
                                          {c.premiumValue > 0 ? 'Prêmio: '+numberToMoneyString(c.premiumValue) : ''}
                                        </span> */}
                                        </div>
                                        <Icon
                                          name="pencil"
                                          className={"low-opacity  pencil-icon " + (c.premiumValue > 0 ? "marginTop" : "")}
                                            onClick={() => {
                                              setIsEditing(true);
                                              setFieldValue(`coverages[${index}].onEdit`, true);
                                            }
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <div className="editable-coverages">
                                        <Input
                                          fluid
                                          className="editable-coverages-input"
                                          value={
                                            c.onEditValue
                                              ? numberToMoneyString(c.onEditValue)
                                              : ''
                                          }
                                          onChange={event =>
                                            onChangeCoverage(
                                              `coverages[${index}].onEditValue`,
                                              event.target.value
                                            )
                                          }
                                        />
                                        <Icon
                                          className="icon"
                                          name="check"
                                          color="green"
                                          onClick={() => {
                                            const value = c.onEditValue;

                                            if (
                                              value < c.minValue ||
                                              value > c.maxValue
                                            ) {
                                              if (c.name.includes('Aluguel')) {
                                                warningNotification(
                                                  'Valor inválido',
                                                  `O valor da cobertura ${c.name} não pode ser menor que ${c.minValue}.`
                                                );
                                                return;
                                              } else {
                                                warningNotification(
                                                  'Valor inválido',
                                                  `O valor da cobertura ${c.name} deve ser entre ${c.minValue} e ${c.maxValue}`
                                                );
                                                return;
                                              }
                                            }

                                            if (
                                              c.maxMultiplier &&
                                              value > c.maxMultiplier * values.rentValue
                                            ) {
                                              if (c.name.includes('Aluguel')) {
                                                warningNotification(
                                                  'Valor inválido',
                                                  `O valor da cobertura ${c.name} não pode ser menor que ${c.minValue}.`
                                                );
                                                return;
                                              } else {
                                                warningNotification(
                                                  'Valor inválido',
                                                  `O valor da cobertura ${c.name} não pode ser maior que ${c.maxMultiplier * values.rentValue}`
                                                );
                                              }
                                              return;
                                            }

                                            if (
                                              c.name.includes('Aluguel') &&
                                              value > (c.multiplier * values.rentValue)
                                            ) {
                                              warningNotification(
                                                'Valor inválido',
                                                `O valor da cobertura ${c.name} não pode ser maior que 6x o valor do aluguel.`
                                              );
                                              return;
                                            }

                                            setFieldValue(`coverages[${index}]`, {
                                              ...c,
                                              value: c.onEditValue,
                                              onEdit: false,
                                              premiumValue: 0
                                            });

                                            setIsEditing(false);
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </S.CoveragesRow>
                              )})}

                              {step === 1 && grossPremium && (
                                <>
                                  <S.Hr style={w100} />
                                  <Grid style={w100}>
                                    <Grid.Row>
                                     {
                                        values.channel?.modality === 'Residencial' && alfaConfigs?.residentialDiscount > 0
                                     || values.channel?.modality !== 'Residencial' && alfaConfigs?.commercialDiscount  > 0
                                      ?
                                     <S.CoveragesRow>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                            width: '100%'
                                          }}
                                        >
                                          <input
                                            type="checkbox"
                                            style={{
                                              margin: 0,
                                              marginRight: 5
                                            }}
                                            checked={hasDiscount}
                                            onChange={() => setHasDiscount(!hasDiscount)}
                                          />
                                          <span
                                            style={{
                                              color: '#1D79BE'
                                            }}
                                          >
                                            Aplicar desconto
                                          </span>

                                          <span
                                            style={{
                                              color: '#A3A3A3'
                                            }}
                                          >
                                            {' '}
                                            (Opcional)
                                          </span>
                                        </div>
                                      </S.CoveragesRow>
                                    : <></>}
                                    </Grid.Row>
                                    {hasDiscount && (
                                      <Grid.Row>
                                        <Grid.Column
                                          computer={13}
                                          mobile={13}
                                          tablet={13}
                                          style={columnDistance}
                                        >
                                          <RangeSlider
                                            max={
                                              values.channel?.modality === 'Residencial'
                                                ? Number(
                                                    alfaConfigs.residentialDiscount ?? 0
                                                  )
                                                : Number(
                                                    alfaConfigs.commercialDiscount ?? 0
                                                  )
                                            }
                                            min={0}
                                            step={1}
                                            value={values.discount || 0}
                                            onChange={(value: number) =>
                                              {
                                                setFieldValue('discount', value);
                                                setDiscount(value);
                                                data.totalPremium = data?.maxPremium - (data?.maxPremium * (value / 100));
                                              }
                                            }
                                          />
                                        </Grid.Column>

                                        <Grid.Column
                                          computer={3}
                                          mobile={3}
                                          tablet={3}
                                          style={{
                                            ...columnDistance,
                                            marginTop: -10
                                          }}
                                        >
                                          <Input
                                            fluid
                                            value={values.discount}
                                            onChange={(
                                              event: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                              const number = event.target.value.replace(
                                                '%',
                                                ''
                                              );

                                              const max = values.channel?.modality === 'Residencial'
                                              ? Number(
                                                  alfaConfigs.residentialDiscount ?? 0
                                                )
                                              : Number(
                                                  alfaConfigs.commercialDiscount ?? 0
                                                )
                                              if (Number(number) > max) {
                                                setFieldValue('discount', max);
                                                setDiscount(max);
                                                return;
                                              }
                                              setFieldValue('discount', number);
                                              setDiscount(Number(number));
                                            }}
                                            name={'discount'}
                                            type="number"
                                            min={0}
                                            max={values.channel?.modality === 'Residencial'
                                              ? Number(
                                                  alfaConfigs.residentialDiscount ?? 0
                                                )
                                              : Number(
                                                  alfaConfigs.commercialDiscount ?? 0
                                                )}
                                          />
                                        </Grid.Column>
                                      </Grid.Row>
                                    )}
                                  </Grid>
                                  {hasDiscount && <S.Hr style={w100} />}
                                  <Grid style={w100}>
                                    <Grid.Row>
                                      <Grid.Column
                                        computer={10}
                                        mobile={10}
                                        tablet={10}
                                        style={columnDistance}
                                      >
                                        <FieldLabel paddingBottom>
                                          <span
                                            style={{
                                              color: '#1D79BE'
                                            }}
                                          >
                                            Seu orçamento
                                          </span>
                                        </FieldLabel>
                                        <FieldLabel
                                          paddingBottom
                                          style={{
                                            color: '#1D79BE'
                                          }}
                                        >
                                          {/* {values.installments}x{' '} */}
                                          {priceLabel}
                                          <span
                                            style={{
                                              fontWeight: 800,
                                              fontSize: 22
                                            }}
                                          >
                                            {hasDiscount && values.discount
                                              ? numberToMoneyString(
                                                  Number(
                                                    (grossPremium -
                                                      (grossPremium * values.discount) /
                                                        100) /
                                                      values.installments
                                                  )
                                                )
                                              : numberToMoneyString(
                                                  grossPremium / values.installments
                                                )}
                                          </span>
                                        </FieldLabel>
                                        <FieldLabel paddingBottom>
                                          <span
                                            style={{
                                              color: '#999999'
                                            }}
                                          >
                                            Total:{' '}
                                            <span>
                                              {hasDiscount && values.discount
                                                ? numberToMoneyString(
                                                    grossPremium -
                                                      (grossPremium * values.discount) /
                                                        100
                                                  )
                                                : numberToMoneyString(grossPremium)}
                                            </span>
                                          </span>
                                        </FieldLabel>
                                      </Grid.Column>

                                      <Grid.Column
                                        computer={5}
                                        mobile={5}
                                        tablet={5}
                                        style={columnDistance}
                                      >
                                        <Popup
                                          content={'Contratar'}
                                          trigger={
                                            <div
                                              style={{
                                                marginBottom: 10
                                              }}
                                            >
                                              <Button
                                                icon
                                                color="blue"
                                                fluid
                                                onClick={() => {
                                                  setSavedData(values);
                                                  setIsHireRequest(true);
                                                  setOpenCreatePolicyModal(true);
                                                }}
                                                disabled={isEditing}
                                              >
                                                <Icon name={'check'} /> Contratar
                                              </Button>
                                            </div>
                                          }
                                        />

                                        <Popup
                                          content={'Salvar orçamento'}
                                          trigger={
                                            <Button
                                              basic
                                              icon
                                              color="blue"
                                              fluid
                                              onClick={() => {
                                                setIsHireRequest(false);
                                                setOpenCreatePolicyModal(true);
                                                setSavedData(values);
                                              }}
                                              disabled={isEditing}
                                            >
                                              <Icon name={'save'} /> Salvar cotação
                                            </Button>
                                          }
                                        />
                                      </Grid.Column>
                                    </Grid.Row>
                                  </Grid>
                                </>
                              )}

                              {step === 0 && (
                                <S.Summarysection>
                                  <div className="buttons">
                                    <Button primary
                                      onClick={
                                        () => {
                                          if (coveragesValidation(values.coverages)) {
                                            return submitForm();
                                          }
                                        }
                                      }
                                    >
                                      <Icon name="clipboard check" />
                                      {isEdit ? 'Atualizar Orçamento' : 'Criar Orçamento'}
                                    </Button>
                                  </div>
                                </S.Summarysection>
                              )}
                            </Grid>
                          </S.PreQuoteWrapper>
                        </Sticky>
                      </Grid.Column>
                    </Grid>
                  </S.Container>

                  {openCoverageDetailsModal && <CoverageDetailModal
                    open={openCoverageDetailsModal}
                    onClose={() => setOpenCovarageDetailsModal(false)}
                    data={values.coverages}
                  />}
                </Form>
              </>
            );
          }}
        </Formik>
      {/* )} */}
      {requestData && openCreatePolicyModal && (
        <AlfaCreatePolicyModal
          open={openCreatePolicyModal}
          onClose={() => setOpenCreatePolicyModal(false)}
          context={context}
          data={data}
          requestData={requestData}
          isHireRequest={isHireRequest}
          fireInsuranceAnalysisId={budgetId}
        />
      )}

      {openAssistanceDetailsModal && (
        <AssistanceDetailsModal
          open={openAssistanceDetailsModal}
          onClose={() => setOpenAssistanceDetailsModal(false)}
        />
      )}
    </>
  );
};

const mapState = (state: iRootState) => {};

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateCurrentPage: (state: CurrentPageState) =>
    dispatch.currentPage.updateCurrentPage(state)
});

export default connect(mapState, mapDispatch)(AlfaSeguroBudgetForm);
