import React from 'react';
import Dropdown from './Dropdown';
import { BankOptions, listEstateBanks } from '../resources/bankCodeList';
import { DropdownProps } from 'semantic-ui-react';
import { dangerNotification } from '../services/notification';

const BankCodeDropdown = (props: DropdownProps) => {
  const [banks, setBanks] = React.useState<BankOptions[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {

    async function fetchBanks() {
      setLoading(true);
      try {
        const banks = await listEstateBanks();
        setBanks(banks);
      }catch(err) {
        console.log(err)
        dangerNotification('Erro', 'Erro ao carregar lista de bancos');
      }finally {
        setLoading(false);
      }
    }
    fetchBanks();
  }, []);

  return (
    <>
      <Dropdown
        {...props}
        placeholder={'Selecione código bancário...'}
        options={banks}
        loading={loading}
        search
      />
    </>
  );
};

export default BankCodeDropdown;
