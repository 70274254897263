/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import packageInfo from '../../package.json';
import { Image, Menu as SemanticMenu, Responsive, Icon } from 'semantic-ui-react';

import { menuOptions } from '../services/menu';
import { MenuItemProps } from './types';

import MenuItem from './MenuItem';

import logo from '../resources/imgs/logo.png';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { iRootState } from '../store';
import AuthorizationContainer from '../components/AuthorizationContainer';
import { ActionEnum } from '../enums/authz-action.enum';
import useWindowSize from '../hooks/useWindowSize';
import { ConfigKeysEnum, getConfigKey } from '../services/configKeys';
import { dangerNotification } from '../services/notification';
import { get, set } from '../services/storage';

const LogoContainer = styled.div`
  position: relative;
  padding: 1em;

  i {
    position: absolute;
    top: 38px;
    right: 8px;
    cursor: pointer;
  }
`;

const Logo = styled(Image)`
  width: 5.5em;
`;

const SidebarGrid = styled(Responsive)`
  background-color: #ffffff !important;
  width: 268px !important;
  padding: 0 10px 100px;
  border-right: 1px solid #e6e6e6;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: none;
  height: 100vh;
`;

const VersionText = styled.div`
  position: absolute;
  bottom: 0.5rem;
  width: 100%;
  p {
    text-align: center;
    font-size: 12px;
  }
`;

const AppMenuItemsWrapper = styled.div`
  background-color: #ffffff;
  border-radius: none;
  font-size: 1em;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .ui.vertical.menu > .active.item:only-child {
    border-radius: none;
  }
`;

const AppMenu = styled(SemanticMenu)`
  background-color: #e1e8ed !important;
`;

const mapState = (state: iRootState) => ({
  user: state.user,
  availableBrokersAndEstates: state.availableBrokersAndEstates,
});

export type Props = ReturnType<typeof mapState> & { handleToggleSidebar: () => void };

const Menu = (props: Props) => {
  const { user, availableBrokersAndEstates } = props;
  const [options, setOptions] = useState<MenuItemProps[]>([]);
  const { isMobile } = useWindowSize();
  const [canView, setCanView] = useState<boolean>(false);

  useEffect(() => {
    async function getEmails() {
      const emails = get(ConfigKeysEnum.ACCESS_OPERATIONAL_EMAILS);
      if(emails) {
        if(user.email && emails.includes(user.email)) {
          setCanView(true);
        }
      }else {
      const response = await getConfigKey(ConfigKeysEnum.ACCESS_OPERATIONAL_EMAILS);
      if (response.success) {
        set(ConfigKeysEnum.ACCESS_OPERATIONAL_EMAILS, response.value);
        if(user.email && response.value.includes(user.email))
          setCanView(true);
      } else {
        console.log(response);
      }
    }}
    getEmails();
  }, [setCanView, user.email])

  useEffect(() => {
    let menuOpts = menuOptions;

    if (user.role?.includes('ESTATE')) {
      // menuOpts = menuOpts.filter((option) => option.name !== 'ESTATES');
      // menuOpts = menuOpts.filter((option) => option.name !== 'LOGS');
      //    menuOpts = menuOpts.filter((option) => option.name !== 'POWER_BI');
      menuOpts.forEach((submenu, index) => {
        // submenu.submenuItems = submenu.submenuItems?.filter((option) => option.id !== 3);
        if (submenu.name === 'insurance') {
          submenu.submenuItems?.forEach(subMenuItem => {
            if (subMenuItem.id === 400) {
              subMenuItem.submenus = subMenuItem.submenus?.filter(
                submenu => submenu.name !== 'reports-form'
              );
            }
          });
        }

        if (user.role === 'ESTATE_USER') {
          if (submenu.name === 'user') {
            submenu.submenuItems?.forEach(subMenuItem => {
              if (subMenuItem.id === 21) {
                subMenuItem.submenus = subMenuItem.submenus?.filter(
                  submenu => submenu.name !== 'new-user'
                );
              }
            });
          }
        }
      });
      // } else if (user.role?.includes('CLAIM_ANALYST')) {
      //   menuOpts = menuOpts.filter((option) => option.name !== 'powerbi');
      //   menuOpts = menuOpts.filter((option) => option.name === 'claim_estates' || option.name === 'dashboard');
      // }
    }
    // else if(user.role === UserRolesEnum.brokerAnalyst) {
    //   menuOpts = menuOpts.filter((option) => option.name !== 'powerbi');
    // }

    if(!availableBrokersAndEstates.enableFireInsuranceMenu) {
      const menus = [];

      for (const menu of menuOpts) {
        const hasSubMenuId3 = menu.submenuItems?.find(submenu => submenu.id === 3);

        if (hasSubMenuId3) {
          menu.submenuItems = menu.submenuItems?.filter(submenu => submenu.id !== 3);
        }

        menus.push(menu);
      }

      menuOpts = menus;
    }

    setOptions(menuOpts);
  }, [availableBrokersAndEstates.enableFireInsuranceMenu, user.role]);

  return (
    <SidebarGrid>
      <LogoContainer>
        <Logo src={logo} />
        {isMobile && (
          <Icon
            name="arrow left"
            color="blue"
            size="large"
            onClick={props.handleToggleSidebar}
          />
        )}
      </LogoContainer>
      <AppMenu fluid vertical inverted borderless>
        <AppMenuItemsWrapper>
          {options.map((item: MenuItemProps, _index: number) => {
            if (item.name !== 'OPERATIONAL') {
              return (<AuthorizationContainer
                key={item.id}
                action={ActionEnum.read}
                feature={item.name}
              >
                <MenuItem key={item.id} {...item} />
              </AuthorizationContainer>)
            } else {
              return canView ? (<MenuItem key={item.id} {...item} />) : (<></>);
            }
          })}
        </AppMenuItemsWrapper>
        <VersionText>
          <p>v{packageInfo.version}</p>
        </VersionText>
      </AppMenu>
    </SidebarGrid>
  );
};

export default connect(mapState, null)(Menu);
