import {ObjectSchema, Ref} from "yup";
import {
  ConnectorCoverageSectionSchema,
  connectorCoverageSectionYupSchema
} from "./connector-coverages-section/connector-coverage-section-schema";

export const getFireInsuranceCoveragesYupSchema = (isConnectorEnabled: boolean, insurerCode: string):
  Ref | ObjectSchema<ConnectorCoverageSectionSchema> | undefined => {
  if (isConnectorEnabled) {
    return connectorCoverageSectionYupSchema
  } else {
    return undefined
  }
}
