export const PortoTradicionalHireInitialValues = {
  analysisId: '',
  lessor: {
    document: '',
    name: '',
    birthDate: '',
    email:'',
    cellphone: '',
    commercialPhone: '',
    residentialPhone: '',
    address: {
      zipCode: '',
      streetType: 'R',
      number: '',
      street: '',
      complement: undefined,
      district: '',
      city: '',
      state: ''
    },
    companyType: 1,
    hasIntervener: false,
    partners: [
      {
        name: '',
        document: '',
        email: '',
        cellphone: ''
      }
    ],
      pep: {
        flagPessoaExpPolitica: 2
      }
  },
  installments: undefined,
  contract: {
    startContractDate: '',
    endContractDate: '',
    startInsuranceDate: '',
    endInsuranceDate: ''
  }
}
