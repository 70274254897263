import styled, { keyframes } from 'styled-components';
import { Table } from 'semantic-ui-react';

export const FieldLabel = styled.div<{
  paddingTop?: boolean;
  required?: boolean;
  paddingBottom?: boolean;
}>`
  font-weight: bold;
  cursor: default;
  padding-top: ${props => (props.paddingTop ? '14px' : '0px')};
  padding-bottom: ${props => (props.paddingBottom ? '5px' : '0px')};
  ${props =>
    props.required &&
    `
    &:after {
      content: "*";
      color: red;
      margin-left: 1px;
    }`}
`;

export const FieldValue = styled.p`
  padding-left: 5px;
`;

export const SelectableTableRow = styled(Table.Row)`
  //transition: opacity, background-color linear 0.25s;

  &:hover {
    background-color: whitesmoke;
  }
`;

export const ClickableTableCell = styled(Table.Cell)`
  cursor: pointer;
  position: relative;
`;

export const TextInfo = styled.p`
  font-size: 16px;
`;

export const FilterButton = styled.button`
  display: block;
  position: absolute;
  padding: 10px;
  border-radius: 9999px;
  height: 48px;
  width: 48px;
  border: none;
  background-color: #999999;
  color: white;
  z-index: 30;
  bottom: 10%;
  right: 5%;
  i.large.icon {
    margin: 0;
  }
  @media-query (min-width: 769px) {
    display: none;
  }
`;

export const FilterTitleContainer = styled.div`
  padding: 8px 14px;
`;

export const FilterSidebarOverlay = styled.div<{ visible: boolean }>`
  display: ${props => (props.visible ? 'block' : 'none')};
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100vh;
  background-color: #FFFFFF60;
  z-index: 10;
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StartDate = styled.div`
  display: flex;
  float: left;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const EndDate = styled.div`
  display: flex;
  float: left;
  padding-left: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const skeletonLoading = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

export interface SkeletonLoaderProps {
  width: string;
  height: string;
  marginBottom: string;
}

export const SkeletonLoader = styled.div<SkeletonLoaderProps>`
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '1.5rem')};
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${skeletonLoading} 1.5s ease-in-out infinite;
  border-radius: 4px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 6px;
`;
