import {ToggledMenuReducer, ToggledMenuState} from "../types/temp-types";

type ToggledMenuRematchModel = {
  state: ToggledMenuState;
  reducers: ToggledMenuReducer;
}

const toggledMenu: ToggledMenuRematchModel = {
  state: {
    isOpened: true
  },
  reducers: {
    close: (state: ToggledMenuState, payload: ToggledMenuState): ToggledMenuState => {
      return { ...state, isOpened: false };
    },
    open: (state: ToggledMenuState, payload: ToggledMenuState): ToggledMenuState => {
      return { ...state, isOpened: true };
    },
    toggle: (state: ToggledMenuState, payload: boolean): ToggledMenuState => {
      return { ...state, isOpened: payload };
    }
  }
};

export default toggledMenu;
