import React from 'react';
import { Button, Container, Header, Image, Modal } from 'semantic-ui-react';

interface AssistanceDetailsProps {
  open: boolean;
  onClose: () => void;
};

const AssistanceDetailsModal = ({ open, onClose }: AssistanceDetailsProps) => {
  const urlImage = 'https://4seg-documents-analysis.s3.amazonaws.com/Auxiliar/Detalhe+Assistencias+Alfa.webp';

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
      >
        <Modal.Header>
          <Header>Assistências - Alfa Seguros</Header>
        </Modal.Header>
        <Modal.Content image scrolling>
          <Container>
            <Image
              centered
              rounded
              wrapped
              src={urlImage}
              style={{ marginBottom: '20px' }}
            />
          </Container>
        </Modal.Content>

        <Modal.Actions>
          <Button
            onClick={onClose}
            color={'green'}
          >
            OK, Entendi
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default AssistanceDetailsModal;
