import React, { ReactNode } from 'react';
import { Can } from '@casl/react';
import { PermissionType, getAbility } from '../services/ability';
import * as Storage from '../services/storage';
import { AnyAbility } from '@casl/ability';
import { ActionEnum } from '../enums/authz-action.enum';
import { FeatureEnum } from '../enums/authz-feature.enum';
import { invalidateCache } from '../containers/Navbar';

type AuthzProps = {
  action: string | string[];
  feature: string;
  ability?: AnyAbility;
  children?: ReactNode;
};

export function hasPermission(action: ActionEnum, feature: FeatureEnum): boolean {
  const loginAt = Storage.get('login_at');
  if (loginAt) {
    const now = new Date();
    const loginAtDate = new Date(JSON.parse(loginAt));
    if (now.getDate() > loginAtDate.getDate()) {
      invalidateCache();
    }
  }
  const permissions = Storage.get('permissions');
  if (permissions) {
    const permissionsArray = JSON.parse(permissions);
    const hasPerm = permissionsArray.filter(
      (permission: { action: ActionEnum; feature: FeatureEnum }) =>
        permission.action === action && permission.feature === feature
    );
    return hasPerm.length > 0;
  } else {
    return false;
  }
}

const AuthorizationContainer = (props: AuthzProps) => {
  const permissions = Storage.get('permissions');
  let permissionsArray: PermissionType[] = [];

  if (permissions) {
    permissionsArray = JSON.parse(permissions);
  } else {
    invalidateCache();
  }

  const ability = getAbility(permissionsArray);
  return (
    <Can
      I={props.action}
      a={props.feature}
      ability={props.ability ? props.ability : ability}
    >
      {props.children}
    </Can>
  );
};

export default AuthorizationContainer;
