import * as Yup from 'yup';
import { addMethod } from 'yup';

const REQUIRED_FIELD_MESSAGE = 'Campo Obrigatório!';
const GREATER_THAN_ZERO_MESSAGE = 'Valor deve ser maior que zero!';

declare module 'yup' {
  interface StringSchema {
    greaterThanZero(errorMessage: string): StringSchema;
    lessThanMaximum(errorMessage: string): StringSchema;
  }
}

addMethod(Yup.string, 'greaterThanZero', function (errorMessage) {
  return this.test('test-greater-than-zero', errorMessage, function (value) {
    const { path, createError } = this;

    return value !== '0,00';
  });
});

export const updateEstateSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  cnpj: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  estate_zipcode: Yup.string(),
  estate_street: Yup.string(),
  estate_number: Yup.string(),
  estate_complement: Yup.string(),
  estate_district: Yup.string(),
  estate_city: Yup.string(),
  estate_state: Yup.string(),
  financial_responsible: Yup.string(),
  financial_email: Yup.string(),
  status: Yup.string(),
  contact_source: Yup.string(),
  batchClosingDay: Yup.number()
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    })
    .required(REQUIRED_FIELD_MESSAGE),
  broker: Yup.object().required(REQUIRED_FIELD_MESSAGE),
  fireInsurer: Yup.object().required(REQUIRED_FIELD_MESSAGE),
  email: Yup.string().required(REQUIRED_FIELD_MESSAGE).email('E-mail inválido'),
  averagePropertiesWallet: Yup.number()
    .typeError('Informe um número válido')
    .required(REQUIRED_FIELD_MESSAGE)
    .test('greater-than-zero', GREATER_THAN_ZERO_MESSAGE, function (value) {
      return value !== undefined && value > 0;
    }),
  // profitShare: Yup.number().transform((cv, ov) => {
  //   return ov === '' ? undefined : cv;
  // }).required(REQUIRED_FIELD_MESSAGE)
  //   .integer("O valor deve ser inteiro"),
  // multiplier: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  bankAccount: Yup.object().shape({
    bankCode: Yup.string().required(REQUIRED_FIELD_MESSAGE),
    agencyNumber: Yup.string().required(REQUIRED_FIELD_MESSAGE),
    accountNumber: Yup.string().required(REQUIRED_FIELD_MESSAGE)
  }),
  isPortoTradicionalCapitalizationSelected: Yup.boolean(),
  portoCode: Yup.string().when('isPortoTradicionalCapitalizationSelected', {
    is: true,
    then: Yup.string().required(REQUIRED_FIELD_MESSAGE)
  }),
  isPottencialFireInsuranceSelected: Yup.boolean(),
  insurerSpecificData: Yup.object().shape({
    pottencial: Yup.object().shape({
      fireInsurance: Yup.object().shape({
        commissionPercentage: Yup.string().when('isPottencialFireInsuranceSelected', {
          is: true,
          then: Yup.number().max(100).min(1).required(REQUIRED_FIELD_MESSAGE),
          otherwise: Yup.number()
        }),

        multiplier: Yup.number().when('isPottencialFireInsuranceSelected', {
          is: true,
          then: Yup.number().max(500).min(100).required(REQUIRED_FIELD_MESSAGE),
          otherwise: Yup.number()
        })
      })
    })
  })
  // isLibertyFireInsuranceSelected: Yup.boolean(),
  // isTooSegurosBondInsuranceSelected: Yup.boolean(),
  // insurerSpecificData: Yup.object().shape({
  //   liberty: Yup.object().shape({
  //     fireInsurance: Yup.object().shape({
  //       offerLetter: Yup.string().when('isLibertyFireInsuranceSelected',
  //         {
  //           is: true,
  //           then: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  //           otherwise: Yup.string()
  //         })
  //     })
  //   }),
  //   tooSeguros: Yup.object().shape({
  //     bondInsurance: Yup.object().shape({
  //       commissionPercentage: Yup.string().when('isTooSegurosBondInsuranceSelected',
  //         {
  //           is: true,
  //           then: Yup.string().required(REQUIRED_FIELD_MESSAGE).greaterThanZero(GREATER_THAN_ZERO_MESSAGE),
  //           otherwise: Yup.string()
  //         })
  //     })
  //   }),
  // }),
});
