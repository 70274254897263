import React from 'react';

import { Loader as SemanticLoader, Dimmer, LoaderProps } from 'semantic-ui-react';


const Loader = (props: LoaderProps) => {
  if (props.active === undefined) {
    return LoaderComponent(true);
  }
  return LoaderComponent(props.active);
}

const LoaderComponent = (active: boolean) => (
    <>
      <Dimmer active={active} inverted page>
        <SemanticLoader size='big' />
      </Dimmer>
    </>
  );

export default Loader;
