import { withFormik } from "formik";
import { SurtyGeneralInsuraceInitialValues } from "./initialStatesSurty";
import SurtyGeneralInsuranceForm from '../Steps/SurtyInsuraceFormStep';
export const SurtyGeneralInsuranceFormik = withFormik<any, typeof SurtyGeneralInsuraceInitialValues>({

  mapPropsToValues: () => SurtyGeneralInsuraceInitialValues,

  /* @To Do  Validation Schema  Ex.:
    validationSchema: (props: StepProps) => {
      const { bondInsuranceForm } = props;
        if (props.bondInsuranceForm.currentPage != 2) {
      return validationSchemas[props.bondInsuranceForm.currentPage]
    }*/

  handleSubmit: (values, bag) => true,

})(SurtyGeneralInsuranceForm);
