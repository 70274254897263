import React, { useEffect, useState } from "react";
import { Grid, Dropdown, DropdownItemProps, Icon, Button, Input } from "semantic-ui-react";
import { Field, FormikProps } from "formik";
import SectionLabel from "../../../../../../components/SectionLabel";
import { FieldLabel } from "../../../../../../styles";
import { SyntheticEvent } from "react-router/node_modules/@types/react";
import {
  CancellationInsuranceProps,
  cancellationReasonOptions,
} from "../../../../types";
import { CancellationInitialValues } from "../../Formik/initialStatesCancel";
import _ from "lodash";
import { warningNotification } from "../../../../../../services/notification";
import FormatedTextArea from "../../../../../../components/FormatedTextArea";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FileUploader, { fileTypeProps } from "../../../../../../components/UploadFilesUncontroled";
import { get } from "../../../../../../services/storage";
import { isValidEmail } from "../../../../../../utils/emailValidator";

const fileIdentifiers: fileTypeProps[] = [
  { name: "Outros", key: "OTHER", required: false },
  { name: "Termo de rescisão", key: "TERMINATION_TERM", required: true },
  { name: 'Termo de Entrega de Chaves com Inexistência de débitos', key: 'DELIVERY_KEYS_TERM', required: true },
]

const CancellationInsuranceForm = ({
  values,
  setFieldValue,
  flagSubmit,
  callbackSubmit,
  setFlagSubmit,
  hiredDataId,
  closeModal,
}: FormikProps<typeof CancellationInitialValues> &
  CancellationInsuranceProps) => {
  const [reasonsOptions, setReasonsOptions] = useState<
    Array<DropdownItemProps>
  >([]);

  const userId = get("uid");
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    setReasonsOptions(cancellationReasonOptions);
    MySwal.fire({
      title: `Deseja prosseguir com o cancelamento da Proposta de Seguro Fiança  ?`,
      text: "Importante: Após o cancelamento da Proposta, os dados ficarão disponíveis para consulta, mas a Proposta não poderá ser reativada.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, prosseguir cancelamento!",
      cancelButtonText: "Desistir",
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          const { value: checked } = await MySwal.fire({
            title: "Declaro que: ",
            html:
              '<div style="display:flex;flex-direction:row;align-items:center;justify-content:space-around;text-align:justify;padding-right:30px;"><input id="swal-input1" type="checkbox" class="swal2-input"> <label for="swal-input1">Estou ciente que com este cancelamento não poderei reativar a análise.</label> </div>' +
              '<div style="display:flex;flex-direction:row;align-items:center;justify-content:space-around;text-align:justify;padding-right:30px;"><input id="swal-input2" type="checkbox" class="swal2-input"> <label for="swal-input2">Estou ciente que após cancelado não poderei prosseguir com nenhuma contratação, sendo necessário fazer uma nova análise caso preciso for.</label> </div>',
            focusConfirm: false,
            confirmButtonText: "Ciente e prosseguir",
            denyButtonText: "Cancelar",
            preConfirm: () => {
              return [
                (document.getElementById("swal-input1") as HTMLInputElement)
                  .checked
                  ? "true"
                  : "false",
                (document.getElementById("swal-input2") as HTMLInputElement)
                  ?.checked
                  ? "true"
                  : "false",
              ];
            },
          });
          if (
            Array.isArray(checked) &&
            checked[0] === "true" &&
            checked[1] === "true"
          ) {
          } else {
            closeModal();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Para continuar, deve aceitar os termos!",
            });
          }
        })();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Para continuar, deve aceitar os termos!",
        });
        closeModal();
      }
    });
  }, []);

  useEffect(() => {
    function submitForm() {
      let error = false;
      setFlagSubmit && setFlagSubmit(false);

      if (!values.cancellationReason) {
        warningNotification(
          "Ops...",
          "Por favor, preencha o motivo da locação!"
        );
        error = true;
      }

      if (!values.proposalFiles || values.proposalFiles.length === 0) {
        warningNotification(
          "Ops...",
          "Por favor, anexe pelo menos um arquivo!"
        );
        error = true;
      }

      /*
       else {
        let requireds = fileIdentifiers.filter((fi) => fi.required === true);
        values.proposalFiles.forEach(
          (file: fileTypeProps) => (requireds = requireds.filter((c) => c.key !== file.name))
        );


        // console.log('arquivos', requireds);
        if (requireds.length == 2) {
          warningNotification(
            "Ops...",
            "Por favor, anexe pelo menos um dos arquivos solicitados!"
          );
          // requireds.forEach((req) => {
          //   warningNotification(
          //     "Ops",
          //     `Por favor, anexe um arquivo do tipo: ${req.name}`
          //   );
          // });
          error = true;
          return;
        }
      }
      */

      if(!values.notificationEmails || values.notificationEmails.length === 0) {
        warningNotification(
          "Ops...",
          "Por favor, preencha pelo menos um e-mail para notificação!"
        );
        error = true;
      }


      if (values.notificationEmails) {
        values.notificationEmails.forEach((email) => {
          if (!isValidEmail(email)) {
            warningNotification(
              "Ops...",
              `O e-mail ${email} não é válido!`
            );
            error = true;
          }
        });
      }

      if (error) {
        return;
      }
      const payload = {
        hiredDataId,
        userId: userId,
        cancellationReason: values.cancellationReason,
        notificationEmails: values.notificationEmails,
        observations: values.observations,
        files: values.proposalFiles
      };
      callbackSubmit(payload);
    }
    flagSubmit && submitForm();
  }, [flagSubmit]);

  return (
    <div>
      <SectionLabel text="INFORMAÇÕES DO CANCELAMENTO" />
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel>MOTIVO DO CANCELAMENTO* </FieldLabel>
            <Dropdown
              name={`bondInsurance.reason`}
              value={values.cancellationReason}
              options={reasonsOptions}
              onChange={(e, { value }) =>
                setFieldValue("cancellationReason", value)
              }
              floating
              search
              selection
              fluid
              placeholder="Selecionar..."
            />
          </Grid.Column>
        </Grid.Row>

        <SectionLabel text="Informe os e-mails para receberem notificações do cancelamento:" />
        {(values?.notificationEmails as string[]).map(
          (notificationEmail, index) => {
            return (
              <Grid.Row verticalAlign="middle" key={index}>
                <Grid.Column width={6}>
                  <Field
                    value={notificationEmail}
                    component={Input}
                    fluid
                    onChange={(e: any, { value }: { value: string }) => {
                      setFieldValue(
                        `notificationEmails[${index}]`,
                        value
                      );
                    }}
                    onBlur={() => {
                      if (!isValidEmail(notificationEmail)) {
                        warningNotification(
                          "Ops...",
                          "Por favor, preencha um e-mail válido!"
                        );
                      }
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={1} verticalAlign="middle">
                  <Icon

                    name={"remove circle"}
                    color={"grey"}
                    style={{ cursor: "pointer", paddingTop: "5px" }}
                    size={"large"}
                    onClick={() => {
                      if (values.notificationEmails?.length === 1) {
                        setFieldValue(
                          `notificationEmails`,
                          [""]
                        );
                      } else {
                        const arr1 =
                          values.notificationEmails?.slice(0, index);
                        const arr2 =
                          values.notificationEmails?.slice(
                            index + 1
                          );
                        const newArr = [...arr1 as string[], ...arr2 as string[]];
                        setFieldValue(`notificationEmails`, newArr);
                      }
                    }}
                  />
                </Grid.Column>
                {index + 1 === values.notificationEmails?.length && (
                  <Grid.Column width={5}>
                    <Button
                      primary
                      onClick={() => {
                        if (values.notificationEmails?.length) {
                          const lastEmail =
                            values.notificationEmails[
                            values.notificationEmails.length - 1
                            ];
                          if (lastEmail.length > 0)
                            setFieldValue(`notificationEmails`, [
                              ...values.notificationEmails as string[],
                              "",
                            ]);
                        } else {
                          setFieldValue(
                            `notificationEmails[${index + 1}]`,
                            ""
                          );
                        }
                      }}
                    >
                      <Icon name={"plus circle"} />
                      Adicionar E-mail
                    </Button>
                  </Grid.Column>
                )}
              </Grid.Row>
            );
          }
        )}

        <SectionLabel text="Observações" />
        <Grid.Row>
          <Grid.Column width={16}>
            <Field
              name={`observations`}
              rows={8}
              value={values.observations}
              onChange={(_: SyntheticEvent, data: any) => {
                setFieldValue(`observations`, data.value);
              }}
              component={FormatedTextArea}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <SectionLabel text={'Arquivos'} />
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <FieldLabel>Para solicitar o cancelamento da Proposta é OBRIGATÓRIO anexar pelo menos um dos seguintes arquivos: <br /></FieldLabel>
            <FieldLabel>- TERMO DE ENTREGA DAS CHAVES COM INEXISTÊNCIA DE DÉBITOS. (<a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Modelo+de+termo+entrega+de+chaves+sem+debitos+-+RedeConfiax.docx">Baixar Modelo</a>)<br /></FieldLabel>
            <FieldLabel>- TERMO DE RECISÃO</FieldLabel>
            <FileUploader
              fileIdentifiers={fileIdentifiers}
              files={values.proposalFiles}
              setFieldValue={setFieldValue}
              fieldName={'proposalFiles'}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
export default CancellationInsuranceForm;
