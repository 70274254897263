import React from 'react';

import { Dropdown as SemanticDropDown, DropdownProps } from 'semantic-ui-react';

import styled from 'styled-components';

const DropdownWrapper = styled.div`
  .disabled.dropdown {
    background-color: #F5F8FA !important;
    opacity: 0.5 !important;
  }
`

const Dropdown = (data: DropdownProps) => {
  const { form, search } = data;
  const { setFieldTouched } = form;

  return (
    <>
      <DropdownWrapper>
        <SemanticDropDown
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
          autoComplete="off"
          {...data.field}
          {...data}
          selection
          compact
          basic
          search={search}
          clearable
          placeholder={data.placeholder || 'Selecione...'}
          onBlur={(e, { value }) => {
            setFieldTouched(data.field.name, true, true);
          }}
        />
      </DropdownWrapper>
    </>
  );
}

export default Dropdown;
