import SectionLabel from '../../../../../components/SectionLabel';
import { Grid, Icon, Input, InputOnChangeData } from 'semantic-ui-react';
import { Field } from 'formik';
import { maskZipCode } from '../../../../../services/masks';
import { FormikErrorMessage } from '../../../../../components/ErrorMessage';
import { AddressService } from '../../../../../services/address';
import React, { ReactElement, SyntheticEvent, useState } from 'react';
import { getErrorFormik } from '../../../../../services/errors';
import { FieldLabel } from '../../../../../styles';
import { StepProps } from '../../../BondInsuranceForm';
import { connect } from 'react-redux';
import { iRootDispatch, iRootState } from '../../../../../store';
import { BondInsuranceFormState } from '../../../../../store/types/temp-types';
import { UFOptions, hirePropertyTypeOptions } from '../../../types';
import Dropdown from '../../../../../components/Dropdown';

const SECTION_NAME = 'property_info';

const mapState = (state: iRootState) => ({
  bondInsuranceForm: state.bondInsuranceForm,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateBondInsurance: (bondInsuranceFormState: BondInsuranceFormState) =>
    dispatch.bondInsuranceForm.updateBondInsurance(bondInsuranceFormState)
});

type PropertyAddressProps = StepProps &
  ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch>;

const PropertyAddress = (props: PropertyAddressProps): ReactElement => {
  const [cepLoading, setCepLoading] = useState<boolean>(false);
  const {
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    handleInputChange,
    // handleDateChange,
    user,
    values
  } = props;

  const onChangeCep = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const maskedValue = maskZipCode(e.target.value);

    setFieldValue(`${SECTION_NAME}.zip_code`, maskedValue);
    if (maskedValue.length === 9) {
      setCepLoading(true);

      AddressService.getAddressByZipCode(e.target.value, user.id)
        .then(address => {
          setFieldValue(`${SECTION_NAME}.city`, address.city);
          setFieldValue(`${SECTION_NAME}.district`, address.district);
          setFieldValue(`${SECTION_NAME}.state`, address.state);
          setFieldValue(`${SECTION_NAME}.street`, address.street);

          setCepLoading(false);
        })
        .catch(e => {
          console.log('address error:', e);
        });
    }
  };

  const validateErros = (field: string) =>
    getErrorFormik(errors, touched, SECTION_NAME, field);

  return (
    <>
      <SectionLabel text="ENDEREÇO DO IMÓVEL" />
      <Grid.Row>
        <Grid.Column width={5}>
          <FieldLabel>CEP*</FieldLabel>
          <Field
            id={`${SECTION_NAME}.zip_code`}
            name={`${SECTION_NAME}.zip_code`}
            value={values.property_info.zip_code}
            loading={cepLoading}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
              data: InputOnChangeData
            ): void => {
              onChangeCep(e);
              data.value = maskZipCode(e.target.value);
            }}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.zip_code`, true)}
            error={validateErros('zip_code')}
            component={Input}
            maxLength={9}
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.zip_code`} />
        </Grid.Column>

        <Grid.Column width={11}>
          <FieldLabel>LOGRADOURO*</FieldLabel>
          <Field
            id={`${SECTION_NAME}.street`}
            name={`${SECTION_NAME}.street`}
            value={values.property_info.street}
            onChange={handleInputChange}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.street`, true)}
            error={validateErros('street')}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.street`} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={5}>
          <FieldLabel>BAIRRO*</FieldLabel>
          <Field
            id={`${SECTION_NAME}.district`}
            name={`${SECTION_NAME}.district`}
            value={values.property_info.district}
            onChange={handleInputChange}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.district`, true)}
            error={validateErros('district')}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.district`} />
        </Grid.Column>

        <Grid.Column width={6}>
          <FieldLabel>CIDADE*</FieldLabel>
          <Field
            id={`${SECTION_NAME}.city`}
            name={`${SECTION_NAME}.city`}
            value={values.property_info.city}
            onChange={handleInputChange}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.city`, true)}
            error={validateErros('city')}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.city`} />
        </Grid.Column>

        <Grid.Column width={5}>
          <FieldLabel>UF*</FieldLabel>
          <Field
            id={`${SECTION_NAME}.state`}
            name={`${SECTION_NAME}.state`}
            completeValue={true}
            options={UFOptions}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: SyntheticEvent, data: any) => {
              setFieldValue(`${SECTION_NAME}.state`, data.value);
            }}
            className={validateErros('state')}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.state`, true)}
            component={Dropdown}
            selection
            compact
            basic
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.state`} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={5}>
          <FieldLabel>NÚMERO*</FieldLabel>
          <Field
            id={`${SECTION_NAME}.number`}
            name={`${SECTION_NAME}.number`}
            value={values.property_info.number}
            onChange={handleInputChange}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.number`, true)}
            error={validateErros('number')}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.number`} />
        </Grid.Column>

        <Grid.Column width={6}>
          <FieldLabel>COMPLEMENTO</FieldLabel>
          <Field
            id={`${SECTION_NAME}.complement`}
            name={`${SECTION_NAME}.complement`}
            value={values.property_info.complement}
            onChange={handleInputChange}
            component={Input}
            fluid
          />
        </Grid.Column>

        <Grid.Column width={5}>
          <FieldLabel>TIPO DO IMÓVEL*</FieldLabel>
          <Field
            id={`${SECTION_NAME}.property_type`}
            name={`${SECTION_NAME}.property_type`}
            completeValue={true}
            options={hirePropertyTypeOptions}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: SyntheticEvent, data: any) => {
              setFieldValue(`${SECTION_NAME}.property_type`, data.value);
            }}
            className={validateErros('property_type')}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.property_type`, true)}
            error={validateErros('property_type')}
            component={Dropdown}
            selection
            compact
            basic
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.property_type`} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5} floated="right">
          <p style={{ color: 'red' }}>
            Para prosseguir, é necessário informar todos os valores da locação. Caso não
            haja valores a declarar, clique no botão <Icon name="ban" color="red" />.
          </p>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default connect(mapState, mapDispatch)(PropertyAddress);
