export function adjustDateForTimezone(dateString: string) {
  if (!dateString) {
    return new Date();
  }
  const dateParts = dateString.split('-');
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // Mês começa do zero no JavaScript
  const day = parseInt(dateParts[2], 10);
  const localDate = new Date(year, month, day, 0, 0, 0);
  return localDate;
}
