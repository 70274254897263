import React, { SyntheticEvent, useEffect } from 'react';

import { Dropdown } from 'semantic-ui-react';

import { DropdownContainer, IconContainer, ImageContainer, Image, ButtonIcon, Divider, Title } from './styles';

import IconButton from '../IconButton';
import { cleanUserSession } from '../../services/auth';

import redeconfiax from '../../resources/imgs/redeconfiax.svg';
import redevistorias from '../../resources/imgs/redevistorias.svg';
import redeizee from '../../resources/imgs/redeizee.svg';
import painelrv from '../../resources/imgs/gruporv.svg';
import closeIcon from '../../resources/imgs/unify-close.svg';
import signoutIcon from '../../resources/imgs/unify-signout.svg';
import * as Storage from '../../services/storage';



const logoutHandler = (e: SyntheticEvent) => {
  cleanUserSession();
};

const unify = (
  <IconButton name='th' color='blue' size='big' />
);

const UnifiedApps = () => {
  const userToken = Storage.get("id_token");
  const isDevelop = window.location.hostname === 'app.dev.4seg.com.br' || window.location.hostname === 'localhost';

  const redeOptions = [
    { link: 'https://4seg.com.br/admin/dashboard', image: redeconfiax },
    { link: !isDevelop ? `https://painel.app.somosgruporv.com.br/#access_token=${userToken}&token_type=Bearer&expires_in=86400&scope=users` : `https://painel.develop.app.somosgruporv.com.br/#access_token=${userToken}&token_type=Bearer&expires_in=86400&scope=users`, image: painelrv },
    { link: !isDevelop ? `https://painel.redevistorias.com.br/#access_token=${userToken}&token_type=Bearer&expires_in=86400` : `https://painel.dev.redevistorias.com.br/#access_token=${userToken}&token_type=Bearer&expires_in=86400`, image: redevistorias },
    { link: !isDevelop ? `https://app.izee.com.br/#access_token=${userToken}&token_type=Bearer&expires_in=86400` : `https://app.develop.izee.com.br/#access_token=${userToken}&token_type=Bearer&expires_in=86400`, image: redeizee }
    // { link: `https://app.izee.com.br/auth#access_token=${userToken}&token_type=Bearer&expires_in=86400`, image: redeizee }
  ]

  return (
    <Dropdown trigger={unify} icon={null} direction='left'>
      <Dropdown.Menu>
        <DropdownContainer>
          <IconContainer>
            <ButtonIcon >
              <img src={closeIcon} alt="close" />
            </ButtonIcon>
          </IconContainer>
          <Title>Soluções Grupo RV</Title>
          <Divider />
          <ImageContainer>
            <Dropdown.Item>
              <Image src={redeOptions[0].image} />
            </Dropdown.Item>
            {redeOptions.map((option, index) => {
              if (index !== 0) return (
                <Dropdown.Item>
                  <a href={option.link} target="_blank">
                    <Image src={option.image} />
                  </a>
                </Dropdown.Item>
              )
            })}
          </ImageContainer>
          <IconContainer>
            <ButtonIcon onClick={logoutHandler}>
              <img src={signoutIcon} alt="sign out" />
            </ButtonIcon>
          </IconContainer>
        </DropdownContainer>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default UnifiedApps;
