import React, { SyntheticEvent, useEffect, useState } from "react";
import { Radio, Grid, Input, Form, Icon, Button } from "semantic-ui-react";
import { Field, FormikProps } from "formik";
import SectionLabel from "../../../../../../components/SectionLabel";
import { FieldLabel } from "../../../../../../styles";
import { CreateClaimEstateInitialInitialValuesType } from "../../Formik/types/CreateClaimEstateInitialValuesTypes";
import CustomDatePicker from "../../../../../../components/CustomDatePicker";
import AppMaskedInput from "../../../../../../components/MaskedInput";
import { CreateClaimEstateInitialInfo } from "../../Formik/InitialValues/CreateClaimEstateInitialInitialValues";
import { ClaimValuesTypesEnum } from "../../../../enums/ClaimValues.enum";
import { FormatCurrency, UnMaskCurrency } from "../../../../../../util";
import { ClaimPropertyStatusEnum } from "../../../../../../enums/claim-property-status.enum";
// import { FormatDateYMD, FormatDateYMD_UTC_IGNORE } from "../../../../../../services/date";

const ClaimValuesStep = ({
  values,
  setFieldValue,
}: FormikProps<CreateClaimEstateInitialInitialValuesType>) => {
  const [totalValue, setTotalValue] = useState<number>(0)
  const today = new Date()
  useEffect(() => {
    const claimValues = values.claimValues.condominiumFee
      .concat(values.claimValues.energyBill)
      .concat(values.claimValues.gasBill)
      .concat(values.claimValues.paintingValue)
      .concat(values.claimValues.penalty)
      .concat(values.claimValues.propertyDamageValue)
      .concat(values.claimValues.rentValue)
      .concat(values.claimValues.taxesValue)
      .concat(values.claimValues.waterBill)
      .concat(values.claimValues.fireInsuranceBill);
    let totalValue = 0;
    claimValues.forEach(cv => { if (cv.value) totalValue += Number(cv.value) })
    setTotalValue(totalValue)
  }, [values.claimValues])
  return (
    <div >
      <SectionLabel text={`VALOR TOTAL: R$ ${FormatCurrency(totalValue)}`} />
      <div style={{ height: '60vh', overflowY: 'scroll', overflowX: 'hidden' }}>
        <SectionLabel text="Aluguel" />
        <Grid columns="16" style={{ paddingLeft: "1em" }}>
          {values.claimValues.rentValue.map((claimValue, index) => {
            return (
              <Grid.Row>
                <Grid.Column width={5}>
                  <FieldLabel>Valor do Aluguel </FieldLabel>
                  <Field
                    value={claimValue.value ? FormatCurrency(claimValue.value) : claimValue.value}
                    component={Input}
                    fluid
                    onChange={(e: any, { value }: { value: string }) => {
                      setFieldValue(
                        `claimValues.rentValue[${index}].value`,
                        UnMaskCurrency(value)
                      );
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <FieldLabel>Vencimento do aluguel</FieldLabel>
                  <Field
                    name={`dueDate`}
                    selected={claimValue.dueDate}
                    component={CustomDatePicker}
                    customInput={
                      <AppMaskedInput
                        mask={[
                          /[0-9]/,
                          /[0-9]/,
                          "/",
                          /[0-9]/,
                          /[0-9]/,
                          "/",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                      />
                    }
                    dateFormat={"dd/MM/yyyy"}
                    onChange={(date: Date, e: SyntheticEvent) => {
                      setFieldValue(
                        `claimValues.rentValue[${index}].dueDate`,
                        date
                      );
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={2}>
                  <Icon
                    name={"remove circle"}
                    color={"grey"}
                    style={{ cursor: "pointer", paddingTop: "25px" }}
                    size={"large"}
                    onClick={() => {
                      if (values.claimValues.rentValue.length === 1) {
                        const claimV =
                          CreateClaimEstateInitialInfo.claimValues.rentValue[0];
                        claimV.valueType = ClaimValuesTypesEnum.rent_value;
                        setFieldValue(`claimValues.rentValue[0]`, claimV);
                      } else {
                        const arr1 = values.claimValues.rentValue.slice(0, index);
                        const arr2 = values.claimValues.rentValue.slice(
                          index + 1
                        );
                        const newArr = [...arr1, ...arr2];
                        setFieldValue(`claimValues.rentValue`, newArr);
                      }
                    }}
                  />
                </Grid.Column>
                {index + 1 === values.claimValues.rentValue.length && (
                  <Grid.Column width={4}>
                    <Button
                      style={{ marginTop: "1rem" }}
                      primary
                      onClick={() => {
                        const claimV =
                          CreateClaimEstateInitialInfo.claimValues.rentValue[0];
                        claimV.valueType = ClaimValuesTypesEnum.rent_value;
                        setFieldValue(
                          `claimValues.rentValue[${index + 1}]`,
                          claimV
                        );
                      }}
                    >
                      <Icon name={"plus circle"} />
                      Novo
                    </Button>
                  </Grid.Column>
                )}
              </Grid.Row>
            );
          })}
        </Grid>

        <SectionLabel text="IPTU" />
        <Grid columns="16" style={{ paddingLeft: "1em" }}>
          {values.claimValues.taxesValue.map((claimValue, index) => {
            return (
              <Grid.Row>
                <Grid.Column width={4}>
                  <FieldLabel>Valor do IPTU </FieldLabel>
                  <Field
                    value={claimValue.value ? FormatCurrency(claimValue.value) : claimValue.value}
                    component={Input}
                    fluid
                    onChange={(e: any, { value }: { value: string }) => {

                      setFieldValue(
                        `claimValues.taxesValue[${index}].value`,
                        UnMaskCurrency(value)
                      );
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <FieldLabel>Vencimento do IPTU</FieldLabel>
                  <Field
                    name={`dueDate`}
                    selected={claimValue.dueDate}
                    component={CustomDatePicker}
                    customInput={
                      <AppMaskedInput
                        mask={[
                          /[0-9]/,
                          /[0-9]/,
                          "/",
                          /[0-9]/,
                          /[0-9]/,
                          "/",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                      />
                    }
                    dateFormat={"dd/MM/yyyy"}
                    onChange={(date: Date, e: SyntheticEvent) => {
                      setFieldValue(
                        `claimValues.taxesValue[${index}].dueDate`,
                        date
                      );
                    }}
                  />
                </Grid.Column>

                <Grid.Column width={2}>
                  <FieldLabel>Nº parcela</FieldLabel>
                  <Field
                    value={claimValue.numberInstallment}
                    component={Input}
                    fluid
                    onChange={(e: any, { value }: { value: string }) => {
                      setFieldValue(
                        `claimValues.taxesValue[${index}].numberInstallment`,
                        Number(value)
                      );
                    }}
                  />
                </Grid.Column>

                {index === 0 && (
                  <Grid.Column width={2}>
                    <FieldLabel>Total Parcelas</FieldLabel>
                    <Field
                      value={claimValue.totalInstallments}
                      component={Input}
                      fluid
                      onChange={(e: any, { value }: { value: string }) => {
                        setFieldValue(
                          `claimValues.taxesValue[${index}].totalInstallments`,
                          Number(value)
                        );
                      }}
                    />
                  </Grid.Column>
                )}
                <Grid.Column width={1}>
                  <Icon
                    name={"remove circle"}
                    color={"grey"}
                    style={{ cursor: "pointer", paddingTop: "25px" }}
                    size={"large"}
                    onClick={() => {
                      if (values.claimValues.taxesValue.length === 1) {
                        const claimV =
                          CreateClaimEstateInitialInfo.claimValues.taxesValue[0];
                        claimV.valueType = ClaimValuesTypesEnum.taxes_value;
                        setFieldValue(`claimValues.taxesValue[0]`, claimV);
                      } else {
                        const arr1 = values.claimValues.taxesValue.slice(
                          0,
                          index
                        );
                        const arr2 = values.claimValues.taxesValue.slice(
                          index + 1
                        );
                        const newArr = [...arr1, ...arr2];
                        setFieldValue(`claimValues.taxesValue`, newArr);
                      }
                    }}
                  />
                </Grid.Column>
                {index + 1 === values.claimValues.taxesValue.length && (
                  <Grid.Column width={3}>
                    <Button
                      style={{ marginTop: "1rem" }}
                      primary
                      onClick={() => {
                        const claimV =
                          CreateClaimEstateInitialInfo.claimValues.taxesValue[0];
                        claimV.valueType = ClaimValuesTypesEnum.taxes_value;
                        setFieldValue(
                          `claimValues.taxesValue[${index + 1}]`,
                          claimV
                        );
                      }}
                    >
                      <Icon name={"plus circle"} />
                      Novo
                    </Button>
                  </Grid.Column>
                )}
              </Grid.Row>
            );
          })}
        </Grid>

        <SectionLabel text="Condomínio" />
        <Grid columns="16" style={{ paddingLeft: "1em" }}>
          {values.claimValues.condominiumFee.map((claimValue, index) => {
            return (
              <Grid.Row>
                <Grid.Column width={5}>
                  <FieldLabel>Valor do condomínio </FieldLabel>
                  <Field
                    value={claimValue.value ? FormatCurrency(claimValue.value) : claimValue.value}
                    component={Input}
                    fluid
                    onChange={(e: any, { value }: { value: string }) => {
                      setFieldValue(
                        `claimValues.condominiumFee[${index}].value`,
                        UnMaskCurrency(value)
                      );
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <FieldLabel>Vencimento do condomínio</FieldLabel>
                  <Field
                    name={`dueDate`}
                    selected={claimValue.dueDate}
                    component={CustomDatePicker}
                    customInput={
                      <AppMaskedInput
                        mask={[
                          /[0-9]/,
                          /[0-9]/,
                          "/",
                          /[0-9]/,
                          /[0-9]/,
                          "/",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                      />
                    }
                    dateFormat={"dd/MM/yyyy"}
                    onChange={(date: Date, e: SyntheticEvent) => {
                      setFieldValue(
                        `claimValues.condominiumFee[${index}].dueDate`,
                        date
                      );
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={2}>
                  <Icon
                    name={"remove circle"}
                    color={"grey"}
                    style={{ cursor: "pointer", paddingTop: "25px" }}
                    size={"large"}
                    onClick={() => {
                      if (values.claimValues.condominiumFee.length === 1) {
                        const claimV =
                          CreateClaimEstateInitialInfo.claimValues
                            .condominiumFee[0];
                        claimV.valueType = ClaimValuesTypesEnum.condominium_fee;
                        setFieldValue(`claimValues.condominiumFee[0]`, claimV);
                      } else {
                        const arr1 = values.claimValues.condominiumFee.slice(
                          0,
                          index
                        );
                        const arr2 = values.claimValues.condominiumFee.slice(
                          index + 1
                        );
                        const newArr = [...arr1, ...arr2];
                        setFieldValue(`claimValues.condominiumFee`, newArr);
                      }
                    }}
                  />
                </Grid.Column>
                {index + 1 === values.claimValues.condominiumFee.length && (
                  <Grid.Column width={4}>
                    <Button
                      style={{ marginTop: "1rem" }}
                      primary
                      onClick={() => {
                        const claimV =
                          CreateClaimEstateInitialInfo.claimValues
                            .condominiumFee[0];
                        claimV.valueType = ClaimValuesTypesEnum.condominium_fee;
                        setFieldValue(
                          `claimValues.condominiumFee[${index + 1}]`,
                          claimV
                        );
                      }}
                    >
                      <Icon name={"plus circle"} />
                      Novo
                    </Button>
                  </Grid.Column>
                )}
              </Grid.Row>
            );
          })}
        </Grid>

        <SectionLabel text="Água" />
        <Grid columns="16" style={{ paddingLeft: "1em" }}>
          {values.claimValues.waterBill.map((claimValue, index) => {
            return (
              <Grid.Row>
                <Grid.Column width={5}>
                  <FieldLabel>Valor da Agua </FieldLabel>
                  <Field
                    value={claimValue.value ? FormatCurrency(claimValue.value) : claimValue.value}
                    component={Input}
                    fluid
                    onChange={(e: any, { value }: { value: string }) => {
                      setFieldValue(
                        `claimValues.waterBill[${index}].value`,
                        UnMaskCurrency(value)
                      );
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <FieldLabel>Vencimento da Agua</FieldLabel>
                  <Field
                    name={`duedate`}
                    selected={claimValue.dueDate}
                    component={CustomDatePicker}
                    customInput={
                      <AppMaskedInput
                        mask={[
                          /[0-9]/,
                          /[0-9]/,
                          "/",
                          /[0-9]/,
                          /[0-9]/,
                          "/",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                      />
                    }
                    dateFormat={"dd/MM/yyyy"}
                    onChange={(date: Date, e: SyntheticEvent) => {
                      setFieldValue(
                        `claimValues.waterBill[${index}].dueDate`,
                        date
                      );
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={2}>
                  <Icon
                    name={"remove circle"}
                    color={"grey"}
                    style={{ cursor: "pointer", paddingTop: "25px" }}
                    size={"large"}
                    onClick={() => {
                      if (values.claimValues.waterBill.length === 1) {
                        const claimV =
                          CreateClaimEstateInitialInfo.claimValues.waterBill[0];
                        claimV.valueType = ClaimValuesTypesEnum.rent_value;
                        setFieldValue(`claimValues.waterBill[0]`, claimV);
                      } else {
                        const arr1 = values.claimValues.waterBill.slice(0, index);
                        const arr2 = values.claimValues.waterBill.slice(
                          index + 1
                        );
                        const newArr = [...arr1, ...arr2];
                        setFieldValue(`claimValues.waterBill`, newArr);
                      }
                    }}
                  />
                </Grid.Column>
                {index + 1 === values.claimValues.waterBill.length && (
                  <Grid.Column width={4}>
                    <Button
                      style={{ marginTop: "1rem" }}
                      primary
                      onClick={() => {
                        const claimV =
                          CreateClaimEstateInitialInfo.claimValues.waterBill[0];
                        claimV.valueType = ClaimValuesTypesEnum.water_bill;
                        setFieldValue(
                          `claimValues.waterBill[${index + 1}]`,
                          claimV
                        );
                      }}
                    >
                      <Icon name={"plus circle"} />
                      Novo
                    </Button>
                  </Grid.Column>
                )}
              </Grid.Row>
            );
          })}
        </Grid>

        <SectionLabel text="Luz" />
        <Grid columns="16" style={{ paddingLeft: "1em" }}>
          {values.claimValues.energyBill.map((claimValue, index) => {
            return (
              <Grid.Row>
                <Grid.Column width={5}>
                  <FieldLabel>Valor da Energia </FieldLabel>
                  <Field
                    value={claimValue.value ? FormatCurrency(claimValue.value) : claimValue.value}
                    component={Input}
                    fluid
                    onChange={(e: any, { value }: { value: string }) => {
                      setFieldValue(
                        `claimValues.energyBill[${index}].value`,
                        UnMaskCurrency(value)
                      );
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <FieldLabel>Vencimento da Energia</FieldLabel>
                  <Field
                    name={`duedate`}
                    selected={claimValue.dueDate}
                    component={CustomDatePicker}
                    customInput={
                      <AppMaskedInput
                        mask={[
                          /[0-9]/,
                          /[0-9]/,
                          "/",
                          /[0-9]/,
                          /[0-9]/,
                          "/",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                      />
                    }
                    dateFormat={"dd/MM/yyyy"}
                    onChange={(date: Date, e: SyntheticEvent) => {
                      setFieldValue(
                        `claimValues.energyBill[${index}].dueDate`,
                        date
                      );
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={2}>
                  <Icon
                    name={"remove circle"}
                    color={"grey"}
                    style={{ cursor: "pointer", paddingTop: "25px" }}
                    size={"large"}
                    onClick={() => {
                      if (values.claimValues.energyBill.length === 1) {
                        const claimV =
                          CreateClaimEstateInitialInfo.claimValues.energyBill[0];
                        claimV.valueType = ClaimValuesTypesEnum.energy_bill;
                        setFieldValue(`claimValues.energyBill[0]`, claimV);
                      } else {
                        const arr1 = values.claimValues.energyBill.slice(
                          0,
                          index
                        );
                        const arr2 = values.claimValues.energyBill.slice(
                          index + 1
                        );
                        const newArr = [...arr1, ...arr2];
                        setFieldValue(`claimValues.energyBill`, newArr);
                      }
                    }}
                  />
                </Grid.Column>
                {index + 1 === values.claimValues.energyBill.length && (
                  <Grid.Column width={4}>
                    <Button
                      style={{ marginTop: "1rem" }}
                      primary
                      onClick={() => {
                        const claimV =
                          CreateClaimEstateInitialInfo.claimValues.energyBill[0];
                        claimV.valueType = ClaimValuesTypesEnum.energy_bill;
                        setFieldValue(
                          `claimValues.energyBill[${index + 1}]`,
                          claimV
                        );
                      }}
                    >
                      <Icon name={"plus circle"} />
                      Novo
                    </Button>
                  </Grid.Column>
                )}
              </Grid.Row>
            );
          })}
        </Grid>

        <SectionLabel text="Gás" />
        <Grid columns="16" style={{ paddingLeft: "1em" }}>
          {values.claimValues.gasBill.map((claimValue, index) => {
            return (
              <Grid.Row>
                <Grid.Column width={5}>
                  <FieldLabel>Valor do Gás </FieldLabel>
                  <Field
                    value={claimValue.value ? FormatCurrency(claimValue.value) : claimValue.value}
                    component={Input}
                    fluid
                    onChange={(e: any, { value }: { value: string }) => {
                      setFieldValue(
                        `claimValues.gasBill[${index}].value`,
                        UnMaskCurrency(value)
                      );
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <FieldLabel>Vencimento do Gás</FieldLabel>
                  <Field
                    name={`duedate`}
                    selected={claimValue.dueDate}
                    component={CustomDatePicker}
                    customInput={
                      <AppMaskedInput
                        mask={[
                          /[0-9]/,
                          /[0-9]/,
                          "/",
                          /[0-9]/,
                          /[0-9]/,
                          "/",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                      />
                    }
                    dateFormat={"dd/MM/yyyy"}
                    onChange={(date: Date, e: SyntheticEvent) => {
                      setFieldValue(
                        `claimValues.gasBill[${index}].dueDate`,
                        date
                      );
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={2}>
                  <Icon
                    name={"remove circle"}
                    color={"grey"}
                    style={{ cursor: "pointer", paddingTop: "25px" }}
                    size={"large"}
                    onClick={() => {
                      if (values.claimValues.gasBill.length === 1) {
                        const claimV =
                          CreateClaimEstateInitialInfo.claimValues.gasBill[0];
                        claimV.valueType = ClaimValuesTypesEnum.gas_bill;
                        setFieldValue(`claimValues.gasBill[0]`, claimV);
                      } else {
                        const arr1 = values.claimValues.gasBill.slice(0, index);
                        const arr2 = values.claimValues.gasBill.slice(index + 1);
                        const newArr = [...arr1, ...arr2];
                        setFieldValue(`claimValues.gasBill`, newArr);
                      }
                    }}
                  />
                </Grid.Column>
                {index + 1 === values.claimValues.gasBill.length && (
                  <Grid.Column width={4}>
                    <Button
                      style={{ marginTop: "1rem" }}
                      primary
                      onClick={() => {
                        const claimV =
                          CreateClaimEstateInitialInfo.claimValues.gasBill[0];
                        claimV.valueType = ClaimValuesTypesEnum.gas_bill;
                        setFieldValue(
                          `claimValues.gasBill[${index + 1}]`,
                          claimV
                        );
                      }}
                    >
                      <Icon name={"plus circle"} />
                      Novo
                    </Button>
                  </Grid.Column>
                )}
              </Grid.Row>
            );
          })}
        </Grid>

        <SectionLabel text="Incêndio" />
        <Grid columns="16" style={{ paddingLeft: "1em" }}>
          {values.claimValues.fireInsuranceBill.map((claimValue, index) => {
            return (
              <Grid.Row>
                <Grid.Column width={5}>
                  <FieldLabel>Valor do Seguro Incêndio</FieldLabel>
                  <Field
                    value={claimValue.value ? FormatCurrency(claimValue.value) : claimValue.value}
                    component={Input}
                    fluid
                    onChange={(e: any, { value }: { value: string }) => {
                      setFieldValue(
                        `claimValues.fireInsuranceBill[${index}].value`,
                        UnMaskCurrency(value)
                      );
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <FieldLabel>Vencimento da parcela</FieldLabel>
                  <Field
                    name={`duedate`}
                    selected={claimValue.dueDate}
                    component={CustomDatePicker}
                    customInput={
                      <AppMaskedInput
                        mask={[
                          /[0-9]/,
                          /[0-9]/,
                          "/",
                          /[0-9]/,
                          /[0-9]/,
                          "/",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                      />
                    }
                    dateFormat={"dd/MM/yyyy"}
                    onChange={(date: Date, e: SyntheticEvent) => {
                      setFieldValue(
                        `claimValues.fireInsuranceBill[${index}].dueDate`,
                        date
                      );
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={2}>
                  <Icon
                    name={"remove circle"}
                    color={"grey"}
                    style={{ cursor: "pointer", paddingTop: "25px" }}
                    size={"large"}
                    onClick={() => {
                      if (values.claimValues.fireInsuranceBill.length === 1) {
                        const claimV =
                          CreateClaimEstateInitialInfo.claimValues.fireInsuranceBill[0];
                        claimV.valueType = ClaimValuesTypesEnum.fire_insurance_bill;
                        setFieldValue(`claimValues.fireInsuranceBill[0]`, claimV);
                      } else {
                        const arr1 = values.claimValues.fireInsuranceBill.slice(0, index);
                        const arr2 = values.claimValues.fireInsuranceBill.slice(index + 1);
                        const newArr = [...arr1, ...arr2];
                        setFieldValue(`claimValues.fireInsuranceBill`, newArr);
                      }
                    }}
                  />
                </Grid.Column>
                {index + 1 === values.claimValues.fireInsuranceBill.length && (
                  <Grid.Column width={4}>
                    <Button
                      style={{ marginTop: "1rem" }}
                      primary
                      onClick={() => {
                        const claimV =
                          CreateClaimEstateInitialInfo.claimValues.fireInsuranceBill[0];
                        claimV.valueType = ClaimValuesTypesEnum.fire_insurance_bill;
                        setFieldValue(
                          `claimValues.fireInsuranceBill[${index + 1}]`,
                          claimV
                        );
                      }}
                    >
                      <Icon name={"plus circle"} />
                      Novo
                    </Button>
                  </Grid.Column>
                )}
              </Grid.Row>
            );
          })}
        </Grid>


        {values.claimProperty.status === ClaimPropertyStatusEnum.vacant && <>

          <SectionLabel text="Multa" />
          <Grid columns="16" style={{ paddingLeft: "1em" }}>
            {values.claimValues.penalty.map((claimValue, index) => {
              return (
                <Grid.Row>
                  <Grid.Column width={5}>
                    <FieldLabel>Valor da Multa </FieldLabel>
                    <Field
                      value={claimValue.value ? FormatCurrency(claimValue.value) : ""}
                      component={Input}
                      fluid
                      onChange={(e: any, { value }: { value: string }) => {
                        setFieldValue(
                          `claimValues.penalty[${index}].value`,
                          UnMaskCurrency(value)
                        );
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <FieldLabel>Vencimento da multa</FieldLabel>
                    <Field
                      name={`duedate`}
                      selected={claimValue.dueDate}
                      component={CustomDatePicker}
                      customInput={
                        <AppMaskedInput
                          mask={[
                            /[0-9]/,
                            /[0-9]/,
                            "/",
                            /[0-9]/,
                            /[0-9]/,
                            "/",
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                          ]}
                        />
                      }
                      dateFormat={"dd/MM/yyyy"}
                      onChange={(date: Date, e: SyntheticEvent) => {
                        setFieldValue(
                          `claimValues.penalty[${index}].dueDate`,
                          date
                        );
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <Icon
                      name={"remove circle"}
                      color={"grey"}
                      style={{ cursor: "pointer", paddingTop: "25px" }}
                      size={"large"}
                      onClick={() => {
                        if (values.claimValues.penalty.length === 1) {
                          const claimV =
                            CreateClaimEstateInitialInfo.claimValues.penalty[0];
                          claimV.valueType = ClaimValuesTypesEnum.penalty;
                          setFieldValue(`claimValues.penalty[0]`, claimV);
                        } else {
                          const arr1 = values.claimValues.penalty.slice(0, index);
                          const arr2 = values.claimValues.penalty.slice(index + 1);
                          const newArr = [...arr1, ...arr2];
                          setFieldValue(`claimValues.penalty`, newArr);
                        }
                      }}
                    />
                  </Grid.Column>
                  {index + 1 === values.claimValues.penalty.length && (
                    <Grid.Column width={4}>
                      <Button
                        style={{ marginTop: "1rem" }}
                        primary
                        onClick={() => {
                          const claimV =
                            CreateClaimEstateInitialInfo.claimValues.penalty[0];
                          claimV.valueType = ClaimValuesTypesEnum.penalty;
                          setFieldValue(
                            `claimValues.penalty[${index + 1}]`,
                            claimV
                          );
                        }}
                      >
                        <Icon name={"plus circle"} />
                        Novo
                      </Button>
                    </Grid.Column>
                  )}
                </Grid.Row>
              );
            })}
          </Grid>

          <SectionLabel text="Pintura" />
          <Grid columns="16" style={{ paddingLeft: "1em" }}>
            {values.claimValues.paintingValue.map((claimValue, index) => {
              return (
                <Grid.Row>
                  <Grid.Column width={5}>
                    <FieldLabel>Valor da Pintura </FieldLabel>
                    <Field
                      value={claimValue.value ? FormatCurrency(claimValue.value) : ""}
                      component={Input}
                      fluid
                      onChange={(e: any, { value }: { value: string }) => {
                        setFieldValue(
                          `claimValues.paintingValue[${index}].value`,
                          UnMaskCurrency(value)
                        );
                        setFieldValue(`claimValues.paintingValue[${index}].dueDate`,today );
                      }}
                    />
                  </Grid.Column>

                  {index + 1 === values.claimValues.paintingValue.length && (
                    <Grid.Column width={4}>
                      <Button
                        style={{ marginTop: "1rem" }}
                        primary
                        onClick={() => {
                          const claimV =
                            CreateClaimEstateInitialInfo.claimValues.paintingValue[0];
                          claimV.valueType = ClaimValuesTypesEnum.painting_value;
                          setFieldValue(
                            `claimValues.paintingValue[${index + 1}]`,
                            claimV
                          );
                        }}
                      >
                        <Icon name={"plus circle"} />
                        Novo
                      </Button>
                    </Grid.Column>
                  )}
                </Grid.Row>
              );
            })}
          </Grid>

          <SectionLabel text="Danos" />
          <Grid columns="16" style={{ paddingLeft: "1em" }}>
            {values.claimValues.propertyDamageValue.map((claimValue, index) => {
              return (
                <Grid.Row>
                  <Grid.Column width={5}>
                    <FieldLabel>Valor de Danos </FieldLabel>
                    <Field
                      value={claimValue.value ? FormatCurrency(claimValue.value) : ""}
                      component={Input}
                      fluid
                      onChange={(e: any, { value }: { value: string }) => {
                        setFieldValue(
                          `claimValues.propertyDamageValue[${index}].value`,
                          UnMaskCurrency(value)
                        );
                        setFieldValue(
                          `claimValues.propertyDamageValue[${index}].dueDate`,
                          today
                        );
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <Icon
                      name={"remove circle"}
                      color={"grey"}
                      style={{ cursor: "pointer", paddingTop: "25px" }}
                      size={"large"}
                      onClick={() => {
                        if (values.claimValues.propertyDamageValue.length === 1) {
                          const claimV =
                            CreateClaimEstateInitialInfo.claimValues.propertyDamageValue[0];
                          claimV.valueType = ClaimValuesTypesEnum.property_damage_value;
                          setFieldValue(`claimValues.propertyDamageValue[0]`, claimV);
                        } else {
                          const arr1 = values.claimValues.propertyDamageValue.slice(0, index);
                          const arr2 = values.claimValues.propertyDamageValue.slice(index + 1);
                          const newArr = [...arr1, ...arr2];
                          setFieldValue(`claimValues.propertyDamageValue`, newArr);
                        }
                      }}
                    />
                  </Grid.Column>
                  {index + 1 === values.claimValues.propertyDamageValue.length && (
                    <Grid.Column width={4}>
                      <Button
                        style={{ marginTop: "1rem" }}
                        primary
                        onClick={() => {
                          const claimV =
                            CreateClaimEstateInitialInfo.claimValues.propertyDamageValue[0];
                          claimV.valueType = ClaimValuesTypesEnum.property_damage_value;
                          setFieldValue(
                            `claimValues.propertyDamageValue[${index + 1}]`,
                            claimV
                          );
                        }}
                      >
                        <Icon name={"plus circle"} />
                        Novo
                      </Button>
                    </Grid.Column>
                  )}
                </Grid.Row>
              );
            })}
          </Grid>
        </>}
      </div>
    </div>
  );
};

export default ClaimValuesStep;
