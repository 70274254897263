import axiosRequest from './axios-instance';

import { get, patch } from './request';
import { AxiosError } from 'axios';

export type claimFiltersType = {
  page: number;
  estateId?: string | null;
  insurerId?: string | null;
  claimEstateStatus?: string | null;
  claimPropertyStatus?: string | null;
  start?: Date | null;
  end?: Date | null;
  responsibleId?: string | null;
  address?: string | null;
  name?: string | null;
  onlyUnread: boolean | null;
  userId?: string | null;
  isReport?: boolean | null;
};
interface IGetClaims {
  id?: string;
  filters?: claimFiltersType;
  isReport?: boolean;
}

export const getClaims = async ({ id, filters }: IGetClaims) => {
  let url = id ? `/claims/${id}` : '/claims';

  let query = '';

  if (filters) {
    Object.entries(filters).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });

    url = `${url}?${query}`;
  }

  const response = await get(url);

  return response;
};

export async function getAllDashboards() {
  const response = await get('/dashboard');

  return response;
}

export const getClaimsReport = async ({ filters }: IGetClaims): Promise<number | undefined> => {
  let query = '';

  if (filters) {
    filters.isReport = true;

    Object.entries(filters).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  try {
    const response = await axiosRequest.get(`/claims/report-excel?${query}`);
    return response.status;
  } catch (error) {
    const { response } = error as AxiosError;
    return response?.status;
  }
};

export async function getClaimsByClaimResponsible(
  claimResponsibleId: string,
  estates: string[]
) {
  const estatesJoined = estates.join(',');
  const response = await get(
    `/claims/by-responsible-and-estates/${claimResponsibleId}/${estatesJoined}`
  );
  return response;
}

export async function transferClaimsResponsibleById(
  oldClaimResponsibleId: string,
  newClaimResponsibleId: string,
  estates: string[],
  shouldTransferClaims: boolean,
) {
  const estatesJoined = estates.join(',');
  return patch(
    `/claims/transfer-estates-claim-responsible`,
    {
      oldUser: oldClaimResponsibleId,
      newUser: newClaimResponsibleId,
      estatesIds: estatesJoined,
      shouldTransferClaims,
    }
  );
}
