import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  Dimmer,
  Segment,
  Loader,
  Grid,
  Input,
  Dropdown,
  Button,
  Menu,
  Icon
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { TableWrapper, Header, NotFoundWrapper, ListWrapper } from './styles';
import { iRootDispatch, iRootState } from '../../store';
import * as usersService from '../../services/user';
import IconButton from '../../components/IconButton';
import { translateUserRole, translateUserStatus } from '../../util';
import { FieldLabel } from '../../styles';
import { UserRolesEnum } from '../../enums/user-roles.enum';
import { UserState } from '../../types/UserState';
import AuthorizationContainer from '../../components/AuthorizationContainer';
import { ActionEnum } from '../../enums/authz-action.enum';
import { FeatureEnum } from '../../enums/authz-feature.enum';
import { AvailableBrokersAndEstatesState } from '../../store/types/temp-types';
import { CustomDropDownOptions } from '../../types';
import { UserStatusEnum } from '../../enums/user-status.enum';

type OptionsType = {
  value: string;
  text: string;
  key: number;
};

const statusOptions: CustomDropDownOptions[] = [
  {
    key: 1,
    text: 'Ativo',
    value: UserStatusEnum.active
  },
  {
    key: 2,
    text: 'Inativo',
    value: UserStatusEnum.inactive
  },
  {
    key: 3,
    text: 'Pendente',
    value: UserStatusEnum.pending
  }
]

const UsersList = (props: Props) => {
  const user: UserState = props.user;
  const availableBrokersAndEstates = props.availableBrokersAndEstates;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<UserState[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataCount, setDataCount] = useState<number>(0);
  const [fieldValues, setFieldValues] = useState({
    name: '',
    email: '',
    estate: '',
    role: '',
    status: ''
  });
  const [options, setOptions] = useState<OptionsType[]>([]);
  const [lastPage, setLastPage] = useState<number>(0);
  const userRole = user.role as UserRolesEnum;

  const confiaxUser = user.broker !== null;

  const roleOptions = availableBrokersAndEstates.roles;

  const getUsers = async () => {
    setIsLoading(true);
    const searchId = user.broker
      ? String(user.broker.id)
      : user.estate
      ? String(user.estate.id)
      : '';
    const estateId = user?.estate ? String(user?.estate?.id) : '';
    const usersList = userRole.includes('BROKER')
      ? await usersService.getUsers(searchId, currentPage, fieldValues)
      : await usersService.getUsers(searchId, currentPage, {
          ...fieldValues,
          estate: estateId
        });
    const list = usersList.data;
    const count = usersList.count;
    setUsers(list);
    if(currentPage === 1) setDataCount(count);
    setIsLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, [currentPage, user.broker, user.estate, userRole]);

  useEffect(() => {
    setLastPage(Math.ceil(dataCount / 10));
  }, [dataCount]);

  useEffect(() => {
    if (options.length === 0) {
      setIsLoading(true);
      const estates = availableBrokersAndEstates.estates.map(estate => {
        return {
          key: estate.key,
          text: estate.text,
          value: estate.value.id
        };
      });

      setOptions(estates);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [availableBrokersAndEstates.estates, options.length]);

  const handleFormInputChange = (e: any) => {
    const { name, value } = e.target;
    setFieldValues({ ...fieldValues, [name]: value });
  };

  const getCompanyName = (user: UserState) => {
    if(user.broker) {
      return user.broker.name
    }

    if(user.estate) {
      return user.estate.name
    }

    if(user.franchise) {
      return user.franchise.name
    }
  }

  function renderTableData(users: UserState[]) {
    return users.map(user => {
      return (
        <Table.Row key={user.id} textAlign={'left'}>
          <Table.Cell width={3}>{user.name}</Table.Cell>
          <Table.Cell width={2}>{user.email}</Table.Cell>
          <Table.Cell width={2}>{translateUserStatus(user.status)}</Table.Cell>
          <Table.Cell width={2}>{translateUserRole(user.role)}</Table.Cell>
          <Table.Cell width={2}>{getCompanyName(user)}</Table.Cell>
          <Table.Cell width={1} textAlign={'right'}>
            <AuthorizationContainer
              action={ActionEnum.update}
              feature={FeatureEnum.users}
            >
              <Dropdown icon={'ellipsis vertical'} direction={'left'}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      (props as any).history.push('/admin/users-edit/', { id: user.id })
                    }
                  >
                    Editar Usuário
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </AuthorizationContainer>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    getUsers();
  };

  const renderFilter = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={confiaxUser ? 4 : 5}>
              <FieldLabel style={{ marginLeft: 5 }}>NOME:</FieldLabel>
              <Input
                fluid
                placeholder={'Nome'}
                name={'name'}
                value={fieldValues.name}
                onChange={handleFormInputChange}
                style={{ marginRight: 10 }}
              />
            </Grid.Column>

            <Grid.Column width={confiaxUser ? 3 : 5}>
              <FieldLabel style={{ marginLeft: 5 }}>E-MAIL:</FieldLabel>
              <Input
                fluid
                placeholder={'E-mail'}
                name={'email'}
                value={fieldValues.email}
                onChange={handleFormInputChange}
                style={{ marginRight: 10 }}
              />
            </Grid.Column>

            <Grid.Column width={confiaxUser ? 3 : 4}>
              <FieldLabel style={{ marginLeft: 5 }}>IMOBILIÁRIA:</FieldLabel>
              <Dropdown
                fluid
                placeholder={'Imobiliária'}
                style={{ cursor: 'pointer' }}
                name={'estate'}
                onChange={(e, data) => {
                  setFieldValues({ ...fieldValues, estate: String(data.value) });
                }}
                value={options.length === 1 ? options[0].value : fieldValues.estate}
                isLoading={options.length === 0 ? true : false}
                disabled={(options.length === 0) || !confiaxUser}
                search
                selection
                clearable
                options={options || []}
              />
            </Grid.Column>

            {confiaxUser && <Grid.Column width={2}>
              <FieldLabel style={{ marginLeft: 5 }}>FUNÇÃO:</FieldLabel>
              <Dropdown
                fluid
                placeholder={'Função'}
                style={{ cursor: 'pointer' }}
                name={'role'}
                onChange={(e, data) => {
                  setFieldValues({ ...fieldValues, role: String(data.value) });
                }}
                value={fieldValues.role}
                isLoading={roleOptions.length === 0 ? true : false}
                search
                selection
                clearable
                options={roleOptions}
              />
            </Grid.Column>}

            {confiaxUser && <Grid.Column width={2}>
              <FieldLabel style={{ marginLeft: 5 }}>STATUS:</FieldLabel>
              <Dropdown
                fluid
                placeholder={'Status'}
                style={{ cursor: 'pointer' }}
                name={'status'}
                onChange={(e, data) => {
                  setFieldValues({ ...fieldValues, status: String(data.value) });
                }}
                value={fieldValues.status}
                isLoading={statusOptions.length === 0 ? true : false}
                search
                selection
                clearable
                options={statusOptions}
              />
            </Grid.Column>}

            <Grid.Column width={2}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <Button
                  size={'mini'}
                  onClick={() => setFieldValues({ name: '', email: '', estate: '', role: '', status: '' })}
                  type="button"
                >
                  Limpar
                </Button>
                <Button type="submit" primary size={'mini'} onClick={handleSubmit}>
                  Pesquisar
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </form>
    );
  };

  return (
    <>
      <ListWrapper>
        {renderFilter()}
        <Dimmer.Dimmable as={Segment} dimmed={isLoading}>
          <Dimmer active={isLoading} inverted>
            <Loader />
          </Dimmer>
          {users && users.length > 0 ? (
            <TableWrapper>
              <Table>
                <Header>
                  <Table.Row textAlign={'left'}>
                    <Table.HeaderCell width={3}>NOME</Table.HeaderCell>
                    <Table.HeaderCell width={2}>EMAIL</Table.HeaderCell>
                    <Table.HeaderCell width={2}>STATUS</Table.HeaderCell>
                    <Table.HeaderCell width={2}>FUNÇÃO</Table.HeaderCell>
                    <Table.HeaderCell width={2}>EMPRESA</Table.HeaderCell>
                    <Table.HeaderCell width={1} textAlign={'right'}>
                      <IconButton color="blue" name="setting" />
                    </Table.HeaderCell>
                  </Table.Row>
                </Header>
                <Table.Body>{renderTableData(users)}</Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="17">
                      <Menu floated="right" pagination>
                        <Menu.Item>
                          <p>{`${currentPage * 10 - 9} - ${
                            currentPage * 10
                          }  de ${dataCount}`}</p>
                        </Menu.Item>
                        <Menu.Item
                          disabled={currentPage === 1}
                          as="a"
                          icon
                          onClick={() => setCurrentPage(1)}
                        >
                          <Icon name="angle double left" />
                        </Menu.Item>
                        <Menu.Item
                          disabled={currentPage === 1}
                          as="a"
                          icon
                          onClick={() => setCurrentPage(currentPage - 1)}
                        >
                          <Icon name="chevron left" />
                        </Menu.Item>
                        <Menu.Item as="a">{currentPage}</Menu.Item>
                        <Menu.Item
                          disabled={currentPage === lastPage || users.length < 10}
                          as="a"
                          icon
                          onClick={() => setCurrentPage(currentPage + 1)}
                        >
                          <Icon name="chevron right" />
                        </Menu.Item>
                        <Menu.Item as="a" icon>
                          <Icon
                            disabled={currentPage === lastPage || users.length < 10}
                            name="angle double right"
                            onClick={() => setCurrentPage(lastPage)}
                          />
                        </Menu.Item>
                      </Menu>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </TableWrapper>
          ) : (
            <NotFoundWrapper>
              <p>Nenhum usuário cadastrado</p>
            </NotFoundWrapper>
          )}
        </Dimmer.Dimmable>{' '}
      </ListWrapper>
    </>
  );
};

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});
type Props = ReturnType<typeof mapState>;
const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

export default connect(mapState, mapDispatch)(UsersList);
