export const PortoEssencialHireInitialValue = {
  product: {
    code: "",
  },
  lessor: {
    document: "",
    name: "",
    birthdate: "",
    mother: "",
    email: "",
    cellphone: "",
    commercialPhone: "",
    residentialPhone: "",
    address: {
      zipcode: "",
      streetType: "",
      street: "",
      number: "",
      complement: "",
      district: "",
      city: "",
      estate: "",
    },
  },
  property: {
    zipcode: "",
    streetType: "",
    street: "",
    number: "",
    complement: "",
    district: "",
    city: "",
    estate: "",
  },
  tenants: {
    document: "",
    name: "",
    holder: "",
    birthdate: "",
    gender: "",
    mother: "",
    email: "",
    cellphone: "",
    commercialPhone: "",
    residentialPhone: "",
  },
  contract: {
    dueDate: "",
    vigencyType:"",
    startContractDate: "",
    endContractDate: "",
    startInsuranceDate: "",
    endInsuranceDate: "",
  }
};
