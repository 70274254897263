import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Input,
  Segment,
} from "semantic-ui-react";
import moment from "moment";
import { FieldLabel } from "../../../../../styles";
import {
  formatAmount,
  maskMoney,
  moneyStringToNumber,
  maskZipCode,
  maskPhoneNumber,
  maskCpfOrCnpj,
  unmaskCpfOrCnpj,
} from "../../../../../services/masks";
import { turnUFIntoEstate } from "../../../util";
import {
  constructionTypeList,
  propertyTypesList,
  propertyUseTypeList,
  translateConstructionType,
  translateCoverageType,
  translatePropertyType,
  translatePropertyUseType,
  translateStatus,
  historicalProtectedPropertyList,
  insuredOwnerList,
  sharedPropertyList,
  coverageTypeList,
} from "../../../utils/Pottencial";
// import SemanticDatePicker from 'react-semantic-ui-datepickers'
import useEffectOnce from "../../../../../services/UseEffectOnce";
import { add365Days, findObjectInArray } from "../../../../../util";
import useDebounce from "../../../../../services/useDebounce";
import {
  dangerNotification,
  successNotification,
  warningNotification,
} from "../../../../../services/notification";
import { AddressService } from "../../../../../services/address";
import { getDataByDocument } from "../../../../../services/documents";
import * as Storage from "../../../../../services/sessionStorage";
import { sendReanalysis } from "../../../../../services/fire-insurance-budget";
import { set } from "lodash";
import CustomDatePicker from "../../../../../components/CustomDatePicker";
import AppMaskedInput from "../../../../../components/MaskedInput";

// import { Container } from './styles';

interface InputDataType {
  value: string;
}

interface PottencialViewInterface {
  fireInsurance: any;
  close: () => void;
}

const HeaderAndDivider = styled.h3`
  border-bottom: 1px solid #3498db;
  color: #3498db;
  width: 100%;
  margin-top: 20px !important;
  padding-bottom: 5px;
  letter-spacing: 0.4px;
`;

const DetailInput = styled(Input)`
  cursor: default !important;
  pointer-events: none !important;
  opacity: 0.7;
`;

const DetailField = ({
  label,
  value,
}: {
  label: string;
  value: string | number;
}) => {
  return (
    <>
      <FieldLabel>{label}</FieldLabel>
      <DetailInput fluid placeholder={label} value={value} />
    </>
  );
};

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);
const thirtyDaysFromNow = new Date(today);
thirtyDaysFromNow.setDate(today.getDate() + 30);

const PottencialEdit = (props: PottencialViewInterface) => {
  const { fireInsurance, close } = props;
  const user = Storage.get("user");
  const history = useHistory();
  const [maxCommissionValue, setMaxCommisionValue] = useState<number>(40);
  const [analisysId, setAnalisysId] = useState<string>(fireInsurance?.id);

  const [policyPeriodStart, setPolicyPeriodStart] = useState<Date>(
    new Date(fireInsurance?.policyPeriodStart) > today
      ? new Date(fireInsurance?.policyPeriodStart)
      : today
  );
  const [policyPeriodEnd, setPolicyPeriodEnd] = useState<Date>(
    new Date(fireInsurance?.policyPeriodEnd)
  );

  const [commissionPercentage, setCommissionPercentage] = useState<any>(
    Number(fireInsurance?.estateComissionPercentage)
  );
  const [maskedCommissionPercentage, setMaskedCommissionPercentage] =
    useState<string>(
      formatAmount(Number(fireInsurance?.estateComissionPercentage) * 100)
    );

  const [rentValue, setRentValue] = useState<any>(
    Number(fireInsurance?.fireInsurance.rentValue)
  );
  const [maskedRentValue, setMaskedRentValue] = useState<string>(
    formatAmount(Number(fireInsurance?.fireInsurance.rentValue) * 100)
  );

  const debouncedmaskedCommissionPercentage = useDebounce<string>(
    maskedCommissionPercentage,
    1000
  );
  const commissionRef = useRef(null);

  const [coverageType, setCoverageType] = useState<any>(
    fireInsurance.coverages
      .map((coverage: any) => coverage.referenceCode)
      .join(", ")
  );

  const [constructionType, setConstructionType] = useState<any>(
    fireInsurance?.fireInsurance.constructionType
  );
  const [propertyType, setPropertyType] = useState<any>(
    fireInsurance?.fireInsurance.propertyType
  );
  const [propertyUseType, setPropertyUseType] = useState<any>(
    fireInsurance?.fireInsurance.propertyUseType
  );

  const [historicalProtectedProperty, setHistoricalProtectedProperty] =
    useState<any>(
      String(
        fireInsurance?.fireInsurance.historicalProtectedProperty
      ).toLocaleLowerCase()
    );
  const [insuredOwner, setInsuredOwner] = useState<any>("");
  const [sharedProperty, setSharedProperty] = useState<any>(
    String(fireInsurance?.fireInsurance.sharedProperty).toLocaleLowerCase()
  );

  const address = fireInsurance?.fireInsurance.propertyAddresses;

  const [isLoadingAddress, setIsLoadingAddress] = useState<boolean>(false);
  const [zipCode, setZipCode] = useState<any>(address.zipcode);
  const debouncedZipCode = useDebounce<string>(zipCode, 500);
  const zipCodeRef = useRef(null);
  const [street, setStreet] = useState<string>(address.street);
  const [number, setNumber] = useState<string>(address.number);
  const [complement, setComplement] = useState<any>(address.complement);
  const [district, setDistrict] = useState<string>(address.district);
  const [city, setCity] = useState<string>(address.city);
  const [state, setState] = useState<string>(address.state);

  const [hasNotComplement, setHasNotComplement] = useState<boolean>(false);
  const [isMandatoryComplement, setIsMandatoryComplement] =
    useState<boolean>(false);

  const [insuredDocument, setInsuredDocument] = useState<string>(
    fireInsurance?.fireInsurance.insuredDocument
  );
  const debouncedInsuredDocument = useDebounce<string>(
    unmaskCpfOrCnpj(insuredDocument),
    500
  );
  const [insuredName, setInsuredName] = useState<string>(
    fireInsurance?.fireInsurance.insuredName
  );
  const [insuredEmail, setInsuredEmail] = useState<string>(
    fireInsurance?.fireInsurance.insuredEmail
  );
  const [insuredCellphone, setInsuredCellphone] = useState<string>(
    fireInsurance?.fireInsurance.insuredCellphone
  );

  const [propertyOwnerDocument, setPropertyOwnerDocument] = useState<string>(
    fireInsurance?.fireInsurance.propertyOwnerDocument
  );
  const debouncedPropertyOwnerDocument = useDebounce<string>(
    unmaskCpfOrCnpj(propertyOwnerDocument),
    500
  );
  const [propertyOwnerName, setPropertyOwnerName] = useState<string>(
    fireInsurance?.fireInsurance.propertyOwnerName
  );
  const [propertyOwnerEmail, setPropertyOwnerEmail] = useState<string>(
    fireInsurance?.fireInsurance.propertyOwnerEmail
  );

  const [isLoadingInsuredDocument, setIsLoadingInsuredDocument] =
    useState<boolean>(false);
  const [isLoadingOwnerDocument, setIsLoadingOwnerDocument] =
    useState<boolean>(false);

  const [isFirstLoadingCep, setIsFirstLoadingCep] = useState<boolean>(true);
  const [isFirstLoadingInsuredDocument, setIsFirstLoadingInsuredDocument] =
    useState<boolean>(true);
  const [isFirstLoadingOwnerDocument, setIsFirstLoadingOwnerDocument] =
    useState<boolean>(true);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  // useEffect(() => {
  //   if (fireInsurance) {
  //     const cover = fireInsurance.coverages.map((coverage: any) => coverage.referenceCode).join(', ')
  //     console.log({fireInsurance, address, cover})
  //   }
  // }, [fireInsurance])

  useEffect(() => {
    if (propertyType) {
      const typesToMandatoryComplement = [
        "Apartment",
        "CondominiumApartment",
        "CondominiumHouse",
      ];
      if (typesToMandatoryComplement.includes(propertyType)) {
        setIsMandatoryComplement(true);
        setHasNotComplement(false);
      } else {
        setIsMandatoryComplement(false);
      }
      // console.log({propertyType});
    }
  }, [propertyType]);

  useEffectOnce(() => {
    const insurerData = (fireInsurance.fireInsurance.estate as any).insurerData;
    const thisInsurer = findObjectInArray(
      insurerData,
      "insurerId",
      fireInsurance.insurerId
    );
    const commissionPercentage = Number(thisInsurer.commissionPercentage);
    setMaxCommisionValue(commissionPercentage);
    // console.log({fireInsurance, historicalProtectedPropertyList, insuredOwnerList, sharedPropertyList});
  });

  useEffect(() => {
    // console.log({maskedCommissionPercentage})
    setCommissionPercentage(moneyStringToNumber(maskedCommissionPercentage));
  }, [maskedCommissionPercentage]);

  useEffect(() => {
    // console.log({maskedCommissionPercentage})
    setRentValue(moneyStringToNumber(maskedRentValue));
  }, [maskedRentValue]);

  useEffect(() => {
    async function getAddress() {
      if (!isFirstLoadingCep) {
        if (debouncedZipCode) {
          setIsLoadingAddress(true);
          console.log({ debouncedZipCode });
          try {
            const address = await AddressService.getAddressByZipCode(
              debouncedZipCode
            );
            setStreet(address.street);
            setCity(address.city);
            setDistrict(address.district);
            setState(address.state);
            setNumber("");
            setComplement("");
          } catch (err) {
            setZipCode("");
            setStreet("");
            setCity("");
            setDistrict("");
            setState("");
            setNumber("");
            setComplement("");
            warningNotification(
              "CEP não encontrado",
              "Não foi possível encontrar o endereço pelo CEP informado"
            );
            (zipCodeRef.current as any).focus();
          }
          setIsLoadingAddress(false);
        }
      } else {
        setIsFirstLoadingCep(false);
      }
    }

    getAddress();
  }, [debouncedZipCode]);

  useEffect(() => {
    if (!isFirstLoadingCep) {
      if (hasNotComplement) {
        setComplement("");
      }
    }
  }, [hasNotComplement]);

  useEffect(() => {
    if (!!debouncedmaskedCommissionPercentage) {
      const nmr = moneyStringToNumber(debouncedmaskedCommissionPercentage);

      if (maxCommissionValue < nmr || nmr < 1) {
        const newValue = formatAmount(maxCommissionValue * 100);
        warningNotification(
          "Error",
          `O percentual de comissão deve ser entre 1,00% e ${newValue}%`,
          "bottom",
          4000
        );
        (commissionRef.current as any).focus();
        setMaskedCommissionPercentage(newValue);
      }
    }
  }, [debouncedmaskedCommissionPercentage]);

  useEffect(() => {
    // console.log({commissionPercentage})
    // setCommissionPercentage(moneyStringToNumber(maskedCommissionPercentage))
  }, [commissionPercentage]);

  useEffect(() => {
    if (policyPeriodStart) {
      setPolicyPeriodEnd(add365Days(policyPeriodStart));
    }
  }, [policyPeriodStart]);

  const getDocument = async (target: string) => {
    if (target === "insured") {
      setIsLoadingInsuredDocument(true);
      // console.log({debouncedInsuredDocument})
      if (
        debouncedInsuredDocument.length === 11 ||
        debouncedInsuredDocument.length === 14
      ) {
        try {
          const insuredData = await getDataByDocument(debouncedInsuredDocument);
          if (debouncedInsuredDocument.length === 14) {
            setInsuredName((insuredData as any).company);
            setInsuredEmail((insuredData as any).email);
            setInsuredCellphone((insuredData as any).phone);
          } else {
            setInsuredName((insuredData as any).name);
            setInsuredEmail("");
            setInsuredCellphone("");
          }
        } catch (err) {
          warningNotification(
            `Não foi possível encontrar o ${
              debouncedInsuredDocument.length === 11 ? "CPF" : "CNPJ"
            }`,
            "O documento informado não foi encontrado no nosso banco de dados"
          );
        }
      }
      setIsLoadingInsuredDocument(false);
    } else if (target === "owner") {
      setIsLoadingOwnerDocument(true);
      // console.log({debouncedPropertyOwnerDocument})
      if (
        debouncedPropertyOwnerDocument.length === 11 ||
        debouncedPropertyOwnerDocument.length === 14
      ) {
        try {
          const ownerData = await getDataByDocument(
            debouncedPropertyOwnerDocument
          );
          if (debouncedPropertyOwnerDocument.length === 14) {
            setPropertyOwnerName(ownerData.company);
            setPropertyOwnerEmail(ownerData.email);
          } else {
            setPropertyOwnerName(ownerData.name);
            setPropertyOwnerEmail("");
            // console.log({data})
          }
        } catch (err) {
          warningNotification(
            `Não foi possível encontrar o ${
              debouncedPropertyOwnerDocument.length === 11 ? "CPF" : "CNPJ"
            }`,
            "O documento informado não foi encontrado no nosso banco de dados"
          );
        }
      }
      setIsLoadingOwnerDocument(false);
    }
  };

  useEffect(() => {
    if (isFirstLoadingInsuredDocument) {
      setIsFirstLoadingInsuredDocument(false);
    } else {
      if (debouncedInsuredDocument) getDocument("insured");
    }
  }, [debouncedInsuredDocument]);

  useEffect(() => {
    if (isFirstLoadingOwnerDocument) {
      setIsFirstLoadingOwnerDocument(false);
    } else {
      if (debouncedPropertyOwnerDocument) getDocument("owner");
    }
  }, [debouncedPropertyOwnerDocument]);

  const edit = async () => {
    setIsEditing(true);
    const analysis_id = analisysId;
    const updated_by = user.id;
    const insurer_payload = {
      policy_period_start: moment(policyPeriodStart).format("YYYY-MM-DD"),
      historical_protected_property: JSON.parse(historicalProtectedProperty),
      shared_property: JSON.parse(sharedProperty),
      insured_owner: JSON.parse(insuredOwner),
      commission_percentage: commissionPercentage,
      property_type: propertyType,
      construction_type: constructionType,
      property_use_type: propertyUseType,
      participants: [
        {
          document_number: insuredDocument.replace(/\D/g, ""),
          name: insuredName,
          email: insuredEmail,
          cellphone: insuredCellphone.replace(/\D/g, ""),
          role: "Insured",
        },
        {
          document_number: propertyOwnerDocument.replace(/\D/g, ""),
          name: propertyOwnerName,
          email: propertyOwnerEmail,
          role: "Beneficiary",
          participation_percentage: 1,
        },
      ],
      coverages: [
        {
          reference: {
            coverage_code: coverageType,
          },
        },
      ],
    };
    const payload = {
      risk_place: {
        rent_value: rentValue,
        zip_code: zipCode.replace("-", ""),
        street,
        number,
        district,
        city,
        complement: !!complement ? complement : null,
        state,
      },
    };

    const dto = {
      analysis_id,
      updated_by,
      insurer_payload,
      payload,
    };

    try {
      const data = await sendReanalysis(dto);
      // console.log({data})

      if (data.success) {
        successNotification("Sucesso", "Análise atualizada com sucesso");
        history.push("/admin/fire-insurance/budget/list");
      }
    } catch (err) {
      dangerNotification("Erro", "Não foi possível atualizar a análise");
      // console.log({err})
    }
    setIsEditing(false);
  };

  return (
    <Grid style={{ padding: 5 }} columns={"equal"}>
      <HeaderAndDivider>Dados do Seguro</HeaderAndDivider>
      <Grid.Row>
        <Grid.Column>
          <DetailField label={"ID"} value={fireInsurance?.id} />
        </Grid.Column>
        <Grid.Column>
          <DetailField
            label={"Seguradora"}
            value={fireInsurance?.insurer.name}
          />
        </Grid.Column>
        <Grid.Column>
          <DetailField
            label={"Imobiliária"}
            value={fireInsurance?.fireInsurance.estate.name}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <FieldLabel>Data de Início</FieldLabel>
          {/* <SemanticDatePicker
            keepOpenOnClear
            locale={'pt-BR'}
            value={policyPeriodStart}
            error={!!!policyPeriodStart}
            minDate={yesterday}
            maxDate={thirtyDaysFromNow}
            format={'DD/MM/YYYY'}
            onChange={(e, data: any) => setPolicyPeriodStart(data.value)}
          /> */}

          <CustomDatePicker
            fluid
            selected={policyPeriodStart}
            error={!!!policyPeriodStart}
            minDate={yesterday}
            maxDate={thirtyDaysFromNow}
            customInput={
              <AppMaskedInput
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  "/",
                  /[0-9]/,
                  /[0-9]/,
                  "/",
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                ]} />}
            dateFormat={"dd/MM/yyyy"}
            onChange={(date: any) => setPolicyPeriodStart(date)}
          />
        </Grid.Column>
        <Grid.Column>
          <FieldLabel>Data de Término</FieldLabel>
          {/* <SemanticDatePicker
            disabled
            keepOpenOnClear
            locale={'pt-BR'}
            value={policyPeriodEnd}
            error={!!!policyPeriodEnd}
            format={'DD/MM/YYYY'}
            onChange={(e, data: any) => setPolicyPeriodEnd(data.value)}
          /> */}

          <CustomDatePicker
            fluid
            selected={policyPeriodEnd}
            error={!!!policyPeriodEnd}
            onChange={(date: any) => setPolicyPeriodEnd(date)}
            customInput={
              <AppMaskedInput
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  "/",
                  /[0-9]/,
                  /[0-9]/,
                  "/",
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                ]} />}
            dateFormat={"dd/MM/yyyy"}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <FieldLabel>Tipo de Cobertura</FieldLabel>
          <Dropdown
            fluid
            search
            clearable
            selection
            options={coverageTypeList}
            error={!!!coverageType}
            value={coverageType}
            onChange={(e, data: any) => setCoverageType(data.value)}
            disabled
          />
        </Grid.Column>
        {/* <Grid.Column>
          <DetailField
            label={'Quantidade de Parcelas'}
            value={11} // TODO - get from API
          />
        </Grid.Column> */}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <FieldLabel required>Tipo de Imóvel</FieldLabel>
          <Dropdown
            fluid
            search
            clearable
            selection
            options={propertyTypesList}
            error={!!!propertyType}
            value={propertyType}
            onChange={(e, { name, value }) => setPropertyType(value)}
          />
        </Grid.Column>
        <Grid.Column>
          <FieldLabel required>Tipo de Ocupação</FieldLabel>
          <Dropdown
            fluid
            search
            clearable
            selection
            options={propertyUseTypeList}
            error={!!!propertyUseType}
            value={propertyUseType}
            onChange={(e, { name, value }) => setPropertyUseType(value)}
          />
        </Grid.Column>
        <Grid.Column>
          <FieldLabel required>Tipo de Construção</FieldLabel>
          <Dropdown
            fluid
            search
            clearable
            selection
            options={constructionTypeList}
            error={!!!constructionType}
            value={constructionType}
            onChange={(e, { name, value }) => setConstructionType(value)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <FieldLabel required>Imóvel Historicamente Protegido</FieldLabel>
          <Dropdown
            fluid
            search
            clearable
            selection
            options={historicalProtectedPropertyList}
            error={!!!historicalProtectedProperty}
            value={historicalProtectedProperty}
            onChange={(e, { name, value }) =>
              setHistoricalProtectedProperty(value)
            }
          />
        </Grid.Column>
        <Grid.Column>
          <FieldLabel required>Imóvel Compartilhado</FieldLabel>
          <Dropdown
            fluid
            search
            clearable
            selection
            options={sharedPropertyList}
            error={!!!sharedProperty}
            value={sharedProperty}
            onChange={(e, { name, value }) => setSharedProperty(value)}
          />
        </Grid.Column>
        <Grid.Column>
          <FieldLabel required>Segurado é o proprietário?</FieldLabel>
          <Dropdown
            fluid
            search
            clearable
            selection
            options={insuredOwnerList}
            error={!!!insuredOwner}
            value={insuredOwner}
            onChange={(e, { name, value }) => setInsuredOwner(value)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <FieldLabel required>Valor do Aluguel</FieldLabel>
          <Input
            fluid
            icon={"dollar"}
            iconPosition={"left"}
            error={!!!rentValue}
            value={formatAmount(maskedRentValue)}
            onChange={(e, data: InputDataType) =>
              setMaskedRentValue(formatAmount(data.value))
            }
          />
        </Grid.Column>
        <Grid.Column>
          <FieldLabel required>Percentual de Comissão</FieldLabel>
          <Input
            fluid
            icon={"percent"}
            iconPosition={"left"}
            error={!!!commissionPercentage}
            value={formatAmount(maskedCommissionPercentage)}
            ref={commissionRef}
            onChange={(e, data: InputDataType) =>
              setMaskedCommissionPercentage(formatAmount(data.value))
            }
          />
        </Grid.Column>
        <Grid.Column></Grid.Column>
      </Grid.Row>

      <HeaderAndDivider>Dados do Imóvel</HeaderAndDivider>
      <Grid.Row>
        <Grid.Column>
          <FieldLabel required>CEP</FieldLabel>
          <Input
            fluid
            disabled={isLoadingAddress}
            loading={isLoadingAddress}
            ref={zipCodeRef}
            placeholder={"CEP"}
            error={!!!zipCode}
            value={maskZipCode(zipCode)}
            onChange={(e, data: InputDataType) => setZipCode(data.value)}
          />
        </Grid.Column>
        <Grid.Column>
          <FieldLabel required>Logradouro</FieldLabel>
          <Input
            fluid
            disabled={isLoadingAddress}
            loading={isLoadingAddress}
            placeholder={"Logradouro"}
            error={!!!street}
            value={street}
            onChange={(e, data: InputDataType) => setStreet(data.value)}
          />
        </Grid.Column>
        <Grid.Column>
          <FieldLabel required>Bairro</FieldLabel>
          <Input
            fluid
            disabled={isLoadingAddress}
            loading={isLoadingAddress}
            placeholder={"Bairro"}
            error={!!!district}
            value={district}
            onChange={(e, data: InputDataType) => setDistrict(data.value)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <FieldLabel required>Cidade</FieldLabel>
          <Input
            fluid
            disabled={isLoadingAddress}
            loading={isLoadingAddress}
            placeholder={"Cidade"}
            error={!!!city}
            value={city}
            onChange={(e, data: InputDataType) => setCity(data.value)}
          />
        </Grid.Column>
        <Grid.Column>
          <FieldLabel required>Estado</FieldLabel>
          <Input
            fluid
            disabled={isLoadingAddress}
            loading={isLoadingAddress}
            placeholder={"Estado"}
            error={!!!state}
            value={turnUFIntoEstate(state)}
            onChange={(e, data: InputDataType) => setState(data.value)}
          />
        </Grid.Column>
        <Grid.Column>
          <FieldLabel required>Número</FieldLabel>
          <Input
            fluid
            disabled={isLoadingAddress}
            loading={isLoadingAddress}
            placeholder={"Número"}
            error={!!!number}
            value={number}
            onChange={(e, data: InputDataType) => setNumber(data.value)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <FieldLabel required={!hasNotComplement}>Complemento</FieldLabel>
          <Input
            fluid
            disabled={hasNotComplement || isLoadingAddress}
            loading={isLoadingAddress}
            placeholder={"Complemento"}
            error={!hasNotComplement && !!!complement}
            value={complement}
            onChange={(e, data: InputDataType) => setComplement(data.value)}
          />
        </Grid.Column>
        <Grid.Column style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            checked={hasNotComplement}
            onClick={() => setHasNotComplement(!hasNotComplement)}
            style={{ pointerEvents: isMandatoryComplement && "none" }}
            label={"Não possui Complemento"}
            disabled={isMandatoryComplement}
          />
        </Grid.Column>
        <Grid.Column></Grid.Column>
      </Grid.Row>

      <HeaderAndDivider>Dados do Inquilino</HeaderAndDivider>
      <Grid.Row>
        <Grid.Column>
          <FieldLabel required>CPF/CNPJ</FieldLabel>
          <Input
            fluid
            placeholder={"CPF/CNPJ"}
            disabled={isLoadingInsuredDocument}
            loading={isLoadingInsuredDocument}
            maxLength={18}
            error={!!!insuredDocument}
            value={maskCpfOrCnpj(insuredDocument)}
            onChange={(e, data: InputDataType) =>
              setInsuredDocument(data.value)
            }
          />
        </Grid.Column>
        <Grid.Column>
          <FieldLabel required>Nome/Razão Social</FieldLabel>
          <Input
            fluid
            placeholder={"Nome/Razão Social"}
            disabled={isLoadingInsuredDocument}
            loading={isLoadingInsuredDocument}
            error={!!!insuredName}
            value={insuredName}
            onChange={(e, data: InputDataType) => setInsuredName(data.value)}
          />
        </Grid.Column>
        <Grid.Column>
          <FieldLabel required>E-mail</FieldLabel>
          <Input
            fluid
            placeholder={"E-mail"}
            disabled={isLoadingInsuredDocument}
            loading={isLoadingInsuredDocument}
            error={!!!insuredEmail}
            value={insuredEmail}
            onChange={(e, data: InputDataType) => setInsuredEmail(data.value)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <FieldLabel required>Telefone</FieldLabel>
          <Input
            fluid
            placeholder={"Telefone"}
            disabled={isLoadingInsuredDocument}
            loading={isLoadingInsuredDocument}
            error={!!!insuredCellphone}
            value={maskPhoneNumber(insuredCellphone)}
            onChange={(e, data: InputDataType) =>
              setInsuredCellphone(data.value)
            }
          />
        </Grid.Column>
        <Grid.Column></Grid.Column>
        <Grid.Column></Grid.Column>
      </Grid.Row>

      <HeaderAndDivider>Dados do Proprietário</HeaderAndDivider>
      <Grid.Row>
        <Grid.Column>
          <FieldLabel required>CPF/CNPJ</FieldLabel>
          <Input
            fluid
            placeholder={"CPF/CNPJ"}
            disabled={isLoadingOwnerDocument}
            loading={isLoadingOwnerDocument}
            error={!!!propertyOwnerDocument}
            value={maskCpfOrCnpj(propertyOwnerDocument)}
            onChange={(e, data: InputDataType) =>
              setPropertyOwnerDocument(data.value)
            }
          />
        </Grid.Column>
        <Grid.Column>
          <FieldLabel required>Nome/Razão Social</FieldLabel>
          <Input
            fluid
            placeholder={"Nome/Razão Social"}
            disabled={isLoadingOwnerDocument}
            loading={isLoadingOwnerDocument}
            error={!!!propertyOwnerName}
            value={propertyOwnerName}
            onChange={(e, data: InputDataType) =>
              setPropertyOwnerName(data.value)
            }
          />
        </Grid.Column>
        <Grid.Column>
          <FieldLabel required>E-mail</FieldLabel>
          <Input
            fluid
            placeholder={"E-mail"}
            disabled={isLoadingOwnerDocument}
            loading={isLoadingOwnerDocument}
            error={!!!propertyOwnerEmail}
            value={propertyOwnerEmail}
            onChange={(e, data: InputDataType) =>
              setPropertyOwnerEmail(data.value)
            }
          />
        </Grid.Column>
      </Grid.Row>

      <Divider />
      <Grid.Row>
        <Grid.Column style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            icon
            labelPosition={"left"}
            loading={isEditing}
            disabled={isEditing}
            onClick={close}
          >
            <Icon name={"arrow left"} />
            Voltar
          </Button>
          <Button
            primary
            icon
            labelPosition={"right"}
            loading={isEditing}
            disabled={isEditing}
            onClick={edit}
          >
            <Icon name={"pencil"} />
            Editar
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default PottencialEdit;
