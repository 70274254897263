import React, { useEffect, useRef, useState } from 'react';
import { Button, Confirm, Icon, Modal, Popup } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom'
import PottencialView from './Pottencial'
import * as Storage from '../../../../services/sessionStorage';
import { hirePottencial } from '../../../../services/fire-insurance-policy';

import { FireInsuranceQuotation } from '../../../../types/FireInsuranceQuotation';
import useEffectOnce from '../../../../services/UseEffectOnce';
import { successNotification } from '../../../../services/notification';

// import { Container } from './styles';
interface DetailModalInterface {
  open: boolean;
  fireInsurance: FireInsuranceQuotation;
  close: () => void;
  hire?: boolean;
}

const DetailModal = (props: DetailModalInterface) => {
  const history = useHistory();
  const { fireInsurance, close, open} = props;
  const [showJson, setShowJson] = useState<boolean>(false);
  const [isHiring, setIsHiring] = useState<boolean>(false);

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const user = Storage.get('user');

  const handleConfirmCancel = () => {
    setConfirmOpen(false);
  }

  const handleConfirmConfirm = () => {
    setConfirmOpen(false);
    hire()
  }


  useEffect(() => {
    if (open) {
      if (props.hire) {
        setConfirmOpen(true)
      }
    }
  }, [open])

  const hire = async () => {
    setIsHiring(true);
    switch (fireInsurance?.insurer.code) {
      case 'POTTENCIAL':
        {
          const dto = {
            fireInsuranceAnalysisId: fireInsurance.id,
            userId: String(user.id),
          }

          try {
            const data = await hirePottencial(dto);

            if (data.success) {
              successNotification('Solicitação de Contratação','Solicitação de contratação encaminhada com sucesso');
              history.push('/admin/fire-insurance/policies');
            }
          } catch (err) {
            alert((err as any).message)
          }
        }
        break;
      default:
        alert('Não implementado')
        break;
    }
    setIsHiring(false);
  }

  const renderView = (insurerCode: string) => {
    switch (insurerCode) {
      case 'POTTENCIAL':
        return <PottencialView fireInsurance={fireInsurance} showJson={showJson} />;
      default:
        return <PottencialView fireInsurance={fireInsurance} showJson={showJson} />;
    }
  }

  return (
    <Modal
      open={open}
      onClose={close}
    >
      <Modal.Header>
        Detalhes da Cotação de Seguro Incêndio
        {
          user.role.includes('ADMIN') &&
            <Popup
              trigger={
                <Icon name={showJson ? 'eye' : 'eye slash'} onClick={() => setShowJson(!showJson)} style={{marginLeft: 10, color: showJson ? '#333' : '#999'}} />
              }
              content={'Mostrar JSON'}
            />
        }
      </Modal.Header>
      <Modal.Content scrolling>
        {
          renderView(fireInsurance?.insurer.code)
        }
        <Confirm
          open={confirmOpen}
          onCancel={handleConfirmCancel}
          onConfirm={handleConfirmConfirm}
          content={'Tem certeza que deseja contratar o Seguro Incêndio?'}
          header={'Seguro Incêndio - Contratar'}
          confirmButton={'Contratar'}
          cancelButton={'Cancelar'}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon
          labelPosition={'left'}
          onClick={close}
        >
          <Icon name={'arrow left'} />
          Voltar
        </Button>

        {
          fireInsurance?.status === 'APPROVED' && (
            <Button
              positive
              icon
              labelPosition={'right'}
              onClick={() => setConfirmOpen(true)}
              loading={isHiring}
              disabled={isHiring}
            >
              <Icon name={'checkmark'} />
              Contratar
            </Button>
          )
        }
      </Modal.Actions>
    </Modal>
  );
}

export default DetailModal;
