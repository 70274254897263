import SectionLabel from '../../../../../../components/SectionLabel';
import { Grid, Input, InputOnChangeData } from 'semantic-ui-react';
import { Field } from 'formik';
import { maskZipCode } from '../../../../../../services/masks';
import { FormikErrorMessage } from '../../../../../../components/ErrorMessage';
import { AddressService } from '../../../../../../services/address';
import React, { ReactElement, useState, useEffect, SyntheticEvent } from 'react';
import { getErrorFormik } from '../../../../../../services/errors';
import { FieldLabel } from '../../../../../../styles';
import { StepProps } from '../../../../BondInsuranceForm';
import {iRootDispatch, iRootState} from "../../../../../../store";
import {BondInsuranceFormState} from "../../../../../../store/types/temp-types";
import {connect} from "react-redux";
import { UFOptions, hirePropertyTypeOptions } from '../../../../types';
import Dropdown from '../../../../../../components/Dropdown';

const SECTION_NAME = 'tenant_info';

const mapState = (state: iRootState) => ({
  bondInsuranceForm: state.bondInsuranceForm,
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateBondInsurance: (bondInsuranceFormState: BondInsuranceFormState) =>
    dispatch.bondInsuranceForm.updateBondInsurance(bondInsuranceFormState)
});

type AddressSectionProps = StepProps & ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const AddressSection = (props: AddressSectionProps): ReactElement => {

  const [cepLoading, setCepLoading] = useState<boolean>(false);
  const {
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    handleInputChange,
    user,
    values } = props;

  const { tenant_info } = values;

  useEffect(() => {
    if (tenant_info.zip_code) {
    }
    if (tenant_info.zip_code && tenant_info.zip_code.length >= 8) {
      onChangeCep(tenant_info.zip_code);
    }
  }, [tenant_info.zip_code])


  const onChangeCep = (zip_code: string): void => {
    const maskedValue = maskZipCode(zip_code);

    setFieldValue(`${SECTION_NAME}.zip_code`, maskedValue);
    if (maskedValue.length >= 8) {
      setCepLoading(true);

      AddressService.getAddressByZipCode(zip_code, user.id).then((address) => {
        setFieldValue(`${SECTION_NAME}.city`, address.city);
        setFieldValue(`${SECTION_NAME}.district`, address.district);
        setFieldValue(`${SECTION_NAME}.state`, address.state);
        setFieldValue(`${SECTION_NAME}.street`, address.street);

        setCepLoading(false);
      }).catch((e) => {
        console.log('address error:', e);
      })
    }
  };

  const validateErros = (field: string) => getErrorFormik(errors, touched, SECTION_NAME, field);

  return (
    <>
      <SectionLabel text='ENDEREÇO DA EMPRESA' />
      <Grid.Row>
        <Grid.Column width={5}>
          <FieldLabel>CEP*</FieldLabel>
          <Field
            name={`${SECTION_NAME}.zip_code`}
            value={tenant_info.zip_code}
            loading={cepLoading}
            onChange={(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
              const maskedValue = maskZipCode(e.target.value);
              setFieldValue(`${SECTION_NAME}.zip_code`, maskedValue);
              data.value = maskZipCode(e.target.value);
            }}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.zip_code`, true)}
            error={validateErros('zip_code')}
            component={Input}
            maxLength={9}
            fluid />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.zip_code`} />
        </Grid.Column>

        <Grid.Column width={11}>
          <FieldLabel>LOGRADOURO*</FieldLabel>
          <Field
            name={`${SECTION_NAME}.street`}
            value={tenant_info.street}
            onChange={handleInputChange}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.street`, true)}
            error={validateErros('street')}
            component={Input}
            fluid />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.street`} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={5}>
          <FieldLabel>BAIRRO*</FieldLabel>
          <Field
            name={`${SECTION_NAME}.district`}
            value={tenant_info.district}
            onChange={handleInputChange}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.district`, true)}
            error={validateErros('district')}
            component={Input}
            fluid />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.district`} />
        </Grid.Column>

        <Grid.Column width={6}>
          <FieldLabel>CIDADE*</FieldLabel>
          <Field
            name={`${SECTION_NAME}.city`}
            value={tenant_info.city}
            onChange={handleInputChange}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.city`, true)}
            error={validateErros('city')}
            component={Input}
            fluid />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.city`} />
        </Grid.Column>

        <Grid.Column width={5}>
          <FieldLabel>UF*</FieldLabel>
          <Field
            id={`${SECTION_NAME}.state`}
            name={`${SECTION_NAME}.state`}
            completeValue={true}
            options={UFOptions}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: SyntheticEvent, data: any) => {
              setFieldValue(`${SECTION_NAME}.state`, data.value);
            }}
            className={validateErros("state")}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.state`, true)}
            component={Dropdown}
            selection
            compact
            basic
            fluid
          />
          {/* <Field
            name={`${SECTION_NAME}.state`}
            value={tenant_info.state}
            onChange={handleInputChange}
            component={Input}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.state`, true)}
            error={validateErros('state')}
            fluid /> */}
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.state`} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={5}>
          <FieldLabel>NÚMERO*</FieldLabel>
          <Field
            name={`${SECTION_NAME}.number`}
            value={tenant_info.number}
            onChange={handleInputChange}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.number`, true)}
            error={validateErros('number')}
            component={Input}
            fluid />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.number`} />
        </Grid.Column>

        <Grid.Column width={6}>
          <FieldLabel>COMPLEMENTO</FieldLabel>
          <Field
            name={`${SECTION_NAME}.complement`}
            value={tenant_info.complement}
            onChange={handleInputChange}
            component={Input}
            fluid />
        </Grid.Column>

        <Grid.Column width={5}>
          <FieldLabel>TIPO DO IMÓVEL*</FieldLabel>
          <Field
            id={`${SECTION_NAME}.property_type`}
            name={`${SECTION_NAME}.property_type`}
            completeValue={true}
            options={hirePropertyTypeOptions}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: SyntheticEvent, data: any) => {
              setFieldValue(`${SECTION_NAME}.property_type`, data.value);
            }}
            className={validateErros("property_type")}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.property_type`, true)}
            component={Dropdown}
            selection
            compact
            basic
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.property_type`} />
        </Grid.Column>
      </Grid.Row>
    </>
  )
};

export default connect(mapState, mapDispatch)(AddressSection);
