import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Info } from "../../../../components/Info";
import { DateToStringDate } from "../../../../services/date";
import { PurposesEnum } from "../../../../enums/purposes.enum";
import { PersonTypesEnum } from "../../../../enums/person-types.enum";
import { BondInsuranceResponseDTO } from '../../types/bondinsurance-interfaces';
import SectionLabel from '../../../../components/SectionLabel';

export type Props = {
  bondInsurance: BondInsuranceResponseDTO;
};

export const BondInsuranceMainInfoDetails = (props: Props) => {
  const {
    bondInsurance
  } = props;
  const { property } = bondInsurance;

  console.log('PROPSSS', props);
  console.log('BOND', bondInsurance);
  console.log('PROP', property);

  return (
    <>
      <SectionLabel text='DADOS DA ANÁLISE' />
      <Grid.Row>
        <Info name={'ID'} value={bondInsurance.id} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'DATA DA ANALISE '} value={DateToStringDate(bondInsurance.createdAt)} width={8} />
        <Info name={'CRIADA POR'} value={bondInsurance.userName} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'CORRETORA'} value={'Confiax Administradora de Seguros'} width={8} />
        <Info name={'IMOBILIÁRIA'} value={bondInsurance.estateName} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'FINALIDADE'} value={bondInsurance.purpose === PurposesEnum.commercial ? 'Comercial' : 'Residencial'} width={8} />
        <Info name={'TIPO DE LOCATÁRIO'} value={bondInsurance.tenantType === PersonTypesEnum.legal ? 'Pessoa Jurídica' : 'Pessoa Física'} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'MOTIVO DA LOCAÇÃO'} value={bondInsurance.property.reason} width={8} />
        <Info name={'VIGÊNCIA DO CONTRATO'} value={`${bondInsurance.property.validity} MESES`} width={8} />
      </Grid.Row>
    </>
  )
}
