import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { iRootDispatch, iRootState } from '../../store';
import { connect } from 'react-redux';
import { Grid, Dropdown as DropdownNativo, Checkbox } from 'semantic-ui-react';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { FilterData } from './types';
import { getUsers } from '../../services/broker';
import { UserForUpdateEstateForm } from '../estates-list/types';
import { UsersToCustomDropDownOptions } from '../../util';
import AppMaskedInput from '../../components/MaskedInput';
import CustomDatePicker from '../../components/CustomDatePicker';
import { FieldLabel } from '../../styles';
import CanceledBondInsurancesTable from './CanceledBondInsurancesTable';
import { CustoDropDownItemProps } from '../../components/types';
import { BondInsuranceListProps } from './CanceledBondInsurancesForm';
import { dangerNotification } from '../../services/notification';
import { AvailableBrokersAndEstatesState } from '../../store/types/temp-types';
import CreateCancelationModal from './components/create-cancelation-modal/Modal';
import 'moment/locale/pt-br';
import { getCanceledBondInsurances, getCanceledBondInsurancesReport } from '../../services/canceled-bond-insurance';
import { GetCanceledBondInsurancesResponseDTO } from '../../dtos/canceled-bond-insurance/get-canceled-bond-insurances-response.dto';
import { CanceledOrigin } from './types';
import { useLocation } from 'react-router-dom';
import AuthorizationContainer from '../../components/AuthorizationContainer';
import { ActionEnum } from '../../enums/authz-action.enum';
import { FeatureEnum } from '../../enums/authz-feature.enum';
import { CanceledStatusEnum } from '../../enums/status-canceled-bond-insurances';
import { maskCpfOrCnpj, unmaskCpfOrCnpj } from '../../services/masks';
import { ReportModal } from '../../components/ReportModal';

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

export const canceledBondInsuranceAvailableStatus = [
  {
    key: 0,
    value: 'all',
    text: 'Todos'
  },
  {
    key: 1,
    value: CanceledStatusEnum.CANCELLATION_IN_PROGRESS,
    text: 'Em análise'
  },
  {
    key: 2,
    value: CanceledStatusEnum.CANCELED,
    text: 'Concluído'
  },
  {
    key: 3,
    value: CanceledStatusEnum.PENDING,
    text: 'Pendente'
  },
  {
    key: 4,
    value: CanceledStatusEnum.UNCANCELLED,
    text: 'Não concluído'
  },
  {
    key: 5,
    value: CanceledStatusEnum.PENDING_BY_INSURER,
    text: 'Solicitado para Seguradora'
  },
  {
    key: 6,
    value: CanceledStatusEnum.FINANCIAL_PENDING,
    text: 'Pendente Financeiro'
  },
  {
    key: 7,
    value: CanceledStatusEnum.CLAIM_CANCELLATION,
    text: 'Cancelamento Sinistro'
  }
];

const originOptions = [
  {
    key: 0,
    value: CanceledOrigin.ALL,
    text: 'Todas'
  },
  {
    key: 1,
    value: CanceledOrigin.ANALYZES,
    text: 'Análises'
  },
  {
    key: 2,
    value: CanceledOrigin.PROPOSALS,
    text: 'Propostas'
  },
  {
    key: 3,
    value: CanceledOrigin.POLICIES,
    text: 'Apólices'
  },
  {
    key: 4,
    value: CanceledOrigin.EXTERNAL,
    text: 'Externos'
  }
];
interface LocationState {
  bondInsuranceId?: string;
}

const CanceledBondInsurances = (props: BondInsuranceListProps) => {
  const { availableBrokersAndEstates, user, setFieldValue, values } = props;
  const location = useLocation();
  const brokers: CustoDropDownItemProps[] = availableBrokersAndEstates.brokers;
  const estates: CustoDropDownItemProps[] = availableBrokersAndEstates.estates;
  const insurers: CustoDropDownItemProps[] = availableBrokersAndEstates.bondInsurers;
  const [canceledBondInsurances, setCanceledBondInsurances] = useState<
    GetCanceledBondInsurancesResponseDTO[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataCount, setDataCount] = useState<number>(0);
  const [selectedEstate, setSelectedEstate] = useState<any>();
  const [selectedInsurer, setSelectedInsurer] = useState<any>();
  const [startDate, setStartDate] = useState<Date | string>('');
  const [endDate, setEndDate] = useState<Date | string>('');
  const [selectedStatus, setSelectedStatus] = useState<any>();
  const [selectedOrigin, setSelectedOrigin] = useState<any>();
  const [selectedResponsible, setSelectedResponsible] = useState<any>(undefined);
  const [responsibleOptions, setResponsibleOptions] = useState<CustoDropDownItemProps[]>(
    []
  );
  const [isResponsibleLoading, setIsResponsibleLoading] = useState<boolean>(false);
  const [notRead, setNotRead] = useState<number[]>([]);
  const [onlyUnread, setOnlyUnread] = useState<boolean>(false);
  const bondInsuranceIdInitialValue = (location.state as LocationState)?.bondInsuranceId;
  const [bondInsuranceId, setBondInsuranceId] = useState(bondInsuranceIdInitialValue);
  const [reportModalIsOpen, setReportModalIsOpen] = useState<boolean>(false);
  const [reportState, setReportState] = useState<number | undefined>(undefined);

  const actualizeUnread = (index: number, value: number) => {
    const copyArray = notRead;
    copyArray[index] = value;
    setNotRead(copyArray);
  };

  const getResponsibles = async () => {
    if (props.user.broker?.id) {
      setIsResponsibleLoading(true);
      getUsers(props.user.broker.id)
        .then(result => {
          const convertedValues: UserForUpdateEstateForm[] = result.map((user: any) => {
            return {
              id: user.id,
              name: user.name
            };
          });

          const analystsOptions = UsersToCustomDropDownOptions(false, convertedValues);
          setResponsibleOptions(analystsOptions);
        })
        .catch(error => {
          dangerNotification(
            'Oops...',
            'Não foi possível encontrar os analistas disponíveis'
          );
        })
        .finally(() => {
          setIsResponsibleLoading(false);
        });
    }
  };

  const getData = async (report = false) => {
    setIsLoading(true);

    try {
      const filter = values as FilterData;

      filter.user_id = user.id;

      if (estates.length === 1) {
        filter.estate_id = estates[0].value.id;
        setFieldValue('estate_id', user.estate?.id);
      }

      if (brokers.length === 1) {
        filter.broker_id = brokers[0].value.id;
        setFieldValue('broker_id', filter.broker_id);
      }

      if (bondInsuranceId) {
        filter.bondInsuranceId = bondInsuranceId;
        setFieldValue('bondInsuranceId', bondInsuranceId);
      }

      if (filter.broker_id) {
        if (report) {
          const status = await getCanceledBondInsurancesReport(filter);

          if (!status) {
            dangerNotification(
              'Ops...',
              'Erro ao solicitar relatório de sinistros.'
            );
          } else {
            setReportState(status);
            setReportModalIsOpen(true);
          }
        } else {
          const canceled = await getCanceledBondInsurances(currentPage, filter);

          if(currentPage === 1) setDataCount(canceled.count);
          setCanceledBondInsurances(canceled.data);
        }
      }

      setFieldValue('bondInsuranceId', undefined);
      setIsLoading(false);
    } catch (e) {
      console.error({ e });
      dangerNotification('Oops...', 'Não foi possível buscar as análises');
    } finally {
      setIsLoading(false);
    }
  };

  const clearFilters = () => {
    Object.keys(values).forEach((key: string) => {
      setFieldValue(key, undefined);
    });

    setSelectedEstate(null);
    setSelectedInsurer(null);
    setSelectedStatus(null);
    setSelectedResponsible(null);
    setSelectedOrigin(null);
    setOnlyUnread(false);
    setStartDate('');
    setEndDate('');
  };

  const handleNewChangeEstate = (estateKey: any) => {
    const estate = estates.find(({ value }) => value.id === estateKey.id);

    if (estate) {
      setFieldValue('estate_id', estate.value.id);
      setSelectedEstate(estateKey);
    } else {
      setFieldValue('estate_id', undefined);
      setSelectedEstate(null);
    }
  };

  const handleNewChangeInsurer = (insurerKey: any) => {
    const insurer = insurers.find(({ value }) => value.id === insurerKey.id);

    if (insurer) {
      setFieldValue('insurer_id', insurer.value.id);
      setSelectedInsurer(insurerKey);
    } else {
      setFieldValue('insurer_id', undefined);
      setSelectedInsurer(null);
    }
  };

  const handleNewChangeStatus = (status: any) => {
    if (status) {
      setFieldValue('status', status);
      setSelectedStatus(status);
    } else {
      setFieldValue('status', undefined);
      setSelectedStatus(null);
    }
  };

  const handleChangeResponsible = (responsibleId: any) => {
    if (responsibleId) {
      setFieldValue('responsibleId', responsibleId);
      setSelectedResponsible(responsibleId);
    } else {
      setFieldValue('responsibleId', undefined);
      setSelectedResponsible(null);
    }
  };

  const handleChangeOrigin = (origin: any) => {
    if (origin) {
      setFieldValue('origin', origin);
      setSelectedOrigin(origin);
    } else {
      setFieldValue('origin', undefined);
      setSelectedOrigin(null);
    }
  };

  const handleChangeStartDate = (startDate: Date) => {
    if (startDate) {
      const start = startDate.toISOString().substring(0, 10);

      setFieldValue('start', start);
      setStartDate(startDate);
    } else {
      setFieldValue('start', undefined);
      setStartDate('');
    }
  };

  const handleChangeEndDate = (endDate: Date) => {
    if (endDate) {
      const end = endDate.toISOString().substring(0, 10);

      setFieldValue('end', end);
      setEndDate(endDate);
    } else {
      setFieldValue('end', undefined);
      setEndDate('');
    }
  };

  const handleChangeName = (e: any) => {
    const { name, value } = e.target;

    if (value) {
      setFieldValue(name, value);
    } else {
      setFieldValue(name, undefined);
    }
  };

  const handleChangeDocument = (e: any) => {
    const { name, value } = e.target;

    if (value) {
      setFieldValue(name, unmaskCpfOrCnpj(value));
    } else {
      setFieldValue(name, undefined);
    }
  };

  const handleChangeAddress = (e: any) => {
    const { name, value } = e.target;

    if (value) {
      setFieldValue(name, value);
    } else {
      setFieldValue(name, undefined);
    }
  };

  const handleUnreadFilter = () => {
    setFieldValue('onlyUnread', !onlyUnread);
    setOnlyUnread(!onlyUnread);
  };

  useEffect(() => {
    if (responsibleOptions.length === 0) {
      getResponsibles();
    }
  }, [responsibleOptions]);

  useEffect(() => {
    window.history.replaceState(
      {
        ...(location.state as any),
        bondInsuranceId: undefined
      },
      ''
    );
  }, [location]);

  useEffect(() => {
    getData();
  }, [currentPage]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setCurrentPage(1);
    getData();
  };

  const handleExportDate = async () => {
    getData(true);
  };

  const handleReportModalClose = () => {
    setReportModalIsOpen(false);
  };

  return (
    <>
      {reportModalIsOpen &&
        reportState === 200 ? (
          <ReportModal
            isOpen={reportModalIsOpen}
            header='Relatório de Fianças Canceladas'
            message={`
              O relatório será gerado em background e será enviado o link para download via e-mail.
              Atenção, só será possível gerar um novo relatório após a finalização desse.
            `}
            onClose={handleReportModalClose}
          />
        ) : (
          <ReportModal
            isOpen={reportModalIsOpen}
            header='Relatório de Fianças Canceladas'
            message='Já existe um relatório na fila para ser gerado, aguarde a notificação via email.'
            onClose={handleReportModalClose}
          />
        )
      }

      <form onSubmit={handleSubmit}>
        <Grid>
          <Grid.Row columns={'equal'}>
            <Grid.Column>
              <div style={{ display: 'flex' }}>
                <AuthorizationContainer
                  action={ActionEnum.create}
                  feature={FeatureEnum.canceleds}
                >
                  <CreateCancelationModal />
                </AuthorizationContainer>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row verticalAlign={'middle'} columns={'equal'}>
            <Grid.Column>
              <FieldLabel>Imobiliária:</FieldLabel>
              <div style={{ width: '100%' }}>
                <DropdownNativo
                  name="estate"
                  style={{ width: '100%' }}
                  value={selectedEstate}
                  defaultValue={
                    availableBrokersAndEstates.estates.length === 1
                      ? availableBrokersAndEstates.estates[0].value
                      : '' || ''
                  }
                  disabled={isLoading || availableBrokersAndEstates.estates.length === 1}
                  options={availableBrokersAndEstates.estates || []}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  onChange={(e, { value }) => handleNewChangeEstate(value)}
                  clearable
                />
              </div>
            </Grid.Column>

            <Grid.Column>
              <FieldLabel>Seguradora:</FieldLabel>
              <div style={{ width: '100%' }}>
                <DropdownNativo
                  name="estate"
                  style={{ width: '100%' }}
                  value={selectedInsurer}
                  defaultValue={
                    availableBrokersAndEstates.bondInsurers.length === 1
                      ? availableBrokersAndEstates.bondInsurers[0].value
                      : '' || ''
                  }
                  disabled={
                    isLoading || availableBrokersAndEstates.bondInsurers.length === 1
                  }
                  options={availableBrokersAndEstates.bondInsurers || []}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  onChange={(e, { value }) => handleNewChangeInsurer(value)}
                  clearable
                />
              </div>
            </Grid.Column>

            <Grid.Column>
              <FieldLabel>Status:</FieldLabel>
              <DropdownNativo
                name="status"
                style={{ width: '100%' }}
                value={selectedStatus}
                options={canceledBondInsuranceAvailableStatus}
                placeholder={'Selecionar...'}
                search
                selection
                disabled={isLoading}
                onChange={(e, { value }) => handleNewChangeStatus(value)}
                clearable
              />
            </Grid.Column>
            {!user.role.includes('ESTATE') && (
              <Grid.Column>
                <FieldLabel>Responsável:</FieldLabel>
                <DropdownNativo
                  name="responsible"
                  style={{ width: '100%' }}
                  value={selectedResponsible}
                  options={responsibleOptions}
                  disabled={isLoading || isResponsibleLoading}
                  loading={isResponsibleLoading}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  onChange={(e, { value }) => handleChangeResponsible(value)}
                  clearable
                />
              </Grid.Column>
            )}

            <Grid.Column width={3}>
              <FieldLabel>Origem:</FieldLabel>
              <DropdownNativo
                name="origin"
                style={{ width: '100%' }}
                value={selectedOrigin}
                disabled={isLoading}
                options={originOptions}
                placeholder={'Selecionar...'}
                search
                selection
                onChange={(e, { value }) => handleChangeOrigin(value)}
                clearable
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row verticalAlign={'middle'} style={{ marginTop: 0 }}>
            <Grid.Column width={2}>
              <FieldLabel>Data Inicial:</FieldLabel>
              <Field
                name="start"
                selected={startDate ?? ''}
                onChange={handleChangeStartDate}
                component={CustomDatePicker}
                disabled={isLoading}
                customInput={
                  <AppMaskedInput
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/
                    ]}
                  />
                }
                dateFormat={'dd/MM/yyyy'}
              />
            </Grid.Column>

            <Grid.Column width={2}>
              <FieldLabel>Data Final:</FieldLabel>
              <Field
                name="end"
                selected={endDate ?? ''}
                onChange={handleChangeEndDate}
                component={CustomDatePicker}
                disabled={isLoading}
                customInput={
                  <AppMaskedInput
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/
                    ]}
                  />
                }
                dateFormat={'dd/MM/yyyy'}
              />
            </Grid.Column>

            <Grid.Column width={3}>
              <FieldLabel>Nome:</FieldLabel>
              <Input
                name="name"
                onChange={handleChangeName}
                value={values?.name ?? ''}
                disabled={isLoading}
                fluid
              />
            </Grid.Column>

            <Grid.Column width={3}>
              <FieldLabel>CPF/CNPJ:</FieldLabel>
              <Input
                name="document"
                onChange={handleChangeDocument}
                disabled={isLoading}
                value={maskCpfOrCnpj(values?.document ?? '')}
                fluid
              />
            </Grid.Column>

            <Grid.Column width={3}>
              <FieldLabel>Endereço:</FieldLabel>
              <Input
                name="address"
                onChange={handleChangeAddress}
                disabled={isLoading}
                value={values?.address ?? ''}
                fluid
              />
            </Grid.Column>

            <Grid.Column width={3} textAlign={'center'}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignContent: 'center',
                  marginRight: '1rem',
                }}
              >
                <Checkbox
                  label={'Mensagens não lidas'}
                  style={{ fontSize: 18 }}
                  onClick={handleUnreadFilter}
                  disabled={isLoading}
                  checked={onlyUnread}
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{ marginTop: 0 }}>
            <Grid.Column
              width={4}
              verticalAlign={'bottom'}
              textAlign={'right'}
              floated="right"
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              {user.role && user.role.includes('BROKER') && (
                <Button
                  fluid
                  type="button"
                  content={'Exportar'}
                  disabled={isLoading}
                  onClick={handleExportDate}
                />
              )}

              <Button
                fluid
                type="button"
                content={'Limpar'}
                disabled={isLoading}
                onClick={clearFilters}
              />

              <Button
                fluid
                primary
                type="submit"
                content={'Pesquisar'}
                disabled={isLoading}
                onClick={handleSubmit}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </form>

      <CanceledBondInsurancesTable
        canceledBondInsurances={canceledBondInsurances}
        responsibleOptions={responsibleOptions}
        notRead={notRead}
        setNotRead={actualizeUnread}
        isLoading={isLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        getData={() => getData()}
        dataCount={dataCount}
      />
    </>
  );
};

export default connect(mapState, mapDispatch)(CanceledBondInsurances);
