import { get } from './request';
// import { Dashboard } from '../types/Dashboard';

export async function getDashboard(id: string, filter: string) {
  const response = await get(`/dashboard/${id}?filter=${filter}`)

  return response
}

export async function getAllDashboards(filter: string) {
  const response = await get(`/dashboard?filter=${filter}`)

  return response
}
