/* eslint-disable @typescript-eslint/no-explicit-any */
import { DropdownItemProps } from 'semantic-ui-react';
import { BondInsuranceTenant } from '../../types/BondInsuranceTenant';
import { BondInsuranceProperty } from '../../types/BondInsuranceProperty';
import { BondInsuranceAttachments } from '../../types/BondInsuranceAttachment';
import { BondInsuranceInitialData } from '../../types/BondInsuranceInitialData';
import { PurposesEnum } from '../../enums/purposes.enum';
import { MaritalStatusEnum } from '../../enums/marital-status.enum';
import { EmploymentRelationshipEnum } from '../../enums/employment-relationship.enum';
import { HireTypePropertyEnum } from '../../enums/residential-occupations.enum';
import { BondInsuranceOwnerData } from '../../types/BondInsuranceOwnerData ';

export const propertyTypeOptions: Array<DropdownItemProps> = [
  { value: PurposesEnum.residential, key: 'RESIDENCIAL', text: 'Residencial' },
  { value: PurposesEnum.commercial, key: 'COMERCIAL', text: 'Comercial' }
];

export const commercialOptions: Array<DropdownItemProps> = [
  { value: 'SERVICOS', key: 'SERVICOS', text: 'Serviços' },
  { value: 'COMERCIO', key: 'COMERCIO', text: 'Comércio' },
  {
    value: 'FABRICAS_INDUSTRIAS',
    key: 'FABRICAS_INDUSTRIAS',
    text: 'Fábricas/Indústrias'
  },
  { value: 'ESCRITORIO', key: 'ESCRITORIO', text: 'Escritório' }
];

export const residentialOptions: Array<DropdownItemProps> = [
  { value: 'CASA', key: 'CASA', text: 'Casa' },
  { value: 'APARTAMENTO', key: 'APARTAMENTO', text: 'Apartamento' }
];

export const naturalResidentialReasonOptions: Array<DropdownItemProps> = [
  {
    value: 'Cancelamento de apólice troca de imóvel',
    key: 'Cancelamento de apólice troca de imóvel',
    text: 'Cancelamento de apólice troca de imóvel'
  },
  { value: 'Casamento', key: 'Casamento', text: 'Casamento' },
  {
    value: 'Funcionário da empresa do retendente',
    key: 'Funcionário da empresa do retendente',
    text: 'Funcionário da empresa do retendente'
  },
  {
    value: 'Imóvel próximo de familiares',
    key: 'Imóvel próximo de familiares',
    text: 'Imóvel próximo de familiares'
  },
  { value: 'Independência', key: 'Independência', text: 'Independência' },
  {
    value: 'Locação adicional',
    key: 'Locação adicional',
    text: 'Locação adicional'
  },
  {
    value: 'Locação em vigor',
    key: 'Locação em vigor',
    text: 'Locação em vigor'
  },
  { value: 'Para terceiros', key: 'Para terceiros', text: 'Para terceiros' },
  {
    value: 'Próximo a instituições de ensino',
    key: 'Próximo a instituições de ensino',
    text: 'Próximo a instituições de ensino'
  },
  {
    value: 'Próximo ao trabalho',
    key: 'Próximo ao trabalho',
    text: 'Próximo ao trabalho'
  },
  {
    value: 'Redução de custo',
    key: 'Redução de custo',
    text: 'Redução de custo'
  },
  {
    value: 'Renovação com aumento de valores',
    key: 'Renovação com aumento de valores',
    text: 'Renovação com aumento de valores'
  },
  { value: 'Separação', key: 'Separação', text: 'Separação' },
  {
    value: 'Transferência de empresa',
    key: 'Transferência de empresa',
    text: 'Transferência de empresa'
  },
  {
    value: 'Troca de garantia da atual locação',
    key: 'Troca de garantia da atual locação',
    text: 'Troca de garantia da atual locação'
  },
  {
    value: 'Venda de imóvel próprio',
    key: 'Venda de imóvel próprio',
    text: 'Venda de imóvel próprio'
  }
];

export const legalCommercialResidentialReasonOptions: Array<DropdownItemProps> = [
  {
    value: 'Abertura de Filial',
    key: 'Abertura de Filial',
    text: 'Abertura de Filial'
  },
  {
    value: 'Locação para moradia',
    key: 'Locação para moradia',
    text: 'Locação para moradia'
  },
  {
    value: 'Troca de garantia',
    key: 'Troca de garantia',
    text: 'Troca de garantia'
  },
  {
    value: 'Troca de local de sede',
    key: 'Troca de local de sede',
    text: 'Troca de local de sede'
  }
];

export const naturalCommercialReasonOptions: Array<DropdownItemProps> = [
  {
    value: 'Abertura de Filial',
    key: 'Abertura de Filial',
    text: 'Abertura de Filial'
  },
  { value: 'Sede de empresa', key: 'Sede de empresa', text: 'Sede de empresa' },
  {
    value: 'Troca de garantia',
    key: 'Troca de garantia',
    text: 'Troca de garantia'
  },
  {
    value: 'Troca de local de sede',
    key: 'Troca de local de sede',
    text: 'Troca de local de sede'
  }
];

export const genderOptions: Array<DropdownItemProps> = [
  { value: 'FEMININO', key: 'FEMININO', text: 'Feminino' },
  { value: 'MASCULINO', key: 'MASCULINO', text: 'Masculino' }
];

export const documentOptions: Array<DropdownItemProps> = [
  { value: 'RG', key: 'RG', text: 'RG' },
  { value: 'RNE', key: 'RNE', text: 'RNE' },
  { value: 'CNH', key: 'CNH', text: 'CNH' },
  {
    value: 'DOCUMENTO_DE_CLASSE',
    key: 'DOCUMENTO_DE_CLASSE',
    text: 'Documento de Classe'
  }
];

export const hirePropertyTypeOptions: Array<DropdownItemProps> = [
  {
    value: HireTypePropertyEnum.wholeFloor,
    key: HireTypePropertyEnum.wholeFloor,
    text: 'Andar Inteiro'
  },
  {
    value: HireTypePropertyEnum.apartment,
    key: HireTypePropertyEnum.apartment,
    text: 'Apartamento'
  },
  {
    value: HireTypePropertyEnum.kitnet,
    key: HireTypePropertyEnum.kitnet,
    text: 'Kitnet'
  },
  {
    value: HireTypePropertyEnum.standardHouse,
    key: HireTypePropertyEnum.standardHouse,
    text: 'Casa Padrão'
  },
  {
    value: HireTypePropertyEnum.commercialHouse,
    key: HireTypePropertyEnum.commercialHouse,
    text: 'Casa Comercial'
  },
  { value: HireTypePropertyEnum.roof, key: HireTypePropertyEnum.roof, text: 'Cobertura' },
  {
    value: HireTypePropertyEnum.setOfStores,
    key: HireTypePropertyEnum.setOfStores,
    text: 'Conjunto De Lojas'
  },
  {
    value: HireTypePropertyEnum.roomSet,
    key: HireTypePropertyEnum.roomSet,
    text: 'Conjunto De Salas'
  },
  {
    value: HireTypePropertyEnum.residenceService,
    key: HireTypePropertyEnum.residenceService,
    text: 'Flat/Residence Service'
  },
  {
    value: HireTypePropertyEnum.commercialStore,
    key: HireTypePropertyEnum.commercialStore,
    text: 'Loja Comercial'
  },
  {
    value: HireTypePropertyEnum.pavilion,
    key: HireTypePropertyEnum.pavilion,
    text: 'Pavilhão'
  },
  {
    value: HireTypePropertyEnum.edifice,
    key: HireTypePropertyEnum.edifice,
    text: 'Prédio'
  },
  {
    value: HireTypePropertyEnum.commercialRoom,
    key: HireTypePropertyEnum.commercialRoom,
    text: 'Sala Comercial'
  },
  { value: HireTypePropertyEnum.loft, key: HireTypePropertyEnum.loft, text: 'Sobrado' },
  {
    value: HireTypePropertyEnum.mezzanine,
    key: HireTypePropertyEnum.mezzanine,
    text: 'Sobreloja'
  },
  {
    value: HireTypePropertyEnum.ground,
    key: HireTypePropertyEnum.ground,
    text: 'Terreno'
  }
];

export const redisentialPropertyTypeOptions: Array<DropdownItemProps> = [
  {
    value: HireTypePropertyEnum.apartment,
    key: HireTypePropertyEnum.apartment,
    text: 'Apartamento'
  },
  {
    value: HireTypePropertyEnum.kitnet,
    key: HireTypePropertyEnum.kitnet,
    text: 'Kitnet'
  },
  {
    value: HireTypePropertyEnum.standardHouse,
    key: HireTypePropertyEnum.standardHouse,
    text: 'Casa Padrão'
  },
  { value: HireTypePropertyEnum.roof, key: HireTypePropertyEnum.roof, text: 'Cobertura' },
  {
    value: HireTypePropertyEnum.residenceService,
    key: HireTypePropertyEnum.residenceService,
    text: 'Flat/Residence Service'
  },
  { value: HireTypePropertyEnum.loft, key: HireTypePropertyEnum.loft, text: 'Sobrado' }
];

export const maritalStatusOptions: Array<DropdownItemProps> = [
  {
    value: MaritalStatusEnum.married,
    key: MaritalStatusEnum.married,
    text: 'Casado(a)'
  },
  {
    value: MaritalStatusEnum.divorced,
    key: MaritalStatusEnum.divorced,
    text: 'Divorciado(a)'
  },
  {
    value: MaritalStatusEnum.separate,
    key: MaritalStatusEnum.separate,
    text: 'Separado'
  },
  {
    value: MaritalStatusEnum.single,
    key: MaritalStatusEnum.single,
    text: 'Solteiro(a)'
  },
  {
    value: MaritalStatusEnum.stableUnion,
    key: MaritalStatusEnum.stableUnion,
    text: 'União Estável'
  },
  {
    value: MaritalStatusEnum.widower,
    key: MaritalStatusEnum.widower,
    text: 'Viúvo(a)'
  }
];

export const employmentRelationshipOptions: Array<DropdownItemProps> = [
  {
    value: EmploymentRelationshipEnum.retired,
    key: EmploymentRelationshipEnum.retired,
    text: 'Aposentado/Pensionista'
  },
  {
    value: EmploymentRelationshipEnum.freelance,
    key: EmploymentRelationshipEnum.freelance,
    text: 'Autônomo'
  },
  {
    value: EmploymentRelationshipEnum.entrepreneur,
    key: EmploymentRelationshipEnum.entrepreneur,
    text: 'Empresário'
  },
  {
    value: EmploymentRelationshipEnum.student,
    key: EmploymentRelationshipEnum.student,
    text: 'Estudante'
  },
  {
    value: EmploymentRelationshipEnum.civilServant,
    key: EmploymentRelationshipEnum.civilServant,
    text: 'Funcionário público'
  },
  {
    value: EmploymentRelationshipEnum.privateServant,
    key: EmploymentRelationshipEnum.privateServant,
    text: 'Funcionário com registro CLT'
  },
  {
    value: EmploymentRelationshipEnum.liberal,
    key: EmploymentRelationshipEnum.liberal,
    text: 'Profissional Liberal'
  },
  {
    value: EmploymentRelationshipEnum.rentIncome,
    key: EmploymentRelationshipEnum.rentIncome,
    text: 'Renda Proveniente de aluguéis'
  }
];

export const activitiesOptions: Array<DropdownItemProps> = [
  { value: 'COMERCIO', key: 'COMERCIO', text: 'Comércio' },
  { value: 'INDUSTRIA', key: 'INDUSTRIA', text: 'Indústria' },
  { value: 'SERVICOS', key: 'SERVICOS', text: 'Serviços' }
];

export const validityOptions: Array<DropdownItemProps> = [
  { value: 12, key: 12, text: '12 meses' },
  { value: 18, key: 18, text: '18 meses' },
  { value: 24, key: 24, text: '24 meses' },
  { value: 30, key: 30, text: '30 meses' },
  { value: 36, key: 36, text: '36 meses' },
  { value: 48, key: 48, text: '48 meses' },
  { value: 60, key: 60, text: '60 meses' }
];

export const indemnityPeriodOptions: Array<DropdownItemProps> = [
  // { value: 6, key: 6, text: "6 meses" },
  // { value: 7, key: 7, text: "7 meses" },
  // { value: 8, key: 8, text: "8 meses" },
  // { value: 9, key: 9, text: "9 meses" },
  // { value: 10, key: 10, text: "10 meses" },
  // { value: 11, key: 11, text: "11 meses" },
  { value: 12, key: 12, text: '12 meses' },
  { value: 15, key: 15, text: '15 meses' },
  { value: 18, key: 18, text: '18 meses' },
  { value: 24, key: 24, text: '24 meses' },
  { value: 30, key: 30, text: '30 meses' }
];

export const UFOptions: Array<DropdownItemProps> = [
  { value: 'AC', key: 'AC', text: 'Acre' },
  { value: 'AL', key: 'AL', text: 'Alagoas' },
  { value: 'AP', key: 'AP', text: 'Amapá' },
  { value: 'AM', key: 'AM', text: 'Amazonas' },
  { value: 'BA', key: 'BA', text: 'Bahia' },
  { value: 'CE', key: 'CE', text: 'Ceará' },
  { value: 'DF', key: 'DF', text: 'Distrito Federal' },
  { value: 'ES', key: 'ES', text: 'Espírito Santo' },
  { value: 'GO', key: 'GO', text: 'Goiás' },
  { value: 'MA', key: 'MA', text: 'Maranhão' },
  { value: 'MT', key: 'MT', text: 'Mato Grosso' },
  { value: 'MS', key: 'MS', text: 'Mato Grosso do Sul' },
  { value: 'MG', key: 'MG', text: 'Minas Gerais' },
  { value: 'PA', key: 'PA', text: 'Pará' },
  { value: 'PB', key: 'PB', text: 'Paraíba' },
  { value: 'PR', key: 'PR', text: 'Paraná' },
  { value: 'PE', key: 'PE', text: 'Pernambuco' },
  { value: 'PI', key: 'PI', text: 'Piauí' },
  { value: 'RJ', key: 'RJ', text: 'Rio de Janeiro' },
  { value: 'RN', key: 'RN', text: 'Rio Grande do Norte' },
  { value: 'RS', key: 'RS', text: 'Rio Grande do Sul' },
  { value: 'RO', key: 'RO', text: 'Rondônia' },
  { value: 'RR', key: 'RR', text: 'Roraima' },
  { value: 'SC', key: 'SC', text: 'Santa Catarina' },
  { value: 'SP', key: 'SP', text: 'São Paulo' },
  { value: 'SE', key: 'SE', text: 'Sergipe' },
  { value: 'TO', key: 'TO', text: 'Tocantins' }
];

export type CustomDropDownOption<T> = {
  value: T;
  key: number;
  text: string;
};

export type CustomDropDownOptions<T> = Array<CustomDropDownOption<T>>;

export interface BondInsuranceSteps {
  initial_info: BondInsuranceInitialData;
  property_info: BondInsuranceProperty;
  owner_info: BondInsuranceOwnerData;
  tenant_info: BondInsuranceTenant;
  attachments: BondInsuranceAttachments;
}

export interface StepItem {
  label: string;
  section:
    | 'initial_info'
    | 'property_info'
    // | 'owner_info'
    | 'tenant_info'
    | 'attachments';
  component: React.ReactNode;
  exceptions?: string[];
}

export interface UserBondInsurance {
  status: string;
  insurer: {
    id: string;
    name: string;
    proposalsSending: boolean;
    fee: number;
    bondInsuranceTax?: number;
    image: string;
    code: string;
    availability: string;
    notificationEmails: string;
  };
  id: string;
  code?: number;
  observations?: string;
  value: string;
  createdAt: string;
  updatedAt: string;
  insurerCreditAnalysisTraceData?: string;
  insurerQuotationId?: string;
  insurerProposalId: string;
  insurerCreditAnalysisId?: string;
  insurerProposalCreatedAt: string;
  insurerProductId?: string;
  fluidQuotationValues?: number;
  userId: string;
  bondInsurance: {
    id: string;
    analysisAssurance: string;
    purpose: string;
    tenantType: string;
    status: string;
    property: {
      city: string;
      state: string;
      number: string;
      reason: string;
      street: string;
      district: string;
      gas_bill?: number;
      validity: number;
      zip_code: string;
      rent_value: number;
      water_bill?: number;
      energy_bill?: number;
      taxes_value?: number;
      total_amount: number;
      property_type: string;
      condominium_fee?: number;
      property_damage_value: number;
      furniture_damage_value: number;
      exterior_painting_value: number;
      interior_painting_value: number;
      contract_penalties_value: number;
      rent_insurance_contract_end_date: string;
      rent_insurance_contract_start_date: string;
      rental_period_start_date: string;
      rental_period_end_date: string;
    };
    observations: string;
    naturalTenant: {
      rent: number;
      mail: string;
      resident: true;
      partner_cpf?: string;
      partner_rent?: number;
      contact_phone?: string;
      quiz_partners: boolean;
      main_tenant_cpf: string;
      main_tenant_name: string;
      main_tenant_email: string;
      main_tenant_phone: string;
      main_tenant_gender: string;
      quiz_company_created: boolean;
      main_tenant_birth_date: string;
      main_tenant_profession: string;
      resident_other_incomes?: number;
      employment_relationship: string;
      main_tenant_mother_name: string;
      main_tenant_nationality: string;
      partner_rent_composition: boolean;
      include_sympathise_tenants: boolean;
      main_tenant_marital_status: string;
      main_tenant_rg_emission_date: string;
      resident_monthly_fixed_income: number;
      resident_politically_exposed_person: string;
      main_tenant_politically_exposed_person: string;
      resident_close_to_politically_exposed_person: {
        politically_exposed_person_cpf?: string;
        politically_exposed_person_name?: string;
        degree_of_kinship_to_politically_exposed_person?: string;
      };
      main_tenant_close_to_politically_exposed_person: {
        politically_exposed_person_cpf?: string;
        politically_exposed_person_name?: string;
        degree_of_kinship_to_politically_exposed_person?: string;
      };
    };
    legalTenant?: any;
    responsibleAddress: {
      city: string;
      state: string;
      number: string;
      street: string;
      district: string;
      zip_code: string;
      complement: string;
    };
    // propertyOwnerNaturalPerson: {
    //   cpf: string;
    //   name: string;
    // };
    attachments?: any;
    createdAt: string;
    updatedAt: string;
    canceledAt?: string;
    expiredAt?: string;
    bondInsuranceHiredData: {
      id: string;
      status: string;
      paymentWay: string;
      insurerPolicyId: string;
      insurerPolicyCreatedAt: string;
      installmentsAmount: number;
      nominalTotalPremiumAmount: string;
      nominalLiquidPremiumAmount: string;
      nominalTotalIofAmount?: string;
      totalPremiumAmount: string;
      liquidPremiumAmount: string;
      totalIofAmount?: any;
      additionalAmount?: any;
      createdAt: string;
      updatedAt: string;
      hiredInstallments: string;
      hiredInstallmentsValue: string;
    };
  };
}
