import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import Table from "./components/Table";
import { connect } from "react-redux";
import { iRootDispatch, iRootState } from "../../store";
import { AvailableBrokersAndEstatesState } from "../../store/types/temp-types";
import CreateCapitalizationModal from "./components/create-capitalization-modal/Modal";
import { list } from "../../services/insurer";
import { DropdownOptions } from "./components/create-capitalization-modal/Steps/CapitalizationInfoStep";
import { ActionEnum } from "../../enums/authz-action.enum";
import { FeatureEnum } from "../../enums/authz-feature.enum";
import AuthorizationContainer from "../../components/AuthorizationContainer";


const CapitalizationIndex = (props: any) => {
  const [insurers, setInsurers] = useState<DropdownOptions[]>([]);


  const loadInsurers = async () => {
    const response = await list({availability: 'CAPITALIZATION'});
    const insurers = response.map((insurer: any) => ({
      index: insurer.id,
      value: insurer.id,
      text: insurer.name,
    }));
    setInsurers(
      insurers);
  };

  useEffect(() => {
    loadInsurers();
  }, [])


  return (
    <>
      <Grid>
        <AuthorizationContainer action={ActionEnum.create} feature={FeatureEnum.captalizations}>
          <Grid.Row columns={"equal"}>
            <Grid.Column>
              <CreateCapitalizationModal insurers={insurers}/>
            </Grid.Column>
          </Grid.Row>
        </AuthorizationContainer>
        <Grid.Row>
          <Table insurers={insurers}/>
        </Grid.Row>
      </Grid>
    </>
  );
};

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user,
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (
    availableBrokers: AvailableBrokersAndEstatesState
  ) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(
      availableBrokers
    ),
});

export default connect(mapState, mapDispatch)(CapitalizationIndex);
