import React, { SyntheticEvent } from 'react';
import { FormikProps, Formik, Field } from 'formik';
import styled from 'styled-components';
import { iRootDispatch, iRootState } from '../../store';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import Dropdown from '../../components/Dropdown';
import Button from '../../components/Button';
import { getPolicies } from '../../services/broker';
import { FilterData } from './types';
import BrokersDropdown from '../../components/BrokersDropdown';
import { EstateState } from '../../types/EstateState';
import { BrokerState } from '../../types/BrokerState';
import { EstatesToCustomDropDownOptions } from '../../util';
import { sortPolicies } from '../../services/fire-insurance-policy';
import { CustomDropDownOptions } from '../../types';
import { FieldLabel } from '../../styles';
import { getUserFireInsurancePolicies } from '../../services/user';
import { UserRolesEnum } from '../../enums/user-roles.enum';
import {AvailableBrokersAndEstatesState, FireInsurancePoliciesListState} from "../../store/types/temp-types";

const SearchButton = styled(Button)`
  margin: 1.5em 0em 0.5em 0em !important;
`;

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  ascending: state.fireInsurancePoliciesList.ascending,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateList: (payload: FireInsurancePoliciesListState) => dispatch.fireInsurancePoliciesList.updateFireInsurancePoliciesList(payload),
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) => dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers),
  updatePolicyBroker: (broker: BrokerState) => dispatch.policy.updatePolicyBroker(broker),
  updatePolicyEstate: (estate: EstateState) => dispatch.policy.updatePolicyEstate(estate),
})

type Props = ReturnType<typeof mapDispatch> & ReturnType<typeof mapState> & FormikProps<FilterData>;

const Filter = (props: Props) => {
  const { availableBrokersAndEstates, updateAvailableBrokersAndEstates, ascending, user } = props;

  const brokers: CustomDropDownOptions[] = props.availableBrokersAndEstates.brokers;
  let defaultBrokerID = '';
  const estates: CustomDropDownOptions[] = availableBrokersAndEstates.estates;
  let defaultEstateID = '';

  if (estates.length === 1) {
    defaultEstateID = estates[0].value.id;
  }
  if (brokers.length === 1) {
    defaultBrokerID = brokers[0].value.id;
  }

  const brokersDropdown = {
    isDisabled: brokers.length === 1,
    disabledValue: brokers.length === 1 ? brokers[0].value.id : ''
  };

  return (
    <Formik
      initialValues={{ broker_id: defaultBrokerID, estate_id: defaultEstateID }}

      onSubmit={async (values, actions) => {
        const broker_id = brokersDropdown.isDisabled ? brokersDropdown.disabledValue : values.broker_id;
        props.updateList({ onceRequested: true, isLoading: true, policies: [], ascending })
        const filter = { ...values };
        const data = user.role === UserRolesEnum.brokerAnalyst || user.role === UserRolesEnum.accountManager
          ? await getUserFireInsurancePolicies(user.id, filter)
          : await getPolicies(filter.broker_id, filter.estate_id);
        const orderedData = sortPolicies(data, ascending);
        props.updateList({ onceRequested: true, isLoading: false, policies: orderedData, ascending });
      }}>

      {formikProps => {
        return (
          <form onSubmit={formikProps.handleSubmit}>
            <Grid>
              <Grid.Row verticalAlign={'middle'}>
                <Grid.Column width={6}>
                  <FieldLabel>CORRETORA:</FieldLabel>
                  <Field name="broker"
                    completeValue={true}
                    searchable={false}
                    onChange={(e: SyntheticEvent, data: any) => {
                      formikProps.setFieldValue('broker_id', data.value.id)
                      updateAvailableBrokersAndEstates({
                        brokers: availableBrokersAndEstates.brokers,
                        estates: EstatesToCustomDropDownOptions(true, data.value.estates || []),
                        insurers: availableBrokersAndEstates.insurers,
                        bondInsurers: availableBrokersAndEstates.bondInsurers,
                        roles: availableBrokersAndEstates.roles,
                        franchises: availableBrokersAndEstates.franchises
                      });
                    }}
                    component={BrokersDropdown}
                    fluid />
                </Grid.Column>
                <Grid.Column width={7}>
                  <FieldLabel>IMOBILIÁRIA:</FieldLabel>
                  <Field
                    name="estate"
                    completeValue={false}
                    onChange={(e: SyntheticEvent, data: any) => {
                      formikProps.setFieldValue('estate_id', data.value.id)
                    }}
                    defaultValue={availableBrokersAndEstates.estates.length === 1 ? availableBrokersAndEstates.estates[0].value : '' || ''}
                    disabled={availableBrokersAndEstates.estates.length === 1 || false}
                    options={availableBrokersAndEstates.estates || []}
                    component={Dropdown}
                    fluid />
                </Grid.Column>
                <Grid.Column width={3}>
                  <SearchButton fluid
                    type={'submit'}
                    size={'mini'}
                    color='blue'
                    text='Pesquisar'
                    onClick={() => formikProps.handleSubmit} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </form>
        )
      }}

    </Formik>
  );
}

export default connect(mapState, mapDispatch)(Filter);
