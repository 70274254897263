import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { iRootState } from '../../store';
import  EstatesTable  from './Table';

const ListWrapper = styled.div`
  display: flex;
  flex-direction:column;
  padding: 1em;
`;

const mapState = (state: iRootState) => ({
    budgetsData: state.fireInsuranceBudgetsList
});

export type Props = ReturnType<typeof mapState> & RouteComponentProps;

const Estates = (props: Props) => {
    return (
        <>
            <ListWrapper>
                <EstatesTable
                    {...props}
                />
            </ListWrapper >
        </>
    );
}

export default connect(mapState, null)(Estates);
