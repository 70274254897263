import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Info } from "../../../../components/Info";
import { DateToStringDate } from "../../../../services/date";
import { CanceledBondInsuranceResponseDTO } from '../../types/bondinsurance-interfaces';
import SectionLabel from '../../../../components/SectionLabel';

export type Props = {
  canceledBondInsurance: CanceledBondInsuranceResponseDTO;
};

export const CanceledBondInsuranceMainInfoDetails = (props: Props) => {
  const {
    canceledBondInsurance
  } = props;

  return (
    <>
      <SectionLabel text='DADOS DO CANCELAMENTO' />
      <Grid.Row>
        <Info name={'ID'} value={canceledBondInsurance?.id} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'MOTIVO'} value={canceledBondInsurance?.cancellationReason} width={8} />
        <Info name={'CANCELADA POR'} value={canceledBondInsurance?.userName} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'DATA DE CANCELAMENTO'} value={DateToStringDate(canceledBondInsurance?.createdAt)} width={8} />
        <Info name={'OBSERVAÇÕES CANCELAMENTO'} value={canceledBondInsurance?.observations} width={8} />
      </Grid.Row>
    </>
  )
}
