import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px;
  label {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0em;
  }

  input {
    width: 100%;
    margin-top: 10px !important;
  }

  .ui.grid > .row {
    padding-bottom: 0px;
  }
`;

export const FormContainer = styled.div`
  padding: 24px 8px 48px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px 8px;
  h3 {
    color: #2185D0;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .status-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const FormTitle = styled.h4`
  font-size: 14px;
  font-weight: 800;
  line-height: 19px;
  margin: 10px 0 !important;
  color: #393939;
`;

export const Line = styled.div`
  height: 1px;
  background: #364445;
  padding: 0 8px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  button {
    min-width: 200px;
  }
`;

export const ErrorMessage = styled.small`
  color: #E3242B;
  padding: 0.1em;
`;
