import { Banner, BannerStatusEnum } from '../types/Banner';
import * as Storage from '../services/storage';
import { get, patch, remove } from './request';

export const getBanners = async (status?: BannerStatusEnum): Promise<Banner[]> => {
  try {
    return get(`/banner${status ? `?status=${status}` : ''}`) as Promise<Banner[]>;
  } catch (e) {
    throw e;
  }
};

export const createBanner = async({ data }: { data: FormData }) => {
  try {
    return fetch(`${process.env.REACT_APP_API_URL}/banner`, {
      method: 'POST',
      body: data,
      headers: {
        'Authorization': Storage.get('id_token') || '',
      }
    });
  } catch (e) {
    console.log('E', e);
    throw e;
  }
}

export const updateBanner = async(bannerId: string, data: FormData): Promise<any> => {
  try {
    return fetch(`${process.env.REACT_APP_API_URL}/banner/${bannerId}`, {
      method: 'PATCH',
      body: data,
      headers: {
        'Authorization': Storage.get('id_token') || '',
      }
    });
  } catch (e) {
    throw e;
  }
}

export const toggleStatus = async({ bannerId, status }: { bannerId: string; status: BannerStatusEnum }): Promise<Banner> => {
  try {
    return patch(`/banner/${bannerId}`, { status }) as Promise<Banner>;
  } catch (e) {
    throw e;
  }
}

export const getBannersByID = async (bannerId: string): Promise<Banner> => {
  try {
    return get(`/banner/${bannerId}`) as Promise<Banner>;
  } catch (e) {
    throw e;
  }
};

export const deleteBannerByID = async(bannerId: string): Promise<any> => {
  try {
    return remove(`/banner/${bannerId}`);
  } catch (e) {
    throw e;
  }
}
