import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px;
`;

export const FormContainer = styled.div`
  padding: 10px 24px;
`;

export const Form = styled.form`
  padding-bottom: 20px;
  label {
    display: flex;
    flex-direction: column;
    gap: 10px;

    font-size: 14px;
    font-weight: 800;
    line-height: 19px;
    letter-spacing: 0em;
  }

  input {
    width: 100%;
  }

  .row input {
    margin-top: 0px;
  }

  .ui.grid > .row {
    padding-bottom: 0px;
  }
`;

export const Disclaimer = styled.div`
  padding: 18px 24px 0;

  h4 {
    font-size: 18px;
    font-weight: 800;
    color: #2185d0;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
  width: 100%;
  margin-top: 15px;
`;
