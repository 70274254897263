import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    display: 'flex',
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
    lineHeight: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  headerTitle: {
    color: '#393939',
    fontSize: 14,
    textAlign: 'left',
    textTransform: 'uppercase',
    fontFamily: 'Helvetica-Bold',
    marginBottom: 4,
  },
  footerCopyrigth: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Helvetica-Bold',
    color: '#393939',
    marginTop: 10,
  },
  footerCopyrigthText: {
    color: '#393939',
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
    marginLeft: 5,
    paddingTop: 10,
    lineHeight: 2,
  },
  footerMessage: {
    color: '#393939',
    fontSize: 8,
    fontFamily: 'Helvetica',
    margin: 5,
    textAlign: 'justify',
  },
  headerSubTitle: {
    color: '#808080',
    fontSize: 10,
    textAlign: 'left',
    fontFamily: 'Helvetica-Bold',
    marginBottom: 4,
  },
  logoAlfa: {
    width: 250,
    height: 35,
  },
  logo4seg: {
    width: 35,
    height: 25,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
  },
  rowHeader: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: 'space-between',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderColor: '#DDDDDD',
    borderWidth: 1,
    borderTopWidth: 0,
    alignItems: 'flex-start',
    textAlign: 'left',
    paddingVertical: 5,
    paddingHorizontal: 8,
    justifyContent: 'space-between',
  },
  subRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderTopWidth: 0,
    alignItems: 'flex-start',
    textAlign: 'left',
    paddingTop: 5,
    paddingHorizontal: 8,
    justifyContent: 'space-between',
    borderColor: '#F2F2F2',
    backgroundColor: '#F2F2F2',
  },
  column: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
  subColumn: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
  label: {
    paddingBottom: 4,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 8,
    fontFamily: 'Helvetica-Bold',
    color: '#393939',
  },
  value: {
    textTransform: 'capitalize',
    color: '#393939',
    fontSize: 8,
  },
  description: {
    color: '#808080',
    fontSize: 6,
    paddingTop: 4,
  },
});
