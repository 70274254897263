import { DropdownItemProps } from "semantic-ui-react";
import { CancellationReasonEnum } from '../../enums/cancellation-reason.enum';
import { BondInsuranceAnalysisStatusEnum } from "../../enums/bond-insurance-analysis-status.enum";
import { PersonTypesEnum } from "../../enums/person-types.enum";
import { fileTypeProps } from "../../components/UploadFilesUncontroled";

export const cancellationReasonOptions: Array<DropdownItemProps> = [
  { value: CancellationReasonEnum.customerGaveUp, key: CancellationReasonEnum.customerGaveUp, text: 'Cliente desistiu da locação' },
  { value: CancellationReasonEnum.rentWithAnotherGuarantee, key: CancellationReasonEnum.rentWithAnotherGuarantee, text: 'Alugou com outra garantia' },
  { value: CancellationReasonEnum.rentWithGuarantor, key: CancellationReasonEnum.rentWithGuarantor, text: 'Alugou com fiador' },
];

export type BondInsurancesTableProps = {
}

export type FilterData = {
  broker_id?: string;
  estate_id?: string;
  user_id?: string;
  address?: string;
  name?: string;
  status?: string;
  document?: string;
  start?: string;
  end?: string;
  insurer?: string;
  analysisStatus?: string;
  reanalysis?: boolean;
  expiresInAWeek?: boolean;
  onlyUnread?: boolean;
  onlyManual?: boolean;
  accountManager?: string | null;
  bondInsuranceId?: string;
  clientType?: string;
}

export interface ListingRouteInfo {
  id: string;
}

export const statusOptions = [
  {
    key: BondInsuranceAnalysisStatusEnum.waiting,
    text: 'EM ESPERA',
    value: BondInsuranceAnalysisStatusEnum.waiting,
    label: { color: 'grey', empty: true, circular: true },
  },
  {
    key: BondInsuranceAnalysisStatusEnum.proposal,
    text: 'EM PROPOSTA',
    value: BondInsuranceAnalysisStatusEnum.proposal,
    label: { color: 'violet', empty: true, circular: true },
  },
  {
    key: BondInsuranceAnalysisStatusEnum.analysis,
    text: 'EM ANÁLISE',
    value: BondInsuranceAnalysisStatusEnum.analysis,
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: BondInsuranceAnalysisStatusEnum.approved,
    text: 'APROVADA',
    value: BondInsuranceAnalysisStatusEnum.approved,
    label: { color: 'green', empty: true, circular: true },
  },
  {
    key: BondInsuranceAnalysisStatusEnum.preapproved,
    text: 'PRÉ-APROVADA',
    value: BondInsuranceAnalysisStatusEnum.preapproved,
    label: { color: 'teal', empty: true, circular: true },
  },
  {
    key: BondInsuranceAnalysisStatusEnum.pending,
    text: 'PENDENCIADO',
    value: BondInsuranceAnalysisStatusEnum.pending,
    label: { color: 'yellow', empty: true, circular: true },
  },
  {
    key: BondInsuranceAnalysisStatusEnum.rejected,
    text: 'RECUSADO',
    value: BondInsuranceAnalysisStatusEnum.rejected,
    label: { color: 'red', empty: true, circular: true },
  },
  {
    key: BondInsuranceAnalysisStatusEnum.biometry,
    text: 'BIOMETRIA',
    value: BondInsuranceAnalysisStatusEnum.biometry,
    label: { color: 'orange', empty: true, circular: true },
  },
  {
    key: BondInsuranceAnalysisStatusEnum.error,
    text: 'ERRO',
    value: BondInsuranceAnalysisStatusEnum.error,
    label: { color: 'black', empty: true, circular: true },
  },
];

export enum MoreFiltersEnum {
  none = '',
  unread = 'onlyUnread',
  reanalysis = 'reanalysis',
  expiring = 'expiresInAWeek',
  manual = 'onlyManual'
}

export enum ArgumentTypeEnum {
  document = 'document',
  address = 'address',
  name = 'name'
}

export const analysisFileIdentifiers: fileTypeProps[] = [
  { name: "Outros", key: "OTHER", required: false },
  { name: "RG/CNH do inquilino", key: "TENANT_RG_CNH", required: false },
  { name: "RG/CNH do locador", key: "LESSOR_RG_CNH", required: false },
  { name: "Holerite/ Contra-cheque", key: "PAY_SLIP_PAYCHECK", required: false },
  { name: "Balancete", key: "BALANCE_SHEET", required: false },
  { name: "Relatório de faturamento", key: "BILLING_REPORT", required: false },
  { name: "Pró-labore", key: "PRO_LABORE", required: false },
  { name: "Contrato social", key: "SOCIAL_CONTRACT", required: false },
  {
    name: "Imposto de renda com Recibo de entrega",
    key: "INCOME_TAX_WITH_DELIVERY_RECEIPT",
    required: false
  },
  {
    name: "Extratos bancários ultimos 3 meses",
    key: "BANK_STATEMENTS_LAST_3_MONTHS",
    required: false
  },
  {
    name: "Balanço patrimonial últimos 3 anos da empresa com DRE",
    key: "BALANCE_SHEET_LAST_3_YEARS_COMPANY_DRE",
    required: false
  },
  {
    name: "Guia PGDAS dos últimos 6 meses com autenticação de pagamento",
    key: "PGDAS_GUIDE_LAST_6_MONTHS_PAYMENT",
    required: false
  }
];

export const typeOptions = [
  {
    index: 1,
    value: 'document',
    text: 'CPF ou CNPJ'
  },
  {
    index: 2,
    value: 'name',
    text: 'Nome do locatário'
  },
  {
    index: 3,
    value: 'address',
    text: 'Endereço'
  }
]

export const moreFiltersOptions = [
  {
    index: 0,
    value: MoreFiltersEnum.none,
    text: 'Nenhum'
  },
  {
    index: 1,
    value: MoreFiltersEnum.reanalysis,
    text: 'Apenas Reanálises'
  },
  {
    index: 2,
    value: MoreFiltersEnum.expiring,
    text: 'A Expirar em 7 dias'
  },
  {
    index: 3,
    value: MoreFiltersEnum.unread,
    text: 'Follow-up não lido'
  },
  {
    index: 4,
    value: MoreFiltersEnum.manual,
    text: 'Manuais'
  }
];

export const clientTypeOptions = [
  {
    index: 1,
    value: 'Todos',
    text: 'Todos'
  },
  {
    index: 2,
    value: PersonTypesEnum.natural,
    text: 'Pessoa Física'
  },
  {
    index: 3,
    value: PersonTypesEnum.legal,
    text: 'Pessoa Jurídica'
  }
];

export const selectableStatus = [
  {
    index: 0,
    value: 'Todos',
    text: 'Todos'
  },
  {
    index: 1,
    value: BondInsuranceAnalysisStatusEnum.approved,
    text: 'Aprovado'
  },
  {
    index: 2,
    value: BondInsuranceAnalysisStatusEnum.preapproved,
    text: 'Pré-Aprovado'
  },
  {
    index: 3,
    value: BondInsuranceAnalysisStatusEnum.analysis,
    text: 'Em Análise'
  },
  {
    index: 4,
    value: BondInsuranceAnalysisStatusEnum.waiting,
    text: 'Em Espera'
  },
  {
    index: 5,
    value: BondInsuranceAnalysisStatusEnum.proposal,
    text: 'Em Proposta'
  },
  {
    index: 6,
    value: BondInsuranceAnalysisStatusEnum.pending,
    text: 'Pendenciado'
  },
  {
    index: 7,
    value: BondInsuranceAnalysisStatusEnum.rejected,
    text: 'Recusado'
  },
  {
    index: 8,
    value: BondInsuranceAnalysisStatusEnum.error,
    text: 'Erro'
  },
  {
    index: 9,
    value: BondInsuranceAnalysisStatusEnum.biometry,
    text: 'Biometria'
  }
];
