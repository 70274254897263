/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import Image1 from './images/image1.png';
import Image2 from './images/image2.png';
import Image3 from './images/image3.png';
import Image4 from './images/image4.png';
//import Image5 from './images/image5.png'
import Image6 from './images/image6.png';
import Image7 from './images/image7.png';

import './style.css';

const InstrucaoSinistro = () => {
  return (
    <html>
      <head></head>
      <body className="c19">
        {/* <p className="c4"><span className="c18">Como abrir expectativa de Sinistro</span></p> */}
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c3">
            Para abrir e acompanhar as expectativas de sinistros solicitadas ou em
            andamento, siga as instru&ccedil;&otilde;es nesta p&aacute;gina.
          </span>
        </p>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c3">
            A partir de agora, n&atilde;o receberemos solicita&ccedil;&otilde;es de
            sinistro via e-mail, whatsapp ou qualquer outro meio diferente da plataforma
            4Seg. Este novo procedimento visa dar maior celeridade, agilidade,
            transpar&ecirc;ncia e autonomia para o processo de sinistro.
          </span>
        </p>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c17">
            Documentos necess&aacute;rios para abertura de sinistro
          </span>
        </p>
        {/* <p className="c4"><span className="c3">Temos regras em rela&ccedil;&atilde;o a documentos necess&aacute;rios a serem anexados, a saber:</span></p> */}
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <ul className="c1 lst-kix_j4tltgt3pa2m-0 start">
          <li className="c14 li-bullet-0">
            <span className="c7">
              <b>Contrato de loca&ccedil;&atilde;o &nbsp;</b> devidamente assinado por
              todas as partes;
            </span>
          </li>
          <li className="c9 li-bullet-0">
            <span className="c7">
              <b>Contra&ccedil;&atilde;o de administra&ccedil;&atilde;o &nbsp;</b>{' '}
              devidamente assinado por todas as partes;
            </span>
          </li>
          <li className="c9 li-bullet-0">
            <span className="c7">
              <b>Contrato Locatário/Garantti – Prestação de Serviço</b> devidamente
              assinado por todas as partes (somente para abertura de sinistros da
              Garantti);
            </span>
          </li>
          <li className="c21 li-bullet-0">
            <span className="c7">
              <b>
                Declara&ccedil;&atilde;o de d&eacute;bitos assinada pela
                imobili&aacute;ria &nbsp;
              </b>
              assinada pela administradora, detalhando todos os valores em aberto;
            </span>
          </li>
          <li className="c9 li-bullet-0">
            <span className="c7">
              <b>Boletos vencidos e n&atilde;o pagos de aluguel &nbsp;</b>
              vencidos e não pagos;
            </span>
          </li>
          <li className="c16 li-bullet-0">
            <span className="c7">
              <b>Procura&ccedil;&atilde;o</b> do escritório parceiro da seguradora,
              conforme modelos para download;
            </span>
          </li>
          <li className="c16 li-bullet-0">
            <span className="c7">
              <b>Guia de IPTU &ndash;</b> (em caso de d&eacute;bito(s) pendente(s));
              &nbsp;
            </span>
          </li>
          <li className="c27 li-bullet-0">
            <span className="c7">
              <b>Boletos originais dos condom&iacute;nios</b> com descritivo &ndash;( em
              caso de d&eacute;bito(s) &nbsp;pendente(s)); &nbsp;
            </span>
          </li>
          <li className="c15 li-bullet-0">
            <span className="c7">
              <b>Boletos de &aacute;gua</b> &ndash; (em caso de d&eacute;bito(s)
              pendente(s));
            </span>
          </li>
          <li className="c15 li-bullet-0">
            <span className="c7">
              <b>Boletos de luz</b> &ndash; (em caso de d&eacute;bito(s) pendente(s));
            </span>
          </li>
          <li className="c15 li-bullet-0">
            <span className="c7">
              <b>Contato telef&ocirc;nico e e-mail</b> do inquilino para cobran&ccedil;a.
            </span>
          </li>
        </ul>
        <p className="c24">
          <span className="c7">
            <b>
              Em caso de entrega de chaves, além dos documentos acima, enviar também:
              &nbsp;
            </b>
          </span>
        </p>
        <ul className="c1 lst-kix_ne73ptb6s8pv-0 start">
          <li className="c25 li-bullet-0">
            <span className="c7">
              <b>Planilha de d&eacute;bitos finais,</b> assinada pela administradora,
              &nbsp;detalhando todos os valores em aberto; &nbsp;
            </span>
          </li>
          <li className="c26 li-bullet-0">
            <span className="c7">
              <b>Termo de entrega de chaves:</b> obrigatório que o documento seja assinado
              pela imobiliária e pelo locatário. Se não for possível de o locatário
              assinar, enviar o documento assinado por duas testemunhas com seus
              respectivos números de CPF e a cópia da identidade delas.
            </span>
          </li>
        </ul>
        <p className="c20">
          <span className="c7">
            <b>
              Para análise de indenização de danos ao imóvel e/ou pintura (tendo sido
              contratada a cobertura) deve-se encaminhar também:
            </b>
          </span>
        </p>

        <p className="c20">
          <span className="c7">
            {' '}
            <b>SINISTROS DA POTTENCIAL SEGURADORA:</b>
          </span>
        </p>
        <ul className="c1 lst-kix_icjd55ndasyc-0 start">
          <span className="c10">
            <u>Danos ao imóvel:</u>
          </span>
          <li className="c8 li-bullet-0">
            <span className="c10">
              UM orçamento, detalhando separadamente, COM NOME E CPF DOS PRESTADORES;
            </span>
          </li>
          <li className="c8 li-bullet-0">
            <span className="c10">Valor de cada serviço;</span>
          </li>
          <li className="c8 li-bullet-0">
            <span className="c10">Valor de cada material;</span>
          </li>
          <br />
          <span className="c10">
            <u>Pintura:</u>
          </span>
          <li className="c8 li-bullet-0">
            <span className="c10">
              UM orçamento, detalhando separadamente, COM NOME E CPF DOS PRESTADORES;
            </span>
          </li>
          <li className="c8 li-bullet-0">
            <span className="c10">Quais cômodos serão pintados;</span>
          </li>
          <li className="c8 li-bullet-0">
            <span className="c10">Metragem da área de pintura;</span>
          </li>
          <li className="c8 li-bullet-0">
            <span className="c10">Valores de material;</span>
          </li>
          <li className="c8 li-bullet-0">
            <span className="c10">Valores de mão de obra.</span>
          </li>
        </ul>

        <p className="c20">
          <span className="c7">
            {' '}
            <b>SINISTROS DEMAIS SEGURADORAS:</b>
          </span>
        </p>
        <ul className="c1 lst-kix_icjd55ndasyc-0 start">
          <span className="c10">
            <u>Danos ao imóvel:</u>
          </span>
          <li className="c8 li-bullet-0">
            <span className="c10">
              DOIS orçamentos, detalhando separadamente, COM NOME E CPF DOS PRESTADORES;
            </span>
          </li>
          <li className="c8 li-bullet-0">
            <span className="c10">Valor cada serviço;</span>
          </li>
          <li className="c8 li-bullet-0">
            <span className="c10">Valor de cada material. </span>
          </li>
          <br />
          <span className="c10">
            <u>Pintura:</u>
          </span>
          <li className="c8 li-bullet-0">
            <span className="c10">
              DOIS orçamentos, detalhando separadamente, COM NOME E CPF DOS PRESTADORES;{' '}
            </span>
          </li>
          <li className="c8 li-bullet-0">
            <span className="c10">Quais cômodos serão pintados; </span>
          </li>
          <li className="c8 li-bullet-0">
            <span className="c10">Valores de material; </span>
          </li>
          <li className="c8 li-bullet-0">
            <span className="c10">Valores de mão de obra. </span>
          </li>
        </ul>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c20">
          <span className="c7">
            <b>DOCUMENTOS DE VISTORIAS</b>
          </span>
        </p>
        <ul className="c1 lst-kix_icjd55ndasyc-0 start">
          <span className="c10">
            <u>Vistoria inicial:</u>
          </span>
          <li className="c8 li-bullet-0">
            <span className="c10">
              Obrigatório que o documento seja assinado pela imobiliária e pelo locatário
              do contrato. No campo reservado para assinatura do locatário, este documento
              nunca poderá ser assinado por terceiros;
            </span>
          </li>
          <span className="c10">
            <u>Vistoria final:</u>
          </span>
          <li className="c8 li-bullet-0">
            <span className="c10">
              Obrigatório que o documento seja assinado pela imobiliária e pelo locatário.
              Se não for possível de o locatário assinar, enviar o documento assinado por
              duas testemunhas com seus respectivos números de CPF e a cópia de identidade
              delas.
            </span>
          </li>
        </ul>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c17">Modelos de documentos para download</span>
        </p>
        <p className="c4">
          <span className="c3">
            Disponibilizamos os modelos de documentos que, a depender da
            situa&ccedil;&atilde;o do im&oacute;vel (ocupado ou desocupado) e da
            Seguradora (Pottencial, FairFax, Liberty, Porto, Tokio ou Garantti):
          </span>
        </p>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c17">Documentos:</span>
        </p>
        <ul className="c1 lst-kix_yeb2kawpe8p9-0 start">
          <li className="c2 li-bullet-0">
            <span className="c11">
              Declara&ccedil;&atilde;o De D&eacute;bitos - Desocupado - Porto Seguro (
            </span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/DECLARACAO+DE+DEBITOS+-+DESOCUPADO+-+PORTO+SEGURO.pdf"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">
              Declara&ccedil;&atilde;o De D&eacute;bitos - Desocupado - Pottencial (
            </span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/DECLARACAO+DE+DEBITOS+-+DESOCUPADO+-+POTTENCIAL.pdf"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">
              Declara&ccedil;&atilde;o De D&eacute;bitos - Ocupado - Porto Seguro (
            </span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/DECLARACAO+DE+DEBITOS+-+OCUPADO+-+PORTO+SEGURO.pdf"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">
              Declara&ccedil;&atilde;o De D&eacute;bitos - Ocupado - Pottencial (
            </span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/DECLARACAO+DE+DEBITOS+-+OCUPADO+-+POTTENCIAL.pdf"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">
              Declara&ccedil;&atilde;o De D&eacute;bitos - Ocupado E Desocupado - Fairfax
              (
            </span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/DECLARACAO+DE+DEBITOS+-+OCUPAD+E+DESOCUPADO+-+FAIRFAX.DOCX"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">
              Declara&ccedil;&atilde;o De D&eacute;bitos - Ocupado E Desocupado - Garantti
              (
            </span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/declaracao_debitos_garantti.docx"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">
              Declara&ccedil;&atilde;o De D&eacute;bitos - Ocupado E Desocupado - Tokio
              (
            </span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Declara%C3%A7%C3%A3o+de+D%C3%A9bitos+-+Ocupado+e+Desocupado+-+Tokio.pdf"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">
              Declara&ccedil;&atilde;o De D&eacute;bitos - Ocupado - Too
              (
            </span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Declara%C3%A7%C3%A3o+de+D%C3%A9bitos+-+Ocupado+-+Too.docx"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">
              Declara&ccedil;&atilde;o De D&eacute;bitos - Desocupado - Too
              (
            </span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Declara%C3%A7%C3%A3o+de+D%C3%A9bitos+-+Desocupado+-+Too.docx"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>

          <li className="c2 li-bullet-0">
            <span className="c11">Acordo de Confissão de Dívidas - Pottencial (</span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Acordo+de+Confiss%C3%A3o+de+D%C3%ADvidas+-+Pottencial.pdf"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">
              Acordo de Confissão de Dívidas - Demais seguradoras (
            </span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Acordo+de+Confiss%C3%A3o+de+D%C3%ADvidas.doc"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">Imissão de Posse. (</span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Termo+de+imiss%C3%A3o+na+posse.DOC"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
        </ul>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c17">Procura&ccedil;&otilde;es:</span>
        </p>
        <ul className="c1 lst-kix_okzs1y3zd91n-0 start">
          <li className="c2 li-bullet-0">
            <span className="c11">Procura&ccedil;&atilde;o ALTAC - Garantti (</span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Garantti-Procuracao-Antunes-Barrak.docx"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">Procura&ccedil;&atilde;o BCM - Garantti (</span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/PROCURAÇÃO_BCM_Modelo_Garanttti_04_2023.docx"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">Procura&ccedil;&atilde;o Costódio - Garantti (</span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Procura%C3%A7%C3%A3o+Cost%C3%B3dio+%E2%80%93+Garantti.docx"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">Procura&ccedil;&atilde;o Costódio - Pottencial - (Para imobiliárias da Região Sul)  (</span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Procura%C3%A7%C3%A3o+Custodio+-++Pottencial+SUL.docx"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">Procura&ccedil;&atilde;o - Fairfax (</span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/PROCURACAO+-+FAIRFAX.docx"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">Procura&ccedil;&atilde;o - Liberty (</span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/PROCURACAO+-+LIBERTY.docx"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">Procura&ccedil;&atilde;o - Pottencial (</span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/PROCURACAO+-+POTTENCIAL.docx"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">Procura&ccedil;&atilde;o - Forte Assessoria - Too Seguros (</span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Procuração+-+Forte+Assessoria+-+Too.docx"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">Procura&ccedil;&atilde;o - FRIL - Too Seguros (</span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Procura%C3%A7%C3%A3o+-+FRIL+-+Too.docx"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
        </ul>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c17">
            Formul&aacute;rios (apenas para Pottencial e FairFax):
          </span>
        </p>
        <ul className="c1 lst-kix_n069zy5tgdu8-0 start">
          <li className="c2 li-bullet-0">
            <span className="c11">Formul&aacute;rio - PF - Pottencial (</span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Formul%C3%A1rio+-+PF+-+Pottencial.pdf"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c11">Formul&aacute;rio - PJ - Pottencial (</span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Formulario+PJ+Pottencial.pdf"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
          {/* <li className="c2 li-bullet-0"><span className="c11">Formul&aacute;rio - PJ - Pottencial (</span><span className="c0"><a target="_blank" className="c6" href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Formul%C3%A1rio+-+PJ+-+Pottencial.pdf">Download</a></span><span className="c3">)</span></li> */}
          <li className="c2 li-bullet-0">
            <span className="c11">Formul&aacute;rio Aviso de Sinistro FFX (</span>
            <span className="c0">
              <a
                target="_blank"
                className="c6"
                href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Formul%C3%A1rio+Aviso+de+Sinistro+FFX.docx"
              >
                Download
              </a>
            </span>
            <span className="c3">)</span>
          </li>
        </ul>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c3">
            Ap&oacute;s preparar os documentos, formul&aacute;rios e eventuais
            procura&ccedil;&otilde;es necess&aacute;rias para a abertura do Sinistro, siga
            os passos abaixo.
          </span>
        </p>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c23">
            Na tela do Sinistro, clique no bot&atilde;o &ldquo;Novo sinistro&rdquo;.
          </span>
        </p>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span id="span1">
            <img alt="" src={Image4} id="img1" title="" />
          </span>
        </p>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c3">
            Na nova janela, na 1&ordf; etapa, informe os dados necess&aacute;rios:
          </span>
        </p>
        <p className="c4">
          <span id="span2">
            <img alt="" src={Image1} id="img2" title="" />
          </span>
        </p>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c3">Role a tela e informe os dados do im&oacute;vel.</span>
        </p>
        <p className="c4">
          <span id="span3">
            <img alt="" src={Image3} id="img3" title="" />
          </span>
        </p>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c3">
            &Eacute; importante preencher os e-mails dos setores ou pessoas
            respons&aacute;veis para receberem as notifica&ccedil;&otilde;es de
            atualiza&ccedil;&otilde;es do andamento do sinistro. Importante salientar que,
            os e-mails podem cair no Spam devido a pol&iacute;ticas que fogem ao nosso
            alcance, recomendamos fortemente acessar o 4Seg para acompanhar as
            atualiza&ccedil;&otilde;es, atrav&eacute;s do Follow-up que ser&aacute; a
            central de atualiza&ccedil;&otilde;es dos sinistros solicitados e em
            andamento.
          </span>
        </p>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c3">
            Ap&oacute;s preenchimento dos dados iniciais, clique no bot&atilde;o
            &ldquo;Pr&oacute;ximo&rdquo;.
          </span>
        </p>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c3">
            No pr&oacute;ximo passo, voc&ecirc; precisar&aacute; incluir os Valores
            conforme tela abaixo:
          </span>
        </p>
        <p className="c4">
          <span id="span4">
            <img alt="" src={Image2} id="img4" title="" />
          </span>
        </p>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c3">
            Voc&ecirc; pode adicionar os valores que forem necess&aacute;rios.
          </span>
        </p>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c3">
            Ap&oacute;s incluir os valores, clique no bot&atilde;o
            &ldquo;Pr&oacute;ximo&rdquo;.
          </span>
        </p>
        <p className="c4">
          <span id="span5">
            <img alt="" src={Image7} id="img5" title="" />
          </span>
        </p>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c3">
            Agora, na &uacute;ltima etapa, anexe os documentos necess&aacute;rios. Veja no
            in&iacute;cio deste tutorial, os documentos necess&aacute;rios.
          </span>
        </p>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span id="span6">
            <img alt="" src={Image6} id="img6" title="" />
          </span>
        </p>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c3">
            Ap&oacute;s anexar os documentos m&iacute;nimos para abertura da expectativa
            de sinistro, clique no bot&atilde;o &ldquo;Solicitar&rdquo;.
          </span>
        </p>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>
        <p className="c4">
          <span className="c3">
            A partir deste momento, o departamento de Sinistros da Rede ConfiaX
            receber&aacute; e iniciar&aacute; o processo de an&aacute;lise e retorno.{' '}
          </span>
        </p>
        <p className="c4 c5">
          <span className="c3"></span>
        </p>

        <p className="c4">
          <span className="c17">Acompanhamento das solicitações de sinistro:</span>
        </p>
        <ul className="c1 lst-kix_n069zy5tgdu8-0 start">
          <li className="c2 li-bullet-0">
            <span className="c11">
              <b>Em análise:</b> Sinistros em analise de documentação por parte da
              corretora.{' '}
            </span>
          </li>{' '}
          <br />
          <li className="c2 li-bullet-0">
            <span className="c11">
              <b>Pendenciado:</b> Sinistros em analise de documentação por parte da
              corretora.
            </span>
          </li>{' '}
          <br />
          <li className="c2 li-bullet-0">
            <span className="c11">
              <b>Em andamento:</b> Sinistros que atenderam aos requisitos exigidos e que
              estão sendo analisados pela seguradora, aguardando a indenização.
            </span>
          </li>{' '}
          <br />
          <li className="c2 li-bullet-0">
            <span className="c11">
              <b>Finalizados:</b> Sinistros que foram abertos e por algum motivo foram
              finalizados pelo atendimento (quitaram débitos ou foram pagos todos os
              débitos pela SEGURADORA).
            </span>
          </li>
        </ul>
        <br />
        <p className="c4">
          <span className="c3">
            É possível acompanhar todas as interações, dúvidas e orientações gerais pelo
            follow-up. Importante além de checar seu e-mail com as notificações, acessar o
            4Seg para acompanhar se existe alguma pendência a ser resolvida. O ícone do
            follow-up indicará mensagem não lida para seu melhor controle e
            acompanhamento.{' '}
          </span>{' '}
        </p>
        {/* <p className="c4"><span className="c3">########################################################</span></p>
        <p className="c4 c5"><span className="c3"></span></p>
        <p className="c4 c5"><span className="c3"></span></p>
        <p className="c4"><span className="c3">Modelos de documentos para download e envio na abertura da expectativa de sinistro:</span></p>
        <ul className="c1 lst-kix_wtqamcgjua6d-0 start">
          <li className="c2 li-bullet-0"><span className="c3">Planilha de d&eacute;bitos</span></li>
          <li className="c2 li-bullet-0"><span className="c3">Procura&ccedil;&atilde;o</span></li>
          <li className="c2 li-bullet-0"><span className="c3">Formul&aacute;rio</span></li>
          <li className="c2 li-bullet-0"><span className="c3">Lista de documentos</span></li>
        </ul>
        <p className="c4 c5"><span className="c3"></span></p>
        <p className="c4 c5"><span className="c3"></span></p>
        <p className="c4 c5"><span className="c3"></span></p>
        <p className="c4 c5"><span className="c3"></span></p>
        <p className="c4 c5"><span className="c3"></span></p>
        <p className="c4 c5"><span className="c3"></span></p>
        <p className="c4 c5"><span className="c3"></span></p>
        <p className="c4 c5"><span className="c3"></span></p>
        <p className="c4 c5"><span className="c3"></span></p>
        <p className="c4 c5"><span className="c3"></span></p>
        <p className="c4 c5"><span className="c3"></span></p>
        <p className="c4 c5"><span className="c3"></span></p>
        <p className="c4 c5"><span className="c3"></span></p>
        <p className="c4"><span className="c22">SINISTRO 11/04/2022</span></p>
        <p className="c4 c5"><span className="c22"></span></p>
        <ol className="c1 lst-kix_gxtg61epsal1-0 start" start={1}>
          <li className="c2 li-bullet-0"><span className="c7">Cria&ccedil;&atilde;o de Sinistro: solicitando termo de entregas de chave para um im&oacute;vel ocupado.</span></li>
          <li className="c2 li-bullet-0"><span className="c7">Hor&aacute;rio corrigido no followup, por&eacute;m 3h a mais no email recebido.</span></li>
          <li className="c2 li-bullet-0">
            <span className="c10">T&iacute;tulo: Novo follow-up. Retirar &ldquo;Equipe 4Seg&rdquo; do par&aacute;grafo, pq j&aacute; tem a assinatura da equipe (FEITO)</span><span id="span7">
              <img alt="" src={Image5} id="img7" title="" />
            </span>
          </li>
          <li className="c2 li-bullet-0"><span className="c7">Luana: Posicionar no passo de valores, primeiro na lista o pre&ccedil;o do aluguel</span></li>
          <li className="c2 li-bullet-0"><span className="c7">Luana: Quando im&oacute;vel ocupado n&atilde;o precisa do laudo de vistoria final e nem termos de entrega de chaves</span></li>
          <li className="c2 li-bullet-0"><span className="c7">Luana: Colocar os tipos de documentos (labels): Guia de IPTU, Vistoria inicial do im&oacute;vel, Contrato de administra&ccedil;&atilde;o (ao inv&eacute;s de Contrato administrativo do im&oacute;vel), Procura&ccedil;&atilde;o</span></li>
          <li className="c2 li-bullet-0"><span className="c7">Luana: Em emails adicionais, colocar a label: &ldquo;Informe os e-mails para receberem notifica&ccedil;&otilde;es do sinistro:&rdquo; e exigir que seja informado pelo menos um e-mail</span></li>
          <li className="c2 li-bullet-0"><span className="c7">Luana: ao lado do status do im&oacute;vel, (e im&oacute;vel descupado) perguntar: Ser&aacute; reclamado danos ao im&oacute;vel ou a pintura? - caso resposta afirmativa, exigir os seguintes documentos: Vistoria inicial do im&oacute;vel e Vistoria final do im&oacute;vel; </span></li>
          <li className="c2 li-bullet-0"><span className="c7">Login de imobili&aacute;ria n&atilde;o pode filtrar por Respons&aacute;vel (pode omitir para usu&aacute;rios de imobili&aacute;rias);</span></li>
          <li className="c2 li-bullet-0"><span className="c7">Labels: Cpf/Cnpj Locat&aacute;rio; Nome Locat&aacute;rio; E-mail Locat&aacute;rio; Telefone Locat&aacute;rio;</span></li>
        </ol>
        <p className="c4 c5"><span className="c7"></span></p>
        <p className="c4 c5"><span className="c7"></span></p> */}
      </body>
    </html>
  );
};

export default InstrucaoSinistro;
