import React, { useEffect, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { CustoDropDownItemProps } from '../../../../components/types';
import { Grid } from 'semantic-ui-react';
import { Field } from 'formik';
import { EstatesToCustomDropDownOptions } from '../../../../util';
import BrokersDropdown from '../../../../components/BrokersDropdown';
import { FormikErrorMessage } from '../../../../components/ErrorMessage';
import { EstateState } from '../../../../types/EstateState';
import { unmaskMoney } from '../../../../services/masks';
import { FireInsuranceBudgetFormSectionProps, calculateCoverageDamage } from '../../FireInsuranceBudgetForm';
import { getErrorFormik } from '../../../../services/errors';
import Dropdown from '../../../../components/Dropdown';
import { FieldLabel } from '../../../../styles';
import { useState } from 'react';
import { getFireInsuranceActiveInsurers, getInsurersStateObjectFromDTO } from "../../../../services/insurer";
import {iRootDispatch, iRootState} from "../../../../store";
import {InsurerState} from "../../../../types/InsurerState";
import {
  FireInsuranceActiveInsurersResponseDTO
} from "../../../../dtos/fire-insurance-budget/fire-insurance-active-insurers-response.dto";
import {
  FireInsuranceBudgetSelectedBrokerState,
  FireInsuranceBudgetSelectedEstateState, FireInsuranceQuotationFormSections
} from "../../../../types/FireInsurance";
import {InsurersListState} from "../../../../store/types/temp-types";

type MainDataProps =
  ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  /*& FireInsuranceBudgetFormSectionProps*/;

const mapDispatchToProps = (dispatch: iRootDispatch) => ({
  updateSelectedBroker: (payload: Partial<FireInsuranceBudgetSelectedBrokerState>) => dispatch.fireInsuranceBudget.updateSelectedBroker(payload),
  updateSelectedEstate: (payload: Partial<FireInsuranceBudgetSelectedEstateState>) => dispatch.fireInsuranceBudget.updateSelectedEstate(payload),
  updateInsurersList: (payload: Partial<InsurersListState>) => dispatch.insurers.updateInsurersList(payload),
  resetSimulatorFields: () => dispatch.simulator.resetSimulatorFields(),
  toggleToResetFormSections: (payload: Partial<FireInsuranceQuotationFormSections>) => dispatch.fireInsuranceBudget.toggleToResetFormSection(payload),
  resetFireInsuranceBudgetConnectorData: () => dispatch.fireInsuranceBudget.resetConnectorQuotationData(),
  resetFireInsuranceFormValuesFromBroker: () => dispatch.fireInsuranceBudget.resetFormValuesFromBroker(),
  resetFireInsuranceFormValuesFromInsurer: () => dispatch.fireInsuranceBudget.resetFormValuesFromInsurer()
});

const mapStateToProps = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user,
  setFieldValue: (fieldName: string, value: any) => state.formik.actions.setFieldValue &&
    state.formik.actions?.setFieldValue(fieldName, value),
  setFieldTouched: (fieldName: string, touched: boolean) => state.formik.actions.setFieldTouched &&
    state.formik.actions?.setFieldTouched(fieldName, touched)
});

const MainData = (props: any) => {
  const {
    values,
    handleInputChange,
    errors,
    touched,
    availableBrokersAndEstates,
    updateAvailableBrokersAndEstates,
    updateSelectedBroker,
    updateSelectedEstate,
    resetSimulatorFields,
    resetFireInsuranceBudgetConnectorData,
    toggleToResetFormSections,
    resetFireInsuranceFormValuesFromBroker,
    resetFireInsuranceFormValuesFromInsurer,
    sectionKey,
    setFieldValue,
    setIsLoading,
    user
   } = props;

  const { budget, simulator } = values;

  const brokers: CustoDropDownItemProps[] = availableBrokersAndEstates.brokers;
  const estates: CustoDropDownItemProps[] = availableBrokersAndEstates.estates;

  const [selectedEstateId, setSelectedEstateId] = useState<string>();

  useEffect(() => {
    if (user.estate) {
      setSelectedEstateId(user.estate.id);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        let insurers: InsurerState[] = [];

        if (selectedEstateId) {
          const response: FireInsuranceActiveInsurersResponseDTO = await getFireInsuranceActiveInsurers(selectedEstateId)
          insurers = getInsurersStateObjectFromDTO(response.fire_insurance_active_insurers);
        }

        props.updateInsurersList({ insurers: insurers });
      } catch (e) {
        props.updateInsurersList({ insurers: [] });
      } finally {
        setIsLoading(false);
      }
    })()
  }, [selectedEstateId])

  const validateErrors = (field: string) => getErrorFormik(errors, touched, sectionKey, field);

  const setDefaultValues = () => {
    resetFireInsuranceFormValuesFromBroker();
    toggleToResetFormSections({
      propertyInputSection: true,
      tenantInputSection: true,
      ownerInputSection: true
    });

    if (brokers.length === 1 && brokers[0].value) {
      const selectedBroker = brokers[0].value;

      updateSelectedBroker({
        id: selectedBroker.id,
        name: selectedBroker.name
      });
    }

    if (estates.length === 1 && estates[0].value) {
      const selectedEstate = estates[0].value;

      updateSelectedEstate({
        id: selectedEstate.id,
        name: selectedEstate.name,
        multiplier: selectedEstate.multiplier
      });
    }

    budget.insurance.estate = estates.length === 1 ? estates[0].value : '';
    budget.insurance.broker = brokers.length === 1 ? brokers[0].value : '';
    simulator.profitShare = estates.length === 1 ? estates[0].value.profit_share : ''
    simulator.brokerageFee = brokers.length === 1 ? brokers[0].value.fee : ''
  }

  useEffect(() => {
    setDefaultValues();
  }, [estates, brokers]);

  const handleChangeEstate = (e: SyntheticEvent, data: CustoDropDownItemProps) => {
    //Reminder to fix the relationship between form fields and Redux state
    resetSimulatorFields();
    resetFireInsuranceBudgetConnectorData();
    resetFireInsuranceFormValuesFromInsurer();
    toggleToResetFormSections({
      propertyInputSection: true,
      tenantInputSection: true,
      ownerInputSection: true
    });

    const estate: EstateState = data.value;
    const insurance = budget.insurance || {};

    updateSelectedEstate({
      id: estate.id,
      name: estate.name,
      multiplier: estate.multiplier
    })
    setSelectedEstateId(estate.id);

    if (estate.fireInsurer) {
      props.updateInsurersList({ insurers: estate.fireInsurers });
    } else {
      props.updateInsurersList({ insurers: [] });
    }



    const rentValue = insurance.rentValue;
    if (estate.multiplier && rentValue) {
      const coverage = calculateCoverageDamage(unmaskMoney(rentValue), estate.multiplier);
      setFieldValue('coverage', coverage);
    }
    simulator.profitShare = estate.profitShare;
    handleInputChange(e, data);
  }

  const handleChangeBroker = (e: SyntheticEvent, data: CustoDropDownItemProps) => {
    resetSimulatorFields();
    resetFireInsuranceBudgetConnectorData();
    resetFireInsuranceFormValuesFromBroker();
    toggleToResetFormSections({
      propertyInputSection: true,
      tenantInputSection: true,
      ownerInputSection: true
    });

    const selectedBroker = data.value;

    updateSelectedBroker({
      id: selectedBroker.id,
      name: selectedBroker.name
    });

    handleInputChange(e, data);
    simulator.brokerageFee = data.value.fee;
    updateAvailableBrokersAndEstates({
      brokers: brokers,
      estates: EstatesToCustomDropDownOptions(true, data.value.estates || [])
    });
  }

  return (
    <Grid.Row>
      <Grid.Column width={8}>
        <FieldLabel>CORRETORA*</FieldLabel>
        <Field name="budget.insurance.broker"
          completeValue={true}
          placeholder={'Selecionar...'}
          error={validateErrors('broker')}
          onChange={handleChangeBroker}
          component={BrokersDropdown}
          fluid />
        <FormikErrorMessage component="div" name="insurance.broker" />
      </Grid.Column>
      <Grid.Column width={8}>
        <FieldLabel>IMOBILIÁRIA* </FieldLabel>
        <Field name="budget.insurance.estate"
          value={budget.insurance.estate.id ? budget.insurance.estate : null}
          defaultValue={estates.length === 1 ? estates[0].value : null}
          completeValue={true}
          placeholder={'Selecionar...'}
          disabled={estates.length === 1}
          options={estates || []}
          error={validateErrors('estate')}
          onChange={handleChangeEstate}
          component={Dropdown}
          search
          fluid />
        <FormikErrorMessage component="div" name="insurance.estate" />
      </Grid.Column>
    </Grid.Row>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MainData)
