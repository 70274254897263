import React, { Dispatch, SetStateAction, SyntheticEvent, useRef, useState } from "react";
import {
  Button,
  Form as SemanticForm,
  Icon,
  Modal,
  Popup,
  Grid
} from "semantic-ui-react";
import { Container, Disclaimer, StyledModalActions } from './styles';
import IconButton from "../../../../../../components/IconButton";
import { ErrorMessage, Field, Formik, FormikProps } from "formik";
import SectionLabel from "../../../../../../components/SectionLabel";
import Input from "../../../../../../components/Input";
import { adjustInsurerResponse, Offer } from "./util";
import * as Yup from 'yup';
import { FieldLabel } from "../../../../../../styles";
import { numberToMoneyString } from "../../../../../../services/masks";
import { editPlans } from "../../../../../../services/bond-insurance";
import { dangerNotification, successNotification, warningNotification } from "../../../../../../services/notification";

export type EditPlansModalProps = {
  analysis: any;
  closeModal: Dispatch<SetStateAction<boolean>>;
}

interface FormValues {
  offers: Offer[];
}

const EditPlansModal = (props: EditPlansModalProps) => {
  const { analysis, closeModal } = props;
  const [openModal, setOpenModal] = useState(false);
  const [formValues, setFormValues] = useState<Offer[]>(analysis.offers);
  const [loading, setLoading] = useState(false)

  const initialValues: FormValues = {
    offers: formValues.map((offer) => ({
      nomeSeguro: offer.nomeSeguro,
      valorParcela: offer.valorParcela,
      quantidadeParcela: offer.quantidadeParcela,
    })),
  };

  const handleChange = (index: number, field: keyof Offer, value: string | number, setFieldValue: (field: string, value: any) => void) => {
    setFieldValue(`offers[${index}].${field}`, value);
    setFormValues(prevFormValues =>
      prevFormValues.map((offer, i) =>
        i === index ? { ...offer, [field]: value } : offer
      )
    );
  };

  const handleSubmit = async (values: FormValues, actions: any) => {
    try {
      setLoading(true)
      const payload = adjustInsurerResponse(analysis.insurerResponse, analysis.insurer.code, values.offers);
      const response = await editPlans(payload, analysis.id)
      if(response) {
        successNotification('Sucesso!', 'Planos atualizados.');
      } else {
        warningNotification('Oops...', 'Algo deu errado, tente novamente mais tarde')
      }
      actions.setSubmitting(false);
    } catch (error) {
      dangerNotification('Erro', 'Ocorreu um erro'+error);
      console.log('ERRO: ', error)
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const validationSchema = Yup.object({
    offers: Yup.array().of(
      Yup.object({
        valorParcela: Yup.string().required('Required'),
        quantidadeParcela: Yup.number().required('Required'),
      })
    ),
  });

  const renderForm = (formikProps: FormikProps<FormValues>) => {
    const { values, setFieldValue } = formikProps;

    return (
      <SemanticForm loading={loading}>
        <Grid>
          {values.offers.map((offer, index) => (
            <>
              <SectionLabel text={offer.nomeSeguro} />
              <Grid.Row key={index} style={{ marginBottom: '20px', marginTop: '0', padding: '0' }}>
                <Grid.Column width={8}>
                  <FieldLabel>Valor da Parcela</FieldLabel>
                  <Field
                    type="text"
                    name={`offers[${index}].valorParcela`}
                    value={numberToMoneyString(Number(offer.valorParcela))}
                    onChange={(_: SyntheticEvent, data: any) => {
                      const onlyNumber = data.value.replace(/\D/g, '');
                      const newValue = Number(onlyNumber) / 100;
                      handleChange(index, 'valorParcela', newValue, setFieldValue)
                    }}
                    component={Input}
                    fluid
                  />
                  <ErrorMessage name={`offers[${index}].valorParcela`} component="div" />
                </Grid.Column>
                <Grid.Column width={8}>
                  <FieldLabel>Quantidade de Parcelas</FieldLabel>
                  <Field
                    type="number"
                    name={`offers[${index}].quantidadeParcela`}
                    placeholder="Quantidade de Parcelas"
                    value={offer.quantidadeParcela}
                    component={Input}
                    onChange={(_: SyntheticEvent, data: any) => handleChange(index, 'quantidadeParcela', Number(data?.value), setFieldValue)}
                    fluid
                    min={1}
                    max={60}
                  />
                  <ErrorMessage name={`offers[${index}].quantidadeParcela`} component="div" />
                </Grid.Column>
              </Grid.Row>
            </>
          ))}
        </Grid>
      </SemanticForm>
    );
  }

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    closeModal(false);
  };


  return (
    <div style={{ zIndex: 10, position: 'relative' }}>
      <Modal
        style={{
          position: "absolute", top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        closeIcon
        open={openModal}
        onClose={handleClose}
        trigger={
          <Popup
            content="Editar Planos"
            trigger={
              <button
                style={{ border: 'none', backgroundColor: 'transparent' }}
                onClick={handleOpen}
              >
                <IconButton name='edit outline' />
              </button>
            }
          />
        }
      >
        <Modal.Content>
          <Container>
            <Disclaimer>
              <Icon name="edit outline" />
              <h4>
                Editar Planos - {analysis.insurer.name}
              </h4>
            </Disclaimer>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {formikProps => (
                <>
                  {renderForm(formikProps)}
                  <StyledModalActions>
                    <Button negative onClick={handleClose}>
                      Fechar
                    </Button>
                    <Button positive onClick={() => formikProps.submitForm()}>
                      Salvar
                    </Button>
                  </StyledModalActions>
                </>
              )}
            </Formik>
          </Container>
        </Modal.Content>
      </Modal>
    </div>
  );
};
export default EditPlansModal;

