import { withFormik } from "formik";
import { CancellationInitialValues } from "./initialStatesCancel";
import CancellationInsuranceForm from '../Steps/CancelInsuraceFormStep';
export const CancellationFormik = withFormik<any, typeof CancellationInitialValues>({

  mapPropsToValues: () => CancellationInitialValues,

  handleSubmit: (values, bag) => true,

})(CancellationInsuranceForm);
