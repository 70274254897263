import React, { useEffect, useState } from 'react';
import { SimulatorProps } from '../../types';
import { Grid } from 'semantic-ui-react';
import { maskMoney, unmaskMoney, fixNumber } from '../../../../services/masks';
import { HeaderWrapper, AmountWithInstallments, HeaderDividerContainer, HeaderDivider } from '../styled-components';
import VMasker from 'vanilla-masker';
import {iRootDispatch, iRootState} from "../../../../store";
import {connect} from "react-redux";
import styled from "styled-components";

type HeaderProps = {
  simulator: SimulatorProps;
} & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: iRootState) => ({
  installmentsData: state.simulator.installmentsData
});

const mapDispatchToProps = (dispatch: iRootDispatch) => ({});

const Header = (props: HeaderProps) => {
  const { simulator, installmentsData } = props;
  const initialBudgetValue = simulator.covered ? simulator.coverValue : simulator.value;
  const [budgetTotalValue, setBudgetTotalValue] = useState<number>(initialBudgetValue);
  const [budgetInstallmentsValue, setBudgetInstallmentsValue] = useState<number>(initialBudgetValue);


  useEffect(() => {
    const installments = simulator.installments && simulator.installments > 0 ? simulator.installments : 1;
    let budgetTotalValue = 0;
    let budgetInstallmentsValue = 0;

    if (simulator.covered) {
      budgetTotalValue = unmaskMoney(`${simulator.coverValue}`);
      budgetInstallmentsValue = budgetTotalValue / installments;
    } else {
      const selectedInstallmentDataIndex = installmentsData.findIndex((data) => {
        return data.installmentsAmount === simulator.installments
      });

      if (selectedInstallmentDataIndex >= 0) {
        budgetTotalValue = simulator.value;
        budgetInstallmentsValue = installmentsData[selectedInstallmentDataIndex].totalPremiumAmount;
      }
    }

     /*= simulator.covered ? unmaskMoney(`${simulator.coverValue}`) : simulator.value;*/


    setBudgetTotalValue(budgetTotalValue);
    setBudgetInstallmentsValue(budgetInstallmentsValue)
  }, [simulator.value, simulator.coverValue, simulator.covered, simulator.installments, installmentsData]);

  return (
    <>
      <HeaderWrapper>
        <Grid.Row>
{/*          <Grid.Column width={8}>
            <div>
              <h5>Seu orçamento</h5>
            </div>
            <div>
              <AmountWithInstallments>
                {simulator.installments || 1}x R$
                {maskMoney(unmaskMoney(
                  fixNumber(budgetInstallmentsValue).toString()
                  ).toString(), true)}
              </AmountWithInstallments>
            </div>
          </Grid.Column>*/}
{/*          <HeaderDividerContainer width={1}>
            <HeaderDivider />
          </HeaderDividerContainer>*/}
          <StyledGridColumn width={16}>
              <EmphasizedText>Orçamento</EmphasizedText>
              {/* <EmphasizedText>R$ {maskMoney(unmaskMoney(
                  fixNumber(budgetTotalValue).toString()
                  ).toString(), true)}</EmphasizedText> */}
          </StyledGridColumn>
        </Grid.Row>
      </HeaderWrapper>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

const EmphasizedText = styled.span`
  display: block;
  font-size: 1em;
  font-weight: bold;
`

const StyledGridColumn = styled(Grid.Column)`
  text-align: center;
`
