import { DropdownItemProps } from "semantic-ui-react";
import { CancellationReasonEnum } from "../../enums/cancellation-reason.enum";
import { BrokerState } from "../../types/BrokerState";
import { EstateState } from "../../types/EstateState";

export interface PoliciesListingRouteInfo {
  id: string;
}

export type PoliciesFilterData = {
  estateId: string;
  brokerId: string;
}

export type FilterData = {
  broker?: BrokerState;
  estate?: EstateState;
  broker_id: string;
  estate_id: string;
  property_code: string;
  document: string;
  property_zipcode: string;
}

export type PoliciesFilters = {
  brokerId?: string;
  estateId?: string;
  start?: string;
  end?: string;
  document?: string;
  name?: string;
  address?: string;
  status?: string;
  insurer?: string;
  accountManager?: string;
  noPolicyAttached?: boolean;
  bondInsuranceId?: string;
  expireInDays?: string;
  hasWarning?: boolean;
  onlyUnread?: boolean;
  onlyQuiver?: boolean;
  onlyManual?: boolean;
  onlyNew?: boolean;
  insurerPolicyId?: string;
  policyId?: string;
  renewResponsible?: string;
}

export interface IBrokerInsurerState {
  insurers_availability: string;
  insurers_bond_insurance_tax: string;
  insurers_code: string;
  insurers_fee: string;
  insurers_id: string;
  insurers_image: string;
  insurers_name: string;
  insurers_notification_emails: string;
  insurers_proposals_sending: number;
}

export interface IEstateInsurerState {
  availability: string;
  bond_insurance_tax: string;
  code: string;
  fee: string;
  id: string;
  image: string;
  name: string;
  notification_emails: string;
  proposals_sending: number;
}

export type CancelModalPolicyProps = {
  hiredDataId: string;
  bondInsuranceId: string;
  closeModal: () => void;
  callback: () => void;
  user_id?: string;
  setOpen: () => void;
  setFlagSubmit?: (a: boolean) => void;
  openModal: boolean;
}
export type CancellationInsurancePolicyProps = {
  flagSubmit: boolean;
  callbackSubmit: (a: any) => void;
  setFlagSubmit: (a: boolean) => void;
  hiredDataId: string;
  closeModal: () => void;
}

export const cancellationReasonOptions: Array<DropdownItemProps> = [
  { value: CancellationReasonEnum.customerGaveUp, key: CancellationReasonEnum.customerGaveUp, text: 'Cliente desistiu da locação' },
  { value: CancellationReasonEnum.rentWithAnotherGuarantee, key: CancellationReasonEnum.rentWithAnotherGuarantee, text: 'Alugou com outra garantia' },
  { value: CancellationReasonEnum.rentWithGuarantor, key: CancellationReasonEnum.rentWithGuarantor, text: 'Alugou com fiador' },
  { value: CancellationReasonEnum.deliveryKeysAndNoDebts, key: CancellationReasonEnum.deliveryKeysAndNoDebts, text: 'Entrega de chaves e inexistência de débitos' },
  { value: CancellationReasonEnum.nonPayment, key: CancellationReasonEnum.nonPayment, text: 'Falta de pagamento' },
  { value: CancellationReasonEnum.divergenceAddress, key: CancellationReasonEnum.divergenceAddress, text: 'Divergência de endereço' },
  { value: CancellationReasonEnum.changeOfWarranty, key: CancellationReasonEnum.changeOfWarranty, text: 'Troca de garantia' },
  { value: CancellationReasonEnum.propertyPurchase, key: CancellationReasonEnum.propertyPurchase, text: 'Compra do imóvel' },
  { value: CancellationReasonEnum.errorInHiring, key: CancellationReasonEnum.errorInHiring, text: 'Erro na contratação' },
  { value: CancellationReasonEnum.errorInNumberOfInstallments, key: CancellationReasonEnum.errorInNumberOfInstallments, text: 'Erro no número de parcelas' },
  { value: CancellationReasonEnum.leaseAdvance, key: CancellationReasonEnum.leaseAdvance, text: 'Antecipação de locação' },
  { value: CancellationReasonEnum.changeOfTenant, key: CancellationReasonEnum.changeOfTenant, text: 'Troca de locatário' },
  { value: CancellationReasonEnum.changeOfInsurer, key: CancellationReasonEnum.changeOfInsurer, text: 'Troca de seguradora' },
  { value: CancellationReasonEnum.duplicationOfPolicy, key: CancellationReasonEnum.duplicationOfPolicy, text: 'Duplicidade de apólice' },
  { value: CancellationReasonEnum.claimClosed, key: CancellationReasonEnum.claimClosed, text: 'Sinistro encerrado' },
];

