import React, { useEffect, useState } from 'react';
import { Button,
  Dimmer,
  Grid,
  GridColumn,
  Header,
  Icon,
  Loader,
  Modal,
  ModalContent,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'semantic-ui-react';
import { maskCpfOrCnpj, numberToMoneyString } from '../../../../services/masks';
import moment from 'moment';
import { getInvoiceDetails } from '../../../../services/fire-insurance-invoices';
import { Info } from '../../../../components/Info';
import { translateInvoiceStatus } from '../../utils/AlfaSeguros';
import { AlfaInvoiceDetailsReport } from '../../../../services/reports/AlfaIncoiceDetailsReport';

interface InvoiceDetailProps {
  open: boolean;
  onClose: () => void;
  invoice: any;
}

const InvoiceDetailModal = (props: InvoiceDetailProps) => {
  const { open, onClose, invoice } = props;

  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [invoiceDetailsData, setInvoiceDetailsData] = useState<any>(null);

  const getInvoiceDetailsData = async (invoiceId: string) => {
    setIsLoadingData(true);

    try {
      const invoices = await getInvoiceDetails(invoiceId);
      setInvoiceDetailsData(invoices?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleInvoiceStatementDownload = async () => {
    invoiceDetailsData && await AlfaInvoiceDetailsReport(invoice, invoiceDetailsData);
  };

  useEffect(() => {
    invoice.id && getInvoiceDetailsData(invoice.id);
  }, [invoice.id]);

  return (
    <Modal
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={open}
      onClose={onClose}
      size='large'
      centered
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '95%'
      }}
    >
      <Header icon='shield alternate' content='Detalhes da Fatura' />
      <ModalContent scrolling>
        <Grid>
          <Grid.Row>
            <Info name={'Imobiliária'} value={invoice?.estate?.name || ''} width={3} />
            <Info name={'Seguradora'} value={invoice?.insurer?.name || ''} width={3} />
            <Info name={'Valor total da fatura'} value={numberToMoneyString(Number(invoice.amount))} width={3} />
            <Info name={'Data de vencimento'} value={moment(invoice.dueDate).format('DD/MM/YYYY')} width={3} />
            <Info name={'Status'} value={translateInvoiceStatus(invoice.status)} width={3} />
          </Grid.Row>
        </Grid>

        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell textAlign={'center'}>Nº DA APÓLICE</TableHeaderCell>
              <TableHeaderCell textAlign={'center'}>ENDEREÇO</TableHeaderCell>
              <TableHeaderCell textAlign={'center'}>PRODUTO</TableHeaderCell>
              <TableHeaderCell textAlign={'center'}>LOCATÁRIO</TableHeaderCell>
              <TableHeaderCell textAlign={'center'}>Nº PARCELA</TableHeaderCell>
              <TableHeaderCell textAlign={'center'}>VALOR PARCELA</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <Dimmer.Dimmable as={TableBody} dimmed={isLoadingData}>
            {isLoadingData && (
              <Dimmer active inverted>
                <Loader />
              </Dimmer>
            )}

            {invoiceDetailsData && invoiceDetailsData.length ? (
              invoiceDetailsData.map((invoice: any) => {
                const [address, city] = String(invoice.address).split('-');

                return (
                  <TableRow key={invoice.id}>
                    <TableCell textAlign={'center'}>
                      {invoice.policyNumber}
                    </TableCell>

                    <TableCell textAlign={'center'} width={4}>
                      {address}<br/>{city}
                    </TableCell>

                    <TableCell textAlign={'center'} width={4}>
                      {invoice.product}
                    </TableCell>

                    <TableCell textAlign={'center'}>
                      {invoice.tenantName}<br />
                      {maskCpfOrCnpj(invoice.tenantDocument)}
                    </TableCell>

                    <TableCell textAlign={'center'}>
                      {invoice.installmentNumber}
                    </TableCell>

                    <TableCell textAlign={'center'}>
                      {numberToMoneyString(Number(invoice.installmentValue))}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={9} textAlign={'center'}>
                  Nenhum registro encontrado
                </TableCell>
              </TableRow>
            )}
          </Dimmer.Dimmable>
        </Table>
      </ModalContent>
      <Modal.Actions>
        <Grid>
          <GridColumn floated='left' width={2}>
            <Button icon floated='left' labelPosition={'left'} onClick={handleInvoiceStatementDownload}>
              <Icon name={'download'} />
              Baixar Extrato
            </Button>
          </GridColumn>

          <GridColumn floated='right' width={2}>
            <Button icon labelPosition={'left'} onClick={onClose}>
              <Icon name={'arrow left'} />
              Voltar
            </Button>
          </GridColumn>
        </Grid>
      </Modal.Actions>
    </Modal>
  );
};

export default InvoiceDetailModal;
