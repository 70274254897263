import React from 'react';
import { Button, Grid, Header, Icon, List, ListHeader, ListItem, Modal, Popup } from 'semantic-ui-react';

interface GeneralConditionProps {
  openModal: boolean;
  toggleOpen: () => void;
  toggleClose: () => void;
}
const FireGeneralCondition = ({ openModal, toggleClose, toggleOpen }: GeneralConditionProps) => {
  return (
    <>
      <Modal
        open={openModal}
        onClose={toggleClose}
        onOpen={toggleOpen}
      >
        <Modal.Header>
          <Header>Condições Gerais</Header>
        </Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <p>Conheça as condições gerais do produto seguro incêndio.</p>

                <List>
                  <ListItem>
                    <ListHeader style={{ fontSize: 18 }}>Alfa Seguros</ListHeader>
                    <List bulleted>
                      <ListItem
                        href='https://4seg-documents-analysis.s3.amazonaws.com/Condicoes+Gerais+-+Incendio/Condi%C3%A7%C3%B5es+Gerais+-+Residencial+-+Alfa+Imobili%C3%A1rio.pdf'
                        target='_blank'
                      >
                        Condições gerais - Residencial
                      </ListItem>

                      <ListItem
                        href='https://4seg-documents-analysis.s3.amazonaws.com/Condicoes+Gerais+-+Incendio/Condi%C3%A7%C3%B5es+Gerais+-+Comercial+-+Alfa+Imobili%C3%A1rio.pdf'
                        target='_blank'
                      >
                        Condições gerais - Comercial
                      </ListItem>
                    </List>

                    <ListItem>
                      <ListHeader>Resumo das assistências por planos:</ListHeader>
                      <List bulleted>
                        <ListItem
                          href='https://4seg-documents-analysis.s3.amazonaws.com/Condicoes+Gerais+-+Incendio/PLANO_PLUS_MAIS_MARROM_ALFA_CONFIAX.pdf'
                          target='_blank'
                        >
                          Cobertura do plano Plus Mais (+ linha marrom)
                        </ListItem>

                        <ListItem
                          href='https://4seg-documents-analysis.s3.amazonaws.com/Condicoes+Gerais+-+Incendio/PLANO_PLUS+-+ALFA+-+CONFIAX.pdf'
                          target='_blank'
                        >
                          Cobertura do plano Plus
                        </ListItem>

                        <ListItem
                          href='https://4seg-documents-analysis.s3.amazonaws.com/Condicoes+Gerais+-+Incendio/PLANO_LIGHT+-+ALFA+-+CONFIAX.pdf'
                          target='_blank'
                        >
                          Cobertura do plano Light
                        </ListItem>
                      </List>
                    </ListItem>
                  </ListItem>

                  <br/>

                  <ListItem>
                    <ListHeader style={{ fontSize: 18 }}>Tokio Marine</ListHeader>
                    <List bulleted>
                      <ListItem
                        href='https://4seg-documents-analysis.s3.amazonaws.com/Condicoes+Gerais+-+Incendio/Condicoes_SeguroIncendio_Tokio_Marine_Residencial.pdf'
                        target='_blank'
                      >
                        Condições gerais - Residencial
                      </ListItem>

                      <ListItem
                        href='https://4seg-documents-analysis.s3.amazonaws.com/Condicoes+Gerais+-+Incendio/Condicoes_SeguroIncendio_Tokio_Marine_Empresarial.pdf'
                        target='_blank'
                      >
                        Condições gerais - Comercial
                      </ListItem>
                    </List>
                  </ListItem>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={toggleClose}
            color={'green'}
          >
            OK, Entendi
          </Button>
        </Modal.Actions>
      </Modal>

      <Popup
        trigger={
          <span  onClick={toggleOpen} style={{color: "#9d9d9d", fontWeight: 'bold', fontSize: '14px', cursor: 'pointer'} }>
            <Icon
              size={'small'}
              name={'file alternate outline'}
              color={'grey'}
              style={{ cursor: 'pointer', marginRight: '10px', fontSize: 14 }}
            />
            Condições Gerais
          </span>
        }
        content={'Clique aqui para ver as condições gerais das seguradoras'}
        position={'left center'}
      />
    </>
  )
}

export default FireGeneralCondition;
