import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

export const TableComponent = styled.div`
  margin-top: 1rem;
  min-height: 50vh;
`;

export const Header = styled(Table.Header)`
  background-color: #e1e8ed;
`;

export const TableWrapper = styled.div`
  font-size: 0.85em;
  font-weight: bold;

  .ui.table {
    border: none;
    text-align: center;
    color: #364445;
  }
`;

export const NotFoundWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #364445;
  font-size: 1.2em;
`;
