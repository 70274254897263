import React from 'react';
import { PersonTypesEnum } from '../../../enums/person-types.enum';
import { PurposesEnum } from '../../../enums/purposes.enum';
import { Grid } from 'semantic-ui-react';
import { Info } from '../../../components/Info';
import { DateToStringDate } from '../../../services/date';
import { AnalysisAssuranceEnum } from '../../../enums/analysis-assurance.enum';
import { BondInsuranceForGetBondInsurancesResponseDTO } from "../../../dtos/bond-insurance-list/get-bond-insurances-response.dto";
import { BondInsuranceAnalysis } from '../../../types/BondInsuranceAnalysis';
import { EstateInsurerDataForGetEstatesResponseDTO, InsurerForGetEstateResponseDTO } from '../../../dtos/estate/estate-for-get-estates-response.dto';
import { InsurersCodeEnum } from '../../../enums/insurers-code.enum';
import { FieldLabel } from '../../../styles';
import { formatAmount } from '../../../services/masks';
import moment from 'moment-timezone';

export type Props = {
  bondInsurance: BondInsuranceForGetBondInsurancesResponseDTO;
  insurersData: EstateInsurerDataForGetEstatesResponseDTO[];
  insurers: InsurerForGetEstateResponseDTO[];
};

export const BondInsuranceMainInfoDetails = (props: Props) => {

  const { bondInsurance, insurersData, insurers } = props;
  const { property } = bondInsurance;

  return (
    <>
      <Grid.Row>
        <Info name={'ID'} value={bondInsurance.id} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'DATA DE EMISSÃO '} value={DateToStringDate(bondInsurance.created_at)} width={8} />
        <Info name={'CRIADA POR'} value={bondInsurance.user?.name} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'CORRETORA'} value={bondInsurance.estate?.broker?.name ? bondInsurance.estate?.broker.name : ''} width={8} />
        <Info name={'IMOBILIÁRIA'} value={bondInsurance.estate?.name} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'FINALIDADE'} value={bondInsurance.purpose === PurposesEnum.commercial ? 'Comercial' : 'Residencial'} width={8} />
        <Info name={'TIPO DE LOCATÁRIO'} value={bondInsurance.tenant_type === PersonTypesEnum.legal ? 'Pessoa Jurídica' : 'Pessoa Física'} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'MOTIVO DA LOCAÇÃO'} value={property.reason} width={8} />
        <Info name={'GESTOR DE CONTAS'} value={bondInsurance.estate?.user.name} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'VIGÊNCIA DO CONTRATO'} value={`${property.validity} MESES`} width={8} />
        <Info name={'DIA DE VENCIMENTO DO ALUGUEL'} value={`${property.rent_due_date ||  'Indisponível'}`} width={8} />
      </Grid.Row>
      {property.location_in_progress &&
        <Grid.Row>
          <Info name={'CONTRATO DE LOCAÇÃO VIGENTE'} value={`${property.location_in_progress ? 'SIM' : 'NÃO'}`} width={6} />
          <Info name={'Início da Locação'} value={moment(property.rent_insurance_contract_start_date).format('DD/MM/YYYY')} width={5} />
          <Info name={'Fim da Locação'} value={moment(property.rent_insurance_contract_end_date).format('DD/MM/YYYY')} width={5} />
        </Grid.Row>
      }
      <FieldLabel paddingTop={true}>SEGURADORAS / GARANTIDORAS ATIVAS </FieldLabel>
      <Grid.Row>
        <div style={{overflow: 'auto', display: 'flex', flexDirection: 'row', gap: '1rem', width: '80vw', whiteSpace: 'nowrap', marginLeft: '1rem'}}>
       {
        insurers?.map((insurer, index) =>
         {
          const insurerData = insurersData?.find(c => c.insurer.id === insurer.id)
          return (
            insurerData &&
          <div key={index} style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            alignItems: 'left',
            width: '240px',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            marginBottom: '10px',
          }}>
            <h3 style={{fontSize: '14px', fontWeight: "bold", marginBottom: '4px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{ insurerData.insurer?.name}</h3>
            {
              insurerData.insurer?.code === InsurersCodeEnum.Porto ||  insurerData.insurer?.code === InsurersCodeEnum.Too ?
                <p style={{fontSize: '14px'}}>Taxa, cobertura e LMI: <br/>  &nbsp; &nbsp; Por Oferta Aprovada </p>
              :

              insurerData.insurer?.code === InsurersCodeEnum.PortoEssencial ?
              <p style={{fontSize: '14px', }}>Cobertura: {insurerData.assurance === AnalysisAssuranceEnum.complete ? 'Completa' : 'Simples'}<br/>
                LMI e Taxas: Por Oferta Aprovada </p>
              :
              <p style={{fontSize: '14px', }}>Cobertura: {insurerData.assurance === AnalysisAssuranceEnum.complete ? 'Completa' : 'Simples'}; {insurerData.insurer.code === InsurersCodeEnum.Pottencial ? <span>Multiplo: {insurerData.multiple}</span>  : (insurerData?.lmi > 0 && <span>LMI: {insurerData.lmi}x</span>)  }<br/>
              {
                parseFloat(insurerData.pf_comercial_tax) > 0 && <span> Taxa Comercial: {formatAmount((parseFloat(insurerData.pf_comercial_tax) * 100).toFixed(2))}% <br/></span>
              }

              {
                parseFloat(insurerData.pf_residential_tax) > 0 && <span> Taxa Residêncial: {formatAmount((parseFloat(insurerData.pf_residential_tax) * 100).toFixed(2))}% <br/></span>
              }
              </p>
            }

          </div>)}
        )
       }
        </div>
      </Grid.Row>
    </>
  )
}
