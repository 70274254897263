import React, { useState, useEffect } from 'react';
import {
  Table,
  Dimmer,
  Segment,
  Loader,
  Menu,
  Icon,
  Button,
  Confirm
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import IconButton from '../../components/IconButton';
import { maskCpf, maskCnpj } from '../../services/masks';
import { sortBondInsurances } from '../../services/bond-insurance';
import { iRootState, iRootDispatch } from '../../store';
import { DateToStringDate, DateToStringTime } from '../../services/date';
import { BondInsuranceDetails } from './components/BondInsuranceDetails';
import { BondInsuranceAnalysisStatusEnum } from '../../enums/bond-insurance-analysis-status.enum';
import { round } from '../../util';
import { PieChart } from 'react-minimal-pie-chart';
import AnalysisProgressModal from './components/AnalysisProgressModal';
import {
  BondInsuranceAnalysisForGetBondInsurancesResponseDTO,
  BondInsuranceForGetBondInsurancesResponseDTO
} from '../../dtos/bond-insurance-list/get-bond-insurances-response.dto';
import { ClickableTableCell, SelectableTableRow } from '../../styles';
import { GetExpiredBondInsurancesResponseDTO } from '../../dtos/expired-bond-insurance/get-expired-bond-insurances-response.dto';
import { useHistory } from 'react-router-dom';

const ProposalsTableComponent = styled.div`
  margin-top: 1rem;
`;

const Header = styled(Table.Header)`
  background-color: #e1e8ed;
`;

const TableWrapper = styled.div`
  font-size: 0.85em;
  font-weight: bold;

  .ui.table {
    border: none;
    text-align: center;
    color: #364445;
  }
`;

const NotFoundWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #364445;
  font-size: 1.2em;
`;

const CopyWarningContainer = styled.div`
  padding: 1.25rem 1.5rem;
`;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapState = (state: iRootState) => ({
  user: state.user,
  availableBrokersAndEstates: state.availableBrokersAndEstates
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatch = (dispatch: iRootDispatch) => ({});

type Props = {
  bondInsurances: BondInsuranceForGetBondInsurancesResponseDTO[];
  expiredBondInsurances: GetExpiredBondInsurancesResponseDTO[];
  isLoading: boolean;
  getData: Function;
  currentPage: number;
  setCurrentPage: Function;
  dataCount: number;
} & ReturnType<typeof mapState>;

type MatchAnalysisProgressBondInsurance<T> = T & {
  bond_insurance_analysis: BondInsuranceAnalysisForGetBondInsurancesResponseDTO[];
};

const CopyWarningContent = () => {
  return (
    <CopyWarningContainer>
      <p>Essa análise será recuperada e retornará ao menu de Gerenciar análises.</p>
      <p>
        Ao prosseguir, os dados desta análise serão reaproveitados para uma nova análise a
        ser solicitada junto as seguradoras parceiras. Ao confirmar, você deverá revisar e
        editar todos os dados para concluir a nova análise.
      </p>
    </CopyWarningContainer>
  );
};

const CanceledBondInsurancesTable = (props: Props): JSX.Element => {
  const {
    bondInsurances,
    expiredBondInsurances,
    isLoading,
    getData,
    user,
    currentPage,
    setCurrentPage,
    dataCount
  } = props;
  const [listData, setListData] = useState<
    BondInsuranceForGetBondInsurancesResponseDTO[]
  >([]);
  const history = useHistory();
  const [ascendingOrder, setAscendingOrder] = useState<boolean>(true);
  const [indexOpen, setIndexOpen] = useState<number>(-1);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>('');

  const lastPage = Math.floor(dataCount / 10) + 1;

  useEffect(() => {
    setListData(bondInsurances);
  }, [bondInsurances]);

  function toggleAscending(): void {
    const toggledAscending = !ascendingOrder;
    setAscendingOrder(toggledAscending);
    const orderedData = sortBondInsurances(bondInsurances, toggledAscending);
    setListData(orderedData);
  }

  function analysisProgress<T>(bondInsurance: MatchAnalysisProgressBondInsurance<T>) {
    let chartData;
    let progress: number;
    if (
      bondInsurance.bond_insurance_analysis &&
      bondInsurance.bond_insurance_analysis.length > 0
    ) {
      const analysisList = bondInsurance.bond_insurance_analysis;
      const proposal = analysisList.filter(
        a => a.status === BondInsuranceAnalysisStatusEnum.proposal
      ).length;
      const waiting = analysisList.filter(
        a => a.status === BondInsuranceAnalysisStatusEnum.waiting
      ).length;
      const pending = analysisList.filter(
        a => a.status === BondInsuranceAnalysisStatusEnum.pending
      ).length;
      const analysis = analysisList.filter(
        a => a.status === BondInsuranceAnalysisStatusEnum.analysis
      ).length;
      const rejected = analysisList.filter(
        a => a.status === BondInsuranceAnalysisStatusEnum.rejected
      ).length;
      const biometry = analysisList.filter(
        a => a.status === BondInsuranceAnalysisStatusEnum.biometry
      ).length;
      const approved = analysisList.filter(
        a => a.status === BondInsuranceAnalysisStatusEnum.approved
      ).length;
      const error = analysisList.filter(
        a => a.status === BondInsuranceAnalysisStatusEnum.error
      ).length;
      chartData = [
        {
          title: 'PENDENCIADO',
          value: round((pending / analysisList.length) * 100, 2),
          color: '#FBBD08'
        },
        {
          title: 'EM PROPOSTA',
          value: round((proposal / analysisList.length) * 100, 2),
          color: '#710193'
        },
        {
          title: 'EM ANÁLISE',
          value: round((analysis / analysisList.length) * 100, 2),
          color: '#2185D0'
        },
        {
          title: 'REJEITADA',
          value: round((rejected / analysisList.length) * 100, 2),
          color: '#DB2828'
        },
        {
          title: 'BIOMETRIA',
          value: round((biometry / analysisList.length) * 100, 2),
          color: '#FE9A76'
        },
        {
          title: 'APROVADA',
          value: round((approved / analysisList.length) * 100, 2),
          color: '#21BA45'
        },
        {
          title: 'ERRO',
          value: round((error / analysisList.length) * 100, 2),
          color: 'black'
        },
        {
          title: 'EM ESPERA',
          value: round((waiting / analysisList.length) * 100, 2),
          color: 'grey'
        }
      ];
      progress = round(((approved + rejected) / analysisList.length) * 100, 0);
    } else {
      chartData = [{ title: 'EM ESPERA', value: 100, color: 'grey' }];
      progress = 0;
    }

    return (
      <div style={{ width: '100%' }}>
        <PieChart
          data={chartData}
          label={({ dataEntry }) => `${progress} %`}
          labelPosition={0}
          lineWidth={25}
          animate
          labelStyle={{ fontSize: '2.2em', fill: '#b8bbbc' }}
          style={{ height: '35px', padding: '0px', cursor: 'pointer', width: '100%' }}
        />
      </div>
    );
  }

  function handleCopyAnalysis(id: string) {
    history.push(`/admin/bond-insurance/${id}`);
  }

  return (
    <ProposalsTableComponent>
      {isConfirmationModalOpen && selectedItem !== '' && (
        <Confirm
          header="Recuperar análise"
          content={<CopyWarningContent />}
          cancelButton="Cancelar"
          confirmButton="Confirmar"
          open={isConfirmationModalOpen}
          onCancel={() => setIsConfirmationModalOpen(false)}
          onConfirm={() => handleCopyAnalysis(selectedItem)}
        />
      )}
      <Dimmer.Dimmable as={Segment} dimmed={isLoading}>
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
        {listData && listData.length > 0 ? (
          <TableWrapper>
            <Table>
              <Header>
                <Table.Row>
                  <Table.HeaderCell width={2}>
                    EXPIRADA EM
                    <IconButton
                      name={
                        ascendingOrder
                          ? 'long arrow alternate up'
                          : 'long arrow alternate down'
                      }
                      color="blue"
                      onClick={toggleAscending}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2}>IMOBILIÁRIA</Table.HeaderCell>
                  <Table.HeaderCell width={2}>DOC. LOCATÁRIO</Table.HeaderCell>
                  <Table.HeaderCell width={2}>LOCATÁRIO</Table.HeaderCell>
                  <Table.HeaderCell width={2}>ENDEREÇO</Table.HeaderCell>
                  <Table.HeaderCell width={3}>GESTOR DE CONTAS</Table.HeaderCell>
                  <Table.HeaderCell width={2}>STATUS</Table.HeaderCell>
                  <Table.HeaderCell width={2}>AÇÕES</Table.HeaderCell>
                </Table.Row>
              </Header>
              <Table.Body>
                {listData.map((bondInsurance, index) => {
                  const ableToRevertExpire = bondInsurance.bond_insurance_analysis.some(analysis => analysis.status === 'APPROVED');
                  const initialParcel = bondInsurance.bond_insurance_analysis.map(
                    analysis => {
                      if (analysis.value === null) {
                        return 0;
                      }

                      const value =
                        typeof analysis.value === 'string'
                          ? parseFloat(analysis.value)
                          : analysis.value;
                      return value;
                    }
                  );
                  const tenantDocument = bondInsurance.natural_tenant
                    ? maskCpf(bondInsurance.natural_tenant.main_tenant_cpf)
                    : maskCnpj(bondInsurance.legal_tenant.document);
                  const tenantName = bondInsurance.natural_tenant
                    ? bondInsurance.natural_tenant.main_tenant_name
                    : bondInsurance.legal_tenant.company;

                  return (
                    /**/
                    <>
                      <BondInsuranceDetails
                        user={user}
                        bondInsurance={bondInsurance}
                        isOpen={index === indexOpen}
                        onClose={() => setIndexOpen(-1)}
                        insurersData={
                          props.availableBrokersAndEstates.estates.find(
                            c => c.value.id === bondInsurance.estate?.id
                          )?.value?.insurer_data
                        }
                        insurers={
                          props.availableBrokersAndEstates.estates.find(
                            c => c.value.id === bondInsurance.estate?.id
                          )?.value?.insurers
                        }
                      />

                      <SelectableTableRow
                        key={bondInsurance.id}
                        style={{ cursor: 'pointer' }}
                      >
                        <ClickableTableCell onClick={() => setIndexOpen(index)}>
                          {`${DateToStringDate(
                            bondInsurance.expired_at || new Date(0, 0, 0)
                          )}`}
                          <br />
                          {DateToStringTime(
                            bondInsurance.expired_at || new Date(0, 0, 0)
                          )}
                        </ClickableTableCell>
                        <ClickableTableCell onClick={() => setIndexOpen(index)}>
                          {bondInsurance.estate?.name}
                        </ClickableTableCell>
                        <ClickableTableCell onClick={() => setIndexOpen(index)}>
                          {tenantDocument}
                        </ClickableTableCell>
                        <ClickableTableCell onClick={() => setIndexOpen(index)}>
                          {tenantName}
                        </ClickableTableCell>
                        <ClickableTableCell onClick={() => setIndexOpen(index)}>
                          {bondInsurance.property.street}
                          <br />(
                          {bondInsurance.purpose === 'RESIDENTIAL'
                            ? 'Residencial'
                            : 'Comercial'}
                          )
                        </ClickableTableCell>
                        <ClickableTableCell onClick={() => setIndexOpen(index)}>
                          {bondInsurance.estate?.user.name}
                        </ClickableTableCell>
                        <ClickableTableCell>
                          {
                            <AnalysisProgressModal
                              user={user}
                              positive
                              header="Detalhes das Análises Expiradas"
                              initialParcel={initialParcel}
                              bondInsurance={bondInsurance}
                              bondInsuranceId={bondInsurance.id}
                              property={bondInsurance.property}
                              estate={bondInsurance.estate}
                              analysisDetails={bondInsurance.bond_insurance_analysis}
                              validityMonths={bondInsurance.property.validity}
                              naturalTentant={bondInsurance.natural_tenant}
                              totalAmount={bondInsurance.property.total_amount}
                              rentValue={parseFloat(
                                `${bondInsurance.property.rent_value}`
                              )}
                              calculationMode={bondInsurance.estate!.calculationMode}
                              trigger={analysisProgress(bondInsurance)}
                              callback={async (): Promise<void> => {
                                await getData();
                              }}
                            />
                          }
                        </ClickableTableCell>
                        <ClickableTableCell>
                          <Button
                            primary
                            icon="undo"
                            onClick={() => {
                              setSelectedItem(bondInsurance.id);
                              setIsConfirmationModalOpen(true);
                            }}
                            disabled={!ableToRevertExpire}
                          />
                        </ClickableTableCell>
                      </SelectableTableRow>
                    </>
                  );
                })}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="17">
                    <Menu floated="right" pagination>
                      <Menu.Item>
                        <p>{`${currentPage * 10 - 9} - ${
                          currentPage * 10
                        }  de ${dataCount}`}</p>
                      </Menu.Item>
                      <Menu.Item
                        disabled={currentPage === 1}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(1)}
                      >
                        <Icon name="angle double left" />
                      </Menu.Item>
                      <Menu.Item
                        disabled={currentPage === 1}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item as="a">{currentPage}</Menu.Item>
                      <Menu.Item
                        disabled={currentPage === lastPage || bondInsurances.length < 10}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                      <Menu.Item as="a" icon>
                        <Icon
                          disabled={
                            currentPage === lastPage || bondInsurances.length < 10
                          }
                          name="angle double right"
                          onClick={() => setCurrentPage(lastPage)}
                        />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </TableWrapper>
        ) : (
          <NotFoundWrapper>
            <p>Análises não encontradas</p>
          </NotFoundWrapper>
        )}
      </Dimmer.Dimmable>
    </ProposalsTableComponent>
  );
};

export default connect(mapState, mapDispatch)(CanceledBondInsurancesTable);
