import Swal from 'sweetalert2'

import React, { useEffect, useState } from "react";
import {
  Button,
  Header,
  Icon,
  Modal,
  Step,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import { post } from "../../../../../../../services/request";
import {
  CancelModalProps,
} from "../../../types/ModalHireProposalProps";
import { CancellationFormik } from "../Formik/CancellationFormFormik";
import {
  dangerNotification,
  successNotification,
} from "../../../../../../../services/notification";
import withReactContent from 'sweetalert2-react-content'
import { hasPermission } from '../../../../../../../components/AuthorizationContainer';
import { ActionEnum } from '../../../../../../../enums/authz-action.enum';
import { FeatureEnum } from '../../../../../../../enums/authz-feature.enum';

const CancelModal = ({
  username,
  closeModal,
  setOpen,
  bondInsurance,
  callback
}: CancelModalProps) => {
  const [openCancellationModal, setOpenCancellationModal] = useState(false);
  const [flagSubmitFrom, setFlagSubmitFrom] = useState(false);
  const [stepOfCancelModal, setStepOfCancelModal] = useState(
    "cancellation"
  );
  const [loading, setLoading] = useState(false);

  const [userCanCancel, setUserCanCancel] = useState(false);

  useEffect(() => {
    const has = hasPermission(ActionEnum.cancel, FeatureEnum.analysis);
    setUserCanCancel(has);
  }, []);

  const MySwal = withReactContent(Swal)

  async function handleSubmit(payload: any) {
    console.log('PAYLOAD: ', payload);
    try {
      setLoading(true);
      const response = await post("/canceled-bond-insurances", payload);
      successNotification("Sucesso", "Cancelamento concluído com sucesso.");
    } catch (err) {
      dangerNotification("Erro", "Erro ao efetuar solicitação.", 4000);
      console.log(err);
    } finally {
      callback()
      setLoading(false);
      setFlagSubmitFrom(false);
      closeModal();
      setOpenCancellationModal(false);
    }
  }

  useEffect(() => {
    setOpen && setOpen(openCancellationModal)
  }, [openCancellationModal])

  return (
    <div style={{ outline: 'none' }}>
      <Modal
        closeIcon
        open={openCancellationModal}
        onClose={
          () => setOpenCancellationModal(false)
        }
        trigger={
          <Button
            className="ui button"
            disabled={!userCanCancel}
          >
            <Icon name={"trash"} />
            Cancelar Análise
          </Button>
        }
        onOpen={() => MySwal.fire({
          title: `Deseja prosseguir com o cancelamento da Análise de Seguro Fiança  ?`,
          text: "Importante: Após o cancelamento da análise, os dados ficarão disponíveis para consulta, mas a análise não poderá ser reativada.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, prosseguir cancelamento!',
          cancelButtonText: 'Desistir'
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              const { value: checked } = await MySwal.fire({
                title: 'Declaro que: ',
                html:
                  '<div style="display:flex;flex-direction:row;align-items:center;justify-content:space-around;text-align:justify;padding-right:30px;"><input id="swal-input1" type="checkbox" class="swal2-input"> <label for="swal-input1">Estou ciente que com este cancelamento não poderei reativar a análise.</label> </div>' +
                  '<div style="display:flex;flex-direction:row;align-items:center;justify-content:space-around;text-align:justify;padding-right:30px;"><input id="swal-input2" type="checkbox" class="swal2-input"> <label for="swal-input2">Estou ciente que após cancelado não poderei prosseguir com nenhuma contratação, sendo necessário fazer uma nova análise caso preciso for.</label> </div>',
                focusConfirm: false,
                confirmButtonText:
                  'Ciente e prosseguir',
                denyButtonText: 'Cancelar',
                preConfirm: () => {
                  return [
                    (document.getElementById('swal-input1') as HTMLInputElement).checked ? 'true' : 'false',
                    (document.getElementById('swal-input2') as HTMLInputElement)?.checked ? 'true' : 'false',
                  ]
                },
              })
              console.log(checked)
              if (Array.isArray(checked) && checked[0] === 'true' && checked[1] === 'true') {
                setOpenCancellationModal(true)
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Para continuar, deve aceitar os termos!',
                })
              }
            })()
          }
        })}
      >
        <Header icon="file alternate outline" content={`Cancelamento de Análise`} />
        <Modal.Content>
          <Dimmer active={loading}>
            <Loader indeterminate> Salvando proposta... </Loader>
          </Dimmer>
          <Step.Group>
            <Step
              onClick={() => setStepOfCancelModal("cancellation")}
              active={stepOfCancelModal === "cancellation"}
              title={`Cancelamento`}
              description=""
            />
          </Step.Group>
          {stepOfCancelModal === "cancellation" && (
            <CancellationFormik
              flagSubmit={flagSubmitFrom}
              setFlagSubmit={setFlagSubmitFrom}
              callbackSubmit={handleSubmit}
              bondInsurance={bondInsurance}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={() => setOpenCancellationModal(false)}>
            <Icon name="remove" /> Sair
          </Button>
          <Button color="green" onClick={() => setFlagSubmitFrom(true)}>
            <Icon name="checkmark" />
            Confirmar
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
export default CancelModal;
