import React, { useRef, useEffect, useState } from 'react';
import { Dimmer, Dropdown, Loader, Select } from 'semantic-ui-react';
import { File as FileIcon } from '@styled-icons/boxicons-regular';
import { RemoveCircle as RemoveIcon } from '@styled-icons/ionicons-outline';
import * as S from './styles';
import { deleteFilesLinkAxios, postFileAndGetPreSignAxios } from '../../services/file';
import {
  dangerNotification,
  successNotification,
  warningNotification
} from '../../services/notification';
import * as Storage from '../../services/storage';
import { UserRolesEnum } from '../../enums/user-roles.enum';
import { DateToStringDate, DateToStringDateTime } from '../../services/date';
import { Link } from 'react-router-dom';
import AuthorizationContainer from '../AuthorizationContainer';
import { ActionEnum } from '../../enums/authz-action.enum';
import { FeatureEnum } from '../../enums/authz-feature.enum';

type FileLineProps = {
  key: number;
  file: FileIdentifier;
};

export type FileAttachmentProps = {
  createdAt: Date;
  fileLink: string;
  option: string;
  id: string;
  tag?: {
    name: string;
    tag: string;
    date?: Date;
  };
};

type FileIdentifier = {
  file: File;
  name: string;
};

type fileTypeProps = {
  name: string;
  key: string;
  required?: boolean;
};

export type FileUploaderProps = {
  tableName: string;
  registerId: string;
  controlSubmitFiles?: boolean;
  minimumAmountOfFiles?: number;
  fileIdentifiers?: fileTypeProps[];
  filesAttachs?: FileAttachmentProps[];
  callback: (_: any) => void;
  brokerOnly?: boolean;
  disabled?: boolean;
};

const FileUploader = ({
  tableName,
  registerId,
  controlSubmitFiles = false,
  minimumAmountOfFiles = 0,
  filesAttachs,
  fileIdentifiers = [
    {
      name: 'Apólice',
      key: 'POLICY',
      required: false
    },
    {
      name: 'Apólice Seguro Fiança',
      key: 'BAIL_INSURANCE_POLICY'
    },
    {
      name: 'Boleto Devolutivo',
      key: 'RETURN_BILL'
    },
    {
      name: 'Carta de Aprovação Porto Essencial',
      key: 'PORTO_ESSENCIAL_APROVAL_LETTER',
      required: false
    },
    {
      name: 'Carta de aprovação condições',
      key: 'PORTO_ESSENCIAL_BUDGETS_OFFERS',
      required: false
    },
    {
      name: 'Comprovante de pagamento',
      key: 'PAYMENT_VOUCHER'
    },
    {
      name: 'Contrato de locação',
      key: 'RENT_CONTRACT',
      required: false
    },
    {
      name: 'Cotação Porto Essencial',
      key: 'PORTO_ESSENCIAL_APROVAL_LETTER_BUDGETS',
      required: false
    },
    {
      name: 'Cotação condições',
      key: 'PORTO_ESSENCIAL_APPROVAL_OFFERS',
      required: false
    },
    {
      name: 'Endosso de cancelamento',
      key: 'CANCELATION_ENDORSEMENT'
    },
    {
      name: 'Informativo de sinistro',
      key: 'CLAIM_INFORMATION'
    },
    {
      name: 'Laudo de vistoria',
      key: 'SURVEY_REPORT',
      required: false
    },
    {
      name: 'Outros',
      key: 'OTHER',
      required: false
    },
    {
      name: 'Planilha de danos',
      key: 'DAMAGE_WORKSHEET'
    },
    {
      name: 'Planilha de pintura',
      key: 'PAINTING_WORKSHEET'
    },
    {
      name: 'Termo cancelamento de troca de seguradora',
      key: 'INSURANCE_CHANGE_TERM'
    },
    {
      name: 'Termo cancelamento duplicidade de apólice',
      key: 'DUPLICATION_POLICY_TERM'
    },
    {
      name: 'Termo cancelamento troca de garantia',
      key: 'CHANGE_WARRANTY_TERM'
    },
    {
      name: 'Termo compra do imóvel',
      key: 'PROPERTY_PURCHASE_TERM'
    },
    {
      name: 'Termo de antecipação de locação',
      key: 'LEASE_ADVANCE_TERM'
    },
    {
      name: 'Termo de desistência de locação',
      key: 'LEASE_WITHDRAWL_TERM'
    },
    {
      name: 'Termo de entrega das chaves',
      key: 'DELIVERY_KEYS_TERM',
      required: true
    },
    {
      name: 'Termo de rescisão',
      key: 'TERMINATION_TERM',
      required: true
    },
    {
      name: 'Termo de troca de locatário',
      key: 'TENANT_CHANGE_TERM'
    }
  ],
  callback,
  brokerOnly,
  disabled = false
}: FileUploaderProps) => {
  const [files, setFiles] = useState<FileIdentifier[]>();
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [attachedFiles, setAttachedFiles] = useState<FileAttachmentProps[]>(
    filesAttachs || []
  );
  const fileOptions = fileIdentifiers.map(fileIdentifier => ({
    key: fileIdentifier.key,
    value: fileIdentifier.name,
    text: fileIdentifier.name
  }));

  useEffect(() => {
    if (filesAttachs) {
      setAttachedFiles(filesAttachs);
    }
  }, [filesAttachs]);

  let ref: any = useRef(null);

  function handleAddFile(fileList: FileList) {
    if (!fileList) {
      return;
    }
    const array = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (file.size > 50 * 1024 * 1024) {
        warningNotification(
          'Limite excedido',
          `O arquvio ${file.name.substr(
            0,
            10
          )} é muito grande. Nosso limite interno é de 50MB.`
        );
        return;
      }

      array.push({ file, name: 'OTHER' });
    }
    setFiles((files || []).concat(array));
  }

  function handleRemoveFile(toRemove: FileIdentifier) {
    const array = files?.filter(fileIdentified => fileIdentified !== toRemove);
    setFiles(array);
  }

  useEffect(() => {
    async function sumbitFiles() {
      if (!files) return;
      if ((files?.length || 0) < minimumAmountOfFiles) {
        warningNotification(
          'Faltam arquivos',
          'Para submeter esse formulário,' +
            ' você precisa submeter pelo menos ' +
            minimumAmountOfFiles +
            ' arquivos.'
        );
        return;
      }

      const requireds = fileIdentifiers.filter(fi => fi.required === true);
      const intersection = requireds.filter(
        r =>
          files.filter(c => fileIdentifiers.find(d => d.name === c.name)?.key === r.name)
            .length > 0
      );
      if (intersection.length > 0) {
        intersection.forEach(req => {
          warningNotification('Ops', `Por favor, anexe um arquivo do tipo: ${req.name}`);
        });
      }

      try {
        setLoading(true);
        const responses = [];
        for (const file of files) {
          const { data: response } = await postFileAndGetPreSignAxios({
            filename: file.file.name
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/[`~!@#$%^&*()_|+-=?;:'",<>{}[]\/]/gi, ''),
            contentType: file.file.type,
            referencedTableName: tableName,
            referencedTableId: registerId,
            name: fileIdentifiers.find(fi => fi.name === file.name)?.key || 'OTHER'
          });

          const fileToBlob = async (theFile: File) =>
            new Blob([new Uint8Array(await theFile.arrayBuffer())], {
              type: theFile.type
            });
          const blob = await fileToBlob(file.file);

          await fetch(response.uploadURL, {
            method: 'PUT',
            body: blob
          });
          responses.push({ id: response.id, contextType: file.name });
        }
        callback(responses);
      } catch (err) {
        callback({ error: err });
        dangerNotification(
          'Erro ao efetuar upload',
          'Ocorreu um erro ao tentar realizar o upload dos arquivos. Tente novamente'
        );
        console.log('ERRO DE ARQUIVO', err);
      } finally {
        setLoading(false);
      }
    }
    controlSubmitFiles && sumbitFiles();
  }, [files, minimumAmountOfFiles, controlSubmitFiles]);

  const Item = ({ key, file: fileIdentified }: FileLineProps) => {
    function handleChange(value: string) {
      const arrayFiles = files?.map(file =>
        file !== fileIdentified ? file : { ...fileIdentified, name: value }
      );
      setFiles(arrayFiles);
    }
    return (
      <S.FileLine key={key}>
        <S.Icon>
          <FileIcon color="blue" />
        </S.Icon>
        <p>{fileIdentified.file.name}</p>
        <p>{(fileIdentified.file.size / 1024 / 1024).toFixed(2)} MB</p>
        <S.SelectDiv>
          <Dropdown
            search
            clearable
            selection
            value={firstLoad ? undefined : fileIdentified.name || undefined}
            options={fileOptions}
            placeholder={'Selecionar...'}
            onChange={(e, { value }) => {
              setFirstLoad(false);
              handleChange(value as string);
            }}
          />
        </S.SelectDiv>
        {/* {(userRole === UserRolesEnum.brokerAdmin ||
          userRole === UserRolesEnum.brokerAnalyst) && ( */}
        {!disabled && (
          <AuthorizationContainer action={ActionEnum.delete} feature={FeatureEnum.files}>
            <S.Icon onClick={() => handleRemoveFile(fileIdentified)}>
              <RemoveIcon color="red" />
            </S.Icon>
          </AuthorizationContainer>
        )}
        {/* )} */}
      </S.FileLine>
    );
  };

  async function SoftDeleteFile(id: string) {
    try {
      if (window.confirm('Deseja deletar este arquivo?')) {
        setLoading(true);
        await deleteFilesLinkAxios(id);
        const array = attachedFiles?.filter(fileIdentified => fileIdentified.id !== id);
        setAttachedFiles(array);
        successNotification('Sucesso!', 'Arquivo deletado com sucesso!');
      }
    } catch (err) {
      console.log(err);
      warningNotification('Ops...', 'Erro ao deletar arquivo.');
    } finally {
      setLoading(false);
    }
  }

  const ItemAttachment = ({ fileLink, option, id, createdAt }: FileAttachmentProps) => {
    return (
      <S.FileLine key={id}>
        <Link to={{ pathname: fileLink }} target="_blank" rel="noopener noreferrer">
          <S.Icon>
            <FileIcon color="green" />
          </S.Icon>
        </Link>
        <Link to={{ pathname: fileLink }} target="_blank" rel="noopener noreferrer">
          <p>{fileOptions.find(c => c.key === option)?.value || option}</p>
        </Link>
        <p> {DateToStringDateTime(createdAt)}</p>
        <S.Select placeholder="Identificacao do documento">
          {fileOptions.map(fileType => (
            <option
              value={fileType.key}
              key={fileType.key}
              selected={fileType.key === option}
              disabled
            >
              {' '}
              {fileType.value}{' '}
            </option>
          ))}
        </S.Select>
        {/* {(userRole === UserRolesEnum.brokerAdmin ||
          userRole === UserRolesEnum.brokerAnalyst) && ( */}
        {!disabled && (
          <AuthorizationContainer action={ActionEnum.delete} feature={FeatureEnum.files}>
            <S.Icon onClick={() => SoftDeleteFile(id)}>
              <RemoveIcon color="red" />
            </S.Icon>
          </AuthorizationContainer>
        )}
        {/* )} */}
      </S.FileLine>
    );
  };


  const renderUniqueTags = () => {
    const uniqueTagsArray = Array.from(new Set(attachedFiles.map(file => file?.tag?.name).filter(Boolean)));
    return uniqueTagsArray.map((tagName, index) => {
      const filesWithTag = attachedFiles.filter(file => file?.tag?.name === tagName);
      return (
        <S.FileWrapper key={index}>
          <S.LabelSavedFiles>Arquivos salvos em: {tagName}</S.LabelSavedFiles>
          {filesWithTag.map(file => (
            <ItemAttachment
              key={file.id}
              fileLink={file.fileLink}
              option={file.option}
              id={file.id}
              createdAt={file.createdAt}
            />
          ))}
        </S.FileWrapper>
      );
    });
  };

  const renderFilesWithoutTags = () => (
    <S.FileWrapper>
      <S.LabelSavedFiles>Arquivos salvos</S.LabelSavedFiles>
      {attachedFiles.map(file => (
        <ItemAttachment
          key={file.id}
          fileLink={file.fileLink}
          option={file.option}
          id={file.id}
          createdAt={file.createdAt}
        />
      ))}
    </S.FileWrapper>
  );

  return (
    <S.Wrapper>
      {attachedFiles?.length > 0 && (attachedFiles[0].tag ? renderUniqueTags() : renderFilesWithoutTags())}

      {files && files?.length > 0 && (
        <S.FileWrapper>
          <S.LabelSavedFiles>Arquivos anexados</S.LabelSavedFiles>
          {files.map((file, i) => (
            <Item key={i} file={file} />
          ))}
        </S.FileWrapper>
      )}

      {brokerOnly && !disabled && (
        <div>
          <Dimmer active={loading}>
            <Loader indeterminate>Aguarde...</Loader>
          </Dimmer>
          <AuthorizationContainer action={ActionEnum.upload} feature={FeatureEnum.files}>
            <S.InputButton type="button" value="Adicionar Arquivo" onClick={() => ref && ref.click()} />
            <S.InputFile
              type="file"
              name="file"
              disabled={disabled}
              multiple
              ref={input => {
                ref = input;
              }}
              onChange={e => e.target.files && handleAddFile(e.target.files)}
              accept="image/png, image/jpeg, image/webp, application/msword, application/pdf, application/vnd.oasis.opendocument.text"
            />
          </AuthorizationContainer>
        </div>
      )}
    </S.Wrapper>
  );
};
export default FileUploader;
