import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Quantity = styled.label<{ isNegative?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  color: ${props => (props.isNegative ? '#DE575F' : '#2185d0')};
  font-weight: bold;
`;

export const Label = styled.span`
  font-size: 14px;
  color: #a3a3a3;
`;

export const MainContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  flex-direction: column;
`;
