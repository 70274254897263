/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Grid,
  Input,
  InputOnChangeData,
  Radio,
  StepProps,
  Modal,
  Button,
  Popup,
  Icon
} from 'semantic-ui-react';
import { Field } from 'formik';
import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../../../resources/css/styles.css';
import { getErrorFormik } from '../../../../../../services/errors';
import SectionLabel from '../../../../../../components/SectionLabel';
import { FieldLabel } from '../../../../../../styles';
import {
  DivErrorMessage,
  FormikErrorMessage
} from '../../../../../../components/ErrorMessage';
import { maskCpf, maskPhoneNumber } from '../../../../../../services/masks';
import { genderOptions, maritalStatusOptions } from '../../../../types';
import Dropdown from '../../../../../../components/Dropdown';
import { NaturalTenant } from '../../../../../../types/NaturalTenant';
import AppMaskedInput from '../../../../../../components/MaskedInput';
import CustomDatePicker from '../../../../../../components/CustomDatePicker';
import { PoliticallyExposedPersonEnum } from '../../../../../../enums/politically-exposed-person.enum';
import styled from 'styled-components';

const Advice = styled.div`
  border: 1px solid red;
  border-radius: 5px;
  color: red;
  background-color: transparent;
  p {
    color: red;
    padding: 8px 12px;
  }
`;

const SECTION_NAME = 'tenant_info';

export const PersonalInformationsSection = (props: StepProps): ReactElement => {
  const {
    errors,
    touched,
    handleInputChange,
    handleDateChange,
    values,
    setFieldTouched,
    setFieldValue
  } = props;

  const tenant_info = values.tenant_info as NaturalTenant;
  const validateErros = (field: string) =>
    getErrorFormik(errors, touched, SECTION_NAME, field);
  const [hasSocialName, setHasSocialName] = useState<boolean>(false);

  return (
    <Grid>
      <SectionLabel text="INFORMAÇÕES PESSOAIS" />
      <Grid.Row>
        <Grid.Column width={4}>
          <FieldLabel>CPF*</FieldLabel>
          <Field
            id={`${SECTION_NAME}.main_tenant_cpf`}
            name={`${SECTION_NAME}.main_tenant_cpf`}
            value={tenant_info.main_tenant_cpf}
            error={validateErros('main_tenant_cpf') || tenant_info?.has_restrictions}
            component={Input}
            disabled
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.main_tenant_cpf`} />
          {tenant_info?.has_restrictions && (
            <DivErrorMessage>
              *O locatário já possui uma análise em andamento.
            </DivErrorMessage>
          )}
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={4}>
          <FieldLabel>NOME DO LOCATÁRIO*</FieldLabel>
          <Field
            id={`${SECTION_NAME}.main_tenant_name`}
            autoCorrect="off"
            autoCapitalize="none"
            spellCheck="false"
            autoComplete="new-password"
            name={`${SECTION_NAME}.main_tenant_name`}
            value={tenant_info.main_tenant_name}
            onChange={handleInputChange}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.main_tenant_name`, true)}
            customInput={<Input />}
            error={validateErros('main_tenant_name')}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.main_tenant_name`} />
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>GÊNERO* </FieldLabel>
          <Field
            id={`${SECTION_NAME}.main_tenant_gender`}
            name={`${SECTION_NAME}.main_tenant_gender`}
            value={tenant_info.main_tenant_gender}
            completeValue={true}
            placeholder={'Selecionar...'}
            options={genderOptions}
            component={Dropdown}
            onChange={handleInputChange}
            onClose={() => setFieldTouched(`${SECTION_NAME}.main_tenant_gender`, true)}
            error={validateErros('main_tenant_gender')}
            fluid
          />
          <FormikErrorMessage
            component="div"
            name={`${SECTION_NAME}.main_tenant_gender`}
          />
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>DATA DE NASCIMENTO* </FieldLabel>
          <Field
            id={`${SECTION_NAME}.main_tenant_birth_date`}
            name={`${SECTION_NAME}.main_tenant_birth_date`}
            error={errors.tenant_info?.main_tenant_birth_date}
            selected={tenant_info.main_tenant_birth_date}
            component={CustomDatePicker}
            customInput={
              <AppMaskedInput
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/
                ]}
              />
            }
            dateFormat={'dd/MM/yyyy'}
            onChange={(date: Date, e: SyntheticEvent) => {
              handleDateChange(`${SECTION_NAME}.main_tenant_birth_date`, date, e);
            }}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.main_tenant_birth_date`, true)}
            className={validateErros('main_tenant_birth_date')}
          />
          <FormikErrorMessage
            component="div"
            name={`${SECTION_NAME}.main_tenant_birth_date`}
          />
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>NOME DA MÃE*</FieldLabel>
          <Field
            autoCorrect="off"
            autoCapitalize="none"
            spellCheck="false"
            autoComplete="new-password"
            id={`${SECTION_NAME}.main_tenant_mother_name`}
            name={`${SECTION_NAME}.main_tenant_mother_name`}
            value={tenant_info.main_tenant_mother_name}
            onChange={handleInputChange}
            onBlur={() =>
              setFieldTouched(`${SECTION_NAME}.main_tenant_mother_name`, true)
            }
            error={validateErros('main_tenant_mother_name')}
            component={Input}
            fluid
          />
          <FormikErrorMessage
            component="div"
            name={`${SECTION_NAME}.main_tenant_mother_name`}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={4}>
          <FieldLabel>
            EMAIL*
            <Popup
              content={
                'Atenção, informar o e-mail correto do locatário para que ele receba mensagens da Seguradora, principalmente se contratar Porto Seguro para receber o link da biometria facial.'
              }
              position={'bottom right'}
              trigger={
                <Icon
                  name={'info circle'}
                  color={'blue'}
                  style={{ cursor: 'help', marginLeft: 5 }}
                />
              }
            />
          </FieldLabel>
          <Field
            autoCorrect="off"
            autoCapitalize="none"
            spellCheck="false"
            autoComplete="new-password"
            id={`${SECTION_NAME}.main_tenant_email`}
            name={`${SECTION_NAME}.main_tenant_email`}
            value={tenant_info.main_tenant_email}
            onChange={handleInputChange}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.main_tenant_email`, true)}
            customInput={<Input />}
            error={validateErros('main_tenant_email')}
            component={Input}
            fluid
          />
          <FormikErrorMessage
            component="div"
            name={`${SECTION_NAME}.main_tenant_email`}
          />
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>
            CELULAR*
            <Popup
              content={
                'Atenção, informar o celular correto do locatário para que ele receba mensagens da Seguradora, principalmente se contratar Porto Seguro para receber o link da biometria facial.'
              }
              position={'bottom right'}
              trigger={
                <Icon
                  name={'info circle'}
                  color={'blue'}
                  style={{ cursor: 'help', marginLeft: 5 }}
                />
              }
            />
          </FieldLabel>
          <Field
            id={`${SECTION_NAME}.main_tenant_phone`}
            name={`${SECTION_NAME}.main_tenant_phone`}
            value={tenant_info.main_tenant_phone}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
              data: InputOnChangeData
            ): void => {
              data.value = maskPhoneNumber(data.value);
              handleInputChange(e, data);
              setFieldValue(`${SECTION_NAME}.contact_phone`, maskPhoneNumber(data.value));
            }}
            onBlur={() => {
              setFieldTouched(`${SECTION_NAME}.main_tenant_phone`, true);
            }}
            error={validateErros('main_tenant_phone')}
            component={Input}
            fluid
          />
          <FormikErrorMessage
            component="div"
            name={`${SECTION_NAME}.main_tenant_phone`}
          />
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>ESTADO CIVIL* </FieldLabel>
          <Field
            class={'dropdown'}
            autoCorrect="off"
            autoCapitalize="none"
            spellCheck="false"
            autoComplete="off"
            search={false}
            id={`${SECTION_NAME}.main_tenant_marital_status`}
            name={`${SECTION_NAME}.main_tenant_marital_status`}
            value={tenant_info.main_tenant_marital_status}
            completeValue={true}
            placeholder={'Selecionar...'}
            options={maritalStatusOptions}
            component={Dropdown}
            onChange={handleInputChange}
            onClose={() =>
              setFieldTouched(`${SECTION_NAME}.main_tenant_marital_status`, true)
            }
            error={validateErros('main_tenant_marital_status')}
            fluid
          />
          <FormikErrorMessage
            component="div"
            name={`${SECTION_NAME}.main_tenant_marital_status`}
          />
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>NACIONALIDADE*</FieldLabel>
          <Field
            id={`${SECTION_NAME}.main_tenant_nationality`}
            name={`${SECTION_NAME}.main_tenant_nationality`}
            value={tenant_info.main_tenant_nationality}
            onChange={handleInputChange}
            onBlur={() =>
              setFieldTouched(`${SECTION_NAME}.main_tenant_nationality`, true)
            }
            error={validateErros('main_tenant_nationality')}
            component={Input}
            fluid
          />
          <FormikErrorMessage
            component="div"
            name={`${SECTION_NAME}.main_tenant_nationality`}
          />
        </Grid.Column>
      </Grid.Row>


      <Grid.Row>
        <Grid.Column width={3}>
          <FieldLabel>Deseja incluir nome social?</FieldLabel>
          <Grid.Row>
            <Grid.Column width={1}>
              <Radio
                style={{ marginRight: '1rem' }}
                label="Sim"
                name="has_social_name"
                checked={hasSocialName}
                onChange={() => {
                  setHasSocialName((prevState) => !prevState);
                  setFieldValue(`${SECTION_NAME}.has_social_name`, !hasSocialName)
                }}
              />
            </Grid.Column>
            <Grid.Column width={1}>
              <Radio
                style={{ marginRight: '1rem' }}
                label="Não"
                name="has_social_name"
                checked={!hasSocialName}
                onChange={() => {
                  setHasSocialName((prevState) => !prevState);
                  setFieldValue(`${SECTION_NAME}.has_social_name`, !hasSocialName)
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid.Column>

        {hasSocialName && (
          <Grid.Column width={4}>
            <FieldLabel>NOME SOCIAL</FieldLabel>
            <Field
              id={`${SECTION_NAME}.main_tenant_social_name`}
              autoCorrect="off"
              autoCapitalize="none"
              spellCheck="false"
              autoComplete="new-password"
              name={`${SECTION_NAME}.main_tenant_social_name`}
              value={tenant_info.main_tenant_social_name}
              onChange={handleInputChange}
              onBlur={() => setFieldTouched(`${SECTION_NAME}.main_tenant_social_name`, true)}
              customInput={<Input />}
              error={validateErros('main_tenant_social_name')}
              component={Input}
              fluid
            />
            <FormikErrorMessage component="div" name={`${SECTION_NAME}.main_tenant_social_name`} />
          </Grid.Column>)}
      </Grid.Row>

      {/* {hasSocialName && (
        <Grid.Row>
          <Grid.Column width={4}>
          <FieldLabel>NOME SOCIAL</FieldLabel>
          <Field
            id={`${SECTION_NAME}.main_tenant_social_name`}
            autoCorrect="off"
            autoCapitalize="none"
            spellCheck="false"
            autoComplete="new-password"
            name={`${SECTION_NAME}.main_tenant_social_name`}
            value={tenant_info.main_tenant_social_name}
            onChange={handleInputChange}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.main_tenant_social_name`, true)}
            customInput={<Input />}
            error={validateErros('main_tenant_social_name')}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.main_tenant_social_name`} />
        </Grid.Column>
        </Grid.Row>)} */}

      <Grid.Row>
        <Grid.Column width={8}>
          <strong style={{ color: 'red' }}>Atenção</strong>
          <Advice>
            <p>
              É obrigatório o preenchimento do e-mail e celular do locatário. O
              preenchimento do e-mail e/ou celular que não seja do locatário poderá
              ocasionar em recusa ou em não contratação do seguro.
            </p>
          </Advice>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>
          <FieldLabel paddingTop={true}>PESSOA POLITICAMENTE EXPOSTA? </FieldLabel>
          <Grid.Row>
            <Grid.Column
              width={5}
              style={{
                paddingTop: '10px'
              }}
            >
              <Radio
                style={{ marginRight: '1rem' }}
                label="Sim"
                id={`${SECTION_NAME}.main_tenant_politically_exposed_person.yes`}
                name={`${SECTION_NAME}.main_tenant_politically_exposed_person`}
                value={PoliticallyExposedPersonEnum.yes}
                checked={
                  tenant_info.main_tenant_politically_exposed_person ===
                  PoliticallyExposedPersonEnum.yes
                }
                onChange={(_, data) => {
                  setFieldValue(
                    data.name!,
                    data.value
                      ? PoliticallyExposedPersonEnum.yes
                      : PoliticallyExposedPersonEnum.no
                  );
                }}
              />

              <Radio
                style={{ marginRight: '1rem' }}
                label="Não"
                id={`${SECTION_NAME}.main_tenant_politically_exposed_person.no`}
                name={`${SECTION_NAME}.main_tenant_politically_exposed_person`}
                value={PoliticallyExposedPersonEnum.no}
                checked={
                  tenant_info.main_tenant_politically_exposed_person ===
                  PoliticallyExposedPersonEnum.no
                }
                onChange={(_, data) => {
                  setFieldValue(
                    data.name!,
                    data.value
                      ? PoliticallyExposedPersonEnum.no
                      : PoliticallyExposedPersonEnum.no
                  );
                }}
              />

              <Radio
                label="Próximo"
                id={`${SECTION_NAME}.main_tenant_politically_exposed_person.closer`}
                name={`${SECTION_NAME}.main_tenant_politically_exposed_person`}
                value={PoliticallyExposedPersonEnum.closeTo}
                checked={
                  tenant_info.main_tenant_politically_exposed_person ===
                  PoliticallyExposedPersonEnum.closeTo
                }
                onChange={(_, data) => {
                  setFieldValue(
                    data.name!,
                    data.value
                      ? PoliticallyExposedPersonEnum.closeTo
                      : PoliticallyExposedPersonEnum.no
                  );
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <FormikErrorMessage
            component="div"
            name={`${SECTION_NAME}.main_tenant_politically_exposed_person`}
          />
        </Grid.Column>

        {tenant_info.main_tenant_politically_exposed_person ===
          PoliticallyExposedPersonEnum.closeTo && (
            <>
              <Grid.Column width={4}>
                <FieldLabel>NOME DA PESSOA*</FieldLabel>
                <Field
                  autoCorrect="off"
                  autoCapitalize="none"
                  spellCheck="false"
                  autoComplete="new-password"
                  id={`${SECTION_NAME}.main_tenant_close_to_politically_exposed_person.politically_exposed_person_name`}
                  name={`${SECTION_NAME}.main_tenant_close_to_politically_exposed_person.politically_exposed_person_name`}
                  value={
                    tenant_info.main_tenant_close_to_politically_exposed_person
                      .politically_exposed_person_name
                  }
                  onChange={handleInputChange}
                  onBlur={() =>
                    setFieldTouched(
                      `${SECTION_NAME}.main_tenant_close_to_politically_exposed_person.politically_exposed_person_name`,
                      true
                    )
                  }
                  customInput={<Input />}
                  error={validateErros(
                    'main_tenant_close_to_politically_exposed_person.politically_exposed_person_name'
                  )}
                  component={Input}
                  fluid
                />
                <FormikErrorMessage
                  component="div"
                  name={`${SECTION_NAME}.main_tenant_close_to_politically_exposed_person.politically_exposed_person_name`}
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>CPF DA PESSOA*</FieldLabel>
                <Field
                  id={`${SECTION_NAME}.main_tenant_close_to_politically_exposed_person.politically_exposed_person_cpf`}
                  name={`${SECTION_NAME}.main_tenant_close_to_politically_exposed_person.politically_exposed_person_cpf`}
                  value={
                    tenant_info.main_tenant_close_to_politically_exposed_person
                      .politically_exposed_person_cpf
                  }
                  component={Input}
                  fluid
                />
                <FormikErrorMessage
                  component="div"
                  name={`${SECTION_NAME}.main_tenant_close_to_politically_exposed_person.politically_exposed_person_cpf`}
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <FieldLabel>GRAU DE PARENTESCO*</FieldLabel>
                <Field
                  autoCorrect="off"
                  autoCapitalize="none"
                  spellCheck="false"
                  autoComplete="new-password"
                  id={`${SECTION_NAME}.main_tenant_close_to_politically_exposed_person.degree_of_kinship_to_politically_exposed_person`}
                  name={`${SECTION_NAME}.main_tenant_close_to_politically_exposed_person.degree_of_kinship_to_politically_exposed_person`}
                  value={
                    tenant_info.main_tenant_close_to_politically_exposed_person
                      .degree_of_kinship_to_politically_exposed_person
                  }
                  onChange={handleInputChange}
                  onBlur={() =>
                    setFieldTouched(
                      `${SECTION_NAME}.main_tenant_close_to_politically_exposed_person.degree_of_kinship_to_politically_exposed_person`,
                      true
                    )
                  }
                  customInput={<Input />}
                  error={validateErros(
                    'main_tenant_close_to_politically_exposed_person.degree_of_kinship_to_politically_exposed_person'
                  )}
                  component={Input}
                  fluid
                />
                <FormikErrorMessage
                  component="div"
                  name={`${SECTION_NAME}.main_tenant_close_to_politically_exposed_person.degree_of_kinship_to_politically_exposed_person`}
                />
              </Grid.Column>
            </>
          )}
      </Grid.Row>
    </Grid>
  );
};
