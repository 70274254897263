import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { pdf } from '@react-pdf/renderer';
import saveAs from 'file-saver';
import { useHistory } from 'react-router-dom';
import { Button, Dropdown, Grid, Icon, Input, Loader, Menu, Popup, Table } from 'semantic-ui-react';

import { getQuotes } from '../../../services/fire-insurance-budget';
import { FireInsuranceAnalysisStatusEnum, translateStatus } from '../utils/Pottencial';
import DetailModal from './Details/DetailModal';
import EditModal from './Edit/EditModal';
import { FireInsuranceQuoteFiltersInterface } from '../util';
import { FieldLabel } from '../../../styles';
import { statusList } from '../utils/Pottencial';
import { DATE_INPUT_MASK, maskCpfOrCnpj } from '../../../services/masks';
import CancelModal from './Cancel/CancelModal';
import CustomDatePicker from '../../../components/CustomDatePicker';
import AppMaskedInput from '../../../components/MaskedInput';
import { AvailableBrokersAndEstatesState, CurrentPageState } from '../../../store/types/temp-types';
import { iRootDispatch, iRootState } from '../../../store';
import { CustoDropDownItemProps } from '../../../components/types';
import { FireInsuranceAnalysisType } from '../types/fireInsuranceAnalysisType';
import { ActionEnum } from '../../../enums/authz-action.enum';
import AuthorizationContainer from '../../../components/AuthorizationContainer';
import { FeatureEnum } from '../../../enums/authz-feature.enum';
import IconButton from '../../../components/IconButton';
import { InsurersCodeEnum } from '../../../enums/insurers-code.enum';
import AlfaBudgetReport from '../../../services/reports/AlfaBudgetReport';
import FireGeneralCondition from '../budget/GeneralConditionModal';
import { BudgetContext } from '../budget/forms-based-in-insurer/Alfa';

const ClickableTableCell = styled(Table.Cell)`
  cursor: pointer;
  position: relative;
`;

const ClickableTableRow = styled(Table.Row)`
  :hover {
    background-color: #f0f0f0;
  }
`;

interface CopyCancelledBudget {
  budgetId: string;
  estateId: string;
  insurerId: string;
  context: string;
};

const BudgetList = (props: { availableBrokersAndEstates: AvailableBrokersAndEstatesState; updateCurrentPage: Function }) => {
  const { availableBrokersAndEstates } = props;

  const estatesOptions: CustoDropDownItemProps[] = availableBrokersAndEstates.estates;
  const insurerOptions: CustoDropDownItemProps[] = availableBrokersAndEstates.insurers.filter(insurer =>
    insurer.value.availability.includes('FIRE_INSURANCE'));

  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);
  const [data, setData] = useState<FireInsuranceAnalysisType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState<number>(0);
  const lastPage = Math.floor(count / 10) + 1;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFireInsurance, setSelectedFireInsurance] = useState<any>();
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [name, setName] = useState<string>('');
  const [document, setDocument] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [selectedEstate, setSelectedEstate] = useState<any>();
  const [selectedInsurer, setSelectedInsurer] = useState<any>();
  const [status, setStatus] = useState<any>();

  const [sendHiring, setSendHiring] = useState<boolean>(false);
  const history = useHistory();
  const ableToHire = ['APPROVED'];
  const ableToEdit = ['APPROVED', 'ERROR', 'DENIED', 'WAITING', ''];
  const ableToCancel = ['APPROVED', 'ERROR', 'DENIED', 'UNDERANALISYS', 'WAITING', 'CREATING'];


  const [openConditions, setOpenConditions] = useState<boolean>(false);
  const toggleOpenConditions = () => {
    setOpenConditions(true);
  };

  const toggleCloseConditions = () => {
    setOpenConditions(false);
  };

  const getQuotations = async () => {
    setIsLoading(true);

    const filter: FireInsuranceQuoteFiltersInterface = {
      page: currentPage,
      start: start ? moment(start).format('YYYY-MM-DD') : undefined,
      end: end ? moment(end).format('YYYY-MM-DD') : undefined,
      name: name ? name : undefined,
      document: document ? document.replace(/[^\d]+/g, '') : undefined,
      address: address ? address : undefined,
      insurerId: selectedInsurer?.id || undefined,
      estateId:
        estatesOptions.length === 1
          ? estatesOptions[0].value.id
          : (selectedEstate as any)?.id || undefined,
      status: status || undefined
    };

    const data = await getQuotes(filter);
    data?.data && setData(data.data);
    if (data?.quantity && currentPage === 1) setCount(data.quantity);
    setIsLoading(false);
  };

  useEffect(() => {
    getQuotations();
  }, [currentPage]);

  const openDetailModal = (fireInsurance: any) => {
    setSelectedFireInsurance(fireInsurance);
    setOpenDetail(true);
  };

  const closeDetailModal = () => {
    setSelectedFireInsurance(undefined);
    setSendHiring(false);
    setOpenDetail(false);
  };

  const openEditModal = (fireInsurance: any) => {
    setSelectedFireInsurance(fireInsurance);
    setOpenEdit(true);
  };

  const closeEditModal = () => {
    setSelectedFireInsurance(undefined);
    setOpenEdit(false);
    getQuotations();
  };

  const handleOpenCancelModal = (fireInsurance: any) => {
    setSelectedFireInsurance(fireInsurance);
    setOpenCancelModal(true);
  };

  const closeCancelModal = () => {
    setOpenCancelModal(false);
    setSelectedFireInsurance(undefined);
  };

  const handleNewChangeEstate = (_e: any, { value }: any) => {
    setSelectedEstate(value);
  };

  const handleNewChangeInsurer = (_e: any, { value }: any) => {
    setSelectedInsurer(value);
  };

  const handleFireInsuranceBudgetReport = async (item: any) => {
    const blob = await pdf(<AlfaBudgetReport data={item} />).toBlob();
    saveAs(blob, `ORÇAMENTO_${new Date().getTime()}.pdf`);
  };

  const handleCopyCancelledBudget = async (copyCancelledBudget: CopyCancelledBudget) => {
    const { budgetId, estateId, insurerId, context } = copyCancelledBudget;

    props.updateCurrentPage({
      id: 4,
      clickHandler: (e: any, data: any) => { },
      name: 'fire-insurance-budget',
      route: 'fire-insurance-budget',
      text: 'Edição de Cotação',
      title: 'Edição de orçamento de seguro incêndio'
    });

    history.push(
      `/admin/fire-insurance-budget/${budgetId}?context=${context}&estateId=${estateId}&insurerId=${insurerId}`
    );
  };

  return (
    <>
      <Grid columns={'equal'}>
      <Grid.Row style={{ textAlign: 'right', justifyContent: 'right', paddingRight: 10  }}>
        <FireGeneralCondition
          openModal={openConditions}
          toggleOpen={toggleOpenConditions}
          toggleClose={toggleCloseConditions}
        />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <FieldLabel>Imobiliária</FieldLabel>
            <Dropdown
              fluid
              search
              clearable
              selection
              placeholder={'Selecionar'}
              options={estatesOptions || []}
              onChange={handleNewChangeEstate}
              value={selectedEstate}
              name={'estate'}
              defaultValue={estatesOptions.length === 1 ? estatesOptions[0].value : '' || ''}
              disabled={estatesOptions.length === 1 || false}
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>Status</FieldLabel>
            <Dropdown
              fluid
              search
              clearable
              selection
              placeholder={'Status'}
              loading={!statusList.length}
              disabled={!statusList.length}
              options={statusList}
              onChange={(e, { name, value }) => setStatus(value)}
              value={status}
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>Data de Início</FieldLabel>
            <CustomDatePicker
              fluid
              placeholder={'Data de Início'}
              selected={start}
              maxDate={end}
              onChange={(date: any) => setStart(date)}
              customInput={
                <AppMaskedInput
                  mask={DATE_INPUT_MASK}
                />
              }
              dateFormat={'dd/MM/yyyy'}
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>Fim</FieldLabel>
            <CustomDatePicker
              fluid
              placeholder={'Data de Fim'}
              selected={end}
              minDate={start}
              onChange={(date: any) => setEnd(date)}
              customInput={
                <AppMaskedInput
                  mask={DATE_INPUT_MASK}
                />
              }
              dateFormat={'dd/MM/yyyy'}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <FieldLabel>Seguradora</FieldLabel>
            <Dropdown
              fluid
              search
              clearable
              selection
              name={'insurer'}
              placeholder={'Selecionar'}
              options={insurerOptions || []}
              onChange={handleNewChangeInsurer}
              value={selectedInsurer}
              defaultValue={insurerOptions.length === 1 ? insurerOptions[0].value : '' || ''}
              disabled={insurerOptions.length === 1 || false}
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>Nome/Razão Social</FieldLabel>
            <Input
              fluid
              placeholder={'Nome/Razão Social'}
              value={name}
              onChange={(e, data: any) => setName(data.value)}
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>CPF/CNPJ</FieldLabel>
            <Input
              fluid
              placeholder={'CPF/CNPJ'}
              value={maskCpfOrCnpj(document)}
              onChange={(e, data: any) => setDocument(data.value)}
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>Endereço</FieldLabel>
            <Input
              fluid
              placeholder={'Endereço'}
              value={address}
              onChange={(e, data: any) => setAddress(data.value)}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column textAlign={'right'}>
            <Button
              disabled={isLoading}
              loading={isLoading}
              onClick={() => getQuotations()}
              primary
            >
              Pesquisar
            </Button>

            <Button
              disabled={isLoading}
              loading={isLoading}
              onClick={() => {
                setStatus('');
                setStart(undefined);
                setEnd(undefined);
                setName('');
                setDocument('');
                setAddress('');
                setSelectedEstate('');
                setSelectedInsurer('');
                setIsFirstLoading(false);
              }}
              basic
            >
              Limpar
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Table celled>
        <Table.Header fullWidth>
          <Table.HeaderCell textAlign={'center'}>Solicitado em</Table.HeaderCell>
          <Table.HeaderCell textAlign={'center'} width={2}>Seguradora</Table.HeaderCell>
          <Table.HeaderCell textAlign={'center'}>Imobiliária</Table.HeaderCell>
          <Table.HeaderCell textAlign={'center'}>Inquilino</Table.HeaderCell>
          <Table.HeaderCell textAlign={'center'}>Endereço</Table.HeaderCell>
          <Table.HeaderCell textAlign={'center'}>Status</Table.HeaderCell>
          <Table.HeaderCell textAlign={'center'}>Valor</Table.HeaderCell>
          <Table.HeaderCell textAlign={'center'}>Ações</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {isLoading ? (
            <Table.Row>
              <Table.Cell textAlign={'center'} colSpan={7}>
                <Loader active={isLoading} inline />
              </Table.Cell>
            </Table.Row>
          ) : (
            <>
              {data.length ? (
                <>
                  {data.map((item, index) => {
                    const isCanceled = item.status === FireInsuranceAnalysisStatusEnum.Canceled;

                    return (
                      <ClickableTableRow key={index}>
                        <ClickableTableCell
                          onClick={() => openDetailModal(item)}
                          textAlign={'center'}
                        >
                          {moment(item.created_at).format('DD/MM/YYYY')}
                        </ClickableTableCell>

                        <ClickableTableCell
                          onClick={() => openDetailModal(item)}
                          textAlign={'center'}
                        >
                          {item.insurer.name}
                        </ClickableTableCell>

                        <ClickableTableCell
                          onClick={() => openDetailModal(item)}
                          textAlign={'center'}
                        >
                          {item.fireInsurance.estate.name}
                        </ClickableTableCell>

                        <ClickableTableCell
                          onClick={() => openDetailModal(item)}
                          textAlign={'center'}
                        >
                          {item.fireInsurance.tenantName}
                          <br />
                          <span style={{ color: '#777' }}>
                            ({maskCpfOrCnpj(item.fireInsurance.tenantDocument)})
                          </span>
                        </ClickableTableCell>

                        <ClickableTableCell
                          onClick={() => openDetailModal(item)}
                          textAlign={'center'}
                        >
                          {`${item.fireInsurance.propertyAddresses.street}, ${
                            item.fireInsurance.propertyAddresses.number
                          }${
                            item.fireInsurance.propertyAddresses?.complement
                              ? ` - ${item.fireInsurance.propertyAddresses.complement}`
                              : ''
                          }`}
                        </ClickableTableCell>

                        <ClickableTableCell
                          onClick={() => openDetailModal(item)}
                          textAlign={'center'}
                        >
                          {item.status === 'ERROR' ? (
                            <Popup
                              header={'Erro'}
                              content={item.observations}
                              position={'left center'}
                              trigger={
                                <span
                                  style={{
                                    color: item.status.length
                                      ? 'rgba(0, 0, 0, .87)'
                                      : '#ccc'
                                  }}
                                >
                                  {translateStatus(item.status)}
                                </span>
                              }
                            />
                          ) : (
                            translateStatus(item.status)
                          )}
                        </ClickableTableCell>

                        <ClickableTableCell
                          onClick={() => openDetailModal(item)}
                          textAlign={'center'}
                        >
                          {item.value ? `R$ ${item.value}` : '-'}
                          <br />
                          <span style={{ color: '#777' }}>({item.installments}x)</span>
                        </ClickableTableCell>

                        <Table.Cell textAlign={'center'}>
                          <Menu compact>
                            <Menu.Item>
                              <Dropdown
                                trigger={
                                  <IconButton name="ellipsis vertical" color="blue" />
                                }
                                icon={null}
                                direction="left"
                              >
                                <Dropdown.Menu style={{ zIndex: 1000 }}>
                                  {ableToHire.includes(item.status) && (
                                    <AuthorizationContainer
                                      action={ActionEnum.contract}
                                      feature={FeatureEnum.fireinsurances}
                                    >
                                      <Dropdown.Item
                                        icon="check"
                                        text={'Contratar'}
                                        onClick={() => {
                                          setSendHiring(true);
                                          openDetailModal(item);
                                        }}
                                      />
                                    </AuthorizationContainer>
                                  )}

                                  {ableToEdit.includes(item.status) && (
                                    // <AuthorizationContainer
                                    //   action={ActionEnum.update}
                                    //   feature={FeatureEnum.fireinsurances}
                                    // >
                                      <Dropdown.Item
                                        icon="pencil alternate"
                                        text={'Editar Orçamento'}
                                        disabled={
                                          item.insurer.code !== InsurersCodeEnum.alfa
                                        }
                                        onClick={() =>
                                          {
                                            props.updateCurrentPage({
                                              id: 4,
                                              clickHandler: (e: any, data: any) => { },
                                              name: 'fire-insurance-budget',
                                              route: 'fire-insurance-budget',
                                              text: 'Edição de Cotação',
                                              title: 'Edição de orçamento de seguro incêndio'
                                            })

                                          history.push(
                                            `/admin/fire-insurance-budget/${item?.id}?context=${BudgetContext.editing}&estateId=${item.fireInsurance.estateId}&insurerId=${item?.insurer?.id}`
                                          )
                                          }
                                        }
                                      />
                                    // </AuthorizationContainer>
                                  )}

                                  <Dropdown.Item
                                    icon='download'
                                    text={'Imprimir Orçamento'}
                                    disabled={
                                      item.insurer.code !== InsurersCodeEnum.alfa ||
                                      isCanceled
                                    }
                                    onClick={() => handleFireInsuranceBudgetReport(item)}
                                  />

                                  <Dropdown.Item
                                    icon='trash alternate'
                                    text={'Cancelar Orçamento'}
                                    disabled={isCanceled}
                                    onClick={() => handleOpenCancelModal(item)}
                                  />

                                  {isCanceled && (
                                    <Dropdown.Item
                                      icon='clone alternate'
                                      text={'Clonar Orçamento'}
                                      disabled={item.insurer.code !== InsurersCodeEnum.alfa}
                                      onClick={() => handleCopyCancelledBudget({
                                        budgetId: item.id,
                                        estateId: item.fireInsurance.estateId,
                                        insurerId: item.insurerId,
                                        context: BudgetContext.copyCanceled,
                                      })}
                                    />
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Menu.Item>
                          </Menu>
                        </Table.Cell>
                      </ClickableTableRow>
                    );
                  })}
                </>
              ) : (
                <Table.Row>
                  <Table.Cell textAlign={'center'} colSpan={7}>
                    Nenhum registro encontrado
                  </Table.Cell>
                </Table.Row>
              )}
            </>
          )}
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan={9} textAlign={'right'}>
              {!isLoading && (
                <Menu floated="right" pagination>
                  <Menu.Item>
                    <p>{`${data.length ? currentPage * 10 - 9 : 0} - ${
                      count < currentPage * 10 ? count : currentPage * 10
                    } de ${count}`}</p>
                  </Menu.Item>
                  <Menu.Item
                    disabled={currentPage === 1}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(1)}
                  >
                    <Icon name="angle double left" />
                  </Menu.Item>
                  <Menu.Item
                    disabled={currentPage === 1}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item as="a">{currentPage}</Menu.Item>
                  <Menu.Item
                    disabled={currentPage === lastPage || data.length < 10}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                  <Menu.Item as="a" icon>
                    <Icon
                      disabled={currentPage === lastPage || data.length < 10}
                      name="angle double right"
                      onClick={() => setCurrentPage(lastPage)}
                    />
                  </Menu.Item>
                </Menu>
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>

      {openDetail && (
        <DetailModal
          open={openDetail}
          fireInsuranceAnalysis={selectedFireInsurance}
          close={closeDetailModal}
          hire={sendHiring}
        />
      )}

      {openEdit && (
        <EditModal
          open={openEdit}
          fireInsurance={selectedFireInsurance}
          close={closeEditModal}
        />
      )}

      {openCancelModal && (
        <CancelModal
          fireInsurance={selectedFireInsurance}
          open={openCancelModal}
          onClose={closeCancelModal}
        />
      )}
    </>
  );
};

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user,
  currentPage: state.currentPage
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers),
  updateCurrentPage: (state: CurrentPageState) =>
    dispatch.currentPage.updateCurrentPage(state)
});

export default connect(mapState, mapDispatch)(BudgetList);
