export const FileIdentifiersAll = [
  { name: 'Boleto Aluguel', key: 'RENT_BILL' },
  { name: 'Boleto Condomínio', key: 'CONDOMINIUM_BILL' },
  {
    name: 'Contrato de administração',
    key: 'PROPERTY_MANAGEMENT_CONTRACT'
  },
  { name: 'Procuração', key: 'LETTER_ATTORNEY' },
  {
    name: 'Vistoria Inicial do Imóvel',
    key: 'INITIAL_INSPECTION_PROPERTY'
  },
  {
    name: 'Vistoria Final do Imóvel',
    key: 'FINAL_INSPECTION_PROPERTY'
  },
  { name: 'Orçamentos', key: 'BUDGETS' },
  {
    name: 'Termo de entrega das chaves',
    key: 'TERM_DELIVERY_PROPERTY_KEYS'
  },
  {
    name: 'Guia de IPTU',
    key: 'IPTU_GUIDE'
  },
  {
    name: 'Declaração de débitos',
    key: 'DEBITS_SHEET'
  },
  {
    name: 'Acordo de Confissão de Dívidas',
    key: 'DEBT_CONFESS_AGREEMENT'
  },
  {
    name: 'Contrato de Locação',
    key: 'LOCATION_QUOTATION'
  },
  {
    name: 'Planilha de indenização',
    key: 'INDEMNITY_WORKSHEET'
  },
  {
    name: 'Planilha de acordo',
    key: 'ACCORD_WORKSHEET'
  },
  {
    name: 'Acordo escritório',
    key: 'OFFICE_AGREEMENT'
  },
  {
    name: 'Imissão na posse',
    key: 'POSSESSION_IMMISSION'
  },
  {
    name: 'Apólice Seguro Incêndio',
    key: 'FIRE_INSURANCE_POLICY'
  },
  {
    name: 'Comprovante de Pagamento (planilha)',
    key: 'PAYMENT_VOUCHER'
  },
  {
    name: 'RG do Locador',
    key: 'LOCATOR_DOCUMENT'
  },
  {
    name: 'RG/CNH/CPF do Locatário',
    key: 'TENANT_DOCUMENT'
  },
  {
    name: 'Comprovante de Endereço Locador',
    key: 'LOCATOR_ADDRESS_DOCUMENT'
  },
  {
    name: 'Contrato Garantti Locatário',
    key: 'LOCATOR_GARANTTI_CONTRACT'
  },
  {
    name: 'Água',
    key: 'WATER_BILL'
  },
  {
    name: 'Luz',
    key: 'ENERGY_BILL'
  },
  {
    name: 'Apólice Seguro Fiança',
    key: 'BAIL_INSURANCE_POLICY',
    required: false
  },
  {
    name: 'Boleto Devolutivo',
    key: 'RETURN_BILL',
    required: false
  },
  {
    name: 'Planilha de danos',
    key: 'DAMAGE_WORKSHEET',
    required: false
  },
  {
    name: 'Planilha de pintura',
    key: 'PAINTING_WORKSHEET',
    required: false
  },
  {
    name: 'Informativo de sinistro',
    key: 'CLAIM_INFORMATION',
    required: false
  },
  {
    name: 'Declaração de condomínio',
    key: 'CONDOMINIUM_DECLARATION',
    required: false
  },
  {
    name: 'Modelo de orçamento',
    key: 'BUDGET_MODEL',
    required: false
  },
  {
    name: 'Mandado de despejo',
    key: 'DESPERATE_REQUEST',
    required: false
  },
  { name: 'Outros', key: 'OTHER' }
];
