import React, { useEffect, useState } from 'react';
import { Button, Grid, Icon, Modal } from 'semantic-ui-react';
import { ViewModalProps } from './types';
import {
  Container,
  FormContainer,
  Form,
  Header,
  Line,
  FormTitle,
  FormContent,
  FormRow,
  InfoDisclaimer,
  InsurerGrid,
  insurerGridStyles
} from './styles';
import { maskCpfOrCnpj, maskPhoneNumber } from '../../../../services/masks';
import { DateToStringDate } from '../../../../services/date';
import { BankOptions, getEstateBankByCode } from '../../../../resources/bankCodeList';
import {
  dangerNotification,
  successNotification
} from '../../../../services/notification';
import AuthorizationContainer from '../../../../components/AuthorizationContainer';
import { ActionEnum } from '../../../../enums/authz-action.enum';
import { FeatureEnum } from '../../../../enums/authz-feature.enum';
import InsurerTaxesModal from '../InsurerTaxesModal';
import {
  EstateInsurerDataForGetEstatesResponseDTO,
  InsurerForGetEstateResponseDTO
} from '../../../../dtos/estate/estate-for-get-estates-response.dto';
import { get } from '../../../../services/request';
import * as storage from '../../../../services/storage';
import { invalidateCacheBroker } from '../../../../services/fire-insurance-policy';
import { InsurersCodeEnum } from '../../../../enums/insurers-code.enum';
import AlfaFireInsuranceDetails from '../AlfaFireInsuranceDetails';
type contactSource = 'CONFIAX_COMERCIAL' | 'REDE_VISTORIAS';

enum contactSourceEnum {
  'CONFIAX_COMERCIAL' = 'Confiax Comercial',
  'REDE_VISTORIAS' = 'Rede Vistorias'
};

interface InsurerFormalized extends InsurerForGetEstateResponseDTO {
  id: string;
  name: string;
  hasCapitalization?: boolean;
  hasFireInsurance?: boolean;
  hasInsurance?: boolean;
}

const ViewModal: React.FC<ViewModalProps> = ({
  isOpen,
  onClose,
  estate
}: ViewModalProps) => {
  const [bankSelected, setBankSelected] = useState<BankOptions | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isFireInsurerModalVisible, setFireInsurerModalVisible] = useState(false);
  const [insurerDataSelected, setInsurerDataSelected] =
    useState<EstateInsurerDataForGetEstatesResponseDTO | null>(null);
  const [insurersDataFormalized, setInsurersDataFormalized] = useState<
    InsurerFormalized[]
  >([]);
  const [activeFireInsuranceMenu, setActiveFireInsuranceMenu] = useState(
    estate.menu_fire_insurance || false
  );
  const role = storage.get('role')

  const handleOpenTaxesModal = (insurer: InsurerForGetEstateResponseDTO) => {
    const insurerDataSelected = estate.insurer_data.find(
      item => item.insurer.code === insurer.code
    );

    if (insurerDataSelected) {
      setInsurerDataSelected(insurerDataSelected);
      setModalVisible(true);
    }
  };

  const handleCloseTaxesModal = () => {
    setInsurerDataSelected(null);
    setModalVisible(false);
  };

  const handleOpenFireInsurerDetails = (insurer: InsurerForGetEstateResponseDTO) => {
    const insurerDataSelected = estate.insurer_data.find(
      item => item.insurer.code === insurer.code
    );

    if (insurerDataSelected) {
      setInsurerDataSelected(insurerDataSelected);
    } else {
      return;
    }

    switch (insurer.code) {
      case InsurersCodeEnum.alfa:
        setFireInsurerModalVisible(true);
        break;
      default:
        setModalVisible(true);
        break;
    }
  };

  const handleCloseFireInsurerDetailsl = () => {
    setInsurerDataSelected(null);
    setFireInsurerModalVisible(false);
  };

  useEffect(() => {
    const insurersFormalized: InsurerFormalized[] = [];

    estate?.insurers?.forEach(insurer => {
      const insurerSelected = insurersFormalized.find(ins => ins.id === insurer.id);

      if (insurerSelected) {
        insurerSelected.hasInsurance = true;
      } else {
        insurersFormalized.push({
          ...insurer,
          id: insurer.id,
          name: insurer.name,
          hasInsurance: true
        });
      }
    });

    estate?.fire_insurers?.forEach(insurer => {
      const insurerSelected = insurersFormalized.find(ins => ins.id === insurer.id);

      if (insurerSelected) {
        insurerSelected.hasFireInsurance = true;
      } else {
        insurersFormalized.push({
          ...insurer,
          id: insurer.id,
          name: insurer.name,
          hasFireInsurance: true,
        });
      }
    });

    estate?.capitalizations?.forEach(capitalization => {
      const capitalizationSelected = insurersFormalized.find(
        ins => ins.id === capitalization.id
      );

      if (capitalizationSelected) {
        capitalizationSelected.hasCapitalization = true;
      } else {
        insurersFormalized.push({
          ...capitalization,
          id: capitalization.id,
          name: capitalization.name,
          hasCapitalization: true,
        });
      }
    });

    setInsurersDataFormalized(insurersFormalized);
  }, [estate?.capitalizations, estate?.fire_insurers, estate?.insurers]);

  async function changeFireInsuranceMenuStatus() {
    try {
      const response = await get(`/estates/change-menu-fire-insurance/${estate.id}`);

      if (response.success) {
        setActiveFireInsuranceMenu(!activeFireInsuranceMenu);
      } else {
        dangerNotification(
          'Erro',
          `Erro ao ${activeFireInsuranceMenu ? 'desativar' : 'ativar'} menu de incêndio!`
        );
      }
    } catch (error) {
      dangerNotification(
        'Erro',
        `Erro ao ${activeFireInsuranceMenu ? 'desativar' : 'ativar'} menu de incêndio!`
      );
    } finally {
      invalidateCacheBroker();
    }
  }

  async function runSyncSegimob() {
      try {
        const response = await get(`/estates/sync-segimob/${estate.id}`);
        if (response.success) {
          successNotification(
            'Sucesso',
            'Sincronização com segimob solicitada com sucesso!'
          );
        } else {
          dangerNotification(
            'Erro',
            'Erro ao solicitar sincronização Segimob!'
          );
        }
      } catch (error) {
        dangerNotification(
          'Erro',
          'Erro ao solicitar sincronização Segimob!'
        );
      }
  }

  useEffect(() => {
    async function fetchBanks() {
      try {
        const bank = await getEstateBankByCode(estate?.bank_account?.bank_code || '');
        setBankSelected(bank);
      } catch (err) {
        dangerNotification('Erro', 'Erro ao carregar lista de bancos');
      }
    }
    if (estate.bank_account?.bank_code) {
      fetchBanks();
    }
  }, [estate.bank_account?.bank_code]);

  if (!isOpen) {
    return null;
  }

  const getContactSource = (source: contactSource) => {
    if (contactSourceEnum[source]) {
      return contactSourceEnum[source];
    }
    return '';
  };

  const getStatus = (status: string) => {
    const options = [
      { key: 'PENDING', text: 'Pendente', value: 'PENDING' },
      { key: 'ACTIVE', text: 'Ativo', value: 'ACTIVE' },
      { key: 'INACTIVE', text: 'Inativo', value: 'INACTIVE' },
      { key: 'BLOCKED', text: 'Bloqueado', value: 'BLOCKED' },
      { key: 'UPDATE', text: 'Update', value: 'UPDATE' }
    ];

    return options.find(item => item.value === status)?.text;
  };

  return (
    <Modal open={isOpen} onClose={onClose} closeIcon>
      <Container>
        <Header>
          <h3>{estate?.name}</h3>
        </Header>
        <Line />
        <Form>
          <FormContainer>
            { role === 'BROKER_ADMIN'&& <FormContent>
              <FormRow>
                <div>
                  <Button
                    color="blue"
                    onClick={ (e) => {
                      e.preventDefault();
                      runSyncSegimob();
                    }}
                  >
                    <Icon name="sync" /> Sincronizar Segimob
                  </Button>
                </div>
                <div style={{ marginLeft: 20 }}>
                  <Button
                    color={activeFireInsuranceMenu ? 'red' : 'green'}
                    onClick={ (e) => {
                      e.preventDefault();
                      changeFireInsuranceMenuStatus()
                    }
                  }
                  >
                    <Icon name={activeFireInsuranceMenu ? 'cancel' : 'check'} />{' '}
                    {activeFireInsuranceMenu ? 'Desativar' : 'Ativar'} Menu de Incêndio
                  </Button>
                </div>
              </FormRow>
            </FormContent>}
          </FormContainer>

          <FormContainer>
            <FormTitle>DADOS DA IMOBILIÁRIA</FormTitle>
            <Line />
            <FormContent>
              <FormRow>
                <label>
                  <b>CORRETORA</b>
                  <span>{estate?.broker?.name}</span>
                </label>
                <label>
                  <b>E-MAIL PRINCIPAL</b>
                  <span>{estate?.email}</span>
                </label>
                <label>
                  <b>ORIGEM DO CONTATO</b>
                  <span>{getContactSource(estate?.contact_source as contactSource)}</span>
                </label>
              </FormRow>
              <FormRow>
                <label>
                  <b>ID</b>
                  <span>{estate?.id}</span>
                </label>
                <label>
                  <b>NOME DA IMOBILIÁRIA</b>
                  <span>{estate?.name}</span>
                </label>
                <label>
                  <b>RAZÃO SOCIAL</b>
                  <span>{estate?.company_name}</span>
                </label>
              </FormRow>
              <FormRow>
                <label>
                  <b>CNPJ</b>
                  <span>{maskCpfOrCnpj(estate?.cnpj)}</span>
                </label>
                <label>
                  <b>RESPONSÁVEL</b>
                  <span>{estate?.responsible_name}</span>
                </label>
                <label>
                  <b>E-MAIL DO RESPONSÁVEL</b>
                  <span>{estate?.responsible_email}</span>
                </label>
              </FormRow>
              <FormRow>
                <label>
                  <b>TELEFONE DO RESPONSÁVEL</b>
                  <span>{maskPhoneNumber(estate?.responsible_phone || '')}</span>
                </label>
                <label>
                  <b>RESPONSÁVEL FINANCEIRO</b>
                  <span>{estate?.financial_responsible}</span>
                </label>
                <label>
                  <b>E-MAIL DO FINANCEIRO</b>
                  <span>{estate?.financial_email}</span>
                </label>
              </FormRow>
              <FormRow>
                <label>
                  <b>STATUS</b>
                  <span>{estate?.status && getStatus(estate?.status)}</span>
                </label>
                <label>
                  <b>MÉDIA DE IMÓVEIS NA CARTEIRA</b>
                  <span>{estate?.average_properties_wallet}</span>
                </label>
                <label>
                  <b>CAPACIDADE DE LOC. MENSAIS</b>
                  <span>{estate?.monthly_tenantry_capacity}</span>
                </label>
              </FormRow>
              <FormRow>
                <label>
                  <b>GESTOR DE CONTAS</b>
                  <span>{estate?.user?.name}</span>
                </label>
                <label>
                  <b>EMPRESA RESPONSÁVEL</b>
                  <span>{estate?.responsible_company === 'REDE_CONFIAX' ? 'Rede Confiax' : 'Rede Vistorias'}</span>
                </label>
                <label>
                  <b>GESTOR DE CONTAS (ADICIONAL)</b>
                  <span>{estate?.additional_account_manager?.name}</span>
                </label>
              </FormRow>
              <FormRow>
                <label>
                  <b>OBSERVAÇÕES DA PARCERIA</b>
                  <span>{estate?.observations}</span>
                </label>
              </FormRow>
            </FormContent>
          </FormContainer>
          <FormContainer>
            <FormTitle>E-MAILS</FormTitle>
            <Line />
            <FormContent>
              <FormRow>
                {estate?.additional_emails?.split(';').map((item, index) => {
                  if (item !== '')
                    return (
                      <label>
                        <b>EMAIL ADICIONAL {index + 1}</b>
                        <span>{item}</span>
                      </label>
                    );

                  return <></>;
                })}
              </FormRow>
            </FormContent>
          </FormContainer>
          <FormContainer>
            <FormTitle>ENDEREÇO DA IMOBILIÁRIA</FormTitle>
            <Line />
            <FormContent>
              <FormRow>
                <label>
                  <b>CEP</b>
                  <span>{estate?.estate_zip_code}</span>
                </label>
                <label>
                  <b>LOGRADOURO</b>
                  <span>{estate?.estate_street}</span>
                </label>
                <label>
                  <b>BAIRRO</b>
                  <span>{estate?.estate_district}</span>
                </label>
              </FormRow>
              <FormRow>
                <label>
                  <b>CIDADE</b>
                  <span>{estate?.estate_city}</span>
                </label>
                <label>
                  <b>UF</b>
                  <span>{estate?.estate_state}</span>
                </label>
                <label>
                  <b>NÚMERO</b>
                  <span>{estate?.estate_number}</span>
                </label>
              </FormRow>
              <FormRow>
                <label>
                  <b>COMPLEMENTO</b>
                  <span>{estate?.estate_complement}</span>
                </label>
              </FormRow>
            </FormContent>
          </FormContainer>
          <FormContainer>
            <FormTitle>SEGURADORAS ATIVAS</FormTitle>
            <Line />
            <FormContent style={{ position: 'relative', padding: '40px 14px' }}>
              <InsurerGrid>
                <Grid.Row style={insurerGridStyles.Header}>
                  <Grid.Column width={6}>
                    <b>SEGURADORA</b>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <b>SEGURO INCÊNDIO</b>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <b>TÍTULO DE CAPITALIZAÇÃO</b>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <b>SEGURO FIANÇA</b>
                  </Grid.Column>
                </Grid.Row>
                {insurersDataFormalized?.map(insurerData => {
                  return (
                    <Grid.Row style={insurerGridStyles.Row}>
                      <Grid.Column
                        width={6}
                        style={insurerGridStyles.InsurerInfoContainer}
                      >
                        <img
                          src={insurerData?.image}
                          className="logo"
                          alt="Insurer logo"
                        />
                        <div className="insurer-content">
                          <span className="insurer-name">{insurerData?.name}</span>
                        </div>
                      </Grid.Column>
                      <Grid.Column width={3} style={insurerGridStyles.IconContainer}>
                        {insurerData.hasFireInsurance ? (
                          <Icon
                            name="check circle"
                            style={insurerGridStyles.EnabledIcon}
                            size="large"
                          />
                        ) : (
                          <Icon
                            name="stop circle"
                            style={insurerGridStyles.DisabledIcon}
                            size="large"
                          />
                        )}
                        {insurerData.hasFireInsurance && (
                          <AuthorizationContainer
                            action={ActionEnum.seeTaxes}
                            feature={FeatureEnum.insurers}
                          >
                            <div style={{ position: 'relative' }}>
                              <small onClick={() => handleOpenFireInsurerDetails(insurerData)}>
                                Detalhes
                              </small>
                            </div>
                          </AuthorizationContainer>
                        )}
                      </Grid.Column>
                      <Grid.Column width={4} style={insurerGridStyles.IconContainer}>
                        {insurerData.hasCapitalization ? (
                          <Icon
                            name="check circle"
                            style={insurerGridStyles.EnabledIcon}
                            size="large"
                          />
                        ) : (
                          <Icon
                            name="stop circle"
                            style={insurerGridStyles.DisabledIcon}
                            size="large"
                          />
                        )}
                      </Grid.Column>
                      <Grid.Column width={3} style={insurerGridStyles.IconContainer}>
                        {insurerData.hasInsurance ? (
                          <Icon
                            name="check circle"
                            style={insurerGridStyles.EnabledIcon}
                            size="large"
                          />
                        ) : (
                          <Icon
                            name="stop circle"
                            style={insurerGridStyles.DisabledIcon}
                            size="large"
                          />
                        )}
                        {insurerData.hasInsurance && (
                          <AuthorizationContainer
                            action={ActionEnum.seeTaxes}
                            feature={FeatureEnum.insurers}
                          >
                            <div style={{ position: 'relative' }}>
                              <small onClick={() => handleOpenTaxesModal(insurerData)}>
                                Detalhes
                              </small>
                            </div>
                          </AuthorizationContainer>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  );
                })}

                {isModalVisible && insurerDataSelected && (
                  <InsurerTaxesModal
                    insurerData={insurerDataSelected}
                    onClose={() => handleCloseTaxesModal()}
                  />
                )}

                {isFireInsurerModalVisible && insurerDataSelected && (
                  <AlfaFireInsuranceDetails
                    fireInsurerData={insurerDataSelected}
                    onClose={() => handleCloseFireInsurerDetailsl()}
                  />
                )}

              </InsurerGrid>
            </FormContent>
          </FormContainer>
          <FormContainer>
            <FormTitle>DADOS BANCÁRIOS</FormTitle>
            <Line />
            <FormContent>
              <FormRow>
                <label>
                  <b>BANCO</b>
                  <span>{bankSelected?.text}</span>
                </label>
                <label>
                  <b>AGÊNCIA</b>
                  <span>
                    {estate?.bank_account?.agency_number}-
                    {estate?.bank_account?.agency_dv}
                  </span>
                </label>
                <label>
                  <b>CONTA</b>
                  <span>
                    {estate?.bank_account?.account_number}-
                    {estate?.bank_account?.account_dv}
                  </span>
                </label>
              </FormRow>
              <InfoDisclaimer>
                <div className="disclaimer-content">
                  <Icon className="icon" name="exclamation circle" />
                  <p>
                    Confira as informações acima e caso encontre divergências, entre em
                    contato com o seu gestor de contas ou atendimento para que seja feita
                    a devida correção.
                  </p>
                </div>
                <div className="disclaimer-info">
                  <p>
                    Criado em:{' '}
                    {estate?.created_at ? DateToStringDate(estate?.created_at) : ''}
                  </p>
                </div>
              </InfoDisclaimer>
            </FormContent>
          </FormContainer>
        </Form>
      </Container>
    </Modal>
  );
};

export default ViewModal;
