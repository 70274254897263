import Swal from 'sweetalert2'
import './styles.css'
import React, { useEffect, useState } from "react";
import {
  Button,
  Header,
  Icon,
  Modal,
  Step,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import { post } from "../../../../../../../services/request";
import {
  HireProposalModalProps,
} from "../../../types/ModalHireProposalProps";
import { SurtyGeneralInsuranceFormik } from "../Formik/SurtyFormFormik";
import {
  dangerNotification,
  successNotification,
} from "../../../../../../../services/notification";
import withReactContent from 'sweetalert2-react-content'
import { InsurersCodeEnum } from '../../../../../../../enums/insurers-code.enum';
import { hasPermission } from '../../../../../../../components/AuthorizationContainer';
import { ActionEnum } from '../../../../../../../enums/authz-action.enum';
import { FeatureEnum } from '../../../../../../../enums/authz-feature.enum';

const GeneralHireProposalModal = ({
  username,
  analysisID,
  maxInstallments,
  nominal_total_premium_amount,
  naturalTentant,
  closeModal,
  setOpen,
  insurer,
  property,
  bondInsurance,
  rentOnly,
  callback,
  user_id,
  open,
  cleanIdReferences
}: HireProposalModalProps) => {
  const [openHireProposalModal, setOpenHireProposalModal] = useState(false);
  const [flagSubmitFrom, setFlagSubmitFrom] = useState(false);
  const [stepOfHireModal, setStepOfHireModal] = useState(
    "hireGeneralProposal"
  );
  const [loading, setLoading] = useState(false)
  const [userCanContract, setUserCanContract] = useState(false);

  useEffect(() => {
    const has = hasPermission(ActionEnum.contract, FeatureEnum.analysis);
    setUserCanContract(has);
  }, []);

  const MySwal = withReactContent(Swal)

  async function handleSubmit(payload: any) {
    try {
      setLoading(true);
      const response = await post("/bond-insurance-analysis/general/hire", { ...payload, username, userId: user_id });
      console.log(response)
      successNotification("Sucesso", "Registro concluido com sucesso.");
    } catch (err) {
      dangerNotification("Erro", "Erro ao efetuar solicitação.", 4000);
      console.log(err);
    } finally {
      callback()
      setLoading(false);
      setFlagSubmitFrom(false);
      closeModal();
      setOpenHireProposalModal(false);
    }
  }

  const AcceptTerms = () => MySwal.fire({
    title: `Deseja prosseguir com a contratação em <b>${insurer?.name}</b> ?`,
    text: "Importante: A cláusula deverá ser inserida no Contrato de Locação e os dados informados para essa contratação deverão estar inseridos e de acordo com o Contrato de Locação.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Sim, prosseguir para a contratação!',
    cancelButtonText: 'Cancelar'
  }).then((result) => {
    if (result.isConfirmed) {
      (async () => {
        const { value: checked } = await MySwal.fire({
          title: 'Declaro que: ',
          html:
            `<div class="row"><input id="swal-input1" type="checkbox" class="checkbox"> <label for="swal-input1">Os dados informados para essa contratação estão de acordo e inseridos no Contrato de Locação; Qualquer alteração realizada nesta etapa, poderá afetar o orçamento realizado.</label> </div>
              <div class="row"><input id="swal-input2" type="checkbox" class="checkbox"> <label for="swal-input2">A Cláusula foi inserida no Contrato de Locação. </label> </div>
              ${insurer!.code === InsurersCodeEnum.Garantti ? '<div class="row"><input id="swal-input3" type="checkbox" class="checkbox"> <label for="swal-input3">O Contrato Locatário Garantti está assinado pelo locatário. </label> </div>' : ''}
              <div class="row"><input id="swal-input4" type="checkbox" class="checkbox"> <label for="swal-input4"> Declaro que essa locação não tem débitos em aberto até esta data. </label> </div>`,
          focusConfirm: false,
          confirmButtonText:
            'Ciente e prosseguir',
          denyButtonText: 'Cancelar',
          preConfirm: () => {
            return [
              (document.getElementById('swal-input1') as HTMLInputElement).checked ? 'true' : 'false',
              (document.getElementById('swal-input2') as HTMLInputElement)?.checked ? 'true' : 'false',
              (document.getElementById('swal-input3') as HTMLInputElement)?.checked ? 'true' : 'false',
              (document.getElementById('swal-input4') as HTMLInputElement)?.checked ? 'true' : 'false',
            ]
          },
        })
        console.log(checked)
        if (Array.isArray(checked) && checked[0] === 'true' && checked[1] === 'true' && (insurer!.code === InsurersCodeEnum.Garantti  && checked[2] === 'true'|| insurer!.code !== InsurersCodeEnum.Garantti) && checked[3] === 'true') {
          setOpenHireProposalModal(true)
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Para continuar, deve aceitar os termos!',
          })
        }
      })()
    }
  })

  return (
    <div style={{ outline: 'none', flex: 'auto' }}>
      <Modal
        closeIcon
        open={openHireProposalModal}
        onClose={
          () => setOpenHireProposalModal(false)
        }
        trigger={
          <Button
            disabled={!userCanContract}
            positive
            fluid
          >
            Contratar
          </Button>
        }
        onOpen={AcceptTerms}
      >
        <Header icon="file alternate outline" content={`Contratação Fiança | ${insurer?.name}`} />
        <Modal.Content>
          <Dimmer active={loading}>
            <Loader indeterminate> Salvando proposta... </Loader>
          </Dimmer>
          <Step.Group>
            <Step
              onClick={() => setStepOfHireModal("hireGeneralProposal")}
              active={stepOfHireModal === "hireGeneralProposal"}
              title={`Contratação seguro fiança | ${insurer?.name}`}
              description=""
            />
          </Step.Group>
          {stepOfHireModal === "hireGeneralProposal" && (
            <SurtyGeneralInsuranceFormik
              rentOnly={rentOnly}
              maxInstallments={maxInstallments}
              nominal_total_premium_amount={nominal_total_premium_amount}
              naturalTentant={naturalTentant}
              property={property}
              flagSubmit={flagSubmitFrom}
              setFlagSubmit={setFlagSubmitFrom}
              callbackSubmit={handleSubmit}
              bondInsurance={bondInsurance}
              analysisID={analysisID}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={() => setOpenHireProposalModal(false)}>
            <Icon name="remove" /> Sair
          </Button>
          <Button color="green" onClick={() => setFlagSubmitFrom(true)}>
            <Icon name="checkmark" />
            Confirmar
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
export default GeneralHireProposalModal;
