import { FilterData, ListingRouteInfo } from "./types";
import BondInsurancesListing from "./BondInsurances";
import { iRootState, iRootDispatch } from "../../store";
import { FormikProps, withFormik } from "formik";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import {AvailableBrokersAndEstatesState} from "../../store/types/temp-types";


const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) => dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers),
})

export type BondInsuranceListProps = ReturnType<typeof mapDispatch>
  & ReturnType<typeof mapState>
  & FormikProps<FilterData>
  & RouteComponentProps<ListingRouteInfo>;

const BondInsurancesForm = withFormik<BondInsuranceListProps, FilterData>({

  mapPropsToValues: () => {
    return {
      broker_id: '',
      estate_id: '',
      user_id: ''
    }
  },

  validationSchema: {},

  handleSubmit: () => {
  },

})(BondInsurancesListing);

export default connect(mapState, mapDispatch)(BondInsurancesForm);
