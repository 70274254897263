import React, { useEffect, useState } from 'react';
import { Button, Icon, Modal, Step, Loader, Dimmer, Header } from 'semantic-ui-react';

import { Formik } from 'formik';
import CapitalizationInfoStep, { DropdownOptions } from '../Steps/CapitalizationInfoStep';
import {
  dangerNotification,
  successNotification,
  warningNotification
} from '../../../../../services/notification';
import { connect } from 'react-redux';
import { iRootDispatch, iRootState } from '../../../../../store';
import { AvailableBrokersAndEstatesState } from '../../../../../store/types/temp-types';
import { CustoDropDownItemProps } from '../../../../../components/types';
import { useHistory } from 'react-router-dom';
import { CreateCapitalizationType } from '../Formik/types/CreateCapitalizationInitialValuesTypes';
import { CreateCapitalizationInitialInfo } from '../Formik/InitialValues/CreateCapitalizationInitialValues';
import { createCapitalization } from '../../../../../services/capitalization';
import CapitalizationPropertyStep from '../Steps/CapitalizationPropertyStep';
import CapitalizationTenantStep from '../Steps/CapitalizationTenantStep';
import { CapitalizationStatusEnum } from '../../../enums/capitalization-status.enum';
import { UserRolesEnum } from '../../../../../enums/user-roles.enum';
import { get } from '../../../../../services/storage';

type ModalContentType = {
  children: React.ReactNode;
  values: CreateCapitalizationType;
};

const CreateCapitalizationModal = (props: {
  availableBrokersAndEstates: any;
  insurers: DropdownOptions[];
}) => {
  const { insurers } = props;
  const estates: CustoDropDownItemProps[] = props.availableBrokersAndEstates.estates;
  const [openHireProposalModal, setOpenHireProposalModal] = useState(false);
  const [stepOfHireModal, setStepOfHireModal] = useState('capitalization_info');
  const [loading, setLoading] = useState(false);
  const role = get('role');
  const history = useHistory();

  function handleCloseModal() {
    setOpenHireProposalModal(false);
    history.push('capitalizations');
  }

  function handleOpenModal() {
    setStepOfHireModal('capitalization_info');
    setOpenHireProposalModal(true);
  }

  async function handleNextStep(values: CreateCapitalizationType) {
    switch (stepOfHireModal) {
      case 'capitalization_info': {
        let error = false;

        if (
          role === UserRolesEnum.brokerAdmin ||
          role === UserRolesEnum.accountManager ||
          role === UserRolesEnum.brokerAnalyst
        ) {
          if (!values.estateId) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, selecione a imobiliária para continuar!'
            );
            error = true;
          }

          if (!values.status) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, selecione o status inicial para continuar!'
            );
            error = true;
          }

          if (!values.insurerId) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, selecione a seguradora para continuar!'
            );
            error = true;
          }
        }

        if (!values.lessorDocument) {
          warningNotification(
            'Ops...',
            'Por favor, informe o documento do proprietário!'
          );
          error = true;
        }

        if (!values.lessorName) {
          warningNotification('Ops...', 'Por favor, informe o nome do proprietário!');
          error = true;
        }

        if (!values.rentalValue || values.rentalValue <= 0) {
          warningNotification('Ops...', 'Por favor, informe o valor da locação!');
          error = true;
        }

        if (!values.multiplier) {
          warningNotification('Ops...', 'Por favor, selecione o multiplicador!');
          error = true;
        }

        if (!values.plan) {
          warningNotification('Ops...', 'Por favor, selecione um plano para continuar!');
          error = true;
        }

        if (!values.totalValue || values.totalValue <= 0) {
          warningNotification(
            'Ops...',
            'Por favor, insira o valor do título para continuar!'
          );
          error = true;
        }

        if (values.totalValue && values.totalValue < 1000) {
          warningNotification(
            'Valor mínimo do título',
            'O valor do título deve ser de no mínimo R$ 1.000,00!'
          );
          error = true;
        }

        if (!error) {
          setStepOfHireModal('capitalization_property');
        }
        return;
      }
      case 'capitalization_property': {
        let error = false;

        if (!values.property.zipCode) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o CEP do imóvel para continuar!'
          );
          error = true;
        }

        if (!values.property.state) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o ESTADO do imóvel para continuar!'
          );
          error = true;
        }

        if (!values.property.city) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira a CIDADE do imóvel para continuar!'
          );
          error = true;
        }

        if (!values.property.district) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o BAIRRO do imóvel para continuar!'
          );
          error = true;
        }

        if (!values.property.street) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o LOGRADOURO do imóvel para continuar!'
          );
          error = true;
        }

        if (!values.property.number) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o NÚMERO do imóvel para continuar!'
          );
          error = true;
        }

        if (!values.property.complement && !values.property.hasComplement) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, caso não haja complemento, marque o checkbox SEM COMPLEMENTO!'
          );
          error = true;
        }

        if (!values.property.propertyType) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o Tipo do Imóvel para continuar!'
          );
          error = true;
        }

        if (!values.locationType) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o Tipo da Locação para continuar!'
          );
          error = true;
        }

        if (!error) {
          setStepOfHireModal('capitalization_tenant');
        }
        return;
      }
      case 'capitalization_tenant': {
        let error = false;

        if (!values.tenant.document) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o DOCUMENTO do inquilino para continuar!'
          );
          error = true;
        }

        if (values.tenant.document === values.lessorDocument) {
          warningNotification(
            'Atenção',
            'O Locatário não pode ser o proprietário do imóvel!'
          );
          error = true;
        }

        if (!values.tenant.name) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o NOME/RAZÃO SOCIAL do inquilino para continuar!'
          );
          error = true;
        }

        const isPJ = values.tenant.document.length > 14;
        if (isPJ) {
          if (
            !values.tenant.companyAnnualRevenue ||
            values.tenant.companyAnnualRevenue <= 0
          ) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira o FATURAMENTO ANUAL do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.companyRepresentativeName) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira o NOME DO REPRESENTANTE da empresa do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.companyRepresentativeDocument) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira o DOCUMENTO DO REPRESENTANTE da empresa do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.companyRepresentativeCellphone) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira o TELEFONE DO REPRESENTANTE da empresa do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.constitutionDate) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira a DATA DE CONSTITUIÇÃO da empresa do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.municipalRegistration && !values.tenant.hasMunicipalRegistration) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira a INSCRIÇÃO MUNICIPAL da empresa do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.stateRegistration && !values.tenant.hasStateRegistration) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira a INSCRIÇÃO ESTADUAL da empresa do inquilino para continuar!'
            );
            error = true;
          }
        } else {
          if (!values.tenant.birthDate) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira a DATA DE NASCIMENTO do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.maritalStatus) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira o ESTADO CIVIL do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.nationality) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira a NACIONALIDADE do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.profession) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira a PROFISSÃO do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.identityDocument) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira o RG do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.identityDocumentDate) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira a DATA DE EMISSÃO do RG do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.identityDocumentUf) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira a UF de emissão do RG do inquilino para continuar!'
            );
            error = true;
          }

          if (!values.tenant.identityDocumentIssuance) {
            warningNotification(
              'Campo obrigatório',
              'Por favor, insira a ORGÃO EMISSOR do RG do inquilino para continuar!'
            );
            error = true;
          }
        }
        if (!values.tenant.cellphone) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o TELEFONE do inquilino para continuar!'
          );
          error = true;
        }

        if (!values.tenant.email) {
          warningNotification(
            'Campo obrigatório',
            'Por favor, insira o EMAIL do inquilino para continuar!'
          );
          error = true;
        }

        if (error) {
          return;
        }

        await submitCapitalization(values);
      }
    }
  }

  async function handleBackStep() {
    switch (stepOfHireModal) {
      case 'capitalization_info': {
        handleCloseModal();
        return;
      }
      case 'capitalization_property': {
        setStepOfHireModal('capitalization_info');
        return;
      }
      case 'capitalization_tenant': {
        setStepOfHireModal('capitalization_property');
        return;
      }
    }
  }

  async function submitCapitalization(values: CreateCapitalizationType) {
    setLoading(true);
    try {
      const status =
        values.status && ((CapitalizationStatusEnum as any)[values.status] as string);
      await createCapitalization({ ...values, status });
      successNotification('Sucesso', 'Criação de capitalização realizada com sucesso!');
    } catch (err) {
      dangerNotification('Erro', 'Erro ao efetuar criação de capitalização');
      console.log(err);
    } finally {
      setLoading(false);
      handleCloseModal();
    }
  }

  const ModalContent = ({ children, values }: ModalContentType) => (
    <>
      <Modal.Content scrolling>
        <Dimmer active={loading}>
          <Loader indeterminate> Comunicando solicitação... </Loader>
        </Dimmer>

        <div style={{ width: '100%' }}>
          <Step.Group widths={3}>
            <Step
              active={stepOfHireModal === 'capitalization_info'}
              title="Informações Gerais"
              description="Informações sobre valores e proprietário"
              icon={{ color: 'grey', name: 'address card' }}
            />
            <Step
              active={stepOfHireModal === 'capitalization_property'}
              title="Dados do imóvel"
              description="Dados do imóvel da locação"
              icon={{ color: 'grey', name: 'home' }}
            />

            <Step
              active={stepOfHireModal === 'capitalization_tenant'}
              title="Informações do locatário"
              description="Dados do locatário"
              icon={{ color: 'grey', name: 'user' }}
            />
          </Step.Group>
        </div>

        {children}
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={() => handleBackStep()}>
          <Icon name="remove" />
          {stepOfHireModal === 'capitalization_info' ? 'Sair' : 'Voltar'}
        </Button>
        <Button color="green" onClick={() => handleNextStep(values)}>
          <Icon name="checkmark" />{' '}
          {stepOfHireModal === 'capitalization_tenant' ? 'Solicitar' : 'Próximo'}
        </Button>
      </Modal.Actions>
    </>
  );

  return (
    <div>
      <Modal
        style={{ height: 'auto', width: '90vw', zIndex: 10 }}
        closeIcon
        open={openHireProposalModal}
        trigger={
          <Button primary icon="hotjar">
            Contrate seu título de capitalização
          </Button>
        }
        onOpen={() => handleOpenModal()}
        onClose={() => handleCloseModal()}
      >
        <Header style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <Icon name={'file alternate outline'} /> Cadastro de capitalização
          </div>
        </Header>
        <Formik
          initialValues={CreateCapitalizationInitialInfo}
          validationSchema={() => true}
          onSubmit={() => true}
        >
          {innerValues => {
            switch (stepOfHireModal) {
              case 'capitalization_info':
                return (
                  <ModalContent
                    values={innerValues.values}
                    children={
                      <CapitalizationInfoStep
                        {...innerValues}
                        estates={estates}
                        insurers={insurers}
                      />
                    }
                  />
                );
              case 'capitalization_property':
                return (
                  <ModalContent
                    values={innerValues.values}
                    children={<CapitalizationPropertyStep {...innerValues} />}
                  />
                );
              case 'capitalization_tenant':
                return (
                  <ModalContent
                    values={innerValues.values}
                    children={<CapitalizationTenantStep {...innerValues} />}
                  />
                );
            }
          }}
        </Formik>
      </Modal>
    </div>
  );
};

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

export default connect(mapState, mapDispatch)(CreateCapitalizationModal);
