export enum BondInsuranceAnalysisStatusEnum {
  proposal = 'PROPOSAL',
  analysis = 'ANALYSIS',
  approved = 'APPROVED',
  preapproved = 'PREAPPROVED',
  error = 'ERROR',
  pending = 'PENDING',
  rejected = 'REJECTED',
  undefined = 'UNDEFINED',
  waiting = 'WAITING',
  biometry = 'BIOMETRY',
}
