import * as Yup from "yup";
import {GREATER_THAN_ZERO_MESSAGE, REQUIRED_FIELD_MESSAGE} from "../constants";

export const insuranceDataSectionYupSchema: Yup.ObjectSchema<InsuranceDataSectionSchema> = Yup.object().shape({
  brokerId: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  estateId: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  insurerId: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  propertyTypeId: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  occupationId: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  assistanceId: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  rentValue: Yup.string().required(REQUIRED_FIELD_MESSAGE).greaterThanZero(GREATER_THAN_ZERO_MESSAGE),
})

export type InsuranceDataSectionSchema = {
  brokerId: string;
  estateId: string;
  insurerId: string;
  propertyTypeId: string;
  occupationId: string;
  assistanceId: string;
  rentValue: string;
}
