import React, { SyntheticEvent, useState, useEffect, useCallback } from "react";
import GaranttiTaxesModal from "./modal/GaranttiTaxesModal";
import { GaranttiTaxesModalProps } from "./modal/types";
import { Grid, Image, Icon, Button, Radio } from "semantic-ui-react";
import { Field } from "formik";
import { FormikErrorMessage } from "../../../components/ErrorMessage";
import { FieldLabel } from "../../../styles";
import { EstateFormProps } from "../UpdateEstateForm";
import Dropdown from "../../../components/Dropdown";
import { CustoDropDownItemProps } from "../../../components/types";
import { dangerNotification } from "../../../services/notification";
import { list } from "../../../services/insurer";
import Toggle from "../../../components/Toggle";
import {
  InsurersConfigWrapper,
  InsurersImageWrapper,
  PositionWrapper,
} from "../styles";
import {
  InsurerForUpdateEstateForm,
} from "../types";
import { InsurersCodeEnum } from "../../../enums/insurers-code.enum";
import Input from "../../../components/Input";
import { AnalysisAssuranceEnum } from "../../../enums/analysis-assurance.enum";
import { ReactSortable } from "react-sortablejs";
import { GaranttiTariffPlanEnum } from "../../../enums/garantti-tariffplan.enum";
import { getEstateData } from "../../../services/estate";
import { hasPermission } from "../../../components/AuthorizationContainer";
import { ActionEnum } from "../../../enums/authz-action.enum";
import { FeatureEnum } from "../../../enums/authz-feature.enum";
import { CalculationTypeEnum } from "../../../enums/calculation-type.enum";

export const BondInsuranceData = (props: EstateFormProps) => {
  const [clientData, setClientData] = useState<GaranttiTaxesModalProps["clientData"] | null>(null);
  const [clientId, setClientId] = useState('');
  const { handleInputChange, values, setFieldValue } = props;
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [availableInsurers, setAvailableInsurers] = useState<any[]>([]);
  const [isGaranttiSelected, setIsGaranttiSelected] = useState<boolean>(false);
  const [garanttiSelectedPlan, setGaranttiSelectedPlan] = useState<string>("");
  const [selectedInsurer, setSelectedInsurer] = useState<any>();

  const fetchGaranttiTaxes = useCallback(async () => {
    if (props.values.garanttiCode) {
      try {
        setIsLoading(true);
        const data = await getEstateData(props.values.garanttiCode);
        setClientData(data);
        setIsModalOpen(true);
      } catch (error) {
        dangerNotification(
          "Oops...",
          "Não foi possível buscar os dados da imobiliária"
        );
      } finally {
        setIsLoading(false);
      }
    }
  }, [props.values.garanttiCode]);

  const [userCanEdit, setUserCanEdit] = useState<boolean>(false);

  useEffect(() => {
    const has = hasPermission(ActionEnum.update, FeatureEnum.insurers);
    setUserCanEdit(has);
  }, []);

  const pottencialMultiplesOptions: CustoDropDownItemProps[] = [
    { key: 0, text: "Indefinido", value: 0 },
    { key: 3, text: "3", value: 3 },
    { key: 6, text: "6", value: 6 },
    { key: 12, text: "12", value: 12 },
    { key: 15, text: "15", value: 15 },
    { key: 18, text: "18", value: 18 },
    { key: 24, text: "24", value: 24 },
    { key: 30, text: "30", value: 30 },
    { key: 45, text: "45", value: 45 },
  ];

  const garanttiLMIOptions: CustoDropDownItemProps[] = [
    { key: 0, text: "Indefinido", value: 0 },
    { key: 12, text: "12X", value: 12 },
    { key: 18, text: "18X", value: 18 },
    { key: 20, text: "20X", value: 20 },
    { key: 30, text: "30X", value: 30 },
    { key: 36, text: "36X", value: 36 },
    { key: 40, text: "40X", value: 40 },
  ];

  const pottencialAssuranceOptions: CustoDropDownItemProps[] = [
    { key: 1, text: "Simples", value: AnalysisAssuranceEnum.simple },
    { key: 2, text: "Completo", value: AnalysisAssuranceEnum.complete },
  ];

  const garanttiTariffOptions: CustoDropDownItemProps[] = [
    {
      key: 1,
      text: "Gold - 10% (LMI 30x)",
      value: GaranttiTariffPlanEnum.gold,
    },
    {
      key: 2,
      text: "Platinum - 12% (LMI 36x)",
      value: GaranttiTariffPlanEnum.platinum,
    },
    { key: 3, text: "Black - 15% (LMI 40X)", value: GaranttiTariffPlanEnum.black },
    { key: 4, text: "Customizado", value: GaranttiTariffPlanEnum.custom },
  ];

  useEffect(() => {
    const hasPortoEssencial = values?.bondInsurers?.find((i: any) =>
      i.code.includes(InsurersCodeEnum.PortoEssencial)
    );

    if (hasPortoEssencial) {
      setFieldValue("portoCode", props.values.portoCode);
    }

    const hasGarantti = values?.bondInsurers?.find((i: any) =>
      i.code.includes(InsurersCodeEnum.Garantti)
    );

    if (hasGarantti) {
      setFieldValue("garanttiCode", props.values.garanttiCode);
    }

    setIsLoading(true);

    list({ availability: "BOND_INSURANCE" })
      .then((result) => {
        /// order result array by property orderPreference
        const hasOrder: any[] = [];
        const notHasOrder: any[] = [];

        if (result) {
          result.forEach((insurer) => {
            let order = 0;
            if (insurer.code === InsurersCodeEnum.PortoEssencial) {
              order =
                values.insurerSpecificData?.portoEssencial?.bondInsurance
                  ?.orderPreference;
            } else if (insurer.code === InsurersCodeEnum.Garantti) {
              order =
                values.insurerSpecificData?.garantti?.bondInsurance
                  ?.orderPreference;
            } else if (insurer.code === InsurersCodeEnum.Pottencial) {
              order =
                values.insurerSpecificData?.pottencial?.bondInsurance
                  ?.orderPreference;
            } else if (insurer.code === InsurersCodeEnum.Too) {
              order =
                values.insurerSpecificData?.tooSeguros?.bondInsurance
                  ?.orderPreference;
            } else if (insurer.code === InsurersCodeEnum.TokioMarine) {
              order =
                values.insurerSpecificData?.tokioMarine?.bondInsurance
                  ?.orderPreference;
            } else if (insurer.code === InsurersCodeEnum.Porto) {
              order =
                values.insurerSpecificData?.portoTradicional?.bondInsurance
                  ?.orderPreference;
            }
            insurer.orderPreference = order;
            if (order > 0) {
              hasOrder.push(insurer);
            } else {
              notHasOrder.push(insurer);
            }
          });
        }
        // let hasOrder = result.filter(c => c.orderPreference !== null && c.orderPreference > 0)
        // let notHasOrder = result.filter(c => c.orderPreference === null || c.orderPreference === 0)
        hasOrder.sort((a, b) => {
          return a.orderPreference - b.orderPreference;
        });
        setAvailableInsurers([...hasOrder, ...notHasOrder]);
      })
      .catch((error) => {
        dangerNotification(
          "Oops...",
          "Não foi possível buscar as seguradoras disponíveis"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsGaranttiSelected(
      props.values.isGaranttiBondInsuranceSelected ||
      values?.bondInsurers?.find(
        (i: any) => i.code === InsurersCodeEnum.Garantti
      )
    );

    setGaranttiSelectedPlan(
      props.values.insurerSpecificData.garantti.bondInsurance.garanttiPlan
    );
    setFieldValue(
      "insurerSpecificData.garantti.bondInsurance.tariffPlan",
      props.values.insurerSpecificData.garantti.bondInsurance.garanttiPlan
    );
  }, []);

  const handleToggle = async (e: SyntheticEvent, value: any) => {
    if (value) {
      const insurer = availableInsurers.find((i) => i.id == value.name);
      if (value.checked && insurer) {
        if (insurer.code === InsurersCodeEnum.Too) {
          setFieldValue("isTooSegurosBondInsuranceSelected", true);
        }

        if (insurer.code === InsurersCodeEnum.TokioMarine) {
          setFieldValue("isTokioMarineBondInsuranceSelected", true);
        }

        if (insurer.code === InsurersCodeEnum.Garantti) {
          setIsGaranttiSelected(true);
          setFieldValue("isGaranttiBondInsuranceSelected", true);
        }

        if (insurer.code === InsurersCodeEnum.Pottencial) {
          setFieldValue("isPottencialBondInsuranceSelected", true);
        }

        if (insurer.code === InsurersCodeEnum.Porto12) {
          setFieldValue("isPorto12xBondInsuranceSelected", true);
        }

        if (insurer.code === InsurersCodeEnum.Porto18) {
          setFieldValue("isPorto18xBondInsuranceSelected", true);
        }

        if (insurer.code === InsurersCodeEnum.Porto20) {
          setFieldValue("isPorto20xBondInsuranceSelected", true);
        }

        if (insurer.code === InsurersCodeEnum.Porto30) {
          setFieldValue("isPorto30xBondInsuranceSelected", true);
        }

        if (insurer.code === InsurersCodeEnum.PortoEssencial) {
          setFieldValue("isPortoEssencialBondInsuranceSelected", true);
        }

        if (insurer.code === InsurersCodeEnum.Porto) {
          setFieldValue("isPortoTradicionalBondInsuranceSelected", true);
        }

        const insurerForForm: InsurerForUpdateEstateForm = {
          id: insurer.id,
          code: insurer.code,
          name: insurer.name,
          imageURL: insurer.image,
        };
        values.bondInsurers.push(insurerForForm);
      } else if (!value.checked && insurer) {
        if (insurer.code === InsurersCodeEnum.Too) {
          setFieldValue("isTooSegurosBondInsuranceSelected", false);
        }

        if (insurer.code === InsurersCodeEnum.TokioMarine) {
          setFieldValue("isTokioMarineBondInsuranceSelected", false);
        }

        if (insurer.code === InsurersCodeEnum.Garantti) {
          setIsGaranttiSelected(false);
          setFieldValue("isGaranttiBondInsuranceSelected", false);
        }

        if (insurer.code === InsurersCodeEnum.Pottencial) {
          setFieldValue("isPottencialBondInsuranceSelected", false);
        }

        if (insurer.code === InsurersCodeEnum.Porto12) {
          setFieldValue("isPorto12xBondInsuranceSelected", false);
        }

        if (insurer.code === InsurersCodeEnum.Porto18) {
          setFieldValue("isPorto18xBondInsuranceSelected", false);
        }

        if (insurer.code === InsurersCodeEnum.Porto20) {
          setFieldValue("isPorto20xBondInsuranceSelected", false);
        }

        if (insurer.code === InsurersCodeEnum.Porto30) {
          setFieldValue("isPorto30xBondInsuranceSelected", false);
        }

        if (insurer.code === InsurersCodeEnum.PortoEssencial) {
          setFieldValue("isPortoEssencialBondInsuranceSelected", false);
        }

        if (insurer.code === InsurersCodeEnum.Porto) {
          setFieldValue("isPortoTradicionalBondInsuranceSelected", false);
        }

        values.bondInsurers = values.bondInsurers.filter(
          (i: any) => i.id != value.name
        );
      }
      setFieldValue(`values.bondInsurers`, values.bondInsurers);
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <FieldLabel>CONFIGURAÇÕES GERAIS</FieldLabel>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column floated="left" width={4}>
          <FieldLabel>Prioridade de cotação</FieldLabel>
          <Field
            name="priority"
            disabled={!userCanEdit}
            options={[
              {
                key: "1",
                text: "Seguir Ordenação",
                value: 'ORDER',
              },
              {
                key: "2",
                text: "Seguir algoritmo 4SEG",
                value: 'ALGORITHM',
              },{
                key: "3",
                text: "Consultar todas",
                value: 'ALL',
              }
            ]}
            value={values.hasPreferenceBondInsurance}
            onChange={(event: SyntheticEvent, { value }: any) => {
              event.preventDefault();
              setFieldValue("hasPreferenceBondInsurance", value);
            }}
            component={Dropdown}
            selection
            clearable
            compact
            basic
            fluid
          />
          <FormikErrorMessage component="div" name="priority" />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={"8"}>
          <FieldLabel>SEGURADORAS DISPONÍVEIS</FieldLabel>
          <p style={{ padding: 0, color: "rgba(54, 68, 69, 0.5)" }}>
            Ative, desative e configure as seguradoras que deseja utilizar.{" "}
          </p>
        </Grid.Column>
        {selectedInsurer && (
          <Grid.Column width={"8"}>
            <FieldLabel>CONFIGURAÇÕES DA SEGURADORA</FieldLabel>
            <p style={{ padding: 0, color: "rgba(54, 68, 69, 0.5)" }}>
              Altere os parâmetros de configuração da seguradora.{" "}
            </p>
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={"8"}>
          {values.hasPreferenceBondInsurance ? (
            <ReactSortable
              list={availableInsurers}
              setList={(newState) => {
                if (newState === availableInsurers) {
                  return;
                }
                setFieldValue(
                  "insurerSpecificData.tooSeguros.bondInsurance.orderPreference",
                  newState.findIndex(
                    (i: any) => i.code === InsurersCodeEnum.Too
                  ) + 1
                );
                setFieldValue(
                  "insurerSpecificData.tokioMarine.bondInsurance.orderPreference",
                  newState.findIndex(
                    (i: any) => i.code === InsurersCodeEnum.TokioMarine
                  ) + 1
                );
                setFieldValue(
                  "insurerSpecificData.pottencial.bondInsurance.orderPreference",
                  newState.findIndex(
                    (i: any) => i.code === InsurersCodeEnum.Pottencial
                  ) + 1
                );
                setFieldValue(
                  "insurerSpecificData.garantti.bondInsurance.orderPreference",
                  newState.findIndex(
                    (i: any) => i.code === InsurersCodeEnum.Garantti
                  ) + 1
                );

                setFieldValue(
                  "insurerSpecificData.portoTradicional.bondInsurance.orderPreference",
                  newState.findIndex(
                    (i: any) => i.code === InsurersCodeEnum.Porto
                  ) + 1
                );

                setFieldValue(
                  "insurerSpecificData.portoEssencial.bondInsurance.orderPreference",
                  newState.findIndex(
                    (i: any) => i.code === InsurersCodeEnum.PortoEssencial
                  ) + 1
                );
                setAvailableInsurers(newState);
              }}
            >
              {availableInsurers.map((insurerOption: any, index: number) => (
                <div
                  key={insurerOption.id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                    marginBottom: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedInsurer(insurerOption);
                  }}
                >
                  <PositionWrapper
                    isSelected={
                      selectedInsurer && selectedInsurer.id === insurerOption.id
                    }
                  >
                    {index + 1}
                  </PositionWrapper>
                  <InsurersImageWrapper
                    isSelected={
                      selectedInsurer && selectedInsurer.id === insurerOption.id
                    }
                    isFluid
                  >
                    <Icon
                      size="large"
                      name="bars"
                      color="grey"
                      className="icon"
                    />

                    <Image src={insurerOption.image} />

                    <FieldLabel className="insurer-title">
                      <span>{insurerOption.name}</span>
                    </FieldLabel>

                    <div className="row">
                      {values.bondInsurers &&
                        values.bondInsurers.find(
                          (i: any) => i.id === insurerOption.id
                        ) ? (
                        <label style={{ color: "#2185D0" }}> Ativa </label>
                      ) : (
                        <label> Inativa </label>
                      )}
                      <Toggle
                        name={insurerOption.id}
                        id={`createEstate-bondInsurance-${insurerOption.code}`}
                        disabled={!userCanEdit}
                        onChange={handleToggle}
                        checked={
                          insurerOption.code === InsurersCodeEnum.Pottencial
                            ? values.isPottencialBondInsuranceSelected
                            : insurerOption.code === InsurersCodeEnum.Garantti
                              ? values.isGaranttiBondInsuranceSelected
                              : insurerOption.code === InsurersCodeEnum.Too
                                ? values.isTooSegurosBondInsuranceSelected
                                : insurerOption.code === InsurersCodeEnum.TokioMarine
                                  ? values.isTokioMarineBondInsuranceSelected
                                  : insurerOption.code === InsurersCodeEnum.Porto
                                    ? values.isPortoTradicionalBondInsuranceSelected
                                    : insurerOption.code ===
                                      InsurersCodeEnum.PortoEssencial
                                      ? values.isPortoEssencialBondInsuranceSelected
                                      : false
                        }
                      />
                    </div>
                  </InsurersImageWrapper>
                </div>
              ))}
            </ReactSortable>
          ) : (
            <div>
              {availableInsurers.map((insurerOption: any) => (
                <div
                  key={insurerOption.id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                    marginBottom: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedInsurer(insurerOption);
                  }}
                >
                  <InsurersImageWrapper
                    isSelected={
                      selectedInsurer && selectedInsurer.id === insurerOption.id
                    }
                  >
                    <Image src={insurerOption.image} />

                    <FieldLabel className="insurer-title">
                      <span>{insurerOption.name}</span>
                    </FieldLabel>

                    <div className="row">
                      {values.bondInsurers &&
                        values.bondInsurers.find(
                          (i: any) => i.id === insurerOption.id
                        ) ? (
                        <label style={{ color: "#2185D0" }}> Ativa </label>
                      ) : (
                        <label> Inativa </label>
                      )}
                      <Toggle
                        name={insurerOption.id}
                        id={`createEstate-bondInsurance-${insurerOption.code}`}
                        disabled={!userCanEdit}
                        onChange={handleToggle}
                        checked={
                          insurerOption.code === InsurersCodeEnum.Pottencial
                            ? values.isPottencialBondInsuranceSelected
                            : insurerOption.code === InsurersCodeEnum.Garantti
                              ? values.isGaranttiBondInsuranceSelected
                              : insurerOption.code === InsurersCodeEnum.Too
                                ? values.isTooSegurosBondInsuranceSelected
                                : insurerOption.code === InsurersCodeEnum.TokioMarine
                                  ? values.isTokioMarineBondInsuranceSelected
                                  : insurerOption.code === InsurersCodeEnum.Porto
                                    ? values.isPortoTradicionalBondInsuranceSelected
                                    : insurerOption.code ===
                                      InsurersCodeEnum.PortoEssencial
                                      ? values.isPortoEssencialBondInsuranceSelected
                                      : false
                        }
                      />
                    </div>
                  </InsurersImageWrapper>
                </div>
              ))}
            </div>
          )}
        </Grid.Column>

        <Grid.Column width={"8"}>
          {selectedInsurer && (
            <InsurersConfigWrapper>
              <div className="row">
                <Image src={selectedInsurer.image} />
                <FieldLabel className="insurer-title">
                  <span>{selectedInsurer.name}</span>
                </FieldLabel>
              </div>
              {selectedInsurer.code === InsurersCodeEnum.Garantti && (
                <Grid>
                  {props.values.garanttiCode ? (
                    <Grid.Row>
                    <Grid.Column width={10}>
                      <Button
                        onClick={fetchGaranttiTaxes}
                        disabled={!props.values.garanttiCode}
                      >
                        Ver taxas e planos da parceria
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  ) : (
                    <Grid.Row>
                    <Grid.Column width={8}>
                      <FieldLabel>Cálculo - Garantti</FieldLabel>

                      <div>
                        <Radio
                          disabled
                          style={{ marginRight: "20px" }}
                          label="Aluguel + Encargos"
                          name={`calculationType`}
                          value={CalculationTypeEnum.rentAndFees}
                          checked={
                            values.calculationType ===
                            CalculationTypeEnum.rentAndFees
                          }
                          onChange={(_, data) => {
                            setFieldValue(
                              data.name!,
                              data.value
                                ? CalculationTypeEnum.rentAndFees
                                : CalculationTypeEnum.rentOnly
                            );
                          }}
                        />

                        <Radio
                          label="Somente Aluguel"
                          name={`calculationType`}
                          value={CalculationTypeEnum.rentOnly}
                          checked={
                            values.calculationType ===
                              CalculationTypeEnum.rentOnly ||
                            values.calculationType ===
                              CalculationTypeEnum.rentAndFees
                          }
                        />
                      </div>
                    </Grid.Column>

                    <Grid.Column width={8}>
                      <FieldLabel>
                        Plano tarifário - Garantti
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.garantti.bondInsurance.tariffPlan"
                        disabled={!userCanEdit}
                        options={garanttiTariffOptions}
                        onChange={(e: SyntheticEvent, data: any) => {
                          handleInputChange(data, props);
                          setGaranttiSelectedPlan(data.value);
                          setFieldValue(
                            "insurerSpecificData.garantti.bondInsurance.garanttiPlan",
                            data.value
                          );
                          if (data.value === GaranttiTariffPlanEnum.gold) {
                            setFieldValue(
                              "insurerSpecificData.garantti.bondInsurance.pfResidentialTax",
                              "10.00"
                            );
                            setFieldValue(
                              "insurerSpecificData.garantti.bondInsurance.pfComercialTax",
                              "10.00"
                            );
                            setFieldValue(
                              "insurerSpecificData.garantti.bondInsurance.lmi",
                              30
                            );
                          }
                          if (data.value === GaranttiTariffPlanEnum.platinum) {
                            setFieldValue(
                              "insurerSpecificData.garantti.bondInsurance.pfResidentialTax",
                              "12.00"
                            );
                            setFieldValue(
                              "insurerSpecificData.garantti.bondInsurance.pfComercialTax",
                              "12.00"
                            );
                            setFieldValue(
                              "insurerSpecificData.garantti.bondInsurance.lmi",
                              36
                            );
                          }
                          if (data.value === GaranttiTariffPlanEnum.black) {
                            setFieldValue(
                              "insurerSpecificData.garantti.bondInsurance.pfResidentialTax",
                              "15.00"
                            );
                            setFieldValue(
                              "insurerSpecificData.garantti.bondInsurance.pfComercialTax",
                              "15.00"
                            );
                            setFieldValue(
                              "insurerSpecificData.garantti.bondInsurance.lmi",
                              40
                            );
                          }
                        }}
                        component={Dropdown}
                        selection
                        clearable
                        compact
                        basic
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                  )}

                  {(garanttiSelectedPlan === GaranttiTariffPlanEnum.custom && !props.values.garanttiCode) && (
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <FieldLabel>
                          Taxa Residencial (%)
                          <span style={{ color: "red" }}>*</span>
                        </FieldLabel>
                        <Field
                          name="insurerSpecificData.garantti.bondInsurance.pfResidentialTax"
                          disabled={!userCanEdit}
                          component={Input}
                          onChange={(e: SyntheticEvent, data: any) => {
                            setFieldValue(data.field.name, data.value);
                          }}
                          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value = e.target.value
                              .replace(/,/g, ".")
                              .replace(/[^\d.]/g, "");

                            if (Number(value) > 100) {
                              setFieldValue(
                                "insurerSpecificData.garantti.bondInsurance.pfResidentialTax",
                                Number(100).toFixed(2)
                              );
                              return;
                            }

                            if (Number(value) < 0) {
                              setFieldValue(
                                "insurerSpecificData.garantti.bondInsurance.pfResidentialTax",
                                0
                              );
                              return;
                            }
                            setFieldValue(
                              "insurerSpecificData.garantti.bondInsurance.pfResidentialTax",
                              Number(value).toFixed(2)
                            );
                          }}
                          type="number"
                          max={100.0}
                          min={1.0}
                          fluid
                        />
                        <FormikErrorMessage
                          component="div"
                          name="insurerSpecificData.garantti.bondInsurance.pfResidentialTax"
                        />
                      </Grid.Column>

                      <Grid.Column width={6}>
                        <FieldLabel>
                          Taxa Comercial (%)
                          <span style={{ color: "red" }}>*</span>
                        </FieldLabel>
                        <Field
                          name="insurerSpecificData.garantti.bondInsurance.pfComercialTax"
                          disabled={!userCanEdit}
                          component={Input}
                          onChange={(e: SyntheticEvent, data: any) => {
                            setFieldValue(data.field.name, data.value);
                          }}
                          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value = e.target.value
                              .replace(/,/g, ".")
                              .replace(/[^\d.]/g, "");

                            if (Number(value) > 100) {
                              setFieldValue(
                                "insurerSpecificData.garantti.bondInsurance.pfComercialTax",
                                Number(100).toFixed(2)
                              );
                              return;
                            }

                            if (Number(value) < 0) {
                              setFieldValue(
                                "insurerSpecificData.garantti.bondInsurance.pfComercialTax",
                                0
                              );
                              return;
                            }
                            setFieldValue(
                              "insurerSpecificData.garantti.bondInsurance.pfComercialTax",
                              Number(value).toFixed(2)
                            );
                          }}
                          type="number"
                          max={100.0}
                          min={1.0}
                          fluid
                        />
                        <FormikErrorMessage
                          component="div"
                          name="insurerSpecificData.garantti.bondInsurance.pfComercialTax"
                        />
                      </Grid.Column>

                      <Grid.Column width={4}>
                        <FieldLabel>LMI</FieldLabel>
                        <Field
                          name="insurerSpecificData.garantti.bondInsurance.lmi"
                          disabled={!userCanEdit}
                          options={garanttiLMIOptions}
                          onChange={(e: SyntheticEvent, data: any) =>
                            handleInputChange(data, props)
                          }
                          component={Dropdown}
                          selection
                          compact
                          basic
                          fluid
                        />
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <FieldLabel>
                        Taxa Comissão
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.garantti.bondInsurance.commissionPercentage"
                        disabled={!userCanEdit}
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, data.value);
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value
                            .replace(/,/g, ".")
                            .replace(/[^\d.]/g, "");

                          if (Number(value) > 100) {
                            setFieldValue(
                              "insurerSpecificData.garantti.bondInsurance.commissionPercentage",
                              Number(100).toFixed(2)
                            );
                            return;
                          }

                          if (Number(value) < 0) {
                            setFieldValue(
                              "insurerSpecificData.garantti.bondInsurance.commissionPercentage",
                              0
                            );
                            return;
                          }
                          setFieldValue(
                            "insurerSpecificData.garantti.bondInsurance.commissionPercentage",
                            Number(value).toFixed(2)
                          );
                        }}
                        type="number"
                        max={100.0}
                        min={1.0}
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <div>
                        <FieldLabel>Garantti Code</FieldLabel>
                      </div>
                      <Field
                        name="garanttiCode"
                        value={values.garanttiCode}
                        component={Input}
                        fluid
                        disabled
                      />
                    </Grid.Column>
                  </Grid.Row>
                  {isModalOpen && clientData ? (
                    <GaranttiTaxesModal
                      isOpen={isModalOpen}
                      setIsOpen={setIsModalOpen}
                      clientId={clientId}
                      clientData={clientData}
                      insurerOption={selectedInsurer}
                    />
                  ) : null}
                </Grid>
              )}

              {selectedInsurer.code === InsurersCodeEnum.Pottencial && (
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <FieldLabel>
                        Taxa Comissão
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.pottencial.bondInsurance.commissionPercentage"
                        disabled={!userCanEdit}
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, data.value);
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value
                            .replace(/,/g, ".")
                            .replace(/[^\d.]/g, "");

                          if (Number(value) > 100) {
                            setFieldValue(
                              "insurerSpecificData.pottencial.bondInsurance.commissionPercentage",
                              Number(100).toFixed(2)
                            );
                            return;
                          }

                          if (Number(value) < 0) {
                            setFieldValue(
                              "insurerSpecificData.pottencial.bondInsurance.commissionPercentage",
                              0
                            );
                            return;
                          }
                          setFieldValue(
                            "insurerSpecificData.pottencial.bondInsurance.commissionPercentage",
                            Number(value).toFixed(2)
                          );
                        }}
                        type="number"
                        max={100.0}
                        min={1.0}
                        fluid
                      />
                    </Grid.Column>

                    <Grid.Column width={5}>
                      <FieldLabel>
                        Multiplo
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.pottencial.bondInsurance.multiple"
                        disabled={!userCanEdit}
                        options={pottencialMultiplesOptions}
                        onChange={(e: SyntheticEvent, data: any) =>
                          handleInputChange(data, props)
                        }
                        component={Dropdown}
                        selection
                        compact
                        basic
                        fluid
                      />
                    </Grid.Column>

                    <Grid.Column width={5}>
                      <FieldLabel>
                        Tipo de coberturas
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.pottencial.bondInsurance.assurance"
                        disabled={!userCanEdit}
                        options={pottencialAssuranceOptions}
                        onChange={(e: SyntheticEvent, data: any) =>
                          handleInputChange(data, props)
                        }
                        component={Dropdown}
                        selection
                        compact
                        basic
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={8}>
                      <FieldLabel>
                        Taxa Residencial (%)
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.pottencial.bondInsurance.pfResidentialTax"
                        disabled={!userCanEdit}
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, data.value);
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value
                            .replace(/,/g, ".")
                            .replace(/[^\d.]/g, "");

                          if (Number(value) > 100) {
                            setFieldValue(
                              "insurerSpecificData.pottencial.bondInsurance.pfResidentialTax",
                              Number(100).toFixed(2)
                            );
                            return;
                          }

                          if (Number(value) < 0) {
                            setFieldValue(
                              "insurerSpecificData.pottencial.bondInsurance.pfResidentialTax",
                              0
                            );
                            return;
                          }
                          setFieldValue(
                            "insurerSpecificData.pottencial.bondInsurance.pfResidentialTax",
                            Number(value).toFixed(2)
                          );
                        }}
                        type="number"
                        max={100.0}
                        min={1.0}
                        fluid
                      />
                    </Grid.Column>

                    <Grid.Column width={8}>
                      <FieldLabel>
                        Taxa Comercial (%)
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.pottencial.bondInsurance.pfComercialTax"
                        disabled={!userCanEdit}
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, data.value);
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value
                            .replace(/,/g, ".")
                            .replace(/[^\d.]/g, "");

                          if (Number(value) > 100) {
                            setFieldValue(
                              "insurerSpecificData.pottencial.bondInsurance.pfComercialTax",
                              Number(100).toFixed(2)
                            );
                            return;
                          }

                          if (Number(value) < 0) {
                            setFieldValue(
                              "insurerSpecificData.pottencial.bondInsurance.pfComercialTax",
                              0
                            );
                            return;
                          }
                          setFieldValue(
                            "insurerSpecificData.pottencial.bondInsurance.pfComercialTax",
                            Number(value).toFixed(2)
                          );
                        }}
                        type="number"
                        max={100.0}
                        min={1.0}
                        fluid
                      />
                    </Grid.Column>

                    {/* <Grid.Column width={4}>
                          <FieldLabel>LMI
                            <span style={{ color: "red" }}>*</span></FieldLabel>
                          <Field
                            name="insurerSpecificData.pottencial.bondInsurance.lmi"
                            options={garanttiLMIOptions}
                            onChange={(e: SyntheticEvent, data: any) =>
                              handleInputChange(data, props)
                            }
                            component={Dropdown}
                            selection
                            compact
                            basic
                            fluid
                          />
                        </Grid.Column> */}
                  </Grid.Row>
                </Grid>
              )}

              {selectedInsurer.code === InsurersCodeEnum.Porto && (
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <FieldLabel>
                        Tipo de coberturas
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.portoTradicional.bondInsurance.assurance"
                        disabled={!userCanEdit}
                        options={pottencialAssuranceOptions}
                        onChange={(e: SyntheticEvent, data: any) =>
                          handleInputChange(data, props)
                        }
                        component={Dropdown}
                        selection
                        compact
                        basic
                        fluid
                      />
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <FieldLabel>
                        LMI
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.portoTradicional.bondInsurance.lmi"
                        disabled={!userCanEdit}
                        options={garanttiLMIOptions}
                        onChange={(e: SyntheticEvent, data: any) =>
                          handleInputChange(data, props)
                        }
                        component={Dropdown}
                        selection
                        compact
                        basic
                        fluid
                      />
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <FieldLabel>Código na Seguradora</FieldLabel>
                      <Field
                        name="portoCode"
                        disabled={!userCanEdit}
                        onChange={(e: SyntheticEvent, data: any) => {
                          if (data.value && data.value.length > 6) {
                            return;
                          }
                          handleInputChange(
                            { ...data, value: data.value.replace(/\D/g, "") },
                            props
                          );
                        }}
                        component={Input}
                        fluid
                      />
                      <FormikErrorMessage component="div" name="portoCode" />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <FieldLabel>
                        Taxa Comissão
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.portoTradicional.bondInsurance.commissionPercentage"
                        disabled={!userCanEdit}
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, data.value);
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value
                            .replace(/,/g, ".")
                            .replace(/[^\d.]/g, "");

                          if (Number(value) > 100) {
                            setFieldValue(
                              "insurerSpecificData.portoTradicional.bondInsurance.commissionPercentage",
                              Number(100).toFixed(2)
                            );
                            return;
                          }

                          if (Number(value) < 0) {
                            setFieldValue(
                              "insurerSpecificData.portoTradicional.bondInsurance.commissionPercentage",
                              0
                            );
                            return;
                          }
                          setFieldValue(
                            "insurerSpecificData.portoTradicional.bondInsurance.commissionPercentage",
                            Number(value).toFixed(2)
                          );
                        }}
                        type="number"
                        max={100.0}
                        min={1.0}
                        fluid
                      />
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <FieldLabel>
                        Taxa Residencial (%)
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.portoTradicional.bondInsurance.pfResidentialTax"
                        disabled={!userCanEdit}
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, data.value);
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value
                            .replace(/,/g, ".")
                            .replace(/[^\d.]/g, "");

                          if (Number(value) > 100) {
                            setFieldValue(
                              "insurerSpecificData.portoTradicional.bondInsurance.pfResidentialTax",
                              Number(100).toFixed(2)
                            );
                            return;
                          }

                          if (Number(value) < 0) {
                            setFieldValue(
                              "insurerSpecificData.portoTradicional.bondInsurance.pfResidentialTax",
                              0
                            );
                            return;
                          }
                          setFieldValue(
                            "insurerSpecificData.portoTradicional.bondInsurance.pfResidentialTax",
                            Number(value).toFixed(2)
                          );
                        }}
                        type="number"
                        max={100.0}
                        min={1.0}
                        fluid
                      />
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <FieldLabel>
                        Taxa Comercial (%)
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.portoTradicional.bondInsurance.pfComercialTax"
                        disabled={!userCanEdit}
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, data.value);
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value
                            .replace(/,/g, ".")
                            .replace(/[^\d.]/g, "");

                          if (Number(value) > 100) {
                            setFieldValue(
                              "insurerSpecificData.portoTradicional.bondInsurance.pfComercialTax",
                              Number(100).toFixed(2)
                            );
                            return;
                          }

                          if (Number(value) < 0) {
                            setFieldValue(
                              "insurerSpecificData.portoTradicional.bondInsurance.pfComercialTax",
                              0
                            );
                            return;
                          }
                          setFieldValue(
                            "insurerSpecificData.portoTradicional.bondInsurance.pfComercialTax",
                            Number(value).toFixed(2)
                          );
                        }}
                        type="number"
                        max={100.0}
                        min={1.0}
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}

              {selectedInsurer.code === InsurersCodeEnum.PortoEssencial && (
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <FieldLabel>Código na Seguradora</FieldLabel>
                      <Field
                        name="portoCode"
                        disabled={!userCanEdit}
                        onChange={(e: SyntheticEvent, data: any) => {
                          if (data.value && data.value.length > 6) {
                            return;
                          }
                          handleInputChange(
                            { ...data, value: data.value.replace(/\D/g, "") },
                            props
                          );
                        }}
                        component={Input}
                        fluid
                      />
                      <FormikErrorMessage component="div" name="portoCode" />
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <FieldLabel>
                        Tipo de coberturas
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.portoEssencial.bondInsurance.assurance"
                        disabled={!userCanEdit}
                        options={pottencialAssuranceOptions}
                        onChange={(e: SyntheticEvent, data: any) =>
                          handleInputChange(data, props)
                        }
                        component={Dropdown}
                        selection
                        compact
                        basic
                        fluid
                      />
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <FieldLabel>
                        LMI
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.portoEssencial.bondInsurance.lmi"
                        disabled={!userCanEdit}
                        options={garanttiLMIOptions}
                        onChange={(e: SyntheticEvent, data: any) =>
                          handleInputChange(data, props)
                        }
                        component={Dropdown}
                        selection
                        compact
                        basic
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <FieldLabel>
                        Taxa Comissão
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.portoEssencial.bondInsurance.commissionPercentage"
                        disabled={!userCanEdit}
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, data.value);
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value
                            .replace(/,/g, ".")
                            .replace(/[^\d.]/g, "");

                          if (Number(value) > 100) {
                            setFieldValue(
                              "insurerSpecificData.portoEssencial.bondInsurance.commissionPercentage",
                              Number(100).toFixed(2)
                            );
                            return;
                          }

                          if (Number(value) < 0) {
                            setFieldValue(
                              "insurerSpecificData.portoEssencial.bondInsurance.commissionPercentage",
                              0
                            );
                            return;
                          }
                          setFieldValue(
                            "insurerSpecificData.portoEssencial.bondInsurance.commissionPercentage",
                            Number(value).toFixed(2)
                          );
                        }}
                        type="number"
                        max={100.0}
                        min={1.0}
                        fluid
                      />
                    </Grid.Column>

                    <Grid.Column width={5}>
                      <FieldLabel>
                        Taxa Residencial (%)
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.portoEssencial.bondInsurance.pfResidentialTax"
                        disabled={!userCanEdit}
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, data.value);
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value
                            .replace(/,/g, ".")
                            .replace(/[^\d.]/g, "");

                          if (Number(value) > 100) {
                            setFieldValue(
                              "insurerSpecificData.portoEssencial.bondInsurance.pfResidentialTax",
                              Number(100).toFixed(2)
                            );
                            return;
                          }

                          if (Number(value) < 0) {
                            setFieldValue(
                              "insurerSpecificData.portoEssencial.bondInsurance.pfResidentialTax",
                              0
                            );
                            return;
                          }
                          setFieldValue(
                            "insurerSpecificData.portoEssencial.bondInsurance.pfResidentialTax",
                            Number(value).toFixed(2)
                          );
                        }}
                        type="number"
                        max={100.0}
                        min={1.0}
                        fluid
                      />
                    </Grid.Column>

                    <Grid.Column width={5}>
                      <FieldLabel>
                        Taxa Comercial (%)
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.portoEssencial.bondInsurance.pfComercialTax"
                        disabled={!userCanEdit}
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, data.value);
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value
                            .replace(/,/g, ".")
                            .replace(/[^\d.]/g, "");

                          if (Number(value) > 100) {
                            setFieldValue(
                              "insurerSpecificData.portoEssencial.bondInsurance.pfComercialTax",
                              Number(100).toFixed(2)
                            );
                            return;
                          }

                          if (Number(value) < 0) {
                            setFieldValue(
                              "insurerSpecificData.portoEssencial.bondInsurance.pfComercialTax",
                              0
                            );
                            return;
                          }
                          setFieldValue(
                            "insurerSpecificData.portoEssencial.bondInsurance.pfComercialTax",
                            Number(value).toFixed(2)
                          );
                        }}
                        type="number"
                        max={100.0}
                        min={1.0}
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}

              {selectedInsurer.code === InsurersCodeEnum.Too && (
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <FieldLabel>
                        Tipo de coberturas
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.tooSeguros.bondInsurance.assurance"
                        disabled={!userCanEdit}
                        options={pottencialAssuranceOptions}
                        onChange={(e: SyntheticEvent, data: any) =>
                          handleInputChange(data, props)
                        }
                        component={Dropdown}
                        selection
                        compact
                        basic
                        fluid
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <FieldLabel>
                        LMI
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.tooSeguros.bondInsurance.lmi"
                        disabled={!userCanEdit}
                        options={garanttiLMIOptions}
                        onChange={(e: SyntheticEvent, data: any) =>
                          handleInputChange(data, props)
                        }
                        component={Dropdown}
                        selection
                        compact
                        basic
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <FieldLabel>
                        Taxa Comissão
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.tooSeguros.bondInsurance.commissionPercentage"
                        disabled={!userCanEdit}
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, data.value);
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value
                            .replace(/,/g, ".")
                            .replace(/[^\d.]/g, "");

                          if (Number(value) > 100) {
                            setFieldValue(
                              "insurerSpecificData.tooSeguros.bondInsurance.commissionPercentage",
                              Number(100).toFixed(2)
                            );
                            return;
                          }

                          if (Number(value) < 0) {
                            setFieldValue(
                              "insurerSpecificData.tooSeguros.bondInsurance.commissionPercentage",
                              0
                            );
                            return;
                          }
                          setFieldValue(
                            "insurerSpecificData.tooSeguros.bondInsurance.commissionPercentage",
                            Number(value).toFixed(2)
                          );
                        }}
                        type="number"
                        max={100.0}
                        min={1.0}
                        fluid
                      />
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <FieldLabel>
                        Taxa Residencial (%)
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.tooSeguros.bondInsurance.pfResidentialTax"
                        disabled={!userCanEdit}
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, data.value);
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value
                            .replace(/,/g, ".")
                            .replace(/[^\d.]/g, "");

                          if (Number(value) > 100) {
                            setFieldValue(
                              "insurerSpecificData.tooSeguros.bondInsurance.pfResidentialTax",
                              Number(100).toFixed(2)
                            );
                            return;
                          }

                          if (Number(value) < 0) {
                            setFieldValue(
                              "insurerSpecificData.tooSeguros.bondInsurance.pfResidentialTax",
                              0
                            );
                            return;
                          }
                          setFieldValue(
                            "insurerSpecificData.tooSeguros.bondInsurance.pfResidentialTax",
                            Number(value).toFixed(2)
                          );
                        }}
                        type="number"
                        max={100.0}
                        min={1.0}
                        fluid
                      />
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <FieldLabel>
                        Taxa Comercial (%)
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.tooSeguros.bondInsurance.pfComercialTax"
                        disabled={!userCanEdit}
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, data.value);
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value
                            .replace(/,/g, ".")
                            .replace(/[^\d.]/g, "");

                          if (Number(value) > 100) {
                            setFieldValue(
                              "insurerSpecificData.tooSeguros.bondInsurance.pfComercialTax",
                              Number(100).toFixed(2)
                            );
                            return;
                          }

                          if (Number(value) < 0) {
                            setFieldValue(
                              "insurerSpecificData.tooSeguros.bondInsurance.pfComercialTax",
                              0
                            );
                            return;
                          }
                          setFieldValue(
                            "insurerSpecificData.tooSeguros.bondInsurance.pfComercialTax",
                            Number(value).toFixed(2)
                          );
                        }}
                        type="number"
                        max={100.0}
                        min={1.0}
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}

              {selectedInsurer.code === InsurersCodeEnum.TokioMarine && (
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <FieldLabel>
                        Tipo de coberturas
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.tokioMarine.bondInsurance.assurance"
                        disabled={!userCanEdit}
                        options={pottencialAssuranceOptions}
                        onChange={(e: SyntheticEvent, data: any) =>
                          handleInputChange(data, props)
                        }
                        component={Dropdown}
                        selection
                        compact
                        basic
                        fluid
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <FieldLabel>
                        LMI
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.tokioMarine.bondInsurance.lmi"
                        disabled={!userCanEdit}
                        options={garanttiLMIOptions}
                        onChange={(e: SyntheticEvent, data: any) =>
                          handleInputChange(data, props)
                        }
                        component={Dropdown}
                        selection
                        compact
                        basic
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <FieldLabel>
                        Taxa Comissão
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.tokioMarine.bondInsurance.commissionPercentage"
                        disabled={!userCanEdit}
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, data.value);
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value
                            .replace(/,/g, ".")
                            .replace(/[^\d.]/g, "");

                          if (Number(value) > 100) {
                            setFieldValue(
                              "insurerSpecificData.tokioMarine.bondInsurance.commissionPercentage",
                              Number(100).toFixed(2)
                            );
                            return;
                          }

                          if (Number(value) < 0) {
                            setFieldValue(
                              "insurerSpecificData.tokioMarine.bondInsurance.commissionPercentage",
                              0
                            );
                            return;
                          }
                          setFieldValue(
                            "insurerSpecificData.tokioMarine.bondInsurance.commissionPercentage",
                            Number(value).toFixed(2)
                          );
                        }}
                        type="number"
                        max={100.0}
                        min={1.0}
                        fluid
                      />
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <FieldLabel>
                        Taxa Residencial (%)
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.tokioMarine.bondInsurance.pfResidentialTax"
                        disabled={!userCanEdit}
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, data.value);
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value
                            .replace(/,/g, ".")
                            .replace(/[^\d.]/g, "");

                          if (Number(value) > 100) {
                            setFieldValue(
                              "insurerSpecificData.tokioMarine.bondInsurance.pfResidentialTax",
                              Number(100).toFixed(2)
                            );
                            return;
                          }

                          if (Number(value) < 0) {
                            setFieldValue(
                              "insurerSpecificData.tokioMarine.bondInsurance.pfResidentialTax",
                              0
                            );
                            return;
                          }
                          setFieldValue(
                            "insurerSpecificData.tokioMarine.bondInsurance.pfResidentialTax",
                            Number(value).toFixed(2)
                          );
                        }}
                        type="number"
                        max={100.0}
                        min={1.0}
                        fluid
                      />
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <FieldLabel>
                        Taxa Comercial (%)
                        <span style={{ color: "red" }}>*</span>
                      </FieldLabel>
                      <Field
                        name="insurerSpecificData.tokioMarine.bondInsurance.pfComercialTax"
                        disabled={!userCanEdit}
                        component={Input}
                        onChange={(e: SyntheticEvent, data: any) => {
                          setFieldValue(data.field.name, data.value);
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value
                            .replace(/,/g, ".")
                            .replace(/[^\d.]/g, "");

                          if (Number(value) > 100) {
                            setFieldValue(
                              "insurerSpecificData.tokioMarine.bondInsurance.pfComercialTax",
                              Number(100).toFixed(2)
                            );
                            return;
                          }

                          if (Number(value) < 0) {
                            setFieldValue(
                              "insurerSpecificData.tokioMarine.bondInsurance.pfComercialTax",
                              0
                            );
                            return;
                          }
                          setFieldValue(
                            "insurerSpecificData.tokioMarine.bondInsurance.pfComercialTax",
                            Number(value).toFixed(2)
                          );
                        }}
                        type="number"
                        max={100.0}
                        min={1.0}
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </InsurersConfigWrapper>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
