import React, { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import {
  BondInsuranceAnalysisForGetCompleteBondInsuranceProposalByIdResponseDTO,
  BondInsuranceHiredDataForGetCompleteBondInsuranceProposalByIdResponseDTO
} from '../../../../../dtos/bond-insurance-proposal-list/get-complete-bond-insurance-proposal-by-id-response.dto';
import { Info } from '../../../../../components/Info';
import { DateToStringDate } from '../../../../../services/date';
import SectionLabel from '../../../../../components/SectionLabel';
import { getBondInsuranceProposalStatusLabel } from '../../../../../services/bond-insurance-proposal';
import { maskMoney } from '../../../../../services/masks';
import { BondInsuranceHiredDataStatusEnum } from '../../../../../enums/bond-insurance-hired-data-status.enum';

export type Props = {
  bondInsuranceAnalysis: BondInsuranceAnalysisForGetCompleteBondInsuranceProposalByIdResponseDTO;
  hiredData: BondInsuranceHiredDataForGetCompleteBondInsuranceProposalByIdResponseDTO;
};

export const BondInsuranceProposalMainInfoDetails = (props: Props) => {
  const { bondInsuranceAnalysis, hiredData } = props;

  const insurer = bondInsuranceAnalysis.insurer.name + (bondInsuranceAnalysis.selectedPlan ? ` (${bondInsuranceAnalysis.selectedPlan?.toUpperCase()})` : '')

  return (
    <>
      <SectionLabel text="DADOS DA PROPOSTA" />
      <Grid.Row>
        <Info name={'ID'} value={bondInsuranceAnalysis.id} width={8} />
        <Info
          name={'DATA DE EMISSÃO DA PROPOSTA'}
          value={
            bondInsuranceAnalysis.insurerProposalCreatedAt
              ? DateToStringDate(bondInsuranceAnalysis.insurerProposalCreatedAt)
              : 'Indisponível'
          }
          width={8}
        />
      </Grid.Row>
      <Grid.Row>
        <Info name={'SEGURADORA'} value={insurer} width={8} />
        <Info
          name={'CÓD. DA COTAÇÃO'}
          value={
            bondInsuranceAnalysis.insurerQuotationId
              ? bondInsuranceAnalysis.insurerQuotationId
              : 'Indisponível'
          }
          width={8}
        />
      </Grid.Row>
      <Grid.Row>
        <Info
          name={'CÓD. DA PROPOSTA'}
          value={bondInsuranceAnalysis.insurerProposalId}
          width={8}
        />
        <Info
          name={'STATUS'}
          value={
            getBondInsuranceProposalStatusLabel(
              hiredData.status as BondInsuranceHiredDataStatusEnum
            ) || ''
          }
          width={8}
        />
      </Grid.Row>
      <Grid.Row>
        <Info
          name={'VIGÊNCIA INFORMADA NA ANÁLISE'}
          value={bondInsuranceAnalysis.bondInsurance.property.validity || 'Indisponível'}
          width={8}
        />
        <Info
          name={'PREMIO TOTAL COTADO (R$)'}
          value={
            maskMoney(
              String(
                bondInsuranceAnalysis.value *
                  bondInsuranceAnalysis.bondInsurance.property.validity
              ),
              true,
              2
            ) || 'Indisponível'
          }
          width={8}
        />
      </Grid.Row>
      <Grid.Row>
        <Info
          name={'Início vigência contrato locação'}
          value={
            bondInsuranceAnalysis.bondInsurance.property
              .rental_period_start_date
              ? DateToStringDate(
                  bondInsuranceAnalysis.bondInsurance.property
                    .rental_period_start_date
                )
              : bondInsuranceAnalysis.bondInsurance.property.rent_insurance_contract_start_date
              ? DateToStringDate(
                  bondInsuranceAnalysis.bondInsurance.property.rent_insurance_contract_start_date
                )
              : 'Indisponível'
          }
          width={8}
        />
        <Info
          name={'Fim vigência contrato locação'}
          value={
            bondInsuranceAnalysis.bondInsurance.property.rental_period_end_date
              ? DateToStringDate(
                  bondInsuranceAnalysis.bondInsurance.property
                    .rental_period_end_date
                )
              : bondInsuranceAnalysis.bondInsurance.property.rent_insurance_contract_end_date
              ? DateToStringDate(
                  bondInsuranceAnalysis.bondInsurance.property.rent_insurance_contract_end_date
                )
              : 'Indisponível'
          }
          width={8}
        />
      </Grid.Row>

      <Grid.Row>
        <Info
          name={'Início vigência da garantia'}
          value={
            bondInsuranceAnalysis.bondInsurance.property
              .rent_insurance_contract_start_date
              ? DateToStringDate(
                  bondInsuranceAnalysis.bondInsurance.property
                    .rent_insurance_contract_start_date
                )
              : bondInsuranceAnalysis.bondInsurance.property
                  .rental_period_start_date
              ? DateToStringDate(
                  bondInsuranceAnalysis.bondInsurance.property
                    .rental_period_start_date
                )
              : 'Indisponível'
          }
          width={8}
        />
        <Info
          name={'Fim vigência da garantia'}
          value={
            bondInsuranceAnalysis.bondInsurance.property.rent_insurance_contract_end_date
              ? DateToStringDate(
                  bondInsuranceAnalysis.bondInsurance.property
                    .rent_insurance_contract_end_date
                )
              : bondInsuranceAnalysis.bondInsurance.property
                  .rental_period_end_date
              ? DateToStringDate(
                  bondInsuranceAnalysis.bondInsurance.property
                    .rental_period_end_date
                )
              : 'Indisponível'
          }
          width={8}
        />
      </Grid.Row>

      <Grid.Row>

        <Info
          name={'DIA DE VENCIMENTO DO ALUGUEL'}
          value={`${
            bondInsuranceAnalysis.bondInsurance.property.rent_due_date || 'Indisponível'
          }`}
          width={8}
        />
      </Grid.Row>

      {bondInsuranceAnalysis.observations && (
        <Grid.Row>
          <Info
            name={'Observações análise'}
            value={bondInsuranceAnalysis.observations}
            width={16}
          />
        </Grid.Row>
      )}
      {hiredData.observations && (
        <Grid.Row>
          <Info
            name={'Observações Contratação'}
            value={hiredData.observations}
            width={16}
          />
        </Grid.Row>
      )}

      {hiredData.nonRenewalReason && (
        <Grid.Row>
          <Info
            name={'Motivo De Não Renovação'}
            value={hiredData.nonRenewalReason}
            width={16}
          />
        </Grid.Row>
      )}

      {(bondInsuranceAnalysis.contractor || bondInsuranceAnalysis.updater) && (
        <Grid.Row>
          {bondInsuranceAnalysis.updater && <Info
            name={'EDITADO POR'}
            value={bondInsuranceAnalysis.updater}
            width={8}
          />}
          {bondInsuranceAnalysis.contractor && <Info
            name={'CONTRATADO POR'}
            value={bondInsuranceAnalysis.contractor}
            width={8}
          />}
        </Grid.Row>
      )}
    </>
  );
};
