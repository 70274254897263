import { InsurersCodeEnum } from "../../../../../../enums/insurers-code.enum";

export type Offer = {
  nomeSeguro: string;
  valorParcela: string;
  quantidadeParcela: number;
}

export function adjustInsurerResponse(insurerResponse: any, insurer: string, values: Offer[]) {
  switch(insurer) {
    case InsurersCodeEnum.Garantti:
      values.forEach(offer => {
        const pos = insurerResponse.findIndex((i: any) => (i.plan_id).toUpperCase() === offer.nomeSeguro)
        insurerResponse[pos].rent_months_count = offer.quantidadeParcela
        insurerResponse[pos].residentialfee_assurance_month_amount = offer.valorParcela
        insurerResponse[pos].commercialfee_assurance_month_amount = offer.valorParcela
      })
      return insurerResponse;
    case InsurersCodeEnum.PortoEssencial:
      values.forEach(offer => {
        const pos = insurerResponse.findIndex((i: any) => i.nome_seguro === offer.nomeSeguro)
        insurerResponse[pos].quantidade_parcela = offer.quantidadeParcela
        insurerResponse[pos].valor_parcela = offer.valorParcela
        insurerResponse[pos].condicao_pagamento = "0"+String(offer.quantidadeParcela)
      })
      return insurerResponse;
    default:
      return insurerResponse;
  }
}
