import React, { SyntheticEvent } from 'react';
import Input from '../../../components/Input';
import BankCodeDropdown from '../../../components/BankCodeDropdown';
import SectionLabel from '../../../components/SectionLabel';
import { Grid } from 'semantic-ui-react';
import { Field } from 'formik';
import { FormikErrorMessage } from '../../../components/ErrorMessage';
import { FieldLabel } from '../../../styles';
import { EstateFormProps } from '../UpdateEstateForm';

export const BankAccountData = (props: EstateFormProps) => {
  const { errors, touched, handleInputChange } = props;
  return (
    <>
      <SectionLabel text='DADOS BANCÁRIOS' />

      <Grid.Row>
        <Grid.Column width={6}>
          <FieldLabel>Banco*</FieldLabel>
          <Field name="bankAccount.bankCode"
            className={errors && errors.bankAccount && touched && touched.bankAccount ? 'text-input error' : 'text-input'}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={BankCodeDropdown}
            maxLength={3}
            fluid />
          <FormikErrorMessage component="div" name="bankAccount.bankCode" />
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>Agência*</FieldLabel>
          <Field name="bankAccount.agencyNumber"
            className={errors && errors.bankAccount  && touched && touched.bankAccount  ? 'text-input error' : 'text-input'}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Input}
            maxLength={4}
            fluid />
          <FormikErrorMessage component="div" name="bankAccount.agencyNumber" />
        </Grid.Column>

        <Grid.Column width={1} style={{ padding: 1 }} >
          <Field name="bankAccount.agencyDv"
            style={{ marginTop: '18px' }}
            className={errors && errors.bankAccount && touched && touched.bankAccount ? 'text-input error' : 'text-input'}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Input}
            maxLength={1}
            fluid />
          <FormikErrorMessage component="div" name="bankAccount.agencyDv" />
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>Conta*</FieldLabel>
          <Field name="bankAccount.accountNumber"
            className={errors && errors.bankAccount  && touched && touched.bankAccount  ? 'text-input error' : 'text-input'}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Input}
            fluid />
          <FormikErrorMessage component="div" name="bankAccount.accountNumber" />
        </Grid.Column>

        <Grid.Column width={1} style={{ padding: 3 }}>
          <Field name="bankAccount.accountDv"
            style={{ marginTop: '18px' }}
            className={errors && errors.bankAccount && touched && touched.bankAccount ? 'text-input error' : 'text-input'}
            onChange={(e: SyntheticEvent, data: any) => handleInputChange(data, props)}
            component={Input}
            maxLength={1}
            fluid />
          <FormikErrorMessage component="div" name="bankAccount.accountDv" />
        </Grid.Column>

      </Grid.Row>
    </>
  );
}
