import React, {ReactHTMLElement} from 'react';
import styled from 'styled-components';

import { InputProps, Input as SemanticInput} from 'semantic-ui-react';

const InputWrapper = styled.div`
  border: ${(p: InputProps) => p.error ? 'red' : 'blue'};
  border-radius: 5px;

  .ui.input input {
    /* background-color: #F5F8FA !important; */
  }

  .disabled.field {
    background-color: #F5F8FA !important;
    opacity: 1 !important;
  }

  .placeholder {
    text-align: center;
    color:red;
  }
`

type SemanticUIInputProps = {
  handleRef?: (el: ReactHTMLElement<any>) => void;
} & InputProps

const Input = (props: SemanticUIInputProps) => {
  return (
    <InputWrapper>
      {
        props.handleRef ?
            <SemanticInput {...props.field} {...props} ref={props.handleRef}/>
          :
          <SemanticInput {...props.field} {...props}/>
      }
    </InputWrapper>
  )
};

export default Input;

