import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Grid,
  Input,
  Dropdown as DropdownNativo,
  Popup,
  Icon,
  Button,
  DropdownItemProps,
  Dropdown,
  Modal
} from 'semantic-ui-react';
import { Field, FormikProps } from 'formik';
import SectionLabel from '../../../../../../components/SectionLabel';
import { FieldLabel } from '../../../../../../styles';
import { CreateCancelationInitialValuesType } from '../../Formik/types/CreateCancelationInitialValuesTypes';
import { InsurerState } from '../../../../../../types/InsurerState';
import { CustoDropDownItemProps } from '../../../../../../components/types';
import { maskCpfOrCnpj, maskZipCode } from '../../../../../../services/masks';
import { AddressService } from '../../../../../../services/address';
import FormatedTextArea from '../../../../../../components/FormatedTextArea';
import { getDataByDocument } from '../../../../../../services/documents';
import { cancellationReasonOptions } from '../../../../types';
import { UserBondInsurance } from '../../../../../bond-insurance-budget-form/types';
import moment from 'moment';
import { getUserBondInsurances } from '../../../../../../services/bond-insurance-analysis.service';
import { BondInsuranceHiredDataStatusEnum } from '../../../../../../enums/bond-insurance-hired-data-status.enum';
import { isValidEmail } from '../../../../../../utils/emailValidator';
import { warningNotification } from '../../../../../../services/notification';

type EstatesInfoProps = {
  estates: CustoDropDownItemProps[] | null;
  insurers: InsurerState[] | null;
};

interface DropdownOptions {
  index: number;
  value: number | string;
  text: string;
}

const CancelationInfoStep = ({
  values,
  insurers,
  estates,
  setFieldValue
}: FormikProps<CreateCancelationInitialValuesType> & EstatesInfoProps) => {
  const [isCpfModalOpen, setIsCpfModalOpen] = useState<boolean>(false);
  const [sanatizedUserBondInsurance, setSanatizedUserBondInsurance] = useState<any[]>([]);
  const [userBondInsuranceData, setUserBondInsuranceData] =
    useState<UserBondInsurance[]>();

  const [loadingZipCode, setLoadingZipCode] = useState(false);
  const [selectableInsurers, setSelectableInsurers] = useState<DropdownOptions[] | any[]>(
    []
  );
  const [selectableEstates, setSelectableEstates] = useState<DropdownOptions[] | any[]>(
    []
  );
  const [loadDocument, setLoadDocument] = useState<boolean>(false);
  const [fullNameFetched, setFullNameFetched] = useState<boolean>(false);
  const [reasonsOptions, setReasonsOptions] = useState<Array<DropdownItemProps>>([]);

  useEffect(() => {
    setReasonsOptions(cancellationReasonOptions);
  }, []);

  useEffect(() => {
    getTenantByDocument(values.tenant.document);
  }, [values.tenant.document])

  useEffect(() => {
    if (insurers && insurers.length) {
      setSelectableInsurers(
        insurers.map(insurer => ({
          index: insurer.id,
          value: insurer.id,
          text: insurer.name
        }))
      );
    }
  }, [insurers]);

  useEffect(() => {
    if (estates && estates.length) {
      setSelectableEstates(
        estates.map(estate => ({
          index: estate.value.id,
          value: estate.value.id,
          text: estate.value.name
        }))
      );

      if (estates.length === 1) {
        setFieldValue('cancelation.estateId', estates[0].value.id);
      }
    }
  }, [estates]);

  useEffect(() => {
    if (values.property.zipCode) {
    }
    if (values.property.zipCode && values.property.zipCode.length >= 8) {
      onChangeCep(values.property.zipCode);
    }
  }, [values.property.zipCode]);

  useEffect(() => {
    if (loadDocument) return;
    const estateId = values.cancelation.estateId;
    const document = values.tenant.document;
    const unformatedDoc = document.replace(/\D/g, '');
    if (document.length < 11) return;
    if (!estateId) return;
    getUserBondInsurances(unformatedDoc, estateId)
      .then(response => {
        if (response.length) {
          setUserBondInsuranceData(response as any);
        } else {
          return;
        }
      })
      .catch(error => {
        console.log({ error });
      });
  }, [values.cancelation.estateId, loadDocument]);

  useEffect(() => {
    if (userBondInsuranceData?.length) {
      let newItem: UserBondInsurance[] = [];
      const finalItem: any[] = [];
      userBondInsuranceData.forEach((item, index, array) => {
        const oldItem = array[index - 1];
        const bondInsuranceId = item.bondInsurance.id;
        const bondInsuranceIdOld = oldItem?.bondInsurance.id;

        if (oldItem) {
          if (bondInsuranceId !== bondInsuranceIdOld) {
            finalItem.push(newItem);

            newItem = [item];
          } else {
            newItem.push(item);
          }
        } else {
          newItem.push(item);
        }

        if (array.length === index + 1) {
          finalItem.push(newItem);
        }
      });

      console.log({ finalItem });
      setSanatizedUserBondInsurance(finalItem);
      setIsCpfModalOpen(true);
    }
  }, [userBondInsuranceData]);

  async function getTenantByDocument(document: string) {
    try {
      setFullNameFetched(false);
      setLoadDocument(true);
      const unformatedDoc = document.replace(/\D/g, '');
      const isCPF = unformatedDoc.length === 11;
      const data = await getDataByDocument(unformatedDoc);
      if (isCPF) {
        setFieldValue('tenant.fullName', data.name);
      } else {
        setFieldValue('tenant.fullName', data.company);
      }
      setFullNameFetched(data.name || data.company);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadDocument(false);
    }
  }

  const onChangeCep = (zipCode: string): void => {
    const maskedValue = maskZipCode(zipCode);

    setFieldValue('property.zipCode', maskedValue);
    if (maskedValue.length >= 9) {
      setLoadingZipCode(true);

      AddressService.getAddressByZipCode(zipCode)
        .then(address => {
          setFieldValue('property.city', address.city);
          setFieldValue('property.district', address.district);
          setFieldValue('property.state', address.state);
          setFieldValue('property.street', address.street);
          setLoadingZipCode(false);
        })
        .catch(e => {
          console.log('address error:', e);
        });
    }
  };

  function translateBondInsuranceAnalysisStatus(status: string) {
    let newStatus = '';
    let color = '';

    switch (status) {
      case 'APPROVED':
        newStatus = 'Aprovado';
        color = 'rgba(19, 135, 46, 1)';
        break;
      case 'PROPOSAL':
        newStatus = 'Em proposta';
        color = 'rgba(33, 133, 208, 1)';
        break;
      case 'ANALYSIS':
        newStatus = 'Em análise';
        color = 'rgba(33, 133, 208, 1)';
        break;
      case 'ERROR':
        newStatus = 'Erro';
        color = 'rgba(219, 40, 40, 1)';
        break;
      case 'PENDING':
        newStatus = 'Pendenciado';
        color = 'rgba(242, 113, 28, 1)';
        break;
      case 'REJECTED':
        newStatus = 'Recusado';
        color = 'rgba(219, 40, 40, 1)';
        break;
      case 'UNDEFINED':
        newStatus = 'Indefinido';
        color = 'rgba(219, 40, 40, 1)';
        break;
      case 'WAITING':
        newStatus = 'Em espera';
        color = 'rgba(242, 113, 28, 1)';
        break;
    }

    return { text: newStatus, color };
  }
  function translateBondInsuranceStatus(status: string) {
    let newStatus = '';
    let color = '';

    switch (status) {
      case 'APPROVED':
        newStatus = 'Aprovado';
        color = 'rgba(19, 135, 46, 1)';
        break;
      case 'CANCELED':
        newStatus = 'Cancelado';
        color = 'rgba(219, 40, 40, 1)';
        break;
      case 'EXPIRED':
        newStatus = 'Expirado';
        color = 'rgba(219, 40, 40, 1)';
        break;
      default:
        newStatus = '';
        break;
    }

    return { text: newStatus, color };
  }

  return (
    <div>
      <Grid columns="equal" style={{ paddingTop: '2em' }}>
        <SectionLabel text="Informações do locatário" />
        <Grid.Row>
          <Grid.Column width={7}>
            <FieldLabel>CPF/CNPJ do Locatário*</FieldLabel>
            <Field
              value={values.tenant.document}
              component={Input}
              loading={loadDocument}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('tenant.document', maskCpfOrCnpj(value))
              }
              onBlur={(e: any) => {
                getTenantByDocument(e.target.value);
              }}
            />
          </Grid.Column>

          <Grid.Column width={7}>
            <FieldLabel>Nome do Locatário*</FieldLabel>
            <Field
              value={values.tenant.fullName}
              component={Input}
              fluid
              disabled={loadDocument || fullNameFetched}
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('tenant.fullName', value)
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid columns="equal" style={{ paddingLeft: '1em' }}>
        <SectionLabel text="Informações do Seguro" />
        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>
              SEGURADORA / GARANTIDORA:
              <Popup
                content={
                  'Em caso de dúvidas, verifique a cláusula da garantia no contrato de locação.'
                }
                position={'bottom right'}
                trigger={
                  <Icon
                    name={'info circle'}
                    color={'blue'}
                    style={{ cursor: 'help', marginLeft: 5 }}
                  />
                }
              />
            </FieldLabel>
            <div style={{ width: '100%' }}>
              <DropdownNativo
                style={{ width: '100%' }}
                name={`cancelation.insurer`}
                options={selectableInsurers || []}
                placeholder={'Selecionar...'}
                search
                selection
                clearable
                onChange={(e, { value }) => {
                  setFieldValue('cancelation.insurerId', value);
                }}
              />
            </div>
          </Grid.Column>

          {selectableEstates.length > 1 && (
            <Grid.Column width={5}>
              <FieldLabel>IMOBILIARIA*:</FieldLabel>
              <div style={{ width: '100%' }}>
                <DropdownNativo
                  style={{ width: '100%' }}
                  name={`cancelation.estate`}
                  options={selectableEstates || []}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  clearable
                  onChange={(e, { value }) => {
                    setFieldValue('cancelation.estateId', value);
                  }}
                />
              </div>
            </Grid.Column>
          )}
          <Grid.Column width={4}>
            <FieldLabel>Número da Apólice</FieldLabel>
            <Field
              value={values.cancelation.policyNumber}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('cancelation.policyNumber', value)
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid columns="equal" style={{ paddingLeft: '1em' }}>
        <SectionLabel text="Endereço do imóvel" />
        <Grid.Row>
          <Grid.Column width={3}>
            <FieldLabel>CEP*</FieldLabel>
            <Field
              name="property.zipCode"
              value={values.property.zipCode}
              loading={loadingZipCode}
              component={Input}
              maxLength={9}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('property.zipCode', maskZipCode(value))
              }
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <FieldLabel>Logradouro*</FieldLabel>
            <Field
              name="property.street"
              value={values.property.street}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('property.street', value)
              }
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <FieldLabel>Bairro*</FieldLabel>
            <Field
              name="property.district"
              value={values.property.district}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('property.district', value)
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>
            <FieldLabel>Cidade*</FieldLabel>
            <Field
              name="city"
              value={values.property.city}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('property.city', value)
              }
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <FieldLabel>UF*</FieldLabel>
            <Field
              name="values.property.state"
              value={values.property.state}
              component={Input}
              fluid
              maxLength={2}
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('property.state', value)
              }
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <FieldLabel>Número*</FieldLabel>
            <Field
              name="values.property.number"
              value={values.property.number}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('property.number', value)
              }
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <FieldLabel>Complemento</FieldLabel>
            <Field
              name="values.property.complement"
              value={values.property.complement}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('property.complement', value)
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid columns="equal" style={{ paddingLeft: '1em' }}>
        <SectionLabel text="Informações do Cancelamento" />
        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel>MOTIVO DO CANCELAMENTO* </FieldLabel>
            <Dropdown
              name={`cancelation.cancellationReason`}
              value={values.cancelation.cancellationReason}
              options={reasonsOptions}
              onChange={(e, { value }) =>
                setFieldValue('cancelation.cancellationReason', value)
              }
              floating
              search
              selection
              fluid
              placeholder="Selecionar..."
            />
          </Grid.Column>
        </Grid.Row>

        {(values.cancelation?.notificationEmails as string[]).map(
          (notificationEmail, index) => {
            return (
              <Grid.Row verticalAlign="middle" key={index}>
                <Grid.Column width={6}>
                  <FieldLabel>
                    Informe os e-mails para receberem notificações do cancelamento:
                  </FieldLabel>
                  <Field
                    value={notificationEmail}
                    component={Input}
                    fluid
                    onChange={(e: any, { value }: { value: string }) => {
                      setFieldValue(`cancelation.notificationEmails[${index}]`, value);
                    }}
                    onBlur={() => {
                      if (!isValidEmail(notificationEmail)) {
                        warningNotification(
                          "Ops...",
                          "Por favor, preencha um e-mail válido!"
                        );
                      }
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={1}>
                  <Icon
                    name={'remove circle'}
                    color={'grey'}
                    style={{ cursor: 'pointer', paddingTop: '5px' }}
                    size={'large'}
                    onClick={() => {
                      if (values.cancelation.notificationEmails?.length === 1) {
                        setFieldValue(`cancelation.notificationEmails`, ['']);
                      } else {
                        const arr1 = values.cancelation.notificationEmails?.slice(
                          0,
                          index
                        );
                        const arr2 = values.cancelation.notificationEmails?.slice(
                          index + 1
                        );
                        const newArr = [...(arr1 as string[]), ...(arr2 as string[])];
                        setFieldValue(`cancelation.notificationEmails`, newArr);
                      }
                    }}
                  />
                </Grid.Column>
                {index + 1 === values.cancelation.notificationEmails?.length && (
                  <Grid.Column width={5}>
                    <Button
                      primary
                      onClick={() => {
                        if (values.cancelation.notificationEmails?.length) {
                          const lastEmail =
                            values.cancelation.notificationEmails[
                              values.cancelation.notificationEmails.length - 1
                            ];
                          if (lastEmail.length > 0)
                            setFieldValue(`cancelation.notificationEmails`, [
                              ...(values.cancelation.notificationEmails as string[]),
                              ''
                            ]);
                        } else {
                          setFieldValue(
                            `cancelation.notificationEmails[${index + 1}]`,
                            ''
                          );
                        }
                      }}
                    >
                      <Icon name={'plus circle'} />
                      Adicionar E-mail
                    </Button>
                  </Grid.Column>
                )}
              </Grid.Row>
            );
          }
        )}
        <Grid.Row>
          <Grid.Column width={12}>
            <FieldLabel>Observações</FieldLabel>
            <Field
              name={`cancelation.observations`}
              rows={3}
              value={values.cancelation.observations}
              onChange={(_: SyntheticEvent, data: any) => {
                setFieldValue(`cancelation.observations`, data.value);
              }}
              component={FormatedTextArea}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Modal
        onClose={() => setIsCpfModalOpen(false)}
        onOpen={() => setIsCpfModalOpen(true)}
        open={isCpfModalOpen}
        size={'large'}
      >
        <Modal.Header>Este documento possui análises em nosso sistema</Modal.Header>
        <Modal.Description>
          <p style={{ padding: '1rem 2rem 0 2rem' }}>
            {' '}
            Atenção, o documento foi localizado no sistema, verifique abaixo em qual
            situação (Análises, Propostas, Apólices ou já em Processo de cancelamento).
            Confira se realmente há necessidade de abrir um novo cancelamento. Só prossiga
            se o seguro do locatário e imóvel não estiver em processo de cancelamento.{' '}
          </p>
        </Modal.Description>
        <Modal.Content scrolling>
          {sanatizedUserBondInsurance.map(item => {
            const userBondInsurance: UserBondInsurance[] = item;

            return (
              <>
                <div
                  key={userBondInsurance[0].bondInsurance.id}
                  style={{
                    flex: 1,
                    padding: 10,
                    border: '1px solid #ccc',
                    borderWidth: 0.5,
                    borderRadius: 6,
                    marginTop: 5,
                    marginBottom: 5
                  }}
                >
                  <div style={{ display: 'flex', flex: 1, gap: 20 }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        justifyContent: 'space-between',
                        gap: 20
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontSize: 13 }}>Data de Criação da Análise:</span>
                        <span style={{ flex: 1, fontSize: 16, fontWeight: 'bold' }}>
                          {userBondInsurance[0].bondInsurance.createdAt
                            ? moment(userBondInsurance[0].bondInsurance.createdAt).format(
                                'DD/MM/YYYY HH:mm'
                              )
                            : 'Sem inicio'}
                        </span>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontSize: 13 }}>Data de Cancelamento:</span>
                        <span style={{ flex: 1, fontSize: 16, fontWeight: 'bold' }}>
                          {userBondInsurance[0].bondInsurance.canceledAt
                            ? moment(
                                userBondInsurance[0].bondInsurance.canceledAt
                              ).format('DD/MM/YYYY HH:mm')
                            : '--/--/--'}
                        </span>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontSize: 13 }}>Data de Contratação:</span>
                        <span style={{ flex: 1, fontSize: 16, fontWeight: 'bold' }}>
                          {userBondInsurance[0].bondInsurance.bondInsuranceHiredData
                            ?.createdAt
                            ? moment(
                                userBondInsurance[0].bondInsurance.bondInsuranceHiredData
                                  .createdAt
                              ).format('DD/MM/YYYY HH:mm')
                            : '--/--/--'}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        gap: 20,
                        justifyContent: 'space-evenly'
                      }}
                    >
                      <div>
                        <p style={{ fontWeight: 600 }}>
                          Encontrado em:
                          <span style={{ color: '#2222a5' }}>
                            {userBondInsurance[0].bondInsurance.canceledAt
                              ? 'Gerenciar Cancelados'
                              : userBondInsurance[0].bondInsurance.bondInsuranceHiredData
                              ? [
                                  BondInsuranceHiredDataStatusEnum.insurerPolicyPending,
                                  BondInsuranceHiredDataStatusEnum.policyError,
                                  BondInsuranceHiredDataStatusEnum.insurerPolicyCreated
                                ].includes(
                                  userBondInsurance[0].bondInsurance
                                    .bondInsuranceHiredData
                                    .status as BondInsuranceHiredDataStatusEnum
                                )
                                ? 'Gerenciar Apólices'
                                : 'Gerenciar Propostas'
                              : 'Gerenciar Análises'}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        justifyContent: 'space-between',
                        gap: 20
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontSize: 13 }}>Endereço:</span>
                        <span style={{ flex: 1, fontSize: 16, fontWeight: 'bold' }}>
                          {userBondInsurance[0].bondInsurance.property.street},{' '}
                          {userBondInsurance[0].bondInsurance.property.number} -{' '}
                          {userBondInsurance[0].bondInsurance.property.district} -{' '}
                          {userBondInsurance[0].bondInsurance.property.city} -{' '}
                          {userBondInsurance[0].bondInsurance.property.state}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="OK, Continuar!"
            labelPosition="right"
            icon="checkmark"
            onClick={() => setIsCpfModalOpen(false)}
            positive
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default CancelationInfoStep;
