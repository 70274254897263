import styled from 'styled-components';

export const ModalBackdrop = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000020;
  inset: 0px;
  height: 100%;
  width: 100%;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: #fff;
  border-radius: 5px;
  width: 607px;
  box-shadow: 0 0 4px #00000026;
  padding: 12px;
`;

export const CloseButton = styled.span`
  position: absolute;
  top: 0px;
  right: 2px;
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: black;
  }
`;

export const TaxesInfo = styled.div<{
  colSpan: 2 | 3;
}>`
  display: grid;
  grid-template-columns: ${(props) => props.colSpan === 2 ? '1fr 1fr' : '1fr 1fr 1fr'};
  padding: 0 12px;
  div {
    padding: 12px 0;
  }

  p {
    margin: 4px 0;
  }
`;

export const InsurerCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 51px;
  margin-bottom: 20px;

  .insurer-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 22px 4px 12px;
  }

  .logo {
    max-height: 84px;
    width: 84px;
  }

  .insurer-name {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
  }

  .info-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    color: #2185D0;
    cursor: pointer;
  }

  small {
    font-size: 14px;
    color: #2185D0;
    font-weight: 400;
  }
`;
