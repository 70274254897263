export const typeOptions = [
  {
    index: 1,
    value: 'name',
    text: 'Nome/Razão social inquilino'
  },
  {
    index: 2,
    value: 'document',
    text: 'CPF ou CNPJ'
  },
  {
    index: 3,
    value: 'address',
    text: 'Endereço'
  }
];
