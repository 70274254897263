import React from 'react';
import { Grid, Input } from 'semantic-ui-react';
import { maskCpfOrCnpj } from '../../../../../../../../services/masks';
import { FormProps } from '../types';
import { getDataByDocument } from '../../../../../../../../services/documents';

interface OwnerFormProps {
  form: FormProps;
  validateForm: boolean;
  setForm: React.Dispatch<React.SetStateAction<FormProps>>;
}

const SaveQuotationModalOwnerForm: React.FC<OwnerFormProps> = ({
  validateForm,
  form,
  setForm
}: OwnerFormProps) => {
  const [loadDocument, setLoadDocument] = React.useState(false);

  async function getOwnerByDocument(document: string) {
    try {
      const unformatedDoc = document.replace(/\D/g, '');
      const isCPF = unformatedDoc.length === 11;
      setLoadDocument(true);
      const data = await getDataByDocument(unformatedDoc);
      if (isCPF) {
        const birthDate = new Date(data.birth_date);
        birthDate.setTime(
          birthDate.getTime() + birthDate.getTimezoneOffset() * 60 * 1000
        );
        setForm((prevValue: FormProps) => ({
          ...prevValue,
          ownerIdentification: {
            ...prevValue.ownerIdentification,
            name: data.name,
            birthdate: birthDate
          }
        }));
      } else {
        setForm((prevValue: FormProps) => ({
          ...prevValue,
          ownerIdentification: {
            ...prevValue.ownerIdentification,
            name: data.company,
            cellphone: data.phone,
            email: data.email,
          }
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadDocument(false);
    }
  }
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={5}>
          <label>
            CPF/CNPJ*
          </label>
          <Input
            placeholder="CPF/CNPJ"
            fluid
            error={validateForm && form?.ownerIdentification?.document === ''}
            value={form?.ownerIdentification?.document}
            onChange={(_, data) => {
              setForm((prevValue: FormProps) => ({
                ...prevValue,
                ownerIdentification: {
                  ...prevValue.ownerIdentification,
                  document: maskCpfOrCnpj(data?.value)
                }
              }));
            }}
            onBlur={(e: any) => {
              getOwnerByDocument(e.target.value);
            }}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <label>
            NOME/RAZÃO SOCIAL*
          </label>
          <Input
            placeholder="Nome"
            fluid
            loading={loadDocument}
            error={validateForm && form?.ownerIdentification?.name === ''}
            value={form?.ownerIdentification?.name}
            onChange={(_, data) =>
              setForm((prevValue: FormProps) => ({
                ...prevValue,
                ownerIdentification: {
                  ...prevValue.ownerIdentification,
                  name: data?.value
                }
              }))
            }
          />
        </Grid.Column>

        <Grid.Column width={5}>
          <label>
            E-MAIL*
          </label>
          <Input
            placeholder="E-mail"
            type="email"
            fluid
            loading={loadDocument}
            error={validateForm && form?.ownerIdentification?.email === ''}
            value={form?.ownerIdentification?.email}
            onChange={(_, data) =>
              setForm((prevValue: FormProps) => ({
                ...prevValue,
                ownerIdentification: {
                  ...prevValue.ownerIdentification,
                  email: data?.value
                }
              }))
            }
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SaveQuotationModalOwnerForm;
