import React from 'react';
import { CreatePolicyProps } from "../../types";
import { Dropdown, Grid, Input } from 'semantic-ui-react';
import { UFOptions, hirePropertyTypeOptions } from '../../../../../bond-insurance-budget-form/types';
import { formatAmount, maskZipCode } from '../../../../../../services/masks';
import { onChangeCep } from '../../utils';

const RenderPropertyForm = ({
  values,
  setFieldValue
}: {
  values: CreatePolicyProps;
  setFieldValue: (field: string, value: any) => void;
}) => {
  const [cepLoading, setCepLoading] = React.useState(false);
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <label>TIPO DO IMÓVEL</label>
          <Dropdown
            search
            style={{ width: '100%' }}
            clearable
            selection
            options={hirePropertyTypeOptions}
            placeholder={'Selecionar...'}
            value={values?.bondInsurance?.property?.property_type}
            onChange={(e, { value }) => {
              setFieldValue('bondInsurance.property.property_type', value);
            }}
          />
        </Grid.Column>

        <Grid.Column width={8}>
          <label>CEP</label>

          <Input
            placeholder="______-__"
            fluid
            loading={cepLoading}
            value={
              values?.bondInsurance?.property?.zip_code
                ? maskZipCode(values?.bondInsurance?.property?.zip_code)
                : ''
            }
            onChange={e => {
              onChangeCep(setFieldValue, 'bondInsurance.property', e, setCepLoading);
            }}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={5}>
          <label>LOGRADOURO</label>
          <Input
            loading={cepLoading}
            fluid
            value={values?.bondInsurance?.property?.street}
            onChange={e => {
              setFieldValue('bondInsurance.property.street', e.target.value);
            }}
            placeholder="Digite..."
          />
        </Grid.Column>

        <Grid.Column width={6}>
          <label>BAIRRO</label>
          <Input
            loading={cepLoading}
            fluid
            value={values?.bondInsurance?.property?.district}
            onChange={e => {
              setFieldValue('bondInsurance.property.district', e.target.value);
            }}
            placeholder="Digite..."
          />
        </Grid.Column>

        <Grid.Column width={5}>
          <label>CIDADE</label>
          <Input
            loading={cepLoading}
            fluid
            value={values?.bondInsurance?.property?.city}
            onChange={e => {
              setFieldValue('bondInsurance.property.city', e.target.value);
            }}
            placeholder="Digite..."
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={5}>
          <label>UF</label>
          <Dropdown
            search
            loading={cepLoading}
            style={{ width: '100%' }}
            clearable
            selection
            options={UFOptions}
            placeholder={'Selecionar...'}
            value={values?.bondInsurance?.property?.state}
            onChange={(e, { value }) => {
              setFieldValue('bondInsurance.property.state', value);
            }}
          />
        </Grid.Column>

        <Grid.Column width={6}>
          <label>NÚMERO</label>
          <Input
            loading={cepLoading}
            fluid
            value={values?.bondInsurance?.property?.number}
            onChange={e => {
              setFieldValue('bondInsurance.property.number', e.target.value);
            }}
            placeholder="Digite..."
          />
        </Grid.Column>

        <Grid.Column width={5}>
          <label>COMPLEMENTO</label>
          <Input
            loading={cepLoading}
            fluid
            value={values?.bondInsurance?.property?.complement}
            onChange={e => {
              setFieldValue('bondInsurance.property.complement', e.target.value);
            }}
            placeholder="Digite..."
          />
        </Grid.Column>
      </Grid.Row>
      <br />

      <Grid.Row>
        <Grid.Column width={5}>
          <label>ALUGUEL (R$)</label>
          <Input
            fluid
            value={
              values?.bondInsurance?.property?.rent_value
                ? formatAmount(values?.bondInsurance?.property?.rent_value)
                : ''
            }
            onChange={e => {
              setFieldValue('bondInsurance.property.rent_value', e.target.value);
            }}
            placeholder={'R$ 0,00'}
          />
        </Grid.Column>

        <Grid.Column width={5}>
          <label>CONDOMÍNIO (R$)</label>
          <Input
            fluid
            value={
              values?.bondInsurance?.property?.condominium_fee
                ? formatAmount(values?.bondInsurance?.property?.condominium_fee)
                : ''
            }
            onChange={e => {
              setFieldValue('bondInsurance.property.condominium_fee', e.target.value);
            }}
            placeholder={'R$ 0,00'}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={5}>
          <label>IPTU (R$)</label>
          <Input
            fluid
            value={
              values?.bondInsurance?.property?.taxes_value
                ? formatAmount(values?.bondInsurance?.property?.taxes_value)
                : ''
            }
            onChange={e => {
              setFieldValue('bondInsurance.property.taxes_value', e.target.value);
            }}
            placeholder={'R$ 0,00'}
          />
        </Grid.Column>

        <Grid.Column width={6}>
          <label>ENERGIA (R$)</label>
          <Input
            fluid
            value={
              values?.bondInsurance?.property?.energy_bill
                ? formatAmount(values?.bondInsurance?.property?.energy_bill)
                : ''
            }
            onChange={e => {
              setFieldValue('bondInsurance.property.energy_bill', e.target.value);
            }}
            placeholder={'R$ 0,00'}
          />
        </Grid.Column>

        <Grid.Column width={5}>
          <label>ÁGUA (R$)</label>
          <Input
            fluid
            value={
              values?.bondInsurance?.property?.water_bill
                ? formatAmount(values?.bondInsurance?.property?.water_bill)
                : ''
            }
            onChange={e => {
              setFieldValue('bondInsurance.property.water_bill', e.target.value);
            }}
            placeholder={'R$ 0,00'}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={5}>
          <label>GÁS CANALIZADO (R$)</label>
          <Input
            fluid
            Value={
              values?.bondInsurance?.property?.gas_bill
                ? formatAmount(values?.bondInsurance?.property?.gas_bill)
                : ''
            }
            onChange={e => {
              setFieldValue('bondInsurance.property.gas_bill', e.target.value);
            }}
            placeholder={'R$ 0,00'}
          />
        </Grid.Column>

        <Grid.Column width={6}>
          <label>MULTAS CONTRATUAIS (R$)</label>
          <Input
            fluid
            value={
              values?.bondInsurance?.property?.contract_penalties_value
                ? formatAmount(
                    values?.bondInsurance?.property?.contract_penalties_value
                  )
                : ''
            }
            onChange={e => {
              setFieldValue(
                'bondInsurance.property.contract_penalties_value',
                e.target.value
              );
            }}
            placeholder={'R$ 0,00'}
          />
        </Grid.Column>

        <Grid.Column width={5}>
          <label>PINTURA INTERNA (R$)</label>
          <Input
            fluid
            value={
              values?.bondInsurance?.property?.interior_painting_value
                ? formatAmount(values?.bondInsurance?.property?.interior_painting_value)
                : ''
            }
            onChange={e => {
              setFieldValue(
                'bondInsurance.property.interior_painting_value',
                e.target.value
              );
            }}
            placeholder={'R$ 0,00'}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={5}>
          <label>PINTURA EXTERNA (R$)</label>
          <Input
            fluid
            value={
              values?.bondInsurance?.property?.exterior_painting_value
                ? formatAmount(values?.bondInsurance?.property?.exterior_painting_value)
                : ''
            }
            onChange={e => {
              setFieldValue(
                'bondInsurance.property.exterior_painting_value',
                e.target.value
              );
            }}
            placeholder={'R$ 0,00'}
          />
        </Grid.Column>

        <Grid.Column width={6}>
          <label>DANOS A MÓVEIS (R$)</label>
          <Input
            fluid
            value={
              values?.bondInsurance?.property?.furniture_damage_value
                ? formatAmount(values?.bondInsurance?.property?.furniture_damage_value)
                : ''
            }
            onChange={e => {
              setFieldValue(
                'bondInsurance.property.furniture_damage_value',
                e.target.value
              );
            }}
            placeholder={'R$ 0,00'}
          />
        </Grid.Column>

        <Grid.Column width={5}>
          <label>DANOS AO IMÓVEL (R$)</label>
          <Input
            fluid
            value={
              values?.bondInsurance?.property?.property_damage_value
                ? formatAmount(values?.bondInsurance?.property?.property_damage_value)
                : ''
            }
            onChange={e => {
              setFieldValue(
                'bondInsurance.property.property_damage_value',
                e.target.value
              );
            }}
            placeholder={'R$ 0,00'}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};


export default RenderPropertyForm;
