import styled from 'styled-components';
import {
  Form,
  Grid,
  Checkbox,
  Sidebar,
  Image,
  Message,
  Responsive
} from 'semantic-ui-react';

export const WelcomeText = styled.p`
  font-size: 1.8em;
`;

export const InfoText = styled.p`
  font-size: 1em;
  margin-bottom: 1em;
`;

export const Logo = styled(Image)`
  width: 6em;
`;

export const Imag = styled(Image)`
  width: 8em;
`;

export const Confiax = styled.div`
  margin-top: 2em;
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const Pushable = styled(Sidebar.Pushable)`
  height: 100vh !important;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-y: hidden !important;
`;

export const FormContainer = styled.div`
  margin-top: 4em;
`;

export const SideBarGrid = styled(Responsive)`
  height: 100%;
  flex: 0.25;
  display: flex;
  flex-direction: column;
  padding: 2em 4em;
  @media (max-width: 768px) {
    flex: 1;
  }
`;

export const Pusher = styled(Sidebar.Pusher)`
  display: initial;
  flex: 0.75;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ImageBackgroundCover = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: #0061ff;
  z-index: -1;
  opacity: 0.6;
`;

export const ImageBackground = styled(Image)`
  width: 100%;
  position: absolute;
  top: -16em;
  z-index: -2;
`;

export const ImageBackgroundText = styled.div`
  font-size: 2em;
  line-height: 1.5em;
  color: white;
  width: 100%;
  text-align: center;
  margin-top: 1em;
`;
