import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Button, Dimmer, Grid, Icon, Input, Loader, Modal } from 'semantic-ui-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { get } from '../../../../services/request';
import { createBanner, updateBanner, getBannersByID } from '../../../../services/banner';
import { Banner, BannerStatusEnum } from '../../../../types/Banner';
import {
  dangerNotification,
  successNotification
} from '../../../../services/notification';
import Toggle from '../../../../components/Toggle';
import { BlankImage, Container, Image, ImageContainer, ImageForm, ModalToggleContainer } from './styles';

type Callback = () => void;

interface ModalProps {
  id: string;
  isOpen: boolean;
  closeModal: Callback;
}

const BannerManagement = ({ id, isOpen, closeModal }: ModalProps) => {
  const queryClient = useQueryClient();
  const [form, setForm] = useState<Banner>({
    title: '',
    observations: '',
    imageUrl: '',
    redirectUrl: '',
    status: BannerStatusEnum.ACTIVE,
    newTab: true,
    banner: null
  });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [validateSubmit, setValidateSubmit] = useState<boolean>(false);
  const modalStyle = {
    marginTop: '2rem',
    padding: 10
  };
  const actionButtonContainerStyle = {
    display: 'flex',
    justifyContent: 'end',
    marginTop: 40
  };
  const toggleStyle = {
    display: 'flex',
    alignItems: 'baseline',
    gap: 10
  };

  const { data: banner, isFetching: isBannerLoading } = useQuery<Banner>(
    ['getBannersByID', id],
    () => getBannersByID(id),
    {
      keepPreviousData: true,
      enabled: id !== 'new',
    }
  );

  const getBannerFormData = (): FormData => {
    const formData = new FormData();
    Object.entries(form).forEach(([name, value]) => {
      if (value !== null) {
        formData.append(name, value);
      }
    });
    return formData;
  };

  const { mutate: createBannerMutate, isLoading: isCreatingBannerLoading } = useMutation(
    createBanner,
    {
      onError: () => {
        dangerNotification('Oops!', `Erro ao cadastrar banner`);
      },
      onSuccess: async () => {
        await get('/users/invalidate-cache');
        successNotification('Sucesso!', 'Banner cadastrado com sucesso.');
        closeModal();
      },
      onSettled: () => {
        queryClient.invalidateQueries();
      }
    }
  );

  const { mutate: updateBannerMutate, isLoading: isUpdatingBannerLoading } = useMutation(
    () => updateBanner(id, getBannerFormData()),
    {
      onSuccess: async () => {
        await get('/users/invalidate-cache');
        successNotification('Sucesso!', 'Banner atualizado com sucesso.');
        closeModal();
      },
      onError: () => {
        dangerNotification('Oops!', `Erro ao atualizar banner`);
      },
      onSettled: () => {
        queryClient.invalidateQueries();
      }
    }
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setValidateSubmit(true);
    if (form?.title) {
      if (id == 'new') {
        if (form?.banner) {
          createBannerMutate({ data: getBannerFormData() });
        } else {
          dangerNotification('Error', 'A imagem é obrigatória no cadastro.');
        }
      } else {
        updateBannerMutate();
      }
    } else {
      dangerNotification('Error', 'O campo título é obrigatório.');
    }
  };

  useEffect(() => {
    setValidateSubmit(false);
    setForm({
      title: '',
      observations: '',
      imageUrl: '',
      redirectUrl: '',
      status: BannerStatusEnum.ACTIVE,
      newTab: true,
      banner: null
    });
    if (id !== 'new' && banner?.id) {
      setForm({
        title: banner?.title,
        observations: banner?.observations,
        imageUrl: banner?.imageUrl,
        redirectUrl: banner?.redirectUrl,
        status: banner?.status,
        newTab: banner?.newTab,
        banner: null,
      });
    }
  }, [id, banner, isOpen]);

  useEffect(() => {
    if (validateSubmit) setValidateSubmit(true);
  }, [form, validateSubmit]);

  return (
    <Modal
      style={modalStyle}
      size="large"
      closeIcon
      open={isOpen}
      onClose={() => closeModal()}
      onOpen={() => closeModal()}
      loading={true}
    >
      <Modal.Header>
        <Grid>
          <Grid.Row>
            <Grid.Column width={12}>
              <h3>{id === 'new' ? 'Novo' : 'Editar'} banner</h3>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right">
              <ModalToggleContainer>
                <label>
                  {form?.status === BannerStatusEnum.ACTIVE ? 'Ativo' : 'Inativo'}
                </label>
                <Toggle
                  checked={form?.status === BannerStatusEnum.ACTIVE}
                  onChange={(_, data) => {
                    setForm(prevValue => ({
                      ...prevValue,
                      status: data.checked
                        ? BannerStatusEnum.ACTIVE
                        : BannerStatusEnum.INACTIVE
                    }));
                  }}
                />
              </ModalToggleContainer>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Content>
          <Dimmer
            active={isBannerLoading || isCreatingBannerLoading || isUpdatingBannerLoading}
            inverted
          >
            <Loader />
          </Dimmer>
          <Container>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <ImageContainer>
                    <ImageForm>
                      {form?.banner && (
                        <Image
                          alt="Preview image"
                          src={URL.createObjectURL(form?.banner)}
                        />
                      )}
                      {!form?.banner && form?.imageUrl && (
                        <Image alt="Preview image" src={form?.imageUrl} />
                      )}
                      {!form?.banner && !form?.imageUrl && (
                        <BlankImage name="image outline" style={{ fontSize: 120 }} />
                      )}
                    </ImageForm>
                  </ImageContainer>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <label>Título do banner*</label>
                  <Input
                    placeholder="Título do banner"
                    fluid
                    onChange={(_, { value }) =>
                      setForm(prevValue => ({
                        ...prevValue,
                        title: value as string
                      }))
                    }
                    value={form.title}
                    error={validateSubmit && form?.title === ''}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <label>Descrição</label>
                  <Input
                    placeholder="Descrição"
                    fluid
                    onChange={(_, { value }) =>
                      setForm(prevValue => ({
                        ...prevValue,
                        observations: value as string
                      }))
                    }
                    value={form.observations}
                    error={validateSubmit && form?.observations === ''}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <label>Selecionar image</label>
                  <br />
                  <small>Proporção recomendada: 550 x 250px.</small>
                  <br />
                  <br />
                  <Button
                    color="blue"
                    type="button"
                    onClick={() => fileInputRef?.current?.click()}
                  >
                    <Icon name="image outline" />
                    {id === 'new' ? 'Fazer upload' : 'Alterar imagem'}
                  </Button>
                  <input
                    ref={fileInputRef}
                    type="file"
                    hidden
                    onChange={event => {
                      const banner = event?.target?.files
                        ? event?.target?.files[0]
                        : null;
                      setForm(prevValue => ({
                        ...prevValue,
                        banner
                      }));
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <label>Adicionar link</label>
                  <br />
                  <small>
                    Ao clicar na imagem o usuário será direcionado até o link.
                  </small>
                  <Input
                    fluid
                    placeholder="https://"
                    onChange={(_, { value }) =>
                      setForm(prevValue => ({
                        ...prevValue,
                        redirectUrl: value as string
                      }))
                    }
                    value={form.redirectUrl}
                  />
                  <br />
                  <div style={toggleStyle}>
                    <Input
                      type="checkbox"
                      onChange={() =>
                        setForm(prevValue => ({
                          ...prevValue,
                          newTab: !prevValue?.newTab
                        }))
                      }
                      checked={form.newTab}
                    />
                    Abrir link em nova aba do navegador
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Modal.Content>
        <Modal.Actions style={actionButtonContainerStyle}>
          <Button color="grey" type="button" onClick={closeModal}>
            Cancelar
          </Button>
          <Button color="blue" type="submit">
            Salvar
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
};

export default BannerManagement;
