import React, { SyntheticEvent, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Confirm, Dimmer, Grid, Icon, Loader } from 'semantic-ui-react';
import Button from '../../components/Button';
import { Banner, BannerStatusEnum } from '../../types/Banner';
import {
  Container,
  Header,
  CustomGridRow,
  CardContainer,
  InfoContainer,
  ImageLinkContainer,
  PlaceholderNumber,
  Image,
  CardGridRow,
  ActionContainer,
  ButtonContainer,
  Wrap
} from './styles';
import Toggle from '../../components/Toggle';
import { getBanners, deleteBannerByID, toggleStatus } from '../../services/banner';
import IconButton from '../../components/IconButton';
import { DateToStringDate } from '../../services/date';
import EditModal from './components/EditModal';
import { dangerNotification, successNotification } from '../../services/notification';
import { get } from '../../services/storage';

const BannerManagement = () => {
  const queryClient = useQueryClient();
  const [selectedId, setSelectedId] = useState<string>('new');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedBanner, setSelectedBanner] = useState<Banner>();
  const iconSize = { fontSize: 18 };

  const handleCreateBanner = (id: string) => {
    setSelectedId(id);
    setIsModalOpen(true);
  };

  const {
    data: banners,
    isFetching: isBannersLoading,
    refetch
  } = useQuery(['getBanners'], () => getBanners(), {
    keepPreviousData: true
  });

  const { mutate: updateBannerMutate, isLoading: isUpdatingBannerLoading } = useMutation(
    toggleStatus,
    {
      onSuccess: async () => {
        await get('/users/invalidate-cache');
        refetch();
        successNotification('Sucesso!', 'Banner atualizado com sucesso.');
      },
      onError: () => {
        dangerNotification('Oops!', `Erro ao atualizar banner`);
      },
      onSettled: () => {
        queryClient.invalidateQueries();
      }
    }
  );

  const { mutate: deleteBannerMutate, isLoading: isDeletingBannerLoading } = useMutation(
    deleteBannerByID,
    {
      onSuccess: async () => {
        await get('/users/invalidate-cache');
        refetch();
        successNotification('Sucesso!', 'Banner removido com sucesso.');
      },
      onError: () => {
        dangerNotification('Oops!', `Erro ao remover banner`);
      },
      onSettled: () => {
        queryClient.invalidateQueries();
      }
    }
  );

  const handleToggle = (banner: Banner) => {
    if (banner?.id) {
      updateBannerMutate({
        bannerId: banner?.id,
        status: banner?.status === BannerStatusEnum.ACTIVE ?
          BannerStatusEnum.INACTIVE : BannerStatusEnum.ACTIVE,
      });
    }
  };

  const handleDelete = (banner: Banner) => {
    if (banner?.id) {
      setSelectedBanner(banner);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    refetch();
  };

  const getImageName = (filename: string) => {
    const nameSplitted = filename.split('_');
    return nameSplitted.pop()?.split('?')[0] || '';
  };

  return (
    <Container>
      <Dimmer
        active={isBannersLoading || isUpdatingBannerLoading || isDeletingBannerLoading}
        inverted
      >
        <Loader />
      </Dimmer>
      <Confirm
        open={!!selectedBanner}
        onCancel={() => setSelectedBanner(undefined)}
        onConfirm={() => {
          if (selectedBanner?.id) {
            deleteBannerMutate(selectedBanner?.id);
          }
          setSelectedBanner(undefined);
        }}
        cancelButton={'Cancelar'}
        confirmButton={'Remover'}
        header={'Remover banner'}
        content={'Após a remoção, a imagem deixará de ser exibida no painel'}
        size='tiny'
      />
      <EditModal id={selectedId} isOpen={isModalOpen} closeModal={closeModal} />
      <Header>
        <div>
          <h3>Banners</h3>
          <span>Inclua os banners para exibição rotativa no dashboard do painel.</span>
        </div>
        <Button
          type={'button'}
          size={'medium'}
          color="blue"
          text="Novo Banner"
          disabled={isBannersLoading || isUpdatingBannerLoading || isDeletingBannerLoading}
          onClick={() => handleCreateBanner('new')}
        />
      </Header>
      <Grid>
        <CustomGridRow>
          <Grid.Column width={12}>Conteúdo</Grid.Column>
          <Grid.Column width={4}>Status de exibição</Grid.Column>
        </CustomGridRow>
        {banners?.map((banner, index) => (
          <CardGridRow key={banner?.id}>
            <Grid.Column width={12}>
              <CardContainer>
                <PlaceholderNumber>{index + 1}</PlaceholderNumber>
                <Image alt="Banner" src={banner?.imageUrl} />
                <InfoContainer>
                  <h3>{banner?.title}</h3>
                  <small>{banner?.observations}</small>
                  <ImageLinkContainer>
                    <Icon color="grey" name="image outline" style={iconSize} />
                    {banner?.imageUrl && <span>{getImageName(banner?.imageUrl)}</span>}
                    <Icon color="grey" name="linkify" style={iconSize} />
                    <a
                      href={banner?.redirectUrl}
                      target={banner?.newTab ? '_blank' : 'unset'}
                    >
                      {banner?.redirectUrl}
                    </a>
                  </ImageLinkContainer>
                </InfoContainer>
              </CardContainer>
            </Grid.Column>
            <Grid.Column width={4}>
              <ActionContainer>
                <ButtonContainer>
                  <Wrap>
                    <label>
                      {banner?.status === BannerStatusEnum.ACTIVE ? 'Ativo' : 'Inativo'}
                    </label>
                    <Toggle
                      checked={banner?.status === BannerStatusEnum.ACTIVE}
                      onChange={()  => handleToggle(banner)}
                    />
                  </Wrap>
                  <Wrap>
                    <IconButton
                      color="blue"
                      name="pencil"
                      style={iconSize}
                      type="button"
                      onClick={() => handleCreateBanner(banner?.id || 'new')}
                    />
                    <IconButton
                      color="red"
                      name="trash alternate outline"
                      type="button"
                      onClick={() => handleDelete(banner)}
                      style={iconSize}
                    />
                  </Wrap>
                </ButtonContainer>
                {banner?.createdAt && (
                  <small>Adicionado em: {DateToStringDate(banner?.createdAt)}</small>
                )}
              </ActionContainer>
            </Grid.Column>
          </CardGridRow>
        ))}
      </Grid>
    </Container>
  );
};

export default BannerManagement;
