import * as Yup from "yup";
import {INVALID_EMAIL_MESSAGE, REQUIRED_FIELD_MESSAGE} from "../../constants";

export const tokioMarineTenantInputSectionYupSchema: Yup.ObjectSchema<TokioMarineTenantInputSectionSchema> = Yup.object().shape({
  document: Yup.string()
    .required(REQUIRED_FIELD_MESSAGE)
    .isValidDocument(),
  name: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  email: Yup.string().required(REQUIRED_FIELD_MESSAGE).email(INVALID_EMAIL_MESSAGE),
  birthDate: Yup.date().required(REQUIRED_FIELD_MESSAGE),
  telephone: Yup.string().required(REQUIRED_FIELD_MESSAGE).isValidMaskedTelephone()
});

export type TokioMarineTenantInputSectionSchema = {
  document: string;
  name: string;
  email: string;
  birthDate: Date;
  telephone: string;
}
