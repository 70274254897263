/* eslint-disable @typescript-eslint/no-use-before-define */
import Swal from 'sweetalert2';
import './styles.css';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Header, Icon, Modal, Step, Loader, Dimmer } from 'semantic-ui-react';
import { PortoModalHireProposalProps } from '../../../types/ModalHireProposalProps';
import ProductSelectForm from '../Steps/ProductSelectStep';
import SurtyTenantForm from '../Steps/LessorFormStep';
import PropertyForm from '../Steps/PropertyFormStep';
import TenantForm from '../Steps/TenantFormStep';
import { Formik } from 'formik';
import PortoEssencialHireValidationSchema from './Model/validationSchema';
import { PortoEssencialHireInitialValue } from '../Formik/initialValues';
import { post, put } from '../../../../../../../services/request';
import { get } from '../../../../../../../services/storage';
import { PayloadPortoUpdateRequestInterface } from './interfaces/SubmitEssencialHirePorto.interface';
import {
  dangerNotification,
  successNotification,
  warningNotification
} from '../../../../../../../services/notification';
import { validateCNPJ, validateCPF } from '../../../../../../../services/validators';
import ContractDateForm from '../Steps/ContractDatesStep';
import { FormatDateYMD } from '../../../../../../../services/date';
import { AxiosError } from 'axios';
import withReactContent from 'sweetalert2-react-content';
import { hasPermission } from '../../../../../../../components/AuthorizationContainer';
import { ActionEnum } from '../../../../../../../enums/authz-action.enum';
import { FeatureEnum } from '../../../../../../../enums/authz-feature.enum';
import { sliceString } from '../../../../../../../utils/sliceString';
import { monthDiff } from '../../../../../../../util';

export type ProductFormProps = {
  nomeSeguro: string;
  seguro: string;
  condicaoPagamento: string;
  premioTotal: number;
  premioLiquido: number;
  premiobruto: number;
  quantidadeParcela: number;
  primeiroVencimento: string;
  valorParcela: number;
  iof: number;
  coberturas: {
    nomeCobertura: string;
    multiploLMI: number;
    obrigatorio: string;
    codCobertura: string;
    valorIS: number;
    valorLMI: number;
    valorIsOriginal: number;
    valorPremionumber: number;
  }[];
  beneficios: [
    {
      titulo: string;
      descricao: string;
    }[]
  ];
};

const HirePortoModal = ({
  analysis,
  bondInsurance,
  estate,
  callback,
  open,
  cleanIdReferences,
  setOpen
}: PortoModalHireProposalProps) => {
  enum stepsEnum {
    PROPERTY_INFO, /// INFORMAÇÕES DO IMÓVEL (ENDEREÇO)
    RESIDENTS, /// INFORMAÇÕES DOS INQUILINOS
    LESSOR_INFO, //// INFORMAÇÕES DO LOCADOR
    CONTRACT, /// AJUSTE DAS DATAS DO CONTRATO
    PRODUCT_SELECT, /// ESCOLHER OFERTA
    SUMMARY /// RESUMO
  }

  type ModalContentType = {
    children: React.ReactNode;
    values: PayloadPortoUpdateRequestInterface;
    onSubmit?: Function | undefined;
  };

  const userId = get('uid');
  const [offers, setOffers] = useState(analysis.offers);
  const [openHireProposalModal, setOpenHireProposalModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeStep, setactiveStep] = useState<stepsEnum>(stepsEnum.PROPERTY_INFO);

  const [userCanContract, setUserCanContract] = useState(false);

  useEffect(() => {
    const has = hasPermission(ActionEnum.contract, FeatureEnum.analysis);
    setUserCanContract(has);
  }, []);

  const MySwal = withReactContent(Swal);

  const AcceptTerms = useCallback(
    () =>
      MySwal.fire({
        title: `Deseja prosseguir com a contratação em <b>${analysis.insurer.name}</b> ?`,
        text: 'Importante: A cláusula deverá ser inserida no Contrato de Locação e os dados informados para essa contratação deverão estar inseridos e de acordo com o Contrato de Locação.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, prosseguir para a contratação!',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.isConfirmed) {
          (async () => {
            const { value: checked } = await MySwal.fire({
              title: 'Declaro que: ',
              html: `<div class="row"><input id="swal-input1" type="checkbox" class="checkbox"> <label for="swal-input1">Os dados informados para essa contratação estão de acordo e inseridos no Contrato de Locação; Qualquer alteração realizada nesta etapa, poderá afetar o orçamento realizado.</label> </div>
              <div class="row"><input id="swal-input2" type="checkbox" class="checkbox"> <label for="swal-input2">A Cláusula foi inserida no Contrato de Locação. </label> </div>
              <div class="row"><input id="swal-input4" type="checkbox" class="checkbox"> <label for="swal-input4"> Declaro que essa locação não tem débitos em aberto até esta data. </label> </div>`,
              focusConfirm: false,
              confirmButtonText: 'Ciente e prosseguir',
              denyButtonText: 'Cancelar',
              preConfirm: () => {
                return [
                  (document.getElementById('swal-input1') as HTMLInputElement).checked
                    ? 'true'
                    : 'false',
                  (document.getElementById('swal-input2') as HTMLInputElement)?.checked
                    ? 'true'
                    : 'false',
                  (document.getElementById('swal-input3') as HTMLInputElement)?.checked
                    ? 'true'
                    : 'false',
                  (document.getElementById('swal-input4') as HTMLInputElement)?.checked
                    ? 'true'
                    : 'false'
                ];
              }
            });
            console.log(checked);
            if (
              Array.isArray(checked) &&
              checked[0] === 'true' &&
              checked[1] === 'true' &&
              checked[3] === 'true'
            ) {
              setOpenHireProposalModal(true);
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Para continuar, deve aceitar os termos!'
              });
            }
          })();
        }
      }),
    [MySwal, analysis.insurer.name]
  );

  useEffect(() => {
    if (open) {
      cleanIdReferences && cleanIdReferences();
      AcceptTerms();
    }
  }, [AcceptTerms, cleanIdReferences, open]);

  useEffect(() => {
    setOpen && setOpen(openHireProposalModal);
  }, [openHireProposalModal, setOpen]);

  function handleDisposeHireProposal() {
    setactiveStep(stepsEnum.PROPERTY_INFO);
    setOpenHireProposalModal(false);
  }

  function validateProductSelectStep(values: PayloadPortoUpdateRequestInterface) {
    let error = false;
    if (!values.product.code) {
      error = true;
      warningNotification('Campo obrigatório.', 'Por favor, selecione uma oferta.');
    }
    return !error;
  }

  function validateNames(name: string) {
    //minimo de 5 e máximo de 60 caracteres, sendo necessário no mínimo duas palavras separadas por um espaço
    // const regex = /^[a-zA-ZÀ-ú]{2,}(?: [a-zA-ZÀ-ú]+){1,}$/;
    const maxcharregex = /^.{5,60}$/;
    return maxcharregex.test(name);
    // return regex.test(name) && maxcharregex.test(name);
  }

  function validateEmail(email: string) {
    /// email validation
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  }

  function validateCellPhone(phone: string) {
    // onlynumbres with 11 digits
    const onlyNumbers = phone.replace(/\D/g, '');
    const regex = /^[0-9]{11}$/;
    return regex.test(onlyNumbers);
  }

  function validatePhone(phone: string) {
    // onlynumbres with 10 digits
    const onlyNumbers = phone.replace(/\D/g, '');
    const regex = /^[0-9]{10}$/;
    return regex.test(onlyNumbers);
  }

  function validateCpfCnpj(cpfCnpj: string) {
    const onlyNumbers = cpfCnpj.replace(/\D/g, '');
    if (onlyNumbers.length === 11) {
      return validateCPF(onlyNumbers);
    } else if (onlyNumbers.length === 14) {
      return validateCNPJ(onlyNumbers);
    }
    return false;
  }

  async function validateContractStep(values: PayloadPortoUpdateRequestInterface) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let error = false;
    if (!values.contract.startContractDate) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, selecione a data de início do contrato.'
      );
    }
    if (!values.contract.endContractDate) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, selecione a data de término do contrato.'
      );
    }
    if (!values.contract.startInsuranceDate) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, selecione a data de início da vigência do seguro.'
      );
    }
    if (!values.contract.endInsuranceDate) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, selecione a data de término da vigência do seguro.'
      );
    }

    /*
      Motor de regras | Regra: 000041 |
      Retorno: Vigencia Futura(0) não permite:
      o Início de Contrato e Seguro DIFERENTES OU Fim de Contrato e Seguro DIFERENTES | V
      o Início de Contrato MENOR que a data atual OU Início de Contrato MAIOR que a data atual + 90 dias. |
      o Fim de Contrato MENOR que a data atual + 6 meses OU Fim de Contrato MAIOR que a data atual + 5 anos |
      Período do seguro MENOR que 6 meses. (->)
    */

    if (
      FormatDateYMD(new Date(values.contract.startContractDate)) !==
      FormatDateYMD(new Date(values.contract.startInsuranceDate))
    ) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'O início do contrato e o início da vigência do seguro devem ser iguais.'
      );
    }

    if (
      FormatDateYMD(new Date(values.contract.endContractDate)) !==
      FormatDateYMD(new Date(values.contract.endInsuranceDate))
    ) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'O fim do contrato e o fim da vigência do seguro devem ser iguais.'
      );
    }
    //   o Início de Contrato MENOR que a data atual OU Início de Contrato MAIOR que a data atual + 90 dias. |

    // timestamp To Date

    if (new Date(values.contract.startContractDate) < today) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'O início do contrato não pode ser menor que a data atual.'
      );
    }

    if (
      new Date(values.contract.startContractDate) >
      new Date(new Date().setDate(new Date().getDate() + 90))
    ) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'O início do contrato não pode ser maior que a data atual + 90 dias.'
      );
    }

    //   o Fim de Contrato MENOR que a data atual + 12 meses OU Fim de Contrato MAIOR que a data atual + 5 anos |

    if (
      new Date(values.contract.endContractDate) <=
      new Date(new Date().setMonth(new Date().getMonth() + 12))
    ) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'O fim do contrato não pode ser menor que a data atual + 12 meses.'
      );
    }

    if (
      new Date(values.contract.endContractDate) >
      new Date(new Date().setFullYear(new Date().getFullYear() + 5))
    ) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'O fim do contrato não pode ser maior que a data atual + 5 anos.'
      );
    }

    //  DIFERENCA ENTRE INICIO E FIM DO SEGURO DEVEM SER MAIOR OU IGUAL A 12 MESES

    if (
      new Date(values.contract.endInsuranceDate) <=
      new Date(
        new Date(values.contract.startInsuranceDate).setMonth(
          new Date(values.contract.startInsuranceDate).getMonth() + 12
        )
      )
    ) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'A diferença entre o início e o fim da vigência do seguro deve ser maior ou igual a 12 meses.'
      );
    }

    if (!values.contract.dueDate) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'O dia de Vencimento do Aluguel é obrigatório.'
      );
    }


    const informedVigency = monthDiff(
      new Date(values.contract.startContractDate),
      new Date(values.contract.endContractDate)
    )
    if(!error && informedVigency !== bondInsurance.property.validity) {
      const response = await Swal.fire({
        title: "Alteração de vigência",
        text: `A vigência do seguro informada é de ${informedVigency} meses, diferente da vigência da cotação inicial do seguro que é de ${bondInsurance.property.validity} meses. Essa divergência pode gerar alteração na quantidade e valores das parcelas do seguro. Deseja continuar?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, continuar",
        cancelButtonText: "Voltar",
      });

      if (response.isConfirmed) {
        await Swal.fire({
          title: "Vigência alterada com sucesso!",
          text: "A cotação será reajustada para a nova vigência.",
          icon: "success",
          timer: 1500,
        });
      } else {
        error = true;
      }
    }
    return !error;
  }

  const isCnpj = (cnpj: string) => {
    const onlynumbers = cnpj.replace(/[^\d]+/g, '');
    if (onlynumbers === '') return false;
    if (onlynumbers.length !== 14) return false;
    return true;
  };

  function validateLessorStep(values: PayloadPortoUpdateRequestInterface) {
    let error = false;
    const isCnpjValue = isCnpj(values.lessor.document);
    if (values.lessor.document === values.tenants.document) {
      error = true;
      warningNotification(
        'Documento inválido.',
        'O documento do inquilo não pode ser igual ao documento do locador. Por favor, insira um documento diferente.'
      );
    }

    if (!values.lessor.name) {
      error = true;
      warningNotification('Campo obrigatório.', 'Por favor, preencha o nome do locador.');
    } else {
      if (!validateNames(values.lessor.name)) {
        error = true;
        warningNotification(
          'Campo inválido.',
          'Atenção, o nome do locador deve conter no mínimo 5 caracteres e no máximo 60 caracteres e ao menos 1 espaço. '
        );
      }
    }
    if (!values.lessor.document) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o documento do locador.'
      );
    } else {
      if (!validateCpfCnpj(values.lessor.document)) {
        error = true;
        warningNotification(
          'Campo inválido.',
          'Atenção, documento do locador é inválido. Verifique e tente novamente. '
        );
      }
    }
    if (!values.lessor.email) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o email do locador.'
      );
    } else {
      if (!validateEmail(values.lessor.email)) {
        error = true;
        warningNotification(
          'Campo inválido.',
          'Atenção, o email do locador é inválido. Verifique e tente novamente. '
        );
      }
    }

    if (!isCnpjValue && values.lessor.residentialPhone) {
      if (!validatePhone(values.lessor.residentialPhone)) {
        error = true;
        warningNotification(
          'Campo inválido.',
          'Atenção, o telefone residencial do locador é inválido. Verifique e tente novamente. '
        );
      }
    }

    if (isCnpjValue && values.lessor.commercialPhone) {
      if (!validatePhone(values.lessor.commercialPhone)) {
        error = true;
        warningNotification(
          'Campo inválido.',
          'Atenção, o telefone comercial do locador é inválido. Verifique e tente novamente. '
        );
      }
    }

    if (!isCnpjValue && values.lessor.cellphone) {
      if (!validateCellPhone(values.lessor.cellphone)) {
        error = true;
        warningNotification(
          'Campo inválido.',
          'Atenção, o celular do locador é inválido. Verifique e tente novamente. '
        );
      }
    }

    if (!isCnpjValue && !values.lessor.birthdate) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha a data de nascimento do locador.'
      );
    }

    if (!isCnpjValue && values.lessor.mother) {
      if (!validateNames(values.lessor.mother)) {
        error = true;
        warningNotification(
          'Campo inválido.',
          'Atenção, o nome da mãe do locador deve conter no mínimo 5 caracteres e no máximo 60 caracteres e ao menos 1 espaço. '
        );
      }
    }

    if (!values.lessor.address.city) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o municipio do locador.'
      );
    }

    if (!values.lessor.address.streetType) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o tipo do logradouro.'
      );
    }

    if (!values.lessor.address.estate) {
      error = true;
      warningNotification('Campo obrigatório.', 'Por favor, preencha o estado.');
    }

    if (!values.lessor.address.street) {
      error = true;
      warningNotification('Campo obrigatório.', 'Por favor, preencha a rua.');
    }
    if (!values.lessor.address.number) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o número da residência do locador.'
      );
    } else {
      if (values.lessor.address.number === 'SN') {
        if (!values.lessor.address.complement) {
          error = true;
          warningNotification(
            'Campo obrigatório.',
            'Por favor, preencha o complemento do endereço do locador.'
          );
        }
      }
    }
    if (!values.lessor.address.zipcode) {
      error = true;
      warningNotification('Campo obrigatório.', 'Por favor, preencha o CEP do locador.');
    }
    if (!values.lessor.address.district) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o bairro do locador.'
      );
    }
    return !error;
  }

  function validatePropertyStep(values: PayloadPortoUpdateRequestInterface) {
    let error = false;
    if (!values.property.city) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha a cidade para prosseguir.'
      );
    }

    if (!values.property.streetType) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o tipo do logradouro do imóvel.'
      );
    }

    if (!values.property.estate) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o estado para prosseguir.'
      );
    }

    if (!values.property.district) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o bairro para prosseguir.'
      );
    }

    if (!values.property.street) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o Logradouro para prosseguir.'
      );
    }

    if (!values.property.number) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o número para prosseguir.'
      );
    }

    if (!values.property.zipcode) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o CEP para prosseguir.'
      );
    }

    if (!values.property.complement && !values.property.hasComplement) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Complemento é obrigatório. Caso não haja, marque no checkbox. '
      );
    }

    if (!values.property.propertyType) {
      error = true;
      warningNotification('Campo obrigatório.', 'Tipo do imóvel é obrigatório.');
    }

    return !error;
  }

  function validateResidentStep(values: PayloadPortoUpdateRequestInterface) {
    let error = false;
    if (!values.tenants.name) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o nome do inquilino.'
      );
    } else {
      if (!validateNames(values.tenants.name)) {
        error = true;
        warningNotification(
          'Campo inválido.',
          'Atenção, nome do inquilino inválido. Verifique e tente novamente. '
        );
      }
    }

    if (!values.tenants.document) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o documento do inquilino.'
      );
    } else {
      if (!validateCpfCnpj(values.tenants.document)) {
        error = true;
        warningNotification(
          'Campo inválido.',
          'Atenção, documento do inquilino inválido. Verifique e tente novamente. '
        );
      }
    }

    if (!values.tenants.email) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o email do inquilino.'
      );
    } else {
      if (!validateEmail(values.tenants.email)) {
        error = true;
        warningNotification(
          'Campo inválido.',
          'Atenção, email do inquilino inválido. Verifique e tente novamente. '
        );
      }
    }

    if (!values.tenants.cellphone) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o telefone do inquilino.'
      );
    } else {
      if (!validateCellPhone(values.tenants.cellphone)) {
        error = true;
        warningNotification(
          'Campo inválido.',
          'Atenção, celular do inquilino inválido. Verifique e tente novamente. '
        );
      }
    }

    if (!values.tenants.birthdate) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha a data de nascimento do inquilino.'
      );
    }

    return !error;
  }

  function submitValidation(
    values: PayloadPortoUpdateRequestInterface,
    submit: Function
  ) {
    let error = false;
    if (!validateLessorStep(values)) {
      error = true;
    }
    if (!validatePropertyStep(values)) {
      error = true;
    }
    if (!validateResidentStep(values)) {
      error = true;
    }
    if (!validateProductSelectStep(values)) {
      error = true;
    }
    if (!validateContractStep(values)) {
      error = true;
    }
    if (!error) {
      values.lessor.document = values.lessor.document.replace(/[^\d]+/g, '');
      submit();
    }
  }

  async function handleNextStep(
    values: PayloadPortoUpdateRequestInterface,
    onSubmit?: Function
  ): Promise<void | null> {
    switch (activeStep) {
      case stepsEnum.PROPERTY_INFO:
        return validatePropertyStep(values)
          ? setactiveStep(stepsEnum.RESIDENTS)
          : setactiveStep(stepsEnum.PROPERTY_INFO);
      case stepsEnum.RESIDENTS:
        return validateResidentStep(values)
          ? setactiveStep(stepsEnum.LESSOR_INFO)
          : setactiveStep(stepsEnum.RESIDENTS);
      case stepsEnum.LESSOR_INFO:
        return validateLessorStep(values)
          ? setactiveStep(stepsEnum.CONTRACT)
          : setactiveStep(stepsEnum.LESSOR_INFO);
      case stepsEnum.CONTRACT:
        return (await validateContractStep(values))
          ? await updateContract(values)
          : setactiveStep(stepsEnum.CONTRACT);
      case stepsEnum.PRODUCT_SELECT:
        return validateProductSelectStep(values)
          ? setactiveStep(stepsEnum.SUMMARY)
          : setactiveStep(stepsEnum.PRODUCT_SELECT);
      case stepsEnum.SUMMARY:
        return onSubmit && submitValidation(values, onSubmit);
      default:
        return setactiveStep(stepsEnum.PROPERTY_INFO);
    }
  }

  function handleBackStep(): void {
    if (activeStep === stepsEnum.PROPERTY_INFO) {
      handleDisposeHireProposal();
      return;
    }
    setactiveStep(activeStep - 1);
  }

  const ModalContent = ({ children, values, onSubmit }: ModalContentType) => (
    <>
      <Modal.Content
        scrolling
        style={{ height: `${activeStep === stepsEnum.PRODUCT_SELECT ? '100%' : '58vh'}` }}
      >
        {children}
      </Modal.Content>
      <Modal.Actions>
        <Button
          color={activeStep === stepsEnum.PROPERTY_INFO ? 'red' : 'grey'}
          onClick={() => handleBackStep()}
        >
          {activeStep === stepsEnum.PROPERTY_INFO ? (
            <>
              <Icon name={'remove'} /> Cancelar{' '}
            </>
          ) : (
            <>
              <Icon name={'arrow left'} /> Voltar{' '}
            </>
          )}
        </Button>
        <Button color="green" onClick={() => handleNextStep(values, onSubmit)}>
          {activeStep === stepsEnum.SUMMARY ? (
            <>
              {' '}
              <Icon name={'checkmark'} /> Confirmar{' '}
            </>
          ) : (
            <>
              {' '}
              Próximo <Icon name={'arrow right'} />
            </>
          )}
        </Button>
      </Modal.Actions>
    </>
  );

  async function submitValues(values: PayloadPortoUpdateRequestInterface) {
    try {
      setLoading(true);
      delete values.property.hasComplement;
      await post('/bond-insurance/proposals/hire/porto', {
        userId: userId,
        analysisId: analysis.id,
        payload: {
          ...values,
          property: {
            ...values.property,
            city: sliceString(values.property.city, 30),
            district: sliceString(values.property.district, 40),
            complement: sliceString(values.property.complement, 20),
            street: sliceString(values.property.street, 40),
            number: sliceString(values.property.number, 6)
          }
        }
      });
      successNotification('Sucesso!', 'Proposta enviada com sucesso!');
      setLoading(false);
      callback();
      handleDisposeHireProposal();
    } catch (error) {
      setLoading(false);
      const err = error as AxiosError;

      if (err.response?.status === 406) {
        // Erro da porto ao efetivar a proposta
        warningNotification(
          'Atenção!',
          "Sua proposta será efetivada manualmente, você poderá entrontrá-la em 'Gerenciar Propostas'."
        );
        callback();
        handleDisposeHireProposal();
        return;
      }
      if (err.response?.status === 407) {
        // Deu quase certo
        successNotification(
          'Sucesso!',
          "Proposta enviada com sucesso! Sua apólice foi gerada e será anexada em breve. Poderá acompanhar o andamento em 'Gerenciar Apólices'."
        );
        callback();
        handleDisposeHireProposal();
        return;
      }

      if (err.response?.status === 401) {
        // Erro basico
        dangerNotification(
          'Erro!',
          'Erro interno, por favor tente novamente mais tarde.'
        );
        return;
      }

      dangerNotification(
        'Erro!',
        'Módulo indisponível, por favor tente novamente mais tarde.'
      ); // Erro desconhecido
      return;
    }
  }

  async function updateContract(values: PayloadPortoUpdateRequestInterface) {
    try {
      setLoading(true);
      const response = await put('/bond-insurance/proposals/update/porto', {
        userId: userId,
        analysisId: analysis.id,
        payload: {
          contract: values.contract,
          property: {
            ...values.property,
            city: sliceString(values.property.city, 30),
            district: sliceString(values.property.district, 40),
            complement: sliceString(values.property.complement, 20),
            street: sliceString(values.property.street, 40),
            number: sliceString(values.property.number, 6)
          }
        }
      });
      setOffers(response.details.offers);
      successNotification('Sucesso!', 'Ajuste de contrato realizado com sucesso!');
      setactiveStep(stepsEnum.PRODUCT_SELECT);
      return;
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status) {
        if (err.response.status === 405) {
          dangerNotification(
            'Atenção!',
            'Essa alteração não foi aceita pela Porto. Verifique as datas. Caso erro persista, entre em contato com seu Gestor de Contas. '
          );
          return null;
        }
        if (err.response.status === 406) {
          dangerNotification(
            'Erro!',
            'Ocorreu um erro ao realizar o ajuste das datas, por favor, tente novamente. Caso erro persista, entre em contato com seu Gestor de Contas. '
          );
          return null;
        }
      }
      dangerNotification(
        'Erro!',
        'Caso erro persista, entre em contato com seu Gestor de Contas.'
      );
      return null;
    } finally {
      setLoading(false);
    }
  }

  return (
    <div style={{ outline: 'none', flex: 'auto' }}>
      <Modal
        style={{ marginTop: '2rem' }}
        size="large"
        closeIcon
        open={openHireProposalModal}
        trigger={
          <Button disabled={!userCanContract} positive fluid>
            Contratar
          </Button>
        }
        onClose={() => setOpenHireProposalModal(false)}
        onOpen={AcceptTerms}
      >
        <Dimmer active={loading}>
          <Loader indeterminate>
            {activeStep === stepsEnum.CONTRACT
              ? 'Ajustando dados do contrato...'
              : 'Enviando proposta...'}
          </Loader>
        </Dimmer>
        <Header icon="file alternate outline" content="Contratação Fiança" />
        <Step.Group size="small" widths={6} style={{ margin: '0 0 20px 0' }}>
          <Step
            active={activeStep === stepsEnum.PROPERTY_INFO}
            title="Informações do imóvel"
            description=""
          />

          <Step
            active={activeStep === stepsEnum.RESIDENTS}
            title="Informações do locatário"
            description=""
          />

          <Step
            active={activeStep === stepsEnum.LESSOR_INFO}
            title="Informações do Locador"
            description=""
          />

          <Step
            active={activeStep === stepsEnum.CONTRACT}
            title="Ajustes do contrato"
            description=""
          />

          <Step
            active={activeStep === stepsEnum.PRODUCT_SELECT}
            title="Escolha uma oferta"
            description=""
          />

          <Step
            active={activeStep === stepsEnum.SUMMARY}
            title="Confira as informações"
            description=""
          />
        </Step.Group>
        <Formik
          initialValues={PortoEssencialHireInitialValue}
          validationSchema={PortoEssencialHireValidationSchema}
          onSubmit={e => submitValues(e)}
        >
          {innerValues => {
            switch (activeStep) {
              case stepsEnum.PRODUCT_SELECT:
                return (
                  <ModalContent
                    children={<ProductSelectForm {...innerValues} offers={offers} />}
                    values={innerValues.values}
                  />
                );
              case stepsEnum.LESSOR_INFO:
                return (
                  <ModalContent
                    children={<SurtyTenantForm {...innerValues} estate={estate} />}
                    values={innerValues.values}
                  />
                );
              case stepsEnum.PROPERTY_INFO:
                return (
                  <ModalContent
                    children={
                      <PropertyForm {...innerValues} property={bondInsurance.property} />
                    }
                    values={innerValues.values}
                  />
                );
              case stepsEnum.RESIDENTS:
                return (
                  <ModalContent
                    children={
                      <TenantForm
                        {...innerValues}
                        tenant={bondInsurance.natural_tenant}
                      />
                    }
                    values={innerValues.values}
                  />
                );

              case stepsEnum.CONTRACT:
                return (
                  <ModalContent
                    children={
                      <ContractDateForm
                        {...innerValues}
                        property={bondInsurance.property}
                        minDate={analysis.createdAt!}
                      />
                    }
                    values={innerValues.values}
                  />
                );

              case stepsEnum.SUMMARY:
                return (
                  <ModalContent
                    // eslint-disable-next-line
                    children={
                      analysis.offers && (
                        <div>
                          <ContractDateForm
                            {...innerValues}
                            property={bondInsurance.property}
                            readOnly
                          />
                          <ProductSelectForm {...innerValues} offers={offers} />
                          <SurtyTenantForm {...innerValues} estate={estate} />
                          <PropertyForm {...innerValues} />
                          <TenantForm {...innerValues} />
                        </div>
                      )
                    }
                    values={innerValues.values}
                    onSubmit={innerValues.handleSubmit}
                  />
                );
              default:
                return <div>Not Found</div>;
            }
          }}
        </Formik>
      </Modal>
    </div>
  );
};
export default HirePortoModal;
