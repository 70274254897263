import React from 'react';
import { FieldLabel, FieldValue } from '../styles';
import { Grid } from 'semantic-ui-react';

type InfoProps = {
  name: string;
  width: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;
  value: any;
}

export const Info = (props: InfoProps) => {


  const { name, value, width } = props;
  return (
    <>
      <Grid.Column width={width}>
        <FieldLabel style={{ whiteSpace: 'nowrap', marginLeft: '5px' }}>{`${name.toUpperCase()}: `}</FieldLabel>
        <FieldValue>{value}</FieldValue>
      </Grid.Column>
    </>
  );
}
