import { DropdownItemProps } from "semantic-ui-react";
import { AnalysisAssuranceEnum } from "../../enums/analysis-assurance.enum";
import { CalculationModeEnum } from "../../enums/calculation-mode.enum";
import { CalculationTypeEnum } from "../../enums/calculation-type.enum";
import { EstatePartner } from "../../types/EstatePartner";
import { ResponsibleCompanyEnum } from "../../enums/responsible-company.enum";

export const batchClosingDayOptions: Array<DropdownItemProps> = [
  { value: 1, key: 1, text: "Dia 01" },
  { value: 2, key: 2, text: "Dia 02" },
  { value: 3, key: 3, text: "Dia 03" },
  { value: 4, key: 4, text: "Dia 04" },
  { value: 5, key: 5, text: "Dia 05" },
  { value: 6, key: 6, text: "Dia 06" },
  { value: 7, key: 7, text: "Dia 07" },
  { value: 8, key: 8, text: "Dia 08" },
  { value: 9, key: 9, text: "Dia 09" },
  { value: 10, key: 10, text: "Dia 10" },
  { value: 11, key: 11, text: "Dia 11" },
  { value: 12, key: 12, text: "Dia 12" },
  { value: 13, key: 13, text: "Dia 13" },
  { value: 14, key: 14, text: "Dia 14" },
  { value: 15, key: 15, text: "Dia 15" },
  { value: 16, key: 16, text: "Dia 16" },
  { value: 17, key: 17, text: "Dia 17" },
  { value: 18, key: 18, text: "Dia 18" },
  { value: 19, key: 19, text: "Dia 19" },
  { value: 20, key: 20, text: "Dia 20" },
  { value: 21, key: 21, text: "Dia 21" },
  { value: 22, key: 22, text: "Dia 22" },
  { value: 23, key: 23, text: "Dia 23" },
  { value: 24, key: 24, text: "Dia 24" },
  { value: 25, key: 25, text: "Dia 25" },
  { value: 26, key: 26, text: "Dia 26" },
  { value: 27, key: 27, text: "Dia 27" },
  { value: 28, key: 28, text: "Dia 28" },
  { value: 29, key: 29, text: "Dia 29" },
  { value: 30, key: 30, text: "Dia 30" },
  { value: 31, key: 31, text: "Dia 31" }
];


export const installmentsOptions: Array<DropdownItemProps> = [
  { value: 1, key: 1, text: "1x" },
  { value: 2, key: 2, text: "2x" },
  { value: 3, key: 3, text: "3x" },
  { value: 4, key: 4, text: "4x" },
  { value: 5, key: 5, text: "5x" },
  { value: 6, key: 6, text: "6x" },
  { value: 7, key: 7, text: "7x" },
  { value: 8, key: 8, text: "8x" },
  { value: 9, key: 9, text: "9x" },
  { value: 10, key: 10, text: "10x" },
  { value: 11, key: 11, text: "11x" },
  { value: 12, key: 12, text: "12x" },
];

export const estateFormInitialValues: EstateFormValues = {
  observations: '',
  hasPreferenceBondInsurance: false,
  name: '',
  email: '',
  cnpj: '',
  company_name: '',
  profitShare: 0,
  batchClosingDay: 0,
  multiplier: 0,
  broker: {
    id: '',
    name: ''
  },
  estateAnalystUser: {
    id: '',
    name: ''
  },
  bankAccount: {
    accountDv: '',
    accountNumber: '',
    agencyDv: '',
    agencyNumber: '',
    bankCode: '',
  },
  calculationMode: CalculationModeEnum.rent,
  calculationType: CalculationTypeEnum.rentOnly,
  responsible_company: ResponsibleCompanyEnum.confiax,
  bondInsurers: [],
  fireInsurers: [],
  capitalizations: [],
  partners: [],
  isLibertyFireInsuranceSelected: false,
  isPottencialFireInsuranceSelected: false,
  isTooSegurosBondInsuranceSelected: false,
  isTokioMarineBondInsuranceSelected: false,
  isGaranttiBondInsuranceSelected: false,
  isPottencialBondInsuranceSelected: false,
  isPorto12xBondInsuranceSelected: false,
  isPorto18xBondInsuranceSelected: false,
  isPortoEssencialBondInsuranceSelected: false,
  isPortoTradicionalBondInsuranceSelected: false,
  isPortoTradicionalCapitalizationSelected: false,
  insurerSpecificData: {
    liberty: {
      fireInsurance: {
        offerLetter: ''
      }
    },
    tooSeguros: {
      bondInsurance: {
        commissionPercentage: '',
        orderPreference: 0,
        assurance: undefined,
        pfResidentialTax: '',
        pfComercialTax: '',
        lmi: 0,
      }
    },
    tokioMarine: {
      bondInsurance: {
        commissionPercentage: '',
        orderPreference: 0,
        assurance: undefined,
        pfResidentialTax: '',
        pfComercialTax: '',
        lmi: 0,
      }
    },
    garantti: {
      bondInsurance: {
        pfResidentialTax: '',
        pfComercialTax: '',
        lmi: 0,
        garanttiPlan: '',
        garanttiBonus: false,
        assurance: undefined,
        orderPreference: 0,
        commissionPercentage: '',
      }
    },
    pottencial: {
      bondInsurance: {
        multiple: 0,
        assurance: undefined,
        orderPreference: 0,
        pfResidentialTax: '',
        pfComercialTax: '',
        lmi: 0,
        commissionPercentage: '',
      },
      fireInsurance: {
        commissionPercentage: 40,
        multiplier: 200
      }
    },
    portoEssencial: {
      bondInsurance: {
        orderPreference: 0,
        pfResidentialTax: '',
        pfComercialTax: '',
        lmi: 0,
        assurance: undefined,
        commissionPercentage: '',
      }
    },
    portoTradicional: {
      bondInsurance: {
        orderPreference: 0,
        pfResidentialTax: '',
        pfComercialTax: '',
        lmi: 0,
        assurance: undefined,
        commissionPercentage: '',
      }
    }

  }
};

export interface EstateFormValues {
  observations?: string;
  hasPreferenceBondInsurance: boolean;
  preRegisterId?: string;
  isGaranttiBondInsuranceSelected: any;
  isPottencialBondInsuranceSelected: boolean;
  name: string;
  email: string;
  cnpj: string;
  creci?: string;
  franchiseId?: string;
  company_name: string;
  profitShare: number;
  batchClosingDay: number;
  multiplier: number;
  broker: BrokerForEstateForm;
  isLibertyFireInsuranceSelected: boolean;
  isPottencialFireInsuranceSelected: boolean;
  isTooSegurosBondInsuranceSelected: boolean;
  isTokioMarineBondInsuranceSelected: boolean;
  isPorto12xBondInsuranceSelected: boolean;
  isPorto18xBondInsuranceSelected: boolean;
  isPortoEssencialBondInsuranceSelected: boolean;
  isPortoTradicionalBondInsuranceSelected: boolean;
  isPortoTradicionalCapitalizationSelected: boolean;
  insurerSpecificData: EstateInsurerSpecificDataForEstateForm;
  fireInsurers: InsurerForEstateForm[];
  capitalizations: InsurerForEstateForm[];
  bondInsurers: InsurerForEstateForm[];
  estateAnalystUser: UserForEstateForm;
  calculationMode: CalculationModeEnum;
  calculationType: CalculationTypeEnum;
  bankAccount: BankAccountForEstateForm;
  partners?: EstatePartner[];

  estate_zipcode?: string;
  estate_street?: string;
  estate_number?: string;
  estate_complement?: string;
  estate_district?: string;
  estate_city?: string;
  estate_state?: string;

  contact_source?: string;

  responsible_name?: string;
  responsible_email?: string;
  responsible_phone?: string;

  financial_responsible?: string;
  financial_email?: string;
  status?: string;

  additional_emails?: string;
  emails?: any;

  portoCode?: string;
  garanttiCode?: string;
  classification?: string;
  averagePropertiesWallet?: number;
  monthlyTenantryCapacity?: number;
  additionalAccountManager?: UserForEstateForm;
  claimResponsible?: UserForEstateForm;
  responsible_company: ResponsibleCompanyEnum;
}

export type EstateInsurerSpecificDataForEstateForm = {
  liberty: EstateLibertyInsurerSpecificDataForEstateForm;
  tooSeguros: EstateTooSegurosInsurerSpecificDataForEstateForm;
  tokioMarine: EstateTokioMarineInsurerSpecificDataForEstateForm;
  garantti: EstateTGaranttiInsurerSpecificDataForEstateForm;
  pottencial: EstatePottencialInsurerSpecificDataForEstateForm;
  portoEssencial: EstatePortoEssencialInsurerSpecificDataForEstateForm;
  portoTradicional: EstatePortoEssencialInsurerSpecificDataForEstateForm;
}

export type EstatePortoEssencialInsurerSpecificDataForEstateForm = {
  bondInsurance: EstatePortoEssencialInsurerBondInsuranceSpecificDataForEstateForm;
}

export type EstatePortoEssencialInsurerBondInsuranceSpecificDataForEstateForm = {
  orderPreference: number;
  pfResidentialTax: string;
  pfComercialTax: string;
  lmi: number;
  assurance: AnalysisAssuranceEnum | undefined;
  commissionPercentage: string;
}

export type EstateTooSegurosInsurerSpecificDataForEstateForm = {
  bondInsurance: EstateTooSegurosInsurerBondInsuranceSpecificDataForEstateForm;
}

export type EstateTokioMarineInsurerSpecificDataForEstateForm = {
  bondInsurance: EstateTokioMarineInsurerBondInsuranceSpecificDataForEstateForm;
}

export type EstatePottencialInsurerSpecificDataForEstateForm = {
  bondInsurance: EstatePottecnialSegurosInsurerBondInsuranceSpecificDataForEstateForm;
  fireInsurance: EstatePottecnialSegurosInsureFireInsuranceSpecificDataForEstateForm;
}

export type EstatePorto12xInsurerSpecificDataForEstateForm = {
  bondInsurance: EstatePorto12xInsurerBondInsuranceSpecificDataForEstateForm;
}

export type EstatePorto18xInsurerSpecificDataForEstateForm = {
  bondInsurance: EstatePorto18xInsurerBondInsuranceSpecificDataForEstateForm;
}

export type EstatePottecnialSegurosInsurerBondInsuranceSpecificDataForEstateForm = {
  multiple: number;
  assurance: AnalysisAssuranceEnum | undefined;
  orderPreference: number;
  pfResidentialTax: string;
  pfComercialTax: string;
  lmi: number;
  commissionPercentage: string;
}

export type EstatePottecnialSegurosInsureFireInsuranceSpecificDataForEstateForm = {
  multiplier: number;
  commissionPercentage: number;
}

export type EstateTooSegurosInsurerBondInsuranceSpecificDataForEstateForm = {
  commissionPercentage: string;
  orderPreference: number;
  assurance: AnalysisAssuranceEnum | undefined;
  pfResidentialTax: string;
  pfComercialTax: string;
  lmi: number;
}

export type EstateTokioMarineInsurerBondInsuranceSpecificDataForEstateForm = {
  commissionPercentage: string;
  orderPreference: number;
  assurance: AnalysisAssuranceEnum | undefined;
  pfResidentialTax: string;
  pfComercialTax: string;
  lmi: number;
}

export type EstateLibertyInsurerSpecificDataForEstateForm = {
  fireInsurance: EstateLibertyInsurerFireInsuranceSpecificDataForEstateForm;
}

export type EstateLibertyInsurerFireInsuranceSpecificDataForEstateForm = {
  offerLetter: string;
}

export type EstateTGaranttiInsurerSpecificDataForEstateForm = {
  bondInsurance: EstateGaranttiInsurerBondInsuranceSpecificDataForEstateForm;
}

export type EstateGaranttiInsurerBondInsuranceSpecificDataForEstateForm = {
  pfResidentialTax: string;
  pfComercialTax: string;
  lmi: number;
  garanttiPlan: string;
  garanttiBonus: boolean;
  orderPreference: number;
  assurance: AnalysisAssuranceEnum | undefined;
  commissionPercentage: string;
}

export type EstatePorto12xInsurerBondInsuranceSpecificDataForEstateForm = {
  porto12XTax: string;
  orderPreference: number;
  assurance: AnalysisAssuranceEnum | undefined;
}

export type EstatePorto18xInsurerBondInsuranceSpecificDataForEstateForm = {
  porto18XTax: string;
  orderPreference: number;
  assurance: AnalysisAssuranceEnum | undefined;
}

export type BrokerForEstateForm = {
  id: string;
  name: string;
}

export type InsurerForEstateForm = {
  id: string;
  code: string;
  name: string;
  imageURL: string;
}

export type UserForEstateForm = {
  id: string;
  name: string;
}

export type BankAccountForEstateForm = {
  bankCode: string;
  agencyNumber: string;
  agencyDv: string;
  accountNumber: string;
  accountDv: string;
}
