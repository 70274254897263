import React from 'react';
import { BondInsurance } from '../../../types/BondInsurance';
import PrintableModalWithoutAttach from '../../../components/PrintableModalWithoutAttach';
import { Grid } from 'semantic-ui-react';
import { BondInsuranceMainInfoDetails } from './BondInsuranceMainInfoDetails';
import { BondInsurancePropertyDetails } from './BondInsurancePropertyDetails';
import SectionLabel from '../../../components/SectionLabel';
import { PersonTypesEnum } from '../../../enums/person-types.enum';
import { BondInsuranceNaturalTenantDetails } from './BondInsuranceNaturalTenantDetails';
import { BondInsuranceLegalTenantDetails } from './BondInsuranceLegalTenantDetails';
import { BondInsuranceForGetBondInsurancesResponseDTO } from "../../../dtos/bond-insurance-list/get-bond-insurances-response.dto";
import { EstateInsurerDataForGetEstatesResponseDTO, InsurerForGetEstateResponseDTO } from '../../../dtos/estate/estate-for-get-estates-response.dto';
import { UserRolesEnum } from '../../../enums/user-roles.enum';
import GenericLogModal from '../../../components/GenericLogModal/LogModal';

type UserProps = {
  id: string;
  name: string;
  role: string;
}

export type Props = {
  user: UserProps;
  bondInsurance: BondInsuranceForGetBondInsurancesResponseDTO;
  isOpen: boolean;
  onClose: React.ReactEventHandler<{}>;
  insurersData: EstateInsurerDataForGetEstatesResponseDTO[];
  insurers: InsurerForGetEstateResponseDTO[];
};

export const BondInsuranceDetails = (props: Props) => {

  const { insurersData, user, bondInsurance, isOpen, onClose, insurers } = props;
  const [relatedIds, setRelatedIds] = React.useState<string[]>([]);

  React.useEffect(() => {
   if(bondInsurance.bond_insurance_analysis) {
    const analysisIds = bondInsurance.bond_insurance_analysis.map((analysis) => analysis.id);
    setRelatedIds(analysisIds.concat(bondInsurance.id));
   }
  }, [bondInsurance])

  return (
    <>
      <PrintableModalWithoutAttach
        isOpen={isOpen}
        onClose={onClose}
        header={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
           Seguro Fiança Expirado
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
            {user.role === UserRolesEnum.brokerAdmin && (
                 <GenericLogModal
                    createdAt={bondInsurance.created_at}
                    updatedAt={bondInsurance.updated_at}
                    relatedIds={relatedIds}
                />
              )}
            </div>
          </div>
        }
        bondInsuranceId={bondInsurance.id}
        content={
          <Grid>
            {/* <SectionLabel text='DADOS DO CANCELAMENTO' /> */}
            <SectionLabel text='DADOS DA ANÁLISE' />
            <BondInsuranceMainInfoDetails bondInsurance={bondInsurance} insurersData={insurersData} insurers={insurers}/>
            <BondInsurancePropertyDetails bondInsurance={bondInsurance} />
            <SectionLabel text='DADOS DO LOCATÁRIO' />
            {bondInsurance.tenant_type === PersonTypesEnum.natural && (
              <BondInsuranceNaturalTenantDetails bondInsurance={bondInsurance} />
            )}
            {bondInsurance.tenant_type === PersonTypesEnum.legal && (
              <BondInsuranceLegalTenantDetails bondInsurance={bondInsurance} />
            )}
          </Grid>
        }
        callback={(): Promise<void | BondInsurance> => {
          return {} as Promise<BondInsurance>;
        }
        } />
    </>
  );
}
