import React, { useEffect, useState } from 'react';
import {
  Grid,
  Input,
  Dropdown as DropdownNativo,
  DropdownItemProps,
  Icon,
  Button
} from 'semantic-ui-react';
import { UpdateCancelation } from '../../types';
import { InsurerState } from '../../../../../../types/InsurerState';
import { CustoDropDownItemProps } from '../../../../../../components/types';
import { maskCpfOrCnpj, maskZipCode } from '../../../../../../services/masks';
import { AddressService } from '../../../../../../services/address';
import FormatedTextArea from '../../../../../../components/FormatedTextArea';
import { cancellationReasonOptions } from '../../../../types';
import { FormContainer, FormTitle, Line, EmailContainer } from '../../styles';
import { UserState } from '../../../../../../types/UserState';
import { canceledBondInsuranceAvailableStatus } from '../../../../CanceledBondInsurances';
import { isValidEmail } from '../../../../../../utils/emailValidator';
import { FieldLabel } from '../../../../../../styles';

type EstatesInfoProps = {
  estates: CustoDropDownItemProps[] | null;
  insurers: InsurerState[] | null;
};

interface DropdownOptions {
  index: number;
  value: number | string;
  text: string;
}

interface FormProps {
  form: UpdateCancelation;
  setForm: React.SetStateAction<any>;
  isValidForm: boolean;
  user: UserState;
  responsibleOptions: CustoDropDownItemProps[];
}

const CancelationInfoForm = ({
  form,
  insurers,
  estates,
  setForm,
  isValidForm,
  user,
  responsibleOptions,
}: FormProps & EstatesInfoProps) => {
  const [selectableInsurers, setSelectableInsurers] = useState<DropdownOptions[] | any[]>(
    []
  );
  const [selectableEstates, setSelectableEstates] = useState<DropdownOptions[] | any[]>(
    []
  );
  const [loadDocument, setLoadDocument] = useState<boolean>(false);
  const [reasonsOptions, setReasonsOptions] = useState<Array<DropdownItemProps>>([]);
  const [isCepLoading, setIsCepLoading] = useState<boolean>(false);

  useEffect(() => {
    setReasonsOptions(cancellationReasonOptions);
  }, []);

  useEffect(() => {
    if (insurers && insurers.length) {
      setSelectableInsurers(
        insurers.map(insurer => ({
          index: insurer.id,
          value: insurer.id,
          text: insurer.name
        }))
      );
    }
  }, [insurers]);

  useEffect(() => {
    if (estates && estates.length) {
      setSelectableEstates(
        estates.map(estate => ({
          index: estate.value.id,
          value: estate.value.id,
          text: estate.value.name
        }))
      );
    }
  }, [estates]);

  const renderCancelationForm = (): React.ReactNode => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel>Status*</FieldLabel>
            <div style={{ width: "100%", paddingTop: '8px' }}>
              <DropdownNativo
                search
                clearable
                selection
                value={form?.status}
                options={canceledBondInsuranceAvailableStatus}
                placeholder={'Selecionar...'}
                error={isValidForm && form?.status === ''}
                onChange={(_, { value }) => {
                  setForm((prevValue: UpdateCancelation) => ({
                    ...prevValue,
                    status: value as string
                  }));
                }}
                fluid
              />
            </div>
          </Grid.Column>
          <Grid.Column width={8}>
            <FieldLabel>Motivo do cancelamento*</FieldLabel>
            <div style={{ width: "100%", paddingTop: '8px' }}>
              <DropdownNativo
                search
                clearable
                selection
                value={form.cancellationReason}
                options={reasonsOptions}
                placeholder={'Selecionar...'}
                error={isValidForm && form.cancellationReason === ''}
                onChange={(_, { value }) => {
                  setForm((prevValue: UpdateCancelation) => ({
                    ...prevValue,
                    cancellationReason: value as string
                  }));
                }}
                fluid
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel>Responsável:</FieldLabel>
            <div style={{ width: "100%", paddingTop: '8px' }}>
              <DropdownNativo
                search
                clearable
                selection
                value={form?.responsibleId}
                options={responsibleOptions}
                placeholder={'Selecionar...'}
                error={isValidForm && form?.responsibleId === ''}
                onChange={(_, { value }) => {
                  setForm((prevValue: UpdateCancelation) => ({
                    ...prevValue,
                    responsibleId: value as string
                  }));
                }}
                fluid
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <label>
              Observações
              <FormatedTextArea
                placeholder="Digite..."
                fluid
                value={form?.observations}
                onChange={(_, { value }) => {
                  setForm((prevValue: UpdateCancelation) => ({
                    ...prevValue,
                    observations: value as string
                  }));
                }}
              />
            </label>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const renderBondInsuranceForm = (): React.ReactNode => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel>Seguradora garantidora*</FieldLabel>
            <div style={{ width: "100%", paddingTop: '8px' }}>
              <DropdownNativo
                search
                clearable
                selection
                value={form?.cancelation?.insurerId}
                options={selectableInsurers}
                placeholder={'Selecione...'}
                error={isValidForm && form?.cancelation?.insurerId === ''}
                onChange={(_, { value }) => {
                  setForm((prevValue: UpdateCancelation) => ({
                    ...prevValue,
                    cancelation: {
                      ...prevValue.cancelation,
                      insurerId: value as string
                    }
                  }));
                }}
                fluid
              />
            </div>
          </Grid.Column>
          <Grid.Column width={8}>
            <FieldLabel>Imobiliária*</FieldLabel>
            <div style={{ width: "100%", paddingTop: '8px' }}>
              <DropdownNativo
                search
                clearable
                selection
                value={form?.cancelation?.estateId}
                options={selectableEstates}
                placeholder={'Selecione...'}
                error={isValidForm && form?.cancelation?.estateId === ''}
                onChange={(_, { value }) => {
                  setForm((prevValue: UpdateCancelation) => ({
                    ...prevValue,
                    cancelation: {
                      ...prevValue.cancelation,
                      estateId: value as string
                    }
                  }));
                }}
                fluid
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel>Número da apólice*</FieldLabel>
            <div style={{ width: "100%", paddingTop: '8px' }}>
              <Input
                placeholder="Número da apólice"
                onChange={(_, { value }) =>
                  setForm((prevValue: UpdateCancelation) => ({
                    ...prevValue,
                    cancelation: {
                      ...prevValue.cancelation,
                      policyNumber: value as string
                    }
                  }))
                }
                error={isValidForm && form?.cancelation?.policyNumber === ''}
                value={form?.cancelation?.policyNumber}
                fluid
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const renderTenantForm = (): React.ReactNode => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>
              CPF/CNPJ*
              <Input
                placeholder="CPF/CNPJ"
                onChange={(_, { value }) =>
                  setForm((prevValue: UpdateCancelation) => ({
                    ...prevValue,
                    tenant: {
                      ...prevValue.tenant,
                      document: maskCpfOrCnpj(value as string)
                    }
                  }))
                }
                error={isValidForm && form?.tenant?.document === ''}
                fluid
                value={form?.tenant?.document}
              />
            </label>
          </Grid.Column>
          <Grid.Column width={8}>
            <label>
              Nome do locatário*
              <Input
                placeholder="Nome do locatário"
                onChange={(_, { value }) =>
                  setForm((prevValue: UpdateCancelation) => ({
                    ...prevValue,
                    tenant: {
                      ...prevValue.tenant,
                      fullName: value as string
                    }
                  }))
                }
                disabled={loadDocument}
                error={isValidForm && form?.tenant?.fullName === ''}
                fluid
                value={form?.tenant?.fullName}
              />
            </label>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const changeCep = (value: string) => {
    setForm((prevValue: UpdateCancelation) => ({
      ...prevValue,
      property: {
        ...prevValue.property,
        zipCode: maskZipCode(value)
      }
    }));
    if (maskZipCode(value).length === 9) {
      setIsCepLoading(true);
      AddressService.getAddressByZipCode(value, user.id)
        .then(address => {
          setForm((prevValue: UpdateCancelation) => ({
            ...prevValue,
            property: {
              ...prevValue.property,
              city: address.city,
              district: address.district,
              state: address.state,
              street: address.street
            }
          }));

          setIsCepLoading(false);
        })
        .catch(e => {
          console.log('address error:', e);
        });
    }
  };

  const renderPropertyForm = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>
              CEP*
              <Input
                placeholder="CEP"
                fluid
                onChange={(_, { value }) => {
                  changeCep(value);
                }}
                error={isValidForm && form?.property?.zipCode === ''}
                value={form?.property?.zipCode}
              />
            </label>
          </Grid.Column>
          <Grid.Column width={8}>
            <label>
              Rua*
              <Input
                placeholder="Rua"
                fluid
                onChange={(_, { value }) =>
                  setForm((prevValue: UpdateCancelation) => ({
                    ...prevValue,
                    property: {
                      ...prevValue.property,
                      street: value as string
                    }
                  }))
                }
                disabled={isCepLoading}
                error={isValidForm && form?.property?.street === ''}
                value={form?.property?.street}
              />
            </label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <label>
              Bairro*
              <Input
                placeholder="Bairro"
                fluid
                onChange={(_, { value }) =>
                  setForm((prevValue: UpdateCancelation) => ({
                    ...prevValue,
                    property: {
                      ...prevValue.property,
                      district: value as string
                    }
                  }))
                }
                disabled={isCepLoading}
                error={isValidForm && form?.property?.district === ''}
                value={form?.property?.district}
              />
            </label>
          </Grid.Column>
          <Grid.Column width={8}>
            <label>
              Cidade*
              <Input
                placeholder="Cidade"
                fluid
                onChange={(_, { value }) =>
                  setForm((prevValue: UpdateCancelation) => ({
                    ...prevValue,
                    property: {
                      ...prevValue.property,
                      city: value as string
                    }
                  }))
                }
                disabled={isCepLoading}
                error={isValidForm && form?.property?.city === ''}
                value={form?.property?.city}
              />
            </label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <label>
              Número*
              <Input
                placeholder="Número"
                fluid
                onChange={(_, { value }) =>
                  setForm((prevValue: UpdateCancelation) => ({
                    ...prevValue,
                    property: {
                      ...prevValue.property,
                      number: value as string
                    }
                  }))
                }
                error={isValidForm && form?.property?.number === ''}
                value={form?.property?.number}
              />
            </label>
          </Grid.Column>
          <Grid.Column width={4}>
            <label>
              UF*
              <Input
                placeholder="UF"
                fluid
                onChange={(_, { value }) =>
                  setForm((prevValue: UpdateCancelation) => ({
                    ...prevValue,
                    property: {
                      ...prevValue.property,
                      state: value as string
                    }
                  }))
                }
                disabled={isCepLoading}
                error={isValidForm && form?.property?.state === ''}
                value={form?.property?.state}
              />
            </label>
          </Grid.Column>
          <Grid.Column width={8}>
            <label>
              Complemento
              <Input
                placeholder="Complemento"
                fluid
                onChange={(_, { value }) =>
                  setForm((prevValue: UpdateCancelation) => ({
                    ...prevValue,
                    property: {
                      ...prevValue.property,
                      complement: value as string
                    }
                  }))
                }
                value={form?.property?.complement}
              />
            </label>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const renderEmailsForm = (): React.ReactNode => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <p>
              <Icon name="info circle" color="blue" />{' '}
              <b>Informe os e-mails para receberem notificações do cancelamento.</b>
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <label>
              E-mail
              {form?.cancelation?.notificationEmails?.map(
                (item: string, index: number) => (
                  <EmailContainer>
                    <Input
                      placeholder="E-mail"
                      onChange={(_, { value }) =>
                        setForm((prevValue: UpdateCancelation) => ({
                          ...prevValue,
                          cancelation: {
                            ...prevValue.cancelation,
                            notificationEmails:
                              prevValue?.cancelation?.notificationEmails?.map(
                                (email, i) => (index === i ? value : email)
                              )
                          }
                        }))
                      }
                      fluid
                      value={item}
                      error={isValidForm && (item === '' || !isValidEmail(item))}
                      style={{ marginBottom: 10 }}
                    />
                    <Button
                      className="ui button"
                      style={{  borderRadius: 0, backgroundColor: "#fff" }}
                      type="button"
                    >
                      <Icon
                        name={'trash'}
                        color="red"
                        onClick={() =>
                          setForm((prevValue: UpdateCancelation) => ({
                            ...prevValue,
                            cancelation: {
                              ...prevValue.cancelation,
                              notificationEmails:
                                prevValue?.cancelation?.notificationEmails?.filter(
                                  (_, i) => index !== i
                                )
                            }
                          }))
                        }
                      />
                    </Button>
                  </EmailContainer>
                )
              )}
            </label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Button
              color="blue"
              type="button"
              onClick={e => {
                e.preventDefault();
                setForm((prevValue: UpdateCancelation) => {
                  const newArray = prevValue?.cancelation?.notificationEmails || [];
                  // If the last email is empty, don't add another field
                  if (newArray[newArray.length - 1] !== '') {
                    newArray.push('');
                  }
                  return {
                    ...prevValue,
                    cancelation: {
                      ...prevValue.cancelation,
                      notificationEmails: newArray
                    }
                  };
                });
              }}
            >
              <Icon name="add circle" /> Adicionar novo destinatário
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  return (
    <>
      <FormContainer>
        <FormTitle>INFORMAÇÕES DO CANCELAMENTO</FormTitle>
        <Line />
        {renderCancelationForm()}
      </FormContainer>
      <FormContainer>
        <FormTitle>INFORMAÇÕES DO SEGURO</FormTitle>
        <Line />
        {renderBondInsuranceForm()}
      </FormContainer>
      <FormContainer>
        <FormTitle>INFORMAÇÕES DO LOCATÁRIO</FormTitle>
        <Line />
        {renderTenantForm()}
      </FormContainer>
      <FormContainer>
        <FormTitle>ENDEREÇO DO IMÓVEL</FormTitle>
        <Line />
        {renderPropertyForm()}
        {renderEmailsForm()}
      </FormContainer>
    </>
  );
};

export default CancelationInfoForm;
