import { Sidebar } from 'semantic-ui-react';
import styled from 'styled-components';

export const Pushable = styled(Sidebar.Pushable)`
  position: relative;
  .pusher {
    position: relative;
    overflow: initial !important;
  }
  .ui.left.visible.sidebar,
  .ui.right.visible.sidebar {
    box-shadow: none !important;
  }
`;

const sideBarWidth = '258px';

export const NavbarContainer = styled.div`
  width: 100%;
`;

export const ContentContainer = styled.div`
  height: calc(100vh - 2px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 2px;
  @media (min-width: 769px) {
    width: calc(100% - ${sideBarWidth});
  }
`;

export const Content = styled.div`
  padding: 2em 2em 8em 2em;
  @media (max-width: 768px) {
    padding: 14px;
  }
`;

export const SidebarPusher = styled(Sidebar.Pusher)``;

export const SidebarWrapper = styled(Sidebar)`
  overflow-x: hidden;
  position: absolute;
  z-index: 10;
`;
