import React from 'react'
import { Button, Grid, Modal } from 'semantic-ui-react'
import { FieldLabel } from '../../../../styles';
import { Info } from '../../../../components/Info';
import { GaranttiTaxesModalProps } from './types';
import {
  PlanContainer,
  InsurerContainer,
  Attention,
  CustomModalContent,
  Separation,
  InfoContainer
} from './styles';

const GaranttiTaxesModal: React.FC<GaranttiTaxesModalProps> = ({ isOpen, setIsOpen, clientData, insurerOption }) => {
  const { client, plans } = clientData;

  const translateStatus = (status: string) => {
    switch (status) {
      case 'active':
        return 'ATIVO';
      case 'inactive':
        return 'INATIVO';
      default:
        return status.toUpperCase();
    }
  }

  const feeContent = (
    <CustomModalContent>
      <Modal.Description>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <FieldLabel>DADOS DO CLIENTE</FieldLabel>
              <Separation />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Info name={'CNPJ/CPF'} value={client.document} width={8} />
            <Info name={'TIPO PESSOA'} value={client.type_person} width={8} />
          </Grid.Row>
          <Grid.Row>
            <Info name={'RAZÃO SOCIAL'} value={client.trade_name} width={8} />
            <Info name={'NOME FANTASIA'} value={client.trade_name} width={8} />
          </Grid.Row>
          <Grid.Row>
            <Info name={'NOME RESPONSÁVEL'} value={client.responsible_name} width={8} />
            <Info name={'EMAIL RESPONSÁVEL'} value={client.email} width={8} />
          </Grid.Row>
          <Grid.Row>
            <Info name={'TELEFONE'} value={client.phone ? client.phone : 'Sem telefone cadastrado'} width={8} />
            <Info name={'CRECI'} value={client.creci ? client.creci : 'Sem CRECI cadastrado'} width={8} />
          </Grid.Row>
          <Grid.Row>
            <Info name={'ENDEREÇO'} value={client.street} width={8} />
            <Info name={'BAIRRO'} value={client.district} width={8} />
          </Grid.Row>
          <Grid.Row>
            <Info name={'CIDADE'} value={client.city} width={8} />
            <Info name={'UF E CEP'} value={client.state + ' - ' + client.zipcode} width={8} />
          </Grid.Row>
          <Grid.Row>
            <Info name={'STATUS DO CADASTRO'} value={translateStatus(client.status)} width={8} />
            <Info name={'CADASTRADO EM'} value={client.created_from} width={8} />
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <FieldLabel>TAXAS E PLANOS</FieldLabel>
              <Separation />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {plans.map((plan) => (
              <Grid.Column width={8} key={plan.id}>
                <FieldLabel>{plan.plan.toUpperCase()}{plan.status === 'active' && <span> - Ativo</span>}</FieldLabel>
                <PlanContainer>
                  <p>RESIDENCIAL: {plan.residentialFee}%</p>
                  <p>COMERCIAL: {plan.commercialFee}%</p>
                  <p>LMI: {plan.lmi}X</p>
                </PlanContainer>
              </Grid.Column>
            ))}
          </Grid.Row>

          <Attention>
            ATENÇÃO! Em caso de divergências ou ajustes de taxas e planos, entrar em contato
            direto com a parceira através dos seus canais de atendimentos disponíveis.
          </Attention>
        </Grid>
      </Modal.Description>
    </CustomModalContent>
  );

  return (
    <Modal
      style={{
        marginTop: '2rem',
        padding: 10,
      }}
      size="large"
      closeIcon
      open={isOpen}
      trigger={
        <Button
          positive
          fluid
        >
          Contratar
        </Button>
      }
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(false)}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <InfoContainer>
              <h1>
                Visualizar dados do cliente junto a parceria
              </h1>
              <p>Código integração: {client.id}</p>
            </InfoContainer>
          </Grid.Column>
          <Grid.Column width={8} verticalAlign='top' textAlign='center'>
            <InsurerContainer>
              <img src={insurerOption.image} alt="Logotipo" />
              <p>{insurerOption.name}</p>
            </InsurerContainer>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {feeContent}
    </Modal>
  )
}
export default GaranttiTaxesModal
