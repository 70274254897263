import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';
import { Grid } from 'semantic-ui-react';
import { maskCnpj } from '../../services/masks';
import { BrokerState } from '../../types/BrokerState';
import { EstateData } from './components/EstateData';
import { BankAccountData } from './components/BankAccountData';
import { EstateFormProps } from './EstateForm';
import { BondInsuranceData } from './components/BondInsuranceData';
import { FireInsuranceData } from './components/FireInsuranceData';
import Loader from "../../components/Loader";
import { CapitalizationInsuranceData } from './components/CapitalizationInsuranceData';

const EstateContainer = styled.div`
  height: 100%;
`;

const handleFormInputChange = (data: any, formProps: EstateFormProps) => {
  const { setFieldValue } = formProps;
  const { field } = data;
  let { value } = data;

  if (field.name === 'estate[cnpj]') {
    value = maskCnpj(value);
  }
  setFieldValue(field.name, value);
};

const EstateFormContent = (props: EstateFormProps) => {
  const { availableBrokersAndEstates, setFieldValue, isLoading } = props;
  // Checks if exists only one available broker and get its id
  // If not, the default value is empty and the user must select the broker from dropdown options
  const selectedBroker: BrokerState = availableBrokersAndEstates.brokers.length === 1 ? availableBrokersAndEstates.brokers[0].value : null;
  /*const maxProlaboreValue = (selectedBroker.minimumGain > selectedBroker.fee ? 0.7 - selectedBroker.minimumGain : 0.7 - selectedBroker.fee) * 100;*/

  useEffect(() => {
    if (selectedBroker) {
      setFieldValue('broker', selectedBroker);
    }
  }, [])


  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <EstateContainer>
      {isLoading &&
        <>
          <Loader />
        </>
      }
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid>
              <EstateData {...props} handleInputChange={handleFormInputChange} />
              <FireInsuranceData {...props} handleInputChange={handleFormInputChange} />
              <CapitalizationInsuranceData {...props} handleInputChange={handleFormInputChange} />
              <BondInsuranceData {...props} handleInputChange={handleFormInputChange} />
              <BankAccountData {...props} handleInputChange={handleFormInputChange} />
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign='left' width={16}>
            <Button text="Cadastrar"
              color='green'
              size='large'
              type='button'
              onClick={(e) => {
                props.onSubmit(props.values)
              }} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </EstateContainer>
  );
}

export default EstateFormContent;
