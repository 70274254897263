import { DropdownItemProps } from 'semantic-ui-react';
import { AnalysisAssuranceEnum } from '../../enums/analysis-assurance.enum';
import { CalculationModeEnum } from '../../enums/calculation-mode.enum';
import { CalculationTypeEnum } from '../../enums/calculation-type.enum';
import { EstatePartner } from '../../types/EstatePartner';
import { ResponsibleCompanyEnum } from '../../enums/responsible-company.enum';

export const batchClosingDayOptions: Array<DropdownItemProps> = [
  { value: 1, key: 1, text: 'Dia 01' },
  { value: 2, key: 2, text: 'Dia 02' },
  { value: 3, key: 3, text: 'Dia 03' },
  { value: 4, key: 4, text: 'Dia 04' },
  { value: 5, key: 5, text: 'Dia 05' },
  { value: 6, key: 6, text: 'Dia 06' },
  { value: 7, key: 7, text: 'Dia 07' },
  { value: 8, key: 8, text: 'Dia 08' },
  { value: 9, key: 9, text: 'Dia 09' },
  { value: 10, key: 10, text: 'Dia 10' },
  { value: 11, key: 11, text: 'Dia 11' },
  { value: 12, key: 12, text: 'Dia 12' },
  { value: 13, key: 13, text: 'Dia 13' },
  { value: 14, key: 14, text: 'Dia 14' },
  { value: 15, key: 15, text: 'Dia 15' },
  { value: 16, key: 16, text: 'Dia 16' },
  { value: 17, key: 17, text: 'Dia 17' },
  { value: 18, key: 18, text: 'Dia 18' },
  { value: 19, key: 19, text: 'Dia 19' },
  { value: 20, key: 20, text: 'Dia 20' }
];

export const estateFormInitialValues: UpdateEstateFormValues = {
  name: '',
  email: '',
  cnpj: '',
  creci: '',
  franchiseId: '',
  company_name: '',
  estateZipcode: '',
  estateStreet: '',
  estateNumber: '',
  estateComplement: '',
  estateDistrict: '',
  estateCity: '',
  estateState: '',
  financialResponsible: '',
  financialEmail: '',
  status: 'ACTIVE',
  partners: [],

  additionalEmails: '',
  portoCode: '',
  garanttiCode: '',

  contactSource: '',

  broker: {
    id: '',
    name: ''
  },
  estateAnalystUser: {
    id: '',
    name: ''
  },
  additionalAccountManager: {
    id: '',
    name: ''
  },
  bankAccount: {
    id: '',
    accountDv: '',
    accountNumber: '',
    agencyDv: '',
    agencyNumber: '',
    bankCode: ''
  },
  calculationMode: CalculationModeEnum.rent,
  calculationType: CalculationTypeEnum.rentOnly,
  responsible_company: ResponsibleCompanyEnum.confiax,
  bondInsurers: [],
  fireInsurers: [],
  capitalizations: [],
  isLibertyFireInsuranceSelected: false,
  isTooSegurosBondInsuranceSelected: false,
  isTokioMarineBondInsuranceSelected: false,
  isGaranttiBondInsuranceSelected: false,
  isPottencialBondInsuranceSelected: false,
  isPorto12xBondInsuranceSelected: false,
  isPorto18xBondInsuranceSelected: false,
  isPorto20xBondInsuranceSelected: false,
  isPorto30xBondInsuranceSelected: false,
  isPortoEssencialBondInsuranceSelected: false,
  isPottencialFireInsuranceSelected: false,
  isPortoTradicionalBondInsuranceSelected: false,
  isPortoTradicionalCapitalizationSelected: false,
  insurerSpecificData: {
    liberty: {
      fireInsurance: {
        tableId: '',
        offerLetter: ''
      }
    },
    tooSeguros: {
      bondInsurance: {
        pfResidentialTax: '',
        pfComercialTax: '',
        lmi: 0,
        tableId: '',
        commissionPercentage: '',
        orderPreference: 0,
        assurance: undefined
      }
    },
    tokioMarine: {
      bondInsurance: {
        pfResidentialTax: '',
        pfComercialTax: '',
        lmi: 0,
        tableId: '',
        commissionPercentage: '',
        orderPreference: 0,
        assurance: undefined
      }
    },
    garantti: {
      bondInsurance: {
        tableId: '',
        pfResidentialTax: '',
        pfComercialTax: '',
        lmi: 0,
        garanttiPlan: '',
        garanttiBonus: false,
        commissionPercentage: '',
        orderPreference: 0
      }
    },
    pottencial: {
      bondInsurance: {
        pfResidentialTax: '',
        pfComercialTax: '',
        lmi: 0,
        tableId: '',
        multiple: 0,
        assurance: undefined,
        commissionPercentage: '',
        orderPreference: 0
      },
      fireInsurance: {
        tableId: '',
        commissionPercentage: 40,
        multiplier: 200
      }
    },
    portoEssencial: {
      bondInsurance: {
        pfResidentialTax: '',
        pfComercialTax: '',
        lmi: 0,
        tableId: '',
        commissionPercentage: '',
        orderPreference: 0,
        assurance: undefined
      }
    },
    portoTradicional: {
      bondInsurance: {
        pfResidentialTax: '',
        pfComercialTax: '',
        lmi: 0,
        tableId: '',
        commissionPercentage: '',
        orderPreference: 0,
        assurance: undefined
      },
    }
  }
};

export interface UpdateEstateFormValues {
  name: string;
  observations?: string;
  email: string;
  emailsToInsert?: any;
  emailsToRemove?: any;
  cnpj: string;
  creci?: string;
  franchiseId?: string;
  company_name: string;
  broker: BrokerForUpdateEstateForm;
  isPottencialFireInsuranceSelected: boolean;
  isLibertyFireInsuranceSelected: boolean;
  isPortoTradicionalCapitalizationSelected: boolean;
  isTooSegurosBondInsuranceSelected: boolean;
  isTokioMarineBondInsuranceSelected: boolean;
  isGaranttiBondInsuranceSelected: boolean;
  isPottencialBondInsuranceSelected: boolean;
  isPorto12xBondInsuranceSelected: boolean;
  isPorto18xBondInsuranceSelected: boolean;
  isPorto20xBondInsuranceSelected: boolean;
  isPorto30xBondInsuranceSelected: boolean;
  isPortoTradicionalBondInsuranceSelected: boolean;
  isPortoEssencialBondInsuranceSelected: boolean;
  insurerSpecificData: EstateInsurerSpecificDataForUpdateEstateForm;
  fireInsurers: InsurerForUpdateEstateForm[];
  bondInsurers: InsurerForUpdateEstateForm[];
  capitalizations: InsurerForUpdateEstateForm[];
  estateAnalystUser: UserForUpdateEstateForm;
  calculationMode: CalculationModeEnum;
  calculationType: CalculationTypeEnum;
  bankAccount: BankAccountForUpdateEstateForm;
  partners?: EstatePartner[];


  additionalAccountManager: AdditionalAccountManagerForUpdateEstateForm;
  claimResponsible?: UserForUpdateEstateForm;

  estateZipcode?: string;
  estateStreet?: string;
  estateNumber?: string;
  estateComplement?: string;
  estateDistrict?: string;
  estateCity?: string;
  estateState?: string;

  contactSource?: string;

  additionalEmails?: string;
  notificationEmails?: any;
  portoCode?: string;
  garanttiCode?: string;

  classification?: string;
  monthlyTenantryCapacity?: number;
  averagePropertiesWallet?: number;

  responsibleName?: string;
  responsibleEmail?: string;
  responsiblePhone?: string;

  financialResponsible?: string;
  financialEmail?: string;
  status?: string;

  hasPreferenceBondInsurance?: boolean;

  responsible_company?: ResponsibleCompanyEnum;
}

export type EstateInsurerSpecificDataForUpdateEstateForm = {
  liberty: EstateLibertyInsurerSpecificDataForUpdateEstateForm;
  tooSeguros: EstateTooSegurosInsurerSpecificDataForUpdateEstateForm;
  tokioMarine: EstateTokioMarineInsurerSpecificDataForUpdateEstateForm;
  garantti: EstateGarantiInsurerSpecificDataForUpdateEstateForm;
  pottencial: EstatePottencialInsurerSpecificDataForUpdateEstateForm;
  portoEssencial: EstatePortoEssencialInsurerSpecificDataForUpdateEstateForm;
  portoTradicional: EstatePortoTradicionalInsurerSpecificDataForUpdateEstateForm;
  alfa?: any;
};

export type EstateTokioMarineInsurerSpecificDataForUpdateEstateForm = {
  bondInsurance: EstateTokioMarineInsurerBondInsuranceSpecificDataForUpdateEstateForm;
};

export type EstateTokioMarineInsurerBondInsuranceSpecificDataForUpdateEstateForm = {
  tableId: string;
  orderPreference: number;
  pfResidentialTax: string;
  pfComercialTax: string;
  lmi: number;
  commissionPercentage: string;
  assurance: AnalysisAssuranceEnum | undefined;
};

export type EstateTooSegurosInsurerSpecificDataForUpdateEstateForm = {
  bondInsurance: EstateTooSegurosInsurerBondInsuranceSpecificDataForUpdateEstateForm;
};

export type EstateTooSegurosInsurerBondInsuranceSpecificDataForUpdateEstateForm = {
  tableId: string;
  orderPreference: number;
  pfResidentialTax: string;
  pfComercialTax: string;
  lmi: number;
  commissionPercentage: string;
  assurance: AnalysisAssuranceEnum | undefined;
};

export type EstateLibertyInsurerSpecificDataForUpdateEstateForm = {
  fireInsurance: EstateLibertyInsurerFireInsuranceSpecificDataForUpdateEstateForm;
};

export type EstateLibertyInsurerFireInsuranceSpecificDataForUpdateEstateForm = {
  tableId: string;
  offerLetter: string;
};

export type EstateGarantiInsurerSpecificDataForUpdateEstateForm = {
  bondInsurance: EstateGarantiInsurerBondInsuranceSpecificDataForUpdateEstateForm;
};

export type EstateGarantiInsurerBondInsuranceSpecificDataForUpdateEstateForm = {
  tableId: string;
  pfResidentialTax: string;
  pfComercialTax: string;
  lmi: number;
  garanttiPlan: string;
  garanttiBonus: boolean;
  orderPreference: number;
  commissionPercentage: string;
};

export type EstatePortoEssencialInsurerSpecificDataForUpdateEstateForm = {
  bondInsurance: EstatePortoEssencialInsurerBondInsuranceSpecificDataForUpdateEstateForm;
};

export type EstatePortoTradicionalInsurerSpecificDataForUpdateEstateForm = {
  bondInsurance: EstatePortoEssencialInsurerBondInsuranceSpecificDataForUpdateEstateForm;
};

export type EstatePortoEssencialInsurerBondInsuranceSpecificDataForUpdateEstateForm = {
  tableId: string;
  orderPreference: number;
  pfResidentialTax: string;
  pfComercialTax: string;
  lmi: number;
  commissionPercentage: string;
  assurance: AnalysisAssuranceEnum | undefined;
};

export type EstatePottencialInsurerSpecificDataForUpdateEstateForm = {
  bondInsurance: EstatePottencialInsurerBondInsuranceSpecificDataForUpdateEstateForm;
  fireInsurance: EstatePottencialInsurerFireInsuranceSpecificDataForUpdateEstateForm;
};

export type EstatePottencialInsurerBondInsuranceSpecificDataForUpdateEstateForm = {
  tableId: string;
  multiple: number;
  assurance: AnalysisAssuranceEnum | undefined;
  orderPreference: number;
  pfResidentialTax: string;
  pfComercialTax: string;
  lmi: number;
  commissionPercentage: string;
};

export type EstatePottencialInsurerFireInsuranceSpecificDataForUpdateEstateForm = {
  tableId: string;
  multiplier: number;
  commissionPercentage: number;
};

export type EstatePortoInsurerCapitalizationSpecificDataForUpdateEstateForm = {
  code: string;
};

export type BrokerForUpdateEstateForm = {
  id: string;
  name: string;
};

export type InsurerForUpdateEstateForm = {
  id: string;
  code: string;
  name: string;
  imageURL: string;
};

export type UserForUpdateEstateForm = {
  id: string;
  name: string;
};

export type AdditionalAccountManagerForUpdateEstateForm = {
  id: string;
  name: string;
};

export type BankAccountForUpdateEstateForm = {
  id: string;
  bankCode: string;
  agencyNumber: string;
  agencyDv: string;
  accountNumber: string;
  accountDv: string;
};

export type TariffPlanForUpdateEstateForm = {
  id: string;
  name: string;
};
