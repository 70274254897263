import axiosRequest from "./axios-instance";

export type postFileProps = {
  tableName: string;
  registerId: string;
  name: string;
}

export type uploadFileResponseProps = {
  id: string;
  bucketFileKey: string;
  bucketName: string;
  ext: string;
  name: string;
  referencedTableId: string;
  referencedTableName: string;
}

export type createPreSignFilesUploadRequest = {
  filename: string;
  contentType: string;
  name: string;
  referencedTableId: string;
  referencedTableName: string;
}

export type createPreSignFilesUploadResponse = {
  uploadURL: string;
  id: string;
}


export const postFileAndGetPreSignAxios = async (data: createPreSignFilesUploadRequest) =>
  axiosRequest.post<createPreSignFilesUploadResponse>(`/files/presign`,
  data);

export const postFileAxios = async (data: FormData, querys: postFileProps) =>
  axiosRequest.post<uploadFileResponseProps>(`/files?referenced_table_id=${querys.registerId}&referenced_table_name=${querys.tableName}&name=${querys.name}`,
  data, { headers: { 'Content-Type': 'multipart/form-data' }});

export const getFilesLinkAxios = async (bondInsuranceId: string, tableName?: string) =>
  axiosRequest.get(`/bond-insurances/files?bondInsuranceId=${bondInsuranceId}&tableName=${tableName ? tableName : null}`)

export const deleteFilesLinkAxios = async(fileId: string) =>
  axiosRequest.delete(`/bond-insurances/files?fileId=${fileId}`)
