import { Field, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Input,
  Dropdown as DropdownNativo,
  Checkbox,
  InputOnChangeData
} from 'semantic-ui-react';
import CustomDatePicker from '../../../../../../../../components/CustomDatePicker';
import AppMaskedInput from '../../../../../../../../components/MaskedInput';

import SectionLabel from '../../../../../../../../components/SectionLabel';
import { EstateForGetEstatesResponseDTO } from '../../../../../../../../dtos/estate/estate-for-get-estates-response.dto';
import { AddressService } from '../../../../../../../../services/address';
import { getDataByDocument } from '../../../../../../../../services/documents';
import {
  maskCpfOrCnpj,
  maskPhoneNumber,
  maskZipCode
} from '../../../../../../../../services/masks';
import { FieldLabel } from '../../../../../../../../styles';
import { PayloadPortoSubmitRequestInterface } from '../../ModalHireProposal/interfaces/SubmitEssencialHirePorto.interface';
import { formField } from '../../ModalHireProposal/Model/formFields';
import { PortoStreetTypeEnum, PortoStreetTypeOptions } from '../Helpers/portoStreetType';
import { DateInputOnChangeData } from 'semantic-ui-calendar-react';

interface SurtyLessorFormProps {
  estate: EstateForGetEstatesResponseDTO;
}

const SurtyLessorForm = ({
  values,
  setFieldValue,
  estate
}: FormikProps<PayloadPortoSubmitRequestInterface> & SurtyLessorFormProps) => {
  const [isLegalPerson, setIsLegalPerson] = useState(false);
  const [cepLoading, setCepLoading] = useState<boolean>(false);
  const [notNumber, setNotNumber] = useState<boolean>(false);
  const [loadDocument, setLoadDocument] = useState(false);
  const [disableNameField, setDisabledNameField] = useState<boolean>(true);

  async function getLessorByDocument(document: string) {
    try {
      setLoadDocument(true);
      const unformatedDoc = document.replace(/\D/g, '');
      const isCPF = unformatedDoc.length === 11;
      const data = await getDataByDocument(unformatedDoc);
      if(!data.name && !data.company) {
        setDisabledNameField(false);
      }
      if (isCPF) {
        setFieldValue(formField.lessor.name.name, data.name);
        setDisabledNameField(true);
        if (data.birth_date) {
          setFieldValue(formField.lessor.birthdate.name, new Date(data.birth_date));
        }
        if (data.email) {
          setFieldValue(formField.lessor.email.name, new Date(data.email));
        }
      } else {
        setDisabledNameField(false);
        setFieldValue(formField.lessor.name.name, data.company);
        setFieldValue(formField.lessor.cellphone.name, data.phone);
        setFieldValue(formField.lessor.email.name, data.email);
      }
    } catch (err) {
      console.log(err);
      setDisabledNameField(false);
    } finally {
      setLoadDocument(false);
    }
  }

  useEffect(() => {
    if (isLegalPerson) {
      setFieldValue(formField.lessor.birthdate.name, '');
      setFieldValue(formField.lessor.residentialPhone.name, '');
    } else {
      setFieldValue(formField.lessor.commercialPhone.name, '');
    }
  }, [isLegalPerson, setFieldValue]);

  useEffect(() => {
    if (estate.estate_zip_code) {
      setFieldValue(
        formField.lessor.address.zipcode.name,
        maskZipCode(estate.estate_zip_code)
      );
    }
    if (estate.estate_district) {
      setFieldValue(formField.lessor.address.district.name, estate.estate_district);
    }
    if (estate.estate_city) {
      setFieldValue(formField.lessor.address.city.name, estate.estate_city);
    }
    if (estate.estate_state) {
      setFieldValue(formField.lessor.address.state.name, estate.estate_state);
    }
    if (estate.estate_street) {
      setFieldValue(formField.lessor.address.street.name, estate.estate_street);
      setFieldValue(formField.lessor.address.streetType.name, PortoStreetTypeEnum.RUA);
    }
    if (estate.estate_number) {
      setFieldValue(formField.lessor.address.number.name, estate.estate_number);
    }
    if (estate.estate_complement) {
      setFieldValue(formField.lessor.address.complement.name, estate.estate_complement);
    }
  }, [estate, setFieldValue]);

  const onChangeCep = (zipCode: string): void => {
    const maskedValue = maskZipCode(zipCode);

    setFieldValue(formField.lessor.address.zipcode.name, maskedValue);
    if (maskedValue.length >= 9) {
      setCepLoading(true);

      AddressService.getAddressByZipCode(zipCode)
        .then(address => {
          setFieldValue(formField.lessor.address.city.name, address.city);
          setFieldValue(formField.lessor.address.district.name, address.district);
          setFieldValue(formField.lessor.address.state.name, address.state);
          setFieldValue(formField.lessor.address.street.name, address.street);
          setCepLoading(false);
        })
        .catch(e => {
          console.log('address error:', e);
        });
    }
  };

  return (
    <div style={{ padding: '0 0 0 10px' }}>
      <Grid columns="equal">
        <SectionLabel text="INFORMAÇÕES PESSOAIS DO LOCADOR" />
        <Grid.Row columns={4}>
          <Grid.Column>
            <FieldLabel>{formField.lessor.document.label}</FieldLabel>
            <Field
              name={formField.lessor.document.name}
              value={values.lessor.document}
              loading={loadDocument}
              onChange={(e: any) => {
                if (e.target.value.length <= 14) {
                  setIsLegalPerson(false);
                } else {
                  setIsLegalPerson(true);
                }
                setFieldValue(
                  formField.lessor.document.name,
                  maskCpfOrCnpj(e.target.value)
                );
              }}
              customInput={<Input />}
              component={Input}
              onBlur={(e: any) => {
                getLessorByDocument(e.target.value);
              }}
              fluid
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>{isLegalPerson ? 'Razão Social*' : 'Nome Completo*'}</FieldLabel>
            <Field
              name={formField.lessor.name.name}
              loading={loadDocument}
              customInput={<Input />}
              component={Input}
              value={values.lessor.name}
              disabled={disableNameField}
              onChange={(e: any) => {
                setFieldValue(formField.lessor.name.name, e.target.value);
              }}
              error={60 - values.lessor.name.length < 0}
              fluid
            />
            <span> {60 - values.lessor.name.length} caractéres restantes</span>
          </Grid.Column>

          {!isLegalPerson && (
            <>
              <Grid.Column>
                <FieldLabel>{formField.lessor.birthdate.label}</FieldLabel>
                <Field
                  name={formField.lessor.birthdate.name}
                  selected={values.lessor.birthDate}
                  component={CustomDatePicker}
                  loading={loadDocument}
                  fluid
                  onChange={(date: DateInputOnChangeData) => {
                    setFieldValue(formField.lessor.birthdate.name, date);
                  }}
                  customInput={
                    <AppMaskedInput
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        '/',
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/
                      ]}
                    />
                  }
                  dateFormat={'dd/MM/yyyy'}
                />
              </Grid.Column>
            </>
          )}
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <FieldLabel>{formField.lessor.email.label}</FieldLabel>
            <Field
              name={formField.lessor.email.name}
              loading={loadDocument}
              customInput={<Input />}
              component={Input}
              value={values.lessor.email}
              onChange={(e: InputOnChangeData) => {
                setFieldValue(formField.lessor.email.name, e.target.value);
              }}
              fluid
            />
          </Grid.Column>
          {!isLegalPerson && (
            <>
              <Grid.Column>
                <FieldLabel>{formField.lessor.cellphone.label}*</FieldLabel>
                <Field
                  name={formField.lessor.cellphone.name}
                  value={values.lessor.cellphone}
                  onChange={(e: InputOnChangeData) =>
                    setFieldValue(
                      formField.lessor.cellphone.name,
                      maskPhoneNumber(e.target.value)
                    )
                  }
                  customInput={<Input />}
                  component={Input}
                  fluid
                />
              </Grid.Column>

              <Grid.Column>
                <FieldLabel>{formField.lessor.residentialPhone.label}*</FieldLabel>
                <Field
                  name={formField.lessor.residentialPhone.name}
                  customInput={<Input />}
                  component={Input}
                  value={values.lessor.residentialPhone}
                  onChange={(e: InputOnChangeData) => {
                    setFieldValue(
                      formField.lessor.residentialPhone.name,
                      maskPhoneNumber(e.target.value)
                    );
                  }}
                  fluid
                />
              </Grid.Column>
            </>
          )}
          {isLegalPerson && (
            <Grid.Column>
              <FieldLabel>{formField.lessor.commercialPhone.label}</FieldLabel>
              <Field
                name={formField.lessor.commercialPhone.name}
                customInput={<Input />}
                component={Input}
                value={values.lessor.commercialPhone}
                onChange={(e: InputOnChangeData) => {
                  setFieldValue(
                    formField.lessor.commercialPhone.name,
                    maskPhoneNumber(e.target.value)
                  );
                }}
                fluid
              />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>

      <Grid columns="equal">
        <SectionLabel text="ENDEREÇO DO PRINCIPAL LOCADOR" />
        <Grid.Row>
          <Grid.Column>
            <FieldLabel>{formField.lessor.address.zipcode.label}</FieldLabel>
            <Field
              name={formField.lessor.address.zipcode.name}
              component={Input}
              loading={cepLoading}
              value={values.lessor.address.zipCode}
              onChange={(e: InputOnChangeData) => {
                onChangeCep(e.target.value);
              }}
              maxLength={9}
              fluid
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>{formField.lessor.address.streetType.label}</FieldLabel>
            <DropdownNativo
              style={{ width: '100%' }}
              name={formField.lessor.address.streetType.name}
              loading={cepLoading}
              search
              clearable
              placeholder={'Selecione'}
              options={PortoStreetTypeOptions()}
              onChange={(e: React.SyntheticEvent<HTMLElement>, { value }) =>
                setFieldValue(formField.lessor.address.streetType.name, value)
              }
              value={values.lessor.address.streetType}
              component={DropdownNativo}
              selection
            />
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>{formField.lessor.address.street.label}</FieldLabel>
            <Field
              name={formField.lessor.address.street.name}
              loading={cepLoading}
              component={Input}
              value={values.lessor.address.street}
              onChange={(e: InputOnChangeData) => {
                setFieldValue(formField.lessor.address.street.name, e.target.value);
              }}
              fluid
              error={values.lessor.address.street.length > 40}
            />
            <span> {40 - values.lessor.address.street.length} caractéres restantes</span>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <FieldLabel>{formField.lessor.address.district.label}</FieldLabel>
            <Field
              name={formField.lessor.address.district.name}
              component={Input}
              loading={cepLoading}
              value={values.lessor.address.district}
              onChange={(e: InputOnChangeData) => {
                setFieldValue(formField.lessor.address.district.name, e.target.value);
              }}
              fluid
              error={values.lessor.address.district.length > 40}
            />
            <span>{40 - values.lessor.address.district.length} caractéres restantes</span>
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>{formField.lessor.address.city.label}</FieldLabel>
            <Field
              name={formField.lessor.address.city.name}
              component={Input}
              loading={cepLoading}
              value={values.lessor.address.city}
              onChange={(e: InputOnChangeData) => {
                setFieldValue(formField.lessor.address.city.name, e.target.value);
              }}
              fluid
              error={values.lessor.address.city.length > 30}
            />
            <span>{30 - values.lessor.address.city.length} caractéres restantes</span>
          </Grid.Column>

          <Grid.Column>
            <FieldLabel>{formField.lessor.address.state.label}</FieldLabel>
            <Field
              name={formField.lessor.address.state.name}
              loading={cepLoading}
              component={Input}
              maxLength={2}
              value={values.lessor.address.state}
              onChange={(e: InputOnChangeData) => {
                setFieldValue(
                  formField.lessor.address.state.name,
                  e.target.value.toUpperCase()
                );
              }}
              fluid
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={2}>
            <FieldLabel>{formField.lessor.address.number.label}</FieldLabel>
            <Field
              name={formField.lessor.address.number.name}
              component={Input}
              value={values.lessor.address.number}
              onChange={(e: InputOnChangeData) => {
                setFieldValue(
                  formField.lessor.address.number.name,
                  e.target.value.replace(/[^a-zA-Z0-9]/g, '')
                );
              }}
              fluid
              disabled={notNumber}
            />
          </Grid.Column>

          <Grid.Column verticalAlign="middle" width={3}>
            <Checkbox
              label="Sem número"
              onChange={(e: React.FormEvent<HTMLInputElement>, data) => {
                if (data.checked) {
                  setNotNumber(true);
                  setFieldValue(formField.lessor.address.number.name, 'SN');
                } else {
                  setNotNumber(false);
                  setFieldValue(formField.lessor.address.number.name, '');
                }
              }}
            />
          </Grid.Column>

          <Grid.Column width={3}>
            <FieldLabel>{formField.lessor.address.complement.label}</FieldLabel>
            <Field
              name={formField.lessor.address.complement.name}
              component={Input}
              value={values.lessor.address.complement}
              onChange={(e: InputOnChangeData) => {
                setFieldValue(formField.lessor.address.complement.name, e.target.value);
              }}
              error={
                values.lessor.address.complement &&
                values.lessor.address.complement?.length > 20
              }
            />
            {values.lessor.address?.complement && (
              <span>
                {20 - values.lessor.address.complement?.length || 0} caractéres restantes
              </span>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
export default SurtyLessorForm;
