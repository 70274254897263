import React, {useEffect, useState} from 'react';
import { FireInsuranceBudgetFormProps, QuotePayload } from '../FireInsuranceBudgetForm';
import { Grid, Button, InputOnChangeData } from 'semantic-ui-react';
import {Code} from '../../../types/InsurerState';
import { CoverageData } from './budget/CoverageData';
import SegimobCoverageData from './budget/CoverageData';
import TenantData from './budget/TenantData';
import OwnerData from './budget/OwnerData';
import MainData from './budget/MainData';
import InsuranceData from './budget/InsuranceData';
import PropertyData from './budget/PropertyData';
import {connect} from "react-redux";
import {iRootDispatch, iRootState} from "../../../store";
import {InsuranceConnectorCoverageState, PropertyTypeState} from "../../../types/PropertyTypeState";
import ConnectorCoverageList from "./budget/ConnectorCoverageList";
import styled from "styled-components";
import {getFireInsuranceQuotationYupSchema} from "../validators/index";
import {InsuranceDataSectionSchema} from "../validators/insurance-data-section/insurance-data-section-schema";
import {numberToMoneyString} from "../../../services/masks";
import {ConnectorCoverageSchema} from "../validators/coverages-section/connector-coverages-section/connector-coverage-option-schema";
import {ConnectorCoverageSectionSchema} from "../validators/coverages-section/connector-coverages-section/connector-coverage-section-schema";
import {
  FireInsuranceBudgetSelectedConnectorCoverages,
  FireInsuranceQuotationFormSections,
  OwnerState,
  PropertyState,
  TenantState
} from "../../../types/FireInsurance";
import {reach} from 'yup';

type BudgetProps = FireInsuranceBudgetFormProps
  & { quotationLoading: boolean }
  & { quotePayload: QuotePayload }
  & { handleInputChange: (_: any, data: InputOnChangeData) => void }
  & { setIsLoading: React.Dispatch<React.SetStateAction<boolean>> }
  & { setQuotePayload: React.Dispatch<React.SetStateAction<QuotePayload>> }
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>;

const mapDispatchToProps = (dispatch: iRootDispatch) => ({
  updateFormValidationStatus: (payload: Partial<FireInsuranceQuotationFormSections>) => dispatch.fireInsuranceBudget.updateFormValidationStatus(payload)
});

const mapStateToProps = (state: iRootState) => ({
  rentInformation: state.fireInsuranceBudget.rent,
  selectedInsurerBasic: state.fireInsuranceBudget.selected.insurer,
  selectedBrokerId: state.fireInsuranceBudget.selected.broker,
  selectedPropertyTypeBasic: state.fireInsuranceBudget.selected.propertyType,
  selectedInsurer: state.insurers.insurers.find((insurer) => insurer.id === state.fireInsuranceBudget.selected.insurer.id),
  selectedEstateInsurersList: state.insurers.insurers,
  formData: {
    rent: state.fireInsuranceBudget.rent,
    selected: state.fireInsuranceBudget.selected,
    property: state.fireInsuranceBudget.property,
    tenant: state.fireInsuranceBudget.tenant,
    owner: state.fireInsuranceBudget.owner,
    coverage: state.fireInsuranceBudget.coverage
  },
  validation: state.fireInsuranceBudget.isFormValidSections
});

const getForm = (props: any & {connectorCoveragesList: InsuranceConnectorCoverageState[]; formIsValid: boolean}) => {
  const { quotationLoading, errors, selectedInsurerBasic, selectedPropertyTypeBasic } = props;
  const { budget } = props.values;

  let connectorCoveragesList: InsuranceConnectorCoverageState[] = [];

  if (selectedInsurerBasic.isConnectorEnabled) {
    const selectedPropertyType: PropertyTypeState | undefined =
      props.selectedInsurer?.propertyTypes?.find((propertyType: any) => propertyType.id === selectedPropertyTypeBasic.id)

    if (selectedPropertyType) {
      connectorCoveragesList = selectedPropertyType.coverages || [];
    }
  }

  return (
    <>
      <InsuranceData sectionKey='budget.insurance'
        sectionValues={budget.insurance} {...props} />
      <FormContainer isVisible={selectedInsurerBasic.id ? true : false}>
        {props.rentInformation.touched &&
        (selectedInsurerBasic.isConnectorEnabled ?
          <ConnectorCoverageList coverageList={connectorCoveragesList} />
          :
          budget.insurance.insurer.code === Code.TokioMarine ?
            (<SegimobCoverageData sectionKey='budget.coverage'
                                  sectionValues={budget.coverage}
                                  {...props}
                                  selectedBrokerId={props.selectedBrokerId}/>) :
            (<CoverageData sectionKey='budget.coverage'
                           sectionValues={budget.coverage}
                           {...props}
                           />))}
        <PropertyData sectionKey='budget.property'
                       />
        <TenantData sectionKey='budget.tenant'
                    sectionValues={budget.tenant}
                    {...props} />
        <OwnerData sectionKey='budget.owner'
                   sectionValues={budget.owner}
                   {...props} />
        <Button
          disabled={!props.formIsValid}
          fluid type="submit" color="green"
          loading={quotationLoading}>
          Simular Seguro </Button>
      </FormContainer>
    </>
  );
}

const Budget = (props: any) => {
  const { formData, updateFormValidationStatus, selectedPropertyTypeBasic } = props;
  const { budget } = props.values;
  const [yupValidationSchema, setYupValidationSchema] = useState<any>(null);
  const [connectorCoveragesList, setConnectorCoveragesList] = useState<InsuranceConnectorCoverageState[]>([]);
  const [formIsValid, setFormIsValid] = useState<boolean>(false);

  useEffect(() => {
    if (props.formData.selected.insurer.id) {
      const insurerCode = props.formData.selected.insurer.code;
      const isInsurerConnectorEnabled = props.formData.selected.insurer.isConnectorEnabled;

      const yupSchema = getFireInsuranceQuotationYupSchema(isInsurerConnectorEnabled, insurerCode);

      if (yupSchema !== null) {
        setYupValidationSchema(yupSchema);
      }

      if (formData.selected.insurer.isConnectorEnabled) {
        const selectedPropertyType: PropertyTypeState | undefined =
          props.selectedInsurer?.propertyTypes?.find((propertyType: any) => propertyType.id === selectedPropertyTypeBasic.id)

        if (selectedPropertyType) {
          const coveragesList = selectedPropertyType.coverages ? selectedPropertyType.coverages : [];
          setConnectorCoveragesList(coveragesList);
        }
      }
    }
  }, [props.formData.selected.insurer]);

  useEffect(() => {
    async function validate() {
      if (yupValidationSchema) {
         isValidToSimulate();
      }
    }

    validate();
  }, [
    formData.rent.value,
    formData.coverage,
    formData.owner,
    formData.property,
    formData.selected,
    formData.tenant,
    yupValidationSchema
  ]);

  useEffect(() => {
    setFormIsValid(isAbleToSimulateQuotation())
  }, [props.validation])

  const isValidToSimulate = async () => {
    const insuranceDataSection: InsuranceDataSectionSchema = {
      brokerId: formData.selected.broker.id,
      estateId: formData.selected.estate.id,
      insurerId: formData.selected.insurer.id,
      assistanceId: formData.selected.assistance.id,
      propertyTypeId: formData.selected.propertyType.id,
      occupationId: formData.selected.occupation.id,
      rentValue: numberToMoneyString(formData.rent.value)
    }

    const insuranceDataSectionYupSchema = reach(yupValidationSchema, 'insuranceDataSection');

    insuranceDataSectionYupSchema?.validate(insuranceDataSection)
      .then((result: any) => {
        updateFormValidationStatus({
          insuranceDataSection: true
        });
      }).catch((e: any) => {
      updateFormValidationStatus({
        insuranceDataSection: false
      });
    });

    const coverages: ConnectorCoverageSchema[] = formData.selected.connectorCoverages.map((coverage: FireInsuranceBudgetSelectedConnectorCoverages) => {
        const coverageInformation = connectorCoveragesList.find((coverageInfo: InsuranceConnectorCoverageState) => coverage.id === coverageInfo.id)
        const maximumInsuredAmount = coverageInformation ? coverageInformation.maximumInsuredAmount : 9999999;
        return {
          checked: true,
          insuredAmount: coverage.insuredAmount,
          maximumInsuredAmount
        }
    });

    const coveragesSection: ConnectorCoverageSectionSchema = {
      selectedCoveragesAmount: formData.selected.connectorCoverages.length,
      coverages
    };

    const coveragesSectionYupSchema = reach(yupValidationSchema, 'coveragesSection');


    coveragesSectionYupSchema?.validate(coveragesSection)
      .then((result: any) => {
        updateFormValidationStatus({
          coveragesSection: true
        });
      }).catch((e: any) => {
      updateFormValidationStatus({
        coveragesSection: false
      })
    });

    const propertyInputSection: PropertyState = {
      ...formData.property
    }

    const propertyInputSectionYupSchema = reach(yupValidationSchema, 'propertyInputSection');

    propertyInputSectionYupSchema?.validate(propertyInputSection)
      .then((result: any) => {
        updateFormValidationStatus({
          propertyInputSection: true
        });
      }).catch((e: any) => {
      updateFormValidationStatus({
        propertyInputSection: false
      })
    });

    const tenantInputSection: TenantState = {
      ...formData.tenant
    }

    const tenantInputSectionYupSchema = reach(yupValidationSchema, 'tenantInputSection');

    tenantInputSectionYupSchema?.validate(tenantInputSection)
      .then((result: any) => {
        updateFormValidationStatus({
          tenantInputSection: true
        });
      }).catch((e: any) => {
      updateFormValidationStatus({
        tenantInputSection: false
      })
    });

    const ownerInputSection: OwnerState = {
      ...formData.owner
    }

    const ownerInputSectionYupSchema = reach(yupValidationSchema, 'ownerInputSection');

    ownerInputSectionYupSchema?.validate(ownerInputSection)
      .then((result: any) => {
        updateFormValidationStatus({
          ownerInputSection: true
        });
      }).catch((e: any) => {
      updateFormValidationStatus({
        ownerInputSection: false
      })
    });

    (!insuranceDataSectionYupSchema) && updateFormValidationStatus({insuranceDataSection: true});
    (!coveragesSectionYupSchema) && updateFormValidationStatus({coveragesSection: true});
    (!propertyInputSectionYupSchema) && updateFormValidationStatus({propertyInputSection: true});
    (!tenantInputSectionYupSchema) && updateFormValidationStatus({tenantInputSection: true});
    (!ownerInputSectionYupSchema) && updateFormValidationStatus({ownerInputSection: true});

    return true;
  }

  const isAbleToSimulateQuotation = () => {
    let isAble = true;
    const validation = props.validation as any;

    for(const validationSectionKey in validation) {
      if (validation.hasOwnProperty(validationSectionKey)) {
        if (!validation[validationSectionKey]) {
          isAble = false;
          break;
        }
      }
    }

    return isAble;
  }

  return (
    <>
      <Grid.Column width={11}>
        <Grid>
          <MainData sectionKey='budget.insurance'
            sectionValues={budget.insurance}
            quotationLoading={props.quotationLoading}
            values={props.values}
            quotePayload={props.quotePayload}
            handleInputChange={props.handleInputChange}
            setIsLoading={props.setIsLoading}/>
          {budget.insurance.estate ? getForm({
              quotationLoading: props.quotationLoading,
              values: props.values,
              quotePayload: props.quotePayload,
              handleInputChange: props.handleInputChange,
              setIsLoading: props.setIsLoading,
              selectedInsurerBasic: props.selectedInsurerBasic,
              selectedPropertyTypeBasic: props.selectedPropertyTypeBasic,
              selectedInsurer: props.selectedInsurer,
              selectedBrokerId: props.selectedBrokerId,
              rentInformation: props.rentInformation,
              ...props,
              formIsValid}) :
            <>
              <Grid.Column width={5}></Grid.Column>
              <Grid.Column width={6}>
                <Grid.Row style={{ marginTop: '100px', display: 'block', color: 'rgba(0,0,0,.25)' }}>
                  <h2>Escolha uma Imobiliária</h2>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column width={4}></Grid.Column>
            </>}
        </Grid>
      </Grid.Column>
    </>
  );
}

const FormContainer = styled(Grid)`
  transition: opacity linear 0.5s;
  opacity: ${(props: FormContainerProps) => props.isVisible ? 1 : 0};
`;

type FormContainerProps = {
  isVisible: boolean;
};

export default connect(mapStateToProps, mapDispatchToProps)(Budget);
