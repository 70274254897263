import React, { useState } from 'react';
import { Button, Dimmer, Icon, Loader, Modal } from 'semantic-ui-react';
import { CreatePolicyModalProps, CreatePolicyProps } from './types';
import {
  Container,
  FormContainer,
  Form,
  Header,
  Line,
  FormTitle,
  Steper,
  StepItem,
  StepForm
} from './styles';
import { Formik } from 'formik';
import {
  successNotification,
  warningNotification
} from '../../../../services/notification';
import { createPolicy } from '../../../../services/bond-insurance-policy';
import RenderAnalysisForm from './steps/analysisForm';
import RenderProposalForm from './steps/proposalForm';
import RenderPropertyForm from './steps/propertyForm';
import { RenderPropertyOwnerForm } from './steps/propertyOwnerForm';
import { RenderTenantForm } from './steps/tenantForm';
import { RenderPolicyForm } from './steps/policyForm';
import { StepValidation } from './validations';

const CreatePolicyModal: React.FC<CreatePolicyModalProps> = ({
  estatesOptions,
  insurers
}: CreatePolicyModalProps) => {
  const [step, setStep] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  async function handleSubmit(values: CreatePolicyProps) {
    setLoading(true);
    const payload = {
      ...values,
      bondInsurance: {
        ...values?.bondInsurance,
        property: {
          ...values?.bondInsurance?.property,
          rent_insurance_contract_start_date:
            values?.bondInsurance.rentInsuranceContractStartDate,
          rent_insurance_contract_end_date:
            values?.bondInsurance.rentInsuranceContractEndDate,
          rental_period_start_date: values?.bondInsurance.rentalPeriodStartDate,
          rental_period_end_date: values?.bondInsurance.rentalPeriodEndDate,
          rent_value:
            +values?.bondInsurance?.property?.rent_value.replace(/\D/g, '') / 100,
          condominium_fee: values?.bondInsurance?.property?.condominium_fee
            ? +values?.bondInsurance?.property?.condominium_fee.replace(/\D/g, '') / 100
            : undefined,
          taxes_value: values?.bondInsurance?.property?.taxes_value
            ? +values?.bondInsurance?.property?.taxes_value.replace(/\D/g, '') / 100
            : undefined,
          energy_bill: values?.bondInsurance?.property?.energy_bill
            ? +values?.bondInsurance?.property?.energy_bill.replace(/\D/g, '') / 100
            : undefined,
          water_bill: values?.bondInsurance?.property?.water_bill
            ? +values?.bondInsurance?.property?.water_bill.replace(/\D/g, '') / 100
            : undefined,
          gas_bill: values?.bondInsurance?.property?.gas_bill
            ? +values?.bondInsurance?.property?.gas_bill.replace(/\D/g, '') / 100
            : undefined,
          contract_penalties_value: values?.bondInsurance?.property
            ?.contract_penalties_value
            ? +values?.bondInsurance?.property?.contract_penalties_value.replace(
                /\D/g,
                ''
              ) / 100
            : undefined,
          interior_painting_value: values?.bondInsurance?.property
            ?.interior_painting_value
            ? +values?.bondInsurance?.property?.interior_painting_value.replace(
                /\D/g,
                ''
              ) / 100
            : undefined,
          exterior_painting_value: values?.bondInsurance?.property
            ?.exterior_painting_value
            ? +values?.bondInsurance?.property?.exterior_painting_value.replace(
                /\D/g,
                ''
              ) / 100
            : undefined,
          furniture_damage_value: values?.bondInsurance?.property?.furniture_damage_value
            ? +values?.bondInsurance?.property?.furniture_damage_value.replace(
                /\D/g,
                ''
              ) / 100
            : undefined,
          property_damage_value: values?.bondInsurance?.property?.property_damage_value
            ? +values?.bondInsurance?.property?.property_damage_value.replace(/\D/g, '') /
              100
            : undefined
        }
      },
      analysis: {
        ...values?.analysis,
        value: +values?.analysis?.value.replace(/\D/g, '') / 100
      },
      hiredData: {
        ...values?.hiredData,
        totalPremiumAmount:
          +values?.hiredData?.totalPremiumAmount.replace(/\D/g, '') / 100,
        installmentsAmount: values?.hiredData?.installmentsAmount
          ? +values?.hiredData?.installmentsAmount.replace(/\D/g, '') / 100
          : undefined
      }
    };

    try {
      await createPolicy(payload);
      successNotification('Sucesso', 'Apólice criada com sucesso!');
      setStep(1);
      setOpen(false);
    } catch (err) {
      console.log('err', err);
      warningNotification(
        'Oops...',
        (err as { message?: string })?.message || 'Ocorreu um erro ao criar a apólice'
      );
    } finally {
      setLoading(false);
    }
  }

  function nextStep(values: CreatePolicyProps) {
    const hasError = StepValidation(step, values);
    if (hasError) {
      return;
    } else {
      if (step === 4) {
        handleSubmit(values);
        return;
      }
    }
    setStep(step + 1);
  }

  function previousStep() {
    if (step === 1) {
      return;
    }
    setStep(step - 1);
  }

  const renderActionButtons = (values: CreatePolicyProps): React.ReactNode => {
    return (
      <Modal.Actions>
        {step === 1 ? (
          <Button type="button" color="red" onClick={() => setOpen(false)}>
            Cancelar
          </Button>
        ) : (
          <Button
            type="button"
            color="grey"
            onClick={() => {
              previousStep();
            }}
          >
            Voltar
          </Button>
        )}
        {step === 4 ? (
          <Button
            type="submit"
            color="green"
            form="policy-form"
            onClick={() => {
              nextStep(values);
            }}
          >
            Salvar
          </Button>
        ) : (
          <Button
            type="button"
            color="blue"
            onClick={() => {
              nextStep(values);
            }}
          >
            Próximo
          </Button>
        )}
      </Modal.Actions>
    );
  };

  return (
    <Formik
      initialValues={{} as CreatePolicyProps}
      validationSchema={{}}
      onSubmit={handleSubmit}
    >
      {innerProps => {
        const { setFieldValue, values } = innerProps;

        return (
          <>
            <Modal
              trigger={
                <Button
                  type="button"
                  color="blue"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <Icon name={'plus'} />
                  Nova Apólice
                </Button>
              }
              closeIcon
              size="large"
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <Container>
                <Header>
                  <h3>
                    <Icon name="shield" />
                    Nova Apólice
                  </h3>
                  <Line />
                  <Steper>
                    <StepItem active={step === 1} onClick={() => setStep(1)}>
                      Dados da locação
                    </StepItem>
                    <StepItem active={step === 2} onClick={() => setStep(2)}>
                      Dados do imóvel
                    </StepItem>
                    <StepItem active={step === 3} onClick={() => setStep(3)}>
                      Dados do locatário
                    </StepItem>
                    <StepItem active={step === 4} onClick={() => setStep(4)}>
                      Dados do da contratação
                    </StepItem>
                  </Steper>
                </Header>

                <Modal.Content scrolling style={{ padding: 0 }}>
                  {loading ? (
                    <Dimmer active={loading}>
                      <Loader indeterminate> Enviando... </Loader>
                    </Dimmer>
                  ) : (
                    <Container>
                      <Form id="policy-form">
                        {step === 1 && (
                          <StepForm>
                            <FormContainer>
                              <FormTitle>ANÁLISE</FormTitle>
                              <Line />
                              {estatesOptions && (
                                <RenderAnalysisForm
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  estatesOptions={estatesOptions}
                                />
                              )}
                            </FormContainer>
                            <FormContainer>
                              <FormTitle>PROPOSTA</FormTitle>
                              <Line />
                              <RenderProposalForm
                                values={values}
                                setFieldValue={setFieldValue}
                                insurers={insurers}
                              />
                            </FormContainer>
                          </StepForm>
                        )}

                        {step === 2 && (
                          <StepForm>
                            <FormContainer>
                              <FormTitle>IMÓVEL</FormTitle>
                              <Line />
                              <RenderPropertyForm
                                values={values}
                                setFieldValue={setFieldValue}
                              />
                            </FormContainer>

                            <FormContainer>
                              <FormTitle>PROPRIETÁRIO</FormTitle>
                              <Line />

                              <RenderPropertyOwnerForm
                                values={values}
                                setFieldValue={setFieldValue}
                              />
                            </FormContainer>
                          </StepForm>
                        )}

                        {step === 3 && (
                          <StepForm>
                            <FormContainer>
                              <FormTitle>DADOS DO LOCATÁRIO</FormTitle>
                              <Line />
                              <RenderTenantForm
                                values={values}
                                setFieldValue={setFieldValue}
                              />
                            </FormContainer>
                          </StepForm>
                        )}

                        {step === 4 && (
                          <StepForm>
                            <FormContainer>
                              <FormTitle>DADOS DA APÓLICE</FormTitle>
                              <Line />
                              <RenderPolicyForm
                                values={values}
                                setFieldValue={setFieldValue}
                              />
                            </FormContainer>
                          </StepForm>
                        )}
                      </Form>
                    </Container>
                  )}
                </Modal.Content>
              </Container>
              {renderActionButtons(values)}
            </Modal>
          </>
        );
      }}
    </Formik>
  );
};

export default CreatePolicyModal;
