import React from 'react';
import { Button, Modal, ModalActions, ModalContent, ModalHeader } from 'semantic-ui-react';

interface ReportModalProps {
  isOpen: boolean;
  header: string;
  message: string;
  onClose: () => void;
};

export const ReportModal = ({
  isOpen,
  header,
  message,
  onClose
}: ReportModalProps) => {
  return (
    <Modal size='tiny' open={isOpen} onClose={onClose}>
      <ModalHeader>{header}</ModalHeader>
      <ModalContent>
        <p>{message}</p>
      </ModalContent>
      <ModalActions>
        <Button positive onClick={onClose}>
          Fechar
        </Button>
      </ModalActions>
    </Modal>
  );
};
