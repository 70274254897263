import React, { useEffect, useState } from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import { ViewModalProps } from './types';
import {
  Container,
  FormContainer,
  Form,
  Header,
  Line,
  FormTitle,
  FormContent,
  FormRow,
  InfoDisclaimer
} from './styles';
import { maskCpfOrCnpj, maskPhoneNumber } from '../../../../services/masks';
import { dangerNotification } from '../../../../services/notification';
import { BankOptions, getEstateBankByCode } from '../../../../resources/bankCodeList';
import { statusOptions } from '../../types';
import { DateToStringDate } from '../../../../services/date';

const ViewModal: React.FC<ViewModalProps> = ({
  isOpen,
  onClose,
  franchise
}: ViewModalProps) => {
  const [bankSelected, setBankSelected] = useState<BankOptions | null>(null);

  useEffect(() => {
    async function fetchBanks() {
      try {
        const bank = await getEstateBankByCode(franchise?.bankAccount?.bankCode || '');
        setBankSelected(bank);
      } catch (err) {
        dangerNotification('Erro', 'Erro ao carregar lista de bancos');
      }
    }
    if (franchise.bankAccount?.bankCode) {
      fetchBanks();
    }
  }, [franchise.bankAccount?.bankCode]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal open={isOpen} onClose={onClose} closeIcon>
      <Container>
        <Header>
          <h3>{franchise?.name}</h3>
        </Header>
        <Line />
        <Form>
          <FormContainer>
            <FormTitle>DADOS CADASTRAIS</FormTitle>
            <Line />
            <FormContent>
              <FormRow>
                <label>
                  <b>NOME FANTASIA </b>
                  <span>{franchise?.name}</span>
                </label>
                <label>
                  <b>RAZÃO SOCIAL</b>
                  <span>{franchise?.company_name}</span>
                </label>
                <label>
                  <b>CNPJ</b>
                  <span>{maskCpfOrCnpj(franchise?.document)}</span>
                </label>
              </FormRow>
              <FormRow>
                <label>
                  <b>NOME DO RESPONSÁVEL</b>
                  <span>{franchise?.responsible_name}</span>
                </label>
                <label>
                  <b>E-MAIL DO RESPONSÁVEL</b>
                  <span>{franchise?.responsible_email}</span>
                </label>
                <label>
                  <b>TELEFONE DA UNIDADE</b>
                  <span>{maskPhoneNumber(franchise?.phone)}</span>
                </label>
              </FormRow>
              <FormRow>
                <label>
                  <b>E-MAIL DA UNIDADE</b>
                  <span>{franchise?.email}</span>
                </label>
                <label>
                  <b>GESTOR DE CONTAS</b>
                  <span>{franchise?.accountManager?.name}</span>
                </label>
                <label>
                  <b>STATUS</b>
                  <span>
                    {
                      statusOptions.find(status => status.value === franchise?.status)
                        ?.text
                    }
                  </span>
                </label>
              </FormRow>
            </FormContent>
          </FormContainer>
          <FormContainer>
            <FormTitle>ENDEREÇO</FormTitle>
            <Line />
            <FormContent>
              <FormRow>
                <label>
                  <b>CEP</b>
                  <span>{maskCpfOrCnpj(franchise?.zipcode)}</span>
                </label>
                <label>
                  <b>LOGRADOURO</b>
                  <span>{franchise?.street}</span>
                </label>
                <label>
                  <b>BAIRRO</b>
                  <span>{franchise?.district}</span>
                </label>
              </FormRow>
              <FormRow>
                <label>
                  <b>CIDADE</b>
                  <span>{franchise?.city}</span>
                </label>
                <label>
                  <b>UF</b>
                  <span>{franchise?.state}</span>
                </label>
                <label>
                  <b>NÚMERO</b>
                  <span>{franchise?.number}</span>
                </label>
              </FormRow>
              <FormRow>
                <label>
                  <b>COMPLEMENTO</b>
                  <span>{franchise?.complement}</span>
                </label>
              </FormRow>
            </FormContent>
          </FormContainer>
          <FormContainer>
            <FormTitle>DADOS BANCÁRIOS</FormTitle>
            <Line />
            <FormContent>
              <FormRow>
                <label>
                  <b>BANCO</b>
                  <span>{bankSelected?.text}</span>
                </label>
                <label>
                  <b>AGÊNCIA</b>
                  <span>
                    {franchise?.bankAccount?.agencyNumber}-
                    {franchise?.bankAccount?.agencyDv}
                  </span>
                </label>
                <label>
                  <b>CONTA</b>
                  <span>
                    {franchise?.bankAccount?.accountNumber}-
                    {franchise?.bankAccount?.accountDv}
                  </span>
                </label>
              </FormRow>
              <InfoDisclaimer>
                <div className="disclaimer-content">
                  <Icon className="icon" name="exclamation circle" />
                  <p>
                    Confira as informações acima e caso encontre divergências, entre em
                    contato com o seu gestor de contas ou atendimento para que seja feita a
                    devida correção.
                  </p>
                </div>
                <div className="disclaimer-info">
                  <p>
                    Criado em:{' '}
                    {franchise?.created_at ? DateToStringDate(franchise?.created_at) : ''}
                  </p>
                </div>
              </InfoDisclaimer>
            </FormContent>
          </FormContainer>
        </Form>
      </Container>
    </Modal>
  );
};

export default ViewModal;
