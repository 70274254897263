import React, { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { StepProps } from '../../../BondInsuranceForm';
import { PersonalInformationsSection } from './components/PersonalInformationsSection';
import { MaritalPartnerSection } from './components/MaritalPartnerSection';
import { ProfissionalInformationSection } from './components/ProfissionalInformationSection';
import { ResidentInformationSection } from './components/ResidentInformationSection';
import { PurposesEnum } from '../../../../../enums/purposes.enum';
import { PersonTypesEnum } from '../../../../../enums/person-types.enum';
import { SympathiseTenantsSection } from './components/SympathiseTenantsSection';
import { QuizSection } from './components/QuizSection';
import {iRootDispatch, iRootState} from "../../../../../store";
import {BondInsuranceFormState} from "../../../../../store/types/temp-types";
import {connect} from "react-redux";

const mapState = (state: iRootState) => ({
  bondInsuranceForm: state.bondInsuranceForm,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateBondInsurance: (bondInsuranceFormState: BondInsuranceFormState) =>
    dispatch.bondInsuranceForm.updateBondInsurance(bondInsuranceFormState)
});

type NaturalCommercialResidentialFormProps = StepProps
  & ReturnType<typeof mapState>
  & ReturnType<typeof mapDispatch>;

const NaturalCommercialResidentialForm = (props: NaturalCommercialResidentialFormProps) => {

  const { values } = props;
  const { tenant_info, initial_info } = values;

  useEffect(() => {
    if (props.bondInsuranceForm) {
      props.updateBondInsurance({ ...props.bondInsuranceForm })
    }
  }, [])

  return (
    <Grid>
      <PersonalInformationsSection {...props} />
      {tenant_info.partner_rent_composition && <MaritalPartnerSection {...props} />}
      <ProfissionalInformationSection {...props} />
      {initial_info.purpose === PurposesEnum.residential && initial_info.tenant_type === PersonTypesEnum.natural &&
        <>
          {/* <ResidentInformationSection {...props} /> */}
          <SympathiseTenantsSection {...props} />
        </>
      }
      {initial_info.purpose === PurposesEnum.commercial && initial_info.tenant_type === PersonTypesEnum.natural &&
        <QuizSection {...props} />}
    </Grid>
  );
}

export default connect(mapState, mapDispatch)(NaturalCommercialResidentialForm);
