import React, { SyntheticEvent } from 'react';
import Input from '../../components/Input';
import Button from '../../components/Button';
import IconButton from '../../components/IconButton';
import background from '../../resources/imgs/4seg-login-image.png';
import logo from '../../resources/imgs/logo.png';
import { Grid, Form } from 'semantic-ui-react';
import Loader from '../../components/Loader';
import { Pushable, SideBarGrid, Logo, FormContainer, WelcomeText, InstructionText, Pusher, ImageBackgroundCover, ImageBackgroundText, ImageBackground } from './styles';
import { FormikErrorMessage } from '../../components/ErrorMessage';
import { FieldLabel } from '../../styles';
import { RecoveryPasswordProps } from './RecoveryPassword';
import { FormikProps } from 'formik';
import { FormValues } from './types';

const RecoveryPasswordForm = (props: RecoveryPasswordProps & FormikProps<FormValues>) => {
  const {
    values,
    handleSubmit,
    status,
    handleChange,
    isSubmitting,
  } = props;

  if (isSubmitting) {
    return (
      <>
        <Loader />
      </>
    );
  }
  return (
    <>
      <Pushable>
        <SideBarGrid>
          <Logo src={logo} />
          <FormContainer>
            <IconButton name='arrow left' color='blue' onClick={(e: SyntheticEvent) => { props.history.push('/'); }} />
            <div>
              <WelcomeText>Recuperação <br /> de senha</WelcomeText>
              <InstructionText>Informe seu e-mail pare receber as instruções</InstructionText>
              <Form
                initialValues={values}
                onSubmit={handleSubmit}>
                <FieldLabel>E-MAIL:</FieldLabel>
                <Input
                  name='email'
                  type='email'
                  icon={{ name: 'mail', color: 'blue' }}
                  iconPosition='left'
                  fluid
                  onChange={handleChange}
                  value={values.email} />
                <FormikErrorMessage component="div" name="email" />
                <p />
                <Grid columns={2}>
                  <Grid.Row>
                    <Grid.Column textAlign='center' width={16}>
                      <Button text="Solicitar"
                        color='green'
                        size='large'
                        type='submit' />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </div>
          </FormContainer>
        </SideBarGrid>

        <Pusher>
          <ImageBackgroundCover />

          <ImageBackgroundText>
            <div><b>Proteger você,</b></div>
            <div><span>é o que importa</span></div>
          </ImageBackgroundText>

          <ImageBackground src={background} resizeMode='contain' />
        </Pusher>
      </Pushable>
    </>
  );
};

export default RecoveryPasswordForm;
