import { post, get, put } from './request';
import { MessageType } from '../components/FollowUp';

export enum ConfigKeysEnum {
  LINK_POWER_BI = 'LINK_POWER_BI',
  OPERATIONAL_BI_LINK = 'OPERATIONAL_BI_LINK',
  ACCESS_OPERATIONAL_EMAILS = 'ACCESS_OPERATIONAL_EMAILS',
  POTTENCIAL_REGISTER_BIOMETRICS_URL = 'POTTENCIAL_REGISTER_BIOMETRICS_URL'
}

export type ConfigKeyResponse = {
  success: boolean;
  key: ConfigKeysEnum;
  value: string;
  context: string;
}
export async function getConfigKey(key: ConfigKeysEnum): Promise<ConfigKeyResponse> {
  try {
    const response = await get(`/config-keys/${key}`);
    return response;
  }catch(err) {
    return {
      success: false,
      key,
      value: '',
      context: ''
    };
  }
}
