import React, { useEffect, useState } from 'react';
import { Button, Icon, Modal, Popup } from 'semantic-ui-react';
import DetailPottencial from './Pottencial';
import { InsurersCodeEnum } from '../../../../enums/insurers-code.enum';
import ModalAttachFiles from '../../../../components/ModalAttachFiles';
import { fileIdentifiers } from '../../utils/Pottencial';
import DetailSegimob from './Segimob';
import DetailPortoSeguro from './PortoSeguro';
import DetailAlfa from './Alfa';
import { PreviousHiredButton } from '../../../bond-insurance-policy-list/styles';
import { useHistory } from 'react-router-dom';
import { get } from '../../../../services/request';
import * as storage from '../../../../services/storage';
import GenericLogModal from '../../../../components/GenericLogModal/LogModal';
import { UserRolesEnum } from '../../../../enums/user-roles.enum';
interface DefailtModalInterface {
  open: boolean;
  onClose: () => void;
  fireInsurancePolicy: any;
}

const DetailModal = (props: DefailtModalInterface) => {
  const history = useHistory();
  const { fireInsurancePolicy, open, onClose } = props;
  const fireInsuranceAnalysis = fireInsurancePolicy?.fireInsuranceAnalysis;
  const role = storage.get('role');
  const [showJson, setShowJson] = useState<boolean>(false);
  const [newPolicyId, setNewPolicyId] = useState<any>(null);

  const renderView = (insurerCode: any) => {
    switch (insurerCode) {
      case InsurersCodeEnum.Pottencial:
        return <DetailPottencial fireInsurancePolicy={fireInsurancePolicy} />;
      case InsurersCodeEnum.TokioMarineSegimob:
        return <DetailSegimob fireInsurancePolicy={fireInsurancePolicy} showJson={showJson} />;
      case InsurersCodeEnum.Porto:
          return <DetailPortoSeguro fireInsurancePolicy={fireInsurancePolicy} showJson={showJson} />;
      case InsurersCodeEnum.alfa:
        return <DetailAlfa fireInsurancePolicy={fireInsurancePolicy} showJson={showJson} />;
      default:
        return <div>DEFAULT</div>;
    }
  };

  useEffect(() => {
    async function checkIfHasRenewal() {
      try{
        if(fireInsurancePolicy?.fireInsuranceAnalysis?.fireInsurance?.id) {
          const response = await get(`/fire-insurance-proposals/renewal/${fireInsurancePolicy.fireInsuranceAnalysis.fireInsuranceId}`);
          if(response.status === 200) {
            const fireInsuranceId = response?.fireInsuranceId;
            setNewPolicyId(fireInsuranceId);
          }
        }
      }catch(err) {
        console.log(err)
      }

    }
    checkIfHasRenewal();
  }, [fireInsurancePolicy, history]);

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>
        <div style={{display: 'flex', justifyContent: "space-between"}}>
        Detalhes do Seguro
      {role?.includes('BROKER') && fireInsuranceAnalysis?.insurer.code !== InsurersCodeEnum.alfa &&  (
          <Popup
            trigger={
              <Icon
                name={showJson ? 'eye' : 'eye slash'}
                onClick={() => setShowJson(!showJson)}
                style={{ marginLeft: 10, color: showJson ? '#333' : '#999' }}
              />
            }
            content={'Mostrar JSON'}
          />
        )}

        { role === UserRolesEnum.brokerAdmin && fireInsuranceAnalysis?.id &&
        <GenericLogModal
          relatedIds={[fireInsuranceAnalysis?.id, fireInsuranceAnalysis?.fireInsuranceId, fireInsurancePolicy?.id]}
          createdAt={fireInsuranceAnalysis.created_at}
          updatedAt={fireInsuranceAnalysis.updated_at}
          key={'FIRE_INSURANCE'}
        />
      }

        {
          //history.push(`/admin/fire-insurance-policies/${fireInsuranceId}`);
          fireInsuranceAnalysis?.fireInsurance?.renewalFireInsuranceId && (
            <PreviousHiredButton
            onClick={() => {
              history.push(`/admin/fire-insurance-policies/${fireInsuranceAnalysis?.fireInsurance?.renewalFireInsuranceId}`);
            }}
          >
            <Icon name='reply' color='blue'/>
            Ver apólice original
          </PreviousHiredButton>
          )
        }
        {
          //history.push(`/admin/fire-insurance-policies/${fireInsuranceId}`);
          newPolicyId && (
            <PreviousHiredButton
            onClick={() => {
              history.push(`/admin/fire-insurance-policies/${newPolicyId}`);
            }}
          >
            <Icon name='reply' color='blue'/>
            Ver apólice renovada
          </PreviousHiredButton>
          )
        }


          </div>
      </Modal.Header>
      <Modal.Content>
        {open && renderView(fireInsuranceAnalysis?.insurer.code)}
      </Modal.Content>
      <Modal.Actions>
        <Button icon labelPosition={'left'} onClick={onClose}>
          <Icon name={'arrow left'} />
          Voltar
        </Button>

        {fireInsuranceAnalysis?.fireInsuranceId && (
          <ModalAttachFiles
            bondInsuranceId={fireInsuranceAnalysis.fireInsuranceId}
            tableName={'fire_insurance'}
            fileIdentifiers={fileIdentifiers}
            brokerOnly={true}
            message={''}
            trigger={
              <Button icon primary labelPosition={'left'}>
                <Icon name={'download'} />
                Anexos
              </Button>
            }
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default DetailModal;
