import React from 'react';
import { Button, Grid, Header, Icon, Modal, Popup } from 'semantic-ui-react';
import InstrucaoSinistro from '../../../resources/docs/instrucao-sinistro/instrucao-sinistro';
import { FieldLabel } from './Table';

interface GeneralConditionProps {
  openModal: boolean;
  toggleOpen: () => void;
  toggleClose: () => void;
}
const GeneralCondition = (props: GeneralConditionProps) => {
  const { openModal, toggleClose, toggleOpen} = props
  return (
    <>
      <Modal
        open={openModal}
        onClose={toggleClose}
        onOpen={toggleOpen}
      >
        <Modal.Header>
          <Header>Condições Gerais</Header>
        </Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <p>
                  Conheça as condições das seguradoras parceiras, com as
                  respectivas cláusulas relacionadas às modalidades e coberturas
                  dos seguros/apólices, que estabelecem as obrigações e os direitos
                  das partes contratantes.
                </p>
                <ul>
                    <li>
                      Condição Geral - Garantti (
                      <a
                        href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Condicoes+Gerais+-+IMOBILIARIA+GARANTTI+-+Versao+1-1-1.pdf"
                        target="_blank"
                      >
                        Download
                      </a>)
                    </li>
                    <li>
                      Condição Geral - Pottencial (
                      <a
                        href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Condicoes+Gerais+-+Pottencial.pdf"
                        target="_blank"
                      >
                        Download
                      </a>)
                    </li>
                    <li>
                      Condição Geral - Porto Seguro Essencial (
                      <a
                        href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Condicoes+Gerais+-+Porto+Seguro+Essencial.pdf"
                        target="_blank"
                      >
                        Download
                      </a>)
                    </li>
                    <li>
                      Condição Geral - Porto Seguro Tradicional (
                      <a
                        href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Condicoes+Gerais+-+Porto+Seguro+Tradicional.pdf"
                        target="_blank"
                      >
                        Download
                      </a>)
                    </li>
                    <li>
                      Condição Geral - Tokio Marine (
                      <a
                        href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Condicoes+Gerais+-+Tokio.pdf"
                        target="_blank"
                      >
                        Download
                      </a>)
                    </li>
                    <li>
                      Condição Geral - Too Seguros (
                      <a
                        href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Sinistro/Condicoes+Gerais+-+Too+Seguros.pdf"
                        target="_blank"
                      >
                        Download
                      </a>)
                    </li>
                  </ul>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={toggleClose}
            color={'green'}
          >
            OK, Entendi
          </Button>
        </Modal.Actions>
      </Modal>

      <Popup
        trigger={
          <span  onClick={toggleOpen} style={{color: "#9d9d9d", fontWeight: 'bold', fontSize: '14px', cursor: 'pointer'} }>
            <Icon
              size={'small'}
              name={'file alternate outline'}
              color={'grey'}
              style={{ cursor: 'pointer', marginRight: '10px', fontSize: 14 }}
            />
            Condições Gerais
          </span>
        }
        content={'Clique aqui para ver as condições gerais das seguradoras'}
        position={'left center'}
      />
    </>
  )
}

export default GeneralCondition;
