import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import { iRootState, iRootDispatch } from '../../store';
import { getAllDashboards, getDashboard } from '../../services/dashboard';
import {
  Container,
  CardWrapper,
  WelcomeTitle,
  WelcomeSubtitle,
  InfoBanner,
  AppLabel,
  AppsContainer,
  AppCard,
  IconRight
} from './styles';
import Card from './components/Card';
import { HeaderWrapper, ButtonsWrapper, BannersWrapper } from './styles';

import RedeVistoriasIMG from '../../resources/imgs/redevistorias.svg';
import RedeIzeeIMG from '../../resources/imgs/redeizee.svg';

import { CurrentPageState } from '../../store/types/temp-types';
import { PushRoute } from '../../util';
import AuthorizationContainer from '../../components/AuthorizationContainer';
import { ActionEnum } from '../../enums/authz-action.enum';
import { FeatureEnum } from '../../enums/authz-feature.enum';
import { dangerNotification } from '../../services/notification';
import useWindowSize from '../../hooks/useWindowSize';
import CustomBanner from './components/CustomBanner';
import { useQuery } from '@tanstack/react-query';
import { getBanners } from '../../services/banner';
import { BannerStatusEnum } from '../../types/Banner';
import { UserRolesEnum } from '../../enums/user-roles.enum';
import { get } from '../../services/storage';

const mapState = (state: iRootState) => {
  return {
    user: state.user,
    availableBrokersAndEstates: state.availableBrokersAndEstates
  };
};

enum FilterEnum {
  bondInsurancesTotal = 'biTotal',
  bondInsurancesApproveds = 'biApproveds',
  bondInsurancePolicyCreated = 'biPolicyCreated',
  bondInsuranceHired = 'biHired',
  bondInsuranceWaiting = 'biWaiting',
  bondInsuranceToExpire = 'biToExpire',
  policyToExpire = 'policyToExpire',
  fireInsurancesTotal = 'fiTotal',
  fireInsurancesPendentPayment = 'fiPendentPayment',
  fireInsurancesToExpire = 'fiToExpire',
  fireInsurancesPolicyToExpire = 'fiPolicyToExpire',
  fireInsurancesPolicyWaiting = 'fiPolicyWaiting',
  fireInsurancesPolicyHired = 'fiPolicyHired',
  capitalizationsInProgress = 'capitalizationsInProgress',
  capitalizationsWaitingPayment = 'capitalizationsWaitingPayment',
  capitalizationsHired = 'capitalizationsHired'
}

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateCurrentPage: (state: CurrentPageState) =>
    dispatch.currentPage.updateCurrentPage(state)
});

const RealDashboard = (props: any) => {
  const { user } = props;
  const history = useHistory();

  const [biTotal, setBiTotal] = useState<number>();
  const [biApproveds, setBiApproveds] = useState<number>();
  const [biPolicyCreated, setBiPolicyCreated] = useState<number>();
  const [biHired, setBiHired] = useState<number>();
  const [biWaiting, setBiWaiting] = useState<number>();
  const [biToExpire, setBiToExpire] = useState<number>();
  const [capitalizationsInProgress, setCapitalizationsInProgress] = useState<number>();
  const [capitalizationsHired, setCapitalizationsHired] = useState<number>();
  const [capitalizationsWaitingPayment, setCapitalizationsWaitingPayment] =
    useState<number>();
  const [policyToExpire, setPolicyToExpire] = useState<number>(0);
  const [fiTotal, setFiTotal] = useState<number>(0);
  const [fiPendentPayment, setFiPendentPayment] = useState<number>(0);
  const [fiToExpire, setFiToExpire] = useState<number>(0);
  const [fiPolicyHired, setFiPolicyHired] = useState<number>(0);
  const [fiPolicyWaiting, setFiPolicyWaiting] = useState<number>(0);
  const [fiPolicyToExpire, setFiPolicyToExpire] = useState<number>(0);

  const { isMobile } = useWindowSize();

  const fetchData = async (filter: string) => {
    try {
      let response;
      if (props.user.role.includes('ESTATE')) {
        response = await getDashboard(props.user.estate.id, filter);
      } else {
        response = await getAllDashboards(filter);
      }
      switch (filter) {
        case FilterEnum.bondInsurancesTotal:
          setBiTotal(response);
          break;
        case FilterEnum.bondInsurancesApproveds:
          setBiApproveds(response);
          break;
        case FilterEnum.bondInsurancePolicyCreated:
          setBiPolicyCreated(response);
          break;
        case FilterEnum.bondInsuranceHired:
          setBiHired(response);
          break;
        case FilterEnum.bondInsuranceToExpire:
          setBiToExpire(response);
          break;
        case FilterEnum.bondInsuranceWaiting:
          setBiWaiting(response);
          break;
        case FilterEnum.policyToExpire:
          setPolicyToExpire(response);
          break;
        case FilterEnum.capitalizationsHired:
          setCapitalizationsHired(response);
          break;
        case FilterEnum.capitalizationsInProgress:
          setCapitalizationsInProgress(response);
          break;
        case FilterEnum.capitalizationsWaitingPayment:
          setCapitalizationsWaitingPayment(response);
          break;
        // case FilterEnum.fireInsurancesTotal:
        //   setFiTotal(response);
        //   break;
        // case FilterEnum.fireInsurancesPendentPayment:
        //   setFiPendentPayment(response);
        //   break;
        // case FilterEnum.fireInsurancesPolicyToExpire:
        //   setFiPolicyToExpire(response);
        //   break;
        // case FilterEnum.fireInsurancesPolicyHired:
        //   setFiPolicyHired(response);
        //   break;
        // case FilterEnum.fireInsurancesToExpire:
        //   setFiToExpire(response);
        //   break;
        // case FilterEnum.fireInsurancesPolicyWaiting:
        //   setFiPolicyWaiting(response);
        //   break;
        default:
          break;
      }
    } catch (err) {
      console.log('ERROW', err);
      dangerNotification(
        'Oops...',
        'Não foi possível buscar os dados do Dashboard, tente novamente mais tarde.'
      );
    }
  };

  useEffect(() => {
    fetchData(FilterEnum.bondInsurancesTotal);
  }, [biTotal]);

  useEffect(() => {
    fetchData(FilterEnum.bondInsurancesApproveds);
  }, [biApproveds]);

  useEffect(() => {
    fetchData(FilterEnum.bondInsurancePolicyCreated);
  }, [biPolicyCreated]);

  useEffect(() => {
    fetchData(FilterEnum.bondInsuranceHired);
  }, [biHired]);

  useEffect(() => {
    fetchData(FilterEnum.bondInsuranceWaiting);
  }, [biWaiting]);

  useEffect(() => {
    fetchData(FilterEnum.bondInsuranceToExpire);
  }, [biToExpire]);

  useEffect(() => {
    fetchData(FilterEnum.policyToExpire);
  }, [policyToExpire]);

  useEffect(() => {
    fetchData(FilterEnum.capitalizationsInProgress);
  }, [capitalizationsInProgress]);

  useEffect(() => {
    fetchData(FilterEnum.capitalizationsHired);
  }, [capitalizationsHired]);

  useEffect(() => {
    fetchData(FilterEnum.capitalizationsWaitingPayment);
  }, [capitalizationsWaitingPayment]);

  // useEffect(() => {
  //   fetchData(FilterEnum.fireInsurancesTotal);
  // }, [fiTotal]);

  // useEffect(() => {
  //   fetchData(FilterEnum.fireInsurancesPendentPayment);
  // }, [fiPendentPayment]);

  // useEffect(() => {
  //   fetchData(FilterEnum.fireInsurancesPolicyToExpire);
  // }, [fiPolicyToExpire]);

  // useEffect(() => {
  //   fetchData(FilterEnum.fireInsurancesPolicyHired);
  // }, [fiPolicyHired]);

  // useEffect(() => {
  //   fetchData(FilterEnum.fireInsurancesPolicyWaiting);
  // }, [fiPolicyWaiting]);

  // useEffect(() => {
  //   fetchData(FilterEnum.fireInsurancesToExpire);
  // }, [fiToExpire]);

  const userToken = get('id_token');
  const isDevelop =
    window.location.hostname === 'app.dev.4seg.com.br' ||
    window.location.hostname === 'localhost';

  const redeOptions = [
    {
      link: !isDevelop
        ? `https://painel.redevistorias.com.br/#access_token=${userToken}&token_type=Bearer&expires_in=86400`
        : `https://painel.dev.redevistorias.com.br/#access_token=${userToken}&token_type=Bearer&expires_in=86400`
    },
    {
      link: !isDevelop
        ? `https://app.izee.com.br/auth#access_token=${userToken}&token_type=Bearer&expires_in=86400`
        : `https://app.develop.izee.com.br/auth#access_token=${userToken}&token_type=Bearer&expires_in=86400`
    }
  ];

  const { data: banners, isFetching: isBannersLoading } = useQuery(
    ['getBanners', ''],
    () => getBanners(BannerStatusEnum.ACTIVE),
    {
      keepPreviousData: true
    }
  );

  return (
    <Container>
      {!isMobile && (
        <BannersWrapper>
          <InfoBanner>
            <WelcomeTitle>
              Bem vindo à sua <br />
              <b>Dashboard</b>
            </WelcomeTitle>
            <WelcomeSubtitle>
              Aqui você poderá encontrar informações gerenciais e navegar em nossos outros
              produtos e agregar ainda mais para seu negócio.
            </WelcomeSubtitle>
            <ButtonsWrapper>
              <Button
                primary
                size={'tiny'}
                onClick={() => PushRoute(history, props, 'bond-insurance')}
              >
                Nova Análise
              </Button>
              <Button
                basic
                color={'blue'}
                size={'tiny'}
                onClick={() => {
                  PushRoute(history, props, 'bond-insurances');
                }}
              >
                Gerenciar
              </Button>
            </ButtonsWrapper>
            <AppLabel>
              Otimize seus processo - Conheça os outros serviços do Grupo RV!
            </AppLabel>
            <AppsContainer>
              <AppCard
                href={redeOptions[0].link}
                title="Rede Vistorias"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img width={70} height={40} src={RedeVistoriasIMG} />
                <span>Realize e acompanhe pedidos de vistorias</span>
                <IconRight name="arrow right" color="grey" />
              </AppCard>
              <AppCard
                href={redeOptions[1].link}
                title="Rede Izee"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img width={70} height={40} src={RedeIzeeIMG} />
                <span>Gerencie contratos e analise sua carteira de imóveis.</span>
                <IconRight name="arrow right" color="grey" />
              </AppCard>
            </AppsContainer>
          </InfoBanner>
          <CustomBanner banners={banners} isLoading={isBannersLoading} />
        </BannersWrapper>
      )}

      <AuthorizationContainer action={ActionEnum.read} feature={FeatureEnum.analysis}>
        <div style={{ flex: 1, minWidth: 320 }}>
          <HeaderWrapper>
            <h2>Seguros Fiança</h2>

            {/* <ButtonsWrapper>
              <Button
                basic
                color={'blue'}
                size={'tiny'}
                onClick={() => {
                  PushRoute(history, props, 'bond-insurances');
                }}
              >
                Gerenciar
              </Button>

              <Button
                primary
                size={'tiny'}
                onClick={() => PushRoute(history, props, 'bond-insurance')}
              >
                Nova Análise
              </Button>
            </ButtonsWrapper> */}
          </HeaderWrapper>

          <CardWrapper>
            <Card
              label={'Aprovados'}
              quantity={biApproveds}
              isLoading={biApproveds === undefined}
              {...(biApproveds && biApproveds > 0
                ? {
                    onClick: () => PushRoute(history, props, 'bond-insurances', {
                      analysisStatus: 'APPROVED'
                    })
                  }
                : '')}
            />
            {user.role === UserRolesEnum.brokerAdmin && (
              <Card
                label={'Contratações em andamento'}
                quantity={biHired}
                isLoading={biHired === undefined}
              />
            )}

            <Card
              label={'Aprovações a expirar em 07 dias'}
              quantity={biToExpire || 0}
              isLoading={biToExpire === undefined}
              {...(biToExpire && biToExpire > 0
                ? {
                    onClick: () =>
                      PushRoute(history, props, 'bond-insurances', {
                        expiresInAWeek: true
                      })
                  }
                : '')}
              isNegative={!!(biToExpire && biToExpire > 0)}
            />

            {user.role === UserRolesEnum.brokerAdmin && (
              <Card
                label={'Cadastrados'}
                quantity={biTotal}
                isLoading={biTotal === undefined}
              />
            )}

            {user.role === UserRolesEnum.brokerAdmin && (
              <Card
                label={'Aguardando emissão'}
                quantity={biWaiting || 0}
                isLoading={biWaiting === undefined}
              />
            )}

            <Card
              label={'Emitidos'}
              quantity={biPolicyCreated || 0}
              isLoading={biPolicyCreated === undefined}
            />
            {/* <Card
              label={'Apólices expiram nos próximos 30 dias'}
              quantity={policyToExpire || 0}
              isLoading={policyToExpire === undefined}
              {...(policyToExpire && policyToExpire > 0
                ? {
                    onClick: () =>
                      PushRoute(history, props, 'bond-insurance-policies', {
                        expireInDays: '30'
                      })
                  }
                : '')}
            /> */}
          </CardWrapper>
        </div>
      </AuthorizationContainer>

    { isDevelop && <AuthorizationContainer
        action={ActionEnum.read}
        feature={FeatureEnum.fireinsurances}
      >
        <div style={{ flex: 1, minWidth: 320 }}>
          <HeaderWrapper>
            <h2>Incêndio</h2>

            {/* <ButtonsWrapper>
              <Button
                basic
                color={'blue'}
                size={'tiny'}
                onClick={() => PushRoute(history, props, 'fire-insurance-policies')}
              >
                Gerenciar
              </Button>
            </ButtonsWrapper> */}
          </HeaderWrapper>
          <CardWrapper>
            <Card
              label={'Orçados'}
              quantity={fiTotal || 0}
              isLoading={fiTotal === undefined}
            />

            <Card
              label={'Pagamentos pendentes'}
              quantity={fiPendentPayment || 0}
              isLoading={fiPendentPayment === undefined}
              isNegative={!!(fiPendentPayment && fiPendentPayment > 0)}
            />

            <Card
              label={'Orçados a expirar em 07 dias'}
              quantity={fiToExpire || 0}
              isLoading={fiToExpire === undefined}
            />

            <Card
              label={'Apólices emitidas'}
              quantity={fiPolicyHired || 0}
              isLoading={fiPolicyHired === undefined}
            />

            {user.role === UserRolesEnum.brokerAdmin && (
              <Card
                label={'Apólices em espera'}
                quantity={fiPolicyWaiting || 0}
                isLoading={fiPolicyWaiting === undefined}
              />
            )}

            <Card
              label={'Apólices a expirar'}
              quantity={fiPolicyToExpire || 0}
              isLoading={fiPolicyToExpire === undefined}
            />
          </CardWrapper>{' '}
        </div>
      </AuthorizationContainer>}

      <AuthorizationContainer action={ActionEnum.read} feature={FeatureEnum.claims}>
        <div style={{ flex: 1, minWidth: 320 }}>
          <HeaderWrapper>
            <h2>Capitalizações</h2>

            {/* <ButtonsWrapper>
              <Button
                basic
                color={'blue'}
                size={'tiny'}
                onClick={() => PushRoute(history, props, 'claim_estates')}
              >
                Gerenciar
              </Button>
            </ButtonsWrapper> */}
          </HeaderWrapper>
          <CardWrapper>
            <Card
              label={'Em emissão'}
              quantity={capitalizationsInProgress || 0}
              isLoading={capitalizationsInProgress === undefined}
            />

            <Card
              label={'Aguardando pagamento'}
              quantity={capitalizationsWaitingPayment || 0}
              isLoading={capitalizationsWaitingPayment === undefined}
            />

            <Card
              label={'Emitido'}
              quantity={capitalizationsHired || 0}
              isLoading={capitalizationsHired === undefined}
            />
          </CardWrapper>
        </div>
      </AuthorizationContainer>
    </Container>
  );
};

export default connect(mapState, mapDispatch)(RealDashboard);
