import React from 'react';
import { StepIndex, Step, StepName, Nav, ContentWrapper } from '../style';
import Loader from '../../../components/Loader';

type StepNavProps = {
  isLoading: boolean;
  steps: StepItem[];
  currentStep: number;
  onStepChange: Function;
}

type StepItem = {
  label: string;
  component: React.ReactNode;
}

export const StepNav = (props: StepNavProps) => {
  const { steps, currentStep, isLoading } = props;

  const stepsNav = [];

  for (let i = 0; i < steps.length; i++) {
    stepsNav.push((
      <Step selected={i === currentStep}>
        <StepIndex>{`${i + 1}.`}</StepIndex>
        <StepName>{steps[i].label}</StepName>
      </Step>
    ))
  }

  return (
    <div>
      <Nav>{stepsNav}</Nav>
      {isLoading && <Loader />}
      {!isLoading &&
        <ContentWrapper>
          <>
            <input type='hidden' value='something' />
            {steps[currentStep].component}
          </>
        </ContentWrapper>}
    </div>
  );
}
