import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { Checkbox } from 'semantic-ui-react';

export const CustomCheckbox = styled(Checkbox).attrs(({ proceed, checke }: {proceed: boolean; checke: boolean}) => ({
  style: {
    inputCheckedLabelBefore: (proceed && checke) ? '1px solid red' : '',
  },
}))`
  input:not(:checked):not(:disabled) + label:before {
    border: ${props => props.style.inputCheckedLabelBefore} !important;
  }
`;


export const Header = styled(Table.Header)`
  background-color: #e1e8ed;
`;

export const TableWrapper = styled.div`
  font-size: 0.85em;
  font-weight: bold;

  .ui.table {
    border: none;
    text-align: center;
    color: #364445;
  }
`;

export const NotFoundWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #364445;
  font-size: 1.2em;
`;
