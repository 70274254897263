import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Info } from '../../../components/Info';
import { DateToStringDate } from '../../../services/date';
import { maskCpfOrCnpj, maskMoney, maskCnpj } from '../../../services/masks';
import { FieldLabel } from '../../../styles';
import {BondInsuranceForGetBondInsurancesResponseDTO} from "../../../dtos/bond-insurance-list/get-bond-insurances-response.dto";
import SectionLabel from '../../../components/SectionLabel';

export type Props = {
  bondInsurance: BondInsuranceForGetBondInsurancesResponseDTO;
};

export const BondInsuranceLegalTenantDetails = (props: Props) => {

  const { legal_tenant } = props.bondInsurance;
  return (
    <>
    <SectionLabel text='DADOS DO LOCATÁRIO' />
      <Grid.Row>
        <Info name={'EMPRESA'} value={legal_tenant.company} width={4} />
        <Info name={'CNPJ'} value={legal_tenant.document ? maskCnpj(legal_tenant.document) : "Indisponível"} width={4} />
        <Info name={'E-MAIL'} value={legal_tenant.email} width={4} />
        <Info name={'TELEFONE'} value={legal_tenant.phone} width={4} />
      </Grid.Row>

      <Grid.Row>
        <Info name={'DATA DE CONSTITUIÇÃO'} value={legal_tenant.foundation_date ? DateToStringDate(legal_tenant.foundation_date) : "Indisponível"} width={4} />
        <Info name={'ÚLT. ALT. CONTRATUAL'} value={legal_tenant.last_contractual_alteration_date ? DateToStringDate(legal_tenant.last_contractual_alteration_date) : "Indisponível"} width={4} />
        <Info name={'CAPITAL SOCIAL (R$)'} value={legal_tenant.share_capital ? maskMoney(`${legal_tenant.share_capital}`) : "Indisponível"} width={4} />
        <Info name={'CAPITAL INTERGRALIZADO (R$)'} value={legal_tenant.paid_in_capital ? maskMoney(`${legal_tenant.paid_in_capital}`) : "Indisponível"} width={4} />
      </Grid.Row>

      <Grid.Row>
        <Info name={'FATURAMENTO ANUAL (R$)'} value={legal_tenant.anual_revenue ? maskMoney(`${legal_tenant.anual_revenue}`) : "Indisponível"} width={4} />
        <Info name={'NOME CONTATO'} value={legal_tenant.contact_name} width={4} />
        <Info name={'TELEFONE CONTATO'} value={legal_tenant.contact_phone} width={4} />
      </Grid.Row>

      <Grid.Row>
        <Info name={'LOGRADOURO'} value={legal_tenant.street} width={4} />
        <Info name={'NÚMERO'} value={legal_tenant.number} width={4} />
        <Info name={'COMPLEMENTO'} value={legal_tenant.complement} width={4} />
        <Info name={'BAIRRO'} value={legal_tenant.district} width={4} />
      </Grid.Row>

      <Grid.Row>
        <Info name={'CIDADE'} value={legal_tenant.city} width={4} />
        <Info name={'ESTADO'} value={legal_tenant.state} width={4} />
        <Info name={'CEP'} value={legal_tenant.zip_code} width={4} />
      </Grid.Row>

      {legal_tenant.partners.map((partner, index) => {
        return (
          <>
            <Grid.Row style={{ paddingLeft: '15px' }}>
              <FieldLabel style={{ color: '#364445', marginTop: '0px' }}>
                {`${index + 1}º SÓCIO`}
              </FieldLabel>
            </Grid.Row>

            <Grid.Row>
              <Info name={'NOME'} value={partner.name} width={4} />
              <Info name={'CPF'} value={partner.document ? maskCpfOrCnpj(partner.document) : "Indisponível"} width={4} />
              <Info name={'RENDA'} value={`${partner.percent} %`} width={4} />
              <Info name={'DATA DE ENTRADA'} value={partner.date_of_entry ? DateToStringDate(partner.date_of_entry) : "Indisponível"} width={4} />
            </Grid.Row>
          </>
        )
      })}
    </>
  )
}
