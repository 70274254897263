import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Dimmer, Divider, Feed, Loader, Modal, Segment } from 'semantic-ui-react';
import { getEventsTimeline } from '../../services/events';

import { CircleInitials, OldText, NewText, P } from './styles'

interface EventsTimelineInterface {
  relatedId: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const EventsTimeline = ({relatedId, open, setOpen}: EventsTimelineInterface) => {
  const [data, setData] = useState<any[]>([]);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [treatedData, setTreatedData] = useState<any[] | null>()
  const count = data.length;

  const toggleModal = () => {
    if (open) {
      clearData();
    }
    setOpen(!open);
  }

  const clearData = () => {
    setData([]);
    setIsReady(false);
    setTreatedData([]);
  }

  function convertToCamelCase(str: string) {
    return str.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
  }


  const loadData = async () => {
    if (relatedId) {
      const { data } = await getEventsTimeline(relatedId)
      setTreatedData(await treatData(data));

      // console.log({data})

      setData(data);
    }
  }

  useEffect(() => {
    if (open && relatedId) loadData()
  }, [relatedId, open])

  async function treatData(data: any): Promise<any[] | null> {
    if (data.length) {
      const treatedData = data.map((event: any) => {
        const { user, createdAt, payload, label, content, context, code } = event
        let diffs: any[] = []

        // console.log({user: user, createdAt, payload, status: label})
        if (context === 'CLAIM') {
          if (label !== 'CREATED') {
            if (payload && payload.new && payload.old) {
              // console.log({new: payload.new, old: payload.old})
              diffs = differenceBetweenTwoClaimObjects(payload.new, payload.old)
            }
          }
        }

        if (context === 'ESTATES') {
          if (payload && payload.new && payload.old) {
            // console.log({new: payload.new, old: payload.old.oldEstate, content})
            // console.log({content, new: payload.new, old: payload.old.oldEstate})
            diffs = differenceBetweenTwoEstateObjects(payload.new, payload.old, code)
          }
        }

        return {user: user?.name ? user.name : '', createdAt, payload, status: label, diffs, code }
      })

      setIsReady(true);

      return treatedData
    } else {
      setIsReady(true);
      return []
    }
  }

  function differenceBetweenTwoClaimObjects (obj1: any, obj2: any) {
    if (obj1 && obj2) {
      const diff = Object.keys(obj1).map(key => {
        if (obj1[key] !== obj2[key]) {
          if (key !== 'createdBy') {
            return {new: obj1[key], old: obj2[key], key}
          }
        }
      });
  
      const sanatizeDifference = diff.filter(n => n)
  
      return sanatizeDifference;
    } else {
      console.log('obj1 or obj2 is undefined', {obj1, obj2})
      return []
    }
  }

  const convertSnakeCaseToCamelCase = (str: string) => {
    return str.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
  }

  const convertObjectKeysToCamelCase = (obj: any) => {
    const newObj: any = {};
    Object.keys(obj).forEach(key => {
      const newKey = convertSnakeCaseToCamelCase(key);
      newObj[newKey] = obj[key];
    });
    return newObj;
  }

  function differenceBetweenTwoEstateObjects (obj1: any, obj2: any, code: number) {
    obj1 = convertObjectKeysToCamelCase(obj1);
    if (obj1 && obj2) {
      if (obj2.oldEstate) {
        if (obj2.oldEstateInsurerData) {
          obj2.oldEstate.insurerSpecificData = obj2.oldEstateInsurerData

          const diff = Object.keys(obj1).map(key => {
            obj1.estateZipCode = obj1.estateZipcode
            // delete unnecessary keys
            delete obj1.agencyDv
            delete obj1.bankCode
            delete obj1.accountDv
            delete obj1.agencyNumber
            delete obj1.accountNumber
            delete obj1.tariffPlanId
            delete obj1.bankAccountId
            delete obj1.estateZipcode
            delete obj1.additionalAccountManager


            if (obj1[key] !== obj2.oldEstate[key]) {
              const type1 = Array.isArray(obj1[key]) ? 'array' : typeof obj1[key];
              const type2 = Array.isArray(obj2.oldEstate[key]) ? 'array' : typeof obj2.oldEstate[key];
              if (key !== 'userId' && key !== 'brokerId') {
                if (type1 !== 'array') {
                  // console.log({key, oo1: obj1[key], oo2: obj2.oldEstate[key], type1, type2})
                  return {new: obj1[key], old: obj2[key], key}
                } else {
                  if (key === 'insurers') {
                    const newInsurers = obj1[key].map((item: any) => {
                      return item.name
                    }).join(', ')

                    const oldInsurers = obj2.oldEstate[key].map((item: any) => {
                      return item.name
                    }).join(', ')

                    // console.log({newInsurers, oldInsurers});
                    if (newInsurers !== oldInsurers) {
                      return {new: newInsurers, old: oldInsurers, key};
                    }
                  }

                  if (key === 'insurerSpecificData') {
                    const insurerData1 = obj1[key]
                    const insurerData2 = obj2.oldEstate[key]
                    
                    if (type1 === 'array') {
                      const arrDiffs: any[] = [];
                      if (insurerData1.length && insurerData2.length) {
                        const diff = Object.values(insurerData1).forEach((insurer1: any) => {
                          Object.values(insurerData2).forEach((insurer2: any) => {
                            if (insurer1.id === insurer2.id) {
                              // console.log({insurer1, insurer2})
                              Object.keys(insurer1).forEach(obj1 => {
                                if (insurer1[obj1] !== insurer2[obj1]) {
                                  if (obj1 !== 'estateId' && obj1 !== 'insurerId') {
                                    if (obj1 === 'pfComercialTax' || obj1 === 'pfResidentialTax') {
                                      // console.log({new: insurer1[obj1], old: Number(insurer2[obj1]), key: obj1});
                                      if (insurer1[obj1] !== Number(insurer2[obj1])) {
                                        arrDiffs.push({new: insurer1[obj1], old: Number(insurer2[obj1]), key: obj1});
                                      }
                                    } else {
                                      // console.log({new: insurer1[obj1], old: insurer2[obj1], key: obj1});
                                      arrDiffs.push({new: insurer1[obj1], old: insurer2[obj1], key: obj1});
                                    }
                                    // return {new: insurer1[obj1], old: insurer2[obj1], key: obj1}
                                  }
                                }
                              })
                            }
                          })
                        });
                      } else {
                        // console.log({code, insurerData1, insurerData2})
                        insurerData1.forEach((insurer1: any) => {
                          Object.keys(insurer1).forEach(obj1 => {
                            const label = obj1;
                            const newValue = insurer1[obj1];
                            const oldValue = null;
                            // console.log({label, newValue, oldValue})
                            arrDiffs.push({new: newValue, old: oldValue, key: label});
                          })
                        })
                      }

                      const sanatizeDifference = arrDiffs.filter(n => n)
                      
                      if (sanatizeDifference.length > 0) {
                        // console.log({sanatizeDifference})
                        return sanatizeDifference;
                      }
                    }
                  }

                  if (key === 'fireInsurers') {
                    // console.log({key, oo1: obj1[key], oo2: obj2.oldEstate[key], type1, type2})
                    // TODO - quando habilitar as seguradoras para incêndio é necessário montar a estrutura para verificar as diferenças
                  }

                  // fire_insurers

                }
              }
            }
          })

          const sanatizeDifference = diff.filter(n => n)
  
          return sanatizeDifference;
        } else {
          console.log('ESSE É SEM O OLDESTATEINSURERDATA');
        }
      }
      return []
    } else {
      console.log('obj1 or obj2 is undefined', {obj1, obj2})
      return []
    }
  }

  function getInitialFromName(name: string) {
    if (name.length) {
      let newName: string;
      const splitedName = name.split(' ');
      if (splitedName.length > 1) {
        newName = splitedName[0].charAt(0) + splitedName[1].charAt(0)
      } else {
        newName = splitedName[0].charAt(0)
      }
  
      return newName
    }

    return '4S'
  }

  return (
    <Modal
      centered
      open={open}
      onClose={toggleModal}
    >
      <Modal.Header>Timeline</Modal.Header>
      <Modal.Content scrolling>
        {
          isReady ? (
            <>
              {
                treatedData && treatedData.length ? treatedData.map((event: any, i: number) => (
                  <>
                    {
                      event.diffs.length || event.status === 'CREATED'  ? (
                        <>
                          <Feed>
                            <Feed.Event>
                              <Feed.Label>
                                <CircleInitials>{getInitialFromName(event.user)}</CircleInitials>
                              </Feed.Label>
                              <Feed.Content>
                                <Feed.Summary>
                                  <Feed.User>{event.user ? event.user : '4Seg'}</Feed.User>
                                  <Feed.Date>{moment(event.createdAt).fromNow()} - #{event.code}</Feed.Date>
                                </Feed.Summary>
                                <Feed.Extra>
                                  {
                                    event.status === 'CREATED' ? (
                                      <>Criou o registro</>
                                    ) : (
                                      <>
                                        {
                                          event.diffs.map((diff: any) => (
                                            <P>
                                              {
                                                Array.isArray(diff) ? (
                                                  <>
                                                    {
                                                      (diff.map((d: any) => (
                                                        <>
                                                          <strong>{d.key}:</strong>
                                                          { d.old && <OldText>{d.old}</OldText> }
                                                          <NewText>{d.new}</NewText>
                                                          <br />
                                                        </>
                                                      )))
                                                    }
                                                  </>
                                                ) : (
                                                  <>
                                                    <strong>{diff.key}:</strong>
                                                    { diff.old && <OldText>{diff.old}</OldText> }
                                                    <NewText>{diff.new}</NewText>
                                                    <br />
                                                  </>
                                                )
                                              }
                                            </P>
                                          ))
                                        }
                                      </>
                                    )
                                  }
                                </Feed.Extra>
                              </Feed.Content>
                            </Feed.Event>
                          </Feed>
                          {
                            i < count - 1 && <Divider />  
                          }
                        </>
                      ) : (
                        <></>
                      )
                    }
                  </>
                )) : (
                  <p>Não há eventos para este registro</p>
                )
              }
            </>
          ) : (
            <Dimmer active inverted>
              <Loader active content={'Carregando'} />
            </Dimmer>
          )
        }
      </Modal.Content>
      <Modal.Actions>
        <small>{relatedId}</small>
      </Modal.Actions>
    </Modal>
  );
}

export default EventsTimeline;