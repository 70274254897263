import Swal from 'sweetalert2'

import React, { useEffect, useState } from "react";
import { Button, Header, Icon, Modal} from "semantic-ui-react";
import { LogModalProps } from '../types';
import LogTable from '../LogTable';

const GenericLogModal = ({
  relatedIds,
  createdAt,
  updatedAt,
  context
}: LogModalProps) => {
  const [openLogModal, setOpenLogModal] = useState(false);

  return (
    <div style={{ outline: 'none' }}>
      <Modal
        closeIcon
        open={openLogModal}
        onClose={
          () => setOpenLogModal(false)
        }
        trigger={
          <Button
            className="ui button"
            onClick={() => setOpenLogModal(true)}
          >
            <Icon name={"file alternate outline"} />
            Abrir Logs
          </Button>
        }
      >
        <Header icon="file alternate outline" content={`Logs`} />
        <Modal.Content scrolling>
          <LogTable relatedIds={relatedIds} createdAt={createdAt} updatedAt={updatedAt} context={context}/>
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={() => setOpenLogModal(false)}>
            <Icon name="remove" /> Sair
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
export default GenericLogModal;
