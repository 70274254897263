import React, { SyntheticEvent } from 'react';
import { FormikProps, Formik, Field } from 'formik';
import { FireInsuranceBudgetFormData, validityOptions, segimobValidityOptions } from '../../types';
import { Code } from '../../../../types/InsurerState';
import Input from '../../../../components/Input';
import Dropdown from '../../../../components/Dropdown';
import { coverProposal } from '../../../../services/fire-insurance-budget';
import { successNotification, dangerNotification } from '../../../../services/notification';
import { Grid, Button, DropdownProps, InputOnChangeData } from 'semantic-ui-react';
import { formatAmount, unmaskMoney } from '../../../../services/masks';
import { addMonthToDate } from '../../../../util';
import { getErrorFormik } from '../../../../services/errors';
import { FormikErrorMessage } from '../../../../components/ErrorMessage';
import { ContentWrapper, ContentDivider } from '../styled-components';
import { Sections } from './Sections';
import { initialSimulator } from '../../constants';
import { submitQuote, FireInsuranceBudgetFormProps, QuotePayload } from '../../FireInsuranceBudgetForm';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import {connect} from "react-redux";
import {iRootDispatch, iRootState} from "../../../../store";
import {SimulatorState} from "../../../../store/types/temp-types";

type ContentProps =
  FormikProps<FireInsuranceBudgetFormData>
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & { fireInsuranceBudgetFormProps: FireInsuranceBudgetFormProps }
  & { setIsLoading: React.Dispatch<React.SetStateAction<boolean>> }
  & { handleInputChange: (_: any, data: InputOnChangeData) => void }
  & { setQuotePayload: React.Dispatch<React.SetStateAction<QuotePayload>> }
  & { simulatorState: SimulatorState };


const mapStateToProps = (state: iRootState) => ({
  simulatorState: state.simulator,
  selectedBrokerId: state.fireInsuranceBudget.selected.broker.id,
  installmentsData: state.simulator.installmentsData
});

const mapDispatchToProps = (dispatch: iRootDispatch) => ({
  updateSimulatorFields: (simulatorFields: Partial<SimulatorState>) => dispatch.simulator.updateSimulatorFields(simulatorFields),
});

const Content = (props: ContentProps) => {
  const {
    values,
    errors,
    touched,
    fireInsuranceBudgetFormProps,
    updateSimulatorFields,
    installmentsData,
    handleBlur,
    setFieldTouched,
    setIsLoading,
    setQuotePayload,
    handleInputChange,
    selectedBrokerId,
    setFieldValue } = props;
  const { simulator, budget } = props.values;

  const { property, tenant, owner, insurance } = budget;

  const validateErros = (field: string) => getErrorFormik(errors, touched, 'simulator', field);

  const setSimulatorFieldValue = (field: string, value: any) => {
    setFieldValue(`simulator.${field}`, value);
  }

  const onChangeValidity = (e: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const _validity = data.value;
    setSimulatorFieldValue('validity', _validity);

    if (_validity && simulator.startDate) {
      const end_date = addMonthToDate(simulator.startDate, parseInt(`${data.value}`));
      setSimulatorFieldValue('endDate', end_date);
    }
  }

  const onChangeStartDate = (date: Date, e: SyntheticEvent<HTMLElement, Event>) => {
    setFieldTouched('simulator.startDate', true);
    setSimulatorFieldValue('startDate', date);
    let end_date;
    if (date && simulator.validity) {
      end_date = addMonthToDate(date, simulator.validity);
      setSimulatorFieldValue('endDate', end_date);
    }
  };

  const onChangeCoverValue = (e: SyntheticEvent, data: any) => {
    data.value = formatAmount(data.value);
    setSimulatorFieldValue('coverValue', data.value);
    setSimulatorFieldValue('covered', false);
  }

  const coverValue = async (e: SyntheticEvent) => {
    if (budget && simulator) {
      if (budget.insurance.insurer.code !== Code.TokioMarine) {
        try {
          const updatedValues = coverProposal(
            budget.insurance.broker.name,
            simulator.confiaxComission,
            budget.insurance.broker.minimumGain,
            simulator.value,
            Number(unmaskMoney(`${simulator.coverValue}`)),
            simulator.profitShare,
            simulator.brokerageFee,
            simulator.insurerGain
          );
          setFieldValue('budget.insurance.estate.profitShare', updatedValues.profit_share);
          setFieldValue('budget.insurance.broker.fee', updatedValues.brokerage_fee);
          setFieldValue('budget.confiaxComission', updatedValues.confiax_comission);
          setSimulatorFieldValue('covered', true);
          successNotification('Sucesso!', 'Novo valor aplicado');
        } catch (e) {
          setSimulatorFieldValue('covered', false);
          setSimulatorFieldValue('coverValue', 0);
          dangerNotification('Erro', 'Não é possível cobrir o valor informado');
        }
      } else {
        try {
          setIsLoading(true);
          budget.coverage = budget.coverage
          .filter((f) => f !== undefined)
          .map((c) => { c.damageAmount = formatAmount(c.damageAmount); return c;})
          const res = await submitQuote(budget, simulator, {...fireInsuranceBudgetFormProps, updateSimulatorFields}, selectedBrokerId);
          setIsLoading(false);
          if (!res) throw new Error('Failed quote');

          setQuotePayload({
            insurerId: budget.insurance.insurer.id,
            assistanceId: budget.insurance.assistance.id,
            occupationId: budget.insurance.occupation.id,
            propertyType: budget.insurance.propertyType,
            rentValue: budget.insurance.rentValue,
            coverage: Object.values(budget.coverage)
          } as QuotePayload);
          setSimulatorFieldValue('covered', true);
          successNotification('Sucesso!', 'Novo valor aplicado');
        } catch (e) {
          setSimulatorFieldValue('covered', false);
          setSimulatorFieldValue('coverValue', 0);
          dangerNotification('Erro', 'Não é possível cobrir o valor informado');
        }
      }
    }
  }

  return (
    <>
      <Formik
        initialValues={initialSimulator}
        onSubmit={() => { }}>
        {innerProps => (
          <ContentWrapper>
            <Grid.Row>
              <Grid.Column width={16}>
                <label>Quantidade de parcelas:</label>
                <Field name="simulator.installments"
                       completeValue={true}
                       defaultValue={simulator.installments}
                       value={simulator.installments}
                       options={simulator.installmentsOptions}
                       error={validateErros('installments')}
                       onChange={handleInputChange}
                       component={Dropdown}
                       disabled={!simulator.value}
                       selection
                       compact
                       basic
                       fluid />
                <FormikErrorMessage component="div" name="simulator.installments" />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                <label>Duração de Vigência:</label>
                <Field name="simulator.validity"
                  completeValue={true}
                  defaultValue={simulator.validity}
                  disabled={budget.insurance.insurer.code === Code.Fairfax || !simulator.value}
                  options={(budget.insurance.insurer.code === Code.TokioMarine ? segimobValidityOptions : validityOptions)}
                  onChange={onChangeValidity}
                  error={validateErros('validity')}
                  onBlur={handleBlur}
                  component={Dropdown}
                  selection
                  compact
                  basic
                  fluid/>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row >
              <Grid.Column width={8}>
                <label>Início:</label>
                <Field name="simulator.startDate"
                  error={errors.simulator?.startDate}
                  selected={simulator.startDate}
                  disabled={!simulator.validity || !simulator.value}
                  component={CustomDatePicker}
                  customInput={<Input style={{ width: '85%' }} />}
                  dateFormat={'dd/MM/yyyy'}
                  onChange={onChangeStartDate}
                  className={validateErros('startDate') ? 'text-input error' : 'text-input'}
                />
                <FormikErrorMessage component="div" name="simulator.startDate" />
              </Grid.Column>
              <Grid.Column width={8}>
                <label>Fim:</label>
                <Field name="simulator.endDate"
                  disabled
                  error={validateErros('endDate')}
                  selected={simulator.endDate}
                  component={CustomDatePicker}
                  dateFormat={'dd/MM/yyyy'}
                  customInput={<Input style={{ width: '85%' }} />}
                  onChange={handleInputChange}
                  className={validateErros('endDate') ? 'text-input error' : 'text-input'}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                <ContentDivider />
              </Grid.Column>
            </Grid.Row>
          </ContentWrapper>
        )}
      </Formik>
      <Sections budget={budget} />
    </>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
