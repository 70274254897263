import styled from 'styled-components';

export const Container = styled.div``;

export const Form = styled.form`
  padding: 10px 14px;
  label {
    font-weight: 600;
  }
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  color: #393939;
  margin: 0;
`;

export const Subtitle = styled.small`
  color: #393939;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
`;

export const Disclaimer = styled.div`
  min-height: 60px;
  padding: 18px 24px;

  p {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0em;
  }

  i {
    background: #2185d0;
    color: white;
    border-radius: 2px;
    box-shadow: 0px 0px 1px #2185d070;
  }

  input {
    margin-right: 10px !important;
  }
`;
