import { PersonTypesEnum } from '../../../../enums/person-types.enum';
import { warningNotification } from '../../../../services/notification';
import { CreatePolicyProps } from './types';

export function StepValidation(step: number, values: CreatePolicyProps): boolean {
  let error = false;
  switch (step) {
    case 1:
      if (!values?.bondInsurance?.estateId) {
        warningNotification('Atenção!', 'Selecione uma imobiliária');
        error = true;
      }
      if (!values?.bondInsurance?.purpose) {
        warningNotification('Atenção!', 'Selecione uma finalidade');
        error = true;
      }
      if (!values?.bondInsurance?.tenantType) {
        warningNotification('Atenção!', 'Selecione um tipo de locatário');
        error = true;
      }
      if (!values?.bondInsurance?.analysisAssurance) {
        warningNotification('Atenção!', 'Selecione uma garantia da análise');
        error = true;
      }

      if (!values?.bondInsurance?.property?.validity) {
        warningNotification('Atenção!', 'Selecione uma vigência do contrato');
        error = true;
      }

      if (!values?.bondInsurance?.property?.reason) {
        warningNotification('Atenção!', 'Selecione um motivo da locação');
        error = true;
      }

      if (!values?.analysis?.insurerId) {
        warningNotification('Atenção!', 'Selecione uma seguradora');
        error = true;
      }

      if (!values?.bondInsurance?.property?.rent_due_date) {
        warningNotification('Atenção!', 'Informe o dia de vencimento do aluguel');
        error = true;
      }

      if (!values?.analysis?.value) {
        warningNotification('Atenção!', 'Informe o valor da análise');
        error = true;
      }

      if (!values?.bondInsurance?.rentInsuranceContractStartDate) {
        warningNotification('Atenção!', 'Informe o início da vigência do contrato');
        error = true;
      }

      if (!values?.bondInsurance?.rentInsuranceContractEndDate) {
        warningNotification('Atenção!', 'Informe o fim da vigência do contrato');
        error = true;
      }

      if (!values?.bondInsurance?.rentalPeriodStartDate) {
        warningNotification('Atenção!', 'Informe o início da garantia');
        error = true;
      }

      if (!values?.bondInsurance?.rentalPeriodEndDate) {
        warningNotification('Atenção!', 'Informe o fim da garantia');
        error = true;
      }
      return error;
    case 2:
      error = false;
      if (!values?.bondInsurance?.property?.property_type) {
        warningNotification('Atenção!', 'Selecione um tipo de imóvel');
        error = true;
      }
      if (!values?.bondInsurance?.property?.zip_code) {
        warningNotification('Atenção!', 'Informe o CEP do imóvel');
        error = true;
      }
      if (!values?.bondInsurance?.property?.street) {
        warningNotification('Atenção!', 'Informe o logradouro do imóvel');
        error = true;
      }

      if (!values?.bondInsurance?.property?.district) {
        warningNotification('Atenção!', 'Informe o bairro do imóvel');
        error = true;
      }

      if (!values?.bondInsurance?.property?.city) {
        warningNotification('Atenção!', 'Informe a cidade do imóvel');
        error = true;
      }

      if (!values?.bondInsurance?.property?.state) {
        warningNotification('Atenção!', 'Informe o estado do imóvel');
        error = true;
      }

      if (!values?.bondInsurance?.property?.number) {
        warningNotification('Atenção!', 'Informe o número do imóvel');
        error = true;
      }

      if (!values?.bondInsurance?.property?.rent_value) {
        warningNotification('Atenção!', 'Informe o valor do aluguel');
        error = true;
      }

      if (!values?.bondInsurance?.propertyOwnerNaturalPerson?.cpf) {
        warningNotification('Atenção!', 'Informe o CPF do proprietário');
        error = true;
      }

      if (!values?.bondInsurance?.propertyOwnerNaturalPerson?.name) {
        warningNotification('Atenção!', 'Informe o nome do proprietário');
        error = true;
      }

      if (!values?.bondInsurance?.responsibleAddress?.zip_code) {
        warningNotification('Atenção!', 'Informe o CEP do proprietário');
        error = true;
      }

      if (!values?.bondInsurance?.responsibleAddress?.street) {
        warningNotification('Atenção!', 'Informe o logradouro do proprietário');
        error = true;
      }

      if (!values?.bondInsurance?.responsibleAddress?.district) {
        warningNotification('Atenção!', 'Informe o bairro do proprietário');
        error = true;
      }

      if (!values?.bondInsurance?.responsibleAddress?.city) {
        warningNotification('Atenção!', 'Informe a cidade do proprietário');
        error = true;
      }

      if (!values?.bondInsurance?.responsibleAddress?.state) {
        warningNotification('Atenção!', 'Informe o estado do proprietário');
        error = true;
      }

      if (!values?.bondInsurance?.responsibleAddress?.number) {
        warningNotification('Atenção!', 'Informe o número do proprietário');
        error = true;
      }

      return error;
    case 3:
      error = false;
      if (values?.bondInsurance?.tenantType === PersonTypesEnum.natural) {
        if (!values?.bondInsurance?.naturalTenant?.main_tenant_cpf) {
          warningNotification('Atenção!', 'Informe o CPF do locatário');
          error = true;
        }

        if (!values?.bondInsurance?.naturalTenant?.main_tenant_name) {
          warningNotification('Atenção!', 'Informe o nome do locatário');
          error = true;
        }

        if (!values?.bondInsurance?.naturalTenant?.main_tenant_email) {
          warningNotification('Atenção!', 'Informe o email do locatário');
          error = true;
        }

        if (!values?.bondInsurance?.naturalTenant?.main_tenant_phone) {
          warningNotification('Atenção!', 'Informe o telefone do locatário');
          error = true;
        }

        if (!values?.bondInsurance?.naturalTenant?.main_tenant_birth_date) {
          warningNotification('Atenção!', 'Informe a data de nascimento do locatário');
          error = true;
        }

        if (!values?.bondInsurance?.naturalTenant?.main_tenant_gender) {
          warningNotification('Atenção!', 'Informe o gênero do locatário');
          error = true;
        }

        if (!values?.bondInsurance?.naturalTenant?.main_tenant_marital_status) {
          warningNotification('Atenção!', 'Informe o estado civil do locatário');
          error = true;
        }

        if (!values?.bondInsurance?.naturalTenant?.employment_relationship) {
          warningNotification('Atenção!', 'Informe a ocupação do locatário');
          error = true;
        }

        if (!values?.bondInsurance?.naturalTenant?.main_tenant_profession) {
          warningNotification('Atenção!', 'Informe a profissão do locatário');
          error = true;
        }

        return error;
      } else {
        if (!values?.bondInsurance?.legalTenant?.company) {
          warningNotification('Atenção!', 'Informe a razão social do locatário');
          error = true;
        }

        if (!values?.bondInsurance?.legalTenant?.document) {
          warningNotification('Atenção!', 'Informe o CNPJ do locatário');
          error = true;
        }

        if (!values?.bondInsurance?.legalTenant?.email) {
          warningNotification('Atenção!', 'Informe o email do locatário');
          error = true;
        }

        if (!values?.bondInsurance?.legalTenant?.phone) {
          warningNotification('Atenção!', 'Informe o telefone do locatário');
          error = true;
        }

        if (!values?.bondInsurance?.legalTenant?.contact_name) {
          warningNotification('Atenção!', 'Informe o nome de um responsável (CONTATO) ');
          error = true;
        }

        if (!values?.bondInsurance?.legalTenant?.contact_phone) {
          warningNotification(
            'Atenção!',
            'Informe o telefone de um responsável (CONTATO)'
          );
          error = true;
        }

        if (!values?.bondInsurance?.legalTenant?.foundation_date) {
          warningNotification('Atenção!', 'Informe a data de fundação do locatário');
          error = true;
        }

        if (!values?.bondInsurance?.legalTenant?.last_contractual_alteration_date) {
          warningNotification(
            'Atenção!',
            'Informe a data da última alteração contratual do locatário'
          );
          error = true;
        }

        if (!values?.bondInsurance?.legalTenant?.share_capital) {
          warningNotification('Atenção!', 'Informe o capital social do locatário');
          error = true;
        }

        if (!values?.bondInsurance?.legalTenant?.anual_revenue) {
          warningNotification('Atenção!', 'Informe a receita anual do locatário');
          error = true;
        }

        if (!values?.bondInsurance?.legalTenant?.zip_code) {
          warningNotification('Atenção!', 'Informe o CEP da empresa');
          error = true;
        }

        if (!values?.bondInsurance?.legalTenant?.street) {
          warningNotification('Atenção!', 'Informe o logradouro da empresa');
          error = true;
        }

        if (!values?.bondInsurance?.legalTenant?.district) {
          warningNotification('Atenção!', 'Informe o bairro da empresa');
          error = true;
        }

        if (!values?.bondInsurance?.legalTenant?.city) {
          warningNotification('Atenção!', 'Informe a cidade da empresa');
          error = true;
        }

        if (!values?.bondInsurance?.legalTenant?.state) {
          warningNotification('Atenção!', 'Informe o estado da empresa');
          error = true;
        }

        if (!values?.bondInsurance?.legalTenant?.number) {
          warningNotification('Atenção!', 'Informe o número da empresa');
          error = true;
        }

        if (!values?.bondInsurance?.legalTenant?.property_type) {
          warningNotification('Atenção!', 'Informe o tipo do imóvel');
          error = true;
        }

        return error;
      }
    case 4:
      error = false;
      if (!values?.hiredData?.installments) {
        warningNotification('Atenção!', 'Informe a quantidade de parcelas');
        error = true;
      }

      if (!values?.analysis?.value) {
        warningNotification('Atenção!', 'Informe o valor da parcela');
        error = true;
      }

      if (!values?.hiredData?.insurerPolicyCreatedAt) {
        warningNotification('Atenção!', 'Informe a data de emissão da apólice');
        error = true;
      }

      if (!values?.hiredData?.insurerPolicyId) {
        warningNotification('Atenção!', 'Informe o número da apólice');
        error = true;
      }

      if (!values?.hiredData?.totalPremiumAmount) {
        warningNotification('Atenção!', 'Informe o valor total do prêmio');
        error = true;
      }
      return error;
    default:
      return false;
  }
}
