import React, { useState, useEffect, ReactElement } from 'react';
import {
  Modal,
  Button,
  Icon,
  Header,
  Dimmer,
  Loader,
  Grid,
  Accordion,
  Popup
} from 'semantic-ui-react';
import { getFilesLinkAxios } from '../../services/file';
import {
  dangerNotification,
  successNotification,
  warningNotification
} from '../../services/notification';
import { FieldLabel } from '../../styles';
import FileUploader, { FileAttachmentProps } from '../UploadFiles';
import { fileTypeProps } from '../UploadFilesUncontroled';
import JSZip from 'jszip';
import axios from 'axios';
import { saveAs } from 'file-saver';
import AuthorizationContainer from '../AuthorizationContainer';
import { ActionEnum } from '../../enums/authz-action.enum';
import { FeatureEnum } from '../../enums/authz-feature.enum';
import { FollowUpContextEnum } from '../../enums/followup-context.enum';

export type ModalAttachFilesProps = {
  bondInsuranceId: string;
  message?: string;
  tableName?: string;
  trigger?: ReactElement;
  fileIdentifiers?: fileTypeProps[];
  callback?: (b: string[]) => void;
  brokerOnly?: boolean;
  tenantName?: string;
  disabled?: boolean;
  hideModels?: boolean;
  tags?: {
    tag: string;
    name: string;
    date?: Date;
  }[];
};

const ModalAttachFiles = ({
  bondInsuranceId,
  message = 'Recomendamos que faça upload do Laudo de Vistoria e do Contrato de Locação. Lembrando que estes documentos não são obrigatórios e não serão analisados para a contratação.',
  tableName = 'bond_insurance',
  trigger,
  fileIdentifiers,
  callback,
  brokerOnly = true,
  tenantName,
  disabled = false,
  hideModels = false,
  tags = []
}: ModalAttachFilesProps) => {
  const [loading, setLoading] = useState(true);
  const [loadingZip, setLoadingZip] = useState(false);
  const [attachFilesModal, setAttachFilesModal] = useState(false);
  const [flagSubmitAttachments, setFlagSubmitAttachments] = useState(false);
  const [attachedLinkFiles, setAttachedLinkFiles] = useState<FileAttachmentProps[]>([]);

  const [openModels, setOpenModels] = useState(false);

  const handleOpenModel = () => {
    setOpenModels(!openModels);
  };

  async function callbackFilesAttachment(files: [{ id: string; contextType: string }]) {
    setLoading(true);
    setFlagSubmitAttachments(false);
    try {
      if (attachFilesModal && bondInsuranceId) {
        const { data } = await getFilesLinkAxios(bondInsuranceId, tableName);
        if (data.urls?.length > 0) {
          const filesAttached = data.urls.map((url: any) => ({
            fileLink: url.link,
            option: url.name,
            id: url.id,
            createdAt: url.createdAt
          }));


          setAttachedLinkFiles(
            filesAttached.sort(function (a: any, b: any) {
              return b.createdAt - a.createdAt;
            })
          );

          if (callback && files && files[0].contextType) {
            const callbackReturn = files.map(c => c.contextType);
            callback(callbackReturn);
          }
        }
      }
    } catch (err) {
      console.log(err);
      dangerNotification('Ops...', 'Erro ao tentar recuperar o link dos arquivos!');
    } finally {
      setAttachFilesModal(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    async function getFiles() {
      try {
        setLoading(true);
        const { data } = await getFilesLinkAxios(bondInsuranceId, tableName);
        if (data.urls?.length > 0) {
          const filesAttached = data.urls.map((url: any) => ({
            fileLink: url.link,
            option: url.name,
            id: url.id,
            createdAt: url.createdAt,
            tag: tags?.length > 1 && tags[1]?.date !== undefined
            ? (url.createdAt < tags[1].date ? tags[0] : tags[1])
            : ''
          }));
            //// temos 2 tags, uma para Arquivos anexados antes da data de efetivação de apólice
          //// e outra para arquivos anexados após a data de efetivação de apólice
          /// a Tag de análise não tem data de criação, enquando a de apólice tem a data de criação de apólices!

          setAttachedLinkFiles(
            filesAttached.sort(
              (a: any, b: any) =>
                new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            )
          );
        }
      } catch (err) {
        console.log(err);
        dangerNotification('Ops...', 'Erro ao tentar recuperar o link dos arquivos!');
      } finally {
        setLoading(false);
        setLoading(false);
      }
    }

    if (attachFilesModal) {
      getFiles();
    } else {
      setAttachedLinkFiles([]);
    }
  }, [attachFilesModal, bondInsuranceId, tableName]);

  useEffect(() => {
    fileIdentifiers?.sort(function compare(a, b) {
      return a.name.localeCompare(b.name, 'pt-BR');
    });
  }, [fileIdentifiers]);

  async function downloadFiles() {
    try {
      setLoadingZip(true);
      const zip = new JSZip();

      const filePromises = attachedLinkFiles.map(file =>
        axios.get(file.fileLink, { responseType: 'arraybuffer' }).then(response => {
          const originalFileName = file.fileLink.split('?')[0].split('/').pop()?.slice(37) || '';
          const identifier = fileIdentifiers?.find(f => f.key === file.option)?.name || file.option;
          const fileName = `${identifier}_${originalFileName}`;
          zip.file(fileName!, response.data, { binary: true });
        })
      );

      await Promise.all(filePromises);

      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(content, tenantName ? `anexos_${tenantName}.zip` : 'anexos.zip');
      });

      successNotification('Sucesso', 'Download realizado com sucesso!');
    } catch (e) {
      console.log(e);
      dangerNotification(
        'Oops...',
        'Ocorreu um erro ao realizar o download dos arquivos!'
      );
    } finally {
      setLoadingZip(false);
    }
  }

  return (
    <Modal
      closeIcon
      open={attachFilesModal}
      onClose={() => setAttachFilesModal(false)}
      trigger={
        trigger ? (
          trigger
        ) : (
          <Button type="button" color="blue">
            <Icon name={'attach'} />
            Gerenciar Anexos
          </Button>
        )
      }
      onOpen={() => setAttachFilesModal(true)}
    >
      <Header icon="file alternate outline">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            {' '}
            <Icon name="file alternate outline" /> Gerenciar Anexos
          </div>
          <Popup
            content={'Clique para realizar o download de todos os anexos!'}
            position={'top right'}
            trigger={
              <AuthorizationContainer
                action={ActionEnum.download}
                feature={FeatureEnum.files}
              >
                <Button
                  icon="download"
                  color="blue"
                  onClick={downloadFiles}
                  loading={loadingZip}
                  disabled={attachedLinkFiles.length === 0 || loadingZip}
                />
              </AuthorizationContainer>
            }
          />
        </div>
      </Header>

      <Modal.Content>
        <Dimmer active={loading}>
          <Loader indeterminate> Carregando... </Loader>
        </Dimmer>
        <div>
          <p>
            {' '}
            {message} <br />
            São aceitos arquivos de até <b>50MB</b> <br />
            Formatos Aceitos: jpeg, png, webp, doc, docx, pdf e odt.
          </p>

          {!hideModels && tableName !== 'capitalization' && tableName !== FollowUpContextEnum.analysis && (
            <Accordion>
              <Accordion.Title active={openModels} onClick={handleOpenModel}>
                <Icon name="dropdown" color="blue" size="big" />
                <b>Modelos de arquivos para solicitações de cancelamentos.</b>
              </Accordion.Title>
              <Accordion.Content active={openModels}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <FieldLabel>
                        - Termo cancelamento compra do imóvel. (
                        <a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Termo+cancelamento+compra+do+im%C3%B3vel.docx">
                          Baixar Modelo
                        </a>
                        )<br />
                      </FieldLabel>
                      <FieldLabel>
                        - Termo cancelamento de desistência de locação. (
                        <a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Termo+cancelamento+de+desist%C3%AAncia+de+loca%C3%A7%C3%A3o.docx">
                          Baixar Modelo
                        </a>
                        )<br />
                      </FieldLabel>
                      <FieldLabel>
                        - Termo cancelamento de entrega de chaves e inexistência de
                        débitos. (
                        <a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Termo+cancelamento+de+entrega+de+chaves+e+inexist%C3%AAncia+de+d%C3%A9bitos.docx">
                          Baixar Modelo
                        </a>
                        )<br />
                      </FieldLabel>
                      <FieldLabel>
                        - Termo cancelamento de troca de locatário. (
                        <a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Termo+cancelamento+de+troca+de+locat%C3%A1rio.docx">
                          Baixar Modelo
                        </a>
                        )<br />
                      </FieldLabel>
                      <FieldLabel>
                        - Termo cancelamento de troca de seguradora. (
                        <a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Termo+cancelamento+de+troca+de+seguradora.docx">
                          Baixar Modelo
                        </a>
                        )<br />
                      </FieldLabel>
                      <FieldLabel>
                        - Termo cancelamento duplicidade de apólice. (
                        <a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Termo+cancelamento+duplicidade+de+ap%C3%B3lice.docx">
                          Baixar Modelo
                        </a>
                        )<br />
                      </FieldLabel>
                      <FieldLabel>
                        - Termo cancelamento troca de garantia. (
                        <a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Termo+cancelamento+troca+de+garantia.docx">
                          Baixar Modelo
                        </a>
                        )<br />
                      </FieldLabel>
                      <FieldLabel>
                        - Termo de inexistência de débitos. (
                        <a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Termo+de+inexistencia+de+debitos.docx">
                          Baixar Modelo
                        </a>
                        )<br />
                      </FieldLabel>
                      <FieldLabel>
                        - Distrato locador imobiliária e inexistência de débitos. (
                        <a href="https://4seg-documents-analysis.s3.amazonaws.com/Modelos+Cancelamento/Distrato+locador+imobiliaria+e+inexistencia+de+debitos.docx">
                          Baixar Modelo
                        </a>
                        )<br />
                      </FieldLabel>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Accordion.Content>
            </Accordion>
          )}

          <FileUploader
            callback={(data: any) => callbackFilesAttachment(data)}
            registerId={bondInsuranceId}
            tableName={tableName}
            controlSubmitFiles={flagSubmitAttachments}
            filesAttachs={attachedLinkFiles}
            fileIdentifiers={fileIdentifiers}
            brokerOnly={brokerOnly}
            disabled={disabled}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={() => setAttachFilesModal(false)}>
          <Icon name="remove" /> Fechar {disabled ? 'anexos' : 'sem fazer upload'}
        </Button>
        {!disabled && (
          <AuthorizationContainer action={ActionEnum.upload} feature={FeatureEnum.files}>
            <Button color="green" onClick={() => setFlagSubmitAttachments(true)}>
              <Icon name="checkmark" />
              Fazer upload dos arquivos anexados
            </Button>
          </AuthorizationContainer>
        )}
      </Modal.Actions>
    </Modal>
  );
};
export default ModalAttachFiles;
