export const CancellationInitialValues = {
  createdAt: new Date(),
  cancellationReason: '',
  observations: '',
  userId: '',
  bondInsuranceId: '',
  proposalFiles: [],
  fieldName: '',
  notificationEmails: ['']
}

