import styled, { css } from "styled-components";

type CardProps = {
  isSelected: boolean;
};

export const Wrapper = styled.div`
  display: flex;
  width:100%;
  justify-content: space-around;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #e0e0e0;
  margin-bottom: 20px;

  td {
    padding: 10px;
    border: 1px solid #e0e0e0;
    text-align: left;
  }

  th {
    padding: 10px;
    border: 1px solid #e0e0e0;
    text-align: center;
    font-weight: 600;
  }

`

export const Card = styled.div<CardProps>`
  ${({ isSelected }) => css`
    margin: 15px;
    background: white;
    display: flex;
    flex-direction: column;
    border: none;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px 4px #3297fd;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    cursor: pointer;
    h2 {
      font-size: 15px;
    }

    ${!isSelected &&
    css`
      &::after {
        -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      }

      &:hover {
        -webkit-transform: scale(1.05, 1.05);
        transform: scale(1.05, 1.05);
        &::after {
          opacity: 1;
        }
      }
      box-shadow: 0 0 10px 4px #d0d0d0;
      &:hover {
        box-shadow: 0 0 10px 4px #d0d0ff;
        -webkit-transform: scale(1.05, 1.05);
        transform: scale(1.05, 1.05);
      }
    `}
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 25px;
  margin-top: -20px;
`;
export const Input = styled.input`
  width: 10px;
  transform: scale(2);
  cursor: pointer;
`;
