import React, { useEffect, useState } from 'react';
import EditCapitalizationModal from './edit-capitalization-modal/Modal';
import {
  Dimmer,
  Table,
  Segment,
  Loader,
  Popup,
  Menu,
  Icon,
  Grid,
  Button,
  Input,
  Dropdown,
  Label,
} from 'semantic-ui-react';
import { iRootDispatch, iRootState } from '../../../store';
import { connect } from 'react-redux';
import { AvailableBrokersAndEstatesState } from '../../../store/types/temp-types';
import CustomDatePicker from '../../../components/CustomDatePicker';
import AppMaskedInput from '../../../components/MaskedInput';
import { maskCpfOrCnpj } from '../../../services/masks';
import DetailModal from './DetailModal';
import styled from 'styled-components';
import { translateStatusCapitalization } from '../util';
import ModalAttachFiles from '../../../components/ModalAttachFiles';
import { UserRolesEnum } from '../../../enums/user-roles.enum';
import moment from 'moment-timezone';
import { getCapitalizations } from '../../../services/capitalization';
import { ListCapitalizationInterface } from '../ListCapitalizationInitial.interface';
import { CapitalizationStatusEnum } from '../enums/capitalization-status.enum';
import { DropdownOptions } from './create-capitalization-modal/Steps/CapitalizationInfoStep';
import { CustoDropDownItemProps } from '../../../components/types';
import { FileIdentifiersCapitalizationType } from '../types/fileidentifiers.capitalization.type';
import FollowUpModal from '../../../components/GeneralFollowUpModal';
import { PurposesEnum } from '../../../enums/purposes.enum';
import { sendMessage } from '../../../services/generalfollowup';
import AuthorizationContainer from '../../../components/AuthorizationContainer';
import { ActionEnum } from '../../../enums/authz-action.enum';
import { FeatureEnum } from '../../../enums/authz-feature.enum';
import { getUsers } from '../../../services/broker';
import { UsersToCustomDropDownOptions } from '../../../util';
import { useQuery } from '@tanstack/react-query';
import { FollowUpType } from '../../../types/FollowUp';
import { get } from '../../../services/storage';

const ClickableTableCell = styled(Table.Cell)`
  cursor: pointer;
  position: relative;
`;

const ClickableTableRow = styled(Table.Row)`
  :hover {
    background-color: #f0f0f0;
  }
`;

export const FieldLabel = styled.label<{ paddingTop?: boolean }>`
  font-weight: bold;
  padding-top: ${props => (props.paddingTop ? '14px' : '0px')};
  text-transform: uppercase;
  margin-left: 5px;
`;

export const FieldValue = styled.p`
  padding-left: 5px;
`;

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

const CapitalizationTable = (props: any) => {
  const { availableBrokersAndEstates, user, insurers } = props;
  const estates: CustoDropDownItemProps[] = availableBrokersAndEstates.estates;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [start, setStart] = useState<Date | null>();
  const [end, setEnd] = useState<Date | null>();
  const [selectedEstate, setSelectedEstate] = useState<any>();
  const [selectedInsurer, setSelectedInsurer] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [address, setAddress] = useState<string>('');
  const [tenantName, setTenantName] = useState<string>('');
  const [cleanInputs, setCleanInputs] = useState(false);
  const [selectedCapitalization, setSelectedCapitalization] = useState<any>(false);
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [idFollowUp, setIdFollowUp] = useState<string>('');
  const [bondInsuranceIdOpen, setBondInsuranceIdOpen] = useState<string>('');
  const [statusOptions, setStatusOptions] = useState<DropdownOptions[]>([]);
  const [openFollowUp, setOpenFollowUp] = useState<boolean>(false);
  const [selectedAccountManager, setSelectedAccountManager] = useState<string | null>(
    null
  );
  const [lastPage, setLastPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const handleOpen = () => {
    setOpenFollowUp(true);
  };

  const handleClose = () => {
    setOpenFollowUp(false);
  };

  const clearFilters = () => {
    if (
      user.role !== UserRolesEnum.estateAdmin &&
      user.role !== UserRolesEnum.estateUser
    ) {
      setSelectedEstate(null);
    }
    setSelectedInsurer(null);
    setStatus(null);
    setAddress('');
    setTenantName('');
    setStart(null);
    setEnd(null);
    setSelectedAccountManager(null);
    setCleanInputs(!cleanInputs);
    setCurrentPage(1);
  };


  const getAccountManagers = async () => {
    const result = await getUsers(props?.user?.broker?.id, [{ isDropdown: true}]);
    return UsersToCustomDropDownOptions(false, result.map(user => ({
      id: user.id,
      name: user.name
    })));
  };

  const {
    data: accountManagerOptions,
    isFetching: isAccountManagerLoading,
  } = useQuery(
    ['getAccountManagers'],
    getAccountManagers,
    {
      keepPreviousData: true,
      enabled: !!props?.user?.broker?.id,
    }
  );

  useEffect(() => {
    const statusDropdownOptions = Object.keys(CapitalizationStatusEnum).map(
      (key, index) => ({
        index,
        value: key,
        text: translateStatusCapitalization(
          (CapitalizationStatusEnum as any)[key] as string
        )
      })
    );
    setStatusOptions(statusDropdownOptions);
  }, []);

  useEffect(() => {
    setOpenFollowUp(!!idFollowUp.length);
  }, [idFollowUp]);

  useEffect(() => {
    if (!!estates.length) {
      if (
        user.role === UserRolesEnum.estateAdmin ||
        user.role === UserRolesEnum.estateUser
      ) {
        setSelectedEstate(estates[0].value);
      }
    }
  }, [user, estates]);

  const handleNewChangeEstate = (estateKey: any) => {
    const selected = estates.find(c => c.value.id === estateKey.id);
    if (!selected) {
      setSelectedEstate(0);
      return;
    }
    setSelectedEstate(estateKey);
  };

  const fetchCapitalizations = async () => {
    const userId = get('uid');
    const filters: any = {
      page: currentPage,
      estateId:
        estates && estates.length > 0
          ? estates.length > 1
            ? selectedEstate
              ? selectedEstate.id
              : null
            : estates[0].value.id
          : null,
      insurerId: selectedInsurer || null,
      status: status || null,
      start: start ? moment(start).format('YYYY-MM-DD') : null,
      end: end ? moment(end).format('YYYY-MM-DD') : null,
      address: address || null,
      name: tenantName || null,
      onlyUnread: false,
      userId,
      accountManager: selectedAccountManager || null
    };
    const response = await getCapitalizations(filters);

    if(currentPage === 1) setTotal(response.count);

    return {
      data: response.data,
      count: currentPage === 1 ? response.count : total
    };
  };

  const {
    data: capitalizationsData,
    isFetching: isLoading,
    refetch: refetchCapitalizations,
  } = useQuery(
    ['getCapitalizations', cleanInputs, currentPage],
    fetchCapitalizations,
    {
      enabled: !!estates.length,
      keepPreviousData: true,
      refetchOnMount: 'always',
    }
  );

  const { data = [], count = 0 } = capitalizationsData || {};

  const openDetailModal = (capitalization: any) => {
    setSelectedCapitalization(capitalization);
    setOpenDetail(true);
  };

  const changeDate = (date: Date, type: string) => {
    if (type === 'start') {
      setStart(date);
    } else {
      setEnd(date);
    }
  };

  async function callback(fileTypes: string[], capitalization_id: string) {
    if (fileTypes) {
      let message = 'Foram anexados os seguintes arquivos: ';
      message += `${fileTypes.join(', ')}.`;
      try {
        await sendMessage(
          capitalization_id,
          user.id,
          message,
          user.name,
          'CAPITALIZATION',
          null,
          null,
          capitalization_id,
          false
        );
      } catch (err) {
        console.log(err);
      }
    }
  }

  const handleNewChangeAccountManager = (accountManagerKey: any) => {
    if (!!accountManagerKey.length) {
      setSelectedAccountManager(accountManagerKey);
    } else {
      setSelectedAccountManager(null);
    }
  };

  const handleCalcUnread = (followup: FollowUpType[], idUser: string) => {
    if (idUser === '') return 0;

    if (!followup || followup.length === 0) return 0;

    let count = 0;
    followup.forEach(item => {
      const dontCount = user.role.includes('ESTATE') && item.isInternal;
      if (item.viewedBy && item.viewedBy?.length > 0 && !dontCount) {
        if (!item.viewedBy.includes(idUser)) count++;
      }
    });
    return count;
  };

  useEffect(() => {
    setLastPage(Math.ceil(count / 10));
  }, [count]);

  return (
    <>
      {openFollowUp && (
        <FollowUpModal
          isOpen={openFollowUp}
          onOpen={handleOpen}
          onClose={handleClose}
          header="Follow Up"
          id={bondInsuranceIdOpen}
          context={'CAPITALIZATION'}
          user={user}
        />
      )}

      <Grid style={{ margin: '10px 0' }}>
        <Grid.Row verticalAlign={'middle'} columns={'equal'}>
          <Grid.Column>
            <FieldLabel>IMOBILIÁRIA:</FieldLabel>
            <Dropdown
              style={{ width: '100%' }}
              value={selectedEstate}
              name={`estate`}
              defaultValue={
                availableBrokersAndEstates.estates.length === 1
                  ? availableBrokersAndEstates.estates[0].value
                  : '' || ''
              }
              disabled={isLoading || availableBrokersAndEstates.estates.length === 1}
              options={availableBrokersAndEstates.estates || []}
              placeholder={'Selecionar...'}
              search
              selection
              onChange={(e, { value }) => handleNewChangeEstate(value)}
              clearable
            />
          </Grid.Column>
          <Grid.Column>
            <FieldLabel>SEGURADORA</FieldLabel>
            <div style={{ width: '100%' }}>
              <Dropdown
                style={{ width: '100%' }}
                value={selectedInsurer}
                name={`insurer`}
                disabled={isLoading}
                options={insurers || []}
                placeholder={'Selecionar...'}
                search
                selection
                clearable
                onChange={(e, { value }) => setSelectedInsurer(value)}
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <FieldLabel>STATUS:</FieldLabel>
            <div style={{ width: '100%' }}>
              <Dropdown
                style={{ width: '100%' }}
                value={status}
                name={`status`}
                disabled={isLoading}
                options={statusOptions}
                placeholder={'Selecionar...'}
                search
                selection
                onChange={(e, { value }) => setStatus(value)}
                clearable
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <FieldLabel>GESTOR DE CONTAS:</FieldLabel>
            <div style={{ width: '100%' }}>
              <Dropdown
                style={{ width: '100%' }}
                value={selectedAccountManager || undefined}
                name={`accountManager`}
                options={accountManagerOptions}
                disabled={isLoading || isAccountManagerLoading}
                loading={isAccountManagerLoading}
                placeholder={'Selecionar...'}
                search
                selection
                onChange={(e, { value }) => handleNewChangeAccountManager(value)}
                clearable
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={2}>
            <FieldLabel>DATA INICIAL:</FieldLabel>
            <CustomDatePicker
              customInput={
                <AppMaskedInput
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/
                  ]}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              disabled={isLoading}
              max={end?.toLocaleDateString('pt-BR')}
              onChange={(e: Date) => changeDate(e, 'start')}
              value={start?.toLocaleDateString('pt-BR')}
            />
          </Grid.Column>
          <Grid.Column width={2}>
            <FieldLabel>DATA FINAL:</FieldLabel>
            <CustomDatePicker
              customInput={
                <AppMaskedInput
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/
                  ]}
                />
              }
              dateFormat={'dd/MM/yyyy'}
              disabled={isLoading}
              mine={start?.toLocaleDateString('pt-BR')}
              onChange={(e: Date) => changeDate(e, 'end')}
              value={end?.toLocaleDateString('pt-BR')}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <FieldLabel>INQUILINO</FieldLabel>
            <Input
              placeholder="Nome do inquilino"
              value={tenantName}
              disabled={isLoading}
              onChange={e => setTenantName(e.target.value)}
              fluid
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <FieldLabel>ENDEREÇO:</FieldLabel>
            <Input
              placeholder="Endereço"
              value={address}
              disabled={isLoading}
              onChange={e => setAddress(e.target.value)}
              fluid
            />
          </Grid.Column>
          <Grid.Column
            width={4}
            verticalAlign={'bottom'}
            textAlign={'right'}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <Button fluid onClick={clearFilters} content={'Limpar'} />

            <Button
              primary
              fluid
              disabled={isLoading}
              onClick={() => {
                setCurrentPage(1)
                refetchCapitalizations()}}
              content={'Pesquisar'}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Table style={{ margin: '0 25px 20px' }} celled>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell textAlign={'center'}>Solicitado em</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>Seguradora</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>Imobiliária</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>Inquilino</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>Endereço</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>Status</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>Ações</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Dimmer.Dimmable as={Table.Body} dimmed={isLoading}>
          {isLoading && (
            <Dimmer active blurring>
              <Loader />
            </Dimmer>
          )}
          {data && data.length > 0 ? (
            <>
              {data.map((item: ListCapitalizationInterface) => {
                const unread = handleCalcUnread(item.followup ?? [], user.id ?? '');
                const {
                  id,
                  createdAt,
                  insurer,
                  estate,
                  status,
                  capitalizationTenant,
                  capitalizationProperty
                } = item;

                const fullAddress = `${capitalizationProperty.street}, ${
                  capitalizationProperty.number
                }${
                  capitalizationProperty.complement
                    ? ` ${capitalizationProperty.complement}`
                    : ''
                } - ${capitalizationProperty.district} - ${
                  capitalizationProperty.city
                } - ${capitalizationProperty.state}`;
                const address = `${capitalizationProperty.street}, ${
                  capitalizationProperty.number
                }${
                  capitalizationProperty.complement
                    ? ` ${capitalizationProperty.complement}`
                    : ''
                }`;
                const popupAddress = () => (
                  <>
                    <span>{fullAddress}</span>
                  </>
                );
                const createdAtFormatted = moment(createdAt)
                  .format(`DD/MM/YYYY HH:mm`)
                  .split(' ');
                return (
                  <>
                    <ClickableTableRow key={id!}>
                      <ClickableTableCell
                        onClick={() => openDetailModal(item)}
                        textAlign={'center'}
                        collapsing
                      >
                        {createdAtFormatted[0]} <br /> {createdAtFormatted[1]}
                      </ClickableTableCell>
                      <ClickableTableCell
                        onClick={() => openDetailModal(item)}
                        textAlign={'center'}
                        width={2}
                        style={{
                          textOverflow: 'ellipsis',
                          wordWrap: 'nowrap'
                        }}
                      >
                        {insurer?.name ?? (
                          <span style={{ color: '#ccc' }}>Não atribuído</span>
                        )}
                      </ClickableTableCell>
                      <ClickableTableCell
                        onClick={() => openDetailModal(item)}
                        textAlign={'center'}
                      >
                        {estate?.name}
                      </ClickableTableCell>
                      <ClickableTableCell
                        onClick={() => openDetailModal(item)}
                        textAlign={'center'}
                      >
                        {capitalizationTenant.name}
                        <br />
                        <span style={{ color: '#777' }}>
                          ({maskCpfOrCnpj(capitalizationTenant.document)})
                        </span>
                      </ClickableTableCell>
                      <ClickableTableCell
                        onClick={() => openDetailModal(item)}
                        style={{ cursor: 'default' }}
                        textAlign={'center'}
                      >
                        <Popup
                          content={popupAddress}
                          as={Segment}
                          style={{ border: '2px solid #dfdfdf' }}
                          position={'left center'}
                          trigger={
                            <>
                              <span style={{ cursor: 'help' }}>{address}</span>
                              <br />
                              <span>
                                {item.locationType === PurposesEnum.residential
                                  ? '(Residêncial)'
                                  : '(Comercial)'}
                              </span>
                            </>
                          }
                        />
                      </ClickableTableCell>
                      <ClickableTableCell
                        onClick={() => openDetailModal(item)}
                        textAlign={'center'}
                      >
                        {translateStatusCapitalization(status)}
                      </ClickableTableCell>

                      <Table.Cell width={1}>
                        <Menu compact>
                          <Menu.Item>
                            <Button
                              primary
                              icon="chat"
                              onClick={() => {
                                setIdFollowUp(id);
                                setBondInsuranceIdOpen(id);
                                setOpenFollowUp(true);
                              }}
                            />
                            {unread !== 0 && (
                              <Label color="red" circular floating>
                                {unread}
                              </Label>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            <ModalAttachFiles
                              message={`Arquivos anexos para capitalização`}
                              trigger={<Button color="teal" icon="attach" />}
                              bondInsuranceId={id}
                              tableName={'capitalization'}
                              fileIdentifiers={FileIdentifiersCapitalizationType}
                              callback={data => callback(data, id)}
                              brokerOnly={true}
                            />
                          </Menu.Item>
                          <AuthorizationContainer
                            action={ActionEnum.update}
                            feature={FeatureEnum.captalizations}
                          >
                            <Menu.Item>
                              <EditCapitalizationModal
                                capitalization={item}
                                trigger={
                                  <Button color="yellow" icon="pencil alternate" />
                                }
                                insurers={insurers}
                              />
                            </Menu.Item>
                          </AuthorizationContainer>
                        </Menu>
                      </Table.Cell>
                    </ClickableTableRow>
                  </>
                );
              })}
            </>
          ) : (
            <Table.Row>
              <Table.Cell colSpan={9} textAlign={'center'}>
                <span>Nenhum registro encontrado</span>
              </Table.Cell>
            </Table.Row>
          )}
        </Dimmer.Dimmable>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan={9} textAlign={'right'}>
              {!isLoading && (
                <Menu floated="right" pagination>
                  <Menu.Item>
                    <p>{`${currentPage * 10 - 9} - ${
                      count < currentPage * 10 ? count : currentPage * 10
                    } de ${count}`}</p>
                  </Menu.Item>
                  <Menu.Item
                    disabled={currentPage === 1}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(1)}
                  >
                    <Icon name="angle double left" />
                  </Menu.Item>
                  <Menu.Item
                    disabled={currentPage === 1}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item as="a">{currentPage}</Menu.Item>
                  <Menu.Item
                    disabled={currentPage === lastPage || data.length < 10}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                  <Menu.Item as="a" icon>
                    <Icon
                      disabled={currentPage === lastPage || data.length < 10}
                      name="angle double right"
                      onClick={() => setCurrentPage(lastPage)}
                    />
                  </Menu.Item>
                </Menu>
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <DetailModal
        open={openDetail}
        capitalization={selectedCapitalization}
        close={() => setOpenDetail(false)}
      />
    </>
  );
};
export default connect(mapState, mapDispatch)(CapitalizationTable);
