import Swal from 'sweetalert2';
import './styles.css';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Header, Icon, Modal, Step, Loader, Dimmer } from 'semantic-ui-react';
import { PortoModalHireProposalProps } from '../../../types/ModalHireProposalProps';
import { Formik } from 'formik';
import { post, put } from '../../../../../../../services/request';
import { get } from '../../../../../../../services/storage';
import {
  dangerNotification,
  successNotification,
  warningNotification
} from '../../../../../../../services/notification';
import ContractDateForm from '../Steps/ContractDatesStep';
import { FormatDateYMD } from '../../../../../../../services/date';
import { AxiosError } from 'axios';
import withReactContent from 'sweetalert2-react-content';
import { PayloadPortoSubmitRequestInterface } from './interfaces/SubmitEssencialHirePorto.interface';
import { PortoTradicionalHireInitialValues } from './formsInitialValues';
import { FormatCurrency, monthDiff } from '../../../../../../../util';
import SurtyLessorForm from '../Steps/LessorFormStep';
import { isCnpj } from 'validator-brazil';
import { validateCNPJ, validateCPF } from '../../../../../../../services/validators';
import { hasPermission } from '../../../../../../../components/AuthorizationContainer';
import { ActionEnum } from '../../../../../../../enums/authz-action.enum';
import { FeatureEnum } from '../../../../../../../enums/authz-feature.enum';

const HirePortoTradicionalModal = ({
  analysis,
  estate,
  bondInsurance,
  callback,
  open,
  cleanIdReferences,
  setOpen
}: PortoModalHireProposalProps) => {
  enum stepsEnum {
    CONTRACT,
    LESSOR_INFO
  }

  type ModalContentType = {
    children: React.ReactNode;
    values: PayloadPortoSubmitRequestInterface;
    onSubmit?: Function | undefined;
  };

  const [openHireProposalModal, setOpenHireProposalModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeStep, setactiveStep] = useState<stepsEnum>(stepsEnum.CONTRACT);

  const [userCanContract, setUserCanContract] = useState(false);

  useEffect(() => {
    const has = hasPermission(ActionEnum.contract, FeatureEnum.analysis);
    setUserCanContract(has);
  }, []);

  const MySwal = withReactContent(Swal);

  const AcceptTerms = useCallback(
    () =>
      MySwal.fire({
        title: `Deseja prosseguir com a contratação em <b>${analysis.insurer.name}</b> ?`,
        text: 'Importante: A cláusula deverá ser inserida no Contrato de Locação e os dados informados para essa contratação deverão estar inseridos e de acordo com o Contrato de Locação.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, prosseguir para a contratação!',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.isConfirmed) {
          (async () => {
            const { value: checked } = await MySwal.fire({
              title: 'Declaro que: ',
              html: `<div class="row"><input id="swal-input1" type="checkbox" class="checkbox"> <label for="swal-input1">Os dados informados para essa contratação estão de acordo e inseridos no Contrato de Locação; Qualquer alteração realizada nesta etapa, poderá afetar o orçamento realizado.</label> </div>
            <div class="row"><input id="swal-input2" type="checkbox" class="checkbox"> <label for="swal-input2">A Cláusula foi inserida no Contrato de Locação. </label> </div>
            <div class="row"><input id="swal-input4" type="checkbox" class="checkbox"> <label for="swal-input4"> Declaro que essa locação não tem débitos em aberto até esta data. </label> </div>`,
              focusConfirm: false,
              confirmButtonText: 'Ciente e prosseguir',
              denyButtonText: 'Cancelar',
              preConfirm: () => {
                return [
                  (document.getElementById('swal-input1') as HTMLInputElement).checked
                    ? 'true'
                    : 'false',
                  (document.getElementById('swal-input2') as HTMLInputElement)?.checked
                    ? 'true'
                    : 'false',
                  (document.getElementById('swal-input3') as HTMLInputElement)?.checked
                    ? 'true'
                    : 'false',
                  (document.getElementById('swal-input4') as HTMLInputElement)?.checked
                    ? 'true'
                    : 'false'
                ];
              }
            });
            console.log(checked);
            if (
              Array.isArray(checked) &&
              checked[0] === 'true' &&
              checked[1] === 'true' &&
              checked[3] === 'true'
            ) {
              setOpenHireProposalModal(true);
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Para continuar, deve aceitar os termos!'
              });
            }
          })();
        }
      }),
    [MySwal, analysis.insurer.name]
  );

  const NewValueAlert = useCallback(
    (oldValue, newValue, installments) =>
      MySwal.fire({
        title: `Atenção: Valor alterado de R$${oldValue.replace(
          '.',
          ','
        )} para ${installments} parcelas de R$${FormatCurrency(newValue)}!`,
        text: 'Importante: Por determinação da Seguradora Porto. O valor da parcela foi alterado devido ajuste das datas de vigência.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Desejo continuar com a contratação!',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.isConfirmed) {
          setactiveStep(activeStep + 1);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Para continuar, deve aceitar os termos!'
          });
        }
      }),
    [MySwal, activeStep]
  );

  useEffect(() => {
    if (open) {
      cleanIdReferences && cleanIdReferences();
      AcceptTerms();
    }
  }, [AcceptTerms, cleanIdReferences, open]);

  useEffect(() => {
    setOpen && setOpen(openHireProposalModal);
  }, [openHireProposalModal, setOpen]);

  function handleDisposeHireProposal() {
    setactiveStep(stepsEnum.CONTRACT);
    setOpenHireProposalModal(false);
  }

  function validateNames(name: string) {
    //minimo de 5 e máximo de 60 caracteres, sendo necessário no mínimo duas palavras separadas por um espaço
    // const regex = /^[a-zA-ZÀ-ú]{2,}(?: [a-zA-ZÀ-ú]+){1,}$/;
    const maxcharregex = /^.{5,60}$/;
    return maxcharregex.test(name);
    // return regex.test(name) && maxcharregex.test(name);
  }

  function validateEmail(email: string) {
    /// email validation
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  }

  function validateCellPhone(phone: string) {
    // onlynumbres with 11 digits
    const onlyNumbers = phone.replace(/\D/g, '');
    const regex = /^[0-9]{11}$/;
    return regex.test(onlyNumbers);
  }

  function validatePhone(phone: string) {
    // onlynumbres with 10 digits
    const onlyNumbers = phone.replace(/\D/g, '');
    const regex = /^[0-9]{10}$/;
    return regex.test(onlyNumbers);
  }

  function validateCpfCnpj(cpfCnpj: string) {
    const onlyNumbers = cpfCnpj.replace(/\D/g, '');
    if (onlyNumbers.length === 11) {
      return validateCPF(onlyNumbers);
    } else if (onlyNumbers.length === 14) {
      return validateCNPJ(onlyNumbers);
    }
    return false;
  }

  async function updateContract(values: PayloadPortoSubmitRequestInterface) {
    try {
      setLoading(true);
      const response = await put(
        '/bond-insurance/proposals/porto-tradicional/update-contract',
        {
          analysisId: analysis.id,
          contract: values.contract
        }
      );
      successNotification('Sucesso!', 'Ajuste de contrato realizado com sucesso!');
      if (
        response.value.replace(/\D/g, '') !== String(analysis.value).replace(/\D/g, '')
      ) {
        NewValueAlert(
          analysis.value,
          Number(response.value.replace(',', '.')),
          response.installments
        );
      } else {
        setactiveStep(activeStep + 1);
      }
      return;
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status) {
        if (err.response.status === 405) {
          dangerNotification(
            'Atenção!',
            'Essa alteração não foi aceita pela Porto. Verifique as datas. Caso erro persista, entre em contato com seu Gestor de Contas. '
          );
          return null;
        }
        if (err.response.status === 406) {
          dangerNotification(
            'Erro!',
            'Ocorreu um erro ao realizar o ajuste das datas, por favor, tente novamente. Caso erro persista, entre em contato com seu Gestor de Contas. '
          );
          return null;
        }
      }
      dangerNotification(
        'Erro!',
        'Caso erro persista, entre em contato com seu Gestor de Contas.'
      );
      return null;
    } finally {
      setLoading(false);
    }
  }

  async function validateContractStep(values: PayloadPortoSubmitRequestInterface) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    console.log(values);

    let error = false;
    if (!values.contract.startContractDate) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, selecione a data de início do contrato.'
      );
    }
    if (!values.contract.endContractDate) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, selecione a data de término do contrato.'
      );
    }
    if (!values.contract.startInsuranceDate) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, selecione a data de início da vigência do seguro.'
      );
    }
    if (!values.contract.endInsuranceDate) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, selecione a data de término da vigência do seguro.'
      );
    }

    if (
      FormatDateYMD(new Date(values.contract.startContractDate)) !==
      FormatDateYMD(new Date(values.contract.startInsuranceDate))
    ) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'O início do contrato e o início da vigência do seguro devem ser iguais.'
      );
    }

    if (
      FormatDateYMD(new Date(values.contract.endContractDate)) !==
      FormatDateYMD(new Date(values.contract.endInsuranceDate))
    ) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'O fim do contrato e o fim da vigência do seguro devem ser iguais.'
      );
    }

    if (new Date(values.contract.startContractDate) < today) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'O início do contrato não pode ser menor que a data atual.'
      );
    }

    if (
      new Date(values.contract.startContractDate).setHours(0, 0, 0, 0) >
      new Date(new Date().setDate(new Date().getDate() + 90)).setHours(0, 0, 0, 0)
    ) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'O início do contrato não pode ser maior que a data atual + 90 dias.'
      );
    }
    if (
      new Date(new Date(values.contract.endContractDate)).setHours(0, 0, 0, 0) <
      new Date(new Date(new Date().setMonth(new Date().getMonth() + 12))).setHours(
        0,
        0,
        0,
        0
      )
    ) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'O fim do contrato não pode ser menor que a data atual + 12 meses.'
      );
    }

    if (
      new Date(values.contract.endContractDate) >
      new Date(new Date().setFullYear(new Date().getFullYear() + 5))
    ) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'O fim do contrato não pode ser maior que a data atual + 5 anos.'
      );
    }

    if (
      new Date(values.contract.endInsuranceDate).setHours(0, 0, 0, 0) <
      new Date(
        new Date(values.contract.startInsuranceDate).setMonth(
          new Date(values.contract.startInsuranceDate).getMonth() + 12
        )
      ).setHours(0, 0, 0, 0)
    ) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'A diferença entre o início e o fim da vigência do seguro deve ser maior ou igual a 12 meses.'
      );
    }

    if (!error) {
      await updateContract(values);
    }

    return !error;
  }

  function validateLessorStep(values: PayloadPortoSubmitRequestInterface) {
    let error = false;
    const isCnpjValue = isCnpj(values.lessor.document);

    if (!values.lessor.name) {
      error = true;
      warningNotification('Campo obrigatório.', 'Por favor, preencha o nome do locador.');
    } else {
      if (!validateNames(values.lessor.name)) {
        error = true;
        warningNotification(
          'Campo inválido.',
          'Atenção, o nome do locador deve conter no mínimo 5 caracteres e no máximo 60 caracteres e ao menos 1 espaço. '
        );
      }
    }
    if (!values.lessor.document) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o documento do locador.'
      );
    } else {
      if (!validateCpfCnpj(values.lessor.document)) {
        error = true;
        warningNotification(
          'Campo inválido.',
          'Atenção, documento do locador é inválido. Verifique e tente novamente. '
        );
      }
    }
    if (!values.lessor.email) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o email do locador.'
      );
    } else {
      if (!validateEmail(values.lessor.email)) {
        error = true;
        warningNotification(
          'Campo inválido.',
          'Atenção, o email do locador é inválido. Verifique e tente novamente. '
        );
      }
    }

    if (!isCnpjValue && values.lessor.residentialPhone) {
      if (!validatePhone(values.lessor.residentialPhone)) {
        error = true;
        warningNotification(
          'Campo inválido.',
          'Atenção, o telefone residencial do locador é inválido. Verifique e tente novamente. '
        );
      }
    }

    if (isCnpjValue && values.lessor.commercialPhone) {
      if (!validatePhone(values.lessor.commercialPhone)) {
        error = true;
        warningNotification(
          'Campo inválido.',
          'Atenção, o telefone comercial do locador é inválido. Verifique e tente novamente. '
        );
      }
    }

    if (!isCnpjValue && values.lessor.cellphone) {
      if (!validateCellPhone(values.lessor.cellphone)) {
        error = true;
        warningNotification(
          'Campo inválido.',
          'Atenção, o celular do locador é inválido. Verifique e tente novamente. '
        );
      }
    }

    if (!isCnpjValue && !values.lessor.birthDate) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha a data de nascimento do locador.'
      );
    }

    if (
      !values.lessor.cellphone &&
      !values.lessor.residentialPhone &&
      !values.lessor.commercialPhone
    ) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha ao menos um telefone do locador.'
      );
    }

    if (!values.lessor.address.city) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o municipio do locador.'
      );
    }

    if (!values.lessor.address.streetType) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o tipo do logradouro.'
      );
    }

    if (!values.lessor.address.state) {
      error = true;
      warningNotification('Campo obrigatório.', 'Por favor, preencha o estado.');
    }

    if (!values.lessor.address.street) {
      error = true;
      warningNotification('Campo obrigatório.', 'Por favor, preencha a rua.');
    }
    if (!values.lessor.address.number) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o número da residência do locador.'
      );
    } else {
      if (values.lessor.address.number === 'SN') {
        if (!values.lessor.address.complement) {
          error = true;
          warningNotification(
            'Campo obrigatório.',
            'Por favor, preencha o complemento do endereço do locador.'
          );
        }
      }
    }
    if (!values.lessor.address.zipCode) {
      error = true;
      warningNotification('Campo obrigatório.', 'Por favor, preencha o CEP do locador.');
    }
    if (!values.lessor.address.district) {
      error = true;
      warningNotification(
        'Campo obrigatório.',
        'Por favor, preencha o bairro do locador.'
      );
    }
    return !error;
  }

  async function handleNextStep(
    values: PayloadPortoSubmitRequestInterface,
    onSubmit?: Function
  ): Promise<void | null> {
    switch (activeStep) {
      case stepsEnum.CONTRACT:
        await validateContractStep(values);
        return;
      case stepsEnum.LESSOR_INFO:
        return validateLessorStep(values) && onSubmit && onSubmit(values);
    }
  }

  function handleBackStep(): void {
    if (activeStep === stepsEnum.CONTRACT) {
      handleDisposeHireProposal();
      return;
    }
    setactiveStep(activeStep - 1);
  }

  const ModalContent = ({ children, values, onSubmit }: ModalContentType) => (
    <>
      <Modal.Content scrolling style={{ height: `100%` }}>
        {children}
      </Modal.Content>
      <Modal.Actions>
        <Button
          color={activeStep === stepsEnum.CONTRACT ? 'red' : 'grey'}
          onClick={() => handleBackStep()}
        >
          {activeStep === stepsEnum.CONTRACT ? (
            <>
              <Icon name={'remove'} /> Cancelar{' '}
            </>
          ) : (
            <>
              <Icon name={'arrow left'} /> Voltar{' '}
            </>
          )}
        </Button>
        <Button color="green" onClick={() => handleNextStep(values, onSubmit)}>
          {activeStep === stepsEnum.LESSOR_INFO ? (
            <>
              {' '}
              <Icon name={'checkmark'} /> Confirmar{' '}
            </>
          ) : (
            <>
              {' '}
              Próximo <Icon name={'arrow right'} />
            </>
          )}
        </Button>
      </Modal.Actions>
    </>
  );

  async function submitValues(values: PayloadPortoSubmitRequestInterface) {
    try {
      setLoading(true);
      const response = await post('/bond-insurance/proposals/hire/porto-tradicional', {
        ...values,
        analysisId: analysis.id,
        installments: monthDiff(
          new Date(values.contract.startContractDate),
          new Date(values.contract.endContractDate)
        )
      });
      console.log(response);
      successNotification('Sucesso!', 'Proposta enviada com sucesso!');
      setLoading(false);
      callback();
      handleDisposeHireProposal();
    } catch (error) {
      setLoading(false);
      const err = error as AxiosError;

      if (err.response?.status === 406) {
        warningNotification(
          'Atenção!',
          "Sua proposta será efetivada manualmente, você poderá entrontrá-la em 'Gerenciar Propostas'."
        );
        callback();
        handleDisposeHireProposal();
        return;
      }
      if (err.response?.status === 407) {
        successNotification(
          'Sucesso!',
          "Proposta enviada com sucesso! Sua apólice foi gerada e será anexada em breve. Poderá acompanhar o andamento em 'Gerenciar Apólices'."
        );
        callback();
        handleDisposeHireProposal();
        return;
      }

      if (err.response?.status === 401) {
        dangerNotification(
          'Erro!',
          'Erro interno, por favor tente novamente mais tarde.'
        );
        return;
      }

      dangerNotification(
        'Erro!',
        'Módulo indisponível, por favor tente novamente mais tarde.'
      ); // Erro desconhecido
      return;
    }
  }

  return (
    <div style={{ outline: 'none', flex: 'auto' }}>
      <Modal
        style={{ marginTop: '2rem' }}
        size="large"
        closeIcon
        open={openHireProposalModal}
        trigger={
          <Button disabled={!userCanContract} positive fluid>
            Contratar
          </Button>
        }
        onClose={() => setOpenHireProposalModal(false)}
        onOpen={AcceptTerms}
      >
        <Dimmer active={loading}>
          <Loader indeterminate>
            {activeStep === stepsEnum.CONTRACT
              ? 'Ajustando dados do contrato...'
              : 'Enviando proposta...'}
          </Loader>
        </Dimmer>
        <Header icon="file alternate outline" content="Contratação Fiança" />
        <Step.Group size="small" widths={6} style={{ margin: '0 0 20px 0' }}>
          <Step
            onClick={() => setactiveStep(stepsEnum.CONTRACT)}
            active={activeStep === stepsEnum.CONTRACT}
            title="Ajuste do contrato!"
            description=""
          />

          <Step
            onClick={() => setactiveStep(stepsEnum.LESSOR_INFO)}
            active={activeStep === stepsEnum.LESSOR_INFO}
            title="Dados do Locador"
            description=""
          />
        </Step.Group>
        <Formik
          initialValues={PortoTradicionalHireInitialValues}
          onSubmit={e => submitValues(e)}
        >
          {innerValues => {
            switch (activeStep) {
              case stepsEnum.CONTRACT:
                return (
                  <ModalContent
                    children={
                      <ContractDateForm
                        {...innerValues}
                        property={bondInsurance.property}
                        minDate={new Date()}
                      />
                    }
                    values={innerValues.values}
                  />
                );

              case stepsEnum.LESSOR_INFO:
                return (
                  <ModalContent
                    children={<SurtyLessorForm estate={estate} {...innerValues} />}
                    values={innerValues.values}
                    onSubmit={innerValues.handleSubmit}
                  />
                );

              default:
                return <div>Not Found</div>;
            }
          }}
        </Formik>
      </Modal>
    </div>
  );
};
export default HirePortoTradicionalModal;
