import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    borderColor: '#2185D0',
    backgroundColor: '#2185D0',
    borderWidth: 1,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    alignItems: 'flex-start',
    textAlign: 'left',
    flexGrow: 1,
    paddingBottom: 5,
  },
  title: {
    fontSize: 10,
    paddingHorizontal: 8,
    paddingTop: 5,
    fontWeight: 900,
    color: '#ffffff',
    fontFamily: 'Helvetica-Bold',
  },
});

const TableHeader = ({ title }: any) => (
  <View style={styles.container}>
    <Text style={styles.title}>{ title }</Text>
  </View>
);

export default TableHeader;
