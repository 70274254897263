import React from "react";
import moment from "moment-timezone";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Timeline, TimelineEvent } from "react-event-timeline";
import { get } from "../../services/storage";

export type MessageType = {
  text:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  senderId: {
    name:
      | boolean
      | React.ReactChild
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined;
  };
  createdAt: moment.MomentInput;
};

const ContainerInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Div = styled.div`
overflow-y: "scroll";
strong {
  font-weight: bold;
}
`;

const MessagesList = (props: any) => {
  const { messages } = props;
  console.log("MSG", messages);

  const userName = get("name");


  return (
    <Div>
      <Timeline>
        {messages.map((message: MessageType) => {
          return (
            <TimelineEvent
              title={message.senderId.name ? message.senderId.name : userName}
              createdAt={moment(message.createdAt).format("DD/MM/YY - HH:mm")}
              icon={<Icon name="envelope open outline" size="large" />}
              iconColor="#5C6BC0"
              style={{
                backgroundColor: "#fff",
                padding: 10,
                boxShadow: "0 0 3px 1px #5C6BC0",
                fontSize: "12px",
              }}
              iconStyle={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 2,
              }}
              contentStyle={{
                backgroundColor: "#FEFEFE",
                color: "#5C6BC0",
                fontSize: "16px",
              }}
            >
              <ReactQuill
                theme="bubble"
                value={(message.text as string) || ""}
                readOnly={true}
              />
            </TimelineEvent>
          );
        })}
      </Timeline>
    </Div>
  );
};

export default MessagesList;

{
  /* <Item.Group>
      {messages.map((message: MessageType) => {
        return (
          <Item>
            <Item.Content>
              <Item.Header>{message.text}</Item.Header>
              <ContainerInfo>
                <Item.Meta><span>{moment(message.createdAt).format('DD/MM/YY - HH:mm:ss')}</span></Item.Meta>
                <Item.Description><p>{message.senderId.name}</p></Item.Description>
              </ContainerInfo>
            </Item.Content>
          </Item>
        )
      })}
    </Item.Group> */
}
