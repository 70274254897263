import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';
import { Grid } from 'semantic-ui-react';
import { maskCnpj } from '../../services/masks';
import { EstateData } from './components/EstateData';
import { BankAccountData } from './components/BankAccountData';
import { EstateFormProps } from './UpdateEstateForm';
import { BondInsuranceData } from './components/BondInsuranceData';
import { FireInsuranceData } from './components/FireInsuranceData';
import { BrokerForUpdateEstateForm } from './types';
import { CapitalizationInsuranceData } from './components/CapitalizationInsuranceData';

const EstateContainer = styled.div`
  height: 100%;
  padding-right: 5em;
`;

const handleFormInputChange = (data: any, formProps: any) => {
  const { setFieldValue } = formProps;
  const { field } = data;
  let { value } = data;

  if (field.name === 'estate[cnpj]') {
    value = maskCnpj(value);
  }

  setFieldValue(field.name, value);
};

const UpdateEstateFormContent = (props: EstateFormProps & any) => {
  const { availableBrokersAndEstates, setFieldValue } = props;
  // Checks if exists only one available broker and get its id
  // If not, the default value is empty and the user must select the broker from dropdown options
  const selectedBroker: BrokerForUpdateEstateForm =
    availableBrokersAndEstates.brokers.length === 1
      ? {
          id: availableBrokersAndEstates.brokers[0].value.id,
          name: availableBrokersAndEstates.brokers[0].value.name
        }
      : {
          id: '',
          name: ''
        };
  // const maxProlaboreValue = (selectedBroker.minimum_gain > selectedBroker.fee ? 0.7 - selectedBroker.minimum_gain : 0.7 - selectedBroker.fee) * 100;
  useEffect(() => {
    if (selectedBroker) {
      setFieldValue('broker', selectedBroker);
    }
  }, []);

  return (
    <EstateContainer>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid>
              <EstateData {...props} handleInputChange={handleFormInputChange} />
              <FireInsuranceData {...props} handleInputChange={handleFormInputChange} />
              <CapitalizationInsuranceData {...props} handleInputChange={handleFormInputChange} />
              <BondInsuranceData {...props} handleInputChange={handleFormInputChange} />
              <BankAccountData {...props} handleInputChange={handleFormInputChange} />
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="left" width={16}>
            <Button
              text="Atualizar"
              color="green"
              size="large"
              type="submit"
              onClick={() => {
                props.onSubmit(props.values);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </EstateContainer>
  );
};

export default UpdateEstateFormContent;
