import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { LoginFormProps, FormValues } from './types';
import { iRootDispatch } from '../../store';
import { getMenuPageInfo } from '../../services/menu';
import * as Yup from 'yup';
import * as Storage from '../../services/storage';
import OAuthLoginForm from './OAuthLoginForm';
import { oAuthSignIn, redirectToCapturePage, redirectToOAuthLogin, redirectToOAuthCreate, checkUserExists, authenticateUser } from '../../services/oauth';
import { dangerNotification } from '../../services/notification';
import { CurrentPageState, CurrentToggledMenuState } from "../../store/types/temp-types";
import { UserState } from "../../types/UserState";

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateUser: (user: UserState) => dispatch.user.updateUser(user),
  updateCurrentPage: (currentPage: CurrentPageState) => dispatch.currentPage.updateCurrentPage(currentPage),
  updateCurrentToggledMenu: (state: CurrentToggledMenuState) => dispatch.currentToggledMenu.updateCurrentToggledMenu(state)
});

export type LoginProps = ReturnType<typeof mapDispatch> & RouteComponentProps & LoginFormProps;

const OAuthLogin = withFormik<LoginProps, FormValues>({
  mapPropsToValues: loginFormProps => ({
    email: loginFormProps.initialEmail || '',
    password: loginFormProps.initialPassword || ''
  }),

  validationSchema: Yup.object().shape({
    // email: Yup.string()
    //   .email("O e-mail informado não é válido")
    //   .required("Favor informar o e-mail"),
    // password: Yup.string().min(6, 'A senha deve ter no mínimo 6 dígitos').required("Favor informar a senha") 
  }),

  async handleSubmit({ email, password }: FormValues, { setSubmitting }) {
    try {
      const userExists = await checkUserExists(email);

      const isAuthenticated = await authenticateUser(email, password);

      console.log(userExists, isAuthenticated);

      if (userExists && isAuthenticated) {
        redirectToOAuthCreate(email);
      } else {
        dangerNotification('Oops...', 'Email ou senha incorretos!');
        setSubmitting(false);
      }
    } catch (e) {
      console.log('LOGIN ERROR: ', e);
      dangerNotification('Oops...', 'Erro ao solicitar login, verifique seu e-mail e senha!');
      setSubmitting(false);
    }
  }
})(OAuthLoginForm)

export default connect(null, mapDispatch)(OAuthLogin);





    // oAuthSignIn(email, password).then(async (response) => {
    //   if (response) {
    //     redirectToOAuthLogin(email);
    //   } else {
    //     const userExists = await checkUserExists(email);

    //     const isAuthenticated = await authenticateUser(email, password);

    //     if (userExists && isAuthenticated) {
    //       redirectToOAuthCreate(email);
    //     } else {
    //       dangerNotification('Oops...', 'Email ou senha incorretos!');
    //       // redirectToCapturePage();
    //       setSubmitting(false);
    //     }
    //   }
    // }).catch((e) => {
    //   dangerNotification('Oops...', 'Você ainda não está cadastrado no 4Seg!');
    //   setSubmitting(false);
    //   redirectToCapturePage();
    // })