import React, { ReactComponentElement, ReactElement, useEffect, useState } from 'react';
import { Button, Header, Icon, Modal, Step, Loader, Dimmer } from 'semantic-ui-react';

import { Formik } from 'formik';
import ClaimEstateInfoStep from '../Steps/ClaimEstateInfoStep';
import ClaimValuesStep from '../Steps/ClaimValuesStep';
import {
  ClaimValueFormikType,
  EditClaimEstateInitialInitialValuesType
} from '../Formik/types/CreateClaimEstateInitialValuesTypes';
import {
  dangerNotification,
  successNotification,
  warningNotification
} from '../../../../../services/notification';
import { list as listInsurer } from '../../../../../services/insurer';
import { InsurerState } from '../../../../../types/InsurerState';
import { connect } from 'react-redux';
import { iRootDispatch, iRootState } from '../../../../../store';
import { AvailableBrokersAndEstatesState } from '../../../../../store/types/temp-types';
import { CustoDropDownItemProps } from '../../../../../components/types';
import { claimSubmitProps, udateClaimEstate } from '../../../../../services/claims';
import { ClaimPropertyStatusEnum } from '../../../../../enums/claim-property-status.enum';
import {
  DateToStringDate,
  FormatDateYMD,
  FormatDateYMD_UTC_IGNORE
} from '../../../../../services/date';
import { ClaimValuesTypesEnum } from '../../../enums/ClaimValues.enum';
import { UserState } from '../../../../../types/UserState';
import { getUsers } from '../../../../../services/broker';
import { useHistory } from 'react-router-dom';
import { CreateClaimEstateInitialInfo } from '../../create-claim-modal/Formik/InitialValues/CreateClaimEstateInitialInitialValues';
import { translateValuesType } from '../../../util';
import ModalInstructions from '../../ModalInstructions';
import { InsurersCodeEnum } from '../../../../../enums/insurers-code.enum';
import { get } from '../../../../../services/storage';

type ModalContentType = {
  children: React.ReactNode;
  values: EditClaimEstateInitialInitialValuesType;
};

const EditClaimEstateModal = (props: {
  availableBrokersAndEstates: any; //{ estates: CustoDropDownItemProps[] };
  trigger?: ReactElement;
  claimEstate: any;
  users: UserState[];
}) => {
  const estates: CustoDropDownItemProps[] = props.availableBrokersAndEstates.estates;
  let insurers: InsurerState[] | null = props.availableBrokersAndEstates.insurers.map(
    (insurer: any) => insurer.value
  );
  if (insurers) {
    insurers = insurers.concat({
      id: '297c0ed6-a9ea-48d3-b0da-235597ae9f7a',
      name: 'Liberty Seguros',
      code: InsurersCodeEnum.Liberty
    } as InsurerState);
  }
  const [openHireProposalModal, setOpenHireProposalModal] = useState(false);
  const [stepOfHireModal, setStepOfHireModal] = useState('claim_estate_info');
  const [registerId, setRegisterId] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [initialValues, setInitialValues] =
    useState<EditClaimEstateInitialInitialValuesType>();
  const history = useHistory();

  const [openModal, setOpenModal] = useState(false);
  const toggleOpen = () => setOpenModal(true);
  const toggleClose = () => setOpenModal(false);

  useEffect(() => {
    setRegisterId(props.claimEstate.id);
    const claimEstate = props.claimEstate;
    const condominiumFee = claimEstate.claimValues.filter(
      (cv: ClaimValueFormikType) => cv.valueType === ClaimValuesTypesEnum.condominium_fee
    );

    const energyBill = claimEstate.claimValues.filter(
      (cv: ClaimValueFormikType) => cv.valueType === ClaimValuesTypesEnum.energy_bill
    );
    const gasBill = claimEstate.claimValues.filter(
      (cv: ClaimValueFormikType) => cv.valueType === ClaimValuesTypesEnum.gas_bill
    );
    const paintingValue = claimEstate.claimValues.filter(
      (cv: ClaimValueFormikType) => cv.valueType === ClaimValuesTypesEnum.painting_value
    );
    const penalty = claimEstate.claimValues.filter(
      (cv: ClaimValueFormikType) => cv.valueType === ClaimValuesTypesEnum.penalty
    );
    const rentValue = claimEstate.claimValues.filter(
      (cv: ClaimValueFormikType) => cv.valueType === ClaimValuesTypesEnum.rent_value
    );
    const taxesValue = claimEstate.claimValues.filter(
      (cv: ClaimValueFormikType) => cv.valueType === ClaimValuesTypesEnum.taxes_value
    );
    const waterBill = claimEstate.claimValues.filter(
      (cv: ClaimValueFormikType) => cv.valueType === ClaimValuesTypesEnum.water_bill
    );
    const propertyDamageValue = claimEstate.claimValues.filter(
      (cv: ClaimValueFormikType) =>
        cv.valueType === ClaimValuesTypesEnum.property_damage_value
    );

    const fireInsuranceBill = claimEstate.claimValues.filter(
      (cv: ClaimValueFormikType) =>
        cv.valueType === ClaimValuesTypesEnum.fire_insurance_bill
    );

    //condominiumFee.lenght < 1 &&
    condominiumFee.push({
      dueDate: undefined,
      value: undefined,
      totalInstallments: undefined,
      numberInstallment: undefined,
      valueType: ClaimValuesTypesEnum.condominium_fee
    });
    // energyBill.lenght < 1 &&
    energyBill.push({
      dueDate: undefined,
      value: undefined,
      totalInstallments: undefined,
      numberInstallment: undefined,
      valueType: ClaimValuesTypesEnum.energy_bill
    });
    // gasBill.lenght < 1 &&
    gasBill.push({
      dueDate: undefined,
      value: undefined,
      totalInstallments: undefined,
      numberInstallment: undefined,
      valueType: ClaimValuesTypesEnum.gas_bill
    });
    // paintingValue.lenght < 1 &&
    paintingValue.push({
      dueDate: undefined,
      value: undefined,
      totalInstallments: undefined,
      numberInstallment: undefined,
      valueType: ClaimValuesTypesEnum.painting_value
    });
    // penalty.lenght < 1 &&
    penalty.push({
      dueDate: undefined,
      value: undefined,
      totalInstallments: undefined,
      numberInstallment: undefined,
      valueType: ClaimValuesTypesEnum.penalty
    });
    //   rentValue.lenght < 1 &&
    rentValue.push({
      dueDate: undefined,
      value: undefined,
      totalInstallments: undefined,
      numberInstallment: undefined,
      valueType: ClaimValuesTypesEnum.rent_value
    });
    //   taxesValue.lenght < 1 &&
    taxesValue.push({
      dueDate: undefined,
      value: undefined,
      totalInstallments: undefined,
      numberInstallment: undefined,
      valueType: ClaimValuesTypesEnum.taxes_value
    });
    //  waterBill.lenght < 1 &&
    waterBill.push({
      dueDate: undefined,
      value: undefined,
      totalInstallments: undefined,
      numberInstallment: undefined,
      valueType: ClaimValuesTypesEnum.water_bill
    });
    //  propertyDamageValue.lenght < 1 &&
    propertyDamageValue.push({
      dueDate: undefined,
      value: undefined,
      totalInstallments: undefined,
      numberInstallment: undefined,
      valueType: ClaimValuesTypesEnum.property_damage_value
    });

    fireInsuranceBill.push({
      dueDate: undefined,
      value: undefined,
      totalInstallments: undefined,
      numberInstallment: undefined,
      valueType: ClaimValuesTypesEnum.fire_insurance_bill
    });

    const payload: EditClaimEstateInitialInitialValuesType = {
      claimEstate: {
        id: claimEstate.id,
        createdBy: claimEstate.createdBy.id,
        estateId: claimEstate.estateId,
        policyNumber: claimEstate.policyNumber,
        processNumber: claimEstate.processNumber,
        claimCode: claimEstate.claimCode,
        responsibleId: claimEstate.responsibleId,
        insurerId: claimEstate.insurerId,
        status: claimEstate.status,
        observations: claimEstate.observations,
        notificationEmails:
          typeof claimEstate.notificationEmails === 'string'
            ? claimEstate.notificationEmails.split(';')
            : claimEstate.notificationEmails || ['']
      },
      claimTenant: {
        id: claimEstate.claimTenant.id,
        document: claimEstate.claimTenant.document,
        fullName: claimEstate.claimTenant.fullName,
        email: claimEstate.claimTenant.email,
        cellphone: claimEstate.claimTenant.cellphone
      },
      claimProperty: {
        id: claimEstate.claimProperty.id,
        country: claimEstate.claimProperty.country,
        state: claimEstate.claimProperty.state,
        city: claimEstate.claimProperty.city,
        district: claimEstate.claimProperty.district,
        street: claimEstate.claimProperty.street,
        number: claimEstate.claimProperty.number,
        complement: claimEstate.claimProperty.complement,
        zipCode: claimEstate.claimProperty.zipCode,
        deliveryKeysDate: claimEstate.claimProperty.deliveryKeysDate,
        status: claimEstate.claimProperty.status
      },
      claimValues: {
        condominiumFee: condominiumFee.sort(compareValues),
        energyBill: energyBill.sort(compareValues),
        gasBill: gasBill.sort(compareValues),
        paintingValue: paintingValue.sort(compareValues),
        penalty: penalty.sort(compareValues),
        rentValue: rentValue.sort(compareValues),
        taxesValue: taxesValue.sort(compareValues),
        waterBill: waterBill.sort(compareValues),
        propertyDamageValue: propertyDamageValue.sort(compareValues),
        fireInsuranceBill: fireInsuranceBill.sort(compareValues)
      }
    };
    setInitialValues(payload);
  }, []);

  function compareValues(a: ClaimValueFormikType, b: ClaimValueFormikType) {
    if (!a.dueDate) {
      return 1;
    }
    if (!b.dueDate) {
      return 1;
    }
    if (a.dueDate > b.dueDate) {
      return 1;
    }
    if (a.dueDate < b.dueDate) {
      return -1;
    }
    return 0;
  }

  function handleCloseModal() {
    setOpenHireProposalModal(false);
  }

  function handleOpenModal() {
    setStepOfHireModal('claim_estate_info');
    setOpenHireProposalModal(true);
  }

  // useEffect(() => {
  //   async function getInsurers() {
  //     const loadInsurers = await listInsurer({
  //       availability: "BOTH",
  //     });
  //     setInsurers(loadInsurers);
  //   }
  //   getInsurers();
  // }, []);

  async function handleNextStep(values: EditClaimEstateInitialInitialValuesType) {
    switch (stepOfHireModal) {
      case 'claim_estate_info': {
        let error = false;

        if (!values.claimEstate.estateId) {
          warningNotification('Ops...', 'Por favor, selecione a imobiliária desejada!');
          error = true;
        }

        if (
          !values.claimEstate.notificationEmails ||
          values.claimEstate.notificationEmails.length < 1 ||
          !values.claimEstate.notificationEmails[0]
        ) {
          warningNotification(
            'Ops...',
            'Por favor, insira pelo menos um email para notificação!'
          );
          error = true;
        }

        if (
          values.claimEstate.notificationEmails &&
          values.claimEstate.notificationEmails.length > 0
        ) {
          const emails = values.claimEstate.notificationEmails;
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          for (let i = 0; i < emails.length; i++) {
            if (!emailRegex.test(emails[i]) && emails[i].length !== 0) {
              dangerNotification(
                'E-mail Adicional',
                "O campo E-mail Adicional '" +
                  emails[i] +
                  "' é inválido, verifique e tente novamente."
              );
              return;
            }
          }
        }

        if (!values.claimEstate.status) {
          warningNotification(
            'Ops...',
            'Por favor, informe se o imóvel está ocupado ou desocupado!'
          );
          error = true;
        }

        if (values.claimEstate.claimCode && values.claimEstate.claimCode.length > 36) {
          warningNotification(
            'Ops...',
            'O tamanho máximo para o CÓDIGO DO SINISTRO é 36 caracteres'
          );
          error = true;
        }

        if (
          values.claimEstate.processNumber &&
          values.claimEstate.processNumber.length > 36
        ) {
          warningNotification(
            'Ops...',
            'O tamanho máximo para o Nº DO PROCESSO é 36 caracteres'
          );
          error = true;
        }

        if (!values.claimTenant.document) {
          warningNotification('Ops...', 'Por favor, informe o DOCUMENTO do locatário!');
          error = true;
        }

        if (!values.claimTenant.fullName) {
          warningNotification(
            'Ops...',
            'Por favor, informe o NOME COMPLETO do locatário!'
          );
          error = true;
        }

        if (!values.claimTenant.cellphone) {
          warningNotification('Ops...', 'Por favor, informe o TELEFONE do locatário!');
          error = true;
        }

        if (!values.claimTenant.email) {
          warningNotification('Ops...', 'Por favor, informe o EMAIL do locatário!');
          error = true;
        }

        if (
          values.claimProperty.status === ClaimPropertyStatusEnum.vacant &&
          !values.claimProperty.deliveryKeysDate
        ) {
          warningNotification(
            'Ops...',
            'Por favor, informe a DATA DE ENTREGA DAS CHAVES!'
          );
          error = true;
        }

        if (!values.claimProperty.zipCode) {
          warningNotification('Ops...', 'Por favor, informe o CEP do imóvel!');
          error = true;
        }

        if (!values.claimProperty.state) {
          warningNotification('Ops...', 'Por favor, informe o ESTADO do imóvel!');
          error = true;
        }

        if (!values.claimProperty.city) {
          warningNotification('Ops...', 'Por favor, informe a CIDADE do imóvel!');
          error = true;
        }

        if (!values.claimProperty.district) {
          warningNotification('Ops...', 'Por favor, informe o BAIRRO do imóvel!');
          error = true;
        }

        if (!values.claimProperty.street) {
          warningNotification('Ops...', 'Por favor, informe o LOGRADOURO do imóvel!');
          error = true;
        }

        if (!values.claimProperty.number) {
          warningNotification('Ops...', 'Por favor, informe o NÚMERO do imóvel!');
          error = true;
        }

        if (!error) {
          setStepOfHireModal('claim_values_info');
        }
        return;
      }
      case 'claim_values_info': {
        let error = false;
        const claimValues = values.claimValues.condominiumFee
          .concat(values.claimValues.energyBill)
          .concat(values.claimValues.gasBill)
          .concat(values.claimValues.paintingValue)
          .concat(values.claimValues.penalty)
          .concat(values.claimValues.propertyDamageValue)
          .concat(values.claimValues.rentValue)
          .concat(values.claimValues.taxesValue)
          .concat(values.claimValues.waterBill)
          .concat(values.claimValues.fireInsuranceBill);
        const cv = [];
        claimValues.forEach(e => {
          if (e.value && e.valueType) {
            if (!e.dueDate) {
              warningNotification(
                'Ops...',
                `Favor informar a data de vencimento de ${translateValuesType(
                  e.valueType
                )}`
              );
              error = true;
            }
            if (e.value <= 0) {
              warningNotification(
                'Ops...',
                `Valor de ${translateValuesType(e.valueType)}; Deve ser maior que Zero`
              );
              error = true;
            }
            if (e.value && e.valueType && e.dueDate) {
              cv.push(e);
            }
          }
        });

        if (cv.length < 1) {
          warningNotification(
            'Ops...',
            'Por favor, informe pelo menos um valor para abertura do sinistro!'
          );
          error = true;
        }

        if (!error) {
          submitClaimEstate(values);
        }
        return;
      }
    }
  }

  async function handleBackStep() {
    switch (stepOfHireModal) {
      case 'claim_estate_info': {
        handleCloseModal();
        return;
      }
      case 'claim_values_info': {
        setStepOfHireModal('claim_estate_info');
        return;
      }
    }
  }

  async function submitClaimEstate(values: EditClaimEstateInitialInitialValuesType) {
    setLoading(true);
    values.claimEstate.id = registerId;

    values.claimValues.taxesValue = values.claimValues.taxesValue.map(c => ({
      ...c,
      totalInstallments: values.claimValues.taxesValue[0].totalInstallments
    }));

    const claimValues = values.claimValues.condominiumFee
      .concat(values.claimValues.energyBill)
      .concat(values.claimValues.gasBill)
      .concat(values.claimValues.paintingValue)
      .concat(values.claimValues.penalty)
      .concat(values.claimValues.propertyDamageValue)
      .concat(values.claimValues.rentValue)
      .concat(values.claimValues.taxesValue)
      .concat(values.claimValues.waterBill)
      .concat(values.claimValues.fireInsuranceBill);
    const cv: any = [];
    claimValues.forEach(e => {
      if (e.value)
        cv.push({
          id: e.id,
          dueDate: e.dueDate,
          claimEstateId: registerId,
          numberInstallment: Number(e.numberInstallment),
          totalInstallments: Number(e.totalInstallments),
          value: Number(e.value),
          valueType: e.valueType
        } as ClaimValueFormikType);
    });

    const cvFormatted = cv.map((claimValue: ClaimValueFormikType) => {
      const format = claimValue;
      format.dueDate = FormatDateYMD_UTC_IGNORE(new Date(claimValue.dueDate!));
      return format;
    });

    const userId = get('uid');
    const payload = {
      claimEstate: {
        ...values.claimEstate,
        insurerId: values?.claimEstate?.insurerId || null
      },
      claimProperty: values.claimProperty,
      claimTenant: values.claimTenant,
      claimValues: cvFormatted,
      userId: userId
    };

    if (payload.claimEstate.notificationEmails as string[]) {
      payload.claimEstate.notificationEmails = (
        payload.claimEstate.notificationEmails as string[]
      ).join(';');
    }

    try {
      await udateClaimEstate(values.claimEstate.id, payload as claimSubmitProps);
      successNotification('Sucesso', 'Solicitação de sinistro ATUALIZADA com sucesso!');
    } catch (err) {
      dangerNotification(
        'Erro',
        'Erro ao efetuar solicitação de ATUALIZAÇÃO de sinistro'
      );
      console.log(err);
    } finally {
      setLoading(false);
      handleCloseModal();
      history.push('claim_estates');
    }
  }

  const ModalContent = ({ children, values }: ModalContentType) => (
    <>
      <Modal.Content>
        <div style={{ width: '100%' }}>
          <Step.Group widths={8}>
            <Step
              active={stepOfHireModal === 'claim_estate_info'}
              title="Informações Básicas"
              description="Informações sobre imóvel e locatário"
              icon={{ color: 'grey', name: 'home' }}
            />
            <Step
              active={stepOfHireModal === 'claim_values_info'}
              title="Sobre Valores"
              description="Datas de vencimento e valores devidos;"
              icon={{ color: 'grey', name: 'dollar sign' }}
            />
          </Step.Group>
        </div>
        {children}
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={() => handleBackStep()}>
          <Icon name="remove" />
          {stepOfHireModal === 'claim_estate_info' ? 'Cancelar' : 'Voltar'}
        </Button>
        <Button color="green" onClick={() => handleNextStep(values)}>
          <Icon name="checkmark" />{' '}
          {stepOfHireModal === 'claim_values_info' ? 'Confirmar' : 'Próximo'}
        </Button>
      </Modal.Actions>
    </>
  );

  return (
    <div>
      <Modal
        style={{ height: 'auto', width: '70vw', zIndex: 10 }}
        closeIcon
        open={openHireProposalModal}
        trigger={
          props.trigger ? (
            props.trigger
          ) : (
            <Button primary icon="hotjar">
              Novo Sinistro
            </Button>
          )
        }
        onOpen={() => handleOpenModal()}
        onClose={() => {
          handleCloseModal();
        }}
      >
        <Header style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <Icon name={'file alternate outline'} /> Atualização de Sinistro
          </div>

          <ModalInstructions
            openModal={openModal}
            toggleClose={toggleClose}
            toggleOpen={toggleOpen}
          />
        </Header>
        <Dimmer active={loading}>
          <Loader indeterminate> Comunicando solicitação... </Loader>
        </Dimmer>
        <Formik
          initialValues={initialValues!}
          validationSchema={() => true}
          onSubmit={() => true}
        >
          {innerValues => {
            switch (stepOfHireModal) {
              case 'claim_estate_info':
                return (
                  <ModalContent
                    values={innerValues.values}
                    children={
                      <ClaimEstateInfoStep
                        {...innerValues}
                        estates={estates}
                        insurers={insurers}
                        users={props.users}
                      />
                    }
                  />
                );
              case 'claim_values_info':
                return (
                  <ModalContent
                    values={innerValues.values}
                    children={<ClaimValuesStep {...innerValues} />}
                  />
                );
            }
          }}
        </Formik>
      </Modal>
    </div>
  );
};

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

export default connect(mapState, mapDispatch)(EditClaimEstateModal);
