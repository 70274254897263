import React from 'react';
import Routes from './Routes';
import Login from './pages/login/Login';
import OAuthLogin from './pages/oauth-login/OAuthLogin';
import AuthValidate from './pages/auth-validate/AuthValidate';
import RecoveryPassword from './pages/recovery-password/RecoveryPassword';
import ProtectedRoute from './components/ProtectedRoute';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PageMaintenance from './pages/MaintenancePage';

const App = () => {
  return (
    <>
      <Helmet>
        <meta name="google" content="notranslate" />
      </Helmet>
      <Route path="/" exact component={Login} />
      <Route path="/login" component={OAuthLogin} />
      <Route path="/auth-validate" component={AuthValidate} />
      <Route path="/recovery-password" component={RecoveryPassword} />
      <Route path={`/manutencao`} component={PageMaintenance} />
      <ProtectedRoute path="/admin" component={Routes} />
    </>
  );
};

export default App;
