import { PoliticallyExposedPersonEnum } from "../../../../../../enums/politically-exposed-person.enum";
import { CapitalizationStatusEnum } from "../../../../enums/capitalization-status.enum";
import { CreateCapitalizationType } from "../types/CreateCapitalizationInitialValuesTypes";

export const CreateCapitalizationInitialInfo: CreateCapitalizationType =
{
  "lessorName": "",
  "lessorDocument": "",
  "locationType": "",
  "property": {
    "country": "",
    "state": "",
    "city": "",
    "district": "",
    "street": "",
    "number": "",
    "complement": "",
    "zipCode": "",
    "propertyType": "",
    "hasComplement": false
  },
  "tenant": {
    "document": "",
    "name": "",
    "nationality": "Brasileiro(a)",
    "gender": "",
    "birthDate": "",
    "motherName": "",
    "maritalStatus": "",
    "politicallyExposedPerson": PoliticallyExposedPersonEnum.no,
    "identityDocument": "",
    "identityDocumentIssuance": "",
    "identityDocumentUf": "",
    "identityDocumentDate": "",
    "profession": "",
    "professionCode": "",
    "cellphone": "",
    "email": "",
    "companyRepresentativeName": "",
    "companyRepresentativeDocument": "",
    "companyRepresentativeCellphone": "",
    "companyRepresentativeEmail": "",
    "type": "",
    "stateRegistration": "",
    "municipalRegistration": "",
    "constitutionDate": undefined,
    "hasStateRegistration": false,
    "hasMunicipalRegistration": false,
  },
  insurerId: "",
  estateId: "",
  status:  CapitalizationStatusEnum.inIssue,
  startVigency: undefined,
  endVigency: undefined,
}
