import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px;
`;

export const FormContainer = styled.div`
  padding: 10px 24px;
`;

export const Header = styled.div`
  padding: 24px 18px;
  h3 {
    color: #393939;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const FormTitle = styled.h4`
  font-size: 14px;
  font-weight: 800;
  line-height: 19px;
  margin: 10px 0 !important;
  color: #393939;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 14px 0px;
`;

export const Line = styled.div`
  height: 1px;
  background: #364445;
`;

export const Form = styled.form`
  padding-bottom: 20px;
  label {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0em;
    width: 33%;
  }

  .ui.grid > .row {
    padding-bottom: 0px;
  }
`;

export const FormRow = styled.div`
  position: relative;
  padding: 10px 0%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const InfoDisclaimer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 18px 0;

  .disclaimer-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 70%;
    padding: 0px 12px 0 0;
  }

  .disclaimer-info {
    text-align: right;
    width: 30%;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0em;
    color: #393939;
  }

  .icon {
    font-size: 1.5rem;
    color: #a3a3a3;
  }
`;

export const InsurerGrid = styled(Grid)`
  border-radius: 4px;
  border: 1px solid #d9d9d9;

  .row {
    margin-top: 0px;
  }

  .insurer-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 22px 4px 12px;
    width: 100%;
  }

  .logo {
    width: 32px;
    height: 32px;
    aspect-ratio: auto;
    border-radius: 4px;
  }

  .insurer-name {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
  }

  .info-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    color: #2185d0;
    cursor: pointer;
  }

  small {
    font-size: 14px;
    color: #2185d0;
    font-weight: 400;
    cursor: pointer;
  }
`;

export const insurerGridStyles = {
  Header: {
    backgroundColor: '#D9D9D9',
    minHeight: 45,
    marginTop: 0
  },
  Row: {
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #D9D9D9',
    height: 68,
    paddingTop: 0
  },
  EnabledIcon: {
    width: 20,
    height: 20,
    color: '#2185D0'
  },
  DisabledIcon: {
    width: 20,
    height: 20,
    color: '#A3A3A3'
  },
  IconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 8
  },
  InsurerInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
};
