import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Button, Dimmer, Dropdown, Grid, Icon, Input, Loader } from 'semantic-ui-react';
import {
  formatAmount,
  moneyStringToNumber,
  numberToMoneyString,
  unmaskMoney
} from '../../../../../services/masks';
import { FieldLabel } from '../../../../../styles';
import * as S from './styles';
import AppMaskedInput from '../../../../../components/MaskedInput';
import { get, post } from '../../../../../services/request';
import CustomDatePicker from '../../../../../components/CustomDatePicker';
import {
  dangerNotification,
  successNotification
} from '../../../../../services/notification';
import {
  SegimobBudgetFormProps,
  SegimobCoveragesProps,
  SegimobQuotedProps
} from '../../../types/segimob';
import SaveQuotationModal from './components/SaveQuotationModal';
import { FormProps as SaveQuotationModalFormProps } from './components/SaveQuotationModal/types';
import { PushRoute } from '../../../../../util';
import { useHistory } from 'react-router-dom';
import { iRootDispatch, iRootState } from '../../../../../store';
import { CurrentPageState } from '../../../../../store/types/temp-types';
import { connect } from 'react-redux';
import AuthorizationContainer, {
  hasPermission
} from '../../../../../components/AuthorizationContainer';
import { ActionEnum } from '../../../../../enums/authz-action.enum';
import { FeatureEnum } from '../../../../../enums/authz-feature.enum';
import moment from 'moment-timezone';

const minRange = moment().subtract(45, 'days').toDate();
const maxRange = moment().add(25, 'days').toDate();

const InitInputValue = [
  {
    key: 0,
    text: 'Aguarde...',
    value: ''
  }
];

interface OccupationResponse {
  id: string;
  nome: string;
  ativo: boolean;
}

interface AssistanceResponse {
  id: number;
  nome: string;
  idTipoSeguro: string;
  padrao: boolean;
}

const SegimobBudgetForm = (props: SegimobBudgetFormProps) => {
  const history = useHistory();
  const { estate, insurerId } = props;
  const [occupations, setOccupations] = useState<any[]>(InitInputValue);
  const [maskedRentValue, setMaskedRentValue] = useState<string>();
  const [propertyType, setPropertyType] = useState('');
  const [propertyUseType, setPropertyUseType] = useState<any>('');
  const [months, setMonths] = useState<number>();
  const [startDate, setStartDate] = useState<Date | null>();
  const [loadingQuote, setLoadingQuote] = useState(false);
  const [loadingOccupations, setLoadingOccupations] = useState(false);
  const [step, setStep] = useState(1);
  const [editableCoverageCode, setEditableCoverageCode] = useState<number>(-2);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [editableCoverages, setEditableCoverages] = useState<SegimobCoveragesProps[]>();
  const [isHiring, setIsHiring] = useState<boolean>(false);
  const [quote, setQuote] = useState<SegimobQuotedProps>();
  const [quoteControl, setQuoteControl] = useState(false);
  const [isSaveQuotationModalOpen, setIsSaveQuotationModalOpen] =
    useState<boolean>(false);
  const [isSaveQuotationModalLoading, setIsSaveQuotationModalLoading] =
    useState<boolean>(false);
  const [assistances, setAssistances] = useState<any[]>(InitInputValue);
  const [assistanceId, setAssistanceId] = useState<any>(null);
  const [loadingAssistances, setLoadingAssistances] = useState(false);
  const [proLaboreCommission, setProLaboreCommission] = useState<number | undefined>(
    undefined
  );
  const [proLaboreCommissionMin, setProLaboreCommissionMin] = useState<number>(0);
  const [proLaboreCommissionMax, setProLaboreCommissionMax] = useState<number>(0);
  const [changedRentValue, setChangedRentValue] = useState(false);
  const [activeButtons, setActiveButtons] = useState(true);
  const [userCanSave, setUserCanSave] = useState<boolean>(false);

  useEffect(() => {
    const has = hasPermission(ActionEnum.save, FeatureEnum.fireinsurances);
    setUserCanSave(has);
  }, []);

  useEffect(() => {
    async function loadOccupations() {
      const url = `/fire-insurance-quotations/segimob/domains/occupations?estateId=${estate.id}&insuranceType=${propertyType}`;
      setPropertyUseType('');
      setLoadingOccupations(true);

      try {
        const data = await get(url);
        const response = data as OccupationResponse[];
        setOccupations(
          response.map(occupation => ({
            key: occupation.id,
            text: occupation.nome,
            value: occupation.id
          }))
        );
        // successNotification('Sucesso', 'Ocupações carregadas com sucesso!');
      } catch (err) {
        console.log(err);
        dangerNotification('Erro', 'Não foi possível carregar as ocupações!');
      } finally {
        setLoadingOccupations(false);
      }
    }
    propertyType && loadOccupations();
  }, [estate.id, propertyType]);

  useEffect(() => {
    async function loadAssistances() {
      setLoadingAssistances(true);
      const url = `/fire-insurance-quotations/segimob/domains/assistances?estateId=${estate.id}&occupationId=${propertyUseType}`;

      try {
        const data = await get(url);
        const response = data as AssistanceResponse[];

        const filterPadrao = response.filter(assistance => assistance.padrao === true);

        setAssistances(
          response.map(assistance => ({
            key: assistance.id,
            text: assistance.nome,
            value: assistance.id
          }))
        );

        if (filterPadrao.length === 1) {
          setAssistanceId(+filterPadrao[0].id);
        }

        // successNotification('Sucesso', 'Assistências carregadas com sucesso!');
      } catch (err) {
        console.log(err);
        dangerNotification('Erro', 'Não foi possível carregar as assistências!');
      } finally {
        setLoadingAssistances(false);
      }
    }

    estate.id && propertyUseType && loadAssistances();
  }, [estate.id, propertyUseType]);

  const monthsOptions = () => {
    return Array.from(Array(12).keys()).map(month => {
      return {
        key: month + 1,
        text: `${month + 1} ${month >= 1 ? 'Meses' : 'Mês'}`,
        value: month + 1
      };
    });
  };

  const propertyTypesList = [
    {
      key: 'RESIDENCIAL',
      text: 'Residencial',
      value: 'RESIDENCIAL'
    },
    {
      key: 'EMPRESARIAL',
      text: 'Comercial',
      value: 'EMPRESARIAL'
    }
  ];

  async function handleQuote() {
    const payload = {
      estateId: estate.id,
      startDate,
      assistanceId,
      changedRentValue,
      vigency: months,
      insuranceTypeId: propertyType,
      occupationId: propertyUseType,
      rentValue: maskedRentValue ? moneyStringToNumber(maskedRentValue) : 0,
      proLaborePercentageValue: proLaboreCommission,
      coverages: quote?.coverages
        ? quote?.coverages
        : [
            {
              id: 0,
              valorImportanciaSegurada: 0,
              flagSelecionada: true,
              valorPremioTotal: 0
            }
          ]
    };

    setLoadingQuote(true);
    try {
      const response: SegimobQuotedProps = await post(
        '/fire-insurance-quotations/segimob/quote',
        payload
      );

      if (response.coverages && response.installments) {
        const fcoverages = response.coverages.map((coverage, i) => {
          if (i === 0) {
            return {
              ...coverage,
              valorImportanciaSeguradaMasked: numberToMoneyString(
                coverage.valorImportanciaSegurada
              )
            };
          }
          return coverage;
        });

        response.coverages = fcoverages;

        setQuote(response);
        setChangedRentValue(false);
        // setAssistanceId(+response.assistanceId);
        setProLaboreCommission(+response.proLaborePercentageValue);
        setProLaboreCommissionMin(+response.proLaboreMinPercentageValue);
        setProLaboreCommissionMax(+response.proLaboreMaxPercentageValue);
        setEditableCoverages(response.coverages);
        setStep(2);
        // successNotification('Sucesso', 'Cotação realizada com sucesso!');
      } else {
        dangerNotification('Erro', 'Não foi possível realizar a cotação!');
      }
    } catch (err) {
      console.log(err);
      dangerNotification('Erro', 'Não foi possível realizar a cotação!');
    } finally {
      setLoadingQuote(false);
      setQuoteControl(false);
    }
  }

  useEffect(() => {
    if (
      startDate &&
      months &&
      propertyUseType &&
      assistanceId &&
      proLaboreCommission &&
      maskedRentValue
    ) {
      setActiveButtons(false);
    } else {
      setActiveButtons(true);
    }
  }, [
    assistanceId,
    maskedRentValue,
    months,
    proLaboreCommission,
    propertyUseType,
    startDate
  ]);

  useEffect(() => {
    quoteControl && handleQuote();
  }, [quoteControl]);

  const handleSaveQuotation = (event: SyntheticEvent, hiringFlag: boolean) => {
    event.preventDefault();
    setIsHiring(hiringFlag);
    setIsSaveQuotationModalOpen(true);
  };

  const handleCloseSaveQuotationModal = (event: SyntheticEvent) => {
    event.preventDefault();
    setIsSaveQuotationModalOpen(false);
  };

  const handleSubmitQuotationModal = async (data: SaveQuotationModalFormProps) => {
    try {
      setIsSaveQuotationModalLoading(true);

      const payload = {
        estateId: estate.id,
        insurerId,
        payload: {
          codigoExternoImovel: '',
          quantidadeParcelasSelecionada: quote?.installments?.length,
          valorParcela: quote?.installments[quote?.installments.length - 1].valorParcela,
          calculo: {
            idSeguradora: 'TOKIO_MARINE',
            idCorretora: '11',
            idImobiliaria: estate.segimobCode,
            idOcupacao: propertyUseType,
            idAssistencia: assistanceId,
            valorAluguel: moneyStringToNumber(maskedRentValue || ''),
            dataInicialVigencia: startDate,
            mesesVigencia: months,
            valorPremioTotal: quote?.totalValue,
            valorPercentualProlabore: proLaboreCommission,
            valorPercentualComissaoCorretora: quote?.brokerCommissionPercentageValue,
            flagAluguelAlterado: true,
            //valorPercentualPredio: 70,
            //valorPercentualConteudo: 30,
            coberturas: quote?.coverages
          },
          endereco: {
            cep: data.propertyAddress.zipCode,
            logradouro: data.propertyAddress.street,
            numero: data.propertyAddress.number,
            complemento: data.propertyAddress.complement,
            bairro: data.propertyAddress.neighborhood,
            uf: data.propertyAddress.state,
            cidade: data.propertyAddress.city
          },
          inquilino: {
            nome: data.tenantIdentification.name,
            cpfCnpj: data.tenantIdentification.document?.replace(/\D/g, ''),
            email: data.tenantIdentification.email,
            dataNascimento: data.tenantIdentification.birthdate,
            numeroTelefone: data.tenantIdentification.phoneNumber?.replace(/\D/g, '')
          },
          proprietario: {
            nome: data.ownerIdentification.name,
            cpfCnpj: data.ownerIdentification.document?.replace(/\D/g, ''),
            email: data.ownerIdentification.email
          }
        }
      };

      const response = await post('/fire-insurance-quotations/segimob/budget', payload);

      if (response) {
        if (isHiring) {
          try {
            const data = await post('/fire-insurance-proposals/hire/segimob', {
              fireInsuranceAnalysisId: response.id
            });

            if (data.success) {
              // successNotification(
              //   'Solicitação de Contratação',
              //   'Solicitação de contratação encaminhada com sucesso'
              // );
              PushRoute(history, props, 'fire-insurance-policies');
              return;
            }
          } catch (err) {
            console.error('Erro ao realizar contratação! ', err);
            dangerNotification(
              'Solicitação de Contratação',
              'Erro ao realizar contratação!'
            );
          }
        }
        setIsSaveQuotationModalOpen(false);
        // successNotification('Sucesso', 'Orçamento salvo com sucesso!');
        PushRoute(history, props, 'fire-insurance-budgets');
      }
    } catch (error) {
      console.log('Error', error);
      dangerNotification('Erro', 'Não foi possível realizar o orçamento!');
      setIsSaveQuotationModalLoading(false);
    } finally {
      setIsSaveQuotationModalLoading(false);
    }
  };

  const handleStartDate = (date: Date) => {
    setStartDate(date);

    if (step === 2) {
      setQuoteControl(true);
    }
  };

  const handleMonths = (value: number) => {
    setMonths(value);

    if (step === 2) {
      setQuoteControl(true);
    }
  };

  const handleAssistance = (value: number) => {
    setAssistanceId(value as number);

    if (step === 2) {
      setQuoteControl(true);
    }
  };

  const handleProLaboreCommission = () => {
    if (step === 2) {
      setQuoteControl(true);
    }
  };

  const handleRentValue = () => {
    if (step === 2) {
      setQuoteControl(true);
      setChangedRentValue(true);
    }
  };

  const w100 = {
    width: '100%'
  };

  const columnDistance = {
    marginBottom: 20
  };

  const flexCenter = {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 0,
    paddingRight: 0
  };

  return (
    <S.Container>
      <Grid style={w100} centered>
        <Grid.Column
          computer={step === 1 ? 8 : 8}
          tablet={16}
          mobile={16}
          style={flexCenter}
        >
          <Grid style={w100} centered>
            <Grid.Row>
              <Grid.Column width={16}>
                <S.Subtitle> Dados para cotação </S.Subtitle>
              </Grid.Column>
              <Grid.Column width={16}>
                <S.Hr />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column computer={8} tablet={8} mobile={16} style={columnDistance}>
                <FieldLabel>INÍCIO DE VIGÊNCIA</FieldLabel>
                <S.DatePickerWrapper>
                  <CustomDatePicker
                    placeholderText={'Data Inicial'}
                    selected={startDate ?? undefined}
                    minDate={minRange}
                    maxDate={maxRange}
                    onChange={handleStartDate}
                    customInput={
                      <AppMaskedInput
                        mask={[
                          /[0-9]/,
                          /[0-9]/,
                          '/',
                          /[0-9]/,
                          /[0-9]/,
                          '/',
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/
                        ]}
                      />
                    }
                    dateFormat={'dd/MM/yyyy'}
                  />
                </S.DatePickerWrapper>
              </Grid.Column>

              <Grid.Column computer={8} tablet={8} mobile={16} style={columnDistance}>
                <FieldLabel paddingBottom>MESES DE VIGÊNCIA</FieldLabel>
                <Dropdown
                  fluid
                  search
                  clearable
                  selection
                  placeholder={'Número de Meses'}
                  options={monthsOptions()}
                  onChange={(e, { value }) => handleMonths(value as number)}
                  value={months}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column computer={8} tablet={8} mobile={16} style={columnDistance}>
                <FieldLabel paddingBottom>TIPO DE IMÓVEL</FieldLabel>
                <Dropdown
                  fluid
                  search
                  clearable
                  selection
                  placeholder={'Tipo de Imóvel'}
                  options={propertyTypesList}
                  onChange={(e, { value }) => setPropertyType(value as string)}
                  value={propertyType}
                />
              </Grid.Column>
              <Grid.Column computer={8} tablet={8} mobile={16} style={columnDistance}>
                <FieldLabel paddingBottom>TIPO DE OCUPAÇÃO</FieldLabel>
                <Dropdown
                  fluid
                  search
                  clearable
                  selection
                  options={occupations}
                  defaultValue={'Usual'}
                  placeholder={'Tipo de Ocupação'}
                  onChange={(e, { value }) => {
                    setPropertyUseType(value);
                    setAssistanceId('');
                  }}
                  value={propertyUseType}
                  loading={loadingOccupations}
                />
              </Grid.Column>
            </Grid.Row>

            {step === 2 && (
              <Grid.Row>
                <Grid.Column computer={8} tablet={8} mobile={16} style={columnDistance}>
                  <FieldLabel paddingBottom>ASSISTÊNCIA*</FieldLabel>
                  <Dropdown
                    fluid
                    search
                    clearable
                    selection
                    placeholder={'Assistência'}
                    options={assistances}
                    onChange={(e, { value }) => handleAssistance(value as number)}
                    value={assistanceId}
                    loading={loadingAssistances}
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16} style={columnDistance}>
                  <FieldLabel paddingBottom>COMISSÃO (%)</FieldLabel>
                  <Input
                    type="number"
                    placeholder={'Comissão da Corretora'}
                    value={proLaboreCommission}
                    onChange={(e, { value }) => setProLaboreCommission(+value)}
                    min={proLaboreCommissionMin}
                    max={proLaboreCommissionMax}
                    onBlur={handleProLaboreCommission}
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            )}

            <Grid.Row>
              <Grid.Column width={16}>
                <FieldLabel paddingBottom>VALOR DO ALUGUEL</FieldLabel>
                <Input
                  fluid
                  placeholder={'Valor do Aluguel'}
                  icon={'dollar'}
                  iconPosition={'left'}
                  onChange={(e, data: any) =>
                    setMaskedRentValue(formatAmount(data.value))
                  }
                  value={maskedRentValue}
                  onBlur={handleRentValue}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>

        <Grid.Column computer={step === 1 ? 8 : 8} tablet={16} mobile={16}>
          {step === 1 ? (
            <S.PreQuoteWrapper>
              <S.PreQuoteHeader>
                <span>
                  <Icon name="shield" /> Validação da Cotação
                </span>
              </S.PreQuoteHeader>
              <Grid style={{ padding: 10, height: 'calc(100% - 60px)' }}>
                <Grid.Row>
                  <S.RequirementsWrapper>
                    <div
                      style={{ cursor: startDate ? 'default' : 'help', marginBottom: 5 }}
                    >
                      <Icon
                        name={startDate ? 'check' : 'times'}
                        color={startDate ? 'green' : 'red'}
                      />
                      <strong style={{ color: startDate ? 'green' : 'red' }}>
                        Início de Vigência
                      </strong>
                    </div>

                    <div style={{ cursor: months ? 'default' : 'help', marginBottom: 5 }}>
                      <Icon
                        name={months ? 'check' : 'times'}
                        color={months ? 'green' : 'red'}
                      />
                      <strong style={{ color: months ? 'green' : 'red' }}>
                        Meses de vigência
                      </strong>
                    </div>

                    <div
                      style={{
                        cursor: propertyType ? 'default' : 'help',
                        marginBottom: 5
                      }}
                    >
                      <Icon
                        name={propertyType ? 'check' : 'times'}
                        color={propertyType ? 'green' : 'red'}
                      />
                      <strong style={{ color: propertyType ? 'green' : 'red' }}>
                        Tipo de imóvel
                      </strong>
                    </div>

                    <div
                      style={{
                        cursor: propertyUseType ? 'default' : 'help',
                        marginBottom: 5
                      }}
                    >
                      <Icon
                        name={propertyUseType ? 'check' : 'times'}
                        color={propertyUseType ? 'green' : 'red'}
                      />
                      <strong style={{ color: propertyUseType ? 'green' : 'red' }}>
                        Tipo de ocupação
                      </strong>
                    </div>

                    <div
                      style={{
                        cursor:
                          maskedRentValue && moneyStringToNumber(maskedRentValue) > 0
                            ? 'default'
                            : 'help',
                        marginBottom: 5
                      }}
                    >
                      <Icon
                        name={
                          maskedRentValue && moneyStringToNumber(maskedRentValue) > 0
                            ? 'check'
                            : 'times'
                        }
                        color={
                          maskedRentValue && moneyStringToNumber(maskedRentValue) > 0
                            ? 'green'
                            : 'red'
                        }
                      />
                      <strong
                        style={{
                          color:
                            maskedRentValue && moneyStringToNumber(maskedRentValue) > 0
                              ? 'green'
                              : 'red'
                        }}
                      >
                        Valor do aluguel
                      </strong>
                    </div>
                  </S.RequirementsWrapper>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column style={columnDistance}>
                    <Button
                      fluid
                      positive
                      disabled={
                        !startDate ||
                        !months ||
                        !propertyType ||
                        !propertyUseType ||
                        !maskedRentValue ||
                        moneyStringToNumber(maskedRentValue) <= 0 ||
                        loadingQuote
                      }
                      loading={loadingQuote}
                      onClick={() => handleQuote()}
                      content={'Solicitar cotação'}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </S.PreQuoteWrapper>
          ) : (
            <S.PreQuoteWrapper>
              <S.PreQuoteHeader>
                <span className="left">
                  <Icon name="shield" />
                  Orçamento de Seguro
                </span>
              </S.PreQuoteHeader>
              <Grid style={{ height: 'calc(100% - 60px)', margin: 0 }}>
                <S.SecctionName> Coberturas disponíveis </S.SecctionName>
                {quote?.coverages && quote?.coverages[0] && editableCoverages && (
                  <>
                    <S.CoveragesRow>
                      <div>
                        <span className="insurance-name">
                          <Icon name="shield" className="low-opacity" /> Incêndio &nbsp;
                          <span className="required">(Obrigatório)</span>
                        </span>
                      </div>
                      <div>
                        {editableCoverageCode === -1 ? (
                          <div className="editable-coverages">
                            <Input
                              className="editable-coverages-input"
                              fluid
                              iconPosition={'left'}
                              value={numberToMoneyString(
                                +editableCoverages[0].valorImportanciaSegurada
                              )}
                              onChange={e => {
                                setEditableCoverages(
                                  editableCoverages.map((coverage, i) => {
                                    if (i === 0) {
                                      return {
                                        ...coverage,
                                        valorImportanciaSeguradaMasked: formatAmount(
                                          moneyStringToNumber(
                                            formatAmount(e.target.value)
                                          ).toFixed(2)
                                        )
                                      };
                                    }
                                    return coverage;
                                  })
                                );
                              }}
                            />
                            <Icon
                              className="icon"
                              name="check"
                              color="green"
                              onClick={() => {
                                const numberValue = unmaskMoney(
                                  editableCoverages[0].valorImportanciaSeguradaMasked
                                );

                                if (
                                  numberValue >
                                  editableCoverages[0]
                                    .valorLimiteMaximoImportanciaSegurada!
                                ) {
                                  dangerNotification(
                                    'Atenção',
                                    `O valor máximo para essa cobertura é de R$ ${numberToMoneyString(
                                      editableCoverages[0]
                                        .valorLimiteMaximoImportanciaSegurada!
                                    )}`
                                  );
                                  return;
                                }

                                if (
                                  numberValue <
                                  editableCoverages[0]
                                    .valorLimiteMinimoImportanciaSegurada!
                                ) {
                                  dangerNotification(
                                    'Atenção',
                                    `O valor mínimo para essa cobertura é de R$ ${numberToMoneyString(
                                      editableCoverages[0]
                                        .valorLimiteMinimoImportanciaSegurada!
                                    )}`
                                  );
                                  return;
                                }

                                setEditableCoverageCode(-2);
                                const valorImportanciaSegurada = moneyStringToNumber(
                                  editableCoverages[0].valorImportanciaSeguradaMasked
                                );
                                editableCoverages[0].valorImportanciaSegurada =
                                  valorImportanciaSegurada;
                                quote.coverages[0].valorImportanciaSegurada =
                                  valorImportanciaSegurada;
                                setQuoteControl(true);
                              }}
                            />
                            <Icon
                              className="icon"
                              name="close"
                              color="red"
                              onClick={() => {
                                setEditableCoverageCode(-2);
                                setEditableCoverages(quote.coverages);
                              }}
                            />
                          </div>
                        ) : (
                          <span className="insurance-value">
                            {editableCoverages[0]?.valorImportanciaSegurada
                              ? numberToMoneyString(
                                  editableCoverages[0]?.valorImportanciaSegurada
                                )
                              : 'R$ x.xxx,xx'}
                            <Icon
                              name="pencil"
                              color="black"
                              className="low-opacity pencil-icon"
                              onClick={() => setEditableCoverageCode(-1)}
                            />
                          </span>
                        )}
                      </div>
                    </S.CoveragesRow>
                    <S.CoveragesRow>
                      <div
                        className="low-opacity"
                        onClick={e => {
                          setEditableCoverages(
                            editableCoverages.map((coverage, i) => {
                              if (i !== 0) {
                                return {
                                  ...coverage,
                                  flagSelecionada: !allChecked
                                } as any;
                              }
                              return coverage;
                            })
                          );
                          setAllChecked(!allChecked);
                        }}
                      >
                        <span style={{ cursor: 'pointer' }}>
                          <input type="checkbox" checked={allChecked} />
                          Habilitar/Desabilitar todas as coberturas adicionais
                        </span>
                      </div>
                    </S.CoveragesRow>

                    {editableCoverages.slice(1)?.map((coverage, index) => (
                      <S.CoveragesRow key={index} offwhite={index % 2 === 0}>
                        <div>
                          {coverage.nomeCobertura && (
                            <span
                              className={
                                editableCoverageCode === index
                                  ? 'slice-insurance-name'
                                  : 'full-insurance-name'
                              }
                            >
                              <input
                                type="checkbox"
                                checked={coverage.flagSelecionada}
                                onClick={() => {
                                  const cvgs = editableCoverages.map((coverage, i) => {
                                    if (i === index + 1) {
                                      return {
                                        ...coverage,
                                        flagSelecionada: !coverage.flagSelecionada,
                                        valorImportanciaSegurada:
                                          quote.coverages[i].valorImportanciaSegurada
                                      } as any;
                                    }
                                    return coverage;
                                  });
                                  setEditableCoverageCode(-2);
                                  setEditableCoverages(cvgs);
                                  setQuote({
                                    ...quote,
                                    coverages: cvgs
                                  });
                                  setQuoteControl(true);
                                }}
                              />
                              {coverage.nomeCobertura[0] +
                                coverage.nomeCobertura?.toLowerCase().slice(1)}
                            </span>
                          )}
                        </div>
                        <div>
                          {editableCoverageCode !== index ? (
                            <>
                              <span className="insurance-value">
                                {numberToMoneyString(coverage.valorImportanciaSegurada)}
                              </span>
                              {coverage.flagSelecionada && (
                                <Icon
                                  name="pencil"
                                  className="low-opacity  pencil-icon"
                                  onClick={() => setEditableCoverageCode(index)}
                                />
                              )}
                            </>
                          ) : (
                            <div className="editable-coverages">
                              <Input
                                className="editable-coverages-input"
                                fluid
                                iconPosition={'left'}
                                value={numberToMoneyString(
                                  +coverage.valorImportanciaSegurada
                                )}
                                onChange={e => {
                                  setEditableCoverages(
                                    editableCoverages.map((coverage, i) => {
                                      if (i === index + 1) {
                                        return {
                                          ...coverage,
                                          valorImportanciaSeguradaMasked: formatAmount(
                                            e.target.value
                                          )
                                        };
                                      }
                                      return coverage;
                                    })
                                  );
                                }}
                              />
                              <Icon
                                className="icon"
                                name="check"
                                color="green"
                                onClick={() => {
                                  const numberValue = unmaskMoney(
                                    coverage.valorImportanciaSeguradaMasked
                                  );

                                  if (
                                    numberValue >
                                    coverage.valorLimiteMaximoImportanciaSegurada!
                                  ) {
                                    dangerNotification(
                                      'Atenção',
                                      `O valor máximo para essa cobertura é de R$ ${numberToMoneyString(
                                        coverage.valorLimiteMaximoImportanciaSegurada!
                                      )}`
                                    );
                                    return;
                                  }
                                  if (
                                    numberValue <
                                    coverage.valorLimiteMinimoImportanciaSegurada!
                                  ) {
                                    dangerNotification(
                                      'Atenção',
                                      `O valor mínimo para essa cobertura é de R$ ${numberToMoneyString(
                                        coverage.valorLimiteMinimoImportanciaSegurada!
                                      )}`
                                    );
                                    return;
                                  }
                                  setEditableCoverageCode(-2);
                                  const updatedEditableCoverages = editableCoverages.map(
                                    (coverage, i) => {
                                      if (i === index + 1) {
                                        const valorImportanciaSegurada =
                                          moneyStringToNumber(
                                            coverage.valorImportanciaSeguradaMasked
                                          );
                                        return { ...coverage, valorImportanciaSegurada };
                                      }

                                      return coverage;
                                    }
                                  );

                                  quote.coverages = updatedEditableCoverages;
                                  setEditableCoverages(updatedEditableCoverages);
                                  setQuoteControl(true);
                                }}
                              />
                              <Icon
                                className="icon"
                                name="close"
                                color="red"
                                onClick={() => {
                                  setEditableCoverageCode(-2);
                                  setEditableCoverages(quote.coverages);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </S.CoveragesRow>
                    ))}

                    <S.Summarysection>
                      <div className="budget">
                        <span className="title">Seu orçamento</span>
                        <div>
                          <h1>
                            <span>
                              {
                                quote.installments[quote.installments.length - 1]
                                  .numeroParcela
                              }
                              x&nbsp;
                            </span>
                            {numberToMoneyString(
                              quote.installments[quote.installments.length - 1]
                                .valorParcela
                            )}
                          </h1>
                          <span className="total">
                            Total: {numberToMoneyString(+quote.totalValue)}
                          </span>
                        </div>
                      </div>
                      <div className="buttons">
                        <Button
                          style={{ marginRight: 10 }}
                          primary
                          onClick={e => handleSaveQuotation(e, true)}
                          disabled={
                            !userCanSave && (activeButtons || editableCoverageCode !== -2)
                          }
                        >
                          <Icon name="clipboard check" />
                          Contratar
                        </Button>
                        <Button
                          basic
                          primary
                          icon="save"
                          onClick={e => handleSaveQuotation(e, false)}
                          disabled={
                            !userCanSave && (activeButtons || editableCoverageCode !== -2)
                          }
                        >
                          <Icon name="download" />
                          Salvar cotação
                        </Button>
                      </div>
                    </S.Summarysection>
                  </>
                )}
              </Grid>
            </S.PreQuoteWrapper>
          )}
        </Grid.Column>
      </Grid>
      {quote && (
        <>
          <Dimmer active={isSaveQuotationModalLoading || loadingQuote} inverted page>
            <Loader size="big" />
          </Dimmer>

          <SaveQuotationModal
            isOpen={isSaveQuotationModalOpen}
            onClose={handleCloseSaveQuotationModal}
            quotation={{
              rentValue: maskedRentValue ?? '',
              vigency: months ?? '',
              startDate: startDate || new Date()
            }}
            occupation={propertyUseType}
            handleSubmit={handleSubmitQuotationModal}
            quote={quote}
            isHiring={isHiring}
          />
        </>
      )}
    </S.Container>
  );
};

const mapState = () => {
  return {};
};

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateCurrentPage: (state: CurrentPageState) =>
    dispatch.currentPage.updateCurrentPage(state)
});

export default connect(mapState, mapDispatch)(SegimobBudgetForm);
