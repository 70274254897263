export enum ClaimEstatesStatusEnum {
  created = 'CREATED',
  inAnalysis = 'IN_ANALYSIS',
  pending = 'PENDING',
  inProgress = 'IN_PROGRESS',
  finished = 'FINISHED',
  debtConfession = 'DEBT_CONFESSION',
  inAccord = 'IN_ACCORD',
  monthlyIndemnity = 'MONTHLY_INDEMNITY',
  lmiLmgReached = 'LMI_LMG_REACHED'
}
