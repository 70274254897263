/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'semantic-ui-react';
import { ModalProps } from './types';
import styled from 'styled-components';

export const ConfirmationModalContent = styled(Modal.Content)`
  line-height: 1.8rem;
  div:last-child:not(:first-child){
    font-weight: bold;
  }
`;

const ConfirmationModal = (props: ModalProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const open = (): void => { setIsOpen(true); };
  const close = (): void => { setIsOpen(false); };

  const { callback, content, header, trigger, positive, negative } = props;

  const cancelButton = (<Button key="no" content="Não" onClick={close} />);
  const confirmButton = (<Button key="yes" content="Sim" {... { positive, negative }}
    onClick={(_: any): void => {
      close();
      callback();
    }}
  />);

  return (
    <Modal open={isOpen} onOpen={open} onClose={close} trigger={trigger}>
      <Modal.Header>{header}</Modal.Header>
      <ConfirmationModalContent>{content}</ConfirmationModalContent>
      <Modal.Actions>{[cancelButton, confirmButton]}</Modal.Actions>
    </Modal>
  );
};
export default connect()(ConfirmationModal);
