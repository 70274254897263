import React, { useState } from 'react';
import moment from 'moment';
import { Button, Grid, Icon, Modal } from 'semantic-ui-react';

import { FieldLabel } from '../../../../../../../styles';
import { turnUFIntoEstate } from '../../../../../util';
import { useHistory } from 'react-router-dom';
import {
  maskCpfOrCnpj,
  maskPhoneNumber,
  maskZipCode,
  numberToMoneyString
} from '../../../../../../../services/masks';
import {
  CoveragesRow,
  Disclaimer,
  DisclaimerContainer,
  HeaderAndDivider,
  QuotationContainer,
  QuotationHeader,
  QuotationList,
  QuotationResultContainer,
  QuotationValue,
} from './styles';
import { PortoSeguroProposalFormProps } from '../../interfaces/portoseguro.proposal.interface';
import { dangerNotification, successNotification } from '../../../../../../../services/notification';
import { post } from '../../../../../../../services/request';

const ControlledField = ({ label, value }: { label: string; value: string | number | undefined}) => {
  return (
    <>
      <FieldLabel>{label}</FieldLabel>
      <span>{value}</span>
    </>
  );
};

const PortoSeguroCreatePolicyModal = (props: PortoSeguroProposalFormProps) => {
  const history = useHistory();
  const { open, onClose, fireInsuranceBudget } = props;
  const [isHiring, setIsHiring] = useState<boolean>(false);

  const handleHireButton = async () => {
    setIsHiring(true);

    try {
      await post(
        '/fire-insurance-proposals/porto-seguro/proposal',
        { fireInsuranceAnalysisId: fireInsuranceBudget?.budgetId }
      );

      successNotification(
        'Seguro incendio',
        'Proposta de seguro incendio criado com sucesso.',
        'bottom',
        7000
      );

      history.push('/admin/fire-insurance-policies');
    } catch (error) {
      console.log(error);
      dangerNotification(
        'Erro ao criar proposta de seguro incendio',
        'Por favor, tente novamente mais tarde ou contate o suporte.',
        7000
      );
    } finally {
      setIsHiring(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>Detalhes do Orçamento de Seguro Incêndio</Modal.Header>
      <Modal.Content scrolling>
        <DisclaimerContainer>
          <Disclaimer>
            <Icon bordered name='exclamation' />
            <p>
              Este orçamento está salvo para contratação posterior. Você terá um
              prazo de <time>30</time> dias para confirmar a contratação
            </p>
          </Disclaimer>
        </DisclaimerContainer>
        <Grid style={{ padding: 5 }} columns={'equal'}>
          <HeaderAndDivider>Dados do orçamento</HeaderAndDivider>
          <Grid.Row>
            <Grid.Column>
              <ControlledField
                label={'ID:'}
                value={fireInsuranceBudget?.budgetId}
              />
            </Grid.Column>

            <Grid.Column>
              <ControlledField
                label={'Nº do orçamento:'}
                value={fireInsuranceBudget?.budgetNumber}
              />
            </Grid.Column>

            <Grid.Column>
              <ControlledField
                label={'Valor do aluguel:'}
                value={numberToMoneyString(fireInsuranceBudget?.rentValue ?? 0)}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <ControlledField
                label={'Data de Início:'}
                value={moment(fireInsuranceBudget?.initialEffectiveDate).format('DD/MM/YYYY')}
              />
            </Grid.Column>

            <Grid.Column>
              <ControlledField
                label={'Data de Término:'}
                value={
                  moment(fireInsuranceBudget?.initialEffectiveDate)
                    .add(fireInsuranceBudget?.monthsValidity, 'M')
                    .format('DD/MM/YYYY')
                }
              />
            </Grid.Column>

            <Grid.Column>
              <ControlledField
                label={'Meses de vigência:'}
                value={fireInsuranceBudget?.monthsValidity ?? ''}
              />
            </Grid.Column>
          </Grid.Row>

          <HeaderAndDivider>Dados do Imóvel</HeaderAndDivider>
          <Grid.Row>
            <Grid.Column>
              <ControlledField
                label={'CEP:'}
                value={maskZipCode(fireInsuranceBudget?.zipCode ?? '')}
              />
            </Grid.Column>

            <Grid.Column>
              <ControlledField
                label={'Logradouro:'}
                value={fireInsuranceBudget?.street}
              />
            </Grid.Column>

            <Grid.Column>
              <ControlledField
                label={'Bairro:'}
                value={fireInsuranceBudget?.district}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <ControlledField
                label={'Cidade:'}
                value={fireInsuranceBudget?.city}
              />
            </Grid.Column>

            <Grid.Column>
              <ControlledField
                label={'Estado:'}
                value={fireInsuranceBudget?.state ? turnUFIntoEstate(fireInsuranceBudget?.state) : ''}
              />
            </Grid.Column>

            <Grid.Column>
              <ControlledField
                label={'Número:'}
                value={fireInsuranceBudget?.number}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <ControlledField
                label={'Complemento:'}
                value={fireInsuranceBudget?.complement ?? 'SEM COMPLEMENTO'}
              />
            </Grid.Column>
          </Grid.Row>

          <HeaderAndDivider>Dados do Proponente</HeaderAndDivider>
          <Grid.Row>
            <Grid.Column>
              <ControlledField
                label={'Nome/Razão Social:'}
                value={fireInsuranceBudget?.proponentName}
              />
            </Grid.Column>

            <Grid.Column>
              <ControlledField
                label={'CPF/CNPJ:'}
                value={maskCpfOrCnpj(fireInsuranceBudget?.document ?? '')}
              />
            </Grid.Column>

            <Grid.Column>
              <ControlledField
                label={
                  fireInsuranceBudget?.document.length === 11
                  ? 'Data de Nascimento:'
                  : 'Data de fundação da empresa'
                }
                value={moment(fireInsuranceBudget?.birthdate).format('DD/MM/YYYY')}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <ControlledField
                label={'E-mail:'}
                value={fireInsuranceBudget?.proponentEmail}
              />
            </Grid.Column>

            <Grid.Column>
              <ControlledField
                label={'Telefone:'}
                value={maskPhoneNumber(fireInsuranceBudget?.cellphone ?? '')}
              />
            </Grid.Column>

            <Grid.Column></Grid.Column>
          </Grid.Row>
        </Grid>

        <HeaderAndDivider>Dados de cobertura do seguro incêndio</HeaderAndDivider>
        <QuotationContainer>
        <QuotationHeader>
          <h3>
            <Icon name='shield' /> Coberturas
          </h3>
        </QuotationHeader>
        <QuotationResultContainer>
          <QuotationList>
              <>
                <CoveragesRow>
                  <div>
                    <span className='insurance-name'>
                      <Icon name='shield' className='low-opacity' /> Incêndio &nbsp;
                      <span className='required'>(Obrigatório)</span>
                    </span>
                  </div>
                  <div>
                    <span className='insurance-value'>
                      {numberToMoneyString(fireInsuranceBudget?.fireCoverageValue ?? 0)}
                    </span>
                  </div>
                </CoveragesRow>

                <CoveragesRow>
                  <div>
                    <span className={'full-insurance-name'}>
                      <input
                        type='checkbox'
                        disabled
                        checked={!!fireInsuranceBudget?.civilLiabilityCoverageValue}
                      />Cobertura responsabilidade civil
                    </span>
                  </div>
                  <div>
                    <span className='insurance-value'>
                      {numberToMoneyString(fireInsuranceBudget?.civilLiabilityCoverageValue ?? 0)}
                    </span>
                  </div>
                </CoveragesRow>

                <CoveragesRow>
                  <div>
                    <span className={'full-insurance-name'}>
                      <input
                        type='checkbox'
                        disabled
                        checked={!!fireInsuranceBudget?.coverageContentValue}
                      />Cobertura de conteúdo
                    </span>
                  </div>
                  <div>
                    <span className='insurance-value'>
                      {numberToMoneyString(fireInsuranceBudget?.coverageContentValue ?? 0)}
                    </span>
                  </div>
                </CoveragesRow>

                <CoveragesRow>
                  <div>
                    <span className={'full-insurance-name'}>
                      <input
                        type='checkbox'
                        disabled
                        checked={!!fireInsuranceBudget?.electricalDamageCoverageValue}
                      />Cobertura danos elétricos
                    </span>
                  </div>
                  <div>
                    <span className='insurance-value'>
                      {numberToMoneyString(fireInsuranceBudget?.electricalDamageCoverageValue ?? 0)}
                    </span>
                  </div>
                </CoveragesRow>

                <CoveragesRow>
                  <div>
                    <span className={'full-insurance-name'}>
                      <input
                        type='checkbox'
                        disabled
                        checked={!!fireInsuranceBudget?.coverageImpactsAirLandVehiclesValue}
                      />Cobertura por impactos de veículos terrestres ou aéreos
                    </span>
                  </div>
                  <div>
                    <span className='insurance-value'>
                      {numberToMoneyString(fireInsuranceBudget?.coverageImpactsAirLandVehiclesValue ?? 0)}
                    </span>
                  </div>
                </CoveragesRow>

                <CoveragesRow>
                  <div>
                    <span className={'full-insurance-name'}>
                      <input
                        type='checkbox'
                        disabled
                        checked={!!fireInsuranceBudget?.rentalLossCoverageValue}
                      />Cobertura por perda de aluguel
                    </span>
                  </div>
                  <div>
                    <span className='insurance-value'>
                      {numberToMoneyString(fireInsuranceBudget?.rentalLossCoverageValue ?? 0)}
                    </span>
                  </div>
                </CoveragesRow>

                <CoveragesRow>
                  <div>
                    <span className={'full-insurance-name'}>
                      <input
                        type='checkbox'
                        disabled
                        checked={!!fireInsuranceBudget?.galeCoverageValue}
                      />Cobertura por vendaval
                    </span>
                  </div>
                  <div>
                    <span className='insurance-value'>
                      {numberToMoneyString(fireInsuranceBudget?.galeCoverageValue ?? 0)}
                    </span>
                  </div>
                </CoveragesRow>

                <CoveragesRow>
                  <div>
                    <span className={'full-insurance-name'}>
                      <input
                        type='checkbox'
                        disabled
                        checked={!!fireInsuranceBudget?.riotCoverageValue}
                      />Cobertura por tumulto
                    </span>
                  </div>
                  <div>
                    <span className='insurance-value'>
                      {numberToMoneyString(fireInsuranceBudget?.riotCoverageValue ?? 0)}
                    </span>
                  </div>
                </CoveragesRow>
              </>
          </QuotationList>
          <QuotationValue>
            <h3 style={{ fontSize: '20px' }}>Seu orçamento</h3>
            <label style={{ fontSize: '13px', paddingBottom: '10px' }}>
              {fireInsuranceBudget?.installments} x FATURA MENSAL - SEM ENTRADA
            </label>
            <small style={{ fontSize: '15px' }}>
              Valor da primeira parcela: {numberToMoneyString(Number(fireInsuranceBudget?.valueFirstInstallment))}
            </small>
            <small style={{ fontSize: '15px', paddingBottom: '10px' }}>
              Valor das demais parcelas: {numberToMoneyString(Number(fireInsuranceBudget?.valueOtherInstallments))}
            </small>
            <label style={{ fontSize: '17px' }}>
              Total: {
                numberToMoneyString(
                  Number(fireInsuranceBudget?.valueFirstInstallment) +
                  Number((fireInsuranceBudget?.installments - 1) * (fireInsuranceBudget?.valueOtherInstallments))
                )
              }
            </label>
          </QuotationValue>
        </QuotationResultContainer>
        </QuotationContainer>
      </Modal.Content>

      <Modal.Actions>
        <Button
          icon
          disabled={isHiring}
          labelPosition={'left'}
          onClick={() => history.push('/admin/fire-insurance-budgets')}
        >
          <Icon name={'arrow left'} />
          Voltar
        </Button>

        <Button
          positive
          icon
          labelPosition={'right'}
          onClick={handleHireButton}
          loading={isHiring}
          disabled={isHiring}
        >
          <Icon name={'checkmark'} />
          Contratar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PortoSeguroCreatePolicyModal;
