import { PurposesEnum } from "../../enums/purposes.enum";
import { PersonTypesEnum } from "../../enums/person-types.enum";
import { BondInsuranceFormReducer, BondInsuranceFormState } from "../types/temp-types";

type BondInsuranceFormRematchModel = {
  state: BondInsuranceFormState;
  reducers: BondInsuranceFormReducer;
}

const bondInsuranceForm: BondInsuranceFormRematchModel = {
  state: {
    isToo: false,
    isLoading: false,
    currentPage: 0,
    currentStep: 'initial_info',
    propertyType: PurposesEnum.residential,
    tenantType: PersonTypesEnum.natural,
    estateEmail: ''
  },
  reducers: {
    updateBondInsurance: (state: BondInsuranceFormState, payload: Partial<BondInsuranceFormState>) => {
      return {
        ...state,
        ...payload
      }
    }
  }
};

export default bondInsuranceForm;
