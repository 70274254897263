import React, { useState, useEffect } from "react";
import {
  Table,
  Dimmer,
  Segment,
  Loader,
  Menu,
  Icon,
  Dropdown,
  Label,
  Popup,
} from "semantic-ui-react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  getBondInsuranceProposal,
  getBondInsuranceProposalStatusLabel,
  printBondInsuraceProposal,
  sortBondInsuranceProposals,
} from "../../../services/bond-insurance-proposal";
import { DateToStringDateTime } from "../../../services/date";
import { maskMoney, maskCpfOrCnpj } from "../../../services/masks";
import { iRootDispatch, iRootState } from "../../../store";
import IconButton from "../../../components/IconButton";
import {
  dangerNotification,
  successNotification,
} from "../../../services/notification";
import {
  BondInsuranceAnalysisForGetCompleteBondInsuranceProposalByIdResponseDTO,
  BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO,
  BondInsuranceHiredDataForGetCompleteBondInsuranceProposalByIdResponseDTO,
} from "../../../dtos/bond-insurance-proposal-list/get-complete-bond-insurance-proposal-by-id-response.dto";
import { SelectableTableRow, ClickableTableCell } from "../../../styles";
import { BondInsuranceProposalDetails } from "./proposal-details/BondInsuranceProposalDetails";
import { BondInsuranceHiredDataStatusEnum } from "../../../enums/bond-insurance-hired-data-status.enum";
import { ProposalForGetBondInsuranceProposalsListResponseDTO } from "../../../dtos/bond-insurance-proposal-list/interfaces/proposal-for-get-bond-insurance-proposals-list-response-dto";
import GeneralConfirmHireProposalModal from "./HireGeneralModal/ModalHireProposal";
import { AxiosError } from "axios";
import ModalAttachFiles from "../../../components/ModalAttachFiles";
import { InsurersCodeEnum } from "../../../enums/insurers-code.enum";
import CancelModal from "./CancellationModal/CancelModal";
import { diffHours } from "../../../util";
import { ActionEnum } from "../../../enums/authz-action.enum";
import { FeatureEnum } from "../../../enums/authz-feature.enum";
import AuthorizationContainer from "../../../components/AuthorizationContainer";

const ProposalsTableComponent = styled.div`
  margin-top: 1rem;
`;

const Header = styled(Table.Header)`
  background-color: #e1e8ed;
`;

const TableWrapper = styled.div`
  font-size: 0.85em;
  font-weight: bold;

  .ui.table {
    border: none;
    text-align: center;
    color: #364445;
  }
`;

const NotFoundWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #364445;
  font-size: 1.2em;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapState = (state: iRootState) => ({
  user: state.user,
  availableBrokersAndEstates: state.availableBrokersAndEstates,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatch = (dispatch: iRootDispatch) => ({});

type Props = {
  bondInsuranceProposals: ProposalForGetBondInsuranceProposalsListResponseDTO[];
  setIsLoading: (isLoading: boolean) => any;
  isLoading: boolean;
  getData: Function;
  currentPage: number;
  setCurrentPage: Function;
  dataCount: number;
} & ReturnType<typeof mapState>;

const BondInsuranceProposalsTable = (props: Props): JSX.Element => {
  const {
    bondInsuranceProposals,
    setIsLoading,
    isLoading,
    getData,
    user,
    currentPage,
    setCurrentPage,
    dataCount,
  } = props;

  const [listData, setListData] = useState<
    ProposalForGetBondInsuranceProposalsListResponseDTO[]
  >([]);
  const [ascendingOrder, setAscendingOrder] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] =
    useState<ProposalForGetBondInsuranceProposalsListResponseDTO | null>(null);
  const [selectedCompleteBondInsurance, setSelectedCompleteBondInsurance] =
    useState<BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO | null>(
      null
    );
  const [
    selectedCompleteBondInsuranceAnalysis,
    setSelectedCompleteBondInsuranceAnalysis,
  ] = useState<BondInsuranceAnalysisForGetCompleteBondInsuranceProposalByIdResponseDTO | null>(
    null
  );
  const [
    selectedCompleteBondInsuranceHiredData,
    setSelectedCompleteBondInsuranceHiredData,
  ] = useState<BondInsuranceHiredDataForGetCompleteBondInsuranceProposalByIdResponseDTO | null>(
    null
  );
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [openHireProposal, setOpenHireProposalModal] = useState<boolean>(false);
  const [downloadProposalControl, setDownloadProposalControl] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [proposalSelected, setProposalSelected] =
    useState<ProposalForGetBondInsuranceProposalsListResponseDTO | null>(null);
  const lastPage = Math.floor(dataCount / 10) + 1;

  useEffect(() => {
    setListData(bondInsuranceProposals);
  }, [bondInsuranceProposals]);

  function handleOpenConfirmModal(
    proposal: ProposalForGetBondInsuranceProposalsListResponseDTO
  ) {
    // console.log("GOD", proposal.hiredDataId);
    setProposalSelected(proposal);
    setOpenHireProposalModal(true);
  }

  async function prepareToOpenDetails(
    proposal: ProposalForGetBondInsuranceProposalsListResponseDTO
  ) {
    setIsLoading(true);

    try {
      const response = await getBondInsuranceProposal(proposal.analysisTableId);
      setSelectedCompleteBondInsurance(response.analysis.bondInsurance);
      setSelectedCompleteBondInsuranceAnalysis(response.analysis);
      setSelectedCompleteBondInsuranceHiredData(response.hiredData);
      setIsDetailsModalOpen(true);
    } catch (e) {
      dangerNotification(
        "Erro!",
        `Erro ao abrir proposta: ${(e as AxiosError).message}`
      );
      setSelectedCompleteBondInsurance(null);
      setSelectedCompleteBondInsuranceAnalysis(null);
      setSelectedCompleteBondInsuranceHiredData(null);
      setIsDetailsModalOpen(false);
    } finally {
      setIsLoading(false);
    }
  }

  const close = (): void => {
    setOpenHireProposalModal(false);
    setProposalSelected(null);
  };

  function toggleAscending(): void {
    const toggledAscending = !ascendingOrder;
    setAscendingOrder(toggledAscending);
    const orderedData = sortBondInsuranceProposals(
      bondInsuranceProposals,
      toggledAscending
    );
    setListData(orderedData);
  }

  const renderPdf = (base64: string, filename: string) => {
    const linkSource = `data:application/pdf;base64,${base64}`;
    const downloadLink = document.createElement("a");
    const fileName = "proposta" + filename + ".pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setDownloadProposalControl(false);
  };

  async function handleDownloadProposalFile(
    proposal: ProposalForGetBondInsuranceProposalsListResponseDTO
  ) {
    setDownloadProposalControl(true);
    return printBondInsuraceProposal(proposal.analysisTableId)
      .then((res) => {
        renderPdf(res.payload, proposal.insurerProposalId);
        successNotification("Sucesso", "Sucesso!");
      })
      .catch((e) => {
        dangerNotification("Erro", "Não foi possível imprimir a proposta");
      })
      .finally(() => setDownloadProposalControl(false));
  }

  function renderTableData(
    proposals: Array<ProposalForGetBondInsuranceProposalsListResponseDTO>
  ): Array<JSX.Element> {
    return proposals.map(
      (
        proposal: ProposalForGetBondInsuranceProposalsListResponseDTO,
        index
      ) => {
        const tenantName = proposal.tenant?.name
          ? proposal.tenant.name
          : "Indisponível";
        const tenantDocument = proposal.tenant?.document
          ? maskCpfOrCnpj(proposal.tenant.document)
          : "Indisponível";
        const trigger = (
          <AuthorizationContainer action={ActionEnum.update} feature={FeatureEnum.proposals}>
            <IconButton color="blue" name="ellipsis vertical" />
          </AuthorizationContainer>
        );

        const StatusPopup = () => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px 10px",
                gap: 5,
              }}
            >
              <div>
                <span>Atuação manual necessária</span>
              </div>
            </div>
          );
        };

        return (
          <>
            {proposalSelected !== null && user.role!.includes("BROKER") && (
              <GeneralConfirmHireProposalModal
                callback={getData}
                openModal={openHireProposal}
                closeModal={close}
                hiredDataId={proposalSelected.hiredDataId}
                observations={proposalSelected.observations}
                quotedInstallments={proposalSelected.values.quotedInstallments}
                quotedValue={proposalSelected.values.quotedValue}
                hiredInstallments={
                  proposalSelected.values.hiredInstallments ||
                  proposalSelected.values.quotedInstallments
                }
                hiredInstallmentsValue={
                  proposalSelected.values.hiredInstallmentsValue ||
                  proposalSelected.values.quotedValue
                }
                rentInsuranceContractEndDate={
                  proposalSelected.rentInsuranceContractEndDate
                }
                rentInsuranceContractStartDate={
                  proposalSelected.rentInsuranceContractStartDate
                }
                userId={user.id}
              />
            )}
            {
              <SelectableTableRow key={proposal.analysisTableId}>
                <ClickableTableCell
                  onClick={async () => {
                    setSelectedItem(proposal);
                    await prepareToOpenDetails(proposal);
                  }}
                >
                  {DateToStringDateTime(
                    proposal.insurerProposalCreatedAt || new Date(0, 0, 0)
                  )}
                </ClickableTableCell>
                <ClickableTableCell
                  onClick={async () => {
                    setSelectedItem(proposal);
                    await prepareToOpenDetails(proposal);
                  }}
                >
                  {DateToStringDateTime(
                    proposal.analysisCreatedAt || new Date(0, 0, 0)
                  )}
                </ClickableTableCell>
                <ClickableTableCell
                  onClick={async () => {
                    setSelectedItem(proposal);
                    await prepareToOpenDetails(proposal);
                  }}
                >
                  {proposal.insurer.name}
                </ClickableTableCell>
                <ClickableTableCell
                  onClick={async () => {
                    setSelectedItem(proposal);
                    await prepareToOpenDetails(proposal);
                  }}
                >
                  {proposal.estate.name ? proposal.estate.name : "Indisponível"}
                </ClickableTableCell>
                <ClickableTableCell
                  onClick={async () => {
                    setSelectedItem(proposal);
                    await prepareToOpenDetails(proposal);
                  }}
                >
                  {proposal.hiredDataStatus ===
                    BondInsuranceHiredDataStatusEnum.insurerHirePendingIntegred &&
                    proposal.insurerProposalCreatedAt ? (
                    diffHours(
                      new Date(proposal.insurerProposalCreatedAt),
                      new Date()
                    ) < 2 ? (
                      <>
                        {getBondInsuranceProposalStatusLabel(
                          proposal.hiredDataStatus
                        ) || ""}
                      </>
                    ) : (
                      <Popup
                        content={<StatusPopup />}
                        as={Segment}
                        wide
                        position={"right center"}
                        trigger={
                          <span style={{ cursor: "help" }}>
                            {getBondInsuranceProposalStatusLabel(
                              proposal.hiredDataStatus
                            ) || ""}
                            {
                              <Label
                                empty
                                circular
                                color={"orange"}
                                size={"mini"}
                                style={{
                                  position: "relative",
                                  top: -10,
                                  right: -5,
                                }}
                              />
                            }
                          </span>
                        }
                      />
                    )
                  ) : (
                    <>
                      {getBondInsuranceProposalStatusLabel(
                        proposal.hiredDataStatus
                      ) || ""}
                    </>
                  )}
                </ClickableTableCell>
                <ClickableTableCell
                  onClick={async () => {
                    setSelectedItem(proposal);
                    await prepareToOpenDetails(proposal);
                  }}
                >
                  {tenantName}
                  <br />({tenantDocument})
                </ClickableTableCell>
                <ClickableTableCell>
                  {proposal.property.address}
                  <br />
                  ({proposal.bondInsurance.purpose === 'RESIDENTIAL' ? 'Residencial' : 'Comercial'})
                </ClickableTableCell>
                <ClickableTableCell
                  onClick={async () => {
                    setSelectedItem(proposal);
                    await prepareToOpenDetails(proposal);
                  }}
                >{`R$ ${maskMoney(
                  `${proposal.values.totalPremiumAmount
                    ? proposal.values.totalPremiumAmount
                    : 0
                  }`,
                  true
                )}`}</ClickableTableCell>
                {/* <ClickableTableCell
                  onClick={async () => {
                    setSelectedItem(proposal);
                    await prepareToOpenDetails(proposal);
                  }}
                >
                  {proposal?.insurerProposalId || "Indisponível"}
                </ClickableTableCell> */}
                <Table.Cell width={1} textAlign={"right"}>
                  <Dropdown
                    trigger={trigger}
                    icon={null}
                    direction="left"
                    onOpen={() => {
                      setSelectedItem(proposal);
                    }}
                    onClose={() => {
                      //setSelectedItem(null);
                    }}
                  >
                    <Dropdown.Menu>
                      {proposal.insurer.code === InsurersCodeEnum.Too && (
                        <Dropdown.Item
                          text={
                            downloadProposalControl
                              ? "Carregando..."
                              : "Imprimir Proposta"
                          }
                          icon="print"
                          disabled={
                            proposal.hiredDataStatus ===
                            BondInsuranceHiredDataStatusEnum.waiting ||
                            proposal.hiredDataStatus ===
                            BondInsuranceHiredDataStatusEnum.insurerHireRelatedInformationPending ||
                            proposal.hiredDataStatus ===
                            BondInsuranceHiredDataStatusEnum.hireRelatedInformationError ||
                            proposal.hiredDataStatus ===
                            BondInsuranceHiredDataStatusEnum.cancelled ||
                            downloadProposalControl
                          }
                          onClick={(): Promise<void> =>
                            handleDownloadProposalFile(proposal)
                          }
                        ></Dropdown.Item>
                      )}
                      {user.role!.includes("BROKER") && (
                        <AuthorizationContainer action={ActionEnum.contract} feature={FeatureEnum.proposals}>
                          <Dropdown.Item
                            icon="checkmark"
                            text={"Confirmar Contratação"}
                            onClick={() => handleOpenConfirmModal(proposal)}
                          />
                        </AuthorizationContainer>
                      )}
                      <AuthorizationContainer action={ActionEnum.cancel} feature={FeatureEnum.proposals}>
                        <Dropdown.Item
                          icon="trash"
                          text={"Solicitar Cancelamento"}
                          onClick={() => setOpenCancelModal(true)}
                        />
                      </AuthorizationContainer>
                    </Dropdown.Menu>
                  </Dropdown>

                  {selectedItem &&
                    selectedItem.hiredDataId === proposal.hiredDataId &&
                    openCancelModal && (
                      <CancelModal
                        closeModal={() => setOpenCancelModal(false)}
                        setOpen={() => setOpenCancelModal(true)}
                        hiredDataId={proposal.hiredDataId}
                        bondInsuranceId={proposal.bondInsuranceId}
                        callback={() => getData()}
                        openModal={openCancelModal || false}
                      />
                    )}
                </Table.Cell>
              </SelectableTableRow>
            }
          </>
        );
      }
    );
  }

  return (
    <ProposalsTableComponent>
      {isDetailsModalOpen && (
        <BondInsuranceProposalDetails
          callback={getData}
          bondInsurance={selectedCompleteBondInsurance}
          bondInsuranceId={selectedCompleteBondInsurance?.id}
          bondInsuranceAnalysis={selectedCompleteBondInsuranceAnalysis}
          hiredData={selectedCompleteBondInsuranceHiredData}
          isOpen={isDetailsModalOpen}
          onClose={() => {
            setSelectedCompleteBondInsurance(null);
            setSelectedCompleteBondInsuranceAnalysis(null);
            setSelectedItem(null);
            setIsDetailsModalOpen(false);
          }}
        />
      )}
      <Dimmer.Dimmable as={Segment} dimmed={isLoading}>
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
        {listData && listData.length > 0 ? (
          <TableWrapper>
            <Table>
              <Header>
                <Table.Row>
                  <Table.HeaderCell width={2}>
                    <p>
                      CRIADO EM
                      <IconButton
                        name={
                          ascendingOrder
                            ? "long arrow alternate up"
                            : "long arrow alternate down"
                        }
                        color="blue"
                        onClick={toggleAscending}
                      />
                    </p>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2}>
                    <p>
                      COTADO EM
                      <IconButton
                        name={
                          ascendingOrder
                            ? "long arrow alternate up"
                            : "long arrow alternate down"
                        }
                        color="blue"
                        onClick={toggleAscending}
                      />
                    </p>
                  </Table.HeaderCell>
                  <Table.HeaderCell>SEGURADORA</Table.HeaderCell>
                  <Table.HeaderCell>IMOBILIARIA</Table.HeaderCell>
                  <Table.HeaderCell>STATUS</Table.HeaderCell>
                  <Table.HeaderCell>INQUILINO</Table.HeaderCell>
                  <Table.HeaderCell>ENDEREÇO</Table.HeaderCell>
                  <Table.HeaderCell>PRÊMIO TOTAL COTADO(R$)</Table.HeaderCell>
                  {/* <Table.HeaderCell>Nº PROPOSTA</Table.HeaderCell> */}
                  <Table.HeaderCell width={1} textAlign={"right"}>
                    <IconButton color="blue" name="setting" />
                  </Table.HeaderCell>
                </Table.Row>
              </Header>
              {/*              <Table.Body>
                {listData.map((bondInsurance, index) => {
                  const tenantDocument = bondInsurance.natural_tenant
                    ? maskCpf(bondInsurance.natural_tenant.main_tenant_cpf)
                    : maskCnpj(bondInsurance.legal_tenant.document);
                  const tenantName = bondInsurance.natural_tenant
                    ? bondInsurance.natural_tenant.main_tenant_name
                    : bondInsurance.legal_tenant.company;
                  return (
                    <>
                      <BondInsuranceDetails
                        bondInsurance={bondInsurance}
                        isOpen={index === indexOpen}
                        onClose={() => setIndexOpen(-1)} />

                      <Table.Row key={bondInsurance.id}>
                        <Table.Cell onClick={() => setIndexOpen(index)}>
                          {`${DateToStringDate(bondInsurance.created_at || new Date(0, 0, 0))}`}
                          <p>{DateToStringTime(bondInsurance.created_at || new Date(0, 0, 0))}</p>
                        </Table.Cell>
                        <Table.Cell onClick={() => setIndexOpen(index)}>{bondInsurance.estate?.name}</Table.Cell>
                        <Table.Cell onClick={() => setIndexOpen(index)}>{tenantDocument}</Table.Cell>
                        <Table.Cell onClick={() => setIndexOpen(index)}>{tenantName}</Table.Cell>
                        <Table.Cell onClick={() => setIndexOpen(index)}>{bondInsurance.property.street}</Table.Cell>
                        <Table.Cell onClick={() => setIndexOpen(index)}>{bondInsurance.estate?.user.name}</Table.Cell>
                      </Table.Row>
                    </>
                  );
                })}
              </Table.Body>*/}
              {
                console.log({bondInsuranceProposals})
              }
              <Table.Body>{renderTableData(bondInsuranceProposals)}</Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="17">
                    <Menu floated="right" pagination>
                      <Menu.Item>
                        <p>{`${currentPage * 10 - 9} - ${dataCount < currentPage * 10
                          ? dataCount
                          : currentPage * 10
                          } de ${dataCount}`}</p>
                      </Menu.Item>
                      <Menu.Item
                        disabled={currentPage === 1}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(1)}
                      >
                        <Icon name="angle double left" />
                      </Menu.Item>
                      <Menu.Item
                        disabled={currentPage === 1}
                        as="a"
                        icon
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item as="a">{currentPage}</Menu.Item>
                      <Menu.Item
                        disabled={
                          currentPage === lastPage ||
                          bondInsuranceProposals.length < 10
                        }
                        as="a"
                        icon
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                      <Menu.Item as="a" icon>
                        <Icon
                          disabled={
                            currentPage === lastPage ||
                            bondInsuranceProposals.length < 10
                          }
                          name="angle double right"
                          onClick={() => setCurrentPage(lastPage)}
                        />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </TableWrapper>
        ) : (
          <NotFoundWrapper>
            <p>Propostas não encontradas</p>
          </NotFoundWrapper>
        )}
      </Dimmer.Dimmable>
    </ProposalsTableComponent>
  );
};

export default connect(mapState, mapDispatch)(BondInsuranceProposalsTable);
