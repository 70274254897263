import React, { useEffect, useState } from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import moment from 'moment-timezone';
import styled from 'styled-components';

import { formatAmount, maskMoney } from '../../../../../services/masks';
import { FieldLabel } from '../../../../../styles';
import { translateProposalStatus } from '../../../utils/Pottencial';
import { SegimobAssistanceMap } from '../../../budget-list/Details/TokioMarineSegimob';
import { get } from '../../../../../services/request';

const HeaderAndDivider = styled.h3`
  border-bottom: 1px solid #3498db;
  color: #3498db;
  width: 100%;
  margin-top: 20px !important;
  padding-bottom: 5px;
  letter-spacing: 0.4px;
`;

interface DetailPottencialInterface {
  fireInsurancePolicy: any;
  showJson?: boolean;
}

interface ControledFieldInterface {
  label: string;
  value: any;
}

const ControledFields = (props: ControledFieldInterface) => {
  const { label, value } = props;
  return (
    <Grid.Column>
      <FieldLabel>{label}</FieldLabel>
      <span>{value}</span>
    </Grid.Column>
  );
};

const DetailSegimob = (props: DetailPottencialInterface) => {
  const { fireInsurancePolicy, showJson } = props;
  const [segimobJson, setSegimobJson] = useState<any>();

  useEffect(() => {
    async function loadSegimobJson() {
      try {
        if (!fireInsurancePolicy?.id) {
          return;
        }

        const json = await get(`/segimob-import/json/${fireInsurancePolicy.id}`);

        setSegimobJson(json.payload);
      } catch (err) {
        console.log(err);
      }
    }

    if (showJson) {
      loadSegimobJson();
    }
  }, [showJson, fireInsurancePolicy]);

  return (
    <Grid columns={'equal'}>
      <Segment style={{ display: showJson ? 'block' : 'none' }}>
        <pre style={{ width: '100%', height: '40vh', overflowX: 'auto' }}>
          {JSON.stringify(segimobJson, null, 2)}
        </pre>
      </Segment>

      {fireInsurancePolicy.policyCancelation && (
        <>
          <HeaderAndDivider>Dados do Cancelamento</HeaderAndDivider>
          <Grid.Row>
            <ControledFields
              label={'Cancelada em:'}
              value={moment(fireInsurancePolicy.policyCancelation.createdAt).format(
                'DD/MM/YYYY'
              )}
            />

            <ControledFields
              label={'Cancelada por:'}
              value={fireInsurancePolicy?.policyCancelation?.canceledBy?.name || ''}
            />

            <ControledFields
              label={'Motivo:'}
              value={fireInsurancePolicy?.policyCancelation?.cancellation_reason || ''}
            />
          </Grid.Row>

          <Grid.Row>
            <ControledFields
              label={'Valor restituído:'}
              value={`R$ ${formatAmount(
                fireInsurancePolicy?.policyCancelation?.refundValue
              )}`}
            />

            <ControledFields
              label={'Data da restituição:'}
              value={
                fireInsurancePolicy?.policyCancelation?.expectedPaymentDate
                  ? moment(
                      fireInsurancePolicy?.policyCancelation?.expectedPaymentDate
                    ).format('DD/MM/YYYY')
                  : '-'
              }
            />

            <ControledFields label="" value={''} />
          </Grid.Row>

          <Grid.Row>
            <ControledFields
              label={'Observações:'}
              value={fireInsurancePolicy?.policyCancelation?.observations || ''}
            />
          </Grid.Row>
        </>
      )}

      <HeaderAndDivider>Dados da Apólice</HeaderAndDivider>
      <Grid.Row>
        <ControledFields
          label={'Nº da Apólice'}
          value={fireInsurancePolicy.policyNumber || 'Indisponível'}
        />

        <ControledFields
          label={'Assistencia'}
          value={
            fireInsurancePolicy.fireInsuranceAnalysis.assistance
              ? SegimobAssistanceMap.find(
                  ass => ass.id == fireInsurancePolicy.fireInsuranceAnalysis.assistance
                )?.nome || ''
              : ''
          }
        />
        <ControledFields label={''} value={''} />
      </Grid.Row>

      <Grid.Row>
        <ControledFields label={'ID da Apólice'} value={fireInsurancePolicy.id} />

        <ControledFields
          label={'Data de Criação'}
          value={moment(fireInsurancePolicy.createdAt).format('DD/MM/YYYY')}
        />

        <ControledFields
          label={'Última Atualização'}
          value={
            fireInsurancePolicy.updatedAt ? (
              moment(fireInsurancePolicy.updatedAt).format('DD/MM/YYYY')
            ) : (
              <span style={{ color: '#ccc' }}>Indisponível</span>
            )
          }
        />
      </Grid.Row>

      <Grid.Row>
        <ControledFields
          label={'Data de Início'}
          value={moment(fireInsurancePolicy.policyPeriodStart).format('DD/MM/YYYY')}
        />

        <ControledFields
          label={'Data de Término'}
          value={moment(fireInsurancePolicy.policyPeriodEnd).format('DD/MM/YYYY')}
        />

        <ControledFields
          label={'Vigência do Contrato'}
          value={`${moment(fireInsurancePolicy.policyPeriodEnd).diff(
            moment(fireInsurancePolicy.policyPeriodStart),
            'months'
          )} meses`}
        />
      </Grid.Row>

      <Grid.Row>
        <ControledFields
          label={'Nº de Parcelas'}
          value={fireInsurancePolicy.fireInsuranceAnalysis.installments}
        />

        <ControledFields
          label={'Valor da Parcela'}
          value={`R$ ${formatAmount(fireInsurancePolicy.fireInsuranceAnalysis.value)}`}
        />
        <ControledFields
          label={'Status'}
          value={translateProposalStatus(fireInsurancePolicy.status)}
        />
      </Grid.Row>

      <Grid.Row>
        <ControledFields
          label={'Seguradora'}
          value={fireInsurancePolicy?.fireInsuranceAnalysis?.insurer?.name || ''}
        />

        <ControledFields
          label={''}
          value={null}
        />

        <ControledFields
          label={''}
          value={null}
        />
      </Grid.Row>

      <HeaderAndDivider>Coberturas</HeaderAndDivider>
      {fireInsurancePolicy.fireInsuranceAnalysis.coverages.map((coverage: any) => (
        <Grid.Row key={coverage.id}>
          <Grid.Column>
            <ControledFields label={'Cobertura'} value={coverage.coverageName} />
          </Grid.Column>
          <Grid.Column>
            <ControledFields
              label={'Valor'}
              value={`R$ ${maskMoney(String(coverage.value || 0), true, 2)}`}
            />
          </Grid.Column>
          <Grid.Column>
            <ControledFields
              label={'Prêmio'}
              value={`R$ ${maskMoney(String(coverage.totalPremium || 0), true, 2)}`}
            />
          </Grid.Column>
        </Grid.Row>
      ))}

      <HeaderAndDivider>Dados do imóvel</HeaderAndDivider>
      <Grid.Row>
        <ControledFields
          label={'CEP'}
          value={
            fireInsurancePolicy.fireInsuranceAnalysis.fireInsurance.propertyAddresses
              .zipcode
          }
        />

        <ControledFields
          label={'Cidade'}
          value={
            fireInsurancePolicy.fireInsuranceAnalysis.fireInsurance.propertyAddresses.city
          }
        />

        <ControledFields
          label={'Estado'}
          value={
            fireInsurancePolicy.fireInsuranceAnalysis.fireInsurance.propertyAddresses
              .state
          }
        />
      </Grid.Row>
      <Grid.Row>
        <ControledFields
          label={'Bairro'}
          value={
            fireInsurancePolicy.fireInsuranceAnalysis.fireInsurance.propertyAddresses
              .district
          }
        />

        <ControledFields
          label={'Rua'}
          value={
            fireInsurancePolicy.fireInsuranceAnalysis.fireInsurance.propertyAddresses
              .street
          }
        />

        <ControledFields
          label={'Número'}
          value={
            fireInsurancePolicy.fireInsuranceAnalysis.fireInsurance.propertyAddresses
              .number +
            '  ' +
            fireInsurancePolicy.fireInsuranceAnalysis.fireInsurance.propertyAddresses
              .complement
          }
        />
      </Grid.Row>
      <Grid.Row>
        <ControledFields
          label={'Valor do Aluguel'}
          value={
            'R$ ' +
            maskMoney(
              String(
                Number(fireInsurancePolicy.fireInsuranceAnalysis.fireInsurance.rentValue)
              )
            )
          }
        />
        <ControledFields label={''} value={''} />
        <ControledFields label={''} value={''} />
      </Grid.Row>

      <HeaderAndDivider>Dados do Inquilino</HeaderAndDivider>
      <Grid.Row>
        <Grid.Column>
          <ControledFields
            label={'Nome/Razão Social'}
            value={fireInsurancePolicy.fireInsuranceAnalysis.fireInsurance.tenantName}
          />
        </Grid.Column>
        <Grid.Column>
          <ControledFields
            label={'CPF/CNPJ'}
            value={fireInsurancePolicy.fireInsuranceAnalysis.fireInsurance.tenantDocument}
          />
        </Grid.Column>
        <Grid.Column width={5}>
          <ControledFields
            label={'E-mail'}
            value={fireInsurancePolicy.fireInsuranceAnalysis.fireInsurance.tenantEmail}
          />
        </Grid.Column>
        <Grid.Column>
          <ControledFields
            label={'Telefone'}
            value={
              fireInsurancePolicy.fireInsuranceAnalysis.fireInsurance.tenantCellphone ||
              'Não informado'
            }
          />
        </Grid.Column>
      </Grid.Row>

      <HeaderAndDivider>Dados do Proprietário</HeaderAndDivider>
      <Grid.Row>
        <Grid.Column>
          <ControledFields
            label={'Nome/Razão Social'}
            value={fireInsurancePolicy.fireInsuranceAnalysis.fireInsurance.insuredName}
          />
        </Grid.Column>
        <Grid.Column>
          <ControledFields
            label={'CPF/CNPJ'}
            value={
              fireInsurancePolicy.fireInsuranceAnalysis.fireInsurance.insuredDocument
            }
          />
        </Grid.Column>
        <Grid.Column width={5}>
          <ControledFields
            label={'E-mail'}
            value={fireInsurancePolicy.fireInsuranceAnalysis.fireInsurance.insuredEmail}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default DetailSegimob;
