import React from 'react';
import moment from 'moment';
import { Text, View, Image } from '@react-pdf/renderer';

import { styles } from '../styles';
import logoAlfa from '../images/logo-alfa.png';

const BudgetHeader = ({ data }: any) => (
  <View style={styles.rowHeader}>
    <View style={styles.column}>
      <Text style={styles.headerTitle}>{data.title}</Text>
      <Text style={styles.headerSubTitle}>
        {`Data orçamento: ${moment(data.budgetDate).format('DD/MM/YYYY HH:mm')}`}
      </Text>
      <Text style={styles.headerSubTitle}>
        {`Imobiliária: ${data.estateName}`}
      </Text>
      <Text style={styles.headerSubTitle}>
        {`Orçamento válido até: ${moment(data.budgetDate).add(1, 'M').format('DD/MM/YYYY')}`}
      </Text>
    </View>

    <Image style={styles.logoAlfa} src={logoAlfa} />
  </View>
);

export default BudgetHeader;
