import { BudgetProps, Insurance, SimulatorProps } from "./types";
import { EstateState } from "../../types/EstateState";
import { BrokerState } from "../../types/BrokerState";
import { InsurerState } from "../../types/InsurerState";
import { OccupationState } from "../../types/OccupationState";
import { AssistanceState } from "../../types/AssistanceState";
import {addMonthToDate, EMPTY_DATE} from "../../util";
import { DropdownItemProps } from "semantic-ui-react";
import {PropertyTypeState} from "../../types/PropertyTypeState";

export const initialFireInsuranceBudget: BudgetProps = {
  insurance: {
    broker: { estates: Array<EstateState>() } as BrokerState,
    estate: {} as EstateState,
    insurer: {} as InsurerState,
    occupation: {} as OccupationState,
    assistance: {} as AssistanceState,
    propertyType: {} as PropertyTypeState,
    rentValue: '0,00'
  } as Insurance,
  property: {
    zipCode: '',
    street: '',
    district: '',
    city: '',
    state: '',
    number: '',
    complement: '',
    externalCode: '',
  },
  tenant: {
    document: '',
    name: '',
    email: '',
    telephone: '',
    birthDate: undefined
  },
  owner: {
    document: '',
    name: '',
    email: '',
    telephone: '',
  },
  coverage: [],
  confiaxComission: 0,
  quotationRequest: {}
};

const initialStartDate = new Date();
const initialEndDate = addMonthToDate(initialStartDate, 12);

export const initialSimulator: SimulatorProps = {
  installments: 1,
  installmentsOptions: [{} as DropdownItemProps],
  value: 0,
  coverValue: 0,
  validity: 12,
  startDate: initialStartDate,
  endDate: initialEndDate,
  covered: false,
  profitShare: 0,
  brokerageFee: 0,
  confiaxComission: 0,
  insurerGain: 0,
  iof: 0
}
