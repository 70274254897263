import React, { SyntheticEvent } from 'react';

import { Menu, List, Icon } from 'semantic-ui-react';
import { MenuItemProps, SubMenuItemsProps } from './types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SubMenuItem from './SubMenuItem';
import styled from 'styled-components';
import { iRootState, iRootDispatch } from '../store';
import { RouteComponentProps } from 'react-router';
import { CurrentPageState, CurrentToggledMenuState } from '../store/types/temp-types';

const MenuItemWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 8px !important;

  & a {
    color: #9da6ba !important;
    font-size: 16px;
  }

  & a > span {
    font-size: 16px !important;
  }

  & .toggle-icon {
    color: #000;
  }

  & a.opened > * {
    color: #3498db !important;
  }

  & a.active {
    //   background-color: #3498db !important;
    color: #3498db !important;
    border-radius: 8px;
  }

  & a.active > * {
    color: #3498db !important;
  }

  & a:not(.active):hover {
    background-color: #eeeeee !important;
  }

  .item.closed {
    .list {
      margin: 0 !important;
    }
  }
`;

const AppMenuItemIcon = styled(Icon)`
  float: left !important;
  margin: 0 !important;
  margin-right: 1em !important;
  color: #999999;
`;

const MenuGroupList = styled(List)`
  & .item {
    color: #222 !important;
    font-size: 12px;
    line-height: 12px !important;
  }
`;

const mapState = (state: iRootState) => ({
  currentToggledMenu: state.currentToggledMenu,
  currentPage: state.currentPage
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateCurrentToggledMenu: (state: CurrentToggledMenuState) =>
    dispatch.currentToggledMenu.updateCurrentToggledMenu(state),
  updateCurrentPage: (state: CurrentPageState) =>
    dispatch.currentPage.updateCurrentPage(state)
});

type Props = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch> &
  MenuItemProps &
  RouteComponentProps;

const MenuItem: React.FC<Props> = (item: Props) => {
  const { updateCurrentToggledMenu, updateCurrentPage } = item;

  const currentToggledMenu: CurrentToggledMenuState = item.currentToggledMenu;
  const currentPage: CurrentPageState = item.currentPage;
  const history: any = item.history;
  const match: any = item.match;

  const handlePageChange = (e: SyntheticEvent, item: MenuItemProps) => {
    if (currentPage.id !== item.id) {
      updateCurrentPage({
        id: item.id,
        name: item.name,
        text: item.text,
        title: item.title
      });

      history.push(`${match.url}/${item.route}`);
    }
  };

  const handleCurrentToggledMenuChange = (e: SyntheticEvent, item: MenuItemProps) => {
    if (currentToggledMenu.name && currentToggledMenu.name === item.name) {
      updateCurrentToggledMenu({});
    } else {
      updateCurrentToggledMenu({ id: item.id, name: item.name });
    }
  };

  return (
    <>
      <MenuItemWrapper>
        <Menu.Item
          position="left"
          name={item.name}
          {...(item.submenuItems &&
            item.submenuItems.length > 0 && {
              className:
                currentToggledMenu &&
                currentToggledMenu.name &&
                currentToggledMenu.name.includes(item.name)
                  ? 'opened'
                  : 'closed'
            })}
          {...(item.submenuItems && item.submenuItems.length > 0
            ? { onClick: (e: SyntheticEvent) => handleCurrentToggledMenuChange(e, item) }
            : { onClick: (e: SyntheticEvent) => handlePageChange(e, item) })}
          {...((currentPage && currentPage.name === item.name) ||
          (currentPage && currentPage.parent && currentPage.parent.name === item.name)
            ? { active: true }
            : { active: false })}
          as="a"
          key={item.id}
          id={item.id}
        >
          <AppMenuItemIcon name={item.icon} />
          <span>{item.text}</span>
          {item.submenuItems && (
            <Icon
              className={`toggle-icon ${
                currentToggledMenu &&
                currentToggledMenu.name &&
                currentToggledMenu.name.includes(item.name)
                  ? 'openedIcon'
                  : 'closedIcon'
              }`}
              name={
                currentToggledMenu &&
                currentToggledMenu.name &&
                currentToggledMenu.name.includes(item.name)
                  ? 'angle down'
                  : 'angle down'
              }
              color={'grey'}
              position="right"
            />
          )}
          {item.submenuItems && item.submenuItems.length > 0 && (
            <MenuGroupList>
              {currentToggledMenu &&
                currentToggledMenu.name &&
                currentToggledMenu.name.includes(item.name) && (
                  <>
                    {item.submenuItems.map(
                      (submenuItem: SubMenuItemsProps, index: number) => {
                        return (
                          <>
                            <SubMenuItem
                              key={submenuItem.id}
                              {...submenuItem}
                              parent={item}
                            />
                          </>
                        );
                      }
                    )}
                  </>
                )}
            </MenuGroupList>
          )}
        </Menu.Item>
      </MenuItemWrapper>
    </>
  );
};

export default withRouter(connect(mapState, mapDispatch)(MenuItem));
