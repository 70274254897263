import { useEffect, useState, useMemo } from 'react';

interface WindowSize {
  width: number;
  height: number;
  isMobile: boolean;
}

export const mobileBreakpoint = 769

/**
 * Custom hook that provides the current window size and a boolean flag indicating
 * whether the window width is below 769 pixels (indicating a mobile device).
 * @returns {object} An object containing width, height, and isMobile properties.
 */

const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: window.innerWidth < mobileBreakpoint
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth < mobileBreakpoint
      });
    };

    // Add a listener to the window resize event
    window.addEventListener('resize', handleResize);

    // Remove the listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const memoizedWindowSize = useMemo(() => {
    return windowSize;
  }, [windowSize]);

  return memoizedWindowSize;
};

export default useWindowSize;
