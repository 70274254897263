import React, { useEffect, useState } from 'react';
import { Grid, Icon, Segment, Button, Label } from 'semantic-ui-react';
import { translatePropertyStatus, translateStatus, translateValuesType } from '../../../claim_estates/util';

import { ClaimEstate } from '../../../../types/ClaimEstate';
import { maskCpfOrCnpj, maskPhoneNumber } from '../../../../services/masks';
import { ClaimValuesTypesEnum } from '../../../claim_estates/enums/ClaimValues.enum';
import moment from 'moment-timezone';
import SectionLabel from '../../../../components/SectionLabel';
import { Info } from '../../../../components/Info';

interface Props {
  claim: ClaimEstate;
}

const ClaimDetailModal = (props: Props) => {
  const { claim } = props;
  const { id, createdAt, updatedAt, createdBy, responsible, estate, insurer, protocolNumber, policyNumber, status, observations, claimProperty, claimTenant, claimValues } = claim
  const address = `${claimProperty?.street}${claimProperty?.number ? `, ${claimProperty?.number}` : ''}${claimProperty?.complement ? ` - ${claimProperty?.complement}` : ''}`
  const [sumValues, setSumValues] = useState<number>(0)

  function sumInArray(array: any[], prop: string): number {
    return array.reduce((a, b) => a + Number(b[prop]), 0)
  }

  useEffect(() => {
    const isArray = Array.isArray(claimValues)

    if (isArray) {
      setSumValues(sumInArray(claimValues, 'value'))
    }
  }, [claim, claimValues]);

  return (
    <>
      <SectionLabel text="DADOS DO SINISTRO" />
      <Grid.Row>
        <Info name='ID do Sinistro' value={id} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name='Data de Solicitação' value={moment(createdAt).subtract(3, 'h').format('DD/MM/YYYY HH:mm:ss')} width={8} />
        <Info name='Última atualização' value={updatedAt ? moment(updatedAt).subtract(3, 'h').format('DD/MM/YYYY HH:mm:ss') : (<span style={{color: '#ccc'}}>Não houve atualização</span>)} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name='Criado Por' value={createdBy?.name} width={8} />
        <Info name='Responsável' value={responsible ? responsible?.name : (<span style={{color: '#ccc'}}>Não atribuído</span>)} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name='Imobiliária' value={estate?.name} width={8} />
        <Info name='Seguradora' value={insurer ? insurer?.name : (<span style={{color: '#ccc'}}>Não atribuído</span>)} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name='Nº do Protocolo' value={protocolNumber} width={8} />
        <Info name='Nº da Apólice' value={policyNumber || (<span style={{color: '#ccc'}}>Não atribuído</span>)} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name='Status' value={translateStatus(status)} width={8} />
        <Info name='Observações' value={observations} width={8} />
      </Grid.Row>

      <SectionLabel text="DADOS DO IMÓVEL" />
      <Grid.Row >
        <Info name='ID do Imóvel' value={claimProperty?.id} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name='Data de Criação'
          value={
            claimProperty?.createdAt ?
            moment(claimProperty.createdAt).subtract(3, 'h').format('DD/MM/YYYY HH:mm:ss') :
            (<span style={{color: '#ccc'}}>Não Criado</span>)
          }
          width={8}
        />

        <Info name='Última atualização'
          value={
            claimProperty?.updatedAt ?
            moment(claimProperty.updatedAt).subtract(3, 'h').format('DD/MM/YYYY HH:mm:ss') :
            (<span style={{color: '#ccc'}}>Não houve atualização</span>)
          }
          width={8}
        />
      </Grid.Row>
      <Grid.Row>
        <Info name='Endereço' value={address} width={8} />
        <Info name='Bairro' value={claimProperty?.district} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name='Cidade' value={claimProperty?.city} width={8} />
        <Info name='Estado' value={claimProperty?.state} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name='CEP' value={claimProperty?.zipCode} width={8} />
        <Info name='Situação' value={translatePropertyStatus(claimProperty?.status) && (<Icon name={'circle'} color={claimProperty?.status === 'OCCUPIED' ? 'red' : 'green'} />)} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info
          name='Data de Entrega das Chaves'
          value={
            claimProperty?.deliveryKeysDate ?
              new Date(claimProperty.deliveryKeysDate).toLocaleDateString('pt-BR')
              :
              (<span style={{color: '#ccc'}}>Não entregue / Não informado</span>)
            }
          width={8}
        />
      </Grid.Row>

      <SectionLabel text="DADOS DO INQUILINO" />
      <Grid.Row>
        <Info name='ID do Inquilino' value={claimTenant?.id} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info
          name='Data de Criação'
          value={
            claimTenant?.createdAt ?
            moment(claimTenant.createdAt).subtract(3, 'h').format('DD/MM/YYYY HH:mm:ss') :
            (<span style={{color: '#ccc'}}>Não Criado</span>)
          }
          width={8}
        />
        <Info
          name='Última atualização'
          value={
            claimTenant?.updatedAt ?
            moment(claimTenant.updatedAt).subtract(3, 'h').format('DD/MM/YYYY HH:mm:ss') :
            (<span style={{color: '#ccc'}}>Não houve atualização</span>)
          }
          width={8}
        />
      </Grid.Row>
      <Grid.Row>
        <Info name='Nome' value={claimTenant?.fullName} width={8} />
        <Info name='Documento' value={maskCpfOrCnpj(claimTenant?.document)} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name='Telefone' value={claimTenant?.cellphone ? maskPhoneNumber(claimTenant?.cellphone) : (<span style={{color: '#ccc'}}>Não informado</span>)} width={8} />
        <Info name='Email' value={claimTenant?.email} width={8} />
      </Grid.Row>

      <SectionLabel text="VALORES INFORMADOS" />
      {
        sumValues > 0 && (
          <Button as='div' labelPosition='right' style={{cursor: 'default', pointerEvents: 'none', marginLeft: 15}}>
            <Button color={'green'}>
              Total
            </Button>
            <Label as='a' basic color='green' pointing='left'>
              {sumValues.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })}
            </Label>
          </Button>
        )
      }
      {
        Array.isArray(claimValues) && claimValues.length > 0 ? claimValues.map(item => {
          const { createdAt, dueDate, id, numberInstallment, totalInstallments, updatedAt, value, valueType } = item

          return (
            <Grid.Column key={id!} width={16}>
              <Segment style={{backgroundColor: '#fafafa', borderWidth: 4}}>
                <Grid>
                  <Grid.Row>
                    <Info name='ID do Valor' value={id} width={8}/>
                  </Grid.Row>
                  <Grid.Row>
                    <Info
                      name='Data de Criação'
                      value={
                        createdAt ?
                        moment(createdAt).subtract(3, 'h').format('DD/MM/YYYY HH:mm:ss') :
                        (<span style={{color: '#ccc'}}>Não Criado</span>)
                      }
                      width={8}
                    />
                    <Info
                      name='Última atualização'
                      value={
                        updatedAt ?
                        moment(updatedAt).subtract(3, 'h').format('DD/MM/YYYY HH:mm:ss') :
                        (<span style={{color: '#ccc'}}>Não houve atualização</span>)
                      }
                      width={8}
                    />
                  </Grid.Row>
                  <Grid.Row>
                    <Info name='Valor' value={Number(Number(value).toFixed(2)).toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })} width={8}/>
                    <Info name='Data de Vencimento' value={dueDate ? new Date(dueDate).toLocaleDateString('pt-BR') : (<span style={{color: '#ccc'}}>Não informado</span>)} width={8}/>
                  </Grid.Row>
                  <Grid.Row>
                    <Info name='Tipo de Valor' value={translateValuesType(valueType)} width={8}/>
                    { valueType === ClaimValuesTypesEnum.taxes_value && (
                      <Info name='Parcela' value={numberInstallment+' de '+totalInstallments} width={8}/>
                    )}
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          )
        }) : (
          <Grid.Row>
            <span style={{color: '#ccc', marginLeft: 5}}>Não há valores informados</span>
          </Grid.Row>
        )
      }
    </>
  )
}

export default ClaimDetailModal;
