import React from 'react';
import moment from 'moment';
import { Page, Document } from '@react-pdf/renderer';

import { styles } from './styles';
import { maskCpfOrCnpj, maskPhoneNumber, maskZipCode, numberToMoneyString } from '../../masks';
import BudgetHeader from './components/BudgetHeader';
import BudgetDataTable from './components/BudgetDataTable';
import TenantDataTable from './components/TenantDataTable';
import InsuredDataTable from './components/InsuredDataTable';
import PropertyDataTable from './components/PropertyDataTable';
import CoveragesDataTable from './components/CoveragesDataTable';
import InsurancePriceDataTable from './components/InsurancePriceDataTable';
import BudgetFooter from './components/BudgetFooter';
import { ModalitiesEnum } from './enums/modalities.enum';
import { PropertyTypeEnum } from './enums/property-type.enum';
import { OccupationTypeEnum } from './enums/occupation-type.enum';

const AlfaBudgetReport = ({ data }: any) => {
  const { fireInsurance } = data;
  const { propertyAddresses } = fireInsurance;

  const budgetHeader = {
    title: 'Orçamento Seguro Incêndio',
    estateName: data?.fireInsurance?.estate?.name || '---',
    budgetDate: data?.created_at || '---',
  };

  const budgetData = {
    locationType: fireInsurance?.locationType ? ModalitiesEnum[Number(fireInsurance?.locationType)] : '---',
    propertyType: fireInsurance?.propertyType ? PropertyTypeEnum[Number(fireInsurance?.propertyType)] : '---',
    propertyUseType: fireInsurance?.propertyUseType ? OccupationTypeEnum[Number(fireInsurance?.propertyUseType)] : '---',
    policyPeriodStart: data?.policyPeriodStart ? moment(data.policyPeriodStart).format('DD/MM/YYYY') : '---',
    policyPeriodEnd: data?.policyPeriodEnd ? moment(data.policyPeriodEnd).format('DD/MM/YYYY') : '---',
  };

  const tenantIsPJ = String(fireInsurance?.tenantDocument).length === 14 ? true : false;
  const tenantGender = tenantIsPJ ? '---'
    : fireInsurance?.tenantGender
      ? fireInsurance?.tenantGender === 'M'
        ? 'Maculino'
        : 'Feminino'
      : '---';

  const tenantData = {
    tenantIsPJ,
    tenantGender,
    tenantName: fireInsurance?.tenantName ?? '---',
    tenantDocument: fireInsurance?.tenantDocument ? maskCpfOrCnpj(fireInsurance?.tenantDocument) : '---',
    tenantBirthdate: fireInsurance?.tenantBirthdate ? moment(fireInsurance?.tenantBirthdate).format('DD/MM/YYYY') : '---',
    tenantEmail: fireInsurance?.tenantEmail ?? '---',
    tenantCellphone: fireInsurance?.tenantCellphone ? maskPhoneNumber(fireInsurance?.tenantCellphone) : '---',
  };

  const insuredData = {
    propertyOwnerIsPJ: String(fireInsurance?.propertyOwnerDocument).length === 14 ? true : false,
    propertyOwnerName: fireInsurance?.propertyOwnerName ?? '---',
    propertyOwnerDocument: fireInsurance?.propertyOwnerDocument ? maskCpfOrCnpj(fireInsurance?.propertyOwnerDocument) : '---',
  };

  const propertyData = {
    city: propertyAddresses?.city || '---',
    complement: propertyAddresses?.complement || '---',
    district: propertyAddresses?.district || '---',
    number: propertyAddresses?.number || '---',
    state: propertyAddresses?.state || '---',
    street: propertyAddresses?.street || '---',
    zipcode: propertyAddresses?.zipcode ? maskZipCode(propertyAddresses?.zipcode) : '---',
  };

  let coverageAmount = 0;

  const coverageData = data.coverages.map((coverage: any) => {
    coverageAmount += Number(coverage?.totalPremium);

    return {
      coverageName: coverage?.coverageName ?? '---',
      totalPremium: coverage?.totalPremium ? numberToMoneyString(Number(coverage?.totalPremium)) : '---',
      value: coverage?.value ? numberToMoneyString(Number(coverage?.value)) : '---',
      description: coverage?.description && coverage?.description !== '-' ? coverage?.description : null,
    };
  }).sort((a: any, b: any) => {
    const awi = a.coverageName.startsWith('Incêndio');
    const bwi = b.coverageName.startsWith('Incêndio');
    if (awi && !bwi) return -1;
    if (!awi && bwi) return 1;
    return 0;
  });

  const insurancePriceData = {
    coverageAmount: coverageAmount ? numberToMoneyString(coverageAmount) : '---',
    iof: data?.iof ? numberToMoneyString(Number(data.iof)) : '---',
    assistanceValue: data?.assistanceValue ? numberToMoneyString(Number(data.assistanceValue)) : '---',
    grossPremium: data?.grossPremium ? numberToMoneyString(Number(data.grossPremium)) : '---',
    installments: data?.installments ?? '---',
  };

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <BudgetHeader data={budgetHeader} />
        <BudgetDataTable data={budgetData} />
        <TenantDataTable data={tenantData} />
        <InsuredDataTable data={insuredData} />
        <PropertyDataTable data={propertyData} />
        <CoveragesDataTable data={coverageData} />
        <InsurancePriceDataTable data={insurancePriceData} />
        <BudgetFooter />
      </Page>
    </Document>
  );
};

export default AlfaBudgetReport;
