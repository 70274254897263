import {FireInsuranceProposal} from "../../types/FireInsurance";
import {FireInsuranceProposalsListReducer, FireInsuranceProposalsListState} from "../types/temp-types";

type FireInsuranceProposalsListRematchModel = {
  state: FireInsuranceProposalsListState;
  reducers: FireInsuranceProposalsListReducer;
}

const fireInsuranceProposalsList: FireInsuranceProposalsListRematchModel = {
  state: {
    proposals: Array<FireInsuranceProposal>(),
    onceRequested: false,
    isLoading: false,
    ascending: true
  },
  reducers: {
    updateFireInsuranceProposal: (state: FireInsuranceProposalsListState, updatedProposal: FireInsuranceProposal): FireInsuranceProposalsListState => {
      return {
        proposals: state.proposals?.map((proposal) => proposal.id === updatedProposal.id ? updatedProposal : proposal),
        ...state
      }
    },
    updateFireInsuranceProposalsList: (state: FireInsuranceProposalsListState, payload: FireInsuranceProposalsListState): FireInsuranceProposalsListState => payload
  }
};

export default fireInsuranceProposalsList;
