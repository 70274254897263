import React, { useEffect, useState } from 'react';
import { Table, Loader, Icon, Modal, Header, Dimmer, Segment } from 'semantic-ui-react';
import { getEventsByArrayRelatedId } from '../../../services/events';
import moment from 'moment-timezone';
import styled from 'styled-components';

type LogTableProps = {
  relatedIds: string[];
  createdAt: Date | string;
  updatedAt?: Date;
  context?: string;
};

const Eye = styled(Icon)`
  cursor: pointer;

  color: grey;

  &:hover {
    color: black;
    font-size: 20px;
    transition: all 0.2s ease-in-out;
  }
`;

const LogTable = ({ relatedIds, createdAt, updatedAt, context }: LogTableProps) => {
  const [data, setData] = useState<any[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [currentPayload, setCurrentPayload] = useState<any>({});
  const [currentCode, setCurrentCode] = useState<number | undefined>();

  useEffect(() => {
    async function getData() {
      try {
        if (relatedIds) {
          setIsLoadingData(true);
          const stringRealtedIds = relatedIds.join(',');
          const data = await getEventsByArrayRelatedId(
            createdAt,
            stringRealtedIds,
            updatedAt ? updatedAt : undefined,
            context
          );
          data.sort((a: any, b: any) => (a.code > b.code ? -1 : 1));
          setData(data);
          setIsLoadingData(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingData(false);
      }
    }
    getData()
  }, [relatedIds]);

  const openPayloadModal = (payload: any, code: number) => {
    setCurrentPayload(payload);
    setCurrentCode(code);
    setOpenModal(true);
  };

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Data</Table.HeaderCell>
            <Table.HeaderCell>Origem</Table.HeaderCell>
            <Table.HeaderCell>Label</Table.HeaderCell>
            <Table.HeaderCell>Related ID</Table.HeaderCell>
            <Table.HeaderCell>Mensagem</Table.HeaderCell>
            <Table.HeaderCell>Payload</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {isLoadingData ? (
            <Table.Row>
              <Table.Cell colSpan={6}>
                <Segment style={{ padding: 30 }}>
                  <Dimmer active>
                    <Loader inline />
                  </Dimmer>
                </Segment>
              </Table.Cell>
            </Table.Row>
          ) : (
            <>
              {data.length ? (
                data.map((item: any) => (
                  <Table.Row key={item.id}>
                    <Table.Cell textAlign={'center'}>
                      {moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                    </Table.Cell>
                    <Table.Cell textAlign={'center'}>{item.origin}</Table.Cell>
                    <Table.Cell textAlign={'center'}>{item.label}</Table.Cell>
                    <Table.Cell textAlign={'center'}>{item.relatedId}</Table.Cell>
                    <Table.Cell textAlign={'center'}>{item.content}</Table.Cell>
                    <Table.Cell textAlign={'center'}>
                      {item.payload ? (
                        <Eye
                          name={'eye'}
                          onClick={() => openPayloadModal(item.payload, item.code)}
                        />
                      ) : (
                        <Icon name={'ban'} style={{ cursor: 'no-drop' }} />
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={5} textAlign={'center'}>
                    Nenhum registro foi encontrado
                  </Table.Cell>
                </Table.Row>
              )}
            </>
          )}
        </Table.Body>
      </Table>
      <Modal
        closeIcon
        open={openModal}
        onClose={() => setOpenModal(false)}
        onOpen={() => setOpenModal(true)}
      >
        <Header
          icon="file alternate outline"
          content={`Detalhe do Payload #${currentCode}`}
        />
        <Modal.Content>
          {currentPayload ? (
            <Segment padded='very'>{JSON.stringify(currentPayload, null, 2)}</Segment>
          ) : (
            <Loader active inline="centered" />
          )}
        </Modal.Content>
      </Modal>
    </>
  );
};

export default LogTable;
