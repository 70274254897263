import { Grid, Icon, Input, InputOnChangeData, Popup } from 'semantic-ui-react';
import { Field } from 'formik';
import React, { ReactElement, SyntheticEvent } from 'react';
import { getErrorFormik } from '../../../../../../services/errors';
import SectionLabel from '../../../../../../components/SectionLabel';
import { FieldLabel } from '../../../../../../styles';
import { FormikErrorMessage } from '../../../../../../components/ErrorMessage';
import { formatAmount, maskPhoneNumber } from '../../../../../../services/masks';
import { StepProps } from '../../../../BondInsuranceForm';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../../../resources/css/styles.css';
import AppMaskedInput from '../../../../../../components/MaskedInput';
import CustomDatePicker from '../../../../../../components/CustomDatePicker';

const SECTION_NAME = 'tenant_info';

export const IdentificationSection = (props: StepProps): ReactElement => {
  const {
    errors,
    touched,
    handleInputChange,
    handleDateChange,
    values,
    setFieldTouched,
    setFieldValue
  } = props;

  const { tenant_info } = values;
  const validateErros = (field: string) =>
    getErrorFormik(errors, touched, SECTION_NAME, field);

  return (
    <>
      <SectionLabel text="IDENTIFICAÇÃO DA EMPRESA" />
      <Grid.Row>
        <Grid.Column width={4}>
          <FieldLabel>CNPJ*</FieldLabel>
          <Field
            name={`${SECTION_NAME}.document`}
            value={tenant_info.document}
            error={validateErros('document')}
            component={Input}
            disabled
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.document`} />
        </Grid.Column>

        <Grid.Column width={8}>
          <FieldLabel>EMPRESA*</FieldLabel>
          <Field
            name={`${SECTION_NAME}.company`}
            value={tenant_info.company}
            onChange={handleInputChange}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.company`, true)}
            customInput={<Input />}
            error={validateErros('company')}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.company`} />
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>TELEFONE*</FieldLabel>
          <Field
            name={`${SECTION_NAME}.phone`}
            value={tenant_info.phone}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
              data: InputOnChangeData
            ): void => {
              data.value = maskPhoneNumber(data.value);
              handleInputChange(e, data);
            }}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.phone`, true)}
            error={validateErros('phone')}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.phone`} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={4}>
          <FieldLabel>
            EMAIL*
            <Popup
              content={
                'Atenção, informar o e-mail correto do locatário para que ele receba mensagens da Seguradora, principalmente se contratar Porto Seguro para receber o link da biometria facial.'
              }
              position={'bottom right'}
              trigger={
                <Icon
                  name={'info circle'}
                  color={'blue'}
                  style={{ cursor: 'help', marginLeft: 5 }}
                />
              }
            />
          </FieldLabel>
          <Field
            name={`${SECTION_NAME}.email`}
            value={tenant_info.email}
            onChange={handleInputChange}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.email`, true)}
            customInput={<Input />}
            error={validateErros('email')}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.email`} />
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>DATA DE CONSTITUIÇÃO*</FieldLabel>
          <Field
            name={`${SECTION_NAME}.foundation_date`}
            error={errors.tenant_info?.foundation_date}
            selected={tenant_info.foundation_date}
            component={CustomDatePicker}
            customInput={
              <AppMaskedInput
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/
                ]}
              />
            }
            dateFormat={'dd/MM/yyyy'}
            onChange={(date: Date, e: SyntheticEvent) => {
              handleDateChange(`${SECTION_NAME}.foundation_date`, date, e);
            }}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.foundation_date`, true)}
            className={validateErros('foundation_date')}
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.foundation_date`} />
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>ÚLT. ALT. CONTRATUAL*</FieldLabel>
          <Field
            name={`${SECTION_NAME}.last_contractual_alteration_date`}
            error={errors.tenant_info?.last_contractual_alteration_date}
            selected={tenant_info.last_contractual_alteration_date}
            component={CustomDatePicker}
            customInput={<Input />}
            dateFormat={'dd/MM/yyyy'}
            onChange={(date: Date, e: SyntheticEvent) => {
              handleDateChange(
                `${SECTION_NAME}.last_contractual_alteration_date`,
                date,
                e
              );
            }}
            onBlur={() =>
              setFieldTouched(`${SECTION_NAME}.last_contractual_alteration_date`, true)
            }
            className={validateErros('last_contractual_alteration_date')}
          />
          <FormikErrorMessage
            component="div"
            name={`${SECTION_NAME}.last_contractual_alteration_date`}
          />
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>CAPITAL SOCIAL*</FieldLabel>
          <Field
            name={`${SECTION_NAME}.share_capital`}
            value={tenant_info.share_capital}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
              data: InputOnChangeData
            ): void => {
              data.value = formatAmount(data.value);
              handleInputChange(e, data);
            }}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.share_capital`, true)}
            error={validateErros('share_capital')}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.share_capital`} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={4}>
          <FieldLabel>CAPITAL INTEGRALIZADO*</FieldLabel>
          <Field
            name={`${SECTION_NAME}.paid_in_capital`}
            value={tenant_info.paid_in_capital}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
              data: InputOnChangeData
            ): void => {
              data.value = formatAmount(data.value);
              handleInputChange(e, data);
            }}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.paid_in_capital`, true)}
            error={validateErros('paid_in_capital')}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.paid_in_capital`} />
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>FATURAMENTO ANUAL*</FieldLabel>
          <Field
            name={`${SECTION_NAME}.anual_revenue`}
            value={tenant_info.anual_revenue}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
              data: InputOnChangeData
            ): void => {
              data.value = formatAmount(data.value);
              handleInputChange(e, data);
            }}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.anual_revenue`, true)}
            error={validateErros('anual_revenue')}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.anual_revenue`} />
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>CONTATO (NOME)*</FieldLabel>
          <Field
            name={`${SECTION_NAME}.contact_name`}
            value={tenant_info.contact_name}
            onChange={handleInputChange}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.contact_name`, true)}
            customInput={<Input />}
            error={validateErros('contact_name')}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.contact_name`} />
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>
            CONTATO (TELEFONE)*
            <Popup
              content={
                'Atenção, informar o telefone correto do locatário para que ele receba mensagens da Seguradora, principalmente se contratar Porto Seguro para receber o link da biometria facial.'
              }
              position={'bottom right'}
              trigger={
                <Icon
                  name={'info circle'}
                  color={'blue'}
                  style={{ cursor: 'help', marginLeft: 5 }}
                />
              }
            />
          </FieldLabel>
          <Field
            name={`${SECTION_NAME}.contact_phone`}
            // defaultValue={maskPhoneNumber('00000000000')}
            value={tenant_info.contact_phone}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
              data: InputOnChangeData
            ): void => {
              data.value = maskPhoneNumber(data.value);
              handleInputChange(e, data);
            }}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.contact_phone`, true)}
            customInput={<Input />}
            error={validateErros('contact_phone')}
            component={Input}
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.contact_phone`} />
        </Grid.Column>
      </Grid.Row>
    </>
  );
};
