import { Grid, Input, InputOnChangeData, StepProps } from "semantic-ui-react";
import { Field } from "formik";
import React, { ReactElement, SyntheticEvent, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../../../resources/css/styles.css";
import { employmentRelationshipOptions } from "../../../../types";
import { getErrorFormik } from "../../../../../../services/errors";
import SectionLabel from "../../../../../../components/SectionLabel";
import { FieldLabel } from "../../../../../../styles";
import { FormikErrorMessage } from "../../../../../../components/ErrorMessage";
import { formatAmount } from "../../../../../../services/masks";
import Dropdown from "../../../../../../components/Dropdown";
import { NaturalTenant } from "../../../../../../types/NaturalTenant";
import { EmploymentRelationshipEnum } from "../../../../../../enums/employment-relationship.enum";
import AppMaskedInput from "../../../../../../components/MaskedInput";
import CustomDatePicker from "../../../../../../components/CustomDatePicker";
const SECTION_NAME = "tenant_info";

export const ProfissionalInformationSection = (
  props: StepProps
): ReactElement => {
  const {
    errors,
    touched,
    handleInputChange,
    handleDateChange,
    values,
    setFieldTouched,
  } = props;

  const tenant_info = values.tenant_info as NaturalTenant;

  useEffect(() => { }, []);

  const validateErros = (field: string) =>
    getErrorFormik(errors, touched, SECTION_NAME, field);

  return (
    <>
      <SectionLabel text="INFORMAÇÕES PROFISSIONAIS" />

      <Grid.Row>
        <Grid.Column width={8}>
          <FieldLabel paddingTop={true}>VÍNCULO EMPREGATÍCIO* </FieldLabel>
          <Field
            id={`${SECTION_NAME}.employment_relationship`}
            name={`${SECTION_NAME}.employment_relationship`}
            value={tenant_info.employment_relationship}
            completeValue={true}
            placeholder={"Selecionar..."}
            options={employmentRelationshipOptions}
            component={Dropdown}
            onChange={handleInputChange}
            onClose={() =>
              setFieldTouched(`${SECTION_NAME}.employment_relationship`, true)
            }
            error={validateErros("employment_relationship")}
            fluid
          />
          <FormikErrorMessage
            component="div"
            name={`${SECTION_NAME}.employment_relationship`}
          />
        </Grid.Column>
        {(tenant_info.employment_relationship ===
          EmploymentRelationshipEnum.privateServant ||
          tenant_info.employment_relationship ===
          EmploymentRelationshipEnum.civilServant) && (
            <>
              <Grid.Column width={8}>
                <FieldLabel>DATA DE ADMISSÃO*</FieldLabel>
                <Field
                  id={`${SECTION_NAME}.employment_admission_date`}
                  name={`${SECTION_NAME}.employment_admission_date`}
                  error={errors.tenant_info?.employment_admission_date}
                  selected={tenant_info.employment_admission_date}
                  component={CustomDatePicker}
                  customInput={
                    <AppMaskedInput
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        "/",
                        /[0-9]/,
                        /[0-9]/,
                        "/",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                      ]}
                    />
                  }
                  dateFormat={"dd/MM/yyyy"}
                  onChange={(date: Date, e: SyntheticEvent) => {
                    handleDateChange(
                      `${SECTION_NAME}.employment_admission_date`,
                      date,
                      e
                    );
                  }}
                  onBlur={() =>
                    setFieldTouched(
                      `${SECTION_NAME}.employment_admission_date`,
                      true
                    )
                  }
                  className={validateErros("employment_admission_date")}
                />
                <FormikErrorMessage
                  component="div"
                  name={`${SECTION_NAME}.employment_admission_date`}
                />
              </Grid.Column>
            </>
          )}
      </Grid.Row>
       <Grid.Row>
        <Grid.Column width={4}>
          <FieldLabel>PROFISSÃO*</FieldLabel>
          <Field
            id={`${SECTION_NAME}.main_tenant_profession`}
            name={`${SECTION_NAME}.main_tenant_profession`}
            value={tenant_info.main_tenant_profession}
            onChange={handleInputChange}
            onBlur={() =>
              setFieldTouched(`${SECTION_NAME}.main_tenant_profession`, true)
            }
            error={validateErros("main_tenant_profession")}
            component={Input}
            fluid
          />
          <FormikErrorMessage
            component="div"
            name={`${SECTION_NAME}.main_tenant_profession`}
          />
        </Grid.Column>
        </ Grid.Row>
        {/*
        <Grid.Column width={4}>
          <FieldLabel>RENDA MENSAL*</FieldLabel>
          <Field
            name={`${SECTION_NAME}.main_tenant_monthly_fixed_income`}
            value={tenant_info.main_tenant_monthly_fixed_income}
            component={Input}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
              data: InputOnChangeData
            ): void => {
              data.value = formatAmount(data.value);
              handleInputChange(e, data);
            }}
            onBlur={(e: React.FocusEvent<any>) => {
              setFieldTouched(
                `${SECTION_NAME}.main_tenant_monthly_fixed_income`,
                true
              );
            }}
            error={validateErros("main_tenant_monthly_fixed_income")}
            fluid
          />
          <FormikErrorMessage
            component="div"
            name={`${SECTION_NAME}.main_tenant_monthly_fixed_income`}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <FieldLabel>OUTRAS RENDAS</FieldLabel>
          <Field
            name={`${SECTION_NAME}.main_tenant_other_incomes`}
            value={tenant_info.main_tenant_other_incomes}
            component={Input}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
              data: InputOnChangeData
            ): void => {
              data.value = formatAmount(data.value);
              handleInputChange(e, data);
            }}
            onBlur={(e: React.FocusEvent<any>) => {
              setFieldTouched(
                `${SECTION_NAME}.main_tenant_other_incomes`,
                true
              );
            }}
            error={validateErros("main_tenant_other_incomes")}
            fluid
          />
          <FormikErrorMessage
            component="div"
            name={`${SECTION_NAME}.main_tenant_other_incomes`}
          />
        </Grid.Column>
      </Grid.Row>  */}
    </>
  );
};
