import styled from 'styled-components';
import GradientBanner from '../../resources/imgs/degrade-background.png';
import BannerImage3 from '../../resources/imgs/banner-image3cap.png';
import { Icon } from 'semantic-ui-react';

export const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: row;
`;

export const InfoBanner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const AppLabel = styled.label`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  color: #2185d0;
`;

export const AppsContainer = styled.label`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
`;

export const AppCard = styled.a`
  position: relative;
  display: flex;
  flex-direction: row;
  border: 1px solid #dddddd;
  border-radius: 6px;
  align-items: center;
  justify-content: start;
  width: 100%;
  min-height: 68px;
  padding: 4px 20px 5px 5px;
  gap: 10px;
  span {
    color: #808080;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
  }
`;

export const IconRight = styled(Icon)`
  position: absolute;
  right: 5px;
`;

export const WelcomeTitle = styled.span`
  color: #2185d0;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

  b {
    font-size: 38px;
    line-height: 51px;
    margin-top: -10px;
  }
`;

export const WelcomeSubtitle = styled.span`
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  color: #808080;
`;

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #2185d0;
  padding-bottom: 5px;

  h2 {
    font-size: 28px;
    font-weight: 800;
    color: #2185d0;
  }
`;

export const ButtonsWrapper = styled.div<{
  align?: 'between' | 'no-align';
}>`
  display: flex;
  justify-content: ${props => (props.align === 'between' ? 'space-between' : 'start')};
  gap: 10px;
  @media (max-width: 768px) {
    width: 100%;
    button {
      display: flex;
      flex: 1;
      text-align: center;
      margin: 0px !important; // To override the lib style
    }
  }
`;

export const BannersWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  width: 100%;
`;

export const Banner = styled.div`
  flex: 1;
  height: 288px;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 7fr 5fr;
`;

export const GradientImage = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  background-image: url(${GradientBanner});
  background-size: contain;
  background-repeat: no-repeat;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), 10% #1cb9df);
  }
`;

export const GradientImageBanner2 = styled.div`
  position: relative;
  background-image: url(${BannerImage3});
  background-size: cover;
  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to right,
      rgba(52, 152, 219, 1),
      rgba(20, 60, 87, 0)
    );
  }
`;

export const CapitalizeContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 82%;
  height: 100%;
  padding: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 1;
  background-color: transparent;
`;

export const CapitalizeText = styled.span`
  font-size: 26px;
  color: #ffffff;
  padding-right: 180px;
  line-height: 26px;
`;

export const CapitalizeButton = styled.button`
  padding: 16px;
  background-color: #ffffff;
  color: #3498db;
  border: 0;
  border-radius: 5px;
  font-size: 14px;
  letter-spacing: 0.02em;
  width: 152px;
  cursor: pointer;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 10px 24px;
  justify-content: center;
  gap: 5px;
  height: 288px;

  h3 {
    font-weight: 800;
    font-size: 24px;
    color: #2b313c;
    z-index: 3;
    margin: 0;
  }

  span {
    background-color: #e69e0d;
    padding: 12px 24px;
    text-align: center;
    font-size: 22px;
    font-weight: 800;
    z-index: 3;
    margin: 0;
  }

  p {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    z-index: 3;
    margin: 0;
  }

  a {
    color: #ffffff;
    background-color: #2b313c;
    padding: 8px 6px;
    border-radius: 4px;
    z-index: 3;
    font-size: 24px;
    margin-top: 10px;
  }

  .building-background-image {
    position: absolute;
    height: auto;
    width: 100%;
    z-index: 2;
    top: 10px;
  }

  .logo {
    width: 100px;
    height: auto;
    margin-top: 10px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .laptop-image {
    width: 420px;
    height: auto;
    position: absolute;
    top: -10px;
    left: 10px;
    z-index: 3;
  }
`;
