import { Grid, Input, InputOnChangeData, ButtonProps, Button } from 'semantic-ui-react';
import { Field, FieldArray, FieldArrayRenderProps } from 'formik';
import React, { ReactElement, SyntheticEvent, useState } from 'react';
import { getErrorFormik } from '../../../../../../services/errors';
import SectionLabel from '../../../../../../components/SectionLabel';
import { FieldLabel } from '../../../../../../styles';
import { FormikErrorMessage } from '../../../../../../components/ErrorMessage';
import { maskCpf, unmaskCpfOrCnpj } from '../../../../../../services/masks';
import { StepProps } from '../../../../BondInsuranceForm';
import "react-datepicker/dist/react-datepicker.css";
import "../../../../../../resources/css/styles.css";
import { getDataByDocument } from '../../../../../../services/documents';
import { dangerNotification } from '../../../../../../services/notification';
import AppMaskedInput from '../../../../../../components/MaskedInput';
import CustomDatePicker from '../../../../../../components/CustomDatePicker';

const SECTION_NAME = 'tenant_info';

export const PartnersSection = (props: StepProps): ReactElement => {

  const {
    errors,
    touched,
    handleInputChange,
    handleDateChange,
    values,
    setFieldTouched,
    setFieldValue } = props;

  const { tenant_info } = values;

  const [cpfIsLoading, setCpfIsLoading] = useState<boolean>(false);

  const onChangeCpf = (index: number, e: React.ChangeEvent<HTMLInputElement>): void => {
    const unmaskedValue = unmaskCpfOrCnpj(e.target.value);

    if (unmaskedValue.length >= 11) {
      setCpfIsLoading(true);

      getDataByDocument(unmaskedValue).then((data) => {
        setFieldValue(`${SECTION_NAME}.partners[${index}].name`, data.name);
      }).catch((e) => {
        dangerNotification('Oops...', 'Não foi possível encontrar dados associados ao documento informado')
      }).finally(() => {
        setCpfIsLoading(false);
      })
    }
  };

  const validateErros = (field: string) => getErrorFormik(errors, touched, SECTION_NAME, field);

  const AddPartnerButton = (props: ButtonProps): ReactElement => {
    return (
      <Button color="green" type="button" {...props}>Adicionar</Button>
    );
  }

  return (
    <>
      <SectionLabel text='ACIONISTAS/SÓCIOS/TITULAR' />
      <FieldArray
        name={`tenant_info.partners`}
        render={(arrayHelper: FieldArrayRenderProps) => (
          <>
            {tenant_info.partners.map((partner, index) => {
              return (
                <>
                  <Grid.Row >

                    <Grid.Column width={4}>
                      <FieldLabel>CPF*</FieldLabel>
                      <Field
                        loading={cpfIsLoading}
                        name={`${SECTION_NAME}.partners[${index}].document`}
                        value={tenant_info.partners[index].document}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
                          data.value = maskCpf(data.value);
                          handleInputChange(e, data);
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                          setFieldTouched(`${SECTION_NAME}.partners[${index}].document`, true)
                          onChangeCpf(index, e);
                        }}
                        error={validateErros(`partners[${index}].document`)}
                        component={Input}
                        fluid />
                      <FormikErrorMessage component="div" name={`${SECTION_NAME}.partners[${index}].document`} />
                    </Grid.Column>

                    <Grid.Column width={6}>
                      <FieldLabel>NOME*</FieldLabel>
                      <Field
                        name={`${SECTION_NAME}.partners[${index}].name`}
                        value={tenant_info.partners[index].name}
                        onChange={handleInputChange}
                        onBlur={() => setFieldTouched(`${SECTION_NAME}.partners[${index}].name`, true)}
                        customInput={<Input />}
                        error={validateErros(`partners[${index}].name`)}
                        component={Input}
                        fluid />
                      <FormikErrorMessage component="div" name={`${SECTION_NAME}.partners[${index}].name`} />
                    </Grid.Column>

                    <Grid.Column width={3}>
                      <FieldLabel>% DO CAPITAL SOCIAL*</FieldLabel>
                      <Field
                        name={`${SECTION_NAME}.partners[${index}].percent`}
                        value={tenant_info.partners[index].percent}
                        onChange={handleInputChange}
                        onBlur={() => setFieldTouched(`${SECTION_NAME}.partners[${index}].percent`, true)}
                        error={validateErros(`partners[${index}].percent`)}
                        component={Input}
                        fluid />
                      <FormikErrorMessage component="div" name={`${SECTION_NAME}.partners[${index}].percent`} />
                    </Grid.Column>

                    <Grid.Column width={3}>
                      <FieldLabel>DATA DE ENTRADA*</FieldLabel>
                      <Field
                        name={`${SECTION_NAME}.partners[${index}].date_of_entry`}
                        value={tenant_info.partners[index].date_of_entry}
                        selected={tenant_info.partners[index].date_of_entry}
                        component={CustomDatePicker}
                        customInput={<AppMaskedInput
                          mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                        />}
                        dateFormat={'dd/MM/yyyy'}
                        onChange={(date: Date, e: SyntheticEvent) => {
                          handleDateChange(`${SECTION_NAME}.partners[${index}].date_of_entry`, date, e)
                        }}
                        onBlur={() => setFieldTouched(`${SECTION_NAME}.partners[${index}].date_of_entry`, true)}
                        error={validateErros(`partners[${index}].date_of_entry`)}
                      />
                      <FormikErrorMessage component="div" name={`${SECTION_NAME}.partner_date_of_entry`} />
                    </Grid.Column>
                  </Grid.Row>

                </>
              )
            })}
            <Grid.Row>
              <AddPartnerButton onClick={() => {
                arrayHelper.push({ name: '', document: '', percent: 0, date_of_entry: '' })
              }} />
            </Grid.Row>
          </>

        )}
      />
    </>
  )
};
