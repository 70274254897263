import { BrokerState } from "./types/BrokerState";
import { CustoDropDownItemProps } from "./components/types";
import { EstateState } from "./types/EstateState";
import { InsurerState } from "./types/InsurerState";
import { FranchisesBasicState } from "./types/FranchiseState";
import { RolesBasicState, TranslatedBrokerRolesEnum } from "./types/Roles";
import moment from "moment-timezone";
import { CustomDropDownOptions } from "./types";
import { UserRolesEnum } from "./enums/user-roles.enum";
import { UserStatusEnum } from "./enums/user-status.enum";
import { useEffect, useState } from "react";
import { menuOptions } from "./services/menu";

export const EMPTY_DATE = new Date('2000-01-01')
export const IOF = 0.0738;


export function PushRoute(history: any, reduxPropos: any, route: string, params?: any) {
  let selectedMenu: any;
  for (const item of menuOptions) {
    if (item.route === route) {
      selectedMenu = item;
      break;
    }
    for (const subItem of item.submenuItems || []) {
      for (const subSubItem of subItem.submenus || []) {
        if (subSubItem.route === route) {
          selectedMenu = subSubItem;
          break;
        }
      }
      if (selectedMenu) {
        break;
      }
    }
    if (selectedMenu) {
      break;
    }
  }

  if (selectedMenu) {
    if (reduxPropos.currentPage) {
      reduxPropos.updateCurrentPage(selectedMenu);
    }
    history.push('./' + route, params);
  }
}

export const allDataAreFilled = (data: any, exceptions?: Array<string>) => {
  if (!data || !Object.entries(data).length) {
    return false;
  }

  const dataCopy = { ...data };

  if (exceptions) {
    exceptions.forEach(exception => {
      if (exception in dataCopy) {
        delete dataCopy[exception];
      }
    });
  }
  return Object.keys(dataCopy).every(key => {
    const value = dataCopy[key];
    if (value === null || value === '' || value === EMPTY_DATE) {
      // console.log('Missing field: ', key);
    }
    return value !== null && value !== '' && value !== EMPTY_DATE && value !== '0,00';
  });
}

export const BrokersToCustomDropDownOptions = (completeValue: boolean, brokers: BrokerState[]) => {
  return brokers.map((broker: BrokerState, index: number): CustoDropDownItemProps => {
    return {
      value: completeValue ? { ...broker } : broker.id,
      key: index,
      text: broker.name
    }
  })
}

export const EstatesToCustomDropDownOptions = (completeValue: boolean, estates: EstateState[]) => {
  return estates.map((estate: EstateState, index: number): CustoDropDownItemProps => {
    return {
      value: completeValue ? { ...estate } : estate.id,
      key: index,
      text: estate.name
    }
  });
}

export const InsurersToCustomDropDownOptions = (completeValue: boolean, insurers: InsurerState[]) => {
  return insurers.map((insurer: InsurerState, index: number): CustoDropDownItemProps => {
    return {
      value: completeValue ? { ...insurer } : insurer.id,
      key: index,
      text: insurer.name
    }
  });
}

export const RolesToCustomDropDownOptions = (roles: RolesBasicState[]) => {
  const brokerRoles = roles.filter(role => role.name !== UserRolesEnum.estateAdmin && role.name !== UserRolesEnum.estateUser);
  return brokerRoles.map((role: RolesBasicState, index: number): CustoDropDownItemProps => {
    return {
      value: role.name,
      key: index,
      text: translateUserRole(role.name)
    }
  });
}

export const FranchisesToCustomDropDownOptions = (franchises: FranchisesBasicState[]) => {
  return franchises.map((franchise: FranchisesBasicState, index: number): CustoDropDownItemProps => {
    return {
      value: franchise.id,
      key: index,
      text: franchise.name
    }
  });
}

export const UsersToCustomDropDownOptions = (completeValue: boolean, users: any[]) => {
  return users.map((user: any, index: number): CustoDropDownItemProps => {
    return {
      value: completeValue ? { ...user } : user.id,
      key: index,
      text: user.name
    }
  })
}

export const addMonthToDate = (date: Date | string, months: number): Date => {
  return moment(date).add(months, 'months').toDate();
}

export const subtractMonthToDate = (date: Date | string, months: number): Date => {
  return moment(date).subtract(months, 'months').toDate();
}

export const sortAssistanceOptionsByValue = (options: Array<CustomDropDownOptions>): Array<CustomDropDownOptions> => {
  return options.sort((a, b) => (a.value.value > b.value.value) ? 1 : ((b.value.value > a.value.value) ? -1 : 0));
}

export const round = (value: number, decimals: number): number => Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);

export const translateUserStatus = (status: string): string => {
  switch (status) {
    case UserStatusEnum.active:
      return 'ATIVO';
    case UserStatusEnum.inactive:
      return 'INATIVO';
    case UserStatusEnum.pending:
      return 'AGUARDANDO CONFIRMAÇÃO'
    default:
      return 'INDEFINIDO';
  }
}

export const translateUserRole = (role: string): string => {
  switch (role) {
    case UserRolesEnum.brokerAdmin:
      return TranslatedBrokerRolesEnum.brokerAdmin;
    case UserRolesEnum.brokerAnalyst:
      return TranslatedBrokerRolesEnum.brokerAnalyst;
    case UserRolesEnum.accountManager:
      return TranslatedBrokerRolesEnum.accountManager;
    case UserRolesEnum.claimAnalyst:
      return TranslatedBrokerRolesEnum.claimAnalyst;
    case UserRolesEnum.cancelationAnalyst:
      return TranslatedBrokerRolesEnum.cancelationAnalyst;
    case UserRolesEnum.franchiseAdmin:
      return TranslatedBrokerRolesEnum.franchiseAdmin;
    case UserRolesEnum.estateAdmin:
      return TranslatedBrokerRolesEnum.estateAdmin;
    case UserRolesEnum.estateUser:
      return TranslatedBrokerRolesEnum.estateUser;
    default:
      return 'INDEFINIDO';
  }
}

export const setAllStepTouched = (errors: any, step: string) => {
  const touched: any = {}
  touched[step] = {}
  Object.keys(errors).map(key => {
    touched[step][key] = true;
  });
  return touched;
};

export const FormatCurrency = (n: number | undefined) => {
  const v = n || 0;
  const currency = new Intl.NumberFormat('pt-br', {
    minimumFractionDigits: 2
  }).format(v)
  return currency
}

export const UnMaskCurrency = (value: string) => {
  const onlyDigits = value
    .split('')
    .filter((s: any) => /\d/.test(s))
    .join('')
    .padStart(2, '0')
  const digitsFloat = onlyDigits.slice(0, -2) + '.' + onlyDigits.slice(-2)
  return Number(digitsFloat)
}


export const isEmptyObject = (obj: any): boolean => {
  return Object.keys(obj).length === 0;
}

export const dateDiffInYears = (dateold: Date, datenew: Date) => {
  const ynew = datenew.getFullYear();
  const mnew = datenew.getMonth();
  const dnew = datenew.getDate();
  const yold = dateold.getFullYear();
  const mold = dateold.getMonth();
  const dold = dateold.getDate();
  let diff = ynew - yold;
  if (mold > mnew) diff--;
  else {
    if (mold == mnew) {
      if (dold > dnew) diff--;
    }
  }
  return diff;
}

export function monthDiff(dateFrom: Date, dateTo: Date) {
  return dateTo.getMonth() - dateFrom.getMonth() +
    (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}

export function daysDiff(a: Date, b: Date) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

export function diffHours(a: Date, b: Date) {
  const utc1 = a.getTime()
  const utc2 = b.getTime()
  const MS_PER_HOUR = 1000 * 60 * 60;
  return (utc2 - utc1) / MS_PER_HOUR;
}

export function findObjectInArray(array: any[], key: string, value: string) {
  return array.find((obj: any) => obj[key] === value);
}
export function isOlderDate(base: Date, compare: Date) {
  const baseYear = base.getFullYear();
  const baseMonth = base.getMonth();
  const baseDay = base.getDate();
  const compareYear = compare.getFullYear();
  const compareMonth = compare.getMonth();
  const compareDay = compare.getDate();

  if (baseYear !== compareYear && compareYear < baseYear) {
    return true
  } else {
    if (baseMonth !== compareMonth && compareMonth < baseMonth) {
      return true
    } else {
      if (baseDay !== compareDay && compareDay < baseDay) {
        return true
      } else {
        return false
      }
    }
  }
}

export function cpfValidity(value: string) {
  const cpf = value.replace(/[^\d]+/g, '')
  if (cpf == '') return false
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999'
  )
    return false
  // Valida 1o digito
  let add = 0
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i)
  let rev = 11 - (add % 11)
  if (rev == 10 || rev == 11) rev = 0
  if (rev !== parseInt(cpf.charAt(9))) return false
  // Valida 2o digito
  add = 0
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i)
  rev = 11 - (add % 11)
  if (rev == 10 || rev == 11) rev = 0
  if (rev !== parseInt(cpf.charAt(10))) return false
  return true
}

export function cnpjValidity(cnpj: any) {
  cnpj = cnpj.replace(/[^\d]+/g, '')

  if (cnpj == '') return false

  if (cnpj.length !== 14) return false

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  )
    return false

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  const digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado != digitos.charAt(0)) return false

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado != digitos.charAt(1)) return false

  return true
}

export function isEmail(text: string) {
  let arroba = '@',
    ponto = '.',
    posponto = 0,
    posarroba = 0

  if (text == '') return false

  for (let indice = 0; indice < text.length; indice++) {
    if (text.charAt(indice) == arroba) {
      posarroba = indice
      break
    }
  }

  for (let indice = posarroba; indice < text.length; indice++) {
    if (text.charAt(indice) == ponto) {
      posponto = indice
      break
    }
  }
  if (posponto == 0 || posarroba == 0) return false
  if (posponto == posarroba + 1) return false
  if (posponto + 1 == text.length) return false
  return true
}

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function add365Days(date: Date) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 365);
  return newDate;
}
export function useDebounce(fn: any, delay: number) {
  const [debounced, setDebounced] = useState(fn);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounced(fn);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [fn, delay]);
  return debounced;
}


