import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Button, Dimmer, Dropdown, Grid, Icon, Input, Loader, Modal, Step } from 'semantic-ui-react';
import { RenewRequestModalProps, FormProps } from './types';
import {
  Container,
  Header,
  Line,
  FormContainer,
  FormTitle,
  Form,
  ButtonContainer,
  StepContainer,
  Disclaimer
} from './styles';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import AppMaskedInput from '../../../../components/MaskedInput';
import { formatAmount, maskCpfOrCnpj, maskMoney } from '../../../../services/masks';
import { warningNotification } from '../../../../services/notification';
import { iRootState } from '../../../../store';
import { connect } from 'react-redux';
import ModalAttachFiles from '../../../../components/ModalAttachFiles';
import { DateToStringDate } from '../../../../services/date';
import InfoBanner from './InfoBanner';
import { Formik } from 'formik';

const mapState = (state: iRootState) => ({
  user: state.user
});

export const FileIdentifiers = [
  {
    name: 'ADITIVO DE CONTRATO',
    key: 'CONTRACT_ADDITIVE',
    required: true,
  }
];

const initialValues = {
  rentValue: '',
  energyBill: '',
  gasBill: '',
  waterBill: '',
  taxesValue: '',
  condominiumFee: '',
  startContract: undefined,
  endContract: undefined,
  startVigency: undefined,
  endVigency: undefined,
  renovationPeriod: ''
};

const RenewRequestModal: React.FC<
  RenewRequestModalProps & ReturnType<typeof mapState>
> = ({
  isOpen,
  onClose,
  handleSubmit,
  bondInsuranceId,
  bondInsuranceHiredDataId,
  policy,
  hasClaim
}: RenewRequestModalProps & ReturnType<typeof mapState>) => {
    const [isValidForm, setIsValidForm] = useState<boolean>(false);
    const [step, setStep] = useState<1 | 2>(1);
    const [hasContractAdditive, setHasContractAdditive] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      modalRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, [step]);

    const getFormIsValid = (values: FormProps) => {
      return (
        values.rentValue !== '' &&
        values.startContract &&
        values.endContract &&
        values.renovationPeriod
      );
    };

    const getMonthsOptions = (insurer: string) => {
      let options = Array.from(Array(60).keys()).map(month => {
        return {
          key: month + 1,
          text: `${month + 1} ${month >= 1 ? 'Meses' : 'Mês'}`,
          value: String(month + 1)
        };
      });

      if (insurer === 'GARANTTI') {
        options = options.splice(11);
      }

      return options;
    };

    if (!isOpen) {
      return null;
    }

    const handleFormSubmit = async (values: FormProps) => {
      if (step === 1) {
        setStep(2);
      } else if (getFormIsValid(values)) {
        setIsValidForm(true);
        if (!hasContractAdditive && (values.renovationPeriod && +values.renovationPeriod !== 12)) {
          warningNotification(
            'Campos obrigatórios',
            'Por favor, anexe o aditivo de contrato.'
          );
          return;
        }
        setLoading(true);

        await handleSubmit({
          ...values,
          bondInsuranceHiredDataId
        });
        setLoading(false);

      } else {
        warningNotification(
          'Campos obrigatórios',
          'Por favor, preencha todos os campos obrigatórios.'
        );
      }
    };

    const renderPurposeView = (): React.ReactNode => {
      const premium = String(policy.values.totalPremiumAmount) !== ('0.00' || '0,00') ? String(policy.values.totalPremiumAmount) : String(+policy.values.hiredInstallments * +policy.values.hiredInstallmentsValue);
      return (
        <Grid>
          <Grid.Row>
            <Grid.Column width={5}>
              <label>SEGURADORA</label>
              <p>{policy?.insurer?.name}</p>
            </Grid.Column>
            <Grid.Column width={5}>
              <label>CÓDIGO DA APÓLICE</label>
              <p>{policy?.code ?? 'Não informado'}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <label>IMOBILIÁRIA</label>
              <p>{policy?.estate?.name}</p>
            </Grid.Column>
            <Grid.Column width={5}>
              <label>INÍCIO DO CONTRATO</label>
              <p>{DateToStringDate(policy?.property?.rental_period_start_date)}</p>
            </Grid.Column>
            <Grid.Column width={5}>
              <label>FIM DO CONTRATO</label>
              <p>{DateToStringDate(policy?.property?.rental_period_end_date)}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <label>DATA DE CONTRATAÇÃO</label>
              <p>{DateToStringDate(policy?.insurerProposalCreatedAt)}</p>
            </Grid.Column>
            <Grid.Column width={5}>
              <label>INÍCIO DA VIGÊNCIA</label>
              <p>
                {DateToStringDate(policy?.property?.rent_insurance_contract_start_date)}
              </p>
            </Grid.Column>
            <Grid.Column width={5}>
              <label>FIM DA VIGÊNCIA</label>
              <p>{DateToStringDate(policy?.property?.rent_insurance_contract_end_date)}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <label>NÚMERO DE PARCELAS</label>
              <p>{policy?.values?.hiredInstallments}</p>
            </Grid.Column>
            <Grid.Column width={5}>
              <label>VALOR DAS PARCELAS</label>
              <p>R$ {maskMoney(String(policy?.values?.hiredInstallmentsValue), true, 2)}</p>
            </Grid.Column>
            <Grid.Column width={5}>
              <label>VALOR DO PRÊMIO</label>
              <p>R$ {maskMoney(premium, true, 2)}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <label>FINALIDADE</label>
              <p>{policy?.bondInsurance?.purpose === 'RESIDENTIAL' ? 'Residencial' : 'Comercial'}</p>
            </Grid.Column>
            <Grid.Column width={5}>
              <label>COBERTURA</label>
              <p>{policy?.bondInsurance?.analysis_assurance === 'COMPLETE' ? 'Completa' : 'Simples'}</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    };

    const renderPropertyView = (): React.ReactNode => {
      return (
        <Grid>
          <Grid.Row>
            <Grid.Column width={10}>
              <label>ENDEREÇO DO IMÓVEL</label>
              <p>
                {policy?.property?.street}, n°: {policy?.property?.number}{' '}
                {policy?.property?.district}, {policy?.property?.city}{' - '}
                {policy?.property?.state}, CEP: {policy?.property?.zip_code}
              </p>
              {policy?.property?.complement && <p>Complemento: {policy?.property?.complement}</p>}
            </Grid.Column>
            <Grid.Column width={5}>
              <label>TIPO</label>
              <p>{policy?.property?.property_type ?? 'Não informado'}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <label>VALOR DO ALUGUEL</label>
              <p>R$ {maskMoney(String(policy?.property?.rent_value), true, 2)}</p>
            </Grid.Column>
            <Grid.Column width={5}>
              <label>CONDOMÍNIO</label>
              <p>R$ {maskMoney(String(policy?.property?.condominium_fee), true, 2)}</p>
            </Grid.Column>
            <Grid.Column width={5}>
              <label>IPTU MENSAL</label>
              <p>R$ {maskMoney(String(policy?.property?.taxes_value), true, 2)}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <label>ENERGIA</label>
              <p>R$ {maskMoney(String(policy?.property?.energy_bill), true, 2)}</p>
            </Grid.Column>
            <Grid.Column width={5}>
              <label>ÁGUA</label>
              <p>R$ {maskMoney(String(policy?.property?.water_bill), true, 2)}</p>
            </Grid.Column>
            <Grid.Column width={5}>
              <label>GÁS</label>
              <p>R$ {maskMoney(String(policy?.property?.gas_bill), true, 2)}</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    };

    const handleClose = (e: SyntheticEvent) => {
      if (step === 1) {
        onClose(e);
      } else {
        setStep(1);
      }
    };

    const renderRenewForm = (values: FormProps, setFieldValue: any): React.ReactNode => {
      return (
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <label>
                VALOR DO ALUGUEL*
              </label>
              <Input
                placeholder="Valor do Aluguel"
                onChange={(_, data) =>
                  setFieldValue('rentValue', formatAmount(data?.value))
                }
                error={isValidForm && values.rentValue === ''}
                fluid
                value={values.rentValue}
              />
              <small>Atual: {maskMoney(String(policy?.property?.rent_value), true, 2)}</small>
            </Grid.Column>
            <Grid.Column width={8}>
              <label>
                IPTU MENSAL
              </label>
              <Input
                placeholder="IPTU Mensal"
                onChange={(_, data) =>
                  setFieldValue('taxesValue', formatAmount(data?.value))
                }
                fluid
                value={values.taxesValue}
              />
              <small>Atual: {maskMoney(String(policy?.property?.taxes_value), true, 2)}</small>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <label>
                ENERGIA
              </label>
              <Input
                placeholder="Energia"
                onChange={(_, data) =>
                  setFieldValue('energyBill', formatAmount(data?.value))
                }
                fluid
                value={values.energyBill}
              />
              <small>Atual: {maskMoney(String(policy?.property?.energy_bill), true, 2)}</small>
            </Grid.Column>
            <Grid.Column width={8}>
              <label>
                GÁS
              </label>
              <Input
                placeholder="Gás"
                onChange={(_, data) =>
                  setFieldValue('gasBill', formatAmount(data?.value))
                }
                fluid
                value={values.gasBill}
              />
              <small>Atual: {maskMoney(String(policy?.property?.gas_bill), true, 2)}</small>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={8}>
              <label>
                ÁGUA
              </label>
              <Input
                placeholder="Água"
                onChange={(_, data) =>
                  setFieldValue('waterBill', formatAmount(data?.value))
                }
                fluid
                value={values.waterBill}
              />
              <small>Atual: {maskMoney(String(policy?.property?.water_bill), true, 2)}</small>
            </Grid.Column>

            <Grid.Column width={8}>
              <label>
                TAXA DE CONDOMÍNIO
              </label>
              <Input
                placeholder="Taxa de Condomínio"
                onChange={(_, data) =>
                  setFieldValue('condominiumFee', formatAmount(data?.value))
                }
                fluid
                value={values.condominiumFee}
              />
              <small>
                Atual: {maskMoney(String(policy?.property?.condominium_fee), true, 2)}
              </small>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <label>
                INÍCIO DO CONTRATO*
              </label>

              <CustomDatePicker
                selected={values?.startContract ?? undefined}
                onChange={(date: Date) => setFieldValue('startContract', date)}
                customInput={
                  <AppMaskedInput
                    error={isValidForm && !values?.startContract}
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/
                    ]}
                  />
                }
                dateFormat={'dd/MM/yyyy'}
                style={{
                  marginTop: 0
                }}
              />
              <small>
                Atual:{' '}
                {DateToStringDate(policy?.property?.rent_insurance_contract_start_date)}
              </small>
            </Grid.Column>
            <Grid.Column width={8}>
              <label>
                FIM DO CONTRATO*
              </label>

              <CustomDatePicker
                selected={values?.endContract ?? undefined}
                onChange={(date: Date) => setFieldValue('endContract', date)}
                customInput={
                  <AppMaskedInput
                    error={isValidForm && !values?.endContract}
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/
                    ]}
                  />
                }
                dateFormat={'dd/MM/yyyy'}
                style={{
                  marginTop: 0
                }}
              />
              <small>
                Atual:{' '}
                {DateToStringDate(policy?.property?.rent_insurance_contract_end_date)}
              </small>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <label>PERÍODO DE RENOVAÇÃO*</label>
              <div
                style={{
                  width: '100%',
                  marginTop: '.5rem'
                }}
              >
                <Dropdown
                  search
                  selection
                  clearable
                  name="renovationPeriod"
                  value={values.renovationPeriod}
                  options={getMonthsOptions(policy.insurer.code)}
                  placeholder={'Selecionar...'}
                  error={isValidForm && values?.renovationPeriod === ''}
                  style={{
                    width: '100%'
                  }}
                  onChange={(_, { value }) => {
                    setFieldValue('renovationPeriod', value?.toString() || '');
                  }}
                />
              </div>
            </Grid.Column>
            <Grid.Column width={4}>
              <label style={{ marginBottom: 5 }}>
                ADITIVO DE CONTRATO
              </label>
              <ModalAttachFiles
                bondInsuranceId={bondInsuranceId}
                tableName="bond_insurance"
                message=""
                hideModels
                fileIdentifiers={FileIdentifiers}
                callback={data => setHasContractAdditive(data.includes('ADITIVO DE CONTRATO'))}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    };

    const renderTenantView = (): React.ReactNode => {
      return (
        <Grid>
          <Grid.Row>
            <Grid.Column width={5}>
              <label>LOCATÁRIO</label>
              <p>{policy?.tenant?.name}</p>
            </Grid.Column>
            <Grid.Column width={5}>
              <label>CPF DO LOCATÁRIO</label>
              <p>{maskCpfOrCnpj(policy?.tenant?.document)}</p>
            </Grid.Column>
            {/* <Grid.Column width={5}>
            <label>ENDEREÇO</label>
            <p>{policy?.tenant?.name}</p>
          </Grid.Column> */}
          </Grid.Row>
        </Grid>
      );
    };

    const renderLessorView = (): React.ReactNode => {
      return (
        <Grid>
          <Grid.Row>
            <Grid.Column width={5}>
              <label>LOCADOR</label>
              <p>{policy?.bondInsurance?.propertyOwnerNaturalPerson?.name}</p>
            </Grid.Column>
            <Grid.Column width={5}>
              <label>DOCUMENTO DO LOCADOR</label>
              <p>
                {maskCpfOrCnpj(
                  policy?.bondInsurance?.propertyOwnerNaturalPerson?.cnpj
                    ? policy.bondInsurance.propertyOwnerNaturalPerson.cnpj
                    : (policy?.bondInsurance?.propertyOwnerNaturalPerson?.cpf
                      ? policy.bondInsurance.propertyOwnerNaturalPerson.cpf
                      : '')
                )}
              </p>
            </Grid.Column>
            {/*<Grid.Column width={5}>
            <label>ENDEREÇO</label>
            <p>{policy?.estate?.address}e</p>
          </Grid.Column> */}
          </Grid.Row>
        </Grid>
      );
    };

    const renderActionButtons = (values: FormProps): React.ReactNode => {
      return (
        <Modal.Actions>
          <ButtonContainer>
            <Button type="button" color={step === 1 ? 'red' : 'blue'} onClick={handleClose}>
              {step === 1 ? 'Cancelar' : 'Anterior'}
            </Button>
            <Button
              color={step === 1 ? 'blue' : 'green'}
              onClick={() => handleFormSubmit(values)}
              disabled={hasClaim}
            >
              {step === 1 ? 'Próximo' : 'Solicitar renovação'}
            </Button>
          </ButtonContainer>
        </Modal.Actions>
      );
    };

    return (
      <Modal
        open={isOpen}
        onClose={onClose}
        onUnmount={() => {
          setStep(1);
        }}
        closeIcon
        size="large"
      >
        <Header>
          <h3>
            <Icon name="refresh" /> Renovação de apólice
          </h3>
        </Header>
        <Line />
        <Formik initialValues={initialValues} validationSchema={{}} onSubmit={() => { }}>
          {innerProps => {
            const { setFieldValue, values } = innerProps;

            return (
              <>
                <Modal.Content scrolling style={{ padding: 0 }}>
                  <Dimmer active={loading} inverted>
                    <Loader />
                  </Dimmer>
                  <StepContainer ref={modalRef}>
                    {hasClaim ?
                      (
                        <Disclaimer>
                          <Icon bordered name="exclamation" />
                          <p>
                            <b>Atenção -</b> Apólice com sinistro em andamento,favor entrar em
                            <br />contato com o gestor de contas ou o atendimento da Corretora.
                          </p>
                        </Disclaimer>
                      ) :
                      (
                        <Step.Group>
                          <Step
                            onClick={() => setStep(1)}
                            active={step === 1}
                            title={'Dados iniciais'}
                            description=""
                          />
                          <Step
                            onClick={() => setStep(2)}
                            active={step === 2}
                            title={'Renovação'}
                            description=""
                          />
                        </Step.Group>
                      )}
                  </StepContainer>
                  <Form>
                    {step === 1 && (
                      <FormContainer>
                        <FormTitle>DADOS DA PROPOSTA</FormTitle>
                        <Line />
                        {renderPurposeView()}
                      </FormContainer>
                    )}
                    {step === 1 && (
                      <FormContainer>
                        <FormTitle>DADOS DO IMÓVEL</FormTitle>
                        <Line />
                        {renderPropertyView()}
                      </FormContainer>
                    )}
                    {step === 1 && (
                      <FormContainer>
                        <FormTitle>DADOS DO LOCATÁRIO</FormTitle>
                        <Line />
                        {renderTenantView()}
                      </FormContainer>
                    )}
                    {step === 1 && (
                      <FormContainer>
                        <FormTitle>DADOS DO LOCADOR</FormTitle>
                        <Line />
                        {renderLessorView()}
                      </FormContainer>
                    )}
                    {step === 2 && (
                      <FormContainer>
                        <FormTitle>VALORES REAJUSTADOS/ATUALIZADOS</FormTitle>
                        <InfoBanner />
                        {renderRenewForm(values, setFieldValue)}
                      </FormContainer>
                    )}
                  </Form>
                </Modal.Content>
                <FormContainer>{renderActionButtons(values)}</FormContainer>
              </>
            );
          }}
        </Formik>
      </Modal>
    );
  };

export default connect(mapState)(RenewRequestModal);
