
import {UserReducer} from "../types/temp-types";
import {UserState} from "../../types/UserState";

type UserRematchModel = {
  state: UserState;
  reducers: UserReducer;
}

const user: UserRematchModel = {
  state: {} as UserState,
  reducers: {
    updateUser: (state: UserState, payload: Partial<UserState>): UserState => {
      return {
        ...state,
        ...payload
      }
    }
  }
};

export default user;
