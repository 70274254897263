import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Info } from '../../../components/Info';
import { DateToStringDate } from '../../../services/date';
import { maskCpfOrCnpj, maskMoney, maskCnpj } from '../../../services/masks';
import { FieldLabel } from '../../../styles';
import {BondInsuranceForGetBondInsurancesResponseCamelCaseDTO, BondInsuranceForGetBondInsurancesResponseDTO} from "../../../dtos/bond-insurance-list/get-bond-insurances-response.dto";

export type Props = {
  bondInsurance: BondInsuranceForGetBondInsurancesResponseCamelCaseDTO;
};

export const BondInsuranceLegalTenantDetails = (props: Props) => {

  const { legalTenant } = props.bondInsurance;
  return (
    <>
      <Grid.Row>
        <Info name={'EMPRESA'} value={legalTenant.company} width={4} />
        <Info name={'CNPJ'} value={ legalTenant.document ? maskCnpj(legalTenant.document) : "Indisponível"} width={4} />
        <Info name={'E-MAIL'} value={legalTenant.email} width={4} />
        <Info name={'TELEFONE'} value={legalTenant.phone} width={4} />
      </Grid.Row>

      <Grid.Row>
        <Info name={'DATA DE CONSTITUIÇÃO'} value={legalTenant.foundation_date ? DateToStringDate(legalTenant.foundation_date): "Indisponível"} width={4} />
        <Info name={'ÚLT. ALT. CONTRATUAL'} value={legalTenant.last_contractual_alteration_date ? DateToStringDate(legalTenant.last_contractual_alteration_date): "Indisponível"} width={4} />
        <Info name={'CAPITAL SOCIAL (R$)'} value={legalTenant.share_capital ? maskMoney(`${legalTenant.share_capital}`): "Indisponível"} width={4} />
        <Info name={'CAPITAL INTERGRALIZADO (R$)'} value={legalTenant.paid_in_capital ? maskMoney(`${legalTenant.paid_in_capital}`): "Indisponível"} width={4} />
      </Grid.Row>

      <Grid.Row>
        <Info name={'FATURAMENTO ANUAL (R$)'} value={legalTenant.anual_revenue ? maskMoney(`${legalTenant.anual_revenue}`): "Indisponível"} width={4} />
        <Info name={'NOME CONTATO'} value={legalTenant.contact_name} width={4} />
        <Info name={'TELEFONE CONTATO'} value={legalTenant.contact_phone} width={4} />
      </Grid.Row>

      <Grid.Row>
        <Info name={'LOGRADOURO'} value={legalTenant.street} width={4} />
        <Info name={'NÚMERO'} value={legalTenant.number} width={4} />
        <Info name={'COMPLEMENTO'} value={legalTenant.complement} width={4} />
        <Info name={'BAIRRO'} value={legalTenant.district} width={4} />
      </Grid.Row>

      <Grid.Row>
        <Info name={'CIDADE'} value={legalTenant.city} width={4} />
        <Info name={'ESTADO'} value={legalTenant.state} width={4} />
        <Info name={'CEP'} value={legalTenant.zip_code} width={4} />
      </Grid.Row>

      {legalTenant.partners.map((partner, index) => {
        return (
          <>
            <Grid.Row style={{ paddingLeft: '15px' }}>
              <FieldLabel style={{ color: '#364445', marginTop: '0px' }}>
                {`${index + 1}º SÓCIO`}
              </FieldLabel>
            </Grid.Row>

            <Grid.Row>
              <Info name={'NOME'} value={partner.name} width={4} />
              <Info name={'CPF'} value={partner.document ? maskCpfOrCnpj(partner.document) : "Indisponível"} width={4} />
              <Info name={'RENDA'} value={`${partner.percent} %`} width={4} />
              <Info name={'DATA DE ENTRADA'} value={partner.date_of_entry ? DateToStringDate(partner.date_of_entry) : "Indisponível"} width={4} />
            </Grid.Row>
          </>
        )
      })}
    </>
  )
}
