import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom'
import { Button, Divider, Dropdown, Grid, Icon, TextArea } from 'semantic-ui-react';
import { FieldLabel } from '../../../../../styles';
import { cancellationReasonOptions } from '../../../utils/Pottencial'

import { dangerNotification, successNotification } from '../../../../../services/notification';

import * as Storage from '../../../../../services/sessionStorage';
import { cancelRequest } from '../../../../../services/fire-insurance-quotation';


interface PottencialViewInterface {
  fireInsurance: any;
  close: () => void;
}

const HeaderAndDivider = styled.h3`
  border-bottom: 1px solid #3498db;
  color: #3498db;
  width: 100%;
  margin-top: 20px !important;
  padding-bottom: 5px;
  letter-spacing: .4px;
`

const ObservationsText = styled(TextArea)`
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #fff;
  resize: none;
  opacity: .7;
  &:focus {
    outline: none ! important;
    border-color: #719ECE;
  }
`


const PottencialCancel = (props: PottencialViewInterface) => {
  const { fireInsurance, close } = props;
  console.log('FIREINSURANCE', fireInsurance);
  const user = Storage.get('user')
  const history = useHistory();

  console.log('user', user);
  const [cancellationReason, setCancellationReason] = useState<any>(cancellationReasonOptions[0].value);
  const [observations, setObservations] = useState<any>('');

  const [isCanceling, setIsCanceling] = useState<boolean>(false);

  // const [notificationEmails, setNotificationEmails] = useState<string[]>(['']);
  // const [email, setEmail] = useState<any>('');

  const cancel = async () => {
    setIsCanceling(true);

    const payload = {
      fire_insurance_analysis_id: fireInsurance.id,
      canceled_by: user.id,
      cancellation_reason: cancellationReason,
		  observations: observations
    }

    try {
      const data = await cancelRequest(payload);
      console.log({data})

      if (data) {
        successNotification('Sucesso', 'Cancelamento solicitado com sucesso!')
        history.push('/admin/fire-insurance/budget/list')
      }
    } catch (err) {
      dangerNotification('Erro', 'Não foi possível solicitar o cancelamento.')
      // console.log({err})
    }
    setIsCanceling(false);
  }

  return (
    <Grid style={{padding: 5}} columns={'equal'}>
      <HeaderAndDivider>Dados do Cancelamento</HeaderAndDivider>
        <Grid.Row>
          <Grid.Column>
            <FieldLabel required>Motivo do Cancelamento</FieldLabel>
            <Dropdown
              fluid
              search
              clearable
              selection
              options={cancellationReasonOptions}
              error={!!!cancellationReason}
              value={cancellationReason}
              onChange={(e, { name, value }) => {
                console.log(cancellationReasonOptions);
                console.log(cancellationReason);
                console.log(value);
                setCancellationReason(value)}}

            />
          </Grid.Column>
        </Grid.Row>

        {/* <FieldLabel required>Informe os e-mails para receberem notificações do cancelamento:</FieldLabel>
        {notificationEmails.map(
          (notificationEmail, index) => {
            return (
              <Grid.Row verticalAlign="middle">

                <Grid.Column width={6}>
                  <Input
                    value={email}
                    fluid
                    onChange={(e: any, { name, value }: any) => setEmail(value)}
                  />
                </Grid.Column>
                <Grid.Column width={1} verticalAlign="middle">
                  <Icon
                    name={"remove circle"}
                    color={"grey"}
                    style={{ cursor: "pointer", paddingTop: "5px" }}
                    size={"large"}
                    onClick={() => {
                      if (notificationEmails.length === 1 && notificationEmails[0] !== '') {
                        setNotificationEmails(['']);
                      } else {
                        const arr1 =
                          notificationEmails.slice(0, index);
                        const arr2 =
                          notificationEmails.slice(
                            index + 1
                          );
                        const newArr = [...arr1 as string[], ...arr2 as string[]];
                        setNotificationEmails([...newArr])
                      }
                    }}
                  />
                </Grid.Column>
                {index + 1 === notificationEmails.length && (
                  <Grid.Column width={5}>
                    <Button
                      primary
                      onClick={() => {
                          setNotificationEmails([...notificationEmails, email]);
                          console.log(notificationEmails)
                          setEmail('');
                        if (notificationEmails.length) {
                          console.log('AKI 0');
                          const lastEmail =
                            notificationEmails[
                            notificationEmails.length - 1
                            ];
                            console.log(lastEmail);
                          if (lastEmail.length > 0)
                            console.log('AKI 1');
                            setNotificationEmails([...notificationEmails]);
                        } else {
                          console.log('AKI 2');
                          notificationEmails[index + 1] ="";
                        }
                        console.log(notificationEmails)
                      }}
                    >
                      <Icon name={"plus circle"} />
                      Adicionar E-mail
                    </Button>
                  </Grid.Column>
                )}
              </Grid.Row>
            );
          }
        )} */}

        <Grid.Row>
          <Grid.Column>
          <FieldLabel>Observações</FieldLabel>

            <ObservationsText
                rows={8}
                value={observations}
                onChange={(e: any, { name, value }: any) => setObservations(value)}
                fluid
            />

          </Grid.Column>
        </Grid.Row>

      <Divider />
      <Grid.Row>
        <Grid.Column style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button
            icon
            labelPosition={'left'}
            loading={isCanceling}
            disabled={isCanceling}
            onClick={close}
          >
            <Icon name={'arrow left'} />
            Voltar
          </Button>
          <Button
            primary
            icon
            labelPosition={'right'}
            loading={isCanceling}
            disabled={isCanceling}
            onClick={cancel}
          >
            <Icon name={'trash'} />
            Solicitar Cancelamento
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default PottencialCancel;
