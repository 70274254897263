import { get } from "./request";

import {
  FireInsuranceActiveInsurersResponseDTO,
  InsurerForFireInsuranceActiveInsurersResponseDTO,
} from "../dtos/fire-insurance-budget/fire-insurance-active-insurers-response.dto";
import { InsurerState } from "../types/InsurerState";

export const list = async (filter?: object): Promise<Array<any>> => {
  let query = "";

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  if (query.substring(query.length - 1) === "&") {
    query = query.substring(0, query.length - 1);
  }

  let response;

  try {
    if (filter) {
      response = await get(`/insurers?${query}`);
    } else {
      response = await get("/insurers");
    }
  } catch (e) {
    throw e;
  }

  return response;
};

export const getFireInsuranceActiveInsurers = async (
  estateId: string
): Promise<FireInsuranceActiveInsurersResponseDTO> => {
  let response;

  try {
    response = await get(
      `/insurers/fire-active-insurers-by-estate-id/${estateId}`
    );

    return response;
  } catch (e) {
    throw e;
  }
};

export const getActiveBondInsurers = async (estateId?: string) => {
  let response;

  try {
    response = await get(`/insurers/active-bond-insurers-by-estate-id/${estateId}`);
  } catch (e) {
    throw e;
  }

  return response;
}

export const getActiveFireInsurers = async (estateId?: string) => {
  let response;

  try {
    response = await get(`/insurers/active-fire-insurers-by-estate-id/${estateId}`);
  } catch (e) {
    throw e;
  }

  return response;
}

export const getInsurersStateObjectFromDTO = (
  insurersFromDTO: InsurerForFireInsuranceActiveInsurersResponseDTO[]
): InsurerState[] => {
  const insurers: InsurerState[] = [];

  for (const insurerDTO of insurersFromDTO) {
    const insurer: InsurerState = {
      id: insurerDTO.id,
      name: insurerDTO.name,
      code: insurerDTO.code,
      imageUrl: insurerDTO.image_url,
      availabily: insurerDTO.availability,
      assistances: insurerDTO.assistances?.map((a) => {
        return {
          id: a.id,
          description: a.description,
          fromConnectorTable: a.from_connector_table,
        };
      }),
      connectorInformation: {
        enabled: insurerDTO.connector_information.enabled,
        insuranceType: insurerDTO.connector_information.insurance_type,
      },
      propertyTypes: insurerDTO.property_types.map((p) => {
        return {
          id: p.id,
          description: p.description,
          fromConnectorTable: p.from_connector_table,
          occupations: p.occupations.map((o) => {
            return {
              id: o.id,
              presentationalDescription: o.presentational_description,
              valueDescription: o.value_description,
              fromConnectorTable: o.from_connector_table,
            };
          }),
          coverages: p.coverages?.map((c) => {
            return {
              id: c.id,
              description: c.description,
              insuredFloorPlan: c.insured_floor_plan,
              maximumInsuredAmount: c.maximum_insured_amount,
              mandatoryInQuotation: c.mandatory_in_quotation,
              fromConnectorTable: c.from_connector_table,
              defaultCoverageValueStrategy: c.default_quotation_value_strategy,
            };
          }),
        };
      }),
    };

    insurers.push(insurer);
  }

  return insurers;
};
