import React, { useState } from 'react';
import { Button, Dimmer, Dropdown, Grid, Input, Loader } from 'semantic-ui-react';
import {
  Container,
  FormContainer,
  Header,
  Line,
  FormTitle,
  ButtonContainer,
  ErrorMessage
} from './styles';
import { FranchiseState } from '../../types/FranchiseState';
import { useHistory, useParams } from 'react-router-dom';
import { dangerNotification, successNotification } from '../../services/notification';
import { useQuery } from '@tanstack/react-query';
import {
  getFranchiseById,
  createFranchise,
  updateFranchiseById,
  getBanks,
  getFranchiseByLoggedUser
} from '../../services/franchises';
import { Formik } from 'formik';
import {
  maskCnpj,
  maskCpfOrCnpj,
  maskPhoneNumber,
  maskZipCode,
  unmaskCpfOrCnpj,
  unmaskPhoneNumber
} from '../../services/masks';
import { AddressService } from '../../services/address';
import { iRootDispatch, iRootState } from '../../store';
import { connect } from 'react-redux';
import {
  FranchisesToCustomDropDownOptions,
  UsersToCustomDropDownOptions
} from '../../util';
import { CustoDropDownItemProps } from '../../components/types';
import {
  getFranchises,
  getUsers
} from '../../services/broker';
import { FormErrorsMessages, statusOptions } from '../franchises-list/types';
import { AxiosError } from 'axios';
import { isValidEmail } from '../../utils/emailValidator';
import { AvailableBrokersAndEstatesState } from '../../store/types/temp-types';
import { hasPermission } from '../../components/AuthorizationContainer';
import { ActionEnum } from '../../enums/authz-action.enum';
import { FeatureEnum } from '../../enums/authz-feature.enum';

const mapState = (state: iRootState) => ({
  user: state.user,
  availableBrokersAndEstates: state.availableBrokersAndEstates
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

export type FranchiseFormPageProps = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch>;

const FranchiseForm: React.FC<FranchiseFormPageProps> = ({
  user,
  updateAvailableBrokersAndEstates,
  availableBrokersAndEstates
}) => {
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const [formErrors, setFormErrors] = useState<FormErrorsMessages>({
    name: '',
    company_name: '',
    document: '',
    status: '',
    responsible_name: '',
    responsible_email: '',
    responsible_phone: '',
    phone: '',
    email: '',
    account_manager_id: '',
    zipcode: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: '',
    agencyDv: '',
    agencyNumber: '',
    accountDv: '',
    accountNumber: '',
    bankCode: '',
    user_id: ''
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isCepLoading, setIsCepLoading] = useState<boolean>(false);

  const getFranchise = async () => {
    const response = await getFranchiseById(id);
    return {
      ...response,
      document: maskCpfOrCnpj(response.document),
      responsible_phone: maskPhoneNumber(response.responsible_phone),
      phone: maskPhoneNumber(response.phone)
    };
  };

  const { data: franchise, isFetching: isFranchiseLoading } = useQuery(
    ['getFranchiseById', id],
    getFranchise,
    {
      enabled: id !== 'new',
      keepPreviousData: true,
      refetchOnMount: 'always'
    }
  );

  const fetchBankList = async () => {
    const response = await getBanks();
    const valuesFormatted: CustoDropDownItemProps[] = response.map(
      (bank: any, index: number) => {
        return {
          value: bank?.code,
          key: index,
          text: bank?.text
        };
      }
    ) as CustoDropDownItemProps[];
    return valuesFormatted;
  };

  const { data: banks, isFetching: isBanksLoading } = useQuery(
    ['getBankList'],
    fetchBankList,
    {
      keepPreviousData: true
    }
  );

  const fetchAccountManagers = async () => {
    if (!user.broker?.id) return [];
    const result = await getUsers(user.broker.id);
    return UsersToCustomDropDownOptions(
      false,
      result.map(user => ({
        id: user.id,
        name: user.name
      }))
    );
  };

  const { data: accountManagerOptions, isFetching: isAccountManagersLoading } = useQuery(
    ['getAccountManagers', !!user?.broker?.id],
    fetchAccountManagers,
    {
      enabled: !!user?.broker?.id,
      keepPreviousData: true
    }
  );

  const validateForm = (values: FranchiseState) => {
    setIsValidForm(true);

    const errors: FormErrorsMessages = {
      name: '',
      company_name: '',
      document: '',
      responsible_name: '',
      responsible_email: '',
      responsible_phone: '',
      phone: '',
      email: '',
      account_manager_id: '',
      zipcode: '',
      street: '',
      number: '',
      district: '',
      city: '',
      state: '',
      agencyDv: '',
      agencyNumber: '',
      accountDv: '',
      accountNumber: '',
      bankCode: '',
      status: ''
    };

    errors.email = !values.email
      ? 'E-mail obrigatório'
      : !isValidEmail(values.email)
      ? 'E-mail inválido'
      : '';
    errors.company_name = !values.company_name ? 'Campo obrigatório' : '';
    errors.document = !values.document ? 'Campo obrigatório' : '';
    errors.name = !values.name ? 'Campo obrigatório' : '';
    errors.phone = !values.phone ? 'Campo obrigatório' : '';
    errors.responsible_name = !values.responsible_name ? 'Campo obrigatório' : '';
    errors.responsible_email = !values.responsible_email
      ? 'E-mail obrigatório'
      : !isValidEmail(values.responsible_email)
      ? 'E-mail inválido'
      : '';
    errors.responsible_phone = !values.responsible_phone ? 'Campo obrigatório' : '';
    errors.account_manager_id = !values.account_manager_id ? 'Campo obrigatório' : '';
    errors.zipcode = !values.zipcode ? 'Campo obrigatório' : '';
    errors.street = !values.street ? 'Campo obrigatório' : '';
    errors.number = !values.number ? 'Campo obrigatório' : '';
    errors.district = !values.district ? 'Campo obrigatório' : '';
    errors.city = !values.city ? 'Campo obrigatório' : '';
    errors.state = !values.state ? 'Campo obrigatório' : '';
    if (values?.bankAccount) {
      errors.agencyDv = !values?.bankAccount?.agencyDv ? 'Campo obrigatório' : '';
      errors.agencyNumber = !values.bankAccount?.agencyNumber ? 'Campo obrigatório' : '';
      errors.accountDv = !values.bankAccount?.accountDv ? 'Campo obrigatório' : '';
      errors.accountNumber = !values.bankAccount?.accountNumber
        ? 'Campo obrigatório'
        : '';
      errors.bankCode = !values.bankAccount?.bankCode ? 'Campo obrigatório' : '';
    }

    const isValid = Object.values(errors).every(error => error === '');

    return { errors, isValid };
  };

  async function handleSubmit(values: FranchiseState) {
    const result = validateForm(values);
    setFormErrors(result?.errors);
    if (!result?.isValid) return;

    setIsLoading(true);
    try {
      const payload = {
        name: values?.name || '',
        company_name: values?.company_name || '',
        document: values?.document ? unmaskCpfOrCnpj(values?.document) : '',
        status: values?.status || '',
        responsible_name: values?.responsible_name || '',
        responsible_email: values?.responsible_email || '',
        responsible_phone: values?.responsible_phone
          ? unmaskPhoneNumber(values?.responsible_phone)
          : '',
        phone: values?.phone ? unmaskPhoneNumber(values?.phone) : '',
        email: values?.email || '',
        account_manager_id: values?.account_manager_id || '',
        zipcode: values?.zipcode || '',
        street: values?.street || '',
        number: values?.number || '',
        complement: values?.complement || '',
        district: values?.district || '',
        city: values?.city || '',
        state: values?.state || '',
        bank_account: {
          id: values?.bankAccount?.id || '',
          agencyDv: values?.bankAccount?.agencyDv || '',
          agencyNumber: values?.bankAccount?.agencyNumber || '',
          accountDv: values?.bankAccount?.accountDv || '',
          accountNumber: values?.bankAccount?.accountNumber || '',
          bankCode: values?.bankAccount?.bankCode || ''
        },
        user_id: user?.id
      };
      if (id === 'new') {
        await createFranchise(payload);
      } else {
        await updateFranchiseById(id, payload);
      }

      let franchises = [];
      const hasFranchisesPermission = hasPermission(
        ActionEnum.read,
        FeatureEnum.franchises
      );
      if (hasFranchisesPermission) {
        franchises = await getFranchises();
      }
      if (user.franchiseId) {
        const franchiseUser = await getFranchiseByLoggedUser();
        franchises = [franchiseUser];
      }

      updateAvailableBrokersAndEstates({
        ...availableBrokersAndEstates,
        franchises: FranchisesToCustomDropDownOptions(franchises)
      });

      successNotification(
        'Sucesso',
        `Parceiro ${id ? 'atualizado' : 'criado'} com sucesso!`
      );
      history.goBack();
    } catch (error) {
      if ((error as any).message) {
        const errorMessage = (error as any).message;
        if (errorMessage.includes('Duplicate entry')) {
          const duplicated = errorMessage.split(' ')[2];
          dangerNotification(
            'Valor Duplicado',
            `O valor ${duplicated} já foi cadastrado anteriormente.`
          );
        } else {
          dangerNotification('Ops...', errorMessage);
        }
      } else {
        dangerNotification(
          'Oops...',
          (error as AxiosError).response?.data?.message || 'Erro ao criar parceiro'
        );
      }
    } finally {
      setIsLoading(false);
    }
  }

  const renderActionButtons = (values: FranchiseState): React.ReactNode => {
    return (
      <ButtonContainer>
        <Button type="button" color="red" onClick={() => history.goBack()}>
          Voltar
        </Button>
        <Button
          type="submit"
          color="green"
          form="policy-form"
          onClick={e => {
            e.preventDefault();
            handleSubmit(values);
          }}
        >
          Salvar
        </Button>
      </ButtonContainer>
    );
  };

  const changeZipCode = (cep: string, setFieldValue: any) => {
    setFieldValue('zipcode', maskZipCode(cep));
    if (maskZipCode(cep).length === 9) {
      setIsCepLoading(true);
      AddressService.getAddressByZipCode(cep, user.id)
        .then((address: any) => {
          setFieldValue('city', address.city);
          setFieldValue('district', address.district);
          setFieldValue('state', address.state);
          setFieldValue('street', address.street);

          setIsCepLoading(false);
        })
        .catch(e => {
          console.log('address error:', e);
        });
    }
  };

  const renderGeneralForm = (values: FranchiseState, setFieldValue: any) => {
    return (
      <Grid>
        <Grid.Row columns={'equal'}>
          <Grid.Column>
            <label>Nome Fantasia*</label>
            <Input
              placeholder="Nome Fantasia"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('name', e.target.value)
              }
              value={values?.name}
              error={isValidForm && values?.name === ''}
            />
            <ErrorMessage>{formErrors.name}</ErrorMessage>
          </Grid.Column>
          <Grid.Column>
            <label>Razão Social*</label>
            <Input
              placeholder="Razão Social"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('company_name', e.target.value)
              }
              value={values?.company_name}
              error={isValidForm && values?.company_name === ''}
            />
            <ErrorMessage>{formErrors.company_name}</ErrorMessage>
          </Grid.Column>
          <Grid.Column>
            <label>CNPJ*</label>
            <Input
              placeholder="CNPJ"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('document', maskCnpj(e.target.value))
              }
              value={values?.document}
              error={isValidForm && values?.document === ''}
            />
            <ErrorMessage>{formErrors.document}</ErrorMessage>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={'equal'}>
          <Grid.Column>
            <label>Nome do Responsável*</label>
            <Input
              placeholder="Nome do Responsável"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('responsible_name', e.target.value)
              }
              value={values?.responsible_name}
              error={isValidForm && values?.responsible_name === ''}
            />
            <ErrorMessage>{formErrors.responsible_name}</ErrorMessage>
          </Grid.Column>
          <Grid.Column>
            <label>E-mail do Responsável*</label>
            <Input
              placeholder="E-mail do Responsável"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('responsible_email', e.target.value)
              }
              value={values?.responsible_email}
              error={
                isValidForm &&
                !(
                  values?.responsible_email !== '' &&
                  isValidEmail(values?.responsible_email)
                )
              }
            />
            <ErrorMessage>{formErrors.responsible_email}</ErrorMessage>
          </Grid.Column>
          <Grid.Column>
            <label>Contato do Responsável*</label>
            <Input
              placeholder="Contato do Responsável"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('responsible_phone', maskPhoneNumber(e.target.value))
              }
              value={values?.responsible_phone}
              error={isValidForm && values?.responsible_phone === ''}
            />
            <ErrorMessage>{formErrors.responsible_phone}</ErrorMessage>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={'equal'}>
          <Grid.Column>
            <label>Telefone da Unidade*</label>
            <Input
              placeholder="Telefone da Unidade"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('phone', maskPhoneNumber(e.target.value))
              }
              value={values?.phone}
              error={isValidForm && values?.phone === ''}
            />
            <ErrorMessage>{formErrors.phone}</ErrorMessage>
          </Grid.Column>
          <Grid.Column>
            <label>E-mail do Unidade*</label>
            <Input
              placeholder="E-mail do Unidade"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('email', e.target.value)
              }
              value={values?.email}
              error={
                isValidForm && !(values?.email !== '' && isValidEmail(values?.email))
              }
            />
            <ErrorMessage>{formErrors.email}</ErrorMessage>
          </Grid.Column>
          <Grid.Column>
            <label>Gestor de Contas*</label>
            <Dropdown
              style={{
                width: '100%',
                marginTop: 10
              }}
              value={values?.account_manager_id}
              name={`accountManager`}
              options={accountManagerOptions || []}
              disabled={isAccountManagersLoading}
              loading={isAccountManagersLoading}
              placeholder={'Selecionar...'}
              search
              selection
              onChange={(_, { value }) => {
                setFieldValue('account_manager_id', value);
              }}
              error={isValidForm && values?.account_manager_id === ''}
              clearable
            />
            <ErrorMessage>{formErrors.account_manager_id}</ErrorMessage>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const renderPropertyForm = (values: FranchiseState, setFieldValue: any) => {
    return (
      <Grid>
        <Grid.Row columns={'equal'}>
          <Grid.Column>
            <label>CEP*</label>
            <Input
              placeholder="CEP"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                changeZipCode(e.target.value, setFieldValue)
              }
              value={values?.zipcode}
              error={isValidForm && values?.zipcode === ''}
            />
            {values?.zipcode === '' && <ErrorMessage>{formErrors.zipcode}</ErrorMessage>}
          </Grid.Column>
          <Grid.Column>
            <label>*</label>
            RUA
            <Input
              placeholder="Rua"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('street', e.target.value)
              }
              value={values?.street}
              error={isValidForm && values?.street === ''}
            />
            {values?.street === '' && <ErrorMessage>{formErrors.street}</ErrorMessage>}
          </Grid.Column>
          <Grid.Column>
            <label>NÚMERO*</label>
            <Input
              placeholder="Número"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('number', e.target.value)
              }
              value={values?.number}
              error={isValidForm && values?.number === ''}
            />
            {values?.number === '' && <ErrorMessage>{formErrors.number}</ErrorMessage>}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={'equal'}>
          <Grid.Column>
            <label>BAIRRO*</label>
            <Input
              placeholder="Bairro"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('district', e.target.value)
              }
              value={values?.district}
              disabled={isCepLoading}
              error={isValidForm && values?.district === ''}
            />
            {values?.district === '' && (
              <ErrorMessage>{formErrors.district}</ErrorMessage>
            )}
          </Grid.Column>
          <Grid.Column>
            <label>CIDADE*</label>
            <Input
              placeholder="Cidade"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('city', e.target.value)
              }
              value={values?.city}
              disabled={isCepLoading}
              error={isValidForm && values?.city === ''}
            />
            {values?.city === '' && <ErrorMessage>{formErrors.city}</ErrorMessage>}
          </Grid.Column>
          <Grid.Column>
            <label>UF*</label>
            <Input
              placeholder="UF"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('state', e.target.value)
              }
              value={values?.state}
              disabled={isCepLoading}
              error={isValidForm && values?.state === ''}
            />
            {values?.state === '' && <ErrorMessage>{formErrors.state}</ErrorMessage>}
          </Grid.Column>
          <Grid.Column>
            <label>COMPLEMENTO</label>
            <Input
              placeholder="Complemento"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('complement', e.target.value)
              }
              value={values?.complement}
              disabled={isCepLoading}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const renderBankForm = (values: FranchiseState, setFieldValue: any) => {
    return (
      <Grid>
        <Grid.Row columns={'equal'}>
          <Grid.Column width={4}>
            <label>Banco*</label>
            <Dropdown
              style={{
                width: '100%',
                marginTop: 10
              }}
              value={values?.bankAccount?.bankCode}
              name={`bankAccount`}
              options={banks || []}
              placeholder={'Selecionar...'}
              loading={isBanksLoading}
              disabled={isBanksLoading}
              search
              selection
              onChange={(_, { value }) => {
                setFieldValue('bankAccount.bankCode', value);
              }}
              clearable
              error={isValidForm && values?.bankAccount?.bankCode === ''}
            />
            {values?.bankAccount?.bankCode === '' && (
              <ErrorMessage>{formErrors.state}</ErrorMessage>
            )}
          </Grid.Column>
          <Grid.Column width={4}>
            <label>Agência*</label>
            <Input
              placeholder="Agência"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('bankAccount.agencyNumber', e.target.value)
              }
              value={values?.bankAccount?.agencyNumber}
              error={isValidForm && values?.bankAccount?.agencyNumber === ''}
            />
            {values?.bankAccount?.agencyNumber === '' && (
              <ErrorMessage>{formErrors.agencyNumber}</ErrorMessage>
            )}
          </Grid.Column>
          <Grid.Column>
            <label>Dígito*</label>
            <Input
              placeholder="Dígito"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('bankAccount.agencyDv', e.target.value)
              }
              value={values?.bankAccount?.agencyDv}
              error={isValidForm && values?.bankAccount?.agencyDv === ''}
            />
            {values?.bankAccount?.agencyDv === '' && (
              <ErrorMessage>{formErrors.agencyDv}</ErrorMessage>
            )}
          </Grid.Column>
          <Grid.Column width={4}>
            <label>Conta*</label>
            <Input
              placeholder="Conta"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('bankAccount.accountNumber', e.target.value)
              }
              value={values?.bankAccount?.accountNumber}
              error={isValidForm && values?.bankAccount?.accountNumber === ''}
            />
            {values?.bankAccount?.accountNumber === '' && (
              <ErrorMessage>{formErrors.accountNumber}</ErrorMessage>
            )}
          </Grid.Column>
          <Grid.Column>
            <label>Dígito*</label>
            <Input
              placeholder="Dígito"
              fluid
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('bankAccount.accountDv', e.target.value)
              }
              value={values?.bankAccount?.accountDv}
              error={isValidForm && values?.bankAccount?.accountDv === ''}
            />
            {values?.bankAccount?.accountDv === '' && (
              <ErrorMessage>{formErrors.accountDv}</ErrorMessage>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  return (
    <Dimmer.Dimmable dimmed={isCepLoading}>
      <Dimmer active={isLoading || isFranchiseLoading} inverted>
        <Loader />
      </Dimmer>
      <Container>
        <Formik
          enableReinitialize
          initialValues={
            franchise || {
              name: '',
              company_name: '',
              document: '',
              status: 'ACTIVE',
              responsible_name: '',
              responsible_email: '',
              responsible_phone: '',
              phone: '',
              email: '',
              account_manager_id: '',
              zipcode: '',
              street: '',
              number: '',
              complement: '',
              district: '',
              city: '',
              state: '',
              bankAccount: {
                agencyDv: '',
                agencyNumber: '',
                accountDv: '',
                accountNumber: '',
                bankCode: ''
              },
              user_id: ''
            }
          }
          validationSchema={{}}
          onSubmit={handleSubmit}
        >
          {innerProps => {
            const { setFieldValue, values, error, errors } = innerProps;
            return (
              <>
                <Header>
                  <h3>{franchise?.id ? franchise?.name : 'Novo parceiro'}</h3>

                  <div className="status-container">
                    <label>Status*</label>
                    <Dropdown
                      value={values?.status}
                      name={`bankAccount`}
                      options={statusOptions}
                      placeholder={'Selecionar...'}
                      search
                      selection
                      onChange={(_, { value }) => {
                        setFieldValue('status', value);
                      }}
                      clearable
                    />
                  </div>
                </Header>
                <FormContainer>
                  <FormTitle>DADOS CADASTRAIS</FormTitle>
                  <Line />
                  {renderGeneralForm(values, setFieldValue)}
                </FormContainer>
                <FormContainer>
                  <FormTitle>ENDEREÇO</FormTitle>
                  <Line />
                  {renderPropertyForm(values, setFieldValue)}
                </FormContainer>
                <FormContainer>
                  <FormTitle>DADOS BANCÁRIOS</FormTitle>
                  <Line />
                  {renderBankForm(values, setFieldValue)}
                </FormContainer>
                {renderActionButtons(values)}
              </>
            );
          }}
        </Formik>
      </Container>
    </Dimmer.Dimmable>
  );
};

export default connect(mapState, mapDispatch)(FranchiseForm);
