import React from 'react';
import styled from 'styled-components';
import MaskedInput from 'react-text-mask';

import { InputProps } from 'semantic-ui-react';

const InputWrapper = styled.div`
  border: ${(p: InputProps) => (p.error ? 'red' : 'blue')};
  border-radius: 5px;
  margin: -5px 0 0;

  input {
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: 0.67857143em 1em;
    /* background: #F5F8FA; */
    border: 1px solid
      ${(p: InputProps) => (p.error ? '#e0b4b4' : 'rgba(34, 36, 38, 0.15)')};
    background-color: ${(p: InputProps) => (p.error ? '#fff6f6' : '#ffffff')};
    color: ${(p: InputProps) => (p.error ? '#9f3a38' : 'rgba(0, 0, 0, 0.87)')};
    border-radius: 0.28571429rem;
    transition: box-shadow 0.1s ease, border-color 0.1s ease;
    box-shadow: none;
  }

  .disabled.field {
    background-color: #f5f8fa !important;
    opacity: 1 !important;
  }

  .placeholder {
    text-align: center;
    color: red;
  }
`;

const AppMaskedInput = React.forwardRef((props: InputProps, ref) => (
  <InputWrapper {...props}>
    <MaskedInput {...props.field} {...props} ref={ref} />
  </InputWrapper>
));

AppMaskedInput.displayName = 'AppMaskedInput';

export default AppMaskedInput;
