import * as Yup from "yup";
import {INVALID_EMAIL_MESSAGE, REQUIRED_FIELD_MESSAGE} from "../constants";

export const ownerInputSectionYupSchema: Yup.ObjectSchema<OwnerInputSectionSchema> = Yup.object().shape({
  document: Yup.string()
    .required(REQUIRED_FIELD_MESSAGE)
    .isValidDocument(),
  name: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  email: Yup.string().email(INVALID_EMAIL_MESSAGE)
})

export type OwnerInputSectionSchema = {
  name: string;
  document: string;
  email?: string;
}
