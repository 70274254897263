import { IOF, round } from '../util';
import { BondInsuranceCalculationData } from '../types/BondInsuranceCalculationData';
import { Code } from '../types/InsurerState';
import { post } from './request';

export const calculateInsuranceValue = (
  totalAmount: number,
  validity: number,
  insurerTax: number,
  insurerCode: string,
  rentValue?: number
): BondInsuranceCalculationData => {
  const value = (rentValue || totalAmount) * insurerTax * validity;
  const netValue = value / (1 + IOF);
  const installmentsValue =
    value / (insurerCode === Code.Fairfax ? validity - 2 : validity);
  const lmi = (rentValue || totalAmount) * 18;
  const netTax = value / lmi;

  return {
    value: round(value, 2),
    netValue: round(netValue, 2),
    installmentsValue: round(installmentsValue, 2),
    lmi: round(lmi, 2),
    netTax: round(netTax, 4)
  };
};

export const getUserBondInsurances = async (
  cpf: string,
  estate_id: string,
  status?: string,
  fromDate?: Date
) => {
  const filters = { cpf, estate_id, fromDate, status };
  try {
    const response = await post(`/bond-insurance-analysis/user`, filters);

    return response;
  } catch (err) {
    console.error({ err });

    return err;
  }
};
