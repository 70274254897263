import React, { useState } from 'react';
import { LoaderProps } from 'semantic-ui-react';
import Loader from '../../components/Loader';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import BudgetsTable from './BudgetsTable'
import Filter from './Filter';
import { connect } from 'react-redux';
import { iRootState } from '../../store';
import { FormikProps } from 'formik';
import { FilterData } from './types';

const ListWrapper = styled.div`
  display: flex;
  flex-direction:column;
  padding: 1em;
`;

const mapState = (state: iRootState) => ({
  budget: state.fireInsuranceBudget,
  budgetsData: state.fireInsuranceBudgetsList
});


export type Props = ReturnType<typeof mapState>
& RouteComponentProps & FormikProps<FilterData>;

const Budgets = (props: Props) => {

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const loaderProps = {
    active: isLoading
  } as LoaderProps

  return (
    <>
    <Loader {...loaderProps} />
      <ListWrapper>
        <Filter {...props}/>
        <p/>
        <BudgetsTable
        setIsLoading={setIsLoading}
        {...props} />
      </ListWrapper >
    </>
  );
}

export default connect(mapState, null)(Budgets);
