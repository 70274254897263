import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { Button, Dropdown, Grid, Icon, Input, Loader, Menu, Popup, Table } from 'semantic-ui-react';
import { getCanceleds } from '../../../services/fire-insurance-quotation';
import useEffectOnce from '../../../services/UseEffectOnce';
import { translateStatus } from '../utils/Pottencial'
import DetailModal from './Details/DetailModal';
import { FireInsuranceQuoteFiltersInterface } from '../util'
import { FieldLabel } from '../../../styles';
import * as Storage from '../../../services/sessionStorage'
import { canceledStatusList } from '../utils/Pottencial'
import { dangerNotification } from '../../../services/notification';
import { sleep, UsersToCustomDropDownOptions } from '../../../util';
import { UserForUpdateEstateForm } from '../../estates-edit-form/types';
import { getUsers } from '../../../services/broker';
import { maskCpfOrCnpj } from '../../../services/masks';
import CustomDatePicker from '../../../components/CustomDatePicker';
import AppMaskedInput from '../../../components/MaskedInput';

const ClickableTableCell = styled(Table.Cell)`
  cursor: pointer;
  position: relative;
`;

const ClickableTableRow = styled(Table.Row)`
  :hover {
    background-color: #f0f0f0;
  }
`;


const CanceledList = () => {
  const user = Storage.get('user')

  const [insurerOptions, setInsurerOptions] = useState<any[]>([])
  const [estateOptions, setEstateOptions] = useState<any[]>([])
  const [accountManagerOptions, setAccountManagerOptions] = useState<any[]>([])

  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true)

  const [data, setData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState<number>(0);
  const lastPage = Math.floor(count / 10) + 1;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedFireInsurance, setSelectedFireInsurance] = useState<any>();
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [name, setName] = useState<string>('');
  const [document, setDocument] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [responsibleId, setResponsibleId] = useState<string>('');
  const [id, setId] = useState<string>('');

  const [estate, setEstate] = useState<any>(user.role.includes('ESTATE') ? user.estate.id : undefined)
  const [insurer, setInsurer] = useState<any>()
  const [status, setStatus] = useState<any>()

  const [sendHiring, setSendHiring] = useState<boolean>(false)

  const getQuotations = async () => {
    setIsLoading(true);

    if (isFirstLoading) {
      await sleep(1000);
      setIsFirstLoading(false);
    }

    const filter: FireInsuranceQuoteFiltersInterface = {
      page: currentPage,
      start: start ? moment(start).format('YYYY-MM-DD') : undefined,
      end: end ? moment(end).format('YYYY-MM-DD') : undefined,
      name: name ? name : undefined,
      document: document ? document.replace(/[^\d]+/g,'') : undefined,
      address: address ? address : undefined,
      responsibleId: responsibleId ? responsibleId : undefined,
      id: id ? id : undefined,
      insurerId: insurer || undefined,
      estateId: estate || undefined,
      status: status || undefined
    }
    const resp = await getCanceleds(filter);
    setData(resp[0]);
    setCount(resp[1]);
    setIsLoading(false);
  }

  const getEstatesAndInsurers = async () => {
    if (Storage.exists('insurers')) {
      const storageInsurers = Storage.get('insurers');
      // console.log({storageInsurers})

      storageInsurers?.fireInsurers.map((insurer: any) => {
        setInsurerOptions(insurerOptions => [...insurerOptions, {
          key: insurer.id,
          value: insurer.id,
          text: insurer.name
        }])
      });
    }

    if (Storage.exists('estates')) {
      const storageEstates = Storage.get('estates');
      // console.log({storageInsurers})

      storageEstates.map((estate: any) => {
        setEstateOptions(estateOptions => [...estateOptions, {
          key: estate.id,
          value: estate.id,
          text: estate.name
        }])
      });
    }
  }

  useEffect(() => {
    if (currentPage)
    getQuotations()
  }, [currentPage])

  const getAccountManagers = async () => {
    if (user.broker?.id) {
      getUsers(user.broker.id, [{ isDropdown: true}]).then((result) => {
        const convertedValues: UserForUpdateEstateForm[] = result.map((user: any) => {
          const userForForm: UserForUpdateEstateForm = {
            id: user.id,
            name: user.name,

          }

          return userForForm
        })

        const analystsOptions = UsersToCustomDropDownOptions(false, convertedValues);
        // console.log({analystsOptions})
        setAccountManagerOptions(analystsOptions);
      }).catch((error) => {
        dangerNotification('Oops...', 'Não foi possível encontrar os analistas disponíveis')
      });
    }
  }

  useEffectOnce(() => {
    // getQuotations()
    getEstatesAndInsurers()
    // getAccountManagers()
  })

  useEffect(() => {
    if (insurerOptions.length === 1) {
      setInsurer(insurerOptions[0].value)
    }
  }, [insurerOptions])

  useEffect(() => {
    if (estateOptions.length === 1) {
      setEstate(estateOptions[0].value)
    }
  }, [estateOptions])

  const openDetailModal = (fireInsurance: any) => {
    setSelectedFireInsurance(fireInsurance);
    setOpenDetail(true);
  };

  const closeDetailModal = () => {
    setSelectedFireInsurance(undefined);
    setSendHiring(false);
    setOpenDetail(false);
  }

  useEffect(() => {
    if (data.length) {
      console.log({data})
    }
  }, [data])

  return (
    <>
      <Grid columns={'equal'}>
        <Grid.Row>
          <Grid.Column width={4}>
            <FieldLabel>Imobiliária</FieldLabel>
            <Dropdown
              fluid
              search
              clearable
              selection
              placeholder={'Imobiliária'}
              loading={!estateOptions.length}
              disabled={!estateOptions.length || estateOptions.length === 1}
              options={estateOptions}
              onChange={(e, { name, value }) => setEstate(value)}
              value={estate}
            />
          </Grid.Column>
          <Grid.Column>
            <FieldLabel>Seguradora</FieldLabel>
            <Dropdown
              fluid
              search
              clearable
              selection
              placeholder={'Seguradora'}
              loading={!insurerOptions.length}
              disabled={!insurerOptions.length || insurerOptions.length === 1}
              options={insurerOptions}
              onChange={(e, { name, value }) => setInsurer(value)}
              value={insurer}
            />
          </Grid.Column>
          <Grid.Column>
            <FieldLabel>Status</FieldLabel>
            <Dropdown
              fluid
              search
              clearable
              selection
              placeholder={'Status'}
              loading={!canceledStatusList.length}
              disabled={!canceledStatusList.length}
              options={canceledStatusList}
              onChange={(e, { name, value }) => setStatus(value)}
              value={status}
            />
          </Grid.Column>
          <Grid.Column></Grid.Column>
          <Grid.Column></Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <FieldLabel>Início</FieldLabel>
            {/* <SemanticDatePicker
              keepOpenOnClear
              locale={'pt-BR'}
              value={start}
              maxDate={end}
              format={'DD/MM/YYYY'}
              placeholder={'Data de Início'}
              onChange={(e, data: any) => setStart(data.value)}
            /> */}

        <CustomDatePicker
            fluid
            selected={start}
            maxDate={end}
            type={"date"}
            placeholder={'Data de Início'}
            onChange={(date: any) => setStart(date)}
            customInput={
              <AppMaskedInput
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  "/",
                  /[0-9]/,
                  /[0-9]/,
                  "/",
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                ]} />}
            dateFormat={"dd/MM/yyyy"}
          />
          </Grid.Column>
          <Grid.Column>
            <FieldLabel>Fim</FieldLabel>
            {/* <SemanticDatePicker
                keepOpenOnClear
                locale={'pt-BR'}
                value={end}
                minDate={start}
                format={'DD/MM/YYYY'}
                placeholder={'Data de Fim'}
                onChange={(e, data: any) => setEnd(data.value)}
              /> */}

        <CustomDatePicker
            fluid
            selected={end}
            minDate={start}
            placeholder={'Data de Fim'}
            onChange={(date: any) => setEnd(date)}
            customInput={
              <AppMaskedInput
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  "/",
                  /[0-9]/,
                  /[0-9]/,
                  "/",
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                ]} />}
            dateFormat={"dd/MM/yyyy"}
          />
          </Grid.Column>
          <Grid.Column width={4}>
            <FieldLabel>Nome/Razão Social</FieldLabel>
            <Input
              fluid
              placeholder={'Nome/Razão Social'}
              value={name}
              onChange={(e, data: any) => setName(data.value)}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <FieldLabel>CPF/CNPJ</FieldLabel>
            <Input
              fluid
              placeholder={'CPF/CNPJ'}
              value={maskCpfOrCnpj(document)}
              onChange={(e, data: any) => setDocument(data.value)}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <FieldLabel>Endereço</FieldLabel>
            <Input
              fluid
              placeholder={'Endereço'}
              value={address}
              onChange={(e, data: any) => setAddress(data.value)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign={'right'}>
            <Button
              disabled={isLoading}
              loading={isLoading}
              onClick={() => getQuotations()}
              primary
            >
              Pesquisar
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Table celled>
        <Table.Header fullWidth>
          <Table.HeaderCell
            textAlign={'center'}
          >
            Solicitado em
          </Table.HeaderCell>

          <Table.HeaderCell
            textAlign={'center'}
            width={2}
          >
            Seguradora
          </Table.HeaderCell>

          <Table.HeaderCell
            textAlign={'center'}
          >
            Imobiliária
          </Table.HeaderCell>

          <Table.HeaderCell
            textAlign={'center'}
          >
            Inquilino
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign={'center'}
          >
            Endereço
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign={'center'}
          >
            Status
          </Table.HeaderCell>
          {/* <Table.HeaderCell
            textAlign={'center'}
          >
            Responsável
          </Table.HeaderCell> */}
          {/* <Table.HeaderCell
            textAlign={'center'}
          >
            Ações
          </Table.HeaderCell> */}
        </Table.Header>
        <Table.Body>
          {
            isLoading ? (
              <Table.Row>
                <Table.Cell textAlign={'center'} colSpan={7}>
                  <Loader active={isLoading} inline />
                </Table.Cell>
              </Table.Row>
            ) : (
              <>
              {
                data.length ? (
                  <>
                  {
                    data.map((item: any) => {
                      console.log('FIRE', item);
                      const fireInsurance = item.fireInsuranceAnalysis.fireInsurance;
                      return (
                        <ClickableTableRow>
                          <ClickableTableCell
                            onClick={() => openDetailModal(item.fireInsuranceAnalysis)}
                            textAlign={'center'}
                          >
                            {moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                          </ClickableTableCell>
                          <ClickableTableCell
                            onClick={() => openDetailModal(item.fireInsuranceAnalysis)}
                            textAlign={'center'}
                          >
                            {item.fireInsuranceAnalysis.insurer.name}
                          </ClickableTableCell>
                          <ClickableTableCell
                            onClick={() => openDetailModal(item.fireInsuranceAnalysis)}
                            textAlign={'center'}
                          >
                            {fireInsurance.estate.name}
                          </ClickableTableCell>
                          <ClickableTableCell
                            onClick={() => openDetailModal(item.fireInsuranceAnalysis)}
                            textAlign={'center'}
                          >
                            {fireInsurance.insuredName}
                            <br />
                            <span style={{color: '#777'}}>({maskCpfOrCnpj(fireInsurance.insuredDocument)})</span>
                          </ClickableTableCell>
                          <ClickableTableCell
                            onClick={() => openDetailModal(item.fireInsuranceAnalysis)}
                            textAlign={'center'}
                          >
                            {`${fireInsurance.propertyAddresses.street}, ${fireInsurance.propertyAddresses.number}${fireInsurance.propertyAddresses?.complement ? ` - ${fireInsurance.propertyAddresses.complement}` : ''}`}
                          </ClickableTableCell>
                          <ClickableTableCell
                            onClick={() => openDetailModal(item.fireInsuranceAnalysis)}
                            textAlign={'center'}
                          >
                            {
                              item.status === 'ERROR' ? (
                                <Popup
                                  header={'Erro'}
                                  content={item.observations}
                                  position={'left center'}
                                  trigger={<span style={{color: item.status.length ? 'rgba(0, 0, 0, .87)' : '#ccc'}}>{translateStatus(item.status)}</span>}
                                />
                              ) : translateStatus(item.status)
                            }
                            {
                              console.log({stats: item.status, tranlate: translateStatus(item.status), length: !!item.status.length})
                            }
                          </ClickableTableCell>
                        </ClickableTableRow>
                      )
                    })
                  }
                  </>
                ) : (
                  <Table.Row>
                    <Table.Cell textAlign={'center'} colSpan={7}>
                      Nenhum registro encontrado
                    </Table.Cell>
                  </Table.Row>
                )
              }
              </>
            )

          }
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan={9} textAlign={"right"}>
              {!isLoading && (
                <Menu floated="right" pagination>
                  <Menu.Item>
                    <p>{`${data.length ? currentPage * 10 - 9 : 0} - ${
                      count < currentPage * 10 ? count : currentPage * 10
                    } de ${count}`}</p>
                  </Menu.Item>
                  <Menu.Item
                    disabled={currentPage === 1}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(1)}
                  >
                    <Icon name="angle double left" />
                  </Menu.Item>
                  <Menu.Item
                    disabled={currentPage === 1}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item as="a">{currentPage}</Menu.Item>
                  <Menu.Item
                    disabled={currentPage === lastPage || data.length < 10}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                  <Menu.Item as="a" icon>
                    <Icon
                      disabled={currentPage === lastPage || data.length < 10}
                      name="angle double right"
                      onClick={() => setCurrentPage(lastPage)}
                    />
                  </Menu.Item>
                </Menu>
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <DetailModal
        open={openDetail}
        fireInsurance={selectedFireInsurance}
        close={closeDetailModal}
        hire={sendHiring}
      />
    </>
  )
}

export default CanceledList;
