import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { styles } from '../styles';
import TableHeader from './TableHeader';

const InsurancePriceDataTable = ({ data }: any) => {
  return (
    <>
      <View style={styles.tableContainer} wrap={false}>
        <TableHeader title={'VALORES DO SEGURO E PAGAMENTO'} />

        <View style={styles.row}>
          <View style={styles.column}>
            <Text style={styles.label}>{'Prêmio Líquido Coberturas'}</Text>
            <Text style={styles.value}>{data.coverageAmount}</Text>
          </View>

          <View style={styles.column}>
            <Text style={styles.label}>{'IOF'}</Text>
            <Text style={styles.value}>{data.iof}</Text>
          </View>

          <View style={styles.column}>
            <Text style={styles.label}>{'Custo Serviços Adicionais'}</Text>
            <Text style={styles.value}>{data.assistanceValue}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.column}>
            <Text style={styles.label}>{'TOTAL'}</Text>
            <Text style={styles.value}>{data.grossPremium}</Text>
          </View>

          <View style={styles.column}>
            <Text style={styles.label}>{'NÚMERO DE PARCELAS'}</Text>
            <Text style={styles.value}>{data.installments}</Text>
          </View>

          <View style={styles.column}>
            <Text style={styles.label}>{'FORMA DE PAGAMENTO'}</Text>
            <Text style={styles.value}>{'---'}</Text>
          </View>
        </View>
      </View>
    </>
  );
};

export default InsurancePriceDataTable;
