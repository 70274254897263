import {isCnpj, isCpf} from "validator-brazil";
import {addMethod} from "yup";
import * as Yup from "yup";
import {unmaskCpfOrCnpj} from "../../../services/masks";

export const REQUIRED_FIELD_MESSAGE = 'Campo Obrigatório!';
export const INVALID_EMAIL_MESSAGE = 'O e-mail informado não é válido';
export const INVALID_CEP_MESSAGE = 'CEP inválido';
export const INVALID_CPF_MESSAGE = 'CPF inválido!';
export const INVALID_CNPJ_MESSAGE = 'CNPJ inválido!';
export const INVALID_DOCUMENT_MESSAGE = 'Documento inválido!';
export const INVALID_CELLPHONE_MESSAGE = 'Celular inválido!'
export const INVALID_TELEPHONE_MESSAGE = 'Telefone inválido!';
export const GREATER_THAN_ZERO_MESSAGE = 'Valor deve ser maior que zero!';
export const LESS_THAN_OR_EQUAL_TO_MESSAGE = 'O valor informado deve ser menor ou igual a R$';

declare module 'yup' {
  interface StringSchema {
    isValidDocument(): StringSchema;
    isValidMaskedCEP(): StringSchema;
    isValidMaskedTelephone(): StringSchema;
  }
}

addMethod(Yup.string, 'isValidDocument', function() {
  return this.test('test-is-valid-document', '', function (maskedDocument: string) {
    const { path, createError } = this;

    let errorMessage = '';
    let isValid = true;
    const unmaskedDocument = unmaskCpfOrCnpj(maskedDocument);

    if (unmaskedDocument.length === 11) {
      if (!isCpf(unmaskedDocument)) {
        isValid = false;
        errorMessage = INVALID_CPF_MESSAGE;
      }
    } else if (unmaskedDocument.length === 14) {
      if (!isCnpj(unmaskedDocument)) {
        isValid = false;
        errorMessage = INVALID_CNPJ_MESSAGE;
      }
    } else {
      isValid = false;
      errorMessage = INVALID_DOCUMENT_MESSAGE;
    }

    if (isValid) {
      return isValid;
    } else {
      return createError({
        message: errorMessage,
        path
      });
    }
  })
});

addMethod(Yup.string, 'isValidMaskedCEP', function() {
  return this.test('test-is-valid-masked-cep', '', function (maskedCEP: string) {
    const { path, createError } = this;

    const maskedCepRegex = /^[0-9]{5}-[0-9]{3}$/;

    if (maskedCepRegex.test(maskedCEP)) {
      return true;
    } else {
      return createError({
        message: INVALID_CEP_MESSAGE,
        path
      });
    }
  })
});

addMethod(Yup.string, 'isValidMaskedTelephone', function() {
  return this.test('test-is-valid-masked-telephone', '', function (maskedTelephone: string) {
    const { path, createError } = this;

    const maskedTelephoneRegex = /^\([0-9]{2}\) 9[0-9]{4}\-[0-9]{4}$/;

    if (maskedTelephoneRegex.test(maskedTelephone)) {
      if (maskedTelephone.length === 15) {
        const firstDigit = maskedTelephone.split(' ')[1].charAt(0);

        if (firstDigit !== '9' && firstDigit !== '8') {
          return createError({
            message: INVALID_CELLPHONE_MESSAGE,
            path
          });
        }
      }

      return true;
    } else {
      return createError({
        message: INVALID_TELEPHONE_MESSAGE,
        path
      });
    }
  })
});
