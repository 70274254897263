import { Label, Image, Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

export const NavbarContainer = styled.div`
  width: 100%;
  height: 4em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 0 0;
  flex-wrap: wrap;
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CustomDropdownItemContainer = styled(Dropdown.Item)<{
  align: 'row' | 'column';
  hasBottomBorder?: boolean;
}>`
  display: flex !important;
  flex-direction: ${props => props.align || 'column'};
  gap: 10px;
  min-width: 260px;
  padding: 10px !important;
  border-bottom: ${props =>
    props.hasBottomBorder ? '1px solid #DDDDDD' : 'unset'} !important;
  opacity: 100 !important;
  color: #393939 !important;
`;

export const NavbarText = styled.div`
  font-weight: bold;
  font-size: 24px;
  padding-left: 1em;
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: unset;
  gap: 5px;
`;

export const Avatar = styled(Label)`
  &.ui.circular.label {
    min-height: 1.5em;
    min-width: 1.5em;
    line-height: 1.5em;
  }
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: end;
`;

export const UserName = styled.p`
  color: #393939;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
`;

export const ContentText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #393939;
  margin: 0;
  width: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const DescriptionText = styled.p<{ weight?: number }>`
  font-size: 12px;
  font-weight: ${props => props?.weight || 600};
  line-height: 16px;
  color: #808080;
  margin: 0;
`;

export const MobileHeaderContainer = styled.div`
  display: flex;
  height: 60px;
  box-shadow: 0px 0px 7px #00000080;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 16px;
  align-items: center;
`;

export const Logo = styled(Image)`
  width: 3.5em;
`;
