import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { useQuery } from '@tanstack/react-query';
import { iRootDispatch, iRootState } from '../../store';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Grid,
  Dropdown as DropdownNativo,
  Sidebar,
  Icon,
  Dropdown,
} from 'semantic-ui-react';
import { UsersToCustomDropDownOptions } from '../../util';
import Button from '../../components/Button';
import { getUsers } from '../../services/broker';
import Input from '../../components/Input';
import { FilterData } from './types';
import AppMaskedInput from '../../components/MaskedInput';
import CustomDatePicker from '../../components/CustomDatePicker';
import {
  FieldLabel,
  FilterButton,
  FilterTitleContainer,
  FilterSidebarOverlay,
  ButtonsContainer
} from '../../styles';
import {
  sortBondInsurances,
  getBondInsuranceByID,
  getBondInsurances,
} from '../../services/bond-insurance';
import BondInsurancesTable from './BondInsurancesTable';
import { CustoDropDownItemProps } from '../../components/types';
import { BondInsuranceListProps } from './BondInsurancesForm';
import { dangerNotification } from '../../services/notification';
import { AvailableBrokersAndEstatesState } from '../../store/types/temp-types';
import { InsurerState } from '../../types/InsurerState';
import useClickOutside from '../../hooks/useClickOutside';
import useWindowSize from '../../hooks/useWindowSize';

import { moreFiltersOptions, selectableStatus, clientTypeOptions, typeOptions, MoreFiltersEnum, ArgumentTypeEnum } from './types';

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

interface LocationState {
  expiresInAWeek?: boolean;
  bondInsuranceId?: string;
  bondInsuranceAnalysisId?: string;
  analysisStatus?: string;
}

const BondInsurances = (props: BondInsuranceListProps) => {
  const id = props.match.params.id;
  const location = useLocation();
  const { availableBrokersAndEstates, user, setFieldValue, values } = props;
  const estates: CustoDropDownItemProps[] = availableBrokersAndEstates.estates;
  const insurers: InsurerState[] | null =
    props.availableBrokersAndEstates.bondInsurers.map((insurer: any) => insurer.value);
  const [externalBondInsuranceAnalysisId, setExternalBondInsuranceAnalysisId] = useState(
    (location.state as LocationState)?.bondInsuranceAnalysisId
  );
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [fieldValues, setFieldValues] = useState<FilterData>({
    expiresInAWeek: !!(location.state as LocationState)?.expiresInAWeek,
    bondInsuranceId: (location.state as LocationState)?.bondInsuranceId,
    analysisStatus: (location.state as LocationState)?.analysisStatus
  });
  const [cleanInputs, setCleanInputs] = useState(false);
  const [ascendingOrder, setAscendingOrder] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedEstate, setSelectedEstate] = useState<number>();
  const [datesRange, setDatesRange] = useState('');
  const [startDate, setStartDate] = useState<Date | string>('');
  const [endDate, setEndDate] = useState<Date | string>('');
  const selectableInsurers = insurers.map(insurer => ({
    index: insurer.id,
    value: insurer.id,
    text: insurer.name
  }));
  const [analysisStatus, setAnalysisStatus] = useState<string>('Todos');
  const [selectedAccountManager, setSelectedAccountManager] = useState<any>();
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);

  const filterContainerRef = useClickOutside<HTMLDivElement>(() => {
    if (isFilterVisible) {
      setIsFilterVisible(false);
    }
  });

  const [type, setType] = useState<any>('document');
  const [argument, setArgument] = useState<string>('');
  const [clientType, setClientType] = useState<string>('Todos');

  const [filterSelected, setFilterSelected] = useState<any>();

  const [total, setTotal] = useState(0);

  const { isMobile } = useWindowSize();

  const handleMoreFilters = (value: string) => {
    switch (value) {
      case MoreFiltersEnum.unread:
        setFieldValue('onlyUnread', true);
        setFieldValue('reanalysis', false);
        setFieldValue('expiresInAWeek', false);
        setFieldValue('onlyManual', false);
        setFieldValues(prevValue => ({ ...prevValue, onlyUnread: true, expiresInAWeek: false, onlyManual: false, reanalysis: false}));
        break;
      case MoreFiltersEnum.reanalysis:
        setFieldValue('reanalysis', true);
        setFieldValue('onlyUnread', false);
        setFieldValue('expiresInAWeek', false);
        setFieldValue('onlyManual', false);
        setFieldValues(prevValue => ({ ...prevValue, reanalysis: true, expiresInAWeek: false, onlyUnread: false, onlyManual: false }));
        break;
      case MoreFiltersEnum.expiring:
        setFieldValue('expiresInAWeek', true);
        setFieldValue('reanalysis', false);
        setFieldValue('onlyUnread', false);
        setFieldValue('onlyManual', false);
        setFieldValues(prevValue => ({ ...prevValue, expiresInAWeek: true, reanalysis: false, onlyManual: false, onlyUnread: false }));
        break;
      case MoreFiltersEnum.manual:
        setFieldValue('onlyManual', true);
        setFieldValue('onlyUnread', false);
        setFieldValue('reanalysis', false);
        setFieldValue('expiresInAWeek', false);
        setFieldValues(prevValue => ({ ...prevValue, onlyManual: true, expiresInAWeek: false, reanalysis: false, onlyUnread: false }));
        break;
    }
  }

  const handleSearchFor = (argument: string) => {
    switch (type) {
      case ArgumentTypeEnum.name:
        setFieldValue('name', argument);
        break;
      case ArgumentTypeEnum.address:
        setFieldValue('address', argument);
        break;
      case ArgumentTypeEnum.document:
        setFieldValue('document', argument);
        break;
      default:
        break;
    }
  }

  const handleNewChangeAccountManager = (accountManagerKey: any) => {
    if (!!accountManagerKey.length) {
      setFieldValues(prevValue => ({ ...prevValue, accountManager: accountManagerKey }));
      setSelectedAccountManager(accountManagerKey);
    } else {
      setFieldValues(prevValue => ({ ...prevValue, accountManager: accountManagerKey }));
      setSelectedAccountManager(null);
    }
  };

  useEffect(() => {
    if((location.state as LocationState)?.analysisStatus !== undefined) {
      const status = selectableStatus.find(status => status.value ===  (location.state as LocationState)?.analysisStatus)
      if (status) {
        setAnalysisStatus(status.value)
      }
    }
  }, [])

  useEffect(() => {
    if (analysisStatus === 'Todos') {
      setFieldValues(prevValue => ({ ...prevValue, analysisStatus: undefined }));
    } else {
      setFieldValues(prevValue => ({ ...prevValue, analysisStatus: analysisStatus }));
    }
  }, [analysisStatus]);

  useEffect(() => {
    if (startDate && typeof startDate !== 'string') {
      const newDate = startDate.toISOString().substring(0, 10);

      setFieldValues(prevValue => ({ ...prevValue, start: newDate }));
    } else {
      setFieldValues(prevValue => ({ ...prevValue, start: '' }));
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate && typeof endDate !== 'string') {
      const newDate = endDate.toISOString().substring(0, 10);

      setFieldValues(prevValue => ({ ...prevValue, end: newDate }));
    } else {
      setFieldValues(prevValue => ({ ...prevValue, end: '' }));
    }
  }, [endDate]);

  useEffect(() => {
    const splitDate = datesRange.split(' - ');
    if (splitDate.length === 2) {
      const rawStart = splitDate[0];
      const rawEnd = splitDate[1];
      const start = rawStart.split('-').reverse().join('-');
      const end = rawEnd.split('-').reverse().join('-');
      setFieldValue('start', start);
      setFieldValue('end', end);
    }
  }, [datesRange]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFieldValues(prevValue => ({ ...prevValue, [name]: value }));
  };

  const getAccountManagers = async () => {
    const result = await getUsers(props?.user?.broker?.id, [{isDropdown: true}]);
    return UsersToCustomDropDownOptions(false, result.map(user => ({
      id: user.id,
      name: user.name
    })));
  };

  const { data: accountManagerOptions, isFetching: isAccountManagersLoading } = useQuery(
    ['getAccountManagers'],
    getAccountManagers,
    {
      keepPreviousData: false,
      enabled: !!props?.user?.broker?.id,
    }
  );

  async function loadTotal(filter: FilterData) {
    const response = await getBondInsurances(currentPage, {...filter, action: "COUNT"});
    setTotal(response.count);
  }

  const getData = async () => {
    try {
      let filter = values as FilterData;

      Object.keys(fieldValues).forEach((key: string) => {
        switch (key) {
          case 'name':
            filter.name = fieldValues.name ? fieldValues.name.toLowerCase() : '';
            break;
          case 'status':
            filter.status = fieldValues.status ? fieldValues.status.toLowerCase() : '';
            break;
          case 'document':
            filter.document = fieldValues.document
              ? fieldValues.document.toLowerCase()
              : '';
            break;
          case 'address':
            filter.address = fieldValues.address ? fieldValues.address.toLowerCase() : '';
            break;
          case 'start':
            filter.start = fieldValues.start ? fieldValues.start : undefined;
            break;
          case 'end':
            filter.end = fieldValues.end ? fieldValues.end : undefined;
            break;
          case 'analysisStatus':
            filter.analysisStatus = fieldValues.analysisStatus || undefined;
            break;
          case 'insurer':
            filter.insurer = fieldValues.insurer || undefined;
            break;
          case 'reanalysis':
            filter.reanalysis = fieldValues.reanalysis || false;
            break;
          case 'onlyUnread':
            filter.onlyUnread = fieldValues.onlyUnread || false;
            break;
          case 'onlyManual':
            filter.onlyManual = fieldValues.onlyManual || false;
            break;
          case 'expiresInAWeek':
            filter.expiresInAWeek = fieldValues.expiresInAWeek || false;
            break;
          case 'accountManager':
            filter.accountManager = fieldValues.accountManager
              ? fieldValues.accountManager
              : '';
            break;
          case 'bondInsuranceId':
            filter.bondInsuranceId = fieldValues.bondInsuranceId;
            break;
          case 'typeClient':
            if (clientType !== 'Todos') {
              filter.clientType = clientType
            }
            break;
        }
      });

      if (id) {
        const bondInsurance = await getBondInsuranceByID(id);

        if (bondInsurance) {
          return {
            data: [bondInsurance],
            current: 1,
            count: 1,
            page: 1,
          };
        }
      }

      filter = { ...filter, status: 'CREATED' };
      const response = await getBondInsurances(currentPage, filter);
      response.data = sortBondInsurances(response.data, ascendingOrder);

      setFieldValues(prevValue => ({ ...prevValue, bondInsuranceId: undefined }));

      if(currentPage === 1) {
        loadTotal(filter);
      }

      return response;
    } catch (e) {
      console.log({ e });
      dangerNotification('Oops...', 'Não foi possível buscar as análises');
    } finally {
      setCleanInputs(false);
    }
  };

  const { data: bondInsurances, isFetching: isLoading, refetch } = useQuery(
    ['getData', currentPage],
    getData,
    {
      keepPreviousData: true,
      refetchOnMount: true,
      staleTime: 5000,
    }
  );

  useEffect(() => {
    window.history.replaceState(
      {
        ...(location.state as any),
        bondInsuranceId: undefined,
        analysisStatus: undefined
      },
      ''
    );
  }, [location]);

  const clearInputs = () => {
    setFieldValues({
      name: '',
      address: '',
      status: '',
      document: '',
      start: '',
      end: '',
      estate_id: '',
      insurer: '',
      reanalysis: false,
      expiresInAWeek: false,
      onlyUnread: false,
      onlyManual: false,
      accountManager: null
    });

    if (availableBrokersAndEstates.estates.length === 1) {
      setSelectedEstate(availableBrokersAndEstates.estates[0].value.id);
      setFieldValue('estate_id', availableBrokersAndEstates.estates[0].value.id);
    } else {
      setSelectedEstate(0);
      setFieldValue('estate_id', null);
    }
    setType(null);
    setFilterSelected('');
    setClientType('Todos');
    setCurrentPage(1);
    setAnalysisStatus('Todos');
    setStartDate('');
    setEndDate('');
    setSelectedAccountManager(null);
    setFieldValues(prevValue => ({ ...prevValue, bondInsuranceId: undefined, expiresInAWeek: false, onlyUnread: false, onlyManual: false, reanalysis: false }));
    setCleanInputs(true);
  };

  const CleanLocation = () => {
    setExternalBondInsuranceAnalysisId(undefined);
    const fields = fieldValues;
    fields.bondInsuranceId = undefined;
    fields.analysisStatus = undefined;
    setFieldValues(fields);
  }

  useEffect(() => {
    refetch();
  }, [cleanInputs])

  const handleSubmitSearch = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (isMobile) {
      setIsFilterVisible(false);
    }

    setCurrentPage(1);
    refetch();
  };

  const handleNewChangeEstate = (estateKey: any) => {
    const selected = estates.find(c => c.value.id === estateKey.id);

    if (!selected) {
      setFieldValue('estate_id', null);
      setSelectedEstate(0);
      return;
    }

    setSelectedEstate(estateKey);
    setFieldValue('estate_id', selected.value.id);
  };

  const renderFilter = () => (
    <div ref={filterContainerRef}>
      {isMobile && (
        <FilterTitleContainer>
          <h3>Filtros</h3>
        </FilterTitleContainer>
      )}
      <form onSubmit={handleSubmitSearch}>
      <Grid>
          <Grid.Row>
            <Grid.Column width={3}>
              <FieldLabel>Pesquisar por</FieldLabel>
              <div style={{ width: '100%' }}>
                <Dropdown
                  style={{ width: '100%' }}
                  value={type}
                  name="type"
                  options={typeOptions}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  disabled={isLoading}
                  onChange={(e, { value }) => {
                    setType(value);
                    if (argument) {
                      handleSearchFor(argument);
                    }
                  }}
                  clearable
                />
              </div>
            </Grid.Column>
            <Grid.Column width={4}>
              <FieldLabel>Termo de pesquisa:</FieldLabel>
              <Input
                name="argument"
                onChange={(e, { value }) => {
                  setArgument(value);
                  if (type) {
                    handleSearchFor(value);
                  }
                }}
                disabled={isLoading}
                value={argument}
                fluid
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <FieldLabel>Seguradora:</FieldLabel>
              <div style={{ width: '100%' }}>
                <DropdownNativo
                  style={{ width: '100%' }}
                  value={fieldValues.insurer}
                  name={`insurer`}
                  defaultValue={
                    selectableInsurers.length === 1
                      ? selectableInsurers[0].value
                      : '' || ''
                  }
                  disabled={isLoading || selectableInsurers.length === 1}
                  options={selectableInsurers || []}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  onChange={(e, { value }) =>
                    setFieldValues(prevValue => ({
                      ...prevValue,
                      insurer: String(value)
                    }))
                  }
                  clearable
                />
              </div>
            </Grid.Column>
            <Grid.Column width={4}>
              <FieldLabel>Imobiliária:</FieldLabel>
              <div style={{ width: '100%' }}>
                <DropdownNativo
                  style={{ width: '100%' }}
                  value={selectedEstate}
                  name={`estate`}
                  defaultValue={
                    availableBrokersAndEstates.estates.length === 1
                      ? availableBrokersAndEstates.estates[0].value
                      : '' || ''
                  }
                  disabled={isLoading || availableBrokersAndEstates.estates.length === 1}
                  options={availableBrokersAndEstates.estates || []}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  onChange={(e, { value }) => handleNewChangeEstate(value)}
                  clearable
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>
              <FieldLabel>Data Inicial:</FieldLabel>
              <Field
                name={`start`}
                selected={startDate ? startDate : ''}
                onChange={(date: Date) => {
                  setStartDate(date);
                }}
                disabled={isLoading}
                component={CustomDatePicker}
                customInput={
                  <AppMaskedInput
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/
                    ]}
                  />
                }
                dateFormat={'dd/MM/yyyy'}
              />
            </Grid.Column>

            <Grid.Column width={2}>
              <FieldLabel>Data Final:</FieldLabel>
              <Field
                name={`end`}
                selected={endDate ? endDate : ''}
                onChange={(date: Date) => {
                  setEndDate(date);
                }}
                disabled={isLoading}
                component={CustomDatePicker}
                customInput={
                  <AppMaskedInput
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/
                    ]}
                  />
                }
                dateFormat={'dd/MM/yyyy'}
              />

            </Grid.Column>

            {!user.role.includes('ESTATE') && (
              <Grid.Column width={3}>
                <FieldLabel>Gestor de Contas:</FieldLabel>
                <DropdownNativo
                  style={{ width: '100%' }}
                  value={selectedAccountManager}
                  name={`accountManager`}
                  options={accountManagerOptions}
                  disabled={isLoading || isAccountManagersLoading}
                  loading={isAccountManagersLoading}
                  placeholder={'Selecionar...'}
                  search
                  selection
                  onChange={(e, { value }) => handleNewChangeAccountManager(value)}
                  clearable
                />
              </Grid.Column>
            )}

            <Grid.Column width={3}>
              <FieldLabel>Status:</FieldLabel>
              <DropdownNativo
                value={analysisStatus}
                name={`status`}
                defaultValue={
                  selectableStatus.length === 1
                    ? selectableStatus[0].value
                    : 'Todos' || 'Todos'
                }
                disabled={isLoading || selectableStatus.length === 1}
                options={selectableStatus || []}
                placeholder={'Selecionar...'}
                search
                fluid
                selection
                onChange={(e, { value }) => {
                  setAnalysisStatus(value as string);
                }}
                clearable
              />
            </Grid.Column>

            <Grid.Column width={3}>
              <FieldLabel>Tipo de Cliente:</FieldLabel>
              <div style={{ width: '100%' }}>
                <DropdownNativo
                  value={clientType}
                  placeholder="Selecionar..."
                  style={{ width: '100%' }}
                  name="clientType"
                  options={clientTypeOptions}
                  search
                  disabled={isLoading}
                  selection
                  onChange={(e, { value }) => {
                    value !== 'Todos' ?
                      setFieldValue('clientType', value as string)
                    : setFieldValue('clientType', undefined)
                    setClientType(value as string)
                  }}
                  clearable
                />
              </div>
            </Grid.Column>

            {!user.role.includes('ESTATE') && (
              <Grid.Column width={3}>
                <FieldLabel>Mais Filtros:</FieldLabel>
                <div style={{ width: '100%' }}>
                  <DropdownNativo
                    value={filterSelected}
                    placeholder="Selecionar..."
                    style={{ width: '100%' }}
                    name="moreFilters"
                    options={moreFiltersOptions}
                    search
                    disabled={isLoading}
                    selection
                    onChange={(e, { value }) => {
                      handleMoreFilters(String(value));
                      setFilterSelected(value);
                    }}
                    clearable
                  />
                </div>
              </Grid.Column>
            )}
          </Grid.Row>
          <Grid.Row style={{ margin: 0 }}>
            <Grid.Column width={12}></Grid.Column>
            <Grid.Column width={4} >
              <ButtonsContainer>
                <Button
                  fluid
                  type={'submit'}
                  color="blue"
                  disabled={isLoading}
                  text="Pesquisar"
                  onClick={handleSubmitSearch}
                />
                <Button basic fluid type={'button'} text="Limpar" onClick={clearInputs} />
              </ButtonsContainer>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </form>
    </div>
  );

  return (
    <>
      {isMobile ? (
        <>
          <h3>Gerenciar Análises</h3>
          <FilterSidebarOverlay visible={isFilterVisible} />
          <Sidebar
            visible={isFilterVisible}
            direction={'right'}
            style={{ backgroundColor: '#ffffff' }}
            animation="push"
          >
            {renderFilter()}
          </Sidebar>
        </>
      ) : (
        renderFilter()
      )}
      {(
        <BondInsurancesTable
          bondInsurances={bondInsurances?.data || []}
          isLoading={isLoading}
          currentPage={bondInsurances?.page || 1}
          setCurrentPage={setCurrentPage}
          getData={() => refetch()}
          dataCount={total}
          externalBondInsuranceAnalysisId={externalBondInsuranceAnalysisId}
          cleanIdReferences={() => CleanLocation()}
        />
      )}
      {!isLoading && (
        <FilterButton
          aria-label="Abrir Filtro"
          title="Abrir Filtro"
          onClick={() => setIsFilterVisible(true)}
        >
          <Icon name="search" size="large" />
        </FilterButton>
      )}
    </>
  );
};

export default connect(mapState, mapDispatch)(BondInsurances);
