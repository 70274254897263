import React, { useState } from 'react';
import { Button, Grid, Icon, Popup } from 'semantic-ui-react';
import { BondInsuranceMainInfoDetails } from './components/BondInsuranceMainInfoDetails';
import { BondInsuranceNaturalTenantDetails } from './components/BondInsuranceNaturalTenantDetails';
import { BondInsuranceLegalTenantDetails } from './components/BondInsuranceLegalTenantDetails';
import { BondInsurancePropertyDetails } from './components/BondInsurancePropertyDetails';
import {
  BondInsuranceAnalysisForGetCompleteBondInsuranceProposalByIdResponseDTO,
  BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO,
  BondInsuranceHiredDataForGetCompleteBondInsuranceProposalByIdResponseDTO
} from '../../../../dtos/bond-insurance-proposal-list/get-complete-bond-insurance-proposal-by-id-response.dto';
import PrintableModalWithoutAttach from '../../../../components/PrintableModalWithoutAttach';
import SectionLabel from '../../../../components/SectionLabel';
import { PersonTypesEnum } from '../../../../enums/person-types.enum';
import { BondInsuranceProposalMainInfoDetails } from './components/BondInsuranceProposalMainInfoDetails';
import { BondInsuranceOwnerDetails } from './components/BondInsuranceOwnerDetails';
import { downloadLetter, sendAnyLetter } from '../../../../services/bond-insurance';
import {
  dangerNotification,
  successNotification
} from '../../../../services/notification';
import LogModal from '../../../../components/LogModal/LogModal';
import { UserRolesEnum } from '../../../../enums/user-roles.enum';
import { getUser } from '../../../../services/user';
import { get } from '../../../../services/storage';
import AuthorizationContainer from '../../../../components/AuthorizationContainer';
import { ActionEnum } from '../../../../enums/authz-action.enum';
import { FeatureEnum } from '../../../../enums/authz-feature.enum';

export type Props = {
  bondInsurance: BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO | null;
  bondInsuranceAnalysis: BondInsuranceAnalysisForGetCompleteBondInsuranceProposalByIdResponseDTO | null;
  hiredData: BondInsuranceHiredDataForGetCompleteBondInsuranceProposalByIdResponseDTO | null;
  isOpen: boolean;
  bondInsuranceId?: string;
  callback: Function;
  onClose: React.ReactEventHandler<{}>;
};

export const BondInsuranceProposalDetails = (props: Props) => {
  const {
    bondInsurance,
    bondInsuranceAnalysis,
    hiredData,
    isOpen,
    bondInsuranceId,
    onClose,
    callback
  } = props;

  const [openLogModal, setOpenLogModal] = useState(false);
  const [loadingDownloadFile, setLoadingDownloadFile] = useState(false);

  const close = (): void => {
    setOpenLogModal(false);
  };

  const userRole = get('role');

  const [loadingMail, setLoadingMail] = useState(false);
  return (
    <>
      {bondInsurance && bondInsuranceAnalysis && hiredData && (
        <PrintableModalWithoutAttach
          isOpen={isOpen}
          onClose={onClose}
          bondInsuranceId={bondInsuranceId}
          header={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              Proposta de Seguro Fiança
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                {userRole === UserRolesEnum.brokerAdmin && (
                  <LogModal
                    setOpen={setOpenLogModal}
                    callback={callback}
                    closeModal={close}
                    analysisId={bondInsurance.id}
                    proposalId={bondInsuranceAnalysis.id}
                    policyId={hiredData.id}
                    createdAt={bondInsurance.createdAt}
                  />
                )}
                <AuthorizationContainer action={ActionEnum.send} feature={FeatureEnum.letters}>
                  <Button
                    floated="left"
                    color="blue"
                    loading={loadingMail}
                    disabled={loadingMail}
                    onClick={async () => {
                      setLoadingMail(true);
                      try {
                        await sendAnyLetter(bondInsuranceAnalysis.id);
                        successNotification('Sucesso', 'Carta enviada!');
                      } catch (e) {
                        dangerNotification('Oops...', 'Ocorreu um erro ao enviar a carta');
                      }
                      setLoadingMail(false);
                    }}
                  >
                    Reenviar carta de aprovação
                  </Button>
                  </AuthorizationContainer>
                  <AuthorizationContainer action={ActionEnum.download} feature={FeatureEnum.letters}>
                    <Popup
                      content={'Clique para realizar o Download da carta de Aprovação e sua Cláusula!'}
                      position={'top left'}
                      trigger={
                        <Button
                          icon="download"
                          color="blue"
                          loading={loadingDownloadFile}
                          onClick={async () => {
                            try {
                              setLoadingDownloadFile(true);
                              await downloadLetter(
                                bondInsuranceAnalysis.id,
                                bondInsurance.naturalTenant
                                  ? bondInsurance.naturalTenant.main_tenant_name
                                  : bondInsurance.legalTenant.company
                              );
                              successNotification(
                                'Sucesso',
                                'Download realizado com sucesso!'
                              );
                            } catch (e) {
                              dangerNotification(
                                'Oops...',
                                'Ocorreu um erro ao realizar o download carta'
                              );
                            }
                            finally{
                              setLoadingDownloadFile(false);
                            }
                          }}
                        ></Button>
                      }
                    />
                  </AuthorizationContainer>
              </div>
            </div>
          }
          content={
            <Grid>
              <BondInsuranceProposalMainInfoDetails
                bondInsuranceAnalysis={bondInsuranceAnalysis}
                hiredData={hiredData}
              />
              <SectionLabel text="DADOS DO PROPRIETÁRIO" />
              <BondInsuranceOwnerDetails bondInsurance={bondInsurance} />
              <SectionLabel text="DADOS DA ANÁLISE" />
              <BondInsuranceMainInfoDetails
                bondInsurance={bondInsurance}
                bondInsuranceAnalysis={bondInsuranceAnalysis}
              />
              <BondInsurancePropertyDetails bondInsurance={bondInsurance} />
              <SectionLabel text="DADOS DO LOCATÁRIO" />
              {bondInsurance.tenantType === PersonTypesEnum.natural && (
                <BondInsuranceNaturalTenantDetails bondInsurance={bondInsurance} />
              )}
              {bondInsurance.tenantType === PersonTypesEnum.legal && (
                <BondInsuranceLegalTenantDetails bondInsurance={bondInsurance} />
              )}
            </Grid>
          }
          callback={(): Promise<void | BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO> => {
            return {} as Promise<BondInsuranceForGetCompleteBondInsuranceProposalByIdResponseDTO>;
          }}
        />
      )}
    </>
  );
};
