import React, { useEffect, useState, SyntheticEvent } from "react";
import {
  Table,
  Icon,
  Dimmer,
  Segment,
  Loader,
  Dropdown,
} from "semantic-ui-react";
import { BudgetsTableProps, FilterData } from "./types";
import { connect } from "react-redux";
import styled from "styled-components";
import IconButton from "../../components/IconButton";
import { FireInsuranceBudget } from "../../types/FireInsurance";
import { maskMoney } from "../../services/masks";
import { getBudgets } from "../../services/fire-insurance-budget";
import { iRootState, iRootDispatch } from "../../store";
import moment from "moment-timezone";
import { createProposalFromBudget } from "../../services/fire-insurance-proposal";
import {
  cancelBudget,
  printBudget,
} from "../../services/fire-insurance-budget";
import ConfirmationModal, {
  ConfirmationModalContent,
} from "../../components/ConfirmationModal";
import {
  successNotification,
  dangerNotification,
} from "../../services/notification";
import { CustoDropDownItemProps } from "../../components/types";
import { getUserFireInsuranceBudgets } from "../../services/user";
import { UserRolesEnum } from "../../enums/user-roles.enum";
import { DateToStringDateTime } from "../../services/date";
import { Code } from "../../types/InsurerState";
import { FireInsuranceBudgetsListState } from "../../store/types/temp-types";

const Header = styled(Table.Header)`
  background-color: #e1e8ed;
`;

const TableWrapper = styled.div`
  font-size: 0.85em;
  font-weight: bold;

  .ui.table {
    border: none;
    text-align: center;
    color: #364445;
  }
`;

const NotFoundWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #364445;
  font-size: 1.2em;
`;

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  budgetsData: state.fireInsuranceBudgetsList,
  user: state.user,
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateList: (payload: Partial<FireInsuranceBudgetsListState>) =>
    dispatch.fireInsuranceBudgetsList.updateFireInsuranceBudgetsList(payload),
});

type Props = BudgetsTableProps & {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
} & ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch>;

const BudgetsTable = (props: Props) => {
  const { availableBrokersAndEstates, budgetsData, user, setIsLoading } = props;
  const { isLoading, budgets } = budgetsData;
  const [ascending, setAscending] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] = useState<string>("");

  const brokers: CustoDropDownItemProps[] = availableBrokersAndEstates.brokers;
  const estates: CustoDropDownItemProps[] = availableBrokersAndEstates.estates;

  useEffect(() => {
    (async () => {
      const filter = {} as FilterData;
      if (estates.length === 1) {
        filter.estate_id = estates[0].value.id;
      }
      if (brokers.length === 1) {
        filter.broker_id = brokers[0].value.id;
      }
      props.updateList({ isLoading: true, budgets: [] });
      const data =
        user.role === UserRolesEnum.brokerAnalyst || user.role === UserRolesEnum.accountManager
          ? await getUserFireInsuranceBudgets(user.id, filter)
          : await getBudgets({ ...filter });
      props.updateList({ isLoading: false, budgets: data || [] });
    })();
  }, []);

  const orderData = () => {
    props.updateList({ isLoading: true });
    const sortedBudgets: Array<FireInsuranceBudget> = budgets.sort(
      (b1: FireInsuranceBudget, b2: FireInsuranceBudget) => {
        if (!b1.created_at) b1.created_at = new Date(0, 0, 0);
        if (!b2.created_at) b2.created_at = new Date(0, 0, 0);
        if (ascending) {
          if (moment(b1.created_at).valueOf() > moment(b2.created_at).valueOf())
            return -1;
          if (moment(b1.created_at).valueOf() < moment(b2.created_at).valueOf())
            return 1;
        } else {
          if (moment(b1.created_at).valueOf() > moment(b2.created_at).valueOf())
            return 1;
          if (moment(b1.created_at).valueOf() < moment(b2.created_at).valueOf())
            return -1;
        }
        return 0;
      }
    );
    props.updateList({ isLoading: false, budgets: sortedBudgets });
  };

  function saveBudgetConfirmationContent(
    budget: FireInsuranceBudget
  ): string | JSX.Element {
    const tenantName = budget.tenant_name || "";
    const propertyCode = budget.property_code || "";

    const tenantIdentifier = tenantName ? ` de ${tenantName}` : "";
    const baseMessage = `Você tem certeza que deseja salvar o orçamento${tenantIdentifier}?`;

    if (propertyCode) {
      const codeIdentifier = `Código externo ${propertyCode}`;
      return (
        <ConfirmationModalContent>
          <div id="baseMessage">{baseMessage}</div>
          <div id="codeIdentifier">{codeIdentifier}</div>
        </ConfirmationModalContent>
      );
    } else {
      return baseMessage;
    }
  }

  function saveBudgetCancelContent(
    budget: FireInsuranceBudget
  ): string | JSX.Element {
    const tenantName = budget.tenant_name || "";
    const propertyCode = budget.property_code || "";

    const tenantIdentifier = tenantName ? ` de ${tenantName}` : "";
    const baseMessage = `Você tem certeza que deseja cancelar o orçamento${tenantIdentifier}?`;

    if (propertyCode) {
      const codeIdentifier = `Código externo ${propertyCode}`;
      return (
        <ConfirmationModalContent>
          <div id="baseMessage">{baseMessage}</div>
          <div id="codeIdentifier">{codeIdentifier}</div>
        </ConfirmationModalContent>
      );
    } else {
      return baseMessage;
    }
  }

  function saveBudgetPrintContent(
    budget: FireInsuranceBudget
  ): string | JSX.Element {
    const tenantName = budget.tenant_name || "";
    const propertyCode = budget.property_code || "";

    const tenantIdentifier = tenantName ? ` de ${tenantName}` : "";
    const baseMessage = `Deseja imprimir o orçamento${tenantIdentifier}?`;

    if (propertyCode) {
      const codeIdentifier = `Código externo ${propertyCode}`;
      return (
        <ConfirmationModalContent>
          <div id="baseMessage">{baseMessage}</div>
          <div id="codeIdentifier">{codeIdentifier}</div>
        </ConfirmationModalContent>
      );
    } else {
      return baseMessage;
    }
  }

  const confirmPrintBudget = async (
    id: string,
    userId: string,
    code: string
  ): Promise<any> => {
    setIsLoading(true);
    return printBudget(id, userId, code);
  };

  const confirmCreateProposalFromBudget = async (
    budget: FireInsuranceBudget
  ): Promise<any> => {
    setIsLoading(true);
    return createProposalFromBudget(
      budget,
      budget?.occupation!.property_type?.insurer?.id
    );
  };

  const confirmCancelBudget = async (
    id: string,
    code: string
  ): Promise<boolean> => {
    setIsLoading(true);
    return cancelBudget(id, code);
  };

  const renderPdf = (res: any) => {
    const linkSource = `data:application/pdf;base64,${res.base64}`;
    const downloadLink = document.createElement("a");
    const fileName = res.document + ".pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  function renderTableData(budgets: Array<FireInsuranceBudget>) {
    return budgets.map((budget) => {
      const saveBudgetConfirmationTrigger = (
        <Dropdown.Item
          text="Contratar Seguro"
          disabled={selectedItem !== budget.id}
          icon="check"
        />
      );

      const saveBudgetCancelTrigger = (
        <Dropdown.Item
          text="Cancelar"
          disabled={selectedItem !== budget.id}
          icon="cancel"
        />
      );

      const saveBudgetPrintTrigger = (
        <Dropdown.Item
          text="Imprimir Orçamento"
          disabled={
            selectedItem !== budget.id || budget.code !== Code.TokioMarine
          }
          icon="print"
        />
      );

      const trigger = (
        <>
          <IconButton color="blue" name="ellipsis vertical" />
        </>
      );

      budget.user_id = user.id;
      return (
        <Table.Row key={budget.id}>
          <Table.Cell>
            {DateToStringDateTime(budget.created_at || new Date(0, 0, 0))}
          </Table.Cell>
          <Table.Cell>
            {budget?.occupation?.property_type.insurer.name}
          </Table.Cell>
          <Table.Cell>
            {budget?.estate?.name ?
              budget?.estate?.name :
            'Indisponível'}
          </Table.Cell>
          <Table.Cell>
            {budget.occupation?.property_type.description}
          </Table.Cell>
          <Table.Cell>{budget.tenant_name}</Table.Cell>
          <Table.Cell>{budget.property_code}</Table.Cell>
          <Table.Cell>{`R$ ${maskMoney(
            `${budget.cover_value}`,
            true
          )}`}</Table.Cell>
          <Table.Cell>{budget.installments}</Table.Cell>
          <Table.Cell width={1} textAlign={"right"}>
            <Dropdown
              trigger={trigger}
              icon={null}
              direction="left"
              onOpen={() => setSelectedItem(budget.id || "")}
              onClose={() => setSelectedItem("")}
            >
              <Dropdown.Menu>
                <ConfirmationModal
                  positive
                  header="Imprimir Orçamento"
                  content={saveBudgetPrintContent(budget)}
                  trigger={saveBudgetPrintTrigger}
                  callback={(): Promise<void | FireInsuranceBudget> =>
                    confirmPrintBudget(
                      budget.id || "",
                      budget.user_id,
                      budget.code
                    )
                      .then((res) => {
                        renderPdf(res);
                        setIsLoading(false);
                        successNotification("Sucesso", "Concluído!");
                        budgets.splice(budgets.indexOf(budget), 1);
                        setSelectedItem("");
                      })
                      .catch((e) => {
                        if (e.message) {
                          dangerNotification("Erro", e.message);
                        } else {
                          dangerNotification(
                            "Erro",
                            "Não foi possível imprimir o orçamento"
                          );
                        }
                        setIsLoading(false);
                        console.error(e);
                      })
                  }
                />
                <ConfirmationModal
                  positive
                  header="Contratar Seguro"
                  content={saveBudgetConfirmationContent(budget)}
                  trigger={saveBudgetConfirmationTrigger}
                  callback={(): Promise<void | FireInsuranceBudget> =>
                    confirmCreateProposalFromBudget(budget)
                      .then((res) => {
                        successNotification(
                          "Sucesso",
                          "Seguro contratado com sucesso!"
                        );
                        budgets.splice(budgets.indexOf(budget), 1);
                        setIsLoading(false);
                        setSelectedItem("");
                      })
                      .catch((e) => {
                        if (e.message) {
                          dangerNotification("Erro", e.message);
                        } else {
                          dangerNotification(
                            "Erro",
                            "Não foi possível realizar a contratação do seguro"
                          );
                        }
                        setIsLoading(false);
                        console.error(e);
                      })
                  }
                />
                <ConfirmationModal
                  positive
                  header="Cancelar Orçamento"
                  content={saveBudgetCancelContent(budget)}
                  trigger={saveBudgetCancelTrigger}
                  callback={(): Promise<void | FireInsuranceBudget> =>
                    confirmCancelBudget(budget.id || "", budget.code)
                      .then((res) => {
                        successNotification(
                          "Sucesso",
                          "Orçamento cancelado com sucesso!"
                        );
                        budgets.splice(budgets.indexOf(budget), 1);
                        setIsLoading(false);
                        setSelectedItem("");
                      })
                      .catch((e) => {
                        if (e.message) {
                          dangerNotification("Erro", e.message);
                        } else {
                          dangerNotification(
                            "Erro",
                            "Não foi possível cancelar o orçamento"
                          );
                        }
                        setIsLoading(false);
                        console.error(e);
                      })
                  }
                />
              </Dropdown.Menu>
            </Dropdown>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return (
    <div>
      <Dimmer.Dimmable as={Segment} dimmed={isLoading}>
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
        {budgets && budgets.length > 0 ? (
          <TableWrapper>
            <Table>
              <Header>
                <Table.Row>
                  <Table.HeaderCell width={2}>
                    <p>
                      CRIADO EM
                      <IconButton
                        name={
                          ascending
                            ? "long arrow alternate down"
                            : "long arrow alternate up"
                        }
                        color="blue"
                        onClick={(e: SyntheticEvent) => {
                          setAscending(!ascending);
                          orderData();
                        }}
                      />
                    </p>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>SEGURADORA</Table.HeaderCell>
                  <Table.HeaderCell width={3}>IMOBILIÁRIA</Table.HeaderCell>
                  <Table.HeaderCell width={2}>TIPO IMÓVEL</Table.HeaderCell>
                  <Table.HeaderCell width={3}>INQUILINO</Table.HeaderCell>
                  <Table.HeaderCell width={1}>COD. EXTERNO</Table.HeaderCell>
                  <Table.HeaderCell width={3}>
                    PRÊMIO TOTAL (R$)
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2}>Nº PARCELAS</Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign={"right"}>
                    <IconButton color="blue" name="setting" />
                  </Table.HeaderCell>
                </Table.Row>
              </Header>
              <Table.Body>{renderTableData(budgets)}</Table.Body>
            </Table>
          </TableWrapper>
        ) : (
          <NotFoundWrapper>
            <p>Orçamento não encontrado</p>
          </NotFoundWrapper>
        )}
      </Dimmer.Dimmable>
    </div>
  );
};

export default connect(mapState, mapDispatch)(BudgetsTable);
