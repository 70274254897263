import React, {ReactHTMLElement} from 'react';
import styled from 'styled-components';

import { TextAreaProps, TextArea as SemanticTextArea} from 'semantic-ui-react';

const TextAreaWrapper = styled.div`
  border: ${(p: TextAreaProps) => p.error ? 'red' : 'blue'};
  border-radius: 5px;

  textarea {
    width: 100%;
    padding: 12px 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: vertical;
    outline: none;
  };

  .disabled.field {
    background-color: #F5F8FA !important;
    opacity: 1 !important;
  };

  .placeholder {
    text-align: center;
    color:red;
  };
`

type SemanticUITextAreaProps = {
  handleRef?: (el: ReactHTMLElement<any>) => void;
} & TextAreaProps

const FormatedTextArea = (props: SemanticUITextAreaProps) => {
  return (
    <TextAreaWrapper>
      {
        props.handleRef ?
            <SemanticTextArea {...props.field} {...props} ref={props.handleRef}/>
          :
          <SemanticTextArea {...props.field} {...props}/>
      }
    </TextAreaWrapper>
  )
};

export default FormatedTextArea;
