import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Info } from "../../../../components/Info";
import { DateToStringDate } from "../../../../services/date";
import SectionLabel from "../../../../components/SectionLabel";
import {
  getBondInsuranceProposalStatusLabel
} from "../../../../services/bond-insurance-proposal";
import { maskMoney } from "../../../../services/masks";
import { BondInsuranceHiredDataStatusEnum } from '../../../../enums/bond-insurance-hired-data-status.enum';
import { AnalysisResponseDTO, HiredDataResponseDTO } from '../../types/bondinsurance-interfaces';

export type Props = {
  analysis: AnalysisResponseDTO;
  hiredData: HiredDataResponseDTO;
};

export const BondInsuranceProposalMainInfoDetails = (props: Props) => {

  const { analysis, hiredData } = props;

  return (
    <>
      <SectionLabel text='DADOS DA PROPOSTA' />
      <Grid.Row>
        <Info name={'ID'} value={analysis.id} width={8} />
        <Info name={'DATA DE EMISSÃO DA PROPOSTA'} value={
          analysis.insurerProposalCreatedAt
            ? DateToStringDate(analysis.insurerProposalCreatedAt)
            : 'Indisponível'} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'SEGURADORA'} value={analysis.insurerName} width={8} />
        <Info name={'CÓD. DA COTAÇÃO'} value={analysis.insurerQuotationId ? analysis.insurerQuotationId : 'Indisponível'} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'CÓD. DA PROPOSTA'} value={analysis.insurerProposalId} width={8} />
        <Info name={'STATUS'} value={getBondInsuranceProposalStatusLabel(hiredData.status as BondInsuranceHiredDataStatusEnum) || ""} width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'VIGÊNCIA INFORMADA NA ANÁLISE'} value={analysis.property.validity || 'Indisponível'} width={8} />
        <Info name={'PREMIO TOTAL COTADO (R$)'} value={
          maskMoney(String(analysis.value * analysis.property.validity), true, 2)
          || 'Indisponível'
        } width={8} />
      </Grid.Row>
      <Grid.Row>
        <Info name = {"Início vigência contrato locação"} value = {analysis.property.rental_period_start_date ? DateToStringDate(analysis.property.rental_period_start_date) : (analysis.property.rent_insurance_contract_start_date ? DateToStringDate(analysis.property.rent_insurance_contract_start_date) : 'Indisponível') }  width={8} />
        <Info name = {"Fim vigência contrato locação"} value ={analysis.property.rental_period_end_date ? DateToStringDate(analysis.property.rental_period_end_date) : (analysis.property.rent_insurance_contract_end_date ? DateToStringDate(analysis.property.rent_insurance_contract_end_date) : 'Indisponível') }  width={8}/>
      </Grid.Row>

      <Grid.Row>
          <Info name = {"Início vigência da garantia"} value = {analysis.property.rent_insurance_contract_start_date ? DateToStringDate(analysis.property.rent_insurance_contract_start_date) : (analysis.property.rental_period_start_date ? DateToStringDate(analysis.property.rental_period_start_date) : 'Indisponível') }  width={8} />
          <Info name = {"Fim vigência da garantia"}  value ={analysis.property.rent_insurance_contract_end_date ? DateToStringDate(analysis.property.rent_insurance_contract_end_date) : (analysis.property.rental_period_end_date ? DateToStringDate(analysis.property.rental_period_end_date) : 'Indisponível') }  width={8}/>
      </Grid.Row>

      <Grid.Row> <Info name={'DIA DE VENCIMENTO DO ALUGUEL'} value={`${analysis.property.rent_due_date || "Indisponível"}`} width={8} /> </Grid.Row>

      <Grid.Row>
        <Info name={'Observações análise'} value={analysis.observations} width={16} />
      </Grid.Row>
      <Grid.Row>
        <Info name={'Observações Contratação'} value={hiredData.observations} width={16} />
      </Grid.Row>
    </>
  )
}
