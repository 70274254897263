import moment from "moment-timezone";
import {get, post} from "./request";
import {GetCompleteBondInsuranceProposalByIdResponseDTO} from "../dtos/bond-insurance-proposal-list/get-complete-bond-insurance-proposal-by-id-response.dto";
import { warningNotification } from "./notification";
import { ResponsePrintBondInsuraceProposalDTO } from "../dtos/bond-insurance-proposal-list/get-bond-insurance-proposal-download";
import { GetCoveragesProps } from "../types/BondInsuranceProperty";
import { GetCoveragesPropsResponse } from "../pages/fire-insurance-proposal-list/types";
import { BondInsuranceHiredDataStatusEnum } from "../enums/bond-insurance-hired-data-status.enum";

export function getBondInsuranceProposalStatusLabel(status: BondInsuranceHiredDataStatusEnum): string {
  switch(status) {
    case BondInsuranceHiredDataStatusEnum.waiting:
      return "Aguardando Ação";
    case BondInsuranceHiredDataStatusEnum.insurerHireRelatedInformationPending:
      return "Informações de Contratação Enviadas para a Seguradora";
    case BondInsuranceHiredDataStatusEnum.hireRelatedInformationError:
      return "Erro no Envio de Informações de Contratação";
    case BondInsuranceHiredDataStatusEnum.insurerReadyToHire:
      return "Pronto para Contratar";
    case BondInsuranceHiredDataStatusEnum.insurerHirePending:
      return "Contratação em Andamento";
    case BondInsuranceHiredDataStatusEnum.insurerHirePendingIntegred:
      return "Contratação integrada em Andamento"
    case BondInsuranceHiredDataStatusEnum.hireError:
      return "Erro na Contratação";
    case BondInsuranceHiredDataStatusEnum.insurerPolicyPending:
      return "Emissão de Apólice Pendente pela Seguradora";
    case BondInsuranceHiredDataStatusEnum.policyError:
      return "Erro na Emissão de Apólice";
    case BondInsuranceHiredDataStatusEnum.insurerPolicyCreated:
      return "Emitida";
    case BondInsuranceHiredDataStatusEnum.cancelled:
      return "Cancelada";
    default:
      return "Contratada";
  }
}

export const getBondInsuranceProposal =
  async (analysisId: string): Promise<GetCompleteBondInsuranceProposalByIdResponseDTO> => {
    return await get(`/bond-insurance/proposals/${analysisId}`);
}

export function sortBondInsuranceProposals<T> (
  data: Array<SortBondInsuranceProposalType<T>>,
  ascending: boolean
): Array<SortBondInsuranceProposalType<T>> {
  return data.sort(
    (p1: SortBondInsuranceProposalType<T>, p2: SortBondInsuranceProposalType<T>): number => {
      const p1CreatedAt = moment(p1.insurerProposalCreatedAt || new Date(0, 0, 0));
      const p2CreatedAt = moment(p2.insurerProposalCreatedAt || new Date(0, 0, 0));
      if (p1CreatedAt.isSame(p2CreatedAt)) {
        return 0;
      } else {
        return ascending === p1CreatedAt.isAfter(p2CreatedAt) ? -1 : 1;
      }
    }
  );
};

export const printBondInsuraceProposal = async (
  bond_insurance_analysis_id: string,
): Promise<ResponsePrintBondInsuraceProposalDTO> => {
  try {
    warningNotification("Aguarde!", "Carregando proposta para download.", undefined , 10000);
    const response: ResponsePrintBondInsuraceProposalDTO = await post("/bond-insurance-analysis/document/proposal", { bond_insurance_analysis_id });
    if(response.success){
      return response
    }else{
      throw new Error(JSON.stringify(response.errorMessages))
    }
  } catch (e) {
    throw e;
  }
};

export const getCoverages = async (data: GetCoveragesProps): Promise<GetCoveragesPropsResponse> => {
  {
    let response;

    try {
      response = await get(
        `/fire-insurance-quotations/coverages?insurerCode=${data.insurer_code}`
      );
    } catch (e) {
      response = [];
    }
    return response;
  };
};

type SortBondInsuranceProposalType<T> = T & {insurerProposalCreatedAt: string | Date};
