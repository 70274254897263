import { subtractMonthToDate } from '../../services/date';

export const reportFormInitialValues: ReportFormValues = {
  broker: {
    id: '',
    name: ''
  },
  isAnalysisSelected: false,
  isProposalSelected: false,
  isPolicySelected: false,
  general: {
    estate: '',
    insurer: '',
    accountManager: ''
  },
  analysis: {
    status: 'ALL',
    startDate: subtractMonthToDate(new Date, 1),
    endDate: new Date,
    initialValue: 0,
    finalValue: 0,
    expiresInDays: 0,
  },
  proposal: {
    status: '0',
    startDate: subtractMonthToDate(new Date, 1),
    endDate: new Date,
    initialValue: 0,
    finalValue: 0
  },
  policy: {
    status: 'ALL',
    startDate: subtractMonthToDate(new Date, 1),
    endDate: new Date,
    initialValue: 0,
    finalValue: 0
  }
};

export interface ReportFormValues {
  broker: BrokerForReportForm;
  isAnalysisSelected: boolean;
  isProposalSelected: boolean;
  isPolicySelected: boolean;
  general: GeneralInfos;
  analysis: SpecificInfos;
  proposal: SpecificInfos;
  policy: SpecificInfos;
}

export type GeneralInfos = {
  estate?: string;
  insurer?: string;
  accountManager?: string;
}

export type SpecificInfos = {
  status: string;
  startDate?: Date | string;
  endDate?: Date | string;
  initialValue?: number;
  finalValue?: number;
  expiresInDays?: number;

  estate?: string;
  insurer?: string;
  accountManager?: string;
}

// BondInsuraceList
export type FilterData = {
  broker_id?: string;
  estate_id?: string;
  user_id?: string;
  address?: string;
  name?: string;
  status?: string;
  document?: string;
  start?: string;
  end?: string;
  insurer?: string;
  analysisStatus?: string;
}

//PoliciesList
export type PoliciesFilters = {
  brokerId?: string;
  estateId?: string;
  start?: string;
  end?: string;
  name?: string;
  address?: string;
  document?: string;
  status?: string;
  insurer?: string;
}

//ProposalList
export type ProposalsFilterData = {
  broker_id: string;
  estate_id: string;
  user_id: string;
  address?: string;
  name?: string;
  document?: string;
  start?: string;
  insurer?: string;
  end?: string;
  status?: number;
}

export type BrokerForReportForm = {
  id: string;
  name: string;
}

export type UserForReportForm = {
  id: string;
  name: string;
}
