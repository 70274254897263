export const RESIDENTIAL_FLAG = [
  {
    key: 'S',
    value: 'S',
    text: 'SIM'
  },
  {
    key: 'N',
    value: 'N',
    text: 'NÃO'
  }
];

export const PROPERTY_TYPE = [
  {
    key: 1,
    value: 1,
    text: 'CONSULTORIOS, ESCOLAS, ESCRITORIOS E HOSPITAIS'
  },
  {
    key: 2,
    value: 2,
    text: 'COMERCIO E SERVICOS'
  },
  {
    key: 3,
    value: 3,
    text: 'INDUSTRIAS'
  },
  {
    key: 4,
    value: 4,
    text: 'IMOVEIS DESOCUPADOS NAO LOCADOS'
  },
  {
    key: 5,
    value: 5,
    text: 'CASAS'
  },
  {
    key: 6,
    value: 6,
    text: 'APARTAMENTOS'
  },
  {
    key: 7,
    value: 7,
    text: 'IMOVEIS DESOCUPADOS'
  },
  {
    key: 8,
    value: 8,
    text: 'IMOVEIS DESOCUPADOS, APARTAMENTOS'
  },
  {
    key: 9,
    value: 9,
    text: 'CASA DE MADEIRA'
  }
];

export const STREET_TYPE = [
  {
    key: 'AL',
    value: 'AL',
    text: 'ALAMEDA'
  },
  {
    key: 'AV',
    value: 'AV',
    text: 'AVENIDA'
  },
  {
    key: 'EST',
    value: 'EST',
    text: 'ESTRADA'
  },
  {
    key: 'R',
    value: 'R',
    text: 'RUA'
  },
  {
    key: 'ROD',
    value: 'ROD',
    text: 'RODOVIA'
  },
  {
    key: 'TV',
    value: 'TV',
    text: 'TRAVESSA'
  }
];

export const SEX_TYPE = [
  {
    key: '0',
    value: '0',
    text: 'Masculino'
  },
  {
    key: '1',
    value: '1',
    text: 'Feminino'
  }
];

export const MARITAL_STATUS = [
  {
    key: 1,
    value: 1,
    text: 'Solteiro(a)'
  },
  {
    key: 2,
    value: 2,
    text: 'Casado(a)'
  },
  {
    key: 3,
    value: 3,
    text: 'Viúvo(a)'
  },
  {
    key: 4,
    value: 4,
    text: 'Desquitado(a)'
  },
  {
    key: 5,
    value: 5,
    text: 'Divorciado(a)'
  }
];

export const EDITABLE_COVERAGE = [true, true, true, true, true, true, true, true];

export const FORM_INITIAL_VALUES = {
  modality: '',
  propertyType: '',
  occupation: '',
  monthsValidity: undefined,
  rentValue: 0,
  proponentName: '',
  document: '',
  cellphone: '',
  birthdate: '',
  sexType: '',
  maritalStatus: undefined,
  proponentEmail: '',
  zipCode: '',
  streetType: '',
  street: '',
  district: '',
  city: '',
  state: '',
  number: undefined,
  complement: '',
  codePropertyType: undefined,
  fireCoverageValue: 30000,
  civilLiabilityCoverageValue: 0,
  coverageContentValue: 0,
  electricalDamageCoverageValue: 0,
  coverageImpactsAirLandVehiclesValue: 0,
  rentalLossCoverageValue: 0,
  galeCoverageValue: 0,
  riotCoverageValue: 0,
  estateId: '',
  insurerId: ''
};

const PropertyResidencialTypes = {
  Residencial: [
    {
      name: 'Casa Habitual',
      code: '4000'
    },
    // {
    //   name: 'Casa Veraneio',
    //   code: '4010'
    // },
    {
      name: 'Apartamento Habitual',
      code: '4070'
    },
    // {
    //   name: 'Apartamento Veraneio',
    //   code: '4080'
    // },
    // {
    //   name: 'Desocupado, Imóvel totalmente desocupado',
    //   code: '32070',
    //   types: [{ name: '', code: '1099' }]
    // }
  ],
  Comercial: [
    // {
    //   name: 'Academia de Ginástica/Lutas/Danças/Esportes',
    //   code: '32000'
    // },
    // {
    //   name: 'Açougue/Boutique de Carnes/Peixaria',
    //   code: '32001'
    // },
    // {
    //   name: 'Agência Bancária/Casa de Câmbio/Cooperativa de Crédito (loja)',
    //   code: '32003'
    // },
    // {
    //   name: 'Agência de Turismo/Viagem (excluído câmbio de valores)',
    //   code: '32004'
    // },
    // {
    //   name: 'Agência do Correio (excluindo bens de terceiros)',
    //   code: '32005'
    // },
    // {
    //   name: 'Armarinho/Artigos para presente, depósito',
    //   code: '32222'
    // },
    // {
    //   name: 'Armarinho/Artigos para presente, Loja',
    //   code: '32009'
    // },
    // {
    //   name: 'Asilo/Clinica de Reabilitação/Casa de Repouso',
    //   code: '32013'
    // },
    // {
    //   name: 'Bar/Botequim/Lanchonete/Pastelaria',
    //   code: '32020'
    // },
    // {
    //   name: 'Bebidas, Loja',
    //   code: '32023'
    // },
    // {
    //   name: 'Biblioteca/Sebo (Somente Prédio)',
    //   code: '32024'
    // },
    // {
    //   name: 'Bicicletas, Loja',
    //   code: '32025'
    // },
    // {
    //   name: 'Bilhares e Boliches, Salão de Jogos',
    //   code: '32028'
    // },
    // {
    //   name: 'Biscoitos/Balas/Doces/Chocolates, Loja',
    //   code: '32030'
    // },
    // {
    //   name: 'Bolsas/Cintos/Malas ou similares, Loja',
    //   code: '32033'
    // },
    // {
    //   name: 'Brinquedos, Loja',
    //   code: '32037'
    // },
    // {
    //   name: 'Buffet, Salão de festa',
    //   code: '32038'
    // },
    // {
    //   name: 'Cabeleireiro, Salão de Beleza/Estética',
    //   code: '32040'
    // },
    // {
    //   name: 'Calçados, Loja',
    //   code: '32044'
    // },
    // {
    //   name: 'Canetas/Carimbos/Chaveiros, Depósito',
    //   code: '32245'
    // },
    // {
    //   name: 'Canetas/Carimbos/Chaveiros, Loja',
    //   code: '32047'
    // },
    // {
    //   name: 'Cartórios',
    //   code: '32048'
    // },
    // {
    //   name: 'Cimento/Gesso, Loja',
    //   code: '32053'
    // },
    // {
    //   name: 'Cirúrgicos/Dentários/Hospitalares/Ortopédicos, Artigos Loja (excluindo medicamentos',
    //   code: '32056'
    // },
    // {
    //   name: 'Clínica Veterinária',
    //   code: '32057'
    // },
    // {
    //   name: 'Clínicas Médicas/Fisioterapia',
    //   code: '32220'
    // },
    // {
    //   name: 'Consultório/Médico/Odontológico',
    //   code: '32064'
    // },
    // {
    //   name: 'Copiadora, incluindo encadernação, plastificação e outros serviços inerentes',
    //   code: '32065'
    // },
    // {
    //   name: 'Discos, CDs e similares, Loja',
    //   code: '32071'
    // },
    // {
    //   name: 'Elétrica, Centros Automotivos incluindo instalação',
    //   code: '32074'
    // },
    // {
    //   name: 'Elétricos, Loja de artigos',
    //   code: '32076'
    // },
    // {
    //   name: 'Escola Privada de Ensino fundamental/Médio/Creche',
    //   code: '32086'
    // },
    // {
    //   name: 'Escola Privada de Idiomas/Curso Preparatório/Auto-escola',
    //   code: '32087'
    // },
    // {
    //   name: 'Escola Privada de Informática',
    //   code: '32088'
    // },
    // {
    //   name: 'Escolas Técnicas (profisionalizantes)',
    //   code: '32089'
    // },
    // {
    //   name: 'Escritório',
    //   code: '32090'
    // },
    // {
    //   name: 'Esportes, Loja',
    //   code: '32092'
    // },
    // {
    //   name: 'Estacionamento/Lava Rápido, sem oficina/abastecimento',
    //   code: '32095'
    // },
    // {
    //   name: 'Farmácia/Drogaria',
    //   code: '32100'
    // },
    // {
    //   name: 'Flores e Plantas, Loja',
    //   code: '32104'
    // },
    // {
    //   name: 'Fotografias, Loja',
    //   code: '32106'
    // },
    // {
    //   name: 'Funerárias (sem oficina)',
    //   code: '32110'
    // },
    // {
    //   name: 'Hospitais',
    //   code: '32118'
    // },
    // {
    //   name: 'Hotéis e Pousadas',
    //   code: '32119'
    // },
    // {
    //   name: 'Igreja/Convento',
    //   code: '32121'
    // },
    // {
    //   name: 'Informática/Equipamentos Eletrônicos, Loja',
    //   code: '32123'
    // },
    // {
    //   name: 'Laboratórios de Pesquisas e Análises Clínicas',
    //   code: '32128'
    // },
    // {
    //   name: 'Lavanderia, sem tinturaria (exceto industrial)',
    //   code: '32131'
    // },
    // {
    //   name: 'Limpeza, Loja',
    //   code: '32132'
    // },
    // {
    //   name: 'Livrarias',
    //   code: '32133'
    // },
    // {
    //   name: 'Lotéricas',
    //   code: '32135'
    // },
    // {
    //   name: 'Louças/Porcelanas/Cerâmica, Loja',
    //   code: '32137'
    // },
    // {
    //   name: 'Máquinas Comerciais/Industriais, Loja',
    //   code: '32140'
    // },
    // {
    //   name: 'Marmoraria',
    //   code: '32143'
    // },
    // {
    //   name: 'Mercearias/Mini-mercados até 300 m² de área construída',
    //   code: '32147'
    // },
    // {
    //   name: 'Motéis',
    //   code: '32120'
    // },
    // {
    //   name: 'Ortopédicos (Artigos ), Loja',
    //   code: '32157'
    // },
    // {
    //   name: 'Ótica, Loja',
    //   code: '32158'
    // },
    // {
    //   name: 'Padarias/Confeitarias',
    //   code: '32159'
    // },
    // {
    //   name: 'Papelarias, Loja',
    //   code: '32161'
    // },
    // {
    //   name: 'Perfumes/Cosméticos, Loja',
    //   code: '32164'
    // },
    // {
    //   name: 'Pet Shop',
    //   code: '32218'
    // },
    // {
    //   name: 'Produtos Naturais, loja',
    //   code: '32221'
    // },
    // {
    //   name: 'Restaurante/Churrascaria/Choperia/Pizzaria',
    //   code: '32186'
    // },
    // {
    //   name: 'Roupas, Lojas',
    //   code: '32190'
    // },
    // {
    //   name: 'Silk Screen/Estamparia de Roupas/Tecidos, permitindo venda de artigos',
    //   code: '32196'
    // },
    // {
    //   name: 'Tecidos ou Fios, Loja',
    //   code: '32206'
    // },
    // {
    //   name: 'Telefones (inclusive celulares), Loja',
    //   code: '32207'
    // },
    // {
    //   name: 'Universidades/Faculdades',
    //   code: '32214'
    // },
    // {
    //   name: 'Video Locadora',
    //   code: '32215'
    // },
    // {
    //   name: 'Vidros ou Espelhos, Lojas (Vidraceiros)',
    //   code: '32216'
    // },
    // {
    //   name: 'Desocupado, Imóvel totalmente desocupado',
    //   code: '32070'
    // },
    {
      name: 'Comércio',
      code: '32236'
    },
    {
      name: 'Serviços',
      code: '32237'
    }
  ]
};

const propertyResidentialOptions = PropertyResidencialTypes.Residencial.map(property => {
  return {
    key: property.code,
    text: property.name,
    value: property.code
  };
});

const propertyCommercialOptions = PropertyResidencialTypes.Comercial.map(property => {
  return {
    key: property.code,
    text: property.name,
    value: property.code
  };
});

export const getOccupationTypeOptions = (modality?: string, propertyType?: string) => {
  if (modality === 'Residencial') {
    if (propertyType === '1001') return propertyResidentialOptions.filter(property => !property.text.includes("Apartamento"));
    if (propertyType === '1002') return propertyResidentialOptions.filter(property => !property.text.includes("Casa"));
    return propertyResidentialOptions;
  } else {
    if (modality) {
      return propertyCommercialOptions;
    }
    else {
      return propertyResidentialOptions.concat(propertyCommercialOptions);
    }
  }
};

export const getPropertyTypeOptions = (modality?: string) => {
  if (modality === 'Residencial') {
    return [
      {
        key: '1001',
        text: 'Casa',
        value: '1001'
      },
      {
        key: '1002',
        text: 'Apartamento',
        value: '1002'
      }
    ];
  }

  if (modality) {
    return [
      {
        key: '1099',
        text: '-',
        value: '1099'
      }
    ];
  }
  return (
    [
      {
        key: '1001',
        text: 'Casa',
        value: '1001'
      },
      {
        key: '1002',
        text: 'Apartamento',
        value: '1002'
      },
      {
        key: '1099',
        text: '-',
        value: '1099'
      }
    ]
  )
};

export const coverageTypesOptions = [
  // {
  //   key: "2",
  //   text: "Prédio e Conteúdo",
  //   value: "2"
  // },
  // {
  //   key: "3",
  //   text: "Somente Prédio",
  //   value: "3"
  // },
  {
    key: "4",
    text: "Prédio (90%) e Conteúdo (10%)",
    value: "4"
  },
  // {
  //   key: "5",
  //   text: "Prédio (85%) e Conteúdo (15%)",
  //   value: "5"
  // },
]

export const installmentsOptions = [
  {
    key: "1",
    text: "1",
    value: "1"
  },
  {
    key: "2",
    text: "2",
    value: "2"
  },
  {
    key: "3",
    text: "3",
    value: "3"
  },
  {
    key: "4",
    text: "4",
    value: "4"
  },
  {
    key: "5",
    text: "5",
    value: "5"
  },
  {
    key: "6",
    text: "6",
    value: "6"
  },
  {
    key: "7",
    text: "7",
    value: "7"
  },
  {
    key: "8",
    text: "8",
    value: "8"
  },
  {
    key: "9",
    text: "9",
    value: "9"
  },
  {
    key: "10",
    text: "10",
    value: "10"
  },
  {
    key: "11",
    text: "11",
    value: "11"
  },
  {
    key: "12",
    text: "12",
    value: "12"
  }
];
