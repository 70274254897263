import { get } from "./request";
import { DataSyncFilter } from "../pages/events/types";

export const getDataSyncControlEntries = async (
  filters: DataSyncFilter
): Promise<any> => {
  let response;
  let query = "";

  if (filters) {
    Object.entries(filters).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  try {
    response = await get(`/datasync/synccontrol/logs?${query}`);
  } catch (e) {
    throw e;
  }
  return response;
};
