import React, { useState } from 'react';
import { Button, Checkbox, Dimmer, Grid, Icon, Loader, Modal } from 'semantic-ui-react';

import { dangerNotification, successNotification } from '../../../../../services/notification';
import { CancelAlfaSegurosBudget } from '../../../../../services/fire-insurance-quotation';
import { FieldLabel } from '../../../../../styles';
import { ObservationsText } from '../styles';

interface AlfaSeguroBudgetCancelModalProps {
  budgetId: string;
  open: boolean;
  onClose: () => void;
};

const AlfaSeguroCancelModal = (props: AlfaSeguroBudgetCancelModalProps): JSX.Element => {
  const { budgetId, open, onClose } = props;
  const [isCanceling, setIsCanceling] = useState<boolean>(false);
  const [observations, setObservations] = useState<string>('');
  const [checkBox, setCheckBox] = useState<boolean>(false);

  const handleOnChangeObservation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObservations(event.target.value);
  };

  const toggleCheckBox = () => {
    setCheckBox((prev) => !prev);
  };

  const handleConfirmButton = async () => {
    setIsCanceling(true);

    try {
      const payload = {
        budgetId,
        observations,
      };

      await CancelAlfaSegurosBudget(payload);

      successNotification(
        'Sucesso!',
        'Orçamento de seguro incêndio cancelado com sucesso.',
      );
    } catch (error) {
      dangerNotification(
        'Erro!',
        'Erro ao cancelar orçamento de seguro incêndio.',
      );
    } finally {
      setIsCanceling(false);
      onClose();
    }
  };

  return (
    <Modal
      size='small'
      open={open}
      onClose={onClose}
    >
      <Modal.Header>
        <Icon name='trash alternate'/>
        Confirmar cancelamento de orçamento
      </Modal.Header>

      <Modal.Content>
        <Dimmer active={isCanceling} inverted>
          <Loader />
        </Dimmer>

        <Grid style={{ padding: 5 }} columns={'equal'}>
          <Grid.Row>
            <Grid.Column>
              <p><b>Tem certeza que deseja cancelar este orçamento?</b></p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <FieldLabel>Observações</FieldLabel>
              <ObservationsText
                fluid={'true'}
                rows={8}
                value={observations}
                onChange={handleOnChangeObservation}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Checkbox
                label={
                  <label>
                    <b>Estou ciente que esta operação é irreversível.</b> Ao optar por cancelar um orçamento de incêndio,
                    não será possível reversão, caso ocorra desistência, deverá ser feito um novo orçamento.
                  </label>
                }
                onChange={toggleCheckBox}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>

      <Modal.Actions>
        <Button
          negative
          disabled={isCanceling}
          onClick={onClose}
        >
          Sair
        </Button>
        <Button
          positive
          disabled={!checkBox || isCanceling}
          onClick={handleConfirmButton}
        >
          Confirmar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AlfaSeguroCancelModal;
