import React, { useState } from 'react';
import { Button, Checkbox, Dropdown, Grid, Icon, Modal, TextArea } from 'semantic-ui-react';
import { NoRenewRequestModalProps } from './types';
import { Container, FormContainer, Form, Disclaimer, ButtonContainer } from './styles';
import { warningNotification } from '../../../../services/notification';
import { TextAreaWrapper } from '../CreatePolicyModal/styles';

const statusOptions = [
  { value: 'Troca de corretagem', text: 'Troca de corretagem', key: 1 },
  { value: 'Rescisão da locação', text: 'Rescisão da locação', key: 2 },
  { value: 'Troca de garantia', text: 'Troca de garantia', key: 3 },
  { value: 'Outros', text: 'Outros', key: 4 }
];

const NoRenewRequestModal: React.FC<NoRenewRequestModalProps> = ({
  isOpen,
  onClose,
  handleSubmit,
  bondInsuranceHiredDataId
}: NoRenewRequestModalProps) => {
  const [status, setStatus] = useState<string>('');
  const [isStatusError, setIsStatusError] = useState<boolean>(false);
  const [observation, setObservations] = useState<string>('');
  const [agree, setAgree] = useState<boolean>(false);

  if (!isOpen) {
    return null;
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    if (status !== '') {
      if (status === 'Outros' && !observation) {
        setIsStatusError(true);
        warningNotification(
          'Campos obrigatórios',
          'Por favor, informe no campo de observações o motivo da não renovação.'
        );
        return;
      }
      handleSubmit({ bondInsuranceHiredDataId, status, observation });
    } else {
      setIsStatusError(true);
      warningNotification(
        'Campos obrigatórios',
        'Por favor, preencha todos os campos obrigatórios.'
      );
    }
  };

  const renderActionButtons = (): React.ReactNode => {
    return (
      <ButtonContainer>
        <Button type="button" color="red" onClick={onClose}>
          Cancelar
        </Button>
        <Button type="submit" color="green" disabled={!agree}>
          Confirmar
        </Button>
      </ButtonContainer>
    );
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeIcon
    >
      <Container>
        <Disclaimer>
          <h4>
            <Icon circular name="exclamation" /> Atenção
          </h4>
          <Checkbox
            label={
              `Estou ciente que esta operação é irreversível. Ao optar por não renovar, a seguradora vai
              expirar a apólice no fim da vigência e não será possível reversão, caso ocorra
              desistência, deverá ser feita uma nova análise. Para cancelar a apólice,
              utilize a opção “Solicitar cancelamento”`
            }
            style={{ fontSize: 16 }}
            checked={agree}
            onClick={() => setAgree(prevState => !prevState)}
          />
        </Disclaimer>

        <Form onSubmit={handleFormSubmit}>
          <FormContainer>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <label>
                    <b>Selecione abaixo o motivo para a não renovação</b>
                  </label>
                  <div
                    style={{
                      width: '100%',
                      marginTop: '.5rem'
                    }}
                  >
                    <Dropdown
                      search
                      clearable
                      selection
                      value={status}
                      options={statusOptions}
                      placeholder={'Selecionar...'}
                      style={{
                        width: '100%'
                      }}
                      error={isStatusError}
                      onChange={(e, { value }) => {
                        setIsStatusError(false);
                        setStatus(value as string);
                      }}
                    />
                  </div>
                </Grid.Column>

                {status === 'Outros' && (
                  <Grid.Column width={16}>
                    <br />
                    <label>Observações</label>
                    <TextAreaWrapper>
                      <TextArea
                        value={observation}
                        onChange={(e, { value }) => {
                          setObservations(value as string);
                        }}
                      />
                    </TextAreaWrapper>
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </FormContainer>
          <FormContainer>{renderActionButtons()}</FormContainer>
        </Form>
      </Container>
    </Modal>
  );
};

export default NoRenewRequestModal;
