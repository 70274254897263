import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import {
  Button,
  Header,
  Icon,
  Modal,
  Step,
  Loader,
  Dimmer,
  Dropdown,
  Grid,
  InputOnChangeData,
} from "semantic-ui-react";
import { post } from "../../../../../services/request";
import {
  dangerNotification,
  successNotification,
} from "../../../../../services/notification";
import { ConfirmHireModalProps } from "../Types/ConfirmHireModal.types";
import { FieldLabel } from "../../../../../styles";
import { Field, Formik } from "formik";
import SectionLabel from "../../../../../components/SectionLabel";
import CustomDatePicker from "../../../../../components/CustomDatePicker";
import Input from "../../../../../components/Input";

import AppMaskedInput from "../../../../../components/MaskedInput";
import { ConfirmHirePayloadSubmitProps } from "../Types/ConfirmHirePayloadSubmit.types";
import FormatedTextArea from "../../../../../components/FormatedTextArea";
import { formatAmount, maskMoney } from "../../../../../services/masks";
import { AxiosError } from "axios";

const GeneralConfirmPolicyProposalModal = ({
  closeModal,
  updateList,
  openModal,
  hiredDataId,
  observations,
  hiredInstallments,
  hiredValue,
  policyNumber,
  rentInsuranceContractStartDate,
  rentInsuranceContractEndDate,
  userId
}: ConfirmHireModalProps) => {
  const [loading, setLoading] = useState(false);

  async function handleSubmit(data: ConfirmHirePayloadSubmitProps) {
    try {
      setLoading(true);
      await post("/bond-insurance/policies/confirm/general", {
        policyNumber: data.policyNumber,
        rentInsuranceContractStartDate: data.rentInsuranceContractStartDate,
        rentInsuranceContractEndDate: data.rentInsuranceContractEndDate,
        hiredInstallments: data.hiredInstallments,
        hiredInstallmentsValue: Number(data.hiredInstallmentsValue),
        hiredDataId,
        userId
      });
      successNotification(
        "Sucesso!",
        "Esta proposta foi marcada como emitida e as notificações foram disparadas."
      );
    } catch (err) {
      console.log(err);
      dangerNotification("Ops...", (err as AxiosError).message);
    } finally {
      setLoading(false);
      updateList();
      closeModal();
    }
  }

  return (
    <div style={{ outline: "none" }}>
      <Modal closeIcon open={openModal} onClose={closeModal}>
        <Header
          icon="file alternate outline"
          content={`Confirmação de contratação - Seguro Fiança `}
        />
        <Modal.Content>
          <Dimmer active={loading}>
            <Loader indeterminate> Salvando proposta... </Loader>
          </Dimmer>

          <Formik
            initialValues={{
              policyNumber: policyNumber,
              rentInsuranceContractStartDate: rentInsuranceContractStartDate,
              rentInsuranceContractEndDate: rentInsuranceContractEndDate,
              observations: observations,
              hiredInstallments: hiredInstallments,
              formattedHiredInstallmentsValue: maskMoney(String(hiredValue), true, 2),
              hiredInstallmentsValue: hiredValue,
            }}
            validate={(values) => {
              const errors = {} as any;
              if (!values.policyNumber) {
                errors.policyNumber = "Campo obrigatório";
              }
              if (!values.hiredInstallments || values.hiredInstallments < 1) {
                errors.hiredInstallments = "Quantidade de parcelas inválidas";
              }
              if (
                !values.hiredInstallmentsValue ||
                values.hiredInstallmentsValue < 1
              ) {
                errors.hiredInstallmentsValue =
                  "Valor da parcela inválido";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              await handleSubmit(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid>
                  {/* <SectionLabel text="Apólice" /> */}
                  <Grid.Row centered width={16}>
                    <Grid.Column width={8}>
                      <FieldLabel>Número da Apólice</FieldLabel>
                      <Field
                        type="text"
                        name={`policyNumber`}
                        value={values.policyNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={Input}
                        fluid
                      />
                      <p style={{ color: "#ca0000" }}>
                        {errors.policyNumber &&
                          touched.policyNumber &&
                          errors.policyNumber}
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                  <SectionLabel text="VIGÊNCIA DO SEGURO" />
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <FieldLabel>INICIO DA VIGÊNCIA DO SEGURO* </FieldLabel>
                      <Field
                        name={`rentInsuranceContractStartDate`}
                        selected={
                          new Date(values.rentInsuranceContractStartDate)
                        }
                        onChange={(date: Date, e: SyntheticEvent) => {
                          setFieldValue(`rentInsuranceContractStartDate`, date);
                        }}
                        component={CustomDatePicker}
                        customInput={
                          <AppMaskedInput
                            mask={[
                              /[0-9]/,
                              /[0-9]/,
                              "/",
                              /[0-9]/,
                              /[0-9]/,
                              "/",
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                            ]}
                          />
                        }
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </Grid.Column>

                    <Grid.Column width={8}>
                      <FieldLabel>FIM DA VIGÊNCIA DO SEGURO* </FieldLabel>
                      <Field
                        name={`rentInsuranceContractEndDate`}
                        selected={new Date(values.rentInsuranceContractEndDate)}
                        onChange={(date: Date, e: SyntheticEvent) => {
                          setFieldValue(`rentInsuranceContractEndDate`, date);
                        }}
                        component={CustomDatePicker}
                        customInput={
                          <AppMaskedInput
                            mask={[
                              /[0-9]/,
                              /[0-9]/,
                              "/",
                              /[0-9]/,
                              /[0-9]/,
                              "/",
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                            ]}
                          />
                        }
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <SectionLabel text="Valores do seguro" />
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <FieldLabel>Parcelas contratadas</FieldLabel>
                      <Field
                        name={`hiredInstallments`}
                        type="number"
                        value={values.hiredInstallments}
                        component={Input}
                        onChange={handleChange}
                        fluid
                      />
                      <p style={{ color: "#ca0000" }}>
                        {errors.hiredInstallments &&
                          touched.hiredInstallments &&
                          errors.hiredInstallments}
                      </p>
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <FieldLabel>Valor da parcela contratada</FieldLabel>
                      <Field
                        name={`hiredInstallmentsValue`}
                        onChange={(_: any, data: InputOnChangeData) => {
                          setFieldValue(
                            "hiredInstallmentsValue",
                            Number(data.value.replace(/\D/g, "")) / 100
                          );
                          setFieldValue(
                            "formattedHiredInstallmentsValue",
                            formatAmount(Number(data.value.replace(/\D/g, "")))
                          );
                        }}
                        value={values.formattedHiredInstallmentsValue}
                        component={Input}
                        fluid
                      />

                      <p style={{ color: "#ca0000" }}>
                        {errors.hiredInstallmentsValue &&
                          touched.hiredInstallmentsValue &&
                          errors.hiredInstallmentsValue}
                      </p>
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <FieldLabel>Prêmio total a ser contratado</FieldLabel>
                      <Field
                        name={`totalValue`}
                        disabled
                        value={maskMoney(
                          String(values.hiredInstallments *
                            values.hiredInstallmentsValue), true, 2
                        )}
                        component={Input}
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <SectionLabel text="Observações" />
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <Field
                        name={`bondInsurance.observations`}
                        rows={8}
                        value={values.observations}
                        readonly
                        component={FormatedTextArea}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <Grid>
                  <Grid.Row width={16}>
                    <Grid.Column width={11} />
                    <Grid.Column width={5}>
                      <Modal.Actions>
                        <Button color="red" onClick={() => closeModal()}>
                          <Icon name="remove" /> Sair
                        </Button>
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          color="green"
                        >
                          <Icon name="checkmark" />
                          Confirmar
                        </Button>
                      </Modal.Actions>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </form>
            )}
          </Formik>
        </Modal.Content>
      </Modal>
    </div>
  );
};
export default GeneralConfirmPolicyProposalModal;
