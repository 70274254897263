import { get, post } from './request';

import { EstateState } from '../types/EstateState';
import { EventsFilter } from '../pages/events/types'
import { UserState } from '../types/UserState';
import { EstateForGetEstatesResponseDTO } from "../dtos/estate/estate-for-get-estates-response.dto";
import { UpdateEstateFormValues } from "../pages/estates-edit-form/types";
import { CalculationModeEnum } from "../enums/calculation-mode.enum";
import { InsurersCodeEnum } from "../enums/insurers-code.enum";
import { formatAmount } from "./masks";
import { CalculationTypeEnum } from '../enums/calculation-type.enum';
import { GetBondInsuranceProposalsListResponseDTO } from "../dtos/bond-insurance-proposal-list/get-bond-insurance-proposals-list-response-dto";
import { GetBondInsurancePoliciesListResponseDTO } from "../dtos/bond-insurance-policy-list/get-bond-insurance-policies-list-response-dto";
import { warningNotification } from './notification';

export const list = async (): Promise<Array<EstateState>> => {
  let response;

  try {
    response = await get('/estates');
  } catch (e) {
    throw e;
  }

  return response;
};

export const getEventsByRelatedId = async (relatedId: string, createdAt: Date, updated_at?: Date): Promise<any> => {
  let response;

  try {
    response = await get(`/events/related-id/${relatedId}?created_at=${createdAt}&?updated_at=${updated_at}`);
  } catch (e) {
    throw e;
  }

  return response;
}

export const getEventsToProposalAndPolicy = async (analysisId: string, proposalId: string , policyId: string): Promise<any> => {
  let response;

  const payload = {
    analysisId,
    proposalId,
    policyId
  }
  try {
    response = await post(`/events/related`, payload);
  } catch (e) {
    console.log('ERROWWW', e);
    throw e;
  }

  return response;
}

export const getEventsByArrayRelatedId = async (createdAt: Date | string, relatedIds: string, updatedAt?: Date, context?: string): Promise<any> => {
  let response;
  try {
    response = await get(`/events/related-array?createdAt=${createdAt}&relatedIds=${relatedIds}&updatedAt=${updatedAt}&context=${context}`);
  } catch (e) {
    throw e;
  }

  return response;
}

export const getEvents = async (filters: EventsFilter): Promise<any> => {
  let response;

  let query = '';

  if (filters) {
    Object.entries(filters).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`
      }
    })
  }

  try {
    response = await get(`/events?${query}`);
  } catch (e) {
    throw e;
  }

  return response;
}

export const getEventsTimeline = async (relatedId: string) => {
  try {
    const response = await get(`/events/timeline/${relatedId}`);

    return response;
  } catch (e) {
    warningNotification('Erro na consulta', 'Não foi possível consultar a timeline');
    console.error(e)
    // throw e;
  }
}
