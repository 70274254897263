import React, { useState, ReactElement, useEffect, SyntheticEvent } from 'react';
import { indemnityPeriodOptions } from '../../../types';
import Dropdown from '../../../../../components/Dropdown';
import SectionLabel from '../../../../../components/SectionLabel';
import { Grid, Icon, Input, InputOnChangeData } from 'semantic-ui-react';
import {
  FormikErrorMessage,
  DivErrorMessage
} from '../../../../../components/ErrorMessage';
import { Field } from 'formik';
import { formatAmount, unmaskMoney, maskCpfOrCnpj } from '../../../../../services/masks';
import { getErrorFormik } from '../../../../../services/errors';
import { FieldLabel } from '../../../../../styles';
import { StepProps } from '../../../BondInsuranceForm';
import { round } from '../../../../../util';
import Toggle from '../../../../../components/Toggle';
import styled from 'styled-components';
import { iRootDispatch, iRootState } from '../../../../../store';
import { BondInsuranceFormState } from '../../../../../store/types/temp-types';
import { connect } from 'react-redux';
import { Disclaimer, FieldLabelContainer } from '../../../style';
import IconButton from '../../../../../components/IconButton';

const SECTION_NAME = 'property_info';

const mapState = (state: iRootState) => ({
  bondInsuranceForm: state.bondInsuranceForm
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateBondInsurance: (bondInsuranceFormState: BondInsuranceFormState) =>
    dispatch.bondInsuranceForm.updateBondInsurance(bondInsuranceFormState)
});

type PropertyDataProps = StepProps &
  ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch>;

const PropertyData = (props: PropertyDataProps): ReactElement => {
  const { errors, touched, setFieldValue, handleInputChange, setFieldTouched, values } =
    props;

  const { property_info } = props.values;

  const {
    rent_value,
    condominium_fee,
    taxes_value,
    energy_bill,
    water_bill,
    gas_bill,
    contract_penalties_value,
    interior_painting_value,
    exterior_painting_value,
    furniture_damage_value,
    property_damage_value
  } = property_info;

  const [isDocValid, setIsDocValid] = useState<boolean>(true);
  const [isContractPenaltiesCoverageChecked, setIsContractPenaltiesCoverageChecked] =
    useState<boolean>(false);
  const [isInteriorPaintingCoverageChecked, setIsInteriorPaintingCoverageChecked] =
    useState<boolean>(false);
  const [isExteriorPaintingCoverageChecked, setIsExteriorPaintingCoverageChecked] =
    useState<boolean>(false);
  const [isFurnitureDamageCoverageChecked, setIsFurnitureDamageCoverageChecked] =
    useState<boolean>(false);
  const [isPropertyDamageCoverageChecked, setIsPropertyDamageCoverageChecked] =
    useState<boolean>(false);

  useEffect(() => {
    setFieldValue(`${SECTION_NAME}.total_amount`, 0);
  }, []);

  useEffect(() => {
    props.bondInsuranceForm.isToo
      ? setFieldValue(
          `${SECTION_NAME}.total_amount`,
          unmaskMoney(rent_value || '0') +
            unmaskMoney(condominium_fee || '0') +
            unmaskMoney(taxes_value || '0') +
            unmaskMoney(energy_bill || '0') +
            unmaskMoney(water_bill || '0') +
            unmaskMoney(gas_bill || '0') +
            unmaskMoney(contract_penalties_value || '0') +
            unmaskMoney(interior_painting_value || '0') +
            unmaskMoney(exterior_painting_value || '0') +
            unmaskMoney(furniture_damage_value || '0') +
            unmaskMoney(property_damage_value || '0')
        )
      : setFieldValue(
          `${SECTION_NAME}.total_amount`,
          unmaskMoney(rent_value || '0') +
            unmaskMoney(condominium_fee || '0') +
            unmaskMoney(taxes_value || '0') +
            unmaskMoney(energy_bill || '0') +
            unmaskMoney(water_bill || '0') +
            unmaskMoney(gas_bill || '0')
        );
  }, [
    rent_value,
    condominium_fee,
    taxes_value,
    energy_bill,
    water_bill,
    gas_bill,
    contract_penalties_value,
    interior_painting_value,
    exterior_painting_value,
    furniture_damage_value,
    property_damage_value,
    props.bondInsuranceForm.isToo,
    setFieldValue
  ]);

  const validateErros = (field: string) =>
    getErrorFormik(errors, touched, SECTION_NAME, field);

  return (
    <>
      <SectionLabel text="DADOS DO IMÓVEL" />
      <Grid.Row
        style={{
          marginBottom: '0'
        }}
      >
        <Grid.Column
          style={{
            textAlign: 'center'
          }}
        >
          <Disclaimer>
            <Icon bordered name="exclamation" />
            <p>
              <b>Importante -</b> Atente-se ao preenchimento dos campos abaixo para fins
              de cobertura. Os valores dos encargos não preenchidos, não terão indenização
              em caso de sinistro.
            </p>
          </Disclaimer>
        </Grid.Column>
      </Grid.Row>

      {property_info.reason === 'Locação para moradia' && (
        <>
          <Grid.Row
            style={{
              marginTop: '0'
            }}
          >
            <Grid.Column width={8}>
              <FieldLabel>NOME DO REAL OCUPANTE*</FieldLabel>
              <Field
                id={`real_occupant_name`}
                name={`${SECTION_NAME}.real_occupant_name`}
                value={values.property_info.real_occupant_name}
                onChange={handleInputChange}
                onBlur={() => setFieldTouched(`${SECTION_NAME}.real_occupant_name`, true)}
                error={validateErros('real_occupant_name')}
                component={Input}
                fluid
              />
              <FormikErrorMessage
                component="div"
                name={`${SECTION_NAME}.real_occupant_name`}
              />
            </Grid.Column>

            <Grid.Column width={8}>
              <FieldLabel>CPF DO REAL OCUPANTE*</FieldLabel>
              <Field
                id={`real_occupant_doc`}
                name={`${SECTION_NAME}.real_occupant_doc`}
                value={values.property_info.real_occupant_doc}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement>,
                  data: InputOnChangeData
                ): void => {
                  data.value = maskCpfOrCnpj(data.value);
                  handleInputChange(e, data);
                }}
                onBlur={() => setFieldTouched(`${SECTION_NAME}.real_occupant_doc`, true)}
                error={validateErros('real_occupant_doc')}
                component={Input}
                fluid
              />
              {isDocValid ? null : <DivErrorMessage>Documento inválido</DivErrorMessage>}
              <FormikErrorMessage
                component="div"
                name={`${SECTION_NAME}.real_occupant_doc`}
              />
            </Grid.Column>
          </Grid.Row>
        </>
      )}

      <Grid.Row>
        <Grid.Column width={6}>
          <p>
            Clique em <Icon name="ban" color="blue" /> para habilitar/desabilitar o valor na
            cotação.
          </p>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row
        style={{
          marginTop: '0'
        }}
      >
        <Grid.Column width={4}>
          <FieldLabel>VALOR DO ALUGUEL*</FieldLabel>
          <Field
            data-ci={`${SECTION_NAME}.rent_value`}
            id={`${SECTION_NAME}.rent_value`}
            name={`${SECTION_NAME}.rent_value`}
            value={property_info.rent_value}
            component={Input}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
              data: InputOnChangeData
            ): void => {
              data.value = formatAmount(data.value);
              handleInputChange(e, data);

              if (props.bondInsuranceForm.isToo) {
                if (isContractPenaltiesCoverageChecked) {
                  setFieldValue(`${SECTION_NAME}.contract_penalties_value`, data.value);
                }
                if (isInteriorPaintingCoverageChecked) {
                  setFieldValue(`${SECTION_NAME}.interior_painting_value`, data.value);
                }
                if (isExteriorPaintingCoverageChecked) {
                  setFieldValue(`${SECTION_NAME}.exterior_painting_value`, data.value);
                }
                if (isFurnitureDamageCoverageChecked) {
                  setFieldValue(`${SECTION_NAME}.furniture_damage_value`, data.value);
                }
                if (isPropertyDamageCoverageChecked) {
                  setFieldValue(`${SECTION_NAME}.property_damage_value`, data.value);
                }
              }
            }}
            onBlur={() => {
              setFieldTouched(`${SECTION_NAME}.rent_value`, true);
            }}
            error={validateErros('rent_value')}
            fluid
          />
          <FormikErrorMessage component="div" name={`${SECTION_NAME}.rent_value`} />
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>
            VALOR DO CONDOMÍNIO {property_info.is_condominium_fee_required && '*'}
          </FieldLabel>
          <FieldLabelContainer>
            <Field
              id={`${SECTION_NAME}.condominium_fee`}
              name={`${SECTION_NAME}.condominium_fee`}
              value={property_info.condominium_fee}
              component={Input}
              disabled={!property_info.is_condominium_fee_required}
              placeholder="0,00"
              onChange={(
                e: React.ChangeEvent<HTMLInputElement>,
                data: InputOnChangeData
              ): void => {
                data.value = formatAmount(data.value);
                handleInputChange(e, data);
              }}
              onBlur={() => {
                setFieldTouched(`${SECTION_NAME}.condominium_fee`, true);
              }}
              error={
                property_info.is_condominium_fee_required &&
                validateErros('condominium_fee')
              }
              fluid
            />
            <IconButton
              className="icon-button"
              onClick={(e: SyntheticEvent) => {
                handleInputChange(e, {
                  field: {
                    name: `${SECTION_NAME}.is_condominium_fee_required`
                  },
                  value: !property_info.is_condominium_fee_required
                });
                handleInputChange(e, {
                  field: {
                    name: `${SECTION_NAME}.condominium_fee`
                  },
                  value: '0,00'
                });
              }}
            >
              <Icon
                name="ban"
                className={
                  !property_info.is_condominium_fee_required ? 'not-required' : ''
                }
              />
            </IconButton>
          </FieldLabelContainer>
          {property_info.is_condominium_fee_required && (
            <FormikErrorMessage
              component="div"
              name={`${SECTION_NAME}.condominium_fee`}
            />
          )}
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>
            VALOR DO IPTU MENSAL {property_info.is_taxes_value_required && '*'}
          </FieldLabel>
          <FieldLabelContainer>
            <Field
              id={`${SECTION_NAME}.taxes_value`}
              name={`${SECTION_NAME}.taxes_value`}
              value={property_info.taxes_value}
              component={Input}
              disabled={!property_info.is_taxes_value_required}
              placeholder="0,00"
              onChange={(
                e: React.ChangeEvent<HTMLInputElement>,
                data: InputOnChangeData
              ): void => {
                data.value = formatAmount(data.value);
                handleInputChange(e, data);
              }}
              onBlur={() => {
                setFieldTouched(`${SECTION_NAME}.taxes_value`, true);
              }}
              error={
                property_info.is_taxes_value_required && validateErros('taxes_value')
              }
              fluid
            />
            <IconButton
              className="icon-button"
              onClick={(e: SyntheticEvent) => {
                handleInputChange(e, {
                  field: {
                    name: `${SECTION_NAME}.is_taxes_value_required`
                  },
                  value: !property_info.is_taxes_value_required
                });

                handleInputChange(e, {
                  field: {
                    name: `${SECTION_NAME}.taxes_value`
                  },
                  value: '0,00'
                });
              }}
            >
              <Icon
                name="ban"
                className={!property_info.is_taxes_value_required ? 'not-required' : ''}
              />
            </IconButton>
          </FieldLabelContainer>
          {property_info.is_taxes_value_required && (
            <FormikErrorMessage component="div" name={`${SECTION_NAME}.taxes_value`} />
          )}
        </Grid.Column>
        <Grid.Column width={4}>
          <FieldLabel>
            VALOR DA ENERGIA {property_info.is_energy_bill_required && '*'}
          </FieldLabel>
          <FieldLabelContainer>
            <Field
              id={`${SECTION_NAME}.energy_bill`}
              name={`${SECTION_NAME}.energy_bill`}
              value={property_info.energy_bill}
              disabled={!property_info.is_energy_bill_required}
              placeholder="0,00"
              component={Input}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement>,
                data: InputOnChangeData
              ): void => {
                data.value = formatAmount(data.value);
                handleInputChange(e, data);
              }}
              onBlur={() => {
                setFieldTouched(`${SECTION_NAME}.energy_bill`, true);
              }}
              error={
                property_info.is_energy_bill_required && validateErros('energy_bill')
              }
              fluid
            />
            <IconButton
              className="icon-button"
              onClick={(e: SyntheticEvent) => {
                handleInputChange(e, {
                  field: {
                    name: `${SECTION_NAME}.is_energy_bill_required`
                  },
                  value: !property_info.is_energy_bill_required
                });
                handleInputChange(e, {
                  field: {
                    name: `${SECTION_NAME}.energy_bill`
                  },
                  value: '0,00'
                });
              }}
            >
              <Icon
                name="ban"
                className={!property_info.is_energy_bill_required ? 'not-required' : ''}
              />
            </IconButton>
          </FieldLabelContainer>
          {property_info.is_energy_bill_required && (
            <FormikErrorMessage component="div" name={`${SECTION_NAME}.energy_bill`} />
          )}
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={4}>
          <FieldLabel>
            VALOR DA ÁGUA {property_info.is_water_bill_required && '*'}
          </FieldLabel>
          <FieldLabelContainer>
            <Field
              id={`${SECTION_NAME}.water_bill`}
              name={`${SECTION_NAME}.water_bill`}
              value={property_info.water_bill}
              disabled={!property_info.is_water_bill_required}
              placeholder="0,00"
              component={Input}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement>,
                data: InputOnChangeData
              ): void => {
                data.value = formatAmount(data.value);
                handleInputChange(e, data);
              }}
              onBlur={() => {
                setFieldTouched(`${SECTION_NAME}.water_bill`, true);
              }}
              error={validateErros('water_bill')}
              fluid
            />
            <IconButton
              className="icon-button"
              onClick={(e: SyntheticEvent) => {
                handleInputChange(e, {
                  field: {
                    name: `${SECTION_NAME}.is_water_bill_required`
                  },
                  value: !property_info.is_water_bill_required
                });

                handleInputChange(e, {
                  field: {
                    name: `${SECTION_NAME}.water_bill`
                  },
                  value: '0,00'
                });
              }}
            >
              <Icon
                name="ban"
                className={!property_info.is_water_bill_required ? 'not-required' : ''}
              />
            </IconButton>
          </FieldLabelContainer>
          {property_info.is_water_bill_required && (
            <FormikErrorMessage component="div" name={`${SECTION_NAME}.water_bill`} />
          )}
        </Grid.Column>

        <Grid.Column width={4}>
          <FieldLabel>
            VALOR DO GÁS CANALIZADO {property_info.is_gas_bill_required && '*'}
          </FieldLabel>
          <FieldLabelContainer>
            <Field
              id={`gas_bill`}
              name={`${SECTION_NAME}.gas_bill`}
              value={property_info.gas_bill}
              disabled={!property_info.is_gas_bill_required}
              placeholder="0,00"
              component={Input}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement>,
                data: InputOnChangeData
              ): void => {
                data.value = formatAmount(data.value);
                handleInputChange(e, data);
              }}
              onBlur={() => {
                setFieldTouched(`${SECTION_NAME}.gas_bill`, true);
              }}
              error={property_info.is_gas_bill_required && validateErros('gas_bill')}
              fluid
            />
            <IconButton
              className="icon-button"
              onClick={(e: SyntheticEvent) => {
                handleInputChange(e, {
                  field: {
                    name: `${SECTION_NAME}.is_gas_bill_required`
                  },
                  value: !property_info.is_gas_bill_required
                });

                handleInputChange(e, {
                  field: {
                    name: `${SECTION_NAME}.gas_bill`
                  },
                  value: '0,00'
                });
              }}
            >
              <Icon
                name="ban"
                className={!property_info.is_gas_bill_required ? 'not-required' : ''}
              />
            </IconButton>
          </FieldLabelContainer>
          {property_info.is_gas_bill_required && (
            <FormikErrorMessage component="div" name={`${SECTION_NAME}.gas_bill`} />
          )}
        </Grid.Column>
      </Grid.Row>

      {props.bondInsuranceForm.isToo && (
        <>
          <Grid.Row>
            <Grid.Column width={4}>
              <OptionContainer>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <FieldLabel>MULTAS CONTRATUAIS*</FieldLabel>
                    <Field
                      id={`contract_penalties_value`}
                      name={`${SECTION_NAME}.contract_penalties_value`}
                      value={property_info.contract_penalties_value}
                      component={Input}
                      onBlur={() => {
                        setFieldTouched(`${SECTION_NAME}.contract_penalties_value`, true);
                      }}
                      disabled={true}
                      error={validateErros('contract_penalties_value')}
                      fluid
                    />
                    <FormikErrorMessage
                      component="div"
                      name={`${SECTION_NAME}.contract_penalties_value`}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Toggle
                  id={`is_contract_penalties_coverage_checked`}
                  name={`${SECTION_NAME}.is_contract_penalties_coverage_checked`}
                  color="blue"
                  style={{ alignSelf: 'center' }}
                  onChange={(e: SyntheticEvent, data: any) => {
                    if (data) {
                      setIsContractPenaltiesCoverageChecked(data.checked);

                      if (data.checked) {
                        setFieldValue(
                          `${SECTION_NAME}.contract_penalties_value`,
                          values.property_info.rent_value
                        );
                      } else {
                        setFieldValue(`${SECTION_NAME}.contract_penalties_value`, '0,00');
                      }
                    }
                  }}
                  checked={isContractPenaltiesCoverageChecked}
                  disabled={true}
                />
              </OptionContainer>
            </Grid.Column>

            <Grid.Column width={4}>
              <OptionContainer>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <FieldLabel>PINTURA INTERNA</FieldLabel>
                    <Field
                      id={`interior_painting_value`}
                      name={`${SECTION_NAME}.interior_painting_value`}
                      value={property_info.interior_painting_value}
                      component={Input}
                      onBlur={() => {
                        setFieldTouched(`${SECTION_NAME}.interior_painting_value`, true);
                      }}
                      disabled={true}
                      error={validateErros('interior_painting_value')}
                      fluid
                    />
                    <FormikErrorMessage
                      component="div"
                      name={`${SECTION_NAME}.interior_painting_value`}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Toggle
                  id={`is_interior_painting_coverage_checked`}
                  name={`${SECTION_NAME}.is_interior_painting_coverage_checked`}
                  color="blue"
                  style={{ alignSelf: 'center' }}
                  onChange={(e: SyntheticEvent, data: any) => {
                    if (data) {
                      setIsInteriorPaintingCoverageChecked(data.checked);

                      if (data.checked) {
                        setFieldValue(
                          `${SECTION_NAME}.interior_painting_value`,
                          values.property_info.rent_value
                        );
                      } else {
                        setFieldValue(`${SECTION_NAME}.interior_painting_value`, '0,00');
                      }
                    }
                  }}
                  checked={isInteriorPaintingCoverageChecked}
                  disabled={false}
                />
              </OptionContainer>
            </Grid.Column>

            <Grid.Column width={4}>
              <OptionContainer>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <FieldLabel>PINTURA EXTERNA*</FieldLabel>
                    <Field
                      id={`exterior_painting_value`}
                      name={`${SECTION_NAME}.exterior_painting_value`}
                      value={property_info.exterior_painting_value}
                      component={Input}
                      onBlur={() => {
                        setFieldTouched(`${SECTION_NAME}.exterior_painting_value`, true);
                      }}
                      disabled={true}
                      error={validateErros('exterior_painting_value')}
                      fluid
                    />
                    <FormikErrorMessage
                      component="div"
                      name={`${SECTION_NAME}.exterior_painting_value`}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Toggle
                  id={`is_exterior_painting_coverage_checked`}
                  name={`${SECTION_NAME}.is_exterior_painting_coverage_checked`}
                  color="blue"
                  style={{ alignSelf: 'center' }}
                  onChange={(e: SyntheticEvent, data: any) => {
                    if (data) {
                      setIsExteriorPaintingCoverageChecked(data.checked);

                      if (data.checked) {
                        setFieldValue(
                          `${SECTION_NAME}.exterior_painting_value`,
                          values.property_info.rent_value
                        );
                      } else {
                        setFieldValue(`${SECTION_NAME}.exterior_painting_value`, '0,00');
                      }
                    }
                  }}
                  checked={isExteriorPaintingCoverageChecked}
                  disabled={true}
                />
              </OptionContainer>
            </Grid.Column>

            <Grid.Column width={4}>
              <OptionContainer>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <FieldLabel>DANOS A MÓVEIS</FieldLabel>
                    <Field
                      id={`furniture_damage_value`}
                      name={`${SECTION_NAME}.furniture_damage_value`}
                      value={property_info.furniture_damage_value}
                      component={Input}
                      onBlur={() => {
                        setFieldTouched(`${SECTION_NAME}.furniture_damage_value`, true);
                      }}
                      disabled={true}
                      error={validateErros('furniture_damage_value')}
                      fluid
                    />
                    <FormikErrorMessage
                      component="div"
                      name={`${SECTION_NAME}.furniture_damage_value`}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Toggle
                  id={`is_furniture_damage_coverage_checked`}
                  name={`${SECTION_NAME}.is_furniture_damage_coverage_checked`}
                  color="blue"
                  style={{ alignSelf: 'center' }}
                  onChange={(e: SyntheticEvent, data: any) => {
                    if (data) {
                      setIsFurnitureDamageCoverageChecked(data.checked);

                      if (data.checked) {
                        setFieldValue(
                          `${SECTION_NAME}.furniture_damage_value`,
                          values.property_info.rent_value
                        );
                      } else {
                        setFieldValue(`${SECTION_NAME}.furniture_damage_value`, '0,00');
                      }
                    }
                  }}
                  checked={isFurnitureDamageCoverageChecked}
                  disabled={false}
                />
              </OptionContainer>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={4}>
              <OptionContainer>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <FieldLabel>DANOS AO IMÓVEL</FieldLabel>
                    <Field
                      id={`property_damage_value`}
                      name={`${SECTION_NAME}.property_damage_value`}
                      value={property_info.property_damage_value}
                      component={Input}
                      onBlur={() => {
                        setFieldTouched(`${SECTION_NAME}.property_damage_value`, true);
                      }}
                      disabled={true}
                      error={validateErros('property_damage_value')}
                      fluid
                    />
                    <FormikErrorMessage
                      component="div"
                      name={`${SECTION_NAME}.property_damage_value`}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Toggle
                  id={`is_property_damage_coverage_checked`}
                  name={`${SECTION_NAME}.is_property_damage_coverage_checked`}
                  color="blue"
                  style={{ alignSelf: 'center' }}
                  onChange={(e: SyntheticEvent, data: any) => {
                    if (data) {
                      setIsPropertyDamageCoverageChecked(data.checked);

                      if (data.checked) {
                        setFieldValue(
                          `${SECTION_NAME}.property_damage_value`,
                          values.property_info.rent_value
                        );
                      } else {
                        setFieldValue(`${SECTION_NAME}.property_damage_value`, '0,00');
                      }
                    }
                  }}
                  checked={isPropertyDamageCoverageChecked}
                  disabled={false}
                />
              </OptionContainer>
            </Grid.Column>
          </Grid.Row>
        </>
      )}

      <Grid.Row>
        <Grid.Column width={4}>
          <FieldLabel>VALOR TOTAL</FieldLabel>
          <Field
            id={`${SECTION_NAME}.total_amount`}
            disabled={true}
            name={`${SECTION_NAME}.total_amount`}
            value={formatAmount(round(property_info.total_amount * 100, 2) || 0)}
            component={Input}
            onBlur={() => setFieldTouched(`${SECTION_NAME}.total_amount`, true)}
            error={validateErros('total_amount')}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <p>*Preenchimento obrigatório.</p>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5em 0;
  width: 100%;
  border: 1px solid silver;
  border-radius: 5px;
  padding: 2.5%;
`;

export default connect(mapState, mapDispatch)(PropertyData);
