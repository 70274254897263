import React, { useEffect, useState } from 'react';
import { Button, Header, Icon, Modal, Step, Loader, Dimmer } from 'semantic-ui-react';
import { ChangingFormik } from '../Formik/ChangingFormFormik';
import { post, put } from '../../../../../../services/request';
import {
  dangerNotification,
  successNotification,
  warningNotification
} from '../../../../../../services/notification';
import { ConfirmCancelModalProps } from '../../types/ConfirmCancelModalProps';
import { CanceledStatusEnum } from '../../../../../../enums/status-canceled-bond-insurances';
import { sendMessage } from '../../../../../../services/generalfollowup';
import { get } from '../../../../../../services/storage';
import { TranslatedCanceledStatus } from '../../../../types';
import { uncancel, updateCancelation } from '../../../../../../services/canceled-bond-insurance';

const ChangeStatusModal = ({
  openModal,
  closeModal,
  setOpen,
  canceledId,
  callback,
  triggerOptions,
  canceled
}: ConfirmCancelModalProps) => {
  const [flagSubmitFrom, setFlagSubmitFrom] = useState(false);

  const [loading, setLoading] = useState(false);

  async function handleSubmit(payload: any, status: any) {
    const userId = get('uid');
    const userName = get('name');
    const name = userName ? userName : '';
    const context = 'CANCELED';
    console.log(' OPA ',payload, status);

    const message = `Status alterado de ${canceled.status} para ${getStatus(
      status
    )}. Observações: ${payload.observations}`;

    payload.status = status;
    if(status === CanceledStatusEnum.UNCANCELLED) {
      setLoading(true);
      const uncancelling = await uncancel(payload.canceledId, userId!);
      if (uncancelling) {
        sendMessage(
          payload.canceledId,
          payload.userId,
          message,
          name,
          context,
          userId,
          userId,
          canceled.bondInsurance.id,
          false
        );
        successNotification(
          'Sucesso!',
          'Desistencia de Cancelamento Efetuada com Sucesso'
        );
      } else {
        dangerNotification(
          'Ooops!',
          'Erro ao realizar solicitação\nCaso persista, contate o Suporte'
        );
      }
      setLoading(false);
    } else {
      if (status.includes('PENDING') && !payload.observations) {
        warningNotification(
          'Oops...',
          'Por favor, preencha uma Observação antes de tornar Pendente.'
        );
      } else {
        try {
          setLoading(true);
          const response = await updateCancelation(payload);
          sendMessage(
            payload.canceledId,
            payload.userId,
            message,
            name,
            context,
            userId,
            userId,
            canceled.bondInsurance.id,
            false
          );
          successNotification('Sucesso', `${response.message}`);
        } catch (err) {
          dangerNotification('Erro', 'Erro ao efetuar solicitação.', 4000);
          console.log(err);
        } finally {
          if (callback) callback();
          setLoading(false);
          setFlagSubmitFrom(false);
          closeModal();
        }
      }
    }
  }

  function getStatus(status: string) {
    switch (status) {
      case 'CANCELLATION_IN_PROGRESS': {
        return TranslatedCanceledStatus.CANCELLATION_IN_PROGRESS;
      }
      case 'CANCELLED_IN_PROGRESS': {
        return TranslatedCanceledStatus.CANCELLATION_IN_PROGRESS;
      }
      case 'CANCELED': {
        return TranslatedCanceledStatus.CANCELED;
      }
      case 'CANCELLED': {
        return TranslatedCanceledStatus.CANCELED;
      }
      case 'UNCANCELLED': {
        return TranslatedCanceledStatus.UNCANCELLED;
      }
      case 'INSURER_HIRE_PENDING': {
        return TranslatedCanceledStatus.PENDING;
      }
      case 'CANCELLATION_PENDING': {
        return TranslatedCanceledStatus.PENDING;
      }
      case 'PENDING': {
        return TranslatedCanceledStatus.PENDING;
      }
      case `PENDING_BY_INSURER`: {
        return TranslatedCanceledStatus.PENDING_BY_INSURER;
      }
      case `EXPIRED`: {
        return TranslatedCanceledStatus.EXPIRED;
      }

      case `FINANCIAL_PENDING`: {
        return TranslatedCanceledStatus.FINANCIAL_PENDING;
      }

      case `CLAIM_CANCELLATION`: {
        return TranslatedCanceledStatus.CLAIM_CANCELLATION;
      }

      default: {
        return status;
      }
    }
  }

  return (
    <div style={{ outline: 'none' }}>
      <Modal
        closeIcon
        open={openModal}
        onClose={() => closeModal()}
        trigger={
          <Button
            style={{ backgroundColor: typeof triggerOptions === 'undefined' && '#fff' }}
            {...triggerOptions}
          >
            <Icon name={'exchange'} />
            Mudar Status
          </Button>
        }
        onOpen={() => setOpen()}
      >
        <Header icon="exchange" content={`Mudar Status`} />
        <Modal.Content>
          <Dimmer active={loading}>
            <Loader indeterminate> Salvando alterações... </Loader>
          </Dimmer>
          <ChangingFormik
            flagSubmit={flagSubmitFrom}
            setFlagSubmit={setFlagSubmitFrom}
            callbackSubmit={handleSubmit}
            canceledId={canceledId}
            actionText={'Selecione o novo status:'}
            canceled={canceled}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={() => closeModal()}>
            <Icon name="remove" /> Sair
          </Button>
          <Button color="green" onClick={() => setFlagSubmitFrom(true)}>
            <Icon name="checkmark" />
            Confirmar
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
export default ChangeStatusModal;
