import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

export const Container = styled.div`
  input {
    width: 100%;
    margin-top: 10px !important;
  }

  .ui.grid > .row {
    padding-bottom: 0px;
  }

  small {
    color: #808080;
  }
`;

export const Image = styled.img`
  height: 100%;
  aspect-ratio: auto;
`;

export const ImageForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 200px;
  border-radius: 10px;
  border: 1px solid #eee;
  @media-query (min-width: 769px) {
    width: 500px;
    height: 280px;
    border-radius: 20px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const BlankImage = styled(Icon)`
  color: #dddddd;
`;

export const ModalToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  label {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-right: 6px;
  }
`;
