import { AnalysisAssuranceEnum } from "../../../../../../../enums/analysis-assurance.enum";
import { MaritalStatusEnum } from "../../../../../../../enums/marital-status.enum";
import { PoliticallyExposedPersonEnum } from "../../../../../../../enums/politically-exposed-person.enum";


const today = new Date()
const year = today.getFullYear();
const month = today.getMonth();
const day = today.getDate();
const nextYear = new Date(year + 1, month, day);

export const SurtyPottencialInsuraceinitialValues = {
  coverageType: AnalysisAssuranceEnum,
  rentInsuranceContractStartDate: null,
  rentInsuranceContractEndDate: null,
  coverages: [{id: '', type: '', label: '',multiple: '', disabled: true}],
  due_date_first_rent: undefined,
  rentalPeriodEndDate: undefined,
  rentalPeriodStartDate: undefined,
  rent_insurance_contract_start_date: undefined,
  rent_insurance_contract_end_date: undefined,
  rental_period_end_date: undefined,
  rental_period_start_date: undefined,

  analysis_assurance: AnalysisAssuranceEnum.simple,

  cpf: '',
  name: '',
  birthDate: '',
  rg: '',
  issueDate: '',
  issuingAgency: '',
  email: '',
  cellphone: '',
  maritalStatus: MaritalStatusEnum.single,
  nationality: '',
  politicallyExposed: PoliticallyExposedPersonEnum.no,
  gender: '',

  politicallyExposedName: '',
  politicallyExposedCpf: '',
  politicallyExposedRelatedness: '',

  occupation: '',
  fixedIncome: '',

  zipCode: '',
  street: '',
  neighborhood: '',
  city: '',
  state: '',
  addressNumber: '',
  complement: '',

  installments: 0,
  installmentsValue: 'R$ 0,00',

  fireInstallments: 0,
  typeResidence: '',

  owner_data: {
    public_document_id: '',
    name: '',
    email: ''
  },

  property: {
    rent_value: '',
    condominium_fee: '',
    taxes_value: '',
    energy_bill: '',
    water_bill: '',
    gas_bill: '',
    reason: '',
    type: '',
  },

  estate: {
    estateZipCode: '',
    estateStreet: '',
    estateDistrict: '',
    estateCity: '',
    estateState: '',
    estateNumber: '',
    estateComplement: ''
  },

  hiredInstallments: 0,
  hiredInstallmentsValue: 0
}

/* Removi a obrigatoriedade do user_id no backend */
