import { FormikProps, withFormik } from 'formik';
import { connect } from 'react-redux';
import { iRootDispatch, iRootState } from '../../store';
import BondInsuranceContentForm from './BondInsuranceContentForm';
import { BondInsuranceSteps } from './types';
import { PersonTypesEnum } from '../../enums/person-types.enum';
import { BondInsuranceTenant } from '../../types/BondInsuranceTenant';
import { BondInsuranceProperty } from '../../types/BondInsuranceProperty';
import { BondInsuranceInitialData } from '../../types/BondInsuranceInitialData';
import { Partner } from '../../types/Partner';
import {
  legalCommercialResidentialSchema,
  naturalResidentialSchema,
  validationSchemas
} from './validators';
import { EstateState } from '../../types/EstateState';
import { SympathiseTenant } from '../../types/SympathiseTenant';
import { BondInsuranceFormState } from '../../store/types/temp-types';
import { PoliticallyExposedPersonEnum } from '../../enums/politically-exposed-person.enum';
import { BondInsuranceOwnerData } from '../../types/BondInsuranceOwnerData ';

const mapState = (state: iRootState) => ({
  user: state.user,
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  bondInsuranceForm: state.bondInsuranceForm
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateBondInsurance: (bondInsuranceFormState: Partial<BondInsuranceFormState>) =>
    dispatch.bondInsuranceForm.updateBondInsurance(bondInsuranceFormState)
});

export type StepProps = {
  handleInputChange: Function;
  handleDateChange: Function;
  isCopied?: boolean;
} & FormikProps<BondInsuranceSteps>;

export type BondInsuranceFormProps = StepProps &
  ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch>;

export const initialValues = {
  initial_info: {
    analysis_assurance: '',
    estate: null as unknown as EstateState,
    purpose: undefined,
    tenant_type: undefined
  } as BondInsuranceInitialData,
  property_info: {
    validity: null,
    contract_penalties_value: '0,00',
    interior_painting_value: '0,00',
    exterior_painting_value: '0,00',
    furniture_damage_value: '0,00',
    property_damage_value: '0,00',
    is_condominium_fee_required: true,
    is_energy_bill_required: true,
    is_water_bill_required: true,
    is_gas_bill_required: true,
    is_taxes_value_required: true
  } as BondInsuranceProperty,
  owner_info: {} as BondInsuranceOwnerData,
  tenant_info: {
    resident: true,
    partner_rent_composition: false,
    main_tenant_nationality: 'Brasileiro(a)',
    partners: [{} as Partner],
    sympathise_tenants: [{} as SympathiseTenant],
    include_sympathise_tenants: false,
    quiz_company_created: false,
    quiz_partners: false,
    contact_phone: '',
    main_tenant_politically_exposed_person: PoliticallyExposedPersonEnum.no,
    main_tenant_close_to_politically_exposed_person: {
      politically_exposed_person_name: '',
      politically_exposed_person_cpf: '',
      degree_of_kinship_to_politically_exposed_person: ''
    },
    resident_politically_exposed_person: PoliticallyExposedPersonEnum.no,
    resident_close_to_politically_exposed_person: {
      politically_exposed_person_name: '',
      politically_exposed_person_cpf: '',
      degree_of_kinship_to_politically_exposed_person: ''
    },
    main_tenant_profession: '',
    has_restrictions: false
  } as BondInsuranceTenant,
  attachments: {
    files: []
  }
};

const BondInsuranceForm = withFormik<BondInsuranceFormProps, BondInsuranceSteps>({
  mapPropsToValues: () => initialValues,

  validationSchema: (props: BondInsuranceFormProps) => {
    const { bondInsuranceForm } = props;
    if (props.bondInsuranceForm.currentPage != 2) {
      return validationSchemas[props.bondInsuranceForm.currentPage];
    }
    if (bondInsuranceForm.tenantType === PersonTypesEnum.natural) {
      return naturalResidentialSchema(bondInsuranceForm.estateEmail);
    } else {
      return legalCommercialResidentialSchema;
    }
  },

  handleSubmit: (values, bag) => true
})(BondInsuranceContentForm);

export default connect(mapState, mapDispatch)(BondInsuranceForm);
