import React, { SyntheticEvent, useEffect } from 'react';
import { Table, Button, Icon, Grid } from 'semantic-ui-react';
import { BondInsuranceAnalysis } from '../../../types/BondInsuranceAnalysis';
import { Formik, Field } from 'formik';
import StatusDropdown from '../../../components/StatusDropdown';
import { BondInsuranceAnalysisStatusEnum } from '../../../enums/bond-insurance-analysis-status.enum';
import Input from '../../../components/Input';
import { TranslatedCanceledStatus, statusOptions } from '../types';
import { CanceledBondInsuranceFull } from '../../../services/canceled-bond-insurance';

export type AnalysisDetailsProps = {
  analysisDetails: BondInsuranceAnalysis[];
  setIsLoading: Function;
  isCancelled: boolean;
  cancelledBondInsurance: CanceledBondInsuranceFull;
};


export const AnalysisDetailsTableForm = (props: AnalysisDetailsProps) => {
  const { analysisDetails, isCancelled, cancelledBondInsurance } = props;

  // useEffect(() => {
  //   if (cancelledBondInsurance !== null) {
  //     // console.log({cancelledBondInsurance})
  //   }
  // }, [cancelledBondInsurance])

  // useEffect(() => {
  //   if (!!analysisDetails.length) {
  //     console.log({analysisDetails})
  //   }
  // }, [analysisDetails])

  const analysisFormData: any[] = [];

  analysisDetails.forEach((analysis) => {
    const { id, status, observations, value } = analysis;
    analysisFormData.push({ id, status, observations, insurer: analysis.insurer.name, value })
  })

  const getStatusColor = (status: string): 'grey' | 'yellow' | 'green' | 'red' | 'orange' => {
    switch (status) {
      case BondInsuranceAnalysisStatusEnum.pending:
        return 'grey';
      case BondInsuranceAnalysisStatusEnum.analysis:
        return 'yellow';
      case BondInsuranceAnalysisStatusEnum.approved:
        return 'green';
      case BondInsuranceAnalysisStatusEnum.rejected:
        return 'red';
      case BondInsuranceAnalysisStatusEnum.biometry:
        return 'orange';
      default:
        return 'grey';
    }
  }

  return (
    <>
      <Formik
        initialValues={{ analysis: analysisFormData }}
        validationSchema={{}}
        onSubmit={() => {
        }}
      >
        {innerProps => {
          const { setFieldValue, setFieldTouched, values } = innerProps;
          const { analysis } = values;

          return (
            <>
              <Table >
                <Table.Header>
                  <Table.Row >
                    <Table.HeaderCell width={1}>#</Table.HeaderCell>
                    <Table.HeaderCell width={4}>SEGURADORA</Table.HeaderCell>
                    <Table.HeaderCell width={3}>STATUS DA ANÁLISE</Table.HeaderCell>
                    <Table.HeaderCell width={5}>OBSERVAÇÕES</Table.HeaderCell>
                    {
                      isCancelled && (
                        <Table.HeaderCell width={4}>VALOR DA PARCELA</Table.HeaderCell>
                      )
                    }
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {analysis.map((a, index) => {
                    return (
                      <>
                        <Table.Row key={index}>
                          <Table.Cell><Icon name='circle' color={getStatusColor(a.status)}></Icon></Table.Cell>
                          <Table.Cell>{a.insurer}</Table.Cell>
                          <Table.Cell>
                            <Field
                              name={`analysis[${index}].status`}
                              value={analysis[index].status}
                              defaultValue={a.status}
                              completeValue={true}
                              placeholder={'Selecionar...'}
                              options={statusOptions}
                              component={StatusDropdown}
                              disabled={isCancelled}
                              onChange={(_: SyntheticEvent, data: any) => {
                                setFieldValue(`analysis[${index}].status`, data.value)
                              }}
                              onClose={() => setFieldTouched(`analysis[${index}].status`, true)}
                              fluid />
                          </Table.Cell>
                          <Table.Cell>
                            <Field
                              name={`analysis[${index}].observations`}
                              value={analysis[index].observations}
                              disabled={isCancelled}
                              onChange={(_: SyntheticEvent, data: any) => {
                                setFieldValue(`analysis[${index}].observations`, data.value)
                              }}
                              onBlur={() => setFieldTouched(`analysis[${index}].observations`, true)}
                              component={Input}
                              fluid />
                          </Table.Cell>
                          {
                            isCancelled && (
                              <Table.Cell>
                                <Field
                                  name={`analysis[${index}].value`}
                                  value={Number(analysis[index].value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                  disabled={isCancelled}
                                  onChange={(_: SyntheticEvent, data: any) => {
                                    setFieldValue(`analysis[${index}].value`, data.value)
                                  }}
                                  onBlur={() => setFieldTouched(`analysis[${index}].value`, true)}
                                  component={Input}
                                  fluid
                                />
                              </Table.Cell>
                            )
                          }
                        </Table.Row>
                      </>
                    );
                  })}
                </Table.Body>
              </Table>

              {
                !isCancelled ? (
                  <Button
                    type={'submit'}
                    style={{ float: 'right', marginBottom: '5px' }}
                    color={'blue'}><Icon name={'save'}
                    onClick={() => props.setIsLoading(true)}
                  />
                    Salvar
                  </Button>
                ) : (
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={16} textAlign={'center'}>
                        {
                          cancelledBondInsurance.bondInsurance.bondInsuranceHiredData?.status === TranslatedCanceledStatus.CANCELLATION_IN_PROGRESS && (
                            <Button
                              type={'submit'}
                              style={{ float: 'right', marginBottom: '5px' }}
                              color={'green'}
                              onClick={() => props.setIsLoading(true)}
                            >
                              <Icon name={'check'} />
                              Confirmar Cancelamento
                            </Button>
                          )
                        }
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                )
              }
            </>
          )
        }}
      </Formik>
    </>
  )
}
