import {EstatesListReducer, EstatesListState} from "../types/temp-types";

type EstatesListRematchModel = {
  state: EstatesListState;
  reducers: EstatesListReducer;
}

const estatesList: EstatesListRematchModel = {
  state: {
    estates: [],
    isLoading: true
  },
  reducers: {
    updateEstatesList: (state: EstatesListState, payload: any) => {
      return {
        ...state,
        ...payload
      };
    }
  }
};

export default estatesList;
