import {FireInsurancePolicy} from "../../types/FireInsurance";
import {FireInsurancePoliciesListReducer, FireInsurancePoliciesListState} from "../types/temp-types";

type FireInsurancePoliciesListRematchModel = {
  state: FireInsurancePoliciesListState;
  reducers: FireInsurancePoliciesListReducer;
}

const fireInsurancePoliciesList: FireInsurancePoliciesListRematchModel = {
  state: {
    policies: Array<FireInsurancePolicy>(),
    onceRequested: false,
    isLoading: false,
    ascending: true
  },
  reducers: {
    updateFireInsurancePolicy: (state: FireInsurancePoliciesListState, updatedPolicy: FireInsurancePolicy): FireInsurancePoliciesListState => {
      return {
        policies: state.policies?.map((policy) => policy.id === updatedPolicy.id ? updatedPolicy : policy),
        ...state
      }
    },
    updateFireInsurancePoliciesList: (state: FireInsurancePoliciesListState, payload: Partial<FireInsurancePoliciesListState>): FireInsurancePoliciesListState => {
      return {
        ...state,
        ...payload
      }
    },
  }
};

export default fireInsurancePoliciesList;
