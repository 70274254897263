export enum EmploymentRelationshipEnum{
  retired = 'APOSENTADO/PENSIONISTA',
  freelance = 'AUTONOMO',
  entrepreneur = 'EMPRESARIO',
  student = 'ESTUDANTE',
  civilServant = 'FUNCIONARIO PUBLICO',
  privateServant = 'FUNCIONARIO CLT',
  liberal = 'LIBERAL',
  rentIncome = 'RENDA ALUGUEIS'
}
