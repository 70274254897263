import React, { useEffect, useState } from 'react';
import EditClaimEstateModal from '../components/edit-claim-modal/Modal';
import { getClaims, getClaimsReport } from '../../../services/claim';
import { get } from '../../../services/storage';
import FollowUpModal from './FollowUpModal';
import {
  Dimmer,
  Table,
  Segment,
  Loader,
  Label,
  Popup,
  Menu,
  Icon,
  Grid,
  Button,
  Input,
  Dropdown,
  Checkbox,
} from 'semantic-ui-react';
import { iRootDispatch, iRootState } from '../../../store';
import { connect } from 'react-redux';
import { AvailableBrokersAndEstatesState } from '../../../store/types/temp-types';
import CustomDatePicker from '../../../components/CustomDatePicker';
import AppMaskedInput from '../../../components/MaskedInput';
import { CustoDropDownItemProps } from '../types/CustoDropDownItem';
import { DATE_INPUT_MASK, maskCpfOrCnpj } from '../../../services/masks';
import DetailModal from './DetailModal';
import styled from 'styled-components';
import { translateStatus, translatePropertyStatus } from '../util';
import ModalAttachFiles from '../../../components/ModalAttachFiles';
import { FileIdentifiersAll } from '../types/FileIdentifiersClaimEstate.enum';
import { UserState } from '../../../types/UserState';
import { UserRolesEnum } from '../../../enums/user-roles.enum';
import { getUsers } from '../../../services/broker';
import moment from 'moment-timezone';
import { sendMessage } from '../../../services/followup';
import AuthorizationContainer from '../../../components/AuthorizationContainer';
import { ActionEnum } from '../../../enums/authz-action.enum';
import { FeatureEnum } from '../../../enums/authz-feature.enum';
import { useLocation } from 'react-router-dom';
import { ClaimEstatesStatusEnum } from '../types/ClaimEstatesStatusEnum';
import { Field, Formik } from 'formik';
import { ChecklistContainer } from '../../estates-preregister-list/styles';
import { DateContainer, EndDate, StartDate } from '../../../styles';
import { dangerNotification } from '../../../services/notification';
// import * as Sentry from '@sentry/react';
import { ReportModal } from '../../../components/ReportModal';

interface LocationState {
  claimId?: string;
}

const ClickableTableCell = styled(Table.Cell)`
  cursor: pointer;
  position: relative;
`;

const ClickableTableRow = styled(Table.Row)`
  :hover {
    background-color: #f0f0f0;
  }
`;

export const FieldLabel = styled.label<{ paddingTop?: boolean }>`
  font-weight: bold;
  padding-top: ${props => (props.paddingTop ? '14px' : '0px')};
  text-transform: uppercase;
  margin-left: 5px;
`;

export const FieldValue = styled.p`
  padding-left: 5px;
`;


const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

const ClaimTable = (props: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [id, setId] = useState('');
  const userId = get('uid');

  const claimPropertiesStatusEnum = [
    {
      index: 0,
      value: 'null',
      text: 'Todos'
    },
    {
      index: 1,
      value: 'OCCUPIED',
      text: 'Ocupado'
    },
    {
      index: 2,
      value: 'VACANT',
      text: 'Desocupado'
    }
  ];

  const claimEstatesStatusEnum = [
    {
      index: 10,
      value: 'RETURN_BILL',
      text: 'Boleto Devolutivo'
    },
    {
      index: 5,
      value: 'DEBT_CONFESSION',
      text: 'Confissão de Dívida'
    },
    {
      index: 0,
      value: 'CREATED',
      text: 'Em aberto'
    },
    {
      index: 6,
      value: 'IN_ACCORD',
      text: 'Em acordo'
    },
    {
      index: 1,
      value: 'IN_ANALYSIS',
      text: 'Em análise'
    },
    {
      index: 2,
      value: 'IN_PROGRESS',
      text: 'Em andamento'
    },
    {
      index: 4,
      value: 'FINISHED',
      text: 'Finalizado'
    },
    {
      index: 7,
      value: 'MONTHLY_INDEMNITY',
      text: 'Indenizado mensalmente'
    },
    {
      index: 8,
      value: 'LMI_LMG_REACHED',
      text: 'LMI/LMG atingido'
    },
    {
      index: 3,
      value: 'PENDING',
      text: 'Pendenciado'
    },
    {
      index: 9,
      value: 'PRE_ACCIDENT',
      text: 'Pré-sinistro'
    },
  ];

  const TYPE_OPTIONS = [
    {
      index: 1,
      value: 'name',
      text: 'Nome do locatário'
    },
    {
      index: 2,
      value: 'document',
      text: 'CPF ou CNPJ'
    },
    {
      index: 3,
      value: 'address',
      text: 'Endereço do imóvel'
    },
    {
      index: 4,
      value: 'claimCode',
      text: 'Código do sinistro'
    },
    {
      index: 5,
      value: 'processNumber',
      text: 'Número do processo'
    },
    {
      index: 6,
      value: 'policyNumber',
      text: 'Número da apólice'
    }
  ];

  const { availableBrokersAndEstates, user } = props;
  const brokers: CustoDropDownItemProps[] = availableBrokersAndEstates.brokers;
  const estates: CustoDropDownItemProps[] = availableBrokersAndEstates.estates;
  const allInsurers: CustoDropDownItemProps[] = availableBrokersAndEstates.insurers.map(
    (insurer: any) => ({
      index: insurer.value.id,
      value: insurer.value.id,
      text: insurer.value.name
    })
  );

  const insurers: CustoDropDownItemProps[] = allInsurers.concat({
    key: '297c0ed6-a9ea-48d3-b0da-235597ae9f7a',
    value: '297c0ed6-a9ea-48d3-b0da-235597ae9f7a',
    text: 'Liberty Seguros'
  } as any);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false);
  const [responsibleOptions, setResponsibleOptions] = useState<CustoDropDownItemProps[]>([]);
  const [isResponsibleLoading, setIsResponsibleLoading] = useState<boolean>(false);
  const [insurersOptions, setInsurersOptions] = useState<CustoDropDownItemProps[]>([]);
  const [estatesOptions, setEstatesOptions] = useState<CustoDropDownItemProps[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [broker, setBroker] = useState<any>();
  const [users, setUsers] = useState<UserState[]>();
  const [selectedEstate, setSelectedEstate] = useState<any>();
  const lastPage = Math.floor(count / 10) + 1;
  const [isBlocked, setIsBlocked] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState<any>(false);
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [followUpClaim, setFollowUpClaim] = useState<any>();
  const [functionUpdateFollowup, setFunctionUpdate] = useState<(a: number) => void>();
  const location = useLocation();
  const initialClaimId = (location.state as LocationState)?.claimId;
  const [claimId, setClaimId] = useState<any>(initialClaimId);
  const [filters, setFilters] = useState<any>({});
  const [reportModalIsOpen, setReportModalIsOpen] = useState<boolean>(false);
  const [reportState, setReportState] = useState<number | undefined>(undefined);

  const normalizeFilters = async (filters: any) => {
    const validFilters: any = {};

    for (const key in filters) {
      if (filters[key] === '' || key === 'argument') {
        continue;
      }

      if (key === 'start' || key === 'end') {
        validFilters[key] = moment(filters[key]).format('YYYY-MM-DD');
        continue;
      }

      if (key === 'type') {
        validFilters[filters[key]] = filters['argument'];
        continue;
      }

      validFilters[key] = filters[key];
    }

    return validFilters;
  };

  const handleClose = () => {
    setIsOpen(false);
    //history.push("claim_estates");
  };

  const handleReportModalClose = () => {
    setReportModalIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
    if (functionUpdateFollowup) {
      functionUpdateFollowup(0);
    }
  };

  const getResponsibleUsers = async (id: string) => {
    setIsLoadingUser(true);

    try {
      const users = await getUsers(id, [ { isDropdown: true} ]);

      setUsers(users);

      const nonResponsible = {
        key: 0,
        value: 'null',
        text: 'Não Atribuído'
      };

      const newUsers = users.map(user => {
        const { id, name } = user;

        const returnUser: CustoDropDownItemProps = {
          key: id,
          value: id,
          text: name
        };

        return returnUser;
      });

      const finalUsers = [nonResponsible, ...newUsers];

      setResponsibleOptions(finalUsers);
      setIsResponsibleLoading(false);
    } catch (error) {
      dangerNotification(
        'Ops...',
        'Erro ao carregar campo responsável.'
      );
    } finally {
      setIsLoadingUser(false);
    }
  };

  const getData = async (searchFilters?: any) => {
    setIsLoading(true);
    const userId = get('uid');
    const normalizedFilters = await normalizeFilters(searchFilters ?? filters);
    const filtersData: any = {
      page: currentPage,
      userId,
      ...normalizedFilters
    };

    const claims =
      claimId !== undefined
        ? await getClaims({ id: claimId, filters:filtersData })
        : await getClaims({ filters: filtersData });

    if (claimId) {
      setData([claims]);
      setCount(1);
    } else {
      setData(claims.data);
      if(currentPage === 1) setCount(claims.count);
    }

    setIsLoading(false);
    setClaimId(undefined);
  };

  const handleExportData = async () => {
    setIsLoading(true);

    try {
      const userId = get('uid');
      const normalizedFilters = await normalizeFilters(filters);
      const filtersData: any = {
        page: currentPage,
        userId,
        ...normalizedFilters
      };

      const status = await getClaimsReport({ filters: filtersData });

      if (!status) {
        dangerNotification(
          'Ops...',
          'Erro ao solicitar relatório de sinistros.'
        );
      } else {
        setReportState(status);
        setReportModalIsOpen(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFollowUp = (id: string, status: string, item: any) => {
    setIsOpen(true);
    setFollowUpClaim(item);
    setId(id);
    //setIsBlocked(false);
    setIsBlocked(status === 'FINISHED');
  };

  const openDetailModal = (claim: any) => {
    setSelectedClaim(claim);
    setOpenDetail(true);
  };

  async function callback(fileTypes: string[], claim_id: string) {
    if (fileTypes) {
      let message = 'Foram anexados os seguintes arquivos: ';
      message += `${fileTypes.join(', ')}.`;
      try {
        await sendMessage(claim_id, user.id, message, user.name);
      } catch (err) {
        dangerNotification("Erro", "Erro ao enviar notificação de followup, por favor, envie manualmente.");
        // Sentry.captureException(err, {
        //   tags: {
        //     page: 'claim_estates/components/Table.tsx',
        //     claimId: claim_id,
        //     user: user,
        //     fileTypes: fileTypes ? fileTypes.join(', ') : 'undefined',
        //   }
        // });
      }
    } else {
      await sendMessage(claim_id, user.id, "Arquivos foram anexados", user.name);
    }
  }

  const handleSearchFormSubmit = async (filters: any) => {
    setCurrentPage(1);
    getData(filters);
    setFilters(filters);
  };

  useEffect(() => {
    if (!!estates.length) {
      getData();
    }
  }, [currentPage, estates]);

  useEffect(() => {
    if (!insurersOptions.length) {
      const insurersOptions = insurers.map((insurer: any) => ({
        key: insurer.index,
        value: insurer.value,
        text: insurer.text
      }));

      setInsurersOptions(insurersOptions);
    }

    if (!estatesOptions.length) {
      const estatesOptions = estates.map((estate: any) => ({
        key: estate.value.id,
        value: estate.value.id,
        text: estate.text
      }));

      setEstatesOptions(estatesOptions);
    }
  }, [availableBrokersAndEstates, estates, estatesOptions, insurers, insurersOptions]);

  useEffect(() => {
    if (!!estates.length) {
      if (
        user.role === UserRolesEnum.estateAdmin ||
        user.role === UserRolesEnum.estateUser
      ) {
        setSelectedEstate(estates[0].value);
      }
    }
  }, [user, estates]);

  useEffect(() => {
    window.history.replaceState(
      {
        ...(location.state as any),
        claimId: undefined
      },
      ''
    );
  }, [location]);

  useEffect(() => {
    setIsResponsibleLoading(true);
    if (!!brokers.length) {
      setBroker(brokers[0].value);
    }
  }, [brokers]);

  useEffect(() => {
    if (typeof broker !== 'undefined' && !isLoadingUser) {
      const { id } = broker;
      getResponsibleUsers(id);
    }
  }, [broker]);

  return (
    <>
      {reportModalIsOpen &&
        reportState === 200 ? (
          <ReportModal
            isOpen={reportModalIsOpen}
            header='Relatório de Sinistros'
            message={`
              O relatório será gerado em background e será enviado o link para download via e-mail.
              Atenção, só será possível gerar um novo relatório após a finalização desse.
            `}
            onClose={handleReportModalClose}
          />
        ) : (
          <ReportModal
            isOpen={reportModalIsOpen}
            header='Relatório de Sinistros'
            message='Já existe um relatório na fila para ser gerado, aguarde a notificação via email.'
            onClose={handleReportModalClose}
          />
        )
      }

      {isOpen && (
        <FollowUpModal
          isOpen={isOpen}
          isBlocked={isBlocked}
          onOpen={handleOpen}
          claim={followUpClaim}
          onClose={handleClose}
          header="Follow Up"
          id={id}
        />
      )}

      <Formik
        initialValues={{
          type: 'document',
          argument: '',
          claimEstateStatus: '',
          claimPropertyStatus: '',
          start: '',
          end: '',
          estateId: '',
          insurerId: '',
          responsibleId: '',
          onlyUnread: false
        }}
        onSubmit={
          async (values, { setSubmitting }) => {
            await handleSearchFormSubmit(values);
            setSubmitting(false);
          }
        }
      >
        {({ values, handleChange, handleSubmit, setFieldValue, handleReset }) => (
          <form onSubmit={handleSubmit}>
            <Grid style={{ marginBottom: 20 }}>
              <Grid.Row style={{ paddingBottom: 0 }}>
                <Grid.Column width={3}>
                  <strong>Pesquisar por:</strong>
                  <Dropdown
                    name="type"
                    value={values.type}
                    options={TYPE_OPTIONS}
                    search
                    selection
                    disabled={isLoading}
                    onChange={(e, { value }) => {
                      if (value === '') setFieldValue('argument', value);
                      setFieldValue('type', value);
                    }}
                    clearable
                  />
                </Grid.Column>

                <Grid.Column width={3}>
                  <strong>Termo de pesquisa:</strong>
                  <Input
                    fluid
                    id="argument"
                    name="argument"
                    disabled={isLoading || !values.type}
                    value={values.argument}
                    onChange={handleChange}
                  />
                </Grid.Column>

                <Grid.Column width={3}>
                  <strong>Status:</strong>
                  <Dropdown
                    fluid
                    name="claimEstateStatus"
                    value={values.claimEstateStatus}
                    disabled={isLoading}
                    options={claimEstatesStatusEnum || []}
                    search
                    selection
                    onChange={(e, { value }) => setFieldValue('claimEstateStatus', value)}
                    clearable
                  />
                </Grid.Column>

                <Grid.Column width={3}>
                  <strong>Status do imóvel:</strong>
                  <Dropdown
                    fluid
                    search
                    selection
                    clearable
                    disabled={isLoading}
                    name="claimPropertyStatus"
                    value={values.claimPropertyStatus}
                    options={claimPropertiesStatusEnum || []}
                    onChange={(e, { value }) =>
                      setFieldValue('claimPropertyStatus', value)
                    }
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <strong>Data de cadastro:</strong>
                  <DateContainer>
                    <StartDate>
                      <label style={{ paddingRight: 5 }}>De:</label>
                      <Field
                        name="start"
                        selected={values.start}
                        disabled={isLoading}
                        component={CustomDatePicker}
                        onChange={(date: Date) => {
                          if (!date) setFieldValue('end', '');
                          setFieldValue('start', date ?? '');
                        }}
                        customInput={<AppMaskedInput mask={DATE_INPUT_MASK} />}
                        dateFormat={'dd/MM/yyyy'}
                      />
                    </StartDate>
                    <EndDate>
                      <label style={{ paddingRight: 5 }}>Até:</label>
                      <Field
                        name="end"
                        selected={values.end}
                        disabled={isLoading || !values.start}
                        component={CustomDatePicker}
                        onChange={(date: Date) => setFieldValue('end', date ?? '')}
                        customInput={<AppMaskedInput mask={DATE_INPUT_MASK} />}
                        dateFormat={'dd/MM/yyyy'}
                      />
                    </EndDate>
                  </DateContainer>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row style={{ marginTop: 0 }}>
                <Grid.Column width={3}>
                  <strong>Imobiliária:</strong>
                  <Dropdown
                    fluid
                    search
                    selection
                    clearable
                    name={'estateId'}
                    disabled={isLoading}
                    value={values.estateId}
                    options={estatesOptions}
                    onChange={(e, { value }) => setFieldValue('estateId', value)}
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <strong>Seguradora / Garantidora:</strong>
                  <Dropdown
                    fluid
                    search
                    selection
                    clearable
                    name={'insurerId'}
                    disabled={isLoading}
                    value={values.insurerId}
                    options={insurersOptions}
                    onChange={(e, { value }) => setFieldValue('insurerId', value)}
                  />
                </Grid.Column>

                <Grid.Column width={3}>
                  <strong>Responsável:</strong>
                  <Dropdown
                    fluid
                    search
                    selection
                    clearable
                    name="responsibleId"
                    value={values.responsibleId}
                    options={responsibleOptions}
                    loading={isResponsibleLoading}
                    disabled={isLoading}
                    onChange={(e, { value }) => setFieldValue('responsibleId', value)}
                  />
                </Grid.Column>

                <Grid.Column width={2}>
                  <ChecklistContainer>
                    <Checkbox
                      id="onlyUnread"
                      name="onlyUnread"
                      label={'Mensagens não lidas'}
                      style={{ fontSize: 14 }}
                      disabled={isLoading}
                      checked={values.onlyUnread}
                      onChange={handleChange}
                    />
                  </ChecklistContainer>
                </Grid.Column>

                <Grid.Column
                  width={4}
                  verticalAlign={'bottom'}
                  textAlign={'right'}
                  floated="right"
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  {user.role && user.role.includes('BROKER') && (
                    <Button
                      disabled={isLoading}
                      fluid
                      type="button"
                      onClick={handleExportData}
                      content={'Exportar'}
                    />
                  )}

                  <Button
                    fluid
                    onClick={handleReset}
                    content={'Limpar'}
                    disabled={isLoading}
                    type="button"
                  />

                  <Button
                    primary
                    fluid
                    disabled={isLoading}
                    type="submit"
                    content={'Pesquisar'}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </form>
        )}
      </Formik>

      <Table celled>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell textAlign={'center'}>Solicitado em</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>Seguradora</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>Imobiliária</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>Inquilino</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>Endereço</Table.HeaderCell>
            {/* <Table.HeaderCell textAlign={'center'}>Status do Imóvel</Table.HeaderCell> */}
            <Table.HeaderCell textAlign={'center'}>Status</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>Responsável</Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>Ações</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Dimmer.Dimmable as={Table.Body} dimmed={isLoading}>
          {isLoading && (
            <Dimmer active blurring>
              <Loader />
            </Dimmer>
          )}
          {data.length > 0 ? (
            <>
              {data.map((item: any) => {
                // console.log({ item })
                const {
                  id,
                  createdAt,
                  insurer,
                  estate,
                  status,
                  claimTenant,
                  claimProperty,
                  createdBy,
                  responsible,
                  messages
                } = item;
                // setIsBlocked(status ===  'FINISHED')
                let unread = 0;
                // console.log('Msgs', messages);

                messages.map((message: any) => {
                  // console.log('MSG', message);
                  if (!message.viewed && message.senderId !== userId) {
                    unread++;
                  }
                });

                // console.log(unread);

                const fullAddress = `${claimProperty.street}, ${claimProperty.number}${
                  claimProperty.complement ? ` ${claimProperty.complement}` : ''
                } - ${claimProperty.district} - ${claimProperty.city} - ${
                  claimProperty.state
                }`;
                const address = `${claimProperty.street}, ${claimProperty.number}${
                  claimProperty.complement ? ` ${claimProperty.complement}` : ''
                }`;
                const popupAddress = () => (
                  <>
                    <span>{fullAddress}</span>
                    <br />
                    <strong>
                      Imóvel {translatePropertyStatus(claimProperty.status)}
                    </strong>{' '}
                    <Icon
                      name={'circle'}
                      color={claimProperty.status === 'OCCUPIED' ? 'red' : 'green'}
                    />
                  </>
                );

                const createdAtFormatted = moment(createdAt)
                  .format(`DD/MM/YYYY HH:mm`)
                  .split(' ');
                return (
                  <>
                    <ClickableTableRow key={id!}>
                      <ClickableTableCell
                        onClick={() => openDetailModal(item)}
                        textAlign={'center'}
                        collapsing
                      >
                        {createdAtFormatted[0]} <br /> {createdAtFormatted[1]}
                      </ClickableTableCell>
                      <ClickableTableCell
                        onClick={() => openDetailModal(item)}
                        textAlign={'center'}
                        width={2}
                        style={{
                          textOverflow: 'ellipsis',
                          wordWrap: 'nowrap'
                        }}
                      >
                        {insurer?.name}
                      </ClickableTableCell>
                      <ClickableTableCell
                        onClick={() => openDetailModal(item)}
                        textAlign={'center'}
                      >
                        {estate.name}
                      </ClickableTableCell>
                      <ClickableTableCell
                        onClick={() => openDetailModal(item)}
                        textAlign={'center'}
                      >
                        {claimTenant.fullName}
                        <br />
                        <span style={{ color: '#777' }}>
                          ({maskCpfOrCnpj(claimTenant.document)})
                        </span>
                      </ClickableTableCell>
                      <ClickableTableCell
                        onClick={() => openDetailModal(item)}
                        style={{ cursor: 'default' }}
                        textAlign={'center'}
                      >
                        <Popup
                          content={popupAddress}
                          as={Segment}
                          style={{ border: '2px solid #dfdfdf' }}
                          position={'left center'}
                          trigger={
                            <span style={{ cursor: 'help' }}>
                              {address}
                              <br />
                              <span style={{ color: '#777' }}>
                                ({translatePropertyStatus(claimProperty.status)})
                              </span>
                            </span>
                          }
                        />
                      </ClickableTableCell>
                      <ClickableTableCell
                        onClick={() => openDetailModal(item)}
                        textAlign={'center'}
                      >
                        {translateStatus(status)}
                      </ClickableTableCell>
                      <ClickableTableCell
                        onClick={() => openDetailModal(item)}
                        style={{ cursor: 'default' }}
                        textAlign={'center'}
                      >
                        {responsible?.name ? (
                          <span>{responsible.name}</span>
                        ) : (
                          <span style={{ color: '#ccc', cursor: 'default' }}>
                            Não Atribuido
                          </span>
                        )}
                      </ClickableTableCell>
                      <Table.Cell>
                        <Menu compact>
                          <Menu.Item>
                            <Button
                              primary
                              icon="chat"
                              onClick={() => {
                                handleFollowUp(id, status, item);
                              }}
                            />
                            {unread !== 0 && (
                              <Label color="red" circular floating>
                                {unread}
                              </Label>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            <ModalAttachFiles
                              message={`A inserção de documentos é obrigatória para a abertura do sinistro.`}
                              trigger={<Button color="teal" icon="attach" />}
                              bondInsuranceId={id}
                              tableName={'CLAIM_ESTATES'}
                              fileIdentifiers={FileIdentifiersAll}
                              callback={data => callback(data, id)}
                              brokerOnly={true}
                              tenantName={claimTenant.fullName}
                              disabled={status === ClaimEstatesStatusEnum.finished}
                            />
                          </Menu.Item>
                          {
                            //status !== "FINISHED" &&
                            !user.role!.includes('ESTATE') && (
                              <AuthorizationContainer
                                action={ActionEnum.update}
                                feature={FeatureEnum.claims}
                              >
                                <Menu.Item>
                                  <EditClaimEstateModal
                                    users={users!}
                                    claimEstate={item}
                                    trigger={
                                      <Button color="yellow" icon="pencil alternate" />
                                    }
                                  />
                                </Menu.Item>
                              </AuthorizationContainer>
                            )
                          }
                        </Menu>
                      </Table.Cell>
                    </ClickableTableRow>
                  </>
                );
              })}
            </>
          ) : (
            <Table.Row>
              <Table.Cell colSpan={9} textAlign={'center'}>
                <span>Nenhum registro encontrado</span>
              </Table.Cell>
            </Table.Row>
          )}
        </Dimmer.Dimmable>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan={9} textAlign={'right'}>
              {!isLoading && (
                <Menu floated="right" pagination>
                  <Menu.Item>
                    <p>{`${currentPage * 10 - 9} - ${
                      count < currentPage * 10 ? count : currentPage * 10
                    } de ${count}`}</p>
                  </Menu.Item>
                  <Menu.Item
                    disabled={currentPage === 1}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(1)}
                  >
                    <Icon name="angle double left" />
                  </Menu.Item>
                  <Menu.Item
                    disabled={currentPage === 1}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item as="a">{currentPage}</Menu.Item>
                  <Menu.Item
                    disabled={currentPage === lastPage || data.length < 10}
                    as="a"
                    icon
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                  <Menu.Item as="a" icon>
                    <Icon
                      disabled={currentPage === lastPage || data.length < 10}
                      name="angle double right"
                      onClick={() => setCurrentPage(lastPage)}
                    />
                  </Menu.Item>
                </Menu>
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <DetailModal
        open={openDetail}
        claim={selectedClaim}
        close={() => setOpenDetail(false)}
      />
    </>
  );
};
export default connect(mapState, mapDispatch)(ClaimTable);
