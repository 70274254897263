import styled from 'styled-components';
import { Grid, GridColumn } from 'semantic-ui-react';

interface ImgProps {
  selected?: boolean;
}

export const InsurerImageWrapper = styled.div<ImgProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 250px;
    opacity: ${props => (props.selected ? '1' : '.3')};
    vertical-align: middle;
    border-radius: 16px;
    cursor: pointer;
  }

  ${props =>
    props.selected
      ? `
    &:after {
      content: "✅";
      position: absolute;
      bottom: -5px;
      right: -15px;
      font-size: 32px;
    }`
      : ''}
`;

export const PreQuoteWrapper = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;
`;

export const PreQuoteHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;

  height: 60px;

  font-weight: bold;

  color: #fff;
  background-color: #1667fa;

  > h3 {
    color: #ffffff;
  }
`;

export const RequirementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

export const PopupContent = styled.div`
  > div {
    display: flex;
    flex-direction: column;

    > span {
    }
  }
`;

export const Subtitle = styled.h2`
  font-size: 20px;
  color: #2185d0;
  padding-top: 40px;
`;

export const Hr = styled.hr`
  border: 1px solid #ddd;
`;

export const InsurerWrapper = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: left;
  align-items: center;
  padding: 10px;
  gap: 0.5rem;

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    width: 20px;

  }
  input[type='checkbox']:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-color: #2185d0;
  }
  input[type='checkbox']:checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background-color: #fff;
    border: 1.4px solid #ccc;
    border-radius: 4px;
  }
  input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: 1px solid #2185d0;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 5px;
    left: 8px;
  }
  img {
    border-radius: 50%;
    width: 30px;
    border: 1px solid #ccc;
    padding: 1px;
  }
`;

export const BudgetGridColumn = styled(Grid.Column)`
  width: unset;
  @media (max-width: 768px) {
    width: 80% !important;
  }
`;

