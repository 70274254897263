import { FireInsurancePolicy } from "../types/FireInsurance";
import axiosRequest from './axios-instance';
import { AxiosError } from 'axios';
import moment from "moment-timezone";
import { get, post } from "./request";

export enum FireInsurancePolicyStatus {
  CREATED = "CREATED",
  CANCELED = "CANCELED",
}
export const fireInsurancePolicyStatusLabel = new Map<
  FireInsurancePolicyStatus,
  string
>([
  [FireInsurancePolicyStatus.CREATED, "Emitida"],
  [FireInsurancePolicyStatus.CANCELED, "Cancelada"],
]);

export enum FireInsurancePolicySendStatus {
  PENDING = "PENDING",
  SENT = "SENT",
}

export const sortPolicies = (
  data: Array<FireInsurancePolicy>,
  ascending: boolean
): Array<FireInsurancePolicy> => {
  return data.sort(
    (p1: FireInsurancePolicy, p2: FireInsurancePolicy): number => {
      const p1CreatedAt = moment(p1.created_at || new Date(0, 0, 0));
      const p2CreatedAt = moment(p2.created_at || new Date(0, 0, 0));
      if (p1CreatedAt.isSame(p2CreatedAt)) {
        return 0;
      } else {
        return ascending === p1CreatedAt.isAfter(p2CreatedAt) ? -1 : 1;
      }
    }
  );
};

export const cancelPolicy = async (
  policy: FireInsurancePolicy
): Promise<FireInsurancePolicy> => {
  if (policy.id) {
    const body = {
      id: policy.id,
      code: policy.fire_insurance_proposal.fire_insurance_budget.code,
      insurer_id:
        policy.fire_insurance_proposal.fire_insurance_budget.occupation
          ?.property_type.insurer.id,
    };
    return await post("/fire-insurance-policies/cancel", body);
  } else {
    return new Promise((_, reject) => reject("Policy ID is not defined."));
  }
};

export const printPolicy = async (
  id: string,
  userId: string,
  code: string,
  insurer?: string | undefined
): Promise<any> => {
  try {
    return await get(
      `/fire-insurance-policies/print?policy_id=${id}&user_id=${userId}&code=${code}&insurer_id=${insurer || 0
      }`
    );
  } catch (e) {
    throw e;
  }
};


export const printPolicyByCombo = async (
  ticket_number: string,
): Promise<any> => {
  try {
    return await get(
      `/fire-insurance-policies/combo?ticket_number=${ticket_number}`
    );
  } catch (e) {
    throw e;
  }
};

export const hirePottencial = async (dto: { userId: string; fireInsuranceAnalysisId: string }): Promise<any> => {
  const url = '/fire-insurance-proposals/pottencial'

  console.log({ url, dto })

  let response;

  try {
    response = await post(url, dto);

  } catch (e) {
    console.log({ message: (e as any).message })
    throw e;
  }

  return response
}

export const getPolicies = async (filter: any) => {
  let response;
  let query = '';

  if (filter) {
    Object.entries(filter).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`
      }
    })
  }

  try {
    const [data, quantity] = await get(`/fire-insurance-proposals?${query}`);
    response = {
      data,
      quantity
    }
  } catch (e) {
    throw e;
    response = null;
  }

  return response;
}

export const getPolicyInfo = async (id: string) => {
  try {
    return get(`/fire-insurance-proposals/id/${id}`);
  } catch (error) {
    console.log(error);
  }
}

export const getPolicyInfoAwaitingBilling = async (estateId: string) => {
  try {
    return get(`/fire-insurance-proposals/awaiting-billing?estateId=${estateId}`);
  } catch (error) {
    console.log(error);
  }
}

export const getFireInsuranceReport = async (filters: any) => {
  let query = '';

  if (filters) {
    Object.entries(filters).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  try {
    const response = await axiosRequest.get(`/fire-insurance-proposals/report-excel?${query}`);
    return response.status;
  } catch (error) {
    const { response } = error as AxiosError;
    return response?.status;
  }
};

export const getFireInsuranceModelAlfaReport = async (filters: any) => {
  let query = '';

  if (filters) {
    Object.entries(filters).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  try {
    const response = await get(`/fire-insurance-proposals/export-policies-xlsx?${query}`);
    return response;
  } catch (error) {
    const { response } = error as AxiosError;
    return response;
  }
};

export const getFireInsuranceEstatesReport = async (filters: any) => {
  let query = '';

  if (filters) {
    Object.entries(filters).forEach((map: [string, any]) => {
      if (map[1]) {
        query += `${map[0]}=${map[1]}&`;
      }
    });
  }

  try {
    const response = await axiosRequest.get(`/fire-insurance-proposals/report-estates?${query}`);
    return response.status;
  } catch (error) {
    const { response } = error as AxiosError;
    return response?.status;
  }
};

export async function invalidateCacheBroker() {
  try {
    await axiosRequest.delete('/cache-aux/del');
  } catch (error) {
    console.error('Failed to invalidate cache:', error);
  }
};
