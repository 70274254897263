import { StringSchema, addMethod } from "yup";
import * as Yup from "yup";
import { formatAmount, unmaskMoney } from "../../services/masks";
import { isCpf, isCnpj } from "validator-brazil";
import { Code } from "../../types/InsurerState";

export const REQUIRED_FIELD = "Campo Obrigatório!";
const INVALID_FIELD = "Valor inválido!";
export const GREATER_THAN_ZERO = "Valor deve ser maior que zero!";
const MIN_DAMAGE_COVERAGE = 99999;

export const isValidDocument = (document: string) => {
  if(!document) return false;
  const onlyNumbers = document.replace(/\D/g, "");
  return onlyNumbers.length === 11 ? isCpf(document) : isCnpj(document);
}

declare module "yup" {
  interface StringSchema {
    greaterThanZero(errorMessage: string): StringSchema;
    lessThanMaximum(errorMessage: string): StringSchema;
  }
}

addMethod(Yup.string, "greaterThanZero", function (errorMessage) {
  return this.test("test-greater-than-zero", errorMessage, function (value) {
    const { path, createError } = this;

    return value !== "0,00";
  });
});

addMethod(Yup.string, "lessThanMaximum", function () {
  return this.test(
    "test-lesser-than-maximum",
    "",
    function (insuredAmount: string) {
      const { path, createError, options } = this;

      const context = this.options.context as any;
      const maximumInsuredAmount: number = context?.maximumInsuredAmount;

      if (unmaskMoney(insuredAmount) <= maximumInsuredAmount) {
        return true;
      } else {
        return this.createError({
          message:
            "Valor não pode ser maior do que R$" +
            formatAmount(maximumInsuredAmount),
          path: "insuredAmount",
        });
      }
    }
  );
});

/*export const getFireInsuranceQuotationValidationSchema = () => {
  //@Todo finish dynamic schema generation
  const schema = Yup.object().shape({
    budget: Yup.object().shape({
      coverageSection: getCoverageSectionValidationSchema()
    })
  });

  return schema;
};*/

/*export const getCoverageSectionValidationSchema = (): Yup.ObjectSchema<ConnectorCoverageSectionSchema>  => {
  const coverageSchema: Yup.ObjectSchema<ConnectorCoverageSectionSchema> = Yup.object().shape({
    selectedCoveragesAmount: Yup.number().integer()
      .min(MINIMUM_COVERAGES_AMOUNT, `São necessárias pelo menos ${MINIMUM_COVERAGES_AMOUNT} coberturas selecionadas!`),
    coverages: Yup.array().of(Yup.object().shape(connectorCoverageYupSchema))
  });

  return coverageSchema;
}*/

export const fireInsuranceBudgetValidationSchema: any = Yup.object().shape({
  budget: Yup.object().shape({
    /*insurance: Yup.object().shape({
      broker: Yup.string().required(REQUIRED_FIELD),
      estate: Yup.string().required(REQUIRED_FIELD),
      insurer: Yup.string().required(REQUIRED_FIELD),
      propertyType: Yup.string().required(REQUIRED_FIELD),
      occupation: Yup.string().required(REQUIRED_FIELD),
      assistance: Yup.string().required(REQUIRED_FIELD),
      rentValue: Yup.string().required(REQUIRED_FIELD).greaterThanZero(GREATER_THAN_ZERO),
    }),*/
    /* @TODO: Fix it - bad behavior on formik */
    // coverage: Yup.array().of(
    //   Yup.object().shape({
    //     damage_amount: Yup.number().required(REQUIRED_FIELD).transform((_: any, originalValue: any) => {
    //       return originalValue === '' ? undefined : unmaskMoney(originalValue)
    //     })
    //       .required(REQUIRED_FIELD)
    //       .integer(INVALID_FIELD)
    //       .moreThan(MIN_DAMAGE_COVERAGE, `O valor coberto deve ser no mínimo R$ ${maskMoney((MIN_DAMAGE_COVERAGE + 1).toString())}`),
    //   })
    // ),
    /*    property: Yup.object().shape({
      zipCode: Yup.string().required(REQUIRED_FIELD),
      street: Yup.string().required(REQUIRED_FIELD),
      district: Yup.string().required(REQUIRED_FIELD),
      city: Yup.string().required(REQUIRED_FIELD),
      state: Yup.string().required(REQUIRED_FIELD),
      number: Yup.string().required(REQUIRED_FIELD),
    }),*/
    /*    owner: Yup.object().shape({
      document: Yup.boolean()
        .required(REQUIRED_FIELD)
        .transform((_: any, originalValue: any) => {
          return originalValue === false ? undefined : isValidDocument(unmaskCpfOrCnpj(originalValue))
        })
        .oneOf([true], 'Documento inválido!'),
      name: Yup.string().required(REQUIRED_FIELD),
      email: Yup.string()
        .email("O e-mail informado não é válido"),
    }),*/
    /*    tenant: Yup.object().shape({
      document: Yup.boolean()
        .required(REQUIRED_FIELD)
        .transform((_: any, originalValue: any) => {
          return originalValue === false ? undefined : isValidDocument(unmaskCpfOrCnpj(originalValue))
        })
        .oneOf([true], 'Documento inválido!'),
      name: Yup.string().required(REQUIRED_FIELD),
      email: Yup.string()
        .email("O e-mail informado não é válido").required(REQUIRED_FIELD),
      birthDate: Yup.date()
        .when('budget.insurance.insurer.code', {
          is: Code.TokioMarine,
          then: Yup.date().required(REQUIRED_FIELD)
      }),
      telephone: Yup.string()
      .when('budget.insurance.insurer.code', {
        is: Code.TokioMarine,
        then: Yup.string().required(REQUIRED_FIELD)
    }),
    }),*/
  }),
  simulator: Yup.object().shape({
    validity: Yup.number().required(REQUIRED_FIELD),
    startDate: Yup.date().required(REQUIRED_FIELD),
    endDate: Yup.date().required(REQUIRED_FIELD),
    installments: Yup.number().integer(INVALID_FIELD).required(REQUIRED_FIELD),
  }),
});
