import Swal from 'sweetalert2'

import React, { useEffect, useState } from "react";
import {
  Button,
  Header,
  Icon,
  Modal,
  Step,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import { CancellationFormik } from "../Formik/CancellationFormFormik";
import { CancelModalProps } from '../../../types'
import withReactContent from 'sweetalert2-react-content'
import { post } from "../../../../../services/request";
import {
  dangerNotification,
  successNotification,
} from "../../../../../services/notification";
import { postFileAndGetPreSignAxios } from '../../../../../services/file';

const CancelModal = ({
  openModal,
  closeModal,
  setOpen,
  hiredDataId,
  bondInsuranceId,
  callback
}: CancelModalProps) => {
  const [flagSubmitFrom, setFlagSubmitFrom] = useState(false);
  const [stepOfCancelModal, setStepOfCancelModal] = useState(
    "cancellation"
  );

  const [loading, setLoading] = useState(false)

  const MySwal = withReactContent(Swal);

  async function submitFiles(
    files: {
      file: File;
      name: string;
    }[]
  ) {
    const responses = [];
    for (const file of files) {
      const { data: response } = await postFileAndGetPreSignAxios({
        filename: file.file.name,
        contentType: file.file.type,
        referencedTableName: "bond_insurance",
        referencedTableId: bondInsuranceId,
        name: file.name,
      });

      const fileToBlob = async (theFile: File) =>
        new Blob([new Uint8Array(await theFile.arrayBuffer())], {
          type: theFile.type,
        });
      const blob = await fileToBlob(file.file);

      await fetch(response.uploadURL, {
        method: "PUT",
        body: blob,
      });
      responses.push({ id: response.id, contextType: file.name });
    }
    return responses;
  }



  async function handleSubmit(payload: any) {
    console.log('PAYLOAD: ', payload);
    MySwal.fire({
      title: `Atenção`,
      text: "Esta ação é irreversível e consequentemente não será possível realizar a abertura de sinistro para indenização. Deseja prosseguir? ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, desejo prosseguir",
      cancelButtonText: "Não",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const files = await submitFiles(payload.files);
          console.log({ files });
          const response = await post("/canceled-bond-insurances/proposal", payload);
          console.log(response);
          successNotification("Sucesso", "Solicitação de Cancelamento recebida com sucesso.");
        } catch (err) {
          dangerNotification("Erro", "Erro ao efetuar solicitação.", 4000);
          console.log(err);
        } finally {
          callback()
          setLoading(false);
          setFlagSubmitFrom(false);
          closeModal();
        }
      }
    })
  }

  return (
    <div style={{ outline: 'none' }}>
      <Modal
        closeIcon
        open={openModal}
        onClose={
          () => closeModal()
        }
        trigger={
          <Button style={{ width: '100%', borderRadius: 0, backgroundColor: "#fff" }} >
            <Icon name={"trash"} />
            Solicitar Cancelamento
          </Button>
        }
      >
        <Header icon="file alternate outline" content={`Cancelamento de Proposta`} />
        <Modal.Content>
          <Dimmer active={loading}>
            <Loader indeterminate> Salvando proposta... </Loader>
          </Dimmer>
          <Step.Group>
            <Step
              onClick={() => setStepOfCancelModal("cancellation")}
              active={stepOfCancelModal === "cancellation"}
              title={`Cancelamento`}
              description=""
            />
          </Step.Group>
          {stepOfCancelModal === "cancellation" && (
            <CancellationFormik
              flagSubmit={flagSubmitFrom}
              setFlagSubmit={setFlagSubmitFrom}
              callbackSubmit={handleSubmit}
              hiredDataId={hiredDataId}
              closeModal={closeModal}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={() => closeModal()}>
            <Icon name="remove" /> Sair
          </Button>
          <Button color="green" onClick={() => setFlagSubmitFrom(true)}>
            <Icon name="checkmark" />
            Confirmar
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
export default CancelModal;
