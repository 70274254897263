import { iRootState, iRootDispatch } from '../../store';
import { connect } from 'react-redux';
import { AvailableBrokersAndEstatesState } from '../../store/types/temp-types';
import FranchiseListPage from './components/Page';

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers)
});

export type FranchiseListPageProps = ReturnType<typeof mapDispatch> &
  ReturnType<typeof mapState> & { broker: any; estate: any };

export default connect(mapState, mapDispatch)(FranchiseListPage);
