import React, { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { StepProps } from '../../BondInsuranceForm';
import PropertyData from './components/PropertyData';
import PropertyAddress from './components/PropertyAddress';
import {connect} from "react-redux";
import {iRootDispatch, iRootState} from "../../../../store";
import {BondInsuranceFormState} from "../../../../store/types/temp-types";
import ContractData from "./components/ContractData";

const mapState = (state: iRootState) => ({
  bondInsuranceForm: state.bondInsuranceForm
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateBondInsurance: (bondInsuranceFormState: BondInsuranceFormState) =>
    dispatch.bondInsuranceForm.updateBondInsurance(bondInsuranceFormState)
});

type PropertyInfoStepProps = StepProps
  & ReturnType<typeof mapState>
  & ReturnType<typeof mapDispatch>;

const PropertyInfoStep = (props: PropertyInfoStepProps) => {
  useEffect(() => {
    if (props.bondInsuranceForm) {
      props.updateBondInsurance({
        ...props.bondInsuranceForm
      })
    }
  }, [])

  return (
    <Grid>
      <ContractData  {
        ...props
      } />
      <PropertyData {...props} />
      <PropertyAddress  {...props} />
    </Grid>
  );
}

export default connect(mapState, mapDispatch)(PropertyInfoStep);
