export enum UserRolesEnum {
  brokerAdmin = 'BROKER_ADMIN',
  brokerAnalyst = 'BROKER_ANALYST',
  estateAdmin = 'ESTATE_ADMIN',
  estateUser = 'ESTATE_USER',
  claimAnalyst = 'CLAIM_ANALYST',
  accountManager = 'ACCOUNT_MANAGER',
  cancelationAnalyst = 'CANCELATION_ANALYST',
  franchiseAdmin = 'FRANCHISE_ADMIN',
  //support = 'SUPPORT'
}
