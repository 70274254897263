/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Loader, Modal, Dimmer, Button } from 'semantic-ui-react';
import { SelectableModalProps } from '../types';
import FollowUp from '../GeneralFollowUp';
import { getMessages } from '../../services/generalfollowup';
import MessageSender from '../GeneralMessageSender';
import {
  getCanceledBondInsuranceByID,
  updateViewedByIds
} from '../../services/canceled-bond-insurance';
import { dangerNotification } from '../../services/notification';
import { CanceledStatusEnum } from "../../enums/status-canceled-bond-insurances";
import { updateViewed } from '../../services/generalfollowup';
import { getHiredInvolvedByBondId, InvolvedType } from '../../services/bond-insurance-policy';
import { FollowUpContextEnum } from '../../enums/followup-context.enum';
import { getBondInsuranceByID } from '../../services/bond-insurance';
import { get } from '../../services/storage';
import { BondInsuranceHiredDataStatusEnum } from '../../enums/bond-insurance-hired-data-status.enum';

export type FollowUpModalProps = {
  onClose: () => any;
  onOpen: () => any;
  id: string;
  context: string;
  isBlocked?: boolean;
  involved?: string;
  viewedBy?: string;
  user?: any;
} & SelectableModalProps;

const FollowUpModal = (props: FollowUpModalProps) => {
  const { header, onClose, onOpen, isOpen, id, context, user } = props; // , setUnread, unread
  const [messages, setMessages] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [involved, setInvolved] = useState<string>('');
  const [viewedBy, setViewedBy] = useState<string>('');
  const [bondId, setBondId] = useState<string>('');
  const [bond, setBond] = useState<any>(null);
  const [blocked, setBlocked] = useState<boolean>(false);
  const [isInternal, setIsInternal] = useState<boolean | null>((user.estateId || user.franchiseId) ? false : null);

  const user_id = get('uid');
  const user_role = get('role');

  useEffect(() => {
    if(user_id) {
      setViewedBy(user_id)
    }
  }, [user_id])

  const statusToBlock = [CanceledStatusEnum.CANCELED, CanceledStatusEnum.UNCANCELLED]

  const getInvolved = async (id: string, involved?: string) => {
    const hiredInvolved: InvolvedType = await getHiredInvolvedByBondId(id);
    const uniqueIds: string[] = [];
    let involvedString = involved ?? '';

    Object.values(hiredInvolved).forEach(value => {
      if (value !== null && typeof value === 'string' && !uniqueIds.includes(value)) {
        uniqueIds.push(value);
      }
    });

    involvedString += uniqueIds.join(',')

    setInvolved(involvedString);
  }

  const getBondInsurance = async () => {
    switch(context) {
      case FollowUpContextEnum.canceleds:
        getCanceledBondInsuranceByID(id)
        .then(res => {
          let estateId = "";
          const resBondInsurance = res.bondInsurance
            ? res.bondInsurance
            : res.externalCancelation;
          setBondId(resBondInsurance.id);
          estateId = resBondInsurance.estateId
            ? resBondInsurance.estateId
            : resBondInsurance.estate!.id
            ? resBondInsurance.estate!.id
            : "BROKER";
          setBond(resBondInsurance);
          if (resBondInsurance && statusToBlock.includes(res.status)) {
            setBlocked(true);
          }
          })
        .catch((err) => {
          console.error({ err });
        });
        break;
      case FollowUpContextEnum.estates:
        setBondId(id);
        break;
      case FollowUpContextEnum.policies:
        getInvolved(id);
        setBondId(id);
        getBondInsuranceByID(id).then(res => {
          setBond(res);
          if(res && res.bond_insurance_hired_data && res.bond_insurance_hired_data.status !== BondInsuranceHiredDataStatusEnum.underRenewal) {
            if(user_role?.includes('ESTATE')) {
              setBlocked(true);
            }
          }
        }).catch((error) => {
          console.log('ERRO:', error);
        })
        break;
      case FollowUpContextEnum.analysis:
        setIsInternal(false);
        setBondId(id);
        getBondInsuranceByID(id).then(res => {
          setBond(res);
        }).catch((error) => {
          console.log('ERRO:', error);
        })
        break;
      default:
        setBondId(id);
        break;
    }

  };

  async function updateViewedBy(messages: any[]) {
    let count = 0;
    messages.forEach(message => {
      const viewers = message.viewedBy ? message.viewedBy.split(',') : [];
      const viewed = viewers.includes(user_id);
      if (!viewed) {
        const newViewed = message.viewedBy ? (message.viewedBy + ',' + user_id) : (user_id ?? 'id');
        updateMessage(message.id, newViewed, context);
        count++;
      }
    });
  }

  async function updateMessage(messageId: string, viewedBy: string, context: string) {
    switch(context) {
      case FollowUpContextEnum.canceleds:
        await updateViewedByIds(messageId, viewedBy);
        break;
      default:
        await updateViewed(messageId, viewedBy);
        break;
    }
  }

  useEffect(() => {
    getBondInsurance();
  }, [id]);

  useEffect(() => {
    async function loadMessages() {
    if (bondId && isInternal !== null) {
      setIsLoading(true)
      try {
        const msgs = await getMessages({ id: bondId, context, isInternal: isInternal  })
        setMessages([...msgs]);
      }catch(err) {
        console.error({ err })
        dangerNotification('Erro ao carregar mensagens', 'Por favor, tente novamente mais tarde.')
      }finally {
        setIsLoading(false)
      }

    }}
    loadMessages();
  }, [bondId, isInternal]);

  useEffect(() => {
    if (messages.length > 0) {
      if (context !== FollowUpContextEnum.capitalizations) {
        updateViewedBy(messages);
        if (context === FollowUpContextEnum.policies) {
          getInvolved(id);
        }
      }
      setInvolved(messages[0].involved);
      setViewedBy(user.id);
    } else {
      if (bond) {
        const usersInvolved = bond.tenant
          ? user.id
          : user.id === bond.estate.user.id
          ? user.id
          : user.id + ',' + bond.estate.user.id;
        setInvolved(usersInvolved);
        setViewedBy(user.id);
        if (context === FollowUpContextEnum.policies) {
          getInvolved(id, usersInvolved);
        }
      } else {
        setInvolved(user.id);
        setViewedBy(user.id);
      }
    }
  }, [messages]);

  return (
    <>
      <Modal
        style={{ width: '50%', height: '90vh' }}
        open={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        closeIcon
      >
        <Modal.Header>{isInternal === null ? "Follow Up" : (isInternal ? "Follow Up Interno" : "Follow Up Geral") }</Modal.Header>
        <Modal.Content>
          <>
            <div>
              <div style={{ marginBottom: '15px', marginLeft: '15px' }}>
                {isInternal !== null && (
                  <div>
                    {!(user.estateId || user.franchiseId) && context !== FollowUpContextEnum.analysis && <Button
                      primary
                      basic
                      icon="angle left"
                      onClick={() => {
                        setIsInternal(null)
                        setMessages([])
                      }}
                    />}
                  </div>
                )}
              </div>
            </div>

            {isInternal === null && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '30vh'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <Button
                    primary
                    onClick={() => setIsInternal(true)}
                    style={{
                      width: '400px',
                      height: '50px',
                      marginBottom: '10px',
                      fontSize: '16px'
                    }}
                  >
                    Selecionar Follow-up Interno
                  </Button>
                  <Button
                    primary
                    basic
                    onClick={() => setIsInternal(false)}
                    style={{ width: '400px', height: '50px', fontSize: '16px' }}
                  >
                    Selecionar Follow-up Geral
                  </Button>
                </div>
              </div>
            )}
            {isInternal !== null &&
              (isLoading ? (
                <Dimmer active={isLoading} inverted>
                  <Loader content="Carregando mensagens..." />
                </Dimmer>
              ) : messages.length > 0 ? (
                <FollowUp
                  messages={messages}
                  id={bondId}
                  relatedId={id}
                  context={context}
                  involved={involved}
                  viewedBy={viewedBy}
                  isBlocked={blocked}
                  isInternal={isInternal}
                />
              ) : (
                <MessageSender
                  id={bondId}
                  relatedId={id}
                  context={context}
                  involved={involved}
                  viewedBy={viewedBy}
                  isBlocked={blocked}
                  isInternal={isInternal}
                />
              ))}
          </>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default FollowUpModal;
