import React from 'react';
import { Button,
  Header,
  Modal,
  ModalContent,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'semantic-ui-react';
import { numberToMoneyString } from '../../../../services/masks';

interface CoverageDetailProps {
  open: boolean;
  onClose: () => void;
  data: any;
};

const CoverageDetailModal = (props: CoverageDetailProps) => {
  const { open, onClose, data: coverageDetailsData } = props;

  return (
    <Modal
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={open}
      onClose={onClose}
      size='small'
    >
      <Header icon='shield alternate' content='Detalhes das coberturas' />
      <ModalContent scrolling>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell textAlign={'left'}>COBERTURA</TableHeaderCell>
              <TableHeaderCell textAlign={'left'}>VALOR MÍNIMO</TableHeaderCell>
              <TableHeaderCell textAlign={'left'}>VALOR MÁXIMO</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {coverageDetailsData.length && (
              coverageDetailsData.map((coverage: any) => {
                return (
                  <TableRow key={coverage.id}>
                    <TableCell textAlign={'left'}>
                      {coverage?.name || '-'}
                    </TableCell>

                    <TableCell textAlign={'left'}>
                      {coverage?.minValue ? numberToMoneyString(coverage.minValue) : '-'}
                    </TableCell>

                    <TableCell textAlign={'left'}>
                      {coverage?.maxValue ? numberToMoneyString(coverage.maxValue) : '-'}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </ModalContent>
      <Modal.Actions>
        <Button icon onClick={onClose}>
          Fechar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CoverageDetailModal;
