import * as Storage from './storage';
import { post } from './request';
import { UserState } from '../types/UserState';
import { getPersistor } from '@rematch/persist';

export const isAuthenticated = () => null !== Storage.get('uid');

export const cleanUserSession = () => {
  Storage.clear();
  getPersistor().purge();
  window.location.reload();
}

// export const refreshToken = async (headers: any): Promise<any> => {
//   const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-token`, {
//     method: 'POST',
//     headers,
//     body: JSON.stringify({ refresh_token: Storage.get('refresh_token') })
//   });

//   if (response.status >= 400) {
//     cleanUserSession();
//     throw new Error('Could not get a new ID Token from provided refresh token')
//   } else {
//     const data = await response.json();
//     Storage.set('refresh_token', data.refresh_token);
//     Storage.set('id_token', data.id_token);
//     return data;
//   }
// }

export async function recoveryPassword(email: string): Promise<void> {
  const payload: any = {
    email: email
  };
  await post('/auth/recovery-password', payload);
}

export async function signIn(email: string, password: string): Promise<UserState> {
  const payload: any = {
    email: email,
    password: password
  };
  const response = await post(`/auth/login`, payload);
  return response;
}
