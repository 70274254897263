import { FormikProps } from "formik";
import React, { useState } from "react";
import { Button, Header, Icon, Modal, Popup } from "semantic-ui-react";

import FileUploader, { FileAttachmentProps, fileTypeProps } from "../../../../../../components/UploadFilesUncontroled";
import { CreateClaimEstateInitialInitialValuesType } from "../../Formik/types/CreateClaimEstateInitialValuesTypes";

import InstrucaoSinistro from '../../../../../../resources/docs/instrucao-sinistro/instrucao-sinistro'

type Properties = {
  fileIdentifiers: fileTypeProps[];
}

const DocumentsComponent = ({ fileIdentifiers,  values, setFieldValue }:  FormikProps<CreateClaimEstateInitialInitialValuesType> & Properties ) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const toggleOpen = () => {
    setOpenModal(true)
  }

  const toggleClose = () => {
    setOpenModal(false)
  }

  return (
    <div style={{ height: '60vh', overflowY: 'scroll', overflowX: 'hidden'}}>
      <p>
        A inserção de documentos é obrigatória para a abertura do sinistro. <br/>
        São aceitos arquivos de até <b>50MB</b> <br/>
        Formatos Aceitos: jpeg, png, webp, doc, docx, pdf e odt
      </p>
      <FileUploader
        fileIdentifiers={fileIdentifiers}
        files={values.claimFiles}
        setFieldValue={setFieldValue}
        fieldName={'claimFiles'}
      />
    </div>
  );
};
export default DocumentsComponent;
