import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Button,
  Header,
  Icon,
  Modal,
  Step,
  Loader,
  Dimmer,
  Dropdown,
  Grid,
  InputOnChangeData
} from 'semantic-ui-react';
import { post } from '../../../../../services/request';
import {
  dangerNotification,
  successNotification
} from '../../../../../services/notification';
import { HireProposalModalProps } from '../../../../bond-insurance-list/components/AnalysisProgressModal/types/ModalHireProposalProps';
import { ConfirmHireModalProps } from '../Types/ConfirmHireModal.types';
import { FieldLabel } from '../../../../../styles';
import { Field, Formik } from 'formik';
import SectionLabel from '../../../../../components/SectionLabel';
import CustomDatePicker from '../../../../../components/CustomDatePicker';
import Input from '../../../../../components/Input';

import AppMaskedInput from '../../../../../components/MaskedInput';
import { ConfirmHirePayloadSubmitProps } from '../Types/ConfirmHirePayloadSubmit.types';
import FormatedTextArea from '../../../../../components/FormatedTextArea';
import { formatAmount, maskMoney } from '../../../../../services/masks';
import { AxiosError } from 'axios';
import { monthDiff } from '../../../../../util';

const GeneralConfirmHireProposalModal = ({
  closeModal,
  openModal,
  hiredDataId,
  observations,
  quotedInstallments,
  quotedValue,
  callback,
  hiredInstallments,
  hiredInstallmentsValue,
  rentInsuranceContractEndDate,
  rentInsuranceContractStartDate,
  userId
}: ConfirmHireModalProps) => {
  const [loading, setLoading] = useState(false);

  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const day = today.getDate();
  const nextYear = new Date(year + 1, month, day);

  async function handleSubmit(data: ConfirmHirePayloadSubmitProps) {
    try {
      setLoading(true);
      await post('/bond-insurance/proposals/hire/confirm/general', {
        policyNumber: data.policyNumber || null,
        rentInsuranceContractStartDate: new Date(data.rentInsuranceContractStartDate),
        rentInsuranceContractEndDate: new Date(data.rentInsuranceContractEndDate),
        hiredInstallments: data.hiredInstallments,
        hiredInstallmentsValue: Number(data.hiredInstallmentsValue),
        hiredDataId,
        userId
      });
      successNotification(
        'Sucesso!',
        'Esta proposta foi marcada como emitida e as notificações foram disparadas.'
      );
    } catch (err) {
      console.log(err);
      dangerNotification('Ops..', (err as AxiosError).message);
    } finally {
      setLoading(false);
      callback();
      closeModal();
    }
  }

  return (
    <div style={{ outline: 'none' }}>
      <Modal closeIcon open={openModal} onClose={closeModal}>
        <Header
          icon="file alternate outline"
          content={`Confirmação de contratação - Seguro Fiança`}
        />
        <Modal.Content>
          <Dimmer active={loading}>
            <Loader indeterminate> Salvando proposta... </Loader>
          </Dimmer>

          <Formik
            initialValues={{
              policyNumber: '',
              rentInsuranceContractStartDate: rentInsuranceContractStartDate || today,
              rentInsuranceContractEndDate: rentInsuranceContractEndDate || nextYear,
              observations: observations,
              hiredInstallments: hiredInstallments,
              formattedHiredInstallmentsValue: maskMoney(
                String(hiredInstallmentsValue),
                true,
                2
              ),
              hiredInstallmentsValue: hiredInstallmentsValue
            }}
            validate={values => {
              //  const errors = {};
              //  if (!values.email) {
              //    errors.email = 'Required';
              //  } else if (
              //    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              //  ) {
              //    errors.email = 'Invalid email address';
              //  }
              //  return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              await handleSubmit(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid>
                  {/* <SectionLabel text="Apólice" /> */}
                  <Grid.Row centered width={16}>
                    <Grid.Column width={8}>
                      <FieldLabel>Número da Apólice</FieldLabel>
                      <Field
                        type="text"
                        name={`policyNumber`}
                        value={values.policyNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={Input}
                        fluid
                      />
                      {errors.policyNumber && touched.policyNumber && errors.policyNumber}
                    </Grid.Column>
                  </Grid.Row>
                  <SectionLabel text="VIGÊNCIA DO SEGURO" />
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <FieldLabel>INICIO DA VIGÊNCIA DO SEGURO* </FieldLabel>
                      <Field
                        name={`rentInsuranceContractStartDate`}
                        selected={
                          new Date(values.rentInsuranceContractStartDate || Date.now())
                        }
                        onChange={(date: Date) => {
                          setFieldValue(`rentInsuranceContractStartDate`, date);
                          const diff = monthDiff(
                            new Date(date),
                            new Date(values.rentInsuranceContractEndDate),
                          );
                          setFieldValue('hiredInstallments', diff);
                        }}
                        component={CustomDatePicker}
                        customInput={
                          <AppMaskedInput
                            mask={[
                              /[0-9]/,
                              /[0-9]/,
                              '/',
                              /[0-9]/,
                              /[0-9]/,
                              '/',
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/
                            ]}
                          />
                        }
                        dateFormat={'dd/MM/yyyy'}
                      />
                    </Grid.Column>

                    <Grid.Column width={8}>
                      <FieldLabel>FIM DA VIGÊNCIA DO SEGURO* </FieldLabel>
                      <Field
                        name={`rentInsuranceContractEndDate`}
                        selected={
                          new Date(values.rentInsuranceContractEndDate || Date.now())
                        }
                        onChange={(date: Date) => {
                          setFieldValue(`rentInsuranceContractEndDate`, date);
                          const diff = monthDiff(
                            new Date(values.rentInsuranceContractStartDate),
                            new Date(date)
                          );
                          setFieldValue('hiredInstallments', diff);
                        }}
                        component={CustomDatePicker}
                        customInput={
                          <AppMaskedInput
                            mask={[
                              /[0-9]/,
                              /[0-9]/,
                              '/',
                              /[0-9]/,
                              /[0-9]/,
                              '/',
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/
                            ]}
                          />
                        }
                        dateFormat={'dd/MM/yyyy'}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <SectionLabel text="Valores a serem contratados" />
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <FieldLabel>Parcelas a serem contratadas</FieldLabel>
                      <Field
                        name={`hiredInstallments`}
                        type="number"
                        value={values.hiredInstallments}
                        // onChange={(_: any, data: InputOnChangeData) => {
                        //   setFieldValue('hiredInstallments', Number(data.value))
                        // }}
                        disabled
                        component={Input}
                        fluid
                        step={1}
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <FieldLabel>Valor da parcela</FieldLabel>
                      <Field
                        name={`hiredInstallmentsValue`}
                        value={values.formattedHiredInstallmentsValue}
                        // onChange={(_: any, data: InputOnChangeData) => {
                        //   setFieldValue('hiredInstallmentsValue', Number(data.value.replace(/\D/g, '')) / 100)
                        //   setFieldValue('formattedHiredInstallmentsValue', formatAmount(Number(data.value.replace(/\D/g, ''))))
                        // }}
                        disabled
                        component={Input}
                        fluid
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <FieldLabel>Prêmio a ser contratado</FieldLabel>
                      <Field
                        name={`totalValue`}
                        disabled
                        value={maskMoney(
                          String(
                            values.hiredInstallments * values.hiredInstallmentsValue
                          ),
                          true,
                          2
                        )}
                        component={Input}
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <SectionLabel text="Valores cotados" />
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <FieldLabel>Parcelas cotadas</FieldLabel>
                      <Field
                        name={`hiredInstallments`}
                        type="number"
                        value={quotedInstallments}
                        component={Input}
                        fluid
                        disabled
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <FieldLabel>Valor da parcela</FieldLabel>
                      <Field
                        name={`hiredInstallmentsValue`}
                        value={maskMoney(String(quotedValue), true, 2)}
                        component={Input}
                        fluid
                        disabled
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <FieldLabel>Prêmio cotado</FieldLabel>
                      <Field
                        name={`totalValue`}
                        disabled
                        value={maskMoney(
                          String(quotedInstallments * quotedValue),
                          true,
                          2
                        )}
                        component={Input}
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <SectionLabel text="Observações" />
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <Field
                        name={`bondInsurance.observations`}
                        rows={8}
                        value={values.observations}
                        readonly
                        component={FormatedTextArea}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <Grid>
                  <Grid.Row width={16}>
                    <Grid.Column width={11} />
                    <Grid.Column width={5}>
                      <Modal.Actions>
                        <Button color="red" onClick={() => closeModal()}>
                          <Icon name="remove" /> Sair
                        </Button>
                        <Button type="submit" disabled={isSubmitting} color="green">
                          <Icon name="checkmark" />
                          Confirmar
                        </Button>
                      </Modal.Actions>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </form>
            )}
          </Formik>
        </Modal.Content>
      </Modal>
    </div>
  );
};
export default GeneralConfirmHireProposalModal;
