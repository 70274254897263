import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store, history } from './store';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { getPersistor } from '@rematch/persist';
import { PersistGate } from 'redux-persist/integration/react';
import '../node_modules/semantic-ui-css/semantic.min.css';
import '../node_modules/react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';
// import * as Sentry from "@sentry/react";
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';

const isDevelop = window.location.hostname === 'app.dev.4seg.com.br' || window.location.hostname === 'localhost';
if (!isDevelop) {
  // Sentry.init({
  //   dsn: process.env.REACT_APP_SENTRY_DSN,
  //   replaysSessionSampleRate: 0.1,
  //   replaysOnErrorSampleRate: 1.0,
  //   integrations: [new Sentry.Replay(), new Sentry.BrowserTracing()],
  // });
}

const Application = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        refetchOnMount: false,
      }
    }
  });
  useEffect(() => {
    const { protocol, href } = window.location;

    if (protocol === 'http:' && process.env.REACT_APP_STAGE === 'production') {
      const newUrl = href.replace('http', 'https');
      window.location.replace(newUrl);
    }
  }, []);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <PersistGate persistor={getPersistor()}>
          <QueryClientProvider client={queryClient}>
            <ReactNotification />
            <App />
          </QueryClientProvider>
        </PersistGate>
      </ConnectedRouter>
    </Provider>
  );
};
ReactDOM.render(
  <CacheBuster currentVersion={version} isVerboseMode={false} isEnabled={true}>
    <Application />
  </CacheBuster>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
