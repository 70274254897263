import React from 'react';
import { Dimmer, Icon, Loader, Modal } from 'semantic-ui-react';
import PottencialEdit from './Pottencial';
import { InsurersCodeEnum } from '../../../../enums/insurers-code.enum';
import SegimobEditQuotation from './TokioMarineSegimob/EditQuotation';
import { FireInsuranceAnalysisType } from '../../types/fireInsuranceAnalysisType';
import { Header } from './TokioMarineSegimob/EditQuotation/styles';
import { FormProps } from './TokioMarineSegimob/EditQuotation/types';
import { put } from '../../../../services/request';
import {
  dangerNotification,
  successNotification
} from '../../../../services/notification';
interface EditModalInterface {
  open: boolean;
  fireInsurance: FireInsuranceAnalysisType;
  close: () => void;
}

const EditModal = (props: EditModalInterface) => {
  const { fireInsurance, close, open } = props;
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSegimobSubmit = async (data: FormProps) => {
    setLoading(true);
    const payload = {
      codigoExternoImovel: '',
      quantidadeParcelasSelecionada: fireInsurance.installments,
      valorParcela: fireInsurance.value,
      calculo: {
        idSeguradora: 'TOKIO_MARINE',
        idCorretora: '11',
        idOcupacao: fireInsurance.fireInsurance.occupation,
        idAssistencia: 2,
        valorAluguel: fireInsurance.fireInsurance.rentValue,
        dataInicialVigencia: fireInsurance.fireInsurance.startDate,
        mesesVigencia: fireInsurance.fireInsurance.vigency,
        valorPremioTotal: fireInsurance.grossPremium,
        valorPercentualProlabore: fireInsurance.estateComissionPercentage,
        valorPercentualComissaoCorretora: 35,
        flagAluguelAlterado: true,
        //valorPercentualPredio: 70,
        //valorPercentualConteudo: 30,
        coberturas: data.coverages.map(coverage => {
          return {
            id: coverage.id,
            flagSelecionada: coverage.enabled,
            valorImportanciaSegurada: coverage.value,
            valorImportanciaSeguradaMasked: coverage.value,
            valorPremioTotal: coverage.totalPremium,
            nomeCobertura: coverage.coverageName,
            valorLimiteMaximoImportanciaSegurada: coverage.maxValue,
            valorLimiteMinimoImportanciaSegurada: coverage.minValue
          };
        })
      },
      endereco: {
        cep: data.propertyAddress.zipCode,
        logradouro: data.propertyAddress.street,
        numero: data.propertyAddress.number,
        complemento: data.propertyAddress.complement,
        bairro: data.propertyAddress.neighborhood,
        cidade: data.propertyAddress.city,
        uf: data.propertyAddress.state
      },
      inquilino: {
        nome: data.tenantIdentification.name,
        cpfCnpj: data.tenantIdentification.document?.replace(/\D/g, ''),
        email: data.tenantIdentification.email,
        dataNascimento: data.tenantIdentification.birthdate,
        numeroTelefone: data.tenantIdentification.phoneNumber?.replace(
          /\D/g,
          ''
        )
      },
      proprietario: {
        nome: data.ownerIdentification.name,
        cpfCnpj: data.ownerIdentification.document?.replace(/\D/g, ''),
        email: data.ownerIdentification.email
      }
    };
    try {
      await put(
        `/fire-insurance-quotations/segimob/budget/${fireInsurance.id}`,
        payload
      );
      successNotification('Sucesso', 'Orçamento editado com sucesso');
      close();
    } catch (err) {
      console.log(err);
      dangerNotification('Atenção', 'Erro ao editar orçamento');
    } finally {
      setLoading(false);
    }
  }

  const renderView = (insurerCode: string) => {
    switch (insurerCode) {
      case InsurersCodeEnum.Pottencial:
        return <PottencialEdit fireInsurance={fireInsurance} close={close} />;
      case InsurersCodeEnum.TokioMarineSegimob:
        return (
          <SegimobEditQuotation
            handleSubmit={handleSegimobSubmit}
            fireInsuranceAnalysis={fireInsurance}
            close={close}
          />
        );
      default:
        return <div>Não implementado</div>;
    }
  };

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <Modal open={open} onClose={close}>
        <Modal.Header>
          <Header>
            <h3>
              <Icon name="shield" /> Editar Orçamento
            </h3>
          </Header>
        </Modal.Header>
        <Modal.Content scrolling>{renderView(fireInsurance?.insurer.code)}</Modal.Content>
      </Modal>
    </>
  );
};

export default EditModal;
