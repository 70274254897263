import React from 'react';
import { DropdownProps } from 'semantic-ui-react';
import Dropdown from './Dropdown';
import { iRootState, iRootDispatch } from '../store';
import { connect } from 'react-redux';
import { CustomDropDownOptions } from '../types';
import {AvailableBrokersAndEstatesState} from "../store/types/temp-types";

const mapState = (state: iRootState) => ({
  availableBrokersAndEstates: state.availableBrokersAndEstates,
  user: state.user
});

const mapDispatch = (dispatch: iRootDispatch) => ({
  updateAvailableBrokersAndEstates: (availableBrokers: AvailableBrokersAndEstatesState) =>
    dispatch.availableBrokersAndEstates.updateAvailableBrokersAndEstates(availableBrokers),
});

type Props = {
  completeValue: boolean;
}

type BrokersDropdownProps = DropdownProps & Props & ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const BrokersDropdown = (props: BrokersDropdownProps) => {
  const availableBrokers: CustomDropDownOptions[] = props.availableBrokersAndEstates.brokers;
  return (
    <>
      <Dropdown {...props}
        placeholder={'Selecione...'}
        defaultValue={availableBrokers.length === 1 ? availableBrokers[0].value : null}
        disabled={availableBrokers.length === 1}
        options={availableBrokers} />
    </>
  );
};

export default connect(mapState, mapDispatch)(BrokersDropdown);
