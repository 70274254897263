export const ContextEnum = [
  {
    index: 0,
    value: 'BOND_INSURANCE',
    text: 'BOND_INSURANCE',
  },
  {
    index: 1,
    value: 'BOND_INSURANCE_ANALYSIS',
    text: 'BOND_INSURANCE_ANALYSIS',
  },
  {
    index: 2,
    value: 'BOND_INSURANCE_ANALYSIS_UPDATE',
    text: 'BOND_INSURANCE_ANALYSIS_UPDATE',
  },
  {
    index: 3,
    value: 'BOND_INSURANCE_INTEGRED',
    text: 'BOND_INSURANCE_INTEGRED',
  },
  {
    index: 4,
    value: 'BOND_INSURANCE_NOT_INTEGRED',
    text: 'BOND_INSURANCE_NOT_INTEGRED',
  },
  {
    index: 5,
    value: 'CLAIM',
    text: 'CLAIM'
  },
  {
    index: 6,
    value: 'ESTATES',
    text: 'ESTATES'
  }
]

export const OriginEnum = [
  {
    index: 0,
    value: 'USERLOG',
    text: 'USERLOG'
  },
  {
    index: 1,
    value: 'SYSLOG',
    text: 'SYSLOG'
  },
  {
    index: 2,
    value: 'WEBSERVICE',
    text: 'WEBSERVICE'
  }
]

export const TypeEnum = [
  {
    index: 0,
    value: 'ALERT',
    text: 'ALERT'
  },
  {
    index: 1,
    value: 'LOG',
    text: 'LOG'
  }
]

export const ProviderEnum = [
  {
    index: 0,
    value: 'QUIVER',
    text: 'QUIVER'
  },
  {
    index: 1,
    value: 'SENSEDATA',
    text: 'SENSEDATA'
  },
]

export const EntityEnum = [
  {
    index: 0,
    value: 'bond-insurance-policy',
    text: 'BOND_INSURANCE_POLICY'
  },
]

export const StatusEnum = [
  {
    index: 0,
    value: 1,
    text: 'Não Sincronizado'
  },
  {
    index: 1,
    value: 2,
    text: 'Sincronizado'
  },
]

export const LabelEnum = [
  {
    index: 0,
    text: 'ANALYSIS',
    value: 'ANALYSIS'
  },
  {
    index: 1,
    text: 'CONFIRM_POLICY',
    value: 'CONFIRM_POLICY'
  },
  {
    index: 2,
    text: 'CONFIRM_PROPOSAL',
    value: 'CONFIRM_PROPOSAL'
  },
  {
    index: 3,
    text: 'CANCELLATION',
    value: 'CANCELLATION'
  },
  {
    index: 4,
    text: 'CREATED',
    value: 'CREATED'
  },
  {
    index: 5,
    text: 'ERROR',
    value: 'ERROR'
  },
  {
    index: 6,
    text: 'FOLLOW_UP',
    value: 'FOLLOW_UP'
  },
  {
    index: 7,
    text: 'GARANTTI_INTEGRATION',
    value: 'GARANTTI_INTEGRATION'
  },
  {
    index: 8,
    text: 'HIRING',
    value: 'HIRING'
  },
  {
    index: 9,
    text: 'REANALYSIS',
    value: 'REANALYSIS'
  },
  {
    index: 10,
    text: 'SERASA',
    value: 'SERASA'
  },
  {
    index: 11,
    text: 'SUBSCRIPTION',
    value: 'SUBSCRIPTION'
  },
  {
    index: 12,
    text: 'UPDATED',
    value: 'UPDATED'
  },
  {
    index: 13,
    text: 'WARNING',
    value: 'WARNING'
  }
]