import * as Yup from "yup";
import {MINIMUM_COVERAGES_AMOUNT, MINIMUM_COVERAGES_AMOUNT_MESSAGE} from "./constants";
import {connectorCoverageOptionYupSchema, ConnectorCoverageSchema} from "./connector-coverage-option-schema";

export const connectorCoverageSectionYupSchema: Yup.ObjectSchema<ConnectorCoverageSectionSchema> = Yup.object().shape({
  selectedCoveragesAmount: Yup.number().integer()
    .min(MINIMUM_COVERAGES_AMOUNT, MINIMUM_COVERAGES_AMOUNT_MESSAGE),
  coverages: Yup.array().of(connectorCoverageOptionYupSchema)
});

export type ConnectorCoverageSectionSchema = {
  selectedCoveragesAmount: number;
  coverages: Array<ConnectorCoverageSchema>;
}
