import React from 'react';

import { Icon, IconProps } from 'semantic-ui-react';

import styled from 'styled-components';

const PointerIcon = styled(Icon)`
  cursor: pointer;  
  
  &:hover{
    opacity:0.5;
  }
`;

const IconButton = (props: IconProps) => (
  <>
    <PointerIcon {...props} />
  </>
);

export default IconButton;
