import React, { useState } from 'react';
import { Button, Dropdown, Grid, Modal } from 'semantic-ui-react';
import { SetResponsibleModalProps } from './types';
import { FormContainer, Form, ButtonContainer } from './styles';
import { dangerNotification, successNotification, warningNotification } from '../../../../services/notification';
import { getUsers } from '../../../../services/broker';
import { UsersToCustomDropDownOptions } from '../../../../util';
import { useQuery } from '@tanstack/react-query';
import { updateRenewalResponsible } from '../../../../services/bond-insurance-policy';


const SetResponsibleModal: React.FC<SetResponsibleModalProps> = (props: SetResponsibleModalProps) => {
  const {
    isOpen,
    onClose,
    bondInsuranceHiredDataId,
    responsible
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [renewalResponsible, setRenewalResponsible] = useState<any>(responsible);

  const getAccountManagers = async () => {
    const result = await getUsers(props?.user?.broker?.id, [{ isDropdown: true}]);
    return UsersToCustomDropDownOptions(false, result.map((user, index) => {
      return{
        id: user.id,
        name: user.name
      };
    }));
  };

  const {
    data: accountManagerOptions,
    isFetching: isAccountManagersLoading,
  } = useQuery(
    ['getAccountManagers'],
    getAccountManagers,
    {
      keepPreviousData: true,
      enabled: !!props?.user?.broker?.id,
    }
  );

  if (!isOpen) {
    return null;
  }

  const handleFormSubmit = async (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    setIsLoading(true);
    if (renewalResponsible) {
      const result = await updateRenewalResponsible(bondInsuranceHiredDataId, renewalResponsible);
      if (result.statusCode === 200) {
        successNotification('Sucesso', String(result.message));
      } else {
        dangerNotification('Erro', String(result.message));
      }
    } else {
      warningNotification(
        'Atenção',
        'Por favor, selecione um responsável.'
      );
    }
    setIsLoading(false);
    onClose(e);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeIcon
    >
      <Modal.Header>Selecionar o responsável pela renovação</Modal.Header>
        <Form  onSubmit={handleFormSubmit}>
          <FormContainer>
            <Grid>
              <Grid.Row>
              <Grid.Column width={8}>
                <label style={{ paddingBottom: '6px' }}>Responsável pela Renovação:</label>
                <Dropdown
                  style={{ width: '100%' }}
                  value={renewalResponsible}
                  name={`accountManager`}
                  placeholder={'Selecionar...'}
                  options={accountManagerOptions}
                  disabled={isLoading || isAccountManagersLoading}
                  loading={isAccountManagersLoading}
                  search
                  selection
                  onChange={(e, { value }) => {
                    setRenewalResponsible(value);
                  }}
                  clearable
                />
              </Grid.Column>
              </Grid.Row>
            </Grid>
          </FormContainer>
        </Form>
      <Modal.Actions>
        <ButtonContainer>
          <Button type="button" color="red" onClick={onClose}>
            Cancelar
          </Button>
          <Button type="button" color="green" onClick={handleFormSubmit}>
            Confirmar
          </Button>
        </ButtonContainer>
      </Modal.Actions>
    </Modal>
  );
};

export default SetResponsibleModal;
