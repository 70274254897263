import React from 'react';

import { Button as SemanticButton, ButtonProps } from 'semantic-ui-react';

const Button = (props: ButtonProps) => {
  return (
    <SemanticButton {...props}>{props.text}</SemanticButton>
  )
}


export default Button;
