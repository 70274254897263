import React, { useState } from 'react';
import {
  Grid,
  Input,
  Dimmer,
  Loader,
  Menu,
  Segment,
  Table,
  Icon,
  Checkbox
} from 'semantic-ui-react';
import Button from '../../../../../../components/Button';
import { Field, FormikProps } from 'formik';
import SectionLabel from '../../../../../../components/SectionLabel';
import { ClickableTableCell, FieldLabel, SelectableTableRow } from '../../../../../../styles';
import { CreateCancelationInitialValuesType } from '../../Formik/types/CreateCancelationInitialValuesTypes';
import { maskCpfOrCnpj } from '../../../../../../services/masks';
import { Header, TableWrapper, NotFoundWrapper, CustomCheckbox } from './style';
import { dangerNotification } from '../../../../../../services/notification';
import { getRecords } from '../../../../../../services/find-record';
import { ListRecordsType } from '../../../../../find-record/types/options-find.type';
import { DetailModal } from '../../../../../find-record/components/RecordDetailsModal';
import { FindRecordOriginEnum } from '../../../../../../enums/find-record-origin.enum';
import { PushRoute } from '../../../../../../util';
import { useHistory } from 'react-router-dom';

const CheckPolicyStep = (props: FormikProps<CreateCancelationInitialValuesType>) => {
  const { values, setFieldValue } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [proceed, setProceed] = useState<boolean>(false);
  const [data, setData] = useState<ListRecordsType[]>([]);

  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [recordId, setRecordId] = useState<string>('');
  const [origin, setOrigin] = useState<string>('');

  const history = useHistory();

  const getData = async () => {
    setIsLoading(true);

    const filters: any = {
      page: 1,
      type: 'document',
      argument: values.tenant.document,
      modules: 'POLICY'
    };

    try {
      const records = await getRecords(filters);
      setData(records.data);
      setFieldValue('records', records.data);
      setFieldValue('checked', true);
    } catch (error) {
      dangerNotification('Ops..', 'Erro ao tentar buscar apólices, tente novamente.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheck = () => {
    setFieldValue('proceed', !proceed);
    setProceed((prevState) => !prevState);
  }

  const handleOpen = () => {
    setOpenDetail(true)
  }

  const handleClose = () => {
    setOpenDetail(false)
  }

  const openDetailModal = (item: any) => {
    setRecordId(item.id);
    setOrigin(item.origem);
    setOpenDetail(true);
  };

  const redirectToRegister = (item: ListRecordsType) => {
    switch (item.origem) {
      case FindRecordOriginEnum.analysis: {
        PushRoute(history, props, 'bond-insurances', { bondInsuranceId: item.id })
        break;
      }
      case FindRecordOriginEnum.proposals: {
        PushRoute(history, props, 'bond-insurance-proposals', { bondInsuranceId: item.id })
        break;
      }

      case FindRecordOriginEnum.policies: {
        PushRoute(history, props, 'bond-insurance-policies', { bondInsuranceId: item.id })
        break;
      }

      case FindRecordOriginEnum.canceleds: {
        PushRoute(history, props, 'canceled-bond-insurances', { bondInsuranceId: item.id })
        break;
      }

      case FindRecordOriginEnum.expireds: {
        PushRoute(history, props, 'expired-bond-insurances', { bondInsuranceId: item.id })
        break;
      }
    }
  }

  const renderTable = (data: ListRecordsType[]) => {
    const dataFiltered = data.map(item => {
      if (item.property && item.property?.zipCode === undefined) {
        item.property.zipCode = (item as any).property.zip_code
      }
      return item;
    });

    const table = dataFiltered.map(record => {
      return (
        <>
          <SelectableTableRow key={record.id} style={{ cursor: 'pointer' }}>
            <ClickableTableCell onClick={() => openDetailModal(record)}>
              {record.origem}
            </ClickableTableCell>
            <ClickableTableCell onClick={() => openDetailModal(record)}>
              {record.estate}
            </ClickableTableCell>
            <ClickableTableCell onClick={() => openDetailModal(record)}>
              {record.insurer}
            </ClickableTableCell>
            <ClickableTableCell onClick={() => openDetailModal(record)}>
              {record.property?.street + ', ' + record.property?.number + ', ' + record.property?.district + ', ' + record.property?.city + ' - ' + record.property?.state + ', CEP: ' + record.property?.zipCode}
            </ClickableTableCell>
            <ClickableTableCell onClick={() => openDetailModal(record)}>
              {record.name ? record.name : record.company_name}
              <br />({record.cpf ? maskCpfOrCnpj(record.cpf) : record.cnpj ? maskCpfOrCnpj(record.cnpj) : ''})
            </ClickableTableCell>
            <Table.Cell>
              <Menu compact>
                <Menu.Item>
                  <Button
                    color="teal"
                    icon="eye"
                    onClick={() => openDetailModal(record)}
                  />
                </Menu.Item>
                <Menu.Item>
                  <Button
                    primary
                    icon="arrow right"
                    onClick={() => redirectToRegister(record)}
                  />
                </Menu.Item>
              </Menu>
            </Table.Cell>
          </SelectableTableRow>
        </>
      );
    });

    return table;
  }

  return (
    <>
      {
        openDetail &&
        <DetailModal
          isOpen={openDetail}
          onOpen={handleOpen}
          onClose={handleClose}
          header={origin}
          recordId={recordId}
          origin={origin}
          key={recordId}
        />
      }
      <Grid columns="equal" style={{ paddingTop: '2em' }}>
        <SectionLabel text="INFORMAÇÕES DO LOCATÁRIO" />
        <Grid.Row verticalAlign='bottom'>
          <Grid.Column width={12}>
            <FieldLabel>CPF/CNPJ do Locatário*</FieldLabel>
            <Field
              value={values.tenant.document}
              component={Input}
              fluid
              onChange={(_e: any, { value }: { value: string }) =>
                setFieldValue('tenant.document', maskCpfOrCnpj(value))
              }
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <Button
              fluid
              type={'submit'}
              color="blue"
              text="Pesquisar"
              onClick={() => { getData() }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid columns="equal">
        <SectionLabel text="APÓLICES RELACIONADAS" />
        <p>Evite cancelamentos duplicados. Verifique se a apólice que deseja cancelar se encontra na listagem abaixo.</p>
        <Grid.Row>
          <Grid.Column width={16}>
            <Dimmer.Dimmable as={Segment} dimmed={isLoading}>
              <Dimmer active={isLoading} inverted>
                <Loader />
              </Dimmer>
              {(data && data.length > 0) ? (
                <TableWrapper>
                  <Table>
                    <Header>
                      <Table.Row>
                        <Table.HeaderCell width={2}>Origem</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Imobiliária</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Seguradora</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Endereço</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Inquilino</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Ações</Table.HeaderCell>
                      </Table.Row>
                    </Header>
                    <Table.Body>
                      {renderTable(data)}
                    </Table.Body>
                  </Table>
                </TableWrapper>
              ) : (
                <NotFoundWrapper>
                  <p>Apólices não encontradas</p>
                </NotFoundWrapper>
              )}
            </Dimmer.Dimmable>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <p>
              <Icon name="info circle" color="blue" />{' '}
              <b>Caso a apólice não tenha sido localizada, siga com a criação do cancelamento externo *</b>
            </p>
            <CustomCheckbox
              label="Estou ciente de que a apólice não se encontra na listagem do sistema e desejo solicitar um novo cancelamento de apólice."
              checked={proceed}
              onChange={handleCheck}
              proceed={!proceed}
              checke={values.checked}
            />
            {!values.proceed && (
              <div>
                <small style={{ color: 'red' }} >
                  * Antes de prosseguir com a criação de um novo cancelamento é necessário reconhecer as condições acima.
                </small>
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default CheckPolicyStep;
